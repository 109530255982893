import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  CircularProgressOverlay,
  ClassesType,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import RightDrawer from './RightDrawer'

const useStyles = makeStyles(
  (theme: PuiTheme) => {
    const height =
      (theme.mixins.toolbar.minHeight as number) +
      theme.constants.soapHeaderHeight

    return {
      informationPanel: {
        boxShadow: '-2px 0 4px 0 rgb(0 0 0 / 10%)',
        maxWidth: 400,
        minWidth: 400,
        padding: theme.spacing(1),
        top: height,
        maxHeight: `calc(100vh - ${height}px)`,
        zIndex: theme.utils.modifyZIndex(theme.zIndex.rightRail, 'above'),
      },
    }
  },
  { name: 'PuiInformationPanel' },
)

export interface PuiInformationPanelProps {
  children: React.ReactNode
  classes?: ClassesType<typeof useStyles>
  isLoading?: boolean
  isOpened: boolean
  onClose: () => void
}

const PuiInformationPanel = ({
  children,
  classes: classesProp,
  isLoading = false,
  isOpened,
  onClose,
}: PuiInformationPanelProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <RightDrawer
      classes={{ paper: classes.informationPanel }}
      open={isOpened}
      onClose={onClose}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        rowGap={1}
        width="100%"
      >
        <CircularProgressOverlay halfTransparent open={isLoading} />
        {children}
      </Box>
    </RightDrawer>
  )
}

export default PuiInformationPanel
