import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as R from 'ramda'
import { Defaults, PuiTheme, Text } from '@pbt/pbt-ui-components'

import { fetchMoreForSearch } from '~/store/actions/orders'
import {
  useGetDisabledOrderWeakMap,
  useGetSelectedOrderWithNotes,
} from '~/store/hooks/orders'
import {
  getAppliedPatientRestrictions,
  getOrdersIsReceiving,
  getOrdersList,
  getOrdersSearchResults,
  getOrdersSearchResultsTotalCount,
  getOrdersTotalCount,
} from '~/store/reducers/orders'
import { BaseChargeTabProps, OrderFilter, OrderListType } from '~/types'
import { hasPriceOrAggregatedPrice } from '~/utils/orderUtils'
import { useInfiniteListDynamicHeight } from '~/utils/useInfiniteListDynamicHeight'

import ChargeSearchResultItem from './ChargeSearchResultItem'

const DEFAULT_HEIGHT = 80
const MIN_HEIGHT = 345

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    hide: {
      display: 'none',
    },
    listContainer: {
      backgroundColor: theme.colors.tableBackground,
      boxShadow:
        '3px 3px 20px 0px rgba(168, 163, 163, 0.5), 0 2px 4px 0 rgba(0, 0, 0, 0.2)',
      borderRadius: '0 0 2px 2px',
      marginTop: theme.spacing(-2),
      paddingTop: theme.spacing(3),
      position: 'absolute',
      left: 0,
      right: 0,
      top: 40,
    },
    mainWrapper: {
      '& > div': {
        flexGrow: 1,
      },
    },
  }),
  { name: 'ChargeSearchResults' },
)

export interface ChargeSearchResultsProps extends BaseChargeTabProps {
  appointmentId?: string
  clearSearch?: () => void
  clientId: string
  itemsRefs: React.RefObject<any>[]
  onSearch: (value: string) => void
  orderFilters: OrderFilter[]
  patientId: string
  searchTerm: string
}

const ChargeSearchResults = ({
  appointmentId,
  clearSearch,
  clientId,
  disabled,
  handleEditListItem,
  handleRefill,
  handleSavePrescription,
  itemsRefs,
  logItemStateGetter,
  onSearch,
  openPrescriptionDialog,
  orderFilters,
  patientId,
  searchTerm,
}: ChargeSearchResultsProps) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const { t } = useTranslation('Search')

  const isReceivingOrders = useSelector(getOrdersIsReceiving)
  const orders = useSelector(getOrdersList)
  const ordersTotalCount = useSelector(getOrdersTotalCount)
  const searchResults = useSelector(getOrdersSearchResults)
  const searchResultsTotalCount = useSelector(getOrdersSearchResultsTotalCount)
  const appliedPatientRestrictions = useSelector(getAppliedPatientRestrictions)

  const infiniteScrollRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const items = searchResults.map((result) => result.item)
  const hasSearchedWithoutResults =
    Boolean(searchTerm) && items.length === 0 && !isReceivingOrders
  const hasMinSearchLength = searchTerm.length < Defaults.MIN_SEARCH_LENGTH

  const getSelectedOrderWithNotes = useGetSelectedOrderWithNotes()
  const disabledWeakMap = useGetDisabledOrderWeakMap(
    orders.map(getSelectedOrderWithNotes),
  )

  const dynamicHeight = useInfiniteListDynamicHeight({
    minHeight: MIN_HEIGHT,
    defaultHeight: DEFAULT_HEIGHT,
    hasSearchedWithoutResults,
    infiniteContainerWrapperRef: containerRef,
    isFetchingMore: isReceivingOrders,
    itemsLength: items.length,
  })

  const hasMoreItems = R.isNil(searchResultsTotalCount)
    ? orders.length < ordersTotalCount
    : searchResults.length < searchResultsTotalCount

  const onFetchMore = useCallback(() => {
    dispatch(
      fetchMoreForSearch({
        applyPatientRestriction: appliedPatientRestrictions,
        filters: R.pluck('type', orderFilters),
        clientId,
        eventId: appointmentId,
        forShipments: false,
        listType: OrderListType.FULL,
        patientId,
        searchTerm,
        withTasks: true,
        from: items.length,
        to: items.length + Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
      }),
    )
  }, [
    appointmentId,
    clientId,
    appliedPatientRestrictions,
    items.length,
    orderFilters,
    patientId,
    searchTerm,
  ])

  useEffect(() => {
    if (hasMinSearchLength && clearSearch) {
      clearSearch()
    }
  }, [searchTerm])

  // Trigger onSearch when toggling enableBackendFiltering
  useEffect(() => {
    onSearch(searchTerm)
  }, [appliedPatientRestrictions])

  const firstItemWithoutPrice = items.findIndex(
    (item) => !hasPriceOrAggregatedPrice(item),
  )

  return (
    <Grid
      container
      item
      className={classes.mainWrapper}
      height={R.isEmpty(items) ? DEFAULT_HEIGHT : MIN_HEIGHT}
      position="absolute"
      ref={containerRef}
      width="100%"
      wrap="nowrap"
      zIndex={0}
    >
      <InfiniteScroll
        className={classNames(classes.listContainer, {
          [classes.hide]: hasMinSearchLength,
        })}
        dataLength={items.length}
        hasMore={hasMoreItems}
        height={dynamicHeight}
        loader={
          isReceivingOrders && (
            <CircularProgress color="secondary" size={16} sx={{ ml: 2 }} />
          )
        }
        next={onFetchMore}
        scrollableTarget="scrollableDiv"
      >
        <Grid id="scrollableDiv" ref={infiniteScrollRef}>
          {isReceivingOrders && (
            <Grid
              container
              justifyContent="center"
              position="absolute"
              top="40%"
            >
              <CircularProgress color="secondary" size={24} />
            </Grid>
          )}
          {hasSearchedWithoutResults && (
            <Text px={2} variant="body">
              {t('Search:NO_SEARCH_RESULTS')} ‘
              <Text strong component="span">
                {searchTerm}
              </Text>
              ‘
              {appliedPatientRestrictions && (
                <>
                  <br />
                  {t('Search:TRY_TURNING_OFF_FILTERS')}
                </>
              )}
            </Text>
          )}
          {!R.isEmpty(items) &&
            items.map((order, index) => {
              const searchResultFilter = searchResults.find(
                (r) => r.item === order,
              )?.filter
              const isFirstItem = index === 0
              const isFirstOccurrenceWithoutPrice =
                index === firstItemWithoutPrice
              return (
                <ChargeSearchResultItem
                  clientId={clientId}
                  contentRef={itemsRefs[index]}
                  disabled={disabled}
                  disabledWeakMap={disabledWeakMap}
                  handleEditListItem={handleEditListItem}
                  handleRefill={handleRefill}
                  handleSavePrescription={handleSavePrescription}
                  isFirstItem={isFirstItem}
                  isFirstOccurrenceWithoutPrice={isFirstOccurrenceWithoutPrice}
                  isReceivingOrders={isReceivingOrders}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  logItemStateGetter={logItemStateGetter}
                  openPrescriptionDialog={openPrescriptionDialog}
                  order={order}
                  patientId={patientId}
                  searchResultFilter={searchResultFilter}
                  searchTerm={searchTerm}
                />
              )
            })}
        </Grid>
      </InfiniteScroll>
    </Grid>
  )
}

export default ChargeSearchResults
