import React, { PropsWithChildren, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useSearchParams } from 'react-router-dom'
import { isNil } from 'ramda'
import { CircularProgressOverlay } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { USER_NO_ACCESS_TO_BUSINESS } from '~/constants/errorMessages'
import FeatureToggle from '~/constants/featureToggle'
import { fetchGroupRoles } from '~/store/actions/roles'
import {
  useUpdateBusinessIdUrlParam,
  useUpdateCurrentUsersBusinessId,
} from '~/store/hooks/user'
import {
  getAssignedLocations,
  getCurrentBusiness,
  getCurrentBusinessId,
  getCurrentUserId,
} from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import useDialog from '~/utils/useDialog'

const useNoAccessToBusinessAlert = () => {
  const { t } = useTranslation('Common')

  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  return (onCloseCallback: () => void) => {
    const onClose = () => {
      onCloseCallback()
      closeAlert()
    }
    openAlert({
      message: USER_NO_ACCESS_TO_BUSINESS,
      okButtonText: t('Common:OK'),
      onOk: onClose,
      onClose,
    })
  }
}

export const BusinessIdParamSync = ({ children }: PropsWithChildren) => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const currentUserId = useSelector(getCurrentUserId)
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const currentBusiness = useSelector(getCurrentBusiness)
  const isBusinessIdUrlParamEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BUSINESS_ID_URL_PARAM),
  )
  const isGroupSharedRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.AUTO_CWAV_VCR_ROLES_ASSIGN),
  )
  const assignedBusinesses = useSelector(getAssignedLocations)

  const updateBusinessIdUrlParam = useUpdateBusinessIdUrlParam()
  const updateCurrentUsersBusinessId = useUpdateCurrentUsersBusinessId()
  const openNoAccessToBusinessAlert = useNoAccessToBusinessAlert()

  const isSyncEnabled = !isNil(currentUserId) && isBusinessIdUrlParamEnabled
  const businessIdFromUrl = searchParams.get('businessId')
  const isBusinessIdMismatch =
    isSyncEnabled &&
    businessIdFromUrl &&
    businessIdFromUrl !== currentBusinessId

  const location = useLocation()

  useEffect(() => {
    if (isGroupSharedRolesEnabled && currentBusiness) {
      if (currentBusiness.children) {
        dispatch(fetchGroupRoles(currentBusiness.id))
      } else if (currentBusiness.parentBusinessId) {
        dispatch(fetchGroupRoles(currentBusiness.parentBusinessId))
      }
    }
  }, [isGroupSharedRolesEnabled, currentBusiness?.id])

  useEffect(() => {
    if (isBusinessIdMismatch && currentBusinessId) {
      if (assignedBusinesses.some(({ id }) => id === businessIdFromUrl)) {
        updateCurrentUsersBusinessId({
          businessId: businessIdFromUrl,
          userId: currentUserId,
        })
      } else {
        openNoAccessToBusinessAlert(() =>
          updateBusinessIdUrlParam(currentBusinessId),
        )
      }
    }
  }, [
    isBusinessIdMismatch,
    currentBusinessId,
    businessIdFromUrl,
    currentUserId,
  ])

  useEffect(() => {
    if (isSyncEnabled && !businessIdFromUrl && currentBusinessId) {
      // Using setTimeout with a minimal delay to push adding business id param
      // logic to the end of the call stack. This ensures that any `navigate` or
      // `setSearchParams` logic in child components runs first, preventing the
      // unintentional removal of URL parameters.
      // some info here: https://github.com/remix-run/react-router/issues/8974

      setTimeout(() => {
        updateBusinessIdUrlParam(currentBusinessId)
      }, 1)
    }
  }, [currentBusinessId, location.search])

  if (isBusinessIdMismatch) {
    return <CircularProgressOverlay />
  }

  return <>{children}</>
}
