import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, PuiPopper, PuiTheme } from '@pbt/pbt-ui-components'

import PuiIconButton from '~/components/common/PuiIconButton'
import {
  closeNotificationsHistoryPopup,
  openNotificationsHistoryPopup,
} from '~/store/actions/notifications'
import {
  getNotificationsHistoryButtonTotalCount,
  getNotificationsIsHistoryTablePopupOpen,
} from '~/store/reducers/notifications'

import NotificationCountBadge from '../NotificationCountBadge'
import NotificationHistoryTable from './NotificationHistoryTable'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    activeBackground: {
      backgroundColor: theme.colors.important,
      '&:hover': {
        backgroundColor: theme.colors.important,
        opacity: 0.9,
      },
    },
    activeColor: {
      color: theme.colors.badgeColor,
    },
    paper: {
      width: 830,
      maxWidth: 830,
      boxShadow: theme.constants.blockShadow,
    },
    tableContainer: {
      height: 422,
    },
    historyButtonContainer: {
      position: 'relative',
    },
  }),
  { name: 'NotificationsHistoryButton' },
)

export interface NotificationsHistoryButtonProps {
  classes?: ClassesType<typeof useStyles>
}

const NotificationsHistoryButton = ({
  classes: classesProp,
}: NotificationsHistoryButtonProps) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const historyTableIsOpen = useSelector(
    getNotificationsIsHistoryTablePopupOpen,
  )
  const notificationTotalCount = useSelector(
    getNotificationsHistoryButtonTotalCount,
  )

  const buttonRef = useRef<HTMLButtonElement>(null)

  const setHistoryTableIsOpen = (isOpen: boolean) => {
    if (isOpen) {
      dispatch(openNotificationsHistoryPopup())
    } else {
      dispatch(closeNotificationsHistoryPopup())
    }
  }

  const openNotificationHistoryTable = () => {
    setHistoryTableIsOpen(!historyTableIsOpen)
  }

  const handleClose = () => {
    setHistoryTableIsOpen(false)
  }

  return (
    <>
      <Grid className={classes.historyButtonContainer}>
        <PuiIconButton
          Icon={NotificationsIcon}
          classes={{
            iconButtonSvg: classNames({
              [classes.activeColor]: historyTableIsOpen,
            }),
            iconButton: classNames({
              [classes.activeBackground]: historyTableIsOpen,
            }),
          }}
          ref={buttonRef}
          onClick={() => openNotificationHistoryTable()}
        />
        {!historyTableIsOpen && (
          <NotificationCountBadge notificationCount={notificationTotalCount} />
        )}
      </Grid>
      <PuiPopper
        hideCloseButton
        anchorEl={buttonRef.current}
        classes={{
          paper: classes.paper,
        }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [8, 8],
            },
          },
          {
            name: 'flip',
            enabled: false,
          },
          {
            name: 'preventOverflow',
            enabled: true,
          },
        ]}
        open={historyTableIsOpen}
        placement="bottom"
        onClose={handleClose}
      >
        <Grid container className={classes.tableContainer}>
          <NotificationHistoryTable onClose={handleClose} />
        </Grid>
      </PuiPopper>
    </>
  )
}

export default NotificationsHistoryButton
