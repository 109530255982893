import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme, Text, TextProps } from '@pbt/pbt-ui-components'

import { ColorVariant } from '~/constants/colors'

export interface AppointmentStatusLabelProps extends Partial<TextProps> {
  colorVariant: ColorVariant
  name: string
}

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    statusSelect: {
      padding: theme.spacing(0, 1),
      fontWeight: 500,
      borderRadius: 3,
      color: theme.colors.lowAccentText,
    },
    colorNormal: {
      color: theme.colors.title,
      backgroundColor: theme.colors.newLabel,
      '&&:focus': {
        backgroundColor: theme.colors.newLabel,
      },
    },
    colorImportant: {
      color: theme.colors.important,
      backgroundColor: theme.colors.actionNeededBackground,
      '&&:focus': {
        backgroundColor: theme.colors.actionNeededBackground,
      },
    },
    colorSuccess: {
      color: theme.colors.success,
      backgroundColor: theme.colors.successBackground,
      '&&:focus': {
        backgroundColor: theme.colors.successBackground,
      },
    },
    colorError: {
      color: theme.colors.profileText,
      backgroundColor: theme.colors.errorColor,
      '&&:focus': {
        backgroundColor: theme.colors.errorColor,
      },
    },
    colorDisabled: {
      color: theme.colors.disabledLabelText,
      backgroundColor: theme.colors.disabledLabel,
      '&:focus': {
        backgroundColor: theme.colors.disabledLabel,
      },
    },
  }),
  { name: 'AppointmentStatusLabel' },
)

export const useColorClassname = (colorVariant: ColorVariant) => {
  const classes = useStyles()

  return classNames({
    [classes.colorNormal]: colorVariant === ColorVariant.NORMAL,
    [classes.colorImportant]: colorVariant === ColorVariant.IMPORTANT,
    [classes.colorDisabled]: colorVariant === ColorVariant.DISABLED,
    [classes.colorSuccess]: colorVariant === ColorVariant.SUCCESS,
    [classes.colorError]: colorVariant === ColorVariant.ERROR,
  })
}

const AppointmentStatusLabel = ({
  name,
  colorVariant,
  ...rest
}: AppointmentStatusLabelProps) => {
  const classes = useStyles()

  const colorClass = classNames(
    classes.statusSelect,
    useColorClassname(colorVariant),
  )

  return (
    <Grid container alignItems="end">
      <Grid item lineHeight={0.375} ml="3px">
        <Text className={colorClass} variant="body2" {...rest}>
          {name}
        </Text>
      </Grid>
    </Grid>
  )
}

export default AppointmentStatusLabel
