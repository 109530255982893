import React from 'react'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, Field, PuiTheme } from '@pbt/pbt-ui-components'

import { Price, Variation } from '~/types'

import ChargedPriceRow from './ChargedPriceRow'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    label: {
      fontSize: '1.6rem',
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'ChargedPriceRadioGroup' },
)

export interface ChargedPriceRadioGroupProps {
  classes?: ClassesType<typeof useStyles>
  disabled?: boolean
  isLoading?: boolean
  onPriceIdChange: (value: string) => void
  priceId: string
  prices: Price[]
  quantityField: Field
  variation: Variation
}

const ChargedPriceRadioGroup = ({
  disabled,
  priceId,
  prices,
  quantityField,
  variation,
  onPriceIdChange,
  classes: classesProp,
  isLoading,
}: ChargedPriceRadioGroupProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <RadioGroup
      row
      value={priceId || ''}
      onChange={(_, value) => onPriceIdChange(value)}
    >
      <Grid container direction="column">
        {prices.map((price) => {
          const isChosen = price.id === priceId
          return (
            <FormControlLabel
              classes={{ label: classes.label }}
              control={<Radio disableRipple color="secondary" />}
              disabled={disabled}
              key={price.id}
              label={
                <ChargedPriceRow
                  disabled={disabled}
                  field={isChosen ? quantityField : undefined}
                  isChosen={isChosen}
                  isLoading={isLoading}
                  price={price}
                  variation={variation}
                />
              }
              value={price.id}
            />
          )
        })}
      </Grid>
    </RadioGroup>
  )
}

export default ChargedPriceRadioGroup
