import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  LanguageUtils,
  PuiTheme,
  StateLabel,
  StateLabelProps,
  Utils,
} from '@pbt/pbt-ui-components'

import { ColorNames } from '~/constants/colors'
import { LabTestState } from '~/constants/SOAPStates'
import { getDashboardLabTestStates } from '~/store/reducers/constants'
import { LabTestDashboardTest } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    stateLabel: {
      width: 'fit-content',
      textAlign: 'center',
      margin: theme.spacing(1, 1, 1, 0),
    },
  }),
  { name: 'LabTestsStatusLabel' },
)

export interface LabTestsStatusLabelProps extends StateLabelProps {
  tests: LabTestDashboardTest[]
}

const LabTestsStatusLabel = ({ tests, ...rest }: LabTestsStatusLabelProps) => {
  const classes = useStyles()
  const DashboardLabTestStates = useSelector(getDashboardLabTestStates)

  return (
    <Grid container alignItems="center" direction="column">
      {(tests || []).map((labTest) => {
        const testState =
          Utils.findByProp(
            labTest.statusId,
            DashboardLabTestStates,
            'labTestStateId',
          ) || {}

        return (
          <StateLabel
            className={classes.stateLabel}
            completed={testState.name === LabTestState.COMPLETED}
            deleted={testState.color === ColorNames.RED}
            disabled={testState.color === ColorNames.GREY}
            key={labTest.id}
            success={
              testState.color === ColorNames.GREEN &&
              testState.name !== LabTestState.COMPLETED
            }
            variant="small"
            warning={testState.color === ColorNames.YELLOW}
            {...rest}
          >
            {LanguageUtils.getTranslatedFieldName(testState)}
          </StateLabel>
        )
      })}
    </Grid>
  )
}

export default LabTestsStatusLabel
