import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  Field,
  Nil,
  PuiTextArea,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import ToInput, {
  ToInputHandle,
} from '~/components/dashboard/communications/common/ToInput'
import CommunicationTemplateInput from '~/components/dashboard/template-inputs/CommunicationTemplateInput'
import { WrappedBaseTemplateInputProps } from '~/components/dashboard/template-inputs/WrappedBaseTemplateInput'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { EmailEntityConfigRecipient } from '~/types'

interface UseStylesProps {
  classes?: ClassesType<typeof useStyles>
}

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    form: {},
    textArea: {
      fontSize: '1.6rem',
    },
    plusButtonIconContainer: {
      margin: theme.spacing(1),
    },
  }),
  { name: 'EmailForm' },
)

export type EmailFormHandle = {
  formRecipients: () => EmailEntityConfigRecipient[]
}

interface EmailFormProps {
  CommunicationTemplateInputProps?: Omit<
    WrappedBaseTemplateInputProps,
    'filter'
  >
  clientId: string | Nil
  eventId?: string
  message: Field
  patientId?: string | Nil
  soapId?: string | Nil
  subject: Field
  to: Field
}

const EmailForm = forwardRef<EmailFormHandle, EmailFormProps>(
  (
    {
      to,
      subject,
      message,
      eventId,
      soapId,
      clientId: clientIdProp,
      patientId: patientIdProp,
      CommunicationTemplateInputProps,
    },
    ref,
  ) => {
    const useStylesProps: UseStylesProps = {}
    const classes = useStyles(useStylesProps)
    const toInputRef = useRef<ToInputHandle>(null)
    const { t } = useTranslation('Common')

    const appointment = useSelector(getTimetableEvent(eventId))
    const clientId = clientIdProp || (appointment?.client as string)
    const patientId = patientIdProp || (appointment?.patient as string)

    useImperativeHandle(ref, () => ({
      formRecipients: () => toInputRef.current?.formRecipients() || [],
    }))

    return (
      <Grid
        container
        className={classes.form}
        direction="column"
        rowSpacing={2}
      >
        <Grid item>
          <ToInput
            clientId={clientId}
            patientId={patientId}
            ref={toInputRef}
            to={to}
            transport={ConversationTransport.Email}
          />
        </Grid>
        <Grid item>
          <PuiTextArea
            classes={{
              input: classes.textArea,
            }}
            field={subject}
            label={t('Common:EMAIL_FORM.SUBJECT')}
            minRows={1}
          />
        </Grid>
        <Grid item>
          <CommunicationTemplateInput
            clientId={clientId}
            eventId={eventId}
            field={message}
            id="email-form-input"
            maxHeight={480}
            minHeight={240}
            patientId={patientId}
            soapId={soapId}
            title={t('Common:EMAIL_FORM.MESSAGE')}
            onSubjectChangeRequested={subject.setValue}
            {...CommunicationTemplateInputProps}
          />
        </Grid>
      </Grid>
    )
  },
)

export default EmailForm
