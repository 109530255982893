import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Defaults, Nil, PuiTheme } from '@pbt/pbt-ui-components'

import DetailsBackButton from '~/components/dashboard/clients/DetailsBackButton'
import ClientsTable from '~/components/dashboard/clients/table/ClientsTable'
import { GROUP_SEARCH_REQUIRE } from '~/components/dashboard/header/Search'
import FeatureToggle from '~/constants/featureToggle'
import {
  fetchClientsList,
  fetchMoreItemsForClientsList,
} from '~/store/actions/clients'
import {
  getClientIsFetchingList,
  getClientsList,
  getTotalClientCount,
} from '~/store/reducers/clients'
import { getFeatureToggle } from '~/store/reducers/constants'
import { useGetGroupSharingEnabled } from '~/utils/groupSharingUtils'

import SearchFilterValueButton from '../buttons/SearchFilterValueButton'
import ListSearchFilterPanel from '../lists/ListSearchFilterPanel'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    container: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 12,
        paddingRight: 12,
      },
      height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${
        theme.constants.tableHeaderHeight
      }px
      - ${theme.spacing(1)} - ${theme.constants.progressBarHeight}px)`,
      overflow: 'hidden',
    },
    backButtonContainer: {
      paddingRight: '0 !important',
    },
    backButtonText: {
      fontWeight: 500,
    },
  }),
  { name: 'PatientSearchResult' },
)

export interface PatientSearchResultProps {
  actionButtons: any[]
  additionalParams: [string, string][] | Nil
  fieldsQuery: string | Nil
  hideFlags?: boolean
  onItemClick: (clientId: string, patientId: string | Nil) => void
  onSearchCancelled?: () => void
  searchTerm: string
  showLastAppointment?: boolean
}

const PatientSearchResult = ({
  actionButtons,
  fieldsQuery = '',
  additionalParams,
  hideFlags = true,
  onItemClick,
  onSearchCancelled,
  searchTerm,
  showLastAppointment,
}: PatientSearchResultProps) => {
  const { t } = useTranslation('Common')
  const classes = useStyles()
  const dispatch = useDispatch()
  const list = useSelector(getClientsList)
  const totalCount = useSelector(getTotalClientCount)
  const clientIsFetchingList = useSelector(getClientIsFetchingList)

  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isGroupClientSharingFeatureEnabled = useSelector(
    getFeatureToggle(FeatureToggle.GROUP_CLIENT_SHARING),
  )
  const groupSharingEnabled = useGetGroupSharingEnabled()

  const groupSearchRequire = Boolean(
    additionalParams?.some(
      (item) => item[0] === GROUP_SEARCH_REQUIRE && item[1] === 'true',
    ),
  )

  useEffect(() => {
    dispatch(
      fetchClientsList({
        from: 0,
        to: Defaults.CLIENTS_BATCH_LOAD_COUNT,
        query: searchTerm,
        fieldsQuery,
        showLastAppointment,
        groupSearchRequire,
      }),
    )
  }, [searchTerm])

  const isItemLoaded = useCallback(
    (index: number) => Boolean(list[index]),
    [list],
  )

  const loadMoreItems = (startIndex: number, stopIndex: number) => {
    if (startIndex === 0) {
      return
    }
    dispatch(
      fetchMoreItemsForClientsList({
        from: startIndex,
        to: stopIndex,
        query: searchTerm,
        fieldsQuery,
        showLastAppointment,
        groupSearchRequire,
      }),
    )
  }

  return (
    <Grid container className={classes.container} direction="column">
      <Grid container item alignItems="center" justifyContent="space-between">
        <Grid container item xs alignItems="center">
          <Grid item className={classes.backButtonContainer}>
            {onSearchCancelled && (
              <DetailsBackButton
                fullWidth
                classes={{
                  text: classes.backButtonText,
                }}
                onClick={onSearchCancelled}
              >
                {t('Common:BACK_TO_SEARCH')}
              </DetailsBackButton>
            )}
          </Grid>
          {!fieldsQuery && (
            <Grid item ml={6}>
              <SearchFilterValueButton
                hideClearButton
                isLoading={clientIsFetchingList}
                searchCount={totalCount}
                searchTerm={searchTerm}
              />
            </Grid>
          )}
          <Grid item>
            <Grid container>
              <ListSearchFilterPanel
                hideClearButton
                searchCount={totalCount}
                searchFieldsQuery={fieldsQuery}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ClientsTable
        hideMembershipColumn
        hideScheduleColumn
        actionButtons={actionButtons}
        hideActionsColumn={false}
        hideFlagsColumn={hideFlags}
        hideLastAppointmentColumn={!showLastAppointment}
        hideSharedColumn={
          !(isGroupClientSharingFeatureEnabled && groupSharingEnabled) ||
          isPatientSharingEnabled
        }
        highlightProps={{
          client: ['name'],
          petFriend: ['name'],
          patient: ['name'],
        }}
        isItemLoaded={isItemLoaded}
        list={list}
        loadMoreItems={loadMoreItems}
        totalCount={totalCount}
        onItemClick={onItemClick}
      />
    </Grid>
  )
}

export default PatientSearchResult
