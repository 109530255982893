import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { NumberUtils, PuiTheme, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    notesSection: {
      background: theme.colors.tableLeftColumnBackground,
    },
  }),
  { name: 'CancelMembershipPayment' },
)

interface CancelMembershipPaymentProps {
  additionalLabels: {
    name: string
    value: React.ReactNode
  }[]
  isRefund?: boolean
  paymentAmount?: number
}

const CancelMembershipPayment = function CancelMembershipPayment({
  isRefund,
  paymentAmount = 0,
  additionalLabels,
}: CancelMembershipPaymentProps) {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Payments'])

  return (
    <Grid container item>
      <Grid container item direction="column" p={3} pt={2} xs={6}>
        <Grid container item alignItems="center" mb={2} wrap="nowrap">
          <Text mr={1} variant="body">
            {isRefund
              ? t('Common:PAYMENTS.REFUND_AMOUNT')
              : t('Common:CHARGE_ACTION')}
            :
          </Text>
          <Text variant="body">{NumberUtils.formatMoney(paymentAmount)}</Text>
        </Grid>
        {additionalLabels &&
          additionalLabels.map((item) => (
            <Text key={item.name} mb={2} variant="body">
              {item.name}: {item.value}
            </Text>
          ))}
      </Grid>
      <Grid
        container
        item
        xs
        className={classes.notesSection}
        direction="column"
        p={3}
      >
        <Text strong variant="body2">
          {t('Payments:PAYMENT_DOES_NOT_AFFECT_BALANCE')}
        </Text>
        <Text strong mt={1.5} variant="body2">
          {t('Payments:IMMEDIATE_CANCELLATION')}
        </Text>
      </Grid>
    </Grid>
  )
}

export default CancelMembershipPayment
