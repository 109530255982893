import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  CountryCode,
  PuiTheme,
  Utils,
} from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '~/store/reducers/auth'
import { getRegistrationBusinesses } from '~/store/reducers/registration'

import TermsAndConditionsContentSpain from './TermsAndConditionsSpain'
import { TermsAndConditionsContentUSAEulaV3 } from './TermsAndConditionsUSAEulaV3'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    sections: {},
    title: {
      lineHeight: '3.1rem',
      [theme.breakpoints.down('lg')]: {
        textAlign: 'center',
      },
    },
    subtitle: {
      color: theme.colors.title,
      lineHeight: '2.2rem',
      paddingTop: 30,
    },
    subtitleSecondary: {
      color: theme.colors.title,
      lineHeight: '2.2rem',
      paddingTop: theme.spacing(1),
    },
    firstSubtitle: {
      paddingTop: 20,
    },
    text: {
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
      textAlign: 'justify',
      paddingTop: theme.spacing(0.5),
    },
    firstText: {
      fontSize: '2rem',
    },
    firstSection: {
      width: '100%',
    },
  }),
  { name: 'TermsAndConditionsContent' },
)

const TermsAndConditionsEulaMapV3: Record<
  CountryCode,
  React.JSXElementConstructor<any>
> = {
  [CountryCode.ES]: TermsAndConditionsContentSpain,
  [CountryCode.US]: TermsAndConditionsContentUSAEulaV3,
  [CountryCode.BR]: TermsAndConditionsContentUSAEulaV3,
  [CountryCode.CA]: TermsAndConditionsContentUSAEulaV3,
  [CountryCode.PT]: TermsAndConditionsContentUSAEulaV3,
}

export interface TermsAndConditionsContentProps {
  classes?: ClassesType<typeof useStyles>
  showTitle?: boolean
}

const TermsAndConditionsContent = ({
  classes: classesProp,
  showTitle,
}: TermsAndConditionsContentProps) => {
  const classes = useStyles({ classes: classesProp })
  const business = useSelector(getCurrentBusiness)
  const registrationBusiness = Object.values(
    useSelector(getRegistrationBusinesses),
  )
  const registrationCountry = registrationBusiness.length
    ? registrationBusiness[0].countryCatalogCode
    : null

  const countryCode =
    business?.countryCatalogCode ||
    registrationCountry ||
    (Utils.isEuropeRegion() ? CountryCode.ES : CountryCode.US)
  const TermsAndConditionsContentComponent =
    TermsAndConditionsEulaMapV3[countryCode]

  return (
    <TermsAndConditionsContentComponent
      classes={classes}
      showTitle={showTitle}
    />
  )
}

export default TermsAndConditionsContent
