import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ClickAwayListener, Collapse, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  BaseRole,
  ButtonWithLoader,
  LanguageUtils,
  Nil,
  PuiCheckbox,
  PuiDialog,
  PuiTextField,
  PuiTheme,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { Toggle } from '@pbt/pbt-ui-components/src/icons'

import BusinessSelect from '~/components/common/BusinessSelect'
import FeatureToggle from '~/constants/featureToggle'
import RoleName from '~/constants/roleNames'
import i18n from '~/locales/i18n'
import { getAllowedRolesByBusinessMap } from '~/store/reducers/auth'
import { getBusiness } from '~/store/reducers/businesses'
import { getFeatureToggleForBusiness } from '~/store/reducers/constants'
import { getAllStaffRolesList, getStaffRolesList } from '~/store/reducers/roles'
import { getUser } from '~/store/reducers/users'
import { arrayToMap, isAnalyticsRole } from '~/utils'
import { isCurrentBusinessRole, isPracticeAdminRole } from '~/utils/roleUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
    actions: {
      paddingLeft: theme.spacing(2),
    },
    button: {
      width: 160,
    },
    dialogContentRoot: {
      padding: theme.spacing(0, 2, 2),
    },
    practiceSelect: {
      marginBottom: theme.spacing(2),
      cursor: 'pointer',
    },
    checkbox: {
      padding: theme.spacing(1),
    },
    checkboxLabel: {
      margin: 0,
    },
    heading: {
      margin: theme.spacing(0.5, 0, 1, 1),
    },
    collapse: {
      boxShadow: '3px 3px 20px rgba(168,163,163,0.5)',
      position: 'absolute',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      width: `calc(100% - ${theme.spacing(4)})`,
      top: 116,
    },
    practiceSelectInput: {
      cursor: 'pointer',
    },
  }),
  { name: 'RolesDialog' },
)

const RoleTooltipMap: Partial<Record<RoleName, string>> = {
  [RoleName.Boarder]: i18n.t('Admin:MEMBER.ROLE.TOOLTIP.BOARDER'),
  [RoleName.Groomer]: i18n.t('Admin:MEMBER.ROLE.TOOLTIP.GROOMER'),
  [RoleName.InventoryManagement]: i18n.t(
    'Admin:MEMBER.ROLE.TOOLTIP.INVENTORY_MANAGEMENT',
  ),
  [RoleName.Marketing]: i18n.t('Admin:MEMBER.ROLE.TOOLTIP.MARKETING'),
  [RoleName.OfficeStaff]: i18n.t('Admin:MEMBER.ROLE.TOOLTIP.OFFICE_STAFF'),
  [RoleName.PracticeAdministrator]: i18n.t(
    'Admin:MEMBER.ROLE.TOOLTIP.PRACTICE_ADMINISTRATOR',
  ),
  [RoleName.VetAssistant]: i18n.t('Admin:MEMBER.ROLE.TOOLTIP.VET_ASSISTANT'),
  [RoleName.VetTech]: i18n.t('Admin:MEMBER.ROLE.TOOLTIP.VET_TECH'),
  [RoleName.Veterinarian]: i18n.t('Admin:MEMBER.ROLE.TOOLTIP.VETERINARIAN'),
  [RoleName.Walker]: i18n.t('Admin:MEMBER.ROLE.TOOLTIP.WALKER'),
}

interface RolesDialogProps extends BasePuiDialogProps {
  businessId: string | Nil
  ignoreRolesInSearch?: boolean
  includeHiddenRoles?: boolean
  isGroup?: boolean
  isNewBusiness?: boolean
  isRhapsodyAnalytics?: boolean
  onSave: (
    businessId: string,
    selectedRoles: string[],
    isGroup?: boolean,
  ) => void
  onlyAlowedRoles?: boolean
  saveLabel?: string
  selectedRoleList: { businessId: string; roleId: string }[]
  title?: string
  userId?: string
}

const RolesDialog = ({
  ignoreRolesInSearch,
  isNewBusiness = false,
  includeHiddenRoles = false,
  isGroup = false,
  onlyAlowedRoles = false,
  isRhapsodyAnalytics = false,
  open,
  userId,
  businessId: initialBusinessId,
  selectedRoleList,
  onSave,
  onClose,
  saveLabel,
  title: titleProp,
}: RolesDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Admin', 'Common'])

  const roles = useSelector(
    includeHiddenRoles ? getAllStaffRolesList : getStaffRolesList,
  )
  const user = useSelector(getUser(userId))
  const initialBusiness = useSelector(getBusiness(initialBusinessId))
  const allowedRolesByBusinessMap = useSelector(getAllowedRolesByBusinessMap)
  const [currentBusinessId, setCurrentBusinessId] = useState(initialBusinessId)

  const [businessSelectExpanded, setBusinessSelectExpanded] = useState(false)

  const businessRolesMap = R.groupBy(R.prop('businessId'), selectedRoleList)

  const defaultTitle = titleProp || t('Common:ADD_ROLES')

  const editPersonTitle = t('Admin:MEMBER.ROLE.ROLES_AT_BUSINESS', {
    person: Utils.getPersonString(user),
    business: initialBusiness?.name,
  })

  const createPersonTitle = isGroup
    ? t('Admin:MEMBER.ROLE.ROLES_AT_NEW_GROUP', {
        person: Utils.getPersonString(user),
      })
    : t('Admin:MEMBER.ROLE.ROLES_AT_NEW_PRACTICE', {
        person: Utils.getPersonString(user),
      })

  const personTitle = isNewBusiness ? createPersonTitle : editPersonTitle
  const title = user ? personTitle : defaultTitle

  const {
    fields: { businessId },
    validate,
  } = useFields(
    [
      {
        name: 'businessId',
        validators: isNewBusiness ? ['required'] : undefined,
        messages: {
          required: t('Admin:MEMBER.ROLE.PRACTICE_FIELD_REQUIRED'),
        },
        type: 'select',
        initialValue: initialBusinessId,
      },
    ],
    false,
  )

  const businessSelectValue = useSelector(getBusiness(businessId?.value))
  const isCvcRolesEnabled = useSelector(
    getFeatureToggleForBusiness(FeatureToggle.CVC_ROLES, businessId?.value),
  )
  const roleCategoryFilter = isCurrentBusinessRole(
    businessSelectValue?.omniChannel && isCvcRolesEnabled,
  )

  const [analyticsRoles, pimsRoles] = R.partition(
    R.propEq('rhapsodyAnalytics', true),
    roles.filter(roleCategoryFilter),
  )

  const allowedRolesByBusiness =
    (currentBusinessId && allowedRolesByBusinessMap[currentBusinessId]) || []

  const allowedAnalyticsRoles = onlyAlowedRoles
    ? allowedRolesByBusiness.filter(isAnalyticsRole)
    : analyticsRoles

  const getSelectedRolesMapByBusinessId = (id: string) => {
    const selectedRoleIds = R.pluck('roleId', businessRolesMap[id] || [])
    return arrayToMap(selectedRoleIds, R.identity, R.T)
  }

  const [selectedRolesMap, setSelectedRolesMap] = useState(
    getSelectedRolesMapByBusinessId(businessId?.value),
  )

  const toggleSelectedRole = (roleId: string) => {
    setSelectedRolesMap({
      ...selectedRolesMap,
      [roleId]: !selectedRolesMap[roleId],
    })
  }

  const handleSave = () => {
    if (validate()) {
      const selectedRoles = R.pipe(
        R.toPairs,
        R.filter(([, value]) => value),
        R.map(([key]) => key),
      )(selectedRolesMap)

      onSave(businessId.value, selectedRoles, isGroup)
      if (onClose) {
        onClose()
      }
    }
  }

  const renderCheckbox = (role: BaseRole) => (
    <Grid item key={role.id}>
      <PuiCheckbox
        checkboxClasses={{
          root: classes.checkbox,
        }}
        classes={{
          labelRoot: classes.checkboxLabel,
        }}
        field={{
          value: selectedRolesMap[role.id] || false,
          set: () => toggleSelectedRole(role.id),
        }}
        label={
          <TextWithTooltip
            tooltipText={RoleTooltipMap[role.name] || ''}
            variant="body"
          >
            {isGroup && isPracticeAdminRole(role)
              ? t('Common:GROUP_ADMINISTATOR')
              : LanguageUtils.getTranslatedFieldName(role)}
          </TextWithTooltip>
        }
      />
    </Grid>
  )

  const handleBusinessSelect = (newBusinessId: string) => {
    setSelectedRolesMap(getSelectedRolesMapByBusinessId(newBusinessId))
    if (businessId.setValue) {
      businessId.setValue(newBusinessId)
    }
    setBusinessSelectExpanded(false)
    setCurrentBusinessId(newBusinessId)
  }

  const handleExpandBusinessSelect = () => {
    setBusinessSelectExpanded(!businessSelectExpanded)
  }

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader className={classes.button} onClick={handleSave}>
          {saveLabel || t('Common:ADD_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="roles-dialog"
      classes={{
        paper: classes.paper,
        actions: classes.actions,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={title}
      onClose={onClose}
    >
      <Grid container>
        {isNewBusiness && (
          <Grid container direction="column">
            <ClickAwayListener
              mouseEvent="onMouseUp"
              onClickAway={() => setBusinessSelectExpanded(false)}
            >
              <Grid container>
                <PuiTextField
                  InputProps={{
                    endAdornment: <Toggle />,
                    readOnly: true,
                  }}
                  className={classes.practiceSelect}
                  field={{ ...businessId, value: businessSelectValue?.name }}
                  label={isGroup ? t('Common:GROUP') : t('Common:PRACTICE')}
                  onClick={handleExpandBusinessSelect}
                />
                <Collapse
                  mountOnEnter
                  className={classes.collapse}
                  in={businessSelectExpanded}
                >
                  <BusinessSelect
                    showCurrentBusiness
                    ignoreRolesInSearch={ignoreRolesInSearch}
                    isGroup={isGroup}
                    onBusinessSelect={handleBusinessSelect}
                  />
                </Collapse>
              </Grid>
            </ClickAwayListener>
          </Grid>
        )}
        <Grid container direction="row">
          {!isRhapsodyAnalytics && (
            <Grid container item direction="column" xs={6}>
              <Text strong className={classes.heading} variant="subheading3">
                {isGroup ? 'Group PIMs' : 'PIMs'}
              </Text>
              {pimsRoles?.map(renderCheckbox)}
            </Grid>
          )}
          <Grid container item direction="column" xs={6}>
            <Text strong className={classes.heading} variant="subheading3">
              {isGroup ? 'Group Analytics' : 'Analytics'}
            </Text>
            {allowedAnalyticsRoles?.map(renderCheckbox)}
          </Grid>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default RolesDialog
