import React, { forwardRef, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  Badge,
  Grid,
  Skeleton,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ClassesType,
  ImageScalingUtils,
  Nil,
  PuiTheme,
  Text,
  User,
  Utils,
} from '@pbt/pbt-ui-components'
import {
  DefaultAvatar,
  DefaultTeamAvatar,
  Edit as EditIcon,
} from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    defaultAvatar: {},
    shadow: {
      boxShadow: `0 14px 26px -12px rgba(0, 0, 0, 0.22),
                0 4px 23px 0px rgba(0, 0, 0, 0.07),
                0 8px 10px -5px rgba(0, 0, 0, 0.1)`,
    },
    imageBackground: {
      backgroundColor: '#FFFFFF',
    },
    badge: {
      zIndex: 'auto',
      top: '18%',
      right: 8,
      color: theme.colors.badgeColor,
    },
    childrenBackgroundColor: {
      backgroundColor: theme.colors.defaultAvatarBackground,
    },
    avatarColor: {
      backgroundColor: theme.colors.primaryText,
    },
    teamAvatarColor: {
      backgroundColor: theme.colors.selectedOption,
    },
    grid: {
      width: '100%',
      height: '100%',
    },
    text: {
      color: theme.colors.profileText,
    },
    smallText: {
      fontSize: '1.6rem',
      fontWeight: 500,
    },
    normalText: {
      fontSize: '4.4rem',
    },
    editIconCircle: {
      width: 33,
      height: 33,
      cursor: 'pointer',
      backgroundColor: theme.colors.markerHighlighted,
      borderRadius: 16,
    },
    editIcon: {
      fontSize: '2.2rem',
    },
  }),
  { name: 'Avatar' },
)

const getFirstLetter = (str: string | Nil) => (str ? str.charAt(0) : '')

const getAvatarPlaceholder = ({ firstName, lastName }: Partial<User> = {}) =>
  `${getFirstLetter(firstName)}${getFirstLetter(lastName)}`.toUpperCase()

interface AvatarProps extends Omit<MuiAvatarProps, 'src'> {
  children?: React.ReactElement | null
  className?: string
  classes?: ClassesType<typeof useStyles>
  defaultAvatarClass?: string
  height?: number
  isLoading?: boolean
  onEditClick?: () => void
  person?: Partial<User>
  plain?: boolean
  size?: string
  src?: string | Nil
  width?: number
}

const DEFAULT_SERVING_URL_AVATAR_SIZE = 100

const Avatar = forwardRef<HTMLSpanElement, AvatarProps>(function Avatar(
  {
    classes: classesProp,
    person = {},
    src: srcProp,
    size = 'small',
    children,
    onEditClick,
    plain = false,
    defaultAvatarClass,
    isLoading,
    width = 40,
    height = 40,
    ...props
  },
  ref,
) {
  const avatarRef = useRef<HTMLDivElement>(null)
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')
  const [servingUrlAvatarSize, setServingUrlAvatarSize] = useState(
    DEFAULT_SERVING_URL_AVATAR_SIZE,
  )

  useLayoutEffect(() => {
    const offsetWidth = avatarRef.current?.offsetWidth
    const offsetHeight = avatarRef.current?.offsetHeight
    if (offsetWidth && offsetHeight) {
      setServingUrlAvatarSize(Math.max(offsetWidth, offsetHeight))
    }
  }, [avatarRef.current])

  if (isLoading) {
    return <Skeleton height={height} variant="circular" width={width} />
  }

  const personPhoto = person.avatar || person.photo
  const personPhotoThumbnail = person.avatarThumbnail || person.photoThumbnail
  const scaledPhotoUrl = ImageScalingUtils.getScaledImageOrOriginal(
    personPhoto,
    personPhotoThumbnail,
    servingUrlAvatarSize || DEFAULT_SERVING_URL_AVATAR_SIZE,
  )
  const src = srcProp || scaledPhotoUrl

  const personDefaultAvatar = person.firstName ? (
    <Text
      align="center"
      className={classNames(
        classes.text,
        size === 'small' ? classes.smallText : classes.normalText,
      )}
    >
      {getAvatarPlaceholder(person)}
    </Text>
  ) : (
    <DefaultAvatar
      className={classNames(classes.defaultAvatar, defaultAvatarClass)}
    />
  )

  const teamDefaultAvatar = (
    <DefaultTeamAvatar
      className={classNames(classes.defaultAvatar, defaultAvatarClass)}
    />
  )

  const defaultAvatar = person.team ? teamDefaultAvatar : personDefaultAvatar

  return (
    <Badge
      badgeContent={
        Boolean(onEditClick) && (
          <Grid
            container
            alignItems="center"
            className={classes.editIconCircle}
            justifyContent="center"
            onClick={onEditClick}
          >
            <EditIcon className={classes.editIcon} />
          </Grid>
        )
      }
      classes={{ badge: classes.badge }}
      ref={ref}
    >
      <MuiAvatar
        alt={t('Common:PERSON_PROFILE_PICTURE', {
          person: Utils.getPersonString(person),
        })}
        classes={{
          root: classNames({
            [classes.shadow]: plain,
            [classes.imageBackground]: src,
          }),
          colorDefault: classNames(classes.avatarColor, {
            [classes.childrenBackgroundColor]: children || !person.firstName,
            [classes.teamAvatarColor]: person.team,
          }),
        }}
        ref={avatarRef}
        src={src}
        {...props}
      >
        {!src && (
          <Grid
            container
            item
            alignItems="center"
            className={classes.grid}
            justifyContent="center"
          >
            {children || defaultAvatar}
          </Grid>
        )}
      </MuiAvatar>
    </Badge>
  )
})

export default Avatar
