import React, { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SearchIcon from '@mui/icons-material/Search'
import {
  ClickAwayListener,
  Grid,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTextField, PuiTheme, Text, Utils } from '@pbt/pbt-ui-components'

import Avatar from '~/components/common/Avatar'
import LockLink from '~/components/common/link/LockLink'
import LogOutLink from '~/components/common/link/LogOutLink'
import PuiLanguagesSelector from '~/components/common/PuiLanguagesSelector'
import DialogNames from '~/constants/DialogNames'
import { getCurrentBusinessId, getCurrentUser } from '~/store/reducers/auth'
import { getJournalMap } from '~/store/reducers/journal'
import { filterUsers } from '~/utils'
import { useBusinessSupportedLanguages } from '~/utils/useBusinessSupportedLanguages'
import useDialog from '~/utils/useDialog'
import useLogout from '~/utils/useLogout'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    userAvatar: {
      width: 44,
      height: 44,
    },
    root: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: 450,
    },
    topContainer: {
      padding: theme.spacing(1, 0, 1, 2),
      backgroundColor: theme.colors.profileTopBackground,
    },
    userNameText: {
      height: 30,
      fontWeight: 600,
      color: theme.colors.profileText,
    },
    linkText: {
      marginTop: 3,
      color: theme.colors.profileText,
      textDecoration: 'none',
    },
    search: {
      padding: theme.spacing(0, 1, 1.5, 1),
    },
    item: {
      height: 60,
      marginLeft: 8,
      marginRight: 8,
      padding: 0,
      paddingLeft: 8,
      '&&:hover, &&:focus': {
        backgroundColor: `${theme.colors.listItemHover} !important`,
        color: `${theme.colors.listItemHoverText} !important`,
        boxShadow: theme.constants.selectItemSelectedShadow,
      },
    },
    itemText: {
      marginLeft: 10,
    },
  }),
  { name: 'ProfileMenu' },
)

export interface ProfileMenuProps {
  className?: string
  onClose: (e: any) => void
}

const ProfileMenu = forwardRef<HTMLDivElement, ProfileMenuProps>(
  function ProfileMenu({ className, onClose }, ref) {
    const classes = useStyles()
    const user = useSelector(getCurrentUser)
    const logout = useLogout()
    const businessId = useSelector(getCurrentBusinessId)
    const journal = Object.values(useSelector(getJournalMap)).filter(
      ({ email }) => Boolean(email),
    )
    const { t } = useTranslation(['Common', 'Search'])

    const [searchTerm, setSearchTerm] = useState('')
    const [profileDialogOpen, setProfileDialogOpen] = useState(false)

    const [openProfileDialog] = useDialog(DialogNames.PROFILE, () =>
      setProfileDialogOpen(false),
    )

    const hasPreviousLogins = filterUsers(journal, '', user, true).length >= 1
    const { isMultipleLanguagesSupported } =
      useBusinessSupportedLanguages(businessId)

    const onClickAway = (e: MouseEvent | TouchEvent) => {
      if (!profileDialogOpen) {
        onClose(e)
      }
    }

    return (
      <ClickAwayListener onClickAway={onClickAway}>
        <Paper
          aria-label="profile-menu"
          className={classNames(className, classes.root)}
          ref={ref}
        >
          <Grid container direction="column">
            <Grid
              container
              item
              className={classes.topContainer}
              direction="column"
            >
              <Grid item>
                {user && (
                  <Text className={classes.userNameText} variant="h3">
                    {Utils.getPersonString(user)}
                  </Text>
                )}
              </Grid>
              <Grid item>
                {user && (
                  <Text
                    inline
                    link
                    strong
                    className={classes.linkText}
                    mr={2}
                    onClick={(event) => {
                      onClose(event)
                      setProfileDialogOpen(true)
                      openProfileDialog()
                    }}
                  >
                    {t('Common:PROFILE')}
                  </Text>
                )}
                <LogOutLink strong className={classes.linkText} mr={2} />
                {!user.ssoUser && (
                  <LockLink strong className={classes.linkText} mr={2} />
                )}
                {isMultipleLanguagesSupported && businessId && (
                  <PuiLanguagesSelector businessId={businessId} />
                )}
              </Grid>
            </Grid>
            {!user.ssoUser && hasPreviousLogins && (
              <>
                <Grid
                  container
                  item
                  className={classes.search}
                  justifyContent="center"
                >
                  <PuiTextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="off"
                    id="profile-menu-search"
                    label={t('Search:BY_NAME_OR_EMAIL')}
                    margin="none"
                    value={searchTerm}
                    onChange={Utils.handleFormTextInput(setSearchTerm)}
                  />
                </Grid>
                <Grid container item direction="column">
                  <Paper square>
                    <MenuList
                      disableListWrap
                      style={{ maxHeight: 500, overflowY: 'auto' }}
                    >
                      {filterUsers(journal, searchTerm, user, true).map(
                        (filteredUser) => (
                          <MenuItem
                            className={classes.item}
                            key={filteredUser.id}
                            onClick={(e) => {
                              onClose(e)
                              logout(
                                '/auth/lock',
                                undefined,
                                filteredUser?.email,
                              )
                            }}
                          >
                            <Avatar
                              className={classes.userAvatar}
                              person={filteredUser}
                            />
                            <Text
                              noWrap
                              strong
                              className={classes.itemText}
                              variant="body2"
                            >
                              {Utils.getPersonString(filteredUser)}
                            </Text>
                          </MenuItem>
                        ),
                      )}
                    </MenuList>
                  </Paper>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </ClickAwayListener>
    )
  },
)

export default ProfileMenu
