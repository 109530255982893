/* eslint-disable react/no-multi-comp */
import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Calendar,
  CustomFieldValidatorState,
  Nil,
  PermissionArea,
  PuiAutocompleteSelect,
  PuiTextField,
  PuiTextFieldProps,
  PuiTheme,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'
import { CalendarProps } from '@pbt/pbt-ui-components/src/components/inputs/calendar/Calendar'

import EnumSelect from '~/components/common/inputs/EnumSelect'
import UserSelect, {
  UserSelectProps,
} from '~/components/common/inputs/UserSelect'
import ManualInputSelect, {
  ManualInputSelectProps,
} from '~/components/common/ManualInputSelect'
import { ShipmentStatus } from '~/constants/shipments'
import { getCRUDByArea, getCurrentUserId } from '~/store/reducers/auth'
import {
  getInventoryDistributors,
  getInventoryShipmentStatus,
} from '~/store/reducers/constants'
import { DataHandle, Shipment as ShipmentType } from '~/types'
import { arrayToMap } from '~/utils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    item: {
      marginRight: theme.spacing(3),
    },
    other: {
      marginRight: theme.spacing(3),
    },
    statusSelect: {
      marginLeft: theme.spacing(3),
    },
    heading: {
      margin: theme.spacing(0.5, 0),
    },
    headingText: {
      MarginRight: theme.spacing(2),
    },
  }),
  { name: 'Shipment' },
)

const OrderNumber = ({ field, ...rest }: PuiTextFieldProps) => (
  <PuiTextField
    field={field}
    inputProps={{ maxLength: 100 }}
    label={field?.label}
    {...rest}
  />
)

const OrderedDate = ({ field, ...rest }: CalendarProps) => (
  <Calendar fullWidth field={field} label={`${field?.label}*`} {...rest} />
)

const Distributor = ({ fields, options, ...rest }: ManualInputSelectProps) => (
  <ManualInputSelect
    UnitComponent={PuiAutocompleteSelect}
    fields={fields}
    label={`${fields.unitId.label}*`}
    options={options}
    {...rest}
  />
)

const ReceivedBy = ({ field, ...rest }: UserSelectProps) => (
  <UserSelect plainInput field={field} label={`${field?.label}*`} {...rest} />
)

interface ReceivedOnProps extends CalendarProps {
  required?: boolean
}

const ReceivedOn = ({ field, required, ...rest }: ReceivedOnProps) => (
  <Calendar
    fullWidth
    field={field}
    label={required ? `${field?.label}*` : field?.label}
    {...rest}
  />
)

const Notes = ({ field, ...rest }: PuiTextFieldProps) => (
  <PuiTextField field={field} label={field?.label} {...rest} />
)

export interface ShipmentHandle extends DataHandle<ShipmentType> {}

export interface ShipmentProps {
  onShipmentStatusChange?: (statusId: string) => void
  shipment: ShipmentType | Nil
  wide?: boolean
  withHeading?: boolean
}

const Shipment = forwardRef<ShipmentHandle, ShipmentProps>(function Shipment(
  { shipment, wide = false, withHeading = false, onShipmentStatusChange },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const permissions = useSelector(getCRUDByArea(PermissionArea.SHIPMENTS))
  const InventoryShipmentStatus = useSelector(getInventoryShipmentStatus)
  const InventoryDistributors = useSelector(getInventoryDistributors)
  const currentUserId = useSelector(getCurrentUserId)

  const InventoryShipmentStatusIds = arrayToMap(
    InventoryShipmentStatus,
    R.prop('name'),
    R.prop('id'),
  )
  const statusWithReceivedDate = [
    InventoryShipmentStatusIds[ShipmentStatus.RECEIVED],
    InventoryShipmentStatusIds[ShipmentStatus.PARTIAL],
  ]

  const validateReceivedDate = ({
    state: { statusId },
    value,
  }: CustomFieldValidatorState) =>
    !R.includes(statusId, statusWithReceivedDate) || Boolean(value)

  const {
    fields: {
      orderNumber,
      orderedDate,
      distributorId,
      distributorCustomName,
      receivedBy,
      receivedDate,
      notes,
      statusId,
    },
    validate,
    reset,
  } = useFields(
    [
      {
        name: 'orderNumber',
        label: t('Common:ORDER_NUMBER'),
        validators: [],
        initialValue: shipment?.orderNumber,
      },
      {
        name: 'orderedDate',
        label: t('Common:ORDER_DATE'),
        validators: ['required'],
        initialValue: shipment?.orderedDate,
      },
      {
        name: 'distributorId',
        label: t('Common:DISTRIBUTOR'),
        validators: ['required'],
        initialValue: shipment?.distributorId,
      },
      {
        name: 'distributorCustomName',
        label: t('Common:DISTRIBUTOR'),
        validators: [],
        initialValue: shipment?.distributorName,
      },
      {
        name: 'receivedBy',
        label: t('Common:RECEIVED_BY'),
        validators: ['required'],
        initialValue: shipment?.receivedById || currentUserId,
      },
      {
        name: 'receivedDate',
        label: t('Common:RECEIVED_ON'),
        validators: [
          { validator: validateReceivedDate, validatorName: 'required' },
        ],
        initialValue: shipment?.receivedDate,
      },
      {
        name: 'notes',
        label: t('Common:NOTES'),
        validators: [],
        initialValue: shipment?.notes,
      },
      {
        name: 'statusId',
        label: t('Common:STATUS'),
        type: 'select',
        validators: [],
        initialValue:
          shipment?.statusId ||
          InventoryShipmentStatusIds[ShipmentStatus.DRAFT],
      },
    ],
    false,
  )

  const getShipment = () =>
    ({
      ...R.omit(['items', 'receivedByName'], shipment),
      orderNumber: orderNumber.value,
      orderedDate: orderedDate.value,
      // the exception case if the back side doesn't get distributor
      distributorId: distributorId.value || distributorId.initialValue,
      distributorName: distributorCustomName.value,
      receivedById: receivedBy.value,
      receivedDate: receivedDate.value,
      notes: notes.value,
      statusId: statusId.value,
    } as ShipmentType)

  useEffect(() => {
    if (!shipment || !R.isEmpty(shipment)) {
      reset()
    }
  }, [shipment])

  useImperativeHandle(ref, () => ({
    validate,
    get: getShipment,
  }))

  return (
    <Grid container>
      <Stack
        alignItems="center"
        className={classes.heading}
        direction="row"
        spacing={2}
      >
        {withHeading && (
          <Text className={classes.headingText} variant="h2">
            {t('Common:ADD_SHIPMENT')}
          </Text>
        )}
        <EnumSelect
          accent
          Constant={InventoryShipmentStatus}
          field={{
            ...statusId,
            set: ({ target: { value } }) => {
              if (onShipmentStatusChange) {
                onShipmentStatusChange(value as string)
              }
              statusId.setValue(value)
            },
          }}
          label={withHeading ? undefined : statusId.label}
        />
      </Stack>
      {wide ? (
        <>
          <Grid container item alignItems="center" wrap="nowrap">
            <Grid item className={classes.item} xs={2}>
              <OrderNumber disabled={!permissions.update} field={orderNumber} />
            </Grid>
            <Grid item className={classes.item} xs={2}>
              <OrderedDate disabled={!permissions.update} field={orderedDate} />
            </Grid>
            <Grid container item wrap="nowrap" xs={8}>
              <Distributor
                disabled={!permissions.update}
                fields={{
                  unitId: distributorId,
                  customUnit: distributorCustomName,
                }}
                options={InventoryDistributors}
                unitGridWidth={5}
              />
            </Grid>
          </Grid>
          <Grid container item alignItems="center" wrap="nowrap">
            <Grid item className={classes.item} xs={2}>
              <ReceivedBy disabled={!permissions.update} field={receivedBy} />
            </Grid>
            <Grid item className={classes.item} xs={2}>
              <ReceivedOn
                disabled={!permissions.update}
                field={receivedDate}
                required={R.includes(statusId.value, statusWithReceivedDate)}
              />
            </Grid>
            <Grid item xs={8}>
              <Notes disabled={!permissions.update} field={notes} />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container item>
            <Grid item className={classes.item} xs={3}>
              <OrderNumber disabled={!permissions.update} field={orderNumber} />
            </Grid>
            <Grid item className={classes.item} xs={3}>
              <OrderedDate disabled={!permissions.update} field={orderedDate} />
            </Grid>
          </Grid>
          <Grid container item alignItems="center">
            <Distributor
              classes={{
                other: classes.other,
              }}
              disabled={!permissions.update}
              fields={{
                unitId: distributorId,
                customUnit: distributorCustomName,
              }}
              options={InventoryDistributors}
            />
          </Grid>
          <Grid container item alignItems="flex-end">
            <Grid item className={classes.item} xs={3}>
              <ReceivedBy disabled={!permissions.update} field={receivedBy} />
            </Grid>
            <Grid item className={classes.item} xs={3}>
              <ReceivedOn disabled={!permissions.update} field={receivedDate} />
            </Grid>
            <Grid item xs className={classes.item}>
              <Notes disabled={!permissions.update} field={notes} />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
})

export default Shipment
