import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ControlButtonGroup,
  ControlButtonGroupName,
  PuiSelect,
  PuiTextField,
  PuiTheme,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import {
  createDepartment,
  getDepartmentsIsLoading,
} from '~/store/duck/departments'
import { getDepartmentTypes } from '~/store/reducers/constants'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tableAdd: {
      borderTop: theme.constants.tableBorder,
    },
  }),
  { name: 'DepartmentAdd' },
)

interface DepartmentAddProps {
  businessId: string
  className?: string
  onDiscard?: () => void
}

const DepartmentAdd = ({
  className,
  businessId,
  onDiscard = R.F,
}: DepartmentAddProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const DepartmentTypes = useSelector(getDepartmentTypes)
  const isLoading = useSelector(getDepartmentsIsLoading)

  const {
    validate,
    reset,
    fields: { isActive, name, typeId },
  } = useFields([
    {
      name: 'isActive',
      type: 'toggle',
      validators: ['required'],
      initialValue: true,
    },
    { name: 'name', validators: ['required'], initialValue: '' },
    {
      name: 'typeId',
      type: 'select',
      initialValue: Utils.findConstantIdByName('Primary', DepartmentTypes),
    },
  ])

  const handleDiscard = () => {
    reset()
    onDiscard()
  }

  const resetFieldsAfterCreationOn = useCloseAfterCreation(
    reset,
    getDepartmentsIsLoading,
  )

  const handleAdd = () => {
    if (businessId && validate()) {
      dispatch(
        createDepartment(businessId, {
          isActive: isActive.value,
          typeId: typeId.value,
          name: name.value,
        }),
      )
      resetFieldsAfterCreationOn()
    }
  }

  return (
    <Grid
      container
      item
      className={classNames(classes.tableAdd, className)}
      wrap="nowrap"
    >
      <Grid container item alignItems="center" px={1.5} xs={2}>
        <PuiSwitch disabled={isLoading} field={isActive} />
      </Grid>
      <Grid container item alignItems="center" px={1.5} xs={4}>
        <PuiTextField
          autoFocus
          disabled={isLoading}
          field={name}
          inputProps={{ maxLength: 100 }}
          margin="none"
        />
      </Grid>
      <Grid container item alignItems="center" px={1.5} xs={3}>
        <PuiSelect
          disableUnderline
          fullWidth
          aria-labelledby="departmentTypesSelect"
          disabled={isLoading}
          field={typeId}
          items={DepartmentTypes}
          renderEmpty={false}
        />
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        justifyContent="flex-end"
        px={1.5}
        xs={3}
      >
        <ControlButtonGroup
          buttons={[
            {
              name: ControlButtonGroupName.CHECK,
              onClick: handleAdd,
              isLoading,
            },
            {
              name: ControlButtonGroupName.REMOVE,
              onClick: handleDiscard,
              isLoading,
            },
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default DepartmentAdd
