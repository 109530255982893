import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { AddButton, moment, PuiTheme, useFields } from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

import TimeSelector from '~/components/common/inputs/time-selector/TimeSelector'
import { DataHandle } from '~/types'
import { serializeTimeTo24hStr } from '~/utils/time'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    iconButton: {
      padding: theme.spacing(0.5),
    },
  }),
  { name: 'DayTimeRecurrenceGroupItem' },
)

export interface DayTimeRecurrenceGroupItemProps {
  disabled?: boolean
  initialValue?: string
  onAddItem: () => void
  onRefChange?: () => void
  onRemoveItem: () => void
  showAddButton?: boolean
  showRemoveButton?: boolean
}

export type DayTimeRecurrenceGroupItemHandle = DataHandle<string>

const DayTimeRecurrenceGroupItem = forwardRef<
  DayTimeRecurrenceGroupItemHandle,
  DayTimeRecurrenceGroupItemProps
>(function DayTimeRecurrenceGroupItem(
  {
    initialValue,
    showAddButton = false,
    onAddItem,
    showRemoveButton = false,
    disabled,
    onRemoveItem,
    onRefChange,
  },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Tasks'])

  const {
    fields: { time },
    validate,
  } = useFields(
    [
      {
        name: 'time',
        label: t('Tasks:LABEL.SET_TIME_NOUN'),
        validators: ['required', 'timestamp'],
        initialValue: initialValue
          ? moment(initialValue, 'HH:mm').toISOString()
          : moment().set({ h: 12, m: 0, s: 0, ms: 0 }),
      },
    ],
    false,
  )

  useEffectExceptOnMount(() => {
    onRefChange?.()
  }, [time])

  useImperativeHandle(ref, () => ({
    validate,
    get: () => serializeTimeTo24hStr(time.value) as string,
  }))

  return (
    <Grid container item alignItems="center">
      <TimeSelector
        disabled={disabled}
        label={`${time.label}*`}
        startValue={time.value}
        onStartChange={time.setValue}
      />
      {!disabled && showRemoveButton && (
        <Grid item pt={1.5}>
          <IconButton
            aria-label={t('Common:DELETE_ACTION').toLowerCase()}
            className={classes.iconButton}
            size="large"
            onClick={onRemoveItem}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      )}
      {!disabled && showAddButton && (
        <Grid item ml={2} pt={1.5}>
          <AddButton
            addText={t('Tasks:LABEL.ADD_ANOTHER_TIME')}
            onAdd={onAddItem}
          />
        </Grid>
      )}
    </Grid>
  )
})

export default DayTimeRecurrenceGroupItem
