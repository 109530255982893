import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, Grid, Hidden, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Field, PuiSelect, PuiTheme } from '@pbt/pbt-ui-components'

import NumericInput from '~/components/common/inputs/NumericInput'
import { useInStockUnits } from '~/store/hooks/inventories'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    divider: {
      alignSelf: 'center',
      marginTop: theme.spacing(1.5),
      padding: theme.spacing(0, 0.5),
      fontSize: '1.6rem',
    },
    priceTopLeftItem: {
      [theme.breakpoints.down('md')]: {
        paddingRight: theme.spacing(1),
      },
      paddingLeft: theme.spacing(1),
    },
  }),
  { name: 'VariationReOrderSection' },
)

export interface VariationReOrderSectionProps {
  fields: {
    maxQuantity: Field
    maxQuantityUnitId: Field
    point: Field
    pointUnitId: Field
    quantity: Field
    quantityUnitId: Field
  }
  packageTypeId: string
  perPackageUnitsId: string
}

const VariationReOrderSection = ({
  fields: {
    point,
    quantity,
    maxQuantity,
    pointUnitId,
    quantityUnitId,
    maxQuantityUnitId,
  },
  packageTypeId,
  perPackageUnitsId,
}: VariationReOrderSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const inStockUnitOptions = useInStockUnits(perPackageUnitsId, packageTypeId)

  return (
    <Grid container direction="column">
      <Grid container item>
        <Grid item className={classes.priceTopLeftItem} sm={2} xs={6}>
          <NumericInput
            allowDecimal
            field={point}
            label={point.label || t('Common:INVENTORY_RE-ORDER_POINT')}
            min={0}
          />
        </Grid>
        <Hidden mdDown>
          <div className={classes.divider}>/</div>
        </Hidden>
        <Grid container item alignItems="center" sm={3} xs={6}>
          <FormControl fullWidth margin="none">
            <InputLabel>{pointUnitId.label || t('Common:UNIT')}</InputLabel>
            <PuiSelect
              renderEmpty
              field={pointUnitId}
              items={inStockUnitOptions}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item className={classes.priceTopLeftItem} sm={2} xs={6}>
          <NumericInput
            allowDecimal
            field={quantity}
            label={quantity.label || t('Common:INVENTORY_RE-ORDER_QUANTITY')}
            min={0}
          />
        </Grid>
        <Hidden mdDown>
          <div className={classes.divider}>/</div>
        </Hidden>
        <Grid container item alignItems="center" sm={3} xs={6}>
          <FormControl fullWidth margin="none">
            <InputLabel>{quantityUnitId.label || t('Common:UNIT')}</InputLabel>
            <PuiSelect
              renderEmpty
              field={quantityUnitId}
              items={inStockUnitOptions}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item className={classes.priceTopLeftItem} sm={2} xs={6}>
          <NumericInput
            allowDecimal
            field={maxQuantity}
            label={maxQuantity.label || t('Common:MAXIMUM_QUANTITY')}
            min={0}
          />
        </Grid>
        <Hidden mdDown>
          <div className={classes.divider}>/</div>
        </Hidden>
        <Grid container item alignItems="center" sm={3} xs={6}>
          <FormControl fullWidth margin="none">
            <InputLabel>
              {maxQuantityUnitId.label || t('Common:UNIT')}
            </InputLabel>
            <PuiSelect
              renderEmpty
              field={maxQuantityUnitId}
              items={inStockUnitOptions}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default VariationReOrderSection
