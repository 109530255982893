import React from 'react'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiDialog, PuiTheme } from '@pbt/pbt-ui-components'

import { getIsGettingToken } from '~/store/duck/monitor'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      padding: theme.spacing(1),
    },
  }),
  { name: 'AttachmentLightbox' },
)

const MonitorWatcher = () => {
  const isGettingToken = useSelector(getIsGettingToken)

  const classes = useStyles()

  return (
    <PuiDialog
      hideCloseButton
      aria-labelledby="monitor-loading"
      classes={{
        paper: classes.paper,
      }}
      open={isGettingToken}
    >
      <CircularProgress size={32} />
    </PuiDialog>
  )
}

export default MonitorWatcher
