import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Table } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AddButton, Field, PuiTheme } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import useDialog from '~/utils/useDialog'

import { useSoapTemplateAlert } from '../../dialog/utils/useSoapTemplateAlert'
import SoapTemplateTabsTableBody from './SoapTemplateTabsTableBody'
import SoapTemplateTabsTableHeader from './SoapTemplateTabsTableHeader'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    cell: {
      padding: theme.spacing(0, 1),
    },
    mainColumn: {
      borderRight: theme.constants.tableBorder,
      paddingRight: 0,
    },
    table: {
      border: theme.constants.tableBorder,
    },
  }),
  { name: 'SoapTemplateTabsTable' },
)

export interface SoapTemplateTabsTableProps {
  fieldTabs: Field
  isFetching: boolean
  onReset: () => void
}

const SoapTemplateTabsTable = ({
  fieldTabs,
  isFetching,
  onReset,
}: SoapTemplateTabsTableProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Admin', 'Common'])

  const [openSoapTemplateTabDialog] = useDialog(DialogNames.SOAP_TEMPLATE_TAB)
  useSoapTemplateAlert({ fieldTabs, onReset })

  const handleAdd = () => {
    openSoapTemplateTabDialog({
      onSave: ({ name, widgets }) => {
        const newTab = {
          name,
          label: name,
          order: fieldTabs.value.length + 1,
          widgets,
        }

        fieldTabs.setValue([...fieldTabs.value, newTab])
      },
    })
  }

  return (
    <>
      <Table className={classes.table}>
        <SoapTemplateTabsTableHeader
          classes={{
            cell: classes.cell,
            mainColumn: classes.mainColumn,
          }}
        />
        <SoapTemplateTabsTableBody
          classes={{
            cell: classes.cell,
            mainColumn: classes.mainColumn,
          }}
          fieldTabs={fieldTabs}
          isFetching={isFetching}
          onOpenSoapTemplateTabDialog={openSoapTemplateTabDialog}
        />
      </Table>
      <Box id="soap-template-add-tab-button" mt={1}>
        <AddButton addText={t('Common:ADD_A_TAB')} onAdd={handleAdd} />
      </Box>
    </>
  )
}

export default SoapTemplateTabsTable
