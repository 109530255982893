import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiCheckbox, PuiTheme, Text, useFields } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  BasePracticeDetailsSectionProps,
  PracticeSavedSignatureConfigurationFields,
} from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tableHeading: {
      verticalAlign: 'top',
      padding: theme.spacing(1, 0, 1, 2),
    },
    tableViewport: {
      border: theme.constants.tableBorder,
    },
    tableCell: {
      padding: theme.spacing(0, 0, 0, 2),
      border: 'none',
    },
    tableRow: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
  }),
  { name: 'SavedSignatureConfigurationSection' },
)

const SavedSignatureConfigurationSection = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Businesses'])

  const isActiveRxEnabled = useSelector(
    getFeatureToggle(FeatureToggle.ACTIVE_RX),
  )

  const { fields, validate, reset } = useFields([
    ...(isActiveRxEnabled
      ? [
          {
            name: 'chewyPrescription',
            label: t(
              'Businesses:SAVED_SIGNATURE_CONFIGURATION.CHEWY_PRESCRIPTIONS',
            ),
            type: 'toggle',
            // Default value should be true by PR's requirement
            initialValue:
              business?.useDefaultSignature?.chewyPrescription ?? true,
          },
        ]
      : ([] as any)),
    {
      name: 'soap',
      label: t(
        'Businesses:SAVED_SIGNATURE_CONFIGURATION.SOAP_OUTSIDE_PHARMACY',
      ),
      type: 'toggle',
      initialValue: business?.useDefaultSignature?.soap || false,
    },
    {
      name: 'otc',
      label: t('Businesses:SAVED_SIGNATURE_CONFIGURATION.OTC_OUTSIDE_PHARMACY'),
      type: 'toggle',
      initialValue: business?.useDefaultSignature?.otc || false,
    },
    {
      name: 'rabiesVaccine',
      label: t('Businesses:SAVED_SIGNATURE_CONFIGURATION.RABIES_CERTIFICATE'),
      type: 'toggle',
      initialValue: business?.useDefaultSignature?.rabiesVaccine || false,
    },
    {
      name: 'proofOfVaccination',
      label: t('Businesses:SAVED_SIGNATURE_CONFIGURATION.PROOF_OF_VACCINATION'),
      type: 'toggle',
      initialValue: business?.useDefaultSignature?.proofOfVaccination || false,
    },
  ])

  const { chewyPrescription, soap, otc, rabiesVaccine, proofOfVaccination } =
    fields

  usePracticeFieldsSection<PracticeSavedSignatureConfigurationFields>({
    business,
    fields,
    parsedFields: {
      useDefaultSignature: {
        ...(isActiveRxEnabled
          ? {
              chewyPrescription: chewyPrescription.value,
            }
          : {}),
        soap: soap.value,
        otc: otc.value,
        rabiesVaccine: rabiesVaccine.value,
        proofOfVaccination: proofOfVaccination.value,
      },
    },
    reset,
    sectionName: PracticeDetailsPanels.SAVED_SIGNATURE_CONFIGURATION,
    validate,
  })

  return (
    <Grid container pr={4}>
      <Table className={classes.tableViewport}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeading}>
              <Text strong variant="lowAccent2">
                {t(
                  'Businesses:SAVED_SIGNATURE_CONFIGURATION.ALLOW_SAVED_SIGNATURES',
                )}
              </Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isActiveRxEnabled && (
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <PuiCheckbox
                  field={chewyPrescription}
                  label={chewyPrescription.label}
                />
              </TableCell>
            </TableRow>
          )}
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell}>
              <PuiCheckbox field={soap} label={soap.label} />
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell}>
              <PuiCheckbox field={otc} label={otc.label} />
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell}>
              <PuiCheckbox field={rabiesVaccine} label={rabiesVaccine.label} />
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell}>
              <PuiCheckbox
                field={proofOfVaccination}
                label={proofOfVaccination.label}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  )
}

export default SavedSignatureConfigurationSection
