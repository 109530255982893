import React, { useEffect, useState } from 'react'
import {
  Pagination,
  PaginationItem,
  PaginationItemProps,
  PaginationProps,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PuiTheme } from '@pbt/pbt-ui-components'

import { puiPaginationSlots } from '~/components/common/lists/table/PuiPaginationSlots'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    disabled: {
      color: `${theme.colors.selectedOption} !important`,
      opacity: '1 !important',
    },
    selectedItem: {
      color: `${theme.colors.selectedItem} !important`,
      backgroundColor: `${theme.colors.tableEvenItem} !important`,
    },
    text: {
      color: theme.colors.tabLabel,
      fontSize: '1.2rem',
      fontWeight: '400',
      height: 24,
    },
  }),
  { name: 'PuiPagination' },
)

export interface PuiPaginationProps extends PaginationProps {
  initialPage?: number
  itemsPerPage: number
  onFetchMore: (offset: number) => void
  paginationSlots?: PaginationItemProps['slots']
  reset?: boolean
  totalItems: number
}

export const PuiPagination = ({
  initialPage = 1,
  paginationSlots = puiPaginationSlots,
  onFetchMore,
  itemsPerPage,
  reset,
  totalItems,
  ...rest
}: PuiPaginationProps) => {
  const classes = useStyles()

  const [currentPage, setCurrentPage] = useState(initialPage)

  const totalPages = totalItems ? Math.ceil(totalItems / itemsPerPage) : 1

  const handleChange: PaginationProps['onChange'] = (_, newPage) => {
    const offset = (newPage - 1) * itemsPerPage
    setCurrentPage(newPage)
    onFetchMore(offset)
  }

  useEffect(() => {
    if (reset) {
      setCurrentPage(initialPage)
    }
  }, [reset])

  return (
    <Pagination
      count={totalPages}
      page={currentPage}
      renderItem={(item) => (
        <PaginationItem
          classes={{
            disabled: classes.disabled,
            selected: classes.selectedItem,
            text: classes.text,
          }}
          slots={paginationSlots}
          {...item}
        />
      )}
      size="small"
      onChange={handleChange}
      {...rest}
    />
  )
}
