import React, { useEffect, useState } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { omit } from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  DateUtils,
  LanguageUtils,
  PuiDialog,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import AppointmentStateLabel from '~/components/common/labels/AppointmentStateLabel'
import {
  getFinanceInvoice,
  getFinanceIsLoading,
} from '~/store/reducers/finance'
import { Estimate, InvoiceOrEstimate } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 520,
      maxWidth: 520,
    },
    eventContainer: {
      '&:not(:first-child)': {
        marginTop: theme.spacing(1),
      },
    },
    checkboxLabel: {
      color: theme.colors.secondaryText,
      fontSize: '1.6rem',
    },
    labelRoot: {
      alignItems: 'center',
      marginLeft: theme.spacing(-0.75),
    },
    labelRootChildren: {
      marginLeft: theme.spacing(2),
    },
    button: {
      minWidth: 128,
    },
    cancelButton: {
      marginLeft: theme.spacing(2),
    },
    checkbox: {
      padding: theme.spacing(0.5),
    },
    parentItem: {
      width: 320,
    },
  }),
  { name: 'EstimateApprovalDialog' },
)

export interface EstimateApprovalDialogProps extends BasePuiDialogProps {
  cancelButtonLabel: string
  invoiceId: string
  labelWithoutSoap: string
  okButtonLabel: string
  onConfirm: (
    eventsIds: string[],
    soapIds: string[],
    invoice: InvoiceOrEstimate,
  ) => void
  title: string
}

const EstimateApprovalDialog = ({
  open,
  title,
  cancelButtonLabel,
  okButtonLabel: okButtonLabelProp,
  invoiceId,
  onClose,
  onConfirm,
  labelWithoutSoap,
}: EstimateApprovalDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Dialogs'])
  const estimateApprovalDialogCancelButtonLabel =
    cancelButtonLabel || t('Common:CANCEL_ACTION')
  const okButtonLabel = okButtonLabelProp || t('Common:CONTINUE_ACTION')

  const isLoading = useSelector(getFinanceIsLoading)
  const invoice = useSelector(getFinanceInvoice(invoiceId)) as Estimate

  const { events = [] } = invoice || {}

  const [selectedEventMap, setSelectedEventMap] = useState<
    Record<string, boolean>
  >({})
  const [selectedEventSoapMap, setSelectedEventSoapMap] = useState<
    Record<string, string>
  >({})

  const handleEventsSelectionChange = (eventId: string) => {
    const newMap = selectedEventMap[eventId]
      ? omit([eventId], selectedEventMap)
      : { ...selectedEventMap, [eventId]: true }

    setSelectedEventMap(newMap)
  }

  const handleSoapsSelectionChange = (
    eventId: string,
    soapId: string,
    isNone: boolean,
  ) => {
    if (!isNone) {
      setSelectedEventSoapMap({
        ...selectedEventSoapMap,
        [eventId]: soapId,
      })
    }
  }

  const setCloseAfterCreationOn = useCloseAfterCreation(
    onClose,
    getFinanceIsLoading,
  )

  const preselectAllEventsAndSoaps = () => {
    const [newEventsIdsMap, newSoapIdsMap] = events.reduce(
      ([eventsIdsMap, soapIdsMap], event) => {
        if (!event.soaps?.length) {
          eventsIdsMap[event.id] = true

          return [eventsIdsMap, soapIdsMap]
        }

        return [eventsIdsMap, { ...soapIdsMap, [event.id]: event.soaps[0].id }]
      },
      [{} as Record<string, boolean>, {} as Record<string, string>],
    )
    setSelectedEventMap(newEventsIdsMap)
    setSelectedEventSoapMap(newSoapIdsMap)
  }

  useEffect(() => {
    if (events.length > 0) {
      preselectAllEventsAndSoaps()
    }
  }, [events])

  const handleContinue = () => {
    const eventsIds = Object.keys(selectedEventMap)
    const soapIds = Object.values(selectedEventSoapMap).filter(Boolean)
    onConfirm(eventsIds, soapIds, invoice as InvoiceOrEstimate)
    setCloseAfterCreationOn()
  }

  return (
    <PuiDialog
      aria-labelledby="estimate-approval-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <Grid container direction="column">
        <Grid item pb={2} pl={3} pr={5.5} pt={3}>
          <Text variant="h3">{title}</Text>
        </Grid>
        <Grid px={3}>
          {events?.map((item) => {
            const showSubItems = (item?.soaps?.length as number) > 0

            return (
              <React.Fragment key={item.id}>
                <Grid
                  container
                  alignItems="center"
                  className={classes.eventContainer}
                >
                  <Grid item className={classes.parentItem}>
                    {showSubItems ? (
                      <Dotdotdot clamp={1}>
                        <Text>
                          {`${LanguageUtils.getTranslatedFieldName(item.type)}
                              ${DateUtils.formatDateWithHours(
                                item.scheduledStartDatetime,
                              )}`}
                        </Text>
                      </Dotdotdot>
                    ) : (
                      <FormControlLabel
                        classes={{
                          label: classes.checkboxLabel,
                          root: classes.labelRoot,
                        }}
                        control={
                          <Checkbox
                            checked={Boolean(selectedEventMap[item.id])}
                            className={classes.checkbox}
                            value={item.id}
                            onChange={() =>
                              handleEventsSelectionChange(item.id)
                            }
                          />
                        }
                        key={item.id}
                        label={
                          <Dotdotdot clamp={1}>
                            <Text>{`${item.name} ${DateUtils.formatDate(
                              item.scheduledStartDatetime,
                            )}`}</Text>
                          </Dotdotdot>
                        }
                      />
                    )}
                  </Grid>
                  {item.state && (
                    <Grid item>
                      <AppointmentStateLabel item={item} variant="small" />
                    </Grid>
                  )}
                </Grid>
                {showSubItems && (
                  <Grid container direction="column">
                    <RadioGroup
                      name={item.id}
                      value={selectedEventSoapMap[item.id] || ''}
                      onChange={(_, soapId) =>
                        handleSoapsSelectionChange(item.id, soapId, false)
                      }
                    >
                      {[...(item?.soaps || []), { id: '' }].map((soap) => (
                        <FormControlLabel
                          classes={{
                            label: classes.checkboxLabel,
                            root: classNames(
                              classes.labelRoot,
                              classes.labelRootChildren,
                            ),
                          }}
                          control={
                            <Radio
                              className={classes.checkbox}
                              value={soap.id}
                            />
                          }
                          key={soap.id || 'none'}
                          label={
                            <Dotdotdot clamp={1}>
                              {!soap.id
                                ? labelWithoutSoap
                                : `${DateUtils.formatTime(
                                    soap.creationDate || '',
                                  )} ${soap?.assignedVet?.lastName || ''}`}
                            </Dotdotdot>
                          }
                        />
                      ))}
                    </RadioGroup>
                  </Grid>
                )}
              </React.Fragment>
            )
          })}
        </Grid>
        <Grid p={3}>
          <ButtonWithLoader
            className={classes.button}
            disabled={isLoading}
            loading={isLoading}
            onClick={handleContinue}
          >
            {okButtonLabel}
          </ButtonWithLoader>
          <ButtonWithLoader
            className={classNames(classes.button, classes.cancelButton)}
            color="secondary"
            disabled={isLoading}
            type="submit"
            onClick={onClose}
          >
            {estimateApprovalDialogCancelButtonLabel}
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default EstimateApprovalDialog
