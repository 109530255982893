import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { moment, Nil, User } from '@pbt/pbt-ui-components'

// @ts-ignore
import { deserializeFindingsState } from '~/api/utils/serializeSOAPData'
import { vitalsTableConfig } from '~/components/dashboard/soapV2/vitals/config/vitalsTableConfig'
import FeatureToggle from '~/constants/featureToggle'
import { BodySystemState } from '~/constants/SOAPStates'
import { VitalMeasurement } from '~/constants/vitals'
import i18n from '~/locales/i18n'
import {
  DiagnosesState,
  DiagnosesStaticList,
  FindingsStaticList,
  FindingState,
  OrderFilter,
  SoapAppointment,
  SoapData,
  SoapFile,
  SoapFinalizedLog,
  SoapNotesValidationError,
  StaticFinding,
  VitalValue,
} from '~/types'
import { updateAllById } from '~/utils'
import { isEmptyParagraphOrString } from '~/utils/ckEditorUtils'
import { getErrorMessage } from '~/utils/errors'

import { editFindingsState } from '../actions/soap'
import * as SOAPTypes from '../actions/types/soap'
import { CREATE_ADDENDUM_SUCCESS } from '../duck/addendums'
import {
  DELETE_VITAL_SUCCESS,
  getPendingVital,
  getVitalsMap,
} from '../duck/vitals'
import type { RootState } from '../index'
import { getCurrentBusinessId } from './auth'
import { getFeatureToggle } from './constants'
import { getFinanceInvoiceIsPosted } from './finance'
import { getSoapTasksList } from './tasks'
import { getUsersMap } from './users'

export type SoapState = {
  addendumIds: string[]
  appointment: SoapAppointment | null
  assignedDoctorValidationError: string | null
  businessId: string | undefined
  chatInSoap: {
    conversationId: string | undefined
  }
  clientId: string | null
  diagnosesState: Record<string, DiagnosesState>
  dischargeNotes?: string
  dischargeNotesCrc: number | null
  doctorId: string
  doctors: string[]
  documentValidationError: string | null
  error: string | null
  files: SoapFile[]
  finalized: boolean
  finalizedBy: User | null
  finalizedDate: string
  finalizedLogs: SoapFinalizedLog[]
  findingLogsCrc: number | undefined
  findingLogsCrcValidationError: boolean | undefined
  findingToExpand: { categoryId: string; findingId: string } | undefined
  findingsState: Record<string, FindingState>
  id: string | undefined
  invoiceId: string | undefined
  isAttachingDocuments: boolean
  isDischargeNotesUpdating: boolean
  isExamFindingsLoading: boolean
  isFetching: boolean
  isFetchingSoapOrders: boolean
  isFetchingUsersOnCollaboration: boolean
  isLoading: boolean
  isMedicalNotesUpdating: boolean
  isSaving: boolean
  isUploadingFile: boolean
  lastSaveDate: string | null
  medicalNotes?: string
  medicalNotesCrc: number | null
  notesValidationError: SoapNotesValidationError | null
  orderFilters: OrderFilter[]
  ordersLocked: boolean
  patientId: string | Nil
  pendingDiagnosesState: Record<string, DiagnosesState>
  pendingFindingsState: Record<string, FindingState>
  petParents: string[]
  primaryComplaint: string
  room: string
  soapAlert: string | null
  techId: string
  techs: string[]
  types: {
    diagnosesStaticList: DiagnosesStaticList
    findingsStaticList: FindingsStaticList
  }
  usersOnCollaborationSession?: Partial<User>[]
  vitalIds: string[]
  vitalsCount: number
}

export const INITIAL_STATE: SoapState = {
  addendumIds: [],
  appointment: null,
  assignedDoctorValidationError: null,
  businessId: undefined,
  chatInSoap: { conversationId: undefined },
  clientId: null,
  diagnosesState: {},
  dischargeNotes: '',
  dischargeNotesCrc: null,
  doctorId: '',
  doctors: [],
  documentValidationError: null,
  error: null,
  ordersLocked: false,
  files: [],
  finalized: false,
  finalizedBy: null,
  finalizedDate: '',
  finalizedLogs: [],
  findingLogsCrc: undefined,
  findingLogsCrcValidationError: undefined,
  findingsState: {},
  findingToExpand: undefined,
  id: undefined,
  invoiceId: undefined,
  isAttachingDocuments: false,
  isDischargeNotesUpdating: false,
  isExamFindingsLoading: false,
  isFetching: false,
  isFetchingSoapOrders: false,
  isFetchingUsersOnCollaboration: false,
  isLoading: false,
  isMedicalNotesUpdating: false,
  isSaving: false,
  isUploadingFile: false,
  lastSaveDate: null,
  medicalNotes: '',
  medicalNotesCrc: null,
  notesValidationError: null,
  orderFilters: [],
  patientId: null,
  pendingDiagnosesState: {},
  pendingFindingsState: {},
  petParents: [],
  primaryComplaint: '',
  room: '',
  soapAlert: null,
  techId: '',
  techs: [],
  types: { findingsStaticList: [], diagnosesStaticList: [] },
  vitalIds: [],
  vitalsCount: 0,
}

// ToDo: consider refactoring it and moving this logic out from the reducer
const updateFindings = (
  findingsState: Record<string, FindingState>,
  action: ReturnType<typeof editFindingsState>,
) => {
  const bodySystemNotes = Object.prototype.hasOwnProperty.call(action, 'notes')
    ? action.notes
    : findingsState[action.bodySystem.id]?.notes || null

  if (action.state === BodySystemState.NC && !bodySystemNotes) {
    return R.omit([action.bodySystem.id], findingsState)
  }

  const bodySystemState = findingsState[action.bodySystem.id]
    ? {
        ...findingsState[action.bodySystem.id],
        state: action.state || BodySystemState.ONL,
        notes: bodySystemNotes,
      }
    : {
        findings: {} as Record<string, StaticFinding>,
        ...action.bodySystem,
        state: action.state || BodySystemState.ONL,
        notes: bodySystemNotes,
      }

  if (action.state !== BodySystemState.ONL) {
    bodySystemState.findings = {} as Record<string, StaticFinding>
  }

  if (!action.finding) {
    return {
      ...findingsState,
      [action.bodySystem.id]: bodySystemState,
    }
  }

  if (action.uncheckFinding) {
    return {
      ...findingsState,
      [action.bodySystem.id]: {
        ...bodySystemState,
        findings: R.dissoc(action.finding.id, bodySystemState.findings || {}),
      },
    }
  }

  const locations =
    action.locations ||
    R.path(['findings', action.finding.id, 'locations'], bodySystemState) ||
    {}
  const findingState =
    bodySystemState.findings && bodySystemState.findings[action.finding.id]
      ? {
          ...bodySystemState.findings[action.finding.id],
          locations,
        }
      : {
          ...action.finding,
          locations,
        }

  return {
    ...findingsState,
    [action.bodySystem.id]: {
      ...bodySystemState,
      findings: {
        ...bodySystemState.findings,
        [action.finding.id]: findingState,
      },
    },
  }
}

const getMainSoapData = (data: SoapData) => {
  // this is for the case of SOAP Edit when backend does not return all data that a soap has
  const {
    assignedSpace,
    assignedVetId,
    assignedVetTechId,
    medicalNotes,
    medicalNotesCrc,
    dischargeNotes,
    dischargeNotesCrc,
    finalized,
    finalizedBy,
    finalizedDate,
    addendums,
    primaryComplaint,
    soapFinalizedLogs,
  } = data.soapData.soap

  return {
    lastSaveDate: moment().toDate().toISOString(),
    businessId: data.soapData.businessId,
    id: data.soapData.id,
    invoiceId: data.invoice,
    doctors: data.veterinarians,
    techs: data.vetTechs,
    room: assignedSpace || '',
    doctorId: assignedVetId || '',
    techId: assignedVetTechId || '',
    medicalNotes: isEmptyParagraphOrString(medicalNotes) ? '' : medicalNotes,
    medicalNotesCrc,
    dischargeNotes: isEmptyParagraphOrString(dischargeNotes)
      ? ''
      : dischargeNotes,
    dischargeNotesCrc,
    finalized,
    finalizedBy,
    finalizedDate,
    primaryComplaint,
    addendumIds: addendums || [],
    finalizedLogs: soapFinalizedLogs || [],
    ordersLocked: data.ordersLocked,
  }
}

const getSoapFromActionData = (data: SoapData, state: SoapState) => ({
  // this is for the case of soap fetch when backend returns everything
  ...getMainSoapData(data),
  patientId: data.patient,
  clientId: data.client,
  diagnosesState: data.diagnosisData.diagnosisLogs || {},
  findingsState: deserializeFindingsState(
    data.findingLogsContainer?.findingLogs || [],
  ),
  findingLogsCrc: data.findingLogsContainer?.crc,
  appointment: data.appointment,
  vitalIds: data.vitals,
  vitalsCount: data.vitalsCount,
  types: {
    ...state.types,
    findingsStaticList: data.findings,
    diagnosesStaticList: data.diagnosisData.diagnoses,
  },
  orderFilters: data.orderFilters,
  files: data.files || [],
  petParents: data.petParents || [],
})

const soap = (
  state: SoapState = INITIAL_STATE,
  action: AnyAction,
): SoapState => {
  switch (action.type) {
    case SOAPTypes.CREATE_SOAP:
    case SOAPTypes.FETCH_SOAP:
      return {
        ...INITIAL_STATE,
        isLoading: true,
        isFetching: true,
        id: action.id || state.id,
      }
    case SOAPTypes.ADD_SOAP:
      return { ...state, isLoading: true, isFetching: true }
    case SOAPTypes.CREATE_SOAP_FAILURE:
    case SOAPTypes.ADD_SOAP_FAILURE:
    case SOAPTypes.FETCH_SOAP_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case SOAPTypes.CREATE_SOAP_SUCCESS:
    case SOAPTypes.UPDATE_SOAP:
    case SOAPTypes.FETCH_SOAP_SUCCESS:
      return {
        ...state,
        ...getSoapFromActionData(action.data, state),
        isLoading: false,
        isFetching: false,
      }
    case SOAPTypes.FETCH_SOAP_FINDINGS_CONTAINER:
      return { ...state, isExamFindingsLoading: true }
    case SOAPTypes.FETCH_SOAP_FINDINGS_CONTAINER_FAILURE:
      return {
        ...state,
        isExamFindingsLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case SOAPTypes.FETCH_SOAP_FINDINGS_CONTAINER_SUCCESS:
      return {
        ...state,
        findingLogsCrc: action.crc,
        findingsState: action.findingLogs,
        isExamFindingsLoading: false,
      }
    case SOAPTypes.ADD_SOAP_SUCCESS:
      return {
        ...INITIAL_STATE,
        ...getSoapFromActionData(action.data, state),
        isLoading: false,
        isFetching: false,
      }
    case SOAPTypes.SELECT_DOCTOR_LOCALLY:
    case SOAPTypes.SELECT_DOCTOR:
      return { ...state, doctorId: action.id }
    case SOAPTypes.SELECT_TECH_LOCALLY:
    case SOAPTypes.SELECT_TECH:
      return { ...state, techId: action.id }
    case SOAPTypes.EDIT_FINDINGS_STATE:
      return {
        ...state,
        findingsState: updateFindings(
          state.findingsState,
          action as ReturnType<typeof editFindingsState>,
        ),
        pendingFindingsState: state.findingsState,
      }
    case SOAPTypes.EDIT_FINDINGS_STATE_SUCCESS:
      return {
        ...state,
        pendingFindingsState: {},
      }
    case SOAPTypes.EDIT_FINDINGS_STATE_FAILURE:
      return {
        ...state,
        findingsState: state.pendingFindingsState,
        pendingFindingsState: {},
        error: getErrorMessage(action.error),
      }
    case SOAPTypes.UPDATE_FINDING_LOGS_CRC_SUCCESS:
      return {
        ...state,
        pendingFindingsState: {},
        findingLogsCrc: action.crc,
        findingLogsCrcValidationError: false,
      }
    case SOAPTypes.UPDATE_FINDING_LOGS_CRC_FAILURE:
      return {
        ...state,
        isSaving: false,
        findingLogsCrcValidationError: true,
      }
    case SOAPTypes.CLEAR_FINDING_LOGS_CRC_VALIDATION_ERROR:
      return {
        ...state,
        findingLogsCrcValidationError: false,
      }
    case SOAPTypes.UPDATE_FINDING_TO_EXPAND:
      return { ...state, findingToExpand: action.findingToExpand }
    case SOAPTypes.EDIT_DIFFERENTIAL_DIAGNOSES_STATE:
      return {
        ...state,
        diagnosesState: action.data
          ? { ...state.diagnosesState, [action.id]: action.data }
          : R.reject(R.eqProps('id', action), state.diagnosesState),
        pendingDiagnosesState: state.diagnosesState,
      }
    case SOAPTypes.EDIT_DIFFERENTIAL_DIAGNOSES_STATE_SUCCESS:
      return {
        ...state,
        diagnosesState: action.data,
        pendingDiagnosesState: {},
      }
    case SOAPTypes.EDIT_DIFFERENTIAL_DIAGNOSES_STATE_FAILURE:
      return {
        ...state,
        diagnosesState: state.pendingDiagnosesState,
        pendingDiagnosesState: {},
        error: getErrorMessage(action.error),
      }
    case SOAPTypes.SAVE_SOAP:
      return { ...state, isSaving: true }
    case SOAPTypes.SAVE_SOAP_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: getErrorMessage(action.error),
      }
    case SOAPTypes.NOTES_VALIDATION_ERROR:
      return {
        ...state,
        isSaving: false,
        notesValidationError: action.error,
        [action.error.fieldName]: action.error.lastText,
        [`${action.error.fieldName}Crc`]: action.error.crc,
      }
    case SOAPTypes.ASSIGNED_DOCTOR_VALIDATION_ERROR:
      return {
        ...state,
        isSaving: false,
        assignedDoctorValidationError: action.error,
      }
    case SOAPTypes.SAVE_SOAP_SUCCESS:
      // if soap id is missing probably user already left the page and we need to ignore this data
      // because soap state was cleared already

      if (!state.id && !action.isChargeSheetEnabled) {
        return state
      }

      return {
        ...state,
        isSaving: false,
        lastSaveDate: moment().toDate().toISOString(),
        error: null,
        notesValidationError: null,
        ...(action.data ? getMainSoapData(action.data) : {}),
      }
    case SOAPTypes.CLEAR_ASSIGNED_DOCTOR_VALIDATION_ERROR:
      return { ...state, assignedDoctorValidationError: null }
    case SOAPTypes.CLEAR_NOTES_VALIDATION_ERROR:
      return { ...state, notesValidationError: null }
    case SOAPTypes.FETCH_SOAP_FILES_SUCCESS:
      return { ...state, files: action.files || [] }
    case SOAPTypes.FETCH_SOAP_FILES_FAILURE:
      return { ...state, error: getErrorMessage(action.error) }
    case SOAPTypes.UPDATE_SOAP_FILES:
      return {
        ...state,
        files: updateAllById(state.files, action.files),
      }
    case SOAPTypes.UPLOAD_FILE:
      return { ...state, isUploadingFile: true }
    case SOAPTypes.UPLOAD_FILE_SUCCESS:
      return { ...state, isUploadingFile: false }
    case SOAPTypes.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        isUploadingFile: false,
        error: getErrorMessage(action.error),
      }
    case SOAPTypes.ATTACH_DOCUMENTS_TO_SOAP:
      return { ...state, isAttachingDocuments: true }
    case SOAPTypes.ATTACH_DOCUMENTS_TO_SOAP_SUCCESS:
      return { ...state, isAttachingDocuments: false }
    case SOAPTypes.ATTACH_DOCUMENTS_TO_SOAP_FAILURE:
      return {
        ...state,
        isAttachingDocuments: false,
        error: getErrorMessage(action.error),
      }
    case SOAPTypes.UPDATE_DOCUMENT_ON_SOAP:
      return { ...state, isAttachingDocuments: true }
    case SOAPTypes.UPDATE_DOCUMENT_ON_SOAP_SUCCESS:
      return { ...state, isAttachingDocuments: false }
    case SOAPTypes.UPDATE_DOCUMENT_ON_SOAP_FAILURE:
      return {
        ...state,
        isAttachingDocuments: false,
        error: getErrorMessage(action.error),
        documentValidationError:
          action.error.status === 410
            ? i18n.t('Errors:DOCUMENT_WAS_REMOVED')
            : null,
      }
    case SOAPTypes.CLEAR_DOCUMENT_VALIDATION_ERROR:
      return { ...state, documentValidationError: null }
    case SOAPTypes.DELETE_FILE_SUCCESS:
      return {
        ...state,
        files: state.files.filter(({ id }) => id !== action.id),
      }
    case SOAPTypes.DELETE_FILE_FAILURE:
      return { ...state, error: getErrorMessage(action.error) }
    case SOAPTypes.UPDATE_INVOICE_ID:
      return { ...state, invoiceId: action.invoiceId }
    case SOAPTypes.CLOSE_SOAP:
      return INITIAL_STATE
    case SOAPTypes.FETCH_SOAP_ORDERS:
      return { ...state, isFetchingSoapOrders: true }
    case SOAPTypes.FETCH_SOAP_ORDERS_SUCCESS:
      return { ...state, isFetchingSoapOrders: false }
    case SOAPTypes.FETCH_SOAP_ORDERS_FAILURE:
      return {
        ...state,
        isFetchingSoapOrders: false,
        error: getErrorMessage(action.error),
      }
    case SOAPTypes.FETCH_SOAP_ORDER_FILTERS:
      return { ...state, isFetchingSoapOrders: true }
    case SOAPTypes.FETCH_SOAP_ORDER_FILTERS_SUCCESS:
      return {
        ...state,
        isFetchingSoapOrders: false,
        orderFilters: action.orderFilters,
      }
    case SOAPTypes.FETCH_SOAP_ORDER_FILTERS_FAILURE:
      return {
        ...state,
        isFetchingSoapOrders: false,
        error: getErrorMessage(action.error),
      }
    case SOAPTypes.SET_SOAP_ALERT:
      return { ...state, soapAlert: action.message }
    case SOAPTypes.NAVIGATE_TO_SOAP_CONVERSATION:
      return {
        ...state,
        chatInSoap: {
          ...state.chatInSoap,
          conversationId: action.conversationId,
        },
      }
    case SOAPTypes.NAVIGATE_TO_SOAP_CONVERSATIONS_LIST:
      return {
        ...state,
        chatInSoap: { ...state.chatInSoap, conversationId: undefined },
      }
    case SOAPTypes.UPDATE_MEDICAL_NOTES:
      return { ...state, isMedicalNotesUpdating: true }
    case SOAPTypes.UPDATE_MEDICAL_NOTES_COMPLETE:
      return { ...state, isMedicalNotesUpdating: false }
    case SOAPTypes.UPDATE_DISCHARGE_NOTES:
      return { ...state, isDischargeNotesUpdating: true }
    case SOAPTypes.UPDATE_DISCHARGE_NOTES_COMPLETE:
      return { ...state, isDischargeNotesUpdating: false }
    case SOAPTypes.UPDATE_SOAP_VITAL_IDS:
      return { ...state, vitalIds: action.vitalIds }

    case SOAPTypes.ADD_SOAP_VITAL_IDS:
      return {
        ...state,
        vitalIds: R.uniq([...state.vitalIds, ...action.vitalIds]),
      }
    case SOAPTypes.ADD_V2_SOAP_VITAL_ID:
      return { ...state, vitalIds: R.uniq([action.vitalId, ...state.vitalIds]) }
    case SOAPTypes.EDIT_SOAP_FINALIZED_STATUS:
      return state
    case SOAPTypes.UPDATE_SOAP_FINALIZED_STATUS:
      return {
        ...state,
        finalized: action.finalizedStatus.finalized,
        finalizedBy: action.finalizedStatus.finalizedBy,
        finalizedDate: action.finalizedStatus.finalizedDate,
      }
    case DELETE_VITAL_SUCCESS:
      return { ...state, vitalIds: R.without([action.vitalId], state.vitalIds) }
    case CREATE_ADDENDUM_SUCCESS:
      if (action.soapId !== state.id) {
        return state
      }

      return {
        ...state,
        addendumIds: [...state.addendumIds, action.addendumId],
      }
    case SOAPTypes.FETCH_USERS_ON_SOAP_COLLABORATION_SESSION:
      return { ...state, isFetchingUsersOnCollaboration: true }
    case SOAPTypes.FETCH_USERS_ON_SOAP_COLLABORATION_SESSION_SUCCESS:
      return {
        ...state,
        isFetchingUsersOnCollaboration: false,
        usersOnCollaborationSession: action.users,
      }
    case SOAPTypes.FETCH_USERS_ON_SOAP_COLLABORATION_SESSION_FAILURE:
      return {
        ...state,
        isFetchingUsersOnCollaboration: false,
        error: getErrorMessage(action.error),
      }
    case SOAPTypes.SET_MEDICAL_NOTES:
      return {
        ...state,
        medicalNotes: isEmptyParagraphOrString(action.notes)
          ? ''
          : action.notes,
      }
    case SOAPTypes.SET_DISCHARGE_NOTES:
      return {
        ...state,
        dischargeNotes: isEmptyParagraphOrString(action.notes)
          ? ''
          : action.notes,
      }
    case SOAPTypes.CLEAR_SOAP_ERROR:
      return { ...state, error: null }
    default:
      return state
  }
}

export default soap
export const getSOAP = (state: RootState): SoapState => state.soap
export const getSoapId = (state: RootState) => getSOAP(state).id
export const getSoapBusinessId = (state: RootState) => getSOAP(state).businessId
export const getIsSaving = (state: RootState) => getSOAP(state).isSaving
export const getLastSaveDate = (state: RootState) => getSOAP(state).lastSaveDate
export const getMedicalNotes = (state: RootState) => getSOAP(state).medicalNotes
export const getMedicalNotesCrc = (state: RootState) =>
  getSOAP(state).medicalNotesCrc
export const getIsMedicalNotesUpdating = (state: RootState) =>
  getSOAP(state).isMedicalNotesUpdating
export const getDischargeNotes = (state: RootState) =>
  getSOAP(state).dischargeNotes
export const getDischargeNotesCrc = (state: RootState) =>
  getSOAP(state).dischargeNotesCrc
export const getIsDischargeNotesUpdating = (state: RootState) =>
  getSOAP(state).isDischargeNotesUpdating
export const getTechsList = (state: RootState) => getSOAP(state).techs
export const getTechs = createSelector(
  getTechsList,
  getUsersMap,
  (techsList, usersMap) => R.props(techsList, usersMap),
)
export const getDoctorsList = (state: RootState) => getSOAP(state).doctors
export const getDoctors = createSelector(
  getDoctorsList,
  getUsersMap,
  (doctorsList, usersMap) => R.props(doctorsList, usersMap),
)
export const getTechId = (state: RootState) => getSOAP(state).techId
export const getDoctorId = (state: RootState) => getSOAP(state).doctorId
export const getRoom = (state: RootState) => getSOAP(state).room
export const getVitalIds = (state: RootState) => getSOAP(state).vitalIds
export const getVitalsCount = (state: RootState) => getSOAP(state).vitalsCount
export const getPatientId = (state: RootState) => getSOAP(state).patientId
export const getClientId = (state: RootState) => getSOAP(state).clientId
export const getFindingsState = (state: RootState) =>
  getSOAP(state).findingsState
export const getPendingFindingsState = (state: RootState) =>
  getSOAP(state).pendingFindingsState
export const getDiagnosesState = (state: RootState) =>
  getSOAP(state).diagnosesState
export const getError = (state: RootState) => getSOAP(state).error
export const getNotesValidationError = (state: RootState) =>
  getSOAP(state).notesValidationError
export const getAssignedDoctorValidationError = (state: RootState) =>
  getSOAP(state).assignedDoctorValidationError
export const getAppointment = (state: RootState) => getSOAP(state).appointment
export const getAppointmentId = (state: RootState) =>
  getAppointment(state)?.appointmentId
export const getInvoiceId = (state: RootState) => getSOAP(state).invoiceId
export const getSOAPisLoading = (state: RootState) => getSOAP(state).isLoading
export const getSOAPisFetching = (state: RootState) => getSOAP(state).isFetching
export const getSOAPisFetchingUsersOnCollaboration = (state: RootState) =>
  getSOAP(state).isFetchingUsersOnCollaboration
export const getSOAPisFetchingSoapOrders = (state: RootState) =>
  getSOAP(state).isFetchingSoapOrders
export const getSoapAlert = (state: RootState) => getSOAP(state).soapAlert
export const getTypes = (state: RootState) => getSOAP(state).types
export const getDiagnosesStaticList = (state: RootState) =>
  getTypes(state).diagnosesStaticList
export const getFindingsStaticList = (state: RootState) =>
  getTypes(state).findingsStaticList
export const getFindingToExpand = (state: RootState) =>
  getSOAP(state).findingToExpand
export const getIsFinalized = (state: RootState) => getSOAP(state).finalized
export const getFinalizedBy = (state: RootState) => getSOAP(state).finalizedBy
export const getFinalizedDate = (state: RootState) =>
  getSOAP(state).finalizedDate
export const getSoapPrimaryComplaint = (state: RootState) =>
  getSOAP(state).primaryComplaint
export const getFindingLogsCrc = (state: RootState) =>
  getSOAP(state).findingLogsCrc
export const getFindingLogsCrcValidationError = (state: RootState) =>
  getSOAP(state).findingLogsCrcValidationError
export const getIsExamFindingsLoading = (state: RootState) =>
  getSOAP(state).isExamFindingsLoading
export const getUsersOnCollaborationSession = (state: RootState) =>
  getSOAP(state).usersOnCollaborationSession

export const getIsCurrentContextSoap = createSelector(
  getCurrentBusinessId,
  getSoapBusinessId,
  getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  (currentBusinessId, businessId, sharingToggle) =>
    currentBusinessId === businessId || !sharingToggle,
)

export const getSoapVitals = createSelector(
  getPendingVital,
  getSoapId,
  getVitalIds,
  getVitalsMap,
  (pendingVital, soapId, vitalIds, vitalsMap) => {
    const pendingVitals =
      pendingVital && pendingVital.soapId === soapId ? [pendingVital] : []
    const multipleVitals = R.props(vitalIds, vitalsMap)
    return pendingVitals.concat(multipleVitals)
  },
)

export const getCurrentSoapVitals = createSelector(
  getSoapVitals,
  getSoapId,
  (vitals, currentSoapId) =>
    vitals.filter(({ soapId }) => soapId === currentSoapId),
)

export const getLastSoapVital = createSelector(
  getCurrentSoapVitals,
  (vitals) =>
    R.pickBy(
      R.compose(R.not, R.isNil),
      R.pickAll(R.pluck('id', vitalsTableConfig), vitals[0] || {}),
    ) as VitalValue,
)

export const isCurrentSOAPVitals = (state: RootState) =>
  getCurrentSoapVitals(state).length > 0
export const getShowAppointment = (state: RootState) =>
  [getDoctorId, getTechId, getRoom].some((f) => f(state))
export const getShowExamination = (state: RootState) =>
  Object.values(getFindingsState(state)).length > 0
export const getShowDiffDx = (state: RootState) =>
  Object.keys(getDiagnosesState(state)).length > 0
export const getShowWrapUp = (state: RootState) =>
  Boolean(getMedicalNotes(state) || getDischargeNotes(state))
export const getShowToDo = (state: RootState) =>
  getSoapTasksList(state).length > 0

export const getFiles = (state: RootState) => getSOAP(state).files
export const getFile = (id: string | Nil) =>
  createSelector(getFiles, (files) =>
    id ? R.find((file: SoapFile) => file.id === id, files) : undefined,
  )
export const getIsUploadingFile = (state: RootState) =>
  getSOAP(state).isUploadingFile
export const getIsAttachingDocuments = (state: RootState) =>
  getSOAP(state).isAttachingDocuments
export const getOrderFilters = (state: RootState) => getSOAP(state).orderFilters
export const getSoapStaffInfo = createSelector(getSOAP, (soapState) =>
  R.pick(['doctors', 'techs', 'doctorId', 'techId'], soapState),
)
export const getLastVitalMeasurement = R.curry(
  (measurement: VitalMeasurement, state) => {
    const matchingVital = getSoapVitals(state).find(
      (vital) => !R.isNil(vital[measurement]),
    )
    return matchingVital ? matchingVital[measurement]?.value : undefined
  },
)

export const getChat = (state: RootState) => getSOAP(state).chatInSoap
export const getSelectedConversationId = (state: RootState) =>
  getChat(state).conversationId

export const getPetParents = (state: RootState) => getSOAP(state).petParents
export const getSoapAddendumIds = (state: RootState) =>
  getSOAP(state).addendumIds
export const getSoapFinalizedLogs = (state: RootState) =>
  getSOAP(state).finalizedLogs
export const getSoapInvoicePosted = (state: RootState) =>
  getFinanceInvoiceIsPosted(getInvoiceId(state))(state)

export const getDocumentValidationError = (state: RootState) =>
  getSOAP(state).documentValidationError

export const getIsOrdersLocked = (state: RootState) =>
  getSOAP(state).ordersLocked
