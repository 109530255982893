import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import deepEqual from 'fast-deep-equal'
import {
  ButtonWithLoader,
  Nil,
  PhoneUtils,
  PuiTextField,
  PuiTheme,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import PhoneInput from '~/components/common/form-inputs/PhoneInput'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import { updateClient } from '~/store/actions/clients'
import { getClientIsLoading } from '~/store/reducers/clients'
import { getUser } from '~/store/reducers/users'
import { isFieldValuesChanged } from '~/utils'

const useStyles = makeStyles(
  () => ({
    button: {
      width: 220,
    },
  }),
  { name: 'EmergencyContact' },
)
export interface EmergencyContactHandle {
  getHasUnsavedChanges: () => void
  save: () => void
}
export interface EmergencyContactProps {
  clientId: string | Nil
  onOk?: () => void
  showTitle?: boolean
}

const EmergencyContact = forwardRef<
  EmergencyContactHandle,
  EmergencyContactProps
>(function EmergencyContact({ clientId, showTitle, onOk }, ref) {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const client = useSelector(getUser(clientId))
  const isLoading = useSelector(getClientIsLoading)
  const { t } = useTranslation(['Common', 'Clients'])

  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('sm'),
  )

  const isEdit =
    client?.emergencyContact && Object.keys(client?.emergencyContact).length > 0

  const { fields, validate } = useFields(
    [
      {
        name: 'firstName',
        label: t('Common:FIRST_NAME'),
        validators: ['required'],
        initialValue: client?.emergencyContact?.firstName || '',
      },
      {
        name: 'lastName',
        label: t('Common:LAST_NAME'),
        validators: ['required'],
        initialValue: client?.emergencyContact?.lastName || '',
      },
      {
        name: 'mobilePhone',
        label: `${t('Common:MOBILE_PHONE_NUMBER')}*`,
        validators: ['phoneRequired', 'phone'],
        initialValue: client?.emergencyContact?.mobilePhone || '',
      },
      {
        name: 'workPhone',
        label: t('Common:WORK_PHONE_NUMBER'),
        validators: ['phone'],
        initialValue: client?.emergencyContact?.workPhone || '',
      },
      {
        name: 'homePhone',
        label: t('Common:HOME_PHONE_NUMBER'),
        validators: ['phone'],
        initialValue: client?.emergencyContact?.homePhone || '',
      },
      {
        name: 'otherPhone',
        label: t('Common:OTHER_PHONE_NUMBER'),
        validators: ['phone'],
        initialValue: client?.emergencyContact?.otherPhone || '',
      },
    ],
    false,
  )

  const { firstName, lastName, mobilePhone, workPhone, homePhone, otherPhone } =
    fields

  const [closeAfterUpdate, setCloseAfterUpdate] = useState(false)

  const currentEmergencyContact = useMemo(
    () => client?.emergencyContact || {},
    [],
  )

  const goBack = () => navigate(-1)

  const handleNavigate = () => {
    if (isMobile) {
      goBack()
    }

    if (onOk) {
      onOk()
    }
  }

  useEffect(() => {
    if (closeAfterUpdate && !isLoading) {
      handleNavigate()
    }
  }, [closeAfterUpdate, isLoading])

  const addContact = () => {
    if (validate()) {
      const contact = {
        firstName: firstName.value,
        lastName: lastName.value,
        mobilePhone: PhoneUtils.parsePhoneNumber(mobilePhone.value),
        workPhone: PhoneUtils.parsePhoneNumber(workPhone.value),
        homePhone: PhoneUtils.parsePhoneNumber(homePhone.value),
        otherPhone: PhoneUtils.parsePhoneNumber(otherPhone.value),
      }

      if (deepEqual(currentEmergencyContact, contact)) {
        handleNavigate()
      } else {
        setCloseAfterUpdate(true)
        if (clientId) {
          dispatch(updateClient({ id: clientId, emergencyContact: contact }))
        }
      }
    }
  }

  useImperativeHandle(ref, () => ({
    getHasUnsavedChanges: () => isFieldValuesChanged(fields),
    save: addContact,
  }))

  return (
    <Grid container spacing={2}>
      {showTitle && (
        <Grid item xs={12}>
          <Text variant="hero2">
            {isEdit
              ? t('Clients:EMERGENCY_CONTACT.TITLE_EDIT')
              : t('Clients:EMERGENCY_CONTACT.TITLE_NEW')}
          </Text>
        </Grid>
      )}
      <Grid item xs={6}>
        <PuiTextField
          field={firstName}
          inputProps={{ maxLength: 100 }}
          label={`${firstName.label}*`}
        />
      </Grid>
      <Grid item xs={6}>
        <PuiTextField
          field={lastName}
          inputProps={{ maxLength: 100 }}
          label={`${lastName.label}*`}
        />
      </Grid>
      <Grid item xs={6}>
        <PhoneInput field={mobilePhone} />
      </Grid>
      <Grid item xs={6}>
        <PhoneInput field={workPhone} />
      </Grid>
      <Grid item xs={6}>
        <PhoneInput field={homePhone} />
      </Grid>
      <Grid item xs={6}>
        <PhoneInput field={otherPhone} />
      </Grid>
      <Grid item xs={12}>
        <RequiredFieldsNotice />
      </Grid>
      <Grid item mt={5}>
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading}
          loading={isLoading}
          onClick={addContact}
        >
          {isEdit ? t('Common:SAVE_ACTION') : t('Common:ADD_ACTION')}
        </ButtonWithLoader>
      </Grid>
    </Grid>
  )
})

export default EmergencyContact
