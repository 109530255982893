import React from 'react'
import { DragIndicator as DragIndicatorIcon } from '@mui/icons-material'
import { Grid, TableCell } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import { formatWidgetDate } from '~/components/dashboard/landing/widgets/widgetUtils'
import {
  FINANCE_CELL_WIDTH,
  FINANCE_TABLE_CELL_HEIGHT,
} from '~/constants/financeTable'
import { InvoiceLineItem } from '~/types'

const { CREATION_DATE } = FINANCE_CELL_WIDTH

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    creationDate: {
      opacity: '50%',
    },
    creationDateCell: {
      minWidth: CREATION_DATE,
      width: CREATION_DATE,
    },
    icon: {
      color: theme.colors.lowAccentText,
    },
  }),
  { name: 'FinanceItemCreationDateCellBody' },
)

export interface FinanceItemCreationDateCellBodyProps {
  isGrouped: boolean
  isReadOnly: boolean
  item: InvoiceLineItem
  nested?: boolean
  tableCellClassName: string
}

const FinanceItemCreationDateCellBody = ({
  isGrouped,
  item,
  nested,
  tableCellClassName,
  isReadOnly,
}: FinanceItemCreationDateCellBodyProps) => {
  const classes = useStyles()

  return isGrouped ? (
    <>
      <Grid
        container
        alignItems="center"
        className={classNames(tableCellClassName, classes.creationDateCell)}
        height={FINANCE_TABLE_CELL_HEIGHT}
        wrap="nowrap"
      >
        {!isReadOnly && <DragIndicatorIcon className={classes.icon} />}
        {item.items?.[0].creationDate && (
          <Text className={classes.creationDate} pl={0.75} variant="body3">
            {formatWidgetDate(item.items[0].creationDate, true)}
          </Text>
        )}
      </Grid>
    </>
  ) : (
    <TableCell
      className={classNames(tableCellClassName, classes.creationDateCell)}
    >
      <Grid container alignItems="center" wrap="nowrap">
        {!nested && !isReadOnly && (
          <DragIndicatorIcon className={classes.icon} />
        )}
        {item.creationDate && (
          <Text
            className={classes.creationDate}
            ml={nested ? 2 : 0}
            pl={nested ? 2 : 0.75}
            variant="body3"
          >
            {formatWidgetDate(item.creationDate, true)}
          </Text>
        )}
      </Grid>
    </TableCell>
  )
}

export default FinanceItemCreationDateCellBody
