import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, PuiTheme, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    notificationsCount: {
      textAlign: 'center',
      position: 'absolute',
      backgroundColor: theme.colors.important,
      borderRadius: 8,
      color: theme.colors.badgeColor,
      fontSize: '0.9rem',
      padding: 2,
      minWidth: 16,
      right: -4,
      top: -4,
    },
  }),
  { name: 'NotificationCountBadge' },
)

export interface NotificationCountBadgeProps {
  classes?: ClassesType<typeof useStyles>
  notificationCount?: number
}

const NotificationCountBadge = ({
  notificationCount = 0,
  classes: classesProp,
}: NotificationCountBadgeProps) => {
  const classes = useStyles({ classes: classesProp })

  if (!notificationCount || notificationCount <= 0) {
    return null
  }

  return (
    <Text className={classes.notificationsCount}>
      {notificationCount > 99 ? '99+' : notificationCount}
    </Text>
  )
}

export default NotificationCountBadge
