import { ColorVariant } from './colors'

export const EstimateState = {
  DRAFT: 'Draft',
  DECLINED: 'Declined',
  APPROVED: 'Approved',
  EXPIRED_DRAFT: 'Expired-Draft',
  EXPIRED_APPROVED: 'Expired-Approved',
  EXPIRED_DECLINED: 'Expired-Declined',
}

export const RefundInvoiceState = {
  PENDING: 'Pending',
  PARTIALLY_REFUNDED: 'Partially Refunded',
  REFUNDED: 'Refunded',
  VOIDED: 'Voided',
}

export const EstimateStateColor = {
  [EstimateState.DRAFT]: ColorVariant.IMPORTANT,
  [EstimateState.DECLINED]: ColorVariant.DISABLED,
  [EstimateState.APPROVED]: ColorVariant.SUCCESS,
  [EstimateState.EXPIRED_DRAFT]: ColorVariant.ERROR,
  [EstimateState.EXPIRED_APPROVED]: ColorVariant.ERROR,
  [EstimateState.EXPIRED_DECLINED]: ColorVariant.ERROR,
}

export const RefundInvoiceStateColor = {
  [RefundInvoiceState.PENDING]: ColorVariant.IMPORTANT,
  [RefundInvoiceState.PARTIALLY_REFUNDED]: ColorVariant.IMPORTANT,
  [RefundInvoiceState.VOIDED]: ColorVariant.DISABLED,
}

export enum BillableInvoiceType {
  INVENTORY = 'INVENTORY',
  PRESCRIPTION = 'PRESCRIPTION',
  PROCEDURE = 'PROCEDURE',
  LAB_TEST = 'LAB_TEST',
}
