import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import CropIcon from '@mui/icons-material/Crop'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { DocumentFile, FileTemplate, PuiTheme } from '@pbt/pbt-ui-components'

import FileInput, { FileInputHandle } from '../inputs/file-input/FileInput'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      position: 'relative',
    },
    cropButtonContainer: {
      position: 'absolute',
      bottom: theme.spacing(-8),
      right: 0,
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above', 2),
      marginLeft: theme.spacing(3),
    },
    cropButton: {
      backgroundColor: theme.colors.iconBackground,
      padding: theme.spacing(1),
    },
    cropButtonActive: {
      backgroundColor: theme.colors.filterBorderColor,
    },
    image: {
      maxWidth: '100%',
      height: 'auto',
    },
    imageFullscreen: {
      width: `calc(100vw - 100px)`,
      height: 'auto',
    },
  }),
  { name: 'ImagePreview' },
)

export interface ImagePreviewHandle extends FileInputHandle {
  getIsCropping: () => boolean
}

interface ImagePreviewProps {
  file: DocumentFile['file']
  fullScreen?: boolean
  onFileChange: (value: Blob) => void
  onFileSelected?: (file: FileTemplate) => void
}

const ImagePreview = forwardRef<ImagePreviewHandle, ImagePreviewProps>(
  function ImagePreview(
    { file, fullScreen, onFileChange, onFileSelected, ...rest },
    ref,
  ) {
    const classes = useStyles()

    const [cropperEnabled, setCropperEnabled] = useState(false)
    const [fileData, setFileData] = useState<string>()

    const fileInputRef = useRef<FileInputHandle>(null)

    useEffect(() => {
      setFileData(file?.data)
    }, [file?.data])

    const onFileReady = ({ blob, data }: { blob: Blob; data?: string }) => {
      if (onFileChange) {
        onFileChange(blob)
      }
      setFileData(data)
    }

    const onCropButtonClick = () => {
      if (cropperEnabled) {
        fileInputRef.current?.crop()
      } else {
        setFileData(file.data)
      }

      setCropperEnabled(!cropperEnabled)
    }

    const isUploaded = Boolean(file?.fileUrl)

    const getIsCropping = () => {
      if (cropperEnabled) {
        fileInputRef.current?.crop()
        setCropperEnabled(false)
        return true
      }
      return false
    }

    useImperativeHandle(ref, () => ({
      getIsCropping,
      crop: () => fileInputRef.current?.crop(),
      open: () => fileInputRef.current?.open(),
    }))

    return (
      <Grid container item className={classes.root} justifyContent="center">
        {isUploaded ? (
          <Grid item>
            <img
              alt={file.fileName || undefined}
              className={fullScreen ? classes.imageFullscreen : classes.image}
              src={file.fileUrl || undefined}
            />
          </Grid>
        ) : (
          <>
            <FileInput
              forceButtonsHidden
              onlyImages
              disableCropper={!cropperEnabled}
              ref={fileInputRef}
              source={fileData}
              viewMode={1}
              onFileReady={onFileReady}
              onFileSelected={onFileSelected}
              {...rest}
            />
            <Grid item className={classes.cropButtonContainer}>
              <IconButton
                className={classNames(classes.cropButton, {
                  [classes.cropButtonActive]: cropperEnabled,
                })}
                size="large"
                onClick={onCropButtonClick}
              >
                <CropIcon />
              </IconButton>
            </Grid>
          </>
        )}
      </Grid>
    )
  },
)

export default ImagePreview
