import React, { useEffect, useRef, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { moment, PuiTheme } from '@pbt/pbt-ui-components'

import DayTimeColumn from '~/components/dashboard/soap/to-do/DayTimeColumn'
import { dateToIntervals, isToday, isWithinTimeInterval } from '~/utils/time'

import ScrollableColumns, {
  ScrollableColumnsHandle,
  ScrollableColumnsProps,
} from './ScrollableColumns'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    scrollableColumnsHeader: {
      height: theme.spacing(4),
      paddingRight: theme.spacing(1),
    },
  }),
  { name: 'DayScrollableColumns' },
)

export interface DayScrollableColumnsProps
  extends Omit<ScrollableColumnsProps, 'ColumnHeaderComponent'> {
  date?: string
  hasLoaded: boolean
}

const DayScrollableColumns = ({
  hasLoaded,
  date,
  ...rest
}: DayScrollableColumnsProps) => {
  const classes = useStyles()

  const scrollableColumnsRef = useRef<ScrollableColumnsHandle>(null)

  const [hasScrolledForThisDate, setHasScrolledForThisDate] = useState(false)

  const startDate = moment(date).startOf('day')
  const endDate = moment(date).endOf('day')
  const timeColumns = dateToIntervals(startDate, endDate)

  useEffect(() => {
    setHasScrolledForThisDate(false)
  }, [date])

  useEffect(() => {
    if (scrollableColumnsRef.current && hasLoaded && !hasScrolledForThisDate) {
      if (isToday(startDate)) {
        const columnIndex = R.findIndex(
          (col) => isWithinTimeInterval(moment(), col),
          timeColumns,
        )

        scrollableColumnsRef.current.scrollToColumnIndex(columnIndex - 1, false)

        setHasScrolledForThisDate(true)
      }
    }
  }, [hasLoaded])

  return (
    <ScrollableColumns
      ColumnHeaderComponent={DayTimeColumn}
      classes={{
        header: classes.scrollableColumnsHeader,
      }}
      columns={timeColumns}
      ref={scrollableColumnsRef}
      {...rest}
    />
  )
}

export default DayScrollableColumns
