import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  AppointmentCommunicationTemplate,
  Business,
  CircularProgressOverlay,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import AutomaticCommunicationBoopMessagePreview from '~/components/common/automaticCommunication/AutomaticCommunicationBoopMessagePreview'
import AutomaticCommunicationEmailPreview from '~/components/common/automaticCommunication/AutomaticCommunicationEmailPreview'
import AutomaticCommunicationTextMessagePreview from '~/components/common/automaticCommunication/AutomaticCommunicationTextMessagePreview'
import {
  fetchAppointmentConfirmationPreview,
  getAutomaticCommunicationsIsFetchingPreview,
  getAutomaticCommunicationsMessagePreview,
  getAutomaticCommunicationsTextMessagePreview,
} from '~/store/duck/automaticCommunications'
import { useBoopEnabled } from '~/utils/boop'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    emailPreview: {
      marginRight: theme.spacing(2),
      zoom: 0.7,
    },
  }),
  { name: 'AutomaticCommunicationPreview' },
)

export interface AutomaticCommunicationPreviewProps {
  automaticCommunication: AppointmentCommunicationTemplate
  business: Business
  className?: string
}

const AppointmentAutomaticCommunicationPreview = ({
  business,
  automaticCommunication,
  className,
}: AutomaticCommunicationPreviewProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const messagePreview = useSelector(getAutomaticCommunicationsMessagePreview)
  const textMessagePreview = useSelector(
    getAutomaticCommunicationsTextMessagePreview,
  )
  const isFetchingPreview = useSelector(
    getAutomaticCommunicationsIsFetchingPreview,
  )

  useEffect(() => {
    if (automaticCommunication) {
      dispatch(
        fetchAppointmentConfirmationPreview({
          template: automaticCommunication,
        }),
      )
    }
  }, [automaticCommunication])

  const showBoopPreview = useBoopEnabled(business)

  return (
    <Grid
      container
      item
      className={classNames(className, classes.root)}
      wrap="nowrap"
    >
      <CircularProgressOverlay
        open={isFetchingPreview}
        preloaderText={t('Common:LOADING')}
      />
      <Grid item xs mr={5}>
        <AutomaticCommunicationEmailPreview template={messagePreview} />
      </Grid>
      <Grid item>
        <AutomaticCommunicationTextMessagePreview
          textTemplate={textMessagePreview}
        />
      </Grid>
      {showBoopPreview && (
        <Grid item>
          <AutomaticCommunicationBoopMessagePreview
            business={business}
            textTemplate={textMessagePreview}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default AppointmentAutomaticCommunicationPreview
