/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Hidden, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  Business,
  FilterLabel,
  InfiniteLoaderList,
  NoResultsForFiltersLabel,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import { getMultipleBusinesses } from '~/store/reducers/businesses'
import { TableFilter } from '~/types'

import PracticesStatusFilter from './PracticesStatusFilter'
import PracticesTypeFilter from './PracticesTypeFilter'
import PracticeTableGroupRow from './PracticeTableGroupRow'
import PracticeTableRow from './PracticeTableRow'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    container: {
      userSelect: 'none',
      cursor: 'pointer',
      backgroundColor: theme.colors.tableBackground,
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'PracticeTableRowWrapper' },
)

interface PracticeTableRowWrapperProps {
  data: {
    isMobile: boolean
    onClick?: (business: Business) => void
    rowFocused?: string
  }
  highlightProps: {
    internalName?: string[]
    practiceName?: string[]
  }
  item: Partial<Business>
}

const PracticeTableRowWrapper = ({
  item: itemProp,
  data,
  highlightProps,
}: PracticeTableRowWrapperProps) => {
  const item = itemProp || {}
  const classes = useStyles()
  const isLoading = R.isEmpty(item)
  const practice = isLoading ? { children: [], id: '' } : item

  const children = useSelector(getMultipleBusinesses(practice?.children || []))

  const isFocusedRow = data.rowFocused && data.rowFocused === practice?.id

  if (practice.children && practice.children.length > 0) {
    return (
      <Grid container className={classes.container}>
        <PracticeTableGroupRow
          children={children}
          highlightProps={highlightProps}
          isLoading={isLoading}
          isSelectedRow={Boolean(isFocusedRow)}
          practice={practice as Business}
          {...data}
        />
      </Grid>
    )
  }

  return (
    <Grid container className={classes.container}>
      <PracticeTableRow
        highlightProps={highlightProps}
        isLoading={isLoading}
        practice={practice as Business}
        {...data}
      />
    </Grid>
  )
}

const useTableStyles = makeStyles(
  (theme: PuiTheme) => ({
    expandedColumn: {
      maxWidth: '75%',
      transition: theme.transitions.create(['max-width', 'flex-basis'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    collapsedColumn: {
      maxWidth: '0%',
      transition: theme.transitions.create(['max-width', 'flex-basis'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    expandedMainColumn: {
      transition: theme.transitions.create(['max-width', 'flex-basis'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen / 2,
        delay: 100,
      }),
    },
    noOverflow: {
      overflow: 'hidden',
      height: '100%',
    },
    mainContainer: {
      height: '100%',
    },
  }),
  { name: 'PracticesTable' },
)

interface PracticesTableProps {
  filters?: Record<string, TableFilter>
  hasSelectedFilters?: boolean
  highlightProps: {
    internalName?: string[]
    practiceName?: string[]
  }
  isItemLoaded: (index: number) => boolean
  isLoading?: boolean
  loadMoreItems: (startIndex: number, endIndex: number) => void
  onApplyFilter: (filter: string, value: TableFilter) => void
  onClearFilters: () => void
  onItemClick?: (business: Business) => void
  practices: Business[]
  selectedItemId?: string
  totalCount: number
}

const PracticesTable = ({
  practices,
  highlightProps,
  selectedItemId,
  onItemClick,
  isItemLoaded,
  loadMoreItems,
  totalCount,
  hasSelectedFilters,
  isLoading,
  filters,
  onApplyFilter,
  onClearFilters,
}: PracticesTableProps) => {
  const classes = useTableStyles()
  const { t } = useTranslation('Common')
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )

  const [openFilter, setOpenFilter] = useState<string>()

  const onFilterClose = () => {
    setOpenFilter(undefined)
  }

  const onFilterClick = (filter: string) => {
    setOpenFilter(openFilter === filter ? undefined : filter)
  }

  const data = {
    onClick: onItemClick,
    rowFocused: selectedItemId,
    isMobile,
  }

  const isExpanded = !R.isNil(selectedItemId)

  return (
    <Grid
      container
      className={classes.mainContainer}
      direction="column"
      wrap="nowrap"
    >
      <Hidden mdDown>
        <Grid container px={2} py={1}>
          <Grid
            container
            item
            alignItems="center"
            className={classes.noOverflow}
          >
            <Grid
              container
              item
              className={classNames({
                [classes.expandedMainColumn]: !isExpanded,
              })}
              md={isExpanded ? 12 : 3}
              wrap="nowrap"
            >
              <Grid container item md alignItems="center">
                <Text noWrap strong align="left" variant="lowAccent2">
                  {t('Common:PRACTICE')}
                </Text>
              </Grid>
            </Grid>
            <Grid
              container
              item
              className={classNames({
                [classes.collapsedColumn]: isExpanded,
                [classes.expandedColumn]: !isExpanded,
              })}
              wrap="nowrap"
            >
              <Grid container item alignItems="center" md={3}>
                <Text noWrap strong align="left" variant="lowAccent2">
                  {t('Common:WEBSITE')}
                </Text>
              </Grid>
              <Grid container item alignItems="center" md={2}>
                <Text noWrap strong align="left" variant="lowAccent2">
                  {t('Common:PHONE_NUMBER')}
                </Text>
              </Grid>
              <Grid container item alignItems="center" md={3}>
                <Text noWrap strong align="left" variant="lowAccent2">
                  {t('Common:ADDRESS')}
                </Text>
              </Grid>
              <Grid container item md={2}>
                <FilterLabel
                  FilterComponent={PracticesTypeFilter}
                  filter="type"
                  filterValue={filters?.type}
                  label={t('Common:TYPE_ONE')}
                  open={openFilter === 'type'}
                  sublabel={t('Common:TYPE_ONE')}
                  onApplyFilter={onApplyFilter}
                  onFilterClick={onFilterClick}
                  onFilterClose={onFilterClose}
                />
              </Grid>
              <Grid container item md={2}>
                <FilterLabel
                  FilterComponent={PracticesStatusFilter}
                  filter="status"
                  filterValue={filters?.status}
                  label={t('Common:STATUS')}
                  open={openFilter === 'status'}
                  sublabel={t('Common:STATUS')}
                  onApplyFilter={onApplyFilter}
                  onFilterClick={onFilterClick}
                  onFilterClose={onFilterClose}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      {hasSelectedFilters && practices.length === 0 && !isLoading ? (
        <NoResultsForFiltersLabel
          filters={filters}
          onClearFilters={onClearFilters}
        />
      ) : (
        <InfiniteLoaderList
          isItemLoaded={isItemLoaded}
          itemCount={totalCount || practices.length}
          itemData={practices}
          loadMoreItems={loadMoreItems}
        >
          {(item) => (
            <PracticeTableRowWrapper
              data={data}
              highlightProps={highlightProps}
              item={item}
            />
          )}
        </InfiniteLoaderList>
      )}
    </Grid>
  )
}

export default PracticesTable
