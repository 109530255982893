import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Radio, TableCell, TableRow, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { LanguageUtils, PuiTheme } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { PrescriptionWorkflowType } from '~/constants/PrescriptionWorkflow'
import {
  useGetVariationDescriptionString,
  useIsGlobalInventoryItem,
} from '~/store/hooks/orders'
import { useVariationWeightString } from '~/store/hooks/prescriptions'
import {
  getDrugDeliveryMethod,
  getDrugStrengthColor,
  getDrugStrengthUnit,
  getFeatureToggle,
  getInventoryProductSizeUnit,
  getProductForm,
} from '~/store/reducers/constants'
import { OrderDetails, Prescription } from '~/types'
import { getManualInputSelectValue } from '~/utils'
import { getWorkflowType } from '~/utils/prescription'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    row: {
      height: 48,
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
    cell: {
      padding: theme.spacing(1, 2),
      borderBottom: 'none',
      color: theme.colors.secondaryText,
      fontSize: '1.6rem',
    },
    radioCell: {
      width: 40,
      borderRight: theme.constants.tableBorder,
      padding: 0,
    },
    catalogIconCell: {
      width: 40,
    },
    catalogIcon: {
      width: 16,
      height: 16,
      borderRadius: 8,
    },
    inMyCatalogIcon: {
      backgroundColor: theme.colors.markerHighlighted,
    },
    notInMyCatalogIcon: {
      border: theme.constants.filterBorder,
      backgroundColor: theme.colors.tableBackground,
    },
  }),
  { name: 'VariationsTableRow' },
)

export interface VariationsTableRowProps {
  activeWorkflow?: PrescriptionWorkflowType
  checked: boolean
  disabled?: boolean
  onVariationChange: (priceVariation: OrderDetails) => void
  prescription: Prescription
  priceVariation: OrderDetails
  showCatalogIcon: boolean
}

const VariationsTableRow = ({
  activeWorkflow,
  disabled,
  prescription,
  checked,
  priceVariation,
  showCatalogIcon,
  onVariationChange,
}: VariationsTableRowProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Tooltips')

  const DrugStrengthUnit = useSelector(getDrugStrengthUnit)
  const ProductForm = useSelector(getProductForm)
  const DrugStrengthColor = useSelector(getDrugStrengthColor)
  const DrugDeliveryMethod = useSelector(getDrugDeliveryMethod)
  const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const isGlobaItem = useIsGlobalInventoryItem(prescription)

  const { isOrder, isChewyReactiveRx } = getWorkflowType(activeWorkflow)
  const isOnlyOutsidePharmacyAllowed =
    isChewyReactiveRx || (isOrder && !priceVariation.variation)
  const variation =
    priceVariation.variation ||
    priceVariation[
      isFoodCatalogEnabled ? 'globalVariationMapping' : 'globalVariation'
    ]
  const {
    name,
    flavor,
    strength,
    strengthCombo,
    strengthUnitsId,
    formId,
    customForm,
    deliveryMethodId,
    strengthColorId,
    saleUnitOfMeasure,
    perPackageAmount,
    perPackageUnitsId,
  } = variation
  const strengthStr = `${
    strength || ''
  } ${LanguageUtils.getConstantTranslatedName(
    strengthUnitsId,
    DrugStrengthUnit,
  )}`.trim()
  const formStr = getManualInputSelectValue(ProductForm, formId, customForm)
  const routeStr = LanguageUtils.getConstantTranslatedName(
    deliveryMethodId,
    DrugDeliveryMethod,
  )
  const strengthColorStr = LanguageUtils.getConstantTranslatedName(
    strengthColorId,
    DrugStrengthColor,
  )
  const weightStr = useVariationWeightString(variation)
  const perPackageUnits = LanguageUtils.getConstantTranslatedName(
    perPackageUnitsId,
    InventoryProductSizeUnit,
    '',
  )

  const isExposeFlavorEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EXPOSE_FLAVOR),
  )
  const nameWithFlavor = `${name} ${flavor ?? ''}`.trim()
  const globalItemVariationDescriptionStringWithFlavor =
    useGetVariationDescriptionString(variation, flavor ? `(${flavor})` : '')

  const globalItemVariationDescriptionStringNoFlavor = LanguageUtils.capitalize(
    [
      strengthStr || strengthCombo,
      formStr,
      routeStr,
      strengthColorStr,
      weightStr,
      perPackageAmount,
      perPackageUnits,
      saleUnitOfMeasure,
    ]
      .filter(Boolean)
      .join(' ')
      .trim(),
  )

  const variationPriceString = isGlobaItem
    ? isExposeFlavorEnabled
      ? globalItemVariationDescriptionStringWithFlavor || nameWithFlavor
      : globalItemVariationDescriptionStringNoFlavor || name
    : isExposeFlavorEnabled
    ? nameWithFlavor
    : name

  return (
    <TableRow className={classes.row}>
      <Tooltip
        placement="right-end"
        title={
          isOnlyOutsidePharmacyAllowed && !isChewyReactiveRx
            ? t('Tooltips:ONLY_AVAILABLE_OUTSIDE_PHARMACY_WORKFLOW')
            : ''
        }
      >
        <TableCell className={classNames(classes.cell, classes.radioCell)}>
          <Radio
            disableRipple
            checked={checked}
            disabled={disabled || isOnlyOutsidePharmacyAllowed}
            onChange={() => onVariationChange(priceVariation)}
          />
        </TableCell>
      </Tooltip>
      <TableCell className={classes.cell}>{variationPriceString}</TableCell>
      {showCatalogIcon && (
        <TableCell
          className={classNames(classes.cell, classes.catalogIconCell)}
        >
          <Tooltip
            placement="right-end"
            title={
              priceVariation.variation
                ? t('Tooltips:IN_CATALOG')
                : t('Tooltips:EXTERNAL')
            }
          >
            <div
              className={classNames(
                classes.catalogIcon,
                priceVariation.variation
                  ? classes.inMyCatalogIcon
                  : classes.notInMyCatalogIcon,
              )}
            />
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  )
}

export default VariationsTableRow
