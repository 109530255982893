import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ControlButtonGroup,
  ControlButtonGroupName,
  LinkButton,
  Nil,
  PuiTheme,
  StateLabel,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import {
  CancellableLabOrderStates,
  DeletableLabOrderStates,
  LabOrdersStatus,
  orderHasResults,
} from '~/constants/SOAPStates'
import {
  getFeatureToggle,
  getLabOrderStatuses,
} from '~/store/reducers/constants'
import { hasButtons } from '~/utils/orderUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      height: 56,
    },
    stateLabel: {
      padding: theme.spacing(0, 0.5),
      fontSize: '1.6rem',
      marginLeft: theme.spacing(2),
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    button: {
      margin: theme.spacing(0, 2),
    },
    importantButton: {
      width: 150,
      height: 40,
      margin: theme.spacing(0, 2),

      backgroundColor: theme.colors.important,
      color: theme.colors.tableBackground,

      transition:
        'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
      boxShadow: 'none',
      '&&&&:hover': {
        boxShadow:
          'box-shadow: 1px 1px 2px 0 rgba(60,56,56,0.15), 0 1px 4px 0 rgba(76,73,73,0.12)',
        backgroundColor: '#EF7605',
      },
      '&&&&:active': {
        boxShadow: 'box-shadow: 0 4px 23px 0 rgba(0,0,0,0.12)',
        backgroundColor: '#CF6300',
      },
    },
    controlButtonGroup: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingRight: theme.spacing(1),
      minWidth: 130,
    },
  }),
  { name: 'LabOrderStatusRow' },
)

export interface LabOrderStatusRowProps {
  cancellable?: boolean
  onCancelOrder?: () => void
  onEmailResult?: () => void
  onFinalizeOrder?: () => void
  onPrintLabel: () => void
  onPrintOrPreview?: (isResult: boolean, shouldPrint: boolean) => void
  showOrderPrint?: boolean
  showPreview?: boolean
  showResultsPreview?: boolean
  showResultsPrint?: boolean
  statusId: string | Nil
}

const LabOrderStatusRow = ({
  cancellable = true,
  showPreview = true,
  showResultsPreview = true,
  showResultsPrint = true,
  showOrderPrint = true,
  onCancelOrder,
  onFinalizeOrder,
  onPrintLabel,
  onPrintOrPreview,
  onEmailResult,
  statusId,
}: LabOrderStatusRowProps) => {
  const classes = useStyles()
  const isDeleteFinalizedLabTestsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DELETE_FINALIZED_LAB_TESTS),
  )
  const LabOrderStatusList = useSelector(getLabOrderStatuses)
  const status = Utils.getConstantName(statusId, LabOrderStatusList)
  const { t } = useTranslation('Common')

  const orderCancellable =
    cancellable && CancellableLabOrderStates.includes(status)
  const orderDeletable = cancellable && DeletableLabOrderStates.includes(status)

  const labOrderPreviewButtons = [
    showOrderPrint && {
      name: ControlButtonGroupName.PRINT,
      onClick: () => {
        if (onPrintOrPreview) {
          onPrintOrPreview(false, true)
        }
      },
    },
    showPreview && {
      name: ControlButtonGroupName.PREVIEW,
      onClick: () => {
        if (onPrintOrPreview) {
          onPrintOrPreview(false, false)
        }
      },
    },
  ]

  const labResultsPreviewButtons = [
    showResultsPrint && {
      name: ControlButtonGroupName.PRINT,
      onClick: () => {
        if (onPrintOrPreview) {
          onPrintOrPreview(true, true)
        }
      },
    },
    showResultsPrint && {
      name: ControlButtonGroupName.EMAIL,
      onClick: () => {
        if (onEmailResult) {
          onEmailResult()
        }
      },
    },
    showResultsPreview && {
      name: ControlButtonGroupName.PREVIEW,
      onClick: () => {
        if (onPrintOrPreview) {
          onPrintOrPreview(true, false)
        }
      },
    },
  ]

  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      justifyContent="space-between"
    >
      <StateLabel
        className={classes.stateLabel}
        completed={status === LabOrdersStatus.COMPLETED}
        warning={status === LabOrdersStatus.CREATED}
      >
        {status}
      </StateLabel>
      <Grid item className={classes.buttonContainer}>
        {onPrintOrPreview && hasButtons(labOrderPreviewButtons) && (
          <Grid item className={classes.controlButtonGroup}>
            <Text variant="body2">{t('Common:ORDER')}:</Text>
            <ControlButtonGroup buttons={labOrderPreviewButtons} />
          </Grid>
        )}
        {orderHasResults(status) &&
          onPrintOrPreview &&
          hasButtons(labResultsPreviewButtons) && (
            <Grid item className={classes.controlButtonGroup}>
              <Text variant="body2">{t('Common:RESULTS')}:</Text>
              <ControlButtonGroup buttons={labResultsPreviewButtons} />
            </Grid>
          )}
        {isDeleteFinalizedLabTestsEnabled
          ? orderDeletable && (
              <Grid item className={classes.button}>
                <LinkButton onClick={onCancelOrder}>
                  {t('Common:DELETE_ORDER')}
                </LinkButton>
              </Grid>
            )
          : orderCancellable && (
              <Grid item className={classes.button}>
                <LinkButton onClick={onCancelOrder}>
                  {t('Common:CANCEL_ORDER')}
                </LinkButton>
              </Grid>
            )}

        {status === LabOrdersStatus.CREATED && (
          <Grid item>
            <Fab
              className={classes.importantButton}
              color="inherit"
              type="button"
              variant="extended"
              onClick={onFinalizeOrder}
            >
              {t('Common:FINALIZE_ACTION')}
            </Fab>
          </Grid>
        )}
        {onPrintLabel && (
          <Grid item className={classes.button}>
            <LinkButton onClick={onPrintLabel}>
              {t('Common:PRINT_LABEL')}
            </LinkButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default LabOrderStatusRow
