import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { LanguageUtils, Nil, PuiTheme } from '@pbt/pbt-ui-components'

import MarketplaceDeactivatedLink from '~/components/dashboard/admin/marketplace/MarketplaceDeactivatedLink'
import { getBusinessSoapWidgets } from '~/store/reducers/businesses'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    span: {
      '&:not(:last-child)::after': {
        content: "',\\00a0'",
      },
    },
    deactivated: {
      color: theme.colors.lowAccentText,
      display: 'contents',
    },
  }),
  { name: 'SoapWidgetsTableCell' },
)

export interface SoapWidgetsTableCellProps {
  active: boolean
  id: string
  marketplaceId: string | Nil
}

const SoapWidgetsTableCell = ({
  active,
  id,
  marketplaceId,
}: SoapWidgetsTableCellProps) => {
  const classes = useStyles()

  const BusinessSoapWidgets = useSelector(getBusinessSoapWidgets)!

  const deactivatedMarketplace = !active && marketplaceId

  return (
    <span
      className={classNames(classes.span, {
        [classes.deactivated]: deactivatedMarketplace,
      })}
      id="soap-template-tab-widget-names"
    >
      {deactivatedMarketplace ? (
        <MarketplaceDeactivatedLink id={id} marketplaceId={marketplaceId} />
      ) : (
        LanguageUtils.getConstantTranslatedName(id, BusinessSoapWidgets)
      )}
    </span>
  )
}

export default SoapWidgetsTableCell
