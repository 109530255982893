import React, { useState } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { FilterAlt } from '@mui/icons-material'
import { Badge, Box, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import { TableFilter } from '~/types'

import { PuiDataTableColumn } from './puiDataTableType'

export const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    badge: {
      '& .MuiBadge-badge': {
        minWidth: theme.spacing(2),
        width: theme.spacing(2),
        height: theme.spacing(2),
        top: theme.spacing(1),
        right: theme.spacing(0.5),
      },
    },
    filterButton: {
      color: theme.colors.lowAccentText,
    },
  }),
  { name: 'PuiDataTableColumnFilter' },
)

export interface PuiDataTableColumnFilterProps {
  anchorRef: React.RefObject<HTMLDivElement | null>
  columnLabel: string
  filter: PuiDataTableColumn['filter']
}

const PuiDataTableColumnFilter = ({
  anchorRef,
  columnLabel,
  filter,
}: PuiDataTableColumnFilterProps) => {
  const classes = useStyles()

  const { t } = useTranslation('Common')

  const {
    Component,
    iconClassName,
    onApplyFilter,
    onRemoveFilter,
    initialFilter,
    withFilterCounter,
  } = filter || {}

  const [isFilterOpened, setIsFilterOpened] = useState(false)
  const [filterValue, setFilterValue] = useState<any>(initialFilter)

  const handleClose = () => {
    setIsFilterOpened(false)
  }

  const handleChange = ({ value }: TableFilter<any>) => {
    setFilterValue(value)
    onApplyFilter?.(value)
  }

  const handleClear = () => {
    setFilterValue([])
    onRemoveFilter?.()
  }

  return (
    <Box ref={anchorRef}>
      <IconButton
        aria-label={t('Common:FILTERS')}
        className={classNames(classes.filterButton, iconClassName)}
        onClick={() => setIsFilterOpened(!isFilterOpened)}
      >
        <>
          <Dotdotdot clamp={2}>
            <Text disabled strong variant="body2" whiteSpace="nowrap">
              {columnLabel}
            </Text>
          </Dotdotdot>
          {withFilterCounter && filterValue ? (
            <Badge
              badgeContent={filterValue.length}
              className={classes.badge}
              color="primary"
            >
              <FilterAlt color={isFilterOpened ? 'primary' : undefined} />
            </Badge>
          ) : (
            <FilterAlt />
          )}
        </>
      </IconButton>
      {Component && (
        <Component
          anchorEl={anchorRef.current}
          filterValue={filterValue}
          open={isFilterOpened}
          onChange={handleChange}
          onClear={handleClear}
          onClose={handleClose}
        />
      )}
    </Box>
  )
}

export default PuiDataTableColumnFilter
