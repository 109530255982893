import React, { ComponentProps } from 'react'
import { useSelector } from 'react-redux'
import { PermissionArea } from '@pbt/pbt-ui-components'

import {
  analyticsReportToPath,
  CvcOnlyAnalyticsReport,
  GeneralSupportedAnalyticsReport,
  OmnichannelSupportedAnalyticsReport,
  SupportedAnalyticsReport,
} from '~/constants/analytics'
import FeatureToggle from '~/constants/featureToggle'
import { useGetAnalyticsFilterParams } from '~/store/hooks/business'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'

import AnalyticsPage from '../analytics/AnalyticsPage'

type AnalyticsRoute = {
  id: SupportedAnalyticsReport
  signedParams?: ComponentProps<typeof AnalyticsPage>['signedParams']
}

type AnalyticsComponentRoute = {
  component: () => React.JSX.Element
  path: string
  permissions: PermissionArea
}

export const useGetAnalyticsRoutes = (): AnalyticsComponentRoute[] => {
  const currentBusiness = useSelector(getCurrentBusiness)
  const isDynamicRhapsodyDomainEnabled = useSelector(
    getFeatureToggle(FeatureToggle.REPORTING_DYNAMIC_RHAPSODY_DOMAIN),
  )
  const isOmnichannelAnalyticsMenuEnabled = useSelector(
    getFeatureToggle(FeatureToggle.OMNICHANNEL_ANALYTICS_MENU),
  )
  const {
    date: { todayDate, last30DaysDate },
    range: { todayRange, last30DaysRange },
    rhapsodyDomain,
  } = useGetAnalyticsFilterParams()

  /**
   * Prevent all analytics dashboard routes being available if the user switches accounts on a report
   * or has the absolute url saved
   */
  const analyticsRouteFilter = ({ id }: AnalyticsRoute) => {
    if (id in CvcOnlyAnalyticsReport && !currentBusiness?.omniChannel) {
      return false
    }

    if (isOmnichannelAnalyticsMenuEnabled && currentBusiness?.omniChannel) {
      return id in OmnichannelSupportedAnalyticsReport
    }

    return id in GeneralSupportedAnalyticsReport
  }

  const analyticsRoutes: AnalyticsRoute[] = [
    {
      id: SupportedAnalyticsReport.PRACTICE_PERFORMANCE,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsReport.DAILY_RECONCILIATION_OLD,
      signedParams: { dateRange: todayRange },
    },
    {
      id: SupportedAnalyticsReport.PRODUCTION,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsReport.GROUP_PERFORMANCE,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsReport.REMINDERS_COMPLIANCE,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: isDynamicRhapsodyDomainEnabled
        ? SupportedAnalyticsReport.CLIENT_LIST_SSO
        : SupportedAnalyticsReport.CLIENT_LIST,
      signedParams: {
        newPatientsFilter: last30DaysRange,
        newClientsFilter: last30DaysRange,
        rhapsodyDomain,
      },
    },
    {
      id: SupportedAnalyticsReport.INVENTORY_USAGE,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: isDynamicRhapsodyDomainEnabled
        ? SupportedAnalyticsReport.MAGIC_QUADRANT_SSO
        : SupportedAnalyticsReport.MAGIC_QUADRANT,
      signedParams: {
        dateRange: last30DaysRange,
        rhapsodyDomain,
      },
    },
    {
      id: SupportedAnalyticsReport.TIME_TRACKING,
      signedParams: {
        dateFrom: last30DaysDate,
        dateTo: todayDate,
      },
    },
    {
      id: SupportedAnalyticsReport.GROUP_PRICE_LISTS,
    },
    {
      id: SupportedAnalyticsReport.RABIES_VACCINE_HISTORY,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsReport.MONTHLY_SALES_SUMMARY,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsReport.CONTROLLED_SUBSTANCE_LOG,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsReport.MEDICAL_INSIGHTS,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsReport.CLIENT_AND_PATIENT_STATUS,
    },
    {
      id: SupportedAnalyticsReport.MARKETING_DATA_QUALITY,
    },
    {
      id: SupportedAnalyticsReport.FORWARD_BOOKINGS,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsReport.SCHEDULE_MANAGEMENT_OLD,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsReport.SCHEDULE_MANAGEMENT_NEW,
      signedParams: {
        dateRange: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsReport.CANCELLATIONS_AND_NO_SHOWS,
      signedParams: {
        dateRange: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsReport.ACQUISITION_AND_LIFETIME_VALUE,
      signedParams: {
        dateRange: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsReport.WELLNESS_PLANS,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsReport.REFERRAL_BUSINESS,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: isDynamicRhapsodyDomainEnabled
        ? SupportedAnalyticsReport.OPEN_SOAPS_AND_INVOICES_SSO
        : SupportedAnalyticsReport.OPEN_SOAPS_AND_INVOICES,
      signedParams: {
        dateRange: last30DaysRange,
        rhapsodyDomain,
      },
    },
    {
      id: SupportedAnalyticsReport.DAILY_RECONCILIATION_NEW,
      signedParams: { dateRange: todayRange },
    },
    {
      id: SupportedAnalyticsReport.BANK_RECONCILIATION,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsReport.INVOICES_AND_PAYMENTS,
      signedParams: { dateRange: todayRange },
    },
    {
      id: isDynamicRhapsodyDomainEnabled
        ? SupportedAnalyticsReport.ACCOUNTING_SSO
        : SupportedAnalyticsReport.ACCOUNTING,
      signedParams: {
        creationDate: todayDate,
        rhapsodyDomain,
      },
    },
    {
      id: SupportedAnalyticsReport.SALES_SUMMARY_EXCLUDING_UNPAID_OTC,
      signedParams: {
        dateRange: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsReport.DISCOUNT_USAGE,
      signedParams: {
        dateFrom: todayDate,
        dateTo: todayDate,
      },
    },
    {
      id: SupportedAnalyticsReport.PRACTICE_SALES,
      signedParams: {
        dateRange: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsReport.PRODUCER_SALES,
      signedParams: {
        dateRange: last30DaysRange,
      },
    },
    {
      id: isDynamicRhapsodyDomainEnabled
        ? SupportedAnalyticsReport.INVOICE_ACTIVITY_SSO
        : SupportedAnalyticsReport.INVOICE_ACTIVITY,
      signedParams: {
        dateRange: last30DaysRange,
        rhapsodyDomain,
      },
    },
    {
      id: SupportedAnalyticsReport.NEW_CLIENT_AND_PATIENT_ACTIVITY,
      signedParams: {
        dateRange: last30DaysRange,
      },
    },
    {
      id: isDynamicRhapsodyDomainEnabled
        ? SupportedAnalyticsReport.BUNDLES_AND_WELLNESS_PLANS_SSO
        : SupportedAnalyticsReport.BUNDLES_AND_WELLNESS_PLANS,
      signedParams: {
        dateRange: last30DaysRange,
        rhapsodyDomain,
      },
    },
    {
      id: SupportedAnalyticsReport.CLIENT_INITIATED_REQUESTS,

      signedParams: {
        dateRange: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsReport.DENTAL_AND_HEARTWORM_OVERVIEW,
    },
    {
      id: SupportedAnalyticsReport.APPOINTMENT_MIX_BENCHMARK,
    },
    {
      id: SupportedAnalyticsReport.INSURED_PATIENTS_VISITS,
    },
    {
      id: SupportedAnalyticsReport.INVENTORY_USAGE_AND_AVERAGE_INVOICE,
    },
    {
      id: SupportedAnalyticsReport.REPEAT_AND_RECHECK_PATIENTS,
      signedParams: {
        creationDate: todayDate,
      },
    },
    {
      id: SupportedAnalyticsReport.ONLINE_SICK_APPOINTMENTS,
    },
    {
      id: SupportedAnalyticsReport.CVC_RESOLUTION_RATE,
    },
    {
      id: SupportedAnalyticsReport.CVC_DISCOUNT_USAGE,
      signedParams: {
        dateFrom: last30DaysDate,
        dateTo: todayDate,
      },
    },
    {
      id: SupportedAnalyticsReport.CVC_INVENTORY_AND_CATALOG_AUDIT,
    },
    {
      id: SupportedAnalyticsReport.CVC_OVERDUE_TASKS,
    },
    {
      id: SupportedAnalyticsReport.CVC_SMS_ACTIVITY,
    },
    {
      id: SupportedAnalyticsReport.DAILY_BILLING_ACTIVITY,
    },
    {
      id: SupportedAnalyticsReport.BILLING_AGING,
      signedParams: {
        creationDate: todayDate,
        rhapsodyDomain,
      },
    },
    {
      id: SupportedAnalyticsReport.CVC_DAILY_RECONCILIATION,
      signedParams: {
        dateRange: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsReport.CVC_INVOICE_ACTIVITY,
      signedParams: {
        rhapsodyDomain,
        dateRange: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsReport.CVC_PRODUCER_SALES,
      signedParams: {
        creationDate: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsReport.OPEN_AND_UNAPPLIED_BILLING,
      signedParams: {
        rhapsodyDomain,
        dateRange: last30DaysRange,
      },
    },
  ]

  return analyticsRoutes.filter(analyticsRouteFilter).map((route) => ({
    path: analyticsReportToPath[route.id],
    component: () => (
      <AnalyticsPage
        dashboard={route.id}
        signedParams={{ ...(route.signedParams || {}) }}
      />
    ),
    permissions: PermissionArea.REPORTING,
  }))
}
