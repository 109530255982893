import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  MoreHoriz as MoreHorizIcon,
} from '@mui/icons-material'
import { Grid, TableCell } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { LanguageUtils, PuiTheme, Text } from '@pbt/pbt-ui-components'

import { getItemCoveredByWplan } from '~/components/dashboard/invoices/invoiceUtils'
import MembershipIcon from '~/components/dashboard/soap/order/MembershipIcon'
import { FINANCE_TABLE_CELL_HEIGHT } from '~/constants/financeTable'
import { InvoiceLineItem } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    declined: {
      color: theme.colors.lowAccentText,
    },
    groupedCell: {
      borderBottom: theme.constants.tabBorder,
    },
    icon: {
      color: theme.colors.lowAccentText,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    membershipIcon: {
      width: 24,
      height: 24,
      marginRight: theme.spacing(0.5),
    },
    selectedRowText: {
      color: theme.colors.tabSelected,
    },
  }),
  { name: 'FinanceItemNameCellBody' },
)

export interface FinanceItemNameCellBodyProps {
  handleSelectItem: (item: InvoiceLineItem) => void
  isExpanded: boolean
  isGrouped: boolean
  isReadOnly: boolean
  item: InvoiceLineItem
  nested?: boolean
  selectedItem: InvoiceLineItem | undefined
  showAsDisabled?: Boolean
  tableCellClassName: string
  tableCellWithBorder?: string
  toggleExpand: (item: InvoiceLineItem) => void
}

const FinanceItemNameCellBody = ({
  handleSelectItem,
  isGrouped,
  isExpanded,
  isReadOnly,
  showAsDisabled = false,
  item,
  nested,
  selectedItem,
  tableCellClassName,
  tableCellWithBorder,
  toggleExpand,
}: FinanceItemNameCellBodyProps) => {
  const classes = useStyles()

  const { t } = useTranslation(['Common', 'Invoices'])

  const isWplanItem = Boolean(item.wplanLogId)
  const coveredByWplan = getItemCoveredByWplan(item)

  const titleClasses = classNames({
    [classes.selectedRowText]: selectedItem && item.id === selectedItem.id,
    [classes.declined]: item.declined,
  })

  const statusLabel = item.declined
    ? `(${t('Common:DECLINED')})`
    : coveredByWplan
    ? t('Invoices:TABLE.BODY.COVERED_BY_WELLNESS_PLAN')
    : ''

  return isGrouped ? (
    <>
      <Grid
        container
        alignItems="center"
        className={classNames(tableCellClassName)}
        height={FINANCE_TABLE_CELL_HEIGHT}
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid
          container
          item
          alignItems="center"
          flexWrap="nowrap"
          onClick={() => (toggleExpand ? toggleExpand(item) : undefined)}
        >
          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          <Dotdotdot
            clamp={2}
            truncationChar={statusLabel ? `... ${statusLabel}` : '...'}
            useNativeClamp={false}
          >
            <Text
              strong
              className={titleClasses}
              variant={showAsDisabled ? 'lowAccent2' : 'body2'}
            >
              {`${item.groupName} ${statusLabel}`}
            </Text>
          </Dotdotdot>
        </Grid>
        {!isReadOnly && (
          <MoreHorizIcon
            className={classes.icon}
            id={`charge-widget-select-item-${item.id}_${item.logId}`}
            onClick={() => {
              handleSelectItem(item)
            }}
          />
        )}
      </Grid>
    </>
  ) : (
    <TableCell className={classNames(tableCellClassName, tableCellWithBorder)}>
      <Grid
        container
        alignItems="center"
        height={FINANCE_TABLE_CELL_HEIGHT}
        justifyContent="space-between"
        pl={nested ? 3 : 0}
        wrap="nowrap"
      >
        <Grid container alignItems="flex-start" wrap="nowrap">
          {isWplanItem && !item.declined && (
            <MembershipIcon
              className={classes.membershipIcon}
              limitReached={!coveredByWplan}
            />
          )}
          <Grid item>
            <Dotdotdot clamp={2}>
              <Text
                className={titleClasses}
                variant={showAsDisabled ? 'lowAccent2' : 'body2'}
              >
                {`${LanguageUtils.getTranslatedFieldName(item)} ${statusLabel}`}
              </Text>
            </Dotdotdot>
          </Grid>
        </Grid>
        {!isReadOnly && (
          <MoreHorizIcon
            className={classes.icon}
            id={`charge-widget-select-item-${item.id}_${item.logId}`}
            onClick={() => {
              handleSelectItem(item)
            }}
          />
        )}
      </Grid>
    </TableCell>
  )
}

export default FinanceItemNameCellBody
