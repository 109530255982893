import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, PuiTheme } from '@pbt/pbt-ui-components'

import MobilePhone from '../images/mobilePhone.png'

interface UseStylesProps {
  classes?: ClassesType<typeof useStyles>
}

export interface MobilePhonePreviewProps extends UseStylesProps {
  children: React.ReactNode
}

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      width: 260,
      position: 'relative',
      height: 485,
      backgroundImage: `url(${MobilePhone})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      margin: theme.spacing(-1, 2, 0, -2),
    },
    content: {
      width: `calc(100% - ${theme.spacing(4)} - 40px)`,
      height: 'calc(100% - 90px)',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
      borderRadius: 4,
      position: 'absolute',
      top: 50,
      left: 0,
      right: 0,
      backgroundColor: '#F9F0E5',
    },
  }),
  { name: 'MobilePhonePreview' },
)

const MobilePhonePreview = ({
  classes: classesProp,
  children,
}: MobilePhonePreviewProps) => {
  const useStylesProps: UseStylesProps = {
    classes: classesProp,
  }
  const classes = useStyles(useStylesProps)

  return (
    <div className={classes.root}>
      <div className={classes.content}>{children}</div>
    </div>
  )
}

export default MobilePhonePreview
