import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text, ValidateHandle } from '@pbt/pbt-ui-components'

import FileListItem, { FileListItemHandle } from './FileListItem'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      border: theme.constants.tableBorder,
    },
  }),
  { name: 'FileList' },
)

export type FileListHandle = ValidateHandle

interface FileListProps {
  files: File[]
  onDelete: (index: number) => void
  onEdit: (index: number) => void
  onFileChange: (file: File, index: number) => void
}

const FileList = forwardRef<FileListHandle, FileListProps>(function FileList(
  { files, onDelete, onEdit, onFileChange },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const fileRefs = Array.from({ length: files.length }, () =>
    React.createRef<FileListItemHandle>(),
  )

  useImperativeHandle(ref, () => ({
    validate: () => fileRefs.every((fileRef) => fileRef.current?.validate()),
  }))

  return (
    <Grid container className={classes.root} minHeight={195} mt={1} p={1}>
      <Grid container item px={1}>
        <Text strong variant="subheading3">
          {t('Common:ADD_ITEM_OR_ITEMS')}
        </Text>
      </Grid>
      <Grid item p={1}>
        <Text strong variant="lowAccent2">
          {t('Common:ITEM')}
        </Text>
      </Grid>
      <Grid container direction="column">
        {files.map((file, index) => (
          <FileListItem
            file={file}
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${file.size}`}
            ref={fileRefs[index]}
            onChange={(newFile) => onFileChange(newFile, index)}
            onDelete={() => onDelete(index)}
            onEdit={() => onEdit(index)}
          />
        ))}
      </Grid>
    </Grid>
  )
})

export default FileList
