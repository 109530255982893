import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader, PuiTheme, Text, Utils } from '@pbt/pbt-ui-components'

import DetailsBackButton from '~/components/dashboard/clients/DetailsBackButton'
import {
  createPrice,
  editPrice,
  fetchProcedure,
} from '~/store/actions/procedures'
import {
  getAvailableSpeciesForProcedure,
  getProcedure,
  getProceduresIsLoading,
} from '~/store/reducers/procedures'
import { DataHandleWithUnsavedChanges } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import Price from './Price'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    headerContainer: {
      borderBottom: theme.constants.tabBorder,
    },
    backButton: {
      borderBottom: 'none',
    },
    priceContainer: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    button: {
      width: 144,
      height: 40,
      margin: theme.spacing(1),
    },
  }),
  { name: 'PricePage' },
)

const PricePage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isLoading = useSelector(getProceduresIsLoading)
  const { t } = useTranslation(['Admin', 'Common'])

  const { procedureId, priceId } = useParams<{
    priceId: string
    procedureId: string
  }>()

  const priceRef = useRef<DataHandleWithUnsavedChanges>(null)

  const procedure = useSelector(getProcedure(procedureId))
  const availableSpecies = useSelector(
    getAvailableSpeciesForProcedure(procedure?.id),
  )

  const goBack = () => {
    navigate(`/admin/catalog/procedures/${procedureId}`)
  }

  const setCloseAfterCreationOn = useCloseAfterCreation(
    goBack,
    getProceduresIsLoading,
  )

  useEffect(() => {
    if (procedureId) {
      dispatch(fetchProcedure(procedureId))
    }
  }, [procedureId])

  const isEdit = Boolean(priceId)
  const price = isEdit && Utils.findById(priceId, procedure?.prices || [])

  const save = () => {
    if (procedureId && priceRef.current?.validate()) {
      const newPrice = priceRef.current?.get()
      setCloseAfterCreationOn()
      if (isEdit) {
        dispatch(editPrice(procedureId, { ...newPrice, id: price.id }))
      } else {
        dispatch(createPrice(procedureId, newPrice))
      }
    }
  }

  return (
    <Grid container direction="column">
      <DetailsBackButton className={classes.backButton} onClick={goBack}>
        {t('Admin:CATALOG.PRICE_PAGE.PROCEDURE_DETAILS')}
      </DetailsBackButton>
      <Grid item className={classes.headerContainer} mt={1} pb={2} pl={3}>
        <Text strong variant="subheading2">
          {isEdit ? t('Common:EDIT_PRICE') : t('Common:ADD_PRICE')}
        </Text>
      </Grid>
      <Grid item>
        <Price
          availableSpecies={availableSpecies}
          className={classes.priceContainer}
          price={price}
          ref={priceRef}
        />
      </Grid>
      <Grid item>
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading}
          loading={isLoading}
          type="submit"
          onClick={save}
        >
          {t('Common:SAVE_ACTION')}
        </ButtonWithLoader>
      </Grid>
    </Grid>
  )
}

export default PricePage
