import React from 'react'
import { generatePath, Navigate, useParams } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import { PuiTheme } from '@pbt/pbt-ui-components'

export interface ResponsiveRouteProps {
  children: React.ReactElement
  md?: boolean
  redirectTo: string
  redirectWithReplace?: boolean
  sm?: boolean
  xs?: boolean
}

const ResponsiveRoute = ({
  xs,
  sm,
  md,
  redirectTo,
  redirectWithReplace = false,
  children: Component,
}: ResponsiveRouteProps) => {
  const params = useParams()
  const isXS = useMediaQuery((theme: PuiTheme) => theme.breakpoints.only('xs'))
  const isSM = useMediaQuery((theme: PuiTheme) => theme.breakpoints.only('sm'))
  const isMdOrUp = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.up('md'),
  )

  const shouldRedirect = (isXS && !xs) || (isSM && !sm) || (isMdOrUp && !md)

  return shouldRedirect && redirectTo ? (
    <Navigate
      replace={redirectWithReplace}
      to={generatePath(redirectTo, params)}
    />
  ) : (
    Component
  )
}

export default ResponsiveRoute
