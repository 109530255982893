import React, { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { PermissionArea } from '@pbt/pbt-ui-components'

import { useLockableComponent } from '~/store/hooks/soap'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getAppointmentId,
  getIsCurrentContextSoap,
} from '~/store/reducers/soap'
import { getTimetableEvent } from '~/store/reducers/timetable'

import AttachmentsWidget from '../../soapV2/attachments/AttachmentsWidget'
import EstimatesWidget from '../../soapV2/estimates/EstimatesWidget'
import ReasonForVisitWidget from '../../soapV2/reason-for-visit/ReasonForVisitWidget'
import RemindersWidget from '../../soapV2/reminders/RemindersWidget'
import Appointment, { AppointmentHandle } from './Appointment'
import AppointmentLocked from './AppointmentLocked'
import AssignedToSoapDetails from './AssignedToSoapDetails'
import AssignedToSoapDetailsLocked from './AssignedToSoapDetailsLocked'
import SoapClientAndPatientMainDetails from './SoapClientAndPatientMainDetails'
import SoapClientAndPatientMainDetailsLocked from './SoapClientAndPatientMainDetailsLocked'
import SoapInformation from './SoapInformation'
import SoapInformationLocked from './SoapInformationLocked'

const AppointmentPage = forwardRef<AppointmentHandle>(function AppointmentPage(
  _,
  ref,
) {
  const appointmentId = useSelector(getAppointmentId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const appointmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )
  const { read: invoiceReadPermission } = useSelector(
    getCRUDByArea(PermissionArea.INVOICE),
  )
  const soapPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const reminderPermissions = useSelector(
    getCRUDByArea(PermissionArea.REMINDERS),
  )
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)

  const appointmentUpdateDisabled = !appointmentPermissions.update
  const soapUpdateDisabled = !soapPermissions.update
  const soapsAmount = appointment?.soaps?.length || 0
  const multipleSoaps = soapsAmount > 1

  const SoapClientAndPatientMainDetailsComponent = useLockableComponent(
    SoapClientAndPatientMainDetails,
    SoapClientAndPatientMainDetailsLocked,
  )

  const AssignedToSoapDetailsComponent = useLockableComponent(
    AssignedToSoapDetails,
    AssignedToSoapDetailsLocked,
  )

  const AppointmentComponent = useLockableComponent(
    Appointment,
    AppointmentLocked,
  )

  const SoapInformationComponent = useLockableComponent(
    SoapInformation,
    SoapInformationLocked,
  )

  return (
    <Grid container item direction="column" rowSpacing={2}>
      <Grid item>
        <SoapClientAndPatientMainDetailsComponent />
      </Grid>
      {(multipleSoaps || !appointmentId) && (
        <Grid container item>
          <AssignedToSoapDetailsComponent editDisabled={soapUpdateDisabled} />
        </Grid>
      )}
      <Grid item>
        {appointmentId ? (
          <AppointmentComponent
            appointment={appointment}
            editDisabled={appointmentUpdateDisabled}
            ref={ref}
          />
        ) : (
          <SoapInformationComponent />
        )}
      </Grid>
      {appointment && (
        <Grid item>
          <ReasonForVisitWidget />
        </Grid>
      )}
      {isCurrentContextSoap && invoiceReadPermission && (
        <Grid item>
          <EstimatesWidget />
        </Grid>
      )}
      <Grid item>
        <AttachmentsWidget />
      </Grid>
      {reminderPermissions.read && (
        <Grid item>
          <RemindersWidget />
        </Grid>
      )}
    </Grid>
  )
})

export default AppointmentPage
