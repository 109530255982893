import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Fab, FormControl } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ClassesType,
  LanguageUtils,
  PuiSelect,
  PuiTheme,
  Utils,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import {
  LabTestState,
  LabTestStateEasterEgg,
  OrderType,
} from '~/constants/SOAPStates'
import { queueEasterEggEvent } from '~/store/actions/easterEgg'
import { partialEditOrder } from '~/store/actions/orders'
import { editChargeSheetOrder } from '~/store/duck/clientFinanceData'
import {
  getFeatureToggle,
  getSelectableLabTestsStates,
} from '~/store/reducers/constants'
import { EasterEggEvents, InvoiceLineItem, Order } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    fab: {
      height: 33,
      width: '100%',
      fontSize: '1.2rem',
      fontWeight: 500,
      padding: theme.spacing(0, 1),
    },
    select: {
      fontSize: '1.4rem',
    },
    orderStatusChip: {
      color: theme.colors.title,
      pointerEvents: 'none',
    },
    form: {},
  }),
  { name: 'LabTestStateItemButton' },
)

export interface LabTestStateItemButtonProps {
  classes?: ClassesType<typeof useStyles>
  disabled?: boolean
  order: Order | InvoiceLineItem
}

const LabTestStateItemButton = ({
  classes: classesProp,
  disabled,
  order,
}: LabTestStateItemButtonProps) => {
  const classes = useStyles({ classes: classesProp })

  const dispatch = useDispatch()

  const LabTestStates = useSelector(getSelectableLabTestsStates)

  const declinedLabTestStateId = Utils.findConstantIdByName(
    LabTestState.DECLINED,
    LabTestStates,
  )
  const isUnderOrder = Boolean(
    R.path(['labTest', 'labOrderId'], order) || R.prop('labOrderId', order),
  )
  const currentTest = Utils.findById(order.stateId, LabTestStates)

  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )

  const isEasterEggEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EASTER_EGG),
  )

  const [labTestState, setLabTestState] = useState(order.stateId)

  const setState = (stateId: string) => {
    const statusName = Utils.getConstantName(stateId, LabTestStates)
    setLabTestState(stateId)
    if (isEasterEggEnabled && LabTestStateEasterEgg.includes(statusName)) {
      dispatch(
        queueEasterEggEvent({
          actionType: EasterEggEvents.LAB_WORK_STATUS_UPDATE,
          actionSubtype: Utils.getConstantName(statusName, LabTestStates),
        }),
      )
    }
    const logId: string = R.propOr(order.id, 'logId', order)
    const soapLogModificationDate =
      R.prop('soapLogModificationDate', order) ||
      R.prop('modificationDate', order)
    if (isChargeSheetEnabled && soapLogModificationDate && logId) {
      dispatch(
        editChargeSheetOrder({
          id: logId,
          type: OrderType.LAB_TEST,
          order: {
            stateId,
          },
          soapLogModificationDate,
        }),
      )
    } else {
      dispatch(
        partialEditOrder({
          id: order.id,
          type: order.type as OrderType,
          stateId,
        }),
      )
    }
  }

  return (
    <FormControl fullWidth className={classes.form} margin="normal">
      {disabled || isUnderOrder ? (
        <Fab
          className={classes.fab}
          classes={{
            root: classes.orderStatusChip,
          }}
          color="primary"
          variant="extended"
        >
          {LanguageUtils.getTranslatedFieldName(currentTest)}
        </Fab>
      ) : (
        <PuiSelect
          inputProps={{
            className: classes.select,
          }}
          items={LabTestStates.filter(
            ({ id }) => id !== declinedLabTestStateId,
          )}
          value={labTestState}
          onChange={Utils.handleFormSelectInput(setState)}
        />
      )}
    </FormControl>
  )
}

export default LabTestStateItemButton
