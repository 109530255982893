import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  PuiDialog,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import ContactTargetSlot from '~/components/common/ContactTargetSlot'
import { updateConversationRecipientContext } from '~/store/actions/conversations'
import { ConversationRecipientContext } from '~/types'

import ClientSelector from '../../../timetable/scheduler/appointment/ClientSelector'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      overflowY: 'visible',
    },
    button: {
      width: 150,
    },
    contactSlotBlock: {
      minHeight: 200,
    },
    contactTargetSlot: {
      marginTop: theme.spacing(2),
    },
    dialogContentRoot: {
      overflowY: 'visible',
    },
  }),
  { name: 'AddClientPatientDialog' },
)

export interface AddClientPatientToConversationDialogProps
  extends BasePuiDialogProps {
  conversationId: string
}

const AddClientPatientToConversationDialog = ({
  conversationId,
  onClose,
  open,
}: AddClientPatientToConversationDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common'])

  const [clientPatientSlot, setClientPatientSlot] =
    useState<ConversationRecipientContext>({})

  const rootRef = useRef<HTMLDivElement>(null)

  const slotSelected = Boolean(clientPatientSlot.clientId)

  const onClientPatientSelected = (
    clientId: string | Nil,
    patientId: string | Nil,
  ) => {
    setClientPatientSlot({ clientId, patientId })
  }

  const handleAddClick = () => {
    dispatch(
      updateConversationRecipientContext(conversationId, clientPatientSlot),
    )
    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiDialog
      actions={
        slotSelected && (
          <ButtonWithLoader className={classes.button} onClick={handleAddClick}>
            {t('Common:ADD_ACTION')}
          </ButtonWithLoader>
        )
      }
      aria-labelledby="add-client-patient-to-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={t('Common:ADD_CLIENT_AND_PATIENT')}
      onClose={onClose}
    >
      <Grid
        item
        className={classes.contactSlotBlock}
        pb={2}
        px={2}
        ref={rootRef}
      >
        {slotSelected ? (
          <ContactTargetSlot
            anchorRef={rootRef}
            className={classes.contactTargetSlot}
            clientId={clientPatientSlot.clientId}
            patientId={clientPatientSlot.patientId}
            showAddContact={false}
            onSlotChange={({ clientId, patientId }) =>
              onClientPatientSelected(clientId, patientId)
            }
          />
        ) : (
          <ClientSelector
            maxSearchValuesInList={3}
            resultsTitle={t('Common:ADD_CLIENT_AND_PATIENT')}
            onSelected={onClientPatientSelected}
          />
        )}
      </Grid>
    </PuiDialog>
  )
}

export default AddClientPatientToConversationDialog
