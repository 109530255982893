import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Tooltip } from '@mui/material'
import {
  DateUtils,
  Nil,
  PermissionArea,
  StripeUtils,
} from '@pbt/pbt-ui-components'
import {
  Edit as EditIcon,
  Send as SendIcon,
} from '@pbt/pbt-ui-components/src/icons'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Membership, MembershipPayment, MembershipPlan } from '~/types'

import useDialog from './useDialog'

export const getMembershipPaymentId = (
  membershipPayment: Partial<MembershipPayment>,
) => `${membershipPayment.id}-${membershipPayment.stripeSubscriptionId}`

export const isRefundableMembershipPayment = ({
  id,
  refundable,
}: Partial<MembershipPayment>) => id && refundable

export const formatMembershipPeriod = (plan: MembershipPlan | Membership) =>
  plan?.period
    ? `${DateUtils.formatDate(plan.period.startDate)}
     - ${DateUtils.formatDate(plan.period.endDate)}`
    : ''

export const useEditPaymentInfo = ({
  clientId,
  patientId,
  withBillingAddress,
  onClose,
}: {
  clientId: string | Nil
  onClose?: () => void
  patientId: string | Nil
  withBillingAddress: boolean
}) => {
  const { t } = useTranslation('Tooltips')

  const isLinkToUpdateWplansPaymentMethodForCvcEnabled = useSelector(
    getFeatureToggle(
      FeatureToggle.LINK_TO_UPDATE_WPLANS_PAYMENT_METHOD_FOR_CVC,
    ),
  )
  const { update: paymentUpdatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.PAYMENT),
  )

  const isLinkToUpdateWplansPaymentMethodEnabled =
    isLinkToUpdateWplansPaymentMethodForCvcEnabled

  const [openPatientMembershipPaymentInfoDialog] = useDialog(
    DialogNames.PATIENT_MEMBERSHIP_PAYMENT_INFO,
  )
  const [openPatientMembershipPaymentLinkDialog] = useDialog(
    DialogNames.PATIENT_MEMBERSHIP_PAYMENT_LINK,
    onClose,
  )

  const onEditPaymentInfo = (plan: MembershipPlan) => {
    if (!paymentUpdatePermissions) {
      return
    }

    if (isLinkToUpdateWplansPaymentMethodEnabled) {
      openPatientMembershipPaymentLinkDialog({
        clientId,
        plan,
        patientId,
      })
    } else {
      openPatientMembershipPaymentInfoDialog({
        billingAddress:
          withBillingAddress && plan.paymentMethod?.billingDetails?.address
            ? StripeUtils.stripeFormatToBillingAddress(
                plan.paymentMethod?.billingDetails?.address,
              )
            : undefined,
        membershipId: plan.planLogId,
        clientId,
      })
    }
  }

  const Icon = isLinkToUpdateWplansPaymentMethodEnabled ? (
    <Tooltip
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -16],
            },
          },
        ],
      }}
      placement="top"
      title={t('Tooltips:SEND_UPDATE_PAYMENT_LINK')}
    >
      <SendIcon />
    </Tooltip>
  ) : (
    <EditIcon />
  )

  return {
    onEditPaymentInfo,
    Icon,
    isLinkToUpdateWplansPaymentMethodEnabled,
    canUpdate: paymentUpdatePermissions,
  }
}
