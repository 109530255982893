import React from 'react'
import { TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme } from '@pbt/pbt-ui-components'

import { EstimateRangeColumnComponents } from '../../../invoiceTableConstants'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tableCell: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    borderCell: {
      borderRight: theme.constants.tabBorder,
    },
    estimateHeaderCell: {
      fontWeight: 400,
    },
    borderBottom: {},
    emptyCell: {
      borderBottom: 'none',
    },
  }),
  { name: 'EstimateRangeRow' },
)

export interface EstimateRangeRowProps {
  isEstimate: boolean
  rangeEnabled: boolean
  selectedColumns: string[]
}

const EstimateRangeRow = ({
  selectedColumns,
  isEstimate,
  rangeEnabled,
  ...rest
}: EstimateRangeRowProps) => {
  const classes = useStyles()

  if (!isEstimate || !rangeEnabled) {
    return null
  }

  return (
    <TableRow>
      {selectedColumns.map((column) => {
        const CellComponent = EstimateRangeColumnComponents[column]

        if (!CellComponent) {
          return null
        }

        return (
          <CellComponent
            className={classNames(classes.tableCell, classes.borderCell)}
            key={column}
            {...rest}
          />
        )
      })}
    </TableRow>
  )
}

export default EstimateRangeRow
