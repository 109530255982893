import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import * as R from 'ramda'
import {
  Defaults,
  PermissionArea,
  PrimitiveTableColumn,
  PuiTheme,
  useInterval,
} from '@pbt/pbt-ui-components'

import SimplePatientCell from '~/components/common/lists/primitive-table/cells/patient/SimplePatientCell'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import {
  fetchBalanceHistory,
  fetchMoreItemsForBalanceHistory,
  refreshBalanceHistory,
} from '~/store/actions/finance'
import { getBalanceChargeSheet } from '~/store/duck/clientFinanceData'
import { getHasOpenDialogs } from '~/store/duck/dialogs'
import { getRhapsodyPayConfig } from '~/store/duck/rhapsodyPay'
import { getCRUDByArea, getCurrentBusinessId } from '~/store/reducers/auth'
import { getPaymentTransactionState } from '~/store/reducers/constants'
import {
  getBalanceHistory,
  getFinanceIsLoading,
  getFinanceTotalCount,
  getMultipleBalanceEntries,
} from '~/store/reducers/finance'
import { BalanceListEntryItem } from '~/types'
import {
  getAmountLabel,
  getPaymentRowColoring,
  getPaymentTypeLabel,
} from '~/utils/paymentUtils'

import PaymentDateCell from './PaymentDateCell'
import PaymentDescriptionCell from './PaymentDescriptionCell'
import PaymentStatusCell from './PaymentStatusCell'

export interface PaymentsTableComponentProps {
  clientId: string
  onItemClick: (item: BalanceListEntryItem) => void
}

const PaymentsTableComponent = ({
  clientId,
  onItemClick,
}: PaymentsTableComponentProps) => {
  const dispatch = useDispatch()
  const theme = useTheme<PuiTheme>()
  const hasOpenDialogs = useSelector(getHasOpenDialogs)
  const list = useSelector(getBalanceHistory)
  const totalCount = useSelector(getFinanceTotalCount)
  const entries = useSelector(getMultipleBalanceEntries(list))
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const rhapsodyPayConfig = useSelector(getRhapsodyPayConfig(currentBusinessId))
  const isLoading = useSelector(getFinanceIsLoading)
  const paymentPermissions = useSelector(getCRUDByArea(PermissionArea.PAYMENT))
  const PaymentTransactionState = useSelector(getPaymentTransactionState)
  const balanceChargeSheet = useSelector(getBalanceChargeSheet)

  const getActualRowColoring = R.curry(getPaymentRowColoring)(
    theme,
    PaymentTransactionState,
  )

  const { t } = useTranslation(['Common', 'Invoices'])

  const hasServiceFee = Boolean(
    rhapsodyPayConfig?.serviceFee && rhapsodyPayConfig?.serviceFee > 0,
  )

  const columns = [
    {
      label: t('Common:DATE_TIME'),
      prop: PaymentDateCell,
      width: 1,
    },
    {
      label: t('Common:TYPE_ONE'),
      prop: getPaymentTypeLabel,
      width: 2,
    },
    {
      label: t('Common:DESCRIPTION'),
      prop: PaymentDescriptionCell,
      width: 5,
    },
    {
      label: t('Common:PATIENT'),
      prop: SimplePatientCell,
      width: 2,
    },
    {
      label: '',
      component: PaymentStatusCell,
      width: 1.5,
    },
    ...(hasServiceFee
      ? [
          {
            label: t('Common:TOTAL'),
            sublabel: t(
              'Invoices:PAYMENTS.PAYMENTS_TABLE_COMPONENT.SUBLABEL_WITHOUT_FEES',
            ),
            prop: (item: BalanceListEntryItem) => getAmountLabel(item, false),
            color: getActualRowColoring,
            width: 1.5,
          },
          {
            label: t('Common:TOTAL'),
            sublabel: t(
              'Invoices:PAYMENTS.PAYMENTS_TABLE_COMPONENT.SUBLABEL_WITH_FEES',
            ),
            prop: (item: BalanceListEntryItem) => getAmountLabel(item, true),
            color: getActualRowColoring,
            width: 1.5,
          },
        ]
      : [
          {
            label: t('Common:TOTAL'),
            prop: (item: BalanceListEntryItem) => getAmountLabel(item, false),
            color: getActualRowColoring,
            width: 1.5,
          },
        ]),
  ].filter(Boolean) as PrimitiveTableColumn[]

  useInterval(() => {
    if (
      !hasOpenDialogs &&
      !isLoading &&
      clientId &&
      currentBusinessId &&
      paymentPermissions.read
    ) {
      dispatch(refreshBalanceHistory(clientId, Math.max(entries.length - 1, 0)))
    }
  }, Defaults.BALANCE_PAGE_UPDATE_INTERVAL)

  useEffect(() => {
    if (clientId && currentBusinessId && paymentPermissions.read) {
      dispatch(
        fetchBalanceHistory(
          clientId,
          0,
          Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        ),
      )
    }
  }, [clientId])

  const isItemLoaded = (index: number) => Boolean(entries[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(fetchMoreItemsForBalanceHistory(clientId, startIndex, endIndex))
  }

  const preparedEntities = !R.isNil(balanceChargeSheet)
    ? [balanceChargeSheet, ...entries]
    : entries

  return (
    <PrimitiveTableWithSearchHighlights
      useWindowScroll
      columns={columns}
      expandable={false}
      getRowColoring={getActualRowColoring}
      isItemLoaded={isItemLoaded}
      list={preparedEntities}
      loadMoreItems={loadMoreItems}
      totalCount={totalCount}
      onItemClick={onItemClick}
    />
  )
}

export default PaymentsTableComponent
