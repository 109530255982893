import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Fab, Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PermissionArea, PuiTheme } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { getCRUDByArea } from '~/store/reducers/auth'
import { addSearch } from '~/utils'
import useDialog from '~/utils/useDialog'

// @ts-ignore
import DocumentDetails from './DocumentDetails'
import DocumentsTableComponent from './DocumentsTableComponent'

const useStyles = makeStyles(
  () => ({
    button: {
      minWidth: 120,
      height: 40,
    },
  }),
  { name: 'DocumentsPage' },
)

const DocumentsPage = () => {
  const navigate = useNavigate()
  const { documentId } = useParams()
  const location = useLocation()
  const classes = useStyles()
  const permissions = useSelector(getCRUDByArea(PermissionArea.FORM))
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )
  const { t } = useTranslation('Common')

  const [openNewDocumentDialog] = useDialog(DialogNames.DOCUMENT)

  const onDetailsClose = () => {
    navigate(addSearch(location, '/admin/catalog/documents'))
  }

  if (isMobile && typeof documentId !== 'undefined') {
    return <DocumentDetails itemId={documentId} onClose={onDetailsClose} />
  }

  const headerButtons = (
    <Grid container item justifyContent="flex-end" px={3} py={2}>
      <Fab
        className={classes.button}
        color="inherit"
        variant="extended"
        onClick={() =>
          openNewDocumentDialog({
            PreviewProps: { hideEmail: true, hidePrint: true },
          })
        }
      >
        {t('Common:ADD_NEW')}
      </Fab>
    </Grid>
  )

  return (
    <Grid container item flex={1} wrap="nowrap">
      <DocumentsTableComponent
        documentId={documentId}
        headerButtons={permissions.create && headerButtons}
        onDetailsClose={onDetailsClose}
      />
    </Grid>
  )
}

export default DocumentsPage
