import React from 'react'
import { useTranslation } from 'react-i18next'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    button: {
      padding: 0,
      marginLeft: 'auto',
      marginRight: theme.spacing(1),
    },
    expandIcon: {
      width: 16,
      height: 16,
    },
  }),
  { name: 'QuestionsTabsActions' },
)

export interface QuestionsTabsActionsProps {
  onShowAllPriorAnswers: () => void
  showAllPriorAnswers: boolean
}

const QuestionsTabsActions = ({
  onShowAllPriorAnswers,
  showAllPriorAnswers,
}: QuestionsTabsActionsProps) => {
  const classes = useStyles()

  const { t } = useTranslation('Common')

  return (
    <IconButton
      disableRipple
      aria-label="expand-all-prior-question"
      className={classes.button}
      onClick={onShowAllPriorAnswers}
    >
      <Text mr={0.5} variant="body2">
        {t('Common:EXPAND_ALL_PRIOR_ANSWERS')}
      </Text>
      {showAllPriorAnswers ? (
        <ExpandLess className={classes.expandIcon} />
      ) : (
        <ExpandMore className={classes.expandIcon} />
      )}
    </IconButton>
  )
}

export default QuestionsTabsActions
