import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Grid, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { DateUtils, PuiTheme, Text } from '@pbt/pbt-ui-components'
import { ImportedHistory as HistoryIcon } from '@pbt/pbt-ui-components/src/icons'

import { MasterProblemsSnapshotItem } from '~/types'

import ProblemHistoryPanel from './ProblemHistoryPanel'

interface ProblemsDateCellProps {
  item: MasterProblemsSnapshotItem
}

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    icon: {
      fontSize: theme.typography.pxToRem(28),
      color: theme.colors.inactiveBackground,
    },
    cellChild: {
      height: '100%',
    },
    tooltip: {
      backgroundColor: theme.colors.tableBackground,
      width: '320px',
      maxWidth: '320px',
      maxHeight: '600px',
      overflowY: 'auto',
      overflowX: 'hidden',
      borderRadius: '4px',
      padding: '16px',
      boxShadow: theme.constants.blockShadow,
    },
  }),
  { name: 'ProblemsDateCell' },
)

const ProblemsDateCell = ({ item }: ProblemsDateCellProps) => {
  const classes = useStyles()

  return (
    <Grid
      container
      item
      alignItems="center"
      justifyContent="space-between"
      wrap="nowrap"
    >
      <Grid item className={classes.cellChild}>
        <Dotdotdot clamp={1}>
          <Text strong variant="body2">
            {DateUtils.formatDate(item.date) || ''}
          </Text>
        </Dotdotdot>
      </Grid>
      {item.children.length > 1 && (
        <Grid container item className={classes.cellChild} width="auto">
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={<ProblemHistoryPanel problemLogs={item.children} />}
          >
            <HistoryIcon className={classNames(classes.icon)} />
          </Tooltip>
        </Grid>
      )}
    </Grid>
  )
}

export default ProblemsDateCell
