import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  ClassesType,
  CustomFieldValidatorState,
  ErrorTooltip,
  GenderRestriction,
  IdObject,
  PuiTextField,
  Text,
  TextWithTooltip,
  UnitUtils,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { UnitTypes } from '@pbt/pbt-ui-components/src/localization'

import ActiveStateSwitch from '~/components/common/ActiveStateSwitch'
import AgeRangeSelector from '~/components/common/inputs/AgeRangeSelector'
import GenderRestrictionSelect from '~/components/common/inputs/gender/GenderRestrictionSelect'
import PuiSelectAll from '~/components/common/inputs/PuiSelectAll'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import WeightRangeInput from '~/components/common/inputs/WeightRangeInput'
import PuiSwitch from '~/components/common/PuiSwitch'
import FeatureToggle from '~/constants/featureToggle'
import { getUnitsState } from '~/store/duck/settings'
import { getBundlesIsLoading } from '~/store/reducers/bundles'
import {
  getAgeUnits,
  getEventType,
  getFeatureToggle,
  getSpecies,
} from '~/store/reducers/constants'
import { Bundle as BundleType } from '~/types'
import { getConstantsList, isFieldValuesChanged, isNilOrEmpty } from '~/utils'
import { checkAgeRangeValidity } from '~/utils/timeRangeUtils'
import useFieldsChanged from '~/utils/useFieldsChanged'

const useStyles = makeStyles(
  () => ({
    root: {},
    button: {
      width: 152,
    },
  }),
  { name: 'Bundle' },
)

export interface BundleHandle {
  getBundle: () => BundleType
  hasUnsavedChanges: () => boolean
  validate: () => boolean
}

interface BundleProps {
  bundle: BundleType
  classes?: ClassesType<typeof useStyles>
  onChange?: () => void
  onProceed?: (bundle: BundleType) => void
  view?: boolean
}

const Bundle = forwardRef<BundleHandle, BundleProps>(function Bundle(
  { bundle, view = false, onProceed, onChange = R.F, classes: classesProp },
  ref,
) {
  const classes = useStyles({ classes: classesProp })
  const EventType = useSelector(getEventType)
  const Species = useSelector(getSpecies)
  const unitsState = useSelector(getUnitsState)
  const isLoading = useSelector(getBundlesIsLoading)
  const AgeUnits = useSelector(getAgeUnits)
  const { t } = useTranslation(['Admin', 'Common', 'Tooltips'])

  const AppointmentEvent =
    Utils.findConstantByName('Appointment', EventType) || {}

  const isCreate = !bundle

  const validateBundleAgeRange = ({
    state: { startAgeUnit, endAgeUnit, startAge, endAge },
  }: CustomFieldValidatorState) => {
    const ageRange = {
      start: { unit: startAgeUnit, value: startAge },
      end: { unit: endAgeUnit, value: endAge },
    }
    return (
      isNilOrEmpty(startAge) ||
      isNilOrEmpty(endAge) ||
      checkAgeRangeValidity(ageRange, AgeUnits)
    )
  }

  const validateBundleWeightRange = ({
    state: { maxWeight, minWeight },
  }: CustomFieldValidatorState) =>
    maxWeight && minWeight ? maxWeight > minWeight : true

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'name',
        label: t('Admin:CATALOG.BUNDLE.BUNDLE_NAME'),
        validators: ['required'],
        initialValue: bundle?.name || '',
      },
      { name: 'active', type: 'toggle', initialValue: bundle?.active ?? true },
      {
        name: 'appointmentTypeIds',
        label: t('Common:APPOINTMENT_TYPES'),
        type: 'select',
        initialValue: getConstantsList(
          bundle?.appointmentTypeIds || [],
          AppointmentEvent.subTypes,
        ),
      },
      {
        name: 'species',
        label: t('Common:SPECIES'),
        type: 'select',
        initialValue: getConstantsList(bundle?.speciesIds || [], Species),
      },
      {
        name: 'genderRestrictions',
        label: t('Common:GENDER'),
        type: 'select',
        initialValue: bundle?.genderRestrictions || [],
      },
      {
        name: 'startAge',
        initialValue: bundle?.minAge?.age ?? '',
        validators: [
          { validator: validateBundleAgeRange, validatorName: 'validRange' },
        ],
      },
      { name: 'endAge', initialValue: bundle?.maxAge?.age || '' },
      { name: 'startAgeUnit', initialValue: bundle?.minAge?.unitId || '' },
      { name: 'endAgeUnit', initialValue: bundle?.maxAge?.unitId || '' },
      {
        name: 'minWeight',
        initialValue: isCreate
          ? ''
          : UnitUtils.convertUnits(
              UnitTypes.WEIGHT,
              bundle.minWeight,
              unitsState,
            ),
        validators: [
          { validator: validateBundleWeightRange, validatorName: 'weight' },
        ],
        messages: { weight: t('Tooltips:PLEASE_PROVIDE_VALID_WEIGHT_RANGE') },
      },
      {
        name: 'maxWeight',
        initialValue: isCreate
          ? ''
          : UnitUtils.convertUnits(
              UnitTypes.WEIGHT,
              bundle.maxWeight,
              unitsState,
            ),
      },
      {
        name: 'autocharge',
        label: t('Common:AUTO_CHARGE_ACTION'),
        type: 'toggle',
        initialValue: bundle?.autocharge ?? false,
      },
    ],
    false,
  )

  const {
    name,
    active,
    appointmentTypeIds,
    species,
    genderRestrictions,
    startAge,
    endAge,
    startAgeUnit,
    endAgeUnit,
    minWeight,
    maxWeight,
    autocharge,
  } = fields

  useEffect(() => {
    reset()
  }, [Species, EventType, bundle])

  useFieldsChanged(() => {
    onChange()
  }, fields)

  const createBundle = () => {
    const newBundle = {
      ...bundle,
      name: name.value,
      appointmentTypeIds: R.pluck('id', appointmentTypeIds.value as IdObject[]),
      speciesIds: R.pluck('id', species.value as IdObject[]),
      genderRestrictions: (genderRestrictions.value || []).map(
        (restriction: GenderRestriction) =>
          R.pick(['genderId', 'spayedNeuteredStatusId'], restriction),
      ),
      active: active.value,
      autocharge: autocharge.value,
    }

    newBundle.minWeight = UnitUtils.serializeWeightUnit({
      field: minWeight,
      unitsState,
      initialValue: bundle?.minWeight,
    })

    newBundle.maxWeight = UnitUtils.serializeWeightUnit({
      field: maxWeight,
      unitsState,
      initialValue: bundle?.maxWeight,
    })

    if (Number.isInteger(endAge.value)) {
      newBundle.maxAge = {
        age: endAge.value,
        unitId: endAgeUnit.value,
      }
    } else if (Number.isInteger(bundle?.maxAge?.age)) {
      newBundle.maxAge = null
    }

    if (Number.isInteger(startAge.value)) {
      newBundle.minAge = {
        age: startAge.value,
        unitId: startAgeUnit.value,
      }
    } else if (Number.isInteger(bundle?.minAge?.age)) {
      newBundle.minAge = null
    }
    return newBundle
  }

  useImperativeHandle(ref, () => ({
    validate,
    getBundle: createBundle,
    hasUnsavedChanges: () => isFieldValuesChanged(fields),
  }))

  const proceed = () => {
    if (validate() && onProceed) {
      onProceed(createBundle())
    }
  }

  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )

  const tooltipText = isChargeSheetEnabled
    ? t('Tooltips:WHENEVER_NEW_APPOINTMENT_BEGINS_CS')
    : t('Tooltips:WHENEVER_NEW_APPOINTMENT_BEGINS')

  return (
    <Grid container className={classes.root} direction="column" pb={3} px={2}>
      <PuiTextField
        field={name}
        inputProps={{ maxLength: 100 }}
        label={`${name.label}*`}
      />
      {view && (
        <Grid item mt={1}>
          <ActiveStateSwitch field={active} />
        </Grid>
      )}
      <Grid item mt={3}>
        <TextWithTooltip
          strong
          tooltipText={t(
            'Tooltips:IF_PATIENT_OR_APPOINTMENT_DOES_NOT_MEET_CRITERIA_BUNDLE',
          )}
          variant="subheading3"
        >
          {t('Admin:CATALOG.BUNDLE.SHOW_BUNDLE')}
        </TextWithTooltip>
      </Grid>
      <PuiSelectAll
        field={appointmentTypeIds}
        items={AppointmentEvent.subTypes}
        label={appointmentTypeIds.label}
      />
      <Grid container item columnSpacing={3} mt={2}>
        <Grid item xs>
          <PuiSelectAll field={species} items={Species} label={species.label} />
        </Grid>
        <Grid item xs>
          <GenderRestrictionSelect field={genderRestrictions} />
        </Grid>
      </Grid>
      <Grid container item columnSpacing={3} mt={3}>
        <Grid container item alignItems="center" xs={6}>
          <Grid item>
            <Text display="inline" variant="body">
              {t('Common:AGE_RANGE_LABEL')}:
            </Text>
          </Grid>
          <ErrorTooltip
            message={t('Tooltips:PLEASE_PROVIDE_VALID_AGE_RANGE')}
            open={!startAge.valid}
            placement="top"
          >
            <Grid item xs ml={1}>
              <AgeRangeSelector
                fullWidth
                hasClearButton
                endUnit={endAgeUnit.value}
                endValue={endAge.value}
                startUnit={startAgeUnit.value}
                startValue={startAge.value}
                onEndChange={endAge.setValue}
                onEndUnitChange={endAgeUnit.setValue}
                onOpen={() => {
                  validate({ silent: true })
                }}
                onStartChange={startAge.setValue}
                onStartUnitChange={startAgeUnit.setValue}
              />
            </Grid>
          </ErrorTooltip>
        </Grid>
        <Grid container item alignItems="center" wrap="nowrap" xs={6}>
          <WeightRangeInput
            maxWeightField={maxWeight}
            minWeightField={minWeight}
          />
        </Grid>
      </Grid>
      <Grid container item mt={1}>
        <PuiSwitch
          field={autocharge}
          label={
            <TextWithTooltip tooltipText={tooltipText} variant="body">
              {autocharge.label}
            </TextWithTooltip>
          }
        />
      </Grid>
      {!view && (
        <>
          <Grid item mt={2}>
            <RequiredFieldsNotice />
          </Grid>
          <Grid item mt={2}>
            <ButtonWithLoader
              className={classes.button}
              disabled={isLoading}
              loading={isLoading}
              onClick={proceed}
            >
              {t('Common:NEXT')}
            </ButtonWithLoader>
          </Grid>
        </>
      )}
    </Grid>
  )
})

export default Bundle
