import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { NumberUtils, PuiTheme, TextInteractive } from '@pbt/pbt-ui-components'
import { WarnAlert } from '@pbt/pbt-ui-components/src/icons'

import { getPreviewPrice } from '~/store/duck/bulkPrices'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    warnIcon: {
      fontSize: '1.4rem',
      color: theme.colors.alertWarning,
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'BulkPricesReviewRowSubItem' },
)

interface BulkPricesReviewRowSubItemProps {
  className?: string
  priceId: string
}

const BulkPricesReviewRowSubItem = ({
  className,
  priceId,
}: BulkPricesReviewRowSubItemProps) => {
  const classes = useStyles()

  const { t } = useTranslation('Prices')

  const price = useSelector(getPreviewPrice(priceId))
  const isLoading = !priceId
  const {
    newPrice,
    currentPrice,
    difference,
    name,
    percentDifference,

    markupPercent,
    active,
    deleted,
  } = price || {}

  const canUpdatePrice =
    markupPercent === null && active && !deleted && currentPrice > 0

  return (
    <Grid
      container
      item
      alignItems="center"
      className={className}
      wrap="nowrap"
    >
      <Grid item xs px={2} py={1.5}>
        <TextInteractive isLoading={isLoading}>{name}</TextInteractive>
      </Grid>
      <Grid item xs px={2} py={1.5}>
        <TextInteractive isLoading={isLoading}>
          {!canUpdatePrice && (
            <Tooltip title={t('Prices:PRICE_UPDATE_NOT_POSSIBLE')}>
              <WarnAlert className={classes.warnIcon} />
            </Tooltip>
          )}
          {NumberUtils.formatMoney(newPrice)}
        </TextInteractive>
      </Grid>
      <Grid item xs px={2} py={1.5}>
        <TextInteractive isLoading={isLoading}>
          {NumberUtils.formatMoney(currentPrice)}
        </TextInteractive>
      </Grid>
      <Grid item xs px={2} py={1.5}>
        <TextInteractive isLoading={isLoading}>
          {`${NumberUtils.formatMoney(
            difference,
          )} (${NumberUtils.toPercentFormat(percentDifference / 100, 2)})`}
        </TextInteractive>
      </Grid>
    </Grid>
  )
}

export default BulkPricesReviewRowSubItem
