import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { PuiTheme } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import {
  clearItemExistError,
  clearItemStatusError,
} from '~/store/actions/orders'
import { fetchSoap } from '~/store/actions/soap'
import { getItemExistError, getItemStatusError } from '~/store/reducers/orders'
import { getProblemsLogs } from '~/store/reducers/problems'
import { getLastSoapVital } from '~/store/reducers/soap'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    contentWithRail: {
      [theme.breakpoints.up('md')]: {
        maxWidth: `calc(100vw - ${theme.constants.leftMenuWidth}px)`,
      },
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${theme.constants.rightRailCollapsedWidth}px)`,
        maxWidth: `calc(100vw - ${
          theme.constants.leftMenuWidth +
          theme.constants.rightRailCollapsedWidth
        }px)`,
      },
    },
    contentWithoutRail: {
      [theme.breakpoints.up('md')]: {
        maxWidth: `calc(100vw - ${theme.constants.leftMenuWidth}px)`,
      },
    },
    expandedRail: {
      width: theme.constants.rightRailExpandedWidth,
    },
    hidden: {
      width: 0,
      height: 0,
    },
    rightRail: {
      top:
        theme.constants.soapHeaderHeight + theme.constants.appHeader.heightMdUp,
      [theme.breakpoints.down('md')]: {
        paddingTop: 0,
        top:
          theme.constants.soapHeaderHeight +
          theme.constants.appHeader.heightMdDown,
      },
      bottom: 0,
      height: 'auto',
    },
  }),
  { name: 'UseSoapRail' },
)

export const useSoapRail = (isReadOnly: boolean) => {
  const soapRailClasses = useStyles()

  const { soapId: urlSoapId, tabName: urlTabName } = useParams()
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()

  const orderItemExistError = useSelector(getItemExistError)
  const orderItemStatusError = useSelector(getItemStatusError)
  const lastSoapVital = useSelector(getLastSoapVital)
  const problemsLogs = useSelector(getProblemsLogs)

  const isMedium = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('lg'),
  )

  const [rightRailVisible, setRightRailVisible] = useState(true)
  const [expandedRail, setExpandedRail] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)

  const originalBusinessId = searchParams.get('originalBusinessId')

  const [openItemDoesNotExistErrorDialog] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
    () => {
      dispatch(clearItemExistError())
      if (urlSoapId) {
        dispatch(fetchSoap(urlSoapId, originalBusinessId))
      }
    },
  )

  const [openItemStatusErrorDialog] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
    () => {
      dispatch(clearItemStatusError())
    },
  )

  useEffect(() => {
    if (isReadOnly) {
      setRightRailVisible(false)
    } else if (!isReadOnly && !isMedium) {
      setRightRailVisible(true)
    }
  }, [isReadOnly])

  // Whenever we face a conflict between users/tab when making CUD operations on order by using SoapRail
  useEffect(() => {
    if (orderItemExistError) {
      openItemDoesNotExistErrorDialog({
        message: orderItemExistError,
      })
    }
  }, [orderItemExistError])

  // When an action was attempted on an item with DECLINED / CANCELLED / DELETED status
  useEffect(() => {
    if (orderItemStatusError) {
      openItemStatusErrorDialog({
        message: orderItemStatusError,
      })
    }
  }, [orderItemStatusError])

  // Reselect first soap rail tab (Summary) whenever soap vitals has deeply changed
  useDeepCompareEffect(() => {
    if (!R.isEmpty(lastSoapVital) && selectedTab !== 0) {
      setSelectedTab(0)
    }
  }, [lastSoapVital])

  // Reselect first soap rail tab (Summary) whenever someone switches tabs OR problems state changes
  useEffect(() => {
    if (selectedTab !== 0) {
      setSelectedTab(0)
    }
  }, [urlTabName, problemsLogs])

  const hideSoapRail = useCallback(() => {
    setRightRailVisible(false)
  }, [])

  return {
    expandedRail,
    hideSoapRail,
    rightRailVisible,
    selectedTab,
    setExpandedRail,
    setRightRailVisible,
    setSelectedTab,
    soapRailClasses,
  }
}
