import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Button, IconButton, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  Nil,
  PermissionArea,
  PuiTheme,
  PuiTooltip,
  Text,
  WellnessPlans,
} from '@pbt/pbt-ui-components'
import { Eye, Wellness as WellnessIcon } from '@pbt/pbt-ui-components/src/icons'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  getCRUDByArea,
  getCurrentBusinessHasActiveWellnessPlans,
  getCurrentBusinessHasOpenBoop,
  getCurrentBusinessWellnessPlansEnabled,
} from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getPatientStatus } from '~/utils'
import useDialog from '~/utils/useDialog'

import PatientMembershipIcon from '../clients/patient/PatientMembershipIcon'
import {
  getAllPatientPlansCancelled,
  getPatientHasNoMembership,
  getPatientHasPausedPlan,
  getPlanNames,
  useGroupedWellnessPlans,
} from './wellnessPlanUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    button: {
      width: '100%',
      textTransform: 'none',
      color: theme.colors.profileText,
      padding: theme.spacing(0.5, 0.75),
      borderRadius: 4,
      textAlign: 'start',
      lineHeight: '1.8rem',
    },
    multiplePlansButton: {
      alignItems: 'flex-start',
    },
    skeleton: {
      borderRadius: 3,
    },
    buttonMembership: {
      backgroundColor: theme.colors.planBranding,
      '&:hover': {
        backgroundColor: theme.colors.planBranding,
      },
    },
    buttonMembershipActive: {
      backgroundColor: theme.colors.success,
      '&:hover': {
        backgroundColor: theme.colors.success,
      },
    },
    buttonMembershipOpenBoop: {
      backgroundColor: theme.colors.success,
      '&:hover': {
        backgroundColor: theme.colors.success,
      },
    },
    buttonNoMembership: {
      paddingRight: theme.spacing(1.5),
      textDecoration: 'underline',
      backgroundColor: theme.colors.secondaryText,
      '&:hover': {
        backgroundColor: theme.colors.secondaryText,
      },
    },
    pausedMembership: {
      color: theme.colors.planWarning,
      backgroundColor: theme.colors.actionNeededBackground,
      '&:hover': {
        backgroundColor: theme.colors.actionNeededBackground,
      },
    },
    icon: {
      fontSize: '2.4rem !important',
      margin: `${theme.typography.pxToRem(3)} 0`,
    },
    startIcon: {
      marginLeft: 0,
      marginRight: theme.spacing(0.5),
    },
    endIcon: {
      marginLeft: 'auto',
      marginRight: 0,
    },
    tooltip: {
      borderColor: theme.colors.planBranding,
      '&::before': {
        borderTopColor: theme.colors.planBranding,
      },
    },
    chipTooltip: {
      borderColor: theme.colors.success,
      '&::before': {
        borderTopColor: theme.colors.success,
      },
    },
    chipButton: {
      padding: 0,
    },
    chip: {
      borderRadius: '50%',
    },
  }),
  { name: 'WellnessPlanMemberButton' },
)

export interface WellnessPlanMemberButtonProps {
  buttonClassName?: string
  chip?: boolean
  className?: string
  clientId: string | Nil
  isLoading?: boolean
  patientId: string | Nil
  readonly?: boolean
}

const WellnessPlanMemberButton = ({
  buttonClassName,
  clientId,
  isLoading,
  className,
  patientId,
  readonly: readonlyProp = false,
  chip = false,
}: WellnessPlanMemberButtonProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const patient = useSelector(getPatient(patientId))
  const openBoop = useSelector(getCurrentBusinessHasOpenBoop)
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
  )
  const {
    create: wellnessPlanCreatePermissions,
    read: wellnessPlanReadPermissions,
  } = useSelector(getCRUDByArea(PermissionArea.WELLNESS_PLAN))

  const notCancelledPlans = R.filter(
    (plan) => plan.wplanStatus !== WellnessPlans.MembershipStatus.CANCELLED,
    patient?.plans || [],
  )
  const plansNamesList = getPlanNames(notCancelledPlans)

  const wellnessPlansEnabled = useSelector(
    getCurrentBusinessWellnessPlansEnabled,
  )
  const hasActiveWellnessPlans = useSelector(
    getCurrentBusinessHasActiveWellnessPlans,
  )

  const dense = plansNamesList.length > 1

  const [openMembershipSignUpDialog] = useDialog(DialogNames.MEMBERSHIP_SIGN_UP)
  const [openPatientMembershipDialog] = useDialog(
    DialogNames.PATIENT_MEMBERSHIP,
  )

  const isCancelled = getAllPatientPlansCancelled(patient)
  const hasPaused = getPatientHasPausedPlan(patient)
  const hasNoMembership = getPatientHasNoMembership(patient)
  const shouldHideSignUp =
    hasNoMembership &&
    (getPatientStatus(patient).disabled || !hasActiveWellnessPlans)
  const readonly =
    readonlyProp ||
    (hasNoMembership
      ? !wellnessPlanCreatePermissions
      : !wellnessPlanReadPermissions)

  const {
    packages = [],
    extras = [],
    tiers = [],
  } = useGroupedWellnessPlans(notCancelledPlans)

  const tierNames = getPlanNames(tiers)
  const extrasNames = getPlanNames(extras)
  const packagesNames = getPlanNames(packages)

  const hasTiers = tierNames.length > 0
  const hasExtras = extrasNames.length > 0
  const hasPackages = packagesNames.length > 0

  const handleClick = () => {
    if (hasNoMembership) {
      openMembershipSignUpDialog({ clientId, patientId })
    } else {
      openPatientMembershipDialog({ clientId, patientId })
    }
  }

  if (isLoading) {
    return (
      <Skeleton
        className={classNames(className, classes.skeleton)}
        height={24}
        variant="rectangular"
        width={180}
      />
    )
  }

  if (!patientId || !wellnessPlansEnabled || shouldHideSignUp) {
    return null
  }

  const planNames = dense
    ? t('Common:WELLNESS')
    : plansNamesList.length
    ? plansNamesList.join(', ')
    : t('Common:PLAN_MEMBER')

  const noMembershipLabels = isBoopDisablementEnabled
    ? t('Common:ADD_PLAN')
    : t('Common:SIGN_UP_NOW')

  const planNameLabelLabel = isCancelled
    ? t('Common:CANCELLED')
    : hasNoMembership
    ? noMembershipLabels
    : planNames

  const button = (
    <Button
      className={classNames(classes.button, className, {
        [classes.buttonMembershipActive]:
          !hasNoMembership && isBoopDisablementEnabled,
        [classes.buttonMembership]:
          !hasNoMembership && !openBoop && !isBoopDisablementEnabled,
        [classes.buttonMembershipOpenBoop]:
          !hasNoMembership && openBoop && !isBoopDisablementEnabled,
        [classes.buttonNoMembership]: hasNoMembership,
        [classes.pausedMembership]: hasPaused || isCancelled,
        [classes.multiplePlansButton]: !dense && notCancelledPlans?.length > 1,
      })}
      classes={{ startIcon: classes.startIcon, endIcon: classes.endIcon }}
      endIcon={readonly || hasNoMembership ? undefined : <Eye />}
      startIcon={
        <PatientMembershipIcon className={classes.icon} patientId={patientId} />
      }
      onClick={readonly ? undefined : handleClick}
    >
      {planNameLabelLabel}
    </Button>
  )

  if (chip && (hasNoMembership || hasPaused || isCancelled)) {
    return <></>
  }

  return dense || chip ? (
    <PuiTooltip
      classes={{
        tooltip: chip ? classes.chipTooltip : classes.tooltip,
      }}
      tooltipPlacement={chip ? 'top-end' : 'top'}
      tooltipText={
        <>
          {hasTiers && (
            <Text variant="body2">{`${t('Common:PLAN')}: ${tierNames.join(
              ', ',
            )}`}</Text>
          )}
          {hasExtras && !chip && (
            <Text variant="body2">{`${t('Common:EXTRAS')}: ${extrasNames.join(
              ', ',
            )}`}</Text>
          )}
          {hasPackages && !chip && (
            <Text variant="body2">{`${t(
              'Common:SUBSCRIBE_AND_SAVES',
            )}: ${packagesNames.join(', ')}`}</Text>
          )}
        </>
      }
    >
      {chip ? (
        <IconButton
          className={classNames(classes.chipButton, buttonClassName)}
          disabled={readonly}
          onClick={readonly ? undefined : handleClick}
        >
          <WellnessIcon className={classNames(classes.icon, className)} />
        </IconButton>
      ) : (
        button
      )}
    </PuiTooltip>
  ) : (
    button
  )
}

export default WellnessPlanMemberButton
