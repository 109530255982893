import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  CopyToClipboard,
  PuiDialog,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import {
  getCurrentBusinessHasWaitListEnabled,
  getCurrentBusinessId,
} from '~/store/reducers/auth'
import { getKioskUrl } from '~/utils'
import useGoAway from '~/utils/useGoAway'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      minWidth: 372,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    button: {
      minWidth: 150,
    },
    link: {
      color: theme.colors.link,
      marginTop: theme.spacing(0.5),
    },
  }),
  { name: 'KioskDialog' },
)

export interface KioskDialogProps extends BasePuiDialogProps {}

const KioskDialog = ({ open, onClose }: KioskDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const businessId = useSelector(getCurrentBusinessId)
  const waitListEnabled = useSelector(getCurrentBusinessHasWaitListEnabled)
  const openExternalUrl = useGoAway()

  const getUrl = (path: string) => getKioskUrl(path, { businessId })
  const handleOpen = (path: string) => () => openExternalUrl(getUrl(path))

  return (
    <PuiDialog
      aria-labelledby="kiosk-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <Grid
        container
        columnSpacing={1}
        justifyContent={waitListEnabled ? 'space-between' : 'center'}
        pb={3}
        pt={8}
        px={3}
        wrap="nowrap"
      >
        <Grid container item alignItems="center" direction="column">
          <ButtonWithLoader className={classes.button} onClick={handleOpen('')}>
            {t('Common:SHOW_KIOSK')}
          </ButtonWithLoader>
          <CopyToClipboard
            classes={{ link: classes.link }}
            label={t('Common:COPY_LINK')}
            text={getUrl('')}
          />
        </Grid>
        {waitListEnabled && (
          <Grid container item alignItems="center" direction="column">
            <ButtonWithLoader
              className={classes.button}
              onClick={handleOpen('waitlist')}
            >
              {t('Common:SHOW_WAITLIST')}
            </ButtonWithLoader>
            <CopyToClipboard
              classes={{ link: classes.link }}
              label={t('Common:COPY_LINK')}
              text={getUrl('waitlist')}
            />
          </Grid>
        )}
      </Grid>
    </PuiDialog>
  )
}

export default KioskDialog
