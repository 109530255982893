import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { Business, ClassesType, PuiTheme } from '@pbt/pbt-ui-components'

import {
  editBusinessBrandingConfiguration,
  fetchBusinessBrandingConfiguration,
} from '~/store/actions/businesses'
import {
  BusinessSettingsSections,
  getInvalidPanels,
  getSectionFields,
} from '~/store/duck/businessSettings'
import { getCurrentBusiness } from '~/store/reducers/auth'

import BrandingConfiguration from '../../admin/general/practices/PracticeDetailsSections/branding-configuration/BrandingConfiguration'
import { PracticeDetailsPanels } from '../../admin/general/practices/practices'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    container: {
      justifyContent: 'flex-start',
    },
    kioskPreview: {
      width: 450,
      height: 320,
      marginTop: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(4),
      },
    },
  }),
  { name: 'WellnessPlanBranding' },
)

export interface WellnessPlanBrandingProps {
  classes?: ClassesType<typeof useStyles>
}

export interface WellnessPlanBrandingHandle {
  onProceed: () => boolean
}

const WellnessPlanBranding = forwardRef<
  WellnessPlanBrandingHandle,
  WellnessPlanBrandingProps
>(function WellnessPlanBranding({ classes: classesProp }, ref) {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const business = useSelector(getCurrentBusiness)
  const invalidPanels = useSelector(getInvalidPanels(business?.id))
  const brandingConfigurationFields = useSelector(
    getSectionFields<
      BusinessSettingsSections['BRANDING_CONFIGURATION']['fields']
    >(business?.id, PracticeDetailsPanels.BRANDING_CONFIGURATION),
  )

  const isFieldValid = !invalidPanels?.includes(
    PracticeDetailsPanels.BRANDING_CONFIGURATION,
  )

  const hasBrandingConfiguration = !R.isNil(business?.customColorsEnabled)

  useEffect(() => {
    if (!hasBrandingConfiguration && business?.id) {
      dispatch(fetchBusinessBrandingConfiguration(business.id))
    }
  }, [hasBrandingConfiguration])

  useImperativeHandle(ref, () => ({
    onProceed: () => {
      const configuration = {
        ...brandingConfigurationFields,
        customColorsEnabled: true,
      }
      const hasChanged = !R.whereEq(configuration, business)

      if (isFieldValid && hasChanged && business?.id) {
        dispatch(editBusinessBrandingConfiguration(business.id, configuration))
      }

      return true
    },
  }))

  return (
    <BrandingConfiguration
      business={business || ({} as Business)}
      classes={{
        kioskPreview: classes.kioskPreview,
        container: classes.container,
      }}
      showCallOut={false}
      showSwitch={false}
    />
  )
})

export default WellnessPlanBranding
