import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AlertIconType,
  BasePuiDialogProps,
  ButtonWithLoader,
  CircularProgressOverlay,
  Nil,
  PuiDialog,
  PuiTheme,
  Utils,
} from '@pbt/pbt-ui-components'

import DownloadButton from '~/components/common/buttons/DownloadButton'
import CertificatePreviewGenerateTypes from '~/constants/CertificatePreviewGenerateTypes'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { fetchSOAPOrders } from '~/store/actions/soap'
import { getPrintIsLoading, print } from '~/store/duck/print'
import {
  clearGeneratedAttachmentId,
  generateProofOfVaccinations,
  generateRabiesCertificate,
  getLastGeneratedAttachmentId,
  getPatientFiles,
  getRabiesVaccinations,
} from '~/store/duck/vaccination'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getSelectedOrders } from '~/store/reducers/orders'
import {
  getClientId,
  getDoctorId,
  getFiles as getSoapFiles,
} from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'
import { RabiesVaccination, SoapFile, VaccinationSignature } from '~/types'
import useDialog from '~/utils/useDialog'

import DocumentPreview from '../../admin/catalog/documents/DocumentPreview'
import { useCanUpdateSignature } from '../../soapV2/utils/useCanUpdateSignature'
import { useMissingVaccineInfo } from '../../soapV2/utils/useMissingVaccineInfo'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      maxWidth: '100%',
    },
    paperOld: {
      maxWidth: 660,
    },
    button: {
      minWidth: 150,
      height: 40,
    },
    loader: {
      position: 'static',
      paddingTop: theme.spacing(3),
    },
  }),
  { name: 'VaccineCertificatePreviewDialog' },
)

export interface VaccineCertificatePreviewProps {
  clientId?: string | Nil
  document?: SoapFile
  generateType?: CertificatePreviewGenerateTypes
  patientId: string | Nil
  showSkipButton?: boolean
  signatureState?: VaccinationSignature
  soapBusinessId: string | Nil
  soapId: string | Nil
  vaccinationIds?: string[]
}

interface VaccineCertificatePreviewDialogProps
  extends BasePuiDialogProps,
    VaccineCertificatePreviewProps {}

const VaccineCertificatePreviewDialog = ({
  document: documentProp = {} as SoapFile,
  generateType,
  soapId,
  soapBusinessId,
  clientId: clientIdProp,
  patientId,
  vaccinationIds,
  signatureState = {},
  showSkipButton,
  open,
  onClose,
}: VaccineCertificatePreviewDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const soapFiles = useSelector(getSoapFiles)
  const soapDoctorId = useSelector(getDoctorId)
  const soapDoctor = useSelector(getUser(soapDoctorId))
  const patientFiles = useSelector(getPatientFiles(patientId ?? '')) || []
  const files = soapId ? soapFiles : patientFiles
  const clientIdFromSoap = useSelector(getClientId)
  const clientId = clientIdProp || clientIdFromSoap
  const printIsLoading = useSelector(getPrintIsLoading)
  const lastGeneratedAttachmentId = useSelector(getLastGeneratedAttachmentId)
  const patientRabiesVaccinations = useSelector(
    getRabiesVaccinations(patientId),
  )

  const isDocumentFullScreenEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DOCUMENT_FULL_SCREEN),
  )

  const rabiesVaccinations = useMemo(
    () =>
      patientRabiesVaccinations.filter((vaccination) =>
        vaccinationIds?.includes(vaccination.id),
      ),
    [patientRabiesVaccinations],
  )
  const uniqueSOAPVaccinations = useMemo(
    () => R.uniqBy(R.prop('soapId'), rabiesVaccinations),
    [rabiesVaccinations],
  )
  const selectedOrders = useSelector(getSelectedOrders)
  const rabiesOrders = selectedOrders.filter((vaccineOrder) =>
    vaccinationIds?.includes(vaccineOrder.id),
  )
  const missingRabiesInfo = useMissingVaccineInfo(rabiesOrders)
  const isMissingRabiesInfo = missingRabiesInfo.length > 0
  const [updatedRabiesInfo, setUpdatedRabiesInfo] = useState(false)
  const currentBusiness = useSelector(getCurrentBusiness)
  const isAutogenerateVaccineCertificatesEnabled =
    currentBusiness?.autoGenerateVaccineCertificates

  const canUpdateSignature = useCanUpdateSignature()

  const [openEmailAttachmentDialog] = useDialog(
    DialogNames.EMAIL_ATTACHMENT_DIALOG,
  )

  const cancelFlowFromMissingInfoDialog = () => {
    // Only close the current dialog if the
    // missing info dialog didn't save changes
    if (!updatedRabiesInfo && onClose) {
      onClose()
    }
  }

  const [
    openVaccineCertificateValidationDialog,
    closeValidationDialog,
    isValidationDialogOpen,
  ] = useDialog(
    DialogNames.VACCINE_CERTIFICATE_VALIDATION,
    cancelFlowFromMissingInfoDialog,
  )

  const [openAlert, closeAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
    cancelFlowFromMissingInfoDialog,
  )

  const [document, setDocument] = useState(documentProp)
  const [isLoading, setIsLoading] = useState(false)

  const documentEmpty = R.isEmpty(document)

  useEffect(
    () => () => {
      dispatch(clearGeneratedAttachmentId())
    },
    [],
  )

  useEffect(() => {
    if (
      generateType === CertificatePreviewGenerateTypes.RABIES &&
      rabiesOrders.length > 0 &&
      isMissingRabiesInfo &&
      !isValidationDialogOpen &&
      open &&
      isAutogenerateVaccineCertificatesEnabled
    ) {
      if (
        !canUpdateSignature &&
        rabiesOrders.some((order) => !order.signatureUrl)
      ) {
        openAlert({
          iconType: AlertIconType.WARN,
          message: t(
            'Dialogs:VACCINE_CERTIFICATE_MISSING_PERMISSIONS_DIALOG.ONLY_ADMINISTERING_VETERINARIAN',
            { veterinarianName: Utils.getPersonString(soapDoctor) },
          ),
          okButtonText: t('Common:OK'),
          onOk: closeAlert,
        })
      } else {
        openVaccineCertificateValidationDialog({
          clientId,
          patientId,
          vaccines: rabiesOrders,
          requireRabiesUpdates: true,
          showSkipButton,
          onOk: () => setUpdatedRabiesInfo(true),
        })
      }
    }
  }, [rabiesOrders.length])

  useEffect(() => {
    if (
      uniqueSOAPVaccinations &&
      generateType === CertificatePreviewGenerateTypes.RABIES &&
      isAutogenerateVaccineCertificatesEnabled
    ) {
      uniqueSOAPVaccinations.forEach((rabiesVaccination: RabiesVaccination) => {
        dispatch(
          fetchSOAPOrders(
            rabiesVaccination.soapId || '',
            rabiesVaccination.businessId,
          ),
        )
      })
    }
  }, [uniqueSOAPVaccinations])

  useEffect(() => {
    if (patientId && vaccinationIds) {
      if (
        generateType === CertificatePreviewGenerateTypes.RABIES &&
        (!isAutogenerateVaccineCertificatesEnabled ||
          (rabiesOrders.length > 0 && !isMissingRabiesInfo && documentEmpty))
      ) {
        dispatch(
          generateRabiesCertificate(
            patientId,
            vaccinationIds,
            soapId,
            soapBusinessId,
          ),
        )
      }

      if (
        generateType === CertificatePreviewGenerateTypes.PROOF_OF_VACCINATIONS
      ) {
        dispatch(
          generateProofOfVaccinations(
            patientId,
            vaccinationIds,
            signatureState,
            soapId,
            soapBusinessId,
          ),
        )
      }
    }
  }, [generateType, isMissingRabiesInfo, rabiesOrders.length])

  useEffect(() => {
    if (documentEmpty && generateType && files && lastGeneratedAttachmentId) {
      const file = (files as SoapFile[]).find(
        ({ id }) => id === lastGeneratedAttachmentId,
      )
      if (file) {
        setDocument(file)
      }
    }
  }, [files, lastGeneratedAttachmentId])

  const handleSendEmail = () => {
    if (onClose) {
      onClose()
    }
    openEmailAttachmentDialog({
      soapId,
      patientId,
      clientId,
      selectedDocuments: document?.id ? [document.id] : [],
      scopedAttachments: files?.length ? files : [document],
    })
  }

  return (
    <PuiDialog
      fullWidth
      actions={
        !documentEmpty && (
          <Grid container item>
            <Grid item mr={1}>
              <ButtonWithLoader
                className={classes.button}
                loading={documentEmpty || printIsLoading}
                onClick={() => {
                  dispatch(print(document.fileUrl))
                }}
              >
                {t('Common:PRINT_ACTION')}
              </ButtonWithLoader>
            </Grid>
            <Grid item>
              <ButtonWithLoader
                className={classes.button}
                loading={printIsLoading || isLoading}
                onClick={handleSendEmail}
              >
                {t('Common:EMAIL')}
              </ButtonWithLoader>
            </Grid>
            <Grid item ml="auto">
              <DownloadButton
                round
                onClick={() => {
                  window.open(document.fileUrl)
                }}
              />
            </Grid>
          </Grid>
        )
      }
      aria-labelledby="vaccine-certificate-preview-dialog"
      classes={{
        paper: isDocumentFullScreenEnabled ? classes.paper : classes.paperOld,
      }}
      disableClose={isLoading}
      open={open}
      scroll="paper"
      title={t('Common:PREVIEW_NOUN')}
      onClose={() => {
        if (onClose) {
          onClose()
        }
        closeValidationDialog()
      }}
    >
      <CircularProgressOverlay
        classes={{ loaderContainer: classes.loader }}
        open={documentEmpty}
        preloaderText={t(
          'Dialogs:VACCINE_CERTIFICATE_PREVIEW_DIALOG.GENERATING_CERTIFICATE',
        )}
        progressSize={48}
      />
      <DocumentPreview
        hideTopButtons
        view
        document={document}
        fullScreen={isDocumentFullScreenEnabled}
        setIsLoading={setIsLoading}
        showChangeFile={false}
      />
    </PuiDialog>
  )
}

export default VaccineCertificatePreviewDialog
