import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, PuiTheme } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import DocumentDialogStates from '~/constants/DocumentDialogStates'
import { matchImageExtension } from '~/constants/extensions'
import { deleteFile } from '~/store/actions/soap'
import { SoapFile } from '~/types'
import { getDeleteConfirmMessage } from '~/utils'
import useDialog from '~/utils/useDialog'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import AttachmentBody from './AttachmentBody'
import AttachmentHeader from './AttachmentHeader'
import AttachmentPreview from './AttachmentPreview'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      borderRadius: 2,
      backgroundColor: theme.constants.tableBackground,
      border: theme.constants.tableBorder,
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    rootExpanded: {
      paddingTop: theme.spacing(1),
    },
    textContainer: {
      overflow: 'hidden',
      marginLeft: theme.spacing(1),
    },
    attachmentContainer: {
      cursor: 'pointer',
    },
  }),
  { name: 'Attachment' },
)

export interface AttachmentProps {
  attachment: SoapFile
  classes?: ClassesType<typeof useStyles>
  editDisabled?: boolean
  enableBusinessShareIcon?: boolean
  expanded?: boolean
  hideAddedBy?: boolean
  hideDate?: boolean
  inlineHeader?: boolean
  onDelete?: (id: string) => void
  onEmail?: () => void
  onPrint?: () => void
}

const Attachment = ({
  classes: classesProp,
  editDisabled,
  hideAddedBy = false,
  hideDate = false,
  attachment,
  expanded,
  inlineHeader,
  onDelete,
  onPrint,
  onEmail,
  enableBusinessShareIcon,
}: AttachmentProps) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const { soapId } = useParams()
  const { t } = useTranslation('Common')

  const isContextItem = useIsCurrentContextItem(attachment)

  const { extension = '', author } = attachment
  const isImage = matchImageExtension(extension)

  const [openDocumentDialog] = useDialog(DialogNames.DOCUMENT)
  const [openDeleteAttachmentAlert, closeDeleteAttachmentAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const handlePreview = () => {
    openDocumentDialog({
      previewOnly: true,
      document: attachment,
      PreviewProps: {
        hideTopButtons: Boolean(soapId),
        view: true,
        isSOAP: Boolean(soapId),
        showChangeFile: false,
      },
      step: DocumentDialogStates.PREVIEW,
    })
  }

  const onDeleteAttachment = () => {
    if (typeof onDelete === 'function') {
      onDelete(attachment.id)
    } else {
      openDeleteAttachmentAlert({
        message: getDeleteConfirmMessage('attachment'),
        cancelButtonText: t('Common:NO_KEEP'),
        okButtonText: t('Common:YES_DELETE'),
        onCancel: () => closeDeleteAttachmentAlert(),
        onOk: () => {
          dispatch(deleteFile(attachment.id))
          closeDeleteAttachmentAlert()
        },
      })
    }
  }

  const header = (
    <AttachmentHeader
      attachment={attachment}
      author={author}
      editDisabled={editDisabled}
      expanded={expanded}
      hideAddedBy={hideAddedBy}
      hideDate={hideDate}
      hideDelete={!isContextItem}
      onDeleteRequested={onDeleteAttachment}
      onEmailRequested={onEmail}
      onPreviewRequested={handlePreview}
      onPrintRequested={onPrint}
    />
  )

  const onClick = () => {
    if (isImage && !expanded) {
      handlePreview()
    }
  }

  return (
    <Grid
      container
      className={classNames(classes.root, {
        [classes.rootExpanded]: expanded,
      })}
      direction="column"
    >
      {!expanded && header}
      <Grid
        container
        item
        className={classNames({
          [classes.attachmentContainer]: isImage && !expanded,
        })}
        onClick={onClick}
      >
        <AttachmentPreview
          attachment={attachment}
          variant={expanded ? 'large' : 'small'}
        />
        <Grid
          container
          item
          xs
          className={classes.textContainer}
          direction={inlineHeader ? 'row-reverse' : 'column'}
          wrap="nowrap"
        >
          <Grid item>{expanded && header}</Grid>
          <Grid item xs>
            <AttachmentBody
              attachment={attachment}
              enableBusinessShareIcon={enableBusinessShareIcon}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Attachment
