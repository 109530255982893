import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  GenderRestriction,
  NamedEntity,
  Nil,
  PermissionArea,
  PuiCheckbox,
  PuiSelect,
  PuiTextArea,
  PuiTextField,
  SpeciesConstant,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import ActiveStateSwitch from '~/components/common/ActiveStateSwitch'
import GenderRestrictionSelect from '~/components/common/inputs/gender/GenderRestrictionSelect'
import PuiSelectAll from '~/components/common/inputs/PuiSelectAll'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getDocumentTypes,
  getEventType,
  getSpecies,
} from '~/store/reducers/constants'
import { getDocumentsIsSending } from '~/store/reducers/documents'
import { DataHandleWithUnsavedChanges, Document as DocumentType } from '~/types'
import { getConstantsList, isFieldValuesChanged } from '~/utils'
import useFieldsChanged from '~/utils/useFieldsChanged'

const useStyles = makeStyles(
  () => ({
    root: {},
    button: {
      width: 152,
    },
    textArea: {
      fontSize: '1.6rem',
    },
  }),
  { name: 'Document' },
)

export interface DocumentHandle extends DataHandleWithUnsavedChanges {}

interface DocumentProps {
  document: DocumentType | Nil
  onDocumentChange?: () => void
  onProceed?: (newDocument: DocumentType) => void
  view?: boolean
}

const Document = forwardRef<DocumentHandle, DocumentProps>(function Document(
  { document, view = false, onProceed = R.F, onDocumentChange = R.F },
  ref,
) {
  const classes = useStyles()
  const isSending = useSelector(getDocumentsIsSending)
  const DocumentTypes = useSelector(getDocumentTypes)
  const EventType = useSelector(getEventType)
  const Species = useSelector(getSpecies)
  const permissions = useSelector(getCRUDByArea(PermissionArea.FORM))
  const { t } = useTranslation(['Admin', 'Common', 'Tooltips'])

  const AppointmentEvent =
    Utils.findConstantByName('Appointment', EventType) || {}
  const FormType = Utils.findConstantIdByName('Form', DocumentTypes) || {}
  const NotesTemplateType =
    Utils.findConstantIdByName('Notes template', DocumentTypes) || {}
  const CommunicationTemplateType =
    Utils.findConstantIdByName('Communication template', DocumentTypes) || {}

  const isCreate = !document

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'name',
        label: t('Common:NAME'),
        validators: ['required'],
        initialValue: isCreate ? '' : document.name,
      },
      {
        name: 'type',
        label: t('Common:TYPE_ONE'),
        validators: ['required'],
        initialValue: isCreate ? FormType : document.type,
      },
      {
        name: 'isRequired',
        label: t('Admin:CATALOG.DOCUMENT.DOCUMENT_REQUIRED'),
        type: 'toggle',
        initialValue: isCreate ? false : document.required || false,
      },
      {
        name: 'includeInKiosk',
        label: t('Admin:CATALOG.DOCUMENT.INCLUDE_IN_KIOSK_SYSTEM'),
        type: 'toggle',
        initialValue: isCreate ? false : document.includeInKiosk || false,
      },
      {
        name: 'appointmentTypeIds',
        label: t('Common:APPOINTMENT_TYPES'),
        type: 'select',
        initialValue: isCreate
          ? []
          : getConstantsList(
              document.appointmentTypeIds,
              AppointmentEvent.subTypes,
            ),
      },
      {
        name: 'species',
        label: t('Common:SPECIES'),
        type: 'select',
        initialValue: isCreate
          ? []
          : getConstantsList(document.speciesIds, Species),
      },
      {
        name: 'genderRestrictions',
        label: t('Common:GENDER'),
        type: 'select',
        initialValue: isCreate ? [] : document.genderRestrictions || [],
      },
      {
        name: 'active',
        type: 'toggle',
        initialValue: isCreate ? true : document.active || false,
      },
      {
        name: 'subject',
        label: t('Common:EMAIL_SUBJECT'),
        initialValue: isCreate ? '' : document.subject,
      },
    ],
    false,
  )

  const {
    name,
    type,
    isRequired,
    includeInKiosk,
    appointmentTypeIds,
    species,
    genderRestrictions,
    active,
    subject,
  } = fields

  const isCommunicationTemplate = type.value === CommunicationTemplateType
  const isNotesTemplate = type.value === NotesTemplateType
  const showRequiredRestriction = !isNotesTemplate && !isCommunicationTemplate

  useEffect(() => {
    reset()
  }, [document])

  useFieldsChanged(() => {
    onDocumentChange()
  }, fields)

  const createDocument = () => ({
    ...document,
    name: name.value,
    type: type.value,
    required: showRequiredRestriction ? isRequired.value : false,
    includeInKiosk: includeInKiosk.value,
    active: active.value,
    appointmentTypeIds: R.pluck(
      'id',
      appointmentTypeIds.value as NamedEntity[],
    ),
    speciesIds: R.pluck('id', species.value as SpeciesConstant[]),
    genderRestrictions: (
      (genderRestrictions.value as GenderRestriction[]) || []
    ).map((restriction) =>
      R.pick(['genderId', 'spayedNeuteredStatusId'], restriction),
    ),
    subject: subject.value,
  })

  useImperativeHandle(ref, () => ({
    validate,
    get: createDocument,
    hasUnsavedChanges: () => isFieldValuesChanged(fields),
  }))

  const proceed = () => {
    if (validate()) {
      onProceed(createDocument() as DocumentType)
    }
  }

  return (
    <Grid
      container
      item
      className={classes.root}
      direction="column"
      pb={2}
      px={3}
      wrap="nowrap"
    >
      <PuiTextField
        disabled={!permissions.update}
        field={name}
        inputProps={{ maxLength: 100 }}
        label={`${name.label}*`}
      />
      <Grid container item>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="document-type-select">
              {type.label}*
            </InputLabel>
            <PuiSelect
              disabled={!permissions.update}
              field={type}
              input={<Input id="document-type-select" />}
              items={DocumentTypes}
              renderEmpty={false}
            />
          </FormControl>
        </Grid>
      </Grid>
      {!isCreate && (
        <Grid item mt={1}>
          <ActiveStateSwitch disabled={!permissions.update} field={active} />
        </Grid>
      )}
      <Grid container item direction="column" mb={2} mt={4}>
        <TextWithTooltip
          strong
          tooltipText={t(
            'Tooltips:IF_PATIENT_OR_APPOINTMENT_DOES_NOT_MEET_CRITERIA_DOCUMENT',
          )}
          variant="body2"
        >
          {t('Common:RESTRICTION_OTHER')}
        </TextWithTooltip>
        {showRequiredRestriction && (
          <PuiCheckbox
            disabled={!permissions.update}
            field={isRequired}
            label={
              <TextWithTooltip
                tooltipText={t('Tooltips:REQUIRED_DOCUMENTS')}
                variant="body"
              >
                {isRequired.label}
              </TextWithTooltip>
            }
          />
        )}
        <PuiCheckbox
          disabled={!permissions.update}
          field={includeInKiosk}
          label={
            <TextWithTooltip
              tooltipText={t('Tooltips:DOCUMENT_WILL_APPEAR_FOR_CLIENT')}
              variant="body"
            >
              {includeInKiosk.label}
            </TextWithTooltip>
          }
        />
      </Grid>
      <PuiSelectAll
        disabled={!permissions.update}
        field={appointmentTypeIds}
        items={AppointmentEvent.subTypes}
        label={appointmentTypeIds.label}
      />
      <Grid container item columnSpacing={3} mt={2}>
        <Grid item xs>
          <PuiSelectAll
            disabled={!permissions.update}
            field={species}
            items={Species}
            label={species.label}
          />
        </Grid>
        <Grid item xs>
          <GenderRestrictionSelect
            disabled={!permissions.update}
            field={genderRestrictions}
          />
        </Grid>
      </Grid>
      {view && isCommunicationTemplate && (
        <Grid item mt={3}>
          <PuiTextArea
            classes={{
              input: classes.textArea,
            }}
            disabled={!permissions.update}
            label={subject.label}
            minRows={1}
            value={subject.value}
            onChange={subject.set}
          />
        </Grid>
      )}
      {!view && (
        <>
          <Grid item mt={1}>
            <RequiredFieldsNotice />
          </Grid>
          <Grid item mt={2}>
            <ButtonWithLoader
              className={classes.button}
              loading={isSending}
              onClick={proceed}
            >
              {t('Common:NEXT')}
            </ButtonWithLoader>
          </Grid>
        </>
      )}
    </Grid>
  )
})

export default Document
