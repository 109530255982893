import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  LanguageUtils,
  PuiCheckbox,
  PuiDialog,
  PuiTheme,
  Utils,
  WellnessPlanBenefit,
  WplanCoverAppointmentTypeConstant,
} from '@pbt/pbt-ui-components'

import {
  getAppointmentTypes,
  getWellnessPlanCoverAppointmentType,
} from '~/store/reducers/constants'
import { getWellnessPlanGlobalBenefitGroups } from '~/store/reducers/wellnessPlans'
import useGetBusinessSupportedAppointments from '~/utils/useGetBusinessSupportedAppointments'

import { getBenefitIsTelehealth } from '../../wellnessPlanUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
    button: {
      width: 150,
    },
    row: {
      cursor: 'pointer',
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
  }),
  { name: 'SelectBenefitAppointmentTypeDialog' },
)

interface SelectBenefitAppointmentTypeDialogProps extends BasePuiDialogProps {
  benefit: WellnessPlanBenefit
  onSelected: (ids: string[]) => void
}

const SelectBenefitAppointmentTypeDialog = ({
  benefit,
  open,
  onClose,
  onSelected,
}: SelectBenefitAppointmentTypeDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const AppointmentTypes = useSelector(getAppointmentTypes)
  const WellnessPlanCoverAppointmentType: WplanCoverAppointmentTypeConstant[] =
    useSelector(getWellnessPlanCoverAppointmentType)
  const globalBenefitGroups = useSelector(getWellnessPlanGlobalBenefitGroups)

  const TelehealthAppointmentTypes: string[] =
    Utils.findByName('TELEHEALTH', WellnessPlanCoverAppointmentType)
      ?.appointmentTypes || []
  const RegularAppointmentTypes: string[] =
    Utils.findByName('REGULAR', WellnessPlanCoverAppointmentType)
      ?.appointmentTypes || []

  const group = Utils.findById(benefit.groupId, globalBenefitGroups)
  const GlobalBenefit = Utils.findById(
    benefit.globalBenefitId,
    group?.benefits || [],
  )
  const isTelehealth = getBenefitIsTelehealth(GlobalBenefit || benefit)

  const rawList = isTelehealth
    ? TelehealthAppointmentTypes
    : RegularAppointmentTypes

  const list = useGetBusinessSupportedAppointments(rawList)

  const [selectedAppointmentTypeIds, setSelectedAppointmentTypeIds] = useState(
    benefit?.appointmentTypeIds || [],
  )

  const proceed = () => {
    onSelected(selectedAppointmentTypeIds)
    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader className={classes.button} onClick={proceed}>
          {t('Common:ADD_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="select-benefit-appointment-type-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      scroll="paper"
      title={t('Common:ADD_APPOINTMENT_TYPE')}
      onClose={onClose}
    >
      {list.map((id) => {
        const type = Utils.findById(id, AppointmentTypes)
        const isChecked = selectedAppointmentTypeIds.includes(id)

        return (
          <Grid
            container
            item
            className={classes.row}
            key={id}
            px={2}
            onClick={(event: React.MouseEvent) => {
              event.preventDefault()
              setSelectedAppointmentTypeIds(
                Utils.toggleListItem(id, selectedAppointmentTypeIds),
              )
            }}
          >
            <PuiCheckbox
              checked={isChecked}
              label={LanguageUtils.getTranslatedFieldName(type)}
            />
          </Grid>
        )
      })}
    </PuiDialog>
  )
}

export default SelectBenefitAppointmentTypeDialog
