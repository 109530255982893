import React, { forwardRef } from 'react'
import {
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  SwitchProps,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, Field, PuiTheme } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    switchLabel: {
      color: theme.colors.secondaryText,
      fontSize: '1.6rem',
    },
  }),
  { name: 'PuiSwitch' },
)

export interface PuiSwitchProps extends SwitchProps {
  classes?: ClassesType<typeof useStyles> & SwitchProps['classes']
  field?: Field
  label?: FormControlLabelProps['label']
}

const PuiSwitch = forwardRef<HTMLButtonElement, PuiSwitchProps>(
  function PuiSwitch(
    {
      label = '',
      className,
      checked: initialChecked,
      onChange: initialOnChange,
      field,
      classes: classesProp,
      ...rest
    },
    ref,
  ) {
    const classes = useStyles({ classes: classesProp })

    const checked = Boolean(field?.value ?? initialChecked)
    const onChange = field?.set ?? initialOnChange

    return (
      <FormControlLabel
        classes={{
          root: classes.root,
          label: classNames(classes.switchLabel, className),
        }}
        control={
          <Switch
            checked={checked}
            color="primary"
            ref={ref}
            value={checked}
            onChange={onChange}
            {...rest}
          />
        }
        label={label}
      />
    )
  },
)

export default PuiSwitch
