import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import { ConfirmAlertType } from '~/constants/DialogNames'
import { getProgressEntities } from '~/store/reducers/migration'

import CoreMigrationProgressComponent from './CoreMigrationProgressComponent'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    button: {
      height: 40,
      padding: theme.spacing(0, 3),
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'ImportAlreadyDoneDialog' },
)

interface ImportAlreadyDoneDialogProps extends BasePuiDialogProps {
  onStart: () => void
}

const ImportAlreadyDoneDialog = ({
  open,
  onClose,
  onStart,
}: ImportAlreadyDoneDialogProps) => {
  const classes = useStyles()
  const entities = useSelector(getProgressEntities) || []
  const { t } = useTranslation(['Common', 'Businesses'])

  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.START_ALREADY_DONE_IMPORT,
  })

  const handleContinue = () => {
    openConfirmAlert({
      message: t('Businesses:MIGRATION.IMPORT_ALREADY_DONE_MESSAGE'),
      okButtonText: t('Common:IMPORT'),
      preventShowAgainCheckBox: false,
      onConfirm: (proceed) => {
        if (proceed) {
          onStart()
        }
        if (onClose) {
          onClose()
        }
      },
    })
  }

  return (
    <PuiDialog
      fullWidth
      aria-labelledby="import-already-done-dialog"
      maxWidth="sm"
      open={open}
      scroll="paper"
      title={t('Businesses:MIGRATION.IMPORT_ALREADY_DONE')}
      onClose={onClose}
    >
      <CoreMigrationProgressComponent finished entities={entities} />
      <Grid container justifyContent="flex-start" pb={1} pr={2} pt={0}>
        <ButtonWithLoader
          className={classes.button}
          color="secondary"
          onClick={onClose}
        >
          {t('Common:CANCEL_ACTION')}
        </ButtonWithLoader>
        <ButtonWithLoader
          className={classes.button}
          color="primary"
          onClick={handleContinue}
        >
          {t('Businesses:MIGRATION.START_IMPORT')}
        </ButtonWithLoader>
      </Grid>
    </PuiDialog>
  )
}

export default ImportAlreadyDoneDialog
