import React from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import ExpandButton from '~/components/common/buttons/ExpandButton'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      border: '1px solid transparent',
    },
    checkbox: {
      padding: theme.spacing(0.5),
    },
  }),
  { name: 'BulkPricesEditListHeader' },
)

interface BulkPricesEditListHeaderProps {
  allExpanded: boolean
  allSelected: boolean
  onExpansionChange: (allExpanded: boolean) => void
  onSelectionChange: (allSelected: boolean) => void
}

const BulkPricesEditListHeader = ({
  allSelected,
  allExpanded,
  onSelectionChange,
  onExpansionChange,
}: BulkPricesEditListHeaderProps) => {
  const { t } = useTranslation(['Common', 'Prices'])
  const classes = useStyles()

  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      columns={{ xs: 10 }}
      pl={0.5}
      pr={2}
    >
      <Grid item width={70}>
        <Checkbox
          checked={allSelected}
          className={classes.checkbox}
          onChange={() => onSelectionChange(!allSelected)}
        />
        <ExpandButton
          expanded={allExpanded}
          onClick={() => onExpansionChange(!allExpanded)}
        />
      </Grid>
      <Grid item xs pl={1} py={0.5}>
        <Text strong variant="lowAccent2">
          {t('Common:DESCRIPTION')}
        </Text>
      </Grid>
      <Grid item pl={1} py={0.5} xs={1}>
        <Text strong variant="lowAccent2">
          {t('Prices:NEW_PRICE')}
        </Text>
      </Grid>
      <Grid item pl={1} py={0.5} xs={1}>
        <Text strong variant="lowAccent2">
          {t('Prices:CURRENT_PRICE')}
        </Text>
      </Grid>
      <Grid item pl={1} py={0.5} xs={1}>
        <Text strong variant="lowAccent2">
          {t('Common:UNIT')}
        </Text>
      </Grid>
      <Grid item pl={1} py={0.5} xs={1}>
        <Text strong variant="lowAccent2">
          {t('Prices:LAST_UPDATED')}
        </Text>
      </Grid>
      <Grid item pl={1} py={0.5} xs={1}>
        <Text strong variant="lowAccent2">
          {t('Common:SPECIES')}
        </Text>
      </Grid>
      <Grid item pl={1} py={0.5} xs={1}>
        <Text strong variant="lowAccent2">
          {t('Common:GENDER')}
        </Text>
      </Grid>
    </Grid>
  )
}

export default BulkPricesEditListHeader
