import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { PermissionArea, PuiTheme, useFields } from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import DialogNames from '~/constants/DialogNames'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import {
  AutoReplyMessageConfig,
  BasePracticeDetailsSectionProps,
} from '~/types'
import { hasHtmlFieldChanged } from '~/utils/htmlUtils'
import useDialog from '~/utils/useDialog'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'
import MessageWithPreviewConfiguration from '../wellness-plans/MessageWithPreviewConfiguration'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    richEdit: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'BoopCommunicationsConfig' },
)

const BoopCommunicationsConfig = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Businesses')

  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )

  const [openBoopSlaMessagesPreviewDialog] = useDialog(
    DialogNames.BOOP_SLA_MESSAGES_PREVIEW_DIALOG,
  )

  const settings = business.boopCommunicationSettings

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'businessHoursMessageEnabled',
        label: t(
          'Businesses:BOOP_COMMUNICATIONS.BUSINESS_HOURS_MESSAGE_ENABLED',
        ),
        type: 'toggle',
        initialValue: settings?.openHoursMessage?.enabled || false,
      },
      {
        name: 'businessHoursMessage',
        label: t('Businesses:BOOP_COMMUNICATIONS.BUSINESS_HOURS_MESSAGE'),
        initialValue: settings?.openHoursMessage?.message || '',
      },
      {
        name: 'afterHoursMessageEnabled',
        label: t('Businesses:BOOP_COMMUNICATIONS.AFTER_HOURS_MESSAGE_ENABLED'),
        type: 'toggle',
        initialValue: settings?.afterHoursMessage?.enabled || false,
      },
      {
        name: 'afterHoursMessage',
        label: t('Businesses:BOOP_COMMUNICATIONS.AFTER_HOURS_MESSAGE'),
        initialValue: settings?.afterHoursMessage?.message || '',
      },
    ],
    false,
  )

  const {
    businessHoursMessageEnabled,
    businessHoursMessage,
    afterHoursMessageEnabled,
    afterHoursMessage,
  } = fields

  usePracticeFieldsSection({
    business,
    fields,
    validate,
    reset,
    resetDependencies: [business.boopCommunicationSettings],
    sectionName: PracticeDetailsPanels.BOOP_COMMUNICATIONS,
    parsedFields: {
      boopCommunicationSettings: {
        ...settings,
        openHoursMessage: {
          ...settings?.openHoursMessage,
          enabled: businessHoursMessageEnabled.value,
          message:
            businessHoursMessage.value || businessHoursMessage.initialValue,
        },
        afterHoursMessage: {
          ...settings?.openHoursMessage,
          enabled: afterHoursMessageEnabled.value,
          message: afterHoursMessage.value || afterHoursMessage.initialValue,
        },
      },
    },
    softUpdate: R.any(Boolean, [
      hasHtmlFieldChanged(afterHoursMessage),
      hasHtmlFieldChanged(businessHoursMessage),
      businessHoursMessageEnabled.value !==
        businessHoursMessageEnabled.initialValue,
      afterHoursMessageEnabled.value !== afterHoursMessageEnabled.initialValue,
    ]),
  })

  const openBoopSLAMessagesPreview = (message: string) => {
    const config: AutoReplyMessageConfig = { message }

    openBoopSlaMessagesPreviewDialog({
      config,
      business,
    })
  }

  const openBusinessHoursMessagePreview = () => {
    openBoopSLAMessagesPreview(businessHoursMessage.value)
  }

  const openAfterHoursMessagePreview = () => {
    openBoopSLAMessagesPreview(afterHoursMessage.value)
  }

  return (
    <>
      <PuiSwitch
        disabled={!permissions.update}
        field={businessHoursMessageEnabled}
        label={businessHoursMessageEnabled.label}
      />
      <MessageWithPreviewConfiguration
        resetStateOnValueChange
        showDynamicText
        className={classes.richEdit}
        disabled={!permissions.update}
        field={businessHoursMessage}
        showButtons={false}
        tooltipText={t(
          'Businesses:BOOP_COMMUNICATIONS.BUSINESS_MESSAGE_TOOLTIP',
        )}
        tooltipTitle={businessHoursMessage.label}
        onPreview={openBusinessHoursMessagePreview}
      />
      <Grid item mt={4}>
        <PuiSwitch
          disabled={!permissions.update}
          field={afterHoursMessageEnabled}
          label={afterHoursMessageEnabled.label}
        />
      </Grid>
      <MessageWithPreviewConfiguration
        resetStateOnValueChange
        showDynamicText
        className={classes.richEdit}
        disabled={!permissions.update}
        field={afterHoursMessage}
        showButtons={false}
        tooltipText={t('Businesses:BOOP_COMMUNICATIONS.AFTER_MESSAGE_TOOLTIP')}
        tooltipTitle={afterHoursMessage.label}
        onPreview={openAfterHoursMessagePreview}
      />
    </>
  )
}

export default memo(BoopCommunicationsConfig, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
