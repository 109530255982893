import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  PhoneUtils,
  PuiTextField,
  PuiTheme,
  Text,
  useFields,
  ValidateHandle,
} from '@pbt/pbt-ui-components'

import PhoneInput from '~/components/common/form-inputs/PhoneInput'
import DetailsBackButton from '~/components/dashboard/clients/DetailsBackButton'
import { ClientData } from '~/types/entities/clients'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      height: '100%',
      backgroundColor: theme.colors.soapStatusBar,
    },
    backButtonContainer: {
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1.5),
    },
    backButtonText: {
      color: theme.colors.primaryText,
      fontWeight: 500,
      paddingLeft: theme.spacing(1),
    },
  }),
  { name: 'ClientCreate' },
)

export interface ClientHandle extends ValidateHandle {
  getClient: () => ClientData
}

interface ClientCreateProps {
  onBack?: () => void
  showTitle?: boolean
}

const ClientCreate = forwardRef<ClientHandle, ClientCreateProps>(
  function ClientCreate({ showTitle = false, onBack }, ref) {
    const classes = useStyles()
    const { t } = useTranslation('Common')

    const {
      fields: { firstName, lastName, mobilePhone, email, patientName },
      validate,
    } = useFields(
      [
        {
          name: 'firstName',
          label: t('Common:CLIENT_FIRST_NAME'),
          validators: ['required'],
          initialValue: '',
        },
        {
          name: 'lastName',
          label: t('Common:CLIENT_LAST_NAME'),
          validators: ['required'],
          initialValue: '',
        },
        {
          name: 'mobilePhone',
          label: t('Common:MOBILE_NUMBER'),
          validators: ['phone'],
          initialValue: '',
        },
        {
          name: 'email',
          label: t('Common:EMAIL'),
          validators: ['email'],
          initialValue: '',
        },
        {
          name: 'patientName',
          label: t('Common:PATIENT_NAME'),
          validators: ['required'],
          initialValue: '',
        },
      ],
      false,
    )

    useImperativeHandle(ref, () => ({
      validate,
      getClient: () => ({
        firstName: firstName.value,
        lastName: lastName.value,
        mobilePhone: PhoneUtils.parsePhoneNumber(mobilePhone.value),
        email: email.value,
        patients: [
          {
            name: patientName.value,
          },
        ],
      }),
    }))

    return (
      <Grid container className={classes.root} direction="column">
        {onBack && (
          <Grid item>
            <DetailsBackButton
              classes={{
                container: classes.backButtonContainer,
                text: classes.backButtonText,
              }}
              lg={12}
              md={12}
              onClick={onBack}
            >
              {t('Common:BACK_TO_SEARCH')}
            </DetailsBackButton>
          </Grid>
        )}
        <Grid container item direction="column" pb={2} pt={5} px={3}>
          {showTitle && (
            <Grid item>
              <Text strong variant="subheading3">
                {t('Common:NEW_CLIENT_AND_PATIENT')}
              </Text>
            </Grid>
          )}
          <Grid item>
            <PuiTextField
              field={firstName}
              inputProps={{ maxLength: 100 }}
              label={`${firstName.label}*`}
            />
          </Grid>
          <Grid item>
            <PuiTextField
              field={lastName}
              inputProps={{ maxLength: 100 }}
              label={`${lastName.label}*`}
            />
          </Grid>
          <Grid item>
            <PhoneInput field={mobilePhone} />
          </Grid>
          <Grid item>
            <PuiTextField
              field={email}
              inputProps={{ maxLength: 100 }}
              label={email.label}
            />
          </Grid>
          <Grid item>
            <PuiTextField
              field={patientName}
              inputProps={{ maxLength: 100 }}
              label={`${patientName.label}*`}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  },
)

export default ClientCreate
