import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ControlButtonGroup,
  ControlButtonGroupName,
  HtmlNotesPreview,
  LanguageUtils,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { Boop } from '@pbt/pbt-ui-components/src/icons'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import ItemHistoryLabel from '~/components/common/labels/ItemHistoryLabel'
import TaskStatusSelect from '~/components/dashboard/tasks-dashboard/TaskStatusSelect'
import FeatureToggle from '~/constants/featureToggle'
import { useTaskTypes } from '~/store/hooks/tasks'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getTask } from '~/store/reducers/tasks'
import { getUser } from '~/store/reducers/users'
import { Task } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    labelContainer: {
      maxWidth: 'fit-content',
      marginTop: theme.spacing(1),
    },
    taskStateButton: {
      margin: theme.spacing(0.5),
    },
    boopIcon: {
      width: '0.75em',
      height: '0.75em',
      margin: theme.spacing(0, 0.5),
    },
  }),
  { name: 'OneTask' },
)

export interface OneTaskProps {
  enableBusinessShareIcon?: boolean
  onEdit?: (task: Task) => void
  showHistory?: boolean
  showState?: boolean
  taskId: string
}

const OneTask = ({
  showState = true,
  showHistory = true,
  taskId,
  onEdit,
  enableBusinessShareIcon,
}: OneTaskProps) => {
  const classes = useStyles()
  const task = useSelector(getTask(taskId))
  const assignedTo = useSelector(getUser(task?.assigned))
  const { t } = useTranslation(['Common', 'Tasks'])
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
  )

  const TaskTypes = useTaskTypes()

  return (
    <Grid container item direction="column">
      {onEdit && (
        <Grid container item alignItems="center">
          <Grid container item xs alignItems="center" wrap="nowrap">
            <Text strong variant="body">
              {task?.name}
            </Text>
            {enableBusinessShareIcon && (
              <BusinessShareIcon
                businessIds={task?.businessId ? [task.businessId] : []}
              />
            )}
          </Grid>
          <Grid item>
            <ControlButtonGroup
              buttons={[
                {
                  name: ControlButtonGroupName.EDIT,
                  onClick: () => {
                    if (task) {
                      onEdit(task)
                    }
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      )}
      {task?.typeId && (
        <Text variant="body">
          {`${t('Common:TYPE_ONE')}: ${LanguageUtils.getConstantTranslatedName(
            task?.typeId,
            TaskTypes,
          )}`}
        </Text>
      )}
      {assignedTo &&
        (task?.forClient ? (
          <Grid container alignItems="center">
            <Text variant="body">{t('Common:ASSIGNED')}:</Text>
            {!isBoopDisablementEnabled && <Boop className={classes.boopIcon} />}
            <Text variant="body">{`${Utils.getPersonString(assignedTo)} (${t(
              'Common:PET_PARENT',
            )})`}</Text>
          </Grid>
        ) : (
          <Text variant="body">{`${t(
            'Common:ASSIGNED',
          )}: ${Utils.getPersonString(assignedTo)}`}</Text>
        ))}
      {task?.notes && (
        <HtmlNotesPreview
          includeLabel
          label={
            task?.forClient ? t('Common:INTERNAL_NOTES') : t('Common:NOTES')
          }
          notes={task?.notes}
        />
      )}
      {task?.forClient && task?.instructions && (
        <HtmlNotesPreview
          includeLabel
          label={t('Tasks:LABEL.PET_PARENT_INSTRUCTIONS')}
          notes={task?.instructions}
        />
      )}
      {task?.forClient && task?.clientNotes && (
        <Text variant="body">{`${t('Common:PET_PARENT_NOTES')}: ${
          task?.clientNotes
        }`}</Text>
      )}
      {showState && (
        <Grid item className={classes.labelContainer}>
          <TaskStatusSelect fullWidth={false} task={task} />
        </Grid>
      )}
      {showHistory && <ItemHistoryLabel item={task} />}
    </Grid>
  )
}

export default OneTask
