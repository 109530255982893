import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  closeDialog,
  getDialogName,
  getDialogProps,
} from '~/store/duck/dialogs'

import DIALOGS from './dialog-components'

export interface DialogWrapperProps {
  id: string
}

const DialogWrapper = ({ id }: DialogWrapperProps) => {
  const dispatch = useDispatch()
  const componentName = useSelector(getDialogName(id))
  const componentProps = useSelector(getDialogProps(id))

  const onClose = useCallback(() => {
    dispatch(closeDialog(id))
  }, [id])

  if (!componentName) {
    return null
  }

  const Component = DIALOGS[componentName]

  return <Component open onClose={onClose} {...componentProps} />
}

export default DialogWrapper
