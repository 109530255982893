import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  PuiDialog,
  PuiTheme,
  SignatureOption,
} from '@pbt/pbt-ui-components'

import { useSignatureOptions } from '~/components/dashboard/soapV2/utils/useSignatureOptions'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { OrderType } from '~/constants/SOAPStates'
import { partialEditOrder } from '~/store/actions/orders'
import { editChargeSheetOrder } from '~/store/duck/clientFinanceData'
import { useIsDrug } from '~/store/hooks/orders'
import { getCurrentBusiness } from '~/store/reducers/auth'
import {
  getFeatureToggle,
  getInventoryManufacturers,
} from '~/store/reducers/constants'
import {
  getOrdersIsSending,
  getOrderValidationErrorMessage,
  getSelectedOrders,
} from '~/store/reducers/orders'
import { Order, OrderVaccinationDetails, ShipmentItem } from '~/types'
import { getManualInputSelectValue, getObjectDifference } from '~/utils'
import { findOrderItemByItem } from '~/utils/orderUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import { FieldNames } from '../../validation/BaseValidationDialog'
import { ShipmentItemSearchCriteria } from './ChooseShipmentItemDialog'
import VaccineDetails, { VaccineDetailsHandle } from './VaccineDetails'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
    dialogContentRoot: {
      padding: theme.spacing(2),
    },
  }),
  { name: 'VaccineDetailsDialog' },
)

const Steps = {
  INITIAL: 'INITIAL',
  DETAILS: 'DETAILS',
}

const additionalVaccineFields = [
  'manufacturedBy',
  'serialNumber',
  'location',
  'amount',
  'lotNumber',
  'lotExpiration',
  'dueDate',
]

export interface VaccineDetailsDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  order: Order & { producerId?: string }
  patientId: string | Nil
}

const VaccineDetailsDialog = ({
  order,
  open,
  clientId,
  patientId,
  onClose,
}: VaccineDetailsDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )

  const InventoryManufacturers = useSelector(getInventoryManufacturers)
  const selectedOrders = useSelector(getSelectedOrders)
  const validationError = useSelector(getOrderValidationErrorMessage)
  const currentBusiness = useSelector(getCurrentBusiness)
  const isAutogenerateVaccineCertificatesEnabled =
    currentBusiness?.autoGenerateVaccineCertificates

  const orderItem = findOrderItemByItem(order, selectedOrders)
  const fullOrder = orderItem || order

  const vaccineDetailsRef = useRef<VaccineDetailsHandle>(null)

  const getInitialStep = () =>
    R.any(
      Boolean,
      R.props(additionalVaccineFields, order.vaccinationDetails || {}),
    )
      ? Steps.DETAILS
      : Steps.INITIAL

  const [orderCandidate, setOrderCandidate] = useState(fullOrder)
  const [step, setStep] = useState(getInitialStep())

  const isLoading =
    useSelector(getOrdersIsSending) || (orderItem && !orderCandidate.id)

  const [openShipmentItemDialog] = useDialog(
    DialogNames.CHOOSE_SHIPMENT_ITEM_DIALOG,
  )
  const [openVaccineCertificateValidationDialog] = useDialog(
    DialogNames.VACCINE_CERTIFICATE_VALIDATION,
  )
  const [openSignatureDialog] = useDialog(DialogNames.DOCTOR_SIGNATURE_DIALOG)

  const setCloseAfterEditOn = useCloseAfterCreation(onClose, getOrdersIsSending)

  const isDrug = useIsDrug(orderCandidate)

  useEffect(() => {
    setOrderCandidate(fullOrder)
  }, [fullOrder])

  useEffectExceptOnMount(() => {
    if (validationError && onClose) {
      onClose()
    }
  }, [validationError])

  const handleVaccineChosenFromInventory = ({
    inventoryVariation,
    manufacturerId,
    manufacturerName,
    serialNumber,
    lotNumber,
    expirationDate,
    dueDate,
    locationId,
    location,
    doseTypeId,
    deliveryMethodId,
    rabiesTag,
    rabiesTagExpires,
  }: ShipmentItem) => {
    const {
      rabiesTagExpirationOptionId,
      rabiesTagExpirationUnitId,
      rabiesTagExpiration,
      vaccineDefaultDoseTypeId,
      vaccineDeliveryMethodId,
      vaccineDefaultAmountId,
      vaccineDefaultAmount,
      vaccineDefaultDeliveryLocationId,
      vaccineDefaultDeliveryLocation,
      vaccineDurationOfImmunityOptionId,
      vaccineDurationOfImmunityUnitId,
      vaccineDurationOfImmunity,
      vaccineTypeId,
    } = inventoryVariation || {}
    const inventoryDetails = {
      manufacturedBy: getManualInputSelectValue(
        InventoryManufacturers,
        manufacturerId,
        manufacturerName,
      ),
      serialNumber,
      lotNumber,
      lotExpiration: expirationDate,
      amountId: '',
      amount: 0,
      dueDate,
      locationId,
      location,
      doseTypeId,
      deliveryMethodId,
      rabiesTag,
      rabiesTagExpires,
      rabiesTagExpirationOptionId,
      rabiesTagExpirationUnitId,
      rabiesTagExpiration,
      vaccineDefaultDoseTypeId,
      vaccineDeliveryMethodId,
      vaccineDefaultAmountId,
      vaccineDefaultAmount,
      vaccineDefaultDeliveryLocationId,
      vaccineDefaultDeliveryLocation,
      vaccineDurationOfImmunityOptionId,
      vaccineDurationOfImmunityUnitId,
      vaccineDurationOfImmunity,
      vaccineTypeId,
    }
    const details = vaccineDetailsRef.current?.get()

    const newOrderCandidate = {
      ...orderCandidate,
      ...details,
      vaccinationDetails: {
        ...orderCandidate.vaccinationDetails,
        ...details?.vaccinationDetails,
        ...inventoryDetails,
      },
    }
    setOrderCandidate(newOrderCandidate as Order)
    setStep(Steps.DETAILS)
  }

  const handleFillFromInventory = () => {
    openShipmentItemDialog({
      order: orderCandidate,
      searchCriteria: ShipmentItemSearchCriteria.PROCEDURE_WITH_VARIATION,
      onSelect: handleVaccineChosenFromInventory,
      showToggle: !isDrug,
    })
  }

  const handleSign = () => {
    openSignatureDialog({
      outputFormat: 'png',
      enableSignerSignature:
        currentBusiness?.useDefaultSignature?.rabiesVaccine,
      onSign: (signerId: string, signature: string) => {
        const details = vaccineDetailsRef.current?.get()

        setOrderCandidate({
          ...orderCandidate,
          ...details,
          vaccinationDetails: {
            ...orderCandidate.vaccinationDetails,
            ...details?.vaccinationDetails,
            signature,
            signerId,
          },
        })
      },
    })
  }

  const handleSelectSignature = (signer: SignatureOption) => {
    const details = vaccineDetailsRef.current?.get()
    setOrderCandidate({
      ...orderCandidate,
      ...details,
      vaccinationDetails: {
        ...orderCandidate.vaccinationDetails,
        ...details?.vaccinationDetails,
        signature: signer.signature,
        signerId: signer.id,
      },
    })
  }

  const {
    signatureOptions,
    canEdit: canEditSignature,
    canSelect: canSelectSignature,
  } = useSignatureOptions()

  const handleEnterManually = () => {
    setStep(Steps.DETAILS)
  }

  const handleSave = () => {
    if (vaccineDetailsRef.current?.validate()) {
      const details = vaccineDetailsRef.current.get()
      const updatedOrder = {
        ...orderCandidate,
        ...details,
      }

      const { vaccinationDetails } = details
      openVaccineCertificateValidationDialog({
        clientId,
        patientId,
        defaultValues: {
          [FieldNames.PATIENT_RABIES_TAG]: vaccinationDetails.rabiesTag,
          [FieldNames.PATIENT_RABIES_TAG_EXPIRATION]:
            vaccinationDetails.rabiesTagExpires,
          [FieldNames.DOCTOR]: vaccinationDetails.administeredBy,
        },
        vaccines: [updatedOrder],
        disableVaccineUpdate: true,
        onOk: (vaccinationDetailsUpdates?: OrderVaccinationDetails) => {
          const difference = {
            ...getObjectDifference(
              R.omit(['vaccinationDetails'], fullOrder),
              R.omit(['vaccinationDetails'], updatedOrder),
            ),
            id: fullOrder.id,
            type: fullOrder.type,
            ...(R.isEmpty(updatedOrder.vaccinationDetails)
              ? {}
              : {
                  vaccinationDetails: {
                    ...updatedOrder.vaccinationDetails,
                    ...vaccinationDetailsUpdates,
                    id: fullOrder.vaccinationDetails?.id,
                  },
                }),
            expectedModification: fullOrder.modificationDate,
            expectedSoapLogModification: fullOrder.modificationDate,
            vetId: updatedOrder?.vaccinationDetails.administeredBy,
          }

          const action =
            isChargeSheetEnabled || !fullOrder.soapId
              ? () =>
                  editChargeSheetOrder({
                    id: fullOrder.logId || fullOrder.id,
                    type: OrderType.PROCEDURE,
                    order: difference,
                    soapLogModificationDate:
                      updatedOrder.modificationDate || '',
                  })
              : () =>
                  partialEditOrder(difference, {
                    soapId: fullOrder.soapId || undefined,
                  })
          setCloseAfterEditOn()
          dispatch(action())
        },
      })
    }
  }
  return (
    <PuiDialog
      actions={
        <>
          {step === Steps.INITIAL && (
            <>
              <ButtonWithLoader
                loading={isLoading}
                onClick={handleFillFromInventory}
              >
                {t(
                  'Dialogs:VACCINE_DETAILS_DIALOG.FILL_DETAILS_FROM_INVENTORY',
                )}
              </ButtonWithLoader>
              <ButtonWithLoader
                color="secondary"
                loading={isLoading}
                onClick={handleEnterManually}
              >
                {t('Dialogs:VACCINE_DETAILS_DIALOG.ENTER_DETAILS_MANUALLY')}
              </ButtonWithLoader>
              <ButtonWithLoader
                color="secondary"
                loading={isLoading}
                onClick={handleSave}
              >
                {t('Common:SAVE_AND_CLOSE_ACTION')}
              </ButtonWithLoader>
            </>
          )}
          {step === Steps.DETAILS && (
            <>
              <ButtonWithLoader loading={isLoading} onClick={handleSave}>
                {t('Common:SAVE_ACTION')}
              </ButtonWithLoader>
              <ButtonWithLoader
                color="secondary"
                loading={isLoading}
                onClick={handleFillFromInventory}
              >
                {t(
                  'Dialogs:VACCINE_DETAILS_DIALOG.FILL_DETAILS_FROM_INVENTORY',
                )}
              </ButtonWithLoader>
            </>
          )}
        </>
      }
      aria-labelledby="vaccine-details-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={t('Dialogs:VACCINE_DETAILS_DIALOG.ORDER_CANDIDATE_NAME_DETAILS', {
        orderCandidateName: orderCandidate.name,
      })}
      onClose={onClose}
    >
      <VaccineDetails
        clientId={clientId}
        isLoading={isLoading}
        patientId={patientId}
        ref={vaccineDetailsRef}
        showAdditionalDetails={step === Steps.DETAILS}
        signatureOptions={signatureOptions}
        vaccine={orderCandidate}
        onSelectSignature={
          isAutogenerateVaccineCertificatesEnabled && canSelectSignature
            ? handleSelectSignature
            : undefined
        }
        onSign={
          !isAutogenerateVaccineCertificatesEnabled || canEditSignature
            ? handleSign
            : undefined
        }
      />
    </PuiDialog>
  )
}

export default VaccineDetailsDialog
