import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DocumentFile, PuiTheme, Text } from '@pbt/pbt-ui-components'

import FilePlaceholder from '~/components/common/attachments/FilePlaceholder'
import AttachmentFileControlButtons from '~/components/common/inputs/attachment/AttachmentFileControlButtons'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    contentBlock: {
      minWidth: 0,
    },
    iconButton: {
      margin: theme.spacing(0, 0.5),
    },
  }),
  { name: 'MessagePlainAttachment' },
)

export interface MessagePlainAttachmentProps {
  file: DocumentFile['file']
  onPreviewRequested?: () => void
}

const MessagePlainAttachment = ({
  file,
  onPreviewRequested,
}: MessagePlainAttachmentProps) => {
  const classes = useStyles()

  return (
    <Grid
      container
      item
      alignContent="center"
      alignItems="center"
      className={classes.root}
      pr={2}
      wrap="nowrap"
    >
      <FilePlaceholder />
      <Grid className={classes.contentBlock} ml={1}>
        <Text noWrap mt={0.5}>
          {file.name}
        </Text>
        <AttachmentFileControlButtons
          hideDelete
          classes={{ iconButton: classes.iconButton }}
          file={file}
          onPreviewRequested={onPreviewRequested}
        />
      </Grid>
    </Grid>
  )
}

export default MessagePlainAttachment
