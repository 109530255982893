import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError } from '@pbt/pbt-ui-components'

import { LandingType, LandingWidgetName } from '~/constants/landingConstants'
import SnapshotsAliasTypes from '~/constants/SnapshotsAliasTypes'
import {
  LandingLayout,
  LandingSettings,
  LandingWidget,
  WidgetsData,
  WidgetsDataParams,
} from '~/types'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export const FETCH_LANDING_LAYOUT = 'landing/FETCH_LANDING_LAYOUT'
export const FETCH_LANDING_LAYOUT_SUCCESS =
  'landing/FETCH_LANDING_LAYOUT_SUCCESS'
export const FETCH_LANDING_LAYOUT_FAILURE =
  'landing/FETCH_LANDING_LAYOUT_FAILURE'

export const EDIT_LANDING_LAYOUT = 'landing/EDIT_LANDING_LAYOUT'
export const EDIT_LANDING_LAYOUT_SUCCESS = 'landing/EDIT_LANDING_LAYOUT_SUCCESS'
export const EDIT_LANDING_LAYOUT_FAILURE = 'landing/EDIT_LANDING_LAYOUT_FAILURE'

export const DELETE_LANDING_LAYOUT = 'landing/DELETE_LANDING_LAYOUT'
export const DELETE_LANDING_LAYOUT_SUCCESS =
  'landing/DELETE_LANDING_LAYOUT_SUCCESS'
export const DELETE_LANDING_LAYOUT_FAILURE =
  'landing/DELETE_LANDING_LAYOUT_FAILURE'

export const FETCH_WIDGETS_DATA = 'landing/FETCH_WIDGETS_DATA'
export const FETCH_WIDGETS_DATA_SUCCESS = 'landing/FETCH_WIDGETS_DATA_SUCCESS'
export const FETCH_WIDGETS_DATA_FAILURE = 'landing/FETCH_WIDGETS_DATA_FAILURE'

export const CLEAR_LAYOUT = 'landing/CLEAR_LAYOUT'

export const CLEAR_WIDGETS_DATA = 'landing/CLEAR_WIDGETS_DATA'

export const UPDATE_WIDGET_DATA = 'landing/UPDATE_WIDGET_DATA'

export const fetchLandingLayout = () => ({ type: FETCH_LANDING_LAYOUT })
export const fetchLandingLayoutSuccess = (
  layout: LandingLayout,
  widgets: LandingWidget[],
  settings: LandingSettings,
) => ({ type: FETCH_LANDING_LAYOUT_SUCCESS, layout, widgets, settings })
export const fetchLandingLayoutFailure = (error: ApiError) => ({
  type: FETCH_LANDING_LAYOUT_FAILURE,
  error,
})

export const editLandingLayout = (
  layout: LandingLayout,
  settings: LandingSettings,
) => ({
  type: EDIT_LANDING_LAYOUT,
  layout,
  settings,
})
export const editLandingLayoutSuccess = (
  layout: LandingLayout,
  settings: LandingSettings,
) => ({
  type: EDIT_LANDING_LAYOUT_SUCCESS,
  layout,
  settings,
})
export const editLandingLayoutFailure = (error: ApiError) => ({
  type: EDIT_LANDING_LAYOUT_SUCCESS,
  error,
})

export const deleteLandingLayout = () => ({ type: DELETE_LANDING_LAYOUT })
export const deleteLandingLayoutSuccess = (
  layout: LandingLayout,
  settings: LandingSettings,
) => ({
  type: DELETE_LANDING_LAYOUT_SUCCESS,
  layout,
  settings,
})
export const deleteLandingLayoutFailure = (error: ApiError) => ({
  type: DELETE_LANDING_LAYOUT_SUCCESS,
  error,
})

export const fetchWidgetsData = (
  widgetNames: string[],
  params: WidgetsDataParams,
) => ({
  type: FETCH_WIDGETS_DATA,
  widgetNames,
  params,
})
export const fetchWidgetsDataSuccess = (
  widgetsData: WidgetsData,
  widgetNames: string[],
  params: WidgetsDataParams,
) => ({ type: FETCH_WIDGETS_DATA_SUCCESS, widgetsData, widgetNames, params })
export const fetchWidgetsDataFailure = (
  error: ApiError,
  widgetNames: string[],
  params: WidgetsDataParams,
) => ({
  type: FETCH_WIDGETS_DATA_FAILURE,
  error,
  widgetNames,
  params,
})

export const clearLayout = () => ({ type: CLEAR_LAYOUT })

export const clearWidgetsData = (landingType: LandingType) => ({
  type: CLEAR_WIDGETS_DATA,
  landingType,
})

export const updateWidgetData = (
  landingType: LandingType,
  widgetName: string,
  data: string[],
) => ({
  type: UPDATE_WIDGET_DATA,
  landingType,
  widgetName,
  data,
})

export type LandingState = {
  error: string | null
  isLayoutLoading: boolean
  isSettingsLoading: boolean
  layout: LandingLayout
  settings: LandingSettings
  widgetDataLoadingMap: Partial<Record<LandingType, Record<string, boolean>>>
  widgetDataMap: Partial<Record<LandingType, Record<string, WidgetsData>>>
  widgets: LandingWidget[]
}

const INITIAL_STATE: LandingState = {
  layout: {},
  settings: {},
  widgets: [],
  widgetDataMap: {},
  isLayoutLoading: false,
  isSettingsLoading: false,
  widgetDataLoadingMap: {},
  error: null,
}

const getNewLoadingMap = (
  names: string[],
  loadingMap: Record<string, boolean>,
  isLoading: boolean,
) =>
  names.reduce(
    (acc, name) => {
      acc[name] = isLoading
      return acc
    },
    { ...loadingMap },
  )

export const landingReducer = (
  state: LandingState = INITIAL_STATE,
  action: AnyAction,
): LandingState => {
  switch (action.type) {
    case FETCH_LANDING_LAYOUT:
      return {
        ...state,
        error: null,
        isLayoutLoading: true,
      }
    case FETCH_LANDING_LAYOUT_SUCCESS:
      return {
        ...state,
        layout: action.layout,
        settings: action.settings,
        widgets: action.widgets,
        isLayoutLoading: false,
      }
    case FETCH_LANDING_LAYOUT_FAILURE:
      return {
        ...state,
        isLayoutLoading: false,
        error: getErrorMessage(action.error),
      }
    case EDIT_LANDING_LAYOUT:
      return {
        ...state,
        isLayoutLoading: true,
        isSettingsLoading: true,
        error: null,
      }
    case EDIT_LANDING_LAYOUT_SUCCESS:
      return {
        ...state,
        layout: action.layout,
        settings: action.settings,
        isLayoutLoading: false,
        isSettingsLoading: false,
      }
    case EDIT_LANDING_LAYOUT_FAILURE:
      return {
        ...state,
        isLayoutLoading: false,
        isSettingsLoading: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_LANDING_LAYOUT:
      return {
        ...state,
        isLayoutLoading: true,
        error: null,
      }
    case DELETE_LANDING_LAYOUT_SUCCESS:
      return {
        ...state,
        layout: action.layout,
        settings: action.settings,
        isLayoutLoading: false,
      }
    case DELETE_LANDING_LAYOUT_FAILURE:
      return {
        ...state,
        isLayoutLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_WIDGETS_DATA:
      return action.params.quiet
        ? state
        : {
            ...state,
            widgetDataLoadingMap: {
              ...state.widgetDataLoadingMap,
              [action.params.landingType]: getNewLoadingMap(
                action.widgetNames,
                state.widgetDataLoadingMap?.[
                  action.params.landingType as LandingType
                ] || {},
                true,
              ),
            },
          }
    case FETCH_WIDGETS_DATA_SUCCESS:
      return {
        ...state,
        widgetDataMap: {
          ...state.widgetDataMap,
          [action.params.landingType]: {
            ...(state.widgetDataMap[action.params.landingType as LandingType] ||
              {}),
            ...action.widgetsData,
          },
        },
        widgetDataLoadingMap: action.params.quiet
          ? state.widgetDataLoadingMap
          : {
              ...state.widgetDataLoadingMap,
              [action.params.landingType]: getNewLoadingMap(
                action.widgetNames,
                state.widgetDataLoadingMap?.[
                  action.params.landingType as LandingType
                ] || {},
                false,
              ),
            },
      }
    case FETCH_WIDGETS_DATA_FAILURE:
      return {
        ...state,
        widgetDataLoadingMap: action.params.quiet
          ? state.widgetDataLoadingMap
          : {
              ...state.widgetDataLoadingMap,
              [action.params.landingType]: getNewLoadingMap(
                action.widgetNames,
                state.widgetDataLoadingMap?.[
                  action.params.landingType as LandingType
                ] || {},
                false,
              ),
            },
        error: getErrorMessage(action.error),
      }
    case CLEAR_LAYOUT:
      return {
        ...state,
        layout: {},
      }
    case CLEAR_WIDGETS_DATA:
      return {
        ...state,
        widgetDataMap: {
          ...state.widgetDataMap,
          [action.landingType]: {},
        },
      }
    case UPDATE_WIDGET_DATA:
      return {
        ...state,
        widgetDataMap: {
          ...state.widgetDataMap,
          [action.landingType]: {
            ...(state.widgetDataMap[action.landingType as LandingType] || {}),
            [action.widgetName]: {
              page: { data: action.data },
            },
          },
        },
      }
    default:
      return state
  }
}

export const getLanding = (state: RootState): LandingState => state.landing
export const getWidgets = (state: RootState) => getLanding(state).widgets
export const getLayout = (state: RootState) => getLanding(state).layout
export const getSettings = (state: RootState) => getLanding(state).settings
export const getLayoutIsLoading = (state: RootState) =>
  getLanding(state).isLayoutLoading
export const getSettingsIsLoading = (state: RootState) =>
  getLanding(state).isSettingsLoading
export const getWidgetDataMap = (state: RootState) =>
  getLanding(state).widgetDataMap
export const getWidgetDataLoadingMap = (state: RootState) =>
  getLanding(state).widgetDataLoadingMap
export const getWidgetData = (
  landingType: LandingType,
  widgetName: SnapshotsAliasTypes | LandingWidgetName,
) =>
  createSelector(
    getWidgetDataMap,
    (map) => map[landingType]?.[widgetName]?.page?.data || [],
  )
export const getWidgetDataTotalCount = (
  landingType: LandingType,
  widgetName: string,
) =>
  createSelector(
    getWidgetDataMap,
    (map) => map[landingType]?.[widgetName]?.page?.totalCount || 0,
  )
export const getWidgetMeta = (landingType: LandingType, widgetName: string) =>
  createSelector(
    getWidgetDataMap,
    (map) => map[landingType]?.[widgetName]?.meta || [],
  )
export const getWidgetDataIsLoading = (
  landingType: LandingType,
  widgetName: string,
) =>
  createSelector(
    getWidgetDataLoadingMap,
    (loadingMap) => loadingMap[landingType]?.[widgetName] || false,
  )
