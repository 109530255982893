import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {
  ClassesType,
  EventTypeAppointmentRole,
  Field,
  LanguageUtils,
  PuiTheme,
  RoleName,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import UserSelect, {
  UserSelectConstantFilterMap,
} from '~/components/common/inputs/UserSelect'
import { useGetAppointmentStaffRoles } from '~/store/hooks/timetable'
import { useMainStaffRoles } from '~/store/hooks/useMainStaffRoles'
import { getAppointmentId, getSoapStaffInfo } from '~/store/reducers/soap'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { SoapStaffInfo } from '~/types'
import { equalsRespectEmpty } from '~/utils'

import {
  getRolePropertyOfSoap,
  getRoleUpdateAction,
} from './appointmentMemberUtils'

const getFields = (
  roles: EventTypeAppointmentRole[],
  soapStaffInfo: SoapStaffInfo,
) =>
  roles.map(({ name }) => ({
    name,
    initialValue: getRolePropertyOfSoap(soapStaffInfo, name),
  }))

const joinWith = (separator: string) => (array: any[]) => array.join(separator)
const serializeField = R.pipe(R.props(['name', 'value']), joinWith(':'))
const serializeFields = R.pipe(
  R.values,
  R.sortBy<Field>(R.prop('name')),
  R.map(serializeField),
  joinWith('|'),
)

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    titleContainer: {
      borderRight: theme.constants.tableBorder,
    },
  }),
  { name: 'AssignedToSoapDetails' },
)

export interface AssignedToSoapDetailsProps {
  classes?: ClassesType<typeof useStyles>
  editDisabled?: boolean
}

const AssignedToSoapDetails = ({
  classes: classesProp,
  editDisabled,
}: AssignedToSoapDetailsProps) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const soapStaffInfo = useSelector(getSoapStaffInfo)
  const appointmentId = useSelector(getAppointmentId)

  const mainStaffRolesList = useMainStaffRoles()
  const doctorRole: EventTypeAppointmentRole = Utils.findConstantByName(
    RoleName.Veterinarian,
    mainStaffRolesList,
  )
  const techRole: EventTypeAppointmentRole = Utils.findConstantByName(
    RoleName.VetTech,
    mainStaffRolesList,
  )
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const { t } = useTranslation('Common')

  const appointmentTypeId = appointment?.businessAppointmentType?.id

  const appointmentStaffRoles = useGetAppointmentStaffRoles(appointmentTypeId)
  const staffRoles = appointmentTypeId
    ? appointmentStaffRoles
    : [doctorRole, techRole]

  const { fields, reset } = useFields(getFields(staffRoles, soapStaffInfo))

  const [isInitialized, setIsInitialized] = useState(false)

  const onChange = () => {
    staffRoles.forEach(({ name }) => {
      const action = getRoleUpdateAction(name)
      if (action && fields[name]) {
        const actualValue = getRolePropertyOfSoap(soapStaffInfo, name)
        const newValue = fields[name].value
        if (!equalsRespectEmpty(actualValue, newValue)) {
          dispatch(action(newValue))
        }
      }
    })
  }

  // NOTE: Workaround
  //       Amount of fields is dependant to Appointment type
  //       therefore adding workaround to track updates instead of useFieldsChanged hook
  //       Optional solution: useState instead of useFields with a mapped values
  useEffect(() => {
    const isFieldsCreated = Boolean(Object.keys(fields).length)
    if (!isInitialized && isFieldsCreated) {
      reset()
      setIsInitialized(true)
    }
    onChange()
  }, [serializeFields(fields)])

  useDeepCompareEffect(() => {
    reset(getFields(staffRoles, soapStaffInfo))
  }, [staffRoles, soapStaffInfo])

  return (
    <Grid container item className={classes.root} wrap="nowrap">
      <Grid item className={classes.titleContainer} p={2} xs={2}>
        <Text mb={2} variant="h4">
          {t('Common:ASSIGNED_TO_SOAP')}
        </Text>
      </Grid>
      <Grid container item xs p={2} spacing={2} wrap="nowrap">
        {staffRoles.map((role) => (
          <Grid item key={role.id} md={4}>
            <UserSelect
              disabled={editDisabled}
              field={fields[role.name]}
              filterScope={UserSelectConstantFilterMap[role.name]}
              label={LanguageUtils.getTranslatedFieldName(role)}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default AssignedToSoapDetails
