import React from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ClassesType,
  ControlButtonGroup,
  ControlButtonGroupName,
  IdObject,
  Nil,
  PuiTheme,
  StateLabel,
  Text,
} from '@pbt/pbt-ui-components'

import ColGroupList from './ColGroupList'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    tableCell: {
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
      height: 50,
      padding: theme.spacing(0, 1),
      borderBottom: 'none',
    },
    table: {
      border: theme.constants.tableBorder,
      borderRadius: 2,
      backgroundColor: theme.colors.tableBackground,
    },
    borderCell: {
      borderRight: theme.constants.tableBorder,
    },
    mainCell: {
      width: (props: UseStylesProps) => `${50 / props.mainColsLength}%`,
    },
    tableRow: {
      backgroundColor: theme.colors.tableBackground,
      '&:first-of-type': {
        borderTop: theme.constants.tableBorder,
      },
      '&:hover': {
        backgroundColor: theme.colors.panelRow,
        outline: theme.constants.hoverBorder,
        boxShadow:
          '0 2px 4px 0 rgba(0,0,0,0.1), 3px 3px 20px 0 rgba(168,163,163,0.2)',
      },
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    tableTitle: {
      color: theme.colors.tabLabel,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '1.4rem',
      padding: theme.spacing(1),
      height: 40,
    },
    tableSubTitle: {
      color: theme.colors.tabLabel,
      fontSize: '1.2rem',
      padding: theme.spacing(0, 1),
      height: 10,
      borderBottom: 'none',
    },
    name: {},
    draggedRow: {
      display: 'table',
      backgroundColor: theme.colors.panelRow,
      border: theme.constants.hoverBorder,
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.1), 3px 3px 20px 0 rgba(168,163,163,0.2)',
    },
    controlGroupContainer: {
      flexShrink: 0,
    },
    noBorderBottom: {
      borderBottom: 'none',
    },
    stateLabel: {
      marginLeft: theme.spacing(1),
      fontSize: '1rem',
    },
    isSelectedRow: {},
  }),
  { name: 'DragAndDropTable' },
)

export type DragAndDropTableItem = Partial<IdObject> & {}

export type DragAndDropTableColumn<T extends DragAndDropTableItem> = {
  getShowLabel?: (item: T) => boolean
  label: string
  labelButton?: React.ReactNode
  labelText?: string
  prop?: ((item: T) => string) | Extract<keyof T, string>
  subLabels?: {
    label: string
  }[]
  width?: number | string
}

export interface DragAndDropTableProps<T extends DragAndDropTableItem> {
  SubHeaderComponent?: React.JSXElementConstructor<any>
  children: (
    { item, tableCellClassName }: { item: T; tableCellClassName: string },
    index: number,
  ) => React.ReactNode
  classes?: ClassesType<typeof useStyles>
  columnWidths?: (string | number | undefined)[]
  currentItemId?: string
  data?: T[]
  dragHandleProps?: DraggableProvidedDragHandleProps | Nil
  footer?: React.ReactNode
  getId?: (item: T) => string
  headerRow?: DragAndDropTableColumn<T>[]
  isDragDisabled?: boolean
  isDropDisabled?: boolean
  isLoading?: boolean
  loadingItemsLength?: number
  mainColumns?: DragAndDropTableColumn<T>[]
  onDelete?: (item: T) => void
  onEdit?: (item: T) => void
  onHeaderClick?: (currentItemId?: string) => void
  onOrderChange: (sourceIndex: number, destinationIndex: number) => void
  onRowClick?: (item: T) => void
  selectedItem?: T
  useIndexAsId?: boolean
}

interface UseStylesProps {
  classes?: ClassesType<typeof useStyles>
  mainColsLength: number
}

type DragAndDropTableComponent = <T extends DragAndDropTableItem>(
  props: DragAndDropTableProps<T>,
) => JSX.Element

const DragAndDropTable: DragAndDropTableComponent = ({
  children,
  headerRow = [],
  mainColumns = [],
  data = [],
  onDelete,
  onEdit,
  onRowClick,
  onOrderChange,
  getId = R.prop('id'),
  footer,
  selectedItem,
  useIndexAsId,
  classes: classesProp,
  isLoading = false,
  isDropDisabled = false,
  isDragDisabled = false,
  loadingItemsLength = 4,
  SubHeaderComponent,
  currentItemId,
  dragHandleProps,
  onHeaderClick,
  columnWidths,
}) => {
  const { t } = useTranslation('Common')
  const dragAndDropTableMainColumns = mainColumns || [
    {
      label: t('Common:NAME'),
      prop: 'name',
    },
  ]
  const useStylesProps: UseStylesProps = {
    classes: classesProp,
    mainColsLength: dragAndDropTableMainColumns.length,
  }
  const classes = useStyles(useStylesProps)

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return
    }

    onOrderChange(result.source.index, result.destination.index)
  }

  const getColsHaveSubLabels = (columns: typeof mainColumns) =>
    columns.some((col) => col.subLabels && col.subLabels?.length > 0)

  const hasSubLabels =
    getColsHaveSubLabels(dragAndDropTableMainColumns) ||
    getColsHaveSubLabels(headerRow)

  const colGroup = columnWidths ? (
    <ColGroupList columnWidths={columnWidths} />
  ) : null

  return (
    <Grid container className={classes.root}>
      <DragDropContext onDragEnd={onDragEnd}>
        {isLoading ? (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {dragAndDropTableMainColumns.map((row) => (
                  <TableCell key={row.label} width={row.width}>
                    <Skeleton variant="rectangular" width="100%" />
                  </TableCell>
                ))}
                {headerRow.map((row) => (
                  <TableCell key={row.label} width={row.width}>
                    <Skeleton variant="rectangular" width="100%" />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {colGroup}
              {Array.from({ length: loadingItemsLength }, (_, i) => i).map(
                (index) => (
                  <TableRow key={index}>
                    {[...dragAndDropTableMainColumns, ...headerRow]
                      .filter(Boolean)
                      .map((cell) => (
                        <TableCell
                          key={`${index}-${cell.label}`}
                          width={cell.width}
                        >
                          <Skeleton variant="rectangular" width="100%" />
                        </TableCell>
                      ))}
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {dragAndDropTableMainColumns.map(({ label }, index) => (
                  <TableCell
                    className={classNames(classes.tableTitle, {
                      [classes.borderCell]:
                        index === dragAndDropTableMainColumns.length - 1,
                      [classes.noBorderBottom]: hasSubLabels,
                    })}
                    key={label}
                  >
                    {label}
                  </TableCell>
                ))}
                {headerRow.map(
                  ({ label, labelButton, subLabels = [], width }) => (
                    <TableCell
                      className={classNames(classes.tableTitle, {
                        [classes.noBorderBottom]: hasSubLabels,
                      })}
                      colSpan={subLabels.length}
                      key={label}
                      width={width}
                    >
                      <Text inline strong variant="lowAccent2">
                        {label}
                      </Text>
                      {labelButton}
                    </TableCell>
                  ),
                )}
              </TableRow>
              {hasSubLabels && (
                <TableRow>
                  {dragAndDropTableMainColumns
                    .concat(headerRow)
                    .map(({ label, subLabels = [], width }, index) =>
                      subLabels.length > 0 ? (
                        <React.Fragment key={label}>
                          {subLabels.map((subLabel) => (
                            <TableCell
                              className={classNames(classes.tableSubTitle, {
                                [classes.borderCell]:
                                  index ===
                                  dragAndDropTableMainColumns.length - 1,
                              })}
                              key={subLabel.label}
                              width={width}
                            >
                              <Text variant="body2">{subLabel.label}</Text>
                            </TableCell>
                          ))}
                        </React.Fragment>
                      ) : (
                        <TableCell
                          className={classNames(classes.tableSubTitle, {
                            [classes.borderCell]:
                              index === dragAndDropTableMainColumns.length - 1,
                          })}
                          key={label}
                        />
                      ),
                    )}
                </TableRow>
              )}
              {SubHeaderComponent && (
                <SubHeaderComponent
                  currentItemId={currentItemId}
                  dragHandleProps={dragHandleProps}
                  onClick={onHeaderClick}
                />
              )}
            </TableHead>
            <Droppable droppableId="table" isDropDisabled={isDropDisabled}>
              {(droppableProvided) => (
                <TableBody
                  ref={(ref) => {
                    droppableProvided.innerRef(ref)
                  }}
                  {...droppableProvided.droppableProps}
                >
                  {data.map((item, index) => {
                    const id = useIndexAsId ? index : getId(item)
                    return (
                      <Draggable
                        draggableId={`${id}`}
                        index={index}
                        isDragDisabled={isDragDisabled}
                        key={id}
                      >
                        {(provided, snapshot) => (
                          <TableRow
                            className={classNames(classes.tableRow, {
                              [classes.draggedRow]: snapshot.isDragging,
                              [classes.isSelectedRow]:
                                selectedItem &&
                                (item.id === selectedItem.id ||
                                  selectedItem.id === getId(item)),
                            })}
                            ref={provided.innerRef}
                            onClick={
                              onRowClick
                                ? () => {
                                    onRowClick(item)
                                  }
                                : undefined
                            }
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {dragAndDropTableMainColumns.map(
                              (
                                { prop, getShowLabel, labelText },
                                mainColIndex,
                              ) => {
                                const isLastColumn =
                                  mainColIndex ===
                                  dragAndDropTableMainColumns.length - 1

                                return (
                                  <TableCell
                                    className={classNames(
                                      classes.tableCell,
                                      classes.mainCell,
                                      {
                                        [classes.borderCell]: isLastColumn,
                                      },
                                    )}
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={mainColIndex}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      justifyContent="space-between"
                                      wrap="nowrap"
                                    >
                                      <Grid container item alignItems="center">
                                        {prop && (
                                          <Text
                                            className={classes.name}
                                            variant="body2"
                                          >
                                            {
                                              (typeof prop === 'function'
                                                ? prop(item)
                                                : item[prop]) as string
                                            }
                                          </Text>
                                        )}
                                        {getShowLabel && getShowLabel(item) && (
                                          <StateLabel
                                            success
                                            className={classes.stateLabel}
                                          >
                                            {labelText}
                                          </StateLabel>
                                        )}
                                      </Grid>
                                      {isLastColumn && (
                                        <Grid
                                          item
                                          className={
                                            classes.controlGroupContainer
                                          }
                                        >
                                          <ControlButtonGroup
                                            buttons={[
                                              onDelete && {
                                                name: ControlButtonGroupName.DELETE,
                                                onClick: () => onDelete(item),
                                              },
                                              onEdit && {
                                                name: ControlButtonGroupName.EDIT,
                                                onClick: () => onEdit(item),
                                              },
                                            ]}
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  </TableCell>
                                )
                              },
                            )}
                            {children(
                              { item, tableCellClassName: classes.tableCell },
                              index,
                            )}
                          </TableRow>
                        )}
                      </Draggable>
                    )
                  })}
                  {droppableProvided.placeholder}
                </TableBody>
              )}
            </Droppable>
            {footer && (
              <TableFooter>
                {colGroup}
                {footer}
              </TableFooter>
            )}
          </Table>
        )}
      </DragDropContext>
    </Grid>
  )
}

export default DragAndDropTable
