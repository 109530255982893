import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Nil } from '@pbt/pbt-ui-components'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import apiErrorTypes from '~/constants/apiErrorTypes'
import { ConfirmAlertType } from '~/constants/DialogNames'
import i18nPortal from '~/locales/i18n'
import {
  addEstimateToSOAPOrEvent,
  cloneEstimate,
} from '~/store/actions/finance'
import { getFinanceValidationErrorType } from '~/store/reducers/finance'

const appointmentErrorMessageMap = {
  [apiErrorTypes.APPOINTMENT_HAS_FINALIZED_SOAP]: i18nPortal.t(
    'Soap:ESTIMATE.APPOINTMENT_HAS_FINALIZED_SOAP',
  ),
  [apiErrorTypes.APPOINTMENT_HAS_MORE_THAN_ONE_SOAP]: i18nPortal.t(
    'Soap:ESTIMATE.APPOINTMENT_HAS_MORE_THAN_ONE_SOAP',
  ),
  [apiErrorTypes.APPOINTMENT_HAS_NO_SOAP]: i18nPortal.t(
    'Soap:ESTIMATE.APPOINTMENT_HAS_NO_SOAP',
  ),
  [apiErrorTypes.APPOINTMENT_HAS_SOAP_WITH_POSTED_INVOICE]: i18nPortal.t(
    'Soap:ESTIMATE.APPOINTMENT_HAS_SOAP_WITH_POSTED_INVOICE',
  ),
}

type UseEstimateAlertDialogReturnType = {
  setAttachingToSoapEstimateId: (id: string | null) => void
  setCloningEstimateId: (id: string | null) => void
  setSoapToAttachEstimateId: (id: string | null) => void
}

interface UseEstimateAlertDialogProps {
  fromSoap: boolean
  fromTimeline: boolean
  soapId?: string | Nil
  timelineEstimateId?: string | Nil
}

const useEstimateAlertDialog = ({
  fromSoap,
  fromTimeline,
  soapId,
  timelineEstimateId,
}: UseEstimateAlertDialogProps): UseEstimateAlertDialogReturnType => {
  const { t } = useTranslation(['Common', 'Soap'])
  const dispatch = useDispatch()
  const validationErrorType = useSelector(getFinanceValidationErrorType)
  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.CLONE_ESTIMATE,
  })

  const [cloningEstimateId, setCloningEstimateId] = useState<string | null>(
    null,
  )
  const [attachingToSoapEstimateId, setAttachingToSoapEstimateId] = useState<
    string | null
  >(null)
  const [soapToAttachEstimateId, setSoapToAttachEstimateId] = useState<
    string | null
  >(null)
  const [checkInactiveLineItems, setCheckInactiveLineItems] = useState(false)

  const clearValidationError = () => {
    setCloningEstimateId(null)
    setAttachingToSoapEstimateId(null)
    setSoapToAttachEstimateId(null)
  }

  useEffect(() => {
    const noActiveItemsError =
      validationErrorType === apiErrorTypes.ESTIMATE_HAS_NO_ACTIVE_ITEMS
    const hasInactiveItemsError =
      validationErrorType === apiErrorTypes.ESTIMATE_HAS_SOME_INACTIVE_ITEMS
    const estimateAppliedToAnotherSoapError =
      validationErrorType ===
      apiErrorTypes.ESTIMATE_HAS_BEEN_ASSIGNED_TO_ANOTHER_EVENT

    const isAllowedFromTimeline =
      fromTimeline &&
      timelineEstimateId === attachingToSoapEstimateId &&
      soapToAttachEstimateId
    const isAllowedFromSoap =
      fromSoap &&
      (cloningEstimateId || attachingToSoapEstimateId) &&
      !soapToAttachEstimateId

    if (isAllowedFromTimeline || isAllowedFromSoap) {
      const needToAllowNewSoap =
        validationErrorType &&
        [
          apiErrorTypes.APPOINTMENT_HAS_FINALIZED_SOAP,
          apiErrorTypes.APPOINTMENT_HAS_MORE_THAN_ONE_SOAP,
          apiErrorTypes.APPOINTMENT_HAS_NO_SOAP,
          apiErrorTypes.APPOINTMENT_HAS_SOAP_WITH_POSTED_INVOICE,
        ].includes(validationErrorType)

      if (needToAllowNewSoap) {
        openConfirmAlert({
          message: appointmentErrorMessageMap[validationErrorType],
          preventShowAgainCheckBox: false,
          applyCustomMessage: true,
          okButtonText: t('Common:CONTINUE_ACTION'),
          onConfirm: (proceed) => {
            if (proceed && attachingToSoapEstimateId) {
              dispatch(
                addEstimateToSOAPOrEvent({
                  allowNewSoap: true,
                  estimateId: attachingToSoapEstimateId,
                  soapId,
                  eventId: fromTimeline ? soapToAttachEstimateId : null,
                  copyItems: true,
                  cloningOptions: {
                    allowClone: false,
                    checkInactiveLineItems,
                    refetchTimelineAfterCloning: fromTimeline,
                  },
                }),
              )
            }
            if (!proceed) {
              clearValidationError()
            }
          },
          onAbort: () => clearValidationError(),
        })
      }

      if (estimateAppliedToAnotherSoapError) {
        openConfirmAlert({
          message: t(
            'Soap:ESTIMATE.WOULD_YOU_LIKE_TO_CONTINUE_ADDING_ESTIMATE',
          ),
          preventShowAgainCheckBox: false,
          applyCustomMessage: true,
          okButtonText: t('Common:CONTINUE_ACTION'),
          onConfirm: (proceed) => {
            if (proceed && attachingToSoapEstimateId && fromSoap) {
              setCheckInactiveLineItems(true)
              dispatch(
                // No need to pass allowNewSoap
                addEstimateToSOAPOrEvent({
                  estimateId: attachingToSoapEstimateId,
                  soapId,
                  eventId: null,
                  copyItems: true,
                  cloningOptions: {
                    allowClone: true,
                    checkInactiveLineItems: true,
                  },
                }),
              )
            }
            if (
              proceed &&
              attachingToSoapEstimateId &&
              soapToAttachEstimateId &&
              fromTimeline
            ) {
              setCheckInactiveLineItems(true)
              dispatch(
                addEstimateToSOAPOrEvent({
                  allowNewSoap: false,
                  estimateId: attachingToSoapEstimateId,
                  soapId: null,
                  eventId: soapToAttachEstimateId,
                  copyItems: true,
                  cloningOptions: {
                    allowClone: true,
                    checkInactiveLineItems: true,
                    refetchTimelineAfterCloning: true,
                  },
                }),
              )
            }
            if (!proceed) {
              clearValidationError()
            }
          },
          onAbort: () => clearValidationError(),
        })
      }
      if (noActiveItemsError || hasInactiveItemsError) {
        openConfirmAlert({
          message: hasInactiveItemsError
            ? t('Soap:ESTIMATE.WOULD_YOU_LIKE_TO_CONTINUE')
            : t('Soap:ESTIMATE.ESTIMATE_CAN_NOT_BE_CLONED'),
          preventShowAgainCheckBox: false,
          applyCustomMessage: true,
          okButtonText: t('Soap:ESTIMATE.CONTINUE_CLONING'),
          onConfirm: (proceed) => {
            if (proceed) {
              if (cloningEstimateId) {
                dispatch(cloneEstimate(cloningEstimateId, false))
              }
              if (attachingToSoapEstimateId) {
                if (fromSoap) {
                  setCheckInactiveLineItems(false)
                  dispatch(
                    // No need to pass allowNewSoap
                    addEstimateToSOAPOrEvent({
                      estimateId: attachingToSoapEstimateId,
                      soapId,
                      eventId: null,
                      copyItems: true,
                      cloningOptions: {
                        allowClone: true,
                        checkInactiveLineItems: false,
                      },
                    }),
                  )
                }
                if (fromTimeline) {
                  setCheckInactiveLineItems(false)
                  dispatch(
                    addEstimateToSOAPOrEvent({
                      allowNewSoap: false,
                      estimateId: attachingToSoapEstimateId,
                      soapId: null,
                      eventId: soapToAttachEstimateId,
                      copyItems: true,
                      cloningOptions: {
                        allowClone: true,
                        checkInactiveLineItems: false,
                        refetchTimelineAfterCloning: true,
                      },
                    }),
                  )
                }
              }
            } else {
              clearValidationError()
            }
          },
          onAbort: () => clearValidationError(),
          hideOkayButton: noActiveItemsError,
        })
      }
    }
  }, [validationErrorType])

  return {
    setCloningEstimateId,
    setAttachingToSoapEstimateId,
    setSoapToAttachEstimateId,
  }
}

export default useEstimateAlertDialog
