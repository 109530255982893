import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import { getCurrentUserId } from '~/store/reducers/auth'

// @ts-ignore
import NotificationSettings from '../notifications/NotificationSettings'
import ProfileAvatarHeader from './ProfileAvatarHeader'
import ProfileBottomContainer from './ProfileBottomContainer'
import ProfileTopContainer from './ProfileTopContainer'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    profileBottomContainer: {
      padding: theme.spacing(2),
      marginTop: 50,
      [theme.breakpoints.down('md')]: {
        marginTop: 40,
      },
    },
    profileTopContainer: {
      height: 113,
      position: 'relative',
      justifyContent: 'center',
    },
    backToProfileTextContainer: {
      cursor: 'pointer',
    },
    backToProfileText: {
      color: theme.colors.iconColor,
    },
    backArrow: {
      color: theme.colors.iconColor,
    },
  }),
  { name: 'ProfileNotificationSettings' },
)

export interface ProfileNotificationSettingsProps {
  onAvatarEditClick: () => void
  onBack: () => void
  onSaved?: () => void
}

const ProfileNotificationSettings = ({
  onAvatarEditClick,
  onBack,
  onSaved,
}: ProfileNotificationSettingsProps) => {
  const classes = useStyles()
  const currentUserId = useSelector(getCurrentUserId) as string
  const { t } = useTranslation('Common')

  return (
    <Grid
      container
      item
      alignItems="center"
      direction="column"
      justifyContent="center"
    >
      <ProfileTopContainer className={classes.profileTopContainer}>
        <Grid
          container
          item
          alignItems="center"
          className={classes.backToProfileTextContainer}
          pl={4}
          onClick={onBack}
        >
          <ArrowBackIcon className={classes.backArrow} />
          <Text
            strong
            align="center"
            className={classes.backToProfileText}
            ml={1}
          >
            {t('Common:BACK_TO_PROFILE')}
          </Text>
        </Grid>
        <ProfileAvatarHeader onAvatarEditClick={onAvatarEditClick} />
      </ProfileTopContainer>
      <ProfileBottomContainer
        alignItems="flex-start"
        className={classes.profileBottomContainer}
      >
        <NotificationSettings personId={currentUserId} onSaved={onSaved} />
      </ProfileBottomContainer>
    </Grid>
  )
}

export default ProfileNotificationSettings
