import React from 'react'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, PuiTheme, Text } from '@pbt/pbt-ui-components'
import { Collapse as CollapseIcon } from '@pbt/pbt-ui-components/src/icons'

import { MAX_RECORDS_COUNT } from '~/constants/configs/landing'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      border: theme.constants.tableBorderSelected,
      width: `calc(100% - ${theme.spacing(3)})`,
      minHeight: `calc(100% - ${theme.spacing(2)})`,
      backgroundColor: theme.colors.tableBackground,
      position: 'absolute',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
    },
    header: {
      height: 28,
      borderBottom: theme.constants.fabBorder,
    },
    closeButton: {
      padding: 0,
      marginRight: theme.spacing(0.5),
    },
  }),
  { name: 'ExpandedWidget' },
)

interface ExpandedWidgetProps {
  classes?: ClassesType<typeof useStyles>
  disabled?: boolean
  name?: string
  onClose?: () => void
  widgetComponent: React.JSXElementConstructor<any>
  widgetComponentProps?: Record<string, any>
}

const ExpandedWidget = ({
  name,
  widgetComponent: Component,
  classes: classesProp,
  disabled,
  onClose,
  widgetComponentProps: componentProps,
}: ExpandedWidgetProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      mx={1.5}
      my={1}
      wrap="nowrap"
    >
      <Grid
        container
        alignItems="center"
        className={classes.header}
        justifyContent="space-between"
      >
        <Text strong pl={1} variant="h4">
          {name}
        </Text>
        <IconButton
          className={classes.closeButton}
          disabled={disabled}
          onClick={onClose}
        >
          <CollapseIcon />
        </IconButton>
      </Grid>
      <Component
        // max data rows and 1 for heading
        isExpanded
        showHeader
        rowsCount={MAX_RECORDS_COUNT + 1}
        showBorders={false}
        showPaddings={false}
        {...componentProps}
      />
    </Grid>
  )
}

export default ExpandedWidget
