import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Fab, Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PermissionArea, PuiTheme } from '@pbt/pbt-ui-components'

import TimezoneAlert from '~/components/dashboard/alerts/TimezoneAlert'
import DialogNames from '~/constants/DialogNames'
import { getCRUDByArea } from '~/store/reducers/auth'
import { addSearch } from '~/utils'
import useDialog from '~/utils/useDialog'

import AvailabilityRuleDetails from './AvailabilityRuleDetails'
import AvailabilityRulesTableComponent from './AvailabilityRulesTableComponent'

const useStyles = makeStyles(
  () => ({
    addButton: {
      minWidth: 160,
      height: 40,
    },
  }),
  { name: 'AvailabilityRulesPage' },
)

const AvailabilityRulesPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { openRuleDialog = false } = (location.state || {}) as {
    openRuleDialog: boolean
  }
  const { ruleId } = useParams()
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )
  const permissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_AVAILABILITY),
  )
  const { t } = useTranslation('Common')

  const [openNewAvailabilityRuleDialog] = useDialog(
    DialogNames.AVAILABILITY_RULE,
  )

  useEffect(() => {
    if (permissions.create && openRuleDialog) {
      openNewAvailabilityRuleDialog()
    }
  }, [permissions.create])

  const headerButtons = permissions.create && (
    <Grid container item justifyContent="flex-end" px={3} py={2}>
      <Fab
        className={classes.addButton}
        color="inherit"
        variant="extended"
        onClick={() => openNewAvailabilityRuleDialog()}
      >
        {t('Common:ADD_HOURS')}
      </Fab>
    </Grid>
  )

  const onDetailsClose = () => {
    navigate(addSearch(location, '/admin/scheduling/availability'))
  }

  if (isMobile && typeof ruleId !== 'undefined') {
    return <AvailabilityRuleDetails itemId={ruleId} onClose={onDetailsClose} />
  }

  return (
    <Grid container item flex={1} wrap="nowrap">
      <AvailabilityRulesTableComponent
        headerButtons={headerButtons}
        ruleId={ruleId}
        onDetailsClose={onDetailsClose}
      />
      <TimezoneAlert />
    </Grid>
  )
}

export default AvailabilityRulesPage
