import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import { Grid, GridProps, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { parseUrl, stringify } from 'query-string'
import * as R from 'ramda'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'
import {
  Collapse as CollapseIcon,
  Expand as ExpandIcon,
} from '@pbt/pbt-ui-components/src/icons'

import i18n from '~/locales/i18n'
import {
  collapseIframe,
  expandIframe,
  getExpandedIframeId,
} from '~/store/duck/marketplace'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      border: theme.constants.tableBorder,
      minWidth: 328,
    },
    expandedRoot: {
      margin: theme.spacing(2, 3),
      height: `calc(100% - ${theme.spacing(4)})`,
    },
    activeRoot: {
      borderColor: theme.colors.title,
    },
    heading: {
      borderBottom: theme.constants.tableBorder,
      padding: theme.spacing(0, 1),
      height: 40,
    },
    expandedHeading: {
      minHeight: 50,
    },
    iframe: {
      border: 'none',
      width: '100%',
      height: 100,
      minHeight: 100,
    },
    expandedIframe: {
      width: '100%',
      height: '100%',
    },
    expandedIframeContainer: {
      height: '100%',
      overflowX: 'hidden',
    },
    iconButton: {
      marginLeft: 'auto',
      padding: theme.spacing(0.5),
    },
    icon: {
      color: theme.colors.tabLabel,
    },
    activeIcon: {
      color: theme.colors.title,
    },
  }),
  { name: 'MarketplaceItemIframe' },
)

const resolveUrlParams = (urlProp: string, params: any) => {
  const { url, query: serverParams } = parseUrl(urlProp)
  const strParams = stringify(R.mergeLeft(params, serverParams))
  return `${url}?${strParams}`
}

export interface MarketplaceItemIframeProps {
  ContainerProps?: GridProps
  className?: string
  id: string
  params?: any
  preview?: boolean
  title: string
  url: string
  visible?: boolean
}

const MarketplaceItemIframe = ({
  ContainerProps,
  id,
  title,
  url,
  preview = false,
  className,
  visible = true,
  params: paramsProp,
}: MarketplaceItemIframeProps) => {
  const params = paramsProp || {}
  const classes = useStyles()
  const dispatch = useDispatch()

  const expandedIframeId = useSelector(getExpandedIframeId)
  const expanded = expandedIframeId === id && !preview
  const active = expandedIframeId === id && preview

  const handleClose = () => dispatch(collapseIframe())
  const handleExpand = () => dispatch(expandIframe(id))

  const resolvedUrl = resolveUrlParams(url, {
    ...params,
    language: i18n.language,
  })

  useEffect(
    () => () => {
      if (expanded) {
        // remove expanded iFrame on unmount
        handleClose()
      }
    },
    [],
  )

  return (
    <Grid
      container
      className={classNames(classes.root, className, {
        [classes.expandedRoot]: expanded,
        [classes.activeRoot]: active,
      })}
      direction="column"
      wrap="nowrap"
      {...ContainerProps}
    >
      <Grid
        container
        alignItems="center"
        className={classNames(classes.heading, {
          [classes.expandedHeading]: expanded,
        })}
      >
        <Text strong variant={expanded ? 'h4' : 'lowAccent2'}>
          {title}
        </Text>
        <IconButton
          className={classes.iconButton}
          size="large"
          onClick={() => (expanded || active ? handleClose() : handleExpand())}
        >
          {expanded ? (
            <CloseIcon className={classes.icon} />
          ) : active ? (
            <CollapseIcon
              className={classNames(classes.icon, classes.activeIcon)}
            />
          ) : (
            <ExpandIcon className={classes.icon} />
          )}
        </IconButton>
      </Grid>
      <Grid
        container
        className={classNames({
          [classes.expandedIframeContainer]: expanded,
        })}
      >
        {visible && (
          // eslint-disable-next-line react/iframe-missing-sandbox
          <iframe
            className={classNames(classes.iframe, {
              [classes.expandedIframe]: expanded,
            })}
            scrolling={expanded ? 'auto' : 'no'}
            src={resolvedUrl}
            title={title}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default MarketplaceItemIframe
