import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ClassesType,
  CurrencyTextField,
  Field,
  LanguageUtils,
  NumberUtils,
  PuiTheme,
  Text,
  Utils,
  WellnessPlan,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'

import { WellnessPlanLevels } from '~/constants/wellnessPlansConstants'
import { getWellnessPlanPriceType } from '~/store/reducers/constants'

import { getPlanByLevel } from './wellnessPlanUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      width: 'auto',
    },
    priceText: {
      whiteSpace: 'pre',
      fontWeight: 500,
    },
    priceInput: {
      marginLeft: theme.spacing(1),
      width: 100,
    },
  }),
  { name: 'WellnessPlanPriceTextField' },
)

export interface WellnessPlanPriceTextFieldProps {
  className?: string
  classes?: ClassesType<typeof useStyles>
  disableEdit?: boolean
  field: Field
  plan: WellnessPlan
  wellnessPlanVersion: WellnessPlanVersion
}

const WellnessPlanPriceTextField = ({
  disableEdit,
  field,
  wellnessPlanVersion,
  plan,
  className,
  classes: classesProp,
}: WellnessPlanPriceTextFieldProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const WellnessPlanPriceType = useSelector(getWellnessPlanPriceType)

  const isBaseLevel = plan.level === WellnessPlanLevels.BASE
  const baseLevelPlan = getPlanByLevel(
    WellnessPlanLevels.BASE,
    wellnessPlanVersion?.plans,
  )

  const priceTypeId = !isBaseLevel
    ? field.name.split('_')[0]
    : plan?.priceTypeId

  const baseLevelPriceDisplayName = LanguageUtils.getTranslatedFieldName(
    Utils.findById(baseLevelPlan?.priceTypeId, WellnessPlanPriceType),
    'displayName',
  )
  const priceTypeDisplayName = LanguageUtils.getTranslatedFieldName(
    Utils.findById(priceTypeId, WellnessPlanPriceType),
    'displayName',
  )

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(className, classes.root)}
      justifyContent="space-between"
      wrap="nowrap"
    >
      <Text className={classes.priceText} variant="body">{`${t(
        'Common:PRICE',
      )}: `}</Text>
      <Grid container item alignItems="center" wrap="nowrap">
        <Text className={classes.priceText} variant="body">
          {NumberUtils.formatMoney(baseLevelPlan?.price)}
        </Text>
        {!isBaseLevel && (
          <>
            <Text className={classes.priceText} variant="body">
              {`/${baseLevelPriceDisplayName} + `}
            </Text>
            {disableEdit ? (
              <Text className={classes.priceText} variant="body">
                {NumberUtils.formatMoney(field.value)}
              </Text>
            ) : (
              <CurrencyTextField
                className={classes.priceInput}
                disabled={isBaseLevel}
                field={field}
                inputProps={{ maxLength: 100 }}
                margin="none"
              />
            )}
          </>
        )}
        <Text className={classes.priceText} variant="body">
          {`/${priceTypeDisplayName}`}
        </Text>
      </Grid>
    </Grid>
  )
}

export default WellnessPlanPriceTextField
