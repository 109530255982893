import { ErrorInfo } from 'react'
import * as R from 'ramda'
import { AnyAction } from 'redux'
import { all, put, select, takeEvery } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { v4 as uuid } from 'uuid'
import { ApiError, Business, SentryUtils, User } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { ErrorLogRecord, ErrorUserFeedback } from '~/types'
import { replaceLineBreaksWithTag } from '~/utils'
import { isNetworkError } from '~/utils/errors'
import { getLockRocketSessionURL } from '~/utils/LogRocket'
import { getWSStatus } from '~/utils/websocketUtils'

import { setAuthorizationError } from '../actions/auth'
import {
  FETCH_CURRENT_USER_FAILURE,
  LOGIN_FAILURE,
  REFRESH_TOKEN_FAILURE,
  SILENT_LOGIN_FAILURE,
} from '../actions/types/auth'
import { CREATE_CLIENT_FAILURE } from '../actions/types/clients'
import {
  WS_MEDICAL_HISTORY_EMAIL_CREATE_FAILURE,
  WS_MEDICAL_HISTORY_PDF_CREATE_FAILURE,
  WS_REPORT_CARD_EMAIL_CREATE_FAILURE,
  WS_REPORT_CARD_PDF_CREATE_FAILURE,
} from '../actions/types/communications'
import {
  SEND_CONVERSATION_MESSAGE_VALIDATION_FAILURE,
  SEND_GENERATED_MESSAGE_FAILURE,
  UPLOAD_FILE_FAILURE,
} from '../actions/types/conversationMessages'
import {
  CREATE_CONVERSATION_FAILURE,
  CREATE_CONVERSATION_VALIDATION_FAILURE,
} from '../actions/types/conversations'
import { UPDATE_MEMBER_FAILURE } from '../actions/types/members'
import { FETCH_ACTIVATION_TOKEN_SILENT_FAILURE } from '../actions/types/migration'
import {
  FETCH_INVITATION_FAILURE,
  VALIDATE_ACTIVATION_KEY_FAILURE,
} from '../actions/types/registration'
import { FETCH_APPOINTMENT_FAILURE } from '../actions/types/timetable'
import { FETCH_WELLNESS_PLAN_VERSION_FAILURE } from '../actions/types/wellnessPlans'
import type { RootState } from '../index'
import { getCurrentBusiness, getCurrentUser } from '../reducers/auth'
import { CONVERSATION_CREATE_FAILURE_ACTIONS } from '../sagas/conversations'
import requestAPI from '../sagas/utils/requestAPI'
import { FETCH_ANALYTICS_URL_FAILURE } from './analytics'
import {
  startInventoryBulkPricesSessionFailure,
  startLabTestsBulkPricesSessionFailure,
  startProceduresBulkPricesSessionFailure,
} from './bulkPrices'
import { VALIDATE_SECTION_FAILURE } from './businessSettings'
import {
  ASSIGN_ORDER_FAILURE,
  FETCH_DASHBOARD_DETAILS_FAILURE,
  FETCH_DASHBOARD_PAGE_FAILURE,
  FETCH_DASHBOARD_RECORDS_FAILURE,
} from './imagingDashboard'
import {
  BATCH_CREATE_ORDER_FAILURE,
  CANCEL_ORDER_FAILURE,
  COMPLETE_ORDER_FAILURE,
  CREATE_ORDER_FAILURE,
  EDIT_ORDER_FAILURE,
  FETCH_MODALITY_FAILURE,
  FETCH_ORDER_FAILURE,
  FETCH_ORDERS_FAILURE,
} from './imagingOrders'
import { PLACE_LAB_ORDER_FAILURE } from './labOrders'
import {
  approveReactiveRxFailure,
  declineReactiveRxFailure,
  fetchPrescriptionFailure,
  linkReactiveRxFailure,
  reactiveRxUnlinkedClientDetailsFailure,
  saveReactiveRxFailure,
} from './prescriptions'
import { WS_CONNECT_FAILURE } from './websocket'

export const ADD_ERROR = 'errors/ADD_ERROR'
export const REMOVE_ERRORS = 'errors/REMOVE_ERRORS'
export const REMOVE_ALL_ERRORS = 'errors/REMOVE_ALL_ERRORS'
export const REPORT_UNCAUGHT_FAILURE = 'errors/REPORT_UNCAUGHT_FAILURE'

export const REPORT_USERS_ERROR_FEEDBACK = 'errors/REPORT_USERS_ERROR_FEEDBACK'
export const REPORT_USERS_ERROR_FEEDBACK_SUCCESS =
  'errors/REPORT_USERS_ERROR_FEEDBACK_SUCCESS'
export const REPORT_USERS_ERROR_FEEDBACK_FAILURE =
  'errors/REPORT_USERS_ERROR_FEEDBACK_FAILURE'

export const addError = (
  error: ApiError | Error,
  status: number,
  errorId: string,
  url: string,
) => ({
  type: ADD_ERROR,
  error,
  status,
  errorId,
  url,
})
export const removeErrors = (errorIds: string[]) => ({
  type: REMOVE_ERRORS,
  errorIds,
})
export const removeAllErrors = () => ({ type: REMOVE_ALL_ERRORS })
export const reportUncaughtFailure = (error: Error, info: ErrorInfo) => ({
  type: REPORT_UNCAUGHT_FAILURE,
  error,
  info,
})

export const reportUsersErrorFeedback = (feedback: ErrorUserFeedback) => ({
  type: REPORT_USERS_ERROR_FEEDBACK,
  feedback,
})
export const reportUsersErrorFeedbackSuccess = () => ({
  type: REPORT_USERS_ERROR_FEEDBACK_SUCCESS,
})
export const reportUsersErrorFeedbackFailure = (error: ApiError) => ({
  type: REPORT_USERS_ERROR_FEEDBACK_FAILURE,
  error,
})

export type ErrorsState = {
  isLoading: boolean
  list: ErrorLogRecord[]
}

export const INITIAL_STATE: ErrorsState = {
  list: [],
  isLoading: false,
}

const SILENT_ERRORS = [
  CREATE_CLIENT_FAILURE,
  SILENT_LOGIN_FAILURE,
  FETCH_CURRENT_USER_FAILURE,
  FETCH_APPOINTMENT_FAILURE,
  FETCH_INVITATION_FAILURE,
  UPDATE_MEMBER_FAILURE,
  UPLOAD_FILE_FAILURE,
  FETCH_WELLNESS_PLAN_VERSION_FAILURE,
  REFRESH_TOKEN_FAILURE,
  SEND_CONVERSATION_MESSAGE_VALIDATION_FAILURE,
  CREATE_CONVERSATION_VALIDATION_FAILURE,
  CREATE_CONVERSATION_FAILURE,
  FETCH_ANALYTICS_URL_FAILURE,
  PLACE_LAB_ORDER_FAILURE,
  ...CONVERSATION_CREATE_FAILURE_ACTIONS,
]

const NO_USER_FEEDBACK_ERRORS = [
  CREATE_ORDER_FAILURE,
  BATCH_CREATE_ORDER_FAILURE,
  EDIT_ORDER_FAILURE,
  CANCEL_ORDER_FAILURE,
  COMPLETE_ORDER_FAILURE,
  FETCH_MODALITY_FAILURE,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDER_FAILURE,
  FETCH_DASHBOARD_PAGE_FAILURE,
  FETCH_DASHBOARD_DETAILS_FAILURE,
  FETCH_DASHBOARD_RECORDS_FAILURE,
  ASSIGN_ORDER_FAILURE,
  WS_MEDICAL_HISTORY_EMAIL_CREATE_FAILURE,
  WS_MEDICAL_HISTORY_PDF_CREATE_FAILURE,
  WS_REPORT_CARD_EMAIL_CREATE_FAILURE,
  WS_REPORT_CARD_PDF_CREATE_FAILURE,
]

const IGNORED_ERRORS = [
  WS_CONNECT_FAILURE,
  FETCH_ACTIVATION_TOKEN_SILENT_FAILURE,
  VALIDATE_SECTION_FAILURE,
  VALIDATE_ACTIVATION_KEY_FAILURE,
  approveReactiveRxFailure.type,
  declineReactiveRxFailure.type,
  fetchPrescriptionFailure.type,
  linkReactiveRxFailure.type,
  reactiveRxUnlinkedClientDetailsFailure.type,
  saveReactiveRxFailure.type,
  startInventoryBulkPricesSessionFailure.type,
  startLabTestsBulkPricesSessionFailure.type,
  startProceduresBulkPricesSessionFailure.type,
  SEND_GENERATED_MESSAGE_FAILURE,
]

const getHTTPStatus = (action: AnyAction) =>
  R.path(['error', 'response', 'status'], action) ||
  R.path(['error', 'status'], action) ||
  R.path(['error', 'responseBody', 'statusCode'], action)

export const getStatus = (action: AnyAction) =>
  getHTTPStatus(action) || getWSStatus(action)

const errorMatcher = (action: AnyAction) => {
  const status = getStatus(action)

  return (
    (action.type.match('_FAILURE$') || action.type.match('Failure$')) &&
    action.type !== LOGIN_FAILURE &&
    status !== 404 &&
    status !== 409 &&
    status !== 410 &&
    status !== 403 &&
    (!R.includes(status, [400, 404, 413]) ||
      !SILENT_ERRORS.includes(action.type)) &&
    !IGNORED_ERRORS.includes(action.type)
  )
}

const getErrorMessage = (action: AnyAction): ErrorLogRecord['data'] =>
  R.path(['error', 'response', 'data'], action) || { message: action.error }

export const errorsReducer = (
  state: ErrorsState = INITIAL_STATE,
  action: AnyAction,
): ErrorsState => {
  switch (action.type) {
    case ADD_ERROR:
      return {
        ...state,
        list: [
          ...state.list,
          {
            id: action.errorId,
            status: action.status,
            data: getErrorMessage(action),
            url: action.url,
          },
        ],
      }
    case REMOVE_ERRORS:
      return {
        ...state,
        list: state.list.filter(({ id }) => !action.errorIds.includes(id)),
      }
    case REMOVE_ALL_ERRORS:
      return { ...state, list: [] }
    case REPORT_USERS_ERROR_FEEDBACK:
      return { ...state, isLoading: true }
    case REPORT_USERS_ERROR_FEEDBACK_FAILURE:
      return { ...state, isLoading: false }
    case REPORT_USERS_ERROR_FEEDBACK_SUCCESS:
      return { ...state, isLoading: false }
    default:
      return state
  }
}

const FORBIDDEN_ERROR = 'Forbidden'

const isForbiddenError = R.pathEq(['data', 'error'], FORBIDDEN_ERROR)

export const getErrors = (state: RootState): ErrorsState => state.errors
export const getErrorsList = createSelector(getErrors, (errors) =>
  R.reject(isForbiddenError, errors.list),
)
export const getErrorsIsLoading = (state: RootState) =>
  getErrors(state).isLoading
export const getFirstForbidden = (state: RootState) =>
  R.find(isForbiddenError)(getErrors(state).list)

export function* reportErrorSaga(action: AnyAction) {
  const { error, info, type } = action
  const errorInstance =
    error instanceof Error
      ? error
      : new Error(error ? JSON.stringify(error) : 'Unknown Error')

  const status = getStatus(action) as number
  if (status === 401) {
    yield put(setAuthorizationError(errorInstance))
    return
  }

  const errorId = uuid()
  const url = window.location.href || ''

  if (!R.includes(type, NO_USER_FEEDBACK_ERRORS)) {
    yield put(addError(errorInstance, status, errorId, url))
  }

  if (!isNetworkError(errorInstance as ApiError)) {
    const currentUser: User = yield select(getCurrentUser)
    const currentBusiness: Business | undefined = yield select(
      getCurrentBusiness,
    )
    SentryUtils.captureSentryException(
      errorInstance as ApiError,
      info,
      currentUser,
      currentBusiness,
      errorId,
      getLockRocketSessionURL(),
    )
  }
}

export function* reportUsersErrorFeedbackSaga({
  feedback,
}: ReturnType<typeof reportUsersErrorFeedback>) {
  try {
    const currentUser: User = yield select(getCurrentUser)
    const business: Business = yield select(getCurrentBusiness)

    const message = `<strong>Message:</strong> ${replaceLineBreaksWithTag(
      feedback.description || '',
    )}`
    const email = `<strong>Email:</strong> ${
      currentUser ? currentUser.email : 'N/A'
    }`
    const businessInfo = `<strong>Business:</strong> ${
      business ? `${business.name} (id: ${business.id})` : 'N/A'
    }`
    const ua = `<strong>UA:</strong> ${navigator.userAgent}`
    const error = `<strong>Error:</strong> <br /><code>${feedback.error}</code>`
    const content = [message, email, businessInfo, ua, '', error].join('<br />')

    const htmlContent = `<html>${content}</html>`

    yield* requestAPI(API.reportUsersErrorFeedback, htmlContent)
    yield put(reportUsersErrorFeedbackSuccess())
  } catch (error) {
    yield put(reportUsersErrorFeedbackFailure(error as ApiError))
  }
}

function* watchFailures() {
  yield takeEvery(errorMatcher, reportErrorSaga)
}

function* watchReportUsersErrorFeedback() {
  yield takeEvery(REPORT_USERS_ERROR_FEEDBACK, reportUsersErrorFeedbackSaga)
}

export function* errorsSaga() {
  yield all([watchFailures(), watchReportUsersErrorFeedback()])
}
