import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { PermissionArea } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getChargeSheet } from '~/store/duck/clientFinanceData'
import { useGetChargesList } from '~/store/hooks/chargeSheet'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getFinanceInvoice } from '~/store/reducers/finance'
import {
  getClientId,
  getInvoiceId,
  getPatientId,
  getSoapId,
} from '~/store/reducers/soap'
import useWSTopic, { WSTopics } from '~/utils/useWSTopic'

import { getInvoiceGroupedItemsBySoapId } from '../../invoices/invoiceUtils'
import { LineItemComponentsActions } from '../../soap/rail/summary/orders/orderSummaryUtils'
import SoapWidget from '../SoapWidget'
import ChargesSoapTable from './table/ChargesSoapTable'
import ChargesItems from './tabs/ChargesItems'
import { useChargeOrderAlert } from './utils/useChargeOrderAlert'
import { useClearExpandedGroup } from './utils/useClearExpandedGroup'
import { useFetchLineItems } from './utils/useFetchLineItems'

const ChargesWidgetUnlocked = () => {
  const { t } = useTranslation('Payments')

  const soapId = useSelector(getSoapId)
  const patientId = useSelector(getPatientId)
  const clientId = useSelector(getClientId)
  const invoiceId = useSelector(getInvoiceId)
  const invoice = useSelector(getFinanceInvoice(invoiceId))
  const SOAPPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const chargeSheet = useSelector(getChargeSheet)

  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )

  // TODO need to refactor ChargesSoapTable and it's children to useInvoiceLineItem and GroupLineItems types
  // now it handles both bundles and items but it dones't reflect in types
  // @ts-ignore
  const chargesList = useGetChargesList(clientId, soapId)
  // Show alert when order can't be removed
  useChargeOrderAlert()
  // Fetch invoice items
  useFetchLineItems()
  // Expanded bundles from charges widget should be consistent with invoice
  useClearExpandedGroup()

  const [expandedTab, setExpandedTab] = useState<string | null>(null)

  const items = isChargeSheetEnabled
    ? chargesList
    : getInvoiceGroupedItemsBySoapId(invoice, soapId)
  const editDisabled = !SOAPPermissions.update

  const handleExpandTab = useCallback((id: string | null) => {
    setExpandedTab(id)
  }, [])

  useWSTopic({
    wsTopic: WSTopics.CHARGES_WIDGET_INVOICE_LINE_ITEM,
    context: {
      retrySubscription: false,
      topicParams: {
        soapId,
      },
    },
    unsubscribe: true,
  })

  useWSTopic({
    wsTopic: WSTopics.REMINDERS,
    context: {
      retrySubscription: true,
      topicParams: {
        patientId,
      },
    },
    unsubscribe: true,
  })

  useWSTopic({
    wsTopic: WSTopics.CHARGES_POSTED_ON_SOAP,
    context: {
      retrySubscription: false,
      topicParams: {
        soapId,
        clientId,
      },
    },
    unsubscribe: true,
  })

  useWSTopic({
    wsTopic: WSTopics.CHARGES_SPECIAL_PROCEDURE,
    context: {
      retrySubscription: false,
      topicParams: {
        patientId,
      },
    },
    unsubscribe: true,
  })

  return (
    <SoapWidget
      ContentBoxProps={{ flexDirection: 'column-reverse' }}
      id="charges-widget"
      title={t('Payments:CHARGES')}
    >
      {!expandedTab && (
        <ChargesSoapTable
          ComponentsActions={
            isChargeSheetEnabled ? LineItemComponentsActions : undefined
          }
          chargesId={
            isChargeSheetEnabled ? R.propOr('', 'id', chargeSheet) : invoiceId
          }
          editDisabled={editDisabled}
          items={items}
        />
      )}
      <ChargesItems
        editDisabled={editDisabled}
        expandedTab={expandedTab}
        view="drop-down"
        onExpandTab={handleExpandTab}
      />
    </SoapWidget>
  )
}

export default ChargesWidgetUnlocked
