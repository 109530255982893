import React, { forwardRef } from 'react'
import { identity } from 'ramda'

import Mesh, { MeshProps } from '~/components/common/lists/Mesh'

export interface TaskMeshProps extends MeshProps {}

const TaskMesh = forwardRef<HTMLDivElement, TaskMeshProps>(function TaskMesh(
  props,
  ref,
) {
  return <Mesh getColumnId={identity} ref={ref} {...props} />
})

export default TaskMesh
