import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  DateRange as MultiDayIcon,
  EventRepeat as AppointmentRepeatIcon,
  LocationOn as LocationOnIcon,
  Tune as TuneIcon,
  Videocam as VideocamIcon,
} from '@mui/icons-material'
import { FormControl, Grid, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AlertIconType,
  AppointmentEventType,
  CopyToClipboard,
  EventTypeAppointmentResponsibility,
  EventTypeAppointmentRole,
  Nil,
  PermissionArea,
  PuiTextArea,
  PuiTheme,
  Responsibility,
  Role,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { Environment } from '@pbt/pbt-ui-components/src/constants/environment'
import {
  AddNote as AddNoteIcon,
  Chain as ChainIcon,
  Communications as CommunicationsIcon,
  Delete as DeleteIcon,
  Estimates as EstimatesIcon,
  Fullscreen,
  Info as InfoIcon,
  Print as PrintIcon,
} from '@pbt/pbt-ui-components/src/icons'
import { getDomainContext } from '@pbt/pbt-ui-components/src/utils'

import AppointmentRoleItem from '~/components/common/appointments/AppointmentRoleItem'
import AppointmentTypeSelect from '~/components/common/appointments/AppointmentTypeSelect'
import SpaceSelect from '~/components/common/inputs/SpaceSelect'
import ItemHistoryLabel from '~/components/common/labels/ItemHistoryLabel'
import useOpenMonitorApp from '~/components/dashboard/admin/general/spaces/hooks/useOpenMonitorApp'
import { getMonitorDomain } from '~/components/dashboard/admin/general/spaces/MonitorUtils'
import PatientMembershipLink from '~/components/dashboard/clients/patient/PatientMembershipLink'
import Conferencing from '~/components/dashboard/soap/appointment/Conferencing'
import NotesTemplateInput from '~/components/dashboard/template-inputs/NotesTemplateInput'
import { AppointmentTypeName } from '~/constants/appointmentTypes'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { deleteAppointment } from '~/store/actions/timetable'
import { useGetAppointmentResponsibilities } from '~/store/hooks/timetable'
import { useMainStaffRoles } from '~/store/hooks/useMainStaffRoles'
import { getAppointmentTypesMap } from '~/store/reducers/appointmentTypes'
import {
  getCRUDByArea,
  getCurrentBusiness,
  getCurrentBusinessId,
  getCurrentBusinessIsOmniChannel,
  getCurrentBusinessKioskEnabled,
} from '~/store/reducers/auth'
import {
  getEventType,
  getFeatureToggle,
  getResponsibilities,
} from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getTimetableIsDeleting } from '~/store/reducers/timetable'
import { getUser, getUserLastName } from '~/store/reducers/users'
import { DataHandle, TimetableEvent } from '~/types'
import { getKioskUrl } from '~/utils'
import { filterCVCRoles, filterVeterinarian2Role } from '~/utils/roleUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'
import useFieldsChanged, { FieldCache } from '~/utils/useFieldsChanged'
import useGetBusinessSupportedAppointments from '~/utils/useGetBusinessSupportedAppointments'

import AppointmentDateSection, {
  AppointmentDateSectionHandle,
} from './AppointmentDateSection'
import {
  getAppointmentHasFinalizedSoap,
  getIsRepeatedAppointment,
  getOccupyTimeSlotFieldName,
  getOccupyTimeSlotFieldValue,
  getRoleFieldName,
  getRolesForResponsibility,
  useGetOccupyTimeSlotFields,
  useGetSchedulerSettingsByBusiness,
} from './appointmentUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    icon: {
      color: theme.colors.selectedOption,
    },
    notesTemplateInput: {
      maxHeight: 170,
      overflowY: 'auto',
    },
    copyToClipboardLink: {
      height: 'auto',
      padding: 0,
      textDecoration: 'none',
      '&&&&:hover': {
        backgroundColor: 'inherit',
        textDecoration: 'none',
      },
    },
  }),
  { name: 'Appointment' },
)

interface AppointmentProps {
  appointment: TimetableEvent | Nil
  appointmentTypeId: string
  clientId: string
  onAddChargesRequested: () => void
  onFieldsChange?: (changedFields: FieldCache) => void
  onMessageClientRequested: () => void
  onOk: () => void
  patientId: string
  personId?: string
}

const Appointment = forwardRef(function Appointment(
  {
    clientId,
    patientId,
    appointmentTypeId,
    appointment,
    personId,
    onAddChargesRequested,
    onOk,
    onFieldsChange = R.F,
    onMessageClientRequested,
  }: AppointmentProps,
  ref,
) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'TimeTable'])

  const appointmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )
  const soapPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const EventType = useSelector(getEventType)
  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )
  const isAdditionalFieldsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.APPOINTMENT_DIALOG_ADDITIONAL_FIELDS),
  )
  // We are moving to always use personResponsibilities, but for
  // backwards-compatibility, we should make sure the appointment
  // is using it first, then use "personRoles" as a backup
  const shouldUseResponsibilities =
    isCvcRolesEnabled &&
    (Boolean(appointment?.personResponsibilities) || !appointment?.personRoles)
  const roles = useMainStaffRoles()
  const responsibilities = useSelector(getResponsibilities)
  const currentBusiness = useSelector(getCurrentBusiness)
  const kioskEnabled = useSelector(getCurrentBusinessKioskEnabled)
  const clientLastName = useSelector(getUserLastName(clientId))
  const patient = useSelector(getPatient(patientId))
  const person = useSelector(getUser(personId))
  const appointmentTypes = useSelector(getAppointmentTypesMap)
  const businessId = useSelector(getCurrentBusinessId)
  const isOmnichannel = useSelector(getCurrentBusinessIsOmniChannel)

  const spaceMonitorManagmentEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SPACE_MONITOR_MANAGEMENT),
  )

  const AppointmentEvent: AppointmentEventType = Utils.findConstantByName(
    'Appointment',
    EventType,
  )
  const AppointmentEventSubTypes = AppointmentEvent?.subTypes || []
  const businessSupportedAppointments = useGetBusinessSupportedAppointments(
    AppointmentEventSubTypes,
  )

  const defaultClientInstructions = appointment?.clientInstructions || ''
  const defaultConferencing = appointment?.meetingLink || ''
  const defaultInternalNotes = appointment?.internalNotes || ''
  const defaultSpace = appointment?.assignedSpace || ''
  const domainContext = getDomainContext()
  const monitorDomain = getMonitorDomain(domainContext.env as Environment)

  const appointmentDateSectionRef = useRef<AppointmentDateSectionHandle>(null)
  const conferencingRef = useRef<DataHandle>()

  const [openRecurringEventDialog] = useDialog(DialogNames.RECURRING_EVENT)
  const [openPrintCageLabelDialog] = useDialog(DialogNames.PRINT_CAGE_LABEL)
  const [openInactivePatientWarningAlert, closeInactivePatientWarningAlert] =
    useDialog(DialogNames.DISMISSIBLE_ALERT)

  const shouldShowOmniChannelFields = isOmnichannel && isAdditionalFieldsEnabled

  const [clientInstructionsVisible, setClientInstructionsVisible] = useState(
    Boolean(defaultClientInstructions) || shouldShowOmniChannelFields,
  )
  const [conferencingVisible, setConferencingVisible] = useState(
    Boolean(defaultConferencing),
  )
  const hasDefaultInternalNotes = Boolean(defaultInternalNotes)
  const [internalNotesVisible, setInternalNotesVisible] = useState(
    hasDefaultInternalNotes || shouldShowOmniChannelFields,
  )
  const [appointmentRepeatEnabled, setAppointmentRepeatEnabled] =
    useState(false)
  const [multiDayOptionsEnabled, setMultiDayOptionsEnabled] = useState(false)

  const [spaceVisible, setSpaceVisible] = useState(
    Boolean(defaultSpace) || shouldShowOmniChannelFields,
  )

  const DaycareId = Utils.findConstantIdByName(
    AppointmentTypeName.DAYCARE,
    AppointmentEventSubTypes,
  )
  const BoardingId = Utils.findConstantIdByName(
    AppointmentTypeName.BOARDING,
    AppointmentEventSubTypes,
  )

  const isEdit = Boolean(appointment?.id)
  const soaps = appointment?.soaps || []
  const hasSoaps = soaps.length > 0
  const isFinalizedSoap = getAppointmentHasFinalizedSoap(appointment)

  const occupyTimeSlotFields = useGetOccupyTimeSlotFields({
    personRoles: appointment?.personRoles,
    personResponsibilities: appointment?.personResponsibilities,
    roles: shouldUseResponsibilities ? responsibilities : roles,
    isEdit,
  })

  const schedulerSettings = useGetSchedulerSettingsByBusiness(
    currentBusiness?.id,
  )

  const findInitialPersonId = (roleOrResponsibility: Role | Responsibility) => {
    if (personId) {
      const matchingRoleIds = getRolesForResponsibility(
        roleOrResponsibility,
      ).map(({ id }) => id)
      const personHasMatchingRole = Object.values(
        person?.businessToRoleList || [],
      )
        .filter(({ business }) => business === currentBusiness?.id)
        .some(({ role }) =>
          shouldUseResponsibilities
            ? matchingRoleIds.includes(role)
            : roleOrResponsibility.id === role,
        )

      return personHasMatchingRole ? personId : ''
    }

    return shouldUseResponsibilities
      ? appointment?.personResponsibilities?.find(
          (personResponsibility) =>
            personResponsibility.responsibilityId === roleOrResponsibility.id,
        )?.personId
      : appointment?.personRoles?.find(
          (personRole) => personRole.roleId === roleOrResponsibility.id,
        )?.personId
  }

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'type',
        label: t('Common:APPOINTMENT_TYPE'),
        validators: ['required'],
        initialValue:
          appointment?.businessAppointmentType?.id || appointmentTypeId,
      },
      { name: 'notes', initialValue: appointment?.notes || '' },
      { name: 'internalNotes', initialValue: defaultInternalNotes },
      { name: 'clientInstructions', initialValue: defaultClientInstructions },
      { name: 'spaceId', initialValue: defaultSpace },
      ...(shouldUseResponsibilities ? responsibilities : roles).map(
        (roleOrResponsibility) => ({
          name: getRoleFieldName(roleOrResponsibility.id),
          label:
            roleOrResponsibility.nameTranslation || roleOrResponsibility.name,
          initialValue: findInitialPersonId(roleOrResponsibility) || '',
        }),
      ),
      ...occupyTimeSlotFields,
    ],
    false,
  )

  const { type, notes, internalNotes, clientInstructions, spaceId } = fields

  const openMonitor = useOpenMonitorApp(
    `${monitorDomain}/in-room/welcome?businessId=${businessId}&spaceId=${spaceId.value}`,
    defaultSpace,
  )

  const eventTypeId = appointmentTypes[type.value]?.eventTypeId

  const isDaycare = eventTypeId === DaycareId
  const isBoarding = eventTypeId === BoardingId

  const typeName = Utils.getConstantName(
    type.value,
    businessSupportedAppointments,
    '',
  )
  const isTelemedicine = R.startsWith(
    AppointmentTypeName.TELEMEDICINE,
    typeName,
  )
  const currentRoles: EventTypeAppointmentRole[] =
    Utils.findById(eventTypeId, AppointmentEventSubTypes)?.roles || []
  const apptResponsibilities = useGetAppointmentResponsibilities(type.value)

  const showDelete = isEdit && appointmentPermissions.delete && !hasSoaps
  const showAddChargesSOAP =
    isEdit && hasSoaps && soapPermissions.update && !isFinalizedSoap

  useFieldsChanged(onFieldsChange, fields)

  useEffectExceptOnMount(() => {
    reset()
  }, [
    appointment?.id,
    appointment?.personRoles,
    appointment?.personResponsibilities,
  ])

  useEffect(() => {
    if (isTelemedicine) {
      setConferencingVisible(true)
    }
  }, [isTelemedicine])

  useEffect(() => {
    if (!isEdit && patient?.active === false) {
      openInactivePatientWarningAlert({
        iconType: AlertIconType.WARN,
        message: t('Dialogs:DISMISSIBLE_ALERT.APPOINTMENT_INACTIVE_PATIENT', {
          patientName: `${patient.name || ''} ${clientLastName || ''}`,
        }),
        okButtonText: t('Common:YES_CONTINUE'),
        cancelButtonText: t('Common:NO_GO_BACK'),
        onCancel: () => {
          closeInactivePatientWarningAlert()
          onOk()
        },
        onClose: closeInactivePatientWarningAlert,
        onOk: closeInactivePatientWarningAlert,
      })
    }
  }, [patientId])

  useEffect(() => {
    setInternalNotesVisible(hasDefaultInternalNotes)
  }, [hasDefaultInternalNotes])

  const setCloseAfterDeleteOn = useCloseAfterCreation(
    onOk,
    getTimetableIsDeleting,
  )

  const toggleConferencing = () => {
    setConferencingVisible(!conferencingVisible)
  }

  const toggleClientInstructions = () => {
    setClientInstructionsVisible(!clientInstructionsVisible)
  }

  const toggleInternalNotes = () => {
    setInternalNotesVisible(!internalNotesVisible)
  }

  const toggleAppointmentRepeat = () => {
    setAppointmentRepeatEnabled(!appointmentRepeatEnabled)
  }

  const toggleMultiDayOptions = () => {
    setMultiDayOptionsEnabled(!multiDayOptionsEnabled)
  }

  const toggleSpace = () => {
    setSpaceVisible(!spaceVisible)
  }

  const handlePrintCageCard = () => {
    openPrintCageLabelDialog({
      clientId,
      patientId,
      appointmentId: appointment?.id,
    })
  }

  const handleDeleteAppointment = () => {
    if (getIsRepeatedAppointment(appointment)) {
      openRecurringEventDialog({
        onProceed: (param: string | undefined) => {
          if (appointment?.id) {
            setCloseAfterDeleteOn()
            dispatch(deleteAppointment(appointment.id, param))
          }
        },
      })
    } else if (appointment?.id) {
      setCloseAfterDeleteOn()
      dispatch(deleteAppointment(appointment.id))
    }
  }

  const getActions = () => [
    {
      id: 'team-member',
      isGroup: true,
      title: t('Common:ADD_TO_APPOINTMENT'),
      items: [
        {
          checkbox: true,
          checked: conferencingVisible,
          id: 'conferencing',
          disabled: !appointmentPermissions.update || isFinalizedSoap,
          Icon: VideocamIcon,
          label: t('Common:CONFERENCING'),
          onClick: toggleConferencing,
        },
        ...(shouldShowOmniChannelFields
          ? []
          : [
              {
                checkbox: true,
                id: 'info-for-client',
                disabled: !appointmentPermissions.update || isFinalizedSoap,
                checked: clientInstructionsVisible,
                Icon: InfoIcon,
                label: t('Common:INFO_FOR_CLIENT'),
                onClick: toggleClientInstructions,
              },
              {
                checkbox: true,
                id: 'internal-notes',
                disabled: !appointmentPermissions.update || isFinalizedSoap,
                checked: internalNotesVisible,
                Icon: AddNoteIcon,
                label: t('Common:INTERNAL_NOTE'),
                onClick: toggleInternalNotes,
              },
            ]),
        ...(isDaycare
          ? []
          : [
              {
                checkbox: true,
                id: 'repeat',
                disabled: !appointmentPermissions.update || isFinalizedSoap,
                checked: appointmentRepeatEnabled,
                Icon: AppointmentRepeatIcon,
                label: t('Common:APPOINTMENT_REPEAT'),
                onClick: toggleAppointmentRepeat,
              },
            ]),
        ...(isBoarding
          ? []
          : [
              {
                checkbox: true,
                id: 'multi-day',
                disabled: !appointmentPermissions.update || isFinalizedSoap,
                checked: multiDayOptionsEnabled,
                Icon: MultiDayIcon,
                label: t('Common:MULTI_DAY_OPTIONS'),
                onClick: toggleMultiDayOptions,
              },
            ]),
        ...(shouldShowOmniChannelFields
          ? []
          : [
              {
                checkbox: true,
                id: 'space',
                disabled: !appointmentPermissions.update || isFinalizedSoap,
                checked: spaceVisible,
                Icon: LocationOnIcon,
                label: t('Common:SPACE_ONE'),
                onClick: toggleSpace,
              },
            ]),
      ],
    },
    {
      id: 'management',
      isGroup: true,
      title: t('Common:MANAGEMENT'),
      items: [
        showAddChargesSOAP && {
          id: 'add-charges',
          label: t('Common:ADD_CHARGES'),
          Icon: EstimatesIcon,
          onClick: onAddChargesRequested,
        },
        isEdit &&
          kioskEnabled && {
            closeOnClick: false,
            id: 'copy-kiosk-link',
            Icon: ChainIcon,
            content: (
              <CopyToClipboard
                classes={{
                  link: classes.copyToClipboardLink,
                }}
                label={t('Common:COPY_KIOSK_SYSTEM_LINK')}
                text={getKioskUrl('', {
                  businessId: currentBusiness?.id,
                  eventId: appointment?.id,
                  clientId,
                })}
              />
            ),
          },
        isEdit && {
          id: 'message-client',
          label: t('Common:MESSAGE_CLIENT'),
          Icon: CommunicationsIcon,
          onClick: onMessageClientRequested,
        },
        spaceMonitorManagmentEnabled && {
          id: 'view-monitor',
          Icon: Fullscreen,
          IconProps: {
            size: 22,
          },
          label: t('Common:VIEW_MONITOR'),
          onClick: () =>
            openMonitor({
              appointmentTypeId,
              patientId,
              appointmentId: appointment?.id,
            }),
        },
        isEdit && {
          id: 'print-cage-card',
          label: t('Common:PRINT_CAGE_CARD'),
          Icon: PrintIcon,
          onClick: handlePrintCageCard,
        },
        {
          id: 'sign-up-for-wellness',
          Icon: PatientMembershipLink,
          IconProps: {
            removeUnderline: true,
            clientId,
            patientId,
          },
        },
      ],
    },
    {
      id: 'actions',
      isGroup: true,
      items: [
        showDelete && {
          id: 'delete-appointment',
          label: t('Common:DELETE_APPOINTMENT'),
          Icon: DeleteIcon,
          onClick: handleDeleteAppointment,
        },
      ],
    },
  ]

  useImperativeHandle(ref, () => ({
    getActions,
    validate: () =>
      validate() &&
      (appointmentDateSectionRef.current?.validate() ?? true) &&
      (conferencingVisible ? conferencingRef?.current?.validate() : true),
    get: () => ({
      type: eventTypeId,
      businessAppointmentType: {
        id: type.value,
      },
      clientInstructions: clientInstructionsVisible
        ? clientInstructions.value
        : '',
      notes: notes.value,
      internalNotes: internalNotes.value || '',
      spaceId: spaceVisible ? spaceId.value : '',
      ...appointmentDateSectionRef.current?.get(),
      ...(conferencingVisible
        ? conferencingRef.current?.get()
        : {
            meetingLink: '',
            meetingNotes: '',
            dialIn: '',
            includeZoomLink: false,
          }),
      personRoles: !shouldUseResponsibilities
        ? currentRoles
            .filter(filterCVCRoles)
            .filter(filterVeterinarian2Role)
            .map((role: EventTypeAppointmentRole) => ({
              person: fields[getRoleFieldName(role.id)].value,
              role: role.id,
              occupyTimeslot: getOccupyTimeSlotFieldValue(fields, role.id),
            }))
            .filter((personRole) => personRole.person)
        : undefined,
      personResponsibilities: shouldUseResponsibilities
        ? apptResponsibilities
            .map((responsibility: EventTypeAppointmentResponsibility) => ({
              person: fields[getRoleFieldName(responsibility.id)].value,
              responsibility: responsibility.id,
              occupyTimeslot: getOccupyTimeSlotFieldValue(
                fields,
                responsibility.id,
              ),
            }))
            .filter((personRole) => personRole.person)
        : undefined,
    }),
  }))

  return (
    <Grid container item pb={2} pl={3} pr={2} rowSpacing={1}>
      <Grid item xs={12}>
        <Grid
          container
          item
          alignItems="center"
          columnSpacing={1}
          wrap="nowrap"
          xs={9}
        >
          <Grid item mt={3}>
            <TuneIcon className={classes.icon} />
          </Grid>
          <Grid item xs>
            <FormControl fullWidth error={!type.valid} margin="normal">
              <InputLabel htmlFor="appointment-type-select">
                {type.label}*
              </InputLabel>
              <AppointmentTypeSelect
                disabled={!appointmentPermissions.update || isFinalizedSoap}
                field={type}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      {(shouldUseResponsibilities
        ? apptResponsibilities
        : currentRoles.filter(filterCVCRoles).filter(filterVeterinarian2Role)
      ).map(
        (
          role: EventTypeAppointmentRole | EventTypeAppointmentResponsibility,
          index: number,
        ) => (
          <Grid item key={`${role.id}_${role.name}`} xs={12}>
            <Grid
              container
              item
              alignItems="center"
              columnSpacing={1}
              wrap="nowrap"
              xs={9}
            >
              <AppointmentRoleItem
                showPersonIcon
                UserSelectContainerProps={{ minWidth: 240 }}
                UserSelectProps={{
                  displayEmpty: true,
                  emptyLabel: t('Common:UNASSIGNED'),
                }}
                appointmentTypeId={type.value}
                disabled={isFinalizedSoap}
                hasAppointmentTypeChanged={type.initialValue !== type.value}
                isEdit={isEdit}
                isFirstRole={index === 0}
                occupyTimeSlotField={
                  fields[getOccupyTimeSlotFieldName(role.id)]
                }
                personIconClassName={classes.icon}
                role={role}
                roleField={fields[getRoleFieldName(role.id)]}
                schedulerSettings={schedulerSettings}
              />
            </Grid>
          </Grid>
        ),
      )}
      <Grid item xs={12}>
        <AppointmentDateSection
          appointment={appointment}
          appointmentTypeId={type.value}
          multiDayEnabled={multiDayOptionsEnabled}
          ref={appointmentDateSectionRef}
          repeatEnabled={appointmentRepeatEnabled}
          onFieldsChange={onFieldsChange}
        />
      </Grid>
      {spaceVisible && (
        <Grid item xs={12}>
          <Grid
            container
            item
            alignItems="center"
            columnSpacing={1}
            wrap="nowrap"
            xs={6}
          >
            <Grid item mt={3}>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              <SpaceSelect
                appointmentTypeId={eventTypeId}
                disabled={!appointmentPermissions.update || isFinalizedSoap}
                field={spaceId}
                margin="normal"
                patientId={patientId}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {isAdditionalFieldsEnabled ? (
        <Grid item mt={1} xs={12}>
          <NotesTemplateInput
            singleLine
            classes={{ richEditRoot: classes.notesTemplateInput }}
            clientId={clientId}
            disabled={!appointmentPermissions.update || isFinalizedSoap}
            eventId={
              appointmentTypeId === appointment?.type?.id
                ? appointment?.id
                : undefined
            }
            field={notes}
            minHeight={30}
            patientId={patientId}
            title={t('Common:REASON_FOR_VISIT')}
          />
        </Grid>
      ) : (
        <Grid item mt={1} xs={12}>
          <InputLabel htmlFor="notes-input">
            <Text strong variant="subheading3">
              {t('Common:REASON_FOR_VISIT')}:
            </Text>
          </InputLabel>
          <PuiTextArea
            multiline
            disabled={!appointmentPermissions.update || isFinalizedSoap}
            field={notes}
            id="notes-input"
            margin="none"
            maxRows={6}
            minRows={1}
            name="appointmentReason"
          />
        </Grid>
      )}
      {clientInstructionsVisible && (
        <Grid item mt={1} xs={12}>
          <NotesTemplateInput
            singleLine
            classes={{ richEditRoot: classes.notesTemplateInput }}
            clientId={clientId}
            disabled={!appointmentPermissions.update || isFinalizedSoap}
            eventId={
              appointmentTypeId === appointment?.type?.id
                ? appointment?.id
                : undefined
            }
            field={clientInstructions}
            minHeight={30}
            patientId={patientId}
            title={t('TimeTable:ADD_APPOINTMENT.NOTES_TEMPLATE_INPUT_TITLE')}
          />
        </Grid>
      )}
      {conferencingVisible && (
        <Conferencing
          appointment={appointment}
          editDisabled={!appointmentPermissions.update || isFinalizedSoap}
          ref={conferencingRef}
          showUserSelect={!appointment?.id}
          onFieldsChange={onFieldsChange}
        />
      )}
      {internalNotesVisible &&
        (isAdditionalFieldsEnabled ? (
          <NotesTemplateInput
            singleLine
            classes={{ richEditRoot: classes.notesTemplateInput }}
            clientId={clientId}
            disabled={!appointmentPermissions.update || isFinalizedSoap}
            eventId={
              appointmentTypeId === appointment?.type?.id
                ? appointment?.id
                : undefined
            }
            field={internalNotes}
            minHeight={30}
            patientId={patientId}
            subTitle={t('Common:NOT_SHARED_WITH_PET_PARENT')}
            title={t('Common:INTERNAL_NOTE')}
          />
        ) : (
          <Grid item mt={1} xs={12}>
            <InputLabel htmlFor="internal-note-input">
              <Text inline strong variant="subheading3">
                {t('Common:INTERNAL_NOTE')}
              </Text>
              <Text inline variant="body2">
                {t('Common:NOT_SHARED_WITH_PET_PARENT')}
              </Text>
            </InputLabel>
            <PuiTextArea
              multiline
              disabled={!appointmentPermissions.update || isFinalizedSoap}
              field={internalNotes}
              id="internal-notes-input"
              margin="none"
              maxRows={6}
              minRows={1}
            />
          </Grid>
        ))}
      <Grid item mt={1} xs={12}>
        <ItemHistoryLabel item={appointment} />
      </Grid>
    </Grid>
  )
})

export default Appointment
