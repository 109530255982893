import React from 'react'
import { Box, Stack } from '@mui/material'

import {
  Estimate,
  Estimate as GraphqlEstimate,
  PaymentLink,
} from '~/api/graphql/generated/types'
import { TableTextNavigateCell } from '~/components/dashboard/clients/balance/table/common/TableTextNavigateCell'
import { TableTextWithClickCell } from '~/components/dashboard/clients/balance/table/common/TableTextWithClickCell'
import InvoiceType from '~/constants/InvoiceType'
import { getInvoiceType } from '~/utils/refundUtils'

import { CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT } from '../common/tableConstants'

interface TableInvoiceEstimateCellProps {
  estimateInvoices: PaymentLink[]
  onEstimateNameClick: (data: GraphqlEstimate) => void
}

export const TableInvoiceEstimateCell = ({
  estimateInvoices,
  onEstimateNameClick,
}: TableInvoiceEstimateCellProps) => {
  if (!estimateInvoices) {
    return null
  }

  return (
    <Stack>
      {estimateInvoices.map((link) => {
        const type = getInvoiceType(link.invoiceNo)

        if (type === InvoiceType.INVOICE) {
          return (
            <Box
              alignItems="center"
              display="flex"
              height={CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT}
              key={link.invoiceNo}
              width="100%"
            >
              <TableTextNavigateCell
                openOnTheSameTab
                to={`/invoice/${link.invoice.id}`}
                value={link.invoiceNo}
              />
            </Box>
          )
        }
        if (type === InvoiceType.REFUND_INVOICE) {
          return (
            <Box
              alignItems="center"
              display="flex"
              height={CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT}
              key={link.invoiceNo}
              width="100%"
            >
              <TableTextNavigateCell
                openOnTheSameTab
                to={`/refund/${link.invoice.id}`}
                value={link.invoiceNo}
              />
            </Box>
          )
        }
        if (type === InvoiceType.ESTIMATE) {
          const estimate = link.invoice as Estimate
          return (
            <Box
              alignItems="center"
              display="flex"
              height={CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT}
              key={link.invoiceNo}
              width="100%"
            >
              <TableTextWithClickCell
                value={link.invoiceNo}
                onClick={() =>
                  onEstimateNameClick({
                    invoiceIds: [],
                    personId: '',
                    clientId: estimate.clientId,
                    patientId: estimate.patientId,
                    id: estimate.id,
                    businessId: estimate.businessId,
                  })
                }
              />
            </Box>
          )
        }
        return null
      })}
    </Stack>
  )
}
