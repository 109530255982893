import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  Field,
  Nil,
  PuiSelect,
  PuiTextField,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import {
  useGetInventoryPriceUnits,
  useGetInventoryPriceUnitsSizeLabel,
} from '~/store/hooks/inventories'
import { handleNumberInput } from '~/utils'

// @ts-ignore
import { isPluralUnit } from '../inventoryUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    unit: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    unitSize: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  }),
  { name: 'VariationPriceUnit' },
)

export interface VariationPriceUnitProps {
  classes?: ClassesType<typeof useStyles>
  count?: number | Nil
  disabled?: boolean
  fields: {
    priceUnitsSize: Field
    unitId: Field
  }
  packageTypeId: string
  perPackageUnitsId: string
  saleUnitOfMeasure?: string | Nil
}

const VariationPriceUnit = ({
  classes: classesProp,
  disabled,
  fields: { unitId, priceUnitsSize },
  perPackageUnitsId,
  packageTypeId,
  saleUnitOfMeasure,
  count,
}: VariationPriceUnitProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const unitOptions = useGetInventoryPriceUnits(
    perPackageUnitsId,
    packageTypeId,
    priceUnitsSize.value,
    saleUnitOfMeasure,
    count,
  )

  const unitSizeLabel = useGetInventoryPriceUnitsSizeLabel(
    packageTypeId,
    perPackageUnitsId,
    unitId.value,
    saleUnitOfMeasure,
    count,
  )

  return (
    <>
      <Grid item className={classes.unit} md={3} xs={6}>
        <FormControl fullWidth>
          <InputLabel htmlFor="pricing-unit-select">
            {unitId.label || t('Common:UNITS')}*
          </InputLabel>
          <PuiSelect
            disabled={disabled || !perPackageUnitsId || !packageTypeId}
            field={unitId}
            input={<Input id="pricing-unit-select" />}
            items={unitOptions}
          />
        </FormControl>
      </Grid>
      {isPluralUnit(unitId.value) && (
        <Grid item className={classes.unitSize} md={3} xs={6}>
          <PuiTextField
            disabled={disabled}
            field={{
              ...priceUnitsSize,
              set: handleNumberInput(priceUnitsSize.setValue, 10, 4),
            }}
            inputProps={{ maxLength: 10 }}
            label={unitSizeLabel}
          />
        </Grid>
      )}
    </>
  )
}

export default VariationPriceUnit
