import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, GridProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  DateUtils,
  Nil,
  Text,
  TextProps,
  User,
  Utils,
} from '@pbt/pbt-ui-components'

import { getUser } from '~/store/reducers/users'

const useStyles = makeStyles(
  () => ({
    text: {
      whiteSpace: 'pre-wrap',
    },
  }),
  { name: 'ItemHistoryLabel' },
)

export type HistoryLabelItem = {
  completedBy?: Partial<User> | string | Nil
  completerId?: string | Nil
  completionDate?: string | Nil
  createdBy?: Partial<User> | string | Nil
  createdById?: string | Nil
  creationDate?: string | Nil
  creatorId?: string
  modificationDate?: string | Nil
  modifiedBy?: Partial<User> | string | Nil
  modifiedById?: string | Nil
  modifierId?: string | Nil
  recordedById?: string
}

export interface ItemHistoryLabelProps extends Omit<GridProps, 'item'> {
  createdOnly?: boolean
  item?: HistoryLabelItem | Nil
  noSpacing?: boolean
  textVariant?: TextProps['variant']
  vertical?: boolean
}

const ItemHistoryLabel = ({
  item,
  vertical,
  noSpacing,
  createdOnly = false,
  textVariant = 'lowAccent2',
  ...rest
}: ItemHistoryLabelProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const {
    creationDate,
    modificationDate,
    completionDate,
    createdBy,
    createdById,
    modifiedBy,
    modifiedById,
    completedBy,
    creatorId,
    recordedById,
    modifierId,
    completerId,
  } = item || {}

  const creator = recordedById || creatorId || createdById || createdBy
  const modifier = modifierId || modifiedById || modifiedBy
  const completer = completerId || completedBy

  const creatorUserFromStore = useSelector(getUser(creator as string))
  const modifierUserFromStore = useSelector(getUser(modifier as string))
  const completerUserFromStore = useSelector(getUser(completer as string))

  const createdByUser = (
    R.is(Object, creator) ? creator : creatorUserFromStore
  ) as User
  const modifiedByUser = (
    R.is(Object, modifier) ? modifier : modifierUserFromStore
  ) as User
  const completedByUser = (
    R.is(Object, completer) ? completer : completerUserFromStore
  ) as User

  if (!creationDate && !modificationDate && !completionDate) {
    return null
  }

  const nameBreak = vertical ? '\n' : ' '
  const dateBreak = vertical ? '\n' : ' | '

  return (
    <Grid container item direction="column" wrap="nowrap" {...rest}>
      {creationDate && (
        <Text className={classes.text} variant={textVariant}>
          {`${t('Common:CREATED')}:${nameBreak}${DateUtils.formatDateWithHours(
            creationDate,
          )}`}
          {creator && `${dateBreak}${Utils.getPersonString(createdByUser)}`}
        </Text>
      )}
      {!createdOnly && (
        <>
          {vertical && !noSpacing && <br />}
          {modificationDate && (
            <Text className={classes.text} variant={textVariant}>
              {`${t(
                'Common:LAST_MODIFIED',
              )}:${nameBreak}${DateUtils.formatDateWithHours(
                modificationDate,
              )}`}
              {modifier &&
                `${dateBreak}${Utils.getPersonString(modifiedByUser)}`}
            </Text>
          )}
          {vertical && !noSpacing && <br />}
          {completionDate && (
            <Text className={classes.text} variant={textVariant}>
              {`${t(
                'Common:COMPLETED',
              )}:${nameBreak}${DateUtils.formatDateWithHours(completionDate)}`}
              {completer &&
                `${dateBreak}${Utils.getPersonString(completedByUser)}`}
            </Text>
          )}
        </>
      )}
    </Grid>
  )
}

export default ItemHistoryLabel
