import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Field, PuiTextField, PuiTheme, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    radioLabel: {
      fontSize: '1.6rem',
      marginRight: theme.spacing(3),
    },
    textFieldInputReadOnly: {
      backgroundColor: theme.colors.tableOddRowBackground,
      border: 'none',
      borderRadius: 2,
      paddingTop: 5,
      paddingBottom: 5,
    },
    textFieldNoBorder: {
      border: 'none',
    },
  }),
  { name: 'AdministrationInstructions' },
)

export interface AdministrationInstructionsProps {
  autofillField: Field
  disabled?: boolean
  instructionsField: Field
  instructionsRequired?: boolean
  isChewy: boolean
  simplified?: boolean
}

const AdministrationInstructions = ({
  autofillField,
  disabled,
  instructionsField,
  instructionsRequired = false,
  isChewy,
  simplified,
}: AdministrationInstructionsProps) => {
  const classes = useStyles()

  const { t } = useTranslation(['Common', 'Dialogs', 'Tooltips'])

  const AdministrationInstructionsInputMode = {
    CUSTOM: t('Common:WRITE_MY_OWN_PRESCRIPTION'),
    AUTOFILL: t('Dialogs:PRESCRIPTION_DIALOG.AUTOFILL'),
  }

  const isReadOnly = Boolean(autofillField?.value)

  return (
    <Grid container direction="column">
      {!simplified && (
        <>
          {!isChewy && (
            <>
              <Text strong variant="body2">
                {t('Dialogs:PRESCRIPTION_DIALOG.ADMINISTRATION_INSTRUCTIONS')}
                &nbsp;
                <Text component="span" variant="body2">
                  ({t('Dialogs:PRESCRIPTION_DIALOG.APPEARS_ON_LABEL')})
                </Text>
              </Text>
              <RadioGroup
                row
                aria-label="label-section"
                name="administrationInstructionsInputMode"
                value={
                  isReadOnly
                    ? AdministrationInstructionsInputMode.AUTOFILL
                    : AdministrationInstructionsInputMode.CUSTOM
                }
                onChange={(_, value) =>
                  autofillField.setValue(
                    value === AdministrationInstructionsInputMode.AUTOFILL,
                  )
                }
              >
                {Object.values(AdministrationInstructionsInputMode).map(
                  (mode) => (
                    <FormControlLabel
                      classes={{ label: classes.radioLabel }}
                      control={<Radio />}
                      key={mode}
                      label={mode}
                      value={mode}
                    />
                  ),
                )}
              </RadioGroup>
            </>
          )}
        </>
      )}

      <Tooltip
        enterDelay={300}
        placement="top-end"
        title={
          simplified || isChewy
            ? ''
            : isReadOnly
            ? t('Tooltips:CLICK_CUSTOM_TO_EDIT')
            : ''
        }
      >
        <PuiTextField
          multiline
          InputProps={{
            readOnly: isReadOnly,
            inputProps: { maxLength: 1000 },
            className: isReadOnly ? classes.textFieldInputReadOnly : undefined,
            classes: {
              notchedOutline:
                isReadOnly || simplified
                  ? classes.textFieldNoBorder
                  : undefined,
            },
          }}
          disabled={disabled}
          field={instructionsField}
          margin="none"
          minRows={isReadOnly ? 1 : 2}
          placeholder={
            isReadOnly
              ? ''
              : `${t('Common:INSTRUCTIONS')}${instructionsRequired ? '*' : ''}`
          }
          variant="outlined"
        />
      </Tooltip>
    </Grid>
  )
}

export default AdministrationInstructions
