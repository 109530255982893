import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AlertIconType,
  BasePuiDialogProps,
  PuiAlert,
  PuiTextField,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import { CompactAlert } from '~/components/elements/CompactAlert/CompactAlert'
import { AlertLabelColorVariant } from '~/constants/alertColors'
import {
  DISCOUNT_REASON_OTHER_NAME,
  fetchDiscountReasons,
  getActiveDiscountReasonsList,
  getDiscountReasonIsMandatory,
} from '~/store/duck/discountReasons'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { DiscountReason } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    radioGroup: {
      marginRight: 'auto',
    },
    radioButton: {
      flex: '0 0 auto',
    },
    content: {
      width: '434px',
    },
    input: {
      fontSize: theme.typography.pxToRem(14),
    },
    inputWrapper: {
      display: 'inline-flex',
      marginTop: '4px',
      flex: '1 1 auto',
    },
    radioWrapper: {
      display: 'flex',
      marginRight: theme.spacing(4),
    },
  }),
  { name: 'InvoiceDiscountReasonDialog' },
)

interface InvoiceDiscountReasonDialogProps extends BasePuiDialogProps {
  onOk: (selectedDiscountReason?: DiscountReason) => void
}

const InvoiceDiscountReasonDialog = ({
  onClose,
  onOk,
  open,
}: InvoiceDiscountReasonDialogProps) => {
  const { t } = useTranslation('Common')
  const dispatch = useDispatch()
  const classes = useStyles()

  const businessId = useSelector(getCurrentBusinessId)
  const discountReasons = useSelector(getActiveDiscountReasonsList)
  const isReasonMandatory = useSelector(getDiscountReasonIsMandatory)
  const [activeReason, setActiveReason] = useState<DiscountReason | undefined>(
    undefined,
  )
  const [otherReasonDescription, setOtherReasonDescription] =
    useState<string>('')

  const handleSetActiveReason = (discountReasonId: string) => {
    setActiveReason(discountReasons.find((dr) => dr.id === discountReasonId))
  }

  useEffect(() => {
    if (businessId) {
      dispatch(fetchDiscountReasons(businessId))
    }
  }, [businessId])

  const radioGroup = (
    <Grid className={classes.content}>
      {isReasonMandatory && (
        <CompactAlert
          mb={2}
          message={t('Invoices:PICK_A_REASON_FOR_DISCOUNT')}
          variant={AlertLabelColorVariant.ATTENTION_RED}
        />
      )}
      <RadioGroup
        className={classes.radioGroup}
        name="discount-reason-selection"
        value={activeReason?.id || null}
        onChange={(_, value) => handleSetActiveReason(value)}
      >
        {discountReasons.map((dr) => (
          <Grid className={classes.radioWrapper} key={dr.id}>
            <FormControlLabel
              className={classes.radioButton}
              control={<Radio />}
              label={dr.name}
              value={dr.id}
            />
            {dr.name === DISCOUNT_REASON_OTHER_NAME && (
              <Grid className={classes.inputWrapper}>
                <PuiTextField
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                  margin="none"
                  value={otherReasonDescription}
                  onChange={(event) => {
                    handleSetActiveReason(dr.id)
                    setOtherReasonDescription(event.target.value)
                  }}
                />
              </Grid>
            )}
          </Grid>
        ))}
      </RadioGroup>
    </Grid>
  )

  return (
    <PuiAlert
      OkButtonProps={{
        disabled:
          (isReasonMandatory && !activeReason) ||
          !discountReasons ||
          discountReasons.length === 0,
      }}
      cancelButtonText={t('Common:GO_BACK')}
      content={radioGroup}
      iconType={AlertIconType.WARN}
      message={t('Invoices:WHY_WAS_DISCOUNT_APPLIED')}
      okButtonText={t('Invoices:POST_INVOICE_ACTION')}
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onOk={() => {
        onOk(
          activeReason
            ? {
                ...activeReason,
                ...(activeReason.name === DISCOUNT_REASON_OTHER_NAME &&
                  otherReasonDescription !== '' && {
                    name: otherReasonDescription,
                  }),
              }
            : undefined,
        )
      }}
    />
  )
}

export default InvoiceDiscountReasonDialog
