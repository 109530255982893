import React, { RefObject, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { AddButton, Nil, PuiTheme, Shadow } from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

import ClientAndPatientSearchPopper from '~/components/dashboard/search/ClientAndPatientSearchPopper'
import ClientSearchPopper from '~/components/dashboard/search/ClientSearchPopper'
import ContactSearchPopper from '~/components/dashboard/search/ContactSearchPopper'
import { ContactSlot } from '~/types'

import ClientCard from './ClientCard'
import ContactCard from './ContactCard'
import PatientCard from './PatientCard'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      border: theme.constants.tableBorder,
      borderRadius: 2,
    },
    rootDisabled: {
      border: theme.constants.tableBorder,
      borderRadius: 2,
      opacity: 0.5,
    },
    addText: {
      fontWeight: 400,
    },
    deleteIconButton: {
      padding: theme.spacing(0.5),
    },
  }),
  { name: 'ContactTargetSlot' },
)

export interface ContactTargetSlotProps {
  anchorRef: RefObject<HTMLDivElement>
  className?: string
  clientId?: string | Nil
  contactId?: string | Nil
  disabled?: boolean
  onSlotChange: (contactSlot: ContactSlot) => void
  patientId?: string | Nil
  showAddClient?: boolean
  showAddContact?: boolean
  visibleResults?: number
}

const ContactTargetSlot = ({
  className,
  anchorRef,
  onSlotChange,
  clientId: forcedClientId,
  patientId: forcedPatientId,
  contactId: forcedContactId,
  visibleResults,
  showAddClient = false,
  showAddContact = true,
  disabled = false,
}: ContactTargetSlotProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const [clientId, setClientId] = useState(forcedClientId)
  const [patientId, setPatientId] = useState(forcedPatientId)
  const [contactId, setContactId] = useState(forcedContactId)
  const [searchClientPatientPopperOpen, setSearchClientPatientPopperOpen] =
    useState(false)
  const [searchClientPopperOpen, setSearchClientPopperOpen] = useState(false)
  const [searchContactPopperOpen, setSearchContactPopperOpen] = useState(false)

  useEffect(() => {
    setClientId(forcedClientId)
  }, [forcedClientId])

  useEffect(() => {
    setPatientId(forcedPatientId)
  }, [forcedPatientId])

  useEffect(() => {
    setContactId(forcedContactId)
  }, [forcedContactId])

  const updateSlot = (props: ContactSlot = {}) => {
    if (props.clientId !== undefined) {
      setClientId(props.clientId)
    }

    if (props.patientId !== undefined) {
      setPatientId(props.patientId)
    }

    if (props.contactId !== undefined) {
      setContactId(props.contactId)
    }

    setSearchClientPatientPopperOpen(false)
    setSearchClientPopperOpen(false)
    setSearchContactPopperOpen(false)

    onSlotChange({
      clientId,
      patientId,
      contactId,
      ...props,
    })
  }

  const onAddClientPatientRequested = () =>
    !disabled && setSearchClientPatientPopperOpen(true)
  const onAddClientRequested = () =>
    !disabled && setSearchClientPopperOpen(true)
  const onAddContactRequested = () =>
    !disabled && setSearchContactPopperOpen(true)
  const onClientPatientSelected = (
    newClientId: string,
    newPatientId: string | Nil,
  ) => {
    if (!disabled) {
      updateSlot({ clientId: newClientId, patientId: newPatientId })
    }
  }
  const onClientSelected = (newClientId: string) => {
    if (!disabled) {
      updateSlot({ clientId: newClientId })
    }
  }
  const onContactSelected = (newContactId: string) => {
    if (!disabled) {
      updateSlot({ contactId: newContactId })
    }
  }
  const clear = () => {
    if (!disabled) {
      updateSlot({
        clientId: null,
        patientId: null,
        contactId: null,
      })
    }
  }

  return (
    <>
      <Grid
        container
        item
        className={classNames(className, {
          [classes.root]: !disabled,
          [classes.rootDisabled]: disabled,
        })}
        p={1}
      >
        {(clientId || contactId) && (
          <Grid container item columnSpacing={1} wrap="nowrap">
            {contactId && (
              <Grid item xs>
                <ContactCard contactId={contactId} />
              </Grid>
            )}
            {clientId && patientId && (
              <Grid item xs>
                <PatientCard clientId={clientId} patientId={patientId} />
              </Grid>
            )}
            {clientId && (
              <Grid item xs>
                <ClientCard clientId={clientId} />
              </Grid>
            )}
            {!disabled && (
              <Grid item>
                <IconButton
                  aria-label={t('Common:DELETE_ACTION').toLowerCase()}
                  className={classes.deleteIconButton}
                  size="large"
                  onClick={clear}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        )}
        {!clientId && !patientId && !contactId && (
          <Grid container columnSpacing={2} rowSpacing={1}>
            <Grid item>
              <AddButton
                addText={t(
                  'Common:CONTACT_TARGET_SLOT.ASSIGN_TO_CLIENT_AND_PATIENT',
                )}
                classes={{ addText: classes.addText }}
                onAdd={onAddClientPatientRequested}
              />
            </Grid>
            {showAddClient && (
              <Grid item>
                <AddButton
                  addText={t(
                    'Common:CONTACT_TARGET_SLOT.ASSIGN_TO_CLIENT_ONLY',
                  )}
                  classes={{ addText: classes.addText }}
                  onAdd={onAddClientRequested}
                />
              </Grid>
            )}
            {showAddContact && (
              <Grid item>
                <AddButton
                  addText={t('Common:CONTACT_TARGET_SLOT.ASSIGN_TO_CONTACT')}
                  classes={{ addText: classes.addText }}
                  onAdd={onAddContactRequested}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      <ClientAndPatientSearchPopper
        anchorEl={anchorRef.current}
        open={searchClientPatientPopperOpen}
        visibleResults={visibleResults}
        onClose={() => setSearchClientPatientPopperOpen(false)}
        onSelected={onClientPatientSelected}
      />
      <ClientSearchPopper
        anchorEl={anchorRef.current}
        open={searchClientPopperOpen}
        visibleResults={visibleResults}
        onClose={() => setSearchClientPopperOpen(false)}
        onSelected={onClientSelected}
      />
      <ContactSearchPopper
        anchorEl={anchorRef.current}
        open={searchContactPopperOpen}
        visibleResults={visibleResults}
        onClose={() => setSearchContactPopperOpen(false)}
        onSelected={onContactSelected}
      />
      <Shadow
        open={
          searchClientPatientPopperOpen ||
          searchClientPopperOpen ||
          searchContactPopperOpen
        }
      />
    </>
  )
}

export default ContactTargetSlot
