import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, Hidden, Input, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  Calendar,
  ClassesType,
  Nil,
  Patient,
  PermissionArea,
  PuiSelect,
  PuiTextArea,
  PuiTextField,
  PuiTheme,
  User,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import { useGetAcquiredTypeList } from '~/constants/acquiredTypes'
import { getCRUDByArea, getCurrentBusiness } from '~/store/reducers/auth'
import { getIsUpdatingClientActivityState } from '~/store/reducers/clients'
import {
  getActivityLevel,
  getAnimalEnvironment,
  getCountryInsuranceProviderByCode,
  getServiceAnimal,
} from '~/store/reducers/constants'
import { getPatient, getPatientsIsLoading } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
      },
      padding: theme.spacing(0, 2, 3, 2),
    },
    itemsContainer: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(12),
        paddingRight: theme.spacing(2),
      },
    },
    additionalItem: {
      [theme.breakpoints.up('md')]: {
        '&:nth-of-type(odd)': {
          paddingRight: theme.spacing(1.5),
        },
        '&:nth-of-type(even)': {
          paddingLeft: theme.spacing(1.5),
        },
      },
    },
    textAreaContainer: {
      flexShrink: 0,
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
      },
    },
    verticalSpace: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
      },
    },
    flagTallField: {
      minHeight: 61,
    },
    deceasedDate: {
      minWidth: 90,
    },
    deceasedSwitch: {
      marginRight: theme.spacing(2),
    },
    deceasedLabel: {
      width: '140px !important',
    },
  }),
  { name: 'PatientDetailsList' },
)

interface PatientDetailsListProps {
  classes?: ClassesType<typeof useStyles>
  clientId: string
  editedField?: string | Nil
  onUpdate: (
    event: ChangeEvent<HTMLInputElement>,
    field: string,
    isBool?: boolean,
  ) => void
  patientId?: string
}

const PatientDetailsList = ({
  clientId,
  patientId,
  onUpdate,
  editedField,
  classes: classesProp,
}: PatientDetailsListProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Common', 'Clients'])

  const isLoading = useSelector(getPatientsIsLoading)
  const permissions = useSelector(getCRUDByArea(PermissionArea.PATIENT))
  const AnimalEnvironment = useSelector(getAnimalEnvironment)
  const ActivityLevel = useSelector(getActivityLevel)
  const ServiceAnimal = useSelector(getServiceAnimal)
  const client = useSelector(getUser(clientId)) || ({} as Partial<User>)
  const patient = useSelector(getPatient(patientId)) || ({} as Partial<Patient>)
  const business = useSelector(getCurrentBusiness)
  const InsuranceProvider = useSelector(
    getCountryInsuranceProviderByCode(business?.countryCatalogCode),
  )
  const isUpdatingClientActivityState = useSelector(
    getIsUpdatingClientActivityState,
  )

  const { AcquiredTypeItemsList } = useGetAcquiredTypeList()

  const showSendRemindersFlag = business?.sendReminders && client?.sendReminders

  const getIsLoading = (field: string) => isLoading && editedField === field

  return (
    <Grid container item className={classes.root} direction="row-reverse">
      <Grid
        container
        item
        xs
        className={classes.textAreaContainer}
        direction="column"
      >
        <Grid item xs>
          <PuiTextArea
            disabled={!permissions.update}
            isLoading={getIsLoading('diet')}
            label={t('Common:DIET_PATIENT')}
            minRows={4}
            value={patient.diet || ''}
            onChange={(event) =>
              onUpdate(event as ChangeEvent<HTMLInputElement>, 'diet')
            }
          />
        </Grid>
        <Grid item xs className={classes.verticalSpace}>
          <PuiTextArea
            disabled={!permissions.update}
            isLoading={getIsLoading('allergies')}
            label={t('Common:KNOWN_ALLERGIES')}
            minRows={4}
            value={patient.allergies || ''}
            onChange={(event) =>
              onUpdate(event as ChangeEvent<HTMLInputElement>, 'allergies')
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        alignItems="flex-end"
        className={classes.itemsContainer}
        md={8}
        sm={12}
      >
        <Grid
          container
          item
          alignItems="flex-end"
          className={classNames(classes.flagTallField, classes.additionalItem)}
          md={6}
          xs={12}
        >
          <Grid item>
            <PuiSwitch
              checked={patient.active || false}
              disabled={
                !permissions.update ||
                getIsLoading('active') ||
                isUpdatingClientActivityState
              }
              label={
                patient.active
                  ? t('Common:ACTIVE_ONE')
                  : t('Common:INACTIVE_ONE')
              }
              onChange={(event) => onUpdate(event, 'active', true)}
            />
          </Grid>
          {patient.externalPatientId && (
            <Grid item xs>
              <PuiTextField
                disabled
                InputProps={{
                  disableUnderline: true,
                }}
                label={t('Clients:PATIENT_DETAILS_LIST.EXTERNAL_PATIENT_ID', {
                  externalSource: patient.externalSource,
                })}
                margin="none"
                value={patient.externalPatientId}
              />
            </Grid>
          )}
        </Grid>
        {showSendRemindersFlag && (
          <Grid
            container
            item
            alignItems="flex-end"
            className={classes.additionalItem}
            md={6}
            xs={12}
          >
            <Grid item>
              <PuiSwitch
                checked={patient.sendReminders || false}
                disabled={!permissions.update || getIsLoading('sendReminders')}
                label={t('Common:SEND_REMINDERS')}
                onChange={(event) => onUpdate(event, 'sendReminders', true)}
              />
            </Grid>
          </Grid>
        )}

        <Grid
          container
          item
          alignItems="flex-end"
          className={classNames(classes.flagTallField, classes.additionalItem)}
          md={6}
          xs={12}
        >
          <Grid item className={classes.deceasedSwitch} sm={6}>
            <PuiSwitch
              checked={patient.deceased || false}
              disabled={!permissions.update}
              label={t('Common:DECEASED')}
              onChange={(event) => onUpdate(event, 'deceased', true)}
            />
          </Grid>
          {patient.deceased && (
            <Grid item sm className={classes.deceasedDate}>
              <Calendar
                fullWidth
                disabled={!permissions.update}
                label={t('Common:DECEASED_DATE')}
                value={patient.deceasedDate}
                onChange={(newDate) => {
                  onUpdate(
                    {
                      target: { value: newDate },
                    } as ChangeEvent<HTMLInputElement>,
                    'deceasedDate',
                  )
                }}
              />
            </Grid>
          )}
        </Grid>
        {/* placeholder for send reminders flag */}
        {showSendRemindersFlag && (
          <Hidden lgDown>
            <Grid
              container
              item
              alignItems="flex-end"
              className={classes.additionalItem}
              md={6}
            />
          </Hidden>
        )}
        <Grid item className={classes.additionalItem} md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="acquired-type-select">
              {t('Common:WHERE_CLIENT_GET_PATIENT', {
                clientFirstName: client.firstName,
                patientName: patient.name,
              })}
            </InputLabel>
            <PuiSelect
              disabled={!permissions.update}
              input={<Input id="acquired-type-select" />}
              isLoading={getIsLoading('acquiredType')}
              items={AcquiredTypeItemsList}
              value={patient.acquiredType}
              onChange={(event) =>
                onUpdate(event as ChangeEvent<HTMLInputElement>, 'acquiredType')
              }
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.additionalItem} md={6} xs={12}>
          <Calendar
            fullWidth
            disabled={!permissions.update}
            label={t('Common:WHEN_CLIENT_GET_PATIENT', {
              clientFirstName: client.firstName,
              patientName: patient.name,
            })}
            value={patient.acquiredDate}
            onChange={(newDate) => {
              onUpdate(
                { target: { value: newDate } } as ChangeEvent<HTMLInputElement>,
                'acquiredDate',
              )
            }}
          />
        </Grid>
        <Grid item className={classes.additionalItem} md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="environment-select">
              {t('Common:PET_ENVIRONMENT')}
            </InputLabel>
            <PuiSelect
              disabled={!permissions.update}
              input={<Input id="environment-select" />}
              isLoading={getIsLoading('environment')}
              items={AnimalEnvironment}
              value={patient.environment}
              onChange={(event) =>
                onUpdate(event as ChangeEvent<HTMLInputElement>, 'environment')
              }
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.additionalItem} md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="activityLevel-select">
              {t('Clients:PATIENT_DETAILS_LIST.FITNESS_LEVEL')}
            </InputLabel>
            <PuiSelect
              disabled={!permissions.update}
              input={<Input id="activityLevel-select" />}
              isLoading={getIsLoading('activityLevel')}
              items={ActivityLevel}
              value={patient.activityLevel}
              onChange={(event) =>
                onUpdate(
                  event as ChangeEvent<HTMLInputElement>,
                  'activityLevel',
                )
              }
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.additionalItem} md={6} xs={12}>
          <PuiTextField
            disabled={!permissions.update}
            isLoading={getIsLoading('microchipNumber')}
            label={t('Common:MICROCHIP_NUMBER')}
            value={patient.microchipNumber || ''}
            onChange={(event) =>
              onUpdate(
                event as ChangeEvent<HTMLInputElement>,
                'microchipNumber',
              )
            }
          />
        </Grid>
        <Grid item className={classes.additionalItem} md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="serviceAnimalType-select">
              {t('Common:SERVICE_DESIGNATION')}
            </InputLabel>
            <PuiSelect
              disabled={!permissions.update}
              input={<Input id="serviceAnimalType-select" />}
              isLoading={getIsLoading('serviceAnimalType')}
              items={ServiceAnimal}
              value={patient.serviceAnimalType}
              onChange={(event) =>
                onUpdate(
                  event as ChangeEvent<HTMLInputElement>,
                  'serviceAnimalType',
                )
              }
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.additionalItem} md={6} xs={12}>
          <PuiTextField
            disabled={!permissions.update}
            isLoading={getIsLoading('markingsTattoo')}
            label={t('Common:MARKINGS_OR_TATTOO')}
            value={patient.markingsTattoo || ''}
            onChange={(event) =>
              onUpdate(event as ChangeEvent<HTMLInputElement>, 'markingsTattoo')
            }
          />
        </Grid>
        <Grid item className={classes.additionalItem} md={6} xs={12}>
          <PuiTextField
            disabled={!permissions.update}
            isLoading={getIsLoading('license')}
            label={t('Common:LICENSE_NUMBER')}
            value={patient.license || ''}
            onChange={(event) =>
              onUpdate(event as ChangeEvent<HTMLInputElement>, 'license')
            }
          />
        </Grid>
        <Grid item className={classes.additionalItem} md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="insuranceProvider-select">
              {t('Common:INSURANCE')}
            </InputLabel>
            <PuiSelect
              disabled={!permissions.update}
              input={<Input id="insuranceProvider-select" />}
              isLoading={getIsLoading('insuranceProvider')}
              items={InsuranceProvider.map((item) =>
                typeof item === 'string' ? { id: item, name: item } : item,
              )}
              value={`${patient.insuranceProvider || ''}`}
              onChange={(event) =>
                onUpdate(
                  event as ChangeEvent<HTMLInputElement>,
                  'insuranceProvider',
                )
              }
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.additionalItem} md={6} xs={12}>
          <PuiTextField
            disabled={!permissions.update}
            isLoading={getIsLoading('insurancePolicyNumber')}
            label={t('Clients:PATIENT_DETAILS_LIST.PATIENT_INSURANCE_NUMBER')}
            value={patient.insurancePolicyNumber || ''}
            onChange={(event) =>
              onUpdate(
                event as ChangeEvent<HTMLInputElement>,
                'insurancePolicyNumber',
              )
            }
          />
        </Grid>
        <Grid item className={classes.additionalItem} md={6} xs={12}>
          <PuiTextField
            disabled={!permissions.update}
            isLoading={getIsLoading('rabiesTag')}
            label={t('Common:RABIES_TAG')}
            value={patient.rabiesTag || ''}
            onChange={(event) =>
              onUpdate(event as ChangeEvent<HTMLInputElement>, 'rabiesTag')
            }
          />
        </Grid>
        <Grid item className={classes.additionalItem} md={6} xs={12}>
          <Calendar
            fullWidth
            disabled={!permissions.update}
            label={t('Common:RABIES_TAG_EXPIRATION')}
            value={patient.rabiesTagExpiration}
            onChange={(newDate) => {
              onUpdate(
                { target: { value: newDate } } as ChangeEvent<HTMLInputElement>,
                'rabiesTagExpiration',
              )
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PatientDetailsList
