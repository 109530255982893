import React from 'react'
import { Grid, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  HtmlNotesPreview,
  LanguageUtils,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import { EventProduct } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    disabled: {
      opacity: 0.5,
    },
    cell: {
      padding: theme.spacing(1),
      border: theme.constants.tableBorder,
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'EventProductsTableRow' },
)

interface EventProductsTableRowProps {
  product: EventProduct
}

const EventProductsTableRow = ({ product }: EventProductsTableRowProps) => {
  const classes = useStyles()

  const { disabled, qty, notes, details } = product

  return (
    <TableRow className={classNames({ [classes.disabled]: disabled })}>
      <TableCell className={classes.cell}>
        <Text variant="body2">
          {LanguageUtils.getTranslatedFieldName(product)}
        </Text>
        {notes && (
          <Grid item mt={1}>
            <HtmlNotesPreview includeLabel fontSize="1.4rem" notes={notes} />
          </Grid>
        )}
        {details.map((detailLine) => (
          <Text key={detailLine} variant="body2">
            {detailLine}
          </Text>
        ))}
      </TableCell>
      <TableCell className={classes.cell}>{qty}</TableCell>
    </TableRow>
  )
}

export default EventProductsTableRow
