import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  LanguageUtils,
  PuiTheme,
  StateLabel,
  StateLabelProps,
  Utils,
} from '@pbt/pbt-ui-components'

import { ImagingStatusColor } from '~/constants/imaging'
import { getImagingOrderStatuses } from '~/store/reducers/constants'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    stateLabel: {
      width: 'fit-content',
      textAlign: 'center',
      borderRadius: 6,
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'ImagingStatusLabel' },
)

export interface ImagingStatusLabelProps extends StateLabelProps {
  statusId: string
}

const ImagingStatusLabel = ({ statusId, ...rest }: ImagingStatusLabelProps) => {
  const classes = useStyles()

  const ImagingOrderStatuses = useSelector(getImagingOrderStatuses)
  const statusName = Utils.getConstantName(statusId, ImagingOrderStatuses, '')
  const statusDisplayName = LanguageUtils.getConstantTranslatedName(
    statusId,
    ImagingOrderStatuses,
    '',
  )
  const color = ImagingStatusColor[statusName]

  return (
    <StateLabel
      className={classes.stateLabel}
      {...(color ? { [color]: true } : {})}
      {...rest}
    >
      {statusDisplayName}
    </StateLabel>
  )
}

export default ImagingStatusLabel
