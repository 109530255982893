import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { RoleBusinessCategory, User, Utils } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import { RootState } from '~/store'
import {
  updateTimetableFilteredPersonGroups,
  updateTimetableFilteredPersons,
} from '~/store/actions/timetable'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getBusiness } from '~/store/reducers/businesses'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getRolesMap } from '~/store/reducers/roles'
import {
  getTimetableFilteredPersonGroups,
  getTimetableFilteredPersons,
  getTimetablePersonsList,
} from '~/store/reducers/timetable'
import { Schedule, TimetableFilter, WhiteboardSchedule } from '~/types'
import { sortPersons } from '~/utils'

import { getTeamMembersFilters } from './TimetableFilters'
import TimetableFilterTab from './TimetableFilterTab'

const getFilterLabel = (candidate: User) => {
  const suffix = !candidate.active
    ? ` (${i18n.t('Common:INACTIVE_ONE').toLowerCase()})`
    : candidate.deleted
    ? ` (${i18n.t('Common:REMOVED').toLowerCase()})`
    : ''

  return `${Utils.getPersonString(candidate)}${suffix}`
}

export interface TeamMembersProps {
  schedulesSelector: (state: RootState) => Schedule[] | WhiteboardSchedule[]
}

const TeamMembers = ({ schedulesSelector }: TeamMembersProps) => {
  const dispatch = useDispatch()

  const businessId = useSelector(getCurrentBusinessId)
  const business = useSelector(getBusiness(businessId))
  const persons = useSelector(getTimetablePersonsList)
  const schedules = useSelector(schedulesSelector) as Schedule[]
  const roles = useSelector(getRolesMap)
  const selectedFilterPersons = useSelector(getTimetableFilteredPersons)
  const selectedFilterPersonGroups = useSelector(
    getTimetableFilteredPersonGroups,
  )
  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )
  const businessCategory = business?.omniChannel
    ? RoleBusinessCategory.CVC
    : RoleBusinessCategory.THIRD_PARTY

  const sortedPersons = sortPersons(persons)
  const personIds = R.pluck('id', sortedPersons)
  const selectedPersons = R.without(selectedFilterPersons, personIds)

  const filters: TimetableFilter<User>[] = useMemo(
    () =>
      getTeamMembersFilters(
        schedules,
        roles,
        businessId!,
        isCvcRolesEnabled ? businessCategory : undefined,
      ),
    [schedules, roles, businessId],
  )

  if (Object.keys(persons).length === 0) {
    return null
  }

  const handleFilteredDataChange = (filteredPersons: string[]) => {
    dispatch(updateTimetableFilteredPersons(filteredPersons))
  }

  const handleFilteredGroupChange = (filteredPersonGroups: string[]) => {
    dispatch(updateTimetableFilteredPersonGroups(filteredPersonGroups))
  }

  return (
    <TimetableFilterTab
      hasFilterTypes
      data={sortedPersons}
      filters={filters}
      selectedFilters={selectedFilterPersonGroups}
      selectedItems={selectedPersons}
      onFilteredDataChange={handleFilteredDataChange}
      onFilteredGroupChange={handleFilteredGroupChange}
    >
      {(candidate) => getFilterLabel(candidate)}
    </TimetableFilterTab>
  )
}

export default TeamMembers
