import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Grid, GridProps, IconButton, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ClassesType,
  Nil,
  PuiTheme,
  PuiTooltip,
  Text,
} from '@pbt/pbt-ui-components'
import {
  Edit as EditIcon,
  Warning as WarningIcon,
} from '@pbt/pbt-ui-components/src/icons'

import {
  AlertColorLevel,
  AlertLabelColorVariant,
  useGetAlertColor,
  useGetAlertLabelColors,
} from '~/constants/alertColors'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      lineHeight: '3rem',
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 4,
      backgroundColor: (props: UseStylesProps) => props.alertBlockColor,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 5,
        paddingRight: 5,
      },
    },
    icon: {
      marginRight: theme.spacing(1),
      color: (props: UseStylesProps) => props.alertTextColor,
    },
    text: {
      wordBreak: 'break-word',
      color: (props: UseStylesProps) => props.alertTextColor,
    },
    tooltip: {
      margin: 0,
      fontWeight: 500,
      fontSize: '1.4rem',
      color: (props: UseStylesProps) => props.alertBlockColor,
      border: (props: UseStylesProps) => `2px solid ${props.alertBlockColor}`,
      '&::before': {
        borderTopColor: (props: UseStylesProps) => props.alertBlockColor,
      },
    },
    editIcon: {
      color: theme.colors.tableBackground,
    },
    iconButton: {
      padding: theme.spacing(0.5),
      marginLeft: 'auto',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  }),
  { name: 'AlertLabel' },
)

export interface AlertLabelProps
  extends Omit<GridProps, 'style' | 'className'> {
  Icon?: React.JSXElementConstructor<any>
  alertColorId?: string | Nil
  alertColorLevel?: AlertColorLevel
  alertTypes?: string[]
  clamp?: number
  className?: string
  classes?: ClassesType<typeof useStyles>
  handleEdit?: () => void
  message?: React.ReactNode
  showEdit?: boolean
  showIcon?: boolean
  showText?: boolean
  showTooltip?: boolean
  small?: boolean
  tooltipMessage?: string
  variant?: string
}

interface UseStylesProps {
  alertBlockColor?: string
  alertTextColor?: string
  classes?: ClassesType<typeof useStyles>
}

const AlertLabel = ({
  small,
  classes: classesProp,
  clamp = 1,
  className,
  variant = AlertLabelColorVariant.ATTENTION,
  message = '',
  tooltipMessage = '',
  Icon: IconProp,
  showIcon: showIconProp = false,
  showText = true,
  showEdit = false,
  showTooltip,
  alertTypes,
  handleEdit,
  alertColorId,
  alertColorLevel,
  ...rest
}: AlertLabelProps) => {
  const { alertBlockColor, alertTextColor } = useGetAlertLabelColors(variant)
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )
  const useStylesProps: UseStylesProps = {
    classes: classesProp,
    alertBlockColor,
    alertTextColor,
  }

  const classes = useStyles(useStylesProps)

  const Icon = IconProp || WarningIcon
  const showIcon = showIconProp || Boolean(IconProp)
  const alertColor = useGetAlertColor(alertColorId, alertColorLevel)
  const colorStyle =
    variant === AlertLabelColorVariant.ATTENTION
      ? { backgroundColor: alertColor }
      : {}

  const preparedAlertTypes = (alertTypes || []).filter(Boolean)
  const displayText =
    preparedAlertTypes.length > 0 ? preparedAlertTypes.join(', ') : message
  const displayTooltipText =
    preparedAlertTypes.length > 0
      ? preparedAlertTypes.join(', ')
      : tooltipMessage

  const alertRender = (
    <Grid
      className={classNames(classes.root, className)}
      px={small ? 0.5 : 1}
      style={colorStyle}
      {...rest}
    >
      {showIcon && <Icon className={classes.icon} />}
      {showText && (
        <Dotdotdot clamp={clamp}>
          <Text
            strong
            className={classes.text}
            variant={small ? 'body5' : isMobile ? 'body3' : 'body2'}
          >
            {displayText}
          </Text>
        </Dotdotdot>
      )}
      {showEdit && (
        <IconButton
          className={classes.iconButton}
          size="large"
          onClick={handleEdit}
        >
          <EditIcon className={classes.editIcon} />
        </IconButton>
      )}
    </Grid>
  )

  return showTooltip ? (
    <PuiTooltip
      classes={{ tooltip: classes.tooltip }}
      tooltipPlacement="top"
      tooltipText={displayTooltipText}
    >
      {alertRender}
    </PuiTooltip>
  ) : (
    alertRender
  )
}

export default AlertLabel
