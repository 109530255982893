import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { isEmpty } from 'ramda'
import {
  DateFormat,
  LanguageUtils,
  moment,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import { getSchedulerBusinessWorkingHours } from '~/store/reducers/scheduler'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 180,
      padding: theme.spacing(0.75, 2),
      border: theme.constants.tableBorder,
    },
    unassignedText: {
      fontSize: '1.4rem',
    },
    hours: {
      color: theme.colors.sideText,
    },
  }),
  { name: 'BoardingRowHeader' },
)

export interface BoardingRowHeaderProps {
  className?: string
  isUnassigned?: boolean
  name: string
}

const BoardingRowHeader = ({
  className,
  isUnassigned,
  name,
}: BoardingRowHeaderProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const businessWorkingHours = useSelector(getSchedulerBusinessWorkingHours)

  const businessHoursFrom = moment(businessWorkingHours.from)
  const businessHoursTo = moment(businessWorkingHours.to)

  const businessHoursFromFormatted = businessHoursFrom.format(
    DateFormat.FULL_TIME_WITH_MERIDIAN,
  )
  const businessHoursToFormatted = businessHoursTo.format(
    DateFormat.FULL_TIME_WITH_MERIDIAN,
  )

  return (
    <Paper className={classNames(className, classes.paper)} elevation={0}>
      <Text
        strong
        className={classNames({
          [classes.unassignedText]: isUnassigned,
        })}
        variant="subheading2"
      >
        {isUnassigned ? t('Common:UNASSIGNED') : LanguageUtils.capitalize(name)}
      </Text>
      {isUnassigned && !isEmpty(businessWorkingHours) && (
        <Text strong className={classes.hours} variant="lowAccent5">
          {`${t(
            'Common:CLINIC_HOURS',
          )}: ${businessHoursFromFormatted}-${businessHoursToFormatted}`}
        </Text>
      )}
    </Paper>
  )
}

export default BoardingRowHeader
