import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, TextWithTooltip } from '@pbt/pbt-ui-components'
import { WarnAlert } from '@pbt/pbt-ui-components/src/icons'

import { TimetableEvent } from '~/types'

import { getAppointmentWarningLabel } from '../../../timetable/timetableUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    infoIcon: {
      color: theme.colors.important,
      fontSize: '1.5rem',
    },
    tooltip: {
      color: theme.colors.important,
      borderColor: theme.colors.important,
      '&::before': {
        borderTopColor: theme.colors.important,
        marginLeft: 5,
      },
      '&::after': {
        marginLeft: 8,
      },
    },
  }),
  { name: 'AppointmentSmallStatusCell' },
)

interface AppointmentSmallStatusCellProps {
  item: TimetableEvent
}

const AppointmentSmallStatusCell = ({
  item: appointment,
}: AppointmentSmallStatusCellProps) => {
  const classes = useStyles()

  const warningMessage = getAppointmentWarningLabel({
    from: appointment?.scheduledStartDatetime,
    to: appointment?.scheduledEndDatetime,
    stateName: appointment?.state?.name,
  })

  return warningMessage ? (
    <Dotdotdot clamp={1}>
      <TextWithTooltip
        strong
        Icon={WarnAlert}
        TooltipProps={{
          classes: {
            tooltip: classes.tooltip,
          },
        }}
        classes={{
          infoIcon: classes.infoIcon,
        }}
        tooltipText={warningMessage}
      />
    </Dotdotdot>
  ) : null
}

export default AppointmentSmallStatusCell
