import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  LanguageUtils,
  NamedEntity,
  Nil,
  PuiTheme,
  StateLabel,
  StateLabelProps,
  Utils,
} from '@pbt/pbt-ui-components'

import { ProblemType } from '~/constants/problems'
import {
  getDiagnosisProblemStates,
  getFindingProblemStates,
  getProblemBodySystemStates,
} from '~/store/reducers/constants'

import { problemStateColorVariantMap } from './IdentifiedProblemDetailsStatus'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      display: 'inline',
      padding: theme.spacing(0.5, 2),
    },
  }),
  { name: 'IdentifiedProblemListItemStatus' },
)

interface IdentifiedProblemListItemStatusProps {
  problemType: ProblemType | Nil
  stateId: string
  variant?: StateLabelProps['variant']
}

const IdentifiedProblemListItemStatus = ({
  stateId,
  problemType,
  variant = 'default',
}: IdentifiedProblemListItemStatusProps) => {
  const classes = useStyles()
  const DiagnosisProblemStates = useSelector(getDiagnosisProblemStates)
  const FindingProblemStates = useSelector(getFindingProblemStates)
  const ProblemBodySystemStates = useSelector(getProblemBodySystemStates)

  let constants: NamedEntity[]

  switch (problemType) {
    case ProblemType.DIAGNOSIS:
      constants = DiagnosisProblemStates
      break

    case ProblemType.FINDING:
      constants = FindingProblemStates
      break

    default:
      constants = ProblemBodySystemStates
  }

  const state = Utils.findById(stateId, constants)
  const colorProp = problemStateColorVariantMap[state?.name] ?? 'normal'

  return (
    <StateLabel
      {...{ [colorProp]: true }}
      className={classes.root}
      variant={variant}
    >
      {LanguageUtils.getTranslatedFieldName(state)}
    </StateLabel>
  )
}

export default IdentifiedProblemListItemStatus
