import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PermissionArea,
  PuiDialog,
  PuiTheme,
} from '@pbt/pbt-ui-components'
import { Delete } from '@pbt/pbt-ui-components/src/icons'

import {
  deleteReminder,
  editReminder,
  getIsLoading,
  getReminder,
} from '~/store/duck/reminders'
import { getCRUDByArea } from '~/store/reducers/auth'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import ReminderDetails, { ReminderDetailsHandle } from './ReminderDetails'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    button: {
      width: theme.spacing(7),
    },
  }),
  { name: 'ReminderDetailsDialog' },
)

interface ReminderDetailsDialogProps extends BasePuiDialogProps {
  reminderId: string
}

const ReminderDetailsDialog = ({
  open,
  onClose,
  reminderId,
}: ReminderDetailsDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const reminder = useSelector(getReminder(reminderId))
  const isLoading = useSelector(getIsLoading)
  const {
    delete: remindersDeletePermissions,
    update: remindersUpdatePermissions,
  } = useSelector(getCRUDByArea(PermissionArea.REMINDERS))

  const detailsRef = useRef<ReminderDetailsHandle>(null)
  const setCloseAfterSaving = useCloseAfterCreation(onClose, getIsLoading)

  const handleSave = () => {
    if (detailsRef.current?.validate()) {
      const editedReminder = detailsRef.current?.get()
      dispatch(editReminder(editedReminder))
      setCloseAfterSaving()
    }
  }

  const handleDelete = () => {
    dispatch(deleteReminder(reminderId))
    setCloseAfterSaving()
  }

  return (
    <PuiDialog
      fullWidth
      actions={
        <Grid container item justifyContent="space-between">
          <ButtonWithLoader
            className={classes.button}
            disabled={!remindersUpdatePermissions || isLoading}
            loading={isLoading}
            type="submit"
            onClick={remindersUpdatePermissions ? handleSave : undefined}
          >
            {t('Common:SAVE_ACTION')}
          </ButtonWithLoader>
          <IconButton
            disabled={!remindersDeletePermissions || isLoading}
            size="large"
            onClick={remindersDeletePermissions ? handleDelete : undefined}
          >
            <Delete />
          </IconButton>
        </Grid>
      }
      aria-labelledby="reminder-details-dialog"
      classes={{
        paper: classes.paper,
      }}
      maxWidth="sm"
      open={open}
      title={t('Dialogs:REMINDER_DETAILS_DIALOG.TITLE')}
      onClose={onClose}
    >
      <ReminderDetails
        disabled={!remindersUpdatePermissions}
        ref={detailsRef}
        reminder={reminder}
      />
    </PuiDialog>
  )
}

export default ReminderDetailsDialog
