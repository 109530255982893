import * as R from 'ramda'
import {
  all,
  put,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import {
  EstimatePage,
  InvoicePage,
  PaymentPage,
} from '~/api/graphql/generated/types'
import {
  fetchClientBillingActivity,
  fetchClientBillingActivityFailure,
  fetchClientBillingActivitySuccess,
  fetchEstimatePage,
  fetchEstimatePageFailure,
  fetchEstimatePageSuccess,
  fetchInvoicePage,
  fetchInvoicePageFailure,
  fetchInvoicePageSuccess,
  fetchMoreItemsForClientEstimateActivity,
  fetchMoreItemsForClientEstimateActivityFailure,
  fetchMoreItemsForClientEstimateActivitySuccess,
  fetchMoreItemsForClientInvoiceActivity,
  fetchMoreItemsForClientInvoiceActivityFailure,
  fetchMoreItemsForClientInvoiceActivitySuccess,
  fetchMoreItemsForClientPaymentActivity,
  fetchMoreItemsForClientPaymentActivityFailure,
  fetchMoreItemsForClientPaymentActivitySuccess,
  fetchPaymentPage,
  fetchPaymentPageFailure,
  fetchPaymentPageSuccess,
} from '~/store/duck/clientBillingActivityData'
import requestAPI from '~/store/sagas/utils/requestAPI'

const convertInvoiceResponseData = (responseData: any): InvoicePage['data'] =>
  responseData.map((data: any) => ({
    ...R.omit(['refundInvoiceNo', 'refundState', 'refundSections'], data),
    invoiceNo: data.invoiceNo || data.refundInvoiceNo,
    state: data.state || data.refundState,
    sections: data.sections || data.refundSections,
  }))

export function* fetchClientEstimatesSaga({
  payload,
}: ReturnType<typeof fetchEstimatePage>) {
  try {
    const { data, totalCount } = yield* requestAPI(
      API.fetchClientEstimates,
      payload.clientId,
      payload.offset,
      payload.limit,
    )
    yield put(
      fetchEstimatePageSuccess({
        data,
        totalCount,
      }),
    )
  } catch (error) {
    yield put(fetchEstimatePageFailure({ error: error as ApiError }))
  }
}

export function* fetchClientInvoicePageSaga({
  payload: {
    clientId,
    offset,
    limit,
    stateIds = [],
    refundStateIds = [],
    unpaid = false,
  },
}: ReturnType<typeof fetchInvoicePage>) {
  try {
    let response: InvoicePage
    if (unpaid) {
      response = yield* requestAPI(API.fetchUnpaidInvoicesByClientAndStates, {
        clientId,
        offset,
        limit,
      })
    } else {
      response = yield* requestAPI(API.fetchInvoicesByClientAndStates, {
        clientId,
        offset,
        limit,
        stateIds,
        refundStateIds,
      })
    }

    const { data: responseData, totalCount } = response
    const data = convertInvoiceResponseData(responseData)

    yield put(
      fetchInvoicePageSuccess({
        data,
        totalCount,
        unpaid,
      }),
    )
  } catch (error) {
    yield put(fetchInvoicePageFailure({ error: error as ApiError }))
  }
}

export function* fetchPaymentPageSaga({
  payload: { clientId, offset, limit, unapplied = false },
}: ReturnType<typeof fetchPaymentPage>) {
  try {
    let response: PaymentPage
    if (unapplied) {
      response = yield* requestAPI(
        API.fetchUnappliedPaymentByClient,
        clientId,
        offset,
        limit,
      )
    } else {
      response = yield* requestAPI(
        API.fetchAllPaymentEntities,
        clientId,
        offset,
        limit,
      )
    }
    yield put(
      fetchPaymentPageSuccess({
        data: response.data,
        totalCount: response.totalCount,
        unapplied,
      }),
    )
  } catch (error) {
    yield put(fetchPaymentPageFailure({ error: error as ApiError }))
  }
}

export function* fetchClientBillingActivitySaga({
  payload,
}: ReturnType<typeof fetchClientBillingActivity>) {
  try {
    const response = yield* requestAPI(API.fetchClientBillingActivity, payload)
    yield put(
      fetchClientBillingActivitySuccess({
        balance: response.billingActivitySummary.balance,
        deposits: response.billingActivitySummary.deposits,
        unpaidInvoices: response.billingActivitySummary.unpaidInvoices,
        unappliedPayments: response.billingActivitySummary.unappliedPayments,
        pendingRefunds: response.billingActivitySummary.pendingRefunds,
      }),
    )
  } catch (error) {
    yield put(fetchClientBillingActivityFailure({ error: error as ApiError }))
  }
}

function* fetchMoreItemsForClientInvoiceActivitySaga({
  payload: { clientId, from, to, stateIds = [], refundStateIds = [], unpaid },
}: ReturnType<typeof fetchMoreItemsForClientInvoiceActivity>) {
  try {
    let response: InvoicePage
    if (unpaid) {
      response = yield* requestAPI(API.fetchUnpaidInvoicesByClientAndStates, {
        clientId,
        offset: from,
        limit: to,
      })
    } else {
      response = yield* requestAPI(API.fetchInvoicesByClientAndStates, {
        clientId,
        offset: from,
        limit: to,
        stateIds,
        refundStateIds,
      })
    }

    const { data: responseData, totalCount } = response
    const data = convertInvoiceResponseData(responseData)

    yield put(
      fetchMoreItemsForClientInvoiceActivitySuccess({
        from,
        data,
        totalCount,
        unpaid,
      }),
    )
  } catch (error) {
    yield put(
      fetchMoreItemsForClientInvoiceActivityFailure({
        error: error as ApiError,
      }),
    )
  }
}

function* fetchMoreItemsForClientEstimateActivitySaga({
  payload: { clientId, from, to },
}: ReturnType<typeof fetchMoreItemsForClientEstimateActivity>) {
  try {
    const response: EstimatePage = yield* requestAPI(
      API.fetchClientEstimates,
      clientId,
      from,
      to,
    )

    const { data, totalCount } = response

    yield put(
      fetchMoreItemsForClientEstimateActivitySuccess({
        from,
        data,
        totalCount,
      }),
    )
  } catch (error) {
    yield put(
      fetchMoreItemsForClientEstimateActivityFailure({
        error: error as ApiError,
      }),
    )
  }
}

function* fetchMoreItemsForClientPaymentActivitySaga({
  payload: { clientId, from, to, unapplied },
}: ReturnType<typeof fetchMoreItemsForClientPaymentActivity>) {
  try {
    let response: PaymentPage
    if (unapplied) {
      response = yield* requestAPI(
        API.fetchUnappliedPaymentByClient,
        clientId,
        from,
        to,
      )
    } else {
      response = yield* requestAPI(
        API.fetchAllPaymentEntities,
        clientId,
        from,
        to,
      )
    }

    const { data, totalCount } = response

    yield put(
      fetchMoreItemsForClientPaymentActivitySuccess({
        from,
        data,
        totalCount,
        unapplied,
      }),
    )
  } catch (error) {
    yield put(
      fetchMoreItemsForClientPaymentActivityFailure({
        error: error as ApiError,
      }),
    )
  }
}

function* watchFetchClientEstimates() {
  yield takeLeading([fetchEstimatePage.type], fetchClientEstimatesSaga)
}

// As we also have unpaid invoices we want to watch for every dispatched action so we avoid any bad state
function* watchFetchClientInvoicePage() {
  yield takeEvery([fetchInvoicePage.type], fetchClientInvoicePageSaga)
}

function* watchFetchMoreItemsForClientInvoiceActivity() {
  yield takeLatest(
    [fetchMoreItemsForClientInvoiceActivity.type],
    fetchMoreItemsForClientInvoiceActivitySaga,
  )
}

function* watchFetchMoreItemsForClientEstimateActivity() {
  yield takeLatest(
    [fetchMoreItemsForClientEstimateActivity.type],
    fetchMoreItemsForClientEstimateActivitySaga,
  )
}

// As we also have unapplied payments we want to watch for every dispatched action so we avoid any bad state
function* watchFetchPaymentPage() {
  yield takeEvery([fetchPaymentPage.type], fetchPaymentPageSaga)
}

function* watchFetchClientBillingActivity() {
  yield takeLeading(
    [fetchClientBillingActivity.type],
    fetchClientBillingActivitySaga,
  )
}

function* watchFetchMoreItemsForClientPaymentActivity() {
  yield takeLatest(
    [fetchMoreItemsForClientPaymentActivity.type],
    fetchMoreItemsForClientPaymentActivitySaga,
  )
}

export default function* balanceSaga() {
  yield all([
    watchFetchClientEstimates(),
    watchFetchPaymentPage(),
    watchFetchClientInvoicePage(),
    watchFetchClientBillingActivity(),
    watchFetchMoreItemsForClientInvoiceActivity(),
    watchFetchMoreItemsForClientEstimateActivity(),
    watchFetchMoreItemsForClientPaymentActivity(),
  ])
}
