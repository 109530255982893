import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Collapse,
  Divider,
  Grid,
  MenuItemProps,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AddButton,
  Countries,
  LanguageUtils,
  PermissionArea,
  PhoneUtils,
  PuiTheme,
  RegionUtils,
  Text,
  User,
  Utils,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { partialUpdateClient } from '~/store/actions/clients'
import { deleteCoparent } from '~/store/actions/coparents'
import {
  useGetContactMethodWithDisabledFlag,
  useGetIsTextMessage,
} from '~/store/hooks/clients'
import { useGetReferredByName } from '~/store/hooks/contacts'
import { useCreatePatient } from '~/store/hooks/patient'
import {
  getCRUDByArea,
  getCurrentBusiness,
  getCurrentBusinessIsOmniChannel,
} from '~/store/reducers/auth'
import {
  getContactMethod,
  getFeatureToggle,
  getTag,
} from '~/store/reducers/constants'
import { getMultipleUsers, getUser } from '~/store/reducers/users'
import useDialog from '~/utils/useDialog'

import EmailVerificationAlert from '../../EmailVerificationAlert'
import { joinReferredByIds, parseReferredByIds } from '../../ReferredBySelect'
import PetFriendItem from '../PetFriendItem'
import ClientAndPatientDetailsRow, {
  ClientAndPatientDetailsRowProps,
} from './ClientAndPatientDetailsRow'
import { InlineEditInputType } from './InlineEditPopper'
import PatientItem from './PatientItem'
import PetFriendDetails from './PetFriendDetails'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      padding: theme.spacing(0.5, 0, 1, 0),
    },
    switchRoot: {
      marginLeft: theme.spacing(-1),
    },
    switchLabel: {
      color: theme.colors.secondaryText,
    },
    patientItem: {
      padding: theme.spacing(0.5, 0),
      borderBottom: theme.constants.tableBorder,
    },
    petFriendPrimaryText: {
      fontSize: '1.4rem',
    },
    petFriendSecondaryText: {
      fontSize: '1.2rem',
    },
    perFriendIconButton: {
      padding: theme.spacing(0.25),
      margin: theme.spacing(0.125),
    },
    collapse: {
      width: '100%',
    },
    emailVerificationIcon: {
      marginTop: theme.spacing(-0.5),
      marginLeft: theme.spacing(0.5),
    },
    emailReportIcon: {
      color: theme.colors.alertLabelError,
    },
    emailWarningIcon: {
      color: theme.colors.alertWarning,
    },
    selectItem: {
      whiteSpace: 'pre-line',
    },
  }),
  { name: 'ClientDetailsSection' },
)

const EMERGENCY_CONTACT_ID = 'EMERGENCY_CONTACT_ID'

interface ClientDetailsSectionProps {
  clientId: string
  mainPatientId?: string
  patientsIds: string[]
}

const ClientDetailsSection = ({
  clientId,
  mainPatientId,
  patientsIds,
}: ClientDetailsSectionProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { createPatient } = useCreatePatient()
  const isXs = useMediaQuery((theme: PuiTheme) => theme.breakpoints.down('sm'))
  const isSm = useMediaQuery((theme: PuiTheme) => theme.breakpoints.down('md'))
  const { t } = useTranslation([
    'Abbreviations',
    'Common',
    'Plurals',
    'Clients',
  ])

  const ContactMethod = useSelector(getContactMethod)
  const Tag = useSelector(getTag)
  const client = useSelector(getUser(clientId))
  const coparents = useSelector(getMultipleUsers(client?.coparents))
  const business = useSelector(getCurrentBusiness)
  const isCurrentBusinessOmniChannel = useSelector(
    getCurrentBusinessIsOmniChannel,
  )
  const isSuppressAddClientsAndPatientsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SUPPRESS_ADD_CLIENTS_AND_PATIENTS),
  )
  const { update: patientUpdatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.PATIENT),
  )

  const [petFriendDetailsExpanded, setPetFriendDetailsExpanded] = useState(
    {} as Record<string, boolean>,
  )

  const otherPatientsIds = patientsIds.filter(
    (patientId: string) => patientId !== mainPatientId,
  )
  const first5PatientsIds = otherPatientsIds.slice(0, 5)

  const [openPatientDialog] = useDialog(DialogNames.PATIENT)
  const [openPatientsListDialog] = useDialog(DialogNames.PATIENTS_LIST)
  const [openCoPetParentDialog] = useDialog(DialogNames.CO_PET_PARENT_DIALOG)
  const [openEmergencyContactDialog] = useDialog(
    DialogNames.EMERGENCY_CONTACT_DIALOG,
  )
  const [openPetFriendDialog] = useDialog(DialogNames.PET_FRIEND)

  const referredByValue = useGetReferredByName(
    client?.referralSourceId,
    client?.referralSourceTypeId,
  )
  const { canSendSmsToClient, ContactMethodWithDisabledFlag } =
    useGetContactMethodWithDisabledFlag(clientId)
  const isTextMessageOption = useGetIsTextMessage(
    client?.preferredContactMethodId,
  )

  const countryValue = RegionUtils.getAvailableCountry(
    client?.country || business?.country,
  )

  const update = R.curry((fieldName, newValue) => {
    dispatch(partialUpdateClient({ id: clientId, [fieldName]: newValue }))
  })

  const updatePhoneNumber = R.curry((fieldName, newValue) =>
    update(fieldName, PhoneUtils.parsePhoneNumber(newValue)),
  )

  const handleUpdateReferredBy = (referredBy: string) => {
    const [referredById, referredByType] = parseReferredByIds(referredBy) || []
    dispatch(
      partialUpdateClient({
        id: clientId,
        referralSourceId: referredById,
        referralSourceTypeId: referredByType,
      }),
    )
  }

  const clientDetails: (ClientAndPatientDetailsRowProps | undefined)[] = [
    client?.externalSource
      ? {
          label: client.externalSource,
          value: client?.externalPersonId,
          placeholder: '',
        }
      : undefined,
    {
      label: t('Common:EMAIL'),
      value: client?.email,
      onEdit: update('email'),
      inputType: InlineEditInputType.CLIENT_EMAIL,
      inputProps: { client },
      fieldProps: {
        name: 'email',
        label: t('Common:EMAIL'),
        validators: ['email'],
        initialValue: client?.email,
      },
      containerDirection: 'row',
      subContent: <EmailVerificationAlert clientId={clientId} />,
    },
    {
      label: t('Common:MOBILE_NUMBER_SIGN'),
      value: PhoneUtils.formatPhoneNumber(client?.mobilePhone),
      onEdit: updatePhoneNumber('mobilePhone'),
      inputType: InlineEditInputType.PHONE,
      fieldProps: {
        name: 'mobilePhone',
        label: t('Common:MOBILE_PHONE'),
        validators: ['phone'],
        initialValue: client?.mobilePhone || '',
      },
    },
    {
      label: t('Common:HOME_PHONE_NUMBER_SIGN'),
      value: PhoneUtils.formatPhoneNumber(client?.homePhone),
      onEdit: updatePhoneNumber('homePhone'),
      inputType: InlineEditInputType.PHONE,
      fieldProps: {
        name: 'homePhone',
        label: t('Common:HOME_PHONE'),
        validators: ['phone'],
        initialValue: client?.homePhone || '',
      },
    },
    {
      label: t('Common:WORK_PHONE_NUMBER_SIGN'),
      value: PhoneUtils.formatPhoneNumber(client?.workPhone),
      onEdit: updatePhoneNumber('workPhone'),
      inputType: InlineEditInputType.PHONE,
      fieldProps: {
        name: 'workPhone',
        label: t('Common:WORK_PHONE'),
        validators: ['phone'],
        initialValue: client?.workPhone || '',
      },
    },
    {
      label: t('Common:OTHER_NUMBER_SIGN'),
      value: PhoneUtils.formatPhoneNumber(client?.otherPhone),
      onEdit: updatePhoneNumber('otherPhone'),
      inputType: InlineEditInputType.PHONE,
      fieldProps: {
        name: 'otherPhone',
        label: t('Common:OTHER_PHONE'),
        validators: ['phone'],
        initialValue: client?.otherPhone || '',
      },
    },
    {
      label: t('Common:ADDRESS'),
      value: client?.address,
      onEdit: update('address'),
      fieldProps: { name: 'address', initialValue: client?.address },
    },
    {
      label: t('Abbreviations:COMMON.APARTMENT_NUMBER_SIGN'),
      value: client?.suite,
      onEdit: update('suite'),
      fieldProps: { name: 'suite', initialValue: client?.suite },
    },
    {
      label: t('Common:COUNTY'),
      value: client?.county,
      onEdit: update('county'),
      fieldProps: { name: 'county', initialValue: client?.county },
    },
    {
      label: t('Common:CITY'),
      value: client?.city,
      onEdit: update('city'),
      fieldProps: { name: 'city', initialValue: client?.city },
    },
    {
      label: t('Common:STATE'),
      value: client?.state,
      onEdit: update('state'),
      inputType: InlineEditInputType.STATE,
      inputProps: { country: countryValue },
      fieldProps: {
        name: 'state',
        type: 'select',
        initialValue: client?.state,
      },
    },
    {
      label: t('Common:COUNTRY'),
      value: countryValue,
      displayValue: LanguageUtils.getConstantTranslatedName(
        countryValue,
        Countries,
      ),
      onEdit: update('country'),
      inputType: InlineEditInputType.COUNTRY,
      fieldProps: {
        name: 'country',
        type: 'select',
        initialValue: countryValue,
      },
    },
    {
      label: t('Abbreviations:COMMON.ZIP_CODE'),
      value: client?.zip,
      onEdit: update('zip'),
      inputType: InlineEditInputType.ZIP,
      inputProps: { country: countryValue },
      fieldProps: {
        name: 'zip',
        label: t('Common:ZIP_CODE'),
        validators: ['zip'],
        initialValue: client?.zip,
      },
    },
    {
      label: t('Common:CONTACT_ONE'),
      value: Utils.getConstantName(
        client?.preferredContactMethodId,
        ContactMethod,
      ),
      displayValue: LanguageUtils.getConstantTranslatedName(
        client?.preferredContactMethodId,
        ContactMethod,
      ),
      onEdit: update('preferredContactMethodId'),
      inputType: InlineEditInputType.SELECT,
      inputProps: {
        items: ContactMethodWithDisabledFlag,
        selectItemProps: {
          classes: { root: classes.selectItem },
        } as MenuItemProps,
      },
      fieldProps: {
        name: 'preferredContactMethodId',
        type: 'select',
        initialValue: canSendSmsToClient
          ? client?.preferredContactMethodId
          : isTextMessageOption
          ? undefined
          : client?.preferredContactMethodId,
      },
    },
    {
      label: t('Common:TAG'),
      value: Utils.getConstantName(client?.tag, Tag),
      displayValue: LanguageUtils.getConstantTranslatedName(client?.tag, Tag),
      onEdit: update('tag'),
      inputType: InlineEditInputType.SELECT,
      inputProps: { items: Tag },
      fieldProps: { name: 'tag', type: 'select', initialValue: client?.tag },
    },
    {
      label: t('Common:REFERRED_BY'),
      value: referredByValue || '',
      showBorder: false,
      onEdit: handleUpdateReferredBy,
      inputType: InlineEditInputType.REFERRED_BY,
      fieldProps: {
        name: 'referredBy',
        type: 'select',
        initialValue:
          joinReferredByIds(
            client?.referralSourceId,
            client?.referralSourceTypeId,
          ) || '',
      },
    },
  ]

  const emergencyContacts = client?.emergencyContact
    ? [{ ...client?.emergencyContact, id: EMERGENCY_CONTACT_ID }]
    : []

  const mapToPetFriendItem =
    (role: string) => (petFriend: Partial<User> & { id: string }) => ({
      ...petFriend,
      role,
    })

  const petFriends = [
    ...emergencyContacts.map(mapToPetFriendItem(t('Common:EMERGENCY_CONTACT'))),
    ...(coparents || []).map(mapToPetFriendItem(t('Common:CO-PET_PARENT'))),
  ]

  const hasEmergencyContact = Boolean(client?.emergencyContact)

  const showAddPatient = !(
    isCurrentBusinessOmniChannel && isSuppressAddClientsAndPatientsEnabled
  )

  const handleAddPatient = () => {
    if (isXs) {
      navigate(`/client/${clientId}/patient/new`)
    } else {
      openPatientDialog({
        clientId,
        createPatient,
      })
    }
  }

  const handlePatientClick = (patientId?: string) => {
    navigate(`/client/${clientId}/patient/${patientId}`)
  }

  const handlePetFriendDetailsClick = (petFriendId: string) => {
    if (isSm) {
      if (petFriendId === EMERGENCY_CONTACT_ID) {
        navigate(`/client/${clientId}/emergencycontact`)
      } else {
        navigate(`/client/${clientId}/coparent/${petFriendId}`)
      }
    } else {
      setPetFriendDetailsExpanded({
        ...petFriendDetailsExpanded,
        [petFriendId]: !petFriendDetailsExpanded[petFriendId],
      })
    }
  }

  const handleAddPetFriend = () => {
    if (hasEmergencyContact) {
      if (isXs) {
        navigate(`/client/${clientId}/coparent/new`)
      } else {
        openCoPetParentDialog({ clientId })
      }
    } else if (isXs) {
      navigate(`/client/${clientId}/petfriend`)
    } else {
      openPetFriendDialog({ clientId })
    }
  }

  const handlePetFriendEdit = (petFriendId: string) => {
    if (petFriendId === EMERGENCY_CONTACT_ID) {
      openEmergencyContactDialog({ clientId })
    } else {
      openCoPetParentDialog({ clientId, coparentId: petFriendId })
    }
  }

  const handlePetFriendDelete = (petFriendId: string) => {
    if (petFriendId === EMERGENCY_CONTACT_ID) {
      dispatch(partialUpdateClient({ id: clientId, emergencyContact: null }))
    } else {
      dispatch(deleteCoparent(clientId, petFriendId))
    }
  }

  return (
    <Grid container className={classes.root} direction="column">
      {clientDetails.filter(Boolean).map((props) => (
        <ClientAndPatientDetailsRow
          entityType="client"
          key={clientId + (props?.label || '')}
          {...props}
        />
      ))}
      <Text strong mb={1} mt={1.5} variant="h5">
        {t('Common:OTHER_PATIENTS')}
      </Text>
      <Grid item mb={1.5}>
        {showAddPatient && (
          <AddButton
            addText={t('Common:ADD_PATIENT')}
            onAdd={handleAddPatient}
          />
        )}
      </Grid>
      <Grid container direction="column">
        {first5PatientsIds.map((patientId) => (
          <PatientItem
            className={classes.patientItem}
            key={patientId}
            patientId={patientId}
            onClick={handlePatientClick}
          />
        ))}
        {otherPatientsIds.length > 5 && (
          <Text
            link
            align="center"
            my={0.5}
            variant="h5"
            onClick={() =>
              openPatientsListDialog({
                patientsIds,
                onPatientClick: handlePatientClick,
              })
            }
          >
            {t('Plurals:Z_ICU_WORKAROUND.SHOW_ALL_PETS', {
              numResults: otherPatientsIds.length,
            })}
          </Text>
        )}
      </Grid>

      <Text strong mb={1} mt={1.5} variant="h5">
        {t('Clients:CLIENT_DETAILS_SECTION.PET_FRIENDS')}
      </Text>

      <Grid item mb={1.5}>
        <AddButton
          addText={t('Common:ADD_PET_FRIEND')}
          disabled={!patientUpdatePermissions}
          onAdd={patientUpdatePermissions ? handleAddPetFriend : undefined}
        />
      </Grid>

      <Grid container>
        {petFriends.length > 0 &&
          petFriends.map((petFriend) => (
            <React.Fragment key={petFriend.id}>
              <PetFriendItem
                {...petFriend}
                classes={{
                  petFriendPrimaryText: classes.petFriendPrimaryText,
                  petFriendSecondaryText: classes.petFriendSecondaryText,
                  iconButton: classes.perFriendIconButton,
                }}
                onDelete={handlePetFriendDelete}
                onEdit={handlePetFriendEdit}
                onToggle={handlePetFriendDetailsClick}
              />
              {!isXs && (
                <Collapse
                  className={classes.collapse}
                  in={Boolean(
                    petFriend.id && petFriendDetailsExpanded[petFriend.id],
                  )}
                >
                  <PetFriendDetails
                    clientId={clientId}
                    isEmergencyContact={petFriend.id === EMERGENCY_CONTACT_ID}
                    petFriend={petFriend}
                  />
                </Collapse>
              )}
              <Divider />
            </React.Fragment>
          ))}
      </Grid>
    </Grid>
  )
}

export default ClientDetailsSection
