import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { groupBy, propOr } from 'ramda'
import {
  ClassesType,
  LanguageUtils,
  NamedEntity,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      width: '100%',
      borderRadius: 0,
      boxShadow: 'none',
      margin: '0px !important',
      '&::before': {
        display: 'none',
      },
      '&:not(:first-of-type)': {
        borderTop: theme.constants.tabBorder,
      },
      '&:last-of-type': {
        borderBottom: theme.constants.tabBorder,
        marginBottom: -1,
      },
    },
    summary: {
      padding: theme.spacing(1, 0.5, 1, 1.5),
      minHeight: '32px !important',
    },
    summaryContent: {
      margin: '0 !important',
      width: '100%',
      alignItems: 'center',
    },
    details: {
      padding: theme.spacing(0, 3, 1, 3),
      flexDirection: 'column',
    },
    container: {
      backgroundColor: theme.colors.tableBackground,
      padding: theme.spacing(0.5, 0.5, 0.5, 2),
      height: 40,
    },
    formControl: {
      paddingLeft: theme.spacing(1.5),
    },
    name: {
      color: theme.colors.secondaryText,
      marginLeft: theme.spacing(1),
      fontWeight: 500,
    },
    subItemsContainer: {
      paddingLeft: 14,
    },
    subItem: {
      padding: theme.spacing(0.5, 0.5, 0.5, 2),
    },
    subItemName: {
      color: theme.colors.secondaryText,
      marginLeft: theme.spacing(1),
    },
    expandCollapseIconButton: {
      padding: theme.spacing(0.5),
      marginLeft: 'auto',
    },
    checkbox: {
      padding: 0,
      '&&&&:hover': {
        backgroundColor: 'transparent',
      },
      color: theme.colors.secondaryText,
    },
    radioButton: {
      padding: theme.spacing(0.5, 0),
      '&&&&:hover': {
        backgroundColor: 'transparent',
      },
      color: theme.colors.secondaryText,
    },
    selected: {
      color: theme.colors.tabSelected,
    },
    linkButton: {
      color: theme.colors.markerHighlighted,
      marginLeft: 36,
      height: 32,
      display: 'flex',
      alignItems: 'center',
    },
    ellipsis: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    restSubItems: {
      overflow: 'hidden',
    },
    restSubItemsExpanded: {
      opacity: 1,
      transition: theme.transitions.create(['opacity', 'max-height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    restSubItemsCollapsed: {
      opacity: 0,
      maxHeight: 0,
      transition: theme.transitions.create(['opacity', 'max-height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    selectedIcon: {
      color: theme.colors.markerHighlighted,
    },
    formControlLabel: {
      width: '100%',
    },
    refineLabel: {
      color: theme.colors.promptLabel,
    },
  }),
  { name: 'ListFilter' },
)

const DEFAULT_GROUP_NAME = i18n.t('Search:REFINE_FURTHER')

export type ListFilterSubItem = Partial<NamedEntity> & {
  categoryId: string
  refineGroup?: string
  selected: boolean
  subcategoryId?: string
  type: string
}

export type ListFilterType = Omit<NamedEntity, 'id'> & {
  categoryId: string
  children?: ListFilterSubItem[]
  id?: string
  type: string
}

export interface ListFilterProps {
  classes?: ClassesType<typeof useStyles>
  editDisabled: boolean
  expanded?: boolean
  filter: ListFilterType
  importantSubItemsNumber?: number
  label?: string
  onChange: (filter: ListFilterProps['filter']) => void
  onClearFilters: (type: string) => void
  onExpandChange: (expanded: boolean) => void
  onSubItemChange: (subItem: ListFilterSubItem) => void
  selected: boolean
  subItems: ListFilterSubItem[]
}

const ListFilter = ({
  classes: classesProp,
  editDisabled = false,
  filter,
  label,
  selected,
  onChange,
  onSubItemChange,
  onClearFilters,
  subItems,
  importantSubItemsNumber,
  expanded = false,
  onExpandChange,
}: ListFilterProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const [showAll, setShowAll] = useState(false)

  const { categoryId: rootCategoryId, name, nameTranslation, type } = filter

  const hasSubItems = subItems.length > 1
  const groupedSubItems = groupBy(
    propOr(DEFAULT_GROUP_NAME, 'refineGroup'),
    subItems,
  )
  const groupedSubItemsKeys = Object.keys(groupedSubItems)

  const handleExpand = () => {
    onExpandChange(!expanded)
  }

  const importantSubItems = importantSubItemsNumber
    ? subItems.slice(0, importantSubItemsNumber)
    : subItems
  const restSubItems = subItems.slice(importantSubItems.length)
  const hasSelectedItemsInRest = restSubItems.some(
    (subItem) => subItem.selected,
  )

  useEffect(() => {
    if (hasSelectedItemsInRest) {
      setShowAll(true)
    }
  }, [hasSelectedItemsInRest])

  useEffect(() => {
    if (!selected) {
      setShowAll(false)
    }
  }, [selected])

  const renderSubItem = (subItem: ListFilterSubItem) => (
    <Grid
      item
      className={classes.subItem}
      key={subItem.categoryId || subItem.id || subItem.subcategoryId}
    >
      <FormControlLabel
        classes={{
          root: classes.formControlLabel,
          label: classNames(classes.subItemName, classes.ellipsis),
        }}
        control={
          <Checkbox
            checked={subItem.selected}
            className={classes.checkbox}
            disabled={editDisabled}
            onChange={({ target }) =>
              onSubItemChange({
                type,
                categoryId: (rootCategoryId ||
                  subItem.categoryId ||
                  subItem.id) as string,
                subcategoryId: subItem.subcategoryId,
                selected: target.checked,
              })
            }
          />
        }
        label={subItem.nameTranslation || subItem.name}
      />
    </Grid>
  )

  return (
    <Accordion
      className={classes.root}
      expanded={expanded}
      onChange={(event) => {
        event.preventDefault()
        event.stopPropagation()
        if (selected || !expanded) {
          handleExpand()
        }
        if (!selected) {
          onChange(filter)
        }
      }}
    >
      <AccordionSummary
        classes={{ root: classes.summary, content: classes.summaryContent }}
      >
        <FormControlLabel
          classes={{
            root: classes.formControl,
            label: classNames(
              classes.name,
              classes.ellipsis,
              selected && classes.selected,
            ),
          }}
          control={<Radio checked={selected} className={classes.radioButton} />}
          label={
            label ? LanguageUtils.capitalize(label) : nameTranslation || name
          }
        />
        {hasSubItems && (
          <IconButton
            disableRipple
            className={classNames(
              classes.expandCollapseIconButton,
              selected && classes.selectedIcon,
            )}
            size="large"
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              handleExpand()
            }}
          >
            {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        )}
      </AccordionSummary>
      {hasSubItems &&
        groupedSubItemsKeys.map((groupName) => (
          <AccordionDetails classes={{ root: classes.details }} key={groupName}>
            <Grid
              container
              item
              className={classes.subItemsContainer}
              direction="column"
            >
              <Grid container item justifyContent="space-between">
                <Text
                  strong
                  className={classes.refineLabel}
                  pl={1}
                  variant="body2"
                >
                  {groupName}
                </Text>
                {groupedSubItems[groupName].some(
                  (subItem) => subItem.selected,
                ) && (
                  <Text
                    link
                    variant="body2"
                    onClick={() => onClearFilters(type)}
                  >
                    {t('Common:CLEAR_FILTERS')}
                  </Text>
                )}
              </Grid>
              {groupedSubItemsKeys.length === 1 ? (
                <>
                  {importantSubItems.map(renderSubItem)}
                  <div
                    className={classNames(
                      classes.restSubItems,
                      showAll
                        ? classes.restSubItemsExpanded
                        : classes.restSubItemsCollapsed,
                    )}
                    style={{
                      maxHeight: showAll ? restSubItems.length * 32 + 16 : 0,
                    }}
                  >
                    {restSubItems.map(renderSubItem)}
                  </div>
                  {importantSubItemsNumber &&
                    subItems.length > importantSubItemsNumber && (
                      <Text
                        link
                        noWrap
                        className={classes.linkButton}
                        onClick={() => setShowAll(!showAll)}
                      >
                        {`${showAll ? t('Common:LESS') : t('Common:MORE')} ${(
                          nameTranslation || name
                        ).toLowerCase()}`}
                      </Text>
                    )}
                </>
              ) : (
                groupedSubItems[groupName].map(renderSubItem)
              )}
            </Grid>
          </AccordionDetails>
        ))}
    </Accordion>
  )
}

export default ListFilter
