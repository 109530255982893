import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import { VideoItem } from '~/types/entities/analytics'

import EducationalVideoDialog from './EducationalVideoDialog'
import EducationalVideoLink from './EducationalVideoLink'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      backgroundColor: theme.colors.dialogHeaderBackground,
    },
    item: {
      margin: theme.spacing(0, 2),
    },
  }),
  { name: 'EducationalSection' },
)

interface EducationalSectionProps {
  content: VideoItem[]
}

const EducationalSection = ({ content }: EducationalSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const [videoValue, setVideoValue] = useState<string | null>(null)

  const handleVideoLink = (value: string) => {
    setVideoValue(value)
  }

  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      justifyContent="center"
      pb={3}
      pt={1}
      px={2}
    >
      <Text strong mb={1} variant="subheading3">
        {t('Common:LEARN_ABOUT_REPORTS_ON_THIS_PAGE')}
      </Text>
      <Grid container item justifyContent="center" wrap="nowrap">
        {content.map((item) => (
          <EducationalVideoLink
            classes={{ root: classes.item }}
            key={item.value}
            value={item.value}
            onClick={handleVideoLink}
          />
        ))}
      </Grid>
      <EducationalVideoDialog
        items={content}
        open={Boolean(videoValue)}
        value={videoValue}
        onClose={() => {
          setVideoValue(null)
        }}
      />
    </Grid>
  )
}

export default EducationalSection
