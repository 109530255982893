import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import { getAvailabilityRuleIsLoading } from '~/store/reducers/availabilityRules'

import AvailabilityRule, { AvailabilityRuleHandle } from './AvailabilityRule'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      maxWidth: 650,
      width: 650,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    dialogContentRoot: {
      padding: theme.spacing(1, 2),
    },
    button: {
      width: theme.spacing(20),
    },
  }),
  { name: 'AvailabilityRuleDialog' },
)

export interface AvailabilityRuleDialogProps extends BasePuiDialogProps {}

const AvailabilityRuleDialog = ({
  open,
  onClose,
}: AvailabilityRuleDialogProps) => {
  const classes = useStyles()
  const isLoading = useSelector(getAvailabilityRuleIsLoading)
  const { t } = useTranslation('Common')

  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )

  const ruleRef = useRef<AvailabilityRuleHandle>(null)

  const onOk = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: () => ruleRef.current?.createRule(),
      }}
      actions={
        <ButtonWithLoader
          className={classes.button}
          loading={isLoading}
          onClick={() => ruleRef.current?.createRule()}
        >
          {t('Common:ADD_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="availability-rule-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      hasUnsavedChanges={() => ruleRef.current?.hasUnsavedChanges() || false}
      maxWidth={isMobile ? 'sm' : 'md'}
      open={open}
      title={t('Common:NEW_RULE')}
      onClose={onClose}
    >
      <AvailabilityRule ref={ruleRef} onOk={onOk} />
    </PuiDialog>
  )
}

export default AvailabilityRuleDialog
