import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, MenuItem, Select } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { useDebounce } from 'use-debounce'
import {
  Constant,
  Defaults,
  LanguageUtils,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { ChevronDown } from '@pbt/pbt-ui-components/src/icons'

import PracticeStatusLabel from '~/components/dashboard/admin/general/practices/PracticeStatusLabel'
import { getBusinessStatus, getPracticeTypes } from '~/store/reducers/constants'
import { BusinessFiltersRecord } from '~/types'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import BusinessTypeLabel from './BusinessTypeLabel'
import SearchInput from './inputs/SearchInput'

const DEFAULT_SELECT_VALUE = 'all'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      borderBottom: theme.constants.tabBorder,
    },
    search: {
      marginRight: theme.spacing(2),
    },
    searchIcon: {
      color: theme.colors.secondaryText,
    },
    statusSelect: {
      margin: theme.spacing(0, 1),
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  }),
  { name: 'BusinessFilter' },
)

export interface BusinessFilterProps {
  initialFilters: BusinessFiltersRecord
  initialSearch: string
  onFilterChange: (filters: BusinessFiltersRecord) => void
  onSearchChange: (search: string) => void
}

const BusinessFilter = ({
  initialSearch,
  initialFilters,
  onFilterChange,
  onSearchChange,
}: BusinessFilterProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const BusinessStatus: Constant[] = useSelector(getBusinessStatus)
  const PracticeTypes: Constant[] = useSelector(getPracticeTypes)

  const [rawSearch, setRawSearch] = useState(initialSearch)
  const [practiceTypeIds, setPracticeTypeIds] = useState(
    initialFilters.practiceTypeIds || DEFAULT_SELECT_VALUE,
  )
  const [statusIds, setStatusIds] = useState(
    initialFilters.statusIds || DEFAULT_SELECT_VALUE,
  )

  const [search] = useDebounce(rawSearch, Defaults.DEBOUNCE_ACTION_TIME)

  useEffectExceptOnMount(() => {
    onSearchChange(search)
  }, [search])

  useEffectExceptOnMount(() => {
    onFilterChange({
      practiceTypeIds:
        practiceTypeIds !== DEFAULT_SELECT_VALUE ? practiceTypeIds : '',
      statusIds: statusIds !== DEFAULT_SELECT_VALUE ? statusIds : '',
    })
  }, [practiceTypeIds, statusIds])

  const renderTypeValue = (value: string) => {
    if (value === DEFAULT_SELECT_VALUE) {
      return (
        <Text strong mr={0.25} variant="body2">
          {t('Common:TYPE_OTHER')}
        </Text>
      )
    }
    const item = PracticeTypes.find(R.propEq('id', value))
    return item ? (
      <BusinessTypeLabel icon={item.name}>
        {LanguageUtils.getTranslatedFieldName(item)}
      </BusinessTypeLabel>
    ) : (
      <BusinessTypeLabel>{value}</BusinessTypeLabel>
    )
  }

  const renderStatusValue = (value: string) =>
    value === DEFAULT_SELECT_VALUE ? (
      <Text strong mr={0.25} variant="body2">
        {t('Common:STATUS')}
      </Text>
    ) : (
      <PracticeStatusLabel statusId={value} variant="big" />
    )

  return (
    <Grid
      container
      alignItems="flex-end"
      className={classes.root}
      justifyContent="space-between"
      px={2}
      py={1}
      wrap="nowrap"
    >
      <SearchInput
        autoFocus
        className={classes.search}
        label={t('Common:SEARCH_ACTION')}
        value={rawSearch}
        onChange={setRawSearch}
      />
      <Grid
        container
        item
        alignItems="center"
        justifyContent="flex-end"
        wrap="nowrap"
      >
        <Select
          IconComponent={ChevronDown}
          className={classes.statusSelect}
          label={t('Common:TYPE_OTHER')}
          renderValue={renderTypeValue}
          value={practiceTypeIds}
          onChange={Utils.handleFormSelectInput(setPracticeTypeIds)}
        >
          <MenuItem value={DEFAULT_SELECT_VALUE}>
            <Text variant="body">{t('Common:ALL_TYPES')}</Text>
          </MenuItem>
          {PracticeTypes.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <BusinessTypeLabel icon={item.name}>
                {LanguageUtils.getTranslatedFieldName(item)}
              </BusinessTypeLabel>
            </MenuItem>
          ))}
        </Select>
        <Select
          IconComponent={ChevronDown}
          className={classes.statusSelect}
          label={t('Common:STATUS')}
          renderValue={renderStatusValue}
          value={statusIds}
          onChange={Utils.handleFormSelectInput(setStatusIds)}
        >
          <MenuItem value={DEFAULT_SELECT_VALUE}>
            <Text variant="body">{t('Common:ALL_STATUS')}</Text>
          </MenuItem>
          {BusinessStatus.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <PracticeStatusLabel statusId={item.id} variant="big" />
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  )
}

export default BusinessFilter
