import React from 'react'
import { CardHeader, Grid, Hidden, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { isEmpty } from 'ramda'
import {
  ControlButtonGroup,
  ControlButtonGroupName,
  ControlButtonGroupProps,
  DateFormat,
  moment,
  Nil,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import ColorPinIcon from '~/components/common/icons/ColorPinIcon'
import { useCurrentBusinessTimezone } from '~/utils/useCurrentBusinessTimezone'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      position: 'relative',
    },
    subheader: {
      fontSize: '1.4rem',
      marginLeft: theme.spacing(1),
      color: theme.colors.editIconColor,
    },
    headerTitle: {
      color: theme.colors.primaryText,
      fontSize: '1.4rem',
    },
    headerRoot: {
      padding: theme.spacing(0, 2),
    },
    container: {
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% - 25px)',
        maxWidth: 'calc(100% - 25px)',
      },
    },
    iconContainer: {
      width: 25,
      minWidth: 25,
      position: 'relative',
    },
    titleContainer: {
      padding: theme.spacing(0, 2),
    },
    title: {
      lineHeight: '2.4rem',
      overflowWrap: 'anywhere',
    },
    mainPaper: {
      [theme.breakpoints.down('sm')]: {
        overflowX: 'auto',
        paddingTop: theme.spacing(3),
        boxShadow: '-1px 1px 2px 0 rgba(60,56,56,0.1)',
        border: theme.constants.tableBorder,
        borderRadius: 0,
      },
      [theme.breakpoints.up('sm')]: {
        border: theme.constants.tableBorder,
      },
      paddingTop: theme.spacing(0.5),
    },
    iconPaper: {
      [theme.breakpoints.up('sm')]: {
        '&::before': {
          left: 0,
          width: 24,
          height: 24,
          bottom: 0,
          content: '""',
          position: 'absolute',
          borderRadius: '12px 18px 0',
          borderBottom: theme.constants.tableBorder,
        },
        left: 0.5,
        width: 36,
        borderLeft: theme.constants.tableBorder,
        borderTop: theme.constants.tableBorder,
        borderRadius: '16px 0 0 16px',
      },
      [theme.breakpoints.down('sm')]: {
        zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above', 2),
        width: 32,
        left: 8,
        top: -16,
        borderRadius: '50%',
        border: theme.constants.tableBorder,
      },
      color: theme.colors.title,
      height: 32,
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      boxShadow: '-1px 0px 1px 0px rgba(0,0,0,0.14)',
    },
    icon: {
      marginLeft: 3,
    },
    paper: {},
    actionNeededContainer: {
      [theme.breakpoints.up('sm')]: {
        border: `1px solid ${theme.colors.important}`,
      },
    },
    actionNeededIconContainer: {
      [theme.breakpoints.up('sm')]: {
        '&::before': {
          borderBottom: `1px solid ${theme.colors.important}`,
        },
        borderLeft: `1px solid ${theme.colors.important}`,
        borderTop: `1px solid ${theme.colors.important}`,
      },
    },
    actionNeededText: {
      color: theme.colors.important,
    },
  }),
  { name: 'TimelineCard' },
)

export interface TimelineCardProps {
  actionNeeded?: boolean
  buttons?: ControlButtonGroupProps['buttons']
  children: React.ReactNode
  date?: React.ReactNode
  doubleMargin?: boolean
  icon?: React.JSXElementConstructor<any>
  id?: string
  noHours?: boolean
  noMarginBottom?: boolean
  onCheck?: () => void
  onDelete?: () => void
  onEdit?: () => void
  originBusinessId?: string | Nil
  title?: React.ReactNode
  titlePinColor?: string | Nil
}

const TimelineCard = ({
  icon: IconComponent,
  date,
  noHours = false,
  title,
  actionNeeded,
  doubleMargin,
  noMarginBottom,
  children,
  onCheck,
  onEdit,
  onDelete,
  buttons,
  id,
  originBusinessId,
  titlePinColor,
}: TimelineCardProps) => {
  const classes = useStyles()

  const timezone = useCurrentBusinessTimezone()

  const isValidElement = React.isValidElement(date)
  const momentDate = moment.tz(date, timezone)
  const subheader = isValidElement ? null : momentDate.fromNow()

  const dateTitle = isValidElement
    ? date
    : momentDate.format(
        noHours
          ? DateFormat.FULL_DATE_COMMA
          : DateFormat.FULL_DATE_COMMA_PIPE_TIME,
      )

  return (
    <Grid container className={classes.root} id={id} wrap="nowrap">
      {IconComponent && (
        <Hidden smDown>
          <Grid item className={classes.iconContainer}>
            <Paper
              className={classNames(classes.paper, classes.iconPaper, {
                [classes.actionNeededIconContainer]: actionNeeded,
              })}
            >
              <IconComponent
                className={classNames(classes.icon, {
                  [classes.actionNeededText]: actionNeeded,
                })}
              />
            </Paper>
          </Grid>
        </Hidden>
      )}
      {IconComponent && (
        <Hidden smUp>
          <Paper
            className={classNames(classes.paper, classes.iconPaper, {
              [classes.actionNeededIconContainer]: actionNeeded,
            })}
          >
            <IconComponent
              className={classNames(classes.icon, {
                [classes.actionNeededText]: actionNeeded,
              })}
            />
          </Paper>
        </Hidden>
      )}
      <Grid item xs className={classes.container}>
        <Paper
          className={classNames(classes.paper, classes.mainPaper, {
            [classes.actionNeededContainer]: actionNeeded,
          })}
          onScroll={(event) => {
            event.preventDefault()
            event.stopPropagation()
          }}
        >
          <CardHeader
            classes={{
              root: classes.headerRoot,
              title: classes.headerTitle,
              subheader: classNames(classes.subheader, {
                [classes.actionNeededText]: actionNeeded,
              }),
            }}
            subheader={subheader}
            subheaderTypographyProps={{
              display: 'inline',
            }}
            title={dateTitle}
            titleTypographyProps={{
              display: 'inline',
            }}
          />
          <Grid container item className={classes.titleContainer}>
            <Grid
              container
              item
              xs
              alignItems="center"
              mb={noMarginBottom ? 0 : doubleMargin ? 2 : 1.5}
              mt={1.5}
              wrap="nowrap"
            >
              <ColorPinIcon color={titlePinColor} mr={1} variant="big" />
              {title && (
                <Text
                  className={classNames(classes.title, {
                    [classes.actionNeededText]: actionNeeded,
                  })}
                  variant="h1"
                >
                  {title}
                </Text>
              )}
              {originBusinessId && (
                <BusinessShareIcon businessIds={[originBusinessId]} />
              )}
            </Grid>
            {(onEdit || onDelete || onCheck || !isEmpty(buttons)) && (
              <Grid item>
                <ControlButtonGroup
                  buttons={[
                    onDelete && {
                      name: ControlButtonGroupName.DELETE,
                      onClick: onDelete,
                    },
                    onCheck && {
                      name: ControlButtonGroupName.CHECK,
                      onClick: onCheck,
                    },
                    onEdit && {
                      name: ControlButtonGroupName.EDIT,
                      onClick: onEdit,
                    },
                    ...(buttons || []),
                  ]}
                />
              </Grid>
            )}
          </Grid>
          {children}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default TimelineCard
