import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PuiTheme } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tooltip: {
      backgroundColor: theme.colors.secondaryText,
      padding: theme.spacing(0, 0.5),
      fontSize: '1.4rem',
    },
  }),
  { name: 'QuestionTabTooltip' },
)

export interface QuestionTabTooltipProps {
  label: string
}

const QuestionTabTooltip = ({ label }: QuestionTabTooltipProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Tooltips')

  return (
    <Tooltip
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -8],
            },
          },
        ],
      }}
      classes={{ tooltip: classes.tooltip }}
      placement="top"
      title={t('Tooltips:ANSWERS_QUESTIONS')}
    >
      <span>{label}</span>
    </Tooltip>
  )
}

export default QuestionTabTooltip
