import React from 'react'
import { Grid, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme } from '@pbt/pbt-ui-components'

import BenchmarkingLoginBackground from '~/components/common/images/BenchmarkingLoginBackground.svg'
import LoginBackground from '~/components/common/images/LoginBackground.svg'
import RhapsodyLogo from '~/components/common/RhapsodyLogo'
import { isBenchmarking } from '~/utils'

import BenchmarkingPlusLogo from '../common/BenchmarkingPlusLogo'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      [theme.breakpoints.down('md')]: {
        paddingTop: 23,
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingLeft: 10,
        paddingRight: 10,
        marginLeft: 16,
        marginRight: 16,
        height: 'auto',
        minHeight: 600,
        padding: 0,
      },
      [theme.breakpoints.only('md')]: {
        paddingTop: 23,
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingLeft: 25,
        paddingRight: 25,
      },
      paddingTop: 32,
      padding: 48,
      flex: 1,
      height: 545,
    },
    noPadding: {
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
    formContainer: {
      minHeight: '100vh',
      backgroundImage: `url(${LoginBackground})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom center',
      backgroundSize: '100%, auto',
      [theme.breakpoints.down('lg')]: {
        backgroundSize: 'auto auto',
      },
      [theme.breakpoints.down('md')]: {
        padding: '21px 0 0 0',
      },
      padding: 0,
    },
    benchmarkingBackground: {
      backgroundImage: `url(${BenchmarkingLoginBackground})`,
    },
    mainContent: {
      width: '100%',
      maxWidth: 1000,
      height: '100%',
      [theme.breakpoints.down('md')]: {
        height: 'auto',
        width: 'auto',
      },
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
      },
    },
    benchmarkingPlusLogo: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('lg')]: {
        marginBottom: 11,
        marginLeft: 0,
        marginTop: 10,
      },
      marginBottom: 52,
      marginLeft: 0,
      marginTop: 35,
    },
    rhapsodyLogo: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('lg')]: {
        marginBottom: 11,
        marginLeft: 0,
        marginTop: 0,
      },
      marginBottom: 52,
      marginLeft: -80,
      marginTop: 35,
    },
  }),
  { name: 'RegistrationWrapper' },
)

interface RegistrationWrapperProps {
  component: React.JSXElementConstructor<any>
  noPadding?: boolean
}

const RegistrationWrapper = ({
  component: Component,
  noPadding,
}: RegistrationWrapperProps) => {
  const classes = useStyles()
  const paperClassnames = [classes.paper]
  if (noPadding) {
    paperClassnames.push(classes.noPadding)
  }

  return (
    <Grid
      container
      className={classNames(
        classes.formContainer,
        isBenchmarking && classes.benchmarkingBackground,
      )}
      justifyContent="center"
    >
      <Grid item className={classes.mainContent}>
        {isBenchmarking ? (
          <BenchmarkingPlusLogo className={classes.benchmarkingPlusLogo} />
        ) : (
          <RhapsodyLogo className={classes.rhapsodyLogo} />
        )}
        <Paper className={classNames(paperClassnames)}>
          <Component />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default RegistrationWrapper
