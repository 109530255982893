import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AddButton,
  ClassesType,
  ErrorTooltip,
  Nil,
  PermissionArea,
  PuiTextField,
  PuiTheme,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import BooleanRadioGroup from '~/components/common/inputs/BooleanRadioGroup'
import TimeUnitsSelect from '~/components/common/TimeUnitsSelect'
import DialogNames from '~/constants/DialogNames'
import { createReminder, getIsCreating } from '~/store/duck/reminders'
import { getCRUDByArea, getCurrentBusiness } from '~/store/reducers/auth'
import { getReminderProtocolTimeUnits } from '~/store/reducers/constants'
import { getSoapId } from '~/store/reducers/soap'
import { Order, ReminderProtocol, UnsavedReminder } from '~/types'
import { removeByIdList } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import ReminderSettingsList from '../ReminderSettingsList'
import { dateOffsetToDate } from '../reminderUtils'
import AddReminderDialogActions from './AddReminderDialogActions'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    labelOnTop: {
      paddingLeft: '15px !important',
      top: '-7px !important',
    },
    dueInput: {
      width: 48,
    },
    expiresInput: {
      width: 48,
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'StepAddReminderManually' },
)

export interface StepAddReminderManuallyProps {
  classes?: ClassesType<typeof useStyles>
  onBack?: () => void
  onClose?: () => void
  patientId: string | Nil
  protocol: ReminderProtocol | Nil
}

const StepAddReminderManually = ({
  protocol,
  classes: classesProp,
  onBack,
  onClose,
  patientId,
}: StepAddReminderManuallyProps) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Reminders', 'Tooltips'])

  const isCreating = useSelector(getIsCreating)
  const business = useSelector(getCurrentBusiness)
  const TimeUnits = useSelector(getReminderProtocolTimeUnits)
  const soapId = useSelector(getSoapId)
  const { create: remindersCreatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.REMINDERS),
  )

  const [dues, setDues] = useState(protocol?.due || [])
  const [resolvers, setResolvers] = useState(protocol?.resolvesWith || [])

  const [touched, setTouched] = useState(false)
  const [showDuesError, setShowDuesError] = useState(false)
  const [showResolversError, setShowResolversError] = useState(false)

  const [openAddReminderProtocolItems] = useDialog(
    DialogNames.ADD_REMINDER_PROTOCOL_ITEMS,
  )

  const closeAfterCreate = useCloseAfterCreation(onClose, getIsCreating)

  const yearId = Utils.findConstantIdByName('Year', TimeUnits)

  const {
    fields: {
      name,
      dueAmount,
      dueUnit,
      expirationDateOffsetAmount,
      expirationDateOffsetUnit,
      resolveSameAsDue,
      notes,
    },
    validate,
  } = useFields(
    [
      {
        name: 'name',
        label: t('Common:REMINDER_NAME'),
        validators: ['required'],
        initialValue: protocol?.reminderName,
      },
      {
        name: 'dueAmount',
        label: t('Reminders:LABEL.DUE_AMOUNT'),
        validators: ['required'],
        initialValue: protocol?.dueDateOffset?.amount || 1,
      },
      {
        name: 'dueUnit',
        label: t('Reminders:LABEL.DUE_UNIT'),
        type: 'select',
        validators: ['required'],
        initialValue:
          protocol?.dueDateOffset?.unit ||
          business?.defaultReminderExpirationOffset?.unit ||
          yearId,
      },
      {
        name: 'expirationDateOffsetAmount',
        label: t('Reminders:LABEL.EXPIRATION_DATE_OFFSET_AMOUNT'),
        validators: ['required'],
        initialValue:
          protocol?.expirationDateOffset?.amount ||
          business?.defaultReminderExpirationOffset?.amount,
      },
      {
        name: 'expirationDateOffsetUnit',
        label: t('Reminders:LABEL.EXPIRATION_DATE_OFFSET_UNIT'),
        type: 'select',
        validators: ['required'],
        initialValue: protocol?.expirationDateOffset?.unit || yearId,
      },
      {
        name: 'resolveSameAsDue',
        initialValue: true,
      },
      {
        name: 'notes',
        label: t('Common:NOTES'),
        initialValue: '',
      },
    ],
    false,
  )

  const getReminder = () => {
    const dueDatetime = dateOffsetToDate(
      dueAmount.value,
      Utils.getConstantName(dueUnit.value, TimeUnits),
    )

    const expireDatetime = dateOffsetToDate(
      expirationDateOffsetAmount.value,
      Utils.getConstantName(expirationDateOffsetUnit.value, TimeUnits),
      dueDatetime,
    )

    const reminder: UnsavedReminder = {
      patientId,
      name: name.value,
      dueDatetime: dueDatetime.toISOString(),
      expireDatetime: expireDatetime.toISOString(),
      due: dues,
      resolvesWith: resolveSameAsDue.value ? dues : resolvers,
      notes: notes.value,
    }

    if (protocol?.id) {
      reminder.protocolId = protocol.id
    }

    return reminder
  }

  const validateDues = () => {
    const isValid = dues.length > 0
    setShowDuesError(!isValid)
    return isValid
  }

  const validateResolvers = () => {
    const isValid = resolveSameAsDue.value
      ? dues.length > 0
      : resolvers.length > 0
    setShowResolversError(!isValid)
    return isValid
  }

  const handleSave = () => {
    setTouched(true)
    const isValid = validate()
    if (isValid && validateDues() && validateResolvers()) {
      dispatch(createReminder(getReminder(), soapId))
      closeAfterCreate()
    }
  }

  useEffect(() => {
    if (resolveSameAsDue.value === false) {
      setResolvers(dues)
    }
  }, [resolveSameAsDue.value])

  useEffect(() => {
    if (touched) {
      validateDues()
    }
  }, [dues])

  useEffect(() => {
    if (touched) {
      validateResolvers()
    }
  }, [resolvers])

  const handleAddDue = () => {
    openAddReminderProtocolItems({
      onSave: (newDues: Partial<Order>[]) => {
        setDues(R.uniqBy(R.prop('id'), [...dues, ...(newDues as Order[])]))
      },
      title: t('Common:ADD_WHAT_IS_DUE_OR_EXPECTED'),
    })
  }

  const handleAddResolver = () => {
    openAddReminderProtocolItems({
      onSave: (newResolvers: Partial<Order>[]) => {
        setResolvers(
          R.uniqBy(R.prop('id'), [...resolvers, ...(newResolvers as Order[])]),
        )
      },
      title: t('Common:ADD_WHAT_RESOLVES_IT'),
    })
  }

  return (
    <Grid container direction="column" pb={2} px={2}>
      <Grid container item direction="column">
        <PuiTextField field={name} label={`${name.label}*`} />
        <Grid container item alignItems="center">
          <Grid item mr={0.75} width={72}>
            <Text variant="body">
              {t('Reminders:LABEL.DUE_OR_EXPECTED_PERIOD_AMOUNT')}*
            </Text>
          </Grid>
          <Grid item mr={2}>
            <PuiTextField className={classes.dueInput} field={dueAmount} />
          </Grid>
          <Grid item mr={2}>
            <TimeUnitsSelect
              amount={Number(dueAmount.value)}
              field={dueUnit}
              items={TimeUnits}
              renderEmpty={false}
            />
          </Grid>
        </Grid>

        <Grid container item alignItems="center">
          <Grid item mr={0.75} width={72}>
            <Text variant="body">{t('Common:EXPIRES')}*</Text>
          </Grid>
          <Grid item>
            <PuiTextField
              className={classes.expiresInput}
              field={expirationDateOffsetAmount}
            />
          </Grid>
          <Grid item mr={2}>
            <TimeUnitsSelect
              amount={Number(expirationDateOffsetAmount.value)}
              field={expirationDateOffsetUnit}
              items={TimeUnits}
              renderEmpty={false}
            />
          </Grid>
          <Grid item>
            <TextWithTooltip
              tooltipText={t('Tooltips:REMINDER_STATUS_WILL_BE_CHANGED')}
              variant="body"
            />
          </Grid>
        </Grid>

        <Grid item mb={0.5} mt={3}>
          <TextWithTooltip
            strong
            tooltipText={t('Tooltips:REMINDER_CHOOSE_ITEMS')}
            variant="body2"
          >
            {t('Common:WHAT_IS_DUE_OR_EXPECTED')}*
          </TextWithTooltip>
        </Grid>

        <ReminderSettingsList
          items={dues}
          onDelete={(id) => setDues(removeByIdList([id], dues))}
        />

        <ErrorTooltip
          message={t('Tooltips:AT_LEAST_ONE_ITEM_SHOULD_BE_ADDED')}
          open={touched && showDuesError}
        >
          <Grid item mt={1}>
            <AddButton
              inline
              addText={t('Common:ADD_WHAT_IS_DUE_OR_EXPECTED')}
              onAdd={handleAddDue}
            />
          </Grid>
        </ErrorTooltip>

        <Grid item mb={0.5} mt={3}>
          <TextWithTooltip
            strong
            tooltipText={t('Tooltips:REMINDER_ORDERING_ITEMS')}
            variant="body2"
          >
            {t('Common:WHAT_RESOLVES_IT')}*
          </TextWithTooltip>
        </Grid>

        <BooleanRadioGroup
          field={resolveSameAsDue}
          noLabel={t('Common:CUSTOM')}
          yesLabel={t(
            'Reminders:STEP_ADD_REMINDER_INIT.SAME_AS_DUE_OR_EXPECTED',
          )}
        />

        {!resolveSameAsDue.value && (
          <>
            <ReminderSettingsList
              items={resolvers}
              onDelete={(id) => setResolvers(removeByIdList([id], resolvers))}
            />

            <ErrorTooltip
              message={t('Tooltips:AT_LEAST_ONE_ITEM_SHOULD_BE_ADDED')}
              open={touched && showResolversError}
            >
              <Grid mt={1}>
                <AddButton
                  inline
                  addText={t('Common:ADD_WHAT_RESOLVES_IT')}
                  onAdd={handleAddResolver}
                />
              </Grid>
            </ErrorTooltip>
          </>
        )}
        <Grid item my={2}>
          <PuiTextField
            multiline
            InputLabelProps={{
              classes: {
                focused: classes.labelOnTop,
              },
            }}
            field={notes}
            label={notes.label}
            margin="none"
            minRows={4}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid container item justifyContent="space-between">
        <AddReminderDialogActions
          isLoading={isCreating}
          isProceedDisabled={!remindersCreatePermissions}
          proceedLabel={t('Common:SAVE_ACTION')}
          onBack={onBack}
          onProceed={remindersCreatePermissions ? handleSave : () => {}}
        />
      </Grid>
    </Grid>
  )
}

export default StepAddReminderManually
