import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { prop } from 'ramda'
import {
  ControlButtonGroup,
  ControlButtonGroupName,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import BusinessTypeIconGroup from '~/components/common/BusinessTypeIconGroup'
import { getBusiness } from '~/store/reducers/businesses'
import { getBusinessName } from '~/utils/businessUtils'

import PracticeStatusLabel from '../../PracticeStatusLabel'

const EMPTY_CELL = <Text>-</Text>

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    dataContainer: {
      maxHeight: 410,
    },
    tableTop: {
      marginTop: theme.spacing(1),
    },
    tableHead: {
      border: theme.constants.tableBorder,
    },
    tableBody: {
      border: theme.constants.tableBorder,
    },
    cellHead: {
      padding: theme.spacing(1, 2),
    },
    cell: {
      padding: theme.spacing(0.5, 2),
    },
    commonCell: {
      '&:first-child': {
        borderRight: theme.constants.tableBorder,
        minWidth: 330,
        width: 330,
      },
      '&:nth-child(2)': {
        minWidth: 150,
        width: 150,
      },
      '&:nth-child(3)': {
        minWidth: 170,
        width: 170,
      },
    },
    row: {},
    iconButton: {
      margin: theme.spacing(0.5),
      padding: 0,
    },
    practiceStatusLabel: {
      display: 'inline',
    },
  }),
  { name: 'PracticeDetailsChildrenTable' },
)

interface PracticeDetailsChildrenTableProps {
  editable: boolean
  onChangeCurrentBusiness: (id: string) => void
  onDelete: (id: string) => void
  onNavigateToBusiness: (id: string) => void
  practices: string[]
}

const PracticeDetailsChildrenTable = ({
  practices,
  ...rest
}: PracticeDetailsChildrenTableProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common'])

  return (
    <>
      <Grid container overflow="auto">
        <Table className={classes.tableTop}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell
                className={classNames(classes.cellHead, classes.commonCell)}
              >
                <Text strong variant="lowAccent2">
                  {t('Common:PRACTICE')}
                </Text>
              </TableCell>
              <TableCell
                className={classNames(classes.cellHead, classes.commonCell)}
              >
                <Text strong variant="lowAccent2">
                  {t('Common:STATUS')}
                </Text>
              </TableCell>
              <TableCell
                className={classNames(classes.cellHead, classes.commonCell)}
              >
                <Text strong variant="lowAccent2">
                  {t('Common:TYPE_ONE_OR_OTHER')}
                </Text>
              </TableCell>
              <TableCell
                className={classNames(classes.cellHead, classes.commonCell)}
              >
                <Text strong variant="lowAccent2">
                  {t('Common:TAG_ONE_OR_OTHER')}
                </Text>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Grid>
      <Grid container className={classes.dataContainer} overflow="auto">
        <Table>
          {practices && (
            <TableBody className={classes.tableBody}>
              {practices.map((businessId: string) => (
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                <PracticeDetailsChildrenTableRow
                  businessId={businessId}
                  key={businessId}
                  {...rest}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </Grid>
    </>
  )
}

interface PracticeDetailsChildrenTableRowProps {
  businessId: string
  editable: boolean
  onChangeCurrentBusiness: (id: string) => void
  onDelete: (id: string) => void
  onNavigateToBusiness: (id: string) => void
}

// eslint-disable-next-line react/no-multi-comp
const PracticeDetailsChildrenTableRow = ({
  businessId,
  editable = true,
  onDelete,
  onNavigateToBusiness,
  onChangeCurrentBusiness,
}: PracticeDetailsChildrenTableRowProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Admin'])

  const business = useSelector(getBusiness(businessId))

  const buttons = [
    {
      name: ControlButtonGroupName.DELETE,
      disabled: !editable,
      tooltip: t('Admin:PRACTICE.ACTION.REMOVE_FROM_GROUP'),
      onClick: () => onDelete(businessId),
    },
    {
      name: ControlButtonGroupName.PREVIEW,
      tooltip: t('Admin:PRACTICE.ACTION.VIEW_PRACTICE_SETTINGS'),
      onClick: () => onNavigateToBusiness(businessId),
    },
    {
      name: ControlButtonGroupName.NAVIGATE,
      tooltip: t('Admin:PRACTICE.ACTION.GO_TO_PRACTICE'),
      onClick: () => onChangeCurrentBusiness(businessId),
    },
  ]

  return (
    <TableRow>
      <TableCell className={classNames(classes.cell, classes.commonCell)}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid item xs={8}>
            <Text strong>{getBusinessName(business)}</Text>
          </Grid>
          <Grid
            container
            item
            alignItems="center"
            justifyContent="flex-end"
            wrap="nowrap"
            xs={4}
          >
            <ControlButtonGroup
              buttons={buttons}
              classes={{ iconButton: classes.iconButton }}
            />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell className={classNames(classes.cell, classes.commonCell)}>
        <PracticeStatusLabel
          className={classes.practiceStatusLabel}
          statusId={business?.statusId}
          variant="big"
        />
      </TableCell>
      <TableCell className={classNames(classes.cell, classes.commonCell)}>
        {business?.practiceTypeIds?.length ? (
          <BusinessTypeIconGroup showTooltip ids={business?.practiceTypeIds} />
        ) : (
          EMPTY_CELL
        )}
      </TableCell>
      <TableCell className={classNames(classes.cell, classes.commonCell)}>
        {business?.tags?.length ? (
          <Text>{business.tags.map(prop('name')).join(', ')}</Text>
        ) : (
          EMPTY_CELL
        )}
      </TableCell>
    </TableRow>
  )
}

export default React.memo(PracticeDetailsChildrenTable)
