import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { map, omit } from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  PuiDialog,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import {
  editVaccineHistory,
  saveVaccineHistory,
} from '~/store/actions/medicalHistory'
import { getMedicalHistoryIsSending } from '~/store/reducers/medicalHistory'
import { VaccineHistoryItem } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import VaccineHistoryExpandedDetails, {
  VaccineHistoryExpandedDetailsHandle,
} from './VaccineHistoryExpandedDetails'
import VaccineListWithFilters from './VaccineListWithFilters'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 976,
      maxWidth: 976,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    dialogContentRoot: {
      padding: theme.spacing(2),
    },
    button: {
      width: 150,
    },
  }),
  { name: 'VaccineHistoryDialog' },
)

export interface VaccineHistoryDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  patientId: string | Nil
  vaccine?: VaccineHistoryItem
}

const VaccineHistoryDialog = ({
  vaccine,
  patientId,
  clientId,
  open,
  onClose,
}: VaccineHistoryDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isSending = useSelector(getMedicalHistoryIsSending)
  const setCloseAfterCreationOn = useCloseAfterCreation(
    onClose,
    getMedicalHistoryIsSending,
  )
  const { t } = useTranslation(['Common', 'Dialogs'])

  const detailsRef = useRef<VaccineHistoryExpandedDetailsHandle>(null)

  const onProceed = (items: VaccineHistoryItem[]) => {
    const processedItems = map(omit(['id']), items)

    setCloseAfterCreationOn()
    if (patientId) {
      dispatch(saveVaccineHistory(processedItems, patientId, clientId))
    }
  }

  const save = () => {
    if (detailsRef.current?.validate()) {
      setCloseAfterCreationOn()
      if (patientId) {
        dispatch(editVaccineHistory([detailsRef.current.get()], patientId))
      }
    }
  }

  return (
    <PuiDialog
      actions={
        vaccine && (
          <ButtonWithLoader
            className={classes.button}
            disabled={isSending}
            loading={isSending}
            onClick={save}
          >
            {t('Common:SAVE_ACTION')}
          </ButtonWithLoader>
        )
      }
      aria-labelledby="vaccine-history-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={
        vaccine
          ? t('Dialogs:VACCINE_HISTORY_DIALOG.TITLE_EDIT')
          : t('Dialogs:VACCINE_HISTORY_DIALOG.TITLE_ADD')
      }
      onClose={onClose}
    >
      {vaccine ? (
        <VaccineHistoryExpandedDetails
          edit
          ref={detailsRef}
          vaccine={vaccine}
        />
      ) : (
        <VaccineListWithFilters patientId={patientId} onProceed={onProceed} />
      )}
    </PuiDialog>
  )
}

export default VaccineHistoryDialog
