import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  Nil,
  PuiTheme,
  SignatureOption,
  SignatureView,
  useFields,
} from '@pbt/pbt-ui-components'

import NotesTemplateInput from '~/components/dashboard/template-inputs/NotesTemplateInput'
import { getAppointmentId } from '~/store/reducers/soap'
import { getUserName } from '~/store/reducers/users'
import { DataHandle, Order, OrderVaccinationDetails } from '~/types'
import { getIsRabiesVaccine } from '~/utils/orderUtils'

import AdditionalVaccineDetails, {
  AdditionalVaccineDetailsHandle,
} from './AdditionalVaccineDetails'
import MainVaccineDetails, {
  MainVaccineDetailsHandle,
} from './MainVaccineDetails'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    additionalVaccineDetails: {
      marginTop: theme.spacing(1),
    },
    notesTemplateInput: {
      marginTop: theme.spacing(2),
    },
    signature: {
      marginTop: theme.spacing(1.5),
    },
  }),
  { name: 'VaccineDetails' },
)

export interface VaccineDetailsProps {
  clientId: string | Nil
  isLoading?: boolean
  onSelectSignature?: (signer: SignatureOption) => void | undefined
  onSign?: () => void
  patientId: string | Nil
  showAdditionalDetails?: boolean
  signatureOptions: SignatureOption[]
  vaccine: Order
}

export interface VaccineDetailsHandle
  extends DataHandle<{
    notes?: string | Nil
    vaccinationDetails: OrderVaccinationDetails
  }> {}

const VaccineDetails = forwardRef(function VaccineDetails(
  {
    showAdditionalDetails = true,
    clientId,
    patientId,
    vaccine,
    onSign,
    isLoading,
    onSelectSignature,
    signatureOptions,
  }: VaccineDetailsProps,
  ref: ForwardedRef<VaccineDetailsHandle>,
) {
  const classes = useStyles()

  const { t } = useTranslation('Common')

  const mainVaccineDetailsRef = useRef<MainVaccineDetailsHandle>(null)
  const additionalVaccineDetailsRef =
    useRef<AdditionalVaccineDetailsHandle>(null)

  const appointmentId = useSelector(getAppointmentId)

  const isRabies = getIsRabiesVaccine(vaccine)

  const {
    fields: { notes, signature, signerId },
    validate,
  } = useFields([
    { name: 'notes', initialValue: vaccine.notes || '' },
    {
      name: 'signature',
      initialValue: vaccine.vaccinationDetails?.signature || '',
    },
    {
      name: 'signerId',
      initialValue: vaccine.vaccinationDetails?.signerId || '',
    },
  ])

  const signerName = useSelector(getUserName(signerId.value))

  useEffect(() => {
    if (
      vaccine.vaccinationDetails &&
      signature.value !== vaccine.vaccinationDetails.signature
    ) {
      signature.setValue(vaccine.vaccinationDetails.signature)
      signerId.setValue(vaccine.vaccinationDetails.signerId)
    }
  }, [vaccine.vaccinationDetails])

  useImperativeHandle(ref, () => ({
    validate: () => {
      const isValid = validate()
      const isMainDetailsValid =
        mainVaccineDetailsRef.current?.validate() ?? true
      const isAdditionalDetailsValid =
        additionalVaccineDetailsRef.current?.validate() ?? true

      return isValid && isMainDetailsValid && isAdditionalDetailsValid
    },
    get: () => ({
      notes: notes.value,
      vaccinationDetails: {
        ...vaccine.vaccinationDetails,
        ...(mainVaccineDetailsRef.current?.get() || {}),
        ...(additionalVaccineDetailsRef.current?.get() || {}),
        signature: signature.value,
        signerId: signerId.value,
      },
    }),
  }))

  return (
    <>
      <MainVaccineDetails ref={mainVaccineDetailsRef} vaccine={vaccine} />
      {showAdditionalDetails && (
        <AdditionalVaccineDetails
          className={classes.additionalVaccineDetails}
          key={vaccine.vaccinationDetails?.dueDate}
          patientId={patientId}
          ref={additionalVaccineDetailsRef}
          vaccine={vaccine}
        />
      )}
      <NotesTemplateInput
        hidePanel
        className={classes.notesTemplateInput}
        clientId={clientId}
        eventId={appointmentId}
        field={notes}
        patientId={patientId}
        placeholder={t('Common:NOTES')}
      />
      {isRabies && showAdditionalDetails && (
        <div className={classes.signature}>
          <SignatureView
            disabled={isLoading}
            signatureOptions={signatureOptions}
            signatureString={signature.value}
            signerName={signerName}
            onEdit={onSign}
            onSelect={onSelectSignature}
          />
        </div>
      )}
    </>
  )
})

export default VaccineDetails
