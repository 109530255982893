import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { moment, PuiTheme } from '@pbt/pbt-ui-components'

import {
  getWhiteboardIsLoading,
  getWhiteboardTreatmentsSchedules,
} from '~/store/reducers/whiteboard'
import { dateToIntervals } from '~/utils/time'
import useTimetableDate from '~/utils/useTimetableDate'

import Timetable from '../../Timetable'
import WhiteboardTreatmentsViewRow from './WhiteboardTreatmentsViewRow'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    row: {
      marginTop: theme.spacing(1),
    },
    content: {
      padding: theme.spacing(0, 1),
    },
  }),
  { name: 'WhiteboardTreatmentsView' },
)

export interface WhiteboardTreatmentsViewProps {
  HeaderComponent: React.JSXElementConstructor<any>
}

const WhiteboardTreatmentsView = ({
  HeaderComponent,
}: WhiteboardTreatmentsViewProps) => {
  const classes = useStyles()

  const schedules = useSelector(getWhiteboardTreatmentsSchedules)
  const isLoading = useSelector(getWhiteboardIsLoading)

  const { selectedDate } = useTimetableDate()

  const [scrollOffset, setScrollOffset] = useState(0)

  const startDate = moment(selectedDate).startOf('day')
  const endDate = moment(selectedDate).endOf('day')
  const timeColumns = useMemo(
    () => dateToIntervals(startDate, endDate),
    [selectedDate],
  )

  const rowRefs = Array.from(
    schedules,
    (schedule) =>
      [
        React.createRef<HTMLDivElement>(), // mainScrollRef
        Array.from({ length: (schedule.tasks || []).length }, () =>
          React.createRef<HTMLDivElement>(),
        ), // tasksScrollRefs
        React.createRef<HTMLDivElement>(), // doneTaskHeaderScrollRef
        React.createRef<HTMLDivElement>(), // addButtonScrollRef
      ] as [
        React.RefObject<HTMLDivElement>,
        React.RefObject<HTMLDivElement>[],
        React.RefObject<HTMLDivElement>,
        React.RefObject<HTMLDivElement>,
      ],
  )

  return (
    <Timetable
      row
      HeaderComponent={HeaderComponent}
      HeaderProps={{
        onScrollOffsetChange: setScrollOffset,
      }}
      additionalRefs={R.flatten(rowRefs)}
      classes={{
        content: classes.content,
      }}
      isLoading={isLoading}
    >
      {schedules.map((schedule, index) => (
        <WhiteboardTreatmentsViewRow
          className={classes.row}
          key={schedule.appointment}
          schedule={schedule}
          scrollOffset={scrollOffset}
          scrollRefs={rowRefs[index]}
          timeColumns={timeColumns}
        />
      ))}
    </Timetable>
  )
}

export default WhiteboardTreatmentsView
