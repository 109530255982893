import React from 'react'
import { ChevronRight } from '@mui/icons-material'
import { Button, MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Field, NestedSelectEntity, PuiTheme } from '@pbt/pbt-ui-components'

import MenuDropdown from '~/components/common/inputs/MenuDropdown'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    menuButton: {
      justifyContent: 'space-between',
      color: theme.colors.secondaryText,
      fontWeight: 'initial',
      textTransform: 'none',
      width: '100%',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    chevronIcon: {
      color: theme.colors.secondaryText,
      fontSize: '2.6rem !important',
    },
    popperMenu: {
      zIndex: theme.zIndex.modal,
    },
    menuItem: {
      paddingLeft: theme.spacing(1),
    },
  }),
  { name: 'NestedMenuItem' },
)

export interface NestedMenuItemProps {
  children: JSX.Element
  disabled?: boolean
  field?: Field
  id: string
  name: string
  onMouseEnter: (id: string) => void
  onSelected: () => void
  shouldClose: (id: string) => boolean
  subitems?: NestedSelectEntity[]
}

function NestedMenuItem({
  disabled,
  field,
  id,
  children,
  name,
  onMouseEnter,
  onSelected,
  shouldClose,
  subitems,
  ...props
}: NestedMenuItemProps) {
  const classes = useStyles()

  if (subitems?.length) {
    return (
      <div
        onMouseEnter={() => {
          onMouseEnter(id)
        }}
      >
        <MenuDropdown
          openOnHover
          Button={
            <Button
              className={classes.menuButton}
              endIcon={<ChevronRight className={classes.chevronIcon} />}
            >
              {children}
            </Button>
          }
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          classes={{
            popperMenu: classes.popperMenu,
          }}
          forceClosed={shouldClose(id)}
          items={subitems}
          menuProps={{
            placement: 'right-start',
          }}
          onSelected={(value) => {
            field?.setValue(value)
            onSelected()
          }}
        />
      </div>
    )
  }

  return (
    <div
      onMouseEnter={() => {
        onMouseEnter(id)
      }}
    >
      <MenuItem
        className={classes.menuItem}
        disabled={disabled}
        key={id}
        onSelect={onSelected}
        {...props}
      >
        {children}
      </MenuItem>
    </div>
  )
}

export default NestedMenuItem
