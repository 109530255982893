import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader, PuiTheme } from '@pbt/pbt-ui-components'

import { getMedicalHistoryIsSending } from '~/store/reducers/medicalHistory'
import { DiagnoseHistoryItem } from '~/types'

import DiagnosisHistoryExpandedDetails, {
  DiagnosisHistoryDetailsHandle,
} from './DiagnosisHistoryExpandedDetails'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    button: {
      marginTop: theme.spacing(2),
      width: 150,
      height: 40,
    },
  }),
  { name: 'DiagnosisHistory' },
)

export interface DiagnosisHistoryProps {
  diagnosis: DiagnoseHistoryItem
  onProceed: (items: DiagnoseHistoryItem[]) => void
}

const DiagnosisHistory = ({ diagnosis, onProceed }: DiagnosisHistoryProps) => {
  const classes = useStyles()
  const isSending = useSelector(getMedicalHistoryIsSending)
  const { t } = useTranslation('Common')

  const detailsRef = useRef<DiagnosisHistoryDetailsHandle>(null)

  const [diagnosisCandidate, setDiagnosisCandidate] = useState(diagnosis)

  useEffect(() => {
    setDiagnosisCandidate(diagnosis)
  }, [diagnosis])

  const save = () => {
    if (detailsRef.current?.validate()) {
      onProceed([diagnosisCandidate])
    }
  }

  return (
    <>
      <DiagnosisHistoryExpandedDetails
        edit
        diagnosis={diagnosis}
        ref={detailsRef}
        onUpdateItem={setDiagnosisCandidate}
      />
      <ButtonWithLoader
        className={classes.button}
        disabled={isSending}
        loading={isSending}
        onClick={save}
      >
        {t('Common:SAVE_ACTION')}
      </ButtonWithLoader>
    </>
  )
}

export default DiagnosisHistory
