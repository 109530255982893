import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  LanguageUtils,
  Nil,
  PuiTheme,
  Utils,
} from '@pbt/pbt-ui-components'

import {
  ConversationTransport,
  GroupIdentifier,
  InvoiceEmailDto,
} from '~/api/graphql/generated/types'
import DownloadPdfContentButton from '~/components/common/buttons/DownloadPdfContentButton'
import EmailForm, { EmailFormHandle } from '~/components/common/email/EmailForm'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import { fetchClient } from '~/store/actions/clients'
import { getClientFinanceLoading } from '~/store/duck/clientFinanceData'
import { useCreatedConversationsInfo } from '~/store/hooks/conversations'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getConversationsIsCreating } from '~/store/reducers/conversations'
import { getUser } from '~/store/reducers/users'
import { useNewConversationValidationForm } from '~/utils/useNewConversationValidationForm'
import { useSaveEmailAndSendMessage } from '~/utils/useSaveEmailAndSendMessage'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    button: {
      width: 167,
      height: 40,
    },
    row: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'EmailPostedInvoice' },
)

export interface EmailPostedInvoiceProps {
  clientId: string | Nil
  downloadAction: () => void
  expandedGroups?: GroupIdentifier[] | Nil
  includeServiceFee?: boolean
  isEstimate: boolean
  isGenerating: boolean
  onOk?: () => void
  sendAction: (emailData: InvoiceEmailDto) => void
}

const EmailPostedInvoice = ({
  clientId,
  expandedGroups,
  onOk,
  sendAction,
  downloadAction,
  isGenerating,
  includeServiceFee,
  isEstimate,
}: EmailPostedInvoiceProps) => {
  const { t } = useTranslation(['Common', 'Finance'])

  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoading = useSelector(getClientFinanceLoading)
  const isEmailSending = useSelector(getConversationsIsCreating)
  const currentBusiness = useSelector(getCurrentBusiness)
  const client = useSelector(getUser(clientId))

  const clientName = Utils.getPersonString(client)

  const emailFormRef = useRef<EmailFormHandle>(null)

  const messageBody = isEstimate
    ? t('Finance:EMAIL_INVOICE.ESTIMATE_BODY_MESSAGE')
    : t('Finance:EMAIL_INVOICE.INVOICE_BODY_MESSAGE')

  const defaultSubject = isEstimate
    ? t('Finance:EMAIL_INVOICE.ESTIMATE_SUBJECT', {
        clientName,
      })
    : t('Finance:EMAIL_INVOICE.INVOICE_SUBJECT', {
        clientName,
      })

  const initialMessage = t('Finance:EMAIL_INVOICE.INITIAL_MESSAGE', {
    clientFirstName: client?.firstName,
    messageBody,
    sender: currentBusiness?.name,
  })

  const {
    fields: { subject, message, to },
    validate,
  } = useNewConversationValidationForm(
    { transport: ConversationTransport.Email, client },
    { initialSubject: defaultSubject, initialMessage },
  )

  useEffect(() => {
    if (!client?.email) {
      dispatch(fetchClient({ clientId }))
    }
  }, [clientId])

  const formRecipients = () => emailFormRef.current?.formRecipients() || []

  const getEmailData = (): InvoiceEmailDto => ({
    businessId: currentBusiness?.id,
    expandedGroups: expandedGroups || [],
    recipients: formRecipients(),
    subject: subject.value,
    message: message.value,
    includeServiceFee,
  })

  const displayConversationCreationResult = useCreatedConversationsInfo({
    getIsConversationCreating: getConversationsIsCreating,
    onConversationCreationSuccess: onOk,
    createdInfoDialogProps: {
      titleMessageName: LanguageUtils.capitalize(
        isEstimate
          ? t('Finance:EMAIL_INVOICE.CREATED_INFO_ESTIMATE_DIALOG_TITLE', {
              clientName,
            })
          : t('Finance:EMAIL_INVOICE.CREATED_INFO_INVOICE_DIALOG_TITLE', {
              clientName,
            }),
      ),
    },
  })

  const send = (emailData: InvoiceEmailDto) => {
    if (validate()) {
      displayConversationCreationResult()
      dispatch(sendAction(emailData))
    }
  }

  const sendEmail = () => send(getEmailData())

  const handleAskForSaveEmailAndSend = useSaveEmailAndSendMessage(
    sendEmail,
    client,
    formRecipients,
    to.value,
  )
  const handleSendClick = () => validate() && handleAskForSaveEmailAndSend()

  return (
    <Grid container className={classes.root} direction="column" p={3}>
      <EmailForm
        clientId={clientId}
        message={message}
        ref={emailFormRef}
        subject={subject}
        to={to}
      />
      <Grid item className={classes.row}>
        <RequiredFieldsNotice />
      </Grid>
      <Grid
        container
        item
        className={classes.row}
        justifyContent="space-between"
      >
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading || isEmailSending}
          loading={isLoading || isEmailSending}
          onClick={handleSendClick}
        >
          {t('Common:SEND_ACTION')}
        </ButtonWithLoader>
        <DownloadPdfContentButton
          loading={isGenerating}
          onClick={downloadAction}
        />
      </Grid>
    </Grid>
  )
}

export default EmailPostedInvoice
