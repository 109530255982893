import React, { useEffect } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ImageScalingUtils,
  NumberUtils,
  PatientAvatar,
  PermissionArea,
  PuiTextArea,
  PuiTheme,
  TextInteractive,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { Edit as EditIcon } from '@pbt/pbt-ui-components/src/icons'

import AlertLabel from '~/components/common/labels/AlertLabel'
import PatientInfoLabel from '~/components/common/labels/PatientInfoLabel'
import Link from '~/components/common/link/Link'
import { AlertColorLevel } from '~/constants/alertColors'
import DialogNames from '~/constants/DialogNames'
import { updateClient } from '~/store/actions/clients'
import { editPatient } from '~/store/actions/patients'
import { useAlertType, useCreatePatient } from '~/store/hooks/patient'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getClientIsLoading } from '~/store/reducers/clients'
import { getSpecies } from '~/store/reducers/constants'
import { getPatient, getPatientsIsLoading } from '~/store/reducers/patients'
import { getClientId, getPatientId } from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'
import useDialog from '~/utils/useDialog'

import useGetPreferredContactMethod from '../../clients/details/new-client-and-patient/useGetPreferredContactMethod'
import WellnessPlanMemberButton from '../../wellness-plans/WellnessPlanMemberButton'

const AVATAR_SIZE = 56

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    clientContainer: {
      padding: theme.spacing(0, 2, 1),
      borderRight: theme.constants.tableBorder,
    },
    clientNameText: {
      fontWeight: 500,
      fontSize: '1.6rem',
      marginBottom: theme.spacing(1),
      textDecoration: 'none',
    },
    editIcon: {
      color: theme.colors.editIconColor,
      padding: theme.spacing(0.5),
    },
    alertRoot: {
      borderRadius: '0 !important',
      minHeight: theme.spacing(4),
      margin: theme.spacing(0, 1, 1, 1),
      padding: theme.spacing(0.25, 0.5, 0.25, 1),
    },
    alertText: {
      fontSize: '1.4rem',
    },
    alertIcon: {
      fontSize: '1.7rem',
    },
    avatar: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
    },
    patientContainer: {
      padding: theme.spacing(0, 2, 1),
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'flex-end',
      },
    },
    patientDetailsContainer: {
      lineHeight: '1.9rem',
      overflow: 'hidden',
    },
    textArea: {
      fontSize: '1.4rem',
      scrollbarWidth: 'auto',
      '&::-webkit-scrollbar': {
        display: 'auto',
      },
    },
    ageLabelText: {
      whiteSpace: 'pre',
    },
    memberButton: {
      marginTop: theme.spacing(1),
    },
  }),
  { name: 'SoapClientAndPatientMainDetails' },
)

const SoapClientAndPatientMainDetails = () => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const dispatch = useDispatch()
  const { createPatient } = useCreatePatient()
  const patientId = useSelector(getPatientId)
  const clientId = useSelector(getClientId)
  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))
  const clientsLoading = useSelector(getClientIsLoading)
  const patientsLoading = useSelector(getPatientsIsLoading)
  const Species = useSelector(getSpecies)
  const permissions = useSelector(getCRUDByArea(PermissionArea.PATIENT))

  const [openClientDetailsEditDialog] = useDialog(
    DialogNames.CLIENT_DETAILS_EDIT,
  )
  const [openPatientDialog] = useDialog(DialogNames.PATIENT)

  const preferredContactMethod = useGetPreferredContactMethod({ client })

  const { alertsToRender } = useAlertType(patientId)

  const initialClientNotes = client?.notes || ''
  const initialPatientNotes = patient?.notes || ''

  const {
    fields: { clientNotes, patientNotes },
    reset,
  } = useFields(
    [
      { name: 'clientNotes', initialValue: initialClientNotes },
      { name: 'patientNotes', initialValue: initialPatientNotes },
    ],
    false,
  )

  useEffect(() => {
    reset()
  }, [initialClientNotes, initialPatientNotes])

  useEffect(() => {
    if (patient && patientNotes.value !== initialPatientNotes && clientId) {
      dispatch(
        editPatient(clientId, {
          ...patient,
          notes: patientNotes.value,
        }),
      )
    }
  }, [patientNotes.value])

  useEffect(() => {
    if (client && clientNotes.value !== initialClientNotes) {
      dispatch(
        updateClient({
          id: client.id,
          notes: clientNotes.value,
        }),
      )
    }
  }, [clientNotes.value])

  const isLoadingClient = !client?.id
  const isLoadingPatient = !patient?.id
  const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
    patient?.photo,
    patient?.photoThumbnail,
    AVATAR_SIZE,
  )

  return (
    <>
      <Grid container>
        <Grid container item sm={6}>
          {client?.alertText && (
            <AlertLabel
              showIcon
              alertColorId={client?.alertColorId}
              alertColorLevel={AlertColorLevel.CLIENT}
              clamp={Number.MAX_SAFE_INTEGER}
              classes={{
                root: classes.alertRoot,
                text: classes.alertText,
                icon: classes.alertIcon,
              }}
              message={client?.alertText}
            />
          )}
        </Grid>
        <Grid container item sm={6}>
          {!R.isEmpty(alertsToRender) && (
            <AlertLabel
              showIcon
              alertColorId={patient?.alertColorId}
              alertColorLevel={AlertColorLevel.PATIENT}
              alertTypes={alertsToRender}
              clamp={Number.MAX_SAFE_INTEGER}
              classes={{
                root: classes.alertRoot,
                text: classes.alertText,
                icon: classes.alertIcon,
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.root} direction="column">
        <Grid container item>
          <Grid
            container
            item
            xs
            className={classes.clientContainer}
            direction="column"
          >
            <Grid container item alignItems="center">
              <Dotdotdot clamp={1}>
                <TextInteractive
                  isLoading={isLoadingClient}
                  loaderHeight={32}
                  loaderWidth={50}
                >
                  <Link
                    className={classes.clientNameText}
                    to={`/client/${clientId}`}
                  >
                    {Utils.getPersonString(client)}
                  </Link>
                </TextInteractive>
              </Dotdotdot>
              {permissions.update && !isLoadingClient && (
                <Grid item xs ml={0.5}>
                  <IconButton
                    className={classes.editIcon}
                    size="large"
                    onClick={() => {
                      openClientDetailsEditDialog({
                        clientId,
                      })
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid item>
              {!R.isEmpty(preferredContactMethod) && (
                <TextInteractive
                  isLoading={isLoadingClient}
                  loaderHeight={24}
                  variant="body2"
                >
                  {`${preferredContactMethod.label}: ${preferredContactMethod.value}`}
                  &nbsp;
                </TextInteractive>
              )}
              <TextInteractive
                isLoading={isLoadingClient}
                loaderHeight={24}
                loaderWidth={120}
                variant="body2"
              >
                {`${t('Common:BALANCE')}: ${NumberUtils.formatMoney(
                  client?.balance,
                )}`}
              </TextInteractive>
            </Grid>
            <PuiTextArea
              multiline
              InputProps={{
                inputProps: {
                  className: classes.textArea,
                },
              }}
              field={clientNotes}
              isLoading={clientsLoading}
              margin="none"
              maxRows={6}
              minRows={1}
              placeholder={t('Common:NOTES')}
            />
          </Grid>
          <Grid container item xs className={classes.patientContainer}>
            <Grid
              container
              item
              xs
              className={classes.patientDetailsContainer}
              direction="column"
            >
              <Grid container item alignItems="center" wrap="nowrap">
                <Dotdotdot clamp={1}>
                  <TextInteractive
                    isLoading={isLoadingPatient}
                    loaderHeight={32}
                    loaderWidth={50}
                  >
                    <Link
                      className={classes.clientNameText}
                      to={`/patient/${patientId}`}
                    >
                      {patient?.name}
                    </Link>
                  </TextInteractive>
                </Dotdotdot>
                {permissions.update && !isLoadingPatient && (
                  <Grid item xs ml={0.5}>
                    <IconButton
                      className={classes.editIcon}
                      size="large"
                      onClick={() =>
                        openPatientDialog({
                          clientId,
                          patientId,
                          createPatient,
                        })
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
              <Grid container item className={classes.patientDetailsContainer}>
                <PatientInfoLabel fontSize="1.4rem" patientId={patientId} />
              </Grid>
            </Grid>
            <Grid item mt={2}>
              <PatientAvatar
                small
                animal={Utils.getConstantName(patient?.species, Species)}
                className={classes.avatar}
                showBoopBadge={patient?.isBoop}
                src={avatarSrc}
              />
            </Grid>
            <Grid container direction="column">
              <PuiTextArea
                multiline
                InputProps={{
                  inputProps: {
                    className: classes.textArea,
                  },
                }}
                field={patientNotes}
                isLoading={patientsLoading}
                margin="none"
                maxRows={6}
                minRows={1}
                placeholder={t('Common:NOTES')}
              />
            </Grid>
            <WellnessPlanMemberButton
              className={classes.memberButton}
              clientId={clientId}
              isLoading={isLoadingPatient}
              patientId={patientId}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default SoapClientAndPatientMainDetails
