import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {
  ButtonWithLoader,
  ClassesType,
  Nil,
  PuiTheme,
} from '@pbt/pbt-ui-components'
import { getIsScrollSmoothSupported } from '@pbt/pbt-ui-components/src/utils/browserUtils'

import { getHasOpenDialogs } from '~/store/duck/dialogs'
import useWindowWidth from '~/utils/useWindowWidth'

const isScrollSmoothSupported = getIsScrollSmoothSupported()

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    buttonContainer: {
      width: theme.spacing(3),
      minWidth: theme.spacing(3),
      height: '100%',
    },
    scrollButton: {
      fontSize: '1.2rem',
      fontWeight: 500,
      height: '100%',
      width: theme.spacing(3),
      minWidth: theme.spacing(3),
      backgroundColor: theme.colors.title,
      top: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
    scrollButtonLeft: {
      borderRadius: '4px 0 0 4px',
    },
    scrollButtonRight: {
      marginLeft: theme.spacing(1),
      borderRadius: '0 4px 4px 0',
    },
    chevron: {
      color: theme.colors.profileText,
    },
    scrollRoot: {
      maxWidth: 0, // hey safari
      width: 0,
      minWidth: 'calc(100% - 50px)',
      overflowX: 'hidden',
    },
    header: {
      height: theme.spacing(6),
    },
    columnHeaderContainer: {
      '&:last-child': {
        paddingRight: '100vw',
      },
    },
  }),
  { name: 'ScrollableColumns' },
)

enum Direction {
  LEFT = 'Left',
  RIGHT = 'Right',
}

const CONDITIONS = {
  [Direction.LEFT]: R.lte,
  [Direction.RIGHT]: R.gte,
}

// yes, they are reversed
const SCROLL_PROPERTIES = {
  [Direction.RIGHT]: 'left',
  [Direction.LEFT]: 'right',
}

const getOutOfViewColumn = (
  scrollRootRef: React.RefObject<HTMLDivElement>,
  columnRefs: React.RefObject<HTMLDivElement>[],
  direction: Direction,
) => {
  if (!scrollRootRef.current) {
    return undefined
  }

  const parentRect = scrollRootRef.current.getBoundingClientRect()

  return columnRefs.find(({ current }) => {
    if (!current) {
      return false
    }
    const childRect = current.getBoundingClientRect()
    const padding = parseInt(
      getComputedStyle(current)[`padding${direction}`],
      10,
    )
    const directionLower = direction.toLowerCase() as Lowercase<Direction>
    const condition = CONDITIONS[direction]

    const isOutOfView = condition(
      childRect[directionLower] - padding,
      parentRect[directionLower],
    )
    const isSmallerThanRoot = childRect.width - padding < parentRect.width

    return isOutOfView && isSmallerThanRoot
  })
}

const getOutOfViewColumnRight = (
  scrollRoot: React.RefObject<HTMLDivElement>,
  columnRefs: React.RefObject<HTMLDivElement>[],
) => getOutOfViewColumn(scrollRoot, columnRefs, Direction.RIGHT)
const getOutOfViewColumnLeft = (
  scrollRoot: React.RefObject<HTMLDivElement>,
  columnRefs: React.RefObject<HTMLDivElement>[],
) => getOutOfViewColumn(scrollRoot, R.reverse(columnRefs), Direction.LEFT)

const getClosestOffset = (
  offset: number,
  scrollRoot: React.RefObject<HTMLDivElement>,
  headerRefs: React.RefObject<HTMLDivElement>[],
  direction: Direction,
) => {
  const condition = CONDITIONS[direction]
  const rootOffset =
    scrollRoot.current!.scrollLeft -
    scrollRoot.current!.getBoundingClientRect().left

  return headerRefs.reduce((prev, ref) => {
    const curr = ref.current!.getBoundingClientRect().left + rootOffset
    return Math.abs(curr - offset) < Math.abs(prev - offset) &&
      condition(curr, offset)
      ? curr
      : prev
  }, 0)
}

const setRefScrollLeft = (
  ref: React.RefObject<HTMLDivElement> | Nil,
  value: number,
  smooth: boolean,
) => {
  if (ref?.current) {
    ref.current.scrollTo({
      left: value,
      behavior: isScrollSmoothSupported && smooth ? 'smooth' : 'auto',
    })
  }
}

export interface ScrollableColumnsProps {
  ColumnHeaderComponent: React.JSXElementConstructor<any>
  additionalRefs?: React.RefObject<HTMLDivElement>[]
  classes?: ClassesType<typeof useStyles>
  columns?: any[]
  contentRef?: React.RefObject<HTMLDivElement>
  dependencies?: any[]
  isDisabledNextButton?: boolean
  isLoadingNextButton?: boolean
  onScrollOffsetChange?: (offset: number) => void
  onScrollOffsetEnd?: () => void
}

export type ScrollableColumnsHandle = {
  scrollToColumnIndex: (index: number, smooth?: boolean) => void
}

const ScrollableColumns = forwardRef<
  ScrollableColumnsHandle,
  ScrollableColumnsProps
>(function ScrollableColumns(
  {
    classes: classesProp,
    ColumnHeaderComponent,
    dependencies = [],
    columns = [],
    contentRef,
    additionalRefs = [],
    isLoadingNextButton = false,
    isDisabledNextButton = false,
    onScrollOffsetChange = R.F,
    onScrollOffsetEnd = R.F,
  },
  ref,
) {
  const classes = useStyles({ classes: classesProp })
  const hasOpenDialogs = useSelector(getHasOpenDialogs)

  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )

  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false)
  const [isPrevButtonEnabled, setIsPrevButtonEnabled] = useState(false)
  const [scrollOffset, setScrollOffset] = useState(0)

  const windowWidth = useWindowWidth()

  const scrollRoot = useRef<HTMLDivElement>(null)
  const headerRefs = Array.from({ length: columns.length }, () =>
    React.createRef<HTMLDivElement>(),
  )

  const setScrollLeft = (value: number, smooth = true) => {
    setRefScrollLeft(scrollRoot, value, smooth)
    setRefScrollLeft(contentRef, value, smooth)
    additionalRefs.forEach((additionalRef) =>
      setRefScrollLeft(additionalRef, value, smooth),
    )

    if (value !== scrollOffset && onScrollOffsetChange) {
      onScrollOffsetChange(value)
      setScrollOffset(value)
    }
  }

  useDeepCompareEffect(() => {
    setIsNextButtonEnabled(
      Boolean(getOutOfViewColumnRight(scrollRoot, headerRefs)),
    )
  }, [...dependencies, columns, windowWidth])

  useEffect(() => {
    setScrollLeft(0)
  }, [...dependencies])

  useEffect(() => {
    // restore scroll - this is needed for mobile mostly as it seems to
    // reset to 0 once any dialog is opened
    if (isMobile && !hasOpenDialogs && scrollOffset > 0) {
      setScrollLeft(scrollOffset)
    }
  }, [hasOpenDialogs])

  const scrollToColumn = (
    column: React.RefObject<HTMLDivElement> | Nil,
    direction: Direction,
    smooth = true,
  ) => {
    if (!column) {
      onScrollOffsetEnd()
      return
    }

    const prop = SCROLL_PROPERTIES[direction] as Lowercase<Direction>
    const step =
      column.current!.getBoundingClientRect()[prop] -
      scrollRoot.current!.getBoundingClientRect()[prop]
    const offset = scrollRoot.current!.scrollLeft + step
    const closestOffset = getClosestOffset(
      offset,
      scrollRoot,
      headerRefs,
      direction,
    )

    setIsNextButtonEnabled(headerRefs.indexOf(column) < headerRefs.length)
    setIsPrevButtonEnabled(closestOffset > 0)
    setScrollLeft(closestOffset || 0, smooth)
  }

  const scrollToColumnRight = (
    column: React.RefObject<HTMLDivElement> | Nil,
    smooth?: boolean,
  ) => scrollToColumn(column, Direction.RIGHT, smooth)

  const scrollToColumnLeft = (
    column: React.RefObject<HTMLDivElement> | Nil,
    smooth?: boolean,
  ) => scrollToColumn(column, Direction.LEFT, smooth)

  const scrollToColumnIndex = (index: number, smooth?: boolean) =>
    scrollToColumnRight(headerRefs[index], smooth)
  const onMoreButtonClick = () =>
    scrollToColumnRight(getOutOfViewColumnRight(scrollRoot, headerRefs))
  const onLessButtonClick = () =>
    scrollToColumnLeft(getOutOfViewColumnLeft(scrollRoot, headerRefs))

  useImperativeHandle(ref, () => ({
    scrollToColumnIndex,
  }))

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.header}
      wrap="nowrap"
    >
      <Grid item className={classes.buttonContainer}>
        <ButtonWithLoader
          className={classNames(classes.scrollButton, classes.scrollButtonLeft)}
          disabled={!isPrevButtonEnabled}
          onClick={onLessButtonClick}
        >
          <ChevronLeft className={classes.chevron} />
        </ButtonWithLoader>
      </Grid>
      <Grid
        container
        item
        className={classes.scrollRoot}
        ref={scrollRoot}
        wrap="nowrap"
      >
        {columns.map((column, index) => (
          <Grid
            item
            className={classes.columnHeaderContainer}
            key={column.id || column}
            ref={headerRefs[index]}
          >
            <ColumnHeaderComponent column={column} />
          </Grid>
        ))}
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <ButtonWithLoader
          className={classNames(
            classes.scrollButton,
            classes.scrollButtonRight,
          )}
          disabled={
            !isNextButtonEnabled || isLoadingNextButton || isDisabledNextButton
          }
          loading={isLoadingNextButton}
          onClick={onMoreButtonClick}
        >
          <ChevronRight className={classes.chevron} />
        </ButtonWithLoader>
      </Grid>
    </Grid>
  )
})

export default ScrollableColumns
