import React, { ForwardedRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { pluck } from 'ramda'
import { DocumentFile, PuiTheme } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import {
  attachDocumentsToMessage,
  clearFileToSend,
} from '~/store/actions/conversationMessages'
import { getAttachmentsToSend } from '~/store/reducers/conversationMessages'
import { Document } from '~/types'
import useDialog from '~/utils/useDialog'

import { BaseTemplateInputHandle } from './BaseTemplateInput/BaseTemplateInput'
import WrappedBaseTemplateInput, {
  WrappedBaseTemplateInputProps,
} from './WrappedBaseTemplateInput'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    title: {
      marginBottom: theme.spacing(1),
    },
    titleContainer: {
      margin: 0,
    },
  }),
  { name: 'CommunicationTemplateInput' },
)

export interface CommunicationTemplateInputProps
  extends Omit<WrappedBaseTemplateInputProps, 'filter'> {}

const CommunicationTemplateInput = forwardRef(
  function CommunicationTemplateInput(
    {
      showAttachment = false,
      clientId,
      patientId,
      eventId,
      ...rest
    }: CommunicationTemplateInputProps,
    ref: ForwardedRef<BaseTemplateInputHandle>,
  ) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const attachmentsToSend = useSelector(getAttachmentsToSend)
    const { t } = useTranslation(['Common'])

    const [openAttachmentDialog] = useDialog(
      DialogNames.CONVERSATION_MULTI_ATTACHMENT_DIALOG,
    )

    const onDeleteFile = (file: DocumentFile['file']) => {
      dispatch(clearFileToSend(file.id as string))
    }

    const onAttachClick = () =>
      openAttachmentDialog({ clientId, patientId, eventId, ...rest })

    const onAttachDocuments = (documents: Document[]) => {
      dispatch(
        attachDocumentsToMessage(pluck('id', documents), {
          clientId,
          patientId,
          appointmentId: eventId,
        }),
      )
    }

    const attachmentProps = showAttachment
      ? {
          showAttachment: true,
          files: attachmentsToSend,
          onAttachClick,
          onAttachDocuments,
          onDeleteFile,
        }
      : {}

    return (
      <WrappedBaseTemplateInput
        classes={{
          title: classes.title,
          titleContainer: classes.titleContainer,
        }}
        clientId={clientId}
        eventId={eventId}
        filter="Communication template"
        label={t('Common:COMMUNICATION_TEMPLATE')}
        patientId={patientId}
        ref={ref}
        {...rest}
        {...attachmentProps}
      />
    )
  },
)

export default CommunicationTemplateInput
