import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { DateUtils, PuiTheme, TextInteractive } from '@pbt/pbt-ui-components'

import EmailPreview from '~/components/common/emailPreview/EmailPreview'
import { MessageType, MessageWay } from '~/constants/communications'
import { ChatMessage } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    header: {
      borderBottom: theme.constants.tableBorder,
    },
    internalNoteHeader: {
      borderBottom: theme.constants.tableBorderSelected,
    },
    messageContent: {
      position: 'relative',
    },
    previewContainerWrapper: {
      overflowX: 'auto',
      justifyContent: 'flex-start',
    },
    shadowDomPreviewContainer: {
      width: 600,
      overflowWrap: 'anywhere',
    },
    plainTextPreview: {
      width: '100%',
      whiteSpace: 'pre-line',
      overflowWrap: 'anywhere',
    },
  }),
  { name: 'MessageCardPrimary' },
)

export interface MessageCardPrimaryProps {
  isPlainTextMessage?: boolean
  message: ChatMessage
  title?: string
}

const MessageCardPrimary = ({
  isPlainTextMessage = false,
  message,
  title: titleProp,
}: MessageCardPrimaryProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common'])

  const isLoading = !message
  const {
    from = '',
    to = '',
    content,
    type,
    creator,
    way,
    sendingDate,
  } = message || {}

  const sendingDateString = DateUtils.formatDateWithHours(sendingDate, true)
  const isInternalNoteType = type === MessageType.INTERNAL_NOTE

  const creatorName = creator ? `| ${creator}` : ''
  const useTitle = Boolean(titleProp)
  const fromTitle = `${t('Common:EMAIL_FORM.FROM')}: ${from} ${
    way === MessageWay.OUTBOUND ? creatorName : ''
  }`
  const toTitle = `${t('Common:EMAIL_FORM.TO')}: ${to} ${
    way === MessageWay.INBOUND ? creatorName : ''
  }`

  const title = titleProp || fromTitle

  return (
    <Grid container item className={classes.root}>
      <Grid
        container
        className={classNames(classes.header, {
          [classes.internalNoteHeader]: isInternalNoteType,
        })}
        px={2}
        py={1}
      >
        <Grid container item justifyContent="space-between">
          <Dotdotdot clamp={1}>
            <TextInteractive strong isLoading={isLoading} variant="body2">
              {title}
            </TextInteractive>
          </Dotdotdot>
          <Dotdotdot clamp={1}>
            <TextInteractive isLoading={isLoading} variant="lowAccent2">
              {sendingDateString}
            </TextInteractive>
          </Dotdotdot>
        </Grid>
        {!isInternalNoteType && !useTitle && (
          <Grid item>
            <Dotdotdot clamp={1}>
              <TextInteractive isLoading={isLoading} variant="body2">
                {toTitle}
              </TextInteractive>
            </Dotdotdot>
          </Grid>
        )}
      </Grid>
      {content && (
        <Grid className={classes.messageContent} px={2} py={1}>
          <EmailPreview
            allowPointerEvents
            classes={{
              previewContainerWrapper: classes.previewContainerWrapper,
              plainTextPreview: classes.plainTextPreview,
            }}
            disableHtmlRendering={isPlainTextMessage}
            emailPreviewShadowDomClasses={{
              previewContainer: classes.shadowDomPreviewContainer,
            }}
            emailTemplate={content}
            scale={1}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default MessageCardPrimary
