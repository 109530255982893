import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, Radio, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  DateUtils,
  Field,
  PuiCheckbox,
  PuiTheme,
  TextInteractive,
} from '@pbt/pbt-ui-components'

import ExpirationNotice from '~/components/common/ExpirationNotice'
import { getInventoryManufacturers } from '~/store/reducers/constants'
import { getSpaceName } from '~/store/reducers/spaces'
import { ShipmentItem } from '~/types'
import { getManualInputSelectValue } from '~/utils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    expirationIcon: {
      width: '1.4rem',
      height: '1.4rem',
      marginLeft: theme.spacing(1),
    },
    checkboxLabel: {
      margin: 0,
    },
  }),
  { name: 'ChooseShipmentItemTableRow' },
)

export interface ChooseShipmentItemTableRowProps {
  chosenItemField: Field
  isMultiSelect: boolean
  shipmentItem: ShipmentItem
}
const ChooseShipmentItemTableRow = ({
  chosenItemField,
  shipmentItem,
  isMultiSelect,
}: ChooseShipmentItemTableRowProps) => {
  const classes = useStyles()
  const {
    id,
    name,
    receivedDate,
    manufacturerId,
    manufacturerName,
    lotNumber = '',
    serialNumber = '',
    expirationDate,
    businessReferenceNumber,
    storageLocationId,
  } = shipmentItem

  const isLoading = R.isEmpty(shipmentItem)

  const InventoryManufacturers = useSelector(getInventoryManufacturers)
  const spaceName = useSelector(getSpaceName(storageLocationId))

  const isChosen = isMultiSelect
    ? chosenItemField.value?.some((item: ShipmentItem) => item.id === id)
    : chosenItemField.value?.id === id

  return (
    <Grid container alignItems="center">
      <Grid item width={48}>
        {isLoading ? (
          <Skeleton
            height={24}
            sx={{ my: 1, mx: 1 }}
            variant="circular"
            width={24}
          />
        ) : isMultiSelect ? (
          <PuiCheckbox
            checked={isChosen}
            classes={{ labelRoot: classes.checkboxLabel }}
            onChange={() => {
              if (isChosen) {
                chosenItemField.setValue(
                  chosenItemField.value.filter(
                    (item: ShipmentItem) => item.id !== shipmentItem.id,
                  ),
                )
              } else {
                chosenItemField.setValue([
                  ...chosenItemField.value,
                  shipmentItem,
                ])
              }
            }}
          />
        ) : (
          <Radio
            checked={isChosen}
            value={id}
            onChange={() => chosenItemField.setValue(shipmentItem)}
          />
        )}
      </Grid>

      <Grid item width={200}>
        <TextInteractive isLoading={isLoading} variant="body2">
          {name}
        </TextInteractive>
      </Grid>

      <Grid item xs>
        <TextInteractive isLoading={isLoading} variant="body2">
          {DateUtils.formatDate(receivedDate)}
        </TextInteractive>
      </Grid>

      <Grid item xs>
        <TextInteractive isLoading={isLoading} variant="body2">
          {getManualInputSelectValue(
            InventoryManufacturers,
            manufacturerId,
            manufacturerName,
          )}
        </TextInteractive>
      </Grid>

      <Grid item xs>
        <TextInteractive isLoading={isLoading} variant="body2">
          {lotNumber}
        </TextInteractive>
      </Grid>

      <Grid item xs>
        <TextInteractive isLoading={isLoading} variant="body2">
          {serialNumber}
        </TextInteractive>
      </Grid>

      <Grid container item xs alignItems="center">
        <TextInteractive isLoading={isLoading} variant="body2">
          {DateUtils.formatDate(expirationDate)}
          <ExpirationNotice
            classes={{
              icon: classes.expirationIcon,
            }}
            expirationDate={expirationDate}
            warningThresholdDays={90}
          />
        </TextInteractive>
      </Grid>

      <Grid item xs>
        <TextInteractive isLoading={isLoading} variant="body2">
          {businessReferenceNumber}
        </TextInteractive>
      </Grid>

      <Grid item xs>
        <TextInteractive isLoading={isLoading} variant="body2">
          {spaceName}
        </TextInteractive>
      </Grid>
    </Grid>
  )
}

export default ChooseShipmentItemTableRow
