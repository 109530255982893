import React from 'react'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  LanguageUtils,
  PuiTheme,
  SentenceFormatter,
  Text,
} from '@pbt/pbt-ui-components'

import { PreferenceDisplaySubCategoryData } from '~/types/entities/clientPreferences'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    prefInfoRow: {
      borderBottom: theme.constants.tableBorder,
    },
    categoryTitle: {
      width: '150px',
      borderRight: theme.constants.tableBorder,
    },
  }),
  { name: 'PreferencesDisplayRow' },
)

export interface PreferencesDisplayRowProps {
  categoryTitle: string
  subCategory: PreferenceDisplaySubCategoryData[]
}

function PreferencesDisplayRow({
  subCategory,
  categoryTitle,
}: PreferencesDisplayRowProps) {
  const classes = useStyles()
  return (
    <Grid container item className={classes.prefInfoRow} flexWrap="nowrap">
      <Text strong className={classes.categoryTitle} p={1}>
        {categoryTitle}
      </Text>

      <Grid container item flexDirection="column" p={1}>
        {subCategory.map(({ subCategoryTitle, subCategoryPreferences }) => {
          const preferenceString = Array.isArray(subCategoryPreferences)
            ? LanguageUtils.getSentence(
                subCategoryPreferences,
                SentenceFormatter.REGULAR,
                ', ',
              )
            : subCategoryPreferences

          return (
            subCategoryPreferences.length > 0 && (
              <Grid container item key={subCategoryTitle}>
                <Text strong pr={1}>
                  {`${subCategoryTitle}:`}
                </Text>
                <Text strong={categoryTitle === 'Important'}>
                  {preferenceString}
                </Text>
              </Grid>
            )
          )
        })}
      </Grid>
    </Grid>
  )
}

export { PreferencesDisplayRow }
