import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { LanguageUtils, PuiTheme, Text } from '@pbt/pbt-ui-components'

import { BodySystemState } from '~/constants/SOAPStates'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    base: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 96,
      height: 32,
      borderRadius: 16,
      backgroundColor: '#F6F5F5',
      padding: '0 2px',
    },
    bullet: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 27,
      height: 27,
      borderRadius: 14,
      border: theme.constants.inactiveBorder,
      color: '#9F9B9B',
      cursor: 'pointer',
      userSelect: 'none',
      '&&&&:hover': {
        opacity: '0.5',
      },
    },
    disabled: {
      cursor: 'default',
    },
    bulletOn: {
      border: `1px solid ${theme.colors.success}`,
      color: theme.colors.success,
    },
    bulletOff: {
      border: '1px solid #E48736',
      color: '#E48736',
    },
    label: {
      lineHeight: '12px',
      color: 'inherit',
      marginTop: 2,
    },
    smallLabel: {
      fontSize: '0.7rem',
      overflow: 'visible',
    },
  }),
  { name: 'ThreeStepsButton' },
)

export interface ThreeStepsButtonProps {
  disabled?: boolean
  noneLabel: string
  offLabel: string
  onClick: (value: BodySystemState) => void
  onLabel: string
}

const ThreeStepsButton = ({
  disabled,
  noneLabel,
  onLabel,
  offLabel,
  onClick,
}: ThreeStepsButtonProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const handleClick = (value: BodySystemState) => {
    if (!disabled) {
      onClick(value)
    }
  }

  const noneLabelInitials = LanguageUtils.formatInitials(noneLabel)
  const onLabelInitials = LanguageUtils.formatInitials(onLabel)
  const offLabelInitials = LanguageUtils.formatInitials(offLabel)

  const longLabelClass = (label: string) =>
    label.length > 3 ? classes.smallLabel : undefined

  return (
    <div className={classes.base}>
      <Tooltip placement="top" title={t('Common:NOT_CHECKED')}>
        <Box
          className={classNames(classes.bullet, {
            [classes.disabled]: disabled,
          })}
          role="button"
          onClick={() => handleClick(BodySystemState.NC)}
        >
          <Text
            noWrap
            strong
            className={classNames(
              classes.label,
              longLabelClass(noneLabelInitials),
            )}
            variant="body5"
          >
            {noneLabelInitials}
          </Text>
        </Box>
      </Tooltip>
      <Tooltip placement="top" title={t('Common:WITHIN_NORMAL_LIMITS')}>
        <Box
          className={classNames(classes.bullet, classes.bulletOn, {
            [classes.disabled]: disabled,
          })}
          role="button"
          onClick={() => handleClick(BodySystemState.WNL)}
        >
          <Text
            noWrap
            strong
            className={classNames(
              classes.label,
              longLabelClass(onLabelInitials),
            )}
            variant="body5"
          >
            {onLabelInitials}
          </Text>
        </Box>
      </Tooltip>
      <Tooltip placement="top" title={t('Common:OUTSIDE_NORMAL_LIMITS')}>
        <Box
          className={classNames(classes.bullet, classes.bulletOff, {
            [classes.disabled]: disabled,
          })}
          role="button"
          onClick={() => handleClick(BodySystemState.ONL)}
        >
          <Text
            noWrap
            strong
            className={classNames(
              classes.label,
              longLabelClass(offLabelInitials),
            )}
            variant="body5"
          >
            {offLabelInitials}
          </Text>
        </Box>
      </Tooltip>
    </div>
  )
}

export default ThreeStepsButton
