import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { PuiTheme } from '@pbt/pbt-ui-components'

import MenuDropdown from '~/components/common/inputs/MenuDropdown'
import ValidationErrorPopper from '~/components/common/validation/ValidationErrorPopper'
import FeatureToggle from '~/constants/featureToggle'
import { fetchSoap } from '~/store/actions/soap'
import {
  clearAppointmentValidationError,
  patchAppointment,
} from '~/store/actions/timetable'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getAppointmentId,
  getSoapBusinessId,
  getSoapId,
} from '~/store/reducers/soap'
import {
  getTimetableEvent,
  getTimetableValidationError,
} from '~/store/reducers/timetable'
import { addOriginalBusinessId, getUrlSearchParam } from '~/utils'

import SoapHeader from '../../soap/SoapHeader'
import SoapHeaderTabs, { SoapHeaderTabsProps } from './SoapHeaderTabs'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    headerContainer: {
      [theme.breakpoints.down('md')]: {
        top: 0,
      },
      top: theme.mixins.toolbar.minHeight,
      position: 'sticky',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
      backgroundColor: theme.colors.contentBackground,
      zIndex: theme.utils.modifyZIndex(theme.zIndex.progress, 'below'),
    },
    soapDropdown: {
      '&:hover': {
        textDecoration: 'underline',
      },
      color: theme.colors.secondaryText,
      paddingBottom: 0,
      marginRight: `${theme.spacing(1)} !important`,
      marginLeft: `${theme.spacing(1)} !important`,
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'SoapMenu' },
)

export interface SoapMenuProps extends SoapHeaderTabsProps {}

const SoapMenu = ({ tabs }: SoapMenuProps) => {
  const classes = useStyles()

  const { t } = useTranslation('Common')

  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { soapId: urlSoapId } = useParams()

  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const appointmentId = useSelector(getAppointmentId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const soapBusinessId = useSelector(getSoapBusinessId)
  const soapId = useSelector(getSoapId)
  const validationError = useSelector(getTimetableValidationError)

  const rootRef = useRef<HTMLDivElement>(null)

  const originalBusinessId = getUrlSearchParam(
    'originalBusinessId',
    location.search,
  )
  const soaps =
    appointment?.soaps?.map((s, idx) => ({
      name: `${t('Common:SOAP')} ${idx + 1}`,
      value: s.id,
    })) || []
  const currentSoapIdx = R.findIndex(
    (s) => s.id === urlSoapId,
    appointment?.soaps ?? [],
  )

  const handleSoapChange = (currentSoapId: string) => {
    navigate(
      addOriginalBusinessId(
        `/soap/${currentSoapId}`,
        isPatientSharingEnabled ? soapBusinessId : null,
      ),
    )
  }

  useEffect(() => {
    if (urlSoapId) {
      if (soapId !== urlSoapId) {
        dispatch(fetchSoap(urlSoapId, originalBusinessId))
      }
    }
  }, [urlSoapId])

  return (
    <Box className={classes.headerContainer} ref={rootRef}>
      <SoapHeader />
      <Box display="flex">
        {soaps.length > 1 && (
          <MenuDropdown
            linkButton
            className={classes.soapDropdown}
            items={soaps}
            title={`${t('Common:SOAP')} ${currentSoapIdx + 1}`}
            onSelected={handleSoapChange}
          />
        )}
        <SoapHeaderTabs tabs={tabs} />
      </Box>
      <ValidationErrorPopper
        anchorEl={rootRef.current}
        error={validationError}
        open={Boolean(validationError)}
        placement="bottom"
        proceedButtonActionName={t('Common:EDIT_ACTION')}
        onBack={() => {
          dispatch(clearAppointmentValidationError())
        }}
        onProceed={() => {
          dispatch(
            patchAppointment({
              id: appointment?.id!,
              bypassValidation: true,
            }),
          )
        }}
      />
    </Box>
  )
}

export default SoapMenu
