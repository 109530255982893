import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PermissionArea,
  PuiDialog,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import { getCRUDByArea } from '~/store/reducers/auth'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      maxWidth: 422,
    },
    actions: {
      borderTop: 0,
      justifyContent: 'center',
      paddingBottom: theme.spacing(2),
    },
    icon: {
      color: theme.colors.alertWarning,
    },
    button: {
      minWidth: 150,
    },
    dialogInput: {
      width: '80%',
    },
  }),
  { name: 'DoctorLicenseDialog' },
)

interface DoctorLicenseDialogProps extends BasePuiDialogProps {
  doctorId: string
}

const DoctorLicenseDialog = ({
  doctorId,
  open,
  onClose,
}: DoctorLicenseDialogProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation(['Common'])

  const personPermissions = useSelector(getCRUDByArea(PermissionArea.PERSON))

  const navigateToAdminLicensesSection = () => {
    navigate(`/admin/general/members/${doctorId}`)
    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiDialog
      actions={
        <>
          <ButtonWithLoader
            className={classes.button}
            color="secondary"
            onClick={onClose}
          >
            {t('Common:CLOSE_ACTION')}
          </ButtonWithLoader>
          {personPermissions.update && (
            <ButtonWithLoader
              className={classes.button}
              variant="contained"
              onClick={navigateToAdminLicensesSection}
            >
              {t('Common:ADD_NOW')}
            </ButtonWithLoader>
          )}
        </>
      }
      aria-labelledby="doctor-license-dialog"
      classes={{
        paper: classes.paper,
        actions: classes.actions,
      }}
      open={open}
      onClose={onClose}
    >
      <Grid p={3} pb={1}>
        <Grid container justifyContent="center">
          <WarningIcon className={classes.icon} />
        </Grid>
        <Text pb={1} pt={2} textAlign="center" variant="body">
          {t('Common:MISSING_DVM_LICENSE')}
        </Text>
      </Grid>
    </PuiDialog>
  )
}

export default DoctorLicenseDialog
