import React from 'react'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme } from '@pbt/pbt-ui-components'
import { Edit as EditIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      background: theme.colors.loginPageBackground,
      borderRadius: '4px 4px 0 0',
    },
    buttonContainer: {
      paddingTop: 3,
    },
    iconButton: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      padding: 6,
      left: 10,
      top: 10,
      '&:hover': {
        backgroundColor: 'inherit',
      },
      position: 'absolute',
    },
    icon: {
      color: theme.colors.iconColor,
      '&:hover': {
        color: theme.colors.iconHover,
      },
    },
  }),
  { name: 'ProfileTopContainer' },
)

export interface ProfileTopContainerProps {
  children: React.ReactNode
  className?: string
  onClick?: () => void
  showEdit?: boolean
}

const ProfileTopContainer = ({
  className,
  children,
  showEdit = false,
  onClick,
}: ProfileTopContainerProps) => {
  const classes = useStyles()

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(classes.root, className)}
      direction="column"
    >
      {showEdit && (
        <Grid container item>
          <Grid item className={classes.buttonContainer}>
            <IconButton
              className={classes.iconButton}
              size="large"
              onClick={onClick}
            >
              <EditIcon className={classes.icon} />
            </IconButton>
          </Grid>
        </Grid>
      )}
      {children}
    </Grid>
  )
}

export default ProfileTopContainer
