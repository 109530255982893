import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import { ImageScalingUtils, PuiTheme, Utils } from '@pbt/pbt-ui-components'

import {
  getIsIntercomOpen,
  onIntercomClosed,
  sendIntercomFailure,
} from '~/store/duck/intercom'
import { getCurrentUser } from '~/store/reducers/auth'
import { auth0Enabled } from '~/utils'
import useIsAuthenticated from '~/utils/useIsAuthenticated'

const INTERCOM_CONFIG = {
  app_id: 'tg1cjw2l',
  hide_default_launcher: true,
  alignment: 'left',
  horizontal_padding: 70,
}

const IntercomWidget = () => {
  const theme = useTheme<PuiTheme>()

  const dispatch = useDispatch()
  const user = useSelector(getCurrentUser)
  const isOpen = useSelector(getIsIntercomOpen)

  const { isAuthenticated } = useIsAuthenticated()

  const imageLongestDimensionSize = 128
  const photo = ImageScalingUtils.getScaledImageOrOriginal(
    user.avatar,
    user.avatarThumbnail,
    imageLongestDimensionSize,
  )

  const callIntercom = useCallback((method: string, ...props: any) => {
    try {
      if (window.Intercom) {
        window.Intercom(method, ...props)
      }
    } catch (error) {
      dispatch(sendIntercomFailure(error as Error))
    }
  }, [])

  useEffect(() => {
    Utils.callWithRetry(() => {
      if (window.Intercom) {
        callIntercom('onHide', () => dispatch(onIntercomClosed()))
        return true
      }
      return false
    })
  }, [])

  useEffect(() => {
    Utils.callWithRetry(() => {
      if (isOpen) {
        callIntercom('showMessages')
      } else {
        callIntercom('hide')
      }
    })
  }, [isOpen])

  useEffect(
    () => () => {
      callIntercom('shutdown')
    },
    [],
  )

  useEffect(() => {
    if (!window.Intercom) {
      return
    }

    if (!user || (!auth0Enabled && !isAuthenticated)) {
      callIntercom('shutdown')
      return
    }

    const config = {
      ...INTERCOM_CONFIG,
      name: Utils.getPersonString(user),
      email: user.email,
      user_id: user.id,
      user_hash: user.intercomHash,
      avatar: {
        type: 'avatar',
        image_url: photo,
      },
      background_color: theme.colors.loginButtonBackground,
      action_color: theme.colors.loginButtonBackground,
    }
    // We need following line for the cases when Intercom is still not fully initialised
    window.intercomSettings = config
    callIntercom('update', config)
  }, [user, isAuthenticated])

  return null
}

export default IntercomWidget
