import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ErrorTooltip,
  PhoneUtils,
  PuiTextField,
  PuiTheme,
  Text,
  useFields,
  User,
} from '@pbt/pbt-ui-components'

import Avatar from '~/components/common/Avatar'
import EmailTextField from '~/components/common/email/EmailTextField'
import PhoneInput from '~/components/common/form-inputs/PhoneInput'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import { registerByInvite, savePerson } from '~/store/actions/registration'
import { createPerson } from '~/store/dto/Person'
import {
  getIsUserAlreadyExist,
  getRegistrationBusinesses,
  getRegistrationError,
  getRegistrationInvitationToken,
  getRegistrationIsLoading,
  getRegistrationPerson,
} from '~/store/reducers/registration'
import { removeServerErrorPrefix } from '~/utils/errors'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import BusinessLogoPaper from './BusinessLogoPaper'
import RegistrationPage from './RegistrationPage'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    input: {
      marginTop: 0,
    },
    inputContainer: {
      padding: theme.spacing(1, 1, 0),
    },
    form: {
      position: 'relative',
      paddingBottom: 68,
      [theme.breakpoints.down('md')]: {
        paddingBottom: 0,
      },
    },
    profilePictureText: {
      width: 122,
      [theme.breakpoints.down('md')]: {
        marginTop: 5,
        width: 'auto',
      },
      cursor: 'pointer',
      color: theme.colors.sideText,
    },
    formContainer: {
      marginTop: 20,
      [theme.breakpoints.down('md')]: {
        marginTop: 30,
        paddingTop: 40,
      },
    },
    logoPaperImage: {
      width: 200,
      minWidth: 200,
      height: 85,
      '&:not(:last-of-type)': {
        marginRight: theme.spacing(3),
      },
      [theme.breakpoints.up('md')]: {
        '&:not(:first-of-type)': {
          marginTop: theme.spacing(1),
        },
      },
    },
    headerContainer: {
      [theme.breakpoints.down('md')]: {
        backgroundColor: '#F7F7F7',
        paddingTop: 24,
        paddingBottom: 42,
      },
    },
    profilePicContainer: {
      [theme.breakpoints.down('md')]: {
        position: 'absolute',
        top: -45,
      },
    },
    emailInput: {
      [theme.breakpoints.down('md')]: {
        marginTop: 10,
      },
    },
    deaInput: {
      letterSpacing: 5,
      textTransform: 'uppercase',
    },
    avatar: {
      margin: theme.spacing(1),
      width: 85,
      height: 85,
      [theme.breakpoints.down('md')]: {
        width: 67,
        height: 67,
      },
    },
    defaultAvatarClass: {
      fontSize: '3.2em',
    },
    helperText: {
      color: theme.colors.sideText,
      fontSize: 10,
    },
    submitButton: {
      float: 'right',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    },
    additionalInfoContainer: {
      [theme.breakpoints.up('md')]: {
        flexDirection: 'column',
      },
      justifyContent: 'flex-start',
      padding: theme.spacing(1),
      overflowX: 'auto',
      flexWrap: 'nowrap',
    },
  }),
  { name: 'ProfileEdit' },
)

const ProfileEdit = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )
  const { t } = useTranslation(['Abbreviations', 'Common', 'Registration'])

  const person = useSelector(getRegistrationPerson)
  const businesses = Object.values(useSelector(getRegistrationBusinesses))
  const isLoading = useSelector(getRegistrationIsLoading)
  const invitationToken = useSelector(getRegistrationInvitationToken)
  const registrationError = useSelector(getRegistrationError)
  const isUserAlreadyExist = useSelector(getIsUserAlreadyExist)

  const phoneInputCountry = businesses.length
    ? businesses[0].countryCatalogCode
    : undefined

  const {
    fields: { firstName, lastName, phone, dea },
    validate,
  } = useFields(
    [
      {
        name: 'firstName',
        label: t('Common:FIRST_NAME'),
        validators: ['required'],
        initialValue: person?.firstName,
      },
      {
        name: 'lastName',
        label: t('Common:LAST_NAME'),
        validators: ['required'],
        initialValue: person?.lastName,
      },
      {
        name: 'phone',
        label: `${t('Common:MOBILE_PHONE_NUMBER')}*`,
        validators: ['phoneRequired', 'phone'],
        initialValue: person?.phone || '',
      },
      {
        name: 'dea',
        label: t('Abbreviations:ACRONYMS.DEA.DEA_NUMBER'),
        initialValue: person?.dea,
      },
    ],
    false,
  )

  const [registrationErrorMessage, setRegistrationErrorMessage] =
    useState<string>()

  const onRegistrationRequest = () => {
    if (!registrationError && !isUserAlreadyExist) {
      navigate('/register/termsAndConditions')
    }
  }

  const setOnRegistrationRequest = useCloseAfterCreation(
    onRegistrationRequest,
    getRegistrationIsLoading,
  )

  useEffect(() => {
    if (registrationError) {
      setRegistrationErrorMessage(removeServerErrorPrefix(registrationError))
    }
  }, [registrationError])

  if (!person || !invitationToken) {
    return <Navigate replace to="/" />
  }

  const updatePerson = () => {
    dispatch(
      savePerson(
        createPerson({
          email: person?.email,
          password: person?.password,
          avatar: person?.avatar,
          firstName: firstName.value,
          phone: PhoneUtils.parsePhoneNumber(phone.value),
          dea: dea.value,
          lastName: lastName.value,
        }),
      ),
    )
  }

  const onSubmit = () => {
    if (validate()) {
      updatePerson()
      setOnRegistrationRequest()
      dispatch(registerByInvite())
    }
  }

  const navigateToAvatarUpload = () => {
    updatePerson()
    navigate('/register/avatarUpload')
  }

  const additionalInfo = businesses.map((business) => (
    <Grid item className={classes.logoPaperImage} key={business.id}>
      <BusinessLogoPaper business={business} />
    </Grid>
  ))

  return (
    <RegistrationPage
      additionalInfo={additionalInfo}
      classes={{
        additionalInfoContainer: classes.additionalInfoContainer,
        headerContainer: classes.headerContainer,
        submit: classes.submitButton,
      }}
      disabled={isLoading}
      header={t('Common:WELCOME')}
      subheader={t('Registration:PROFILE_EDIT.SUB_HEADER')}
      submitLabel={t('Common:SUBMIT_ACTION')}
      onSubmit={onSubmit}
    >
      <Grid container className={classes.form}>
        <Grid
          container
          item
          alignContent="center"
          alignItems="center"
          className={classes.profilePicContainer}
          direction={isMobile ? 'column' : 'row'}
          justifyContent={isMobile ? 'center' : 'flex-start'}
          xs={12}
        >
          <Grid item xs={isMobile ? true : 2}>
            <Avatar
              className={classes.avatar}
              defaultAvatarClass={classes.defaultAvatarClass}
              person={person as Partial<User>}
              onClick={navigateToAvatarUpload}
            />
          </Grid>
          <Grid item>
            <Text
              strong
              className={classes.profilePictureText}
              pl={2}
              onClick={navigateToAvatarUpload}
            >
              {t('Registration:PROFILE_EDIT.CHOOSE_PROFILE_PICTURE')}
            </Text>
          </Grid>
        </Grid>
        <Grid container item className={classes.formContainer}>
          <Grid item md={6} xs={12}>
            <div className={classes.inputContainer}>
              <PuiTextField
                autoFocus
                className={classes.input}
                field={firstName}
                inputProps={{ maxLength: 100 }}
                label={`${firstName.label}*`}
              />
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div className={classes.inputContainer}>
              <PuiTextField
                className={classes.input}
                field={lastName}
                inputProps={{ maxLength: 100 }}
                label={`${lastName.label}*`}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item md={6} xs={12}>
            <div className={classes.inputContainer}>
              <PhoneInput
                className={classes.input}
                country={phoneInputCountry}
                field={phone}
              />
            </div>
          </Grid>
          <Grid container item alignItems="flex-end" md={6} xs={12}>
            <Grid item xs={12}>
              <div className={classes.inputContainer}>
                {registrationErrorMessage ? (
                  <ErrorTooltip open message={registrationErrorMessage}>
                    <PuiTextField
                      shrink
                      className={classNames(classes.input, classes.emailInput)}
                      value={person?.email}
                    />
                  </ErrorTooltip>
                ) : (
                  <EmailTextField
                    shrink
                    className={classNames(classes.input, classes.emailInput)}
                    value={person?.email}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.inputContainer}>
            <PuiTextField
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              InputProps={{
                classes: {
                  input: classes.deaInput,
                },
              }}
              className={classes.input}
              field={dea}
              helperText={t('Registration:PROFILE_EDIT.ADD_DEA_LICENSE')}
              label={dea.label}
            />
          </div>
        </Grid>
        <Grid item mt={2} pl={1} xs={12}>
          <RequiredFieldsNotice />
        </Grid>
      </Grid>
    </RegistrationPage>
  )
}

export default ProfileEdit
