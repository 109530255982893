import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Input } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  PuiSelect,
  PuiTheme,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import QuantityInput from '~/components/common/inputs/QuantityInput'
import {
  CustomDatePrepositions,
  CustomDatePrepositionsList,
} from '~/constants/taskConstants'
import {
  getAppointmentCommunicationInitializationType,
  getAppointmentCommunicationOffsetPoint,
  getAppointmentCommunicationTimeUnits,
} from '~/store/reducers/constants'
import { DataHandle } from '~/types'

import AppointmentCommunicationTimeUnitsSelect from './AppointmentCommunicationTimeUnitsSelect'
import { AutomaticCommunicationBaseProps } from './interfaces'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    input: {
      height: 45,
    },
    select: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'AutomaticCommunicationSpecificTimeSettings' },
)

export interface AutomaticCommunicationSpecificTimeSettingsProps
  extends AutomaticCommunicationBaseProps {
  initializationTypeId: string
}

const AutomaticCommunicationSpecificTimeSettings = forwardRef<
  DataHandle | undefined,
  AutomaticCommunicationSpecificTimeSettingsProps
>(({ initializationTypeId, className, automaticCommunication }, ref) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const AppointmentCommunicationTimeUnits = useSelector(
    getAppointmentCommunicationTimeUnits,
  )
  const InitializationType = useSelector(
    getAppointmentCommunicationInitializationType,
  )
  const AppointmentCommunicationOffsetPoint = useSelector(
    getAppointmentCommunicationOffsetPoint,
  )

  const SpecificTimeOptionId = Utils.findConstantIdByName(
    'At a specific time',
    InitializationType,
  )
  const DayTimeUnit = Utils.findConstantIdByName(
    'Day',
    AppointmentCommunicationTimeUnits,
  )
  const StartId = Utils.findConstantIdByName(
    'Start',
    AppointmentCommunicationOffsetPoint,
  )

  const isSpecificTime = initializationTypeId === SpecificTimeOptionId
  const amount =
    automaticCommunication?.initializationByTime?.timeOffset.amount || 1

  const {
    fields: {
      customDueDateAmount,
      customDueDateUnits,
      customDueDatePreposition,
      offsetPointId,
    },
    validate,
    reset,
  } = useFields(
    [
      { name: 'customDueDateAmount', initialValue: Math.abs(amount) },
      {
        name: 'customDueDateUnits',
        initialValue:
          automaticCommunication?.initializationByTime?.timeOffset?.unit ||
          DayTimeUnit,
      },
      {
        name: 'customDueDatePreposition',
        initialValue:
          amount >= 0
            ? CustomDatePrepositions.AFTER
            : CustomDatePrepositions.BEFORE,
      },
      {
        name: 'offsetPointId',
        initialValue:
          automaticCommunication?.initializationByTime?.offsetPointId ||
          StartId,
      },
    ],
    false,
  )

  useEffect(() => {
    reset()
  }, [automaticCommunication])

  useImperativeHandle(ref, () => ({
    validate: () => (isSpecificTime ? validate() : true),
    get: () => ({
      initializationByTime: isSpecificTime
        ? {
            timeOffset: {
              amount:
                customDueDatePreposition.value === CustomDatePrepositions.AFTER
                  ? customDueDateAmount.value
                  : -customDueDateAmount.value,
              unit: customDueDateUnits.value,
            },
            offsetPointId: offsetPointId.value,
          }
        : null,
    }),
  }))

  if (!isSpecificTime) {
    return null
  }

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(className, classes.root)}
      wrap="nowrap"
      xs={12}
    >
      <Grid item>
        <QuantityInput
          showControls
          className={classes.input}
          field={customDueDateAmount}
        />
      </Grid>
      <AppointmentCommunicationTimeUnitsSelect
        amount={customDueDateAmount.value}
        className={classes.select}
        field={customDueDateUnits}
      />
      <PuiSelect
        className={classes.select}
        field={customDueDatePreposition}
        input={
          <Input id="automatic-communication-due-date-preposition-select" />
        }
        items={CustomDatePrepositionsList}
        renderEmpty={false}
      />
      <Text variant="body">{t('Common:APPOINTMENT_ONE').toLowerCase()}</Text>
      <PuiSelect
        className={classes.select}
        field={offsetPointId}
        input={<Input id="automatic-communication-due-date-postfix-select" />}
        items={AppointmentCommunicationOffsetPoint}
        renderEmpty={false}
      />
    </Grid>
  )
})

export default AutomaticCommunicationSpecificTimeSettings
