import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, PuiTheme, Text } from '@pbt/pbt-ui-components'

import ImagingOrdersRow from './ImagingOrdersRow'
import { useGetImagingOrdersData } from './useGetImagingOrdersData'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    title: {
      width: '100%',
    },
    labRow: {
      padding: theme.spacing(1, 1, 1, 2),
      borderBottom: theme.constants.tableBorder,
    },
    name: {
      width: 360,
    },
    iconButton: {
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.5),
    },
  }),
  { name: 'ImagingOrdersTable' },
)

export interface ImagingOrdersTableProps {
  classes?: ClassesType<typeof useStyles>
}

const ImagingOrdersTable = ({
  classes: classesProp,
}: ImagingOrdersTableProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const { handleEdit, handleOrderCancel, handlePreview, imagingOrders } =
    useGetImagingOrdersData()

  return (
    <Grid container className={classes.root} direction="column" wrap="nowrap">
      <Grid item>
        <Text className={classes.title} pl={2} pr={1} pt={2} variant="h4">
          {t('Common:IMAGE_REQUESTS')}
        </Text>
      </Grid>
      <Grid container item direction="column">
        {imagingOrders.map((order) => (
          <ImagingOrdersRow
            key={`${order.vendorId}-${order.id}`}
            order={order}
            onHandleEdit={handleEdit}
            onHandleOrderCancel={handleOrderCancel}
            onHandlePreview={handlePreview}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default ImagingOrdersTable
