import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Checkbox, FormControlLabel, Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'
import {
  Attachment as AttachmentIcon,
  Eye as EyeIcon,
} from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    labelRoot: {
      alignItems: 'flex-start',
    },
    checkboxLabel: {
      color: theme.colors.secondaryText,
      marginTop: theme.spacing(1),
      fontSize: '1.6rem',
      width: 320,
    },
    iconButton: {
      marginTop: theme.spacing(1),
      padding: 0,
    },
    eyeIcon: {
      color: theme.colors.title,
    },
    attachmentIcon: {
      opacity: 0.5,
      fontSize: '1.7rem',
    },
    normal: {
      backgroundColor: '#F5F9FB',
      color: theme.colors.title,
    },
    important: {
      backgroundColor: '#FEF7ED',
      color: theme.colors.important,
    },
    disabled: {
      backgroundColor: '#F2F1F1',
      color: theme.colors.disabledLabelText,
    },
    checkbox: {
      margin: theme.spacing(1, 1, 0, 1),
      padding: 0,
    },
  }),
  { name: 'ItemWithChildren' },
)

export interface ItemWithChildrenProps {
  StateComponent?: React.JSXElementConstructor<any>
  getChildName?: (item: any) => string
  getName: (item: any) => string
  handleSelectionChange: () => void
  isSelected: boolean
  item: any
  onEyeClick?: (itemId: string) => void
  propWithChildren?: string
}

const ItemWithChildren = ({
  item,
  isSelected,
  handleSelectionChange,
  propWithChildren,
  getName,
  getChildName,
  onEyeClick,
  StateComponent,
}: ItemWithChildrenProps) => {
  const classes = useStyles()

  if (!item) {
    return null
  }

  return (
    <Grid container item direction="column" key={item.id}>
      <Grid
        container
        item
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <FormControlLabel
          classes={{
            label: classes.checkboxLabel,
            root: classes.labelRoot,
          }}
          control={
            <Checkbox
              checked={isSelected}
              className={classes.checkbox}
              value={item.id}
              onChange={handleSelectionChange}
            />
          }
          label={<Dotdotdot clamp={2}>{getName(item)}</Dotdotdot>}
        />
        {item.state && StateComponent && <StateComponent item={item} />}
        {onEyeClick && (
          <IconButton
            className={classes.iconButton}
            size="large"
            onClick={() => onEyeClick(item.id)}
          >
            <EyeIcon className={classes.eyeIcon} />
          </IconButton>
        )}
      </Grid>
      <Grid item pl={4}>
        {propWithChildren &&
          item[propWithChildren] &&
          item[propWithChildren].map((child: any) => (
            <Grid container item alignItems="center" key={child?.id || child}>
              <AttachmentIcon className={classes.attachmentIcon} />
              {getChildName && (
                <Text inline variant="lowAccent2">
                  {getChildName(child)}
                </Text>
              )}
            </Grid>
          ))}
      </Grid>
    </Grid>
  )
}

export default ItemWithChildren
