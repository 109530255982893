import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import {
  ClassesType,
  DateUtils,
  PuiCheckbox,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import { isToday } from '~/utils/time'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    checkbox: {
      padding: theme.spacing(0.9375),
    },
    container: {},
    hasError: {
      color: theme.colors.errorColor,
    },
    label: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'TableDateCell' },
)

interface TableDateCellProps {
  classes?: ClassesType<typeof useStyles>
  date: string
  hasError?: boolean
  id?: string
  isChecked?: boolean
  onCheck?: (id: string) => void
}

export const TableDateCell = ({
  classes: classesProp,
  hasError = false,
  date,
  isChecked = false,
  id,
  onCheck,
}: TableDateCellProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Time')

  const isTodayDate = isToday(date)

  return (
    <Box alignItems="center" className={classes.container} display="flex">
      {onCheck && id && (
        <PuiCheckbox
          checkboxClasses={{
            root: classNames(classes.checkbox, {
              [classes.hasError]: hasError,
            }),
          }}
          checked={isChecked}
          classes={{ labelRoot: classes.label }}
          id={id}
          onChange={() => onCheck(id)}
        />
      )}
      <Text variant="lowAccent3">
        {isTodayDate ? t('Time:LABEL.TODAY') : DateUtils.formatDate(date)}
      </Text>
    </Box>
  )
}
