import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ButtonWithLoader,
  PuiSelect,
  PuiTextArea,
  PuiTheme,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import {
  LandingType,
  LandingWidgetName,
  WidgetWidthType,
} from '~/constants/landingConstants'
import { getWidgetDataIsLoading } from '~/store/duck/landing'
import {
  clockIn,
  clockOut,
  getCurrentTimeEntity,
  getTimeTrackerIsLoading,
} from '~/store/duck/timeTracker'
import { getClockOutReasons } from '~/store/reducers/constants'
import { WidgetColumn } from '~/types'

import TableWidget from '../TableWidget'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      width: '100%',
    },
    rootFullWidth: {
      width: '80%',
      borderRight: theme.constants.tableBorder,
    },
    button: {
      marginTop: theme.spacing(2),
      width: 140,
    },
    reason: {
      marginBottom: theme.spacing(1.2),
    },
  }),
  { name: 'TimeTrackingContent' },
)

interface TimeTrackingContentProps {
  columns: WidgetColumn[]
  data: any
  isLoading?: boolean
  navigateToItemDetailsTooltip?: string
  onNavigateToItemDetails: (item: any) => void
  rowsCount: number
  widthType?: WidgetWidthType
}

const TimeTrackingContent = ({
  columns,
  data,
  rowsCount,
  isLoading,
  widthType,
  onNavigateToItemDetails,
  navigateToItemDetailsTooltip,
}: TimeTrackingContentProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Time'])

  const ClockOutReasons = useSelector(getClockOutReasons)
  const timeEntity = useSelector(getCurrentTimeEntity)
  const timeTrackingIsLoading = useSelector(getTimeTrackerIsLoading)
  const widgetDataIsLoading = useSelector(
    getWidgetDataIsLoading(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.TIME_TRACKING,
    ),
  )

  const isFullWidth = widthType === WidgetWidthType.FULL_WIDTH
  const isTracking = Boolean(timeEntity)
  const endOfShift = Utils.findConstantIdByName('End of shift', ClockOutReasons)

  const {
    fields: { clockOutReason, notes },
    reset,
  } = useFields([
    {
      name: 'clockOutReason',
      type: 'select',
      initialValue: endOfShift,
    },
    { name: 'notes', label: t('Common:NOTES'), initialValue: '' },
  ])

  const handleClockIn = () => {
    dispatch(clockIn(notes.value))
    reset()
  }

  const handleClockOut = () => {
    dispatch(clockOut(clockOutReason.value, notes.value))
    reset()
  }

  return (
    <Grid container wrap="nowrap">
      <Grid
        className={classNames(classes.root, {
          [classes.rootFullWidth]: isFullWidth,
        })}
      >
        <Grid p={2}>
          {isTracking && (
            <PuiSelect
              className={classes.reason}
              field={clockOutReason}
              items={ClockOutReasons}
              renderEmpty={false}
            />
          )}
          <PuiTextArea
            field={notes}
            margin="none"
            minRows={isTracking ? 2 : 4}
            placeholder={notes.label}
          />
          <ButtonWithLoader
            className={classes.button}
            disabled={widgetDataIsLoading}
            loading={timeTrackingIsLoading}
            onClick={isTracking ? handleClockOut : handleClockIn}
          >
            {isTracking
              ? t('Time:LABEL.CLOCK_OUT_ACTION')
              : t('Time:LABEL.CLOCK_IN_ACTION')}
          </ButtonWithLoader>
        </Grid>
      </Grid>
      {isFullWidth && (
        <TableWidget
          columns={columns}
          data={data}
          isLoading={isLoading}
          navigateToItemDetailsTooltip={navigateToItemDetailsTooltip}
          rowsCount={rowsCount}
          showHeaderTitles={false}
          onNavigateToItemDetails={onNavigateToItemDetails}
        />
      )}
    </Grid>
  )
}

export default TimeTrackingContent
