import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Calendar,
  LinkButton,
  moment,
  Nil,
  PermissionArea,
  PuiDialog,
  PuiTheme,
  useFields,
} from '@pbt/pbt-ui-components'

import TimeSelector from '~/components/common/inputs/time-selector/TimeSelector'
import UserSelect from '~/components/common/inputs/UserSelect'
import DialogNames from '~/constants/DialogNames'
import {
  addVital,
  deleteVital,
  editVital,
  getVital,
  getVitalsIsDeleting,
  getVitalsIsSaving,
} from '~/store/duck/vitals'
import { getCRUDByArea, getCurrentUserId } from '~/store/reducers/auth'
import { getPatient } from '~/store/reducers/patients'
import { Vital, VitalRecord, VitalValue } from '~/types'
import { getDeleteConfirmMessage } from '~/utils'
import { aggregateDateToUtc, roundTime } from '~/utils/time'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import { haveFieldsChanged } from '~/utils/useFieldsChanged'

import VitalsControlTable, {
  VitalsControlTableHandle,
} from './VitalsControlTable'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      overflowY: 'unset',
    },
    button: {
      height: 40,
      padding: theme.spacing(0, 6),
      marginRight: theme.spacing(2),
    },
    startDate: {
      minWidth: 100,
    },
  }),
  { name: 'VitalsDialog' },
)

const castVitalValueToString = (vitalRecord: VitalRecord<any>) => ({
  ...vitalRecord,
  value:
    vitalRecord?.value?.id?.toString() || vitalRecord?.value?.toString() || '',
})

const castVital = (vital: Vital): Vital =>
  R.map<any, any>((vitalValue) => {
    if (R.is(String, vitalValue) || R.isNil(vitalValue?.value)) {
      return vitalValue
    }
    return castVitalValueToString(vitalValue)
  }, vital)

const castVitalStateValueToString = (vitalStateValue: VitalValue): VitalValue =>
  R.map<any, any>(
    (vitalValue) => castVitalValueToString(vitalValue),
    vitalStateValue,
  )

interface VitalsDialogProps extends BasePuiDialogProps {
  clientId?: string | Nil
  patientId?: string | Nil
  soapId?: string | Nil
  vitalsId?: string
}

const VitalsDialog = ({
  vitalsId,
  clientId,
  patientId,
  soapId,
  onClose,
  open,
}: VitalsDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isSaving = useSelector(getVitalsIsSaving)
  const isDeleting = useSelector(getVitalsIsDeleting)
  const patient = useSelector(getPatient(patientId))
  const vital = useSelector(getVital(vitalsId))
  const currentUserId = useSelector(getCurrentUserId)
  const { update: vitalsUpdatePermissions, delete: vitalsDeletePermissions } =
    useSelector(getCRUDByArea(PermissionArea.VITAL))
  const { t } = useTranslation(['Common', 'Dialogs', 'Time'])

  const [openDeleteVitalAlert, closeDeleteVitalAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const vitalsTableRef = useRef<VitalsControlTableHandle>(null)

  const closeOnSaved = useCloseAfterCreation(onClose, getVitalsIsSaving)
  const closeOnDeleted = useCloseAfterCreation(onClose, getVitalsIsDeleting)

  // this is needed to capture defaults on dialog open and do not override them on later re-renders
  const defaultStartDate = useMemo(() => new Date().toISOString(), [])
  const defaultStartTime = useMemo(
    () => roundTime(new Date().toISOString()),
    [],
  )

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'startDate',
        label: t('Dialogs:VITALS_DIALOG.RECORDED_DATE'),
        validators: ['required', 'timestamp'],
        initialValue:
          vital?.recordedDate || vital?.creationDate || defaultStartDate,
      },
      {
        name: 'startTime',
        label: t('Time:TIME_SELECTOR.START_TIME'),
        type: 'none',
        validators: ['required', 'timestamp'],
        initialValue:
          vital?.recordedDate || vital?.creationDate || defaultStartTime,
      },
      {
        name: 'recordedById',
        label: t('Common:PAYMENTS.RECORDED_BY'),
        type: 'select',
        initialValue:
          vital?.recordedById ||
          vital?.creatorId ||
          (vital?.id ? '' : currentUserId),
      },
    ],
    false,
  )

  const { startDate, startTime, recordedById } = fields

  useEffect(() => {
    reset()
  }, [vital])

  const getUpdatedVital = () => {
    const updatedVital = vitalsTableRef.current?.getVitalsState()
    return {
      ...vital,
      ...updatedVital,
      recordedDate: aggregateDateToUtc({
        date: moment(startDate.value),
        time: moment(startTime.value),
        ignoreSeconds: true,
      }),
      recordedById: recordedById.value,
    }
  }

  const handleSave = () => {
    if (validate()) {
      const updatedVital = getUpdatedVital()
      closeOnSaved()
      dispatch(addVital(updatedVital, soapId!, clientId, patientId!))
    }
  }

  const handleUpdate = () => {
    if (validate()) {
      const updatedVital = getUpdatedVital() as Vital
      closeOnSaved()
      dispatch(editVital(updatedVital))
    }
  }

  const handleDelete = () => {
    if (vitalsId) {
      openDeleteVitalAlert({
        message: getDeleteConfirmMessage(t('Common:VITAL').toLowerCase()),
        cancelButtonText: t('Common:NO_KEEP'),
        okButtonText: t('Common:YES_DELETE'),
        onCancel: () => closeDeleteVitalAlert(),
        onOk: () => {
          dispatch(deleteVital(vitalsId))
          closeDeleteVitalAlert()
          closeOnDeleted()
        },
      })
    }
  }

  const castedVital = vital && castVital(vital)
  const patientName = patient?.name || t('Common:PATIENT').toLowerCase()

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: () => (vitalsId ? handleUpdate() : handleSave()),
      }}
      actions={
        vitalsId ? (
          <>
            <ButtonWithLoader
              className={classes.button}
              disabled={!vitalsUpdatePermissions || isSaving || isDeleting}
              id={`vitals-add-edit-dialog-${vital?.id}-save-button`}
              loading={isSaving}
              onClick={handleUpdate}
            >
              {t('Common:SAVE_VITALS')}
            </ButtonWithLoader>
            <LinkButton
              disabled={!vitalsDeletePermissions || isSaving || isDeleting}
              id={`vitals-add-edit-dialog-${vital?.id}-remove-button`}
              loading={isDeleting}
              onClick={handleDelete}
            >
              {t('Common:REMOVE_ALL')}
            </LinkButton>
          </>
        ) : (
          <ButtonWithLoader
            className={classes.button}
            disabled={!vitalsUpdatePermissions || isSaving}
            id="vitals-add-edit-dialog-newVital-save-button"
            loading={isSaving}
            onClick={handleSave}
          >
            {t('Common:SAVE_ACTION')}
          </ButtonWithLoader>
        )
      }
      aria-labelledby="vitals-dialog"
      classes={{
        paper: classes.paper,
      }}
      hasUnsavedChanges={() => {
        const currentVitals = vitalsTableRef.current?.getVitalsState() || {}
        const vitalsState = castVitalStateValueToString(
          R.omit(['soapId'], currentVitals),
        )
        if (!castedVital) {
          return false
        }
        const vitalChanged = !R.whereEq(
          vitalsState,
          castVitalStateValueToString(castedVital) || {},
        )
        const fieldsChanged = haveFieldsChanged(fields)

        return vitalChanged || fieldsChanged
      }}
      header={
        <Grid container item alignItems="flex-end" pb={4} pt={3} wrap="nowrap">
          <Calendar
            className={classes.startDate}
            field={startDate}
            label={startDate.label}
            margin="none"
          />
          <Grid item px={1}>
            <TimeSelector
              offsetArrows
              fromLabel={null}
              startValue={startTime.value}
              withPopper={false}
              onStartChange={startTime.set}
            />
          </Grid>
          <UserSelect
            plainSelect
            field={recordedById}
            label={recordedById.label}
          />
        </Grid>
      }
      open={open}
      scroll="paper"
      title={
        vitalsId
          ? t('Dialogs:VITALS_DIALOG.TITLE_EDIT', { patientName })
          : t('Dialogs:VITALS_DIALOG.TITLE_ADD', { patientName })
      }
      onClose={onClose}
    >
      <VitalsControlTable
        editDisabled={!vitalsUpdatePermissions || isSaving || isDeleting}
        ref={vitalsTableRef}
        soapId={soapId}
        vital={castedVital}
      />
    </PuiDialog>
  )
}

export default VitalsDialog
