import React from 'react'
import { Icon } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, PuiTheme } from '@pbt/pbt-ui-components'
import { TasksDashboard } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    taskIcon: {
      fontSize: '3rem',
      color: theme.colors.signIconBackground,
    },
  }),
  { name: 'TaskIcon' },
)

export interface TaskIconProps {
  classes?: ClassesType<typeof useStyles>
}

const TaskIcon = ({ classes: classesProp }: TaskIconProps) => {
  const classes = useStyles({ classes: classesProp })

  return <Icon className={classes.taskIcon} component={TasksDashboard} />
}

export default TaskIcon
