import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  PuiTextField,
  PuiTheme,
  TextInteractive,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import ClientCard from '~/components/common/ContactTargetSlot/ClientCard'
import PatientCard from '~/components/common/ContactTargetSlot/PatientCard'
import UserSelect, {
  UserSelectFilterScope,
} from '~/components/common/inputs/UserSelect'
import AddedToAppointmentLabel from '~/components/common/labels/AddedToAppointmentLabel'
import Expander from '~/components/common/lists/Expander'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { ImagingOrderStatus } from '~/constants/imaging'
import { OrderType } from '~/constants/SOAPStates'
import i18n from '~/locales/i18n'
import {
  completeOrderWithinDashboard,
  editOrderWithinDashboard,
  fetchDashboardDetails,
  getImagingDashboardIsLoading,
  getImagingDashboardRecord,
  getIsFetchingDetails,
} from '~/store/duck/imagingDashboard'
import { getImagingIsLoading } from '~/store/duck/imagingOrders'
import { useOpenInvoice } from '~/store/hooks/finance'
import {
  getFeatureToggle,
  getImagingOrderStatuses,
} from '~/store/reducers/constants'
import { ImagingOrder } from '~/types'
import { addOriginalBusinessId, isFieldValuesChanged } from '~/utils'
import useDialog from '~/utils/useDialog'

import ImagingStatusSelect from '../../imaging-procedures/ImagingStatusSelect'
import ModalitySelect from '../../imaging-procedures/ModalitySelect'
import NotesTemplateInput from '../../template-inputs/NotesTemplateInput'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    contactContainer: {
      border: theme.constants.tableBorder,
    },
  }),
  { name: 'ImagingDetails' },
)

export interface ImagingDetailsProps {
  item: string
  onClose: () => void
}

const ImagingDetails = ({ item: itemId, onClose }: ImagingDetailsProps) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const isSaving = useSelector(getImagingIsLoading)
  const isFetching = useSelector(getIsFetchingDetails)
  const isLoadingDashboardRecord = useSelector(getImagingDashboardIsLoading)
  const item = useSelector(getImagingDashboardRecord(itemId))
  const ImagingOrderStatuses = useSelector(getImagingOrderStatuses)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)

  useEffect(() => {
    if (itemId) {
      dispatch(fetchDashboardDetails(itemId))
    }
  }, [itemId])

  const {
    client: clientId,
    patient: patientId,
    order,
    assignedVet,
    appointment,
    vendorId,
    businessId,
  } = item || {}

  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)

  const status = Utils.getConstantName(order?.statusId, ImagingOrderStatuses)

  const { fields, validate, reset } = useFields([
    {
      name: 'name',
      label: i18n.t('Common:IMAGING_REQUEST_NAME'),
      initialValue: order?.name || '',
    },
    {
      name: 'modalityId',
      type: 'select',
      initialValue: order?.modalityId || '',
    },
    {
      name: 'assignedVetId',
      type: 'select',
      initialValue: assignedVet || '',
    },
    {
      name: 'notes',
      label: i18n.t('Common:NOTES'),
      initialValue: (order?.notes || '').trim(),
    },
    {
      name: 'statusId',
      type: 'select',
      initialValue: order?.statusId || '',
    },
  ])

  const { name, modalityId, assignedVetId, notes, statusId } = fields

  useEffect(() => {
    if (item) {
      reset()
    }
  }, [item])

  const saveOrder = () => {
    if (validate()) {
      const newOrder = {
        ...(order as ImagingOrder),
        name: name.value,
        modalityId: modalityId.value,
        assignedVetId: assignedVetId.value,
        notes: notes.value,
        statusId: statusId.value,
      }

      dispatch(editOrderWithinDashboard(newOrder, itemId))
    }
  }

  const viewSoap = () => {
    navigate(
      addOriginalBusinessId(
        `/soap/${order?.soapId}`,
        isPatientSharingEnabled ? businessId : null,
      ),
    )
  }

  const markAsReviewed = () => {
    if (order?.id) {
      dispatch(completeOrderWithinDashboard(order.id, itemId))
    }
  }

  const viewImages = () => {
    window.open(order?.resultsUrl)
  }

  const backToDashboard = () => onClose()

  return (
    <Expander
      additionalButtons={[
        status === ImagingOrderStatus.IMAGE_RECEIVED && (
          <ButtonWithLoader
            disabled={isSaving || isLoadingDashboardRecord}
            key="view-invoice"
            onClick={markAsReviewed}
          >
            {t('Common:MARK_AS_REVIEWED')}
          </ButtonWithLoader>
        ),
        (status === ImagingOrderStatus.IMAGE_RECEIVED ||
          status === ImagingOrderStatus.COMPLETED) &&
          Boolean(order?.resultsUrl) && (
            <ButtonWithLoader
              disabled={isSaving || isLoadingDashboardRecord}
              key="view-invoice"
              onClick={viewImages}
            >
              {t('Common:VIEW_IMAGES')}
            </ButtonWithLoader>
          ),
        Boolean(order?.soapId) && !order?.invoiceId && (
          <ButtonWithLoader
            disabled={isSaving || isLoadingDashboardRecord}
            key="view-soap"
            onClick={viewSoap}
          >
            {t('Common:VIEW_SOAP')}
          </ButtonWithLoader>
        ),
        Boolean(order?.invoiceId) && (
          <ButtonWithLoader
            disabled={isSaving || isLoadingDashboardRecord}
            key="view-invoice"
            onClick={() =>
              openInvoice({
                clientId,
                patientId,
                invoiceId: order?.invoiceId,
                soapId: order?.soapId,
                logId: order?.logId,
                logType: OrderType.PROCEDURE,
              })
            }
          >
            {t('Common:VIEW_INVOICE')}
          </ButtonWithLoader>
        ),
      ]}
      expandedItemClass="imaging-request"
      getUnsavedData={() => isFieldValuesChanged(fields)}
      isFetching={isFetching}
      isSaving={isSaving || isLoadingDashboardRecord}
      onBack={backToDashboard}
      onSaveRequested={saveOrder}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextInteractive
            isLoading={isFetching}
            loaderHeight={30}
            variant="h3"
          >
            {order?.name}
          </TextInteractive>
        </Grid>
        <Grid container item>
          <ImagingStatusSelect
            field={statusId}
            isSoapFinalized={Boolean(order?.soapFinalized)}
          />
        </Grid>
        <Grid item className={classes.contactContainer} m={1} p={2}>
          <Grid container spacing={1}>
            {clientId && patientId && (
              <Grid item xs>
                <PatientCard clientId={clientId} patientId={patientId} />
              </Grid>
            )}
            {clientId && (
              <Grid item xs>
                <ClientCard clientId={clientId} />
              </Grid>
            )}
          </Grid>
        </Grid>
        {appointment && order?.soapId && (
          <Grid item>
            <AddedToAppointmentLabel
              appointment={appointment}
              soapId={order?.soapId}
            />
          </Grid>
        )}
        <Grid item>
          <UserSelect
            readOnly
            field={assignedVetId}
            filterScope={UserSelectFilterScope.Doctors}
          />
        </Grid>
        <Grid container item spacing={2}>
          <Grid container item xs={7}>
            <PuiTextField field={name} label={name.label} margin="none" />
          </Grid>
          <Grid item xs={5}>
            <ModalitySelect field={modalityId} vendorId={vendorId} />
          </Grid>
        </Grid>
        <Grid container item>
          <NotesTemplateInput
            hidePanel
            resetStateOnValueChange
            field={notes}
            minHeight={2}
            placeholder={notes.label}
          />
        </Grid>
      </Grid>
    </Expander>
  )
}

export default ImagingDetails
