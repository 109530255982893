import * as R from 'ramda'
import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { ApiError, Constant, Utils } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import {
  NotificationAreaNames,
  NotificationStyleNames,
  NotificationSyntheticAreas,
} from '~/constants/notifications'
import { Notification, NotificationsTotalCounts, TableFilter } from '~/types'
import { prepareUiNotificationByType } from '~/utils/notifications'

import {
  addNotificationForAreas,
  changeNotificationIsRead,
  changeNotificationIsReadFailure,
  changeNotificationIsReadSuccess,
  decrementNotificationsTotalCounts,
  deleteAllNotificationsFailure,
  deleteAllNotificationsSuccess,
  deleteNotification,
  deleteNotificationFailure,
  deleteNotificationSuccess,
  fetchMoreItemsForNotifications,
  fetchMoreItemsForNotificationsFailure,
  fetchMoreItemsForNotificationsSuccess,
  fetchNotificationAreasTotalCountsFailure,
  fetchNotificationAreasTotalCountsSuccess,
  fetchNotifications,
  fetchNotificationSettings,
  fetchNotificationSettingsFailure,
  fetchNotificationSettingsSuccess,
  fetchNotificationsFailure,
  fetchNotificationsSuccess,
  incrementNotificationsTotalCounts,
  markAllNotificationsAsReadFailure,
  markAllNotificationsAsReadForLinkedItem,
  markAllNotificationsAsReadForLinkedItemFailure,
  markAllNotificationsAsReadForLinkedItemSuccess,
  markAllNotificationsAsReadSuccess,
  newListNotification,
  newWsNotification,
  newWsNotificationFailure,
  setNotificationsMap,
  setNotificationsTotalCountsToZero,
  updateNotifications,
  updateNotificationSettings,
  updateNotificationSettingsFailure,
  updateNotificationSettingsSuccess,
  updateNotificationsLinkedToAreaListItem,
  updateNotificationsLinkedToAreaListItemMap,
  updateNotificationsSingleTotalCount,
  updateNotificationsTotalCounts,
  updateNotificationsTotalCountsFailure,
} from '../actions/notifications'
import { FETCH_CURRENT_USER_SUCCESS } from '../actions/types/auth'
import { FETCH_SECURE_CONSTANTS_SUCCESS } from '../actions/types/constants'
import {
  ADD_NOTIFICATION_FOR_AREAS,
  DECREMENT_NOTIFICATIONS_TOTAL_COUNTS,
  FETCH_MORE_ITEMS_FOR_NOTIFICATIONS,
  FETCH_NOTIFICATION_SETTINGS,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS,
  INCREMENT_NOTIFICATIONS_TOTAL_COUNTS,
  NEW_WS_NOTIFICATION,
  NOTIFICATIONS_CHANGE_IS_READ,
  NOTIFICATIONS_DELETE,
  NOTIFICATIONS_DELETE_ALL,
  NOTIFICATIONS_MARK_ALL_AS_READ,
  NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM,
  SET_NOTIFICATIONS_TOTAL_COUNTS_TO_ZERO,
  UPDATE_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_NOTIFICATIONS_LINKED_TO_AREA_LIST_ITEM,
  UPDATE_NOTIFICATIONS_SINGLE_TOTAL_COUNT,
} from '../actions/types/notifications'
import { addUiNotification } from '../duck/uiNotifications'
import { getCurrentUserId } from '../reducers/auth'
import {
  getConstantsIsLoading,
  getNotificationArea,
  getNotificationStyle,
  getNotificationType,
} from '../reducers/constants'
import {
  getMultipleNotifications,
  getNotificationEnabledStyleSettingIds,
  getNotificationIdsForLinkedItemId,
  getNotificationsItem,
  getNotificationsListFilters,
  getNotificationsMap,
  getNotificationsTotalCounts,
} from '../reducers/notifications'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

function* getNotificationAreaIdForNotificationsList() {
  const filters: Record<string, TableFilter> = yield select(
    getNotificationsListFilters,
  )
  return filters?.area?.value &&
    filters.area.value !== NotificationSyntheticAreas.ALL.id
    ? filters.area.value
    : null
}

function* getNotificationAreaIdsForNotificationsList() {
  const notificationAreaId: string = yield call(
    getNotificationAreaIdForNotificationsList,
  )
  return notificationAreaId ? [notificationAreaId] : null
}

const mapStyleTotalCountForAreas = R.curry(
  (totalCountMapper, styleTotalCounts, areaIds) =>
    R.map(
      (totalCountEntry) =>
        !R.isEmpty(
          R.intersection(areaIds, [
            NotificationSyntheticAreas.ALL.id,
            totalCountEntry.notificationAreaId,
          ]),
        )
          ? {
              ...totalCountEntry,
              totalCount: totalCountMapper(totalCountEntry.totalCount),
            }
          : totalCountEntry,
      styleTotalCounts,
    ),
)

const mapStyleTotalCountForArea = R.curry(
  (totalCountMapper, styleTotalCounts, areaId) =>
    mapStyleTotalCountForAreas(totalCountMapper, styleTotalCounts, [areaId]),
)

function* clearTotalCountsForAreas(areaIds: string[]) {
  const totalCounts: NotificationsTotalCounts = yield select(
    getNotificationsTotalCounts,
  )
  const updatedTotalCounts: NotificationsTotalCounts = R.map(
    (totalCount) =>
      mapStyleTotalCountForAreas(R.always(0), totalCount, areaIds),
    totalCounts,
  )
  yield put(updateNotificationsTotalCounts(updatedTotalCounts))
}

const mapStyleTotalCount = R.curry((totalCountMapper, styleTotalCount) =>
  mapStyleTotalCountForArea(
    totalCountMapper,
    styleTotalCount,
    NotificationSyntheticAreas.ALL.id,
  ),
)

function* getTotalCountsForAreaName({ styleId }: { styleId: string }) {
  const NotificationStyles: Constant[] = yield select(getNotificationStyle)
  const menuStyleId = Utils.findConstantIdByName(
    NotificationStyleNames.MENU,
    NotificationStyles,
  )
  const listStyleId = Utils.findConstantIdByName(
    NotificationStyleNames.LIST,
    NotificationStyles,
  )

  const totalCountsForAreaNameByStyleId: Record<
    string,
    keyof NotificationsTotalCounts
  > = {
    [menuStyleId]: 'menuTotalCountsForAreas',
    [listStyleId]: 'listTotalCountsForAreas',
  }

  return totalCountsForAreaNameByStyleId[styleId]
}

function* getStyleIdsEnabledForNotificationType(notificationTypeId: string) {
  const currentUserId: string = yield select(getCurrentUserId)
  const enabledStyleSettingIds: string[] = yield select(
    getNotificationEnabledStyleSettingIds(notificationTypeId, currentUserId),
  )

  return enabledStyleSettingIds
}

export function* addNotificationForAreasSaga({
  notification,
}: ReturnType<typeof addNotificationForAreas>) {
  const styleIdsEnabledForNotificationType: string[] = yield call(
    getStyleIdsEnabledForNotificationType,
    notification.typeId,
  )
  const NotificationTypes: Constant[] = yield select(getNotificationType)
  const NotificationStyles: Constant[] = yield select(getNotificationStyle)

  const notificationTypeName = Utils.getConstantName(
    notification?.typeId,
    NotificationTypes,
  )
  const popupStyleId = Utils.findConstantIdByName(
    NotificationStyleNames.POPUPS,
    NotificationStyles,
  )
  const listStyleId = Utils.findConstantIdByName(
    NotificationStyleNames.LIST,
    NotificationStyles,
  )

  if (R.includes(popupStyleId, styleIdsEnabledForNotificationType)) {
    yield put(
      addUiNotification(
        prepareUiNotificationByType(notification, notificationTypeName),
      ),
    )
  }

  if (R.includes(listStyleId, styleIdsEnabledForNotificationType)) {
    yield put(newListNotification(notification.id))
  }
}

function* updateTotalCountsForNotificationType(
  totalCounts: NotificationsTotalCounts,
  notificationTypeId: string,
  totalCountMapper: (value: number) => number,
) {
  const NotificationTypes: Constant[] = yield select(getNotificationType)
  const styleIdsEnabledForNotificationType: string[] = yield call(
    getStyleIdsEnabledForNotificationType,
    notificationTypeId,
  )

  const notificationsType = Utils.findById(
    notificationTypeId,
    NotificationTypes,
  )
  const areaId = notificationsType.notificationAreaId

  const updatedTotalCounts = { ...totalCounts }

  for (let i = 0; i < styleIdsEnabledForNotificationType.length; i++) {
    const styleId = styleIdsEnabledForNotificationType[i]
    const totalCountsForAreaPropName: keyof NotificationsTotalCounts =
      yield call(getTotalCountsForAreaName, {
        styleId,
      })

    if (totalCountsForAreaPropName) {
      const oldTotalCountsForStyle = totalCounts[totalCountsForAreaPropName]
      updatedTotalCounts[totalCountsForAreaPropName] =
        mapStyleTotalCountForArea(
          totalCountMapper,
          oldTotalCountsForStyle,
          areaId,
        )
    }
  }

  return updatedTotalCounts
}

export function* fetchNotificationSettingsSaga({
  personId,
}: ReturnType<typeof fetchNotificationSettings>) {
  try {
    const notificationSettings = yield* requestAPI(
      API.fetchNotificationSettings,
      personId,
    )
    yield put(fetchNotificationSettingsSuccess(personId, notificationSettings))
  } catch (error) {
    yield put(fetchNotificationSettingsFailure(error as ApiError))
  }
}

export function* updateNotificationSettingsSaga({
  personId,
  notificationSettings,
}: ReturnType<typeof updateNotificationSettings>) {
  try {
    const newNotificationSettings = yield* requestAPI(
      API.updateNotificationSettings,
      personId,
      notificationSettings,
    )
    yield put(
      updateNotificationSettingsSuccess(personId, newNotificationSettings),
    )
  } catch (error) {
    yield put(updateNotificationSettingsFailure(error as ApiError))
  }
}

export function* fetchNotificationsSaga({
  from,
  to,
  notificationStyleId,
}: ReturnType<typeof fetchNotifications>) {
  try {
    const notificationAreaIds: string[] | null = yield call(
      getNotificationAreaIdsForNotificationsList,
    )
    const currentUserId: string = yield select(getCurrentUserId)

    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(
      API.fetchNotificationsList,
      currentUserId,
      notificationAreaIds,
      notificationStyleId,
      from,
      to,
    )

    yield call(updateEntities, entities)
    yield put(
      updateNotificationsTotalCounts({
        listTotalCountsForAreas: entities.notificationListTotalCount,
        menuTotalCountsForAreas: entities.notificationMenuTotalCount,
      }),
    )
    yield put(fetchNotificationsSuccess(list, totalCount))
  } catch (error) {
    yield put(fetchNotificationsFailure(error as ApiError))
  }
}

export function* fetchMoreItemsForNotificationsSaga({
  from,
  to,
  notificationStyleId,
}: ReturnType<typeof fetchMoreItemsForNotifications>) {
  try {
    const currentUserId: string = yield select(getCurrentUserId)
    const notificationAreaIds: string[] = yield call(
      getNotificationAreaIdsForNotificationsList,
    )

    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(
      API.fetchNotificationsList,
      currentUserId,
      notificationAreaIds,
      notificationStyleId,
      from,
      to,
    )

    yield call(updateEntities, entities)
    yield put(
      updateNotificationsTotalCounts({
        listTotalCountsForAreas: entities.notificationListTotalCount,
        menuTotalCountsForAreas: entities.notificationMenuTotalCount,
      }),
    )
    yield put(fetchMoreItemsForNotificationsSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreItemsForNotificationsFailure(error as ApiError))
  }
}

export function* fetchNotificationsTotalCountsSaga() {
  try {
    const constantsIsLoading: boolean = yield select(getConstantsIsLoading)
    if (constantsIsLoading) {
      yield take(FETCH_SECURE_CONSTANTS_SUCCESS)
    }
    const NotificationAreas: Constant[] = yield select(getNotificationArea)
    const menuStyleNotificationAreaIds = R.pipe(
      R.values,
      R.map((areaName: string) =>
        Utils.findConstantIdByName(areaName, NotificationAreas),
      ),
      R.filter(Boolean),
    )(NotificationAreaNames)
    const listStyleNotificationAreaIds = R.pluck('id', NotificationAreas)
    const currentUserId: string = yield select(getCurrentUserId)

    const { entities } = yield* requestAPI(
      API.fetchNotificationsTotalCounts,
      currentUserId,
      menuStyleNotificationAreaIds,
      listStyleNotificationAreaIds,
    )

    yield put(
      updateNotificationsTotalCounts({
        listTotalCountsForAreas: entities.notificationListTotalCount,
        menuTotalCountsForAreas: entities.notificationMenuTotalCount,
      }),
    )
    yield put(fetchNotificationAreasTotalCountsSuccess())
  } catch (error) {
    yield put(fetchNotificationAreasTotalCountsFailure(error as ApiError))
  }
}

export function* changeNotificationIsReadSaga({
  notificationId,
  isRead,
}: ReturnType<typeof changeNotificationIsRead>) {
  try {
    const notification: Notification = yield select(
      getNotificationsItem(notificationId),
    )
    const updateAction = isRead
      ? decrementNotificationsTotalCounts
      : incrementNotificationsTotalCounts
    yield put(updateAction(notification.typeId))

    yield* requestAPI(API.changeNotificationIsRead, notificationId, isRead)
    yield put(changeNotificationIsReadSuccess())
  } catch (error) {
    yield put(
      changeNotificationIsReadFailure(error as ApiError, notificationId),
    )

    const notification: Notification = yield select(
      getNotificationsItem(notificationId),
    )
    const updateAction = isRead
      ? incrementNotificationsTotalCounts
      : decrementNotificationsTotalCounts
    yield put(updateAction(notification.typeId))
  }
}

export function* markAllNotificationsAsReadSaga() {
  const notificationsMap: Record<string, Notification> = yield select(
    getNotificationsMap,
  )
  const pendingMap = R.clone(notificationsMap)
  const pendingTotalCounts: NotificationsTotalCounts = yield select(
    getNotificationsTotalCounts,
  )

  try {
    const notificationAreaIds: string[] | null = yield call(
      getNotificationAreaIdsForNotificationsList,
    )

    if (notificationAreaIds == null) {
      const updatedNotificationsMap: Record<string, Notification> = R.map(
        (it) => ({ ...it, read: true }),
        notificationsMap,
      )
      yield put(updateNotifications(updatedNotificationsMap))
      yield put(setNotificationsTotalCountsToZero())
    } else {
      const updatedNotificationsMap: Record<string, Notification> = R.map(
        (it) =>
          R.includes(it.areaId, notificationAreaIds)
            ? { ...it, read: true }
            : it,
        notificationsMap,
      )
      yield put(updateNotifications(updatedNotificationsMap))
      yield clearTotalCountsForAreas(notificationAreaIds)
    }

    const currentUserId: string = yield select(getCurrentUserId)
    yield* requestAPI(
      API.markAllNotificationsAsRead,
      currentUserId,
      notificationAreaIds,
    )

    yield put(markAllNotificationsAsReadSuccess())
  } catch (error) {
    yield put(markAllNotificationsAsReadFailure(error as ApiError))
    yield put(updateNotifications(pendingMap))
    yield put(updateNotificationsTotalCounts(R.clone(pendingTotalCounts)))
  }
}

export function* deleteNotificationSaga({
  notificationId,
}: ReturnType<typeof deleteNotification>) {
  const notification: Notification = yield select(
    getNotificationsItem(notificationId),
  )

  try {
    const notificationsMap: Record<string, Notification> = yield select(
      getNotificationsMap,
    )
    yield put(setNotificationsMap(R.omit([notificationId], notificationsMap)))

    if (!notification.read) {
      yield put(decrementNotificationsTotalCounts(notification.typeId))
    }

    yield* requestAPI(API.deleteNotification, notificationId)
    yield put(deleteNotificationSuccess(notificationId))
  } catch (error) {
    yield put(deleteNotificationFailure(error as ApiError, notificationId))
    yield put(updateNotifications({ [notificationId]: notification }))

    if (!notification.read) {
      yield put(incrementNotificationsTotalCounts(notification.typeId))
    }
  }
}

export function* deleteAllNotificationsSaga() {
  const notificationsMap: Record<string, Notification> = yield select(
    getNotificationsMap,
  )
  const pendingMap = R.clone(notificationsMap)
  const totalCounts: NotificationsTotalCounts = yield select(
    getNotificationsTotalCounts,
  )
  const pendingTotalCounts = R.clone(totalCounts)

  try {
    const notificationAreaIds: string[] = yield call(
      getNotificationAreaIdsForNotificationsList,
    )

    if (notificationAreaIds == null) {
      yield put(setNotificationsMap({}))
      yield put(setNotificationsTotalCountsToZero())
    } else {
      const notificationsToDelete = R.filter(
        (notification) => R.includes(notification.areaId, notificationAreaIds),
        Object.values(notificationsMap),
      )
      const updatedNotificationsMap = R.omit(
        R.pluck('id', notificationsToDelete),
        notificationsMap,
      )
      yield put(setNotificationsMap(updatedNotificationsMap))
      yield clearTotalCountsForAreas(notificationAreaIds)
    }

    const currentUserId: string = yield select(getCurrentUserId)

    yield* requestAPI(
      API.deleteAllNotifications,
      currentUserId,
      notificationAreaIds,
    )

    yield put(deleteAllNotificationsSuccess())
  } catch (error) {
    yield put(deleteAllNotificationsFailure(error as ApiError))
    yield put(updateNotifications(pendingMap))
    yield put(updateNotificationsTotalCounts(pendingTotalCounts))
  }
}

export function* decrementNotificationsTotalCountsSaga({
  notificationTypeId,
}: ReturnType<typeof decrementNotificationsTotalCounts>) {
  try {
    const totalCounts: NotificationsTotalCounts = yield select(
      getNotificationsTotalCounts,
    )
    const updatedTotalCounts: NotificationsTotalCounts = yield call(
      updateTotalCountsForNotificationType,
      totalCounts,
      notificationTypeId,
      R.dec,
    )
    yield put(updateNotificationsTotalCounts(updatedTotalCounts))
  } catch (error) {
    yield put(updateNotificationsTotalCountsFailure(error as ApiError))
  }
}

export function* incrementNotificationsTotalCountsSaga({
  notificationTypeId,
}: ReturnType<typeof incrementNotificationsTotalCounts>) {
  try {
    const totalCounts: NotificationsTotalCounts = yield select(
      getNotificationsTotalCounts,
    )
    const updatedTotalCounts: NotificationsTotalCounts = yield call(
      updateTotalCountsForNotificationType,
      totalCounts,
      notificationTypeId,
      R.inc,
    )
    yield put(updateNotificationsTotalCounts(updatedTotalCounts))
  } catch (error) {
    yield put(updateNotificationsTotalCountsFailure(error as ApiError))
  }
}

export function* setNotificationsTotalCountsToZeroSaga() {
  try {
    const totalCounts: NotificationsTotalCounts = yield select(
      getNotificationsTotalCounts,
    )
    const updatedTotalCounts: NotificationsTotalCounts = R.map(
      mapStyleTotalCount(R.always(0)),
      totalCounts,
    )
    yield put(updateNotificationsTotalCounts(updatedTotalCounts))
  } catch (error) {
    yield put(updateNotificationsTotalCountsFailure(error as ApiError))
  }
}

export function* updateNotificationSingleTotalCountSaga({
  totalCount,
  areaId,
  styleId,
}: ReturnType<typeof updateNotificationsSingleTotalCount>) {
  try {
    const totalCountsForAreaName: keyof NotificationsTotalCounts = yield call(
      getTotalCountsForAreaName,
      { styleId },
    )
    const totalCounts: NotificationsTotalCounts = yield select(
      getNotificationsTotalCounts,
    )

    if (!totalCountsForAreaName) {
      return
    }

    const updatedTotalCounts = {
      ...totalCounts,
      [totalCountsForAreaName]: {
        ...totalCounts[totalCountsForAreaName],
        [areaId]: {
          ...totalCounts[totalCountsForAreaName]?.[areaId],
          totalCount,
        },
      },
    }

    yield put(updateNotificationsTotalCounts(updatedTotalCounts))
  } catch (error) {
    yield put(updateNotificationsTotalCountsFailure(error as ApiError))
  }
}

export function* updateNotificationsLinkedToAreaListItemSaga({
  notificationIdsByLinkedItemId = {},
  unreadNotificationsTotalCount,
  areaId,
}: ReturnType<typeof updateNotificationsLinkedToAreaListItem>) {
  try {
    const NotificationStyles: Constant[] = yield select(getNotificationStyle)
    const menuStyleId = Utils.findConstantIdByName(
      NotificationStyleNames.MENU,
      NotificationStyles,
    )

    if (!R.isNil(unreadNotificationsTotalCount)) {
      yield put(
        updateNotificationsSingleTotalCount(
          unreadNotificationsTotalCount,
          areaId,
          menuStyleId,
        ),
      )
    }
    yield put(
      updateNotificationsLinkedToAreaListItemMap(
        notificationIdsByLinkedItemId,
        areaId,
      ),
    )

    const unreadNotificationsFromLinkedListItems = Object.values(
      notificationIdsByLinkedItemId,
    )
      .flat()
      .map((notificationId) => ({ id: notificationId, read: false }))
      .reduce(
        (notificationsMap, notification) => ({
          ...notificationsMap,
          [notification.id]: notification,
        }),
        {},
      )
    yield put(updateNotifications(unreadNotificationsFromLinkedListItems))
  } catch (error) {
    // Ignore error
  }
}

export function* markAllNotificationsAsReadForLinkedItemSaga({
  linkedItemId,
  areaId,
}: ReturnType<typeof markAllNotificationsAsReadForLinkedItem>) {
  const pendingTotalCounts: NotificationsTotalCounts = yield select(
    getNotificationsTotalCounts,
  )

  try {
    const notificationIds: string[] = yield select(
      getNotificationIdsForLinkedItemId(areaId, linkedItemId),
    )
    const notifications: Notification[] = yield select(
      getMultipleNotifications(notificationIds),
    )
    const unreadNotifications = notifications.filter(R.propEq('read', false))
    if (R.isEmpty(unreadNotifications)) {
      return
    }

    const updatedNotificationsMap = unreadNotifications
      .map((it) => ({ ...it, read: true }))
      .reduce(
        (notificationsMap, notification) => ({
          ...notificationsMap,
          [notification.id]: notification,
        }),
        {},
      )
    yield put(updateNotifications(updatedNotificationsMap))

    for (let i = 0; i < unreadNotifications.length; i++) {
      yield put(
        decrementNotificationsTotalCounts(unreadNotifications[i].typeId),
      )
    }

    const currentUserId: string = yield select(getCurrentUserId)
    yield* requestAPI(
      API.markNotificationsAsRead,
      currentUserId,
      notificationIds,
    )

    yield put(markAllNotificationsAsReadForLinkedItemSuccess())
  } catch (error) {
    yield put(markAllNotificationsAsReadForLinkedItemFailure(error as ApiError))
    yield put(updateNotificationsTotalCounts(R.clone(pendingTotalCounts)))
  }
}

export function* newWSNotificationSaga({
  body,
}: ReturnType<typeof newWsNotification>) {
  try {
    const { entities, result } = body
    const { notification: notificationId } = result

    const {
      notificationListTotalCount: listTotalCountsForAreas,
      notificationMenuTotalCount: menuTotalCountsForAreas,
    } = entities

    yield call(updateEntities, entities)
    yield put(
      updateNotificationsTotalCounts({
        listTotalCountsForAreas,
        menuTotalCountsForAreas,
      }),
    )

    const notification = entities?.notificationItem?.[notificationId] || {}
    yield put(addNotificationForAreas(notification))
  } catch (error) {
    yield put(newWsNotificationFailure(error as ApiError))
  }
}

function* watchFetchNotificationSettings() {
  yield takeEvery(FETCH_NOTIFICATION_SETTINGS, fetchNotificationSettingsSaga)
}

function* watchUpdateNotificationSettings() {
  yield takeLeading(
    UPDATE_NOTIFICATION_SETTINGS,
    updateNotificationSettingsSaga,
  )
}

function* watchFetchEventsForFetchingTotalCounts() {
  yield takeLeading(
    [FETCH_CURRENT_USER_SUCCESS, UPDATE_NOTIFICATION_SETTINGS_SUCCESS],
    fetchNotificationsTotalCountsSaga,
  )
}

function* watchFetchNotificationsSaga() {
  yield takeLatest(FETCH_NOTIFICATIONS, fetchNotificationsSaga)
}

function* watchFetchMoreItemsForNotificationsSaga() {
  yield takeLatest(
    FETCH_MORE_ITEMS_FOR_NOTIFICATIONS,
    fetchMoreItemsForNotificationsSaga,
  )
}

function* watchFetchNotificationsTotalCountsSaga() {
  yield takeEvery(
    FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS,
    fetchNotificationsTotalCountsSaga,
  )
}

function* watchChangeNotificationIsReadSaga() {
  yield takeEvery(NOTIFICATIONS_CHANGE_IS_READ, changeNotificationIsReadSaga)
}

function* watchMarkAllNotificationsAsReadSaga() {
  yield takeLeading(
    NOTIFICATIONS_MARK_ALL_AS_READ,
    markAllNotificationsAsReadSaga,
  )
}

function* watchDeleteNotificationSaga() {
  yield takeEvery(NOTIFICATIONS_DELETE, deleteNotificationSaga)
}

function* watchDeleteAllNotificationsSaga() {
  yield takeEvery(NOTIFICATIONS_DELETE_ALL, deleteAllNotificationsSaga)
}

function* watchDecrementNotificationsTotalCountsSaga() {
  yield takeEvery(
    DECREMENT_NOTIFICATIONS_TOTAL_COUNTS,
    decrementNotificationsTotalCountsSaga,
  )
}

function* watchIncrementNotificationsTotalCountsSaga() {
  yield takeEvery(
    INCREMENT_NOTIFICATIONS_TOTAL_COUNTS,
    incrementNotificationsTotalCountsSaga,
  )
}

function* watchSetNotificationsTotalCountsToZeroSaga() {
  yield takeEvery(
    SET_NOTIFICATIONS_TOTAL_COUNTS_TO_ZERO,
    setNotificationsTotalCountsToZeroSaga,
  )
}

function* watchUpdateNotificationSingleTotalCount() {
  yield takeEvery(
    UPDATE_NOTIFICATIONS_SINGLE_TOTAL_COUNT,
    updateNotificationSingleTotalCountSaga,
  )
}

function* watchUpdateNotificationLinkedToAreaListItem() {
  yield takeEvery(
    UPDATE_NOTIFICATIONS_LINKED_TO_AREA_LIST_ITEM,
    updateNotificationsLinkedToAreaListItemSaga,
  )
}

function* watchMarkAllNotificationsAsReadForLinkedItem() {
  yield takeEvery(
    NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM,
    markAllNotificationsAsReadForLinkedItemSaga,
  )
}

function* watchWsNewNotification() {
  yield takeEvery(NEW_WS_NOTIFICATION, newWSNotificationSaga)
}

function* watchAddNotificationForAreas() {
  yield takeEvery(ADD_NOTIFICATION_FOR_AREAS, addNotificationForAreasSaga)
}

export default function* notificationsSaga() {
  yield all([
    watchFetchNotificationSettings(),
    watchUpdateNotificationSettings(),
    watchFetchEventsForFetchingTotalCounts(),
    watchFetchNotificationsSaga(),
    watchFetchMoreItemsForNotificationsSaga(),
    watchFetchNotificationsTotalCountsSaga(),
    watchChangeNotificationIsReadSaga(),
    watchMarkAllNotificationsAsReadSaga(),
    watchDeleteNotificationSaga(),
    watchDeleteAllNotificationsSaga(),
    watchDecrementNotificationsTotalCountsSaga(),
    watchIncrementNotificationsTotalCountsSaga(),
    watchSetNotificationsTotalCountsToZeroSaga(),
    watchUpdateNotificationSingleTotalCount(),
    watchUpdateNotificationLinkedToAreaListItem(),
    watchMarkAllNotificationsAsReadForLinkedItem(),
    watchWsNewNotification(),
    watchAddNotificationForAreas(),
  ])
}
