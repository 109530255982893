import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
  PuiTextField,
  PuiTheme,
  Text,
  UnsavedWellnessPlan,
  useFields,
  Utils,
  WellnessPlan,
  WellnessPlanPrice,
} from '@pbt/pbt-ui-components'

import {
  getWellnessPlanPriceType,
  getWellnessPlanType,
} from '~/store/reducers/constants'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 450,
      maxWidth: 450,
    },
    dialogContentRoot: {
      padding: theme.spacing(2, 2, 3),
    },
    input: {
      maxWidth: 240,
    },
    button: {
      width: 150,
    },
  }),
  { name: 'WellnessPlanPackageDialog' },
)

export interface WellnessPlanPackageDialogProps extends BasePuiDialogProps {
  onProceed: (plan: UnsavedWellnessPlan | WellnessPlan) => void
  package?: WellnessPlan
}

const WellnessPlanPackageDialog = ({
  open,
  package: packageProp,
  onClose,
  onProceed,
}: WellnessPlanPackageDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'WellnessPlans'])

  const WellnessPlanType = useSelector(getWellnessPlanType)
  const WellnessPlanPriceType = useSelector(getWellnessPlanPriceType)

  const PackageTypeId = Utils.findConstantIdByName('Package', WellnessPlanType)
  const MonthlyPriceTypeId = Utils.findConstantIdByName(
    'Monthly',
    WellnessPlanPriceType,
  )

  const isEdit = Boolean(packageProp)

  const {
    fields: { name },
    validate,
    reset,
  } = useFields(
    [
      {
        name: 'name',
        label: t('Common:SUBSCRIBE_AND_SAVE_NAME'),
        validators: ['required'],
        initialValue: packageProp?.name || '',
      },
    ],
    false,
  )

  useEffect(() => {
    reset()
  }, [packageProp])

  const handleSave = () => {
    if (validate()) {
      const newPackage = {
        ...packageProp,
        name: name.value,
      }

      if (!isEdit) {
        newPackage.price = 0
        newPackage.prices = [
          {
            priceTypeId: MonthlyPriceTypeId,
            price: 0,
          } as WellnessPlanPrice,
        ]
        newPackage.planTypeId = PackageTypeId
        newPackage.priceTypeId = MonthlyPriceTypeId
      }

      onProceed(newPackage as UnsavedWellnessPlan)

      if (onClose) {
        onClose()
      }
    }
  }

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader
          className={classes.button}
          type="submit"
          onClick={handleSave}
        >
          {packageProp ? t('Common:SAVE_ACTION') : t('Common:ADD_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="wellness-plan-package-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={
        packageProp
          ? t('Common:EDIT_SUBSCRIBE_AND_SAVE')
          : t('Common:ADD_SUBSCRIBE_AND_SAVE')
      }
      onClose={onClose}
    >
      <Text variant="body2">
        {t(
          'WellnessPlans:PATIENTS_CAN_ENROLL_IN_ANY_NUMBER_OF_SUBSCRIBE_AND_SAVE',
        )}
      </Text>
      <PuiTextField
        className={classes.input}
        field={name}
        inputProps={{ maxLength: 100 }}
        label={`${name.label}*`}
      />
    </PuiDialog>
  )
}

export default WellnessPlanPackageDialog
