import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Nil, PuiTheme, Text } from '@pbt/pbt-ui-components'

import { LabTestState } from '~/constants/SOAPStates'
import { LabOrder, Order } from '~/types'

import LabOrderSection from './LabOrderSection'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      '&:not(:last-of-type)': {
        marginBottom: theme.spacing(4),
      },
    },
    title: {
      lineHeight: '20px',
    },
    orderId: {
      color: theme.colors.primaryText,
      fontWeight: 'normal',
    },
  }),
  { name: 'LabSection' },
)

export interface LabSectionProps {
  hasIntegration: boolean
  hideName?: boolean
  invoiceId: string | Nil
  labTestsMap: Record<string, Order[]>
  name: string
  notReadyForOrder?: Order[]
  onAddLab?: (state: string, soapId?: string | Nil) => void
  onCancelOrder: (orderId: string) => void
  onEmailResult: (order: LabOrder, vendorId: string) => void
  onFinalizeOrder: (order: LabOrder) => void
  onPlaceLabOrder: (
    orders: Order[],
    deviceSerialNumber: string | undefined,
  ) => void
  onPrintLabel: (order?: LabOrder) => void
  onPrintOrPreview: (
    isResult: boolean,
    shouldPrint: boolean,
    order: LabOrder,
    vendorId: string,
  ) => void
  ordersMap: Record<string, LabOrder>
  readyForOrder?: Order[]
  refetchChargeSheet?: boolean
  showDevices?: boolean
  vendorId: string
}

const LabSection = ({
  invoiceId,
  hideName: hideNameProp,
  name,
  vendorId,
  labTestsMap,
  ordersMap,
  hasIntegration,
  readyForOrder: readyForOrderProp,
  notReadyForOrder: notReadyForOrderProp,
  showDevices,
  refetchChargeSheet,
  onAddLab,
  onCancelOrder,
  onPlaceLabOrder,
  onFinalizeOrder,
  onPrintLabel,
  onPrintOrPreview,
  onEmailResult,
}: LabSectionProps) => {
  const classes = useStyles()
  const hasOrders = Object.keys(labTestsMap).length > 0
  const readyForOrder = readyForOrderProp || []
  const notReadyForOrder = notReadyForOrderProp || []
  const hideName = Boolean(hideNameProp)

  return (
    <Grid container className={classes.root}>
      {!hasOrders && !hideName && (
        <Text strong className={classes.title} mb={1}>
          {name}
        </Text>
      )}
      {Object.keys(labTestsMap)
        .filter(
          (orderId) => labTestsMap[orderId]?.length > 0 && ordersMap[orderId],
        )
        .map((orderId) => (
          <React.Fragment key={orderId}>
            {!hideName ? (
              <Text strong className={classes.title} mb={1}>
                {name}{' '}
                <span
                  className={classes.orderId}
                >{`(${ordersMap[orderId].labOrderId})`}</span>
              </Text>
            ) : (
              <Text strong className={classes.title} mb={1}>
                <span
                  className={classes.orderId}
                >{`(${ordersMap[orderId].labOrderId})`}</span>
              </Text>
            )}
            <LabOrderSection
              hasIntegration={hasIntegration}
              invoiceId={invoiceId}
              key={orderId}
              labName={name}
              labTests={labTestsMap[orderId]}
              order={ordersMap[orderId]}
              refetchChargeSheet={refetchChargeSheet}
              vendorId={vendorId}
              onCancelOrder={() => onCancelOrder(orderId)}
              onEmailResult={() => onEmailResult(ordersMap[orderId], vendorId)}
              onFinalizeOrder={() => onFinalizeOrder(ordersMap[orderId] || {})}
              onPrintLabel={() => onPrintLabel(ordersMap[orderId])}
              onPrintOrPreview={(isResult, shouldPrint) =>
                onPrintOrPreview(
                  isResult,
                  shouldPrint,
                  ordersMap[orderId],
                  vendorId,
                )
              }
            />
          </React.Fragment>
        ))}
      {readyForOrder.length > 0 && (
        <LabOrderSection
          readyForOrder
          hasIntegration={hasIntegration}
          invoiceId={invoiceId}
          labName={name}
          labTests={readyForOrder}
          refetchChargeSheet={refetchChargeSheet}
          showDevices={showDevices}
          vendorId={vendorId}
          onAddLab={
            onAddLab && ((soapId) => onAddLab(LabTestState.SELECTED, soapId))
          }
          onPlaceLabOrder={(deviceSerialNumber) =>
            onPlaceLabOrder(readyForOrder, deviceSerialNumber)
          }
          onPrintLabel={onPrintLabel}
        />
      )}
      {notReadyForOrder.length > 0 && (
        <LabOrderSection
          notReadyForOrder
          hasIntegration={hasIntegration}
          invoiceId={invoiceId}
          labName={name}
          labTests={notReadyForOrder}
          refetchChargeSheet={refetchChargeSheet}
          vendorId={vendorId}
          onAddLab={
            onAddLab &&
            ((soapId) => onAddLab(LabTestState.WAITING_ON_SAMPLE, soapId))
          }
          onPrintLabel={onPrintLabel}
        />
      )}
    </Grid>
  )
}

export default LabSection
