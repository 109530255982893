import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme } from '@pbt/pbt-ui-components'

import { ColorVariant } from '~/constants/colors'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    warningColor: { color: theme.colors.important },
    warningBackgroundColor: {
      backgroundColor: theme.colors.actionNeededBackground,
    },
    warningHighBackgroundColor: { backgroundColor: theme.colors.important },

    importantColor: { color: theme.colors.important },
    importantBackgroundColor: {
      backgroundColor: theme.colors.actionNeededBackground,
    },
    importantHighBackgroundColor: { backgroundColor: theme.colors.important },

    disabledColor: { color: theme.colors.disabledLabelText },
    disabledBackgroundColor: { backgroundColor: theme.colors.disabledLabel },
    disabledHighBackgroundColor: {
      backgroundColor: theme.colors.disabledLabelText,
    },

    completedColor: { color: theme.colors.completedTextColor },
    completedBackgroundColor: { backgroundColor: theme.colors.success },
    completedHighBackgroundColor: {
      backgroundColor: theme.colors.completedTextColor,
    },

    successColor: { color: theme.colors.success },
    successBackgroundColor: { backgroundColor: theme.colors.successBackground },
    successHighBackgroundColor: { backgroundColor: theme.colors.success },

    errorColor: { color: theme.colors.profileText },
    errorBackgroundColor: { backgroundColor: theme.colors.errorColor },
    errorHighBackgroundColor: { backgroundColor: theme.colors.profileText },

    normalColor: {},
    normalBackgroundColor: {},
    normalHighBackgroundColor: {},
  }),
  { name: 'useStatusClasses' },
)

const useStatusClasses = () => {
  const classes = useStyles()

  const getClassesByStatus = (color: ColorVariant) => ({
    active: Boolean(classes[`${color}Color`]),
    color: classes[`${color}Color`],
    backgroundColor: classes[`${color}BackgroundColor`],
    highBackgroundColor: classes[`${color}HighBackgroundColor`],
  })

  return { getClassesByStatus }
}

export default useStatusClasses
