import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import { ProblemType } from '~/constants/problems'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    typeContainer: {
      flex: 1,
      alignItems: 'center',
    },
    type: {
      display: 'flex',
      width: '14px',
      height: '14px',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.common.white,
      borderRadius: '2px',
    },
    typeDiagnosis: {
      backgroundColor: theme.palette.warning.main,
    },
    typeFinding: {
      backgroundColor: theme.palette.grey[600],
    },
  }),
  { name: 'ProblemsFindingOrDiagnosisIcon' },
)

interface ProblemsFindingOrDiagnosisProps {
  type: ProblemType
}

const ProblemsFindingOrDiagnosisIcon = ({
  type,
}: ProblemsFindingOrDiagnosisProps) => {
  const classes = useStyles({ type })
  const { t } = useTranslation('Soap')

  return (
    <Grid container className={classes.typeContainer} p={0.5}>
      <Tooltip
        placement="top"
        title={
          type === ProblemType.DIAGNOSIS
            ? t('Soap:PROBLEMS.DIAGNOSIS')
            : t('Soap:PROBLEMS.FINDING')
        }
      >
        <Text
          className={classNames(classes.type, {
            [classes.typeDiagnosis]: type === ProblemType.DIAGNOSIS,
            [classes.typeFinding]: type === ProblemType.FINDING,
          })}
          variant="body4"
        >
          {type}
        </Text>
      </Tooltip>
    </Grid>
  )
}

export default ProblemsFindingOrDiagnosisIcon
