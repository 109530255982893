import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme } from '@pbt/pbt-ui-components'
import { Info as InfoIcon } from '@pbt/pbt-ui-components/src/icons'

import SnackNotificationType from '~/constants/SnackNotificationType'
import { getErrorsList, removeAllErrors } from '~/store/duck/errors'
import { isNetworkError } from '~/utils/errors'

import { SnackNotificationProps } from '../notifications/SnackNotification'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    icon: {
      color: theme.colors.alertError,
      backgroundColor: theme.colors.tableBackground,
      width: '1.25em',
      height: '1.25em',
    },
  }),
  { name: 'useErrorNotificationWatcher' },
)

const ERROR_NOTIFIER_KEY = 'ERROR_NOTIFICATION'

const useErrorNotificationWatcher = (): [
  showNotification: boolean,
  setShowNotification: (show: boolean) => void,
  notificationProps: Partial<SnackNotificationProps> & {
    type: SnackNotificationType
  },
] => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Watcher'])

  const errors = useSelector(getErrorsList)

  const [showNotification, setShowNotification] = useState(false)

  const networkError = errors.find(({ data }) => isNetworkError(data?.message))

  const onClose = () => {
    dispatch(removeAllErrors())
  }

  useEffect(() => {
    if (errors.length > 0 && !showNotification) {
      setShowNotification(true)
    }
  }, [errors.length])

  return [
    showNotification,
    setShowNotification,
    {
      Icon: () => <InfoIcon className={classes.icon} />,
      id: ERROR_NOTIFIER_KEY,
      title: t('Watcher:WE_ARE_SORRY_WE_ARE_UNABLE_TO_CONNECT_TO_INTERNET'),
      message: t('Watcher:PLEASE_TRY_AGAIN_OR_REFRESH_YOUR_PAGE'),
      type: networkError
        ? SnackNotificationType.NETWORK_ERROR
        : SnackNotificationType.ERROR,
      actionTitle: t('Common:CLOSE_ACTION'),
      onClose,
    },
  ]
}

export default useErrorNotificationWatcher
