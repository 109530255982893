import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, PuiDialog, PuiTheme } from '@pbt/pbt-ui-components'

import ClientDetailsFooter from './ClientDetailsFooter'
import CreateClient, { CreateClientHandle } from './CreateClient'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 814,
      maxWidth: 814,
      [theme.breakpoints.down('md')]: {
        width: 496,
        maxWidth: 496,
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    dialogContentRoot: {
      padding: theme.spacing(2, 4),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5, 3),
        backgroundColor: theme.colors.contentBackground,
      },
    },
  }),
  { name: 'ClientDialog' },
)

interface ClientDialogProps extends BasePuiDialogProps {}

const ClientDialog = ({ open, onClose }: ClientDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const createClientRef = useRef<CreateClientHandle>(null)

  const continueToDetails = () => {
    if (createClientRef.current?.validate()) {
      createClientRef.current?.proceed()
    }
  }

  const addAppointment = () => {
    if (createClientRef.current?.validate()) {
      createClientRef.current?.proceed(true)
    }
  }

  return (
    <PuiDialog
      actions={
        <ClientDetailsFooter
          addAppointment={addAppointment}
          continueToDetails={continueToDetails}
        />
      }
      aria-labelledby="client-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={t('Common:NEW_CLIENT')}
      onClose={onClose}
    >
      <CreateClient ref={createClientRef} onOk={onClose} />
    </PuiDialog>
  )
}

export default ClientDialog
