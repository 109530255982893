import * as R from 'ramda'
import { all, call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { Document, DocumentToSend } from '~/types'

import {
  createDocument,
  createDocumentFailure,
  createDocumentSuccess,
  deleteDocument,
  deleteDocumentFailure,
  deleteDocumentSuccess,
  editDocument,
  editDocumentFailure,
  editDocumentSuccess,
  fetchDocument,
  fetchDocumentFailure,
  fetchDocuments,
  fetchDocumentsByType,
  fetchDocumentsByTypeFailure,
  fetchDocumentsByTypeSuccess,
  fetchDocumentsFailure,
  fetchDocumentsList,
  fetchDocumentsListFailure,
  fetchDocumentsListSuccess,
  fetchDocumentsSuccess,
  fetchDocumentSuccess,
  fetchMoreItemsForDocumentsList,
  fetchMoreItemsForDocumentsListFailure,
  fetchMoreItemsForDocumentsListSuccess,
  fetchResolvedDocumentBody,
  fetchResolvedDocumentBodyFailure,
  fetchResolvedDocumentBodySuccess,
  fetchResolvedDocuments,
  fetchResolvedDocumentsFailure,
  fetchResolvedDocumentsSuccess,
  generateDocumentInstances,
  generateDocumentInstancesFailure,
  generateDocumentInstancesSuccess,
  generateFileForDocumentTemplate,
  generateFileForDocumentTemplateFailure,
  generateFileForDocumentTemplateSuccess,
} from '../actions/documents'
import {
  CREATE_DOCUMENT,
  CREATE_DOCUMENT_WITH_TEMPLATE,
  DELETE_DOCUMENT,
  EDIT_DOCUMENT,
  EDIT_DOCUMENT_WITH_TEMPLATE,
  FETCH_DOCUMENT,
  FETCH_DOCUMENTS,
  FETCH_DOCUMENTS_BY_TYPE,
  FETCH_DOCUMENTS_LIST,
  FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST,
  FETCH_RESOLVED_DOCUMENT_BODY,
  FETCH_RESOLVED_DOCUMENTS,
  GENERATE_DOCUMENT_INSTANCES,
  GENERATE_FILE_FOR_DOCUMENT_TEMPLATE,
} from '../actions/types/documents'
import { finishLoading, startLoading } from '../duck/progress'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

const createDocumentToSend = (document: Document): DocumentToSend => ({
  id: document.id,
  file: document.template?.file,
  document: new Blob(
    [
      JSON.stringify({
        ...document,
        template: R.omit(['file'], document.template),
      }),
    ],
    {
      type: 'application/json',
    },
  ),
})

export function* fetchDocumentsListSaga({
  from,
  to,
  query,
}: ReturnType<typeof fetchDocumentsList>) {
  try {
    yield put(startLoading('documents'))
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchDocumentsList, from, to, query)
    yield call(updateEntities, entities)
    yield put(fetchDocumentsListSuccess(list, totalCount))
    yield put(finishLoading('documents'))
  } catch (error) {
    yield put(fetchDocumentsListFailure(error as ApiError))
    yield put(finishLoading('documents'))
  }
}

export function* fetchMoreItemsForDocumentsListSaga({
  from,
  to,
  query,
}: ReturnType<typeof fetchMoreItemsForDocumentsList>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchDocumentsList, from, to, query)
    yield call(updateEntities, entities)
    yield put(fetchMoreItemsForDocumentsListSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreItemsForDocumentsListFailure(error as ApiError))
  }
}

export function* fetchDocumentSaga({ id }: ReturnType<typeof fetchDocument>) {
  try {
    const { entities } = yield* requestAPI(API.fetchDocument, id)
    yield call(updateEntities, entities)
    yield put(fetchDocumentSuccess())
  } catch (error) {
    yield put(fetchDocumentFailure(error as ApiError))
  }
}

export function* fetchDocumentsSaga({
  ids,
}: ReturnType<typeof fetchDocuments>) {
  try {
    const { entities } = yield* requestAPI(API.fetchDocuments, ids)
    yield call(updateEntities, entities)
    yield put(fetchDocumentsSuccess())
  } catch (error) {
    yield put(fetchDocumentsFailure(error as ApiError))
  }
}

export function* createDocumentSaga({
  document,
}: ReturnType<typeof createDocument>) {
  try {
    const { result, entities } = yield* requestAPI(API.createDocument, document)
    yield call(updateEntities, entities)
    yield put(fetchDocumentsList())
    yield put(createDocumentSuccess(result))
  } catch (error) {
    yield put(createDocumentFailure(error as ApiError))
  }
}

export function* createDocumentWithTemplateSaga({
  document,
}: ReturnType<typeof createDocument>) {
  try {
    const documentToSend = createDocumentToSend(document)
    const { result, entities } = yield* requestAPI(
      API.createDocumentWithTemplate,
      documentToSend,
    )
    yield call(updateEntities, entities)
    yield put(fetchDocumentsList())
    yield put(createDocumentSuccess(result))
  } catch (error) {
    yield put(createDocumentFailure(error as ApiError))
  }
}

export function* editDocumentSaga({
  document,
}: ReturnType<typeof editDocument>) {
  try {
    const { entities } = yield* requestAPI(API.editDocument, document)
    yield call(updateEntities, entities)
    yield put(editDocumentSuccess())
  } catch (error) {
    yield put(editDocumentFailure(error as ApiError))
  }
}

export function* editDocumentWithTemplateSaga({
  document,
}: ReturnType<typeof editDocument>) {
  try {
    const documentToSend = createDocumentToSend(document)
    const { entities } = yield* requestAPI(
      API.editDocumentWithTemplate,
      documentToSend,
    )
    yield call(updateEntities, entities)
    yield put(editDocumentSuccess())
  } catch (error) {
    yield put(editDocumentFailure(error as ApiError))
  }
}

export function* deleteDocumentSaga({
  documentId,
}: ReturnType<typeof deleteDocument>) {
  try {
    yield* requestAPI(API.deleteDocument, documentId)
    yield put(deleteDocumentSuccess(documentId))
  } catch (error) {
    yield put(deleteDocumentFailure(error as ApiError))
  }
}

export function* fetchResolvedDocumentBodySaga({
  body,
}: ReturnType<typeof fetchResolvedDocumentBody>) {
  try {
    const result = yield* requestAPI(API.fetchResolvedDocumentBody, body)
    yield put(fetchResolvedDocumentBodySuccess(result.body))
  } catch (error) {
    yield put(fetchResolvedDocumentBodyFailure(error as ApiError))
  }
}

export function* fetchDocumentsByTypeSaga({
  documentType,
  patientId,
  eventId,
  skipFileTemplates,
}: ReturnType<typeof fetchDocumentsByType>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.fetchDocumentsByType,
      documentType,
      patientId,
      eventId,
      skipFileTemplates,
    )
    yield call(updateEntities, entities)
    yield put(fetchDocumentsByTypeSuccess(result))
  } catch (error) {
    yield put(fetchDocumentsByTypeFailure(error as ApiError))
  }
}

export function* fetchResolvedDocumentsSaga({
  documentIds,
  context,
  convertToTextDocument,
}: ReturnType<typeof fetchResolvedDocuments>) {
  try {
    const { entities } = yield* requestAPI(
      API.fetchResolvedDocuments,
      documentIds,
      context,
      convertToTextDocument,
    )
    yield call(updateEntities, entities)
    yield put(fetchResolvedDocumentsSuccess())
  } catch (error) {
    yield put(fetchResolvedDocumentsFailure(error as ApiError))
  }
}

export function* generateFileForDocumentTemplateSaga({
  documentId,
}: ReturnType<typeof generateFileForDocumentTemplate>) {
  try {
    const generatedFile = yield* requestAPI(
      API.generateDocumentTemplateFile,
      documentId,
    )
    yield put(generateFileForDocumentTemplateSuccess(generatedFile))
  } catch (error) {
    yield put(generateFileForDocumentTemplateFailure(error as ApiError))
  }
}

export function* generateDocumentInstancesSaga({
  documentIds,
  context,
}: ReturnType<typeof generateDocumentInstances>) {
  try {
    const documentInstances = yield* requestAPI(
      API.generateDocumentInstances,
      documentIds,
      context,
    )
    yield put(generateDocumentInstancesSuccess(documentInstances))
  } catch (error) {
    yield put(generateDocumentInstancesFailure(error as ApiError))
  }
}

function* watchFetchDocumentsList() {
  yield takeLeading(FETCH_DOCUMENTS_LIST, fetchDocumentsListSaga)
}

function* watchFetchMoreItemsForDocumentsList() {
  yield takeLatest(
    FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST,
    fetchMoreItemsForDocumentsListSaga,
  )
}

function* watchFetchDocument() {
  yield takeLeading(FETCH_DOCUMENT, fetchDocumentSaga)
}

function* watchFetchDocuments() {
  yield takeLeading(FETCH_DOCUMENTS, fetchDocumentsSaga)
}

function* watchCreateDocument() {
  yield takeLeading(CREATE_DOCUMENT, createDocumentSaga)
}

function* watchCreateDocumentWithTemplate() {
  yield takeLeading(
    CREATE_DOCUMENT_WITH_TEMPLATE,
    createDocumentWithTemplateSaga,
  )
}

function* watchEditDocument() {
  yield takeLeading(EDIT_DOCUMENT, editDocumentSaga)
}

function* watchEditDocumentWithTemplate() {
  yield takeLeading(EDIT_DOCUMENT_WITH_TEMPLATE, editDocumentWithTemplateSaga)
}

function* watchDeleteDocument() {
  yield takeLeading(DELETE_DOCUMENT, deleteDocumentSaga)
}

function* watchFetchResolvedDocumentBody() {
  yield takeLeading(FETCH_RESOLVED_DOCUMENT_BODY, fetchResolvedDocumentBodySaga)
}

function* watchFetchDocumentsByType() {
  yield takeLeading(FETCH_DOCUMENTS_BY_TYPE, fetchDocumentsByTypeSaga)
}

function* watchFetchResolvedDocuments() {
  yield takeLeading(FETCH_RESOLVED_DOCUMENTS, fetchResolvedDocumentsSaga)
}

function* watchGenerateDocumentTemplateFile() {
  yield takeLeading(
    GENERATE_FILE_FOR_DOCUMENT_TEMPLATE,
    generateFileForDocumentTemplateSaga,
  )
}

function* watchGenerateDocumentInstances() {
  yield takeLeading(GENERATE_DOCUMENT_INSTANCES, generateDocumentInstancesSaga)
}

export default function* documentsSaga() {
  yield all([
    watchFetchDocumentsList(),
    watchFetchMoreItemsForDocumentsList(),
    watchFetchDocument(),
    watchFetchDocuments(),
    watchCreateDocument(),
    watchCreateDocumentWithTemplate(),
    watchEditDocument(),
    watchEditDocumentWithTemplate(),
    watchDeleteDocument(),
    watchFetchResolvedDocumentBody(),
    watchFetchDocumentsByType(),
    watchFetchResolvedDocuments(),
    watchGenerateDocumentTemplateFile(),
    watchGenerateDocumentInstances(),
  ])
}
