import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  FileTemplate,
  IdObject,
  Nil,
  PuiDialog,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import Attachment, { AttachmentProps } from './Attachment'
import AttachmentPreview, { AttachmentPreviewProps } from './AttachmentPreview'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      overflowY: 'visible',
      width: 976,
      maxWidth: 976,
      [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 64px)',
      },
    },
  }),
  { name: 'AttachmentDialog' },
)

const Steps = {
  INITIAL: 'INITIAL',
  PREVIEW: 'PREVIEW',
}

export interface AttachmentDialogProps extends BasePuiDialogProps {
  ListWithFiltersProps?: AttachmentProps['ListWithFiltersProps']
  PreviewProps?: Partial<AttachmentPreviewProps>
  eventId?: string | Nil
  isLoading?: boolean
  onListProceed: (items: IdObject[]) => void
  onPreviewProceed: AttachmentPreviewProps['onProceed']
  patientId: string | Nil
  title?: string
}

const AttachmentDialog = ({
  patientId,
  eventId,
  open,
  title,
  ListWithFiltersProps,
  PreviewProps,
  onClose,
  onListProceed,
  isLoading,
  onPreviewProceed,
}: AttachmentDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const attachmentDialogTitle = title || t('Common:ADD_ATTACHMENT')

  const [step, setStep] = useState(Steps.INITIAL)
  const [file, setFile] = useState<FileTemplate>()

  useEffect(() => {
    if (!open) {
      setStep(Steps.INITIAL)
      setFile(undefined)
    }
  }, [open])

  const onAttachmentFileSelected = (newFile: FileTemplate) => {
    setFile(newFile)
    setStep(Steps.PREVIEW)
  }

  return (
    <PuiDialog
      aria-labelledby="attachment-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      title={attachmentDialogTitle}
      onClose={onClose}
    >
      <Grid container direction="column">
        {step === Steps.INITIAL && (
          <Attachment
            ListWithFiltersProps={ListWithFiltersProps}
            eventId={eventId}
            patientId={patientId}
            onFileSelected={onAttachmentFileSelected}
            onProceed={onListProceed}
          />
        )}
        {step === Steps.PREVIEW && file && (
          <AttachmentPreview
            file={file}
            isLoading={isLoading}
            onFileSelected={setFile}
            onProceed={onPreviewProceed}
            {...(PreviewProps || {})}
          />
        )}
      </Grid>
    </PuiDialog>
  )
}

export default AttachmentDialog
