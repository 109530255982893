import React from 'react'
import { useSelector } from 'react-redux'
import { TreeItem } from '@mui/lab'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme } from '@pbt/pbt-ui-components'

import { getProblemById } from '~/store/reducers/problems'

const useStyles = makeStyles((theme: PuiTheme) => ({
  root: {
    position: 'relative',
    '&:before': {
      pointerEvents: 'none',
      content: '""',
      position: 'absolute',
      width: 17,
      left: 5,
      top: 14,
      borderBottom: ({ isRootNode }: UseStylesProps) =>
        isRootNode ? 'none' : `1.5px solid ${theme.palette.grey[300]}`,
    },
  },
  group: {
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 15,
      bottom: 16,
      left: 21,
      borderRight: `1.5px solid ${theme.palette.grey[300]}`,
    },
  },
  content: {
    height: '30px',
    '&.Mui-selected': {
      backgroundColor: theme.palette.grey[300],
      '&:after': {
        content: '">"',
      },
    },
    '&.Mui-selected.Mui-focused': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:hover': {
      '&:after': {
        content: '">"',
      },
    },
  },
}))

interface UseStylesProps {
  isRootNode: boolean
}

interface TreeBranchProps {
  isRootNode?: boolean
  problemId: string
}

const TreeBranch = ({ problemId, isRootNode = false }: TreeBranchProps) => {
  const problem = useSelector(getProblemById(problemId))
  const classes = useStyles({ isRootNode })
  const isLastChild = problem?.type
  const isRemoved = problem?.removed
  const isHidden = problem?.isHidden

  if (!problem || isLastChild || isRemoved || isHidden) {
    return null
  }

  return (
    <TreeItem
      classes={classes}
      label={problem.nameTranslation}
      nodeId={problem.key}
    >
      {problem.children.map((id: string) => (
        <TreeBranch key={id} problemId={id} />
      ))}
    </TreeItem>
  )
}

export default TreeBranch
