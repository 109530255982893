import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { Nil, PuiTheme, Text, useFields, Utils } from '@pbt/pbt-ui-components'
import { OrderedWeekConstants } from '@pbt/pbt-ui-components/src/localization'

import WeekdaysButtonGroup from '~/components/common/buttons/WeekdaysButtonGroup'
import QuantityInput from '~/components/common/inputs/QuantityInput'
import { RepeatMode } from '~/constants/taskConstants'
import { getTaskTimeUnits } from '~/store/reducers/constants'
import {
  DataHandleWithUnsavedChanges,
  Task,
  TaskRecurrence,
  TaskRecurrencePeriod,
} from '~/types'
import { isFieldValuesChanged } from '~/utils'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import DayTimeRecurrenceGroup, {
  DayTimeRecurrenceGroupHandle,
} from './day-time-recurrence/DayTimeRecurrenceGroup'
import TaskTimeUnitsSelect from './TaskTimeUnitsSelect'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    container: {
      width: 'auto',
    },
    quantityInput: {
      width: 'auto',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    weekdaysButtonGroup: {
      marginTop: theme.spacing(1),
    },
  }),
  { name: 'TaskCustomRecurrencePeriodSettings' },
)

export interface TaskCustomRecurrencePeriodSettingsProps {
  disabled?: boolean
  initialRecurrence?: TaskRecurrence
  onRecurrencePeriodChange?: (unit: string) => void
  onRefChange?: () => void
  repeatMode: string
  task: Task | Nil
}

export interface TaskCustomRecurrencePeriodSettingsHandle
  extends DataHandleWithUnsavedChanges<TaskRecurrencePeriod> {}

const TaskCustomRecurrencePeriodSettings = forwardRef(
  function TaskCustomRecurrencePeriodSettings(
    {
      initialRecurrence,
      onRecurrencePeriodChange,
      onRefChange,
      repeatMode,
      task,
      disabled,
    }: TaskCustomRecurrencePeriodSettingsProps,
    ref: ForwardedRef<TaskCustomRecurrencePeriodSettingsHandle>,
  ) {
    const classes = useStyles()
    const { t } = useTranslation('Tasks')

    const TaskTimeUnits = useSelector(getTaskTimeUnits)

    const isContextItem = useIsCurrentContextItem(task)

    const HourTimeUnit = Utils.findConstantIdByName('Hour', TaskTimeUnits)
    const DayTimeUnit = Utils.findConstantIdByName('Day', TaskTimeUnits)
    const WeekTimeUnit = Utils.findConstantIdByName('Week', TaskTimeUnits)

    const dayTimeRecurrenceGroupRef = useRef<DayTimeRecurrenceGroupHandle>(null)

    const { fields, validate, reset } = useFields(
      [
        {
          name: 'recurrencePeriodAmount',
          initialValue:
            task?.recurrencePeriod?.amount ||
            initialRecurrence?.recurrencePeriod?.amount ||
            1,
        },
        {
          name: 'recurrencePeriodUnits',
          initialValue:
            task?.recurrencePeriod?.unit ||
            initialRecurrence?.recurrencePeriod?.unit ||
            HourTimeUnit,
        },
        { name: 'days', initialValue: task?.daysOfWeek || [] },
      ],
      false,
    )

    const { recurrencePeriodAmount, recurrencePeriodUnits, days } = fields

    useEffectExceptOnMount(() => {
      onRefChange?.()
    }, [recurrencePeriodAmount, recurrencePeriodUnits, days])

    const isRepeatMode = repeatMode === RepeatMode.REPEAT
    const isDayRecurrence = recurrencePeriodUnits.value === DayTimeUnit
    const isWeekRecurrence = recurrencePeriodUnits.value === WeekTimeUnit

    useEffect(() => {
      reset()
    }, [task])

    useEffect(() => {
      if (recurrencePeriodUnits.value && onRecurrencePeriodChange) {
        onRecurrencePeriodChange(recurrencePeriodUnits.value)
      }
    }, [recurrencePeriodUnits.value])

    useImperativeHandle(ref, () => ({
      validate: () => {
        const fieldsValid = validate()
        const dayRecurrenceValid =
          !isDayRecurrence ||
          (dayTimeRecurrenceGroupRef.current?.validate() ?? true)

        return fieldsValid && dayRecurrenceValid
      },
      get: () => ({
        recurrencePeriod: isRepeatMode
          ? {
              amount: recurrencePeriodAmount.value,
              unit: recurrencePeriodUnits.value,
            }
          : null,
        daysOfWeek:
          isRepeatMode && isWeekRecurrence
            ? days.value.length > 0
              ? days.value
              : R.values(OrderedWeekConstants)
            : null,
        timesOfDay:
          isRepeatMode && isDayRecurrence
            ? dayTimeRecurrenceGroupRef.current?.get()
            : null,
      }),
      hasUnsavedChanges: () => isFieldValuesChanged(fields),
    }))

    if (!isRepeatMode) {
      return null
    }

    return (
      <>
        <Grid
          container
          item
          alignItems="center"
          className={classes.container}
          wrap="nowrap"
        >
          <Text>{t('Tasks:FREQUENCY.EVERY')}</Text>
          <QuantityInput
            className={classes.quantityInput}
            disabled={!isContextItem || disabled}
            field={recurrencePeriodAmount}
            max={999}
            min={1}
            showControls={!(!isContextItem || disabled)}
          />
          <TaskTimeUnitsSelect
            amount={recurrencePeriodAmount.value}
            disabled={!isContextItem || disabled}
            field={recurrencePeriodUnits}
          />
        </Grid>
        {isDayRecurrence && (
          <DayTimeRecurrenceGroup
            disabled={!isContextItem || disabled}
            group={task?.timesOfDay}
            ref={dayTimeRecurrenceGroupRef}
            onRefChange={onRefChange}
          />
        )}
        {isWeekRecurrence && (
          <WeekdaysButtonGroup
            className={classes.weekdaysButtonGroup}
            disabled={!isContextItem || disabled}
            field={days}
          />
        )}
      </>
    )
  },
)

export default TaskCustomRecurrencePeriodSettings
