import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AddButton,
  AlertIconType,
  BasePuiDialogProps,
  ButtonWithLoader,
  CircularProgressOverlay,
  Field,
  FileTemplate,
  Nil,
  PermissionArea,
  PuiDialog,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'
import { Upload as UploadIcon } from '@pbt/pbt-ui-components/src/icons'

import EnumSelect from '~/components/common/inputs/EnumSelect'
import SingleFileSelector from '~/components/common/inputs/SingleFileSelector'
import TinyActionButton from '~/components/common/inputs/TinyActionButton'
import Spacer from '~/components/common/Spacer'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  clearShipmentImportItems,
  fetchPreliminaryShipment,
  getCurrentSessionId,
  getIsImportInProgress,
  getIsLoadingShipment,
  getPreliminaryShipment,
  getTotalCount,
  getUnmatchedShipmentItems,
  importShipment,
} from '~/store/duck/shipmentImport'
import {
  createShipment,
  editShipment,
  getShipmentsIsLoading,
  getShipmentsIsUpdating,
} from '~/store/duck/shipments'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getFeatureToggle,
  getInventoryShipmentItemStatus,
} from '~/store/reducers/constants'
import {
  Shipment,
  ShipmentImportItem,
  ShipmentItem,
  ShipmentTaxes as ShipmentTaxesType,
} from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import useGetBulkShipmentItemStatusIdByShipment from '~/utils/useGetBulkShipmentItemStatusIdByShipment'

import ShipmentComponent, { ShipmentHandle } from './Shipment'
import ShipmentImportTable from './shipment-import/ShipmentImportTable'
import {
  addItems,
  cloneItem,
  deleteItem,
  DraftShipmentItem,
  getShipmentPriceInfo,
  inventoryRecordToShipmentItem,
  ShipmentItemsContext,
  toggleItem,
  updateItem,
} from './shipment-item-utils'
import ShipmentDialogPriceInfo from './ShipmentDialogPriceInfo'
import { ShipmentItemHandle } from './ShipmentItem'
import ShipmentItemAccordion from './ShipmentItemAccordion'
import ShipmentTaxes, { ShipmentTaxesHandle } from './ShipmentTaxes'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    content: {
      position: 'relative',
      minHeight: 400,
    },
    header: {
      backgroundColor: theme.colors.dialogHeaderBackground,
    },
    button: {
      minWidth: 100,
    },
    buttonPlaceHolder: {
      minWidth: 168,
    },
    shipmentTaxes: {
      marginTop: theme.spacing(1),
      width: 200,
    },
  }),
  { name: 'ShipmentDialog' },
)

export type OpenMatchDialogOptions = {
  onProceed: (shipment: ShipmentImportItem) => void
  title: string
}
export interface ShipmentDialogProps extends BasePuiDialogProps {
  onSave?: () => void
  shipment: Shipment | Nil
}

const ShipmentDialog = ({
  open,
  onClose,
  shipment,
  onSave,
}: ShipmentDialogProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Dialogs'])
  const shipmentRef = useRef<ShipmentHandle>(null)
  const shipmentTaxesRef = useRef<ShipmentTaxesHandle>(null)

  const InventoryShipmentItemStatus = useSelector(
    getInventoryShipmentItemStatus,
  )
  const permissions = useSelector(getCRUDByArea(PermissionArea.SHIPMENTS))
  const isLoading = useSelector(getShipmentsIsLoading)
  const importSessionId = useSelector(getCurrentSessionId)
  const isImportInProgress = useSelector(getIsImportInProgress)
  const isLoadingShipmentImport = useSelector(getIsLoadingShipment)
  const preliminaryShipment = useSelector(getPreliminaryShipment)
  const totalImportLines = useSelector(getTotalCount)
  const unmatchedItems = useSelector(getUnmatchedShipmentItems)
  const shipmentsIsUpdating = useSelector(getShipmentsIsUpdating)
  const isOptimizeInventoryShipmentsLoadingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.OPTIMIZE_INVENTORY_SHIPMENTS_LOADING),
  )

  const getBulkShipmentItemStatusId = useGetBulkShipmentItemStatusIdByShipment()

  const [openAddShipmentItemDialog] = useDialog(DialogNames.ADD_SHIPMENT_ITEM)
  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const [draftShipmentItems, setDraftShipmentItems] = useState<
    DraftShipmentItem[]
  >([])
  const [draftShipmentTaxes, setDraftShipmentTaxes] =
    useState<ShipmentTaxesType>()
  const [acceptedImportSession, setAcceptedImportSession] = useState<
    string | Nil
  >()

  const dirtyDraftShipmentTotalTax = Boolean(draftShipmentTaxes?.customTotalTax)
  const dirtyDraftShipmentItemsTaxes = draftShipmentItems.some((item) =>
    Number(item.shipmentItem?.tax),
  )

  const closeAfterSave = useCloseAfterCreation(() => {
    if (onClose) {
      onClose()
    }
    if (onSave) {
      onSave()
    }
  }, getShipmentsIsLoading)

  const shipmentItemsRefs = useRef<Record<string, ShipmentItemHandle>>({})

  const shipmentItemsContext: ShipmentItemsContext = {
    drafts: draftShipmentItems,
    setDrafts: setDraftShipmentItems,
    refs: shipmentItemsRefs.current,
  }

  const inventoryRecordToShipmentItemWithShipmentStatus = (
    item: Parameters<typeof inventoryRecordToShipmentItem>[0],
  ) =>
    inventoryRecordToShipmentItem({
      ...item,
      statusId: getBulkShipmentItemStatusId(
        shipmentRef.current?.get().statusId,
      ),
    })

  const handleToggleShipmentItem = toggleItem(shipmentItemsContext)
  const handleUpdateShipmentItem = updateItem(shipmentItemsContext)
  const handleAddItems = addItems(shipmentItemsContext)
  const handleAddInventoryItems = R.pipe(
    R.map(inventoryRecordToShipmentItemWithShipmentStatus),
    handleAddItems,
  )
  const handleDeleteShipmentItem = deleteItem(shipmentItemsContext)
  const handleCloneShipmentItem = cloneItem(shipmentItemsContext)

  useEffect(() => {
    if (shipment?.items) {
      handleAddItems(shipment?.items)
    }
  }, [shipment?.items])

  useEffect(() => {
    if (!preliminaryShipment || R.isEmpty(preliminaryShipment)) {
      return
    }
    const importAcceptedItems = preliminaryShipment?.items || []
    const proceedWithMatch = () => {
      handleAddItems(importAcceptedItems)
      closeAlert()
      setAcceptedImportSession(importSessionId)
      dispatch(clearShipmentImportItems())
    }

    if (importAcceptedItems.length !== totalImportLines) {
      openAlert({
        iconType: AlertIconType.WARN,
        message: (
          <>
            <Spacer />
            <Text>{t('Dialogs:SHIPMENT_DIALOG.DESCRIPTION')}</Text>
            <Spacer spacing={2} />
            {R.take(3, unmatchedItems).map((item) => (
              <Text key={item.id}>{item.name}</Text>
            ))}
          </>
        ),
        cancelButtonText: t('Common:MATCH_ITEMS_ACTION'),
        okButtonText: t('Common:OK_CONTINUE'),
        onCancel: closeAlert,
        onOk: proceedWithMatch,
      })
    } else {
      proceedWithMatch()
    }
  }, [preliminaryShipment])

  useEffect(() => {
    dispatch(clearShipmentImportItems())
    return () => {
      dispatch(clearShipmentImportItems())
    }
  }, [])

  const openMatchDialog = (options: OpenMatchDialogOptions) =>
    openAddShipmentItemDialog({
      ...options,
      singleItemOnly: true,
    })

  const handleAcceptImportSession = () => {
    if (importSessionId) {
      dispatch(fetchPreliminaryShipment(importSessionId))
    }
  }

  const getCurrentShipmentTaxes = () => shipmentTaxesRef.current?.get()

  const validateShipmentItemsChanges = () =>
    Object.values(shipmentItemsRefs.current)
      .filter(Boolean)
      .every((ref) => ref.validate())

  const validateShipmentChanges = () =>
    shipmentRef.current?.validate() && shipmentTaxesRef.current?.validate()

  const getCurrentShipmentItems = () =>
    Object.values(shipmentItemsRefs.current)
      .filter(Boolean)
      .map((ref) => ref.get())

  const handleSave = () => {
    if (validateShipmentChanges() && validateShipmentItemsChanges()) {
      const items = getCurrentShipmentItems()
      const shipmentTaxes = getCurrentShipmentTaxes()

      const { total } = getShipmentPriceInfo({ ...shipmentTaxes, items })

      const newShipment = {
        ...shipmentRef.current?.get(),
        ...shipmentTaxes,
        items,
        uploadSessionId: acceptedImportSession,
        costTotal: total,
      } as Shipment

      closeAfterSave()

      if (newShipment?.id) {
        dispatch(editShipment(newShipment, true))
      } else {
        dispatch(createShipment(newShipment))
      }
    }
  }

  const uploadVetCoveFile = (file: FileTemplate) => {
    dispatch(importShipment(file))
  }

  const propagateShipmentItemStatus = (
    item: DraftShipmentItem,
    statusId: string,
  ) => ({
    ...item,
    shipmentItem: item?.shipmentItem
      ? { ...item.shipmentItem, statusId }
      : null,
  })

  const handleShipmentItemStatusBulkChange = (statusId: string) => {
    setDraftShipmentItems(
      draftShipmentItems.map((item) =>
        propagateShipmentItemStatus(item, statusId),
      ),
    )
  }

  const handleShipmentStatusChange = (shipmentStatusId: string) => {
    handleShipmentItemStatusBulkChange(
      getBulkShipmentItemStatusId(shipmentStatusId),
    )
  }

  return (
    <PuiDialog
      confirmSaveOnClose
      fullWidth
      ConfirmCloseDialogProps={{
        okLabel: t('Common:SAVE_ACTION'),
        onOk: handleSave,
        notOkLabel: t('Common:LEAVE_ACTION'),
      }}
      actions={
        <Grid container alignItems="center" columnSpacing={2}>
          <Grid item>
            {importSessionId ? (
              <ButtonWithLoader
                className={classes.button}
                disabled={isLoadingShipmentImport || isImportInProgress}
                loading={isLoadingShipmentImport || isImportInProgress}
                type="submit"
                onClick={handleAcceptImportSession}
              >
                {t('Common:NEXT')}
              </ButtonWithLoader>
            ) : (
              <ButtonWithLoader
                className={classes.button}
                disabled={isLoading || isImportInProgress}
                loading={isLoading || isImportInProgress}
                type="submit"
                onClick={handleSave}
              >
                {t('Common:SAVE_ACTION')}
              </ButtonWithLoader>
            )}
          </Grid>
          <Grid item>
            <ShipmentDialogPriceInfo
              shipmentItems={getCurrentShipmentItems()}
              shipmentTaxes={getCurrentShipmentTaxes()}
            />
          </Grid>
        </Grid>
      }
      aria-labelledby="shipment-dialog"
      classes={{
        dialogTitle: classes.header,
      }}
      hasUnsavedChanges={() => Boolean(draftShipmentItems.length)}
      header={
        <ShipmentComponent
          wide
          withHeading
          ref={shipmentRef}
          shipment={shipment}
          onShipmentStatusChange={handleShipmentStatusChange}
        />
      }
      maxWidth="lg"
      open={open}
      scroll="paper"
      onClose={onClose}
    >
      <Grid
        container
        className={classes.content}
        direction="column"
        px={3}
        py={2}
      >
        {isOptimizeInventoryShipmentsLoadingEnabled ? (
          <CircularProgressOverlay
            open={isImportInProgress || shipmentsIsUpdating || isLoading}
            preloaderText={
              isImportInProgress
                ? t('Dialogs:SHIPMENT_DIALOG.PRELOADER_TEXT')
                : t('Common:LOADING')
            }
          />
        ) : (
          <CircularProgressOverlay
            open={isImportInProgress}
            preloaderText={t('Dialogs:SHIPMENT_DIALOG.PRELOADER_TEXT')}
          />
        )}
        <Grid container alignItems="center" mb={1} wrap="nowrap">
          <Grid item xs={5}>
            <Text strong variant="subheading2">
              {t('Common:ITEMS_RECEIVED')}
            </Text>
          </Grid>
          {!importSessionId && draftShipmentItems?.length > 0 ? (
            <>
              <Grid item xs={2}>
                <Text strong variant="lowAccent">
                  {t('Common:COST')}
                </Text>
              </Grid>
              <Grid item xs={2}>
                <Text strong variant="lowAccent">
                  {t('Common:RECEIVED')}
                </Text>
              </Grid>
              <Grid item xs={2}>
                <Text strong variant="lowAccent">
                  {t('Common:PACKAGING')}
                </Text>
              </Grid>
              <Grid item xs={2}>
                <EnumSelect
                  accent
                  renderEmpty
                  Constant={InventoryShipmentItemStatus}
                  field={
                    {
                      set: (event) =>
                        handleShipmentItemStatusBulkChange(
                          event?.target?.value,
                        ),
                      value: null,
                    } as Field
                  }
                  placeholder={t('Common:SET_ALL_STATUSES')}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={8} />
          )}
          <Grid item className={classes.buttonPlaceHolder} />
        </Grid>
        {importSessionId && (
          <ShipmentImportTable openMatchDialog={openMatchDialog} />
        )}
        {!importSessionId &&
          draftShipmentItems.map(({ shipmentItem, expanded, key }) => (
            <ShipmentItemAccordion
              defaultExpanded
              expanded={expanded}
              key={key}
              ref={(element: ShipmentItemHandle) => {
                shipmentItemsRefs.current[key] = element
              }}
              shipmentItem={shipmentItem as ShipmentItem}
              taxDisabled={
                dirtyDraftShipmentTotalTax && !dirtyDraftShipmentItemsTaxes
              }
              variation={shipmentItem?.inventoryVariation}
              onChange={() => handleUpdateShipmentItem(key)}
              onClone={(event: React.MouseEvent) => {
                event.stopPropagation()
                handleCloneShipmentItem(key)
              }}
              onDelete={(event: React.MouseEvent) => {
                event.stopPropagation()
                handleDeleteShipmentItem(key)
              }}
              onToggle={() => handleToggleShipmentItem(key)}
            />
          ))}

        {permissions.update && !importSessionId && (
          <Grid container item mt={1} xs={12}>
            <AddButton
              inline
              addText={t('Common:ADD_ITEM')}
              onAdd={() =>
                openAddShipmentItemDialog({
                  onProceed: handleAddInventoryItems,
                })
              }
            />
            <SingleFileSelector accept=".csv" onSelected={uploadVetCoveFile}>
              <TinyActionButton
                Icon={UploadIcon}
                label={t('Dialogs:SHIPMENT_DIALOG.UPLOAD_VETCOVE_COMPANY')}
              />
            </SingleFileSelector>
          </Grid>
        )}
        <ShipmentTaxes
          className={classes.shipmentTaxes}
          ref={shipmentTaxesRef}
          shipment={shipment}
          taxDisabled={
            dirtyDraftShipmentItemsTaxes && !dirtyDraftShipmentTotalTax
          }
          onChange={setDraftShipmentTaxes}
        />
      </Grid>
    </PuiDialog>
  )
}

export default ShipmentDialog
