import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Fab, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  BackButton,
  Constant,
  NumberUtils,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'
import { DefaultPrices } from '@pbt/pbt-ui-components/src/localization'

import { RhapsodyGoConfigurationLabels } from '~/constants/RhapsodyPassStates'
import { RhapsodyPayConfig } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    text: {
      lineHeight: '2.2rem',
    },
    labelText: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
      textAlign: 'left',
    },
    rhapsodyGoContainer: {
      boxShadow: theme.constants.blockShadow,
      borderRadius: 5,
    },
    button: {
      width: 240,
      height: 40,
      margin: theme.spacing(2, 0),
    },
  }),
  { name: 'RhapsodyGoConfiguration' },
)

interface RhapsodyGoConfigurationProps {
  onBack: () => void
  onProceed: () => void
  onRhapsodyGoProvidingChange: (value: string) => void
  payGoConfigList: Constant[]
  posPayConfig: RhapsodyPayConfig
}

const RhapsodyGoConfiguration = ({
  onBack,
  onProceed,
  onRhapsodyGoProvidingChange,
  posPayConfig,
  payGoConfigList,
}: RhapsodyGoConfigurationProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Businesses', 'Prices'])

  return (
    <Grid container direction="column" px={3}>
      <Grid container item direction="column">
        <Text align="left" className={classes.text} variant="body">
          <Trans
            components={{
              boldText: <Text inline strong component="span" variant="body2" />,
            }}
            i18nKey="Businesses:RHAPSODY_PAY.RHAPSODY_GO_USES_OUR_POWERFUL_PAYMENT"
          />
        </Text>
        <Grid mt={4}>
          <Text strong align="left" className={classes.text} variant="body">
            {t('Common:SIGN_UP_NOW')}!*
          </Text>
        </Grid>
      </Grid>
      <Grid
        container
        item
        className={classes.rhapsodyGoContainer}
        mb={8}
        mt={2}
      >
        <Grid container item direction="column" md={6} p={3}>
          <Text strong mb={2} variant="subheading2">
            {t('Prices:CURRENCY.ADD_RHAPSODY_GO_FOR', {
              price: NumberUtils.formatMoney(DefaultPrices.RHAPSODY_GO),
            })}
          </Text>
          <RadioGroup
            aria-label="rhapsody-go"
            name="rhapsody-go"
            value={posPayConfig.goOption}
            onChange={({ target: { value } }) =>
              onRhapsodyGoProvidingChange(value)
            }
          >
            {payGoConfigList.map(({ id, name }) => (
              <FormControlLabel
                classes={{
                  label: classNames(classes.text, classes.labelText),
                }}
                control={<Radio />}
                key={name}
                label={
                  name === RhapsodyGoConfigurationLabels.INCLUDED
                    ? t('Businesses:RHAPSODY_PAY.I_AGREE_LETS_DO_IT')
                    : t('Businesses:RHAPSODY_PAY.NO_THANKS')
                }
                value={id}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
      <Grid container item alignItems="center" justifyContent="space-between">
        <Grid item>
          <BackButton label={t('Common:BACK_ACTION')} onClick={onBack} />
        </Grid>
        <Fab
          className={classes.button}
          color="inherit"
          variant="extended"
          onClick={onProceed}
        >
          {t('Common:CONTINUE_ACTION')}
        </Fab>
      </Grid>
    </Grid>
  )
}

export default RhapsodyGoConfiguration
