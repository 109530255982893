import React from 'react'
import { Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PuiTheme } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    finalizeOrdersRowActionDivider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'FinalizeOrdersActionDivider' },
)

export const FinalizeOrdersActionDivider = () => {
  const classes = useStyles()
  return <Divider className={classes.finalizeOrdersRowActionDivider} />
}
