import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'
import { Hidden, Popover } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  Nil,
  PuiDialog,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import CoPetParent, { CoPetParentHandle } from './CoPetParent'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    infoIcon: {
      marginLeft: 6,
      padding: 4,
      fontSize: 32,
      position: 'absolute',
    },
    popover: {
      pointerEvents: 'none',
    },
    popoverText: {
      maxWidth: 310,
      padding: 10,
    },
    dialogContentRoot: {
      padding: theme.spacing(3),
    },
  }),
  { name: 'CoPetParentDialog' },
)

interface CoPetParentDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  coparentId?: string | Nil
}

const CoPetParentDialog = ({
  open,
  clientId,
  coparentId,
  onClose,
}: CoPetParentDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Clients', 'Dialogs'])

  const coPetParentRef = useRef<CoPetParentHandle>(null)

  const [titleAnchor, setTitleAnchor] = useState<Element | null>(null)

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: () => coPetParentRef.current && coPetParentRef.current.save(),
      }}
      aria-labelledby="co-pet-parent-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      hasUnsavedChanges={() =>
        Boolean(coPetParentRef.current?.getHasUnsavedChanges())
      }
      open={open}
      title={
        <>
          {coparentId
            ? t('Clients:COPET_PARENT.TITLE_EDIT_COPET')
            : t('Clients:COPET_PARENT.TITLE_NEW_COPET')}
          <Hidden mdDown>
            <InfoIcon
              aria-haspopup="true"
              aria-owns={titleAnchor ? 'mouse-over-popover' : undefined}
              className={classes.infoIcon}
              onMouseEnter={({ currentTarget }) =>
                setTitleAnchor(currentTarget)
              }
              onMouseLeave={() => setTitleAnchor(null)}
            />
            <Popover
              disableRestoreFocus
              anchorEl={titleAnchor}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              className={classes.popover}
              id="mouse-over-popover"
              open={Boolean(titleAnchor)}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              onClose={() => setTitleAnchor(null)}
            >
              <Text className={classes.popoverText} variant="body2">
                {t('Dialogs:CO_PET_PARENT_DIALOG.INFO')}
              </Text>
            </Popover>
          </Hidden>
        </>
      }
      onClose={onClose}
    >
      <CoPetParent
        clientId={clientId}
        coparentId={coparentId}
        ref={coPetParentRef}
        onOk={onClose}
      />
    </PuiDialog>
  )
}

export default CoPetParentDialog
