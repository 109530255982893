/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Fab, Grid, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ClassesType,
  PermissionArea,
  PrimitiveTableColumn,
  PuiTheme,
  TableFilter,
  Text,
} from '@pbt/pbt-ui-components'

import TeamMemberFilter from '~/components/common/filters/TeamMemberFilter'
import ExpandableTable from '~/components/common/lists/ExpandableTable'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import { ConversationCategory } from '~/constants/communications'
import DialogNames from '~/constants/DialogNames'
import { BaseRoute } from '~/constants/routes'
import i18n from '~/locales/i18n'
import { useConversationList } from '~/store/hooks/conversations'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getConversationListFetching,
  getConversationsListObjects,
  getConversationsTotalCount,
} from '~/store/reducers/conversations'
import { Conversation } from '~/types'
import {
  getConversationRowColoring,
  getConversationStatus,
  hasAppliedFilters,
  mapDateToConversationDetailsDate,
} from '~/utils/communicationsUtils'
import useDialog from '~/utils/useDialog'

import ConversationStatusLabel from '../common/ConversationStatusLabel'
// @ts-ignore
import { CreatedConversationFlow } from '../common/CreatedConversationsInfoDialog'
import ConversationFilters from '../filters/ConversationFilters'
import TransportIcon from '../TransportIcon'
import ConversationDateRangeFilter from './ConversationDateRangeFilter'
import ConversationStatusFilter from './ConversationStatusFilter'
import ConversationSubjectCell from './ConversationSubjectCell'
import ConversationTypeFilter from './ConversationTypeFilter'
import NoConversationsScreen from './NoConversationsScreen'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    headerButtons: {
      padding: theme.spacing(1, 3),
    },
    newConversationButton: {
      height: 40,
      flexShrink: 0,
    },
    pageTitle: {
      lineHeight: '2.4rem',
    },
  }),
  { name: 'ConversationsTableComponent' },
)

const columns: PrimitiveTableColumn[] = [
  {
    label: i18n.t('Common:CONTACT_ONE'),
    prop: 'contactName',
    width: 2,
  },
  {
    label: i18n.t('Common:EMAIL_FORM.SUBJECT'),
    component: ConversationSubjectCell,
    width: 4,
  },
  {
    label: i18n.t('Common:ASSIGNEE'),
    hasWarning: (item: Conversation) => Boolean(item.id && !item.assigneeName),
    prop: (item: Conversation) =>
      item.assigneeName || i18n.t('Common:UNASSIGNED'),
    filter: 'assignee',
    FilterComponent: TeamMemberFilter,
    width: 3,
  },
  {
    label: i18n.t('Common:STATUS'),
    component: (item: Conversation) => (
      <ConversationStatusLabel
        conversationId={item.id}
        status={getConversationStatus(item)}
      />
    ),
    filter: 'status',
    FilterComponent: ConversationStatusFilter,
    width: 1.5,
  },
  {
    label: i18n.t('Common:LAST_MESSAGE'),
    prop: 'lastMessageDate',
    filter: 'lastMessageDate',
    FilterComponent: ConversationDateRangeFilter,
    width: 2,
  },
  {
    label: i18n.t('Common:TYPE_ONE'),
    component: (item: Conversation) => <TransportIcon type={item.transport} />,
    filter: 'type',
    FilterComponent: ConversationTypeFilter,
    width: 1.5,
  },
]

interface HeaderButtonsProps {
  classes?: ClassesType<typeof useStyles>
  onNewConversationClick: () => void
  showCreate?: boolean
}

const HeaderButtons = ({
  showCreate = true,
  onNewConversationClick,
  classes: classesProp,
}: HeaderButtonsProps) => {
  const classes = useStyles({ classes: classesProp })
  const permissions = useSelector(getCRUDByArea(PermissionArea.COMMUNICATION))
  const { t } = useTranslation(['Common'])

  return (
    <Grid container className={classes.headerButtons} wrap="nowrap">
      <Text className={classes.pageTitle} mr={3} variant="h1">
        {t('Common:COMMUNICATION')}
      </Text>
      <Grid container item justifyContent="space-between">
        <ConversationFilters />
        {permissions.create && showCreate && (
          <Fab
            className={classes.newConversationButton}
            color="inherit"
            variant="extended"
            onClick={onNewConversationClick}
          >
            {t('Common:NEW_CLIENT_CONVERSATION')}
          </Fab>
        )}
      </Grid>
    </Grid>
  )
}

interface ConversationsTableComponentProps {
  classes?: ClassesType<typeof useStyles>
  clientId?: string
  showNewConversation?: boolean
}

const ConversationsTableComponent = ({
  clientId,
  classes: classesProp,
  showNewConversation,
}: ConversationsTableComponentProps) => {
  const theme = useTheme<PuiTheme>()
  const classes = useStyles({ classes: classesProp })
  const navigate = useNavigate()

  const [openNewConversationDialog] = useDialog(
    DialogNames.NEW_CONVERSATION_WITH_STEPS,
  )
  const [openCreatedConversationsInfoDialog] = useDialog(
    DialogNames.CREATED_CONVERSATIONS_INFO_DIALOG,
  )

  const conversations = useSelector(getConversationsListObjects)
  const conversationsTotalCount = useSelector(getConversationsTotalCount)
  const isLoading = useSelector(getConversationListFetching)
  const permissions = useSelector(getCRUDByArea(PermissionArea.COMMUNICATION))

  const getActualRowColoring = R.curry(getConversationRowColoring)(theme)

  const { loadMoreItems, clearFilters, applyFilter, filters } =
    useConversationList({ clientId })

  const filtersWithoutCategory = R.omit(['category'], filters) as Record<
    string,
    TableFilter[]
  >

  const hasSelectedFilters = hasAppliedFilters(filtersWithoutCategory)
  const mapConversationDate = (conversation: Conversation) =>
    mapDateToConversationDetailsDate(conversation.lastMessageDate || '')

  const tableConversations = conversations.map((conversation) =>
    conversation
      ? {
          ...conversation,
          contactName: conversation.to,
          lastMessageDate: mapConversationDate(conversation),
          subject: conversation.title,
        }
      : undefined,
  )

  const navigateToConversation = (id: string) =>
    navigate(`${BaseRoute.COMMUNICATIONS}/${id}`, { state: { clientId } })

  const handleConversationCreated = (flow: CreatedConversationFlow) => {
    if (flow === CreatedConversationFlow.DRAFT_MESSAGE_SENT) {
      applyFilter('category', { value: ConversationCategory.DRAFT })
    }

    openCreatedConversationsInfoDialog({
      flow,
      handleViewConversation: navigateToConversation,
    })
  }

  const handleNewConversationClick = () =>
    openNewConversationDialog({ clientId, handleConversationCreated })
  const isItemLoaded = (index: number) => Boolean(tableConversations[index])

  useEffect(() => {
    if (showNewConversation) {
      handleNewConversationClick()
    }
  }, [showNewConversation])

  return (
    <ExpandableTable
      NoItemsScreen={NoConversationsScreen}
      NoItemsScreenProps={{
        Header: (
          <HeaderButtons
            classes={classes}
            showCreate={false}
            onNewConversationClick={handleNewConversationClick}
          />
        ),
        categoryFilter: filters?.category?.value,
        onSentClick: () =>
          applyFilter('category', { value: ConversationCategory.SENT }),
        onNewConversationClick: permissions.create
          ? handleNewConversationClick
          : undefined,
      }}
      hasSelectedFilters={hasSelectedFilters}
      headerButtons={
        <HeaderButtons
          classes={classes}
          onNewConversationClick={handleNewConversationClick}
        />
      }
      isLoading={isLoading}
      list={tableConversations}
      onSelected={navigateToConversation}
    >
      <PrimitiveTableWithSearchHighlights
        columns={columns}
        filters={filters}
        getRowColoring={getActualRowColoring}
        isItemLoaded={isItemLoaded}
        list={tableConversations}
        loadMoreItems={loadMoreItems}
        totalCount={conversationsTotalCount}
        onApplyFilter={applyFilter}
        onClearFilters={clearFilters}
      />
    </ExpandableTable>
  )
}

export default ConversationsTableComponent
