import React, { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  Box,
  ClickAwayListener,
  Collapse,
  Grid,
  IconButton,
  useTheme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'
import { ChevronDown, ChevronUp } from '@pbt/pbt-ui-components/src/icons'

import { ChargeQueryParams, ChargeTab } from '~/types'
import { useKeydown } from '~/utils/useKeydown'

import { ChargesPanelTabs } from './chargesTabs'
import SearchChargeTab, {
  SearchChargeTabProps as ISearchChargeTabProps,
} from './search/SearchChargeTab'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    collapse: {
      maxWidth: '100%',
      backgroundColor: theme.colors.tableBackground,
    },
    container: {
      // We don't have this color anywhere else
      backgroundColor: '#fafbfb',
      position: 'sticky',
      // it should be bigger than widget content (2 is max) and smaller than both rails (9)
      zIndex: theme.utils.modifyZIndex(theme.zIndex.rightRail, 'below', 6),
      [theme.breakpoints.down('md')]: {
        top: theme.constants.soapHeaderHeight - 1,
      },
      [theme.breakpoints.up('md')]: {
        top:
          theme.constants.soapHeaderHeight +
          theme.constants.appHeader.heightMdUp,
      },
    },
  }),
  { name: 'ChargesDropdownView' },
)

export interface ChargesDropdownViewProps {
  SearchChargeTabProps: ISearchChargeTabProps
  expandedTab: string | null
  onExpandTab: (id: string | null) => void
  panels: ChargesPanelTabs
  tabs: ChargeTab[]
}

const ChargesDropdownView = ({
  SearchChargeTabProps,
  expandedTab,
  onExpandTab,
  panels,
  tabs,
}: ChargesDropdownViewProps) => {
  const classes = useStyles()
  const theme = useTheme<PuiTheme>()
  const [searchParams, setSearchParams] = useSearchParams()

  const innerRef = useRef<HTMLDivElement>(null)

  const { Component, props } =
    panels.find((panel) => panel.id === expandedTab) || {}
  const borderBottom = theme.constants.tabBorder

  const closeTab = () => {
    onExpandTab(null)
  }

  const handleClick = (id: string) => {
    if (id === expandedTab) {
      closeTab()
    } else {
      onExpandTab(id)
    }
  }

  const {
    disabled,
    handleEditListItem,
    handleRefill,
    handleSavePrescription,
    logItemStateGetter,
    openPrescriptionDialog,
    clearSearch,
  } = SearchChargeTabProps

  // 'disableReactTree' prop didn't work for all edge case scenarios, so it's better to check for root element
  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    const root = document.getElementById('root') as HTMLDivElement
    const target = event.target as HTMLDivElement
    const hasTarget = root.contains(target)

    if (!hasTarget) {
      return
    }

    closeTab()
    clearSearch()
  }

  useKeydown({ element: innerRef.current, key: 'Escape', callback: closeTab })
  useKeydown({ element: document, key: 'Escape', callback: clearSearch })

  // For this view we don't want to persist state through query params, so the default will be search
  useEffect(() => {
    searchParams.set(ChargeQueryParams.CHARGE_TAB, 'search')
    setSearchParams(searchParams)
  }, [])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        borderBottom={borderBottom}
        className={classes.container}
        ref={innerRef}
      >
        <Box overflow="auto">
          <Grid container borderBottom={borderBottom} columnSpacing={4} pl={2}>
            {tabs.map(({ id, label, hide }, index) => {
              const isCurrentTab = expandedTab === id
              const ChevronIconComponent = isCurrentTab
                ? ChevronUp
                : ChevronDown

              if (hide) {
                return null
              }

              return (
                <Grid item={index > 0} key={id}>
                  <IconButton
                    sx={{ pb: 0.5, pt: 1, px: 0 }}
                    type="button"
                    onClick={() => handleClick(id)}
                  >
                    <Text strong variant={isCurrentTab ? 'h5' : 'body2'}>
                      {label}
                    </Text>
                    <ChevronIconComponent
                      color={isCurrentTab ? 'primary' : 'inherit'}
                    />
                  </IconButton>
                </Grid>
              )
            })}
          </Grid>
          <Collapse className={classes.collapse} in={Boolean(expandedTab)}>
            {Component && (
              <Component
                disabled={disabled}
                handleEditListItem={handleEditListItem}
                handleRefill={handleRefill}
                handleSavePrescription={handleSavePrescription}
                logItemStateGetter={logItemStateGetter}
                openPrescriptionDialog={openPrescriptionDialog}
                {...(props as any)}
              />
            )}
          </Collapse>
        </Box>
        {!expandedTab && <SearchChargeTab {...SearchChargeTabProps} />}
      </Box>
    </ClickAwayListener>
  )
}

export default ChargesDropdownView
