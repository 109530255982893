import React, { useEffect, useRef, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { ClassesType, Nil } from '@pbt/pbt-ui-components'

import { OrderType } from '~/constants/SOAPStates'
import { RootState } from '~/store'
import { rearrangeInvoiceLineItems } from '~/store/actions/finance'
import {
  fetchChargeSheetLineItem,
  fetchClientFinanceCharges,
  getChargeSheetLineItemLoading,
  getChargeSheetSubItemBySoapPatient,
  getCurrentChargeSheetLineItem,
  resetEditingLineItemState,
} from '~/store/duck/clientFinanceData'
import { getPrescriptionIsUpdating } from '~/store/duck/prescriptions'
import {
  getFinanceErrorRearranging,
  getFinanceInvoice,
  getFinanceIsFetching,
  getFinanceIsSaving,
} from '~/store/reducers/finance'
import { getCreatedOrder, getOrdersIsSending } from '~/store/reducers/orders'
import { getInvoiceId } from '~/store/reducers/soap'
import {
  Invoice,
  InvoiceLineItem,
  InvoiceLineItemsArrangement,
  Order,
} from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import { isGroupedInvoiceItem } from '../../invoices/invoiceUtils'
import ChargeInformationPanel from '../../soapV2/charges/information-panel/ChargeInformationPanel'
import ChargesTable from '../../soapV2/charges/table/ChargesTable'

interface ChargeSheetTableProps {
  ComponentsActions?: Partial<
    Record<OrderType, React.JSXElementConstructor<any>>
  >
  Footer?: React.JSXElementConstructor<any>
  SubHeader?: React.JSXElementConstructor<any>
  chargesGroupedItems: Record<string, InvoiceLineItem[]>
  chargesId: string | Nil
  classes?: ClassesType<any>
  clientId?: string
  hasHeader?: boolean
  invoiceId?: string
  isInvoice?: boolean
  onHeaderClick?: (currentItemId?: string) => void
  sectionsIdsInOrder?: string[]
  useIndexAsId?: boolean
}

const getPropFromLineItem = (prop: string, item: InvoiceLineItem) =>
  R.has('items', item)
    ? R.prop(prop, R.pipe(R.prop('items'), R.head)(item))
    : R.prop(prop, item)

const ChargesPanel = ({
  chargesGroupedItems,
  chargesId,
  isInvoice = false,
  SubHeader,
  Footer,
  hasHeader = true,
  useIndexAsId = true,
  clientId,
  ComponentsActions,
  classes,
  sectionsIdsInOrder,
  invoiceId: invoiceIdProp,
  onHeaderClick,
}: ChargeSheetTableProps) => {
  const dispatch = useDispatch()

  const invoiceIdSoap = useSelector(getInvoiceId)
  const invoiceId = invoiceIdProp || invoiceIdSoap
  const invoice = useSelector(getFinanceInvoice(invoiceId)) as Invoice | Nil
  const isFetching = useSelector(getFinanceIsFetching)
  const isSaving = useSelector(getFinanceIsSaving)
  const isSendingOrders = useSelector(getOrdersIsSending)
  const hasErrorRearranging = useSelector(getFinanceErrorRearranging)
  const currentChargeSheetLineItem = useSelector(getCurrentChargeSheetLineItem)
  const chargeSheetLineItemLoading = useSelector(getChargeSheetLineItemLoading)
  const createdOrder = useSelector(getCreatedOrder)
  const isUpdatingPrescription = useSelector(getPrescriptionIsUpdating)

  const currentOrder = useRef({})

  const isLoading =
    isFetching ||
    isSaving ||
    isSendingOrders ||
    chargeSheetLineItemLoading ||
    isUpdatingPrescription

  const [selectedItem, setSelectedItem] = useState<InvoiceLineItem>()
  const [isChargeInformationPanelOpened, setIsChargeInformationPanelOpened] =
    useState(false)

  // Refactor to handle one state
  const [localGroupedItems, setLocalGroupedItems] =
    useState(chargesGroupedItems)
  const [groupIds, setGroupIds] = useState<string[]>(
    sectionsIdsInOrder || Object.keys(chargesGroupedItems),
  )
  const chargeSheetSectionItem = useSelector(
    getChargeSheetSubItemBySoapPatient(
      selectedItem?.patientId,
      selectedItem?.soapId,
      selectedItem?.chargeSheetSubItemId,
    ),
  )
  const isEditDisabled =
    isInvoice || Boolean(chargeSheetSectionItem?.soap?.finalized)

  useEffect(() => {
    if (hasErrorRearranging) {
      setLocalGroupedItems(chargesGroupedItems)
      setGroupIds(sectionsIdsInOrder || Object.keys(chargesGroupedItems))
    }
  }, [hasErrorRearranging])

  useEffect(() => {
    setLocalGroupedItems(chargesGroupedItems)
    setGroupIds(sectionsIdsInOrder || Object.keys(chargesGroupedItems))
  }, [(sectionsIdsInOrder || Object.keys(chargesGroupedItems)).length])

  useDeepCompareEffect(() => {
    setLocalGroupedItems(chargesGroupedItems)
    setGroupIds(sectionsIdsInOrder || Object.keys(chargesGroupedItems))
    // since we can edit prescription and change their workflow type, there is a problem with detecting new entity id,
    // for order -> prescription we can directly use lineItem.id to fetch invoiceLineItem
    // however for the prescription -> order we don't have lineItem, here we can search through the list of chargeSheet invoiceLineItems
    // unfortunatly chargesGroupedItems update ready after prescription dialog closed, and by closing it we clear createdOrder
    // so we have to put createdOrder into ref, and update selectedItem after chargesGroupedItems udpated and use data from currentOrder ref
    if (currentOrder.current) {
      const lineItemLogId: string | undefined = R.path(
        ['current', 'lineItem', 'logId'],
        currentOrder,
      )
      const logId = R.path(['current', 'id'], currentOrder)
      const currentOrderId = lineItemLogId || logId
      if (currentOrderId) {
        const item = R.pipe<
          (typeof chargesGroupedItems)[],
          InvoiceLineItem[][],
          InvoiceLineItem[],
          InvoiceLineItem | undefined
        >(
          R.values,
          R.flatten,
          R.find(R.propEq('logId', currentOrderId)) as (
            invoiceLineItem: InvoiceLineItem[],
          ) => InvoiceLineItem | undefined,
        )(chargesGroupedItems)
        setSelectedItem(item)
      }
    }
  }, [chargesGroupedItems])

  useEffect(() => {
    if (selectedItem?.id && !isGroupedInvoiceItem(selectedItem)) {
      dispatch(fetchChargeSheetLineItem({ id: selectedItem.id }))
    }
  }, [selectedItem?.id])

  useEffectExceptOnMount(() => {
    if (createdOrder) {
      currentOrder.current = createdOrder
    }
  }, [createdOrder])

  const refetchAfterDelete = useCloseAfterCreation(
    () => clientId && dispatch(fetchClientFinanceCharges({ id: clientId })),
    ({ orders, clientFinanceData }: RootState) =>
      orders.isSending || clientFinanceData.loading,
  )

  const closeChargeInformationPanel = () => {
    refetchAfterDelete()
    setIsChargeInformationPanelOpened(false)
    setSelectedItem(undefined)
    dispatch(resetEditingLineItemState())
  }

  const onSelectItem = (item: InvoiceLineItem | Order) => {
    setIsChargeInformationPanelOpened(true)
    setSelectedItem(item as InvoiceLineItem)
  }

  const rearrangeSections = (from: number, to: number) => {
    const orderedTableIds = R.move(from, to, groupIds)
    const orderedGroupedItems = Object.values(
      R.pick(orderedTableIds, localGroupedItems),
    )
    if (chargesId) {
      const rearrangements = orderedGroupedItems.reduce(
        (arr: InvoiceLineItemsArrangement[], newItemsOrder, index) => {
          const newAllItemsOrder = R.chain(
            (i) => (i.items ? [i, ...i.items] : [i]),
            newItemsOrder,
          )
          newAllItemsOrder.forEach((item, idx) => {
            arr.push({
              invoiceLineItemId: item.id,
              viewOrderNumber: idx,
              viewSectionOrderNumber: index,
            })
          })
          return arr
        },
        [],
      )
      dispatch(rearrangeInvoiceLineItems(chargesId, rearrangements))
    }
    setGroupIds(orderedTableIds)
  }

  const rearrangeSectionItems = (
    from: number,
    to: number,
    sectionId: string,
  ) => {
    const newItemsOrder = R.move(from, to, localGroupedItems[sectionId])
    if (chargesId) {
      const newAllItemsOrder = R.chain(
        (i) => (i.items ? [i, ...i.items] : [i]),
        newItemsOrder,
      )
      const rearrangements = newAllItemsOrder.reduce(
        (arr: InvoiceLineItemsArrangement[], item, index) => {
          arr.push({ invoiceLineItemId: item.id, viewOrderNumber: index })
          return arr
        },
        [],
      )
      dispatch(rearrangeInvoiceLineItems(chargesId, rearrangements))
    }
    setLocalGroupedItems({ ...localGroupedItems, [sectionId]: newItemsOrder })
  }

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return
    }

    rearrangeSections(result.source.index, result.destination.index)
  }

  return (
    <>
      {selectedItem && (
        <ChargeInformationPanel
          ComponentsActions={ComponentsActions}
          classes={classes}
          editDisabled={isEditDisabled}
          invoice={invoice}
          isInvoice={isInvoice}
          isLoading={isLoading}
          isOpened={isChargeInformationPanelOpened}
          isSoap={false}
          item={
            isGroupedInvoiceItem(selectedItem)
              ? selectedItem
              : currentChargeSheetLineItem
          }
          onClose={closeChargeInformationPanel}
          onSelectItem={onSelectItem}
        />
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="table" isDropDisabled={isInvoice} type="table">
          {(droppableProvided) => (
            <Grid
              container
              item
              alignItems="center"
              justifyContent="center"
              ref={(ref) => {
                droppableProvided.innerRef(ref)
              }}
              {...droppableProvided.droppableProps}
            >
              {groupIds.map((id: string, index) => (
                <Draggable
                  draggableId={`${id}`}
                  index={index}
                  isDragDisabled={isInvoice}
                  key={id}
                >
                  {(provided) => (
                    <Grid
                      container
                      item
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <ChargesTable
                        Footer={Footer}
                        SubHeaderComponent={SubHeader}
                        chargesId={chargesId}
                        currentItemId={id}
                        dragHandleProps={provided.dragHandleProps}
                        hasHeader={hasHeader}
                        invoiceId={invoiceIdProp}
                        isInvoice={isInvoice}
                        items={R.map(
                          (item: InvoiceLineItem) => ({
                            ...item,
                            soapId: getPropFromLineItem('soapId', item),
                            patientId: getPropFromLineItem(
                              'patientId',
                              item,
                            ) as unknown as string,
                            chargeSheetSubItemId: id,
                          }),
                          R.prop(id, localGroupedItems),
                        )}
                        selectedItem={selectedItem}
                        useIndexAsId={useIndexAsId}
                        onHeaderClick={onHeaderClick}
                        onItemOrderChange={(from, to) => {
                          rearrangeSectionItems(from, to, id)
                        }}
                        onSelectItem={onSelectItem}
                      />
                    </Grid>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

export default ChargesPanel
