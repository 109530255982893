import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { LanguageUtils, PuiTheme, Text } from '@pbt/pbt-ui-components'

import { getLabTestTypes } from '~/store/reducers/constants'
import { LabTestDashboardOrder, LabTestDashboardTest } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    line: {
      lineHeight: '16px',
    },
    notFirstLine: {
      marginTop: theme.spacing(1),
    },
    container: {
      padding: theme.spacing(1, 0),
    },
  }),
  { name: 'LabOrderTypeCell' },
)

export interface LabOrderTypeCellProps {
  order: LabTestDashboardOrder | null
  tests?: LabTestDashboardTest[]
}

const LabOrderTypeCell = ({
  order,
  tests: testsProp,
}: LabOrderTypeCellProps) => {
  const tests = testsProp || []
  const classes = useStyles()
  const LabTestTypes = useSelector(getLabTestTypes)

  return (
    <Grid container className={classes.container} direction="column">
      {order
        ? tests?.[0]?.typeId && (
            <Text className={classes.line} variant="body2">
              {LanguageUtils.getConstantTranslatedName(
                tests?.[0]?.typeId,
                LabTestTypes,
              )}
            </Text>
          )
        : tests.map(({ typeId }, index) => (
            <Text
              className={classNames(
                classes.line,
                index > 0 && classes.notFirstLine,
              )}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              variant="body2"
            >
              {LanguageUtils.getConstantTranslatedName(typeId, LabTestTypes) ??
                ''}
            </Text>
          ))}
    </Grid>
  )
}

export default LabOrderTypeCell
