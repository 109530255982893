import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { always, cond, equals } from 'ramda'
import { DateUtils, Text, Utils } from '@pbt/pbt-ui-components'

import { getTaskTimeUnits, getTimeOffsets } from '~/store/reducers/constants'
import { Task } from '~/types'

const useStyles = makeStyles(
  () => ({
    text: {
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'TaskRepeatLabel' },
)

export interface TaskRepeatLabelProps {
  task: Task
}

const TaskRepeatLabel = ({ task }: TaskRepeatLabelProps) => {
  const classes = useStyles()
  const TimeOffsets = useSelector(getTimeOffsets)
  const TaskTimeUnits = useSelector(getTaskTimeUnits)
  const { t } = useTranslation(['Common', 'Tasks'])

  const CustomTimeOffset = Utils.findConstantIdByName('Custom', TimeOffsets)

  if (!task.recurrenceModeId) {
    return null
  }

  const isCustomRecurrenceMode = task.recurrenceModeId === CustomTimeOffset
  const { amount, unit } = task.recurrencePeriod || {}
  const currentUnit = Utils.getConstantName(unit, TaskTimeUnits)

  const getPluralFrequency = cond([
    [equals('year'), always(t('Tasks:FREQUENCY.EVERY_OTHER_YEAR', { amount }))],
    [
      equals('month'),
      always(t('Tasks:FREQUENCY.EVERY_OTHER_MONTH', { amount })),
    ],
    [equals('week'), always(t('Tasks:FREQUENCY.EVERY_OTHER_WEEK', { amount }))],
    [equals('day'), always(t('Tasks:FREQUENCY.EVERY_OTHER_DAY', { amount }))],
    [equals('hour'), always(t('Tasks:FREQUENCY.EVERY_OTHER_HOUR', { amount }))],
    [
      equals('minute'),
      always(t('Tasks:FREQUENCY.EVERY_OTHER_MINUTE', { amount })),
    ],
  ])

  const getSingularFrequency = cond([
    [equals('year'), always(t('Tasks:FREQUENCY.EVERY_ONE_YEAR', { amount }))],
    [equals('month'), always(t('Tasks:FREQUENCY.EVERY_ONE_MONTH', { amount }))],
    [equals('week'), always(t('Tasks:FREQUENCY.EVERY_ONE_WEEK', { amount }))],
    [equals('day'), always(t('Tasks:FREQUENCY.EVERY_ONE_DAY', { amount }))],
    [equals('hour'), always(t('Tasks:FREQUENCY.EVERY_ONE_HOUR', { amount }))],
    [
      equals('minute'),
      always(t('Tasks:FREQUENCY.EVERY_ONE_MINUTE', { amount })),
    ],
  ])

  return (
    <>
      {isCustomRecurrenceMode && amount && unit && (
        <Text className={classes.text} px={0.25} variant="body2">
          {amount > 1
            ? getPluralFrequency(currentUnit?.toLowerCase())
            : getSingularFrequency(currentUnit?.toLowerCase())}
        </Text>
      )}
      {(task.recurrenceEndDatetime || task.occurrencesCount) && (
        <Text className={classes.text} px={0.25} variant="body2">
          {`${t('Tasks:LABEL.END_AFTER')} ${
            task.recurrenceEndDatetime
              ? DateUtils.formatDateWithHours(task.recurrenceEndDatetime)
              : task.occurrencesCount === 1
              ? `${task.occurrencesCount} ${t(
                  'Tasks:LABEL.OCCURRENCE_ONE',
                ).toLowerCase()}`
              : `${task.occurrencesCount} ${t(
                  'Tasks:LABEL.OCCURRENCE_OTHER',
                ).toLowerCase()}`
          }`}
        </Text>
      )}
    </>
  )
}

export default TaskRepeatLabel
