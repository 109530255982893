import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  InputLabel,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Calendar,
  CurrencyTextField,
  CustomFieldValidatorState,
  Nil,
  PermissionArea,
  PuiAutocompleteSelect,
  PuiSelect,
  PuiTextField,
  PuiTheme,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import NdcNumberInput from '~/components/common/form-inputs/ndc/NdcNumberInput'
import { getNdc11DigitsFormat } from '~/components/common/form-inputs/ndc/ndcUtils'
import NumericInput from '~/components/common/inputs/NumericInput'
import ManualInputSelect from '~/components/common/ManualInputSelect'
import { fetchSpacesListWithType } from '~/store/actions/spaces'
import { useInStockUnits } from '~/store/hooks/inventories'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getInventoryCategory,
  getInventoryManufacturers,
  getInventoryProductSizeUnit,
  getPackageType,
  getSpaceType,
} from '~/store/reducers/constants'
import {
  getMultipleSpaces,
  getSpaceCurrentTypeId,
  getSpacesIsFetching,
  getSpacesList,
} from '~/store/reducers/spaces'
import {
  DataHandleWithUnsavedChanges,
  ShipmentItem as ShipmentItemType,
} from '~/types'
import { isFieldValuesChanged } from '~/utils'
import useFieldsChanged from '~/utils/useFieldsChanged'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    cost: { width: theme.spacing(13) },
    tax: { width: theme.spacing(8) },
    quantityReceived: { width: theme.spacing(22) },
    amountUnitsId: { width: theme.spacing(15) },
    amountPerPackage: { width: theme.spacing(25) },
    quantityUnits: { width: theme.spacing(15) },
    packageTypeId: { width: theme.spacing(18) },
    lot: { width: theme.spacing(12) },
    serial: { width: theme.spacing(12) },
    expirationDate: { width: theme.spacing(20) },
    storageLocation: { width: theme.spacing(24) },
    practiceReference: { width: theme.spacing(24) },
    notes: { width: theme.spacing(30) },
    manufacturerContainer: {
      width: 'auto',
    },
    manufacturer: {
      width: theme.spacing(27),
    },
    manufacturerOther: {
      width: theme.spacing(22),
    },
    manufacturerSku: { width: theme.spacing(21) },
    distributorSku: { width: theme.spacing(20) },
    upc: { width: theme.spacing(16) },
    ndc: { width: theme.spacing(16) },
    accordion: {
      '&:before': {
        display: 'none',
      },
      boxShadow: 'none',
      '&&$expanded': {
        margin: 0,
      },
    },
    expanded: {},
    accordionSummary: {
      padding: 0,
      fontWeight: 500,
      fontSize: '1.4rem',
      justifyContent: 'flex-start',
      minHeight: 'unset',
      '&&$expanded': {
        minHeight: 'unset',
      },
    },
    accordionSummaryContent: {
      flexGrow: 0,
      margin: 0,
      '&&$expanded': {
        margin: 0,
      },
    },
    accordionSummaryExpandIcon: {
      padding: 0,
      margin: 0,
    },
    accordionDetails: {
      padding: 0,
      flexDirection: 'column',
    },
    expandIcon: {
      color: theme.colors.primaryText,
    },
    noPointerEvents: {
      pointerEvents: 'none',
    },
  }),
  { name: 'ShipmentItem' },
)

export interface ShipmentItemHandle extends DataHandleWithUnsavedChanges {}

interface ShipmentItemProps {
  onChange?: () => void
  shipmentItem: ShipmentItemType | Nil
  taxDisabled?: boolean
}

const ShipmentItem = forwardRef<ShipmentItemHandle, ShipmentItemProps>(
  function ShipmentItem({ shipmentItem, onChange, taxDisabled }, ref) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const permissions = useSelector(getCRUDByArea(PermissionArea.SHIPMENTS))
    const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)
    const PackageType = useSelector(getPackageType)
    const SpaceType = useSelector(getSpaceType)
    const InventoryManufacturers = useSelector(getInventoryManufacturers)
    const InventoryCategory = useSelector(getInventoryCategory)
    const spaceIsFetching = useSelector(getSpacesIsFetching)
    const spacesList = useSelector(getSpacesList)
    const spaces = useSelector(getMultipleSpaces(spacesList))
    const currentSpaceId = useSelector(getSpaceCurrentTypeId)
    const { t } = useTranslation(['Abbreviations', 'Common'])

    const ExpandedCategories = ['Drugs', 'Vaccines']
    const defaultExpanded = shipmentItem?.inventoryCategoryId
      ? ExpandedCategories.includes(
          Utils.getConstantName(
            shipmentItem?.inventoryCategoryId,
            InventoryCategory,
          ),
        )
      : false

    const [moreDetailsExpanded, setMoreDetailsExpanded] =
      useState(defaultExpanded)

    const locationTypeSpaceId = Utils.findConstantIdByName(
      'Storage location',
      SpaceType,
    )

    const validateNdcNumber = ({ value: ndc }: CustomFieldValidatorState) =>
      !ndc || Boolean(getNdc11DigitsFormat(ndc))

    useEffect(() => {
      if (
        !spaceIsFetching &&
        locationTypeSpaceId &&
        currentSpaceId !== locationTypeSpaceId
      ) {
        dispatch(fetchSpacesListWithType(locationTypeSpaceId))
      }
    }, [currentSpaceId, spaceIsFetching])

    const { fields, validate } = useFields(
      [
        {
          name: 'cost',
          label: t('Common:COST'),
          validators: ['required'],
          initialValue: shipmentItem?.costTotal || 0,
        },
        {
          name: 'tax',
          label: t('Common:TAX'),
          validators: [],
          initialValue: shipmentItem?.tax || 0,
        },
        {
          name: 'quantityReceived',
          label: t('Common:QUANTITY_RECEIVED'),
          validators: ['required'],
          initialValue: shipmentItem?.amount,
        },
        {
          name: 'amountUnitsId',
          label: t('Common:UNITS'),
          validators: ['required'],
          initialValue: shipmentItem?.amountUnitsId,
        },
        {
          name: 'perPackageAmount',
          label: t('Common:AMOUNT_PER_PACKAGE'),
          validators: ['required'],
          initialValue: shipmentItem?.perPackageAmount,
        },
        {
          name: 'perPackageUnitsId',
          label: t('Common:UNITS'),
          validators: ['required'],
          type: 'select',
          initialValue: shipmentItem?.perPackageUnitsId,
        },
        {
          name: 'packageTypeId',
          label: t('Common:PACKAGE_TYPE'),
          validators: ['required'],
          type: 'select',
          initialValue: shipmentItem?.packageTypeId,
        },
        {
          name: 'lotNumber',
          label: t('Common:LOT_SHIPMENT_NUMBER'),
          validators: [],
          initialValue: shipmentItem?.lotNumber,
        },
        {
          name: 'serialNumber',
          label: t('Common:SERIAL_NUMBER_SIGN'),
          validators: [],
          initialValue: shipmentItem?.serialNumber,
        },
        {
          name: 'expirationDate',
          label: t('Common:EXPIRATION_DATE'),
          validators: [],
          initialValue: shipmentItem?.expirationDate,
        },
        {
          name: 'storageLocationId',
          label: t('Common:STORAGE_LOCATION'),
          validators: [],
          type: 'select',
          initialValue: shipmentItem?.storageLocationId,
        },
        {
          name: 'practiceReference',
          label: t('Common:PRACTICE_REFERENCE'),
          validators: [],
          initialValue: shipmentItem?.businessReferenceNumber,
        },
        {
          name: 'notes',
          label: t('Common:NOTE'),
          validators: [],
          initialValue: shipmentItem?.notes,
        },
        {
          name: 'manufacturer',
          label: t('Common:MANUFACTURER'),
          validators: [],
          type: 'select',
          initialValue: shipmentItem?.manufacturerId,
        },
        {
          name: 'manufacturerName',
          label: t('Common:MANUFACTURER'),
          validators: [],
          initialValue: shipmentItem?.manufacturerName,
        },
        {
          name: 'manufacturerSku',
          label: t('Common:MANUFACTURER_SKU'),
          validators: [],
          initialValue: shipmentItem?.manufacturerSku,
        },
        {
          name: 'distributorSku',
          label: t('Common:DISTRIBUTOR_SKU'),
          validators: [],
          initialValue: shipmentItem?.distributorSku,
        },
        {
          name: 'upc',
          label: t('Abbreviations:ACRONYMS.UNIVERSAL_PRODUCT_CODE'),
          validators: [],
          initialValue: shipmentItem?.upc,
        },
        {
          name: 'ndc',
          label: t(
            'Abbreviations:ACRONYMS.NATIONAL_DRUG_CODE.LABEL_ABBREVIATION',
          ),
          validators: [{ validator: validateNdcNumber, validatorName: 'ndc' }],
          initialValue: shipmentItem?.ndc,
        },
      ],
      false,
    )

    const {
      cost,
      tax,
      quantityReceived,
      amountUnitsId,
      perPackageAmount,
      perPackageUnitsId,
      packageTypeId,
      lotNumber,
      serialNumber,
      expirationDate,
      storageLocationId,
      practiceReference,
      notes,
      manufacturer,
      manufacturerName,
      manufacturerSku,
      distributorSku,
      upc,
      ndc,
    } = fields

    useImperativeHandle(ref, () => ({
      validate,
      get: () => ({
        ...shipmentItem,
        costTotal: cost.value,
        tax: tax.value,
        amount: quantityReceived.value,
        amountUnitsId: amountUnitsId.value,
        perPackageAmount: perPackageAmount.value,
        perPackageUnitsId: perPackageUnitsId.value,
        packageTypeId: packageTypeId.value,
        lotNumber: lotNumber.value,
        serialNumber: serialNumber.value,
        expirationDate: expirationDate.value,
        storageLocationId: storageLocationId.value,
        businessReferenceNumber: practiceReference.value,
        notes: notes.value,
        manufacturerId: manufacturer.value,
        manufacturerName: manufacturerName.value,
        manufacturerSku: manufacturerSku.value,
        distributorSku: distributorSku.value,
        upc: upc.value,
        ndc: ndc.value,
      }),
      hasUnsavedChanges: () => isFieldValuesChanged(fields),
    }))

    const mainFields = {
      tax,
      cost,
      quantityReceived,
      amountUnitsId,
      perPackageAmount,
      perPackageUnitsId,
      packageTypeId,
    }

    useFieldsChanged(onChange, mainFields)

    const inStockUnitOptions = useInStockUnits(
      perPackageUnitsId.value,
      packageTypeId.value,
    )

    return (
      <Grid container className={classes.root} direction="column">
        <Grid container alignItems="center" columnSpacing={3}>
          <Grid item>
            <CurrencyTextField
              className={classes.cost}
              disabled={!permissions.update}
              field={cost}
              label={`${cost.label}*`}
            />
          </Grid>
          <Grid item>
            <CurrencyTextField
              className={classes.tax}
              disabled={!permissions.update || taxDisabled}
              field={tax}
              label={tax.label}
            />
          </Grid>
          <Grid item>
            <NumericInput
              allowDecimal
              nullable
              className={classes.quantityReceived}
              disabled={!permissions.update}
              field={quantityReceived}
              label={`${quantityReceived.label}*`}
              min={0}
            />
          </Grid>
          <Grid item mt={2}>
            <span>/</span>
          </Grid>
          <Grid item>
            <FormControl
              fullWidth
              className={classes.quantityUnits}
              margin="normal"
            >
              <InputLabel>{amountUnitsId.label}*</InputLabel>
              <PuiSelect
                disabled={!permissions.update}
                field={amountUnitsId}
                items={inStockUnitOptions}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <PuiTextField
              className={classes.amountPerPackage}
              disabled={!permissions.update}
              field={perPackageAmount}
              label={`${perPackageAmount.label}*`}
            />
          </Grid>
          <Grid item>
            <FormControl
              fullWidth
              className={classes.amountUnitsId}
              margin="normal"
            >
              <InputLabel>{perPackageUnitsId.label}*</InputLabel>
              <PuiSelect
                className={classes.noPointerEvents}
                disabled={!permissions.update}
                field={perPackageUnitsId}
                inputProps={{ readOnly: true }}
                items={InventoryProductSizeUnit}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl
              fullWidth
              className={classes.packageTypeId}
              margin="normal"
            >
              <InputLabel>{packageTypeId.label}*</InputLabel>
              <PuiSelect
                className={classes.noPointerEvents}
                disabled={!permissions.update}
                field={packageTypeId}
                inputProps={{ readOnly: true }}
                items={PackageType}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Accordion
          classes={{
            root: classes.accordion,
            expanded: classes.expanded,
          }}
          expanded={moreDetailsExpanded}
        >
          <AccordionSummary
            classes={{
              root: classes.accordionSummary,
              content: classes.accordionSummaryContent,
              expanded: classes.expanded,
            }}
            expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
            onClick={() => setMoreDetailsExpanded(!moreDetailsExpanded)}
          >
            {moreDetailsExpanded
              ? t('Common:LESS_DETAILS')
              : t('Common:MORE_DETAILS')}
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.accordionDetails,
            }}
          >
            <Grid container alignItems="center" columnSpacing={3}>
              <Grid item>
                <PuiTextField
                  className={classes.lot}
                  disabled={!permissions.update}
                  field={lotNumber}
                  label={lotNumber.label}
                />
              </Grid>
              <Grid item>
                <PuiTextField
                  className={classes.serial}
                  disabled={!permissions.update}
                  field={serialNumber}
                  label={serialNumber.label}
                />
              </Grid>
              <Grid item>
                <Calendar
                  fullWidth
                  className={classes.expirationDate}
                  disabled={!permissions.update}
                  field={expirationDate}
                  label={expirationDate.label}
                />
              </Grid>
              <Grid item>
                <FormControl
                  fullWidth
                  className={classes.storageLocation}
                  margin="normal"
                >
                  <InputLabel>{storageLocationId.label}</InputLabel>
                  <PuiSelect
                    disabled={!permissions.update}
                    field={storageLocationId}
                    items={spaces}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <PuiTextField
                  className={classes.practiceReference}
                  disabled={!permissions.update}
                  field={practiceReference}
                  label={practiceReference.label}
                />
              </Grid>
              <Grid item>
                <PuiTextField
                  className={classes.notes}
                  disabled={!permissions.update}
                  field={notes}
                  label={notes.label}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" columnSpacing={3}>
              <Grid
                container
                item
                alignItems="center"
                className={classes.manufacturerContainer}
                wrap="nowrap"
              >
                <ManualInputSelect
                  UnitComponent={PuiAutocompleteSelect}
                  classes={{
                    unit: classes.manufacturer,
                    other: classes.manufacturerOther,
                  }}
                  disabled={!permissions.update}
                  fields={{
                    unitId: manufacturer,
                    customUnit: manufacturerName,
                  }}
                  grid={false}
                  label={manufacturer.label}
                  options={InventoryManufacturers}
                />
              </Grid>
              <Grid item>
                <PuiTextField
                  className={classes.manufacturerSku}
                  disabled={!permissions.update}
                  field={manufacturerSku}
                  label={manufacturerSku.label}
                />
              </Grid>
              <Grid item>
                <PuiTextField
                  className={classes.distributorSku}
                  disabled={!permissions.update}
                  field={distributorSku}
                  label={distributorSku.label}
                />
              </Grid>
              <Grid item>
                <PuiTextField
                  className={classes.upc}
                  disabled={!permissions.update}
                  field={upc}
                  label={upc.label}
                />
              </Grid>
              <Grid item>
                <NdcNumberInput
                  className={classes.ndc}
                  disabled={!permissions.update}
                  field={ndc}
                  label={ndc.label}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    )
  },
)

export default ShipmentItem
