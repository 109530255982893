import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, Grid, GridProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, PuiTheme, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    container: {
      [theme.breakpoints.down('md')]: {
        borderBottom: theme.constants.filterBorder,
      },
    },
    text: {
      textTransform: 'none',
      [theme.breakpoints.up('md')]: {
        fontWeight: 'bold',
      },
    },
    thinText: {
      fontWeight: 400,
      textDecoration: 'underline',
    },
    thinArrow: {
      width: '0.65em',
      height: '0.65em',
      marginRight: theme.spacing(0.5),
    },
  }),
  { name: 'DetailsBackButton' },
)

export interface DetailsBackButtonProps {
  children: React.ReactNode
  className?: string
  classes?: ClassesType<typeof useStyles>
  fullWidth?: boolean
  lg?: GridProps['lg']
  md?: GridProps['md']
  onClick: () => void
  sm?: GridProps['sm']
  thin?: boolean
}

const DetailsBackButton = ({
  classes: classesProp,
  className,
  onClick,
  children,
  fullWidth,
  lg = !fullWidth && 3,
  md = !fullWidth && 5,
  sm = !fullWidth && 12,
  thin = false,
}: DetailsBackButtonProps) => {
  const classes = useStyles({ classes: classesProp })
  return (
    <Grid
      container
      item
      className={classNames(className, classes.container)}
      lg={lg}
      md={md}
      sm={sm}
    >
      <Button onClick={onClick}>
        <ArrowBackIcon className={classNames({ [classes.thinArrow]: thin })} />
        <Text
          strong
          className={classNames(classes.text, { [classes.thinText]: thin })}
          variant="body2"
        >
          {children}
        </Text>
      </Button>
    </Grid>
  )
}

export default DetailsBackButton
