import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'
import { Edit as EditIcon } from '@pbt/pbt-ui-components/src/icons'

import TaskStateButtonMesh from '~/components/dashboard/tasks-dashboard/mesh/TaskStateButtonMesh'
import TaskStateLabel from '~/components/dashboard/tasks-dashboard/TaskStateLabel'
import DialogNames from '~/constants/DialogNames'
import { TaskContext } from '~/constants/taskConstants'
import { getTask } from '~/store/reducers/tasks'
import { getUserName } from '~/store/reducers/users'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    firstColumn: {
      borderRight: theme.constants.tableBorder,
    },
    iconButton: {
      padding: theme.spacing(1),
    },
    editIcon: {
      color: theme.colors.editIconColor,
    },
  }),
  { name: 'WhiteboardTaskRow' },
)

export interface WhiteboardTaskRowProps {
  className?: string
  scrollOffset?: number
  scrollRef: React.Ref<HTMLDivElement>
  taskId: string
  timeColumns: string[]
}

const WhiteboardTaskRow = ({
  scrollOffset,
  scrollRef,
  timeColumns,
  className,
  taskId,
}: WhiteboardTaskRowProps) => {
  const classes = useStyles()
  const task = useSelector(getTask(taskId))
  const assigned = useSelector(getUserName(task?.assignedId || task?.assigned))

  const [openTaskDialog] = useDialog(DialogNames.TASK)

  const editTask = () => {
    openTaskDialog({
      taskId,
    })
  }

  return (
    <Grid container item className={classNames(className, classes.root)}>
      <Grid
        container
        item
        xs
        alignItems="center"
        className={classes.firstColumn}
        mr={1}
        pl={5.5}
      >
        <TaskStateLabel stateId={task?.aggregatedStateId} />
      </Grid>
      <Grid container item alignItems="center" py={0.5} xs={2}>
        {assigned && <Text variant="body2">{assigned}</Text>}
      </Grid>
      <Grid item py={0.5} xs={3}>
        <IconButton
          className={classes.iconButton}
          size="large"
          onClick={editTask}
        >
          <EditIcon className={classes.editIcon} />
        </IconButton>
        <Text inline variant="body2">
          {task?.name}
        </Text>
      </Grid>
      <TaskStateButtonMesh
        columns={timeColumns}
        context={TaskContext.WHITEBOARD}
        ref={scrollRef}
        scrollOffset={scrollOffset}
        taskId={taskId}
      />
    </Grid>
  )
}

export default WhiteboardTaskRow
