/* eslint-disable react/no-danger */
import React from 'react'
import { sanitize } from 'dompurify'
import * as R from 'ramda'
import {
  AddressUtils,
  BreedConstant,
  Business,
  Constant,
  DateFormat,
  DateUtils,
  LanguageUtils,
  moment,
  Nil,
  Patient,
  PhoneUtils,
  SignatureView,
  Utils,
} from '@pbt/pbt-ui-components'

import { getSpayedNeuteredIntactWithGenderString } from '~/components/common/inputs/gender/genderUtils'
import i18n from '~/locales/i18n'
import { Prescription } from '~/types'
import {
  getDEALicensesNumbers,
  getDVMLicensesNumbers,
  getFullBreedName,
  getManualInputSelectValue,
  getOtherLicensesNumbers,
  getPatientAge,
  getQuantityStr,
  joinString,
} from '~/utils'
import { getPrescriptionType } from '~/utils/prescription'

import {
  getFallbackUnit,
  getPrescriptionQuantityUnits,
  getVariationField,
} from '../utils/prescriptionUtils'

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    margin: '-8px -8px 0px -8px',
    height: 'calc(100% + 8px)',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '24px 24px 12px 24px',
  },
  text: {
    margin: 0,
    fontSize: 12,
    fontFamily: 'Rubik, sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
  },
  textRight: {
    textAlign: 'right',
  },
  businessName: {
    lineHeight: '24px',
    fontWeight: '500',
  },
  businessInfo: {
    lineHeight: '16px',
    marginBottom: 4,
  },
  divider: {
    display: 'flex',
    width: '100%',
    height: 0,
    borderBottom: '1px solid #D8D8D8',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 24,
  },
  infoColumn: {
    flex: 1 / 2,
  },
  infoFullWidthColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: '500',
  },
  infoText: {
    lineHeight: '14px',
    marginBottom: 4,
  },
  noPaddingTop: {
    paddingTop: 0,
  },
  notesContainer: {
    border: '1px solid #D8D8D8',
    width: '100%',
    minHeight: 50,
    marginTop: 16,
    padding: '8px 4px',
  },
  notes: {
    margin: 0,
    padding: 8,
  },
  signature: {
    marginTop: 60,
    flexDirection: 'column',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: '1px solid #4C4949',
    padding: '16px 24px 8px 24px',
    marginTop: 'auto',
  },
  footerText: {
    fontSize: 8,
  },
  footerGrayText: {
    fontSize: 8,
    color: '#7C7C7C',
  },
  instructionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '24px',
    paddingBottom: '24px',
  },
}

const formatTime = (date: string) =>
  moment(date).format(DateFormat.TIME_WITH_MERIDIAN_UPPER)

interface PrintInfo extends Prescription {
  business?: Business
}

interface PrescriptionPrintPageProps {
  constants: {
    Breed: BreedConstant
    DrugAdministrationFrequencies: Constant[]
    DrugDeliveryMethod: Constant[]
    DrugStrengthUnit: Constant[]
    Gender: Constant[]
    LicenseTypes: Constant[]
    PrescriptionProductSizeUnit: Constant[]
    ProductForm: Constant[]
    ProductSizeUnit: Constant[]
    Species: Constant[]
  }
  isExposeFlavorEnabled: boolean
  isFood: boolean
  isFoodCatalogEnabled: boolean
  isGlobalItem: boolean
  isSpain: boolean
  printInfo: PrintInfo
}

const PrescriptionPrintPage = ({
  printInfo,
  isExposeFlavorEnabled,
  isFoodCatalogEnabled,
  isFood,
  isGlobalItem,
  isSpain,
  constants: {
    Breed,
    Gender,
    Species,
    ProductSizeUnit,
    PrescriptionProductSizeUnit,
    DrugStrengthUnit,
    DrugDeliveryMethod,
    DrugAdministrationFrequencies,
    LicenseTypes,
    ProductForm,
  },
}: PrescriptionPrintPageProps) => {
  const getSpayedNeuteredGender = (patient: Patient | Nil) =>
    getSpayedNeuteredIntactWithGenderString(
      patient?.gender,
      patient?.spayedNeutered,
      Gender,
    )

  const getAgeWithDateOfBirth = (patient: Patient | Nil) => {
    if (!patient?.dateOfBirth) {
      return null
    }
    const age = getPatientAge(patient)
    if (!isSpain) {
      return age
    }
    const dateOfBirth = DateUtils.formatDate(patient?.dateOfBirth, true)
    return `${age} (${i18n.t(
      'Abbreviations:COMMON.DATE_OF_BIRTH',
    )}: ${dateOfBirth})`
  }

  const {
    client,
    signer,
    patient,
    drugInfo,
    signedDate,
    doctor,
    inventory,
    variation,
    signatureUrl,
    business,
    globalInventory,
    globalVariation,
    globalInventoryMapping,
    globalVariationMapping,
    quantityNumbRefills,
    quantityPerRefill,
    discussedWithClient,
    administrationQuantity,
    administrationCourseType,
    administrationCourseLength,
    totalQuantityUnitId,
    totalQuantityCustomUnit,
    quantityDrugExpirationDate,
  } = printInfo

  const processingGlobalInventory = isFoodCatalogEnabled
    ? globalInventoryMapping
    : globalInventory
  const processingGlobalVariation = isFoodCatalogEnabled
    ? globalVariationMapping
    : globalVariation

  const { administrationQuantityUnit, quantityPerRefillUnit } =
    getPrescriptionQuantityUnits(printInfo, {
      ProductSizeUnit,
      PrescriptionProductSizeUnit,
    })

  const formUnitType = Utils.getConstantName(
    getVariationField('formId', printInfo, isFoodCatalogEnabled) as string,
    ProductForm,
    '',
  )
  const formUnit =
    formUnitType === 'Other'
      ? drugInfo?.customForm || formUnitType
      : formUnitType

  const strengthUnits = Utils.getConstantName(
    getVariationField(
      'strengthUnitsId',
      printInfo,
      isFoodCatalogEnabled,
    ) as string,
    DrugStrengthUnit,
    '',
  )
  const deliveryMethodId =
    printInfo.administrationRouteId ||
    (getVariationField(
      'deliveryMethodId',
      printInfo,
      isFoodCatalogEnabled,
    ) as string)
  const deliveryMethod = Utils.getConstantName(
    deliveryMethodId,
    DrugDeliveryMethod,
    '',
  )
  const frequencyString = Utils.getConstantName(
    printInfo.administrationFrequencyId,
    DrugAdministrationFrequencies,
    '',
  )

  const administrationFrequency =
    frequencyString === 'Other'
      ? printInfo.administrationFrequencyNotes || ''
      : frequencyString
  const dayQuantityStr = getQuantityStr(
    administrationCourseLength,
    i18n.t('Constants:TIME_UNITS.DAY_ONE').toLowerCase(),
  )
  const administrationCourseLengthStr = `${administrationCourseLength} ${dayQuantityStr}`
  const lengthOfCourse = administrationCourseLength
    ? administrationCourseLengthStr
    : administrationCourseType
    ? LanguageUtils.capitalize(administrationCourseType.replace('_', ' '))
    : ''

  const signatureDateInBusinessTz = DateUtils.formatDateWithHours(signedDate)

  const quantityStr = getQuantityStr(
    administrationQuantity || 0,
    administrationQuantityUnit.toLowerCase(),
  )
  const administerRow =
    administrationQuantity && quantityStr
      ? `${i18n.t(
          'Common:ADMINISTER_ACTION',
        )} ${administrationQuantity} ${quantityStr}`
      : ''

  const quantityString = getQuantityStr(
    quantityPerRefill,
    quantityPerRefillUnit.toLowerCase(),
  )
  const refillStr =
    quantityPerRefill && quantityPerRefillUnit
      ? `${quantityPerRefill} ${quantityString} ${i18n.t('Common:PER_REFILL')}`
      : ''

  const { id: fallbackUnitId, customUnit: fallbackCustomUnit } =
    getFallbackUnit(
      (variation || processingGlobalVariation)?.perPackageUnitsId,
      PrescriptionProductSizeUnit,
      ProductSizeUnit,
    )

  const quantityUnit =
    getManualInputSelectValue(
      ProductSizeUnit,
      totalQuantityUnitId || fallbackUnitId,
      totalQuantityCustomUnit,
    ) ||
    fallbackCustomUnit ||
    ''

  const doctorDVMLicenseNumbers = getDVMLicensesNumbers(doctor, LicenseTypes)
  const doctorDEALicenseNumbers = getDEALicensesNumbers(doctor, LicenseTypes)
  const doctorOtherLicenseNumbers = getOtherLicensesNumbers(
    doctor,
    LicenseTypes,
  )
  const doctorDVMLicenceString = R.isEmpty(doctorDVMLicenseNumbers)
    ? ''
    : `${i18n.t(
        'Abbreviations:ACRONYMS.DOCTOR_OF_VETERINARY_MEDICINE',
      )}: ${doctorDVMLicenseNumbers}`
  const doctorDEALicenceString = R.isEmpty(doctorDEALicenseNumbers)
    ? ''
    : `${i18n.t(
        'Abbreviations:ACRONYMS.DEA.DEA_NUMBER',
      )}: ${doctorDEALicenseNumbers}`
  const doctorOtherLicenseString = R.isEmpty(doctorOtherLicenseNumbers)
    ? ''
    : `${i18n.t('Common:OTHER')}: ${doctorOtherLicenseNumbers}`

  const signerDVMLicenseNumbers = getDVMLicensesNumbers(signer, LicenseTypes)
  const signerDEALicenseNumbers = getDEALicensesNumbers(signer, LicenseTypes)
  const signerDVMLicenceString = R.isEmpty(signerDVMLicenseNumbers)
    ? ''
    : `${i18n.t(
        'Abbreviations:ACRONYMS.DOCTOR_OF_VETERINARY_MEDICINE_NUMBER_SIGN',
      )}: ${signerDVMLicenseNumbers}`
  const signerDEALicenceString = R.isEmpty(signerDEALicenseNumbers)
    ? ''
    : `${i18n.t(
        'Abbreviations:ACRONYMS.DEA.DEA_NUMBER_SIGN',
      )}: ${signerDEALicenseNumbers}`
  const { isScriptOut } = getPrescriptionType(printInfo?.prescriptionType)

  const signerLicenseString = isScriptOut
    ? undefined
    : `${signerDVMLicenceString}${
        signerDVMLicenceString && signerDEALicenceString ? ', ' : ''
      }${signerDEALicenceString}`

  const isLocalVariation = inventory && variation
  const flavorString =
    isExposeFlavorEnabled &&
    isLocalVariation &&
    (variation?.flavor || processingGlobalVariation?.flavor)
      ? ` | (${variation?.flavor || processingGlobalVariation?.flavor})`
      : ''

  const nameAndVariation = `${
    inventory?.name || processingGlobalInventory?.name
  }: ${variation?.name || processingGlobalVariation?.name}`
  const prescriptionPrintNameAndVariation = isFood
    ? `${nameAndVariation}${flavorString}`
    : `${nameAndVariation}: ${
        drugInfo?.strength || ''
      } ${strengthUnits} ${formUnit}${flavorString}`

  const clientPhone =
    client?.mobilePhone || client?.homePhone || client?.workPhone || ''

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div>
          <p style={{ ...styles.text, ...styles.businessName }}>
            {business?.name}
          </p>
          <p style={{ ...styles.text, ...styles.businessInfo }}>
            {AddressUtils.formatAddress(business)}
          </p>
          <p style={{ ...styles.text, ...styles.businessInfo }}>
            {PhoneUtils.formatPhoneNumber(business?.phone)}
          </p>
          <p style={{ ...styles.text, ...styles.businessInfo }}>
            {business?.website}
          </p>
          <p style={{ ...styles.text, ...styles.businessInfo }}>
            {business?.email}
          </p>
        </div>
        <div>
          <p style={{ ...styles.text, ...styles.textRight }}>
            {`${i18n
              .t('Abbreviations:COMMON.RECIPE_NUMBER_SIGN')
              .toUpperCase()}: ${printInfo.rxNo}`}
          </p>
          <p style={{ ...styles.text, ...styles.textRight }}>
            {i18n.t<string>(
              'Soap:PRESCRIPTION_PRINT_PAGE.PRESCRIPTION_ISSUED_DATE',
              {
                date: DateUtils.formatDate(new Date().toString()),
              },
            )}
          </p>
        </div>
      </div>
      <div style={styles.divider} />
      <div style={styles.infoContainer}>
        <div style={styles.infoColumn}>
          <p style={{ ...styles.text, ...styles.title, ...styles.infoText }}>
            {Utils.getPersonString(client)}
          </p>
          <p style={{ ...styles.text, ...styles.infoText }}>
            {PhoneUtils.formatPhoneNumber(clientPhone)}
          </p>
          {client?.address && (
            <p
              style={{ ...styles.text, ...styles.infoText }}
            >{`${client?.address},`}</p>
          )}
          <p style={{ ...styles.text, ...styles.infoText }}>
            {AddressUtils.getLocaleOrderedAddressList({
              city: client?.city,
              state: client?.state,
              zip: client?.zipcode,
            }).join(', ')}
          </p>
        </div>
        <div style={styles.infoColumn}>
          <p style={{ ...styles.text, ...styles.title, ...styles.infoText }}>
            {joinString(
              [
                patient?.name,
                LanguageUtils.getConstantTranslatedName(
                  patient?.species,
                  Species,
                  '',
                ),
              ],
              ' | ',
            )}
          </p>
          <p style={{ ...styles.text, ...styles.infoText }}>
            {joinString(
              [
                getAgeWithDateOfBirth(patient),
                getSpayedNeuteredGender(patient),
                getFullBreedName(patient?.species, patient?.breeds, Breed),
              ],
              ' | ',
            )}
          </p>
          {isSpain && patient?.microchipNumber && (
            <p style={{ ...styles.text, ...styles.infoText }}>
              {`${i18n.t('Common:MICROCHIP_NUMBER_SIGN')}: ${
                patient.microchipNumber
              }`}
            </p>
          )}
        </div>
      </div>
      <div style={styles.divider} />
      <div style={styles.infoContainer}>
        <div style={styles.infoColumn}>
          <p style={{ ...styles.text, ...styles.title, ...styles.infoText }}>
            {Utils.getPersonString(doctor)}
          </p>
          {isSpain && (
            <>
              {doctorDVMLicenceString && (
                <p style={{ ...styles.text, ...styles.infoText }}>
                  {doctorDVMLicenceString}
                </p>
              )}
              {doctorDEALicenceString && (
                <p style={{ ...styles.text, ...styles.infoText }}>
                  {doctorDEALicenceString}
                </p>
              )}
              {doctorOtherLicenseString && (
                <p style={{ ...styles.text, ...styles.infoText }}>
                  {doctorOtherLicenseString}
                </p>
              )}
            </>
          )}
          {!isSpain && processingGlobalInventory?.controlled && (
            <p style={{ ...styles.text, ...styles.infoText }}>
              {`${i18n.t('Abbreviations:ACRONYMS.DEA.DEA_NUMBER_SIGN')} ${
                doctor?.dea || ''
              }`}
            </p>
          )}
        </div>
      </div>
      <div style={styles.divider} />
      <div style={styles.infoContainer}>
        <div style={styles.infoColumn}>
          <p style={{ ...styles.text, ...styles.infoText }}>
            {prescriptionPrintNameAndVariation}
          </p>
          <p style={{ ...styles.text, ...styles.infoText }}>
            {`${i18n.t('Common:DISPENSE_ACTION')}: ${printInfo.totalQuantity} ${
              quantityUnit || ''
            }`}
          </p>
          {drugInfo?.description && (
            <p style={{ ...styles.text, ...styles.infoText }}>
              {`${i18n.t('Common:DESCRIPTION')}: ${drugInfo.description}`}
            </p>
          )}
          {quantityDrugExpirationDate && (
            <p style={{ ...styles.text, ...styles.infoText }}>
              {`${i18n.t('Common:EXPIRATION_DATE')}: ${DateUtils.formatDate(
                quantityDrugExpirationDate,
              )}`}
            </p>
          )}
        </div>
      </div>
      <div style={styles.instructionsContainer}>
        <div style={styles.infoColumn}>
          <p style={{ ...styles.text, ...styles.title, ...styles.infoText }}>
            {i18n.t<string>('Common:INSTRUCTIONS')}
          </p>
          {isGlobalItem && (
            <>
              {administerRow && (
                <p style={{ ...styles.text, ...styles.infoText }}>
                  {administerRow}
                </p>
              )}
              {deliveryMethod && (
                <p style={{ ...styles.text, ...styles.infoText }}>
                  {`${i18n.t(
                    'Common:ROUTE_OF_DRUG_ADMINISTRATION',
                  )}: ${deliveryMethod}`}
                </p>
              )}
              {administrationFrequency && (
                <p style={{ ...styles.text, ...styles.infoText }}>
                  {`${i18n.t(
                    'Common:FREQUENCY_ONE',
                  )}: ${administrationFrequency}`}
                </p>
              )}
              {lengthOfCourse && (
                <p style={{ ...styles.text, ...styles.infoText }}>
                  {i18n.t<string>('Common:LENGTH_OF_COURSE_DURATION', {
                    lengthOfCourse,
                  })}
                </p>
              )}
            </>
          )}
          {isFood && (
            <p
              dangerouslySetInnerHTML={{
                __html: sanitize(printInfo.notes || ''),
              }}
              style={{ ...styles.notes, ...styles.text }}
            />
          )}
        </div>
        {quantityNumbRefills > 0 && (
          <div style={styles.infoColumn}>
            <p style={{ ...styles.text, ...styles.title, ...styles.infoText }}>
              {i18n.t<string>('Common:REFILL_OTHER')}
            </p>
            {refillStr && (
              <p style={{ ...styles.text, ...styles.infoText }}>{refillStr}</p>
            )}
            <p style={{ ...styles.text, ...styles.infoText }}>
              {`${i18n.t('Common:REFILL_OTHER')}: ${quantityNumbRefills}`}
            </p>
            {printInfo.quantityRefillExpirationDate && (
              <p style={{ ...styles.text, ...styles.infoText }}>
                {i18n.t<string>('Common:REFILL_BY_DATE', {
                  date: DateUtils.formatDate(
                    printInfo.quantityRefillExpirationDate,
                  ),
                })}
              </p>
            )}
          </div>
        )}
      </div>
      {isGlobalItem && (
        <div style={{ ...styles.infoContainer, ...styles.noPaddingTop }}>
          <div style={styles.infoFullWidthColumn}>
            <p style={{ ...styles.text, ...styles.title, ...styles.infoText }}>
              {i18n.t<string>('Common:NOTES')}
            </p>
            <div style={styles.notesContainer}>
              <p
                dangerouslySetInnerHTML={{
                  __html: sanitize(printInfo.notes || ''),
                }}
                style={{ ...styles.notes, ...styles.text }}
              />
            </div>
          </div>
        </div>
      )}
      <div style={{ ...styles.infoContainer, ...styles.signature }}>
        <SignatureView
          signatureDate={signatureDateInBusinessTz}
          signatureString={signatureUrl}
          signerLicence={signerLicenseString}
          signerName={signer && Utils.getPersonString(signer)}
        />
      </div>
      <div style={styles.footer}>
        {discussedWithClient && (
          <p style={styles.footerText}>
            {i18n.t<string>('Soap:PRESCRIPTION_PRINT_PAGE.EXTRA_LABEL_USAGE')}
          </p>
        )}
        <p style={styles.footerText}>
          {i18n.t<string>('Soap:PRESCRIPTION_PRINT_PAGE.PAGE_ONE_OF_ONE')}{' '}
          <span style={styles.footerGrayText}>
            {i18n.t<string>(
              'Soap:PRESCRIPTION_PRINT_PAGE.PRINTED_DATE_AT_TIME',
              {
                date: DateUtils.formatDate(new Date().toString()),
                time: formatTime(new Date().toString()),
              },
            )}
          </span>
          {isSpain && ', '}
          {isSpain && (
            <span style={styles.footerGrayText}>
              {i18n.t<string>(
                'Soap:PRESCRIPTION_PRINT_PAGE.OWNER_COPY_EXPIRES_AFTER_30_DAYS',
              )}
            </span>
          )}
        </p>
      </div>
    </div>
  )
}

export default PrescriptionPrintPage
