import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import * as R from 'ramda'
import { Nil, Text } from '@pbt/pbt-ui-components'

import {
  Invoice as GraphqlInvoice,
  RefundInvoice,
} from '~/api/graphql/generated/types'
import DialogNames from '~/constants/DialogNames'
import { Invoice } from '~/types'
import useDialog from '~/utils/useDialog'

import { CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT } from '../common/tableConstants'

interface InvoiceTableEventTypeCellProps {
  invoice: Invoice | GraphqlInvoice | RefundInvoice
  multipleRowsLimit?: number
}

export const InvoiceTableEventTypeCell = ({
  invoice,
  multipleRowsLimit,
}: InvoiceTableEventTypeCellProps) => {
  const [openAppointmentDialog] = useDialog(DialogNames.EVENT)

  const openAppointment = (eventId: string | Nil) => {
    openAppointmentDialog({
      appointmentId: eventId,
      isAppointmentType: true,
    })
  }

  const { t } = useTranslation('Abbreviations')

  if ('event' in invoice && invoice.event) {
    const invoiceEvent = invoice.event

    return (
      <Text
        link
        variant="body2"
        onClick={() => openAppointment(invoiceEvent?.id)}
      >
        {invoiceEvent?.type?.name || ''}
      </Text>
    )
  }

  if ('sections' in invoice) {
    const sections = R.isNil(multipleRowsLimit)
      ? invoice.sections
      : invoice.sections.slice(0, multipleRowsLimit)

    return (
      <>
        {sections.map(({ id, event }, index) => (
          <Box
            alignItems="center"
            display="flex"
            // eslint-disable-next-line react/no-array-index-key
            key={`${id}_${index}`}
            minHeight={CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT}
            width="100%"
          >
            {event ? (
              <Text
                link
                variant="body2"
                onClick={() => openAppointment(event.id)}
              >
                {event.type?.name || ''}
              </Text>
            ) : (
              <Text variant="body2">
                {t(
                  'Abbreviations:ACRONYMS.OVER_THE_COUNTER.LABEL_ABBREVIATION',
                )}
              </Text>
            )}
          </Box>
        ))}
      </>
    )
  }

  return null
}
