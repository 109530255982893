import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

import OrderTooltip from '~/components/dashboard/soap/OrderTooltip'
import DialogNames from '~/constants/DialogNames'
import { OrderType } from '~/constants/SOAPStates'
import { fetchUnifiedOrderByTypeAndLogId } from '~/store/actions/orders'
import {
  fetchChargeSheetLineItem,
  getClientFinanceLoading,
} from '~/store/duck/clientFinanceData'
import { useGetDeleteChargeSheetItemAction } from '~/store/hooks/chargeSheet'
import { useIsIntegratedModality } from '~/store/hooks/imagingOrders'
import { useUpdateOrderNotes } from '~/store/hooks/orders'
import { getCurrentBusinessIsIdexxImagingEnabled } from '~/store/reducers/auth'
import { getProcedureCategory } from '~/store/reducers/constants'
import {
  getOrdersIsSending,
  getUnifiedOrder,
  getUnifiedOrderIsLoading,
} from '~/store/reducers/orders'
import { Order } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import ItemWithBadgesActions, {
  ItemWithBadgesActionsProps,
} from '../../item-with-badges/ItemWithBadgesActions'
import { useHandleDelete } from '../../utils/useHandleDelete'

const PROCEDURE_CATEGORY = {
  VACCINATION: 'Vaccinations',
  IMAGING: 'Imaging',
}

export interface ProcedureActionsLineItemProps
  extends ItemWithBadgesActionsProps {
  onDelete?: () => void
  order: Order
}

// TODO need to move it to new OpenApi model once it will be ready, delete doesn't need new model to work
const ProcedureActionsLineItem = ({
  onDelete: onDeleteProp,
  order: orderProp,
  ...rest
}: ProcedureActionsLineItemProps) => {
  const dispatch = useDispatch()

  const orderSoap = useSelector(getUnifiedOrder) || ({} as Order)

  const order = R.anyPass([R.isNil, R.isEmpty])(orderProp)
    ? orderSoap
    : orderProp

  const orderLoading = useSelector(getUnifiedOrderIsLoading)
  const ProcedureCategory = useSelector(getProcedureCategory)

  const onDeleteProcedure = useCloseAfterCreation(
    onDeleteProp,
    getClientFinanceLoading,
  )

  const [openVaccineDetailsDialog, closeVaccinationDetailsDialog] = useDialog(
    DialogNames.VACCINE_DETAILS,
  )
  const [openImagingOrderDialog] = useDialog(DialogNames.IMAGING_ORDER)

  const fetchOrder = () => {
    if (orderProp.logId) {
      dispatch(
        fetchUnifiedOrderByTypeAndLogId(orderProp.logId, OrderType.PROCEDURE),
      )
    }
  }

  useEffect(() => {
    fetchOrder()
  }, [orderProp.logId])

  const handleUpdateNotes = useUpdateOrderNotes(order)
  const isIntegratedModality = useIsIntegratedModality(
    order?.procedure?.modalityId,
  )
  const isImagingIntegrated = useSelector(
    getCurrentBusinessIsIdexxImagingEnabled,
  )
  const deleteAction = useGetDeleteChargeSheetItemAction({
    id: orderProp.id,
    modificationDate: orderProp.modificationDate,
  })
  const closeVaccinationDetailsDialogAfterFetch = useCloseAfterCreation(() => {
    dispatch(fetchChargeSheetLineItem({ id: orderProp.id }))
    closeVaccinationDetailsDialog()
  }, getOrdersIsSending)

  const showImagingEditButton = isImagingIntegrated && isIntegratedModality

  const { procedure: { category, procedureCategoryId } = {} } = order

  const editVaccination = () => {
    openVaccineDetailsDialog({
      clientId: orderProp.clientId,
      order: {
        ...order,
        logId: orderProp.logId,
        producerId: R.prop('producerId', orderProp),
        vaccinationDetails:
          orderSoap.vaccinationDetails || order.vaccinationDetails,
      },
      patientId: orderProp.patientId,
      onClose: () => {
        closeVaccinationDetailsDialogAfterFetch()
        fetchOrder()
      },
    })
  }

  const editImaging = () => {
    openImagingOrderDialog({
      soapLog: order,
      orderId: R.propOr(order?.imagingOrderId, 'procedureOrderId', order),
      soapId: orderProp.soapId,
      isInvoiceLineItem: true,
    })
  }

  const handlersMap = {
    [PROCEDURE_CATEGORY.VACCINATION]: editVaccination,
    ...(showImagingEditButton
      ? {
          [PROCEDURE_CATEGORY.IMAGING]: editImaging,
        }
      : {}),
  }

  const isVaccination =
    Utils.findConstantIdByName(
      PROCEDURE_CATEGORY.VACCINATION,
      ProcedureCategory,
    ) === procedureCategoryId

  const isImaging =
    Utils.findConstantIdByName(
      PROCEDURE_CATEGORY.IMAGING,
      ProcedureCategory,
    ) === procedureCategoryId

  const categoryById = isVaccination
    ? PROCEDURE_CATEGORY.VACCINATION
    : isImaging
    ? PROCEDURE_CATEGORY.IMAGING
    : undefined

  const handleEdit = category
    ? handlersMap[category]
    : categoryById
    ? handlersMap[categoryById]
    : undefined

  const handleDelete = useHandleDelete(
    orderProp,
    deleteAction,
    onDeleteProp && onDeleteProcedure,
  )

  return (
    <ItemWithBadgesActions
      hasNotes={Boolean(order.notes)}
      loading={orderLoading}
      tooltip={<OrderTooltip forceShowPrices order={order} />}
      onDelete={handleDelete}
      onEdit={handleEdit}
      onUpdateNotes={showImagingEditButton ? undefined : handleUpdateNotes}
      {...rest}
    />
  )
}

export default ProcedureActionsLineItem
