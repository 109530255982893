import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Button, ButtonProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      border: theme.constants.filterBorder,
      color: theme.colors.searchButton,
      padding: theme.spacing(0, 1.5, 0, 1),
      height: theme.spacing(4),
      borderRadius: theme.spacing(2),
      background: 'transparent',
    },
    active: {
      border: 'none',
      color: theme.colors.loginButtonText,
      background: theme.colors.title,
      '&&&&:hover': {
        background: theme.colors.title,
      },
    },
    icon: {
      width: 18,
      height: 18,
      marginRight: theme.spacing(0.5),
    },
    label: {
      color: 'inherit',
      fontWeight: 500,
      textTransform: 'none',
      marginLeft: theme.spacing(0.5),
    },
  }),
  { name: 'QuickFilterButton' },
)

export interface QuickFilterButtonProps extends ButtonProps {
  IconComponent?: React.JSXElementConstructor<any>
  IconProps?: any
  active?: boolean
  text?: React.ReactNode
}

const QuickFilterButton = ({
  active,
  className,
  IconComponent,
  IconProps = {},
  onClick,
  disabled,
  text,
  ...rest
}: QuickFilterButtonProps) => {
  const classes = useStyles()

  return (
    <Button
      disableRipple
      className={classNames(className, classes.root, {
        [classes.active]: active,
      })}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {IconComponent && (
        <IconComponent className={classes.icon} {...IconProps} />
      )}
      <Dotdotdot clamp={1}>
        <Text align="left" className={classes.label} variant="body2">
          {text}
        </Text>
      </Dotdotdot>
    </Button>
  )
}

export default QuickFilterButton
