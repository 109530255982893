import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ControlButtonGroup,
  ControlButtonGroupName,
  LanguageUtils,
  PermissionArea,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import ReminderStateLabel from '~/components/common/labels/ReminderStateLabel'
import { getDeletingRemindersMap } from '~/store/duck/reminders'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getEventState } from '~/store/reducers/constants'
import { Reminder } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    container: {
      '&:not(:last-child)': {
        borderBottom: theme.constants.tabBorder,
      },
    },
    text: {
      whiteSpace: 'pre-wrap',
    },
  }),
  { name: 'MultipleReminders' },
)

interface MultipleRemindersProps {
  enableBusinessShareIcon?: boolean
  onDelete?: (reminder: Reminder) => void
  onPreview: (reminder: Reminder) => void
  onUpdate: (newReminder: Reminder) => void
  reminders: Reminder[]
}

const MultipleReminders = ({
  reminders,
  onPreview,
  onUpdate,
  onDelete,
  enableBusinessShareIcon,
}: MultipleRemindersProps) => {
  const classes = useStyles()
  const permissions = useSelector(getCRUDByArea(PermissionArea.REMINDERS))
  const EventState = useSelector(getEventState)
  const ResolvedStateId = Utils.findConstantIdByName('Resolved', EventState)
  const OpenStateId = Utils.findConstantIdByName('Open', EventState)
  const deletingRemindersMap = useSelector(getDeletingRemindersMap)
  const theme = useTheme<PuiTheme>()

  return (
    <>
      {reminders.map((reminder) => (
        <Grid
          container
          item
          alignItems="center"
          className={classes.container}
          key={reminder.id}
        >
          <Grid container item>
            <Grid container item xs alignItems="center" pt={1.5} wrap="nowrap">
              <Text strong className={classes.text}>
                {LanguageUtils.getTranslatedFieldName(reminder)}
              </Text>
              {enableBusinessShareIcon && (
                <BusinessShareIcon
                  businessIds={reminder.businessId ? [reminder.businessId] : []}
                />
              )}
            </Grid>
            <Grid item>
              <ControlButtonGroup
                buttons={[
                  reminder.state.id === ResolvedStateId
                    ? {
                        name: ControlButtonGroupName.ROUNDED_CHECK,
                        onClick: () =>
                          onUpdate({ ...reminder, state: OpenStateId }),
                        IconProps: {
                          fillColor: theme.colors.tabLabel,
                        },
                      }
                    : {
                        name: ControlButtonGroupName.CHECK,
                        onClick: () =>
                          onUpdate({ ...reminder, state: ResolvedStateId }),
                      },
                  {
                    name: ControlButtonGroupName.PREVIEW,
                    onClick: () => onPreview(reminder),
                  },
                  permissions.delete &&
                    onDelete && {
                      name: ControlButtonGroupName.DELETE,
                      onClick: () => onDelete(reminder),
                      isLoading: Object.keys(deletingRemindersMap).includes(
                        reminder.id,
                      ),
                    },
                ]}
              />
            </Grid>
          </Grid>

          <Text className={classes.text}>{reminder.notes}</Text>

          {reminder.state?.id && (
            <Grid item>
              <ReminderStateLabel reminder={reminder} />
            </Grid>
          )}
        </Grid>
      ))}
    </>
  )
}

export default MultipleReminders
