import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  AlertIconType,
  Defaults,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { Attachment } from '@pbt/pbt-ui-components/src/icons'

import MultiFileSelector from '~/components/common/inputs/MultiFileSelector'
import DialogNames from '~/constants/DialogNames'
import { getLabVendors } from '~/store/reducers/constants'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    menuButton: {
      justifyContent: 'space-between',
      color: theme.colors.lowAccentText,
      textTransform: 'none',
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      cursor: 'pointer',
    },
  }),
  { name: 'LabTestAddAttachment' },
)

export interface LabTestAddAttachmentProps {
  clientId: string
  fileCount: number
  labTestIdentifier: string
  labTestLogId: string
  labTestName: string
  patientId: string
  vendorId: string
}

export function LabTestAddAttachment({
  labTestName,
  labTestLogId,
  vendorId,
  clientId,
  patientId,
  labTestIdentifier,
  fileCount,
}: LabTestAddAttachmentProps) {
  const { t } = useTranslation('Common')
  const classes = useStyles()
  const LabVendors = useSelector(getLabVendors)
  const [openLabTestAttachmentDialog] = useDialog(
    DialogNames.LAB_TEST_ATTACHMENT_DIALOG,
  )
  const [openFileAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const handleSelectedFiles = (selectedFiles: File[]) => {
    if (selectedFiles.length + fileCount > 10) {
      openFileAlert({
        iconType: AlertIconType.WARN,
        message: (
          <>
            {t('Common:INPUTS.FILE_SELECTOR.ALERT_MESSAGE_COUNT')}
            &nbsp;
            <a href={`mailto:${Defaults.SUPPORT_EMAIL}`}>
              {Defaults.SUPPORT_EMAIL}
            </a>
            .
          </>
        ),
      })
    } else {
      // Instead of saving files in Redux, we only save URLs because Redux warns of a mutation on the File object's lastModifiedDate field
      const fileURLsMetadata = selectedFiles.map((file) => ({
        url: URL.createObjectURL(file),
        fileName: file.name,
        fileType: file.type,
      }))
      openLabTestAttachmentDialog({
        initialFilesMetadata: fileURLsMetadata,
        initialTitle: labTestName,
        initialSource: Utils.getConstantName(vendorId, LabVendors),
        labTestLogId,
        clientId,
        patientId,
        labTestIdentifier,
        preexistingFileCount: fileCount,
      })
    }
  }

  return (
    <Grid container className={classes.menuButton}>
      <MultiFileSelector onSelected={handleSelectedFiles}>
        <Grid container item>
          <Attachment />
          <Grid item ml={0.5}>
            <Text variant="lowAccent">{t('Common:ADD_ATTACHMENT')}</Text>
          </Grid>
        </Grid>
      </MultiFileSelector>
    </Grid>
  )
}
