import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Grid, Hidden } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme } from '@pbt/pbt-ui-components'

import { fetchClient } from '~/store/actions/clients'
import { getUser } from '~/store/reducers/users'

import ClientAndPatientSection from '../clients/details/new-client-and-patient/ClientAndPatientSection'
import ClientInfoLinkType from '../clients/details/new-client-and-patient/ClientInfoLinkType'
import DetailsBackButton from '../clients/DetailsBackButton'
import MembershipTableComponent from './table/MembershipTableComponent'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    backButton: {
      paddingLeft: theme.spacing(2),
    },
    clientAndPatientColumn: {
      width: 'auto',
    },
  }),
  { name: 'MembershipPage' },
)

const MembershipPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const { clientId = '' } = useParams()
  const dispatch = useDispatch()
  const client = useSelector(getUser(clientId))
  const { t } = useTranslation('Common')

  const onBackButtonClick = () => {
    const { patientId } = (location.state || {}) as { patientId: string }
    const url = patientId ? `/patient/${patientId}` : `/client/${clientId}`

    navigate(url)
  }

  useEffect(() => {
    if (!client && clientId) {
      dispatch(fetchClient({ clientId }))
    }
  }, [clientId])

  return (
    <Grid container wrap="nowrap">
      <Hidden mdDown>
        <Grid
          container
          className={classes.clientAndPatientColumn}
          direction="column"
        >
          <ClientAndPatientSection
            clientId={clientId}
            highlight={ClientInfoLinkType.MEMBERSHIP}
          />
        </Grid>
      </Hidden>

      <Grid container direction="column" wrap="nowrap">
        <DetailsBackButton
          fullWidth
          thin
          className={classes.backButton}
          onClick={onBackButtonClick}
        >
          {t('Common:CLIENT_AND_PATIENT_DETAIL')}
        </DetailsBackButton>
        <Grid container item xs direction="column" pt={2} px={2} wrap="nowrap">
          <MembershipTableComponent clientId={clientId} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MembershipPage
