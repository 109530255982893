import React from 'react'
import { makeStyles } from '@mui/styles'
import { Nil, PuiTheme } from '@pbt/pbt-ui-components'

import { OrderType } from '~/constants/SOAPStates'
import { BaseChargeTabProps, OrderListType } from '~/types'

import ChargeListWithFilters from '../ChargeListWithFilters'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    listWithFiltersContainer: {
      padding: theme.spacing(2, 0, 0, 2),
    },
  }),
  { name: 'RemindersChargeTab' },
)

export interface RemindersChargeTabProps extends BaseChargeTabProps {
  appointmentId?: string
  clientId: string | Nil
  patientId?: string
}

const RemindersChargeTab = (props: RemindersChargeTabProps) => {
  const classes = useStyles()
  return (
    <ChargeListWithFilters
      includeSubItemsPrices
      classes={{
        listWithFiltersContainer: classes.listWithFiltersContainer,
      }}
      preselectedFilter={OrderType.REMINDER}
      preselectedListType={OrderListType.OUR}
      {...props}
    />
  )
}

export default RemindersChargeTab
