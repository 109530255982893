import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ControlButtonGroup,
  ControlButtonGroupName,
  Nil,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import LabTestsStateSelect from '~/components/dashboard/lab-tests-dashboard/lab-tests-table/LabTestsStateSelect'
import { LabTestState } from '~/constants/SOAPStates'
import { editOrder, removeOrder } from '~/store/actions/orders'
import { fetchClientFinanceCharges } from '~/store/duck/clientFinanceData'
import { getLabTestsStates } from '~/store/reducers/constants'
import { getFinanceIsFetching } from '~/store/reducers/finance'
import { getOrdersIsSending } from '~/store/reducers/orders'
import { Order } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      minHeight: 40,
      borderBottom: theme.constants.tableBorder,
    },
    name: {
      maxWidth: 440,
    },
    stateSelect: {
      marginRight: theme.spacing(16),
      marginLeft: 'auto',
    },
    controlButton: {
      padding: theme.spacing(0.5),
    },
  }),
  { name: 'LabTestRow' },
)

export interface LabTestRowProps {
  invoiceId: string | Nil
  labTest: Order
  ordered?: boolean
  refetchChargeSheet?: boolean
}

const LabTestRow = ({
  invoiceId,
  labTest,
  ordered,
  refetchChargeSheet,
}: LabTestRowProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const LabTestsStates = useSelector(getLabTestsStates)
  const isSendingOrders = useSelector(getOrdersIsSending)
  const isFetchingInvoice = useSelector(getFinanceIsFetching)
  const { t } = useTranslation('Common')

  const isLoading = isSendingOrders || isFetchingInvoice

  const DeclinedStateId = Utils.findConstantIdByName(
    LabTestState.DECLINED,
    LabTestsStates,
  )

  const { clientId } = labTest || {}

  const refetchChargeSheetCallback = useCloseAfterCreation(
    () => clientId && dispatch(fetchClientFinanceCharges({ id: clientId })),
    getOrdersIsSending,
  )

  const handleStateChange = (stateId: string) => {
    if (refetchChargeSheet) {
      refetchChargeSheetCallback()
    }
    dispatch(
      editOrder(
        { ...labTest, stateId },
        { invoiceId, fromInvoice: Boolean(invoiceId) },
      ),
    )
  }

  const handleDecline = () => {
    if (refetchChargeSheet) {
      refetchChargeSheetCallback()
    }
    handleStateChange(DeclinedStateId)
  }

  const handleDelete = () => {
    if (refetchChargeSheet) {
      refetchChargeSheetCallback()
    }
    dispatch(removeOrder(labTest, { invoiceId }))
  }

  const showDeleteDecline = !invoiceId

  const stateId =
    labTest.stateId !== null && labTest.stateId !== undefined
      ? labTest.stateId
      : labTest.labTest?.stateId

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Text className={classes.name} ml={2} py={1}>
        {labTest.name}
      </Text>
      {!ordered && (
        <>
          <LabTestsStateSelect
            className={classes.stateSelect}
            isLoading={isLoading}
            value={stateId}
            onChange={Utils.handleFormSelectInput(handleStateChange)}
          />
          {showDeleteDecline && (
            <Grid item mr={1}>
              <ControlButtonGroup
                buttons={[
                  {
                    isLoading,
                    name: ControlButtonGroupName.DECLINE,
                    tooltip: t('Common:CLIENT_DECLINED'),
                    onClick: handleDecline,
                  },
                  {
                    isLoading,
                    name: ControlButtonGroupName.DELETE,
                    onClick: handleDelete,
                  },
                ]}
                classes={{ iconButton: classes.controlButton }}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}

export default LabTestRow
