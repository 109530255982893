import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ImageScalingUtils,
  NumberUtils,
  PatientAvatar,
  PuiTheme,
  Text,
  TextInteractive,
  Utils,
} from '@pbt/pbt-ui-components'
import { Wings as WingsIcon } from '@pbt/pbt-ui-components/src/icons'

import AlertLabel from '~/components/common/labels/AlertLabel'
import BreedLabel from '~/components/common/labels/BreedLabel'
import NeuteringLabel from '~/components/common/labels/NeuteringLabel'
import Link from '~/components/common/link/Link'
import useGetPreferredContactMethod from '~/components/dashboard/clients/details/new-client-and-patient/useGetPreferredContactMethod'
import { AlertColorLevel, useGetAlertColor } from '~/constants/alertColors'
import DialogNames from '~/constants/DialogNames'
import { useAlertType } from '~/store/hooks/patient'
import { getSpecies } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { getPatientAge } from '~/utils'
import useDialog from '~/utils/useDialog'

const AVATAR_SIZE = 40

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    nameText: {
      padding: theme.spacing(0, 1),
      color: theme.colors.title,
      fontSize: '1.6rem',
      fontWeight: 500,
    },
    innerPatientContainer: {
      border: theme.constants.tabBorder,
    },
    notActiveAvatar: {
      opacity: 0.5,
    },
    alertLabelText: {
      fontSize: '1.6rem',
    },
    alertLabelRoot: {
      height: theme.spacing(3),
      borderRadius: 0,
    },
    alertLabelIcon: {
      fontSize: '1.6rem',
    },
    alertBorder: {
      border: theme.constants.errorBorder,
      borderTopLeftRadius: 2,
      borderTopRightRadius: 2,
    },
    clientAlertBorder: {
      borderColor: (props: UseStylesProps) => props.clientAlertColor,
      padding: theme.spacing(1, 0),
    },
    patientAlertBorder: {
      borderColor: (props: UseStylesProps) => props.patientAlertColor,
    },
    wingsIcon: {
      color: theme.colors.title,
      width: '1.5em',
      height: '1.5em',
      marginRight: theme.spacing(1),
      opacity: 0.5,
    },
  }),
  { name: 'ClientView' },
)

interface UseStylesProps {
  clientAlertColor: string
  patientAlertColor: string
}

export interface ClientViewProps {
  clientId?: string
  patientId?: string
}

const ClientView = ({ clientId, patientId }: ClientViewProps) => {
  const { t } = useTranslation('Common')

  const Species = useSelector(getSpecies)
  const patient = useSelector(getPatient(patientId))
  const client = useSelector(getUser(clientId))

  const preferredContactMethod = useGetPreferredContactMethod({ client })
  const patientAlertColor = useGetAlertColor(
    patient?.alertColorId,
    AlertColorLevel.PATIENT,
  )
  const clientAlertColor = useGetAlertColor(
    client?.alertColorId,
    AlertColorLevel.CLIENT,
  )

  const classes = useStyles({ patientAlertColor, clientAlertColor })

  const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
    patient?.photo,
    patient?.photoThumbnail,
    AVATAR_SIZE,
  )

  const { notes: clientNotes = '', balance } = client || {}

  const [openRemindersListDialog] = useDialog(DialogNames.REMINDERS_LIST)

  const handleOpenReminders = () => {
    openRemindersListDialog({ patientId })
  }

  const { alertsToRender } = useAlertType(patientId)
  const hasPatientAlerts = !R.isEmpty(alertsToRender)

  return (
    <Grid container direction="column" rowSpacing={1}>
      <Grid container item px={2}>
        <Grid
          container
          item
          alignItems="flex-end"
          className={classNames({
            [classes.alertBorder]: client?.alertText,
            [classes.clientAlertBorder]: client?.alertText,
          })}
        >
          <Grid item>
            <Link
              className={classes.nameText}
              rel="noopener noreferrer"
              target="_blank"
              to={`/client/${clientId}`}
            >
              {Utils.getPersonString(client)}
            </Link>
          </Grid>
          <Grid item>
            <Text pr={0.5}>{preferredContactMethod.value}</Text>
          </Grid>
          <Grid item>
            {!R.isNil(balance) && (
              <Text pr={0.5}>{`| ${t(
                'Common:BALANCE',
              )}: ${NumberUtils.formatMoney(balance)}`}</Text>
            )}
          </Grid>
          <Grid container>
            <Dotdotdot clamp={1}>
              <TextInteractive px={1} variant="lowAccent2">
                {clientNotes}
              </TextInteractive>
            </Dotdotdot>
          </Grid>
        </Grid>
        {client?.alertText && (
          <Grid container item>
            <AlertLabel
              showIcon
              alertColorId={client?.alertColorId}
              alertColorLevel={AlertColorLevel.CLIENT}
              classes={{
                icon: classes.alertLabelIcon,
                root: classes.alertLabelRoot,
                text: classes.alertLabelText,
              }}
              message={client?.alertText}
            />
          </Grid>
        )}
      </Grid>
      <Grid item px={2}>
        <Grid
          container
          item
          className={classNames(classes.innerPatientContainer, {
            [classes.alertBorder]: hasPatientAlerts,
            [classes.patientAlertBorder]: hasPatientAlerts,
          })}
          direction="row-reverse"
          py={1}
          wrap="nowrap"
        >
          <Grid item mr={1}>
            <PatientAvatar
              small
              animal={Utils.getConstantName(patient?.species, Species)}
              className={classNames({
                [classes.notActiveAvatar]: !patient?.active,
              })}
              src={avatarSrc}
            />
          </Grid>
          <Grid container item alignItems="center" wrap="nowrap">
            {patient?.name && (
              <Grid
                container
                item
                alignItems="center"
                width="fit-content"
                wrap="nowrap"
              >
                <Link
                  className={classes.nameText}
                  rel="noopener noreferrer"
                  target="_blank"
                  to={`/patient/${patientId}`}
                >
                  {patient?.name}
                </Link>
                {patient?.deceased && (
                  <WingsIcon className={classes.wingsIcon} />
                )}
              </Grid>
            )}
            <Grid item>
              {patient?.dateOfBirth && (
                <Text noWrap pr={0.5}>
                  {getPatientAge(patient)}
                </Text>
              )}
            </Grid>
            <Grid item>
              <NeuteringLabel patient={patient} px={1} />
            </Grid>
            <Grid item>
              <BreedLabel patient={patient} px={1} />
            </Grid>
            <Grid item>
              <Text link px={1} onClick={handleOpenReminders}>
                {t('Common:REMINDERS')}
              </Text>
            </Grid>
            {patient?.notes && (
              <Grid item>
                <Dotdotdot clamp={1}>
                  <TextInteractive px={1} variant="lowAccent2">
                    {patient?.notes}
                  </TextInteractive>
                </Dotdotdot>
              </Grid>
            )}
          </Grid>
        </Grid>
        {hasPatientAlerts && (
          <Grid container item>
            <AlertLabel
              showIcon
              alertColorId={patient?.alertColorId}
              alertColorLevel={AlertColorLevel.PATIENT}
              alertTypes={alertsToRender}
              classes={{
                icon: classes.alertLabelIcon,
                root: classes.alertLabelRoot,
                text: classes.alertLabelText,
              }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default ClientView
