import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Defaults, InfiniteLoaderList, PuiTheme } from '@pbt/pbt-ui-components'

import {
  clearBulkPrices,
  getAllSelected,
  getBulkPricesFilter,
  getBulkPricesList,
  getBulkPricesTotalCount,
  setAllSelected,
} from '~/store/duck/bulkPrices'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import BulkPricesEditFilters, {
  BulkPricesCategoryConstant,
  BulkPricesSubCategoryConstant,
} from './BulkPricesEditFilters'
import BulkPricesEditListHeader from './BulkPricesEditListHeader'
import BulkPricesEditRow from './BulkPricesEditRow'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    header: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
    },
  }),
  { name: 'BulkPricesEditListHeader' },
)

interface BulkPricesListStepProps {
  categoryLabel?: string
  categoryName: BulkPricesCategoryConstant
  loadItems: (
    from: number,
    to: number,
    category: string | number,
    subCategory: string | number,
  ) => void
  subCategoryName?: BulkPricesSubCategoryConstant
}

const BulkPricesListStep = ({
  loadItems,
  categoryLabel,
  categoryName,
  subCategoryName,
}: BulkPricesListStepProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const list = useSelector(getBulkPricesList)
  const totalCount = useSelector(getBulkPricesTotalCount)
  const allSelected = useSelector(getAllSelected)
  const categoryValue = useSelector(getBulkPricesFilter(categoryName))
  const subCategoryValue = useSelector(getBulkPricesFilter(subCategoryName))
  const filtersAreSelected =
    categoryValue && (!subCategoryName || subCategoryValue)

  const [allExpanded, setAllExpanded] = useState(false)

  const loadMoreItems = (from: number, to: number) =>
    loadItems(from, to, categoryValue!, subCategoryValue!)
  const isItemLoaded = (index: number) => Boolean(list[index])

  const handleSelectionChange = (newAllSelected: boolean) => {
    dispatch(setAllSelected({ value: newAllSelected }))
  }

  useEffectExceptOnMount(() => {
    if (filtersAreSelected) {
      dispatch(clearBulkPrices())
      loadMoreItems(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT)
    }
  }, [categoryValue, subCategoryValue])

  return (
    <Grid container direction="column" flex={1} pt={2} wrap="nowrap">
      <Grid container item className={classes.header} direction="column" px={3}>
        <BulkPricesEditFilters
          categoryLabel={categoryLabel}
          categoryName={categoryName}
          subCategoryName={subCategoryName}
        />
        {filtersAreSelected && (
          <BulkPricesEditListHeader
            allExpanded={allExpanded}
            allSelected={allSelected}
            onExpansionChange={setAllExpanded}
            onSelectionChange={handleSelectionChange}
          />
        )}
      </Grid>
      <Grid container item direction="column" flex={1} px={3}>
        {filtersAreSelected && (
          <InfiniteLoaderList
            firstItemMargin={8}
            isItemLoaded={isItemLoaded}
            itemCount={totalCount}
            itemData={list}
            loadMoreItems={loadMoreItems}
          >
            {(itemId) => (
              <BulkPricesEditRow expanded={allExpanded} itemId={itemId} />
            )}
          </InfiniteLoaderList>
        )}
      </Grid>
    </Grid>
  )
}

export default BulkPricesListStep
