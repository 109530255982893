import React, { CSSProperties } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  LanguageUtils,
  PuiTheme,
  StateLabel,
  Text,
} from '@pbt/pbt-ui-components'

import { useAlertType } from '~/store/hooks/patient'
import { getEventState } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { getUserLastName } from '~/store/reducers/users'
import { htmlDecode } from '~/utils/htmlUtils'

import PatientMembershipIcon from '../../clients/patient/PatientMembershipIcon'
import CardAlert from './CardAlert'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    content: {
      position: 'relative',
    },
    contentUltraDense: {
      paddingTop: 0,
    },
    contentDense: {
      padding: theme.spacing(0.5, 1, 0),
    },
    disabledText: {
      color: theme.colors.disabledLabelText,
    },
    warningText: {
      color: theme.colors.important,
    },
    normalText: {
      color: theme.colors.title,
    },
    cardTextTiny: {
      fontSize: '0.8rem',
    },
    label: {
      display: 'block',
      padding: 0,
      wordBreak: 'break-word',
      marginRight: theme.spacing(1),
    },
    successLabel: {
      backgroundColor: theme.colors.successBackground,
      color: theme.colors.success,
    },
    warningMessage: {
      paddingTop: 1,
      paddingBottom: 1,
      paddingLeft: theme.spacing(0.75),
      paddingRight: theme.spacing(0.75),
      backgroundColor: theme.colors.important,
      borderRadius: 2,
      color: theme.colors.profileText,
    },
    membershipIcon: {
      position: 'absolute',
      right: 4,
      top: 4,
      fontSize: '1.7rem',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
    },
  }),
  { name: 'CondensedCard' },
)

export interface CondensedCardProps {
  appointmentId: string
  contentStyle?: CSSProperties
  isDisabledState?: boolean
  isHalfWidth?: boolean
  isNormalState?: boolean
  isSuccessState?: boolean
  isWarningState?: boolean
  showAppointmentType?: boolean
  warningMessage?: string
}

const CondensedCard = ({
  appointmentId,
  isDisabledState,
  isNormalState,
  isSuccessState,
  isWarningState,
  isHalfWidth,
  warningMessage,
  contentStyle,
  showAppointmentType,
}: CondensedCardProps) => {
  const classes = useStyles()
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const EventState = useSelector(getEventState)
  const patient = useSelector(getPatient(appointment?.patient))
  const lastName = useSelector(getUserLastName(appointment?.client)) || ''

  const appointmentTypeDisplayName = appointment?.businessAppointmentType?.name

  const patientName = patient?.name || ''

  const { alertsToRender } = useAlertType(appointment?.patient)
  const showAlertLabel = alertsToRender.length > 0

  const textColorClasses = classNames({
    [classes.warningText]: isWarningState,
    [classes.disabledText]: isDisabledState,
    [classes.normalText]: isNormalState,
    [classes.successLabel]: isSuccessState,
  })

  return (
    <Grid
      container
      item
      direction={isHalfWidth ? 'column' : 'row'}
      style={contentStyle}
    >
      {showAlertLabel && (
        <CardAlert
          alertColorId={patient?.alertColorId}
          alertTypes={alertsToRender}
          isDense={!isHalfWidth}
        />
      )}
      <Grid
        container
        item
        xs
        className={classNames(classes.content, {
          [classes.contentDense]: showAlertLabel && !isHalfWidth,
          [classes.contentUltraDense]: showAlertLabel && isHalfWidth,
        })}
        direction="column"
        pb={0.25}
        pt={1}
        px={1}
        wrap="nowrap"
      >
        <PatientMembershipIcon
          hideIfNoMembership
          className={classes.membershipIcon}
          patientId={appointment?.patient}
        />
        <Grid item xs>
          <Dotdotdot clamp={1}>
            <Text strong className={textColorClasses} variant="subheading3">
              {isHalfWidth
                ? `${patientName} ${lastName[0]}.`
                : `${patientName} ${lastName}`}
            </Text>
          </Dotdotdot>
          {isHalfWidth && showAppointmentType && appointment?.typeId && (
            <Dotdotdot clamp={1}>
              <Text
                className={classNames(textColorClasses, {
                  [classes.cardTextTiny]: isHalfWidth,
                })}
                variant="body3"
              >
                {appointmentTypeDisplayName}
              </Text>
            </Dotdotdot>
          )}
          {!isHalfWidth && Boolean(appointment?.notes) && (
            <Dotdotdot clamp={2}>
              <Text sx={{ wordBreak: 'break-word' }} variant="body4">
                {htmlDecode(appointment?.notes || '')}
              </Text>
            </Dotdotdot>
          )}
        </Grid>
        <Grid container item lineHeight={1}>
          {!isHalfWidth && isWarningState && warningMessage && (
            <Dotdotdot clamp={1}>
              <Text
                className={classes.warningMessage}
                display="inline"
                variant="body3"
              >
                {warningMessage}
              </Text>
            </Dotdotdot>
          )}
        </Grid>
        <Grid container item alignItems="flex-end" wrap="nowrap">
          <Dotdotdot clamp={1}>
            <StateLabel
              className={classes.label}
              disabled={isDisabledState}
              display="inline"
              normal={isNormalState}
              success={isSuccessState}
              variant="small"
              warning={isWarningState}
            >
              {LanguageUtils.getConstantTranslatedName(
                appointment?.stateId,
                EventState,
              )}
            </StateLabel>
          </Dotdotdot>
          {!isHalfWidth && showAppointmentType && appointment?.typeId && (
            <Dotdotdot clamp={1}>
              <Text className={textColorClasses} variant="body4">
                {appointmentTypeDisplayName}
              </Text>
            </Dotdotdot>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CondensedCard
