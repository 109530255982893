import * as R from 'ramda'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { ApiError, Nil, TableFilter } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import FeatureToggle from '~/constants/featureToggle'
import { BaseTableFilters, Shipment, ShipmentItem } from '~/types'

import {
  CREATE_SHIPMENT,
  CREATE_SHIPMENT_ITEM,
  CREATE_SHIPMENT_ITEM_SUCCESS,
  createShipment,
  createShipmentFailure,
  createShipmentItem,
  createShipmentItemFailure,
  createShipmentItemSuccess,
  createShipmentSuccess,
  DELETE_SHIPMENT,
  DELETE_SHIPMENT_ITEM,
  DELETE_SHIPMENT_ITEM_SUCCESS,
  deleteShipment,
  deleteShipmentFailure,
  deleteShipmentItem,
  deleteShipmentItemFailure,
  deleteShipmentItemSuccess,
  deleteShipmentSuccess,
  EDIT_SHIPMENT,
  EDIT_SHIPMENT_ITEM,
  EDIT_SHIPMENT_ITEM_SUCCESS,
  editShipment,
  editShipmentFailure,
  editShipmentItem,
  editShipmentItemFailure,
  editShipmentItemSuccess,
  editShipmentSuccess,
  FETCH_MORE_SHIPMENTS,
  FETCH_SHIPMENT,
  FETCH_SHIPMENTS,
  fetchMoreShipments,
  fetchMoreShipmentsFailure,
  fetchMoreShipmentsSuccess,
  fetchShipment,
  fetchShipmentFailure,
  fetchShipments,
  fetchShipmentsFailure,
  fetchShipmentsSuccess,
  fetchShipmentSuccess,
  getShipment,
  getShipmentsFilters,
} from '../duck/shipments'
import { getFeatureToggle } from '../reducers/constants'
import { refreshOnHandCatalog } from './onHandCatalog'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

const serializeFilters = (filters: BaseTableFilters) =>
  Object.entries(filters).reduce((acc, [key, filter]) => {
    if (filter?.value) {
      acc[key] = filter.value.split(',').filter(Boolean)
    }
    return acc
  }, {} as Record<string, string[]>)

const serializeFiltersV2 = (filters: Record<string, TableFilter>) =>
  Object.keys(filters).reduce(
    (acc, key) => ({
      ...acc,
      [key]: filters[key]?.value,
    }),
    {} as Record<string, TableFilter['value']>,
  )

export function* fetchShipmentsSaga({
  from,
  to,
}: ReturnType<typeof fetchShipments>) {
  try {
    const isOptimizeInventoryShipmentsLoadingEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.OPTIMIZE_INVENTORY_SHIPMENTS_LOADING),
    )
    const filters: BaseTableFilters = yield select(getShipmentsFilters) || {}
    const serializedFilters = isOptimizeInventoryShipmentsLoadingEnabled
      ? serializeFiltersV2(filters)
      : serializeFilters(filters)
    const hasFilters = serializedFilters && !R.isEmpty(serializedFilters)

    const endpoint = hasFilters
      ? isOptimizeInventoryShipmentsLoadingEnabled
        ? API.fetchShipmentsWithFilterV2
        : API.fetchShipmentsWithFilter
      : isOptimizeInventoryShipmentsLoadingEnabled
      ? API.fetchShipmentsV2
      : API.fetchShipments

    const {
      result: { data: list, totalCount },
      entities,
    } = yield requestAPI(endpoint, from, to, serializedFilters)

    yield call(updateEntities, entities)
    yield put(fetchShipmentsSuccess(list, totalCount))
  } catch (error) {
    yield put(fetchShipmentsFailure(error as ApiError))
  }
}

export function* fetchMoreShipmentsSaga({
  from,
  to,
}: ReturnType<typeof fetchMoreShipments>) {
  try {
    const isOptimizeInventoryShipmentsLoadingEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.OPTIMIZE_INVENTORY_SHIPMENTS_LOADING),
    )
    const filters: BaseTableFilters = yield select(getShipmentsFilters) || {}
    const serializedFilters = isOptimizeInventoryShipmentsLoadingEnabled
      ? serializeFiltersV2(filters)
      : serializeFilters(filters)
    const hasFilters = serializedFilters && !R.isEmpty(serializedFilters)

    const endpoint = hasFilters
      ? isOptimizeInventoryShipmentsLoadingEnabled
        ? API.fetchShipmentsWithFilterV2
        : API.fetchShipmentsWithFilter
      : isOptimizeInventoryShipmentsLoadingEnabled
      ? API.fetchShipmentsV2
      : API.fetchShipments

    const {
      result: { data: list, totalCount },
      entities,
    } = yield requestAPI(endpoint, from, to, serializedFilters)

    yield call(updateEntities, entities)
    yield put(fetchMoreShipmentsSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreShipmentsFailure(error as ApiError))
  }
}

export function* fetchShipmentSaga({
  shipmentId,
}: ReturnType<typeof fetchShipment>) {
  try {
    const { entities } = yield requestAPI(API.fetchShipment, shipmentId)
    yield call(updateEntities, entities)
    yield put(fetchShipmentSuccess(shipmentId))
  } catch (error) {
    yield put(fetchShipmentFailure(error as ApiError))
  }
}

export function* createShipmentSaga({
  shipment,
}: ReturnType<typeof createShipment>) {
  try {
    const { result, entities } = yield requestAPI(API.createShipment, shipment)
    yield refreshOnHandCatalog()
    yield call(updateEntities, entities)
    yield put(createShipmentSuccess(result))
  } catch (error) {
    yield put(createShipmentFailure(error as ApiError))
  }
}

export function* editShipmentSaga({
  shipment,
  withItems = false,
}: ReturnType<typeof editShipment>) {
  try {
    const { result, entities } = yield requestAPI(
      API.editShipment,
      shipment,
      withItems,
    )
    yield refreshOnHandCatalog()
    yield call(updateEntities, entities)
    yield put(editShipmentSuccess(result))
  } catch (error) {
    yield put(editShipmentFailure(error as ApiError))
  }
}

export function* deleteShipmentSaga({
  shipmentId,
}: ReturnType<typeof deleteShipment>) {
  try {
    yield requestAPI(API.deleteShipment, shipmentId)
    yield put(deleteShipmentSuccess(shipmentId))
  } catch (error) {
    yield put(deleteShipmentFailure(error as ApiError))
  }
}

export function* deleteShipmentItemSaga({
  shipmentId,
  shipmentItemId,
}: ReturnType<typeof deleteShipmentItem>) {
  try {
    yield requestAPI(API.deleteShipmentItem, shipmentItemId)
    yield put(deleteShipmentItemSuccess(shipmentId, shipmentItemId))
  } catch (error) {
    yield put(deleteShipmentItemFailure(error as ApiError))
  }
}

export function* createShipmentItemSaga({
  shipmentId,
  shipmentItem,
}: ReturnType<typeof createShipmentItem>) {
  try {
    const createdShipmentItem: ShipmentItem = yield requestAPI(
      API.createShipmentItem,
      { ...shipmentItem, shipmentId },
    )
    yield put(createShipmentItemSuccess(shipmentId, createdShipmentItem))
  } catch (error) {
    yield put(createShipmentItemFailure(error as ApiError))
  }
}

export function* editShipmentItemSaga({
  shipmentId,
  shipmentItem,
}: ReturnType<typeof editShipmentItem>) {
  try {
    const updatedShipmentItem: ShipmentItem = yield requestAPI(
      API.editShipmentItem,
      { ...shipmentItem, shipmentId },
    )
    yield put(editShipmentItemSuccess(shipmentId, updatedShipmentItem))
  } catch (error) {
    yield put(editShipmentItemFailure(error as ApiError))
  }
}

// actualize shipment counted value(costTotal) after updating nested items
export function* updateShipmentItemSuccessSaga({
  shipmentId,
}:
  | ReturnType<typeof createShipmentItemSuccess>
  | ReturnType<typeof editShipmentItemSuccess>
  | ReturnType<typeof deleteShipmentItemSuccess>) {
  const isOptimizeInventoryShipmentsLoadingEnabled: boolean = yield select(
    getFeatureToggle(FeatureToggle.OPTIMIZE_INVENTORY_SHIPMENTS_LOADING),
  )
  const shipment: Shipment | Nil = yield select(getShipment(shipmentId))
  if (shipment) {
    if (isOptimizeInventoryShipmentsLoadingEnabled) {
      // remove items from the request to speed up the server response
      yield put(editShipment({ ...shipment, items: undefined }, false))
    } else {
      yield put(editShipment(shipment))
    }
  }
}

function* watchUpdateShipmentItemSuccess() {
  yield takeLatest(
    [
      CREATE_SHIPMENT_ITEM_SUCCESS,
      EDIT_SHIPMENT_ITEM_SUCCESS,
      DELETE_SHIPMENT_ITEM_SUCCESS,
    ],
    updateShipmentItemSuccessSaga,
  )
}

function* watchFetchShipments() {
  yield takeLatest(FETCH_SHIPMENTS, fetchShipmentsSaga)
}

function* watchFetchShipment() {
  yield takeLatest(FETCH_SHIPMENT, fetchShipmentSaga)
}

function* watchFetchMoreShipments() {
  yield takeLatest(FETCH_MORE_SHIPMENTS, fetchMoreShipmentsSaga)
}

function* watchCreateShipment() {
  yield takeLatest(CREATE_SHIPMENT, createShipmentSaga)
}

function* watchEditShipment() {
  yield takeLatest(EDIT_SHIPMENT, editShipmentSaga)
}

function* watchDeleteShipment() {
  yield takeLatest(DELETE_SHIPMENT, deleteShipmentSaga)
}

function* watchCreateShipmentItem() {
  yield takeLatest(CREATE_SHIPMENT_ITEM, createShipmentItemSaga)
}

function* watchEditShipmentItem() {
  yield takeLatest(EDIT_SHIPMENT_ITEM, editShipmentItemSaga)
}

function* watchDeleteShipmentItem() {
  yield takeLatest(DELETE_SHIPMENT_ITEM, deleteShipmentItemSaga)
}

export default function* shipmentsSaga() {
  yield all([
    watchFetchShipments(),
    watchFetchMoreShipments(),
    watchFetchShipment(),
    watchCreateShipment(),
    watchEditShipment(),
    watchDeleteShipment(),
    watchDeleteShipmentItem(),
    watchCreateShipmentItem(),
    watchEditShipmentItem(),
    watchUpdateShipmentItemSuccess(),
  ])
}
