import React from 'react'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'

import AlertLabel, {
  AlertLabelProps,
} from '~/components/common/labels/AlertLabel'

const useStyles = makeStyles(
  () => ({
    alertContainer: {
      width: 'fit-content',
    },
    alertIcon: {
      width: 14,
    },
  }),
  { name: 'CompactAlert' },
)

type CompactAlertProps = AlertLabelProps & { className?: string }

export const CompactAlert = ({
  className,
  variant = 'attention',
  ...props
}: CompactAlertProps) => {
  const classes = useStyles()

  return (
    <AlertLabel
      showIcon
      classes={{
        root: classNames(classes.alertContainer, className),
        icon: classes.alertIcon,
      }}
      variant={variant}
      {...props}
    />
  )
}
