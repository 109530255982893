import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme } from '@pbt/pbt-ui-components'
import {
  AAHASimple as AAHASimpleIcon,
  AddNote as AddNoteIcon,
  Analytics as AnalyticsIcon,
  ChewySubmark as OmniChannelIcon,
  MobilePhone as MobilePhoneIcon,
  Pim as PimIcon,
  WellnessClear as WellnessClearIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { BusinessTypeName } from '~/constants/businesses'

export const BusinessTypeLabelIcon = {
  [BusinessTypeName.AAHA]: AAHASimpleIcon,
  [BusinessTypeName.ANALYTICS]: AnalyticsIcon,
  [BusinessTypeName.PHONE]: MobilePhoneIcon,
  [BusinessTypeName.PIMS]: PimIcon,
  [BusinessTypeName.WELLNESS]: WellnessClearIcon,
  [BusinessTypeName.OMNICHANNEL]: OmniChannelIcon,
}

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      color: theme.colors.signIconBackground,
    },
  }),
  { name: 'BusinessTypeIcon' },
)

export interface BusinessTypeIconProps {
  className?: string
  iconName?: string
}

const BusinessTypeIcon = ({ iconName, className }: BusinessTypeIconProps) => {
  const classes = useStyles()

  const IconComponent =
    (iconName && BusinessTypeLabelIcon[iconName as BusinessTypeName]) ||
    AddNoteIcon

  return <IconComponent className={classNames(classes.root, className)} />
}

export default BusinessTypeIcon
