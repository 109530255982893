import React from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, PuiTheme, Text } from '@pbt/pbt-ui-components'
import { Plus as PlusIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    rangeSpan: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inlineSpan: {
      verticalAlign: 'middle',
      display: 'inline-flex',
    },
    gutter: {
      marginLeft: theme.spacing(1),
    },
    icon: {
      color: theme.colors.title,
    },
    closeIcon: {
      fontSize: '1.6rem',
    },
  }),
  { name: 'RangeButton' },
)

export interface RangeButtonProps {
  classes?: ClassesType<typeof useStyles>
  inline?: boolean
  isSingleQuantity?: boolean
  toggleRange: () => void
}

const RangeButton = ({
  inline,
  isSingleQuantity,
  toggleRange,
  classes: classesProp,
}: RangeButtonProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Common', 'Tooltips'])

  const title: string = isSingleQuantity
    ? t('Tooltips:ADD_RANGE')
    : t('Tooltips:REMOVE_RANGE')

  return (
    <Tooltip placement="top" title={title}>
      <Text
        link
        className={classNames(classes.rangeSpan, {
          [classes.gutter]: !isSingleQuantity,
          [classes.inlineSpan]: inline,
        })}
        variant="h5"
        onClick={toggleRange}
      >
        {isSingleQuantity ? (
          <PlusIcon className={classes.icon} />
        ) : (
          <CloseIcon className={classNames(classes.closeIcon, classes.icon)} />
        )}
        {t('Common:RANGE').toLowerCase()}
      </Text>
    </Tooltip>
  )
}

export default RangeButton
