import React from 'react'
import { useSelector } from 'react-redux'
import { FormControlLabel, Radio, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PuiTheme, PuiTooltip } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { PrescriptionWorkflowType } from '~/constants/PrescriptionWorkflow'
import { getFeatureToggle } from '~/store/reducers/constants'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    label: {
      fontSize: '1.6rem',
      marginRight: theme.spacing(2),
    },
    tooltipIcon: {
      backgroundColor: theme.colors.secondaryText,
      padding: theme.spacing(0, 0.5),
      fontSize: '1.4rem',
      maxWidth: 520,
    },
  }),
  { name: 'WorkflowSectionItem' },
)

export interface WorkflowSectionItemProps {
  disabled: boolean
  disabledTooltip?: React.ReactNode
  label: string
  workflow: PrescriptionWorkflowType
}

const WorkflowSectionItem = ({
  disabled,
  disabledTooltip,
  label,
  workflow,
}: WorkflowSectionItemProps) => {
  const classes = useStyles()

  const isActiveRxEnabled = useSelector(
    getFeatureToggle(FeatureToggle.ACTIVE_RX),
  )

  return typeof disabledTooltip === 'string' ? (
    <Tooltip
      PopperProps={
        isActiveRxEnabled
          ? {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -16],
                  },
                },
              ],
            }
          : {}
      }
      classes={isActiveRxEnabled ? { tooltip: classes.tooltipIcon } : {}}
      placement={isActiveRxEnabled ? 'top' : 'top-start'}
      title={disabled ? disabledTooltip : ''}
    >
      <FormControlLabel
        classes={{ label: classes.label }}
        control={<Radio />}
        disabled={Boolean(disabled)}
        label={label}
        value={workflow}
      />
    </Tooltip>
  ) : (
    <PuiTooltip tooltipText={disabled ? disabledTooltip : ''}>
      <FormControlLabel
        classes={{ label: classes.label }}
        control={<Radio />}
        disabled={Boolean(disabled)}
        label={label}
        value={workflow}
      />
    </PuiTooltip>
  )
}

export default WorkflowSectionItem
