import React from 'react'
import { useSelector } from 'react-redux'

import { InventoryCategoryName } from '~/constants/inventoryCategory'
import {
  useIsDrug,
  useIsVetDiet,
  useNavigateOrderToPrescription,
} from '~/store/hooks/orders'
import { getSoapBusinessId, getSoapId } from '~/store/reducers/soap'
import { Order } from '~/types'

import ItemWithBadges, { ItemWithBadgesProps } from '../ItemWithBadges'
import InventoryItemActions from './actions/InventoryItemActions'
import InventoryItemButtonSection from './button-sections/InventoryItemButtonSection'
import InventoryItemContent from './content/InventoryItemContent'

export interface InventoryItemProps extends Omit<ItemWithBadgesProps, 'order'> {
  data: Order
}

const InventoryItem = ({ data: order, ...rest }: InventoryItemProps) => {
  const soapId = useSelector(getSoapId)
  const soapBusinessId = useSelector(getSoapBusinessId)

  const isDrug = useIsDrug(order)
  const isVetDiet = useIsVetDiet(order)
  const navigateToPrescriptionDialog = useNavigateOrderToPrescription({
    businessId: soapBusinessId,
    soapId,
  })

  return (
    <ItemWithBadges
      actions={<InventoryItemActions order={order} {...rest} />}
      buttonSection={<InventoryItemButtonSection order={order} {...rest} />}
      order={order}
      onNameClick={
        isDrug
          ? () =>
              navigateToPrescriptionDialog(InventoryCategoryName.DRUG, order)
          : isVetDiet
          ? () =>
              navigateToPrescriptionDialog(
                InventoryCategoryName.VET_DIET,
                order,
              )
          : undefined
      }
    >
      <InventoryItemContent order={order} />
    </ItemWithBadges>
  )
}

export default InventoryItem
