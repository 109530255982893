import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, PuiTheme } from '@pbt/pbt-ui-components'

import { EventProduct } from '~/types'

import EventProductsTableRow from './EventProductsTableRow'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      padding: theme.spacing(1),
    },
    headingCell: {
      fontWeight: 'bold',
      padding: theme.spacing(1),
      border: theme.constants.tableBorder,
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
    },
  }),
  { name: 'EventProductsTable' },
)

interface EventProductsTableProps {
  classes?: ClassesType<typeof useStyles>
  products?: EventProduct[]
}

const EventProductsTable = ({
  classes: classesProp,
  products: productsProp,
}: EventProductsTableProps) => {
  const products = productsProp || []
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Abbreviations', 'Common'])

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.headingCell}>
            {t('Common:ORDERED')}
          </TableCell>
          <TableCell className={classes.headingCell}>
            {t('Abbreviations:COMMON.QUANTITY')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {products.map((product, index) => (
          <EventProductsTableRow
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            product={product}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default EventProductsTable
