import { gql } from '@apollo/client'

import {
  BILLING_ACTIVITY_SECTION_EVENT,
  BILLING_ACTIVITY_SECTION_PATIENT,
  ESTIMATE_FIELDS,
} from '../fragments'

export const FETCH_ESTIMATES = gql`
  ${ESTIMATE_FIELDS}
  query EstimateList(
    $businessId: ID!
    $patientId: ID!
    $soapId: ID!
    $offset: Int!
    $limit: Int!
  ) {
    getEstimateList(
      businessId: $businessId
      patientId: $patientId
      soapId: $soapId
      offset: $offset
      limit: $limit
    ) {
      data {
        ...EstimateFields
      }
      totalCount
    }
  }
`

export const FETCH_CLIENT_ESTIMATES_PAGE = gql`
  ${ESTIMATE_FIELDS}
  query EstimateList(
    $businessId: ID!
    $clientId: ID!
    $offset: PageOffset!
    $limit: PageLimit!
  ) {
    getEstimates(
      businessId: $businessId
      clientId: $clientId
      offset: $offset
      limit: $limit
    ) {
      data {
        ...EstimateFields
      }
      totalCount
    }
  }
`

export const FETCH_INVOICES_BY_CLIENT_AND_STATES = gql`
  ${BILLING_ACTIVITY_SECTION_PATIENT}
  ${BILLING_ACTIVITY_SECTION_EVENT}
  query FetchInvoicesByClientAndStates($input: InvoiceSearchInput) {
    invoicesByClientAndStates(input: $input) {
      data {
        id
        creationDate
        modificationDate
        ... on Invoice {
          id
          clientId
          invoiceNo
          invoiceDate
          dueToPayNoFee
          paidAmount
          stateId
          state
          type
          subtotal
          amountNoFee
          sections {
            id
            patient {
              ...BillingActivitySectionPatient
            }
            event {
              ...BillingActivitySectionEvent
            }
          }
        }
        ... on RefundInvoice {
          id
          amount
          client {
            id
          }
          creationDate
          refundInvoiceNo: invoiceNo
          refundState: state {
            id
            name
          }
          refundSections: sections {
            id
            patient {
              ...BillingActivitySectionPatient
            }
            event {
              ...BillingActivitySectionEvent
            }
          }
          originalInvoice {
            id
            invoiceNo
          }
        }
      }
      totalCount
    }
  }
`

export const FETCH_UNPAID_INVOICES_BY_CLIENT_AND_STATES = gql`
  ${BILLING_ACTIVITY_SECTION_PATIENT}
  ${BILLING_ACTIVITY_SECTION_EVENT}
  query FetchUnpaidInvoicesByClientAndStates(
    $businessId: ID!
    $clientId: ID!
    $offset: PageOffset!
    $limit: PageLimit!
  ) {
    unpaidInvoices(
      businessId: $businessId
      clientId: $clientId
      offset: $offset
      limit: $limit
    ) {
      data {
        id
        creationDate
        modificationDate
        ... on Invoice {
          id
          clientId
          invoiceNo
          invoiceDate
          dueToPayNoFee
          paidAmount
          stateId
          state
          type
          subtotal
          amountNoFee
          sections {
            id
            patient {
              ...BillingActivitySectionPatient
            }
            event {
              ...BillingActivitySectionEvent
            }
          }
        }
        ... on RefundInvoice {
          id
          amount
          client {
            id
          }
          creationDate
          refundInvoiceNo: invoiceNo
          refundState: state {
            id
            name
          }
          refundSections: sections {
            id
            patient {
              ...BillingActivitySectionPatient
            }
            event {
              ...BillingActivitySectionEvent
            }
          }
          originalInvoice {
            id
            invoiceNo
          }
        }
      }
      totalCount
    }
  }
`
