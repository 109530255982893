import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { LanguageUtils, PuiTheme, Text } from '@pbt/pbt-ui-components'

import EmailDialogSectionItem from './EmailDialogSectionItem'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    panel: {
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(1),
      },
      boxShadow: 'none',
      border: theme.constants.tableBorder,
      borderRadius: 2,
      backgroundColor: theme.colors.tableBackground,
      '&:before': {
        backgroundColor: 'transparent',
      },
    },
    label: {
      color: theme.colors.secondaryText,
    },
    panelSummary: {
      padding: theme.spacing(0, 1.5),
      height: 40,
      minHeight: 40,
      '&&$expanded': {
        minHeight: 40,
      },
    },
    // this is needed since material ui specificity system is awesome =)
    expanded: {},
    panelDetails: {
      paddingTop: 0,
      paddingLeft: theme.spacing(5),
      flexDirection: 'column',
    },
    name: {
      fontSize: '1.4rem',
      color: theme.colors.secondaryText,
      marginBottom: theme.spacing(0.5),
    },
    subcategoryText: {
      position: 'relative',
      left: -5,
    },
    subcategory: {
      '&:not(:last-of-type)': {
        marginBottom: theme.spacing(1),
      },
    },
  }),
  { name: 'EmailDialogSection' },
)

export interface EmailDialogSectionProps {
  checked: boolean
  children?: any[]
  defaultExpanded?: boolean
  id: string
  name: string
  onCheckedChange: (id: string, checked: boolean) => void
}

const EmailDialogSection = ({
  id,
  checked,
  name,
  children,
  onCheckedChange,
  defaultExpanded,
}: EmailDialogSectionProps) => {
  const classes = useStyles()

  const groupedChildren = children && R.groupBy(R.prop('subcategory'), children)

  const label = (
    <FormControlLabel
      className={classes.label}
      control={
        <Checkbox
          checked={checked}
          onChange={() => onCheckedChange(id, !checked)}
        />
      }
      label={name}
      onClick={(event) => event.stopPropagation()}
      onFocus={(event) => event.stopPropagation()}
    />
  )

  if (!groupedChildren) {
    return (
      <Grid container item>
        {label}
      </Grid>
    )
  }

  return (
    <Accordion className={classes.panel} defaultExpanded={defaultExpanded}>
      <AccordionSummary
        classes={{
          root: classes.panelSummary,
          expanded: classes.expanded,
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        {label}
      </AccordionSummary>
      <AccordionDetails className={classes.panelDetails}>
        {Object.keys(groupedChildren).map((subcategory) => {
          const hasSubcategory =
            subcategory !== 'null' && subcategory !== 'undefined'
          const displaySubcategoryName = LanguageUtils.getTranslatedFieldName(
            R.head(groupedChildren[subcategory]),
            'subcategory',
            subcategory,
          )
          return (
            <Grid
              container
              item
              className={classes.subcategory}
              direction="column"
              key={subcategory || 'no subcategory'}
            >
              {hasSubcategory && (
                <Text className={classes.subcategoryText} variant="h5">
                  {displaySubcategoryName}
                </Text>
              )}
              {groupedChildren[subcategory].map((item) => (
                <EmailDialogSectionItem
                  key={item.id}
                  name={LanguageUtils.getTranslatedFieldName(item)}
                  onCheckedChange={onCheckedChange}
                  {...item}
                />
              ))}
            </Grid>
          )
        })}
      </AccordionDetails>
    </Accordion>
  )
}

export default EmailDialogSection
