import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormControlLabel, Grid, Link, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { last } from 'ramda'
import {
  AlertIconType,
  ButtonWithLoader,
  ClassesType,
  Defaults,
  moment,
  Nil,
  PDMPReportVersionConstant,
  PuiTheme,
  Text,
  TextWithTooltip,
} from '@pbt/pbt-ui-components'

import DateRangeInput from '~/components/common/form-inputs/DateRangeInput'
import DialogNames from '~/constants/DialogNames'
import {
  cleanUp,
  generateReport,
  getIsLoading,
  getReportUrl,
} from '~/store/duck/pdmpReport'
import { getPDMPReportVersions } from '~/store/reducers/constants'
import useDialog from '~/utils/useDialog'

import TimezoneWarningLabel from '../alerts/TimezoneWarningLabel'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    configContainer: {
      width: 660,
      border: theme.constants.tableBorder,
    },
    radioGroup: {
      padding: theme.spacing(1),
    },
    button: {
      width: 177,
    },
    text: {
      fontSize: '1.6rem',
    },
    dateRangeContainer: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: 300,
    },
    dateInput: {
      width: 140,
    },
  }),
  { name: 'PDMPReport' },
)

const DATE_OPTIONS = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  LAST_SEVEN_DAYS: 'LAST_SEVEN_DAYS',
  CUSTOM: 'CUSTOM',
}

export interface PDMPReportProps {
  classes?: ClassesType<typeof useStyles>
}

const PDMPReport = ({ classes: classesProp }: PDMPReportProps) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const PDMPReportVersions: PDMPReportVersionConstant[] = useSelector(
    getPDMPReportVersions,
  )
  const isLoading = useSelector(getIsLoading)
  const reportUrl = useSelector(getReportUrl)
  const { t } = useTranslation(['Abbreviations', 'Admin', 'Common', 'Time'])

  const [openDownloadDialog] = useDialog(DialogNames.DISMISSIBLE_ALERT, () =>
    dispatch(cleanUp()),
  )

  const downloadUrl = `${window.location.origin}${reportUrl}`

  const [dateOption, setDateOption] = useState(DATE_OPTIONS.TODAY)
  const [format, setFormat] = useState<string>(
    last(PDMPReportVersions)?.version as string,
  )
  const [customStartDate, setCustomStartDate] = useState<string>('')
  const [customEndDate, setCustomEndDate] = useState<string>('')
  const [dateString, setDateString] = useState<string>()

  const now = moment()
  const yesterday = moment().subtract(1, 'days')
  const sevenDaysAgo = moment().subtract(7, 'days')

  useEffect(
    () => () => {
      dispatch(cleanUp())
    },
    [],
  )

  useEffect(() => {
    if (reportUrl) {
      openDownloadDialog({
        iconType: AlertIconType.SUCCESS,
        message: (
          <span>
            <Trans
              components={{
                span: <span />,
                linkToDownload: (
                  <Link
                    download
                    href={downloadUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                ),
              }}
              i18nKey="Admin:CATALOG.PDMP_REPORT.DOWNLOAD_DIALOG_MESSAGE"
              values={{ dateString, downloadUrl }}
            />
          </span>
        ),
      })
    }
  }, [reportUrl])

  const handleDateRangeChange = useCallback(
    ({
      fromDate,
      toDate,
    }: {
      fromDate: string | Nil
      toDate: string | Nil
    }) => {
      if (fromDate !== customStartDate || toDate !== customEndDate) {
        setCustomStartDate(fromDate as string)
        setCustomEndDate(toDate as string)
      }
    },
    [],
  )

  const onDownloadReportClick = useCallback(() => {
    let startDate = moment()
    let endDate = moment()
    switch (dateOption) {
      case DATE_OPTIONS.TODAY:
        startDate = moment().startOf('day')
        endDate = moment()
        setDateString(startDate.format(Defaults.DATE_FORMAT))
        break
      case DATE_OPTIONS.YESTERDAY:
        startDate = moment().subtract(1, 'days').startOf('day')
        endDate = moment().subtract(1, 'days').endOf('day')
        setDateString(startDate.format(Defaults.DATE_FORMAT))
        break
      case DATE_OPTIONS.LAST_SEVEN_DAYS:
        startDate = moment().subtract(7, 'days').startOf('day')
        endDate = moment()
        setDateString(
          `${startDate.format(Defaults.DATE_FORMAT)} — ${moment().format(
            Defaults.DATE_FORMAT,
          )}`,
        )
        break
      case DATE_OPTIONS.CUSTOM:
        startDate = moment(customStartDate)
        endDate = moment(customEndDate)
        setDateString(
          `${startDate.format(Defaults.DATE_FORMAT)} — ${endDate.format(
            Defaults.DATE_FORMAT,
          )}`,
        )
        break
      default:
        break
    }
    dispatch(
      generateReport(startDate.toISOString(), endDate.toISOString(), format),
    )
  }, [dateOption, customStartDate, customEndDate, format])

  return (
    <Grid container className={classes.root} direction="column" p={3}>
      <Grid item>
        <Text variant="h1">{t('Abbreviations:ACRONYMS.PDMP.REPORT')}</Text>
      </Grid>
      <Grid
        container
        item
        className={classes.configContainer}
        direction="column"
        mt={5}
        pb={3}
        pt={1}
        px={1}
        spacing={1}
      >
        <Grid item>
          <TimezoneWarningLabel
            strong
            iconPlacement="right"
            variant="subheading3"
          >
            {t('Admin:CATALOG.PDMP_REPORT.DATE_RANGE_TO_INCLUDE')}
          </TimezoneWarningLabel>
        </Grid>
        <RadioGroup
          className={classes.radioGroup}
          value={dateOption}
          onChange={(event, value) => setDateOption(value)}
        >
          <FormControlLabel
            classes={{ label: classes.text }}
            control={<Radio disableRipple color="secondary" />}
            label={`${t('Time:LABEL.TODAY')} (${now.format(
              Defaults.DATE_FORMAT,
            )})`}
            value={DATE_OPTIONS.TODAY}
          />
          <FormControlLabel
            classes={{ label: classes.text }}
            control={<Radio disableRipple color="secondary" />}
            label={`${t('Time:LABEL.YESTERDAY')} (${yesterday.format(
              Defaults.DATE_FORMAT,
            )})`}
            value={DATE_OPTIONS.YESTERDAY}
          />
          <FormControlLabel
            classes={{ label: classes.text }}
            control={<Radio disableRipple color="secondary" />}
            label={`
              ${t('Time:LABEL.LAST_SEVEN_DAYS')}
              (${sevenDaysAgo.format(Defaults.DATE_FORMAT)} — ${now.format(
              Defaults.DATE_FORMAT,
            )})
            `}
            value={DATE_OPTIONS.LAST_SEVEN_DAYS}
          />
          <FormControlLabel
            classes={{ label: classes.text }}
            control={<Radio disableRipple color="secondary" />}
            label={t('Time:LABEL.CUSTOM_DATE_RANGE')}
            value={DATE_OPTIONS.CUSTOM}
          />
        </RadioGroup>
        {dateOption === DATE_OPTIONS.CUSTOM && (
          <DateRangeInput
            classes={{
              container: classes.dateRangeContainer,
              input: classes.dateInput,
            }}
            fromDate={customStartDate}
            fromLabel={t('Time:LABEL.FROM_DATE')}
            toDate={customEndDate}
            toLabel={t('Time:LABEL.TO_DATE')}
            onChange={handleDateRangeChange}
          />
        )}
        <Grid item>
          <TextWithTooltip
            strong
            tooltipText={t('Admin:CATALOG.PDMP_REPORT.FILE_TOOLTIP')}
            variant="subheading3"
          >
            {t('Abbreviations:ACRONYMS.ASAP.FILE_FORMAT')}
          </TextWithTooltip>
        </Grid>
        <RadioGroup
          row
          className={classes.radioGroup}
          value={format}
          onChange={(event, value) => setFormat(value)}
        >
          {PDMPReportVersions.map(({ version, name }) => (
            <FormControlLabel
              classes={{ label: classes.text }}
              control={<Radio disableRipple color="secondary" />}
              key={version}
              label={name}
              value={version}
            />
          ))}
        </RadioGroup>
        <Grid>
          <ButtonWithLoader
            className={classes.button}
            disabled={isLoading}
            loading={isLoading}
            onClick={onDownloadReportClick}
          >
            {t('Common:GENERATE_REPORT')}
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PDMPReport
