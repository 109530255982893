import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { LinkButton, PuiTheme, Text } from '@pbt/pbt-ui-components'
import { WarnAlert } from '@pbt/pbt-ui-components/src/icons'

import { isUnpostDisabled } from '~/components/dashboard/invoices/invoiceUtils'
import FeatureToggle from '~/constants/featureToggle'
import { fetchInvoice, saveInvoice } from '~/store/actions/finance'
import { fetchSoapCharges, getCharges } from '~/store/duck/charges'
import {
  getCurrentBusiness,
  getCurrentUserId,
  getIsPracticeAdministrator,
} from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getFinanceInvoice,
  getFinanceIsLoading,
} from '~/store/reducers/finance'
import { getInvoiceV3Loading, voidInvoice } from '~/store/reducers/invoiceV3'
import {
  getClientId,
  getInvoiceId,
  getIsCurrentContextSoap,
  getSoapId,
} from '~/store/reducers/soap'
import { Invoice } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    icon: {
      width: 20,
      height: 20,
      marginRight: theme.spacing(1),
      color: theme.colors.important,
    },
    title: {
      color: theme.colors.important,
    },
    frame: {
      border: theme.constants.tableBorder,
      borderRadius: 2,
      margin: theme.spacing(1, 0),
      padding: theme.spacing(2),
      height: 'fit-content',
    },
    link: {
      color: theme.colors.link,
      marginLeft: theme.spacing(2.5),
    },
  }),
  { name: 'PuiAlert' },
)

const InvoiceIsPostedWarning = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Soap'])

  const business = useSelector(getCurrentBusiness)
  const isPracticeAdmin = useSelector(getIsPracticeAdministrator(business))
  const invoiceId = useSelector(getInvoiceId)
  const invoice = useSelector(getFinanceInvoice(invoiceId)) as Invoice
  const isLoading = useSelector(getFinanceIsLoading)
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)
  const currentUserId = useSelector(getCurrentUserId)
  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )
  const charges = useSelector(getCharges)
  const soapId = useSelector(getSoapId)
  const id = useSelector(getClientId)

  useEffect(() => {
    if (isCurrentContextSoap && invoiceId && isPracticeAdmin) {
      dispatch(fetchInvoice(invoiceId))
    }
  }, [invoiceId, isPracticeAdmin])

  const refetchAfterVoid = useCloseAfterCreation(
    () => soapId && id && dispatch(fetchSoapCharges({ id, soapId })),
    getInvoiceV3Loading,
  )

  const reopen = () => {
    dispatch(
      saveInvoice({
        ...invoice,
        posted: false,
        client: { id: invoice.client },
        patient: { id: invoice.patient },
      }),
    )
  }

  const reopenCharges = () => {
    if (charges?.id && currentUserId) {
      refetchAfterVoid()
      dispatch(
        voidInvoice({ invoiceId: charges?.id, voidedById: currentUserId }),
      )
    }
  }

  const title = isChargeSheetEnabled
    ? t('Common:RE-OPEN_CHARGES')
    : t('Common:RE-OPEN_INVOICE')

  return (
    <Grid item className={classes.frame}>
      <Grid container alignItems="center">
        <WarnAlert className={classes.icon} />
        <Text className={classes.title}>{t('Common:INVOICE_IS_POSTED')}</Text>
      </Grid>
      {isPracticeAdmin ? (
        <LinkButton
          className={classes.link}
          disabled={
            isChargeSheetEnabled && !R.isNil(charges)
              ? isUnpostDisabled(charges)
              : false
          }
          loading={isLoading}
          onClick={isChargeSheetEnabled ? reopenCharges : reopen}
        >
          {title}
        </LinkButton>
      ) : (
        <Text pl={3.5} variant="body2">
          {t('Soap:IF_YOU_NEED_ANY_CHANGES_PRACTICE_ADMINISTRATOR_CAN_RE_OPEN')}
        </Text>
      )}
    </Grid>
  )
}

export default InvoiceIsPostedWarning
