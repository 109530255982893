import React from 'react'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  LanguageUtils,
  NamedEntity,
  PuiTheme,
  Text,
  WellnessPlanCoverItem,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      minHeight: 40,
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tabBorder,
      },
    },
    iconButton: {
      padding: theme.spacing(1),
    },
  }),
  { name: 'WellnessPlanBenefitCoverageRow' },
)

export interface WellnessPlanBenefitCoverageRowProps {
  coverage: WellnessPlanCoverItem | NamedEntity
  onDelete: () => void
  wellnessPlanVersion: WellnessPlanVersion
}

const WellnessPlanBenefitCoverageRow = ({
  wellnessPlanVersion,
  coverage,
  onDelete,
}: WellnessPlanBenefitCoverageRowProps) => {
  const classes = useStyles()

  const { activePatients = 0 } = wellnessPlanVersion
  const hasActivePatients = activePatients > 0

  const coverageDisplayName = LanguageUtils.getTranslatedFieldName(
    coverage,
    'displayName',
    LanguageUtils.getTranslatedFieldName(coverage),
  )

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.root}
      justifyContent="space-between"
      px={1}
      wrap="nowrap"
    >
      <Text variant="body">{coverageDisplayName}</Text>
      {(!hasActivePatients || !coverage.id) && (
        <IconButton
          aria-label="delete"
          className={classes.iconButton}
          size="large"
          onClick={onDelete}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Grid>
  )
}

export default WellnessPlanBenefitCoverageRow
