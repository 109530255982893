import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ClassesType,
  ControlButtonGroup,
  ControlButtonGroupName,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

const DEFAULT_MAIN_COLUMN_WIDTH = 160

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      flexShrink: 0,
      [theme.breakpoints.down('md')]: {
        overflow: 'auto',
      },
    },
    name: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    nameContainer: {
      height: 40,
      width: '100%',
    },
    tableContainer: {
      border: theme.constants.tableBorder,
      display: 'inline-block',
      borderTopLeftRadius: 2,
      borderBottomLeftRadius: 2,
    },
    tableCell: {
      fontSize: '1.6rem',
      border: 'none',
      paddingTop: 0,
      paddingBottom: 0,
      color: theme.colors.secondaryText,
      height: 52,
      whiteSpace: 'nowrap',
    },
    tableTitle: {
      borderBottom: theme.constants.tableBorder,
      color: theme.colors.tabLabel,
      fontSize: '1.6rem',
      fontWeight: 500,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      whiteSpace: 'nowrap',
    },
    table: {
      width: 256,
    },
    tableRow: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
    dataTableContainer: {
      border: theme.constants.tableBorder,
      borderLeftWidth: 0,
      display: 'inline-block',
      borderTopRightRadius: 2,
      borderBottomRightRadius: 2,
      [theme.breakpoints.up('md')]: {
        width: 200,
        overflow: 'auto',
      },
    },
    subTitle: {
      color: theme.colors.tabLabel,
      fontSize: '1.2rem',
      fontWeight: 400,
      lineHeight: '12px',
    },
    innerCell: {
      padding: theme.spacing(0, 2),
      border: 'none',
    },
    leftColumn: {
      paddingTop: 6,
      paddingBottom: 6,
      '&:last-child': {
        paddingRight: 0,
      },
    },
    // Hack to position properly subLabels
    bodyCells: {
      transform: `translateY(-${theme.spacing(1.5)})`, // 12px
    },
    subLabelsRow: {
      transform: `translateY(-${theme.spacing(2.5)})`, // 20px
    },
    subLabelCell: {
      transform: `translateY(-${theme.spacing(0.5)})`, // 4px
    },
  }),
  { name: 'ScrollableDataTable' },
)

export type ScrollableDataTableColumnHeader = {
  label: string
  subLabels?: {
    label: string
    style: CSSProperties
  }[]
}

export type ScrollableDataTableColumn<TItem> = {
  label: string
  prop: keyof TItem | ((item: TItem, index: number) => React.ReactNode)
  width?: string | number
}

export interface ScrollableDataTableProps<TItem, UseIndexAsId = false> {
  TableCellsListComponent?: React.JSXElementConstructor<{ item: TItem }>
  children?: (
    { item, tableCellClassName }: { item: TItem; tableCellClassName?: string },
    index: number,
  ) => React.ReactNode
  className?: string
  classes?: ClassesType<typeof useStyles>
  createDisabled?: boolean
  data?: TItem[]
  deleteDisabled?: boolean
  duplicateDisabled?: boolean
  editDisabled?: boolean
  getNameColumnHeight?: (index: number) => number
  headerRow?: ScrollableDataTableColumnHeader[]
  isDeleting?: boolean
  mainColumns?: ScrollableDataTableColumn<TItem>[]
  onDelete?: (id: UseIndexAsId extends true ? number : string) => void
  onDuplicate?: (id: UseIndexAsId extends true ? number : string) => void
  onEdit?: (id: UseIndexAsId extends true ? number : string) => void
  onPreview?: (id: UseIndexAsId extends true ? number : string) => void
  previewDisabled?: boolean
  useIndexAsId?: UseIndexAsId
}

const ScrollableDataTable = <
  TItem extends { id?: string },
  UseIndexAsId extends boolean,
>({
  useIndexAsId,
  children,
  headerRow = [],
  mainColumns: mainColumnsProp,
  getNameColumnHeight,
  data: dataProp = [],
  onDelete,
  onEdit,
  onDuplicate,
  deleteDisabled,
  previewDisabled,
  onPreview,
  editDisabled,
  createDisabled,
  duplicateDisabled,
  className,
  isDeleting,
  TableCellsListComponent,
  classes: classesProp,
}: ScrollableDataTableProps<TItem, UseIndexAsId>) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')
  const mainColumns =
    mainColumnsProp ||
    ([
      {
        label: t('Common:NAME'),
        prop: 'name',
      },
    ] as ScrollableDataTableColumn<TItem>[])

  const getId = (
    item: TItem,
    index: number,
  ): UseIndexAsId extends true ? number : string =>
    (useIndexAsId ? index : item.id) as UseIndexAsId extends true
      ? number
      : string

  const data = dataProp.filter(Boolean)

  const hasSubLabels = R.any(R.has('subLabels'))(headerRow)

  if (data.length === 0) {
    return null
  }

  const getColumnContent = (
    column: ScrollableDataTableColumn<TItem>,
    item: TItem,
    index: number,
  ) => {
    const columnProp =
      typeof column.prop === 'function'
        ? column.prop(item, index)
        : item[column.prop]
    if (React.isValidElement(columnProp)) {
      return columnProp
    }
    return (
      <Text className={classes.name} py={1} variant="body">
        {columnProp as unknown as string}
      </Text>
    )
  }

  return (
    <Grid
      container
      aria-labelledby="scrollable-data-table"
      className={classNames(className, classes.root)}
      wrap="nowrap"
    >
      <div className={classes.tableContainer}>
        <Table aria-label="scrollable-data-main" className={classes.table}>
          <TableHead>
            <TableRow>
              {mainColumns.map(({ label }) => (
                <TableCell
                  className={classNames(classes.tableCell, classes.tableTitle)}
                  key={label}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => {
              const key = `${item.id}${index}`
              const columnHeight =
                getNameColumnHeight && getNameColumnHeight(index)
              return (
                <TableRow className={classes.tableRow} key={key}>
                  {mainColumns.map((column, colIndex) => {
                    const isLastColumn = colIndex === mainColumns.length - 1
                    return (
                      <TableCell
                        className={classNames(
                          classes.tableCell,
                          classes.leftColumn,
                        )}
                        key={column.label}
                        style={{
                          height: columnHeight,
                          width: column.width || 'auto',
                          maxWidth: column.width
                            ? 'none'
                            : DEFAULT_MAIN_COLUMN_WIDTH,
                        }}
                      >
                        <Grid
                          container
                          alignItems="center"
                          className={classes.nameContainer}
                          justifyContent="space-between"
                          wrap="nowrap"
                        >
                          {getColumnContent(column, item, index)}
                          {isLastColumn && (
                            <Grid item>
                              <ControlButtonGroup
                                buttons={[
                                  !deleteDisabled &&
                                    onDelete && {
                                      name: ControlButtonGroupName.DELETE,
                                      isLoading: isDeleting,
                                      onClick: () =>
                                        onDelete(getId(item, index)),
                                    },
                                  !previewDisabled &&
                                    onPreview && {
                                      name: ControlButtonGroupName.PREVIEW,
                                      onClick: () =>
                                        onPreview(getId(item, index)),
                                    },
                                  !editDisabled &&
                                    onEdit && {
                                      name: ControlButtonGroupName.EDIT,
                                      onClick: () => onEdit(getId(item, index)),
                                    },
                                  !duplicateDisabled &&
                                    !createDisabled &&
                                    onDuplicate && {
                                      name: ControlButtonGroupName.DUPLICATE,
                                      onClick: () =>
                                        onDuplicate(getId(item, index)),
                                    },
                                ]}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
      <Grid item md className={classes.dataTableContainer}>
        <Table aria-label="scrollable-data-rest">
          <TableHead>
            <TableRow>
              {headerRow.map(({ label, subLabels }) => (
                <TableCell
                  className={classNames(classes.tableCell, classes.tableTitle, {
                    [classes.subLabelCell]: hasSubLabels,
                  })}
                  colSpan={subLabels ? subLabels.length : 1}
                  key={label}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>

            <TableRow
              className={classNames({ [classes.subLabelsRow]: hasSubLabels })}
            >
              {headerRow.map(({ subLabels }) =>
                (subLabels || [{ label: '', style: undefined }]).map(
                  ({ label: subLabel, style }) => (
                    <TableCell
                      align="left"
                      className={classNames(
                        classes.subTitle,
                        classes.innerCell,
                      )}
                      colSpan={1}
                      key={subLabel}
                      style={style}
                    >
                      {subLabel}
                    </TableCell>
                  ),
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody
            className={classNames({ [classes.bodyCells]: hasSubLabels })}
          >
            {data.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow className={classes.tableRow} key={`${item.id}${index}`}>
                {TableCellsListComponent ? (
                  <TableCellsListComponent item={item} />
                ) : (
                  children &&
                  children(
                    { item, tableCellClassName: classes.tableCell },
                    index,
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

export default ScrollableDataTable
