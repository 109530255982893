export enum SupportedAnalyticsReport {
  TIME_TRACKING = 27,
  GROUP_PRICE_LISTS = 34,
  CLIENT_AND_PATIENT_STATUS = 59,
  MARKETING_DATA_QUALITY = 60,
  ACCOUNTING = 249,
  DAILY_RECONCILIATION_NEW = 368,
  BANK_RECONCILIATION = 371,
  INVOICES_AND_PAYMENTS = 372,
  PRODUCTION = 374,
  MONTHLY_SALES_SUMMARY = 376,
  WELLNESS_PLANS = 377,
  SCHEDULE_MANAGEMENT_OLD = 379,
  REMINDERS_COMPLIANCE = 386,
  REFERRAL_BUSINESS = 387,
  RABIES_VACCINE_HISTORY = 389,
  PRACTICE_PERFORMANCE = 390,
  OPEN_SOAPS_AND_INVOICES = 392,
  NEW_CLIENT_AND_PATIENT_ACTIVITY = 393,
  MEDICAL_INSIGHTS = 395,
  MAGIC_QUADRANT = 398,
  INVENTORY_USAGE = 401,
  FORWARD_BOOKINGS = 402,
  ACQUISITION_AND_LIFETIME_VALUE = 405,
  CONTROLLED_SUBSTANCE_LOG = 407,
  CLIENT_LIST = 408,
  GROUP_PERFORMANCE = 410,
  SALES_SUMMARY_EXCLUDING_UNPAID_OTC = 415,
  PRACTICE_SALES = 416,
  PRODUCER_SALES = 417,
  INVOICE_ACTIVITY = 418,
  BUNDLES_AND_WELLNESS_PLANS = 419,
  SCHEDULE_MANAGEMENT_NEW = 420,
  CANCELLATIONS_AND_NO_SHOWS = 421,
  CLIENT_INITIATED_REQUESTS = 422,
  DAILY_RECONCILIATION_OLD = 423,
  DISCOUNT_USAGE = 428,
  DENTAL_AND_HEARTWORM_OVERVIEW = 453,
  APPOINTMENT_MIX_BENCHMARK = 456,
  CVC_RESOLUTION_RATE = 458,
  INSURED_PATIENTS_VISITS = 457,
  CVC_DISCOUNT_USAGE = 465,
  CVC_INVENTORY_AND_CATALOG_AUDIT = 466,
  CLIENT_LIST_SSO = 470,
  INVOICE_ACTIVITY_SSO = 471,
  OPEN_SOAPS_AND_INVOICES_SSO = 472,
  ACCOUNTING_SSO = 473,
  BUNDLES_AND_WELLNESS_PLANS_SSO = 474,
  MAGIC_QUADRANT_SSO = 475,
  OPEN_AND_UNAPPLIED_BILLING = 476,
  CVC_DAILY_RECONCILIATION = 478,
  BILLING_AGING = 479,
  CVC_SMS_ACTIVITY = 481,
  DAILY_BILLING_ACTIVITY = 484,
  CVC_INVOICE_ACTIVITY = 485,
  CVC_PRODUCER_SALES = 486,
  INVENTORY_USAGE_AND_AVERAGE_INVOICE = 490,
  REPEAT_AND_RECHECK_PATIENTS = 491,
  ONLINE_SICK_APPOINTMENTS = 494,
  CVC_OVERDUE_TASKS = 495,
}

export enum CvcOnlyAnalyticsReport {
  APPOINTMENT_MIX_BENCHMARK = SupportedAnalyticsReport.APPOINTMENT_MIX_BENCHMARK,
  DENTAL_AND_HEARTWORM_OVERVIEW = SupportedAnalyticsReport.DENTAL_AND_HEARTWORM_OVERVIEW,
  INSURED_PATIENTS_VISITS = SupportedAnalyticsReport.INSURED_PATIENTS_VISITS,
  REPEAT_AND_RECHECK_PATIENTS = SupportedAnalyticsReport.REPEAT_AND_RECHECK_PATIENTS,
  ONLINE_SICK_APPOINTMENTS = SupportedAnalyticsReport.ONLINE_SICK_APPOINTMENTS,
  CVC_RESOLUTION_RATE = SupportedAnalyticsReport.CVC_RESOLUTION_RATE,
  CVC_DISCOUNT_USAGE = SupportedAnalyticsReport.CVC_DISCOUNT_USAGE,
  CVC_INVENTORY_AND_CATALOG_AUDIT = SupportedAnalyticsReport.CVC_INVENTORY_AND_CATALOG_AUDIT,
  CVC_OVERDUE_TASKS = SupportedAnalyticsReport.CVC_OVERDUE_TASKS,
  CVC_SMS_ACTIVITY = SupportedAnalyticsReport.CVC_SMS_ACTIVITY,
}

export enum GeneralSupportedAnalyticsReport {
  ACQUISITION_AND_LIFETIME_VALUE = SupportedAnalyticsReport.ACQUISITION_AND_LIFETIME_VALUE,
  APPOINTMENT_MIX_BENCHMARK = SupportedAnalyticsReport.APPOINTMENT_MIX_BENCHMARK,
  BANK_RECONCILIATION = SupportedAnalyticsReport.BANK_RECONCILIATION,
  BILLING_AGING = SupportedAnalyticsReport.BILLING_AGING,
  BUNDLES_AND_WELLNESS_PLANS = SupportedAnalyticsReport.BUNDLES_AND_WELLNESS_PLANS_SSO,
  CANCELLATIONS_AND_NO_SHOWS = SupportedAnalyticsReport.CANCELLATIONS_AND_NO_SHOWS,
  CLIENT_AND_PATIENT_STATUS = SupportedAnalyticsReport.CLIENT_AND_PATIENT_STATUS,
  CLIENT_INITIATED_REQUESTS = SupportedAnalyticsReport.CLIENT_INITIATED_REQUESTS,
  CLIENT_LIST = SupportedAnalyticsReport.CLIENT_LIST_SSO,
  CONTROLLED_SUBSTANCE_LOG = SupportedAnalyticsReport.CONTROLLED_SUBSTANCE_LOG,
  DAILY_BILLING_ACTIVITY = SupportedAnalyticsReport.DAILY_BILLING_ACTIVITY,
  DAILY_RECONCILIATION_NEW = SupportedAnalyticsReport.DAILY_RECONCILIATION_NEW,
  DAILY_RECONCILIATION_OLD = SupportedAnalyticsReport.DAILY_RECONCILIATION_OLD,
  DENTAL_AND_HEARTWORM_OVERVIEW = SupportedAnalyticsReport.DENTAL_AND_HEARTWORM_OVERVIEW,
  DISCOUNT_USAGE = SupportedAnalyticsReport.DISCOUNT_USAGE,
  FORWARD_BOOKINGS = SupportedAnalyticsReport.FORWARD_BOOKINGS,
  GROUP_PERFORMANCE = SupportedAnalyticsReport.GROUP_PERFORMANCE,
  GROUP_PRICE_LISTS = SupportedAnalyticsReport.GROUP_PRICE_LISTS,
  INSURED_PATIENTS_VISITS = SupportedAnalyticsReport.INSURED_PATIENTS_VISITS,
  INVENTORY_USAGE = SupportedAnalyticsReport.INVENTORY_USAGE,
  INVENTORY_USAGE_AND_AVERAGE_INVOICE = SupportedAnalyticsReport.INVENTORY_USAGE_AND_AVERAGE_INVOICE,
  INVOICE_ACTIVITY = SupportedAnalyticsReport.CVC_INVOICE_ACTIVITY,
  INVOICES_AND_PAYMENTS = SupportedAnalyticsReport.CVC_DAILY_RECONCILIATION,
  MAGIC_QUADRANT = SupportedAnalyticsReport.MAGIC_QUADRANT_SSO,
  MARKETING_DATA_QUALITY = SupportedAnalyticsReport.MARKETING_DATA_QUALITY,
  MEDICAL_INSIGHTS = SupportedAnalyticsReport.MEDICAL_INSIGHTS,
  MONTHLY_SALES_SUMMARY = SupportedAnalyticsReport.MONTHLY_SALES_SUMMARY,
  NEW_CLIENT_AND_PATIENT_ACTIVITY = SupportedAnalyticsReport.NEW_CLIENT_AND_PATIENT_ACTIVITY,
  ONLINE_SICK_APPOINTMENTS = SupportedAnalyticsReport.ONLINE_SICK_APPOINTMENTS,
  OPEN_AND_UNAPPLIED_BILLING = SupportedAnalyticsReport.OPEN_AND_UNAPPLIED_BILLING,
  OPEN_SOAPS_AND_INVOICES = SupportedAnalyticsReport.OPEN_SOAPS_AND_INVOICES_SSO,
  PRACTICE_PERFORMANCE = SupportedAnalyticsReport.PRACTICE_PERFORMANCE,
  PRACTICE_SALES = SupportedAnalyticsReport.PRACTICE_SALES,
  PRODUCER_SALES = SupportedAnalyticsReport.CVC_PRODUCER_SALES,
  PRODUCTION = SupportedAnalyticsReport.PRODUCTION,
  RABIES_VACCINE_HISTORY = SupportedAnalyticsReport.RABIES_VACCINE_HISTORY,
  REFERRAL_BUSINESS = SupportedAnalyticsReport.REFERRAL_BUSINESS,
  REMINDERS_COMPLIANCE = SupportedAnalyticsReport.REMINDERS_COMPLIANCE,
  REPEAT_AND_RECHECK_PATIENTS = SupportedAnalyticsReport.REPEAT_AND_RECHECK_PATIENTS,
  SALES_SUMMARY_EXCLUDING_UNPAID_OTC = SupportedAnalyticsReport.SALES_SUMMARY_EXCLUDING_UNPAID_OTC,
  SCHEDULE_MANAGEMENT_NEW = SupportedAnalyticsReport.SCHEDULE_MANAGEMENT_NEW,
  SCHEDULE_MANAGEMENT_OLD = SupportedAnalyticsReport.SCHEDULE_MANAGEMENT_OLD,
  TIME_TRACKING = SupportedAnalyticsReport.TIME_TRACKING,
  WELLNESS_PLANS = SupportedAnalyticsReport.WELLNESS_PLANS,
}

export enum OmnichannelSupportedAnalyticsReport {
  ACQUISITION_AND_LIFETIME_VALUE = SupportedAnalyticsReport.ACQUISITION_AND_LIFETIME_VALUE,
  APPOINTMENT_MIX_BENCHMARK = SupportedAnalyticsReport.APPOINTMENT_MIX_BENCHMARK,
  BANK_RECONCILIATION = SupportedAnalyticsReport.BANK_RECONCILIATION,
  BILLING_AGING = SupportedAnalyticsReport.BILLING_AGING,
  BUNDLES_AND_WELLNESS_PLANS = SupportedAnalyticsReport.BUNDLES_AND_WELLNESS_PLANS_SSO,
  CANCELLATIONS_AND_NO_SHOWS = SupportedAnalyticsReport.CANCELLATIONS_AND_NO_SHOWS,
  CLIENT_AND_PATIENT_STATUS = SupportedAnalyticsReport.CLIENT_AND_PATIENT_STATUS,
  CLIENT_INITIATED_REQUESTS = SupportedAnalyticsReport.CLIENT_INITIATED_REQUESTS,
  CLIENT_LIST = SupportedAnalyticsReport.CLIENT_LIST_SSO,
  CONTROLLED_SUBSTANCE_LOG = SupportedAnalyticsReport.CONTROLLED_SUBSTANCE_LOG,
  CVC_DAILY_RECONCILIATION = SupportedAnalyticsReport.CVC_DAILY_RECONCILIATION,
  CVC_DISCOUNT_USAGE = SupportedAnalyticsReport.CVC_DISCOUNT_USAGE,
  CVC_INVENTORY_AND_CATALOG_AUDIT = SupportedAnalyticsReport.CVC_INVENTORY_AND_CATALOG_AUDIT,
  CVC_INVOICE_ACTIVITY = SupportedAnalyticsReport.CVC_INVOICE_ACTIVITY,
  CVC_OVERDUE_TASKS = SupportedAnalyticsReport.CVC_OVERDUE_TASKS,
  CVC_PRODUCER_SALES = SupportedAnalyticsReport.CVC_PRODUCER_SALES,
  CVC_RESOLUTION_RATE = SupportedAnalyticsReport.CVC_RESOLUTION_RATE,
  CVC_SMS_ACTIVITY = SupportedAnalyticsReport.CVC_SMS_ACTIVITY,
  DAILY_BILLING_ACTIVITY = SupportedAnalyticsReport.DAILY_BILLING_ACTIVITY,
  DENTAL_AND_HEARTWORM_OVERVIEW = SupportedAnalyticsReport.DENTAL_AND_HEARTWORM_OVERVIEW,
  FORWARD_BOOKINGS = SupportedAnalyticsReport.FORWARD_BOOKINGS,
  GROUP_PERFORMANCE = SupportedAnalyticsReport.GROUP_PERFORMANCE,
  GROUP_PRICE_LISTS = SupportedAnalyticsReport.GROUP_PRICE_LISTS,
  INVENTORY_USAGE = SupportedAnalyticsReport.INVENTORY_USAGE,
  INVENTORY_USAGE_AND_AVERAGE_INVOICE = SupportedAnalyticsReport.INVENTORY_USAGE_AND_AVERAGE_INVOICE,
  MAGIC_QUADRANT_SSO = SupportedAnalyticsReport.MAGIC_QUADRANT_SSO,
  MEDICAL_INSIGHTS = SupportedAnalyticsReport.MEDICAL_INSIGHTS,
  ONLINE_SICK_APPOINTMENTS = SupportedAnalyticsReport.ONLINE_SICK_APPOINTMENTS,
  OPEN_AND_UNAPPLIED_BILLING = SupportedAnalyticsReport.OPEN_AND_UNAPPLIED_BILLING,
  OPEN_SOAPS_AND_INVOICES_SSO = SupportedAnalyticsReport.OPEN_SOAPS_AND_INVOICES_SSO,
  PRACTICE_PERFORMANCE = SupportedAnalyticsReport.PRACTICE_PERFORMANCE,
  PRACTICE_SALES = SupportedAnalyticsReport.PRACTICE_SALES,
  RABIES_VACCINE_HISTORY = SupportedAnalyticsReport.RABIES_VACCINE_HISTORY,
  REMINDERS_COMPLIANCE = SupportedAnalyticsReport.REMINDERS_COMPLIANCE,
  REPEAT_AND_RECHECK_PATIENTS = SupportedAnalyticsReport.REPEAT_AND_RECHECK_PATIENTS,
  SALES_SUMMARY_EXCLUDING_UNPAID_OTC = SupportedAnalyticsReport.SALES_SUMMARY_EXCLUDING_UNPAID_OTC,
  SCHEDULE_MANAGEMENT_NEW = SupportedAnalyticsReport.SCHEDULE_MANAGEMENT_NEW,
  TIME_TRACKING = SupportedAnalyticsReport.TIME_TRACKING,
}

export const analyticsReportToPath: Record<
  SupportedAnalyticsReport | OmnichannelSupportedAnalyticsReport,
  string
> = {
  [SupportedAnalyticsReport.ACCOUNTING_SSO]: '/analytics/accounting',
  [SupportedAnalyticsReport.ACCOUNTING]: '/analytics/accounting',
  [SupportedAnalyticsReport.ACQUISITION_AND_LIFETIME_VALUE]:
    '/analytics/customer-acquisition-and-lifetime-value',
  [SupportedAnalyticsReport.APPOINTMENT_MIX_BENCHMARK]:
    '/analytics/appointment-mix-benchmark',
  [SupportedAnalyticsReport.BANK_RECONCILIATION]:
    '/analytics/bank-reconciliation',
  [SupportedAnalyticsReport.BILLING_AGING]: '/analytics/billing-aging',
  [SupportedAnalyticsReport.BUNDLES_AND_WELLNESS_PLANS_SSO]:
    '/analytics/bundles-and-wellness-plans',
  [SupportedAnalyticsReport.BUNDLES_AND_WELLNESS_PLANS]:
    '/analytics/bundles-and-wellness-plans',
  [SupportedAnalyticsReport.CANCELLATIONS_AND_NO_SHOWS]:
    '/analytics/cancellations-and-no-shows',
  [SupportedAnalyticsReport.CLIENT_AND_PATIENT_STATUS]:
    '/analytics/client-and-patient-status',
  [SupportedAnalyticsReport.CLIENT_INITIATED_REQUESTS]:
    '/analytics/chewy-client-initiated-requests',
  [SupportedAnalyticsReport.CLIENT_LIST_SSO]: '/analytics/client-list',
  [SupportedAnalyticsReport.CLIENT_LIST]: '/analytics/client-list',
  [SupportedAnalyticsReport.CONTROLLED_SUBSTANCE_LOG]:
    '/analytics/controlled-substance-log',
  [SupportedAnalyticsReport.CVC_DAILY_RECONCILIATION]:
    '/analytics/cvc-daily-reconciliation',
  [SupportedAnalyticsReport.CVC_DISCOUNT_USAGE]:
    '/analytics/cvc-discount-usage',
  [SupportedAnalyticsReport.CVC_INVENTORY_AND_CATALOG_AUDIT]:
    '/analytics/cvc-inventory-and-catalog-audit',
  [SupportedAnalyticsReport.CVC_INVOICE_ACTIVITY]:
    '/analytics/cvc-invoice-activity',
  [SupportedAnalyticsReport.CVC_OVERDUE_TASKS]: '/analytics/cvc-overdue-tasks',
  [SupportedAnalyticsReport.CVC_PRODUCER_SALES]:
    '/analytics/cvc-producer-sales',
  [SupportedAnalyticsReport.CVC_RESOLUTION_RATE]:
    '/analytics/cvc-resolution-rate',
  [SupportedAnalyticsReport.CVC_SMS_ACTIVITY]: '/analytics/cvc-sms-activity',
  [SupportedAnalyticsReport.DAILY_BILLING_ACTIVITY]:
    '/analytics/daily-billing-activity',
  [SupportedAnalyticsReport.DAILY_RECONCILIATION_NEW]:
    '/analytics/daily-reconciliation-new',
  [SupportedAnalyticsReport.DAILY_RECONCILIATION_OLD]:
    '/analytics/daily-reconciliation-old',
  [SupportedAnalyticsReport.DENTAL_AND_HEARTWORM_OVERVIEW]:
    '/analytics/dental-and-heartworm-overview',
  [SupportedAnalyticsReport.DISCOUNT_USAGE]: '/analytics/discount-usage',
  [SupportedAnalyticsReport.FORWARD_BOOKINGS]: '/analytics/forward-bookings',
  [SupportedAnalyticsReport.GROUP_PERFORMANCE]: '/analytics/group-performance',
  [SupportedAnalyticsReport.GROUP_PRICE_LISTS]: '/analytics/group-price-lists',
  [SupportedAnalyticsReport.INSURED_PATIENTS_VISITS]:
    '/analytics/insured-patients-visits',
  [SupportedAnalyticsReport.INVENTORY_USAGE_AND_AVERAGE_INVOICE]:
    '/analytics/inventory-usage-and-average-invoice',
  [SupportedAnalyticsReport.INVENTORY_USAGE]: '/analytics/inventory-usage',
  [SupportedAnalyticsReport.INVOICE_ACTIVITY_SSO]:
    '/analytics/invoice-activity',
  [SupportedAnalyticsReport.INVOICE_ACTIVITY]: '/analytics/invoice-activity',
  [SupportedAnalyticsReport.INVOICES_AND_PAYMENTS]:
    '/analytics/invoices-and-payments',
  [SupportedAnalyticsReport.MAGIC_QUADRANT_SSO]: '/analytics/magic-quadrant',
  [SupportedAnalyticsReport.MAGIC_QUADRANT]: '/analytics/magic-quadrant',
  [SupportedAnalyticsReport.MARKETING_DATA_QUALITY]:
    '/analytics/marketing-data-quality',
  [SupportedAnalyticsReport.MEDICAL_INSIGHTS]: '/analytics/medical-insights',
  [SupportedAnalyticsReport.MONTHLY_SALES_SUMMARY]:
    '/analytics/monthly-sales-summary',
  [SupportedAnalyticsReport.NEW_CLIENT_AND_PATIENT_ACTIVITY]:
    '/analytics/new-client-and-patient-activity',
  [SupportedAnalyticsReport.ONLINE_SICK_APPOINTMENTS]:
    '/analytics/online-sick-appointments',
  [SupportedAnalyticsReport.OPEN_AND_UNAPPLIED_BILLING]:
    '/analytics/open-and-unapplied-billing',
  [SupportedAnalyticsReport.OPEN_SOAPS_AND_INVOICES_SSO]:
    '/analytics/open-soaps-and-invoices',
  [SupportedAnalyticsReport.OPEN_SOAPS_AND_INVOICES]:
    '/analytics/open-soaps-and-invoices',
  [SupportedAnalyticsReport.PRACTICE_PERFORMANCE]:
    '/analytics/practice-performance',
  [SupportedAnalyticsReport.PRACTICE_SALES]: '/analytics/practice-sales',
  [SupportedAnalyticsReport.PRODUCER_SALES]: '/analytics/producer-sales',
  [SupportedAnalyticsReport.PRODUCTION]: '/analytics/production',
  [SupportedAnalyticsReport.RABIES_VACCINE_HISTORY]:
    '/analytics/rabies-vaccine-history',
  [SupportedAnalyticsReport.REFERRAL_BUSINESS]: '/analytics/referral-business',
  [SupportedAnalyticsReport.REMINDERS_COMPLIANCE]:
    '/analytics/reminders-compliance',
  [SupportedAnalyticsReport.REPEAT_AND_RECHECK_PATIENTS]:
    '/analytics/repeat-and-recheck-patients',
  [SupportedAnalyticsReport.SALES_SUMMARY_EXCLUDING_UNPAID_OTC]:
    '/analytics/sales-summary-excluding-unpaid-otc',
  [SupportedAnalyticsReport.SCHEDULE_MANAGEMENT_NEW]:
    '/analytics/schedule-management-new',
  [SupportedAnalyticsReport.SCHEDULE_MANAGEMENT_OLD]:
    '/analytics/schedule-management-old',
  [SupportedAnalyticsReport.TIME_TRACKING]: '/analytics/time-tracking',
  [SupportedAnalyticsReport.WELLNESS_PLANS]: '/analytics/wellness-plans',
}
