import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  PuiCloseButton,
  PuiTheme,
  Text,
  TextInteractive,
} from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      height: 40,
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
      padding: theme.spacing(0, 1, 0, 2),
      display: 'flex',
    },
    iconButton: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      padding: 0,
      right: 'unset',
      top: 'unset',
      position: 'unset',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    parameterLabel: {
      fontWeight: 500,
      color: theme.colors.tabLabel,
      paddingLeft: theme.spacing(2),
    },
  }),
  { name: 'SearchFilterValueButton' },
)

export interface SearchFilterValueButtonProps {
  hasFieldsQuery?: boolean
  hideClearButton?: boolean
  isLoading?: boolean
  onClearFilter?: () => void
  searchCount: number
  searchTerm: string
}

const SearchFilterValueButton = ({
  searchCount,
  searchTerm,
  hasFieldsQuery,
  hideClearButton,
  onClearFilter,
  isLoading,
  ...rest
}: SearchFilterValueButtonProps) => {
  const classes = useStyles(rest)
  const { t } = useTranslation(['Common', 'Search', 'Plurals'])

  if (!searchTerm && !hasFieldsQuery) {
    return null
  }

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.root}
      wrap="nowrap"
    >
      <Dotdotdot clamp={1}>
        <TextInteractive strong isLoading={isLoading} variant="body2">
          {t('Plurals:Z_ICU_WORKAROUND.FILTER_RESULTS', {
            numResults: searchCount,
          })}
          {searchTerm && (
            <>
              &nbsp;
              {t('Search:FILTER_TERM', { searchTerm })}
            </>
          )}
        </TextInteractive>
      </Dotdotdot>
      {!searchTerm && !hideClearButton && (
        <Text strong ml={2} variant="lowAccent2">
          &nbsp;{t('Common:CLEAR_ALL')}
        </Text>
      )}
      {!hideClearButton && onClearFilter && (
        <PuiCloseButton
          classes={{ iconButton: classes.iconButton }}
          onClick={onClearFilter}
        />
      )}
    </Grid>
  )
}

export default SearchFilterValueButton
