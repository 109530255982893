import React from 'react'
import { Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LanguageUtils, PuiTheme, Text } from '@pbt/pbt-ui-components'

import { WhiteboardColumnsNames } from '~/constants/whiteboardColumns'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      padding: theme.spacing(0, 2),
      border: theme.constants.tableBorder,
      height: 40,
      display: 'flex',
      alignItems: 'center',
    },
  }),
  { name: 'WhiteboardColumnHeader' },
)

export interface WhiteboardColumnHeaderProps {
  name: string
}

const WhiteboardColumnHeader = ({ name }: WhiteboardColumnHeaderProps) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root} elevation={0}>
      <Text strong variant="subheading2">
        {WhiteboardColumnsNames[name] || LanguageUtils.capitalize(name)}
      </Text>
    </Paper>
  )
}

export default WhiteboardColumnHeader
