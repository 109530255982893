import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Nil, PuiTheme, Text } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

import { formatWidgetDate } from './widgetUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    today: {
      color: theme.colors.title,
    },
    yesterday: {
      color: theme.colors.important,
    },
    date: {
      color: theme.colors.lowAccentText,
    },
  }),
  { name: 'DateGroupingCell' },
)

const isTodayString = (dateStr: string | Nil) =>
  dateStr === i18n.t('Time:LABEL.TODAY')
const isYesterdayString = (dateStr: string | Nil) =>
  dateStr === i18n.t('Time:LABEL.YESTERDAY')
const isRegularDate = (dateStr: string | Nil) =>
  dateStr && !isTodayString(dateStr) && !isYesterdayString(dateStr)

interface DateGroupingCellProps {
  date?: string
  title?: string
}

const DateGroupingCell = ({ date, title }: DateGroupingCellProps) => {
  const classes = useStyles()
  const dateStr = date ? formatWidgetDate(date) : title

  return (
    <Text
      className={classNames({
        [classes.today]: isTodayString(dateStr),
        [classes.yesterday]: isYesterdayString(dateStr),
        [classes.date]: isRegularDate(dateStr),
      })}
      variant="subheading3"
    >
      {dateStr}
    </Text>
  )
}

export default DateGroupingCell
