import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, Text, TextProps } from '@pbt/pbt-ui-components'
import { Eye as EyeIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  () => ({
    icon: {
      verticalAlign: 'middle',
    },
  }),
  { name: 'PreviewButton' },
)

export interface PreviewButtonProps extends TextProps {
  classes?: ClassesType<typeof useStyles>
  disabled?: boolean
  onClick: () => void
}

const PreviewButton = ({
  classes: classesProp,
  onClick,
  disabled = false,
  ...rest
}: PreviewButtonProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  return (
    <Text
      link
      disabled={disabled}
      onClick={() => !disabled && onClick()}
      {...rest}
    >
      <EyeIcon className={classes.icon} />
      {t('Common:PREVIEW_ACTION')}
    </Text>
  )
}

export default PreviewButton
