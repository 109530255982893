import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Table, TableBody } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import { getDiagnosesState } from '~/store/reducers/soap'

import DifferentialDiagnosesLockedItem from './DifferentialDiagnosesLockedItem'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    titleContainer: {
      borderRight: theme.constants.tabBorder,
    },
    table: {
      border: theme.constants.tabBorder,
      borderRadius: 2,
    },
  }),
  { name: 'DifferentialDiagnosesLocked' },
)

const DifferentialDiagnosesLocked = () => {
  const classes = useStyles()
  const diagnosesState = Object.values(useSelector(getDiagnosesState))
  const { t } = useTranslation('Soap')

  return (
    <Grid container item className={classes.root}>
      <Grid item className={classes.titleContainer} px={1.5} py={2} xs={2}>
        <Text variant="h4">
          {t('Soap:SOAP_PAGE.STEP.DIFFERENTIAL_DELTA_X.TEXT')}
        </Text>
      </Grid>
      <Grid item xs pb={3} pt={1.5} px={3}>
        {diagnosesState.length > 0 ? (
          <Table className={classes.table}>
            <TableBody>
              {diagnosesState.map((diagnose) => (
                <DifferentialDiagnosesLockedItem
                  diagnose={diagnose}
                  key={diagnose.id}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Text variant="body">
            {t('Soap:DIFFERENTIAL_DIAGNOSES_LOCKED.NO_DIFFERENTIAL_DIAGNOSES')}
          </Text>
        )}
      </Grid>
    </Grid>
  )
}

export default DifferentialDiagnosesLocked
