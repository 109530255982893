import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  Defaults,
  ErrorTooltip,
  PasswordAdornment,
  PasswordInput,
  PuiTextField,
  PuiTheme,
  Text,
  Validators,
} from '@pbt/pbt-ui-components'

import EmailTextField from '~/components/common/email/EmailTextField'
import { updatePassword } from '~/store/actions/auth'
import {
  getAuthIsPasswordUpdating,
  getCurrentUser,
} from '~/store/reducers/auth'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import ProfileBottomContainer from './ProfileBottomContainer'
import ProfileTopContainer from './ProfileTopContainer'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    profileTopContainer: {
      height: 113,
      position: 'relative',
      justifyContent: 'center',
    },
    profileBottomContainer: {
      padding: 30,
      marginTop: 35,
    },
    saveButton: {
      width: '100%',
    },
    saveButtonContainer: {
      marginTop: 130,
      marginBottom: 20,
      paddingLeft: 37,
      paddingRight: 37,
    },
    backToProfileTextContainer: {
      cursor: 'pointer',
    },
    backToProfileText: {
      color: theme.colors.iconColor,
    },
    input: {
      marginTop: 30,
    },
    passwordContainer: {
      marginTop: 30,
    },
    backArrow: {
      color: theme.colors.iconColor,
    },
    form: {},
    button: {},
  }),
  { name: 'ProfileChangePassword' },
)

export interface ProfileChangePasswordProps {
  onBack: () => void
}

const ProfileChangePassword = ({ onBack }: ProfileChangePasswordProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const user = useSelector(getCurrentUser)
  const { t } = useTranslation(['Common', 'Profile', 'Validations'])

  const isPasswordUpdating = useSelector(getAuthIsPasswordUpdating)
  const [touched, setTouched] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmation, setConfirmation] = useState('')
  const [passwordValidationError, setPasswordValidationError] = useState('')
  const [confirmationValidationError, setConfirmationValidationError] =
    useState('')
  const [showPassword, setShowPassword] = useState(false)

  const setCallbackOnPasswordUpdated = useCloseAfterCreation(
    onBack,
    getAuthIsPasswordUpdating,
  )

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setTouched(false)
    setPasswordValidationError('')
    setConfirmationValidationError('')
    if (!isPasswordUpdating) {
      if (!password) {
        setPasswordValidationError(t('Validations:ENTER_PASSWORD'))
      } else if (password.length < 8) {
        setPasswordValidationError(
          t('Validations:PASSWORD_MIN_LENGTH', {
            minLength: Defaults.REQUIRED_PASSWORD_LENGTH,
          }),
        )
      } else if (!confirmation) {
        setConfirmationValidationError(t('Validations:REQUIRED_FIELD'))
      } else if (password !== confirmation) {
        setConfirmationValidationError(t('Validations:PASSWORD_DOES_NOT_MATCH'))
      } else {
        setCallbackOnPasswordUpdated()
        dispatch(updatePassword(password))
      }
    }
  }

  return (
    <form noValidate className={classes.form} onSubmit={handleSubmit}>
      <Grid container direction="column" width={412}>
        <Grid
          container
          item
          alignItems="center"
          direction="column"
          justifyContent="center"
        >
          <ProfileTopContainer className={classes.profileTopContainer}>
            <Grid
              container
              item
              alignItems="center"
              className={classes.backToProfileTextContainer}
              pl={4}
              onClick={onBack}
            >
              <ArrowBackIcon className={classes.backArrow} />
              <Text
                strong
                align="center"
                className={classes.backToProfileText}
                ml={1}
              >
                {t('Common:BACK_TO_PROFILE')}
              </Text>
            </Grid>
          </ProfileTopContainer>
          <ProfileBottomContainer
            alignItems="flex-start"
            className={classes.profileBottomContainer}
          >
            <EmailTextField
              fullWidth
              className={classes.input}
              label={t('Common:EMAIL')}
              value={user.email || ''}
            />
            <ErrorTooltip
              message={passwordValidationError}
              open={Boolean(!touched && passwordValidationError)}
            >
              <Grid
                container
                alignItems="center"
                className={classes.passwordContainer}
                wrap="nowrap"
              >
                <PasswordInput
                  adornmentEnabled
                  autoFocus
                  disabled={isPasswordUpdating}
                  error={Boolean(!touched && passwordValidationError)}
                  label={t('Profile:PROFILE_CHANGE_PASSWORD.NEW_PASSWORD')}
                  password={password}
                  onChange={(event) => {
                    setTouched(true)
                    setPassword(event.target.value)
                  }}
                  onShowPassword={setShowPassword}
                />
              </Grid>
            </ErrorTooltip>
            <ErrorTooltip
              message={confirmationValidationError}
              open={Boolean(
                !touched &&
                  !passwordValidationError &&
                  confirmationValidationError,
              )}
            >
              <PuiTextField
                InputProps={{
                  endAdornment: (
                    <PasswordAdornment
                      condition={Validators.isPasswordConfirmationValid(
                        confirmation,
                        password,
                      )}
                    />
                  ),
                }}
                autoComplete="password"
                className={classes.input}
                label={t(
                  'Profile:PROFILE_CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD',
                )}
                type={showPassword ? 'text' : 'password'}
                onChange={(event) => setConfirmation(event.target.value)}
              />
            </ErrorTooltip>
            <Grid
              container
              item
              className={classes.saveButtonContainer}
              justifyContent="center"
            >
              <Fab
                className={classNames(classes.button, classes.saveButton)}
                color="secondary"
                type="submit"
                variant="extended"
              >
                {t('Common:SAVE_ACTION')}
              </Fab>
            </Grid>
          </ProfileBottomContainer>
        </Grid>
      </Grid>
    </form>
  )
}

export default ProfileChangePassword
