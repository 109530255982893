import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Field, PuiTheme } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    radioLabel: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
      marginRight: theme.spacing(3),
    },
  }),
  { name: 'BooleanRadioGroup' },
)

const RadioValue = {
  YES: 'yes',
  NO: 'no',
}

export interface BooleanRadioGroupProps {
  field: Field
  noLabel?: string
  yesLabel?: string
}

const BooleanRadioGroup = ({
  field,
  yesLabel,
  noLabel,
}: BooleanRadioGroupProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const booleanRadioGroupYesLabel = yesLabel || t<string>('Common:YES')
  const booleanRadioGroupNoLabel = noLabel || t<string>('Common:NO')

  return (
    <RadioGroup
      row
      aria-label="boolean-radio-group"
      name="boolean-radio-group"
      value={field?.value ? RadioValue.YES : RadioValue.NO}
      onChange={(_, radioValue) => {
        const value = radioValue === RadioValue.YES
        field.setValue(value)
      }}
    >
      <FormControlLabel
        classes={{ label: classes.radioLabel }}
        control={<Radio />}
        label={booleanRadioGroupYesLabel}
        value={RadioValue.YES}
      />
      <FormControlLabel
        classes={{ label: classes.radioLabel }}
        control={<Radio />}
        label={booleanRadioGroupNoLabel}
        value={RadioValue.NO}
      />
    </RadioGroup>
  )
}

export default BooleanRadioGroup
