import React, { forwardRef } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, PuiTheme } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      position: 'absolute',
      left: 14,
    },
    rootHorizontal: {
      right: 0,
    },
    rootVertical: {
      height: 'calc(100% - 12px)',
    },
    needle: {},
    needleHorizontal: {
      display: 'inline-block',
      width: 'calc(100% - 5px)',
      height: 1,
      borderBottom: `1px solid ${theme.colors.sideText}`,
      verticalAlign: 'middle',
    },
    needleVertical: {
      marginLeft: 2,
      width: 1,
      height: '100%',
      borderLeft: `1px solid ${theme.colors.sideText}`,
    },
    needleHead: {
      width: 5,
      height: 5,
      backgroundColor: theme.colors.sideText,
      borderRadius: '50%',
    },
    needleHeadHorizontal: {
      display: 'inline-block',
    },
  }),
  { name: 'Needle' },
)

export interface NeedleProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  classes?: ClassesType<typeof useStyles>
  direction?: 'vertical' | 'horizontal'
  twoWay?: boolean
}

const Needle = forwardRef<HTMLDivElement, NeedleProps>(function Needle(
  { twoWay, children, direction = 'vertical', classes: classesProp, ...rest },
  ref,
) {
  const classes = useStyles({ classes: classesProp })

  const isVertical = direction === 'vertical'
  const isHorizontal = direction === 'horizontal'

  return (
    <div
      className={classNames(classes.root, {
        [classes.rootHorizontal]: isHorizontal,
        [classes.rootVertical]: isVertical,
      })}
      ref={ref}
      {...rest}
    >
      <div
        className={classNames(classes.needleHead, {
          [classes.needleHeadHorizontal]: isHorizontal,
        })}
      />
      <div
        className={classNames(classes.needle, {
          [classes.needleHorizontal]: isHorizontal,
          [classes.needleVertical]: isVertical,
        })}
      />
      {twoWay && (
        <div
          className={classNames(classes.needleHead, {
            [classes.needleHeadHorizontal]: isHorizontal,
          })}
        />
      )}
      {children}
    </div>
  )
})

export default Needle
