import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { moment, PuiTheme, Text, Utils } from '@pbt/pbt-ui-components'

import { OrderType } from '~/constants/SOAPStates'
import { fetchSoapTasks } from '~/store/actions/tasks'
import { getPrescriptionStates } from '~/store/reducers/constants'
import { getSelectedOrders } from '~/store/reducers/orders'
import {
  getIsCurrentContextSoap,
  getIsFinalized,
  getSOAPisFetchingSoapOrders,
  getSOAPisLoading,
} from '~/store/reducers/soap'
import {
  getMultipleTasks,
  getSoapTasksList,
  getSoapTasksSelectedDate,
  getTasksIsDeleting,
  getTasksIsFetching,
  getTasksValidationError,
} from '~/store/reducers/tasks'
import { getTimetableIsLoading } from '~/store/reducers/timetable'
import { Order, Task } from '~/types'
import {
  getOrderByUniqueId,
  getUniqueOrderId,
  isOrderTask,
} from '~/utils/orderUtils'
import { getIsCreatedPrescriptionChewyActiveRx } from '~/utils/prescription'
import { dateToIntervals } from '~/utils/time'
import useErrorAlert from '~/utils/useErrorAlert'

import ToDoTaskListActions from './ToDoTasksListActions'
import ToDoTasksListHeader from './ToDoTasksListHeader'
import ToDoTasksListItem, { ToDoTaskGroup } from './ToDoTasksListItem'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    rootEmptyState: {
      border: theme.constants.tableBorder,
      borderRadius: 2,
      backgroundColor: theme.colors.tableBackground,
    },
    container: {
      border: theme.constants.tabBorder,
      backgroundColor: theme.colors.tableBackground,
      borderRadius: 2,
    },
    spinner: {
      margin: theme.spacing(5, 0),
    },
    emptyStateContainer: {
      backgroundColor: theme.colors.tableBackground,
    },
    titleContainer: {
      borderRight: theme.constants.tabBorder,
    },
  }),
  { name: 'ToDoTasksList' },
)

interface ToDoTasksListProps {
  className?: string
  editDisabled?: boolean
}

const ToDoTasksList = ({ className, editDisabled }: ToDoTasksListProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Soap'])

  const selectedOrders = useSelector(getSelectedOrders)
  const isSoapLoading = useSelector(getSOAPisLoading)
  const isLoadingSoapOrders = useSelector(getSOAPisFetchingSoapOrders)
  const isTimetableLoading = useSelector(getTimetableIsLoading)
  const isFetchingTasks = useSelector(getTasksIsFetching)
  const isDeleting = useSelector(getTasksIsDeleting)
  const tasksList = useSelector(getSoapTasksList)
  const tasks = useSelector(getMultipleTasks(tasksList))
  const selectedDate = useSelector(getSoapTasksSelectedDate)
  const PrescriptionStates = useSelector(getPrescriptionStates)
  const isSoapFinalized = useSelector(getIsFinalized)
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)

  const isReadOnly = isSoapFinalized || !isCurrentContextSoap

  const [checkedTaskIds, setCheckedTaskIds] = useState<string[]>([])
  const [checkedOrderIds, setCheckedOrderIds] = useState<string[]>([])
  const [scrollOffset, setScrollOffset] = useState(0)

  const startDate = moment(selectedDate).startOf('day')
  const endDate = moment(selectedDate).endOf('day')
  const timeColumns = dateToIntervals(startDate, endDate)

  useErrorAlert({
    errorSelector: getTasksValidationError,
    onOpenAction: fetchSoapTasks(),
  })

  const hasRecurringTasks = tasks.some((task) => Boolean(task.recurrenceModeId))
  const isLoading =
    isLoadingSoapOrders ||
    isSoapLoading ||
    isTimetableLoading ||
    isFetchingTasks

  const orders = selectedOrders.filter(
    (order) =>
      order.type !== OrderType.TASK &&
      !tasks.some((task) => isOrderTask(task, order)),
  )

  useEffect(() => {
    setCheckedTaskIds(
      checkedTaskIds.filter((checkedId) =>
        tasks.some(R.propEq('id', checkedId)),
      ),
    )
  }, [tasks.length])

  useEffect(() => {
    setCheckedOrderIds(
      checkedOrderIds.filter((id) => getOrderByUniqueId(id, orders)),
    )
  }, [orders.length])

  const checkItem = (
    itemId: string,
    items: string[],
    setItems: (items: string[]) => void,
  ) => {
    if (items.includes(itemId)) {
      setItems(R.without([itemId], items))
    } else {
      setItems(items.concat(itemId))
    }
  }

  const clearCheckedItems = () => {
    setCheckedOrderIds([])
    setCheckedTaskIds([])
  }

  const checkTask = (task: Task) =>
    checkItem(task.id, checkedTaskIds, setCheckedTaskIds)
  const checkOrder = (order: Order) =>
    checkItem(getUniqueOrderId(order), checkedOrderIds, setCheckedOrderIds)

  const [assignedTasks, unassignedTasks] = R.partition(
    ({ assigned }) => Boolean(assigned),
    tasks,
  )
  const groupedAssignedTasks = R.groupBy(
    ({ assigned }) => assigned || '',
    assignedTasks,
  )
  const assignedGroups: ToDoTaskGroup[] = Object.keys(groupedAssignedTasks).map(
    (userId) => ({
      name: '',
      id: userId,
      items: groupedAssignedTasks[userId],
    }),
  )
  const hasAssignedGroups = assignedGroups.length > 0

  const unassignedGroup: ToDoTaskGroup = {
    nameTranslation: t('Common:UNASSIGNED'),
    name: 'Unassigned',
    id: 'unassigned',
    items: (unassignedTasks as (Task | Order)[]).concat(orders),
  }

  const groups = assignedGroups
    .concat(unassignedGroup)
    .filter((group) => group.items.length > 0)

  if (tasks.length === 0 && isLoading) {
    return (
      <Grid container item justifyContent="center">
        <CircularProgress className={classes.spinner} size={32} />
      </Grid>
    )
  }

  const rowRefs = Array.from(groups, (group) =>
    Array.from({ length: (group.items || []).length }, () =>
      React.createRef<HTMLDivElement>(),
    ),
  )
  const rowHeaderRefs = Array.from(
    { length: hasAssignedGroups ? groups.length : 0 },
    () => React.createRef<HTMLDivElement>(),
  )
  const contentRefs = [...R.flatten(rowRefs), ...rowHeaderRefs]
  const notDeclinedSelectableOrders = orders
    .filter(
      (order) =>
        Utils.getConstantName(order.stateId, PrescriptionStates) !==
          'Declined' &&
        !getIsCreatedPrescriptionChewyActiveRx(
          order.prescriptionType,
          order.origin,
        ),
    )
    .map((order) => getUniqueOrderId(order))
  const isAllTasksSelected =
    notDeclinedSelectableOrders.length > 0 &&
    notDeclinedSelectableOrders.every((order) =>
      checkedOrderIds.includes(order),
    ) &&
    checkedTaskIds.length === tasks.length
  const hasTasksToSelect =
    tasks.length > 0 || notDeclinedSelectableOrders.length > 0
  const shouldShowEmptyState = groups.length === 0 && isReadOnly

  const handleSelectAll = () => {
    if (isAllTasksSelected) {
      clearCheckedItems()
    } else {
      setCheckedOrderIds(notDeclinedSelectableOrders)
      setCheckedTaskIds(tasks.map((task) => task.id))
    }
  }

  return (
    <Grid
      container
      item
      className={classNames(className, classes.root, {
        [classes.rootEmptyState]: shouldShowEmptyState,
      })}
      direction="column"
    >
      {shouldShowEmptyState ? (
        <Grid
          container
          item
          className={classes.emptyStateContainer}
          wrap="nowrap"
        >
          <Grid container item xs direction="column" pb={3} pt={1.5} px={3}>
            <Text>{t('Soap:NO_TASK_OR_ITEMS_WERE_ORDERED_IN_SOAP')}.</Text>
          </Grid>
        </Grid>
      ) : (
        <>
          <ToDoTasksListHeader
            contentRefs={contentRefs}
            handleSelectAll={handleSelectAll}
            hasRecurringTasks={hasRecurringTasks}
            hasTasksToSelect={hasTasksToSelect}
            isAllTasksSelected={isAllTasksSelected}
            onScrollOffsetChange={setScrollOffset}
          />
          <Grid container item className={classes.container}>
            {groups.map((group, groupIndex) => (
              <ToDoTasksListItem
                checkedOrderIds={checkedOrderIds}
                checkedTaskIds={checkedTaskIds}
                disabled={editDisabled || isDeleting}
                group={group}
                hasRecurringTasks={hasRecurringTasks}
                headerRef={rowHeaderRefs[groupIndex]}
                key={group.id}
                rowRef={rowRefs[groupIndex]}
                scrollOffset={scrollOffset}
                showHeader={hasAssignedGroups}
                timeColumns={timeColumns}
                onCheckOrder={checkOrder}
                onCheckTask={checkTask}
              />
            ))}
            <ToDoTaskListActions
              checkedOrderIds={checkedOrderIds}
              checkedTaskIds={checkedTaskIds}
              clearCheckedItems={clearCheckedItems}
              editDisabled={editDisabled}
              orders={orders}
              tasks={tasks}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default ToDoTasksList
