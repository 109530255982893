import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { PuiTheme, QuestionMode } from '@pbt/pbt-ui-components'

import PuiTabsPanel from '~/components/common/PuiTabsPanel'
import EmptyListPlaceholder from '~/components/dashboard/soap/list-with-filters/EmptyListPlaceholder'
import { getQuestionsIsFetching } from '~/store/reducers/questions'

import QuestionsTabsActions from './QuestionsTabsActions'
import { useQuestionsTabs } from './utils/useQuestionsTabs'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    placeholder: {
      fontSize: '1.6rem',
      alignSelf: 'flex-start',
      margin: theme.spacing(2),
      padding: 0,
    },
    tabContainer: {
      marginTop: theme.spacing(1.4),
    },
    tabPanelContainer: {
      margin: theme.spacing(1, 0),
    },
    tabRoot: {
      minWidth: 101,
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'QuestionsTabs' },
)

export interface QuestionsTabsProps {
  hasNoQuestions: boolean
  mode: QuestionMode
}

const QuestionsTabs = ({ hasNoQuestions, mode }: QuestionsTabsProps) => {
  const classes = useStyles()

  const { t } = useTranslation('Common')

  const isFetching = useSelector(getQuestionsIsFetching)

  const [showAllPriorAnswers, setShowAllPriorAnswers] = useState(false)

  const handleShowAllPriorAnswers = () => {
    setShowAllPriorAnswers(!showAllPriorAnswers)
  }

  const { onTabChange, panels, tabs, tabValue } = useQuestionsTabs({
    mode,
    showAllPriorAnswers,
  })

  if (hasNoQuestions) {
    return (
      <EmptyListPlaceholder
        categoryName={t('Common:QUESTION_OTHER').toLowerCase()}
        categoryType="questions"
        className={classes.placeholder}
      />
    )
  }

  return (
    <PuiTabsPanel
      actions={
        <QuestionsTabsActions
          showAllPriorAnswers={showAllPriorAnswers}
          onShowAllPriorAnswers={handleShowAllPriorAnswers}
        />
      }
      classes={{
        tabContainer: classes.tabContainer,
        tabPanelContainer: classes.tabPanelContainer,
        tabRoot: classes.tabRoot,
      }}
      isLoading={isFetching}
      panels={panels}
      tabValue={tabValue}
      tabs={tabs}
      onChange={onTabChange}
    >
      {({ tabPanel: { Component } }) => <Component />}
    </PuiTabsPanel>
  )
}

export default QuestionsTabs
