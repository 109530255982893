import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import {
  LanguageUtils,
  PuiTheme,
  SentenceFormatter,
  StateLabel,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { Wings as WingsIcon } from '@pbt/pbt-ui-components/src/icons'

import { getGenderString } from '~/components/common/inputs/gender/genderUtils'
import { getPatientToMatchById } from '~/store/duck/chewyPets'
import { getGender, getSpecies } from '~/store/reducers/constants'
import { getAge } from '~/utils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    wingsIcon: {
      fontSize: '3.5rem',
      color: theme.colors.title,
    },
    disabled: {
      opacity: 0.7,
    },
    textContainer: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
  { name: 'PatientMenuItem' },
)

interface PatientMenuItemProps {
  patientId: string
}

export const PatientMenuItem = ({ patientId }: PatientMenuItemProps) => {
  const { t } = useTranslation('Common')
  const classes = useStyles()

  const patient = useSelector(getPatientToMatchById(patientId))
  const Species = useSelector(getSpecies)
  const Gender = useSelector(getGender)

  const { dateOfBirth, name, isActive, isDeceased } = patient || {}
  const age = dateOfBirth ? getAge(dateOfBirth) : null
  const gender = getGenderString(patient.gender, Gender)
  const species = Utils.getConstantsValue(patient.species, Species)?.name

  return (
    <>
      <Box className={classes.textContainer}>
        <Text
          inline
          strong
          disabled={!isActive}
          variant="body2"
        >{`${name}: `}</Text>
        <Text inline disabled={!isActive} px={0.5} variant="body2">
          {LanguageUtils.getSentence(
            [age, gender, species],
            SentenceFormatter.REGULAR,
            ', ',
          )}
        </Text>
      </Box>
      <>
        {isDeceased && (
          <>
            <WingsIcon
              className={classNames({
                [classes.wingsIcon]: true,
                [classes.disabled]: !isActive,
              })}
            />
            <StateLabel disabled m={0.5} variant="small">
              {t('Common:DECEASED')}
            </StateLabel>
          </>
        )}
        {!isActive && (
          <StateLabel disabled m={0.5} variant="small">
            {t('Common:INACTIVE_ONE')}
          </StateLabel>
        )}
      </>
    </>
  )
}
