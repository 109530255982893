import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'

import { sendGoogleAnalyticsEvent } from '~/components/common/GoogleAnalytics'
import ChameleonEasterEgg from '~/components/common/images/ChameleonEasterEgg.png'
import HedgehogEasterEgg from '~/components/common/images/HedgehogEasterEgg.png'
import SlothEasterEgg from '~/components/common/images/SlothEasterEgg.png'
import {
  clearEasterEggEventQueue,
  updateEasterEggEventQueue,
} from '~/store/actions/easterEgg'
import { getEasterEggEventQueue } from '~/store/reducers/easterEgg'

const DEFAULT_EASTER_EGG_ODDS = '1500'
const DEFAULT_EASTER_EGG_TIME = 5000
const EASTER_EGG_SIZE = 500
const END_ANIMATION_POINT = -40
const START_ANIMATION_POINT = 140
const ANIMATION_DIFFERENCE = START_ANIMATION_POINT - END_ANIMATION_POINT
const ANIMATION_FRAMES = [
  START_ANIMATION_POINT,
  END_ANIMATION_POINT + ANIMATION_DIFFERENCE * 0.6,
  END_ANIMATION_POINT + ANIMATION_DIFFERENCE * 0.5,
  END_ANIMATION_POINT + ANIMATION_DIFFERENCE * 0.4,
  END_ANIMATION_POINT,
]

const useStyles = makeStyles(
  () => ({
    easterEgg: {
      width: `${EASTER_EGG_SIZE}px`,
      height: `${EASTER_EGG_SIZE}px`,
      position: 'fixed',
      top: `-${EASTER_EGG_SIZE / 2}px`,
      left: `-${EASTER_EGG_SIZE / 2}px`,
      zIndex: 10000,
      animation: `$myEffect ${DEFAULT_EASTER_EGG_TIME}ms linear`,
      opacity: 0,
    },
    '@keyframes myEffect': {
      '0%': {
        opacity: 1,
        transform: `translateX(${ANIMATION_FRAMES[0]}vw) translateY(${ANIMATION_FRAMES[0]}vh)`,
      },
      '25%': {
        opacity: 1,
        transform: `translateX(${ANIMATION_FRAMES[1]}vw) translateY(${ANIMATION_FRAMES[1]}vh)`,
      },
      '50%': {
        opacity: 1,
        transform: `translateX(${ANIMATION_FRAMES[2]}vw) translateY(${ANIMATION_FRAMES[2]}vh)`,
      },
      '75%': {
        opacity: 1,
        transform: `translateX(${ANIMATION_FRAMES[3]}vw) translateY(${ANIMATION_FRAMES[3]}vh)`,
      },
      '99%': {
        opacity: 1,
        transform: `translateX(${ANIMATION_FRAMES[4]}vw) translateY(${ANIMATION_FRAMES[4]}vh)`,
      },
      '100%': {
        opacity: 0,
        transform: `translateX(${ANIMATION_FRAMES[4]}vw) translateY(${ANIMATION_FRAMES[4]}vh)`,
      },
    },
    easterEggImage: {
      width: '100%',
      height: '100%',
    },
  }),
  { name: 'EasterEggWatcher' },
)

const animationImages = [ChameleonEasterEgg, SlothEasterEgg, HedgehogEasterEgg]

const EasterEggWatcher = () => {
  const dispatch = useDispatch()
  const qualifyingEventQueue = useSelector(getEasterEggEventQueue)
  const [animationImage, setAnimationImage] = useState(null)
  const classes = useStyles()

  const easterEggOdds = JSON.parse(
    localStorage?.getItem('devEasterEggOdds') || DEFAULT_EASTER_EGG_ODDS,
  )

  useEffect(() => {
    if (qualifyingEventQueue && qualifyingEventQueue.length > 0) {
      // shift the event off the queue
      const newQueue = [...qualifyingEventQueue]
      const event = newQueue.shift()
      const gaEventOptions = {
        action_type: event?.actionType,
        action_subtype: event?.actionSubtype,
        easter_egg_triggered: false,
      }

      // generate a random number from 0 to (easterEggOdds-1)
      // if the odds are <=0, then never show the animation
      const rng =
        easterEggOdds > 0 ? Math.floor(Math.random() * easterEggOdds) : -1

      // 1 / (easterEggOdds) chance that rng === 0
      if (rng === 0) {
        // show the animation with a random image
        const image =
          animationImages[Math.floor(Math.random() * animationImages.length)]
        setAnimationImage(image)

        gaEventOptions.easter_egg_triggered = true

        // clear the event queue if we show an animation
        dispatch(clearEasterEggEventQueue())
      } else {
        // remove the event from the queue
        dispatch(updateEasterEggEventQueue(newQueue))
      }
      sendGoogleAnalyticsEvent('easter_egg', gaEventOptions)
    } else if (animationImage) {
      // if the event queue is cleared and there is an image being shown, then remove any animation
      setTimeout(() => setAnimationImage(null), DEFAULT_EASTER_EGG_TIME)
    }
  }, [qualifyingEventQueue])

  return (
    animationImage &&
    createPortal(
      <div className={classes.easterEgg}>
        <img
          alt="easter egg"
          className={classes.easterEggImage}
          src={animationImage || ''}
        />
      </div>,
      document.body,
    )
  )
}

export default EasterEggWatcher
