import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import Link from './Link'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(0),
      },
    },
  }),
  { name: 'ForgotPasswordLink' },
)

interface ForgotPasswordLinkProps {
  onClick?: () => void
}

const ForgotPasswordLink = ({ onClick }: ForgotPasswordLinkProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Auth')

  return (
    <Text align="right" className={classes.root} variant="body5">
      <Link color="secondary" to="/auth/password-reset" onClick={onClick}>
        {t('Auth:FORGOT_PASSWORD_LINK.FORGOT')}
      </Link>
    </Text>
  )
}

export default ForgotPasswordLink
