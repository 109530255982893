import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  CityTextField,
  CountrySelect,
  Field,
  PuiDialog,
  PuiTextField,
  PuiTheme,
  StateSelect,
  Text,
  useFields,
  UserPermissions,
  ZipInput,
} from '@pbt/pbt-ui-components'
import { Warning } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 700,
      maxWidth: 700,
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(3),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3, 4, 2, 4),
      },
    },
    button: {
      minWidth: 160,
    },
    icon: {
      color: theme.colors.alertWarning,
    },
  }),
  { name: 'GeneralInformationLocationDialog' },
)

interface GeneralInformationLocationDialogFieldProps {
  address: Field
  city: Field
  clientsCommunicationEnabled: Field
  country: Field
  state: Field
  suite: Field
  zipcode: Field
}

interface GeneralInformationLocationDialogProps extends BasePuiDialogProps {
  originalFields: GeneralInformationLocationDialogFieldProps
  permissions: UserPermissions
}

export const GeneralInformationLocationDialog = ({
  open,
  onClose,
  originalFields: {
    address: originalAddress,
    city: originalCity,
    clientsCommunicationEnabled,
    country: originalCountry,
    state: originalState,
    suite: originalSuite,
    zipcode: originalZipcode,
  },
  permissions,
}: GeneralInformationLocationDialogProps) => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )
  const { t } = useTranslation(['Dialogs'])

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'address',
        label: originalAddress.label,
        validators: ['required'],
        initialValue: originalAddress.value || '',
      },
      {
        name: 'suite',
        label: originalSuite.label,
        validators: [],
        initialValue: originalSuite.value || '',
      },
      {
        name: 'country',
        validators: ['required'],
        initialValue: originalCountry.value,
      },
      {
        name: 'city',
        validators: ['required'],
        initialValue: originalCity.value || '',
      },
      {
        name: 'state',
        type: 'select',
        validators: ['required'],
        initialValue: originalState.value || '',
      },
      {
        name: 'zipcode',
        label: originalZipcode.label,
        validators: ['required', 'zip'],
        initialValue: originalZipcode.value || '',
      },
    ],
    false,
  )

  const { address, suite, country, city, state, zipcode } = fields

  const handleEnableNow = () => {
    if (!validate()) {
      return
    }

    originalAddress.setValue(address.value)
    originalSuite.setValue(suite.value)
    originalCountry.setValue(country.value)
    originalCity.setValue(city.value)
    originalState.setValue(state.value)
    originalZipcode.setValue(zipcode.value)

    clientsCommunicationEnabled.setValue(true)

    if (onClose) {
      onClose()
    }
  }

  const handleEnableLater = () => {
    clientsCommunicationEnabled.setValue(false)
    reset()

    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiDialog
      actions={
        <Grid container direction="row" justifyContent="center" pt={2}>
          <Grid item mr={5}>
            <ButtonWithLoader
              className={classes.button}
              color="secondary"
              onClick={handleEnableLater}
            >
              Enable Later
            </ButtonWithLoader>
          </Grid>

          <Grid item>
            <ButtonWithLoader
              className={classes.button}
              onClick={handleEnableNow}
            >
              Enable now
            </ButtonWithLoader>
          </Grid>
        </Grid>
      }
      aria-labelledby="general-information-location-dialog"
      classes={{
        paper: classes.paper,
      }}
      maxWidth={isMobile ? 'sm' : 'md'}
      open={open}
      onClose={onClose}
    >
      <Grid container alignItems="center" direction="column" pb={2}>
        <Grid item>
          <Warning className={classes.icon} />
        </Grid>
        <Grid item>
          <Text align="center" variant="body">
            {t('Dialogs:GENERAL_INFORMATION_LOCATION_DIALOG')}
          </Text>
        </Grid>
        <Grid
          container
          item
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? 0 : 1}
        >
          <Grid item md={8}>
            <PuiTextField
              disabled={!permissions.update}
              field={address}
              inputProps={{ maxLength: 100 }}
              label={`${address.label}*`}
            />
          </Grid>
          <Grid item md={4}>
            <PuiTextField
              disabled={!permissions.update}
              field={suite}
              inputProps={{ maxLength: 100 }}
              label={suite.label}
            />
          </Grid>
        </Grid>
        <Grid container item alignItems="flex-end" spacing={isMobile ? 0 : 1}>
          <Grid item md={4} xs={12}>
            <CityTextField
              required
              country={country.value}
              disabled={!permissions.update}
              field={city}
            />
          </Grid>
          <Grid item md={3} xs={4}>
            <CountrySelect
              required
              disabled={!permissions.update}
              field={country}
            />
          </Grid>
          <Grid item md={3} xs={4}>
            <StateSelect
              required
              country={country.value}
              disabled={!permissions.update}
              field={state}
            />
          </Grid>
          <Grid item md={2} xs={4}>
            <PuiTextField
              InputProps={{
                inputComponent: ZipInput,
                inputProps: { country: country.value },
              }}
              disabled={!permissions.update}
              field={zipcode}
              label={`${zipcode.label}*`}
            />
          </Grid>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}
