import React from 'react'
import { useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ApiError, ErrorTooltip, PuiTheme } from '@pbt/pbt-ui-components'

import { getErrorMessage, isWorkingOutsidePracticeHours } from '~/utils/errors'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    errorTooltip: {
      width: 369,
      backgroundColor: theme.colors.loginPageSecondaryText,
      maxWidth: 'unset',
    },
    errorArrow: {
      color: theme.colors.loginPageSecondaryText,
    },
    errorTooltipMobile: {
      backgroundColor: theme.colors.loginPageSecondaryText,
    },
  }),
  { name: 'AuthorizationErrorTooltip' },
)

interface AuthorizationErrorTooltipProps {
  children: React.ReactElement
  error: ApiError | null
}

const AuthorizationErrorTooltip = ({
  error,
  children,
}: AuthorizationErrorTooltipProps) => {
  const classes = useStyles()

  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )

  const workingOutsidePracticeHours = error
    ? isWorkingOutsidePracticeHours(error)
    : false

  const errorTooltipClass = workingOutsidePracticeHours
    ? isMobile
      ? classes.errorTooltipMobile
      : classes.errorTooltip
    : ''

  return workingOutsidePracticeHours ? (
    <ErrorTooltip
      classes={{
        errorTooltip: errorTooltipClass,
        errorArrow: classes.errorArrow,
      }}
      message={getErrorMessage(error)}
      open={Boolean(error)}
    >
      {children}
    </ErrorTooltip>
  ) : (
    children
  )
}

export default AuthorizationErrorTooltip
