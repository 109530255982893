import React from 'react'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, PuiTheme, Text } from '@pbt/pbt-ui-components'
import { WarnAlert } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    outstandingContainer: {
      border: theme.constants.tableBorder,
      height: 56,
      backgroundColor: theme.colors.tableBackground,
    },
    icon: {
      width: 20,
      height: 20,
      color: theme.colors.important,
    },
    outstandingTitle: {
      color: theme.colors.important,
    },
    importantButton: {
      minWidth: 150,
      height: 40,
      marginLeft: 'auto',
      backgroundColor: theme.colors.important,
      color: theme.colors.tableBackground,
      transition:
        'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
      boxShadow: 'none',
      '&&&&:hover': {
        boxShadow:
          'box-shadow: 1px 1px 2px 0 rgba(60,56,56,0.15), 0 1px 4px 0 rgba(76,73,73,0.12)',
        backgroundColor: '#EF7605',
      },
      '&&&&:active': {
        boxShadow: 'box-shadow: 0 4px 23px 0 rgba(0,0,0,0.12)',
        backgroundColor: '#CF6300',
      },
    },
  }),
  { name: 'FinalizeSection' },
)

export interface FinalizeSectionProps {
  buttonLabel: React.ReactNode
  classes?: ClassesType<typeof useStyles>
  onClick: () => void
  title: React.ReactNode
}

const FinalizeSection = ({
  title,
  buttonLabel,
  onClick,
  classes: classesProp,
}: FinalizeSectionProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Grid
      container
      alignItems="center"
      className={classes.outstandingContainer}
      mt={2}
      px={2}
    >
      <WarnAlert className={classes.icon} />
      <Text className={classes.outstandingTitle} ml={1} variant="h4">
        {title}
      </Text>
      <Fab
        className={classes.importantButton}
        color="inherit"
        type="button"
        variant="extended"
        onClick={onClick}
      >
        {buttonLabel}
      </Fab>
    </Grid>
  )
}

export default FinalizeSection
