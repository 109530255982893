import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  Nil,
  PuiDialog,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import PdfPreview from '~/components/common/pdf/PdfPreview'
import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'

import OrderResultsActions from './OrderResultsActions'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      maxWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    paperOld: {
      width: 660,
      maxWidth: 660,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    dialogContentRoot: {
      padding: theme.spacing(2, 3),
    },
  }),
  { name: 'OrderResultsDialog' },
)

export interface OrderResultsDialogProps extends BasePuiDialogProps {
  canComplete?: boolean
  hasResults?: boolean
  orderId: string | Nil
  pdfUrl: string | Nil
  vendorId: string | Nil
}

const OrderResultsDialog = ({
  open,
  orderId,
  vendorId,
  pdfUrl,
  canComplete = false,
  hasResults = false,
  onClose,
}: OrderResultsDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const isDocumentFullScreenEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DOCUMENT_FULL_SCREEN),
  )

  const [pdfPreviewIsLoading, setPdfPreviewIsLoading] = useState(false)

  const title = hasResults
    ? t('Common:LAB_TEST_RESULTS')
    : t('Common:LAB_ORDER')

  return (
    <PuiDialog
      actions={
        <OrderResultsActions
          canComplete={canComplete}
          orderId={orderId}
          pdfUrl={pdfUrl}
          vendorId={vendorId}
        />
      }
      aria-labelledby="order-results-dialog"
      classes={{
        paper: isDocumentFullScreenEnabled ? classes.paper : classes.paperOld,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      disableClose={pdfPreviewIsLoading}
      open={open}
      scroll="paper"
      title={title}
      onClose={onClose}
    >
      <PdfPreview
        file={{ fileUrl: pdfUrl }}
        fullScreen={isDocumentFullScreenEnabled}
        setIsLoading={setPdfPreviewIsLoading}
      />
    </PuiDialog>
  )
}

export default OrderResultsDialog
