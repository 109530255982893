import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
  PuiSelect,
  PuiTheme,
  Utils,
} from '@pbt/pbt-ui-components'

import { getZoomUsers } from '~/store/duck/conferencing'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 'min-content',
      minWidth: 385,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    dialogContentRoot: {
      padding: theme.spacing(3),
      alignItems: 'center',
    },
    zoomUserSelect: {
      width: 216,
    },
    button: {
      marginRight: theme.spacing(2),
      minWidth: 150,
    },
  }),
  { name: 'ChooseZoomUserDialog' },
)

export interface ChooseZoomUserDialogProps extends BasePuiDialogProps {
  onOk: (id: string) => void
}

const ChooseZoomUserDialog = ({
  open,
  onClose,
  onOk,
}: ChooseZoomUserDialogProps) => {
  const classes = useStyles()
  const zoomUsers = useSelector(getZoomUsers)
  const { t } = useTranslation(['Common', 'Dialogs'])

  const zoomUsersList = zoomUsers.map((person) => ({
    id: person.id,
    name: Utils.getPersonString(person),
  }))

  const [zoomUserId, setZoomUserId] = useState(zoomUsersList?.[0]?.id)

  return (
    <PuiDialog
      actions={
        <>
          <ButtonWithLoader
            className={classes.button}
            color="secondary"
            onClick={onClose}
          >
            {t('Common:CANCEL_ACTION')}
          </ButtonWithLoader>
          <ButtonWithLoader
            className={classes.button}
            onClick={() => {
              if (onClose) {
                onClose()
              }
              onOk(zoomUserId)
            }}
          >
            {t('Common:ADD_ZOOM_MEETING')}
          </ButtonWithLoader>
        </>
      }
      aria-labelledby="choose-zoom-user-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={t('Dialogs:CHOOSE_ZOOM_USER_DIALOG.CHOOSE_ZOOM_USER')}
      onClose={onClose}
    >
      <FormControl className={classes.zoomUserSelect} margin="none">
        <InputLabel>{t('Common:ASSIGN_ZOOM_USER')}</InputLabel>
        <PuiSelect
          items={zoomUsersList}
          renderEmpty={false}
          value={zoomUserId}
          onChange={Utils.handleFormSelectInput((newZoomUserId) =>
            setZoomUserId(newZoomUserId),
          )}
        />
      </FormControl>
    </PuiDialog>
  )
}

export default ChooseZoomUserDialog
