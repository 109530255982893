import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  DateFormat,
  ErrorTooltip,
  moment,
  Nil,
  PermissionArea,
  PuiSelect,
  PuiTextField,
  PuiTheme,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { Boop } from '@pbt/pbt-ui-components/src/icons'

import AddToAppointmentCard from '~/components/common/addToAppointment/AddToAppointmentCard'
import ContactTargetSlot from '~/components/common/ContactTargetSlot'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import UserSelect, {
  UserSelectFilterScope,
} from '~/components/common/inputs/UserSelect'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { useCreatableTaskTypes } from '~/store/hooks/tasks'
import {
  getCRUDByArea,
  getCurrentBusinessWellnessPlansEnabled,
} from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getTasksIsFetching } from '~/store/reducers/tasks'
import { getUser } from '~/store/reducers/users'
import {
  ContactSlot,
  DataHandleWithUnsavedChanges,
  Task,
  TeamFilter,
} from '~/types'
import { isFieldValuesChanged } from '~/utils'
import useDialog from '~/utils/useDialog'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import { BaseTemplateInputHandle } from '../template-inputs/BaseTemplateInput/BaseTemplateInput'
import NotesTemplateInput from '../template-inputs/NotesTemplateInput'
import { getPatientHasMembership } from '../wellness-plans/wellnessPlanUtils'
import TaskRecurrenceSettings, {
  TaskRecurrenceSettingsHandle,
} from './TaskRecurrenceSettings'
import TaskStateLabel from './TaskStateLabel'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    detailsRow: {
      borderBottom: theme.constants.outlinedInputBorder,
    },
    listbox: {
      maxHeight: 190,
    },
  }),
  { name: 'TaskComponent' },
)

export interface TaskProps {
  appointmentId?: string
  className?: string
  clientId?: string | Nil
  dueDate?: string | Nil
  forClientActive?: boolean
  patientId?: string | Nil
  shortTaskFlow?: boolean
  shouldUseVCRBehavior?: boolean
  soapId?: string
  task?: Task
}

export interface TaskHandle extends DataHandleWithUnsavedChanges<Task> {}

const TaskComponent = forwardRef<TaskHandle, TaskProps>(function TaskComponent(
  {
    className,
    clientId,
    patientId,
    appointmentId,
    dueDate: dueDateProp,
    shouldUseVCRBehavior,
    soapId,
    task,
    forClientActive = false,
    shortTaskFlow = false,
  },
  ref,
) {
  const classes = useStyles()
  const wellnessPlansEnabled = useSelector(
    getCurrentBusinessWellnessPlansEnabled,
  )
  const { t } = useTranslation(['Common', 'Dialogs', 'Tasks', 'Tooltips'])

  const rootRef = useRef<HTMLDivElement>(null)
  const taskRecurrenceSettingsRef = useRef<TaskRecurrenceSettingsHandle>(null)
  const notesRef = useRef<BaseTemplateInputHandle>(null)
  const instructionsRef = useRef<BaseTemplateInputHandle>(null)
  const taskPermissions = useSelector(getCRUDByArea(PermissionArea.TASK))

  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )

  const [openAddToAppointmentDialog, closeAddToAppointmentDialog] = useDialog(
    DialogNames.ADD_TO_APPOINTMENT,
  )

  const isCreate = !task?.id
  const CreatableTaskTypes = useCreatableTaskTypes()
  const OtherType = Utils.findConstantIdByName('Other', CreatableTaskTypes)
  const isFetching = useSelector(getTasksIsFetching)
  const isTaskOptimizationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.TASK_OPTIMIZATION),
  )
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
  )

  const isContextItem = useIsCurrentContextItem(task)

  const getClientId = () => clientId || task?.clientId || task?.client
  const getPatientId = () => patientId || task?.patientId || task?.patient
  const getAppointmentId = () =>
    appointmentId || task?.appointmentId || task?.appointment || null
  const getContactId = () => task?.contactId || task?.contact
  const getAssigned = () => task?.assignedId || task?.assigned
  const getSlotInfo = () => ({
    clientId: getClientId(),
    patientId: getPatientId(),
    contactId: getContactId(),
  })

  const [slotInfo, setSlotInfo] = useState<ContactSlot>(getSlotInfo())

  const [selectedAppointmentId, setSelectedAppointmentId] = useState<
    string | null
  >(getAppointmentId())

  const {
    clientId: selectedClientId,
    patientId: selectedPatientId,
    contactId: selectedContactId,
  } = slotInfo

  const { fields, validate, reset, touched } = useFields(
    [
      {
        name: 'forClient',
        label: isBoopDisablementEnabled
          ? t('Tasks:LABEL.CLIENT_TASK')
          : t('Tasks:LABEL.BOOP_SYSTEM_PET_PARENT_TASK'),
        type: 'toggle',
        initialValue: isCreate ? forClientActive : task.forClient,
      },
      {
        name: 'name',
        label: t('Common:TASK_TITLE'),
        validators: ['required'],
        initialValue: task?.name || '',
      },
      {
        name: 'typeId',
        label: t('Common:TYPE_ONE'),
        initialValue: isCreate ? OtherType : task.typeId,
      },
      {
        name: 'assigneeId',
        label: t('Common:ASSIGN_TEAM_MEMBER'),
        initialValue: getAssigned() || '',
      },
      {
        name: 'notes',
        label: t('Common:NOTES'),
        initialValue: task?.notes || '',
      },
      {
        name: 'instructions',
        label: t('Common:INSTRUCTIONS_FOR_PET_PARENT'),
        initialValue: task?.instructions || '',
      },
      {
        name: 'clientNotes',
        label: t('Common:PET_PARENT_NOTES'),
        initialValue: task?.clientNotes || '',
      },
    ],
    false,
  )

  const {
    forClient,
    name,
    typeId,
    assigneeId,
    notes,
    instructions,
    clientNotes,
  } = fields

  useEffect(() => {
    setSlotInfo({ ...slotInfo, clientId })
  }, [clientId])

  useEffect(() => {
    setSlotInfo({ ...slotInfo, patientId })
  }, [patientId])

  const createTask = (): Task => ({
    id: task?.id as string,
    forClient: forClient.value,
    name: name.value,
    typeId: typeId.value,
    assigneeId: forClient.value ? selectedClientId : assigneeId.value,
    appointmentId: selectedAppointmentId,
    clientId: selectedClientId,
    patientId: selectedPatientId,
    contactId: selectedContactId,
    notes: notes.value,
    instructions: instructions.value,
    soapId: selectedAppointmentId ? soapId || task?.soapId : null,

    ...(isCreate
      ? {}
      : {
          // for existing task if appointmentId was removed we need to explicitly
          // nullify order related fields
          orderId: selectedAppointmentId ? task?.orderId : null,

          orderType: selectedAppointmentId ? task?.orderType : null,

          // preserve existing state
          stateId: task.stateId,
        }),

    ...(taskRecurrenceSettingsRef?.current?.get() || {}),
  })

  useEffect(() => {
    reset()
    setSelectedAppointmentId(getAppointmentId())
    setSlotInfo(getSlotInfo())
    notesRef.current?.setInitialValue(task?.notes)
    instructionsRef.current?.setInitialValue(task?.instructions)
  }, [task])

  useEffect(() => {
    if (!selectedClientId && !selectedPatientId && !shortTaskFlow) {
      setSelectedAppointmentId(null)
    }
  }, [selectedClientId, selectedPatientId, shortTaskFlow])

  const validateTaskRecurrenceSettings = () =>
    taskRecurrenceSettingsRef.current
      ? taskRecurrenceSettingsRef.current.validate()
      : true

  const onAppointmentSelected = (newAppointmentId: string) => {
    closeAddToAppointmentDialog()
    setSelectedAppointmentId(newAppointmentId)
  }

  const onAddToAppointmentRequested = () => {
    openAddToAppointmentDialog({
      clientId: selectedClientId,
      patientId: selectedPatientId,
      title: t('Dialogs:ADD_TASK_TO_APPOINTMENT.TITLE'),
      onAdd: onAppointmentSelected,
      filterByClient: true,
    })
  }

  const selectedClient = useSelector(getUser(selectedClientId))
  const selectedPatient = useSelector(getPatient(selectedPatientId))
  const patientHasMembership = getPatientHasMembership(selectedPatient)

  const assigneeClientAndPatientIsValid =
    !forClient.value ||
    Boolean(
      selectedClientId &&
        selectedPatientId &&
        (selectedClient?.isBoopUser || isBoopDisablementEnabled),
    ) ||
    patientHasMembership

  const showAssignClientAndPatientErrorMessage =
    touched && !assigneeClientAndPatientIsValid

  useImperativeHandle(ref, () => ({
    validate: () =>
      validate() &&
      validateTaskRecurrenceSettings() &&
      assigneeClientAndPatientIsValid,
    get: createTask,
    hasUnsavedChanges: () => {
      const fieldsChanged = isFieldValuesChanged(fields)
      const recurrenceSettingsChanged =
        taskRecurrenceSettingsRef.current?.hasUnsavedChanges() ?? false
      const slotInfoChanged = !R.whereEq(slotInfo, getSlotInfo())
      const appointmentChanged = selectedAppointmentId !== getAppointmentId()

      return (
        fieldsChanged ||
        recurrenceSettingsChanged ||
        slotInfoChanged ||
        appointmentChanged
      )
    },
  }))

  return (
    <Grid
      container
      item
      alignItems="flex-end"
      className={classNames(className, classes.root)}
      px={3}
      ref={rootRef}
    >
      {!isCreate && (
        <Grid container item alignItems="center">
          {task.forClient && (
            <>
              {!isBoopDisablementEnabled && <Boop />}
              <Text
                strong
                ml={isBoopDisablementEnabled ? 0 : 1}
                mr={1}
                variant="body2"
              >
                {forClient.label}
              </Text>
            </>
          )}
          <TaskStateLabel stateId={task.stateId} />
        </Grid>
      )}
      {isCreate && wellnessPlansEnabled && (
        <Grid container item direction="column">
          <Text strong mt={1} variant="body2">
            {t('Common:WORKFLOW_ONE')}
          </Text>
          <RadioGroup
            row
            aria-label="workflow-radio-group"
            name="workflow-radio-group"
            value={Boolean(forClient.value)}
            onChange={() => forClient.setValue(!forClient.value)}
          >
            <FormControlLabel
              disableTypography
              control={<Radio />}
              disabled={!isContextItem}
              label={t('Common:INTERNAL')}
              value={false}
            />
            <FormControlLabel
              disableTypography
              value
              control={<Radio />}
              disabled={!isContextItem}
              label={
                isBoopDisablementEnabled
                  ? t('Common:CLIENT')
                  : t('Common:BOOP_SYSTEM_NAME_PET_PARENT')
              }
            />
          </RadioGroup>
        </Grid>
      )}
      <Grid container item columnSpacing={3}>
        <Grid item xs={6}>
          <PuiTextField
            disabled={
              !isContextItem || (!taskPermissions.update && isCvcRolesEnabled)
            }
            field={name}
            inputProps={{ maxLength: 100 }}
            label={`${name.label}*`}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="task-type-select">{typeId.label}</InputLabel>
            <PuiSelect
              disabled={
                !isContextItem || (!taskPermissions.update && isCvcRolesEnabled)
              }
              field={typeId}
              input={<Input id="task-type-select" />}
              items={CreatableTaskTypes}
              renderEmpty={false}
            />
          </FormControl>
        </Grid>
      </Grid>
      <TaskRecurrenceSettings
        appointmentId={selectedAppointmentId}
        disabled={!taskPermissions.update && isCvcRolesEnabled}
        dueDate={dueDateProp}
        ref={taskRecurrenceSettingsRef}
        task={task}
      />
      <ErrorTooltip
        message={
          !selectedClientId || !selectedPatientId
            ? t('Tooltips:CLIENT_AND_PATIENT_IS_REQUIRED')
            : t('Tooltips:CLIENT_NEEDS_TO_BE_A_BOOP_SYSTEM_USER')
        }
        open={showAssignClientAndPatientErrorMessage}
      >
        <Grid container mt={2}>
          <ContactTargetSlot
            anchorRef={rootRef}
            disabled={
              (isFetching && isTaskOptimizationEnabled) ||
              !isContextItem ||
              (!taskPermissions.update && isCvcRolesEnabled)
            }
            showAddContact={!forClient.value}
            onSlotChange={setSlotInfo}
            {...slotInfo}
          />
        </Grid>
      </ErrorTooltip>
      {selectedClientId && selectedPatientId && isContextItem && (
        <Grid container item mt={2}>
          <AddToAppointmentCard
            appointmentId={selectedAppointmentId}
            readOnly={!taskPermissions.update && isCvcRolesEnabled}
            onAddToAppointmentRequested={onAddToAppointmentRequested}
            onEdit={onAddToAppointmentRequested}
          />
        </Grid>
      )}
      {!forClient.value && (
        <Grid container item mt={2}>
          <Grid item lg={5} md={8} sm={12}>
            <UserSelect
              classes={{
                listbox: classes.listbox,
              }}
              disabled={
                shouldUseVCRBehavior ||
                (isFetching && isTaskOptimizationEnabled)
              }
              field={assigneeId}
              filterScope={UserSelectFilterScope.Staff}
              label={assigneeId.label}
              readOnly={
                !isContextItem || (!taskPermissions.update && isCvcRolesEnabled)
              }
              teamFilter={TeamFilter.ALL}
            />
          </Grid>
        </Grid>
      )}
      <Grid container item mt={3}>
        <Text strong variant="body2">
          {forClient.value ? t('Common:INTERNAL_NOTES') : t('Common:NOTES')}
        </Text>
        <NotesTemplateInput
          hidePanel
          disabled={!taskPermissions.update && isCvcRolesEnabled}
          field={notes}
          isSoap={Boolean(soapId)}
          minHeight={2}
          ref={notesRef}
          soapId={soapId}
        />
      </Grid>
      {wellnessPlansEnabled && forClient.value && (
        <Grid container item mt={3}>
          <Text strong variant="body2">
            {instructions.label}
          </Text>
          <NotesTemplateInput
            hidePanel
            disabled={!taskPermissions.update && isCvcRolesEnabled}
            field={instructions}
            isSoap={Boolean(soapId)}
            minHeight={2}
            ref={instructionsRef}
            soapId={soapId}
          />
        </Grid>
      )}
      {!isCreate && (
        <>
          {wellnessPlansEnabled && task?.completionDate && (
            <Grid container item className={classes.detailsRow} mt={2} py={1}>
              <Grid item xs={3}>
                <Text strong variant="body2">
                  {t('Tasks:LABEL.TASK_COMPLETED')}
                </Text>
              </Grid>
              <Grid item xs>
                <Text variant="body2">
                  {moment(task?.completionDate).format(
                    DateFormat.FULL_DATE_TIME_AT,
                  )}
                </Text>
              </Grid>
            </Grid>
          )}
          {wellnessPlansEnabled && clientNotes.value && (
            <Grid container item className={classes.detailsRow} py={1}>
              <Grid item xs={3}>
                <Text strong variant="body2">
                  {clientNotes.label}
                </Text>
              </Grid>
              <Grid item xs>
                <Text variant="body2">{clientNotes.value}</Text>
              </Grid>
            </Grid>
          )}
        </>
      )}
      {isCreate && (
        <Grid item mt={1}>
          <RequiredFieldsNotice />
        </Grid>
      )}
    </Grid>
  )
})

export default TaskComponent
