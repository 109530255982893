import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Calendar,
  moment,
  Nil,
  PuiDialog,
  PuiTheme,
  useFields,
} from '@pbt/pbt-ui-components'

import TimeSelector from '~/components/common/inputs/time-selector/TimeSelector'
import NotesTemplateInput from '~/components/dashboard/template-inputs/NotesTemplateInput'
import {
  createAddendum,
  editAddendum,
  getAddendum,
  getAddendumsIsLoading,
} from '~/store/duck/addendums'
import { Addendum } from '~/types'
import { aggregateDateToUtc, roundTime } from '~/utils/time'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: '100%',
      maxWidth: 936,
    },
    dialogContentRoot: {
      padding: theme.spacing(1.5, 2, 2),
    },
    button: {
      minWidth: 150,
    },
  }),
  { name: 'AddendumDialog' },
)

export interface AddendumDialogProps extends BasePuiDialogProps {
  addendumId?: string
  patientId: string | Nil
  soapId: string | Nil
}

const AddendumDialog = ({
  addendumId,
  soapId,
  patientId,
  open,
  onClose,
}: AddendumDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const addendum = useSelector(getAddendum(addendumId))
  const isLoading = useSelector(getAddendumsIsLoading)

  const setCloseAfterCreationOn = useCloseAfterCreation(
    onClose,
    getAddendumsIsLoading,
  )

  // this is needed to capture defaults on dialog open and do not override them on later re-renders
  const defaultStartDate = useMemo(() => new Date().toISOString(), [])
  const defaultStartTime = useMemo(() => roundTime(defaultStartDate), [])

  const {
    fields: { date, time, notes },
    validate,
    reset,
  } = useFields(
    [
      {
        name: 'date',
        label: t('Common:DATE_TIME'),
        validators: ['required', 'timestamp'],
        initialValue: addendum?.date || defaultStartDate,
      },
      {
        name: 'time',
        type: 'none',
        validators: ['required', 'timestamp'],
        initialValue: addendum?.date || defaultStartTime,
      },
      {
        name: 'notes',
        label: t('Common:NOTE'),
        initialValue: addendum?.notes || '',
      },
    ],
    false,
  )

  useEffect(() => {
    reset()
  }, [addendum])

  const handleOnSave = () => {
    if (validate()) {
      const newAddendum = {
        ...(addendum || {}),
        notes: notes.value,
        date: aggregateDateToUtc({
          date: moment(date.value),
          time: moment(time.value),
          ignoreSeconds: true,
        }),
      }

      setCloseAfterCreationOn()

      if (soapId && patientId) {
        if (newAddendum?.id) {
          dispatch(editAddendum(soapId, patientId, newAddendum as Addendum))
        } else {
          dispatch(createAddendum(soapId, patientId, newAddendum))
        }
      }
    }
  }

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader
          className={classes.button}
          loading={isLoading}
          onClick={handleOnSave}
        >
          {addendum?.id ? t('Common:EDIT_ADDENDUM') : t('Common:ADD_ADDENDUM')}
        </ButtonWithLoader>
      }
      aria-labelledby="addendum-dialog"
      classes={{
        dialogContentRoot: classes.dialogContentRoot,
        paper: classes.paper,
      }}
      open={open}
      title={t('Common:ADD_ADDENDUM')}
      onClose={onClose}
    >
      <Grid container item alignItems="flex-end" mb={6} wrap="nowrap">
        <Grid item width={110}>
          <Calendar
            field={date}
            label={`${date.label}*`}
            margin="none"
            onChange={date.setValue}
          />
        </Grid>
        <Grid item width={210}>
          <TimeSelector
            offsetArrows
            fromLabel={null}
            startValue={time.value}
            withPopper={false}
            onStartChange={time.set}
          />
        </Grid>
      </Grid>
      <Grid container item>
        <NotesTemplateInput
          isSoap
          field={notes}
          maxEditorHeight={400}
          maxHeight={400}
          minEditorHeight={162}
          minHeight={162}
          placeholder={notes.label}
        />
      </Grid>
    </PuiDialog>
  )
}

export default AddendumDialog
