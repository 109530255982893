import React, { useRef } from 'react'
import { Step as MuiStep, Tooltip, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme, Utils } from '@pbt/pbt-ui-components'

const ARROW_HEIGHT = 32
const CONTAINER_WIDTH = 975
const NAVIGATION_BUTTON_WIDTH = 26
const STEP_ARROW_WIDTH = 12

const getFirstArrowPath = (
  width: number,
  height: number,
  arrowWidth: number,
) => `
  M 0.5 3.5
  Q 0.5 0.5 3.5 0.5
  L ${width - (arrowWidth - 1)} 0.5
  L ${width - 1} ${height / 2 - 2}
  Q ${width} ${height / 2} ${width - 1} ${height / 2 + 2}
  L ${width - (arrowWidth - 1)} ${height - 0.5}
  L 3.5 ${height - 0.5}
  Q 0.5 ${height - 0.5} 0.5 ${height - 3.5}
  Z
`

const getMiddleArrowPath = (
  width: number,
  height: number,
  arrowWidth: number,
) => `
  M 0.5 0.5
  L ${width - (arrowWidth - 1)} 0.5
  L ${width - 1} ${height / 2 - 2}
  Q ${width} ${height / 2} ${width - 1} ${height / 2 + 2}
  L ${width - (arrowWidth - 1)} ${height - 0.5}
  L 0.5 ${height - 0.5}
  L ${arrowWidth - 1} ${height / 2 + 2}
  Q ${arrowWidth} ${height / 2} ${arrowWidth - 1} ${height / 2 - 2}
  Z
`

const getLastArrowPath = (
  width: number,
  height: number,
  arrowWidth: number,
) => `
  M 0.5 0.5
  L ${width - 3.5} 0.5
  Q ${width - 0.5} 0.5 ${width - 0.5} 3.5
  L ${width - 0.5} ${height - 3.5}
  Q ${width - 0.5} ${height - 0.5} ${width - 3.5} ${height - 0.5}
  L 0.5 ${height - 0.5}
  L ${arrowWidth - 1} ${height / 2 + 2}
  Q ${arrowWidth} ${height / 2} ${arrowWidth - 1} ${height / 2 - 2}
  Z
`

const getDimensions = (count: number, width = CONTAINER_WIDTH) => {
  const stepperWidth = width - 2 * NAVIGATION_BUTTON_WIDTH

  const BASE_ARROW_WIDTH = Math.floor(stepperWidth / count)
  const ARROW_WIDTH = BASE_ARROW_WIDTH + STEP_ARROW_WIDTH

  return {
    height: ARROW_HEIGHT,
    width: ARROW_WIDTH,
    widthSmall: BASE_ARROW_WIDTH,
    firstArrowPath: getFirstArrowPath(
      ARROW_WIDTH,
      ARROW_HEIGHT,
      STEP_ARROW_WIDTH,
    ),
    middleArrowPath: getMiddleArrowPath(
      ARROW_WIDTH,
      ARROW_HEIGHT,
      STEP_ARROW_WIDTH,
    ),
    lastArrowPath: getLastArrowPath(
      BASE_ARROW_WIDTH,
      ARROW_HEIGHT,
      STEP_ARROW_WIDTH,
    ),
  }
}

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    arrow: {
      pointerEvents: 'none',
      marginRight: -STEP_ARROW_WIDTH,
    },
    lastArrow: {
      marginRight: 0,
    },
    label: {
      fontWeight: 'normal',
      color: theme.colors.sideText,
      fontSize: '1.4rem',
      width: '100%',
      position: 'absolute',
      textAlign: 'center',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
      whiteSpace: 'nowrap',
    },
    labelActive: {
      fontWeight: 500,
      color: `${theme.colors.tableBackground} !important`,
    },
    middleLabel: {
      left: 10,
    },
    step: {
      padding: 0,
      margin: 0,
      height: ARROW_HEIGHT,
      position: 'relative',
      pointerEvents: 'none',
    },
    activeStep: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
    },
    disabledStep: {
      opacity: 0.5,
    },
    path: {
      pointerEvents: 'visible',
      cursor: 'pointer',
    },
    hideText: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      left: 15,
      maxWidth: 'calc(100% - 15px)',
    },
  }),
  { name: 'Step' },
)

export interface StepProps {
  active: boolean
  containerWidth?: number
  count: number
  disabled?: boolean
  first?: boolean
  label: React.ReactNode
  last?: boolean
  onClick: () => void
  tooltip?: React.ReactNode
}

const Step = ({
  disabled,
  label,
  count,
  tooltip,
  active,
  first,
  last,
  containerWidth,
  onClick,
}: StepProps) => {
  const classes = useStyles()
  const theme = useTheme<PuiTheme>()
  const spanRef = useRef<HTMLSpanElement>(null!)

  const willOverflow = Utils.textWillOverflow(spanRef.current)
  const tooltipTitle =
    willOverflow && !tooltip ? label : tooltip || ('' as React.ReactNode)

  const {
    width,
    widthSmall,
    height,
    firstArrowPath,
    middleArrowPath,
    lastArrowPath,
  } = getDimensions(count, containerWidth)

  return (
    <MuiStep
      className={classNames(classes.step, {
        [classes.activeStep]: active,
        [classes.disabledStep]: disabled,
      })}
      disabled={disabled}
    >
      <Tooltip placement="top" title={tooltipTitle!}>
        <svg
          className={classNames(classes.arrow, {
            [classes.lastArrow]: last,
          })}
          height={height}
          width={last ? widthSmall : width}
        >
          <path
            className={classes.path}
            d={first ? firstArrowPath : last ? lastArrowPath : middleArrowPath}
            fill={
              active ? theme.colors.tabSelected : theme.colors.contentBackground
            }
            stroke={
              active ? theme.colors.tabSelected : theme.colors.selectedOption
            }
            strokeWidth="1"
            onClick={disabled ? undefined : onClick}
          />
        </svg>
      </Tooltip>
      <span
        className={classNames(classes.label, {
          [classes.labelActive]: active,
          [classes.middleLabel]: !first && !last,
          [classes.hideText]: willOverflow,
        })}
        ref={spanRef}
      >
        {label}
      </span>
    </MuiStep>
  )
}

export default Step
