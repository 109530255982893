import React from 'react'
import { useDispatch } from 'react-redux'
import { IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme, Utils } from '@pbt/pbt-ui-components'
import { Circle, RoundedCheck } from '@pbt/pbt-ui-components/src/icons'

import { ColorVariant } from '~/constants/colors'
import { editTaskState } from '~/store/actions/tasks'
import { useTaskEvent } from '~/store/hooks/tasks'
import { Task } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    iconButton: {
      padding: theme.spacing(0.5),
    },
    icon: {},
    normal: {
      stroke: theme.colors.sideText,
      color: 'transparent',
    },
    important: {
      color: theme.colors.important,
      stroke: theme.colors.important,
      fillOpacity: '0.1',
    },
    success: {
      color: theme.colors.success,
    },
  }),
  { name: 'TaskStateButton' },
)

export interface TaskStateButtonProps {
  className?: string
  onClick: () => void
  task: Task
}

const TaskStateButton = ({
  className,
  task,
  onClick,
}: TaskStateButtonProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const TaskType = useTaskEvent()
  const TaskStates = TaskType.states || []

  const DoneState = Utils.findConstantIdByName('Done', TaskStates)
  const currentState = Utils.findById(task.stateId, TaskStates) || {}
  const isDone = task.stateId === DoneState

  const isNormal = currentState.colorVariant === ColorVariant.NORMAL
  const isImportant = currentState.colorVariant === ColorVariant.IMPORTANT

  const defaultOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (!isDone) {
      dispatch(editTaskState(task.id, DoneState))
    }
  }

  return (
    <IconButton
      className={classNames(className, classes.iconButton)}
      size="large"
      onClick={onClick || defaultOnClick}
    >
      {isDone ? (
        <RoundedCheck className={classes.success} />
      ) : (
        <Circle
          className={classNames(classes.icon, {
            [classes.normal]: isNormal,
            [classes.important]: isImportant,
          })}
        />
      )}
    </IconButton>
  )
}

export default TaskStateButton
