import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme } from '@pbt/pbt-ui-components'

import { getUser } from '~/store/reducers/users'

import NewClientDetailsEdit from './NewClientDetailsEdit'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    clientsDetailsContainer: {
      [theme.breakpoints.up('md')]: {
        boxShadow: '0 0 0 1px #E5E5E5',
        width: 650,
      },
    },
  }),
  { name: 'NewClientDetailsEditPage' },
)

const NewClientDetailsEditPage = () => {
  const navigate = useNavigate()
  const { clientId = '' } = useParams()
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const client = useSelector(getUser(clientId))

  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )

  const onOk = () => {
    if (client?.patients) {
      navigate(`/client/${clientId}/patient/${client.patients[0]}/new`)
    }
  }

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      mt={2}
      px={isMobile ? 0 : 3}
      wrap="nowrap"
    >
      <Grid item className={classes.clientsDetailsContainer} py={2} xs={12}>
        <NewClientDetailsEdit
          renderCoparents
          clientId={clientId}
          title={t('Common:NEW_CLIENT')}
          onOk={onOk}
        />
      </Grid>
    </Grid>
  )
}

export default NewClientDetailsEditPage
