import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  DateUtils,
  LinkButton,
  PermissionArea,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { DiagnosisSnapshotItem } from '~/types'
import { addOriginalBusinessId } from '~/utils'

import DiagnosisStatusCell from './DiagnosisStatusCell'

interface ProblemHistoryPanelProps {
  problemLogs: DiagnosisSnapshotItem[]
}

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    linkButton: {
      color: theme.colors.link,
      fontSize: theme.typography.pxToRem(14),
      height: 21,
      padding: '0 8px 2px',
    },
    dateText: {
      color: theme.colors.inactiveBackground,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.5,
      marginLeft: 8,
    },
    descriptionText: {
      fontSize: theme.typography.pxToRem(14),
    },
    dividerBorder: {
      borderBottom: `1px solid ${theme.colors.signIconBackground}`,
    },
    statusUpdate: {
      '&:not(:last-child)': {
        marginBottom: 24,
      },
      width: 300,
    },
    logEntry: {
      marginLeft: 24,
      marginRight: 16,
      marginTop: 8,
    },
    entrySpacer: {
      paddingBottom: 8,
    },
  }),
  { name: 'ProblemHistoryEntry' },
)

const ProblemHistoryEntry = ({ logs }: { logs: DiagnosisSnapshotItem[] }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const classes = useStyles()

  const isSingleEntry = logs.length === 1

  const { read: soapReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.SOAP),
  )
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const goToSoap = (item: DiagnosisSnapshotItem) => {
    navigate(
      addOriginalBusinessId(
        `/soap/${item.soapId}`,
        isPatientSharingEnabled ? item.businessId : null,
      ),
    )
  }

  return (
    <Grid container item className={classes.statusUpdate}>
      <Grid
        container
        alignItems="baseline"
        flexDirection="row"
        flexWrap="nowrap"
        width="100%"
      >
        <Grid item>
          <DiagnosisStatusCell shorten item={logs[0]} />
        </Grid>
        <Grid item>
          <Text className={classes.dateText}>
            {isSingleEntry
              ? DateUtils.formatDate(logs[0].date)
              : `${DateUtils.formatDate(
                  R.last(logs)?.date,
                )}-${DateUtils.formatDate(logs[0].date)}`}
          </Text>
        </Grid>
        {isSingleEntry && logs[0].soapId && (
          <Grid item>
            <LinkButton
              classes={{ button: classes.linkButton }}
              onClick={
                soapReadPermissions ? () => goToSoap(logs[0]) : undefined
              }
            >
              {t('Common:VIEW_SOAP')}
            </LinkButton>
          </Grid>
        )}
      </Grid>
      <Grid container>
        {logs.map((log) => (
          <Grid
            container
            className={classNames(classes.logEntry, {
              [classes.dividerBorder]: !isSingleEntry && log.notes, // TODO CVC-6748: Add border if enumLogs exist
              [classes.entrySpacer]: !isSingleEntry,
            })}
            key={log.logId}
          >
            {!isSingleEntry && (
              <Grid
                container
                alignItems="baseline"
                flexDirection="row"
                marginLeft="-8px"
              >
                <Text className={classes.dateText}>
                  {DateUtils.formatDate(log.date)}
                </Text>
                {log.soapId && (
                  <LinkButton
                    classes={{ button: classes.linkButton }}
                    onClick={
                      soapReadPermissions ? () => goToSoap(log) : undefined
                    }
                  >
                    {t('Common:VIEW_SOAP')}
                  </LinkButton>
                )}
              </Grid>
            )}
            {log.notes && (
              <Text className={classes.descriptionText}>
                <Box component="span" fontWeight="fontWeightMedium">
                  {t('Common:NOTES')}:{' '}
                </Box>
                {log.notes}
              </Text>
            )}
            {/* TODO CVC-6748: Add enumLogs entries */}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

// eslint-disable-next-line react/no-multi-comp
const ProblemHistoryPanel = ({ problemLogs }: ProblemHistoryPanelProps) => {
  const statusEntries = R.pipe(
    R.groupBy((problem: DiagnosisSnapshotItem) => problem.statusId || ''),
    R.values,
  )(problemLogs)

  return (
    <Grid container flexDirection="column">
      {statusEntries.map((entry) => (
        <ProblemHistoryEntry key={entry?.[0].logId} logs={entry} />
      ))}
    </Grid>
  )
}

export default ProblemHistoryPanel
