import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import { Conversation } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    warningIcon: {
      width: '0.75em',
      height: '0.75em',
      fill: theme.colors.important,
      marginRight: theme.spacing(0.5),
    },
    iconPlaceholder: {
      marginRight: 22,
    },
  }),
  { name: 'AssigneeCell' },
)

interface AssigneeCellProps {
  item: Conversation
}

const AssigneeCell = ({ item }: AssigneeCellProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  return item.assigneeId ? (
    <Grid container wrap="nowrap">
      <Grid className={classes.iconPlaceholder} />
      <Dotdotdot clamp={1}>
        <Text variant="body2">{item.assigneeName}</Text>
      </Dotdotdot>
    </Grid>
  ) : (
    <Grid container alignItems="center" wrap="nowrap">
      <WarningIcon className={classes.warningIcon} />
      <Text variant="body2">{t('Common:UNASSIGNED')}</Text>
    </Grid>
  )
}

export default AssigneeCell
