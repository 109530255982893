import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  CurrencyTextField,
  Nil,
  PermissionArea,
  PuiTheme,
  PuiTooltip,
  useFields,
} from '@pbt/pbt-ui-components'
import { Info as InfoIcon } from '@pbt/pbt-ui-components/src/icons'

import { getCRUDByArea } from '~/store/reducers/auth'
import {
  DataHandle,
  Shipment as ShipmentType,
  ShipmentTaxes as ShipmentTaxesType,
} from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    icon: {
      color: theme.colors.link,
      cursor: 'pointer',
      marginTop: theme.spacing(3.5),
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'ShipmentTaxes' },
)

export interface ShipmentTaxesHandle extends DataHandle<ShipmentTaxesType> {}

interface ShipmentTaxesProps {
  className?: string
  onChange?: (taxes: ShipmentTaxesType) => void
  shipment: ShipmentType | Nil
  taxDisabled?: boolean
}

const ShipmentTaxes = forwardRef<ShipmentTaxesHandle, ShipmentTaxesProps>(
  function ShipmentTaxes({ shipment, className, taxDisabled, onChange }, ref) {
    const classes = useStyles()
    const { t } = useTranslation(['Common', 'Abbreviations'])

    const permissions = useSelector(getCRUDByArea(PermissionArea.SHIPMENTS))

    const {
      fields: { shippingAndHandling, customTotalTax },
      validate,
      reset,
    } = useFields(
      [
        {
          name: 'shippingAndHandling',
          label: t('Abbreviations:COMMON.SHIPPING_AND_HANDLING'),
          initialValue: shipment?.shippingAndHandling || 0,
          type: 'number',
        },
        {
          name: 'customTotalTax',
          label: t('Common:TAX'),
          initialValue: shipment?.customTotalTax || 0,
          type: 'number',
        },
      ],
      false,
    )

    useEffect(() => {
      if (!shipment || !R.isEmpty(shipment)) {
        reset()
      }
    }, [shipment])

    const getShipmentTaxes = () => ({
      shippingAndHandling: shippingAndHandling.value,
      customTotalTax: customTotalTax.value,
    })

    useImperativeHandle(ref, () => ({
      validate,
      get: getShipmentTaxes,
    }))

    useEffect(() => {
      if (onChange) {
        onChange({
          shippingAndHandling: shippingAndHandling.value,
          customTotalTax: customTotalTax.value,
        })
      }
    }, [shippingAndHandling.value, customTotalTax.value])

    return (
      <Grid className={className}>
        <CurrencyTextField
          disabled={!permissions.update}
          field={shippingAndHandling}
          label={shippingAndHandling.label}
        />
        <Grid container item wrap="nowrap">
          <CurrencyTextField
            disabled={!permissions.update || taxDisabled}
            field={customTotalTax}
            label={customTotalTax.label}
          />
          <PuiTooltip
            tooltipText={t(
              'Tooltips:ONLY_TOTAL_TAX_OR_INDIVIDUAL_TAX_CAN_BE_ENTERED',
            )}
          >
            <InfoIcon className={classes.icon} />
          </PuiTooltip>
        </Grid>
      </Grid>
    )
  },
)

export default ShipmentTaxes
