import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  PhoneUtils,
  PuiTextField,
  PuiTheme,
  useFields,
} from '@pbt/pbt-ui-components'

import PhoneInput from '~/components/common/form-inputs/PhoneInput'
import { ClientData } from '~/types/entities/clients'

import DetailsBackButton from '../DetailsBackButton'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    backButtonText: {
      color: theme.colors.primaryText,
      paddingLeft: theme.spacing(1),
      fontWeight: 500,
    },
    backButtonContainer: {
      marginLeft: theme.spacing(-1.5),
    },
  }),
  { name: 'CreateNewPetParent' },
)

export interface CreateNewPetParentHandle {
  onChangeRequested: () => void
}

interface CreateNewPetParentProps {
  onBack: () => void
  onChangeWithNewClient: (client: Partial<ClientData>) => void
}

const CreateNewPetParent = forwardRef<
  CreateNewPetParentHandle,
  CreateNewPetParentProps
>(function CreateNewPetParent({ onBack, onChangeWithNewClient }, ref) {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const {
    fields: { clientFirstName, clientLastName, email, mobilePhone },
    validate,
  } = useFields(
    [
      {
        name: 'clientFirstName',
        label: t('Common:CLIENT_FIRST_NAME'),
        initialValue: '',
        validators: ['required'],
      },
      {
        name: 'clientLastName',
        label: t('Common:CLIENT_LAST_NAME'),
        initialValue: '',
        validators: ['required'],
      },
      {
        name: 'email',
        label: t('Common:EMAIL'),
        initialValue: '',
        validators: ['email'],
      },
      {
        name: 'mobilePhone',
        label: t('Common:MOBILE_NUMBER'),
        initialValue: '',
        validators: ['phone'],
      },
    ],
    false,
  )

  const onChangeRequested = () => {
    if (validate()) {
      const newClient = {
        firstName: clientFirstName.value,
        lastName: clientLastName.value,
        mobilePhone: PhoneUtils.parsePhoneNumber(mobilePhone.value) || null,
        email: email.value || null,
        patients: [],
      }
      onChangeWithNewClient(newClient)
    }
  }

  useImperativeHandle(ref, () => ({
    onChangeRequested,
  }))

  return (
    <Grid container columnSpacing={4}>
      <Grid item xs={12}>
        <DetailsBackButton
          classes={{
            text: classes.backButtonText,
            container: classes.backButtonContainer,
          }}
          lg={12}
          md={12}
          onClick={onBack}
        >
          {t('Common:BACK_TO_SEARCH')}
        </DetailsBackButton>
      </Grid>
      <Grid item md={6} xs={12}>
        <PuiTextField
          field={clientFirstName}
          label={`${clientFirstName.label}*`}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <PuiTextField
          field={clientLastName}
          label={`${clientLastName.label}*`}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <PhoneInput field={mobilePhone} />
      </Grid>
      <Grid item md={6} xs={12}>
        <PuiTextField field={email} label={email.label} />
      </Grid>
    </Grid>
  )
})

export default CreateNewPetParent
