import React from 'react'
import { Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  HtmlNotesPreview,
  LanguageUtils,
  Nil,
  PuiTheme,
  Text,
  TextWithTooltip,
} from '@pbt/pbt-ui-components'

import Link from '../link/Link'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    catalogInfoRow: {
      borderBottom: theme.constants.tabBorder,
    },
    link: {
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'DetailsList' },
)

export type DetailsListItem = {
  isHtml?: boolean
  label: string
  linkUrl?: string
  tooltipText?: string
  value: string | Nil
}

export interface DetailsListProps {
  className?: string
  data: DetailsListItem[]
  headerText: string
  headerTooltipText?: React.ReactNode
  labelColumnWidth?: number
}

const DetailsList = ({
  className,
  data,
  headerText,
  headerTooltipText,
  labelColumnWidth = 3,
}: DetailsListProps) => {
  const classes = useStyles()

  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )

  return (
    <Grid
      container
      item
      className={classNames(className, classes.root)}
      direction="column"
    >
      {headerText && (
        <Grid item mb={isMobile ? 1 : 0}>
          <TextWithTooltip
            strong
            tooltipText={headerTooltipText}
            variant={isMobile ? 'subheading2' : 'subheading3'}
          >
            {headerText}
          </TextWithTooltip>
        </Grid>
      )}
      {data.map(({ label, value, tooltipText, linkUrl, isHtml }) => (
        <Grid
          container
          alignItems="center"
          className={classes.catalogInfoRow}
          key={label}
          py={1.5}
        >
          <Grid item md={labelColumnWidth} xs={12}>
            <TextWithTooltip
              allowWrap
              strong
              tooltipText={tooltipText}
              variant={isMobile ? 'body' : 'body2'}
            >
              {label}
            </TextWithTooltip>
          </Grid>
          <Grid item md={12 - labelColumnWidth} xs={12}>
            {Array.isArray(value) ? (
              value.length > 0 ? (
                value.map((item) => (
                  <React.Fragment key={item.id}>
                    {linkUrl ? (
                      <Link
                        className={classes.link}
                        to={`${linkUrl}/${item.id}`}
                      >
                        <Text variant={isMobile ? 'body' : 'body2'}>
                          {LanguageUtils.getTranslatedFieldName(item)}
                        </Text>
                      </Link>
                    ) : (
                      <Text variant={isMobile ? 'body' : 'body2'}>
                        {LanguageUtils.getTranslatedFieldName(item)}
                      </Text>
                    )}
                  </React.Fragment>
                ))
              ) : (
                '-'
              )
            ) : R.isNil(value) || R.isEmpty(value) ? (
              <Text variant={isMobile ? 'body' : 'body2'}>-</Text>
            ) : isHtml ? (
              <HtmlNotesPreview
                fontSize={isMobile ? '1.6rem' : '1.4rem'}
                notes={value}
              />
            ) : (
              <Text variant={isMobile ? 'body' : 'body2'}>{value}</Text>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default DetailsList
