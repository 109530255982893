import React, { useState } from 'react'
import { ClickAwayListener, Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme } from '@pbt/pbt-ui-components'
import { LoveList as LoveListIcon } from '@pbt/pbt-ui-components/src/icons'

import { AlertColorMap } from '~/constants/alertColors'
import { PatientPreferencesDisplayData } from '~/types'
import { ClientPreferencesDisplayData } from '~/types/entities/clientPreferences'

import { PreferencesDisplayWidget } from '../dashboard/preferences/PreferencesDisplayWidget'
import TooltipChip from './TooltipChip'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    icon: {
      width: '100%',
      height: '100%',
      fill: theme.colors.alertErrorText,
    },
  }),
  { name: 'PreferencesChip' },
)

export interface PreferencesChipProps {
  className?: string
  headerText: string
  onEditPreferences?: () => void
  preferences?: ClientPreferencesDisplayData | PatientPreferencesDisplayData
}

function PreferencesChip({
  className,
  headerText,
  preferences,
  onEditPreferences = () => {},
}: PreferencesChipProps) {
  const classes = useStyles()
  const isTouch = useMediaQuery('(pointer:coarse)')

  const [open, setOpen] = useState(false)

  const onEditPreferencesClick = () => {
    setOpen(false)
    onEditPreferences()
  }

  const hasPreferences = Object.values(preferences || {}).some(Boolean)

  const Component = (
    <TooltipChip
      disableTooltipBranding
      className={className}
      color={hasPreferences ? AlertColorMap.BLUE : AlertColorMap.GREY}
      icon={<LoveListIcon className={classes.icon} />}
      open={open}
      tooltipPlacement="bottom-start"
      tooltipText={
        <PreferencesDisplayWidget
          headerText={headerText}
          preferences={preferences}
          onEditPreferences={onEditPreferencesClick}
        />
      }
      onClose={() => {
        if (!isTouch) setOpen(false)
      }}
      onOpen={() => setOpen(true)}
    />
  )

  return isTouch ? (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Grid>{Component}</Grid>
    </ClickAwayListener>
  ) : (
    Component
  )
}

export { PreferencesChip }
