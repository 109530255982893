import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, PuiTheme } from '@pbt/pbt-ui-components'

import SnackNotification, {
  SnackNotificationProps,
  useSnackNotificationStyles,
} from './SnackNotification'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    snackMessage: {
      backgroundColor: theme.colors.title,
    },
    content: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
    title: {
      color: 'inherit',
      marginRight: theme.spacing(2),
    },
    message: {
      color: 'inherit',
      marginRight: theme.spacing(2),
    },
    linkButton: {
      color: 'inherit',
    },
  }),
  { name: 'InfoSnackNotification' },
)

export interface InfoSnackNotificationProps extends SnackNotificationProps {
  classes?: ClassesType<typeof useSnackNotificationStyles>
  infoSnackClasses?: ClassesType<typeof useStyles>
}

const InfoSnackNotification = ({
  infoSnackClasses,
  classes: classesProp,
  ...rest
}: InfoSnackNotificationProps) => {
  const classes = useStyles({ classes: infoSnackClasses })

  return (
    <SnackNotification
      classes={{
        snackMessage: classes.snackMessage,
        content: classes.content,
        title: classes.title,
        message: classes.message,
        linkButton: classes.linkButton,
        ...classesProp,
      }}
      {...rest}
    />
  )
}

export default InfoSnackNotification
