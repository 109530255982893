import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, TextWithTooltip } from '@pbt/pbt-ui-components'
import { WarnAlert } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    infoIcon: {
      color: theme.colors.alertWarning,
      fontSize: '1.5rem',
    },
    tooltip: {
      borderColor: theme.colors.alertWarning,
      '&::before': {
        borderTopColor: theme.colors.alertWarning,
        marginLeft: 5,
      },
      '&::after': {
        marginLeft: 8,
      },
    },
  }),
  { name: 'EmployeeIdDuplicateWarning' },
)

const EmployeeIdDuplicateWarning = () => {
  const classes = useStyles()
  const { t } = useTranslation('Common')
  return (
    <TextWithTooltip
      Icon={WarnAlert}
      TooltipProps={{
        classes: {
          tooltip: classes.tooltip,
        },
      }}
      classes={{
        infoIcon: classes.infoIcon,
      }}
      data-testid="employee-id-duplicate-warning"
      iconPlacement="left"
      tooltipText={t('Common:DUPLICATE')}
    />
  )
}

export default EmployeeIdDuplicateWarning
