import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme } from '@pbt/pbt-ui-components'

import NumericInput from './inputs/NumericInput'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      display: 'flex',
      height: '50px',
      alignItems: 'center',
      padding: theme.spacing(0.5),
    },
    button: {
      border: theme.constants.fabBorder,
      padding: theme.spacing(1),
      borderRadius: 0,
      transition: 'background-color 0.3s, transform 0.1s',
      '&:first-child': {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
      },
      '&:last-child': {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
      },
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:active': {
        transform: 'scale(0.95)',
      },
    },
    inputWrapper: {
      borderTop: theme.constants.fabBorder,
      borderBottom: theme.constants.fabBorder,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      margin: theme.spacing(0, 1),
      width: '30px',

      '& input': {
        textAlign: 'center',
      },
    },
  }),
  { name: 'NumberStepper' },
)

interface NumberStepperProps {
  onChange: (newValue: number) => void
  value: number
}

export const NumberStepper = ({ value, onChange }: NumberStepperProps) => {
  const classes = useStyles()

  const handleDecrement = () => {
    onChange(Math.max(0, value - 1))
  }

  const handleIncrement = () => {
    onChange(value + 1)
  }

  const handleInputChange = (newValue: string | number) => {
    const number = Number(newValue)
    if (!Number.isNaN(number)) {
      onChange(number)
    }
  }

  return (
    <Box className={classes.root}>
      <IconButton className={classes.button} onClick={handleDecrement}>
        <RemoveIcon />
      </IconButton>
      <Box className={classes.inputWrapper}>
        <NumericInput
          className={classes.input}
          value={value}
          onChange={handleInputChange}
        />
      </Box>
      <IconButton className={classes.button} onClick={handleIncrement}>
        <AddIcon />
      </IconButton>
    </Box>
  )
}
