import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import {
  ClassesType,
  InfiniteLoaderListProps as InfiniteLoaderListPropsType,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import PuiDataTable from '~/components/common/lists/pui-data-table/PuiDataTable'
import { PuiDataTableColumn } from '~/components/common/lists/pui-data-table/puiDataTableType'

import {
  CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT,
  TableColumnWidth,
} from '../table/common/tableConstants'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    loaderList: {
      overflow: 'hidden',
    },
    table: {},
    tableCell: {
      '&:nth-child(1)': {
        minWidth: 88,
        width: TableColumnWidth.FIRST,
        maxWidth: TableColumnWidth.FIRST,
      },
      '&:nth-child(2)': {
        width: TableColumnWidth.SECOND,
        maxWidth: TableColumnWidth.SECOND,
      },
      '&:nth-child(3)': {
        width: TableColumnWidth.THIRD,
        maxWidth: TableColumnWidth.THIRD,
      },
      '&:nth-child(4)': {
        width: TableColumnWidth.FOURTH,
        maxWidth: TableColumnWidth.FOURTH,
      },
      '&:nth-child(5)': {
        width: TableColumnWidth.FIFTH,
        maxWidth: TableColumnWidth.FIFTH,
      },
      '&:nth-child(6)': {
        width: TableColumnWidth.SIXTH,
        maxWidth: TableColumnWidth.SIXTH,
      },
      '&:nth-child(7)': {
        width: TableColumnWidth.SEVENTH,
        maxWidth: TableColumnWidth.SEVENTH,
      },
    },
    tableHeader: {
      height: CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT,
      borderBottom: theme.constants.tabBorder,
      position: 'sticky',
      top: theme.constants.appHeader.heightMdUp,
      backgroundColor: theme.colors.tableBackground,
      zIndex: 1,
    },
  }),
  { name: 'ClientBillingActivityInfiniteLoaderList' },
)

export type ClientBillingActivityInfiniteLoaderListClassesType = ClassesType<
  typeof useStyles
>

export interface ClientBillingActivityInfiniteLoaderListProps<T> {
  InfiniteLoaderListProps?: Partial<InfiniteLoaderListPropsType>
  classes?: ClientBillingActivityInfiniteLoaderListClassesType
  clientId: string | undefined
  columns: PuiDataTableColumn[]
  emptyPlaceholder: string
  error: string | null
  isLoadingInitialData: boolean
  onFetchMore: ({
    clientId,
    from,
  }: {
    clientId: string | undefined
    from: number
  }) => void
  pageData: T
  totalCount: number
}

export const ClientBillingActivityInfiniteLoaderList = <
  T extends { id: string }[],
>({
  InfiniteLoaderListProps,
  classes: classesProp,
  clientId,
  columns,
  emptyPlaceholder,
  error,
  isLoadingInitialData,
  onFetchMore,
  pageData,
  totalCount,
}: ClientBillingActivityInfiniteLoaderListProps<T>) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Errors')

  if (error) {
    return (
      <Text px={2} py={1} variant="body2">
        {t('Errors:DEFAULT_ERROR')}
      </Text>
    )
  }

  if (!isLoadingInitialData && R.isEmpty(pageData)) {
    return (
      <Text px={2} py={1} variant="body2">
        {emptyPlaceholder}
      </Text>
    )
  }

  return (
    <PuiDataTable
      densed
      dynamicSize
      withBorder
      InfiniteLoaderListComponentProps={{
        useWindowScroll: true,
        className: classes.loaderList,
        ...InfiniteLoaderListProps,
      }}
      classes={{
        table: classes.table,
      }}
      classesHeader={{
        headingRow: classes.tableHeader,
        headingCell: classes.tableCell,
      }}
      classesRow={{ cell: classes.tableCell }}
      columns={columns}
      isLoading={isLoadingInitialData}
      items={pageData}
      loadMoreItems={(from) => onFetchMore({ clientId, from })}
      minRowHeight={CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT}
      rowHeight={CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT}
      totalCount={totalCount ?? 0}
      viewportItems={4}
    />
  )
}
