import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { CircularProgress, Fab, Grid, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import { openIntercom } from '~/store/duck/intercom'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      position: 'relative',
    },
    button: {
      minWidth: 150,
      maxWidth: '50%',
      height: 40,
      marginTop: theme.spacing(2),
    },
    progress: {
      position: 'absolute',
      left: 'calc(50% - 16px)',
      top: 'calc(50% - 16px)',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
    },
    errorText: {
      color: theme.colors.errorColor,
    },
  }),
  { name: 'VendorFormContainer' },
)

export interface VendorFormContainerProps {
  Footer?: React.ReactNode
  children: React.ReactNode
  disabled?: boolean
  isTesting?: boolean
  onTest: () => void
  status: string
}

const VendorFormContainer = ({
  isTesting,
  Footer,
  status,
  onTest,
  children,
  disabled,
}: VendorFormContainerProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const handleContactUs = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    dispatch(openIntercom())
  }

  return (
    <>
      <Grid container item className={classes.root}>
        {isTesting && (
          <CircularProgress
            className={classes.progress}
            color="secondary"
            size={32}
          />
        )}
        <Grid container style={{ opacity: isTesting ? 0.4 : 1 }}>
          {children}
        </Grid>
      </Grid>
      <Grid container item direction="column">
        {status === 'BAD_CREDENTIALS' && (
          <Text className={classes.errorText} my={1} px={2} variant="body2">
            <Trans
              components={{
                span: <span />,
                linkSpan: (
                  <Link
                    color="inherit"
                    component="button"
                    fontWeight={500}
                    lineHeight={1}
                    underline="always"
                    variant="body1"
                    onClick={handleContactUs}
                  />
                ),
              }}
              i18nKey="Businesses:INTEGRATION.WE_COULD_NOT_LOGIN_WITH_CREDENTIALS"
            />
          </Text>
        )}
        {status === 'CONFLICT' && (
          <Text className={classes.errorText} my={1} px={2} variant="body2">
            {t('Businesses:INTEGRATION.LOGIN_IS_USED_BY_ANOTHER_CLINIC')}
          </Text>
        )}
        <Fab
          className={classes.button}
          color="inherit"
          disabled={isTesting || disabled}
          variant="extended"
          onClick={onTest}
        >
          {isTesting
            ? `${t('Common:TESTING')}...`
            : t('Common:TEST_CREDENTIALS')}
        </Fab>
        {Footer}
      </Grid>
    </>
  )
}

export default VendorFormContainer
