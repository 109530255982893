import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { FilterLabel, Role } from '@pbt/pbt-ui-components'

import CheckboxListFilter from '~/components/common/filters/CheckboxListFilter'
import { getAllStaffRolesList, getRolesIsLoading } from '~/store/reducers/roles'
import { isPracticeAdminRole } from '~/utils/roleUtils'

const useStyles = makeStyles(
  () => ({
    root: {
      maxWidth: 'fit-content',
    },
    label: {},
  }),
  { name: 'RolesFilter' },
)

interface RolesFilterProps {
  includeSearch?: boolean
  isGroup?: boolean
  onSelectedRolesChange: (roleIds: string[]) => void
  roleIds: string[]
  selectedRoles: string[]
}

interface RolesFilterByAnalyticsProps extends RolesFilterProps {
  groupByRemote?: undefined
  groupByRhapsodyAnalytics?: true
}

interface RolesFilterByRemoteProps extends RolesFilterProps {
  groupByRemote?: true
  groupByRhapsodyAnalytics?: undefined
}

const RolesFilter = ({
  isGroup,
  includeSearch,
  groupByRhapsodyAnalytics,
  groupByRemote,
  roleIds,
  selectedRoles: initialSelectedRoles,
  onSelectedRolesChange,
}: RolesFilterByAnalyticsProps | RolesFilterByRemoteProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Abbreviations', 'Admin', 'Common'])

  const isLoading = useSelector(getRolesIsLoading)
  const allStaffRoles = useSelector(getAllStaffRolesList)

  const [filterOpen, setFilterOpen] = useState(false)
  const [selectedRoles, setSelectedRoles] = useState(initialSelectedRoles)

  useEffect(() => {
    setSelectedRoles(initialSelectedRoles)
  }, [filterOpen, initialSelectedRoles])

  const getGroupName = (role: Role) => {
    const groupConditionProp = groupByRhapsodyAnalytics
      ? 'rhapsodyAnalytics'
      : groupByRemote
      ? 'remote'
      : undefined

    if (!groupConditionProp) {
      return undefined
    }

    const firstGroup = groupByRhapsodyAnalytics
      ? t('Admin:MEMBER.ROLE.FILTER_GROUP.ANALYTICS')
      : t('Common:REMOTE')
    const secondGroup = groupByRhapsodyAnalytics
      ? t(
          'Abbreviations:ACRONYMS.PRODUCTION_INFORMATION_MANAGEMENT_SYSTEM.LABEL_ABBREVIATION',
        )
      : t('Common:PRACTICE')

    return role[groupConditionProp] ? firstGroup : secondGroup
  }

  const roles = R.pipe(
    R.filter((role: Role) => roleIds?.includes(role.id)),
    R.map((role) => ({
      ...role,
      nameTranslation:
        isGroup && isPracticeAdminRole(role)
          ? t('Common:GROUP_ADMINISTATOR')
          : role.nameTranslation,
      groupName: getGroupName(role),
    })),
    R.sortBy<Role>(R.prop<any>('groupName')),
  )(allStaffRoles)

  const filterRef = useRef<HTMLDivElement>(null)

  const handleRolesChange = ({ value: newRoleIds }: { value: string[] }) => {
    setSelectedRoles(newRoleIds)
    onSelectedRolesChange(newRoleIds)
  }

  const handleClose = (event: React.SyntheticEvent) => {
    if (!event?.target || !filterRef.current?.contains(event.target as Node)) {
      setFilterOpen(false)
      setSelectedRoles(initialSelectedRoles)
    }
  }

  return (
    <>
      <Grid item className={classes.root} ref={filterRef}>
        <FilterLabel
          className={classes.label}
          filter="filter"
          label={t('Admin:MEMBER.ROLE.ROLE_ONE_OR_OTHER')}
          open={filterOpen}
          onFilterClick={() => {
            setFilterOpen(!filterOpen)
          }}
        />
      </Grid>
      {filterRef && (
        <CheckboxListFilter
          arrayFormat
          resetOnReOpen
          anchorEl={filterRef.current}
          grouped={groupByRhapsodyAnalytics || groupByRemote}
          includeSearch={includeSearch}
          isLoading={isLoading}
          items={roles}
          open={filterOpen}
          value={selectedRoles}
          onChange={handleRolesChange}
          onClose={handleClose}
        />
      )}
    </>
  )
}

export default RolesFilter
