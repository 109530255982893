import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { UaEventOptions } from 'react-ga4/types/ga4'
import { useLocation } from 'react-router-dom'
import { Environment } from '@pbt/pbt-ui-components/src/constants/environment'

import { ENVIRONMENT_VARIABLES } from '~/constants/environmentVariables'
import { getEnvironment } from '~/utils'

const canSendEvents =
  !navigator.userAgent.match(/.*Selenium.*/gi) &&
  getEnvironment() === Environment.PROD

export interface GoogleAnalyticsProps {
  children: JSX.Element
}

const GoogleAnalytics = ({ children }: GoogleAnalyticsProps) => {
  const location = useLocation()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (canSendEvents) {
      ReactGA.initialize(
        ENVIRONMENT_VARIABLES[Environment.PROD].GOOGLE_ANALYTICS_KEY,
      )
      setInitialized(true)
    }
  }, [])

  useEffect(() => {
    if (initialized) {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      })
    }
  }, [location.pathname, location.search, initialized])

  return children
}

export const sendGoogleAnalyticsEvent = (
  optionsOrName: UaEventOptions | string,
  params?: any,
) => {
  if (canSendEvents) {
    ReactGA.event(optionsOrName, params)
  }
}

export default GoogleAnalytics
