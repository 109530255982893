/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Constant,
  DateUtils,
  Defaults,
  PuiTheme,
  useInterval,
  Utils,
} from '@pbt/pbt-ui-components'
import {
  CheckCircle as CheckCircleIcon,
  Eye as EyeIcon,
  ThreeDotsCircle as ThreeDotsCircleIcon,
} from '@pbt/pbt-ui-components/src/icons'

import QuickFilterButton from '~/components/common/buttons/QuickFilterButton'
import DoctorFilter, {
  DoctorFilterProps,
} from '~/components/common/filters/DoctorFilter'
import ExpandableTable from '~/components/common/lists/ExpandableTable'
import ListSearchFilterPanel from '~/components/common/lists/ListSearchFilterPanel'
import ConstantValueCell from '~/components/common/lists/primitive-table/cells/ConstantValueCell'
import PatientCell from '~/components/common/lists/primitive-table/cells/patient/PatientCell'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import { ImagingOrderStatus } from '~/constants/imaging'
import i18n from '~/locales/i18n'
import { getHasOpenDialogs } from '~/store/duck/dialogs'
import {
  clearRecords,
  fetchDashboardPage,
  getImagingDashboardFilters,
  getImagingDashboardIsLoading,
  getImagingDashboardList,
  getTotalCount,
  refreshDashboardItems,
  setFilters,
} from '~/store/duck/imagingDashboard'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getImagingOrderStatuses } from '~/store/reducers/constants'
import { ImagingDashboardItem, TableFilter } from '~/types'
import { addSearch, getUrlSearchParam } from '~/utils'
import { formatCalendarDate } from '~/utils/time'

import AssignedVetCell from './AssignedVetCell'
import ClientCell from './ClientCell'
import ImagingDateFilter from './ImagingDateFilter'
import ImagingDetails from './ImagingDetails'
import ImagingStatusCell from './ImagingStatusCell'
import ImagingStatusFilter from './ImagingStatusFilter'
import ImagingTableRow from './ImagingTableRow'
import ModalityFilter from './ModalityFilter'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    rowText: {
      fontSize: '1.4rem',
    },
    headerButtonsContainer: {
      width: '100%',
      paddingLeft: theme.spacing(2),
    },
  }),
  { name: 'ImagingTableComponent' },
)

const columns = [
  {
    label: i18n.t('Common:PATIENT'),
    component: (item: ImagingDashboardItem) => (
      <PatientCell isLoading={R.isEmpty(item)} patientId={item.patient} />
    ),
    width: 1.5,
  },
  {
    label: i18n.t('Common:CLIENT'),
    component: (item: ImagingDashboardItem) => <ClientCell item={item} />,
    width: 1.5,
  },
  {
    label: i18n.t('Common:IMAGE_REQUEST'),
    prop: 'name',
    highlight: true,
    width: 2,
  },
  {
    label: i18n.t('Common:MODALITY'),
    component: (item: ImagingDashboardItem) => (
      <ConstantValueCell
        constant="ImagingModalities"
        value={item?.modalityId}
      />
    ),
    FilterComponent: ModalityFilter,
    filter: 'modalityIds',
    width: 2.5,
  },
  {
    label: i18n.t('Common:DATE_TIME'),
    prop: (item: ImagingDashboardItem) =>
      DateUtils.formatDate(item?.orderCreatedDate),
    FilterComponent: ImagingDateFilter,
    filter: 'date',
    width: 2,
  },
  {
    label: i18n.t('Common:ASSIGNED_DOCTOR'),
    component: AssignedVetCell,
    FilterComponent: ({ ...props }: DoctorFilterProps) => (
      <DoctorFilter {...props} serialize={false} />
    ),
    filter: 'vetIds',
    width: 3,
  },
  {
    label: i18n.t('Common:STATUS'),
    component: ImagingStatusCell,
    FilterComponent: ImagingStatusFilter,
    filter: 'statusIds',
    width: 2,
  },
  {
    label: i18n.t('Common:RESULTS_RECEIVED'),
    prop: (item: ImagingDashboardItem) =>
      item.order?.resultsReceivedDate
        ? formatCalendarDate(item.order?.resultsReceivedDate)
        : '-',
    width: 2,
  },
]

const getUniqueId = R.prop('id')
const isOrphanWithoutPatient = (record: ImagingDashboardItem) =>
  !record?.patient
const getQuickFilterIds = (
  ImagingOrderStatuses: Constant[],
  quickFilter: ImagingOrderStatus[],
) =>
  quickFilter.map(
    (statusName) =>
      Utils.findConstantByName(statusName, ImagingOrderStatuses)?.id,
  )

const QuickFilters = {
  PENDING: [
    ImagingOrderStatus.ORDERED,
    ImagingOrderStatus.PENDING,
    ImagingOrderStatus.IN_PROGRESS,
  ],
  RESULTS_RECEIVED: [ImagingOrderStatus.IMAGE_RECEIVED],
  COMPLETED: [ImagingOrderStatus.COMPLETED],
}

export interface ImagingTableComponentProps {
  onDetailsClose: () => void
  recordId?: string
}

const ImagingTableComponent = ({
  recordId,
  onDetailsClose,
}: ImagingTableComponentProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation('Common')

  const imagingOrdersList = useSelector(getImagingDashboardList) || []
  const filters = useSelector(getImagingDashboardFilters)
  const isLoading = useSelector(getImagingDashboardIsLoading)
  const totalCount = useSelector(getTotalCount)
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const hasOpenDialogs = useSelector(getHasOpenDialogs)
  const ImagingOrderStatuses = useSelector(getImagingOrderStatuses)

  const loadMoreItems = R.compose(dispatch, fetchDashboardPage)
  const refreshItems = R.compose(dispatch, refreshDashboardItems)
  const clearItems = R.compose(dispatch, clearRecords)
  const search = getUrlSearchParam('query', location.search)

  useEffect(() => {
    refreshItems(search, 0, 50, filters)
    return () => {
      clearItems()
    }
  }, [filters, search])

  useInterval(() => {
    if (!hasOpenDialogs && !isLoading && currentBusinessId) {
      refreshItems(
        search,
        0,
        Math.max(imagingOrdersList.length - 1, 0),
        filters,
      )
    }
  }, Defaults.LAB_ORDERS_UPDATE_INTERVAL)

  const navigateToOrder = (id: string, orderRecord: ImagingDashboardItem) => {
    if (!isOrphanWithoutPatient(orderRecord)) {
      navigate(addSearch(location, `/imaging-dashboard/${orderRecord.id}`))
    }
  }

  const onApplyFilter = (filter: string, value: TableFilter) => {
    dispatch(setFilters({ ...filters, [filter]: value }))
  }

  const onClearFilters = () => {
    dispatch(setFilters({}))
  }

  const isItemLoaded = (index: number) => Boolean(imagingOrdersList[index])

  const hasAllStatusFilters = (ids: string[]) => {
    const statusIds = filters?.statusIds?.value as string[]

    return statusIds?.length > 0 && R.difference(statusIds, ids).length === 0
  }

  const isQuickFilterSelected = (quickFilter: ImagingOrderStatus[]) => {
    const ids = getQuickFilterIds(ImagingOrderStatuses, quickFilter)

    return hasAllStatusFilters(ids)
  }

  const handleQuickFilter = (quickFilter: ImagingOrderStatus[]) => {
    const ids = getQuickFilterIds(ImagingOrderStatuses, quickFilter)
    const isSelected = hasAllStatusFilters(quickFilter)
    const actualStatus = filters?.status || {}

    const value = isSelected
      ? R.without(ids, (actualStatus?.value as string[]) || [])
      : ids

    const newFilter = { ...actualStatus, value }

    onApplyFilter('statusIds', newFilter)
  }

  const headerButtons = (
    <Grid
      container
      item
      alignItems="center"
      className={classes.headerButtonsContainer}
      spacing={2}
      wrap="nowrap"
    >
      <Grid item>
        <QuickFilterButton
          IconComponent={ThreeDotsCircleIcon}
          active={isQuickFilterSelected(QuickFilters.PENDING)}
          text={t('Common:PENDING')}
          onClick={() => handleQuickFilter(QuickFilters.PENDING)}
        />
      </Grid>
      <Grid item>
        <QuickFilterButton
          IconComponent={EyeIcon}
          active={isQuickFilterSelected(QuickFilters.RESULTS_RECEIVED)}
          text={t('Common:IMAGES_RECEIVED')}
          onClick={() => handleQuickFilter(QuickFilters.RESULTS_RECEIVED)}
        />
      </Grid>
      <Grid item>
        <QuickFilterButton
          IconComponent={CheckCircleIcon}
          active={isQuickFilterSelected(QuickFilters.COMPLETED)}
          text={t('Common:COMPLETED')}
          onClick={() => handleQuickFilter(QuickFilters.COMPLETED)}
        />
      </Grid>
      <Grid item ml={1}>
        <ListSearchFilterPanel isLoading={isLoading} searchCount={totalCount} />
      </Grid>
    </Grid>
  )

  return (
    <ExpandableTable
      Expander={ImagingDetails}
      getUniqueId={R.identity}
      hasSelectedFilters={!R.isEmpty(filters)}
      headerButtons={headerButtons}
      isLoading={isLoading}
      item={recordId}
      itemId={recordId}
      list={imagingOrdersList}
      title={t('Common:IMAGING_REQUESTS')}
      onClose={onDetailsClose}
      onSelected={navigateToOrder}
    >
      <PrimitiveTableWithSearchHighlights
        RowComponent={ImagingTableRow}
        columns={columns}
        filters={filters}
        getUniqueId={getUniqueId}
        isItemLoaded={isItemLoaded}
        loadMoreItems={(from, to) => {
          loadMoreItems(search, from, to, filters)
        }}
        mainColumnCount={3}
        rowClasses={{
          text: classes.rowText,
        }}
        totalCount={totalCount}
        onApplyFilter={onApplyFilter}
        onClearFilters={onClearFilters}
      />
    </ExpandableTable>
  )
}

export default ImagingTableComponent
