import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Business,
  PermissionArea,
  PuiTextArea,
  PuiTheme,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import FormattedLabel from '~/components/common/labelPrinting/FormattedLabel'
import LabelSelect from '~/components/common/labelPrinting/LabelSelect'
import PuiSwitch from '~/components/common/PuiSwitch'
import {
  DymoLabelHtmlTypes,
  DymoLabelPreviews,
  DymoLabelType,
} from '~/constants/dymo'
import i18n from '~/locales/i18n'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import { getLabelTemplatesFormatted } from '~/store/reducers/constants'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { getIsDymoSupported } from '../../../../../labels/labelsUtils'
import {
  buildCageLabel,
  buildFolderLabel,
  buildFormattedPrescriptionData,
  buildLabSampleLabel,
  buildPracticeAddressLabel,
  buildPrescriptionLabel,
  // @ts-ignore
} from '../../../../../labels/labelTemplates'
import { PracticeDetailsPanels } from '../../practices'

export const DymoLabelTitles = {
  [DymoLabelType.PRESCRIPTION]: i18n.t(
    'Businesses:LABEL_CONFIGURATION.TITLES.PRESCRIPTION',
  ),
  [DymoLabelType.CAGE_CARD]: i18n.t(
    'Businesses:LABEL_CONFIGURATION.TITLES.CAGE_CARD',
  ),
  [DymoLabelType.CAGE_CARD_ALERT]: i18n.t(
    'Businesses:LABEL_CONFIGURATION.TITLES.CAGE_CARD',
  ),
  [DymoLabelType.LAB_ORDER]: i18n.t(
    'Businesses:LABEL_CONFIGURATION.TITLES.LAB_ORDER',
  ),
  [DymoLabelType.FOLDER]: i18n.t(
    'Businesses:LABEL_CONFIGURATION.TITLES.FOLDER',
  ),
  [DymoLabelType.PRACTICE_ADDRESS]: i18n.t(
    'Businesses:LABEL_CONFIGURATION.TITLES.PRACTICE_ADDRESS',
  ),
}

const DymoLabelPreviewProviders: Record<
  DymoLabelType | DymoLabelHtmlTypes,
  () => DymoLabelType[] | DymoLabelHtmlTypes[]
> = {
  [DymoLabelType.PRESCRIPTION]: () =>
    buildPrescriptionLabel(DymoLabelPreviews[DymoLabelType.PRESCRIPTION]),
  [DymoLabelType.CAGE_CARD]: () =>
    buildCageLabel(DymoLabelPreviews[DymoLabelType.CAGE_CARD]),
  [DymoLabelType.CAGE_CARD_ALERT]: () =>
    buildCageLabel(DymoLabelPreviews[DymoLabelType.CAGE_CARD_ALERT]),
  [DymoLabelType.FOLDER]: () =>
    buildFolderLabel(DymoLabelPreviews[DymoLabelType.FOLDER]),
  [DymoLabelType.PRACTICE_ADDRESS]: () =>
    buildPracticeAddressLabel(
      DymoLabelPreviews[DymoLabelType.PRACTICE_ADDRESS],
    ),
  [DymoLabelType.LAB_ORDER]: () =>
    buildLabSampleLabel(DymoLabelPreviews[DymoLabelType.LAB_ORDER]),
}

const formattedPrescriptionDataTemplate = buildFormattedPrescriptionData(
  DymoLabelPreviews[DymoLabelType.PRESCRIPTION],
)

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tooltip: {
      maxWidth: 1500,
    },
    tableContentText: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
    },
    tableHeading: {
      verticalAlign: 'top',
      padding: theme.spacing(1, 0, 1, 2),
      borderRight: theme.constants.tableBorder,
      borderBottom: theme.constants.stripedTableHeaderBorder,
    },
    tableViewport: {
      border: theme.constants.tableBorder,
    },
    tableCell: {
      padding: theme.spacing(1, 0, 1, 2),
      border: 'none',
      borderRight: theme.constants.tableBorder,
    },
    tableRow: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    formattedLabelPreview: {
      zoom: '0.64',
    },
    labelSelect: {
      width: '100%',
    },
  }),
  { name: 'LabelsConfiguration' },
)

const htmlEnabledLabelTypes = [
  DymoLabelType.PRESCRIPTION,
  DymoLabelType.LAB_ORDER,
  DymoLabelType.CAGE_CARD,
]

export interface LabelConfigurationProps {
  business: Business
}

const LabelsConfiguration = ({ business }: LabelConfigurationProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Businesses'])

  const { fields, validate, reset } = useFields([
    {
      name: 'prescriptionLabelDisclaimer',
      label: t('Businesses:LABEL_CONFIGURATION.PRESCRIPTION_LABEL_DISCLAIMER'),
      initialValue: business.prescriptionLabelDisclaimer || '',
    },
    {
      name: 'labelTemplates',
      initialValue: business.labelTemplates || {},
    },
    {
      name: 'dymoConnectEnabled',
      type: 'toggle',
      initialValue: business.dymoConnectEnabled || false,
    },
  ])

  const { prescriptionLabelDisclaimer, dymoConnectEnabled, labelTemplates } =
    fields

  usePracticeFieldsSection({
    fields,
    business,
    sectionName: PracticeDetailsPanels.LABEL_CONFIGURATION,
    validate,
    reset,
  })

  const isDymoSupported = getIsDymoSupported()

  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )
  const formattedLabels = useSelector(getLabelTemplatesFormatted)

  const isDymoEnabled = isDymoSupported && dymoConnectEnabled.value

  const isFormattedPrescriptionLabel =
    !labelTemplates.value[DymoLabelType.PRESCRIPTION] ||
    formattedLabels.find(
      ({ id }: { id: string }) =>
        id === labelTemplates.value[DymoLabelType.PRESCRIPTION],
    )

  const supportedLabelTypes = Object.values(
    isDymoEnabled ? DymoLabelType : DymoLabelHtmlTypes,
  )

  const formattedPrescriptionData = {
    ...formattedPrescriptionDataTemplate,
    bottomWarning: prescriptionLabelDisclaimer.value,
  }

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <PuiSwitch
          disabled={!permissions.update}
          field={dymoConnectEnabled}
          label={t('Admin:PRACTICE.DYMO_CONNECT')}
        />
      </Grid>

      <Grid item>
        <Table className={classes.tableViewport}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeading}>
                <Text strong variant="lowAccent2">
                  {t('Businesses:LABEL_CONFIGURATION.LABEL_USE_CASES')}
                </Text>
              </TableCell>
              <TableCell className={classes.tableHeading}>
                <TextWithTooltip
                  allowWrap
                  strong
                  tooltipText={t(
                    'Businesses:LABEL_CONFIGURATION.THIS_WILL_SET_DEFAULT_SELECTION_FOR_EACH_TYPE',
                  )}
                  variant="lowAccent2"
                >
                  {t('Businesses:LABEL_CONFIGURATION.DEFAULT_LABEL_SIZE')}
                </TextWithTooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {supportedLabelTypes.map(
              (type: DymoLabelType | DymoLabelHtmlTypes) => {
                const tooltipProvider = DymoLabelPreviewProviders[type]
                const labelLines: DymoLabelType[] | DymoLabelHtmlTypes[] =
                  tooltipProvider ? tooltipProvider() : []

                return (
                  <TableRow className={classes.tableRow} key={type}>
                    <TableCell className={classes.tableCell}>
                      <TextWithTooltip
                        allowWrap
                        TooltipProps={{
                          classes: {
                            tooltip: classes.tooltip,
                          },
                          tooltipDelay: 200,
                        }}
                        iconPlacement="left"
                        tooltipText={
                          <>
                            <Text mb={1} variant="lowAccent2">
                              {t(
                                'Businesses:LABEL_CONFIGURATION.EXAMPLE_LABEL_CONTENT',
                              )}
                              :
                            </Text>
                            {type === DymoLabelType.PRESCRIPTION &&
                            isFormattedPrescriptionLabel ? (
                              <Grid
                                item
                                className={classes.formattedLabelPreview}
                              >
                                <FormattedLabel
                                  data={formattedPrescriptionData}
                                />
                              </Grid>
                            ) : (
                              labelLines.map((line: string, index: number) => (
                                <Text
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={`tooltip-for-${type}-dymo-label-${index}`}
                                  variant="body2"
                                >
                                  {line}
                                  <br />
                                </Text>
                              ))
                            )}
                          </>
                        }
                        variant="body"
                      >
                        {DymoLabelTitles[type]}
                      </TextWithTooltip>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <LabelSelect
                        renderEmpty
                        className={classes.labelSelect}
                        labelType={type}
                        placeholder={t('Common:SELECT_SIZE')}
                        showFormattedLabels={
                          isDymoEnabled && type === DymoLabelType.PRESCRIPTION
                        }
                        showHtmlLabels={Boolean(
                          htmlEnabledLabelTypes.includes(type as DymoLabelType),
                        )}
                        showSimpleTextLabels={isDymoEnabled}
                        value={labelTemplates.value[type] || ''}
                        onChange={Utils.handleFormSelectInput((otherLabel) =>
                          labelTemplates.setValue({
                            ...labelTemplates.value,
                            [type]: otherLabel,
                          }),
                        )}
                      />
                    </TableCell>
                  </TableRow>
                )
              },
            )}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs>
        <PuiTextArea
          disabled={!permissions.update}
          field={prescriptionLabelDisclaimer}
          label={prescriptionLabelDisclaimer.label}
          labelProps={{
            tooltipText: t(
              'Businesses:LABEL_CONFIGURATION.PRESCRIPTION_LABEL_DISCLAIMER_TOOLTIP',
            ),
          }}
        />
      </Grid>
    </Grid>
  )
}

export default memo(LabelsConfiguration, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
