import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, InputLabel, Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Calendar,
  PuiTextField,
  PuiTheme,
  useFields,
  ValidateHandle,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'
import { AttachmentGroupDetails } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    descriptionLabel: {
      color: theme.colors.primaryText,
      fontSize: '1.4rem',
      fontWeight: 500,
    },
  }),
  { name: 'MultiAttachmentDetails' },
)

export interface MultiAttachmentHistoryDetailsProps {
  initialDate?: string
  initialSource?: string
  initialTitle?: string
}

export interface MultiAttachmentDetailsHandle extends ValidateHandle {
  getDetails: () => AttachmentGroupDetails
}

const MultiAttachmentDetails = forwardRef<
  ValidateHandle,
  MultiAttachmentHistoryDetailsProps
>(function AttachmentDetails(
  { initialDate, initialTitle, initialSource },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'MedicalHistory'])

  const isAttachingFilesToLabOrdersEnabled = useSelector(
    getFeatureToggle(FeatureToggle.ATTACH_FILES_TO_LAB_ORDERS),
  )
  const attachmentGroupFromLabel = isAttachingFilesToLabOrdersEnabled
    ? t('MedicalHistory:ATTACHMENT_HISTORY_DETAILS.ATTACHMENT_SOURCE')
    : t('MedicalHistory:ATTACHMENT_HISTORY_DETAILS.PRACTICE_ATTACHMENT_FROM')
  const { fields, validate } = useFields(
    [
      {
        name: 'date',
        label: t('Common:DATE_TIME'),
        validators: ['required'],
        initialValue: initialDate || new Date().toISOString(),
      },
      {
        name: 'title',
        label: t('Common:TITLE'),
        initialValue: initialTitle || '',
      },
      {
        name: 'diagnosedIn',
        label: attachmentGroupFromLabel,
        initialValue: initialSource || '',
      },
      { name: 'description', label: t('Common:DESCRIPTION'), initialValue: '' },
    ],
    false,
  )

  const { date, title, diagnosedIn, description } = fields

  // @ts-ignore
  const getDetails = () => R.pluck('value', fields)

  useImperativeHandle(ref, () => ({
    validate,
    getDetails,
  }))

  return (
    <Grid container item direction="column">
      <Grid item>
        <PuiTextField
          autoFocus
          field={title}
          inputProps={{ maxLength: 100 }}
          label={title.label}
        />
      </Grid>
      <Stack alignItems="center" direction="row" spacing={2}>
        <Grid item>
          <Calendar fullWidth field={date} label={`${date.label}*`} />
        </Grid>
        <Grid item xs>
          <PuiTextField
            field={diagnosedIn}
            inputProps={{ maxLength: 100 }}
            label={diagnosedIn.label}
          />
        </Grid>
      </Stack>
      <Grid item mt={1.5}>
        <InputLabel htmlFor="description-input">
          <span className={classes.descriptionLabel}>{description.label}:</span>
        </InputLabel>
        <PuiTextField
          multiline
          field={description}
          id="description-input"
          inputProps={{ maxLength: 1000 }}
          minRows={3}
          variant="outlined"
        />
      </Grid>
    </Grid>
  )
})

export default MultiAttachmentDetails
