import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  CircularProgressOverlay,
  ControlButtonGroup,
  ControlButtonGroupName,
  HtmlNotesPreview,
  LanguageUtils,
  LinkButton,
  Nil,
  PermissionArea,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import PersonLabel from '~/components/common/labels/PersonLabel'
import Link from '~/components/common/link/Link'
import ImagingStatusLabel from '~/components/dashboard/imaging-procedures/ImagingStatusLabel'
import ImagingStatusSelect from '~/components/dashboard/imaging-procedures/ImagingStatusSelect'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { OrderType } from '~/constants/SOAPStates'
import {
  editOrder,
  getImagingIsLoading,
  getMultipleImagingOrders,
} from '~/store/duck/imagingOrders'
import { useOpenInvoice } from '~/store/hooks/finance'
import { getCRUDByArea, getCurrentBusinessId } from '~/store/reducers/auth'
import {
  getFeatureToggle,
  getImagingModalities,
} from '~/store/reducers/constants'
import { ImagingOrder, ImagingTimelineItem } from '~/types'
import { addOriginalBusinessId, getPropFromFirstItemOfProp } from '~/utils'
import { isDifferedBusinessList } from '~/utils/businessUtils'
import useDialog from '~/utils/useDialog'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import TimelineCard, { TimelineCardProps } from '../TimelineCard'
import TimelineCardActions from '../TimelineCardActions'
import TimelineCardContent from '../TimelineCardContent'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    transportIcon: {
      color: theme.colors.disabledLabelText,
      marginRight: theme.spacing(1),
      paddingBottom: '2px',
    },
    iconButton: {
      marginTop: 0,
      marginBottom: 0,
    },
  }),
  { name: 'ImagingCard' },
)

export interface ImagingCardProps extends TimelineCardProps {
  clientId: string
  item: ImagingTimelineItem
  patientId: string
}

const ImagingCard = ({
  item,
  clientId,
  patientId,
  ...rest
}: ImagingCardProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common'])

  const permissions = useSelector(getCRUDByArea(PermissionArea.LAB_TEST))
  const invoicePermissions = useSelector(getCRUDByArea(PermissionArea.INVOICE))
  const soapPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const isLoadingImagingOrder = useSelector(getImagingIsLoading)
  const ImagingModalities = useSelector(getImagingModalities)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const currentBusinessId = useSelector(getCurrentBusinessId)

  const [openImagingOrderDialog] = useDialog(DialogNames.IMAGING_ORDER)
  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)

  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)

  const {
    businessId,
    soapId,
    invoiceId,
    entries,
    soapFinalized: isSoapFinalized,
  } = item

  const imagingOrderIds = R.pluck('imagingOrder', entries).filter(Boolean)
  const imagingOrders = useSelector(
    getMultipleImagingOrders(imagingOrderIds as string[]),
  )
  const isEntriesWithImagingOrder = entries.some(
    (entry) => entry.orderId && entry.imagingOrder,
  )
  const isOneImagingEntry = entries.length === 1
  const isOneImagingOrder = isOneImagingEntry && imagingOrders.length === 1
  const firstImagingOrder = imagingOrders[0]
  const firstImagingEntry = entries[0]

  const hasDifferedBusinessEntries = isDifferedBusinessList(entries)

  const isContextFirstItem = useIsCurrentContextItem(firstImagingEntry)

  const handleViewImages = (resultsUrl: string | Nil) => {
    if (resultsUrl) {
      window.open(resultsUrl)
    }
  }

  const handleEditOrder = (itemToEdit: ImagingOrder) => {
    openImagingOrderDialog({
      orderId: itemToEdit?.id,
      isSoapFinalized,
    })
  }

  const handleUpdateStatus = (newStatusId: string, order: ImagingOrder) => {
    dispatch(editOrder({ ...order, statusId: newStatusId }))
  }

  const handleOpenInvoice = () => {
    openInvoice({
      clientId,
      patientId,
      soapId,
      invoiceId,
      logId: getPropFromFirstItemOfProp({
        parentProp: 'entries',
        secondaryProp: 'procedureId',
        data: item,
      }),
      logType: OrderType.PROCEDURE,
    })
  }

  return (
    <TimelineCard {...item} {...rest}>
      <CircularProgressOverlay halfTransparent open={isLoadingImagingOrder} />
      <TimelineCardContent noTypography>
        {!isOneImagingEntry && (
          <Grid container item alignItems="center" my={1.5} wrap="nowrap">
            <Text variant="h1">{t('Common:IMAGE_REQUEST')}</Text>
            {!hasDifferedBusinessEntries && (
              <BusinessShareIcon businessIds={[firstImagingEntry.businessId]} />
            )}
          </Grid>
        )}
        {entries.map((entry) => {
          const imagingItem = imagingOrders.find(
            (order) => entry.imagingOrder && entry.imagingOrder === order.id,
          )
          const isContextItem =
            !isPatientSharingEnabled || entry?.businessId === currentBusinessId

          return imagingItem ? (
            <React.Fragment key={imagingItem.id + imagingItem.vendorId}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                wrap="nowrap"
              >
                {isOneImagingOrder && (
                  <>
                    <Grid
                      container
                      item
                      alignItems="center"
                      my={1.5}
                      wrap="nowrap"
                    >
                      <Text variant="h1">
                        {LanguageUtils.getTranslatedFieldName(
                          firstImagingOrder,
                        )}
                      </Text>
                      <BusinessShareIcon businessIds={[entry.businessId]} />
                    </Grid>
                    {isContextFirstItem && (
                      <Grid
                        container
                        item
                        alignItems="center"
                        width="auto"
                        wrap="nowrap"
                      >
                        <ControlButtonGroup
                          buttons={[
                            permissions.read &&
                              Boolean(firstImagingOrder.resultsUrl) && {
                                name: ControlButtonGroupName.PREVIEW,
                                onClick: () =>
                                  handleViewImages(
                                    firstImagingOrder.resultsUrl,
                                  ),
                              },
                            permissions.update && {
                              name: ControlButtonGroupName.EDIT,
                              onClick: () => handleEditOrder(firstImagingOrder),
                            },
                          ]}
                          classes={{ iconButton: classes.iconButton }}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
              {!isOneImagingOrder && (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  wrap="nowrap"
                >
                  <Grid container item alignItems="center" mb={1} wrap="nowrap">
                    <Text strong variant="body">
                      {LanguageUtils.getTranslatedFieldName(imagingItem)}
                    </Text>
                    {hasDifferedBusinessEntries && (
                      <BusinessShareIcon businessIds={[entry.businessId]} />
                    )}
                  </Grid>
                  <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="flex-end"
                    wrap="nowrap"
                  >
                    {imagingItem.statusId && (
                      <Grid item>
                        <ImagingStatusLabel statusId={imagingItem.statusId} />
                      </Grid>
                    )}
                    &nbsp;
                    {isContextItem && (
                      <ControlButtonGroup
                        buttons={[
                          permissions.read &&
                            Boolean(imagingItem.resultsUrl) && {
                              name: ControlButtonGroupName.PREVIEW,
                              onClick: () =>
                                handleViewImages(imagingItem.resultsUrl),
                            },
                          permissions.update && {
                            name: ControlButtonGroupName.EDIT,
                            onClick: () => handleEditOrder(imagingItem),
                          },
                        ]}
                        classes={{ iconButton: classes.iconButton }}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
              <PersonLabel
                mb={0.5}
                personId={imagingItem.assignedVetId}
                strong={isOneImagingOrder}
                variant="body"
              />
              <Text mb={0.5} variant="body">
                {`${t(
                  'Common:MODALITY',
                )}: ${LanguageUtils.getConstantTranslatedName(
                  imagingItem.modalityId,
                  ImagingModalities,
                  '',
                )}`}
              </Text>
              {imagingItem.notes && (
                <HtmlNotesPreview notes={imagingItem.notes} />
              )}
            </React.Fragment>
          ) : (
            <React.Fragment key={entry.procedureId}>
              {isOneImagingEntry && (
                <Grid container item alignItems="center" my={1.5} wrap="nowrap">
                  <Text variant="h1">
                    {LanguageUtils.getTranslatedFieldName(entry)}
                  </Text>
                  <BusinessShareIcon businessIds={[entry.businessId]} />
                </Grid>
              )}
              {!isOneImagingEntry && (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  wrap="nowrap"
                >
                  <Grid container item alignItems="center" mb={1} wrap="nowrap">
                    <Text strong variant="body">
                      {LanguageUtils.getTranslatedFieldName(entry)}
                    </Text>
                    {hasDifferedBusinessEntries && (
                      <BusinessShareIcon businessIds={[entry.businessId]} />
                    )}
                  </Grid>
                </Grid>
              )}
              {entry?.assignedVet && (
                <PersonLabel
                  mb={0.5}
                  personId={entry.assignedVet}
                  strong={isOneImagingEntry}
                  variant="body"
                />
              )}
              <Text mb={0.5} variant="body">
                {`${t(
                  'Common:MODALITY',
                )}: ${LanguageUtils.getConstantTranslatedName(
                  entry.modalityId,
                  ImagingModalities,
                  '',
                )}`}
              </Text>
              {entry.notes && <HtmlNotesPreview notes={entry.notes} />}
            </React.Fragment>
          )
        })}
      </TimelineCardContent>
      {isEntriesWithImagingOrder ? (
        <TimelineCardActions
          customStateControl={
            isOneImagingOrder ? (
              <ImagingStatusSelect
                disabled={!permissions.update || !isContextFirstItem}
                // TODO: refactor using useFields
                // @ts-ignore
                field={{
                  value: firstImagingOrder.statusId,
                  set: Utils.handleFormSelectInput((newStatusId) =>
                    handleUpdateStatus(newStatusId, firstImagingOrder),
                  ),
                }}
                isSoapFinalized={isSoapFinalized}
              />
            ) : null
          }
        >
          {soapPermissions.read && soapId && !invoiceId && (
            <Link
              to={addOriginalBusinessId(
                `/soap/${soapId}`,
                isPatientSharingEnabled ? businessId : null,
              )}
            >
              {t('Common:VIEW_SOAP')}
            </Link>
          )}
          {isContextFirstItem && invoicePermissions.read && invoiceId && (
            <LinkButton onClick={handleOpenInvoice}>
              {t('Common:VIEW_INVOICE')}
            </LinkButton>
          )}
          {isContextFirstItem &&
            permissions.read &&
            isOneImagingOrder &&
            firstImagingOrder.resultsUrl && (
              <LinkButton
                onClick={() => handleViewImages(firstImagingOrder.resultsUrl)}
              >
                {t('Common:VIEW_IMAGES')}
              </LinkButton>
            )}
        </TimelineCardActions>
      ) : (
        <TimelineCardActions>
          {soapPermissions.read && soapId && !invoiceId && (
            <Link
              to={addOriginalBusinessId(
                `/soap/${soapId}`,
                isPatientSharingEnabled ? businessId : null,
              )}
            >
              {t('Common:VIEW_SOAP')}
            </Link>
          )}
          {isContextFirstItem && invoicePermissions.read && invoiceId && (
            <LinkButton onClick={handleOpenInvoice}>
              {t('Common:VIEW_INVOICE')}
            </LinkButton>
          )}
        </TimelineCardActions>
      )}
    </TimelineCard>
  )
}

export default ImagingCard
