import React from 'react'
import { Grid, GridProps, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, PuiTheme, Text } from '@pbt/pbt-ui-components'
import { Download as DownloadIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    link: {
      color: 'inherit',
    },
    text: {
      cursor: 'pointer',
    },
    icon: {
      color: theme.colors.tabLabel,
      cursor: 'pointer',
    },
    roundButton: {
      height: 40,
      width: 40,
      borderRadius: 20,
      display: 'flex',
      cursor: 'pointer',
      border: theme.constants.fabBorder,
      boxShadow: theme.constants.buttonBlockShadow,
    },
  }),
  { name: 'DownloadButton' },
)

export interface DownloadButtonProps extends GridProps {
  classes?: ClassesType<typeof useStyles>
  href?: string
  label?: React.ReactNode
  round?: boolean
}

const DownloadButton = ({
  href,
  label,
  classes: classesProp,
  round = false,
  ...rest
}: DownloadButtonProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Link
      download
      className={classNames(classes.link, {
        [classes.roundButton]: round,
      })}
      href={href}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Grid container alignItems="center" justifyContent="center" {...rest}>
        <DownloadIcon className={classes.icon} />
        <Text inline className={classes.text} variant="lowAccent2">
          {label}
        </Text>
      </Grid>
    </Link>
  )
}

export default DownloadButton
