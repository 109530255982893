import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Grid, Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme, Text, Utils } from '@pbt/pbt-ui-components'

import Avatar from '~/components/common/Avatar'
import LockLink from '~/components/common/link/LockLink'
import LogOutLink from '~/components/common/link/LogOutLink'
import PuiLanguagesSelector from '~/components/common/PuiLanguagesSelector'
import DialogNames from '~/constants/DialogNames'
import {
  getAssignedLocations,
  getCurrentBusinessId,
  getCurrentUser,
} from '~/store/reducers/auth'
import { auth0Enabled } from '~/utils'
import useDialog from '~/utils/useDialog'

import ClinicSelectorMobile from './ClinicSelectorMobile'

const detailsContainerHeight = 110
const noLocationsDetailsContainerHeight = 60
const businessesOnScreen = 5

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      display: 'block',
      backgroundColor: theme.colors.profileTopBackground,
      color: theme.colors.profileText,
    },
    header: {
      height: 54,
      color: 'inherit',
    },
    userNameText: {
      fontWeight: 600,
      maxWidth: 140,
      cursor: 'pointer',
      color: 'inherit',
    },
    noLocationsContainerOpen: {
      height: noLocationsDetailsContainerHeight,
    },
    withLocationsContainerOpen: {
      height: detailsContainerHeight,
    },
    detailsContainerOpen: {
      transition: theme.transitions.create(['height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    detailsContainerClosed: {
      height: 0,
      transition: theme.transitions.create(['height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    noLocationsDetails: {
      height: noLocationsDetailsContainerHeight,
    },
    withLocationsDetails: {
      height: detailsContainerHeight,
    },
    details: {
      borderTop: '1px solid rgba(245, 249, 251, 0.6)',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      overflow: 'hidden',
    },
    linkText: {
      color: 'inherit',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    clinicsContainer: {
      maxHeight: 56 * businessesOnScreen + 1,
      borderTop: '1px solid rgba(245, 249, 251, 0.4)',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        '-moz-appearance': 'none',
        '-webkit-appearance': 'none',
        width: 7,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 4,
        width: 8,
        backgroundColor: 'rgba(0, 0, 0, .2)',
        '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, .4)',
      },
    },
    clinicsContainerOpen: {
      transition: theme.transitions.create('max-height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    clinicsContainerClosed: {
      overflowY: 'hidden',
      maxHeight: 0,
      transition: theme.transitions.create('max-height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    icon: {
      verticalAlign: 'middle',
    },
  }),
  { name: 'UserDetailsMobile' },
)

export interface UserDetailsMobileProps {
  open: boolean
}

const UserDetailsMobile = ({ open }: UserDetailsMobileProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const user = useSelector(getCurrentUser) || {}
  const assignedBusinesses = useSelector(getAssignedLocations)
  const businessId = useSelector(getCurrentBusinessId)

  const [expanded, setExpanded] = useState(false)
  const [openProfileDialog] = useDialog(DialogNames.PROFILE)
  const [clinicsExpanded, setClinicsExpanded] = useState(false)

  useEffect(() => {
    if (!expanded) {
      setClinicsExpanded(false)
    }
  }, [expanded])

  const showLocations = assignedBusinesses.length > 1

  return (
    <Grid container className={classes.root} direction="column" wrap="nowrap">
      <Grid
        container
        item
        alignItems="center"
        className={classes.header}
        px={2.5}
        wrap="nowrap"
        onClick={() => setExpanded(!expanded)}
      >
        <Grid item>
          <Avatar person={user} />
        </Grid>
        <Grid item>
          <Text noWrap className={classes.userNameText} ml={1.5} variant="h4">
            {Utils.getPersonString(user)}
          </Text>
        </Grid>
        {expanded ? (
          <ExpandLess color="inherit" />
        ) : (
          <ExpandMore color="inherit" />
        )}
      </Grid>
      <Grid
        container
        item
        className={classNames({
          [classNames(
            classes.detailsContainerOpen,
            showLocations
              ? classes.withLocationsContainerOpen
              : classes.noLocationsContainerOpen,
          )]: expanded,
          [classes.detailsContainerClosed]: !expanded,
        })}
        overflow="hidden"
      >
        <Grid
          container
          className={classNames(
            classes.details,
            showLocations
              ? classes.withLocationsDetails
              : classes.noLocationsDetails,
          )}
          direction="column"
          justifyContent="space-evenly"
          px={1.5}
          py={3}
          wrap="nowrap"
        >
          <Grid item>
            <Stack direction="row" spacing={3}>
              <Text
                inline
                link
                strong
                className={classes.linkText}
                variant="body"
                onClick={() => openProfileDialog()}
              >
                {t('Common:PROFILE')}
              </Text>
              <LogOutLink
                link
                strong
                className={classes.linkText}
                variant="body"
              />
              {!auth0Enabled && (
                <LockLink
                  link
                  strong
                  className={classes.linkText}
                  variant="body"
                />
              )}
            </Stack>
          </Grid>
          <Grid container item alignItems="center" mt={3} wrap="nowrap">
            {businessId && (
              <PuiLanguagesSelector
                businessId={businessId}
                classes={{ selectorButton: classes.linkText }}
              />
            )}
            {showLocations && (
              <Grid
                container
                onClick={() => setClinicsExpanded(!clinicsExpanded)}
              >
                <Grid item>
                  <Text
                    link
                    strong
                    className={classes.linkText}
                    px={1}
                    variant="h4"
                  >
                    {t('Common:SWITCH_HOSPITALS')}
                  </Text>
                </Grid>
                <Grid item>
                  {clinicsExpanded ? (
                    <ExpandLess className={classes.icon} color="inherit" />
                  ) : (
                    <ExpandMore className={classes.icon} color="inherit" />
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        className={classNames(classes.clinicsContainer, {
          [classes.clinicsContainerOpen]: clinicsExpanded,
          [classes.clinicsContainerClosed]: !clinicsExpanded,
        })}
      >
        <ClinicSelectorMobile open={clinicsExpanded && expanded && open} />
      </Grid>
    </Grid>
  )
}

export default UserDetailsMobile
