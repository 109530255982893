import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid, Hidden, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Nil,
  PuiCloseButton,
  PuiTheme,
  Shadow,
  Text,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { closeClientsSplashScreen } from '~/store/actions/journal'
import { getHasClosedClientsSplashScreen } from '~/store/reducers/journal'
import useDialog from '~/utils/useDialog'

import HelpButton from '../HelpButton'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.searchShadow, 'above'),
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(3),
      maxWidth: 729,
      backgroundColor: theme.colors.tableBackground,
      boxShadow: theme.constants.blockShadow,
      borderRadius: '0 2px 2px 2px',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(1),
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(0),
        paddingTop: theme.spacing(2),
      },
    },
    header: {
      fontSize: '3.3rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.6rem',
      },
    },
    catImg: {
      position: 'absolute',
      top: -39,
      left: -24,
      [theme.breakpoints.down('sm')]: {
        left: -8,
      },
    },
    dogImg: {
      position: 'absolute',
      bottom: -10,
      right: -52,
    },
    img: {
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        left: 74,
        top: 20,
        width: 290,
        height: 301,
      },
      [theme.breakpoints.down('sm')]: {
        top: 22,
        width: 194,
        height: 200,
      },
    },
    button: {
      marginTop: theme.spacing(1),
      miWidth: 150,
      height: 40,
    },
    optionsContainer: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(10),
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
      },
    },
    bottomPanel: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(4),
      background: theme.colors.splashScreenBackground,
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(3),
      },
    },
  }),
  { name: 'ClientsSplashScreen' },
)

export interface ClientsSplashScreenProps {
  search?: string | Nil
}

const ClientsSplashScreen = ({ search }: ClientsSplashScreenProps) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const isXs = useMediaQuery((theme: PuiTheme) => theme.breakpoints.down('sm'))
  const { t } = useTranslation(['Common', 'Clients', 'PageTitles'])

  const dispatch = useDispatch()
  const hasClosedSplashScreen = useSelector(getHasClosedClientsSplashScreen)

  const [openNewClientDialog] = useDialog(DialogNames.CLIENT_NEW)

  if (hasClosedSplashScreen || search) {
    return null
  }

  const addNewClient = () => {
    if (isXs) {
      navigate('/client/new')
    } else {
      openNewClientDialog()
    }
  }

  const goToScheduler = () => {
    navigate('/scheduler', { state: { openDialog: true } })
  }

  const goToTeamMembers = () => {
    navigate('/admin/general/members', {
      state: { openTeamMemberDialog: true },
    })
  }

  const goToAvailabilityRules = () => {
    navigate('/admin/scheduling/availability', {
      state: { openRuleDialog: true },
    })
  }

  return (
    <>
      <Grid
        container
        alignItems="center"
        aria-label="clients-splash-screen"
        className={classes.root}
        direction="column"
      >
        <PuiCloseButton onClick={() => dispatch(closeClientsSplashScreen())} />
        <img
          alt={t('PageTitles:CAT')}
          className={classes.catImg}
          src={`${process.env.PUBLIC_URL}/assets/splash/cat@3x.svg`}
        />
        <Hidden smDown>
          <img
            alt={t('PageTitles:DOG')}
            className={classes.dogImg}
            src={`${process.env.PUBLIC_URL}/assets/splash/dog@3x.svg`}
          />
        </Hidden>
        <Grid item>
          <Text className={classes.header} variant="hero">
            {t('Clients:CLIENTS_SPLASH_SCREEN.WELCOME_MESSAGE')}
          </Text>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          direction={isXs ? 'column-reverse' : 'row'}
        >
          <Grid item xs>
            <img
              alt={t('Clients:CLIENTS_SPLASH_SCREEN.WELCOME_PRODUCT_SHOT')}
              className={classes.img}
              src={`${process.env.PUBLIC_URL}/assets/splash/welcome_productShot@2x.png`}
            />
          </Grid>
          <Grid item xs className={classes.optionsContainer}>
            <Text strong>
              {t('Clients:CLIENTS_SPLASH_SCREEN.LEADING_TEXT')}
            </Text>
            <Text link mt={2} onClick={addNewClient}>
              {t('Clients:CLIENTS_SPLASH_SCREEN.ADD_NEW_CLIENT')}
            </Text>
            <Text link mt={2} onClick={goToScheduler}>
              {t('Clients:CLIENTS_SPLASH_SCREEN.FIRST_APPOINTMENT')}
            </Text>
            <Text link mt={2} onClick={goToTeamMembers}>
              {t('Clients:CLIENTS_SPLASH_SCREEN.INVITE_TEAM')}
            </Text>
            <Text link mt={2} onClick={goToAvailabilityRules}>
              {t('Clients:CLIENTS_SPLASH_SCREEN.SET_UP_OPEN_HOURS')}
            </Text>
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          className={classes.bottomPanel}
          direction="column"
        >
          <Text>{t('Clients:CLIENTS_SPLASH_SCREEN.HAS_DOUBTS')}</Text>
          <HelpButton
            ComponentProps={{
              color: 'inherit',
              variant: 'extended',
            }}
            className={classes.button}
          >
            {t('Clients:CLIENTS_SPLASH_SCREEN.CHAT_WITH_US')}
          </HelpButton>
        </Grid>
      </Grid>
      <Shadow open />
    </>
  )
}

export default ClientsSplashScreen
