import React from 'react'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ClassesType,
  LanguageUtils,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

import { FindingLocation, StaticFinding } from '~/types'

import FindingsNodeLocation from './FindingsNodeLocation'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      position: 'relative',
    },
    iconButton: {
      padding: 0,
    },
    itemName: {
      paddingTop: theme.spacing(0.5),
      fontSize: '1.4rem',
    },
    simpleItemName: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
    },
    itemNameLink: {
      color: theme.colors.important,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    itemNameDisabled: {
      cursor: 'default',
      color: theme.colors.disabledLabelText,
    },
    simpleFindings: {
      paddingTop: 0,
      color: theme.colors.secondaryText,
    },
    simpleFinding: {
      fontSize: '1.6rem',
    },
  }),
  { name: 'FindingsNode' },
)

export interface FindingsNodeProps {
  className?: string
  classes?: ClassesType<typeof useStyles>
  editDisabled?: boolean
  finding: StaticFinding
  linkDisabled?: boolean
  onDelete?: () => void
  onFindingClick?: () => void
  simpleStyles?: boolean
}

const FindingsNode = ({
  finding,
  className,
  onDelete,
  linkDisabled,
  onFindingClick,
  editDisabled,
  simpleStyles,
  classes: classesProp,
}: FindingsNodeProps) => {
  const classes = useStyles(classesProp)

  const displayName =
    LanguageUtils.getTranslatedFieldName(finding) ||
    LanguageUtils.getTranslatedFieldName(finding, 'fullName')

  // Top level location has an empty 'id' and we need to push it to the top of the list on right rail.
  const sortById = R.sortBy<FindingLocation>(({ id }) => id || '')

  return (
    <div className={classNames(classes.root, className)}>
      <Grid
        container
        alignItems="flex-start"
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Text
          className={classNames({
            [classes.itemName]: !simpleStyles,
            [classes.simpleItemName]: simpleStyles,
            [classes.itemNameDisabled]: linkDisabled,
            [classes.itemNameLink]: Boolean(onFindingClick),
          })}
          variant="body2"
          onClick={linkDisabled || !onFindingClick ? undefined : onFindingClick}
        >
          {displayName}
        </Text>
        {!editDisabled && (
          <IconButton
            className={classes.iconButton}
            size="small"
            onClick={onDelete}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
      {finding?.locations &&
        sortById(Object.values(finding.locations)).map(
          ({ id, notes, dimensions = {} }, index) => (
            <FindingsNodeLocation
              classes={
                simpleStyles
                  ? {
                      findings: classes.simpleFindings,
                      finding: classes.simpleFinding,
                    }
                  : {}
              }
              dimensions={dimensions}
              id={id}
              // eslint-disable-next-line react/no-array-index-key
              key={`${id || 'default_location'} + ${index}`}
              name={LanguageUtils.getTranslatedFieldName(finding)}
              notes={notes}
            />
          ),
        )}
    </div>
  )
}

export default FindingsNode
