import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ButtonWithLoader, ClassesType, PuiTheme } from '@pbt/pbt-ui-components'

import { getClientIsLoading } from '~/store/reducers/clients'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    button: {
      minHeight: 40,
      minWidth: 183,
      whiteSpace: 'break-spaces',
      lineHeight: 'initial',
    },
    appointmentButtonContainer: {
      marginLeft: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(0),
        marginTop: theme.spacing(1),
      },
    },
    closeButton: {
      width: 120,
    },
  }),
  { name: 'ClientDetailsFooter' },
)

export interface ClientDetailsFooterProps {
  addAppointment?: () => void
  classes?: ClassesType<typeof useStyles>
  continueToDetails: () => void
  onClose?: () => void
}

const ClientDetailsFooter = ({
  classes: classesProp,
  continueToDetails,
  addAppointment,
  onClose,
}: ClientDetailsFooterProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Common', 'Clients'])
  const isLoading = useSelector(getClientIsLoading)

  return (
    <Grid container className={classes.root} justifyContent="flex-start">
      <Grid container item md={3} sm={5} xs={12}>
        <ButtonWithLoader
          className={classes.button}
          color="primary"
          disabled={isLoading}
          loading={isLoading}
          onClick={continueToDetails}
        >
          {addAppointment
            ? t('Clients:CLIENT_DETAILS_FOOTER.CONTINUE_DETAILS')
            : t('Clients:CLIENT_DETAILS_FOOTER.ADD_PATIENT_DETAILS_NOW')}
        </ButtonWithLoader>
      </Grid>
      <Grid
        container
        item
        className={classes.appointmentButtonContainer}
        md={3}
        sm={5}
        xs={12}
      >
        {addAppointment ? (
          <ButtonWithLoader
            className={classes.button}
            color="primary"
            disabled={isLoading}
            loading={isLoading}
            onClick={addAppointment}
          >
            {t('Common:ADD_APPOINTMENT')}
          </ButtonWithLoader>
        ) : (
          <ButtonWithLoader
            className={classNames(classes.closeButton, classes.button)}
            color="inverted"
            onClick={onClose}
          >
            {t('Common:CLOSE_ACTION')}
          </ButtonWithLoader>
        )}
      </Grid>
    </Grid>
  )
}

export default ClientDetailsFooter
