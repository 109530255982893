import React from 'react'
import { useSelector } from 'react-redux'
import { TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme } from '@pbt/pbt-ui-components'

import {
  FINANCE_TABLE_CELL_HEIGHT,
  FINANCE_TABLE_PADDING_X_SPACING_VALUE,
} from '~/constants/financeTable'
import { getInvoiceV3SubItemById } from '~/store/reducers/invoiceV3'

import FinanceCompactTotalFooterCell from '../components/FinanceCompactTotalFooterCell'

const useStyles = makeStyles((theme: PuiTheme) => ({
  totalCell: {
    padding: theme.spacing(0, FINANCE_TABLE_PADDING_X_SPACING_VALUE),
    textAlign: 'right',
    height: FINANCE_TABLE_CELL_HEIGHT,
  },
}))

export interface InvoiceTableFooterProps {
  columnsLength: number
  currentItemId: string
}

const InvoiceTableFooter = ({
  currentItemId,
  columnsLength,
}: InvoiceTableFooterProps) => {
  const classes = useStyles()

  const currentItem = useSelector(getInvoiceV3SubItemById(currentItemId))
  return (
    <TableRow>
      <TableCell className={classes.totalCell} colSpan={columnsLength}>
        <FinanceCompactTotalFooterCell
          patientId={currentItem?.patientId}
          subtotal={currentItem?.amount}
        />
      </TableCell>
    </TableRow>
  )
}

export default InvoiceTableFooter
