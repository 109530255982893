import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import {
  DateFormat,
  PermissionArea,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'
import {
  Edit as EditIcon,
  Lock as LockIcon,
} from '@pbt/pbt-ui-components/src/icons'

import DialogNames from '~/constants/DialogNames'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getClientId, getPatientId, getSoapId } from '~/store/reducers/soap'
import { isToday } from '~/utils/time'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      height: '100%',
    },
    block: {
      width: 104,
      padding: theme.spacing(0, 1),
      border: theme.constants.tableBorder,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      borderRadius: 2,
      color: theme.colors.primaryText,
      fontSize: '1.4rem',
      fontWeight: 500,
    },
    dateTimeLabel: {
      color: theme.colors.title,
    },
  }),
  { name: 'VitalsHeaderColumn' },
)

export interface VitalsHeaderColumnProps {
  column: {
    createdAt?: string
    creationDate?: string
    finalized?: boolean
    id: string
    recordedDate?: string
  }
}

const VitalsHeaderColumn = ({ column }: VitalsHeaderColumnProps) => {
  const classes = useStyles()
  const { id, creationDate, createdAt, finalized } = column

  const soapId = useSelector(getSoapId)
  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)

  const { update: vitalsUpdatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.VITAL),
  )

  const [openVitalsDialog] = useDialog(DialogNames.VITALS)

  const editVitalsRecord = (vitalsId: string) => {
    openVitalsDialog({ soapId, vitalsId, clientId, patientId })
  }
  const dateCreated = creationDate || createdAt
  const isTodayDate = isToday(dateCreated)
  const date = moment(dateCreated).format(DateFormat.SHORT_DATE_YEAR_ABBREV)
  const time = moment(dateCreated).format(
    DateFormat.FULL_TIME_WITH_MERIDIAN_AND_SPACE,
  )

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.root}
      wrap="nowrap"
    >
      {isTodayDate ? (
        <Text noWrap strong minWidth={100} variant="lowAccent2">
          {time}
        </Text>
      ) : (
        <Text noWrap strong minWidth={100} variant="lowAccent2">
          {date}
        </Text>
      )}
      {vitalsUpdatePermissions && (
        <IconButton
          aria-label="edit-vitals"
          color="default"
          disabled={!id}
          id={`vitals-data-container-table-head-recordId-${column?.id}-openRecordButton`}
          size="small"
          onClick={() => !finalized && editVitalsRecord(id)}
        >
          {finalized ? (
            <LockIcon fontSize="small" />
          ) : (
            <EditIcon fontSize="small" />
          )}
        </IconButton>
      )}
    </Grid>
  )
}

export default VitalsHeaderColumn
