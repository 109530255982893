import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PermissionArea, PuiTheme } from '@pbt/pbt-ui-components'

import Attachments from '~/components/common/attachments/Attachments'
import PuiTabs, { PuiTab } from '~/components/common/PuiTabs'
import RightRail, { RightRailProps } from '~/components/common/RightRail'
import FeatureToggle from '~/constants/featureToggle'
import { MarketplaceWorkflowNames } from '~/constants/marketplaceConstants'
import i18n from '~/locales/i18n'
import { resetTimeline } from '~/store/actions/timeline'
import { fetchMarketplaceIFrames } from '~/store/duck/marketplace'
import { useGetWorkflowIFrames } from '~/store/hooks/marketplace'
import { getCRUDByArea, getCurrentBusinessId } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { SoapTemplateTab } from '~/types'

import MarketplaceWidget from '../../soapV2/marketplace/MarketplaceWidget'
import { SoapV1StepsUrl } from '../../soapV2/utils/useSoapPageRedirect'
import ChatTabComponent from './chat/ChatTabComponent'
import NotesTab from './NotesTab'
import Summary from './summary/Summary'
import Timeline from './Timeline'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    content: {
      overflowY: 'auto',
      overflowX: 'clip',
      flex: 1,
    },
    contentPadding: {
      paddingTop: theme.spacing(2),
    },
    tabRoot: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    smallTabRoot: {
      fontSize: '1rem',
      minWidth: 'unset',
    },
  }),
  { name: 'SoapRail' },
)

interface SoapRailTab extends PuiTab {
  component: React.JSXElementConstructor<any>
  label: string
  noContentPadding?: boolean
}

const SUMMARY_TAB: SoapRailTab = {
  id: 0,
  label: i18n.t('Common:SUMMARY'),
  component: Summary,
  noContentPadding: true,
}

const NOTES_TAB: SoapRailTab = {
  id: 1,
  label: i18n.t('Common:NOTES'),
  component: NotesTab,
}

const TIMELINE_TAB: SoapRailTab = {
  id: 2,
  label: i18n.t('Common:TIMELINE'),
  component: Timeline,
}

const ATTACHMENTS_TAB: SoapRailTab = {
  id: 3,
  label: i18n.t('Common:ATTACHMENTS'),
  component: Attachments,
}

const CHAT_TAB: SoapRailTab = {
  id: 4,
  label: i18n.t('Common:CHAT_NOUN'),
  component: ChatTabComponent,
  noContentPadding: true,
}

const MARKETPLACE_TAB: SoapRailTab = {
  id: 5,
  label: i18n.t('Common:MARKETPLACE'),
  component: MarketplaceWidget,
}

const tabs = [SUMMARY_TAB, NOTES_TAB, TIMELINE_TAB, ATTACHMENTS_TAB, CHAT_TAB]

export interface SoapRailProps extends RightRailProps {
  editDisabled?: boolean
  expanded?: boolean
  redirectTo?: ((step: SoapV1StepsUrl) => string) | null
  selectedTab?: number
  setExpanded: (expanded: boolean) => void
  setSelectedTab: (tab: number) => void
  soapTabs?: SoapTemplateTab[]
}

const SoapRail = ({
  editDisabled,
  expanded = false,
  redirectTo,
  setExpanded,
  selectedTab = 0,
  setSelectedTab,
  soapTabs,
  ...rest
}: SoapRailProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const businessId = useSelector(getCurrentBusinessId)

  const iFrames = useGetWorkflowIFrames(MarketplaceWorkflowNames.SOAP)
  const showMarketplace = iFrames?.length > 0
  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )
  const soapPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))

  const [prevSelectedTab, setPrevSelectedTab] = useState(selectedTab)

  useEffect(() => {
    if (businessId) {
      dispatch(fetchMarketplaceIFrames())
    }
  }, [businessId])

  const filteredTabs = tabs.filter(
    (soapRailTab: SoapRailTab) =>
      !isCvcRolesEnabled || soapPermissions.update || soapRailTab.id !== 1,
  )
  const enabledTabs = showMarketplace
    ? [...filteredTabs, MARKETPLACE_TAB]
    : filteredTabs

  const selectedTabObject = enabledTabs[selectedTab]
  const Component = selectedTabObject?.component
  const noContentPadding = selectedTabObject?.noContentPadding

  useEffect(
    () => () => {
      // reset timeline store state on unmount
      if (selectedTab === TIMELINE_TAB.id) {
        dispatch(resetTimeline())
      }
    },
    [],
  )

  useEffect(() => {
    // reset timeline store state on timeline tab unmount
    if (
      prevSelectedTab === TIMELINE_TAB.id &&
      selectedTab !== TIMELINE_TAB.id
    ) {
      dispatch(resetTimeline())
    }

    setPrevSelectedTab(selectedTab)
  }, [selectedTab])

  const onRailExpandedChange = () => {
    setExpanded(!expanded)
  }

  return (
    <RightRail
      expandable
      hideForAnyScreen
      expanded={expanded}
      onToggleExpandStateButtonClick={onRailExpandedChange}
      {...rest}
    >
      <PuiTabs
        classes={{
          tabRoot: classNames(classes.tabRoot, {
            [classes.smallTabRoot]: showMarketplace,
          }),
        }}
        selectedTab={selectedTab}
        tabs={enabledTabs}
        variant="standard"
        onSelectedTabChange={setSelectedTab}
      />
      <Grid
        container
        item
        className={classNames(classes.content, {
          [classes.contentPadding]: !noContentPadding,
        })}
      >
        {Component && (
          <Component
            editDisabled={editDisabled}
            expanded={expanded}
            redirectTo={redirectTo}
            setSelectedTab={setSelectedTab}
            soapTabs={soapTabs}
          />
        )}
      </Grid>
    </RightRail>
  )
}

export default SoapRail
