import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    registerLink: {
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'RegisterButton' },
)

const RegisterButton = () => {
  const classes = useStyles()
  const { t } = useTranslation(['Auth', 'Common'])

  return (
    <Grid item>
      <Text align="center" mt={3} variant="body2">
        {t('Auth:LOGIN_PAGE.DO_NOT_HAVE_AN_ACCOUNT')}&nbsp;
        <Link
          className={classes.registerLink}
          href="mailto:sales@rhapsody.vet"
          underline="always"
        >
          {t('Common:CONTACT_US')}
        </Link>
      </Text>
    </Grid>
  )
}

export default RegisterButton
