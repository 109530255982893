import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Grid,
  GridDirection,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  AppointmentEventType,
  ClassesType,
  DateUtils,
  EventTypeName,
  HtmlNotesPreview,
  LanguageUtils,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import ItemHistoryLabel from '~/components/common/labels/ItemHistoryLabel'
import { getRolesMap } from '~/store/reducers/roles'
import { getSpaceName } from '~/store/reducers/spaces'
import { TimetableEvent } from '~/types'
import {
  getDisplayNameFromAppointmentType,
  useEventType,
} from '~/utils/useEventType'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    titleContainer: {
      padding: theme.spacing(2, 1.5),
      borderRight: theme.constants.tabBorder,
    },
    titleContainerColumn: {
      borderRight: 'none',
    },
    title: {},
    contentContainer: {
      padding: theme.spacing(1, 2, 3, 1),
    },
    table: {
      border: theme.constants.tabBorder,
      borderRadius: 2,
    },
    tableRow: {
      height: 40,
      border: theme.constants.tabBorder,
    },
    titleCell: {
      width: 130,
    },
    tableCell: {
      padding: theme.spacing(1, 2),
      border: theme.constants.tabBorder,
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
    },
    meetingLink: {
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'AppointmentLocked' },
)

export interface AppointmentLockedProps {
  appointment: TimetableEvent
  classes: ClassesType<typeof useStyles>
  direction: GridDirection
  showHistoryLabel: boolean
}

const AppointmentLocked = ({
  classes: classesProp,
  showHistoryLabel = true,
  direction = 'row',
  appointment,
}: AppointmentLockedProps) => {
  const classes = useStyles({ classes: classesProp })

  const spaceName = useSelector(getSpaceName(appointment.assignedSpace))
  const rolesMap = useSelector(getRolesMap)

  const { t } = useTranslation('Common')

  const AppointmentSubTypes: AppointmentEventType['subTypes'] = useEventType(
    EventTypeName.Appointment,
    'subTypes',
  )
  const appointmentTypeDisplayName = getDisplayNameFromAppointmentType(
    appointment?.type?.name,
    AppointmentSubTypes,
  )

  const isColumn = direction === 'column'

  const shouldUseResponsibilities =
    appointment.personResponsibilities &&
    appointment.personResponsibilities.length > 0

  return (
    <Grid
      container
      item
      className={classes.root}
      direction={direction}
      wrap="nowrap"
    >
      <Grid
        item
        className={classNames(classes.titleContainer, {
          [classes.titleContainerColumn]: isColumn,
        })}
        xs={isColumn ? 'auto' : 2}
      >
        <Text className={classes.title} variant="h4">
          {`${appointmentTypeDisplayName} ${DateUtils.formatDate(
            appointment.scheduledStartDatetime,
          )}`}
        </Text>
        {showHistoryLabel && <ItemHistoryLabel vertical item={appointment} />}
      </Grid>
      <Grid container item xs className={classes.contentContainer}>
        <Table className={classes.table}>
          <TableBody>
            {shouldUseResponsibilities
              ? appointment.personResponsibilities?.map(
                  (personResponsibility) => {
                    const displayRoleName =
                      LanguageUtils.getTranslatedFieldName(
                        rolesMap[personResponsibility.responsibilityId],
                      )

                    return (
                      <TableRow
                        className={classes.tableRow}
                        key={personResponsibility.responsibilityId}
                      >
                        <TableCell
                          className={classNames(
                            classes.tableCell,
                            classes.titleCell,
                          )}
                        >
                          {displayRoleName}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {Utils.getPersonString(personResponsibility.person)}
                        </TableCell>
                      </TableRow>
                    )
                  },
                )
              : appointment.personRoles?.map((personRole) => {
                  const displayRoleName = LanguageUtils.getTranslatedFieldName(
                    rolesMap[personRole.roleId],
                  )

                  return (
                    <TableRow
                      className={classes.tableRow}
                      key={personRole.roleId}
                    >
                      <TableCell
                        className={classNames(
                          classes.tableCell,
                          classes.titleCell,
                        )}
                      >
                        {displayRoleName}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {Utils.getPersonString(personRole.person)}
                      </TableCell>
                    </TableRow>
                  )
                })}
            {Boolean(appointment.meetingLink || appointment.dialIn) && (
              <TableRow className={classes.tableRow}>
                <TableCell
                  className={classNames(classes.tableCell, classes.titleCell)}
                >
                  {t('Common:CONFERENCING')}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {Boolean(appointment.meetingLink) && (
                    <Link
                      className={classes.meetingLink}
                      href={appointment.meetingLink!}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {appointment.meetingLink}
                    </Link>
                  )}
                  {appointment.dialIn}
                </TableCell>
              </TableRow>
            )}
            {Boolean(appointment.clientInstructions) && (
              <TableRow className={classes.tableRow}>
                <TableCell
                  className={classNames(classes.tableCell, classes.titleCell)}
                >
                  {t('Common:ADDITIONAL_INFO')}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {appointment.clientInstructions && (
                    <HtmlNotesPreview notes={appointment.clientInstructions} />
                  )}
                </TableCell>
              </TableRow>
            )}
            {spaceName && Boolean(appointment.assignedSpace) && (
              <TableRow className={classes.tableRow}>
                <TableCell
                  className={classNames(classes.tableCell, classes.titleCell)}
                >
                  {t('Common:SPACE_ASSIGNED')}
                </TableCell>
                <TableCell className={classes.tableCell}>{spaceName}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

export default AppointmentLocked
