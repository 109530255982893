import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { LanguageUtils, PuiTheme, Text } from '@pbt/pbt-ui-components'

import { vitalsTableConfig } from '~/components/dashboard/soapV2/vitals/config/vitalsTableConfig'
import { Vital } from '~/types'

import VitalValueLabel from './VitalValueLabel'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tableRow: {
      height: theme.spacing(6),
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
      '&:first-of-type': {
        borderTop: theme.constants.tableBorder,
      },
    },
    cell: {
      borderBottom: 'none',
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
      padding: theme.spacing(1, 2),
    },
    headerCell: {
      borderRight: theme.constants.tableBorder,
      width: 150,
      fontWeight: 500,
    },
  }),
  { name: 'OneVital' },
)

export interface OneVitalProps {
  vital: Vital
}

const OneVital = ({ vital }: OneVitalProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const configWithValues = vitalsTableConfig.filter(
    ({ id }) => !R.isNil(vital[id]),
  )

  return (
    <Table>
      <TableBody>
        {configWithValues.map((configItem) => {
          const { value, notes } = vital[configItem.id] || {}
          return !R.isNil(value) || notes ? (
            <TableRow className={classes.tableRow} key={configItem.id}>
              <TableCell
                className={classNames(classes.cell, classes.headerCell)}
              >
                {LanguageUtils.getTranslatedFieldName(configItem)}
              </TableCell>
              <TableCell className={classes.cell}>
                <VitalValueLabel vital={vital} vitalConfigItem={configItem} />
                {notes && (
                  <Text>
                    {t('Common:NOTES')}: {notes}
                  </Text>
                )}
              </TableCell>
            </TableRow>
          ) : null
        })}
      </TableBody>
    </Table>
  )
}
export default OneVital
