import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
  PuiTextField,
  PuiTheme,
  Text,
  UnsavedWellnessPlan,
  useFields,
  Utils,
  WellnessPlan,
  WellnessPlanPrice,
} from '@pbt/pbt-ui-components'

import {
  getWellnessPlanPriceType,
  getWellnessPlanType,
} from '~/store/reducers/constants'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 450,
      maxWidth: 450,
    },
    dialogContentRoot: {
      padding: theme.spacing(2, 2, 3),
    },
    input: {
      maxWidth: 240,
    },
    button: {
      width: 150,
    },
  }),
  { name: 'WellnessPlanExtraDialog' },
)

export interface WellnessPlanExtraDialogProps extends BasePuiDialogProps {
  extra?: WellnessPlan
  onProceed: (extra: UnsavedWellnessPlan) => void
}

const WellnessPlanExtraDialog = ({
  open,
  extra,
  onClose,
  onProceed,
}: WellnessPlanExtraDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'WellnessPlans'])

  const WellnessPlanType = useSelector(getWellnessPlanType)
  const WellnessPlanPriceType = useSelector(getWellnessPlanPriceType)

  const ExtraTypeId = Utils.findConstantIdByName('Extra', WellnessPlanType)
  const MonthlyPriceTypeId = Utils.findConstantIdByName(
    'Monthly',
    WellnessPlanPriceType,
  )

  const isEdit = Boolean(extra)

  const {
    fields: { name },
    validate,
    reset,
  } = useFields(
    [
      {
        name: 'name',
        label: t('Common:EXTRA_NAME'),
        validators: ['required'],
        initialValue: extra?.name || '',
      },
    ],
    false,
  )

  useEffect(() => {
    reset()
  }, [extra])

  const handleSave = () => {
    if (validate()) {
      const newExtra = {
        ...extra,
        name: name.value,
      }

      if (!isEdit) {
        newExtra.price = 0
        newExtra.prices = [
          {
            priceTypeId: MonthlyPriceTypeId,
            price: 0,
          } as WellnessPlanPrice,
        ]
        newExtra.planTypeId = ExtraTypeId
        newExtra.priceTypeId = MonthlyPriceTypeId
      }

      onProceed(newExtra as UnsavedWellnessPlan)

      if (onClose) {
        onClose()
      }
    }
  }

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader
          className={classes.button}
          type="submit"
          onClick={handleSave}
        >
          {extra ? t('Common:SAVE_ACTION') : t('Common:ADD_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="wellness-plan-extra-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={extra ? t('Common:EDIT_EXTRA') : t('Common:ADD_EXTRA')}
      onClose={onClose}
    >
      <Text variant="body2">
        {t('WellnessPlans:PATIENTS_CAN_BE_ENROLLED_IN_ANY_NUMBER_OF_EXTRAS')}
      </Text>
      <PuiTextField
        fullWidth
        className={classes.input}
        field={name}
        inputProps={{ maxLength: 100 }}
        label={`${name.label}*`}
      />
    </PuiDialog>
  )
}

export default WellnessPlanExtraDialog
