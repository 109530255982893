import React, {
  BaseSyntheticEvent,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react'
import { Virtuoso } from 'react-virtuoso'
import { KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material'
import {
  BaseTextFieldProps,
  Box,
  CircularProgress,
  InputAdornment,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ClassesType,
  Field,
  Nil,
  PuiPopper,
  PuiTextField,
  PuiTextFieldProps,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import { isArrowKey } from '~/utils'

import NoSearchResults from '../lists/NoSearchResults'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    searchIcon: {
      color: theme.colors.disabledLabelText,
    },
    spinner: {
      color: theme.colors.disabledLabelText,
      marginRight: 4,
    },
    paper: {
      padding: theme.spacing(0.5, 0),
    },
    searchInput: {
      width: '100%',
    },
    item: {
      cursor: 'pointer',
      height: 'auto',
      borderRadius: 2,
      padding: '4px 12px',
      border: 'none',
      '&&:hover, &&:focus': {
        color: '#3C3838 !important',
        backgroundColor: '#E5E5E5',
        boxShadow: theme.constants.selectItemSelectedShadow,
        transition: 'none',
      },
    },
    arrowIcon: {},
  }),
  { name: 'SelectionField' },
)

export interface SelectionFieldProps {
  anchorElement?: JSX.Element
  children: (
    item: any,
    subItem?: any,
    searchQuery?: string | Nil,
  ) => JSX.Element
  classes?: ClassesType<typeof useStyles>
  closeOnClickWhenOpen?: boolean
  closeOnItemClick?: boolean
  disableCloseOnEnter?: boolean
  field?: Field
  formatted?: boolean
  hideSearchIcon?: boolean
  isLoading?: boolean
  itemSize?: number
  label?: string
  list: any[]
  margin?: PuiTextFieldProps['margin']
  onEnterPressed?: () => void
  onItemClick?: ((...args: any[]) => void) | Nil
  onQueryChange?: ((value: string) => void) | Nil
  searchQuery?: string | Nil
  showArrow?: boolean
  subItemField?: any
}

const SelectionField = ({
  classes: classesProp,
  disableCloseOnEnter,
  children,
  searchQuery,
  onQueryChange: initialOnQueryChange = null,
  onEnterPressed,
  list: listProp,
  isLoading = false,
  onItemClick: onItemClickProp = null,
  label,
  subItemField,
  closeOnItemClick = false,
  closeOnClickWhenOpen = false,
  anchorElement: anchorElementProp,
  showArrow,
  hideSearchIcon,
  itemSize = 32,
  field,
  formatted = false,
  margin = 'normal',
}: SelectionFieldProps) => {
  const list = listProp || []
  const onQueryChange = field?.setValue ?? initialOnQueryChange

  const classes = useStyles({ classes: classesProp })
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null)
  const [open, setOpen] = useState(false)
  const [wasLoading, setWasLoading] = useState(false)

  useEffect(() => {
    if (wasLoading) {
      setOpen(true)
    }
    setWasLoading(isLoading)
  }, [list, isLoading])

  const handleClose = (event?: BaseSyntheticEvent) => {
    if (event && anchorEl && anchorEl.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleClick: MouseEventHandler<HTMLInputElement> = ({
    currentTarget,
  }) => {
    setAnchorEl(currentTarget)
    if (closeOnClickWhenOpen && open) {
      handleClose()
    } else if (typeof searchQuery !== 'string' || searchQuery) {
      setOpen(true)
    }
  }

  const onItemClick = (...args: any[]) => {
    if (onItemClickProp) {
      onItemClickProp(...args)
    }
    if (closeOnItemClick) {
      handleClose()
    }
  }

  const handleChange = (event: BaseSyntheticEvent) => {
    setOpen(false)
    if (onQueryChange) {
      onQueryChange(event.target.value)
    }
  }

  const Icon = open ? KeyboardArrowUp : KeyboardArrowDown

  const itemsList = formatted
    ? list
    : R.pipe(
        R.map((item: any) => {
          const subList: any[] = [{ item }]
          if (subItemField && item[subItemField].length > 0) {
            const [firstChild, ...restChildren] = item[subItemField]
            subList[0].subItem = firstChild
            subList.push(...restChildren.map((subItem: any) => ({ subItem })))
          }
          return subList
        }),
        R.flatten,
      )(list)

  const itemsRefs = Array.from({ length: itemsList.length }, () =>
    React.createRef<HTMLDivElement>(),
  )

  const doFocus = (index: number) => {
    const ref = itemsRefs[index]
    if (ref.current) {
      ref.current.focus()
    }
  }

  const onKeyDown = (
    event: KeyboardEvent,
    isItem: boolean,
    { item, subItem, id, subItemId }: any = {},
  ) => {
    if (event.key === 'Enter') {
      if (isItem) {
        onItemClick(item, subItem, id, subItemId)
      } else {
        if (onEnterPressed) {
          onEnterPressed()
        }
        if (!disableCloseOnEnter) {
          handleClose()
        }
      }
      return
    }
    if (isArrowKey(event.key)) {
      event.preventDefault()
      const focusedIndex = itemsRefs.findIndex(
        (ref) => ref.current && ref.current === document.activeElement,
      )
      if (event.key === 'ArrowDown') {
        if (focusedIndex < itemsRefs.length - 1) {
          doFocus(focusedIndex + 1)
        }
      }
      if (event.key === 'ArrowUp') {
        if (focusedIndex > 0) {
          doFocus(focusedIndex - 1)
        }
      }
    }
  }

  const onTextFieldFocus: BaseTextFieldProps['onFocus'] = ({
    currentTarget,
  }) => {
    if (list.length > 0) {
      setAnchorEl(currentTarget as HTMLInputElement)
      setOpen(true)
    }
  }

  const anchorElement =
    anchorElementProp && React.isValidElement(anchorElementProp) ? (
      React.cloneElement(anchorElementProp as JSX.Element, {
        onClick: handleClick,
        onKeyDown,
        tabIndex: '0',
      })
    ) : (
      <PuiTextField
        aria-haspopup
        InputProps={{
          endAdornment: !hideSearchIcon && (
            <InputAdornment className={classes.searchIcon} position="end">
              {isLoading && (
                <CircularProgress className={classes.spinner} size={16} />
              )}
              <Search />
            </InputAdornment>
          ),
        }}
        aria-owns={open ? 'menu-list-grow' : undefined}
        autoComplete="off"
        className={classes.searchInput}
        field={{ ...field, value: searchQuery, set: handleChange } as Field}
        id="search"
        label={label}
        margin={margin}
        onClick={handleClick}
        onFocus={onTextFieldFocus}
        onKeyDown={onKeyDown as KeyboardEventHandler}
      />
    )

  return (
    <>
      {anchorElement}
      {showArrow && <Icon className={classes.arrowIcon} />}
      <PuiPopper
        hideCloseButton
        anchorEl={anchorEl}
        classes={{
          paper: classes.paper,
        }}
        modifiers={[
          {
            name: 'flip',
            enabled: false,
          },
          {
            name: 'hide',
            enabled: false,
          },
          {
            name: 'preventOverflow',
            enabled: false,
          },
        ]}
        open={open}
        placement="bottom"
        style={{ width: anchorEl ? anchorEl.offsetWidth : 0 }}
        onClose={handleClose}
      >
        {itemsList.length > 0 ? (
          <Virtuoso
            data={itemsList}
            /* eslint-disable-next-line react/no-unstable-nested-components */
            itemContent={(index, { item, subItem, id, subItemId }) => {
              const currentItem = item || subItem
              return (
                <Box
                  className={classes.item}
                  key={currentItem.id || currentItem}
                  ref={itemsRefs[index]}
                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                  tabIndex={1}
                  onClick={() => onItemClick(item, subItem, id, subItemId)}
                  onKeyDown={(event) =>
                    onKeyDown(event, true, itemsList[index])
                  }
                >
                  {children(item, subItem, searchQuery)}
                </Box>
              )
            }}
            style={{
              height:
                (itemsList.length > 10 ? 10 : itemsList.length) * itemSize,
            }}
          />
        ) : (
          <NoSearchResults query={searchQuery || ''} />
        )}
      </PuiPopper>
    </>
  )
}

export default SelectionField
