import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Fab, Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PermissionArea, PuiTheme } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { addSearch } from '~/utils'
import useDialog from '~/utils/useDialog'

// @ts-ignore
import BundleDetails from './BundleDetails'
import BundlesTableComponent from './BundlesTableComponent'

const useStyles = makeStyles(
  () => ({
    button: {
      minWidth: 132,
      height: 40,
    },
  }),
  { name: 'BundlesPage' },
)

const BundlesPage = () => {
  const navigate = useNavigate()
  const { bundleId } = useParams()
  const location = useLocation()
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )
  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )
  const permissions = useSelector(getCRUDByArea(PermissionArea.BUNDLE))
  const { t } = useTranslation('Common')

  const [openNewBundleDialog] = useDialog(DialogNames.BUNDLE)

  const onDetailsClose = () => {
    navigate(addSearch(location, '/admin/catalog/bundles'))
  }

  if (isMobile && typeof bundleId !== 'undefined') {
    return <BundleDetails itemId={bundleId} onClose={onDetailsClose} />
  }

  const headerButtons = (!isCvcRolesEnabled || permissions.create) && (
    <Grid container item justifyContent="flex-end" px={3} py={2}>
      <Fab
        className={classes.button}
        color="inherit"
        variant="extended"
        onClick={() => openNewBundleDialog()}
      >
        {t('Common:ADD_BUNDLE')}
      </Fab>
    </Grid>
  )

  return (
    <Grid container item flex={1} wrap="nowrap">
      <BundlesTableComponent
        bundleId={bundleId}
        headerButtons={headerButtons}
        onDetailsClose={onDetailsClose}
      />
    </Grid>
  )
}

export default BundlesPage
