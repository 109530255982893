import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DocumentFile, PuiTheme, Text, Utils } from '@pbt/pbt-ui-components'

import AttachmentSoap from '~/components/common/inputs/attachment/AttachmentSoap'
import DialogNames from '~/constants/DialogNames'
import DocumentDialogStates from '~/constants/DocumentDialogStates'
import {
  fetchRabiesVaccinations,
  fetchVaccinations,
} from '~/store/duck/vaccination'
import { getSoapAttachmentType } from '~/store/reducers/constants'
import { getSelectedOrders } from '~/store/reducers/orders'
import {
  getFiles,
  getPatientId,
  getSoapBusinessId,
  getSoapId,
} from '~/store/reducers/soap'
import { Document, SoapFile } from '~/types'
import useDialog from '~/utils/useDialog'

import SoapWidget from '../SoapWidget'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    content: {
      borderTop: theme.constants.tableBorder,
    },
    itemRow: {
      padding: theme.spacing(0, 1, 0, 2),
    },
  }),
  { name: 'AttachmentsWidgetLocked' },
)

const AttachmentsWidgetLocked = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Soap'])

  const files = useSelector(getFiles)
  const soapAttachmentType = useSelector(getSoapAttachmentType)
  const soapId = useSelector(getSoapId)
  const soapBusinessId = useSelector(getSoapBusinessId)
  const patientId = useSelector(getPatientId)
  const selectedOrders = useSelector(getSelectedOrders)

  const generalAttachmentTypeId = Utils.findConstantIdByName(
    'General',
    soapAttachmentType,
  )

  const [openDocumentDialog] = useDialog(DialogNames.DOCUMENT)
  const [openVaccineCertificatePreviewDialog] = useDialog(
    DialogNames.VACCINE_CERTIFICATE_PREVIEW,
  )

  useEffect(() => {
    if (soapId && patientId) {
      dispatch(fetchVaccinations(patientId, soapId))
      dispatch(fetchRabiesVaccinations(patientId, soapId))
    }
  }, [soapId, patientId, selectedOrders.length])

  const onPreviewRequested = (file: DocumentFile['file']) => {
    if (file.typeId === generalAttachmentTypeId) {
      openDocumentDialog({
        previewOnly: true,
        document: file as Document,
        step: DocumentDialogStates.PREVIEW,
        disableCloseAfterUpdate: true,
        PreviewProps: {
          hideTopButtons: true,
          view: true,
          isSOAP: true,
          showChangeFile: false,
        },
      })
    } else {
      openVaccineCertificatePreviewDialog({
        soapBusinessId,
        soapId,
        patientId,
        document: file as SoapFile,
      })
    }
  }

  return (
    <SoapWidget title={t('Common:ATTACHMENTS')}>
      <Grid container item className={classes.content} direction="column">
        {files.length > 0 ? (
          files.map((file) => (
            <AttachmentSoap
              hideDelete
              classes={{
                itemRow: classes.itemRow,
              }}
              file={file}
              key={file.id}
              onPreviewRequested={onPreviewRequested}
            />
          ))
        ) : (
          <Text p={2}>{t('Soap:APPOINTMENT_ATTACHMENTS.NO_ATTACHMENTS')}</Text>
        )}
      </Grid>
    </SoapWidget>
  )
}

export default AttachmentsWidgetLocked
