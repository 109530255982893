import { Environment } from '@pbt/pbt-ui-components/src/constants/environment'

export const ENVIRONMENT_VARIABLES = {
  [Environment.PROD]: {
    ANALYTICS: {
      AUTH0_DOMAIN: 'auth.rhapsody.vet',
      AUTH0_CLIENT_ID: '6ZMHOcwFhnAebbY2fSe08muz16M4daft', // "Rhapsody Analytics"
    },
    GOOGLE_API_KEY: 'AIzaSyAdE6j0k3_Y9uT53pGc-W2lM7V6u20-f-w',
    GOOGLE_ANALYTICS_KEY: 'GTM-PGXLH65',
    NVA: {
      AUTH0_DOMAIN: 'auth.rhapsody.vet',
      AUTH0_CLIENT_ID: 'XvyovpbpoWwamKB5V7CmBJPF7GlAbosA', // "NVA"
    },
    PORTAL: {
      AUTH0_DOMAIN: 'auth.rhapsody.vet',
      AUTH0_CLIENT_ID: 'epYFNwr6JaEu7a4azeqJgnPAbPCjAJk4', // "Rhapsody Portal"
    },
    PIMS: {
      AUTH0_DOMAIN: 'auth.rhapsody.vet',
      AUTH0_CLIENT_ID: 'x42qBbeOtwR6dF3z67qD8IFyBGdrg1EA', // "Rhapsody PIMS"
    },
    BENCHMARKING: {
      AUTH0_DOMAIN: 'auth.rhapsody.vet',
      AUTH0_CLIENT_ID: 'YFif2NWvubNipsc4FKzCc2rHL7cQhy9i', // "Rhapsody Benchmarking"
    },
  },
  [Environment.MASTER]: {
    ANALYTICS: {
      AUTH0_DOMAIN: 'auth.rhapsody.vet',
      AUTH0_CLIENT_ID: '8aSqP9W2C5WY3P2OwHqKda0TgPwwoSvK', // "Rhapsody Analytics Master"
    },
    NVA: {
      AUTH0_DOMAIN: 'auth.rhapsody.vet',
      AUTH0_CLIENT_ID: 'cm7MZ2Nn0MflBA4rXVg3gmVVRdTXnXCf', // "NVA Petabyte App"
    },
    PORTAL: {
      AUTH0_DOMAIN: 'auth.rhapsody.vet',
      AUTH0_CLIENT_ID: '5YN8mJ8hrtfr2uj1GwWgLjrFMJ3X764x', // "Rhapsody Portal Master"
    },
    PIMS: {
      AUTH0_DOMAIN: 'auth.rhapsody.vet',
      AUTH0_CLIENT_ID: 'Y22kSjsyE6tevusEK8huIHeC9BQAv1nu', // "Rhapsody PIMS Master"
    },
    BENCHMARKING: {
      AUTH0_DOMAIN: 'auth.rhapsody.vet',
      AUTH0_CLIENT_ID: 'tiKNGzxiEvf9B1phWMaYjjJcLB07zgLS', // "Rhapsody Benchmarking Master"
    },
  },
  [Environment.STAGE]: {
    ANALYTICS: {
      AUTH0_DOMAIN: 'auth.stage.rhapsody.vet',
      AUTH0_CLIENT_ID: 'lgPwvAgV1A4JXfWnR5APR1xfOn5z54MH', // "Rhapsody Analytics Stage"
    },
    GOOGLE_API_KEY: 'AIzaSyAdE6j0k3_Y9uT53pGc-W2lM7V6u20-f-w',
    NVA: {
      AUTH0_DOMAIN: 'auth.stage.rhapsody.vet',
      AUTH0_CLIENT_ID: 'cm7MZ2Nn0MflBA4rXVg3gmVVRdTXnXCf', // "NVA Petabyte App"
    },
    PORTAL: {
      AUTH0_DOMAIN: 'auth.stage.rhapsody.vet',
      AUTH0_CLIENT_ID: '9IS7mnfQ83dWOSjAkizl4k13ttEocJFj', // "Rhapsody Portal Stage"
    },
    PIMS: {
      AUTH0_DOMAIN: 'auth.stage.rhapsody.vet',
      AUTH0_CLIENT_ID: 'OxxIzLIvcgoAunJgeFrJfPoy23ZsIoEd', // "Rhapsody PIMS Stage"
    },
    BENCHMARKING: {
      AUTH0_DOMAIN: 'auth.stage.rhapsody.vet',
      AUTH0_CLIENT_ID: 'ZHIDerAet6gJ8GAelcf8rPUKoxZ3hriU', // "Rhapsody Benchmarking Stage"
    },
  },
  [Environment.DEV]: {
    ANALYTICS: {
      AUTH0_DOMAIN: 'auth.stage.rhapsody.vet',
      AUTH0_CLIENT_ID: 'hEkX54yv45X6AfYFDBWnUUnGB2wH24Do', // "Rhapsody Dev"
    },
    GOOGLE_API_KEY: 'AIzaSyAdE6j0k3_Y9uT53pGc-W2lM7V6u20-f-w',
    NVA: {
      AUTH0_DOMAIN: 'auth.stage.rhapsody.vet',
      AUTH0_CLIENT_ID: 'cm7MZ2Nn0MflBA4rXVg3gmVVRdTXnXCf', // "NVA Petabyte App"
    },
    PORTAL: {
      AUTH0_DOMAIN: 'auth.stage.rhapsody.vet',
      AUTH0_CLIENT_ID: 'hEkX54yv45X6AfYFDBWnUUnGB2wH24Do', // "Rhapsody Dev"
    },
    PIMS: {
      AUTH0_DOMAIN: 'auth.stage.rhapsody.vet',
      AUTH0_CLIENT_ID: 'hEkX54yv45X6AfYFDBWnUUnGB2wH24Do', // "Rhapsody Dev"
    },
    BENCHMARKING: {
      AUTH0_DOMAIN: 'auth.stage.rhapsody.vet',
      AUTH0_CLIENT_ID: 'hEkX54yv45X6AfYFDBWnUUnGB2wH24Do', // "Rhapsody Dev"
    },
  },
  [Environment.LOCAL]: {
    ANALYTICS: {
      AUTH0_DOMAIN: 'auth.stage.rhapsody.vet',
      AUTH0_CLIENT_ID: 'lgPwvAgV1A4JXfWnR5APR1xfOn5z54MH', // "Rhapsody Analytics Stage"
    },
    GOOGLE_API_KEY: 'AIzaSyAdE6j0k3_Y9uT53pGc-W2lM7V6u20-f-w',
    NVA: {
      AUTH0_DOMAIN: 'auth.stage.rhapsody.vet',
      AUTH0_CLIENT_ID: 'cm7MZ2Nn0MflBA4rXVg3gmVVRdTXnXCf', // "NVA Petabyte App"
    },
    PORTAL: {
      AUTH0_DOMAIN: 'auth.stage.rhapsody.vet',
      AUTH0_CLIENT_ID: '9IS7mnfQ83dWOSjAkizl4k13ttEocJFj', // "Rhapsody Portal Stage"
    },
    PIMS: {
      AUTH0_DOMAIN: 'auth.stage.rhapsody.vet',
      AUTH0_CLIENT_ID: 'OxxIzLIvcgoAunJgeFrJfPoy23ZsIoEd', // "Rhapsody PIMS Stage"
    },
    BENCHMARKING: {
      AUTH0_DOMAIN: 'auth.stage.rhapsody.vet',
      AUTH0_CLIENT_ID: 'ZHIDerAet6gJ8GAelcf8rPUKoxZ3hriU', // "Rhapsody Benchmarking Stage"
    },
  },
}
