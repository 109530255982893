import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  PhoneUtils,
  PuiTextField,
  PuiTheme,
  useFields,
  ValidateOptions,
} from '@pbt/pbt-ui-components'

import PhoneInput from '~/components/common/form-inputs/PhoneInput'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import DialogNames from '~/constants/DialogNames'
import { clearCurrentClientInfo, createClient } from '~/store/actions/clients'
import {
  getClientIsLoading,
  getClientsError,
  getClientsErrorStatus,
  getCurrentClientId,
} from '~/store/reducers/clients'
import { getCurrentPatientId } from '~/store/reducers/patients'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  { name: 'CreateClient' },
)

type ProceedHandler = (toScheduler?: boolean, force?: boolean) => void

export interface CreateClientHandle {
  proceed: ProceedHandler
  validate: (options?: ValidateOptions) => boolean
}

interface CreateClientProps {
  className?: string
  onOk?: () => void
}

const CreateClient = forwardRef<CreateClientHandle, CreateClientProps>(
  function CreateClient({ className, onOk }, ref) {
    const navigate = useNavigate()
    const classes = useStyles()
    const dispatch = useDispatch()
    const { t } = useTranslation(['Common', 'Validations'])
    const isMobile = useMediaQuery((theme: PuiTheme) =>
      theme.breakpoints.down('sm'),
    )

    const currentClientId = useSelector(getCurrentClientId)
    const currentPatientId = useSelector(getCurrentPatientId)
    const error = useSelector(getClientsError)
    const errorStatus = useSelector(getClientsErrorStatus)

    const {
      fields: { firstName, lastName, mobilePhone, email, patientName },
      validate,
    } = useFields(
      [
        {
          name: 'firstName',
          label: t('Common:CLIENT_FIRST_NAME'),
          validators: ['required'],
          initialValue: '',
        },
        {
          name: 'lastName',
          label: t('Common:CLIENT_LAST_NAME'),
          validators: ['required'],
          initialValue: '',
        },
        {
          name: 'mobilePhone',
          label: t('Common:MOBILE_PHONE_NUMBER'),
          validators: ['phone'],
          initialValue: '',
        },
        {
          name: 'email',
          label: t('Common:EMAIL'),
          validators: ['email'],
          initialValue: '',
        },
        {
          name: 'patientName',
          label: t('Common:PATIENT_NAME'),
          validators: ['required'],
          initialValue: '',
        },
      ],
      false,
    )

    const [openClientDuplicatesDialog] = useDialog(
      DialogNames.CLIENT_DUPLICATES,
    )

    const onClientSelect = (clientId: string) => {
      if (onOk) {
        onOk()
      }
      navigate(`/client/${clientId}`)
    }

    const onPatientSelect = (clientId: string, patientId: string) => {
      if (onOk) {
        onOk()
      }
      navigate('/scheduler', { state: { clientId, patientId } })
    }

    const networkError = errorStatus !== 409 && error

    const checkError = (proceed: ProceedHandler, toScheduler?: boolean) => {
      if (error) {
        if (errorStatus === 409) {
          openClientDuplicatesDialog({
            email: email.value,
            phone: mobilePhone.value,
            onClientSelect,
            onPatientSelect,
            expandPatients: toScheduler,
            onCreate: () => proceed(toScheduler, true),
          })
        }
        return true
      }
      return false
    }

    const setCloseOnCreate = useCloseAfterCreation(
      ({
        proceed,
        toScheduler,
      }: {
        proceed: ProceedHandler
        toScheduler?: boolean
      }) => {
        if (checkError(proceed, toScheduler)) {
          return
        }
        if (onOk) {
          onOk()
          dispatch(clearCurrentClientInfo())
        }
        if (toScheduler) {
          navigate('/scheduler', {
            state: { clientId: currentClientId, patientId: currentPatientId },
          })
        } else {
          navigate(`/client/${currentClientId}/new`)
        }
      },
      getClientIsLoading,
    )

    const proceed: ProceedHandler = (
      toScheduler?: boolean,
      force?: boolean,
    ) => {
      setCloseOnCreate({ toScheduler, proceed })
      const client = {
        firstName: firstName.value,
        lastName: lastName.value,
        mobilePhone: PhoneUtils.parsePhoneNumber(mobilePhone.value),
        email: email.value,
        patients: [
          {
            name: patientName.value,
          },
        ],
      }
      dispatch(createClient(client, !force))
    }

    useImperativeHandle(ref, () => ({
      validate,
      proceed,
    }))

    return (
      <Grid container className={classNames(className, classes.root)}>
        <Grid container spacing={isMobile ? 1 : 2}>
          <Grid item md={6} xs={12}>
            <PuiTextField
              field={firstName}
              inputProps={{ maxLength: 100 }}
              label={`${firstName.label}*`}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PuiTextField
              field={lastName}
              inputProps={{ maxLength: 100 }}
              label={`${lastName.label}*`}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PhoneInput field={mobilePhone} />
          </Grid>
          <Grid item md={6} xs={12}>
            <PuiTextField
              field={{
                ...email,
                message: networkError || email.message,
                valid: networkError ? false : email.valid,
                open: networkError ? true : email.open,
              }}
              inputProps={{ maxLength: 100 }}
              label={email.label}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <PuiTextField
              field={patientName}
              inputProps={{ maxLength: 100 }}
              label={`${patientName.label}*`}
            />
          </Grid>
          <Grid item xs={12}>
            <RequiredFieldsNotice />
          </Grid>
        </Grid>
      </Grid>
    )
  },
)

export default CreateClient
