import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ErrorTooltip,
  MarketingStylesButton,
  PuiTextField,
  PuiTheme,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import {
  clearKeyAlreadyExists,
  validateActivationKey,
} from '~/store/actions/registration'
import {
  getIsKeyAlreadyExist,
  getIsValidateActivationKey,
  getRegistrationIsLoading,
} from '~/store/reducers/registration'
import { isAAHA, isBenchmarking } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import AuthorizationErrorTooltip from './AuthorizationErrorTooltip'

import './LoginWithActivationCodeForm.css'

// Most AAHA styles are also applicable to Benchmarking branding
const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      paddingTop: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(3),
      },
    },
    aahaErrorText: {
      fontFamily: 'Gordita, sans-serif',
    },
    aahaText: {
      fontFamily: 'Gordita, sans-serif',
      color: '#002956',
    },
    aahaBoldText: {
      fontFamily: 'Gordita, sans-serif',
      color: '#002956',
      fontWeight: 700,
    },
    aahaTitle: {
      fontFamily: 'Gordita, sans-serif',
      fontWeight: 700,
      color: '#2A2D33',
    },
    submit: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
      },
      width: 'calc(100% - 16px)',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        width: '100%',
      },
    },
    aahaSubmit: {
      fontFamily: 'Gordita, sans-serif',
      backgroundColor: '#002956',
      '&&&&:hover': {
        backgroundColor: '#002956',
      },
    },
    registerLink: {
      fontFamily: 'Gordita, sans-serif',
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
    },
    aahaRegisterLink: {
      fontSize: '1.4rem',
      fontFamily: 'Gordita, sans-serif',
      color: '#002956',
    },
  }),
  { name: 'LoginWithActivationCodeForm' },
)

const LoginWithActivationCodeForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Auth', 'Common', 'Registration'])

  const isValidateActivationKey = useSelector(getIsValidateActivationKey)
  const keyAlreadyExist = useSelector(getIsKeyAlreadyExist)

  const [touched, setTouched] = useState(false)

  const { fields, validate } = useFields(
    [{ name: 'activationKey', initialValue: '', validators: ['required'] }],
    false,
  )

  const isActivationLandingPage = location.pathname === '/auth/activation'
  const isAahaBrandingPage = isAAHA && isActivationLandingPage
  const isBenchmarkingBrandingPage = isBenchmarking && isActivationLandingPage

  const { activationKey } = fields

  const navigateToNextStep = () => {
    if (isValidateActivationKey) {
      navigate('/register/clinic/select')
    }
  }

  const setCloseAfterFetchingSpaces = useCloseAfterCreation(
    navigateToNextStep,
    getRegistrationIsLoading,
  )

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setTouched(false)
    if (validate()) {
      dispatch(validateActivationKey(activationKey.value))
    }
    setCloseAfterFetchingSpaces()
    return false
  }

  useEffect(() => {
    if (touched) {
      dispatch(clearKeyAlreadyExists())
    }
  }, [touched])

  return (
    <>
      <form noValidate className={classes.form} onSubmit={handleSubmit}>
        {isBenchmarkingBrandingPage ? (
          <>
            <Text
              strong
              align="center"
              className={classes.aahaTitle}
              variant="subheading"
            >
              {t('Auth:ACTIVATION_FORM.WELCOME')}
            </Text>
            <Text
              strong
              align="center"
              className={classes.aahaTitle}
              variant="subheading"
            >
              {t('Auth:ACTIVATION_FORM.ENTER_YOUR_ACTIVATION_CODE')}
            </Text>
          </>
        ) : (
          <Text
            strong
            align="center"
            className={isAahaBrandingPage ? classes.aahaTitle : undefined}
            variant="subheading"
          >
            {t('Auth:ACTIVATION_FORM.WELCOME_ENTER_YOUR_ACTIVATION_CODE')}
          </Text>
        )}

        <ErrorTooltip
          classes={{
            errorTooltipText: isAahaBrandingPage
              ? classes.aahaErrorText
              : undefined,
          }}
          message={t('Auth:ACTIVATION_FORM.WRONG_KEY')}
          open={!touched && Boolean(keyAlreadyExist)}
        >
          <PuiTextField
            InputLabelProps={{
              className: classes.aahaText,
            }}
            InputProps={{
              className: classes.aahaText,
            }}
            id="activationCode"
            label={t('Common:ACTIVATION_CODE')}
            type="text"
            value={activationKey.value}
            onChange={(event) => {
              setTouched(true)
              activationKey.setValue(event.target.value)
            }}
          />
        </ErrorTooltip>
        <AuthorizationErrorTooltip error={null}>
          <MarketingStylesButton
            className={classNames(classes.submit, {
              [classes.aahaSubmit]:
                isAahaBrandingPage || isBenchmarkingBrandingPage,
            })}
            type="submit"
          >
            {isAahaBrandingPage
              ? t('Common:CONTINUE_ACTION')
              : t('Auth:ACTIVATION_FORM.ENTER_YOUR_ACTIVATION_CODE')}
          </MarketingStylesButton>
        </AuthorizationErrorTooltip>
      </form>
      <Grid container item alignItems="center" direction="column">
        <Text
          strong
          className={isAahaBrandingPage ? classes.aahaBoldText : undefined}
          mb={1}
          mt={3}
          variant="body2"
        >
          {t('Auth:ACTIVATION_FORM.DO_NOT_HAVE_A_KEY')}
        </Text>

        {isBenchmarkingBrandingPage ? (
          <Link
            className={classes.registerLink}
            href="https://calendly.com/kendrakshort/chewy-health-benchmarking"
            mb={5}
            underline="always"
          >
            {t('Auth:ACTIVATION_FORM.SCHEDULE_A_CONSULTATION_WITH_CHEWY_TEAM')}
          </Link>
        ) : (
          <>
            <Link
              className={classes.registerLink}
              href="mailto:sales@rhapsody.vet"
              underline="always"
            >
              {t('Auth:ACTIVATION_FORM.SCHEDULE_A_CONSULTATION')}
            </Link>
            <Text
              className={isAahaBrandingPage ? classes.aahaText : undefined}
              mb={5}
              mt={1}
              variant="body2"
            >
              {isAahaBrandingPage
                ? t('Auth:ACTIVATION_FORM.TO_GET_ONE_FROM_CHEWY_TEAM')
                : t('Auth:ACTIVATION_FORM.TO_GET_ONE_FROM_PETABYTE_TEAM')}
            </Text>
          </>
        )}
      </Grid>
    </>
  )
}

export default LoginWithActivationCodeForm
