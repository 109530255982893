import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { pluck } from 'ramda'
import {
  BasePuiDialogProps,
  Nil,
  PuiDialog,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import { createBusiness } from '~/store/actions/businesses'
import Business, { BusinessDto } from '~/store/dto/Business'
import { getBusinessIsLoading } from '~/store/reducers/businesses'

import AddGroup from './AddGroup'
import CreatePractice from './CreatePractice'
import GroupPracticeSelect from './GroupPracticeSelect'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 960,
    },
    title: {
      fontWeight: 500,
      [theme.breakpoints.down('md')]: {
        fontSize: '2rem',
      },
    },
    contentContainer: {
      padding: theme.spacing(3, 4),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 3),
      },
    },
    createPracticeContainer: {
      padding: theme.spacing(3, 4),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 0, 0, 0),
      },
    },
    dialogContentRoot: {
      overflowY: 'visible',
    },
  }),
  { name: 'CreatePracticeGroupDialog' },
)

enum Steps {
  ADD_GROUP = 'ADD_GROUP',
  GROUP_PRACTICE_SELECT = 'GROUP_PRACTICE_SELECT',
  NEW_PRACTICE = 'NEW_PRACTICE',
}

interface CreatePracticeGroupDialogProps extends BasePuiDialogProps {
  creatorRoleId: string | Nil
}

const CreatePracticeGroupDialog = ({
  open,
  onClose,
  creatorRoleId,
}: CreatePracticeGroupDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )

  const dispatch = useDispatch()
  const isLoading = useSelector(getBusinessIsLoading)

  const [groupName, setGroupName] = useState('')
  const [step, setStep] = useState(Steps.ADD_GROUP)
  const [selectedPractices, setSelectedPractices] = useState<BusinessDto[]>([])
  const [closeAfterUpdate, setCloseAfterUpdate] = useState(false)

  useEffect(() => {
    if (closeAfterUpdate && !isLoading && onClose) {
      onClose()
    }
  }, [closeAfterUpdate, isLoading, onClose])

  const addGroup = () => {
    const candidate = new Business({
      isGroup: true,
      name: groupName,
      children: selectedPractices.map(({ id }) => id),
    } as BusinessDto)
    dispatch(createBusiness(candidate, creatorRoleId))
    setCloseAfterUpdate(true)
  }

  return (
    <PuiDialog
      aria-labelledby="create-practice-group-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      maxWidth={isMobile ? 'sm' : 'md'}
      open={open}
      onClose={onClose}
    >
      {step === Steps.ADD_GROUP && (
        <Grid container className={classes.contentContainer} direction="column">
          <AddGroup
            groupName={groupName}
            practices={selectedPractices}
            onAdd={addGroup}
            onAddPractice={() => setStep(Steps.GROUP_PRACTICE_SELECT)}
            onDeletePractice={(id: string) => {
              const newPractices = selectedPractices.filter(
                (practice) => practice.id !== id,
              )
              setSelectedPractices([...newPractices])
            }}
            onGroupNameChange={setGroupName}
          />
        </Grid>
      )}
      {step === Steps.GROUP_PRACTICE_SELECT && (
        <Grid container className={classes.contentContainer} direction="column">
          <Grid item xs={12}>
            <Text className={classes.title} component="h1" variant="h5">
              {t('Common:ADD_PRACTICE')}
            </Text>
          </Grid>
          <GroupPracticeSelect
            selectedPractices={pluck('id', selectedPractices)}
            onAddNewPractice={() => setStep(Steps.NEW_PRACTICE)}
            onSelect={(selected) => {
              setSelectedPractices(selected)
              setStep(Steps.ADD_GROUP)
            }}
          />
        </Grid>
      )}
      {step === Steps.NEW_PRACTICE && (
        <Grid
          container
          item
          className={classes.createPracticeContainer}
          direction="column"
        >
          <CreatePractice
            creatorRoleId={creatorRoleId}
            onCreated={() => setStep(Steps.GROUP_PRACTICE_SELECT)}
          />
        </Grid>
      )}
    </PuiDialog>
  )
}

export default CreatePracticeGroupDialog
