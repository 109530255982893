import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  Nil,
  PuiDialog,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import { AppSelector } from '~/store'

import EmailPreview from './EmailPreview'

export interface EmailPreviewDialogProps extends BasePuiDialogProps {
  fetchTemplate: () => void
  getIsLoadingSelector: AppSelector<boolean>
  getTemplateSelector: AppSelector<string | Nil>
  subtitle: string
  title: string
}

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    spinner: {},
    paper: {
      width: 680,
      maxWidth: 680,
    },
    previewContainerWrapper: {
      width: 384,
      height: '100%',
      minHeight: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
      boxShadow: theme.constants.blockShadow,
    },
  }),
  { name: 'EmailPreviewDialog' },
)

const EmailPreviewDialog = ({
  open,
  title: titleProp,
  subtitle,
  onClose,
  fetchTemplate,
  getTemplateSelector,
  getIsLoadingSelector,
}: EmailPreviewDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const title = titleProp || t('Common:PREVIEW_NOUN')

  useEffect(() => {
    fetchTemplate()
  }, [])

  const template = useSelector(getTemplateSelector)
  const isLoading = useSelector(getIsLoadingSelector)

  return (
    <PuiDialog
      fullWidth
      aria-labelledby="email-preview-dialog"
      classes={{ paper: classes.paper }}
      open={open}
      scroll="paper"
      title={title}
      onClose={onClose}
    >
      <Grid container direction="column" flex={1} pb={3} pt={2} px={3}>
        {subtitle && <Text mb={2}>{subtitle}</Text>}
        {isLoading ? (
          <Grid container justifyContent="center">
            <CircularProgress
              className={classes.spinner}
              color="primary"
              size={32}
            />
          </Grid>
        ) : (
          <Grid className={classes.previewContainerWrapper}>
            <EmailPreview emailTemplate={template} />
          </Grid>
        )}
      </Grid>
    </PuiDialog>
  )
}

export default EmailPreviewDialog
