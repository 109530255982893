import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AppointmentEventType,
  Business,
  Constant,
  CustomFieldValidatorState,
  EventTypeName,
  Field,
  LanguageUtils,
  PuiSelect,
  PuiTextField,
  PuiTheme,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import PuiSelectAll from '~/components/common/inputs/PuiSelectAll'
import PuiSwitch from '~/components/common/PuiSwitch'
import FeatureToggle from '~/constants/featureToggle'
import { getSmsCommunicationsEnabled } from '~/store/reducers/auth'
import {
  getAppointmentCommunicationInitializationType,
  getCommunicationTransportBackupOption,
  getFeatureToggle,
} from '~/store/reducers/constants'
import { CommunicationTransportBackupOptionName, DataHandle } from '~/types'
import { getConstantsList } from '~/utils'
import { useBoopEnabled } from '~/utils/boop'
import { getCommunicationsMessageMaxLength } from '~/utils/communicationsUtils'
import { useEventType } from '~/utils/useEventType'

import MessageWithPreviewConfiguration from '../wellness-plans/MessageWithPreviewConfiguration'
import AutomaticCommunicationChangeInAppointmentStatusSettings from './AutomaticCommunicationChangeInAppointmentStatusSettings'
import AutomaticCommunicationSpecificTimeSettings from './AutomaticCommunicationSpecificTimeSettings'
import { AutomaticCommunicationBaseProps } from './interfaces'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    activeContainer: {
      borderBottom: theme.constants.tabBorder,
    },
    input: {
      width: 380,
    },
    radioLabel: {
      fontSize: '1.6rem',
      marginLeft: theme.spacing(0.5),
    },
    radio: {
      padding: theme.spacing(0.5),
    },
    labelRoot: {
      margin: theme.spacing(0, 2, 0, 0),
    },
  }),
  { name: 'AutomaticCommunication' },
)

const MESSAGE_MAX_LENGTH = getCommunicationsMessageMaxLength(
  ConversationTransport.Sms,
  true,
)

export interface AutomaticCommunicationProps
  extends AutomaticCommunicationBaseProps {
  business: Business
}

const AutomaticCommunication = forwardRef<
  DataHandle | undefined,
  AutomaticCommunicationProps
>(({ automaticCommunication, business, className }, ref) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Businesses', 'Validations'])

  const CommunicationTransportBackupOption: Constant[] = useSelector(
    getCommunicationTransportBackupOption,
  )
  const InitializationType: Constant[] = useSelector(
    getAppointmentCommunicationInitializationType,
  )
  const isSmsCommunicationsEnabled: boolean = useSelector(
    getSmsCommunicationsEnabled,
  )

  const isBusinessOmniChannel = business.omniChannel

  const isHideCommunicationSettingsForOmniChannelEnabled = useSelector(
    getFeatureToggle(
      FeatureToggle.HIDE_COMMUNICATION_SETTINGS_FOR_OMNI_CHANNEL,
    ),
  )

  const shouldHideTextFields =
    isBusinessOmniChannel && isHideCommunicationSettingsForOmniChannelEnabled

  const AppointmentSubTypes: AppointmentEventType['subTypes'] = useEventType(
    EventTypeName.Appointment,
    'subTypes',
  )
  const PreferredContactMethodId: string = Utils.findConstantIdByName(
    CommunicationTransportBackupOptionName.PREFERRED_CONTACT_METHOD,
    CommunicationTransportBackupOption,
  )
  const TextMessageMethodId: string = Utils.findConstantIdByName(
    CommunicationTransportBackupOptionName.TEXT_MESSAGE,
    CommunicationTransportBackupOption,
  )
  const BoopMessagedId: string = Utils.findConstantIdByName(
    CommunicationTransportBackupOptionName.BOOP_MESSAGE,
    CommunicationTransportBackupOption,
  )
  const SpecificTimeOptionId: string = Utils.findConstantIdByName(
    'At a specific time',
    InitializationType,
  )

  const textTemplateValidator = ({
    state: { textTemplate },
  }: CustomFieldValidatorState) => textTemplate.length <= MESSAGE_MAX_LENGTH

  const boopFlowEnabled = useBoopEnabled(business)

  const {
    fields: {
      active,
      name,
      initializationTypeId,
      appointmentTypeIds,
      communicationMethodId,
      communicationMethodPrimaryBackupId,
      communicationMethodSecondaryBackupId,
      communicationMethodTertiaryBackupId,
      subject,
      template,
      textTemplate,
    },
    validate,
    reset,
  } = useFields(
    [
      {
        name: 'active',
        label: t('Common:ACTIVE_ONE'),
        type: 'toggle',
        initialValue: automaticCommunication?.active ?? true,
      },
      {
        name: 'name',
        label: t('Common:NAME'),
        validators: ['required'],
        initialValue: automaticCommunication?.name || '',
      },
      {
        name: 'initializationTypeId',
        initialValue:
          automaticCommunication?.initializationTypeId || SpecificTimeOptionId,
      },
      {
        name: 'appointmentTypeIds',
        label: t(
          'Businesses:APPOINTMENT_COMMUNICATIONS.SEND_FOR_APPOINTMENT_TYPES',
        ),
        type: 'select',
        initialValue: automaticCommunication
          ? getConstantsList(
              automaticCommunication.appointmentTypeIds || [],
              AppointmentSubTypes,
            )
          : [],
      },
      {
        name: 'communicationMethodId',
        label: t('Common:DEFAULT'),
        initialValue:
          automaticCommunication?.communicationMethodId ||
          PreferredContactMethodId,
      },
      {
        name: 'communicationMethodPrimaryBackupId',
        label: t('Businesses:APPOINTMENT_COMMUNICATIONS.PRIMARY_BACK_UP'),
        initialValue:
          automaticCommunication?.communicationMethodPrimaryBackupId || '',
      },
      {
        name: 'communicationMethodSecondaryBackupId',
        label: t('Businesses:APPOINTMENT_COMMUNICATIONS.SECONDARY_BACK_UP'),
        initialValue:
          automaticCommunication?.communicationMethodSecondaryBackupId || '',
      },
      {
        name: 'communicationMethodTertiaryBackupId',
        label: t('Businesses:APPOINTMENT_COMMUNICATIONS.TERTIARY_BACK_UP'),
        initialValue:
          automaticCommunication?.communicationMethodTertiaryBackupId || '',
      },
      {
        name: 'subject',
        label: t('Common:SUBJECT_WILL_NOT_DISPLAY'),
        validators: ['required'],
        initialValue: automaticCommunication?.subject || '',
      },
      {
        name: 'template',
        label: t('Common:MESSAGE'),
        validators: shouldHideTextFields ? [] : ['required'],
        initialValue: automaticCommunication?.template || '',
      },
      {
        name: 'textTemplate',
        label: t('Common:TEXT_MESSAGE'),
        validators: shouldHideTextFields
          ? []
          : [
              'required',
              {
                validator: textTemplateValidator,
                validatorName: 'textTemplateValidator',
              },
            ],
        messages: {
          textTemplateValidator: t(
            'Validations:TEXT_MESSAGE_LENGTH_SHOULD_BE_LESS',
            {
              maxLength: MESSAGE_MAX_LENGTH,
            },
          ),
        },
        initialValue: automaticCommunication?.textTemplate || '',
      },
    ],
    false,
  )

  const specificTimeSettingsRef = useRef<DataHandle>()
  const changeInAppointmentStatusSettingsRef = useRef<DataHandle>()

  const backupFields = [
    communicationMethodId,
    communicationMethodPrimaryBackupId,
    communicationMethodSecondaryBackupId,
    communicationMethodTertiaryBackupId,
  ]

  useEffect(() => {
    reset()
  }, [automaticCommunication])

  useEffect(() => {
    if (!communicationMethodPrimaryBackupId.value) {
      communicationMethodSecondaryBackupId.setValue('')
      communicationMethodTertiaryBackupId.setValue('')
    }
  }, [communicationMethodPrimaryBackupId.value])

  useEffect(() => {
    if (!communicationMethodSecondaryBackupId.value) {
      communicationMethodTertiaryBackupId.setValue('')
    }
  }, [communicationMethodSecondaryBackupId.value])

  const methodEnabledForBusiness = (methodId: string) => {
    if (methodId === TextMessageMethodId) {
      return isSmsCommunicationsEnabled
    }

    return true
  }

  useImperativeHandle(ref, () => ({
    validate: () =>
      validate() &&
      Boolean(specificTimeSettingsRef.current?.validate()) &&
      Boolean(changeInAppointmentStatusSettingsRef.current?.validate()),
    get: () => ({
      ...automaticCommunication,
      active: active.value,
      name: name.value,
      initializationTypeId: initializationTypeId.value,
      appointmentTypeIds: R.pluck<any, string>('id', appointmentTypeIds.value),
      communicationMethodId: communicationMethodId.value,
      communicationMethodPrimaryBackupId:
        communicationMethodPrimaryBackupId.value || null,
      communicationMethodSecondaryBackupId:
        communicationMethodSecondaryBackupId.value || null,
      communicationMethodTertiaryBackupId:
        communicationMethodTertiaryBackupId.value || null,
      subject: subject.value,
      template: template.value,
      textTemplate: textTemplate.value,
      ...specificTimeSettingsRef.current?.get(),
      ...changeInAppointmentStatusSettingsRef.current?.get(),
    }),
  }))

  const createBackupContactMethodList = (backupField: Field) => {
    const valuesToExclude = R.pipe(
      R.without([backupField]),
      R.pluck('value'),
    )(backupFields)

    return CommunicationTransportBackupOption.filter(
      (item) => boopFlowEnabled || item.id !== BoopMessagedId,
    ).map((item) => ({
      ...item,
      disabled:
        R.includes(item.id, valuesToExclude) ||
        !methodEnabledForBusiness(item.id),
    }))
  }

  return (
    <Grid
      container
      item
      className={classNames(className, classes.root)}
      direction="column"
    >
      <Grid item className={classes.activeContainer} px={3} py={0.5}>
        <PuiSwitch field={active} label={active.label} />
      </Grid>
      <Grid container item direction="column" px={3}>
        <Grid item mb={2}>
          <PuiTextField
            className={classes.input}
            field={name}
            inputProps={{ maxLength: 100 }}
            label={`${name.label}*`}
          />
        </Grid>
        <FormControl component="fieldset">
          <Text strong variant="subheading3">
            {t('Common:WHEN_TO_SEND')}
          </Text>
          <RadioGroup
            row
            aria-label="initializationTypeIdGroup"
            name="initializationTypeIdGroup1"
            value={initializationTypeId.value}
            onChange={(_, value) => {
              initializationTypeId.setValue(value)
            }}
          >
            {InitializationType.map((type) => (
              <FormControlLabel
                classes={{
                  root: classes.labelRoot,
                  label: classes.radioLabel,
                }}
                control={<Radio className={classes.radio} />}
                key={type.id}
                label={
                  SpecificTimeOptionId === type.id ? (
                    <TextWithTooltip
                      allowWrap
                      tooltipText={t(
                        'Businesses:APPOINTMENT_COMMUNICATIONS.COMMUNICATIONS_WILL_NOT_BE_SENT',
                      )}
                      variant="body"
                    >
                      {LanguageUtils.getTranslatedFieldName(type)}
                    </TextWithTooltip>
                  ) : (
                    LanguageUtils.getTranslatedFieldName(type)
                  )
                }
                value={type.id}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Grid item mt={2}>
          <AutomaticCommunicationSpecificTimeSettings
            automaticCommunication={automaticCommunication}
            initializationTypeId={initializationTypeId.value}
            ref={specificTimeSettingsRef}
          />
        </Grid>
        <Grid item mt={2}>
          <AutomaticCommunicationChangeInAppointmentStatusSettings
            automaticCommunication={automaticCommunication}
            initializationTypeId={initializationTypeId.value}
            ref={changeInAppointmentStatusSettingsRef}
          />
        </Grid>
        <Grid item mb={3} mt={2}>
          <PuiSelectAll
            field={appointmentTypeIds}
            items={AppointmentSubTypes}
            label={appointmentTypeIds.label}
          />
        </Grid>
        <TextWithTooltip
          allowWrap
          tooltipText={t(
            'Businesses:APPOINTMENT_COMMUNICATIONS.THIS_WILL_DETERMINE_ORDER',
          )}
          variant="body"
        >
          {t('Common:SEND_AS')}
        </TextWithTooltip>
        <Grid container item columnSpacing={3} wrap="nowrap">
          <Grid item xs>
            <FormControl fullWidth margin="none">
              <InputLabel htmlFor="default-select">
                {communicationMethodId.label}
              </InputLabel>
              <PuiSelect
                field={communicationMethodId}
                input={<Input id="default-select" />}
                items={createBackupContactMethodList(communicationMethodId)}
                renderEmpty={false}
              />
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth margin="none">
              <InputLabel shrink htmlFor="primary-backup-select">
                {communicationMethodPrimaryBackupId.label}
              </InputLabel>
              <PuiSelect
                field={communicationMethodPrimaryBackupId}
                input={<Input id="primary-backup-select" />}
                items={createBackupContactMethodList(
                  communicationMethodPrimaryBackupId,
                )}
                placeholder={t('Common:NONE')}
              />
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth margin="none">
              <InputLabel shrink htmlFor="secondary-backup-select">
                {communicationMethodSecondaryBackupId.label}
              </InputLabel>
              <PuiSelect
                disabled={!communicationMethodPrimaryBackupId.value}
                field={communicationMethodSecondaryBackupId}
                input={<Input id="secondary-backup-select" />}
                items={createBackupContactMethodList(
                  communicationMethodSecondaryBackupId,
                )}
                placeholder={t('Common:NONE')}
              />
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth margin="none">
              <InputLabel shrink htmlFor="tertiary-backup-select">
                {communicationMethodTertiaryBackupId.label}
              </InputLabel>
              <PuiSelect
                disabled={
                  !communicationMethodPrimaryBackupId.value ||
                  !communicationMethodSecondaryBackupId.value
                }
                field={communicationMethodTertiaryBackupId}
                input={<Input id="tertiary-backup-select" />}
                items={createBackupContactMethodList(
                  communicationMethodTertiaryBackupId,
                )}
                placeholder={t('Common:NONE')}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item mt={2}>
          <MessageWithPreviewConfiguration
            field={subject}
            showButtons={false}
            showPreview={false}
            tooltipTitle={subject.label}
          />
        </Grid>
        {!shouldHideTextFields && (
          <>
            <Grid item mt={2}>
              <MessageWithPreviewConfiguration
                field={template}
                hidePlusButtonBlock={false}
                showPreview={false}
                tooltipTitle={template.label}
              />
            </Grid>
            <Grid item mt={2}>
              <MessageWithPreviewConfiguration
                field={textTemplate}
                hidePlusButtonBlock={false}
                maxLength={MESSAGE_MAX_LENGTH}
                showButtons={false}
                showPreview={false}
                tooltipTitle={textTemplate.label}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
})

export default AutomaticCommunication
