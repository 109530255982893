import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme } from '@pbt/pbt-ui-components'

import { getSoapAddendumIds } from '~/store/reducers/soap'

import AddendumItem from './AddendumItem'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      backgroundColor: theme.colors.tableLeftColumnBackground,
    },
    addendumItem: {
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(2),
      },
    },
  }),
  { name: 'AddendumsSection' },
)

const AddendumsSection = () => {
  const classes = useStyles()
  const soapAddendumIds = useSelector(getSoapAddendumIds)

  if (soapAddendumIds.length === 0) {
    return null
  }

  return (
    <Grid container item className={classes.root} p={3}>
      {soapAddendumIds.map((addendumId) => (
        <AddendumItem
          addendumId={addendumId}
          className={classes.addendumItem}
          key={addendumId}
        />
      ))}
    </Grid>
  )
}

export default AddendumsSection
