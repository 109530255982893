import React from 'react'
import { DragIndicator as DragIndicatorIcon } from '@mui/icons-material'
import { Box, IconButton, TableCell } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { LanguageUtils, PuiTheme, Text } from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

import { SoapTemplateWidget } from '~/api/graphql/generated/types'
import DragAndDropTable from '~/components/common/lists/DragAndDropTable'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    deleteIcon: {
      marginLeft: 'auto',
      color: theme.colors.lowAccentText,
    },
    dragIndicatorIcon: {
      color: theme.colors.lowAccentText,
    },
    removeBorder: {
      border: 'none',
      borderTop: 'none !important',
    },
    table: {
      margin: theme.spacing(0, -3),
      width: `calc(100% + ${theme.spacing(3 * 2)})`,
    },
    tableCell: {
      padding: theme.spacing(0, 1.5, 0, 3),
      height: 40,
    },
  }),
  { name: 'SoapWidgetsCandidate' },
)

export interface SoapWidgetsCandidateProps {
  onDelete: (item: SoapTemplateWidget) => void
  onOrderChange: (sourceIndex: number, targetIndex: number) => void
  widgets: SoapTemplateWidget[]
}

const SoapWidgetsCandidate = ({
  onDelete,
  onOrderChange,
  widgets,
}: SoapWidgetsCandidateProps) => {
  const classes = useStyles()

  return (
    <DragAndDropTable
      useIndexAsId
      classes={{
        tableRow: classes.removeBorder,
        table: classes.removeBorder,
        root: classes.table,
      }}
      data={widgets}
      onOrderChange={onOrderChange}
    >
      {({ item }) => (
        <TableCell
          className={classNames(classes.removeBorder, classes.tableCell)}
        >
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <DragIndicatorIcon className={classes.dragIndicatorIcon} />
            <Text>{LanguageUtils.getTranslatedFieldName(item)}</Text>
            <IconButton
              className={classes.deleteIcon}
              id="delete-widget-button"
              onClick={() => {
                onDelete(item)
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </TableCell>
      )}
    </DragAndDropTable>
  )
}

export default SoapWidgetsCandidate
