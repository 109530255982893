import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  ImageScalingUtils,
  LanguageUtils,
  PatientAvatar,
  PermissionArea,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { Wings as WingsIcon } from '@pbt/pbt-ui-components/src/icons'

import AlertLabel from '~/components/common/labels/AlertLabel'
import PatientInfoLabel from '~/components/common/labels/PatientInfoLabel'
import Link from '~/components/common/link/Link'
import useGetPreferredContactMethod from '~/components/dashboard/clients/details/new-client-and-patient/useGetPreferredContactMethod'
import { AlertColorLevel } from '~/constants/alertColors'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { useAlertType } from '~/store/hooks/patient'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle, getSpecies } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { getUser } from '~/store/reducers/users'
import { addOriginalBusinessId } from '~/utils'
import { htmlDecode } from '~/utils/htmlUtils'
import useDialog from '~/utils/useDialog'

import { useGetIsBusyType } from './appointmentUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    clientSection: {
      backgroundColor: theme.colors.tableEvenItem,
    },
    nameText: {
      color: theme.colors.title,
      fontSize: '1.6rem',
      fontWeight: 500,
    },
    patientAvatar: {
      marginRight: theme.spacing(1),
    },
    notActiveAvatar: {
      opacity: 0.5,
    },
    button: {
      minWidth: 120,
    },
    notes: {
      wordBreak: 'break-word',
    },
  }),
  { name: 'AppointmentPreview' },
)

export interface AppointmentPreviewProps {
  appointmentId: string
  onClose: () => void
}

const AVATAR_SIZE = 40

const AppointmentPreview = forwardRef<HTMLDivElement, AppointmentPreviewProps>(
  function AppointmentPreview({ appointmentId, onClose }, ref) {
    const classes = useStyles()
    const navigate = useNavigate()
    const { t } = useTranslation('Common')

    const appointment = useSelector(getTimetableEvent(appointmentId))
    const client = useSelector(getUser(appointment?.client))
    const patient = useSelector(getPatient(appointment?.patient))
    const Species = useSelector(getSpecies)
    const soapPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
    const isPatientSharingEnabled = useSelector(
      getFeatureToggle(FeatureToggle.PATIENT_SHARING),
    )

    const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
      patient?.photo,
      patient?.photoThumbnail,
      AVATAR_SIZE,
    )

    const appointmentTypeDisplayName = LanguageUtils.getTranslatedFieldName(
      appointment?.businessAppointmentType,
    )

    const preferredContactMethod = useGetPreferredContactMethod({ client })
    const getIsBusyType = useGetIsBusyType()
    const isBusyType = getIsBusyType(appointment?.type?.id)

    const [openAppointmentDialog] = useDialog(DialogNames.EVENT)

    const { alertsToRender } = useAlertType(appointment?.patient)

    const soaps = appointment?.soaps || []
    const hasSoaps = soaps.length > 0
    const lastSoap = R.last(soaps)
    const hasPatientAlert = alertsToRender.length > 0
    const isPatientInactive = patient?.active === false
    const showOpenSOAP = !isBusyType && hasSoaps && soapPermissions.read
    const showCreateSOAP = !isBusyType && !hasSoaps && soapPermissions.create

    const soapBusinessId = isPatientSharingEnabled
      ? appointment?.businessId
      : null

    const openSoap = () => {
      if (lastSoap) {
        navigate(addOriginalBusinessId(`/soap/${lastSoap.id}`, soapBusinessId))
      } else {
        navigate(
          addOriginalBusinessId(
            `/soap/create/${appointmentId}`,
            soapBusinessId,
          ),
        )
      }
    }

    const expandDetails = () => {
      openAppointmentDialog({
        appointmentId,
      })
      onClose()
    }

    return (
      <Grid
        container
        item
        className={classes.root}
        direction="column"
        ref={ref}
      >
        {isBusyType && (
          <Grid item p={2}>
            <Text variant="body2">{appointment?.notes}</Text>
          </Grid>
        )}
        {!isBusyType && (
          <>
            <Grid
              container
              item
              className={classes.clientSection}
              direction="column"
              p={1}
            >
              <Grid
                container
                item
                alignItems="center"
                justifyContent="space-between"
              >
                <Link
                  className={classes.nameText}
                  to={`/client/${appointment?.client}`}
                >
                  {Utils.getPersonString(client)}
                </Link>
                <Text strong variant="body2">
                  {preferredContactMethod.value}
                </Text>
              </Grid>
              {client?.alertText && (
                <AlertLabel
                  showIcon
                  alertColorId={client?.alertColorId}
                  alertColorLevel={AlertColorLevel.CLIENT}
                  message={client?.alertText}
                />
              )}
            </Grid>
            <Grid container item direction="column" p={1}>
              <Grid container item wrap="nowrap">
                <Grid container item xs direction="column">
                  <Grid container item alignItems="center" wrap="nowrap">
                    <Link
                      className={classes.nameText}
                      to={`/patient/${appointment?.patient}`}
                    >
                      {patient?.name}
                    </Link>
                    {patient?.deceased && <WingsIcon />}
                    {isPatientInactive && (
                      <Grid item ml={1}>
                        <AlertLabel
                          small
                          message={t('Common:INACTIVE_ONE')}
                          variant="normal"
                        />
                      </Grid>
                    )}
                  </Grid>
                  <PatientInfoLabel patientId={appointment?.patient} />
                </Grid>
                <Grid item>
                  <PatientAvatar
                    small
                    animal={Utils.getConstantName(patient?.species, Species)}
                    className={classNames(classes.patientAvatar, {
                      [classes.notActiveAvatar]: !patient?.active,
                    })}
                    src={avatarSrc}
                  />
                </Grid>
              </Grid>
              {hasPatientAlert && (
                <AlertLabel
                  showIcon
                  alertColorId={patient?.alertColorId}
                  alertColorLevel={AlertColorLevel.PATIENT}
                  alertTypes={alertsToRender}
                />
              )}
            </Grid>
            <Grid container item direction="column" p={1}>
              <Text variant="body2">{appointmentTypeDisplayName}</Text>
              {appointment?.notes && (
                <Text strong className={classes.notes} variant="body2">
                  {htmlDecode(appointment.notes)}
                </Text>
              )}
              <Grid
                container
                item
                alignItems="center"
                columnSpacing={2}
                my={1}
                wrap="nowrap"
              >
                <Grid item>
                  <ButtonWithLoader
                    className={classes.button}
                    onClick={expandDetails}
                  >
                    {t('Common:EXPAND_ACTION')}
                  </ButtonWithLoader>
                </Grid>
                {(showOpenSOAP || showCreateSOAP) && (
                  <Grid item>
                    <ButtonWithLoader
                      className={classes.button}
                      color="secondary"
                      onClick={openSoap}
                    >
                      {hasSoaps
                        ? t('Common:OPEN_SOAP')
                        : t('Common:CREATE_SOAP')}
                    </ButtonWithLoader>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    )
  },
)

export default AppointmentPreview
