import * as R from 'ramda'
import {
  ApiError,
  Business,
  DateUtils,
  LanguageUtils,
  moment,
  Nil,
  PhoneUtils,
  PuiTheme,
  User,
  Utils,
} from '@pbt/pbt-ui-components'

import { ConversationTransport, Type } from '~/api/graphql/generated/types'
import { DYNAMIC_TEXT_MARKER } from '~/components/common/inputs/rich-edit/decorator/placeholder'
import {
  ConversationStatus,
  MESSAGE_MAX_LENGTH_BY_TRANSPORT,
  TEXT_MESSAGE_MAX_LENGTH_BUFFER_FOR_DYNAMIC_TEXT,
} from '~/constants/communications'
import i18n from '~/locales/i18n'
import {
  Contact,
  Conversation,
  Filter,
  Recipient,
  RecipientInfo,
  TableFilter,
} from '~/types'

export const filterItemsFromEnum = <T extends string>(
  enumObj: Record<T, string>,
  namesConfig?: Partial<Record<T, string>>,
): { id: T; name: string }[] =>
  Object.keys(enumObj).map((key) => {
    const value = key as T
    const name: string =
      namesConfig?.[value] ||
      enumObj[value] ||
      LanguageUtils.getReadableEnumName(enumObj[value])

    return {
      id: value,
      name,
    }
  })

export const getStatusFilterList = (status?: Filter | TableFilter) =>
  ((status?.value as string) || '').split(',').filter(R.length)

export const getStatusFilterFromList = (statusList: string[]) =>
  statusList.join(',')

export const getConversationStatus = (
  conversation: Pick<Conversation, 'state'>,
) => conversation?.state as ConversationStatus

export const getCommunicationAddress = (
  transport: ConversationTransport | Nil,
  recipient: Recipient,
) => {
  switch (transport) {
    case ConversationTransport.Sms:
      return PhoneUtils.formatPhoneNumber(recipient?.phoneNumber)
    case ConversationTransport.Email:
      return recipient?.email || ''
    default:
      return ''
  }
}

export const getRecipientNameWithCommunicationAddress = (
  transport: ConversationTransport | Nil,
  recipient: Recipient = {},
) => {
  const composeRecipientNameWithCommunicationAddress = (
    name: string,
    communicationAddressText: string,
  ) => {
    const nameText = name ? `${name} ` : ''
    return `${nameText}${communicationAddressText}`
  }

  const communicationAddress = getCommunicationAddress(transport, recipient)
  const noCommunicationAddress =
    R.isEmpty(communicationAddress) || !communicationAddress
  const communicationAddressText = noCommunicationAddress
    ? ''
    : `(${communicationAddress})`

  switch (recipient.type) {
    case Type.Client: {
      const name =
        recipient.name || i18n.t<string>('Communications:UTILS.UNKNOWN_CLIENT')
      return composeRecipientNameWithCommunicationAddress(
        name,
        communicationAddressText,
      )
    }
    case Type.Contact: {
      const name =
        recipient.name || i18n.t<string>('Communications:UTILS.UNKNOWN_CONTACT')
      return composeRecipientNameWithCommunicationAddress(
        name,
        communicationAddressText,
      )
    }
    case Type.Business: {
      const name =
        recipient.name ||
        i18n.t<string>('Communications:UTILS.UNKNOWN_BUSINESS')
      return composeRecipientNameWithCommunicationAddress(
        name,
        communicationAddressText,
      )
    }
    default: {
      return communicationAddress
    }
  }
}

export const getRecipientInfo = ({
  client,
  contact,
  business,
  email,
  phoneNumber,
  recipientType,
}: RecipientInfo) => {
  switch (recipientType) {
    case Type.Client:
      return {
        email: client?.email,
        phoneNumber: PhoneUtils.formatPhoneNumber(client?.mobilePhone),
        name: Utils.getPersonString(client),
        type: recipientType,
      }
    case Type.Contact:
      return {
        email: contact?.email,
        phoneNumber: PhoneUtils.formatPhoneNumber(contact?.phone),
        name: contact?.name,
        type: recipientType,
      }
    case Type.Business:
      return {
        email: business?.email,
        phoneNumber: PhoneUtils.formatPhoneNumber(
          business?.communicationsPhone,
        ),
        name: business?.name,
        type: recipientType,
      }
    default:
      return {
        email,
        phoneNumber: PhoneUtils.formatPhoneNumber(phoneNumber),
        type: Type.Direct,
      }
  }
}

export const PLAIN_TEXT_TRANSPORTS = [
  ConversationTransport.Sms,
  ConversationTransport.Boop,
]

export const isPlainTextTransport = (transport: ConversationTransport | Nil) =>
  R.includes(transport, PLAIN_TEXT_TRANSPORTS)

export const getContactToName = (
  contact: Contact | Nil,
  transport: ConversationTransport | Nil,
) =>
  getRecipientNameWithCommunicationAddress(
    transport,
    getRecipientInfo({
      contact,
      recipientType: Type.Contact,
    }),
  )

export const getClientToName = (
  client: User | Nil,
  transport: ConversationTransport | Nil,
) =>
  getRecipientNameWithCommunicationAddress(
    transport,
    getRecipientInfo({
      client,
      recipientType: Type.Client,
    }),
  )

export const getBusinessToName = (
  business: Business | Nil,
  transport: ConversationTransport | Nil,
) =>
  getRecipientNameWithCommunicationAddress(
    transport,
    getRecipientInfo({
      business,
      recipientType: Type.Business,
    }),
  )

export const getFirstEmailIfIsDirect = (
  formedRecipients: RecipientInfo[] = [],
  toFieldValue = '',
) => {
  const toFieldEmails = toFieldValue.split(',')
  if (R.isEmpty(toFieldEmails)) {
    return undefined
  }
  const toFieldFirstEmail = toFieldEmails[0].trim()

  const isFirstEmailIsDirect =
    formedRecipients.filter(
      ({ recipientType, email }) =>
        recipientType === Type.Direct && email === toFieldFirstEmail,
    ).length > 0

  return isFirstEmailIsDirect ? toFieldFirstEmail : undefined
}

export const getConversationRowColoring = (
  theme: PuiTheme,
  conversation: Conversation,
) => {
  if (conversation.state === ConversationStatus.DELIVERY_FAILED) {
    return theme.colors.important
  }
  return null
}

export const mapDateToConversationDetailsDate = (date: string | Nil) => {
  if (!date) {
    return undefined
  }

  const dateMoment = moment(date)
  return dateMoment.isBetween(moment().startOf('day'), moment().endOf('day'))
    ? DateUtils.formatTime(date)
    : DateUtils.formatDate(date)
}

export const hasAppliedFilters = (filters: Record<string, TableFilter[]>) =>
  Object.keys(filters).filter((filterName) => Boolean(filters[filterName]))
    .length > 0

export const getCommunicationsMessageMaxLength = (
  transport: ConversationTransport,
  withDynamicText: boolean = true,
) => {
  const maxLengthByTransport = MESSAGE_MAX_LENGTH_BY_TRANSPORT[transport]
  if (!maxLengthByTransport) {
    return Number.MAX_SAFE_INTEGER
  }

  return withDynamicText
    ? maxLengthByTransport - TEXT_MESSAGE_MAX_LENGTH_BUFFER_FOR_DYNAMIC_TEXT
    : maxLengthByTransport
}

export const includesDynamicText = (text: string) =>
  R.includes(DYNAMIC_TEXT_MARKER, text ?? '')

export const isCommunicationValidationError = (error: ApiError) =>
  R.includes(error?.status, [400, 413]) && error?.responseBody?.error?.type
