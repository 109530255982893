import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import {
  LanguageUtils,
  PuiTheme,
  StateLabelProps,
  Utils,
} from '@pbt/pbt-ui-components'

import AccentLabel from '~/components/common/labels/AccentLabel'
import { RefundInvoiceState } from '~/constants/invoice'
import { getRefundInvoiceState } from '~/store/reducers/constants'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    status: {
      width: 'fit-content',
      fontSize: '1.2rem',
    },
    refund: {
      backgroundColor: theme.colors.inactiveBackground,
      color: theme.colors.badgeColor,
    },
  }),
  { name: 'RefundInvoiceStatusLabel' },
)

export interface RefundInvoiceStatusLabelProps extends StateLabelProps {
  stateId: string
}

const RefundInvoiceStatusLabel = ({
  stateId,
  className,
  ...rest
}: RefundInvoiceStatusLabelProps) => {
  const RefundInvoiceStates = useSelector(getRefundInvoiceState)
  const classes = useStyles(rest)

  const state = Utils.findById(stateId, RefundInvoiceStates)
  const displayStatusName = LanguageUtils.getConstantTranslatedName(
    stateId,
    RefundInvoiceStates,
  )

  const isRefunded = state.name === RefundInvoiceState.REFUNDED

  return (
    <AccentLabel
      className={classNames(
        className,
        classes.status,
        isRefunded && classes.refund,
      )}
      colorVariant={state.color || state.colorVariant}
      name={displayStatusName}
      {...rest}
    />
  )
}

export default RefundInvoiceStatusLabel
