import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { LanguageUtils, PuiTheme, Text } from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import {
  getDepartmentsIsLoading,
  toggleDepartmentState,
} from '~/store/duck/departments'
import { getDepartmentTypes } from '~/store/reducers/constants'
import { Department } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tableItem: {
      borderBottom: theme.constants.tableBorder,
    },
  }),
  { name: 'DepartmentItem' },
)

interface DepartmentItemProps {
  className?: string
  department: Department
  editable?: boolean
}

const DepartmentItem = ({
  className,
  department,
  editable = true,
}: DepartmentItemProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const DepartmentTypes = useSelector(getDepartmentTypes)
  const isLoading = useSelector(getDepartmentsIsLoading)

  const handleToggleState = () => {
    if (department?.businessId) {
      dispatch(toggleDepartmentState(department.businessId, department.id))
    }
  }

  return (
    <Grid
      container
      item
      className={classNames(classes.tableItem, className)}
      wrap="nowrap"
    >
      <Grid container item alignItems="center" px={1.5} xs={2}>
        <PuiSwitch
          checked={department.isActive}
          disabled={!editable || isLoading}
          onChange={handleToggleState}
        />
      </Grid>
      <Grid container item alignItems="center" px={1.5} xs={4}>
        <Dotdotdot clamp={2}>
          <Text disabled={!department.isActive} variant="body2">
            {department.name}
          </Text>
        </Dotdotdot>
      </Grid>
      <Grid container item alignItems="center" px={1.5} xs={3}>
        <Text disabled={!department.isActive} variant="body2">
          {LanguageUtils.getConstantTranslatedName(
            department.typeId,
            DepartmentTypes,
          )}
        </Text>
      </Grid>
      <Grid container item alignItems="center" px={1.5} xs={3} />
    </Grid>
  )
}

export default DepartmentItem
