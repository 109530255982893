import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Fab } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { PuiTheme } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { OrderType, ProcedureState } from '~/constants/SOAPStates'
import { partialEditOrder } from '~/store/actions/orders'
import { editChargeSheetOrder } from '~/store/duck/clientFinanceData'
import { useGetProcedureStateId, useOrderStateId } from '~/store/hooks/orders'
import { getFeatureToggle } from '~/store/reducers/constants'
import { InvoiceLineItem, Order } from '~/types'
import { useGetAreChargesPostedAndEditable } from '~/utils/finance'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    fab: {
      height: 33,
      width: '100%',
      fontSize: '1.2rem',
      fontWeight: 500,
      padding: theme.spacing(0, 1),
    },
    fabSelected: {
      '&&&&:hover': {
        backgroundColor: theme.colors.selectedOption,
      },
      backgroundColor: theme.colors.selectedOption,
      color: theme.colors.primaryText,
    },
  }),
  { name: 'ProcedureCompleteButton' },
)

export interface ProcedureCompleteButtonProps {
  disabled?: boolean
  order: Order | InvoiceLineItem
}

const ProcedureCompleteButton = ({
  disabled,
  order,
}: ProcedureCompleteButtonProps) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const { t } = useTranslation('Common')

  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )

  const areChargesPostedAndEditable = useGetAreChargesPostedAndEditable(
    OrderType.PROCEDURE,
    R.has('logType', order) ? order.logId : order.id,
  )

  const getProcedureStateId = useGetProcedureStateId()
  const stateId = useOrderStateId(order)
  const logId = R.prop('logId', order)

  const onComplete = () => {
    const soapLogModificationDate = R.prop('soapLogModificationDate', order)
    const currentStateId =
      getProcedureStateId(ProcedureState.COMPLETED) === stateId
        ? getProcedureStateId(ProcedureState.ORDERED)
        : getProcedureStateId(ProcedureState.COMPLETED)
    if (isChargeSheetEnabled && logId && soapLogModificationDate) {
      const id = areChargesPostedAndEditable
        ? R.isNil(logId)
          ? order.id
          : logId
        : logId
      const logModificationDate =
        soapLogModificationDate || order.modificationDate || ''
      dispatch(
        editChargeSheetOrder({
          id,
          type: OrderType.PROCEDURE,
          order: {
            stateId: currentStateId,
          },
          soapLogModificationDate: logModificationDate,
        }),
      )
    } else {
      dispatch(
        partialEditOrder({
          id: order.id,
          type: order.type as OrderType,
          stateId: currentStateId,
        }),
      )
    }
  }

  const isCompleted = stateId === getProcedureStateId(ProcedureState.COMPLETED)

  return (
    <Fab
      className={classNames(classes.fab, {
        [classes.fabSelected]: isCompleted,
      })}
      color="primary"
      disabled={disabled}
      type="button"
      variant="extended"
      onClick={onComplete}
    >
      {isCompleted ? t('Common:COMPLETED') : t('Common:COMPLETE_ACTION')}
    </Fab>
  )
}

export default ProcedureCompleteButton
