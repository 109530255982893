import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  CircularProgressOverlay,
  DateFormat,
  moment,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import { MigrationSteps } from '~/constants/migrationV2'
import i18n from '~/locales/i18n'
import {
  fetchMigrationSessionDetails,
  fetchMigrationSessions,
  getIsLoadingImportSessions,
  getMultipleSessions,
  getSessionsList,
} from '~/store/duck/migrationV2'
import { getPimsFull } from '~/store/reducers/constants'
import {
  BaseStepComponentProps,
  MigrationSession,
} from '~/types/entities/migrationV2'

import MigrationStep from '../core-migration/migration-step/MigrationStep'
import MigrationStepActionButton from '../core-migration/migration-step/MigrationStepActionButton'
import MigrationStepActions from '../core-migration/migration-step/MigrationStepActions'
import MigrationStepContent from '../core-migration/migration-step/MigrationStepContent'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    radioGroup: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'SessionPicker' },
)

const SessionPicker = ({
  businessId,
  stepController,
}: BaseStepComponentProps) => {
  const classes = useStyles()
  const [isInitialized, setIsInitialized] = useState(false)
  const [selectedSession, setSelectedSession] = useState<string | null>(null)
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const isLoading = useSelector(getIsLoadingImportSessions)
  const sessionsIds = useSelector(getSessionsList)
  const sessions = useSelector(getMultipleSessions(sessionsIds))
  const PimsFull = useSelector(getPimsFull)

  const buildSessionItems = ({
    id,
    creationDate,
    exporterPimsId,
    stage,
  }: MigrationSession) => {
    const dateString = moment(creationDate).format(
      DateFormat.FULL_DATE_TIME_SHORT_WITH_PIPE,
    )
    const pimsName = PimsFull.find(
      ({ id: pimsId }) => pimsId === exporterPimsId,
    )?.name
    const name = [pimsName, dateString, stage].join(' | ')

    return { id, name }
  }

  const items = sessions.map(buildSessionItems)

  useEffect(() => {
    if (!isInitialized && businessId) {
      dispatch(fetchMigrationSessions({ businessId }))
      setIsInitialized(true)
    }
  }, [isInitialized, businessId])

  const handleProceed = () => {
    if (!selectedSession) {
      return
    }
    dispatch(fetchMigrationSessionDetails({ sessionId: selectedSession }))
    stepController.next()
  }

  const createNewSession = () => {
    stepController.setCurrentStepOverride({
      migrationStep: MigrationSteps.SESSION_CREATION,
    })
  }

  const createNewSessionButtonText = i18n.t(
    'Businesses:MIGRATION.CREATE_NEW_SESSION',
  )

  return (
    <MigrationStep>
      <MigrationStepContent height={320}>
        {isLoading ? (
          <CircularProgressOverlay
            open
            preloaderText={t('Businesses:LOOKING_FOR_SESSIONS')}
          />
        ) : (
          <RadioGroup
            className={classes.radioGroup}
            name="items"
            value={selectedSession || ''}
            onChange={(_, value) => setSelectedSession(value)}
          >
            {items.map(({ id, name }) => (
              <FormControlLabel
                classes={{
                  label: classes.label,
                }}
                control={<Radio disabled={false} value={id} />}
                key={id}
                label={name}
              />
            ))}
          </RadioGroup>
        )}
      </MigrationStepContent>
      <MigrationStepActions>
        <MigrationStepActionButton
          disabled={Boolean(!selectedSession)}
          onClick={handleProceed}
        >
          {t('Common:PROCEED')}
        </MigrationStepActionButton>
        <MigrationStepActionButton
          loading={isLoading}
          onClick={createNewSession}
        >
          {createNewSessionButtonText}
        </MigrationStepActionButton>
      </MigrationStepActions>
    </MigrationStep>
  )
}

export default SessionPicker
