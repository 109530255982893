import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  CircularProgressOverlay,
  DateUtils,
  NumberUtils,
  PuiTheme,
  Utils,
} from '@pbt/pbt-ui-components'

import ScrollableDataTable, {
  ScrollableDataTableColumn,
  ScrollableDataTableProps,
} from '~/components/common/lists/ScrollableDataTable'
import { fetchSpacesListWithType } from '~/store/actions/spaces'
import {
  getInventoryDistributors,
  getInventoryManufacturers,
  getInventoryProductSizeUnit,
  getPackageType,
  getSpaceType,
} from '~/store/reducers/constants'
import {
  getMultipleSpaces,
  getSpaceCurrentTypeId,
  getSpacesIsFetching,
} from '~/store/reducers/spaces'
import { ShipmentItem } from '~/types'
import { getManualInputSelectValue } from '~/utils'
import useGetInStockAmount from '~/utils/useGetInStockAmount'

import {
  getCommonPackagingLabel,
  getReceivedDate,
  pluckStorageLocation,
  // @ts-ignore
} from '../inventoryUtils'
import { ShipmentItemsTableName } from './ShipmentItemsTableName'
import ShipmentItemStatusLabel from './ShipmentItemStatusLabel'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tableCell: {
      height: 50,
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      whiteSpace: 'nowrap',
      fontSize: '1.4rem',
      color: theme.colors.secondaryText,
    },
    leftColumn: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    tableTitle: {
      height: 38,
    },
  }),
  { name: 'ShipmentItemsTable' },
)

interface ShipmentItemsTableProps
  extends ScrollableDataTableProps<ShipmentItem> {
  isLoading?: boolean
  shipmentItems?: ShipmentItem[]
  showDate?: boolean
  showDistributor?: boolean
  showName?: boolean
}

const ShipmentItemsTable = ({
  isLoading = false,
  shipmentItems,
  showDistributor: showDistributorProp = false,
  showDate: showDateProp = true,
  showName: showNameProp = false,
  ...rest
}: ShipmentItemsTableProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Abbreviations', 'Common'])

  const getHeaderRowDefinition = ({
    showDistributor,
    showStatus,
  }: {
    showDistributor: boolean
    showStatus: boolean
  }) =>
    [
      showStatus && { label: t('Common:STATUS') },
      { label: t('Common:MANUFACTURER') },
      showDistributor && { label: t('Common:DISTRIBUTOR') },
      { label: t('Common:COST') },
      { label: t('Common:TAX') },
      { label: t('Common:RECEIVED') },
      { label: t('Common:PACKAGING') },
      { label: t('Common:LOT_SHIPMENT_NUMBER') },
      { label: t('Common:SERIAL_NUMBER_SIGN') },
      { label: t('Common:STORAGE_LOCATION') },
      { label: t('Common:PRACTICE_REFERENCE') },
      { label: t('Common:EXPIRATION_DATE') },
      { label: t('Common:MANUFACTURER_SKU') },
      { label: t('Common:DISTRIBUTOR_SKU') },
      {
        label: t(
          'Abbreviations:ACRONYMS.NATIONAL_DRUG_CODE.LABEL_ABBREVIATION',
        ),
      },
      { label: t('Abbreviations:ACRONYMS.UNIVERSAL_PRODUCT_CODE') },
    ].filter(Boolean) as { label: string }[]

  const getMainColumns = ({
    showDate,
    showName,
  }: {
    showDate: boolean
    showName: boolean
  }) =>
    [
      showDate && {
        label: t('Common:DATE_TIME'),
        prop: getReceivedDate,
      },
      showName && {
        label: t('Common:NAME'),
        // eslint-disable-next-line react/no-unstable-nested-components
        prop: (item: ShipmentItem) => (
          <ShipmentItemsTableName name={item.name} py={1} variant="body" />
        ),
      },
    ].filter(Boolean) as ScrollableDataTableColumn<ShipmentItem>[]

  const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)
  const InventoryManufacturers = useSelector(getInventoryManufacturers)
  const InventoryDistributors = useSelector(getInventoryDistributors)
  const PackageType = useSelector(getPackageType)
  const SpaceType = useSelector(getSpaceType)

  const spaceIds = pluckStorageLocation(shipmentItems || [])
  const spaces = useSelector(getMultipleSpaces(spaceIds)).filter(Boolean)
  const currentSpaceTypeId = useSelector(getSpaceCurrentTypeId)
  const spaceIsFetching = useSelector(getSpacesIsFetching)

  useEffect(() => {
    const locationTypeSpaceId = Utils.findConstantIdByName(
      'Storage location',
      SpaceType,
    )
    if (!spaces.length || currentSpaceTypeId !== locationTypeSpaceId) {
      dispatch(fetchSpacesListWithType(locationTypeSpaceId))
    }
  }, [spaces.length, currentSpaceTypeId])

  const getInStockAmount = useGetInStockAmount()
  const getPackaging = getCommonPackagingLabel(
    InventoryProductSizeUnit,
    PackageType,
  )
  const headerRow = getHeaderRowDefinition({
    showDistributor: showDistributorProp,
    showStatus: true,
  })
  const mainColumns = getMainColumns({
    showDate: showDateProp,
    showName: showNameProp,
  })

  // eslint-disable-next-line react/no-multi-comp, react/no-unstable-nested-components
  const TableCellsListComponent = ({ item }: { item: ShipmentItem }) => {
    const distributorCustomCells = showDistributorProp
      ? [
          getManualInputSelectValue(
            InventoryDistributors,
            item.distributorId,
            item.distributorName,
          ),
        ]
      : []

    const statusCustomCells = [
      <ShipmentItemStatusLabel
        key={`${item.id}-status`}
        statusId={item.statusId}
      />,
    ]

    const cells = [
      ...statusCustomCells,
      getManualInputSelectValue(
        InventoryManufacturers,
        item.manufacturerId,
        item.manufacturerName,
      ),
      ...distributorCustomCells,
      NumberUtils.formatMoney(item.costTotal),
      NumberUtils.formatMoney(item.tax),
      getInStockAmount(item),
      getPackaging(item),
      item.lotNumber,
      item.serialNumber,
      Utils.findById(item.storageLocationId, spaces)?.name,
      item.businessReferenceNumber,
      DateUtils.formatDate(item.expirationDate),
      item.manufacturerSku,
      item.distributorSku,
      item.ndc,
      item.upc,
    ]

    return (
      <>
        {cells.map((cell, index) => (
          <TableCell
            className={classes.tableCell}
            key={`variation-${item.id}-${headerRow[index]?.label}`}
          >
            {cell}
          </TableCell>
        ))}
      </>
    )
  }

  return (
    <>
      <CircularProgressOverlay
        halfTransparent
        open={isLoading || spaceIsFetching}
      />
      {(!shipmentItems || !R.isEmpty(shipmentItems)) && (
        <ScrollableDataTable
          TableCellsListComponent={TableCellsListComponent}
          classes={classes}
          data={shipmentItems || []}
          headerRow={headerRow}
          mainColumns={mainColumns}
          {...rest}
        />
      )}
    </>
  )
}

export default ShipmentItemsTable
