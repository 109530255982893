import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Business,
  Field,
  PermissionArea,
  PuiTheme,
  useFields,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  fetchEmailAppointmentConfirmationDummyConfig,
  fetchEmailAppointmentConfirmationDummyTemplate,
  fetchEmailAppointmentDummyTemplate,
  fetchEmailAppointmentInfoDummyConfig,
  getEmailAppointmentConfirmationDummyConfig,
  getEmailAppointmentDummyTemplate,
  getEmailAppointmentInfoDummyConfig,
  getEmailAppointmentTemplateIsReceiving,
} from '~/store/duck/emailAppointment'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  BasePracticeDetailsSectionProps,
  EmailEntityConfig,
  PracticeAppointmentCommunicationFields,
} from '~/types'
import { hasHtmlFieldChanged } from '~/utils/htmlUtils'
import useDialog from '~/utils/useDialog'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'
import MessageWithPreviewConfiguration from '../wellness-plans/MessageWithPreviewConfiguration'
import AdditionalAutomaticCommunicationsTable from './AdditionalAutomaticCommunicationsTable'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    communicationsTable: {
      marginTop: theme.spacing(3),
    },
    richEdit: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'AppointmentCommunicationsConfig' },
)

const AppointmentCommunicationsConfig = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Dialogs', 'Businesses'])

  const isBusinessOmniChannel = business.omniChannel
  const isHideCommunicationSettingsForOmniChannelActive = useSelector(
    getFeatureToggle(
      FeatureToggle.HIDE_COMMUNICATION_SETTINGS_FOR_OMNI_CHANNEL,
    ),
  )

  const shouldHideTextFields =
    isBusinessOmniChannel && isHideCommunicationSettingsForOmniChannelActive

  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )
  const appointmentConfirmationEmailConfig = useSelector(
    getEmailAppointmentConfirmationDummyConfig,
  )
  const appointmentInfoEmailConfig = useSelector(
    getEmailAppointmentInfoDummyConfig,
  )

  const [openEmailTemplatePreviewDialog] = useDialog(
    DialogNames.EMAIL_TEMPLATE_PREVIEW_DIALOG,
  )

  const [templatesCandidate, setTemplatesCandidate] = useState(
    business.appointmentCommunicationsConfiguration?.templates || [],
  )

  const configuration =
    business.appointmentCommunicationsConfiguration ||
    ({} as Business['appointmentCommunicationsConfiguration'])

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'newAppointmentAutoEmailEnabled',
        label: t(
          'Businesses:APPOINTMENT_COMMUNICATIONS.AUTO_EMAIL_FOR_NEW_APPOINTMENTS',
        ),
        type: 'toggle',
        initialValue: configuration?.newAppointmentAutoEmailEnabled || false,
      },
      {
        name: 'newAppointmentMessage',
        label: t(
          'Businesses:APPOINTMENT_COMMUNICATIONS.NEW_APPOINTMENT_MESSAGE',
        ),
        initialValue: configuration?.newAppointmentMessage || '',
      },
      {
        name: 'appointmentInfoMessage',
        label: t(
          'Businesses:APPOINTMENT_COMMUNICATIONS.APPOINTMENT_INFO_MESSAGE',
        ),
        initialValue: configuration?.appointmentInfoMessage || '',
      },
    ],
    false,
  )

  const {
    newAppointmentAutoEmailEnabled,
    newAppointmentMessage,
    appointmentInfoMessage,
  } = fields

  usePracticeFieldsSection<PracticeAppointmentCommunicationFields>({
    business,
    fields: {
      ...fields,
      templates: {
        name: 'templates',
        initialValue: configuration?.templates || [],
        value: templatesCandidate,
      } as Field,
    },
    parsedFields: {
      appointmentCommunicationsConfiguration: {
        ...(business?.appointmentCommunicationsConfiguration || {}),
        appointmentInfoMessage: appointmentInfoMessage.value,
        newAppointmentAutoEmailEnabled: newAppointmentAutoEmailEnabled.value,
        newAppointmentMessage: newAppointmentMessage.value,
        templates: templatesCandidate,
      },
    },
    sectionName: PracticeDetailsPanels.APPOINTMENT_COMMUNICATIONS,
    validate,
    reset,
    softUpdate: R.any(Boolean, [
      hasHtmlFieldChanged(appointmentInfoMessage),
      hasHtmlFieldChanged(newAppointmentMessage),
      newAppointmentAutoEmailEnabled.value !==
        newAppointmentAutoEmailEnabled.initialValue,
      !R.equals(configuration?.templates, templatesCandidate),
    ]),
  })

  const openNewAppointmentMessagePreview = () => {
    if (!appointmentConfirmationEmailConfig) {
      return
    }

    const config: EmailEntityConfig = {
      ...appointmentConfirmationEmailConfig,
      message: newAppointmentMessage.value,
    }

    openEmailTemplatePreviewDialog({
      title: t('Dialogs:EMAIL_TEMPLATE_PREVIEW_DIALOG.NEW_APPOINTMENT_TITLE'),
      subtitle: t(
        'Dialogs:EMAIL_TEMPLATE_PREVIEW_DIALOG.NEW_APPOINTMENT_SUBTITLE',
      ),
      fetchTemplate: () =>
        dispatch(fetchEmailAppointmentConfirmationDummyTemplate(config)),
      getTemplateSelector: getEmailAppointmentDummyTemplate,
      getIsLoadingSelector: getEmailAppointmentTemplateIsReceiving,
    })
  }

  const openAppointmentInfoMessagePreview = () => {
    if (!appointmentInfoEmailConfig) {
      return
    }

    const config: EmailEntityConfig = {
      ...appointmentInfoEmailConfig,
      message: appointmentInfoMessage.value,
    }

    openEmailTemplatePreviewDialog({
      title: t('Dialogs:EMAIL_TEMPLATE_PREVIEW_DIALOG.APPOINTMENT_TITLE'),
      subtitle: t('Dialogs:EMAIL_TEMPLATE_PREVIEW_DIALOG.APPOINTMENT_SUBTITLE'),
      fetchTemplate: () => dispatch(fetchEmailAppointmentDummyTemplate(config)),
      getTemplateSelector: getEmailAppointmentDummyTemplate,
      getIsLoadingSelector: getEmailAppointmentTemplateIsReceiving,
    })
  }

  useEffect(() => {
    if (!appointmentConfirmationEmailConfig) {
      dispatch(fetchEmailAppointmentConfirmationDummyConfig())
    }
  }, [appointmentConfirmationEmailConfig])

  useEffect(() => {
    if (!appointmentInfoEmailConfig) {
      dispatch(fetchEmailAppointmentInfoDummyConfig())
    }
  }, [appointmentInfoEmailConfig])

  return (
    <>
      <PuiSwitch
        disabled={!permissions.update}
        field={newAppointmentAutoEmailEnabled}
        label={newAppointmentAutoEmailEnabled.label}
      />
      {!shouldHideTextFields && (
        <>
          <MessageWithPreviewConfiguration
            resetStateOnValueChange
            className={classes.richEdit}
            disabled={!permissions.update}
            field={newAppointmentMessage}
            previewDisabled={!appointmentConfirmationEmailConfig}
            tooltipText={t(
              'Businesses:APPOINTMENT_COMMUNICATIONS.NEW_APPOINTMENT_MESSAGE_TOOLTIP',
            )}
            tooltipTitle={newAppointmentMessage.label}
            onPreview={openNewAppointmentMessagePreview}
          />
          <MessageWithPreviewConfiguration
            resetStateOnValueChange
            className={classes.richEdit}
            disabled={!permissions.update}
            field={appointmentInfoMessage}
            previewDisabled={!appointmentInfoEmailConfig}
            tooltipText={t(
              'Businesses:APPOINTMENT_COMMUNICATIONS.APPOINTMENT_INFO_MESSAGE_TOOLTIP',
            )}
            tooltipTitle={appointmentInfoMessage.label}
            onPreview={openAppointmentInfoMessagePreview}
          />
        </>
      )}
      <AdditionalAutomaticCommunicationsTable
        business={business}
        className={classes.communicationsTable}
        templates={templatesCandidate}
        updateTemplates={setTemplatesCandidate}
      />
    </>
  )
}

export default memo(AppointmentCommunicationsConfig, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
