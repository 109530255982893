import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import {
  LanguageUtils,
  PuiTheme,
  Text,
  WellnessPlan,
  WellnessPlanBenefit,
} from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

import { getAppointmentTypes } from '~/store/reducers/constants'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    coverageRow: {
      flex: 1,
      padding: theme.spacing(1),
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    deleteIconButton: {
      padding: 0,
    },
  }),
  { name: 'WellnessPlanBenefitAppointmentType' },
)

export interface WellnessPlanBenefitAppointmentTypeProps {
  appointmentTypeId: string
  benefit: WellnessPlanBenefit
  disableEdit: boolean
  updateBenefit: (benefit: WellnessPlanBenefit) => WellnessPlan
}

export const WellnessPlanBenefitAppointmentType = ({
  appointmentTypeId: appointmentTypeIdProp,
  benefit: benefitProp,
  disableEdit,
  updateBenefit,
}: WellnessPlanBenefitAppointmentTypeProps) => {
  const classes = useStyles()

  const AppointmentTypes = useSelector(getAppointmentTypes)

  const displayName = LanguageUtils.getConstantTranslatedName(
    appointmentTypeIdProp,
    AppointmentTypes,
  )

  const removeAppointmentTypeFromBenefit = (
    appointmentTypeId: string,
    benefit: WellnessPlanBenefit,
  ) => {
    updateBenefit({
      ...benefit,
      appointmentTypeIds: R.without(
        [appointmentTypeId],
        benefit.appointmentTypeIds,
      ),
    })
  }

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.coverageRow}
      justifyContent="space-between"
      key={appointmentTypeIdProp}
      wrap="nowrap"
    >
      <Text variant="body2">{displayName}</Text>
      {!disableEdit && (
        <IconButton
          aria-label="delete"
          className={classes.deleteIconButton}
          size="large"
          onClick={() =>
            removeAppointmentTypeFromBenefit(appointmentTypeIdProp, benefitProp)
          }
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Grid>
  )
}
