import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ClassesType,
  ImageScalingUtils,
  PatientAvatar,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import {
  Eye as EyeIcon,
  Wings as WingsIcon,
} from '@pbt/pbt-ui-components/src/icons'

import AlertLabel from '~/components/common/labels/AlertLabel'
import { getSpecies } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'

const AVATAR_SIZE = 40

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    patientAvatar: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
      backgroundColor: theme.colors.badgeColor,
      marginRight: theme.spacing(2),
    },
    patientNameContainer: {
      lineHeight: '2.4rem',
      overflow: 'hidden',
      width: 'auto',
      marginRight: theme.spacing(1),
    },
    patientNameText: {
      minWidth: 40,
    },
    showPointer: {
      cursor: 'pointer',
    },
    halfOpacity: {
      opacity: 0.5,
    },
    wingsIcon: {
      fontSize: '3.5rem',
      margin: theme.spacing(-0.5, 0),
      color: theme.colors.title,
    },
    eyeIconButton: {
      marginLeft: 'auto',
      padding: theme.spacing(0.25),
      alignSelf: 'center',
    },
    mainInfoContainer: {
      flex: 1,
    },
    alertLabelContainer: {
      marginBottom: theme.spacing(0.5),
      '&:not(:last-of-type)': {
        marginRight: theme.spacing(1),
      },
    },
    alertLabelRoot: {
      height: 24,
    },
    alertLabelText: {
      fontSize: '1.5rem',
    },
    alertLabelIcon: {
      fontSize: '4rem',
    },
    alertsContainer: {},
  }),
  { name: 'PatientItem' },
)

export interface PatientItemProps {
  Icon?: React.JSXElementConstructor<any>
  className?: string
  classes?: ClassesType<typeof useStyles>
  disableNavigation?: boolean
  onClick: (id?: string) => void
  patientId: string
}

const PatientItem = ({
  patientId,
  className,
  classes: classesProp,
  Icon,
  disableNavigation,
  onClick,
}: PatientItemProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Common', 'Clients'])

  const patient = useSelector(getPatient(patientId))
  const Species = useSelector(getSpecies)

  if (R.isNil(patient)) {
    return null
  }

  const {
    id,
    active,
    deceased,
    species: speciesProp,
    name,
    photo,
    photoThumbnail,
  } = patient

  const species = Utils.getConstantsValue(speciesProp, Species)

  const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
    photo,
    photoThumbnail,
    AVATAR_SIZE,
  )

  return (
    <Grid
      container
      className={classNames(
        !disableNavigation && classes.showPointer,
        deceased || !active ? classes.halfOpacity : '',
        className,
      )}
      wrap="nowrap"
      onClick={() => onClick(id)}
    >
      <PatientAvatar
        small
        animal={species && species.name}
        classes={{ root: classes.patientAvatar }}
        src={avatarSrc}
      />
      <Grid container direction="column">
        <Grid container className={classes.mainInfoContainer}>
          <Grid
            container
            alignItems="center"
            className={classes.patientNameContainer}
          >
            <Dotdotdot clamp={1}>
              <Text
                strong
                align="left"
                className={classes.patientNameText}
                variant="body2"
              >
                {name}
              </Text>
            </Dotdotdot>
          </Grid>
          {deceased && <WingsIcon className={classes.wingsIcon} />}
          {!disableNavigation && (
            <IconButton
              aria-label={t('Clients:PATIENT_ITEM.EXPAND_PATIENT')}
              className={classes.eyeIconButton}
              size="large"
            >
              {Icon ? <Icon /> : <EyeIcon />}
            </IconButton>
          )}
        </Grid>
        <Grid container className={classes.alertsContainer} wrap="nowrap">
          {deceased && (
            <Grid item className={classes.alertLabelContainer}>
              <AlertLabel
                classes={{
                  root: classes.alertLabelRoot,
                  text: classes.alertLabelText,
                }}
                message={t('Common:DECEASED')}
                variant="normal"
              />
            </Grid>
          )}
          {!active && (
            <Grid item className={classes.alertLabelContainer}>
              <AlertLabel
                classes={{
                  root: classes.alertLabelRoot,
                  text: classes.alertLabelText,
                }}
                message={t('Common:INACTIVE_ONE')}
                variant="normal"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PatientItem
