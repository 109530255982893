import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      [theme.breakpoints.down('md')]: {
        backgroundColor: theme.colors.listItemHover,
        justifyContent: 'center',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
      },
    },
    footerText: {
      [theme.breakpoints.down('md')]: {
        fontSize: '1.4rem',
      },
    },
  }),
  { name: 'AddItNowLink' },
)

export interface AddItNowLinkProps {
  onAddNewClinicRequested: () => void
}

const AddItNowLink = ({ onAddNewClinicRequested }: AddItNowLinkProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Registration'])

  return (
    <Grid container item className={classes.root}>
      <Text
        inline
        strong
        align="right"
        className={classes.footerText}
        variant="body2"
      >
        {t('Registration:ADD_IT_NOW_LINK.FOOTER_TEXT')}&nbsp;&nbsp;
      </Text>
      <Text
        inline
        link
        strong
        align="right"
        className={classes.footerText}
        ml={0.5}
        variant="body2"
        onClick={onAddNewClinicRequested}
      >
        {t('Common:ADD_IT_NOW')}
      </Text>
    </Grid>
  )
}

export default AddItNowLink
