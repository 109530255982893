import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PermissionArea, PuiTheme, Text } from '@pbt/pbt-ui-components'

import { getCRUDByArea } from '~/store/reducers/auth'
import { getPatientId } from '~/store/reducers/soap'

import RemindersList from '../../reminders/RemindersList'
import { useIsSoapCustomizationEnabled } from '../utils/useIsSoapCustomizationEnabled'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    titleContainer: {
      borderRight: theme.constants.tabBorder,
    },
    table: {
      border: theme.constants.tabBorder,
      borderRadius: 2,
    },
    tableRow: {
      height: 40,
      border: theme.constants.tabBorder,
    },
    tableCell: {
      padding: theme.spacing(1, 2),
      border: theme.constants.tabBorder,
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
    },
    headerCell: {
      verticalAlign: 'top',
    },
    noPaddingCell: {
      padding: 0,
    },
    appointmentHeaderRoot: {
      padding: 0,
      flexDirection: 'column',
    },
    appointmentHeaderText: {
      fontSize: '1.4rem',
      color: theme.colors.secondaryText,
      fontWeight: 400,
    },
    appointmentHeaderButton: {
      marginTop: theme.spacing(1.5),
    },
    appointmentContentRowContainer: {
      padding: theme.spacing(1, 2, 0),
    },
  }),
  { name: 'RemindersWidgetLocked' },
)

const RemindersWidgetLocked = () => {
  const classes = useStyles()

  const { t } = useTranslation('Common')

  const patientId = useSelector(getPatientId)
  const remindersPermissions = useSelector(
    getCRUDByArea(PermissionArea.REMINDERS),
  )
  const isSoapCustomizationsEnabled = useIsSoapCustomizationEnabled()

  if (!isSoapCustomizationsEnabled || !remindersPermissions.read) {
    return <></>
  }

  return (
    <Grid container item className={classes.root}>
      <Grid item className={classes.titleContainer} px={1.5} py={2} xs={2}>
        <Text variant="h4">{t('Common:REMINDERS')}</Text>
      </Grid>
      <Grid container item xs direction="column" pb={3} pt={1.5} px={2}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow className={classes.tableRow}>
              <TableCell
                className={classNames(classes.tableCell, classes.noPaddingCell)}
                width="70%"
              >
                <RemindersList
                  disableInitialAutoScroll
                  useWindowScroll
                  patientId={patientId}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

export default RemindersWidgetLocked
