import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import { inviteMember } from '~/store/actions/members'
import { getMemberIsLoading, getMembersError } from '~/store/reducers/members'
import { DataHandleWithUnsavedChanges } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import TeamMember, { TeamMemberProps } from './TeamMember'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    dialogContentRoot: {
      padding: theme.spacing(0, 3, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2, 2),
      },
    },
    button: {
      width: 150,
    },
  }),
  { name: 'TeamMemberDialog' },
)

interface TeamMemberDialogProps extends BasePuiDialogProps, TeamMemberProps {}

const TeamMemberDialog = ({
  open,
  onClose,
  ...rest
}: TeamMemberDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Dialogs'])
  const dispatch = useDispatch()
  const isLoading = useSelector(getMemberIsLoading)
  const error = useSelector(getMembersError)

  const setCloseAfterCreationOn = useCloseAfterCreation(() => {
    if (!error && onClose) {
      onClose()
    }
  }, getMemberIsLoading)

  const teamMemberRef = useRef<DataHandleWithUnsavedChanges>()

  const invite = () => {
    if (teamMemberRef?.current?.validate()) {
      setCloseAfterCreationOn()
      dispatch(inviteMember(teamMemberRef.current.get()))
    }
  }

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: invite,
      }}
      actions={
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading}
          loading={isLoading}
          onClick={invite}
        >
          {t('Common:INVITE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="team-member-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      hasUnsavedChanges={() =>
        Boolean(teamMemberRef.current?.hasUnsavedChanges())
      }
      open={open}
      title={t('Dialogs:TEAM_MEMBER_DIALOG.NEW_TEAM_MEMBER')}
      onClose={onClose}
    >
      <TeamMember ref={teamMemberRef} {...rest} />
    </PuiDialog>
  )
}

export default TeamMemberDialog
