import { gql } from '@apollo/client'

import {
  INVOICE_LINE_ITEM_FIELDS,
  INVOICE_LINE_ITEM_TOOLTIP_FIELDS,
} from './finance'

export const CHARGE_SHEET_GROUPPED_ITEMS = gql`
  ${INVOICE_LINE_ITEM_FIELDS}
  ${INVOICE_LINE_ITEM_TOOLTIP_FIELDS}
  fragment ChargeSheetGroupedItems on GroupedItems {
    ... on GroupedItem {
      id: groupId
      declined
      groupName
      subTotal
      groupSnapshotId
      groupId
      discount
      itemDiscount
      additionalDiscount
      taxAmount
      items {
        ...InvoiceLineItemFields
        ...InvoiceLineItemToolTipFields
      }
    }
    ... on InvoiceLineItem {
      ...InvoiceLineItemFields
      ...InvoiceLineItemToolTipFields
    }
  }
`
export const CHARGE_SHEET_EVENTS = gql`
  fragment ChargeSheetEvents on Appointment {
    id
    clientId
    type {
      id
      name
    }
    scheduledStartDatetime
    resources {
      employee {
        id
        firstName
        lastName
      }
      responsibility {
        id
        name
      }
    }
    businessAppointmentType {
      id
      name
    }
  }
`

export const CHARGE_SHEET_SOAP = gql`
  fragment ChargeSheetSoap on Soap {
    id
    medicalNotes
    assignedVetId
    assignedVetTechId
    finalized
  }
`

export const CHARGE_SHEET_SECTIONS_FIELDS = gql`
  ${CHARGE_SHEET_SOAP}
  ${CHARGE_SHEET_GROUPPED_ITEMS}
  fragment ChargeSheetSectionFields on ChargesSection {
    id
    soapId
    subtotal: subTotal
    totalTax: taxAmount
    discount
    additionalDiscount
    itemDiscount
    bundleAdditionalDiscount
    estimateAdditionalDiscount
    modificationDate
    amount: totalAmount
    soap {
      ...ChargeSheetSoap
    }
    # eslint-disable-next-line @graphql-eslint/no-deprecated
    eventId
    # eslint-disable-next-line @graphql-eslint/no-deprecated
    patientId
    clientId
    businessId
    groupedItems {
      ...ChargeSheetGroupedItems
    }
  }
`

export const CHARGE_SHEET_FIELDS = gql`
  ${CHARGE_SHEET_EVENTS}
  ${CHARGE_SHEET_SOAP}
  ${CHARGE_SHEET_GROUPPED_ITEMS}
  ${CHARGE_SHEET_SECTIONS_FIELDS}
  fragment ChargeSheetFields on ChargeSheet {
    id
    businessId
    amount
    amountNoFee
    subtotal: subTotal
    totalTax
    discount: totalDiscount
    type
    persons {
      id
      firstName
      lastName
    }
    prevBalance
    events {
      ...ChargeSheetEvents
    }
    sections {
      ...ChargeSheetSectionFields
    }
    modificationDate
    totalItems
  }
`
export const ORDER_FILTER = gql`
  fragment OrderFilter on OrderFilter {
    type
    name
    categoryId
    subcategoryId
    refineGroup
  }
`

export const CLIENT_FIELDS = gql`
  fragment ClientFields on Client {
    id
    firstName
    lastName
    homePhone
    mobilePhone
    email
    isBoopUser
    alertText
    alertColorId
  }
`

export const SHIPPING_ADDRESS_FIELDS = gql`
  # eslint-disable-next-line @graphql-eslint/no-unused-fragments
  fragment ShippingAddressFields on Client {
    shippingAddresses {
      id
      primary
      address {
        id
        street1
        city
      }
    }
  }
`
