import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Grid, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Nil, PuiTheme, Text, Utils } from '@pbt/pbt-ui-components'

import MarketplaceItemIframe from '~/components/common/marketplaceIframes/MarkeplaceItemIframe'
import { MarketplaceWorkflowNames } from '~/constants/marketplaceConstants'
import { getMarketplaceIFramesLoading } from '~/store/duck/marketplace'
import { useGetWorkflowIFrames } from '~/store/hooks/marketplace'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getStaticSoapWidget } from '~/store/reducers/constants'
import {
  getAppointmentId,
  getClientId,
  getPatientId,
  getSoapId,
} from '~/store/reducers/soap'
import { SoapWidgetName } from '~/types'

import MarketplaceDeactivatedLink from '../../admin/marketplace/MarketplaceDeactivatedLink'
import SoapWidget from '../SoapWidget'
import { useIsSoapCustomizationEnabled } from '../utils/useIsSoapCustomizationEnabled'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    marketplaceItem: {
      '&:first-of-type': {
        paddingTop: theme.spacing(0.5),
      },
      padding: theme.spacing(1, 2),
    },
    marketplaceItemExpanded: {
      '&:nth-of-type(even)': {
        padding: theme.spacing(1, 2, 1, 1),
      },
      '&:nth-of-type(odd)': {
        padding: theme.spacing(1, 1, 1, 2),
      },
    },
  }),
  { name: 'MarketplaceWidget' },
)

export interface MarketplaceWidgetProps {
  active?: boolean
  expanded?: boolean
  id?: string | Nil
}

const MarketplaceWidget = ({
  active = true,
  expanded,
  id,
}: MarketplaceWidgetProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const iFrames = useGetWorkflowIFrames(MarketplaceWorkflowNames.SOAP)
  const businessId = useSelector(getCurrentBusinessId)
  const soapId = useSelector(getSoapId)
  const appointmentId = useSelector(getAppointmentId)
  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)
  const iFramesLoading = useSelector(getMarketplaceIFramesLoading)
  const StaticSoapWidget = useSelector(getStaticSoapWidget)
  const isSoapCustomizationEnabled = useIsSoapCustomizationEnabled()

  // When there's no id we will be using the iFrames from redux store (e.g.: SoapRail Timeline tab)
  if (!isSoapCustomizationEnabled || !id) {
    return (
      <Grid container>
        {iFrames.map((iFrame) => (
          <Grid
            item
            className={classNames(classes.marketplaceItem, {
              [classes.marketplaceItemExpanded]: expanded,
            })}
            key={iFrame.id}
            xs={expanded ? 6 : 12}
          >
            <MarketplaceItemIframe
              preview
              id={iFrame.id}
              params={{
                businessId,
                soapId,
                appointmentId,
                clientId,
                patientId,
              }}
              title={iFrame.name}
              url={iFrame.tileUrl}
            />
          </Grid>
        ))}
      </Grid>
    )
  }

  const iFrame = iFrames.find((i) => i.id === id)
  const marketplaceWidgetId = Utils.findConstantIdByName(
    SoapWidgetName.MARKETPLACE_WIDGET,
    StaticSoapWidget,
  )

  if (!active) {
    return (
      <SoapWidget
        id="marketplace-widget-deactivated"
        title={t('Common:MARKETPLACE')}
      >
        <Text p={2} variant="lowAccent2">
          <MarketplaceDeactivatedLink
            id={`${marketplaceWidgetId}_${id}`}
            marketplaceId={id}
          />
        </Text>
      </SoapWidget>
    )
  }

  if (iFramesLoading || !iFrame) {
    return <Skeleton height={142} width="100%" />
  }

  return (
    <Box id="marketplace-widget">
      <MarketplaceItemIframe
        id={iFrame.id}
        params={{
          businessId,
          soapId,
          appointmentId,
          clientId,
          patientId,
        }}
        preview={!expanded}
        title={iFrame.name}
        url={iFrame.tileUrl}
      />
    </Box>
  )
}

export default MarketplaceWidget
