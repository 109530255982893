import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Fade, Grid, List, ListItem, ListItemText } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  CircularProgressOverlay,
  Defaults,
  ErrorTooltip,
  MarketingStylesButton,
  Nil,
  PasswordAdornment,
  PasswordInput,
  PuiTextField,
  PuiTheme,
  PuiTooltip,
  Text,
  Validators,
} from '@pbt/pbt-ui-components'
import { Warning } from '@pbt/pbt-ui-components/src/icons'

import { auth0Enabled } from '~/utils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    formContainer: {
      position: 'relative',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
      },
      width: '100%',
    },
    warningIcon: {
      color: theme.colors.errorColor,
      height: '1.8rem',
    },
    errorList: {
      width: '100%',
    },
    errorItem: {
      alignItems: 'stretch',
      padding: `${theme.spacing(0.5)} 0`,
      display: 'flex',
      transition: 'all 0.5s linear',
    },
    errorItemText: {
      fontSize: '1.4rem',
      lineHeight: '1.4rem',
      margin: 0,
    },
    show: {
      opacity: 1,
      maxHeight: 60,
    },
    hide: {
      opacity: 0,
      maxHeight: 0,
      padding: 0,
    },
    tooltipNoArrow: {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  }),
  { name: 'RegisterByInviteForm' },
)

interface RegisterByInviteFormProps {
  email: string | Nil
  isFetching?: boolean
  onSubmit: (password: string) => void
}

enum Auth0PasswordErrors {
  MIN_LENGTH = 'MIN_LENGTH',
  DIGIT = 'DIGIT',
  UPPERCASE_LETTER = 'UPPERCASE_LETTER',
  LOWERCASE_LETTER = 'LOWERCASE_LETTER',
  SPECIAL_CHARACTER = 'SPECIAL_CHARACTER',
}

const RegisterByInviteForm = ({
  onSubmit,
  email,
  isFetching = false,
}: RegisterByInviteFormProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Registration', 'Validations'])

  const [touched, setTouched] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmation, setConfirmation] = useState('')
  const [emailValidationError, setEmailValidationError] = useState('')
  const [passwordValidationError, setPasswordValidationError] = useState('')
  const [auth0PasswordErrors, setAuth0PasswordErrors] = useState<
    Auth0PasswordErrors[]
  >([])
  const [confirmationValidationError, setConfirmationValidationError] =
    useState('')
  const [showPassword, setShowPassword] = useState(false)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setTouched(false)
    setPasswordValidationError('')
    setConfirmationValidationError('')
    if (!isFetching) {
      if (!email) {
        setEmailValidationError(t('Validations:WRONG_INVITATION'))
      } else if (!password) {
        setPasswordValidationError(t('Validations:ENTER_PASSWORD'))
      } else if (!Validators.isPasswordValid(password)) {
        setPasswordValidationError(
          t('Validations:PASSWORD_MIN_LENGTH', {
            minLength: Defaults.REQUIRED_PASSWORD_LENGTH,
          }),
        )
      } else if (!confirmation) {
        setConfirmationValidationError(t('Validations:REQUIRED_FIELD'))
      } else if (
        !Validators.isPasswordConfirmationValid(password, confirmation)
      ) {
        setConfirmationValidationError(t('Validations:PASSWORD_DOES_NOT_MATCH'))
      } else if (auth0Enabled && auth0PasswordErrors.length > 0) {
        setConfirmationValidationError(t('Validations:PASSWORD_STRENGTH'))
      } else {
        onSubmit(password)
      }
    }
    return false
  }

  const auth0ErrorMap: Record<Auth0PasswordErrors, string> = {
    [Auth0PasswordErrors.DIGIT]: t('Validations:PASSWORD_DIGITS', {
      number: 1,
    }),
    [Auth0PasswordErrors.UPPERCASE_LETTER]: t(
      'Validations:PASSWORD_UPPERCASE_LETTERS',
      {
        number: 1,
      },
    ),
    [Auth0PasswordErrors.LOWERCASE_LETTER]: t(
      'Validations:PASSWORD_LOWERCASE_LETTERS',
      {
        number: 1,
      },
    ),
    [Auth0PasswordErrors.SPECIAL_CHARACTER]: t(
      'Validations:PASSWORD_SPECIAL_CHARACTERS',
      {
        number: 1,
        charSet: '!@#$%^&*',
      },
    ),
    [Auth0PasswordErrors.MIN_LENGTH]: t('Validations:PASSWORD_MIN_LENGTH', {
      minLength: Defaults.REQUIRED_PASSWORD_LENGTH,
    }),
  }

  const checkAuth0PasswordRestrictions = () => {
    if (!password) {
      setAuth0PasswordErrors([])
    } else {
      const errorList = []
      if (!Validators.isPasswordValid(password)) {
        errorList.push(Auth0PasswordErrors.MIN_LENGTH)
      }
      if (!/[a-z]/.test(password)) {
        errorList.push(Auth0PasswordErrors.LOWERCASE_LETTER)
      }
      if (!/[A-Z]/.test(password)) {
        errorList.push(Auth0PasswordErrors.UPPERCASE_LETTER)
      }
      if (!/\d/.test(password)) {
        errorList.push(Auth0PasswordErrors.DIGIT)
      }
      if (!/[!@#$%^&*]/.test(password)) {
        errorList.push(Auth0PasswordErrors.SPECIAL_CHARACTER)
      }
      setAuth0PasswordErrors(errorList)
    }
  }

  useEffect(() => {
    checkAuth0PasswordRestrictions()
  }, [password, confirmation])

  const getMessage = () => {
    if (isFetching) {
      return `${t('Common:LOADING')}...`
    }
    if (confirmation && confirmation === password) {
      return t('Registration:REGISTER_BY_INVITE_FORM.ALL_SET')
    }
    if (password && !confirmation) {
      return t('Registration:REGISTER_BY_INVITE_FORM.CONFIRM_PASSWORD')
    }
    return t('Registration:REGISTER_BY_INVITE_FORM.CREATE_PASSWORD')
  }

  const passwordValidator = (
    <>
      {password && auth0PasswordErrors.length >= 0 && (
        <List className={classes.errorList}>
          {(
            Object.keys(auth0ErrorMap) as Array<
              keyof typeof Auth0PasswordErrors
            >
          ).map((error) => (
            <ListItem
              className={classNames(
                classes.errorItem,
                auth0PasswordErrors.includes(error as Auth0PasswordErrors)
                  ? classes.show
                  : classes.hide,
              )}
              key={error}
            >
              <Warning className={classes.warningIcon} />
              <ListItemText className={classes.errorItemText}>
                {auth0ErrorMap[error]}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      )}
    </>
  )

  return (
    <form noValidate className={classes.form} onSubmit={handleSubmit}>
      <Grid
        container
        item
        alignItems="center"
        className={classes.formContainer}
        direction="column"
        pb={6}
        pt={2}
        px={3}
      >
        <CircularProgressOverlay transparent open={isFetching} />
        <Text align="center" variant="hero2">
          {t('Common:WELCOME')}
        </Text>
        <ErrorTooltip
          message={emailValidationError}
          open={Boolean(!touched && emailValidationError)}
        >
          <PuiTextField
            disabled
            autoComplete="email"
            label={t('Common:EMAIL_ADDRESS')}
            value={email || ''}
          />
        </ErrorTooltip>
        <ErrorTooltip
          message={passwordValidationError}
          open={Boolean(!touched && passwordValidationError)}
        >
          <PuiTooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 0 }}
            arrow={false}
            classes={{ tooltip: classes.tooltipNoArrow }}
            open={auth0Enabled && auth0PasswordErrors.length > 0}
            placement="right-start"
            tooltipText={passwordValidator}
          >
            <PasswordInput
              autoFocus
              adornmentEnabled={!auth0Enabled}
              disabled={isFetching}
              error={Boolean(!touched && passwordValidationError)}
              label={t('Registration:REGISTER_BY_INVITE_FORM.CREATE_PASSWORD')}
              password={password}
              validatorAdornment={
                auth0Enabled && (
                  <PasswordAdornment
                    condition={
                      Boolean(password) && auth0PasswordErrors.length === 0
                    }
                  />
                )
              }
              onChange={(event) => {
                setTouched(true)
                setPassword(event.target.value)
              }}
              onShowPassword={setShowPassword}
            />
          </PuiTooltip>
        </ErrorTooltip>
        <ErrorTooltip
          message={confirmationValidationError}
          open={Boolean(
            !touched && !passwordValidationError && confirmationValidationError,
          )}
        >
          <PuiTextField
            InputProps={{
              endAdornment: (
                <PasswordAdornment
                  condition={Validators.isPasswordConfirmationValid(
                    password,
                    confirmation,
                  )}
                />
              ),
            }}
            autoComplete="password"
            disabled={isFetching}
            error={Boolean(!touched && confirmationValidationError)}
            id="confirmation"
            label={t('Registration:REGISTER_BY_INVITE_FORM.CONFIRM_PASSWORD')}
            type={showPassword ? 'text' : 'password'}
            value={confirmation}
            onChange={(event) => {
              setTouched(true)
              setConfirmation(event.target.value)
            }}
          />
        </ErrorTooltip>
        <MarketingStylesButton className={classes.submit} type="submit">
          {getMessage()}
        </MarketingStylesButton>
      </Grid>
    </form>
  )
}

export default RegisterByInviteForm
