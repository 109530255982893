import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AddButton,
  AlertIconType,
  BasePuiDialogProps,
  ButtonWithLoader,
  CustomFieldValidatorState,
  ErrorTooltip,
  GenderRestriction,
  IdObject,
  Nil,
  PuiDialog,
  PuiSelect,
  PuiTextField,
  PuiTheme,
  Text,
  TextWithTooltip,
  UnitUtils,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { UnitTypes, WeightTypes } from '@pbt/pbt-ui-components/src/localization'

import AgeRangeSelector from '~/components/common/inputs/AgeRangeSelector'
import BooleanRadioGroup from '~/components/common/inputs/BooleanRadioGroup'
import GenderRestrictionSelect from '~/components/common/inputs/gender/GenderRestrictionSelect'
import NumericInput from '~/components/common/inputs/NumericInput'
import PuiSelectAll from '~/components/common/inputs/PuiSelectAll'
import WeightRangeInput from '~/components/common/inputs/WeightRangeInput'
import ReminderSettingsList from '~/components/dashboard/reminders/ReminderSettingsList'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { getReminderProtocolsIsLoading } from '~/store/duck/reminderProtocols'
import { getUnitsState } from '~/store/duck/settings'
import { getCurrentBusiness } from '~/store/reducers/auth'
import {
  getAgeUnits,
  getFeatureToggle,
  getReminderProtocolTimeUnits,
  getSpecies,
  getWeightUnits,
} from '~/store/reducers/constants'
import { Order, ReminderProtocol, UnsavedReminderProtocol } from '~/types'
import { getConstantsList, removeByIdList } from '~/utils'
import { getSingularOrPluralTimeUnits } from '~/utils/time'
import { checkAgeRangeValidity } from '~/utils/timeRangeUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'
import useFieldsChanged from '~/utils/useFieldsChanged'

import { hasEmptyTrigger } from './reminderProtocolUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
    dialogContentRoot: {
      padding: theme.spacing(1, 2, 3),
    },
    dueInput: {
      width: 48,
    },
    expiresInput: {
      width: 48,
    },
    ageUnitsSelect: {
      width: 80,
    },
    createButton: {
      minWidth: 150,
      height: 40,
    },
  }),
  { name: 'ReminderProtocolDialog' },
)

interface ReminderProtocolDialogProps extends BasePuiDialogProps {
  groupName: string
  onSave: (newProtocol: ReminderProtocol) => void
  protocol?: ReminderProtocol | UnsavedReminderProtocol
  triggers?: ReminderProtocol['triggersWith']
}

const ReminderProtocolDialog = ({
  open,
  groupName,
  protocol,
  triggers: triggersProp,
  onSave,
  onClose,
}: ReminderProtocolDialogProps) => {
  const classes = useStyles()

  const isEdit = Boolean(protocol)

  const AgeUnits = useSelector(getAgeUnits)
  const ReminderProtocolTimeUnits = useSelector(getReminderProtocolTimeUnits)
  const WeightUnits = useSelector(getWeightUnits)
  const Species = useSelector(getSpecies)
  const isLoading = useSelector(getReminderProtocolsIsLoading)
  const business = useSelector(getCurrentBusiness)
  const unitsState = useSelector(getUnitsState)
  const { t } = useTranslation(['Common', 'Dialogs', 'Reminders'])

  const yearId = Utils.findConstantIdByName('Year', ReminderProtocolTimeUnits)
  const defaultUnitId = Utils.findConstantIdByName(
    unitsState[UnitTypes.WEIGHT],
    WeightUnits,
  )
  const kgUnitId = Utils.findConstantIdByName(WeightTypes.KG, WeightUnits)

  const pluralTimeUnits = getSingularOrPluralTimeUnits(
    ReminderProtocolTimeUnits,
  )
  const weightMinUnitId = protocol?.weightMin?.unitId
  const weightMaxUnitId = protocol?.weightMax?.unitId

  const [openAddReminderProtocolItems] = useDialog(
    DialogNames.ADD_REMINDER_PROTOCOL_ITEMS,
  )
  const [openEmptyTriggersAlert, closeEmptyTriggersAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const closeOnSave = useCloseAfterCreation(
    onClose,
    getReminderProtocolsIsLoading,
  )

  const isExpirationDateCalculatedByReminderProtocol = useSelector(
    getFeatureToggle(
      FeatureToggle.VACCINATION_DURATION_OF_IMMUNITY_BASED_ON_REMINDER_PROTOCOL,
    ),
  )

  const dueLabel = isExpirationDateCalculatedByReminderProtocol ? (
    <TextWithTooltip
      tooltipText={t('Tooltips:DUE_DATE_WILL_BE_USED_FOR_REMINDERS')}
      variant="body"
    >
      {t('Reminders:LABEL.DUE_OR_EXPECTED_PERIOD_AMOUNT')}*
    </TextWithTooltip>
  ) : (
    <Text variant="body">
      {t('Reminders:LABEL.DUE_OR_EXPECTED_PERIOD_AMOUNT')}*
    </Text>
  )

  const validateBundleAgeRange = ({
    state: { startAgeUnit, endAgeUnit, startAge, endAge },
  }: CustomFieldValidatorState) => {
    const ageRange = {
      start: { unit: startAgeUnit, value: startAge },
      end: { unit: endAgeUnit, value: endAge },
    }
    return !startAge || !endAge || checkAgeRangeValidity(ageRange, AgeUnits)
  }

  const { fields, validate } = useFields(
    [
      {
        name: 'reminderName',
        label: t('Common:REMINDER_NAME'),
        validators: ['required'],
        initialValue: protocol?.reminderName,
      },
      {
        name: 'dueAmount',
        label: t('Reminders:LABEL.DUE_AMOUNT'),
        validators: ['required'],
        initialValue: protocol?.dueDateOffset?.amount || 1,
      },
      {
        name: 'dueUnit',
        label: t('Reminders:LABEL.DUE_UNIT'),
        type: 'select',
        validators: ['required'],
        initialValue:
          protocol?.dueDateOffset?.unit ||
          business?.defaultReminderExpirationOffset?.unit ||
          yearId,
      },
      {
        name: 'expirationDateOffsetAmount',
        label: t('Reminders:LABEL.EXPIRATION_DATE_OFFSET_AMOUNT'),
        validators: ['required'],
        initialValue:
          protocol?.expirationDateOffset?.amount ||
          business?.defaultReminderExpirationOffset?.amount,
      },
      {
        name: 'expirationDateOffsetUnit',
        label: t('Reminders:LABEL.EXPIRATION_DATE_OFFSET_UNIT'),
        type: 'select',
        validators: ['required'],
        initialValue: protocol?.expirationDateOffset?.unit || yearId,
      },
      {
        name: 'dueSameAsTrigger',
        initialValue: protocol?.dueSameAsTrigger !== false,
      },
      {
        name: 'resolveSameAsTrigger',
        initialValue: protocol?.resolveSameAsTrigger !== false,
      },
      {
        name: 'species',
        label: t('Common:SPECIES'),
        type: 'select',
        initialValue: isEdit
          ? getConstantsList(protocol?.speciesIds, Species)
          : [],
      },
      {
        name: 'genderRestrictions',
        label: t('Common:GENDER'),
        type: 'select',
        initialValue: isEdit ? protocol?.genderRestrictions || [] : [],
      },
      {
        name: 'startAge',
        label: t('Common:START_AGE'),
        initialValue: isEdit ? protocol?.ageMin?.amount : '',
        validators: [
          { validator: validateBundleAgeRange, validatorName: 'validRange' },
        ],
      },
      {
        name: 'endAge',
        initialValue: isEdit ? protocol?.ageMax?.amount : '',
      },
      {
        name: 'startAgeUnit',
        initialValue: isEdit ? protocol?.ageMin?.unitId : '',
      },
      {
        name: 'endAgeUnit',
        initialValue: isEdit ? protocol?.ageMax?.unitId : '',
      },
      {
        name: 'weightMin',
        initialValue: isEdit
          ? weightMinUnitId && weightMinUnitId !== defaultUnitId
            ? weightMinUnitId === kgUnitId
              ? UnitUtils.kgToLbs(protocol?.weightMin?.amount)
              : UnitUtils.convertUnits(
                  UnitTypes.WEIGHT,
                  protocol?.weightMin?.amount,
                  unitsState,
                )
            : protocol?.weightMin?.amount
          : '',
      },
      {
        name: 'weightMax',
        initialValue: isEdit
          ? weightMaxUnitId && weightMaxUnitId !== defaultUnitId
            ? weightMaxUnitId === kgUnitId
              ? UnitUtils.kgToLbs(protocol?.weightMax?.amount)
              : UnitUtils.convertUnits(
                  UnitTypes.WEIGHT,
                  protocol?.weightMax?.amount,
                  unitsState,
                )
            : protocol?.weightMax?.amount
          : '',
      },
      {
        name: 'weightUnit',
        initialValue: defaultUnitId,
      },
    ],
    false,
  )

  const {
    reminderName,
    dueAmount,
    dueUnit,
    expirationDateOffsetAmount,
    expirationDateOffsetUnit,
    dueSameAsTrigger,
    resolveSameAsTrigger,
    species,
    genderRestrictions,
    startAge,
    endAge,
    startAgeUnit,
    endAgeUnit,
    weightMin,
    weightMax,
    weightUnit,
  } = fields

  const initialTriggers = (isEdit ? protocol?.triggersWith : triggersProp) || []
  const [triggers, setTriggers] = useState(initialTriggers)
  const initialDues = protocol?.due || []
  const [dues, setDues] = useState(initialDues)
  const initialResolvers = protocol?.resolvesWith || []
  const [resolvers, setResolvers] = useState(initialResolvers)

  const [isFieldsChanged, setIsFieldsChanged] = useState(false)

  useFieldsChanged(() => {
    setIsFieldsChanged(true)
  }, fields)

  useEffect(() => {
    const pairs = [
      [triggers, initialTriggers],
      [dues, initialDues],
      [resolvers, initialResolvers],
    ]
    if (!R.all(([a, b]) => R.equals(a, b), pairs)) {
      setIsFieldsChanged(true)
    }
  }, [triggers, dues, resolvers])

  useEffectExceptOnMount(() => {
    if (dueSameAsTrigger.value === false) {
      setDues(triggers)
    }
  }, [dueSameAsTrigger.value])

  useEffectExceptOnMount(() => {
    if (resolveSameAsTrigger.value === false) {
      setResolvers(triggers)
    }
  }, [resolveSameAsTrigger.value])

  useEffect(() => {
    if (!reminderName.value && triggers?.length > 0) {
      reminderName.setValue(triggers[0].name)
    }
  }, [triggers])

  const handleCreateOrUpdate = (
    event?: React.MouseEvent | Nil,
    force?: boolean,
  ) => {
    setIsFieldsChanged(false)
    if (onSave && validate()) {
      const newProtocol = {
        ...(protocol || {}),
        reminderName: reminderName.value,
        dueDateOffset: {
          amount: dueAmount.value,
          unit: dueUnit.value,
        },
        expirationDateOffset: {
          amount: expirationDateOffsetAmount.value,
          unit: expirationDateOffsetUnit.value,
        },
        dueSameAsTrigger: dueSameAsTrigger.value,
        resolveSameAsTrigger: resolveSameAsTrigger.value,
        speciesIds: R.pluck('id', species.value as IdObject[]),
        genderRestrictions: (genderRestrictions.value || []).map(
          (restriction: GenderRestriction) =>
            R.pick(['genderId', 'spayedNeuteredStatusId'], restriction),
        ),
        ageMin: startAge.value
          ? {
              amount: startAge.value,
              unitId: startAgeUnit.value,
            }
          : null,
        ageMax: endAge.value
          ? {
              amount: endAge.value,
              unitId: endAgeUnit.value,
            }
          : null,
        weightMin: weightMin.value
          ? {
              amount: weightMin.value,
              unitId: weightUnit.value,
            }
          : null,
        weightMax: weightMax.value
          ? {
              amount: weightMax.value,
              unitId: weightUnit.value,
            }
          : null,
        triggersWith: triggers,
        due: dueSameAsTrigger.value ? triggers : dues,
        resolvesWith: resolveSameAsTrigger.value ? triggers : resolvers,
      } as ReminderProtocol

      if (!force && hasEmptyTrigger(newProtocol)) {
        openEmptyTriggersAlert({
          iconType: AlertIconType.WARN,
          message: t(
            'Dialogs:REMINDER_PROTOCOL_DIALOG.EMPTY_TRIGGERS_ALERT_MESSAGE',
            {
              whatIsDue: `"${t('Common:WHAT_IS_DUE_OR_EXPECTED')}"`,
              whatResolvesIt: `"${t('Common:WHAT_RESOLVES_IT')}"`,
            },
          ),
          onOk: () => {
            closeEmptyTriggersAlert()
            handleCreateOrUpdate(null, true)
          },
          onCancel: () => closeEmptyTriggersAlert(),
        })
      } else {
        onSave(newProtocol)
        closeOnSave()
      }
    }
  }

  const handleAddTrigger = () => {
    openAddReminderProtocolItems({
      onSave: (newTriggers: Partial<Order>[]) => {
        setTriggers(
          R.uniqBy(R.prop('id'), [
            ...triggers,
            ...(newTriggers as ReminderProtocol['triggersWith']),
          ]),
        )
      },
      title: t('Common:ADD_TRIGGER'),
    })
  }

  const handleAddDue = () => {
    openAddReminderProtocolItems({
      onSave: (newDues: Partial<Order>[]) => {
        setDues(
          R.uniqBy(R.prop('id'), [
            ...dues,
            ...(newDues as ReminderProtocol['triggersWith']),
          ]),
        )
      },
      title: t('Common:ADD_WHAT_IS_DUE_OR_EXPECTED'),
    })
  }

  const handleAddResolver = () => {
    openAddReminderProtocolItems({
      onSave: (newResolvers: Partial<Order>[]) => {
        setResolvers(
          R.uniqBy(R.prop('id'), [
            ...resolvers,
            ...(newResolvers as ReminderProtocol['triggersWith']),
          ]),
        )
      },
      title: t('Common:ADD_WHAT_RESOLVES_IT'),
    })
  }

  const groupNamePart = groupName ? `: ${groupName}` : ''
  const title = isEdit
    ? `${t('Common:PROTOCOL_ONE')}${groupNamePart}`
    : `${t('Common:NEW_PROTOCOL')}${groupNamePart}`

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: handleCreateOrUpdate,
      }}
      actions={
        <ButtonWithLoader
          className={classes.createButton}
          disabled={isLoading}
          loading={isLoading}
          onClick={handleCreateOrUpdate}
        >
          {isEdit ? t('Common:SAVE_PROTOCOL') : t('Common:ADD_PROTOCOL')}
        </ButtonWithLoader>
      }
      aria-labelledby="reminder-protocol-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      hasUnsavedChanges={() => isFieldsChanged}
      open={open}
      scroll="paper"
      title={title}
      onClose={onClose}
    >
      <Grid item xs={8}>
        <PuiTextField field={reminderName} label={`${reminderName.label}*`} />
      </Grid>
      <Grid container item alignItems="center" columnSpacing={1.9} mt={2}>
        <Grid item>{dueLabel}</Grid>
        <Grid item>
          <NumericInput className={classes.dueInput} field={dueAmount} />
        </Grid>
        <Grid item>
          <PuiSelect
            className={classes.ageUnitsSelect}
            field={dueUnit}
            items={pluralTimeUnits}
            renderEmpty={false}
          />
        </Grid>
        <Grid item>
          <Text variant="body">{t('Common:EXPIRES')}*</Text>
        </Grid>
        <Grid item>
          <NumericInput
            className={classes.expiresInput}
            field={expirationDateOffsetAmount}
          />
        </Grid>
        <Grid item>
          <PuiSelect
            className={classes.ageUnitsSelect}
            field={expirationDateOffsetUnit}
            items={pluralTimeUnits}
            renderEmpty={false}
          />
        </Grid>
        <Grid item>
          <TextWithTooltip
            tooltipText={t('Tooltips:REMINDER_STATUS_WILL_BE_CHANGED')}
            variant="body"
          >
            {t('Reminders:LABEL.AFTER_DUE_DATE').toLowerCase()}
          </TextWithTooltip>
        </Grid>
      </Grid>

      <Grid item mb={0.5} mt={2}>
        <TextWithTooltip
          strong
          tooltipText={t('Tooltips:WHENEVER_THESE_ITEMS_ARE_ORDERED')}
          variant="body2"
        >
          {t('Tooltips:WHAT_TRIGGERS_IT')}*
        </TextWithTooltip>
      </Grid>

      <ReminderSettingsList
        items={triggers}
        onDelete={(id) => setTriggers(removeByIdList([id], triggers))}
      />

      <Grid item mt={1}>
        <AddButton
          inline
          addText={t('Common:ADD_TRIGGER')}
          onAdd={handleAddTrigger}
        />
      </Grid>

      <Grid item mb={0.5} mt={3}>
        <TextWithTooltip
          strong
          tooltipText={t('Tooltips:REMINDER_CHOOSE_ITEMS')}
          variant="body2"
        >
          {t('Common:WHAT_IS_DUE_OR_EXPECTED')}*
        </TextWithTooltip>
      </Grid>

      <BooleanRadioGroup
        field={dueSameAsTrigger}
        noLabel={t('Common:CUSTOM')}
        yesLabel={t('Dialogs:REMINDER_PROTOCOL_DIALOG.YES_LABEL')}
      />

      {!dueSameAsTrigger.value && (
        <>
          <ReminderSettingsList
            items={dues}
            onDelete={(id) => setDues(removeByIdList([id], dues))}
          />
          <Grid item mt={1}>
            <AddButton
              inline
              addText={t('Common:ADD_WHAT_IS_DUE_OR_EXPECTED')}
              onAdd={handleAddDue}
            />
          </Grid>
        </>
      )}

      <Grid item mb={0.5} mt={3}>
        <TextWithTooltip
          strong
          tooltipText={t('Tooltips:REMINDER_ORDERING_ITEMS')}
          variant="body2"
        >
          {t('Common:WHAT_RESOLVES_IT')}*
        </TextWithTooltip>
      </Grid>

      <BooleanRadioGroup
        field={resolveSameAsTrigger}
        noLabel={t('Common:CUSTOM')}
        yesLabel={t('Dialogs:REMINDER_PROTOCOL_DIALOG.YES_LABEL')}
      />

      {!resolveSameAsTrigger.value && (
        <>
          <ReminderSettingsList
            items={resolvers}
            onDelete={(id) => setResolvers(removeByIdList([id], resolvers))}
          />
          <Grid item mt={1}>
            <AddButton
              inline
              addText={t('Common:ADD_WHAT_RESOLVES_IT')}
              onAdd={handleAddResolver}
            />
          </Grid>
        </>
      )}

      <Grid item mb={0.5} mt={3}>
        <TextWithTooltip
          strong
          tooltipText={t('Tooltips:REMINDER_ONLY_TRIGGERED')}
          variant="body2"
        >
          {t('Common:RESTRICTION_OTHER')}
        </TextWithTooltip>
      </Grid>

      <Grid container columnSpacing={3}>
        <Grid item xs={6}>
          <PuiSelectAll field={species} items={Species} label={species.label} />
        </Grid>

        <Grid item xs={6}>
          <GenderRestrictionSelect field={genderRestrictions} />
        </Grid>
      </Grid>

      <Grid container item columnSpacing={3} mt={2}>
        <Grid
          container
          item
          alignItems="center"
          columnSpacing={1}
          wrap="nowrap"
          xs={6}
        >
          <Grid item>
            <Text noWrap variant="body">
              {t('Common:AGE_RANGE_LABEL')}:
            </Text>
          </Grid>
          <Grid item>
            <ErrorTooltip
              message={t('Tooltips:PLEASE_PROVIDE_VALID_AGE_RANGE')}
              open={!startAge.valid && !isFieldsChanged}
              placement="top"
            >
              <AgeRangeSelector
                hasClearButton
                endUnit={endAgeUnit.value}
                endValue={endAge.value}
                startUnit={startAgeUnit.value}
                startValue={startAge.value}
                onEndChange={endAge.setValue}
                onEndUnitChange={endAgeUnit.setValue}
                onStartChange={startAge.setValue}
                onStartUnitChange={startAgeUnit.setValue}
              />
            </ErrorTooltip>
          </Grid>
        </Grid>
        <Grid container item alignItems="center" wrap="nowrap" xs={6}>
          <WeightRangeInput
            field={weightUnit}
            maxWeightField={weightMax}
            minWeightField={weightMin}
          />
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default ReminderProtocolDialog
