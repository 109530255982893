import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import {
  Calendar,
  DateUtils,
  Field,
  Nil,
  PuiTextField,
  Text,
} from '@pbt/pbt-ui-components'

import { getPatient } from '~/store/reducers/patients'

export interface RabiesVaccineDetailsProps {
  fields: {
    rabiesTag: Field
    rabiesTagExpires: Field
  }
  patientId: string | Nil
}

const RabiesVaccineDetails = ({
  fields,
  patientId,
}: RabiesVaccineDetailsProps) => {
  const patient = useSelector(getPatient(patientId))
  const { t } = useTranslation(['Common', 'Soap'])

  const { rabiesTag, rabiesTagExpires } = fields

  return (
    <>
      <Grid item xs={6}>
        <PuiTextField
          fullWidth
          field={rabiesTag}
          label={t('Common:RABIES_TAG')}
          margin="none"
        />
        {patient?.rabiesTag && (
          <Text variant="lowAccent3">
            {t('Soap:RABIES_VACCINE_DETAILS.CURRENT_RABIES_TAG', {
              currentTag: patient.rabiesTag,
            })}
          </Text>
        )}
      </Grid>
      <Grid item xs={6}>
        <Calendar
          fullWidth
          field={rabiesTagExpires}
          label={t('Common:RABIES_TAG_EXPIRATION')}
          margin="none"
        />
        {patient?.rabiesTagExpiration && (
          <Text variant="lowAccent3">
            {t('Soap:RABIES_VACCINE_DETAILS.CURRENT_RABIES_TAG_EXPIRATION', {
              currentTagExpiration: DateUtils.formatDate(
                patient.rabiesTagExpiration,
              ),
            })}
          </Text>
        )}
      </Grid>
    </>
  )
}

export default RabiesVaccineDetails
