import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Field,
  LanguageUtils,
  NumberUtils,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import NumericInput from '~/components/common/inputs/NumericInput'
import {
  getPriceUnitsValuedFromId,
  // @ts-ignore
} from '~/components/dashboard/admin/catalog/inventory/inventoryUtils'
import { useGetInventoryPriceUnits } from '~/store/hooks/inventories'
import { Price, Variation } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    quantityInput: {
      maxWidth: 120,
      marginTop: theme.spacing(-2),
    },
  }),
  { name: 'ChargedPriceRow' },
)

export interface ChargedPriceRowProps {
  disabled?: boolean
  field?: Field
  isChosen: boolean
  isLoading?: boolean
  price: Price
  variation: Variation
}

const ChargedPriceRow = ({
  disabled,
  isChosen,
  price,
  field,
  variation,
  isLoading,
}: ChargedPriceRowProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const { perPackageUnitsId, packageTypeId } = variation
  const { unitId, price: priceValue, priceUnitsSize } = price

  const ResolvedPriceUnits = useGetInventoryPriceUnits(
    perPackageUnitsId,
    packageTypeId,
    priceUnitsSize,
  )

  const isPlural = priceUnitsSize && priceUnitsSize > 1
  const pluralUnitId = getPriceUnitsValuedFromId(unitId, isPlural)
  const priceUnit = Utils.findById(pluralUnitId, ResolvedPriceUnits)

  const priceNameStr = price.name ? `${price.name} ` : ''
  const priceUnitStr = priceUnit?.name
    ? `/${LanguageUtils.getTranslatedFieldName(priceUnit)}`
    : ''
  const priceDetails = `${priceNameStr}(${NumberUtils.formatMoney(
    priceValue,
  )}${priceUnitStr})`

  return (
    <Grid container alignItems="center" columnSpacing={2} wrap="nowrap">
      <Grid item>
        <NumericInput
          allowDecimal
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.quantityInput}
          disabled={disabled || !isChosen}
          field={field}
          inputProps={{ maxLength: 10 }}
          isLoading={isLoading}
          label={t('Common:QUANTITY')}
          margin="none"
          min={0}
        />
      </Grid>
      <Grid item>
        <Text variant="body">{priceDetails}</Text>
      </Grid>
    </Grid>
  )
}

export default ChargedPriceRow
