import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { SelectChangeEvent } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { Nil, PuiDialog, PuiTheme } from '@pbt/pbt-ui-components'

import {
  getPayPassAppUrl,
  getRhapsodyPayApplicationUrl,
  getRhapsodyPayIsLoading,
} from '~/store/duck/rhapsodyPay'
import {
  getPayGoConfigList,
  getPayPassConfigList,
} from '~/store/reducers/constants'
import { RhapsodyPayConfig } from '~/types'

import RhapsodyPayGoConfiguration from './RhapsodyPaySetupSteps/RhapsodyGoConfiguration'
import RhapsodyPayCardProcessing from './RhapsodyPaySetupSteps/RhapsodyPayCardProcessing'
import RhapsodyPayPayment from './RhapsodyPaySetupSteps/RhapsodyPayPayment'
import RhapsodyPayRates from './RhapsodyPaySetupSteps/RhapsodyPayRates'
import RhapsodyPaySummary from './RhapsodyPaySetupSteps/RhapsodyPaySummary'
import RhapsodyPayTerminalOrdering from './RhapsodyPaySetupSteps/RhapsodyPayTerminalOrdering'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      overflowY: 'visible',
      width: 660,
      maxWidth: 660,
      height: 550,
    },
    dialogTitle: {
      border: 'none',
      padding: theme.spacing(3, 2, 2, 3),
    },
    successAlert: {
      width: 400,
      maxWidth: 400,
      height: 'auto',
    },
  }),
  { name: 'RhapsodyPaySetupDialog' },
)

export enum Steps {
  RATES = 'RATES',
  TERMINALS_ORDER = 'TERMINALS_ORDER',
  CARD_PROCESSING = 'CARD_PROCESSING',
  RHAPSODY_GO = 'RHAPSODY_GO',
  SUMMARY = 'SUMMARY',
  PAYMENT = 'PAYMENT',
  INITIAL = 'INITIAL',
}

interface RhapsodyPaySetupDialogProps {
  businessId: string
  onClose: () => void
  open: boolean
  rhapsodyPayConfig: RhapsodyPayConfig | Nil
  step: Steps
}

const RhapsodyPaySetupDialog = ({
  businessId,
  step: stepProp,
  open,
  rhapsodyPayConfig,
  onClose,
}: RhapsodyPaySetupDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Businesses')

  const isLoading = useSelector(getRhapsodyPayIsLoading)
  const rhapsodyPayAppUrl = useSelector(getRhapsodyPayApplicationUrl)
  const payPassConfigList = useSelector(getPayPassConfigList)
  const payGoConfigList = useSelector(getPayGoConfigList)

  const [step, setStep] = useState(stepProp)
  const [posPayConfig, setPosPayConfig] = useState({
    ...rhapsodyPayConfig,
    goOption: rhapsodyPayConfig?.goOption || payGoConfigList[0]?.id,
    deviceCount: rhapsodyPayConfig?.deviceCount || 1,
    passOption: rhapsodyPayConfig?.deviceCount
      ? String(rhapsodyPayConfig?.deviceCount)
      : payPassConfigList[0]?.id,
  })

  const doClose = () => {
    setStep(stepProp || Steps.RATES)
    setPosPayConfig({
      ...rhapsodyPayConfig,
      goOption: rhapsodyPayConfig?.goOption || payGoConfigList[0]?.id,
      deviceCount: rhapsodyPayConfig?.deviceCount || 1,
      passOption: rhapsodyPayConfig?.deviceCount
        ? String(rhapsodyPayConfig?.deviceCount)
        : payPassConfigList[0]?.id,
    })
    onClose()
  }

  useEffect(() => {
    if (!R.equals(rhapsodyPayConfig ?? {}, posPayConfig)) {
      setPosPayConfig({
        ...rhapsodyPayConfig,
        goOption: rhapsodyPayConfig?.goOption || payGoConfigList[0]?.id,
        deviceCount: rhapsodyPayConfig?.deviceCount || 1,
        passOption: rhapsodyPayConfig?.passOption || payPassConfigList[0]?.id,
      })
    }
  }, [rhapsodyPayConfig, payPassConfigList, payGoConfigList])

  useEffect(() => {
    if (rhapsodyPayAppUrl) {
      setStep(Steps.PAYMENT)
    }
  }, [rhapsodyPayAppUrl])

  useEffect(() => {
    setStep(stepProp || Steps.INITIAL)
  }, [stepProp])

  const showTerminalsConfigStep = () => {
    setStep(Steps.TERMINALS_ORDER)
  }

  const showRhapsodyRatesStep = () => {
    setStep(Steps.RATES)
  }

  const showCardProcessingStep = () => {
    setStep(Steps.CARD_PROCESSING)
  }

  const showRhapsodyGoStep = () => {
    setStep(Steps.RHAPSODY_GO)
  }

  const showSummaryStep = () => {
    setStep(Steps.SUMMARY)
  }

  const applyRhapsodyPayConfig = () => {
    dispatch(getPayPassAppUrl(posPayConfig.goOption, posPayConfig.passOption))
  }

  const onTerminalsQuantityUpdate = (value: number) => {
    setPosPayConfig({ ...posPayConfig, deviceCount: value })
  }

  const onPercentageChange = ({
    target: { value },
  }: SelectChangeEvent<unknown>) => {
    setPosPayConfig({ ...posPayConfig, passOption: value as string })
  }

  const onRhapsodyGoProvidingChange = (value: string) => {
    setPosPayConfig({ ...posPayConfig, goOption: value })
  }

  return (
    <PuiDialog
      aria-labelledby="rhapsody-pay-setup"
      classes={{
        paper: classNames(classes.paper, {
          [classes.successAlert]: step === Steps.PAYMENT,
        }),
        dialogTitle: classes.dialogTitle,
      }}
      open={open}
      scroll="paper"
      title={
        <>
          {step === Steps.RATES && t('Businesses:RHAPSODY_PAY.STEPS.RATES')}
          {step === Steps.TERMINALS_ORDER &&
            t('Businesses:RHAPSODY_PAY.STEPS.TERMINALS_ORDER')}
          {step === Steps.CARD_PROCESSING &&
            t('Businesses:RHAPSODY_PAY.STEPS.CARD_PROCESSING')}
          {step === Steps.RHAPSODY_GO &&
            t('Businesses:RHAPSODY_PAY.STEPS.RHAPSODY_GO')}
          {step === Steps.SUMMARY && t('Businesses:RHAPSODY_PAY.STEPS.SUMMARY')}
        </>
      }
      onClose={doClose}
    >
      {step === Steps.RATES && (
        <RhapsodyPayRates onProceed={showTerminalsConfigStep} />
      )}
      {step === Steps.TERMINALS_ORDER && (
        <RhapsodyPayTerminalOrdering
          posPayConfig={posPayConfig}
          onBack={showRhapsodyRatesStep}
          onProceed={showCardProcessingStep}
          onTerminalsQuantityUpdate={onTerminalsQuantityUpdate}
        />
      )}
      {step === Steps.CARD_PROCESSING && (
        <RhapsodyPayCardProcessing
          payPassConfigList={payPassConfigList}
          posPayConfig={posPayConfig}
          onBack={showTerminalsConfigStep}
          onPercentageChange={onPercentageChange}
          onProceed={showRhapsodyGoStep}
        />
      )}
      {step === Steps.RHAPSODY_GO && (
        <RhapsodyPayGoConfiguration
          payGoConfigList={payGoConfigList}
          posPayConfig={posPayConfig}
          onBack={showCardProcessingStep}
          onProceed={showSummaryStep}
          onRhapsodyGoProvidingChange={onRhapsodyGoProvidingChange}
        />
      )}
      {step === Steps.SUMMARY && (
        <RhapsodyPaySummary
          isLoading={isLoading}
          payGoConfigList={payGoConfigList}
          payPassConfigList={payPassConfigList}
          posPayConfig={posPayConfig}
          onBack={showRhapsodyGoStep}
          onPercentageChange={onPercentageChange}
          onProceed={applyRhapsodyPayConfig}
          onRhapsodyGoProvidingChange={onRhapsodyGoProvidingChange}
          onTerminalsQuantityUpdate={onTerminalsQuantityUpdate}
        />
      )}
      {step === Steps.PAYMENT && (
        <RhapsodyPayPayment
          businessId={businessId}
          firstAmericanUrl={rhapsodyPayAppUrl}
          posPayConfig={posPayConfig}
        />
      )}
    </PuiDialog>
  )
}

export default RhapsodyPaySetupDialog
