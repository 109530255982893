import React, { useEffect } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  Business,
  PermissionArea,
  PuiTheme,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import { updateDiscountReasonStatus } from '~/store/duck/discountReasons'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import { getMemberIsLoading } from '~/store/reducers/members'
import { DiscountReason } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tableItem: {
      borderBottom: theme.constants.tableBorder,
    },
    reasonColumn: {
      borderLeft: theme.constants.tableBorder,
    },
  }),
  { name: 'TeamConfigurationItem' },
)

interface DiscountConfigurationItemProps {
  business?: Business
  className?: string
  reason: DiscountReason
}

const DiscountConfigurationItem = ({
  className,
  reason,
  business,
}: DiscountConfigurationItemProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoading = useSelector(getMemberIsLoading)

  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.REASON_FOR_DISCOUNT, business),
  )

  const {
    fields: { active },
  } = useFields(
    [
      {
        name: 'active',
        type: 'toggle',
        validators: ['required'],
        initialValue: reason.active,
      },
    ],
    false,
  )

  useEffect(() => {
    if (reason.active !== active.value) {
      dispatch(
        updateDiscountReasonStatus(business?.id || '', reason.id, active.value),
      )
    }
  }, [active.value])

  return (
    <Grid
      container
      item
      className={classNames(classes.tableItem, className)}
      wrap="nowrap"
    >
      <Grid container item alignItems="center" px={1.5} xs={1}>
        <PuiSwitch disabled={isLoading || !permissions.update} field={active} />
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classes.reasonColumn}
        px={1.5}
      >
        <Dotdotdot clamp={2}>
          <Text disabled={!active.value} variant="body2">
            {reason.name}
          </Text>
        </Dotdotdot>
      </Grid>
    </Grid>
  )
}

export default DiscountConfigurationItem
