import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useSelector } from 'react-redux'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { head } from 'ramda'
import {
  Constant,
  ImageScalingUtils,
  PatientAvatar,
  PuiTheme,
  StateLabel,
  TextInteractive,
  Utils,
} from '@pbt/pbt-ui-components'
import {
  Eye as EyeIcon,
  Wings as WingsIcon,
} from '@pbt/pbt-ui-components/src/icons'

import Link from '~/components/common/link/Link'
import DialogNames from '~/constants/DialogNames'
import { getSpecies } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getPatientStatus } from '~/utils'
import useDialog from '~/utils/useDialog'

const AVATAR_SIZE = 32

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    rootDisabled: {
      opacity: 0.5,
    },
    disabledContainer: {
      height: 20,
    },
    avatar: {
      minWidth: AVATAR_SIZE,
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
      marginRight: theme.spacing(1),
    },
    icon: {
      fontSize: '2rem',
    },
    iconButton: {
      padding: theme.spacing(0.5),
    },
    wingsIcon: {
      fontSize: '3rem',
      color: theme.colors.title,
      marginRight: theme.spacing(0.5),
    },
    link: {
      color: 'inherit',
      textDecoration: 'none',
      wordBreak: 'break-word',
    },
  }),
  { name: 'MembershipPatientCell' },
)

interface MembershipPatientCellProps {
  clientId?: string
  isBoop?: boolean
  patient?: string
  planLogId?: string
}

const MembershipPatientCell = ({
  patient: patientId,
  clientId,
  isBoop,
  planLogId,
}: MembershipPatientCellProps) => {
  const classes = useStyles()
  const Species: Constant[] = useSelector(getSpecies)
  const patient = useSelector(getPatient(patientId))

  const patientStatus = getPatientStatus(patient)

  const [openPatientMembershipDialog] = useDialog(
    DialogNames.PATIENT_MEMBERSHIP,
  )

  const isLoading = !patientId && !clientId

  const onEdit = () => {
    openPatientMembershipDialog({
      clientId,
      patientId,
    })
  }

  const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
    patient?.photo,
    patient?.photoThumbnail,
    AVATAR_SIZE,
  )

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(classes.root, {
        [classes.rootDisabled]: patientStatus.disabled,
      })}
      justifyContent="space-between"
      wrap="nowrap"
    >
      <Grid container item alignItems="center" wrap="nowrap">
        <PatientAvatar
          animal={Utils.getConstantName(patient?.species, Species)}
          classes={{
            root: classes.avatar,
            defaultIcon: classes.icon,
          }}
          isLoading={isLoading}
          showBoopBadge={isBoop}
          src={avatarSrc}
        />
        <Grid container item alignItems="flex-start" direction="column">
          {patientStatus.disabled && (
            <Grid
              container
              item
              alignItems="center"
              className={classes.disabledContainer}
              wrap="nowrap"
            >
              {patient?.deceased && patient?.active !== false && (
                <WingsIcon className={classes.wingsIcon} />
              )}
              <StateLabel disabled variant="small">
                {head(patientStatus.reasons)}
              </StateLabel>
            </Grid>
          )}
          <Dotdotdot clamp={1}>
            <TextInteractive isLoading={isLoading}>
              <Link className={classes.link} to={`/patient/${patientId}`}>
                {patient?.name}
              </Link>
            </TextInteractive>
          </Dotdotdot>
        </Grid>
      </Grid>
      {planLogId && (
        <IconButton
          className={classes.iconButton}
          size="large"
          onClick={onEdit}
        >
          <EyeIcon />
        </IconButton>
      )}
    </Grid>
  )
}

export default MembershipPatientCell
