import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Divider, Grid, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as R from 'ramda'
import { CurrencyTextField, PuiTheme, Text } from '@pbt/pbt-ui-components'
import { findConstantIdByName } from '@pbt/pbt-ui-components/src/utils'

import { getRefundInvoiceState } from '~/store/reducers/constants'
import { InvoiceLineItem, RefundLineItem } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    totalRefundedText: {
      fontWeight: 500,
      color: theme.colors.secondaryText,
      textAlign: 'end',
    },
    row: {
      width: 98,
      justifyContent: 'flex-end',
    },
    refundDetailText: {
      color: theme.colors.lowAccentText,
      textAlign: 'end',
    },
  }),
  { name: 'ChargeItemRefundDetails' },
)

export interface ChargeItemRefundDetailsProps {
  item: InvoiceLineItem
}

export const ChargeItemRefundDetails = ({
  item,
}: ChargeItemRefundDetailsProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Invoices')
  const refundInvoiceStates = useSelector(getRefundInvoiceState)
  const voidedStateId = findConstantIdByName('Voided', refundInvoiceStates)

  const notVoid = (refundItem: RefundLineItem) =>
    refundItem.refundedInvoice.state.id !== voidedStateId

  const refundLineItems = R.filter(notVoid, item.refundLineItems || [])

  const { refundedAmount, restockedAmount, totalRefunded } = R.reduce(
    (acc, refundLineItem) => {
      acc.refundedAmount += refundLineItem.refundQty
      acc.restockedAmount += refundLineItem.restockQty
      acc.totalRefunded += refundLineItem.subTotal
      return acc
    },
    { refundedAmount: 0, restockedAmount: 0, totalRefunded: 0 },
    refundLineItems,
  )

  return (
    <Grid container mt={2} pl={0.5} pr={1}>
      <Text fontWeight={500}>
        {t('Invoices:LINE_ITEM_DETAIL.REFUND_HEADER')}
      </Text>
      <Stack
        direction="column"
        // When using Divider with Stack we need to render conditional its child,
        // even if child component returns nothing
        divider={
          <Divider
            component="hr"
            orientation="horizontal"
            sx={{ width: 'auto' }}
          />
        }
      >
        <Grid
          container
          item
          alignItems="center"
          flexWrap="nowrap"
          justifyContent="space-between"
        >
          <Text
            className={classes.refundDetailText}
            lineHeight={2}
            p={1}
            variant="body"
          >
            {t('Invoices:LINE_ITEM_DETAIL.REFUND_QTY')}
          </Text>
          <Grid container item className={classes.row}>
            <Text className={classes.refundDetailText}>{refundedAmount}</Text>
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          flexWrap="nowrap"
          justifyContent="space-between"
        >
          <Text
            className={classes.refundDetailText}
            lineHeight={2}
            p={1}
            variant="body"
          >
            {t('Invoices:LINE_ITEM_DETAIL.RESTOCK_QTY')}
          </Text>
          <Grid container item className={classes.row}>
            <Text className={classes.refundDetailText}>{restockedAmount}</Text>
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          flexWrap="nowrap"
          justifyContent="space-between"
        >
          <Text
            className={classes.totalRefundedText}
            lineHeight={2}
            p={1}
            variant="body"
          >
            {t('Invoices:LINE_ITEM_DETAIL.TOTAL_REFUNDED')}
          </Text>
          <CurrencyTextField
            noLonelyAdornment
            InputProps={{ disableUnderline: true }}
            className={classNames(classes.totalRefundedText, classes.row)}
            fullWidth={false}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              className: classes.totalRefundedText,
            }}
            value={totalRefunded}
          />
        </Grid>
      </Stack>
    </Grid>
  )
}
