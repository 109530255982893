import React, { forwardRef, MutableRefObject, useLayoutEffect } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { PuiTheme } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    meshContainer: {
      alignSelf: 'stretch',
      paddingLeft: theme.spacing(3.5),
      paddingRight: theme.spacing(3.5),
      overflowX: 'hidden',
    },
    mesh: {
      height: '100%',
      borderLeft: theme.constants.filterBorder,
      minWidth: theme.spacing(13),
      width: theme.spacing(13),
      paddingLeft: theme.spacing(1),
      '&:last-child': {
        marginRight: '100vw',
      },
    },
  }),
  { name: 'Mesh' },
)

const defaultGetColumnId = R.prop('id')

export interface MeshProps {
  children?: (column: any) => React.ReactNode
  className?: string
  columns: any[]
  getColumnId?: (column: any) => string
  scrollOffset?: number
}

const Mesh = forwardRef<HTMLDivElement, MeshProps>(function Mesh(
  {
    columns,
    children,
    className,
    getColumnId = defaultGetColumnId,
    scrollOffset,
  },
  ref,
) {
  const classes = useStyles()

  useLayoutEffect(() => {
    const current = (ref as MutableRefObject<HTMLDivElement>)?.current
    if (current && scrollOffset) {
      current.scrollLeft = scrollOffset
    }
  }, [])

  return (
    <Grid
      container
      item
      className={classNames(className, classes.meshContainer)}
      ref={ref}
      wrap="nowrap"
      xs={5}
    >
      {columns.map((column) => (
        <Grid
          container
          item
          alignItems="center"
          className={classes.mesh}
          key={getColumnId(column)}
        >
          {children && children(column)}
        </Grid>
      ))}
    </Grid>
  )
})

export default Mesh
