import React from 'react'
import { makeStyles } from '@mui/styles'
import { ClassesType, PuiTheme } from '@pbt/pbt-ui-components'

import StaticTable, {
  StaticTableColumn,
  StaticTableRow,
} from '~/components/common/lists/table/StaticTable'

import {
  CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT,
  TableColumnWidth,
} from './tableConstants'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    cell: {
      padding: theme.spacing(0, 2),
      border: theme.constants.tabBorder,
      lineHeight: '2.1rem',
      '&:nth-child(1)': {
        width: TableColumnWidth.FIRST,
      },
      '&:nth-child(2)': {
        width: TableColumnWidth.SECOND,
      },
      '&:nth-child(3)': {
        width: TableColumnWidth.THIRD,
      },
      '&:nth-child(4)': {
        width: TableColumnWidth.FOURTH,
      },
      '&:nth-child(5)': {
        width: TableColumnWidth.FIFTH,
      },
      '&:nth-child(6)': {
        width: TableColumnWidth.SIXTH,
      },
      '&:nth-child(7)': {
        width: TableColumnWidth.SEVENTH,
      },
    },
    header: {
      height: CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT,
    },
    row: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
      height: CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT,
    },
    table: {
      margin: -1,
      width: 'calc(100% + 2px)',
      border: theme.constants.tabBorder,
    },
    titleCell: {
      border: '0 !important',
      padding: `${theme.spacing(0.5, 2)} !important`,
      fontWeight: `${500} !important`,
    },
  }),
  { name: 'ClientBillingActivityTable' },
)

interface ClientBillingActivityTableProps<T extends StaticTableRow> {
  classes?: ClassesType<typeof useStyles>
  columns: StaticTableColumn<T>[]
  isLoading: boolean
  itemsPerPage: number
  rowItems: T[] | undefined
}

export const ClientBillingActivityTable = <T extends StaticTableRow>({
  classes: classesProp,
  columns,
  isLoading,
  itemsPerPage,
  rowItems,
}: ClientBillingActivityTableProps<T>) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <StaticTable
      classes={{
        cell: classes.cell,
        row: classes.row,
        table: classes.table,
        titleCell: classes.titleCell,
        header: classes.header,
      }}
      columns={columns}
      rows={
        isLoading
          ? (Array.from({ length: itemsPerPage }, () => []) as any)
          : rowItems
      }
    />
  )
}
