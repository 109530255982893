import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'
import { Exclamation } from '@pbt/pbt-ui-components/src/icons'

import { ProblemDiagnosisLog } from '~/types'

import ProblemsDiagnosesTableBodySystem from './ProblemsDiagnosesTableBodySystem'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      border: theme.constants.tableBorder,
    },
    icon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      borderRadius: theme.spacing(1),
      backgroundColor: theme.colors.success,
      color: theme.colors.tableBackground,
      marginRight: theme.spacing(1),
      verticalAlign: 'text-top',
      padding: 1,
    },
    infoIcon: {
      backgroundColor: theme.colors.alertError,
    },
  }),
  { name: 'ProblemsDiagnosesTable' },
)

interface ProblemsDiagnosesTableProps {
  problemFindingLogs: ProblemDiagnosisLog[]
}

const ProblemsDiagnosesTable = ({
  problemFindingLogs,
}: ProblemsDiagnosesTableProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Soap')

  const bodySystemsIds = R.uniq(problemFindingLogs.map(R.prop('bodySystemId')))

  return (
    <Grid
      container
      item
      className={classes.root}
      direction="column"
      display="flex"
    >
      <Grid container item p={1} xs={12}>
        <Text strong variant="body">
          <Exclamation className={classNames(classes.icon, classes.infoIcon)} />
          {t('Soap:FINDINGS_TABLE.OUTSIDE_NORMAL_LIMITS_TITLE')}
        </Text>
      </Grid>
      {bodySystemsIds.map((bodySystemId: string) => (
        <ProblemsDiagnosesTableBodySystem
          bodySystemId={bodySystemId}
          key={bodySystemId}
          logs={problemFindingLogs.filter(
            (log) => log.bodySystemId === bodySystemId,
          )}
        />
      ))}
    </Grid>
  )
}
export default ProblemsDiagnosesTable
