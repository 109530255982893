import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { NamedEntity, PuiTheme } from '@pbt/pbt-ui-components'

import ReminderSettingsItem from './ReminderSettingsItem'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      border: theme.constants.tableBorder,
      borderRadius: 2,
    },
  }),
  { name: 'ReminderSettingsList' },
)

export interface ReminderSettingsListProps {
  items: NamedEntity[]
  onDelete: (id: string) => void
}

const ReminderSettingsList = ({
  items,
  onDelete,
}: ReminderSettingsListProps) => {
  const classes = useStyles()

  return items.length > 0 ? (
    <Grid container className={classes.root} direction="column">
      {items.map(({ id, name }) => (
        <ReminderSettingsItem
          key={id}
          name={name}
          onDelete={() => onDelete(id)}
        />
      ))}
    </Grid>
  ) : null
}

export default ReminderSettingsList
