import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  AppointmentCommunicationTemplate,
  BasePuiDialogProps,
  Business,
  ButtonWithLoader,
  PuiDialog,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import { AutomaticCommunicationType } from '~/components/common/automaticCommunication/AutomaticCommunicationPreviewDialog'
import PreviewButton from '~/components/common/buttons/PreviewButton'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'
import { DataHandle } from '~/types'
import useDialog from '~/utils/useDialog'

import AutomaticCommunication from './AutomaticCommunication'
import { AutomaticCommunicationBaseProps } from './interfaces'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 1025,
      maxWidth: 1025,
    },
    actions: {
      padding: theme.spacing(2, 3),
    },
    dialogTitle: {
      paddingLeft: theme.spacing(3),
    },
    automaticCommunication: {
      paddingBottom: theme.spacing(3),
    },
    button: {
      width: 120,
      marginRight: theme.spacing(1.5),
    },
  }),
  { name: 'AutomaticCommunicationDialog' },
)

export interface AutomaticCommunicationDialogProps
  extends AutomaticCommunicationBaseProps,
    BasePuiDialogProps {
  business: Business
  onProceed: (automaticCommunication: AppointmentCommunicationTemplate) => void
}

const AutomaticCommunicationDialog = ({
  business,
  open,
  automaticCommunication,
  onClose,
  onProceed,
}: AutomaticCommunicationDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Businesses'])
  const isSendReminderViaSmsBoopEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SEND_REMINDER_VIA_SMS_BOOP),
  )
  const isBusinessOmniChannel = business.omniChannel
  const isEdit = Boolean(automaticCommunication)

  const [openPreviewDialog] = useDialog(
    DialogNames.AUTOMATIC_COMMUNICATION_PREVIEW,
  )

  const automaticCommunicationRef = useRef<DataHandle>()

  const handleSave = () => {
    if (automaticCommunicationRef.current?.validate()) {
      onProceed(automaticCommunicationRef.current?.get())
      if (onClose) {
        onClose()
      }
    }
  }

  const handlePreview = () => {
    openPreviewDialog({
      business,
      automaticCommunication: automaticCommunicationRef.current?.get(),
      type: AutomaticCommunicationType.APPOINTMENT,
    })
  }

  return (
    <PuiDialog
      actions={
        <>
          <ButtonWithLoader
            className={classes.button}
            type="submit"
            onClick={handleSave}
          >
            {isEdit ? t('Common:SAVE_ACTION') : t('Common:ADD_ACTION')}
          </ButtonWithLoader>
          {!isBusinessOmniChannel && isSendReminderViaSmsBoopEnabled && (
            <PreviewButton onClick={handlePreview} />
          )}
        </>
      }
      aria-labelledby="automatic-communication-dialog"
      classes={{
        paper: classes.paper,
        actions: classes.actions,
        dialogTitle: classes.dialogTitle,
      }}
      open={open}
      title={`${isEdit ? t('Common:EDIT_ACTION') : t('Common:ADD_ACTION')} ${t(
        'Businesses:APPOINTMENT_COMMUNICATIONS.AUTOMATIC_COMMUNICATION',
      ).toLowerCase()}`}
      onClose={onClose}
    >
      <AutomaticCommunication
        automaticCommunication={automaticCommunication}
        business={business}
        className={classes.automaticCommunication}
        ref={automaticCommunicationRef}
      />
    </PuiDialog>
  )
}

export default AutomaticCommunicationDialog
