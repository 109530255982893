import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { EditSignature, PuiTheme, Text } from '@pbt/pbt-ui-components'

import { updateUserSignature } from '~/store/actions/users'
import { getCurrentUser } from '~/store/reducers/auth'
import { getUserIsFetching } from '~/store/reducers/users'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import ProfileBottomContainer from './ProfileBottomContainer'
import ProfileTopContainer from './ProfileTopContainer'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    profileTopContainer: {
      height: 113,
      position: 'relative',
      justifyContent: 'center',
    },
    backToProfileTextContainer: {
      cursor: 'pointer',
    },
    backArrow: {
      color: theme.colors.iconColor,
    },
    backToProfileText: {
      color: theme.colors.iconColor,
    },
    profileBottomContainer: {
      marginTop: theme.spacing(3),
      paddingBottom: theme.spacing(28.5),
    },
  }),
  { name: 'ProfileSavedSignature' },
)

export interface ProfileSavedSignatureProps {
  onBack: () => void
}

const ProfileSavedSignature = ({ onBack }: ProfileSavedSignatureProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const currentUser = useSelector(getCurrentUser)
  const isFetching = useSelector(getUserIsFetching)

  const setCloseAfterSignatureSave = useCloseAfterCreation(
    onBack,
    getUserIsFetching,
  )

  const handleSignatureSave = (signature: string) => {
    dispatch(updateUserSignature(currentUser.id, signature))
    setCloseAfterSignatureSave()
  }

  return (
    <Grid
      container
      item
      alignItems="center"
      direction="column"
      justifyContent="center"
      width={592}
    >
      <ProfileTopContainer className={classes.profileTopContainer}>
        <Grid
          container
          item
          alignItems="center"
          className={classes.backToProfileTextContainer}
          pl={4}
          onClick={onBack}
        >
          <ArrowBackIcon className={classes.backArrow} />
          <Text
            strong
            align="center"
            className={classes.backToProfileText}
            ml={1}
          >
            {t('Common:BACK_TO_PROFILE')}
          </Text>
        </Grid>
      </ProfileTopContainer>
      <ProfileBottomContainer
        alignItems="flex-start"
        className={classes.profileBottomContainer}
      >
        <EditSignature
          initialSignatureUrl={currentUser?.signatureUrl}
          isLoading={isFetching}
          outputFormat="png"
          onSave={handleSignatureSave}
        />
      </ProfileBottomContainer>
    </Grid>
  )
}

export default ProfileSavedSignature
