import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ClickAwayListener,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  DebouncedInlineSearch,
  Defaults,
  LanguageUtils,
  PuiTheme,
  Text,
  TextInteractive,
} from '@pbt/pbt-ui-components'
import { getHighlightedText } from '@pbt/pbt-ui-components/src/utils'

import SearchPath, { PathItem } from '~/components/common/buttons/SearchPath'
import { StaticFinding } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      position: 'relative',
    },
    paperRoot: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above', 2),
      padding: theme.spacing(2),
      position: 'absolute',
      width: '100%',
    },
    tableRow: {
      height: 'auto',
      '&:not(:last-child)': {
        paddingBottom: theme.spacing(1),
      },
    },
    tableCell: {
      padding: 0,
      border: 'none',
    },
    matchCell: {
      paddingRight: theme.spacing(3),
    },
    boldText: {
      fontWeight: 500,
    },
    button: {
      fontWeight: 'normal',
      textTransform: 'none',
    },
    searchButton: {
      cursor: 'pointer',
    },
  }),
  { name: 'SoapSearch' },
)

const checkIfMatch = (
  term: string,
  item: StaticFinding,
  memory: PathItem[],
  hasTranslation: boolean,
) => {
  if (memory.length === 0) {
    return undefined
  }

  const itemName = hasTranslation
    ? LanguageUtils.getTranslatedFieldName(item)
    : item.name

  return itemName?.toLowerCase().includes(term.toLowerCase())
    ? {
        match: item,
        path: memory,
      }
    : undefined
}

type SearchItem = {
  match: StaticFinding
  path: PathItem[]
}

const search = (
  term: string,
  items: StaticFinding[],
  memory: PathItem[] = [],
  hasTranslation: boolean = false,
): SearchItem[] =>
  items.reduce((acc: SearchItem[], cur) => {
    const curName = hasTranslation
      ? LanguageUtils.getTranslatedFieldName(cur)
      : cur.name
    const curMemory = [
      ...memory,
      {
        id: cur.id,
        name: curName,
      },
    ]
    const current = checkIfMatch(term, cur, memory, hasTranslation)
    const children = cur.children
      ? search(term, cur.children, curMemory, hasTranslation)
      : []

    if (current) {
      acc.push(current, ...children)
    } else {
      acc.push(...children)
    }

    return acc.filter(Boolean)
  }, [])

interface SoapSearchProps {
  items: StaticFinding[]
  label?: string
  onNavigate: (path: (StaticFinding | PathItem)[]) => void
}

const SoapSearch = ({ items, label, onNavigate, ...rest }: SoapSearchProps) => {
  const classes = useStyles(rest)
  const { t } = useTranslation(['Common', 'Search'])

  const [open, setOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState<SearchItem[]>([])

  useEffect(() => {
    if (searchTerm && searchTerm.length >= Defaults.MIN_SEARCH_LENGTH) {
      setOpen(true)
      setSearchResults(search(searchTerm, items, [], true))
    } else {
      setSearchResults([])
    }
  }, [searchTerm])

  const searchResultsTable = (
    <Table>
      <TableBody>
        {searchResults.map((searchResult, index) => {
          const { match, path } = searchResult || {}
          const matchName = LanguageUtils.getTranslatedFieldName(match)

          return (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow className={classes.tableRow} key={index}>
              <TableCell
                className={classNames(classes.matchCell, classes.tableCell)}
              >
                <Button
                  className={classes.button}
                  onClick={() => {
                    onNavigate([...path, match])
                    setOpen(false)
                  }}
                >
                  <TextInteractive
                    highlight={getHighlightedText(matchName, [searchTerm])}
                    strong={false}
                    variant="h4"
                  >
                    {matchName}
                  </TextInteractive>
                </Button>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <SearchPath
                  path={path}
                  onClick={(indexPath) => {
                    onNavigate(path.slice(0, indexPath + 1))
                    setOpen(false)
                  }}
                />
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false)
      }}
    >
      <div className={classes.root}>
        <DebouncedInlineSearch
          name="problemsWidgetSearch"
          placeholder={label || t('Common:SEARCH_NOUN')}
          onChange={setSearchTerm}
          onFocus={() =>
            searchTerm.length >= Defaults.MIN_SEARCH_LENGTH && setOpen(true)
          }
        />
        {open && (
          <Paper className={classes.paperRoot}>
            {searchResults.length > 0 ? (
              searchResultsTable
            ) : (
              <Text variant="body2">
                {t('Search:NO_SEARCH_RESULTS')} ‘
                <span className={classes.boldText}>{searchTerm}</span>’
              </Text>
            )}
          </Paper>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default SoapSearch
