import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as R from 'ramda'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import { GroupedRefundItem } from '~/api/graphql/generated/types'
import { formatMoneyRange } from '~/components/dashboard/invoices/invoiceUtils'
import FinanceItemNameCellBody from '~/components/dashboard/soapV2/charges/table/body/FinanceItemNameCellBody'
import {
  REFUND_AMOUNT_PAID_CELL_WIDTH,
  REFUND_DISCOUNT_CELL_WIDTH,
  REFUND_PATIENT_CELL_WIDTH,
  REFUND_REASON_SUMMARY_CELL_WIDTH,
  REFUND_REFUND_AMOUNT_CELL_WIDTH,
  REFUND_REFUND_QTY_SUMMARY_CELL_WIDTH,
  REFUND_RESTOCK_QTY_SUMMARY_CELL_WIDTH,
  REFUND_SUMMARY_COLUMNS,
  REFUND_TAX_CELL_WIDTH,
} from '~/constants/refund'
import { InvoiceLineItem } from '~/types'

import { RefundContext } from './RefundContext'
import { RefundInvoiceSingleItemRow } from './RefundInvoiceSingleItemRow'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    creationDate: {
      opacity: '50%',
    },
    groupedCell: {
      padding: 0,
    },
    tableCellWithBorder: {
      borderRight: theme.constants.tableBorder,
      borderBottom: 'none',
      paddingTop: 0,
      paddingBottom: 0,
      alignSelf: 'center',
    },
    patientCell: {
      minWidth: REFUND_PATIENT_CELL_WIDTH,
      width: REFUND_PATIENT_CELL_WIDTH,
    },
    reasonCell: {
      maxWidth: REFUND_REASON_SUMMARY_CELL_WIDTH,
      minWidth: REFUND_REASON_SUMMARY_CELL_WIDTH,
      width: REFUND_REASON_SUMMARY_CELL_WIDTH,
    },
    refundQtyCell: {
      minWidth: REFUND_REFUND_QTY_SUMMARY_CELL_WIDTH,
      width: REFUND_REFUND_QTY_SUMMARY_CELL_WIDTH,
    },
    restockQtyCell: {
      minWidth: REFUND_RESTOCK_QTY_SUMMARY_CELL_WIDTH,
      width: REFUND_RESTOCK_QTY_SUMMARY_CELL_WIDTH,
    },
    amountPaidCell: {
      minWidth: REFUND_AMOUNT_PAID_CELL_WIDTH,
      width: REFUND_AMOUNT_PAID_CELL_WIDTH,
    },
    taxCell: {
      minWidth: REFUND_TAX_CELL_WIDTH,
      width: REFUND_TAX_CELL_WIDTH,
    },
    discountCell: {
      minWidth: REFUND_DISCOUNT_CELL_WIDTH,
      width: REFUND_DISCOUNT_CELL_WIDTH,
    },
    refundAmountCell: {
      minWidth: REFUND_REFUND_AMOUNT_CELL_WIDTH,
      width: REFUND_REFUND_AMOUNT_CELL_WIDTH,
    },
  }),
  { name: 'RefundInvoiceGroupRow' },
)

export interface RefundInvoiceGroupRowProps {
  isExpandedSelector?: any
  item: GroupedRefundItem
  tableCellClassName: string
  toggleExpand?: (item: InvoiceLineItem) => void
}

export const RefundInvoiceGroupRow = ({
  isExpandedSelector,
  item,
  tableCellClassName,
  toggleExpand,
}: RefundInvoiceGroupRowProps) => {
  const classes = useStyles()

  const invoiceLineItem = {
    id: item.groupSnapshot.id,
    name: item.groupSnapshot.name,
    groupName: item.groupSnapshot.name,
    patientId: item.groupSnapshot.id,
  } as InvoiceLineItem

  const isExpanded = useSelector(
    isExpandedSelector ? isExpandedSelector(invoiceLineItem) : R.F,
  ) as boolean

  const { onSelectItem, selectedItem } = useContext(RefundContext)

  const columnsLength = REFUND_SUMMARY_COLUMNS.length

  const { subTotal } = item
  const patientName = item.items?.[0].patient?.name

  const toggleExpandInner = (i: InvoiceLineItem) => {
    if (toggleExpand) {
      toggleExpand(i)
    }
  }

  return (
    <TableCell
      className={classes.groupedCell}
      colSpan={columnsLength}
      id={item.groupSnapshot.id}
      scope="row"
    >
      <Grid container wrap="nowrap">
        <Grid container pl={2}>
          <FinanceItemNameCellBody
            isGrouped
            isReadOnly
            nested
            handleSelectItem={onSelectItem}
            isExpanded={isExpanded}
            item={invoiceLineItem}
            selectedItem={selectedItem}
            tableCellClassName={classNames(
              tableCellClassName,
              classes.tableCellWithBorder,
            )}
            toggleExpand={toggleExpandInner}
          />
        </Grid>
        <Grid
          container
          item
          className={classNames(
            classes.tableCellWithBorder,
            classes.patientCell,
          )}
          justifyContent="flex-start"
          pl={2}
        >
          <Text variant="body2">{patientName}</Text>
        </Grid>
        <Grid
          className={classNames(
            classes.tableCellWithBorder,
            classes.reasonCell,
          )}
        />
        <Grid
          className={classNames(
            classes.tableCellWithBorder,
            classes.refundQtyCell,
          )}
        />
        <Grid
          className={classNames(
            classes.tableCellWithBorder,
            classes.restockQtyCell,
          )}
        />
        <Grid
          container
          item
          className={classNames(
            classes.tableCellWithBorder,
            classes.amountPaidCell,
          )}
          justifyContent="flex-start"
          pl={2}
        />
        <Grid
          container
          item
          className={classNames(classes.tableCellWithBorder, classes.taxCell)}
          justifyContent="flex-start"
          pl={2}
        />
        <Grid
          container
          item
          className={classNames(
            classes.tableCellWithBorder,
            classes.discountCell,
          )}
          justifyContent="flex-start"
          pl={2}
        />
        <Grid
          container
          item
          className={classNames(
            classes.tableCellWithBorder,
            classes.refundAmountCell,
          )}
          justifyContent="flex-end"
          pr={2}
        >
          <Text variant="body2">{formatMoneyRange(subTotal)}</Text>
        </Grid>
      </Grid>
      {isExpanded &&
        item.items.map((childItem) => (
          <Table key={`${item.groupSnapshot.id}_${childItem.id}`}>
            <TableBody>
              <TableRow id={childItem.id}>
                <RefundInvoiceSingleItemRow
                  isNested
                  item={childItem}
                  tableCellClassName={tableCellClassName}
                />
              </TableRow>
            </TableBody>
          </Table>
        ))}
    </TableCell>
  )
}
