import React from 'react'
import { Grid, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Nil, PuiTheme, Text } from '@pbt/pbt-ui-components'

import { ProblemType } from '~/constants/problems'
import i18n from '~/locales/i18n'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      borderRadius: '50%',
      backgroundColor: 'red',
    },
    bodySystem: {
      backgroundColor: '#01294a',
    },
    [ProblemType.DIAGNOSIS]: {
      backgroundColor: theme.palette.warning.main,
    },
    [ProblemType.FINDING]: {
      backgroundColor: theme.palette.grey[600],
    },
    label: {
      color: theme.palette.common.white,
    },
  }),
  { name: 'IdentifiedProblemListItemIcon' },
)

const ProblemTypeTooltipTitle = {
  [ProblemType.DIAGNOSIS]: i18n.t('Soap:PROBLEMS.DIAGNOSIS'),
  [ProblemType.FINDING]: i18n.t('Soap:PROBLEMS.FINDING'),
}

interface IdentifiedProblemListItemIconProps {
  problemType: ProblemType | Nil
}

const IdentifiedProblemListItemIcon = ({
  problemType,
}: IdentifiedProblemListItemIconProps) => {
  const classes = useStyles()

  const tooltipTitle = problemType
    ? ProblemTypeTooltipTitle[problemType]
    : i18n.t('Soap:PROBLEMS.BODY_SYSTEM')

  return (
    <Tooltip placement="top" title={tooltipTitle}>
      <Grid
        alignItems="baseline"
        className={classNames(
          classes.root,
          problemType ? classes[problemType] : classes.bodySystem,
        )}
        display="flex"
        justifyContent="center"
        mr={2}
      >
        <Text className={classes.label}>{problemType ?? 'B'}</Text>
      </Grid>
    </Tooltip>
  )
}

export default IdentifiedProblemListItemIcon
