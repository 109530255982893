import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { Field, PuiTextField, PuiTheme, Utils } from '@pbt/pbt-ui-components'
import { UnitTypes } from '@pbt/pbt-ui-components/src/localization'

import {
  Numeral,
  NumeralFormatters,
  NumeralFormatterValidators,
  UnitsNumerals,
  UnitsWithLabels,
} from '~/constants/units'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    input: {
      padding: theme.spacing(1),
      fontSize: '1.5rem',
    },
    textInput: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.controlBorder,
      borderTopLeftRadius: 2,
      borderBottomLeftRadius: 2,
    },
    unitsLabel: {
      backgroundColor: theme.colors.tableBackground,
      color: theme.colors.secondaryText,
      width: 64,
      minWidth: 64,
      border: theme.constants.controlBorder,
      borderLeft: 'none',
      fontSize: '1.5rem',
      fontWeight: 500,
      textAlign: 'center',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    textInputError: {
      border: theme.constants.errorBorder,
    },
  }),
  { name: 'TableUnitInput' },
)

const handleUnitValueChange = (
  units: UnitTypes,
  str: string,
  onChange: (value: string | number) => void,
) => {
  if (!str) {
    onChange('')
    return
  }

  const numeral = UnitsNumerals[units] as Numeral
  const validator = NumeralFormatterValidators[numeral] || R.T
  const formatter = NumeralFormatters[numeral] || R.always(str)

  if (validator(str)) {
    onChange(formatter(str))
  }
}

export interface TableUnitInputProps {
  disabled?: boolean
  field: Field
  placeholder?: string
  units?: UnitTypes
  unitsLabel?: string
}

const TableUnitInput = ({
  unitsLabel,
  units,
  field,
  disabled,
  placeholder,
}: TableUnitInputProps) => {
  const classes = useStyles()
  const { setValue: onChange, valid } = field
  const error = !valid

  return (
    <Grid container wrap="nowrap">
      <PuiTextField
        InputProps={{
          disableUnderline: true,
          inputProps: {
            className: classes.input,
          },
        }}
        className={classNames(classes.textInput, {
          [classes.textInputError]: error,
        })}
        disabled={disabled}
        field={{
          ...field,
          set: Utils.handleFormTextInput(
            (str) => units && handleUnitValueChange(units, str, onChange),
          ),
        }}
        margin="none"
        placeholder={placeholder}
      />
      {unitsLabel && UnitsWithLabels.includes(unitsLabel) && (
        <div className={classes.unitsLabel}>{unitsLabel}</div>
      )}
    </Grid>
  )
}

export default TableUnitInput
