import React, { ChangeEvent, useState } from 'react'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiPopper, PuiPopperProps, PuiTheme } from '@pbt/pbt-ui-components'

import { WidgetRecordsAssignedMode } from '~/constants/landingConstants'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      padding: theme.spacing(2),
    },
    popper: {
      marginTop: theme.spacing(1),
    },
    radioLabel: {
      fontSize: '1.6rem',
    },
  }),
  { name: 'CustomizeWidgetPopper' },
)

interface CustomizeWidgetPopperProps
  extends Omit<PuiPopperProps, 'open' | 'onChange'> {
  assignedMode?: WidgetRecordsAssignedMode
  assignedModes?: Record<string, string>
  onChange: (value: WidgetRecordsAssignedMode) => void
}

const CustomizeWidgetPopper = ({
  anchorEl,
  placement = 'bottom-start',
  assignedMode: initialAssignedMode = WidgetRecordsAssignedMode.OWN,
  assignedModes,
  onChange,
  onClose,
  ...rest
}: CustomizeWidgetPopperProps) => {
  const classes = useStyles()

  const [assignedMode, setAssignedMode] =
    useState<WidgetRecordsAssignedMode>(initialAssignedMode)

  const handleChangeAssignedMode = (
    _: ChangeEvent<HTMLDivElement>,
    newMode: string,
  ) => {
    setAssignedMode(newMode as WidgetRecordsAssignedMode)
    if (onChange) {
      onChange(newMode as WidgetRecordsAssignedMode)
    }
  }

  return (
    <PuiPopper
      hideCloseButton
      anchorEl={anchorEl}
      classes={{
        popper: classes.popper,
        paper: classes.root,
      }}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ]}
      open={Boolean(anchorEl)}
      placement={placement}
      onClose={onClose}
      {...rest}
    >
      <RadioGroup
        aria-label="remove-from-type"
        name="remove-from-type"
        value={assignedMode}
        onChange={handleChangeAssignedMode}
      >
        {Object.entries(assignedModes || {}).map(([value, label]) => (
          <FormControlLabel
            classes={{ label: classes.radioLabel }}
            control={<Radio />}
            key={value}
            label={label}
            value={value}
          />
        ))}
      </RadioGroup>
    </PuiPopper>
  )
}

export default CustomizeWidgetPopper
