import React, { useContext, useState } from 'react'
import { Grid, IconButton, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { LanguageUtils, Nil, PuiTheme, Text } from '@pbt/pbt-ui-components'
import { AddNote } from '@pbt/pbt-ui-components/src/icons'

import {
  Problem,
  ProblemLogBodySystemContainer,
  ProblemLogProblemContainer,
} from '~/types'

import { ProblemsContext } from '../ProblemsContext'
import IdentifiedProblemDetails from './IdentifiedProblemDetails'
import IdentifiedProblemListItemIcon from './IdentifiedProblemListItemIcon'
import IdentifiedProblemListItemStatus from './IdentifiedProblemListItemStatus'
import IdentifiedProblemListItemTag from './IdentifiedProblemListItemTag'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: theme.spacing(0.25),
      cursor: 'pointer',
    },
    breadcrumbs: {
      marginLeft: theme.spacing(1),
    },
    iconButton: {
      padding: theme.spacing(0.25, 1),
    },
    deleteIcon: {
      fontSize: theme.spacing(3.5),
    },
    selected: {
      border: theme.constants.tableBorderSelected,
    },
  }),
  { name: 'IdentifiedProblemListItem' },
)

interface IdentifiedProblemListItemProps {
  isSelected: boolean
  log: ProblemLogProblemContainer | ProblemLogBodySystemContainer
  problem: Problem | Nil
}

const IdentifiedProblemListItem = ({
  isSelected,
  problem,
  log,
}: IdentifiedProblemListItemProps) => {
  const classes = useStyles()
  const [paperElevation, setPaperElevation] = useState(0)

  const { firstName, lastName } = log.entity.createdBy
  const creatorFullName = `${firstName} ${lastName}`

  const { onProblemSelect } = useContext(ProblemsContext)

  if (!problem) {
    return null
  }

  const handleProblemSelect = () => {
    if (problem) {
      onProblemSelect(
        <IdentifiedProblemDetails
          key={problem.key}
          logKey={log.key}
          problem={problem}
        />,
        problem.id!,
      )
    }
  }

  return (
    <Paper
      className={classNames(classes.root, {
        [classes.selected]: isSelected,
      })}
      elevation={paperElevation}
      sx={{ mx: 2, mt: 0, mb: 1, px: 2, py: 1 }}
      onClick={() => {
        setPaperElevation(0)
        handleProblemSelect()
      }}
      onMouseOut={() => setPaperElevation(0)}
      onMouseOver={() => !isSelected && setPaperElevation(3)}
    >
      <Grid container alignItems="center" display="flex">
        <Grid container item md={7.25} pr={0.5} xs={6.5}>
          <Grid item alignItems="center" display="flex" md={1} xs={2}>
            <IdentifiedProblemListItemIcon problemType={problem?.type} />
          </Grid>
          <Grid item alignItems="center" display="flex" md={9} xs={6.75}>
            <Grid item xs="auto">
              <Text noWrap strong variant="body2">
                {LanguageUtils.getTranslatedFieldName(problem)}
              </Text>
            </Grid>
            <Grid item xs>
              {problem?.breadcrumb && (
                <Text className={classes.breadcrumbs} variant="lowAccent3">
                  {problem?.breadcrumb}
                </Text>
              )}
            </Grid>
          </Grid>
          <Grid item alignItems="center" md={1.5} xs={3}>
            <IdentifiedProblemListItemTag log={log} />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Text variant="body2">{creatorFullName}</Text>
        </Grid>
        <Grid item xs={1.5}>
          <IdentifiedProblemListItemStatus
            problemType={problem?.type}
            stateId={log.entity.stateId}
          />
        </Grid>
        <Grid
          container
          item
          flexDirection="row"
          flexWrap="nowrap"
          justifyContent="flex-end"
          md={1.25}
          xs={2}
        >
          <IconButton className={classes.iconButton} size="large">
            <AddNote filled={Boolean(log.entity.notes?.length)} />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default IdentifiedProblemListItem
