import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  CountryCode,
  PuiSelect,
  PuiTheme,
  PuiTooltip,
  useFields,
} from '@pbt/pbt-ui-components'
import { Info as InfoIcon } from '@pbt/pbt-ui-components/src/icons'

import { updateCountryCatalogCode } from '~/store/actions/businesses'
import { getCountryList } from '~/store/reducers/constants'
import {
  BasePracticeDetailsSectionProps,
  PracticeCountrySectionFields,
} from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../practices'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    icon: {
      color: theme.colors.link,
      cursor: 'pointer',
      marginTop: theme.spacing(3.5),
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'CountrySection' },
)

const CountrySection = ({ business }: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const Countries = useSelector(getCountryList)
  const initialCountryCode = business.countryCatalogCode || CountryCode.US
  const { t } = useTranslation(['Admin', 'Businesses'])

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'countryCatalogCode',
        type: 'select',
        label: t('Admin:PRACTICE.COUNTRY_CODE'),
        initialValue: initialCountryCode,
      },
    ],
    false,
  )

  const { countryCatalogCode } = fields

  const onSave = () => {
    if (countryCatalogCode.value !== initialCountryCode) {
      dispatch(updateCountryCatalogCode(business.id, countryCatalogCode.value))
    }
  }

  usePracticeFieldsSection<PracticeCountrySectionFields>({
    business,
    fields,
    sectionName: PracticeDetailsPanels.COUNTRY_SECTION,
    validate,
    reset,
    onSave,
  })

  const countriesList = R.map(
    ({ code }) => ({ id: code, name: code }),
    Countries,
  )

  return (
    <Grid container item>
      <FormControl fullWidth margin="normal" style={{ maxWidth: 120 }}>
        <InputLabel htmlFor="country-code-select">
          {countryCatalogCode.label}
        </InputLabel>
        <PuiSelect
          field={countryCatalogCode}
          input={<Input id="country-code-select" />}
          items={countriesList}
        />
      </FormControl>
      <PuiTooltip
        tooltipPlacement="top-start"
        tooltipText={t('Businesses:COUNTRY.THIS_ALLOWS_LOCALIZATION_OF_DATA')}
      >
        <InfoIcon className={classes.icon} />
      </PuiTooltip>
    </Grid>
  )
}

export default memo(CountrySection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
