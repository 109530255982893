import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { PuiTheme, Text, TextInteractive } from '@pbt/pbt-ui-components'

import Link from '~/components/common/link/Link'
import DialogNames from '~/constants/DialogNames'
import { getUserName } from '~/store/reducers/users'
import { ImagingDashboardItem } from '~/types'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    link: {
      color: theme.colors.secondaryText,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }),
  { name: 'ClientCell' },
)

export interface ClientCellProps {
  item: ImagingDashboardItem
}

const ClientCell = ({ item }: ClientCellProps) => {
  const { client: clientId, patient: patientId, id: orderId } = item
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const clientName = useSelector(getUserName(clientId))

  const [openAssignImagingToPatientDialog] = useDialog(
    DialogNames.ASSIGN_IMAGE_TO_PATIENT,
  )

  const isLoading = R.isEmpty(item)

  const onChoosePatient = () => {
    openAssignImagingToPatientDialog({
      orderId,
    })
  }

  if (isLoading) {
    return <TextInteractive isLoading={isLoading} variant="body2" />
  }

  return clientId && patientId ? (
    <Grid container alignItems="center" wrap="nowrap">
      <Dotdotdot clamp={1}>
        <Text variant="body2">
          {clientId ? (
            <Link className={classes.link} to={`/client/${clientId}`}>
              {clientName}
            </Link>
          ) : (
            clientName
          )}
        </Text>
      </Dotdotdot>
    </Grid>
  ) : (
    <Text link variant="body2" onClick={onChoosePatient}>
      {t('Common:CHOOSE_A_PATIENT')}
    </Text>
  )
}

export default ClientCell
