import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import { useAlertType } from '~/store/hooks/patient'
import { getPatient } from '~/store/reducers/patients'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { getUserLastName } from '~/store/reducers/users'

import CardAlert from './CardAlert'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    content: {
      position: 'relative',
    },
    contentRowWithAlert: {
      maxWidth: `calc(100% - ${theme.spacing(3)})`,
    },
    cardText: {
      color: 'inherit',
    },
    disabledText: {
      color: theme.colors.disabledLabelText,
    },
    warningText: {
      color: theme.colors.important,
    },
    normalText: {
      color: theme.colors.title,
    },
    successLabel: {
      backgroundColor: theme.colors.successBackground,
      color: theme.colors.success,
    },
  }),
  { name: 'ShortCard' },
)

export interface ShortCardProps {
  appointmentId: string
  contentStyle?: React.CSSProperties
  isDisabledState?: boolean
  isHalfWidth?: boolean
  isNormalState?: boolean
  isSuccessState?: boolean
  isWarningState?: boolean
}

const ShortCard = ({
  appointmentId,
  isDisabledState,
  isNormalState,
  isSuccessState,
  isWarningState,
  isHalfWidth,
  contentStyle,
}: ShortCardProps) => {
  const classes = useStyles()
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const patient = useSelector(getPatient(appointment?.patient))
  const lastName = useSelector(getUserLastName(appointment?.client)) || ''

  const patientName = patient?.name || ''

  const { alertsToRender } = useAlertType(appointment?.patient)
  const showAlertLabel = alertsToRender.length > 0

  return (
    <Grid
      container
      item
      alignItems="center"
      direction="row"
      style={contentStyle}
      wrap="nowrap"
    >
      {showAlertLabel && (
        <CardAlert
          isDense
          alertColorId={patient?.alertColorId}
          alertTypes={alertsToRender}
        />
      )}
      <Grid
        container
        item
        xs
        className={classes.content}
        direction="column"
        px={0.5}
        wrap="nowrap"
      >
        <Grid
          container
          item
          xs
          alignItems="center"
          className={classNames({
            [classes.warningText]: isWarningState,
            [classes.disabledText]: isDisabledState,
            [classes.normalText]: isNormalState,
            [classes.successLabel]: isSuccessState,
          })}
        >
          <Dotdotdot clamp={1}>
            <Text strong className={classes.cardText} variant="body3">
              {isHalfWidth
                ? `${patientName} ${lastName[0]}.`
                : `${patientName} ${lastName}`}
            </Text>
          </Dotdotdot>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ShortCard
