import { Task as SagaTask } from '@redux-saga/types'
import {
  all,
  call,
  cancel,
  fork,
  put,
  take,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { LandingType } from '~/constants/landingConstants'

import {
  CLEAR_WIDGETS_DATA,
  DELETE_LANDING_LAYOUT,
  deleteLandingLayoutFailure,
  deleteLandingLayoutSuccess,
  EDIT_LANDING_LAYOUT,
  editLandingLayout,
  editLandingLayoutFailure,
  editLandingLayoutSuccess,
  FETCH_LANDING_LAYOUT,
  FETCH_WIDGETS_DATA,
  fetchLandingLayoutFailure,
  fetchLandingLayoutSuccess,
  fetchWidgetsData,
  fetchWidgetsDataFailure,
  fetchWidgetsDataSuccess,
} from '../duck/landing'
import { finishLoading, startLoading } from '../duck/progress'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchLandingLayoutSaga() {
  try {
    yield put(startLoading('landing-layout'))
    const response = yield* requestAPI(API.fetchLandingLayout)
    const { layout, widgets, settings } = response
    yield put(fetchLandingLayoutSuccess(layout, widgets, settings))
    yield put(finishLoading('landing-layout'))
  } catch (error) {
    yield put(finishLoading('landing-layout'))
    yield put(fetchLandingLayoutFailure(error as ApiError))
  }
}

export function* editLandingLayoutSaga({
  layout,
  settings,
}: ReturnType<typeof editLandingLayout>) {
  try {
    yield put(startLoading('landing-layout'))
    yield* requestAPI(API.editLandingLayout, layout, settings)
    yield put(editLandingLayoutSuccess(layout, settings))
    yield put(finishLoading('landing-layout'))
  } catch (error) {
    yield put(finishLoading('landing-layout'))
    yield put(editLandingLayoutFailure(error as ApiError))
  }
}

export function* deleteLandingLayoutSaga() {
  try {
    yield put(startLoading('landing-layout'))
    const { layout, settings } = yield* requestAPI(API.deleteLandingLayout)
    yield put(deleteLandingLayoutSuccess(layout, settings))
    yield put(finishLoading('landing-layout'))
  } catch (error) {
    yield put(finishLoading('landing-layout'))
    yield put(deleteLandingLayoutFailure(error as ApiError))
  }
}

export function* fetchWidgetsDataSagaCancelable({
  widgetNames,
  params,
}: ReturnType<typeof fetchWidgetsData>) {
  try {
    const { landingType, patientId } = params
    const landingTypeEndpointsMap: Record<LandingType, any> = {
      [LandingType.LANDING_DASHBOARD]: API.fetchLandingWidgetsData,
      [LandingType.CLIENT_AND_PATIENT_SNAPSHOTS]:
        API.fetchClientAndPatientWidgetsData,
    }

    const response = yield* requestAPI(
      landingTypeEndpointsMap[landingType],
      widgetNames,
      patientId,
    )

    const {
      entities,
      result: { widgetData: data },
    } = response
    yield call(updateEntities, entities)
    yield put(fetchWidgetsDataSuccess(data, widgetNames, params))
  } catch (error) {
    yield put(fetchWidgetsDataFailure(error as ApiError, widgetNames, params))
  }
}

export function* fetchWidgetsDataSaga(
  params: ReturnType<typeof fetchWidgetsData>,
) {
  const task: SagaTask = yield fork(fetchWidgetsDataSagaCancelable, params)
  yield take([CLEAR_WIDGETS_DATA])
  yield cancel(task)
}

function* watchFetchLandingLayoutSaga() {
  yield takeLeading(FETCH_LANDING_LAYOUT, fetchLandingLayoutSaga)
}

function* watchEditLandingLayoutSaga() {
  yield takeLeading(EDIT_LANDING_LAYOUT, editLandingLayoutSaga)
}

function* watchDeleteLandingLayoutSaga() {
  yield takeLeading(DELETE_LANDING_LAYOUT, deleteLandingLayoutSaga)
}

function* watchFetchWidgetsDataSaga() {
  yield takeEvery(FETCH_WIDGETS_DATA, fetchWidgetsDataSaga)
}

export default function* landingSaga() {
  yield all([
    watchFetchLandingLayoutSaga(),
    watchEditLandingLayoutSaga(),
    watchDeleteLandingLayoutSaga(),
    watchFetchWidgetsDataSaga(),
  ])
}
