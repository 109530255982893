import React from 'react'
import { Box, Skeleton, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ButtonWithLoader, PuiTheme, Text } from '@pbt/pbt-ui-components'

interface StyleProps {
  isFinalized: boolean
}

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
    },
    titleContainer: {
      borderRight: theme.constants.tabBorder,
      width: 227,
    },
    title: {
      color: ({ isFinalized }: StyleProps) =>
        isFinalized ? theme.colors.primaryText : theme.colors.alertLabelError,
    },
    importantButton: {
      minWidth: 150,
      height: 40,
      marginLeft: 'auto',
      backgroundColor: theme.colors.important,
      color: theme.colors.tableBackground,
      transition:
        'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
      boxShadow: 'none',
      '&&&&:hover': {
        boxShadow:
          'box-shadow: 1px 1px 2px 0 rgba(60,56,56,0.15), 0 1px 4px 0 rgba(76,73,73,0.12)',
        backgroundColor: '#EF7605',
      },
      '&&&&:active': {
        boxShadow: 'box-shadow: 0 4px 23px 0 rgba(0,0,0,0.12)',
        backgroundColor: '#CF6300',
      },
    },
    actionsContainer: {
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'FinalizeOrdersSection' },
)

export interface FinalizeOrdersSectionProps {
  buttonLabel: React.ReactNode
  children: React.ReactNode
  description: React.ReactNode
  isFinalized: boolean
  isLoading?: boolean
  isSubmitting?: boolean
  onClick: () => void
  title: React.ReactNode
}

const FinalizeOrdersSection = ({
  buttonLabel,
  children,
  description,
  isFinalized,
  isLoading,
  isSubmitting,
  onClick,
  title,
}: FinalizeOrdersSectionProps) => {
  const classes = useStyles({ isFinalized })

  return (
    <Box
      alignItems="flex-start"
      className={classes.root}
      display="flex"
      flex={1}
      flexDirection="row"
    >
      <Box
        alignSelf="stretch"
        className={classes.titleContainer}
        display="flex"
        flexDirection="column"
        flexShrink={0}
        px={2}
        py={1}
      >
        {isLoading ? (
          <Skeleton role="status" />
        ) : (
          <Stack spacing={0.5}>
            <Text strong className={classes.title} variant="h4">
              {title}
            </Text>
            {!isFinalized && (
              <>
                <Text>{description}</Text>
                <Box>
                  <ButtonWithLoader
                    className={classes.importantButton}
                    disabled={isFinalized}
                    loading={isSubmitting}
                    type="button"
                    onClick={onClick}
                  >
                    {buttonLabel}
                  </ButtonWithLoader>
                </Box>
              </>
            )}
          </Stack>
        )}
      </Box>
      <Box
        className={classes.actionsContainer}
        display="flex"
        flexDirection="column"
        flexGrow={1}
      >
        {isLoading ? <Skeleton sx={{ m: 1 }} /> : children}
      </Box>
    </Box>
  )
}

export default FinalizeOrdersSection
