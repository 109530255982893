import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CircularProgress, Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { isEmpty } from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PermissionArea,
  PuiDialog,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import Avatar from '~/components/common/Avatar'
import DialogNames from '~/constants/DialogNames'
import { fetchClient } from '~/store/actions/clients'
import {
  updatePatientParent,
  updatePatientParentWithNewClient,
} from '~/store/actions/patients'
import {
  getCRUDByArea,
  getCurrentBusinessWellnessPlansEnabled,
} from '~/store/reducers/auth'
import {
  getClientIsLoading,
  getCurrentClientId,
} from '~/store/reducers/clients'
import { getPatient, getPatientsIsLoading } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { ClientData } from '~/types/entities/clients'
import useDialog from '~/utils/useDialog'

// @ts-ignore
import ClientSelector from '../../timetable/scheduler/appointment/ClientSelector'
import DetailsBackButton from '../DetailsBackButton'
import CreateNewPetParent, {
  CreateNewPetParentHandle,
} from './CreateNewPetParent'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    searchPaper: {
      width: 650,
      height: 240,
      padding: theme.spacing(3),
      overflowY: 'visible',
    },
    dialogTitle: {
      border: 'none',
      padding: theme.spacing(0),
    },
    createClientPaper: {
      height: 310,
    },
    createClientTitle: {
      padding: theme.spacing(0),
    },
    addClientDetailsButton: {
      minWidth: 200,
      height: 40,
      marginTop: theme.spacing(2),
    },
    newPetParentPaper: {
      height: 170,
    },
    existedPetParentPaper: {
      height: 300,
    },
    avatar: {
      width: 72,
      height: 72,
    },
    backButtonText: {
      color: theme.colors.primaryText,
      paddingLeft: theme.spacing(1),
      fontWeight: 500,
    },
    backButtonContainer: {
      marginLeft: theme.spacing(-1.5),
    },
    changeButton: {
      width: 150,
      height: 40,
      marginTop: theme.spacing(3),
    },
    progress: {
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
    searchContentRoot: {
      overflowY: 'visible',
    },
  }),
  { name: 'ChangePetParentDialog' },
)

export const Steps = {
  SEARCH: 'SEARCH',
  CREATE: 'CREATE',
  CHANGE_WITH_NEW_CLIENT: 'CHANGE_WITH_NEW_CLIENT',
  CHANGE_WITH_EXISTED_CLIENT: 'CHANGE_WITH_EXISTED_CLIENT',
}

interface ChangePetParentDialogProps extends BasePuiDialogProps {
  clientId: string
  onAddClientDetails: () => void
  patientId: string
}

const ChangePetParentDialog = ({
  open,
  clientId: currentClientId,
  patientId,
  onAddClientDetails,
  onClose,
}: ChangePetParentDialogProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const isPatientsLoading = useSelector(getPatientsIsLoading)
  const isClientsLoading = useSelector(getClientIsLoading)
  const createdClientId = useSelector(getCurrentClientId)
  const patient = useSelector(getPatient(patientId))
  const wellnessPlansEnabled = useSelector(
    getCurrentBusinessWellnessPlansEnabled,
  )
  const patientPermissions = useSelector(getCRUDByArea(PermissionArea.PATIENT))

  const [step, setStep] = useState(Steps.SEARCH)
  const [clientCandidate, setClientCandidate] = useState({})
  const [existingClientId, setExistingClientId] = useState('')

  const [openWellnessPlanAlert, closeWellnessPlanAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const newClientFormRef = useRef<CreateNewPetParentHandle>(null)

  const newClientId = existingClientId || createdClientId || ''
  const isLoading = isPatientsLoading || isClientsLoading

  const newClient = useSelector(getUser(newClientId))

  const handleClose = () => {
    setStep(Steps.SEARCH)
    setClientCandidate({})
    setExistingClientId('')
    if (onClose) {
      onClose()
    }
  }

  const redirect = (clientId: string) => {
    navigate(`/client/${clientId}/patient/${patientId}`)
    if (clientId !== createdClientId) {
      handleClose()
    }
  }

  useEffect(() => {
    if (
      patient?.id &&
      newClient?.patients?.includes(patient.id) &&
      step !== Steps.SEARCH
    ) {
      redirect(newClient.id)
    }
  }, [newClient])

  const onAddNewClientRequested = () => {
    setStep(Steps.CREATE)
  }

  const onBackToSearchRequested = () => {
    setStep(Steps.SEARCH)
    setClientCandidate({})
    setExistingClientId('')
  }

  const proceedWithUpdate = (proceedAction: () => void) => {
    if (wellnessPlansEnabled) {
      openWellnessPlanAlert({
        message: t('Dialogs:DISMISSIBLE_ALERT.WELLNESS_PLAN_ALERT_MESSAGE'),
        onCancel: closeWellnessPlanAlert,
        onClose: closeWellnessPlanAlert,
        okButtonText: t('Common:CONTINUE_ACTION'),
        onOk: () => {
          closeWellnessPlanAlert()
          proceedAction()
        },
      })
    } else {
      proceedAction()
    }
  }

  const onChangeRequested = () => {
    if (step === Steps.CREATE) {
      newClientFormRef.current?.onChangeRequested()
    } else if (step === Steps.CHANGE_WITH_EXISTED_CLIENT) {
      proceedWithUpdate(() =>
        dispatch(
          updatePatientParent(currentClientId, newClient?.id!, patientId),
        ),
      )
    }
  }

  const onChangeWithNewClient = (newClientCandidate: Partial<ClientData>) => {
    setClientCandidate(newClientCandidate)
    proceedWithUpdate(() => {
      dispatch(
        updatePatientParentWithNewClient(
          currentClientId,
          newClientCandidate,
          patientId,
        ),
      )
      setStep(Steps.CHANGE_WITH_NEW_CLIENT)
    })
  }

  const onClientSelected = (clientId: string) => {
    setExistingClientId(clientId)
    setStep(Steps.CHANGE_WITH_EXISTED_CLIENT)
    dispatch(fetchClient({ clientId }))
  }

  const onAddClientDetailsRequested = () => {
    handleClose()
    onAddClientDetails()
  }

  return (
    <PuiDialog
      aria-labelledby="change-pet-parent-dialog"
      classes={{
        paper: classNames(classes.searchPaper, {
          [classes.createClientPaper]: step === Steps.CREATE,
          [classes.newPetParentPaper]: step === Steps.CHANGE_WITH_NEW_CLIENT,
          [classes.existedPetParentPaper]:
            step === Steps.CHANGE_WITH_EXISTED_CLIENT,
        }),
        dialogTitle: classNames(classes.dialogTitle, {
          [classes.createClientTitle]: step === Steps.CREATE,
        }),
        dialogContentRoot: classes.searchContentRoot,
      }}
      open={open}
      title={t('Dialogs:CHANGE_PET_PARENT_DIALOG.TITLE', {
        patientName: patient?.name,
      })}
      onClose={handleClose}
    >
      {step === Steps.SEARCH && (
        <ClientSelector
          clientOnly
          isSearchOnlyClients
          maxSearchValuesInList={3}
          permissions={patientPermissions}
          onAddNewClientRequested={onAddNewClientRequested}
          onSelected={onClientSelected}
        />
      )}
      {step === Steps.CREATE && (
        <CreateNewPetParent
          ref={newClientFormRef}
          onBack={onBackToSearchRequested}
          onChangeWithNewClient={onChangeWithNewClient}
        />
      )}
      {step === Steps.CHANGE_WITH_EXISTED_CLIENT && (
        <>
          {isEmpty(newClient) && (
            <CircularProgress
              className={classes.progress}
              color="secondary"
              size={32}
            />
          )}
          {!isEmpty(newClient) && (
            <Grid container>
              <Grid item md={12}>
                <DetailsBackButton
                  classes={{
                    text: classes.backButtonText,
                    container: classes.backButtonContainer,
                  }}
                  lg={12}
                  md={12}
                  onClick={onBackToSearchRequested}
                >
                  {t('Common:BACK_TO_SEARCH')}
                </DetailsBackButton>
              </Grid>
              <Grid item md={12} my={1}>
                <Text>
                  {t('Dialogs:CHANGE_PET_PARENT_DIALOG.NEW_PET_PARENT_NAME', {
                    patientName: patient?.name,
                  })}
                  &nbsp;
                  <Text strong component="span">
                    {Utils.getPersonString(newClient)}
                  </Text>
                </Text>
              </Grid>
              <Grid item md={2}>
                <Avatar
                  alt="client-pic"
                  className={classes.avatar}
                  person={newClient}
                  size="normal"
                />
              </Grid>
              <Grid item md={10}>
                <Text>{newClient?.address ? `${newClient.address},` : ''}</Text>
                <Text>{newClient?.city ? `${newClient.city},` : ''}</Text>
                <Text>
                  {newClient?.mobilePhone ? `${newClient.mobilePhone} |` : ''}{' '}
                  {newClient?.email || ''}
                </Text>
              </Grid>
            </Grid>
          )}
        </>
      )}
      {step === Steps.CHANGE_WITH_NEW_CLIENT && (
        <>
          {isLoading && (
            <CircularProgress
              className={classes.progress}
              color="secondary"
              size={32}
            />
          )}
          {!isLoading && (
            <>
              <Text>
                {t(
                  'Dialogs:CHANGE_PET_PARENT_DIALOG.CLIENT_CANDIDATE_HAS_BEEN_ADDED',
                  {
                    clientCandidate: Utils.getPersonString(clientCandidate),
                    patientName: patient?.name,
                  },
                )}
              </Text>
              <Fab
                className={classes.addClientDetailsButton}
                color="inherit"
                variant="extended"
                onClick={onAddClientDetailsRequested}
              >
                {t('Dialogs:CHANGE_PET_PARENT_DIALOG.ADD_CLIENT_DETAILS_NOW')}
              </Fab>
            </>
          )}
        </>
      )}
      {((step === Steps.CHANGE_WITH_EXISTED_CLIENT && !isEmpty(newClient)) ||
        step === Steps.SEARCH ||
        step === Steps.CREATE) && (
        <ButtonWithLoader
          className={classes.changeButton}
          disabled={step === Steps.SEARCH && isEmpty(newClient)}
          loading={isLoading}
          onClick={onChangeRequested}
        >
          {t('Common:CHANGE_ACTION')}
        </ButtonWithLoader>
      )}
    </PuiDialog>
  )
}

export default ChangePetParentDialog
