import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ArrowForward } from '@mui/icons-material'
import {
  Grid,
  GridProps,
  IconButton,
  Tooltip,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'
import {
  Delete as DeleteIcon,
  Expand as ExpandIcon,
  Settings as SettingsIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { PopperAction } from '~/components/common/ActionsPopper'
import { WidgetWidthType } from '~/constants/landingConstants'
import { LandingSettingsItem, WidgetColumn, WidgetColumnRow } from '~/types'

import CustomizeWidgetPopper from './CustomizeWidgetPopper'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    widget: {
      backgroundColor: 'transparent',
    },
    widgetWithPaddings: {
      height: '100%',
      padding: theme.spacing(0.75),
    },
    innerContainer: {
      height: '100%',
      backgroundColor: theme.colors.tableBackground,
    },
    innerContainerBorder: {
      border: theme.constants.tableBorderSelected,
    },
    widgetContent: {
      position: 'relative',
      height: '100%',
      overflow: 'hidden',
    },
    hoverIconButton: {
      padding: theme.spacing(1),
      marginLeft: theme.spacing(1),
      border: '1px solid grey',
      borderRadius: 24,
      backgroundColor: theme.colors.tableBackground,
      '&:hover': {
        opacity: 1,
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    heading: {
      borderBottom: theme.constants.fabBorder,
      height: 28,
      minHeight: 28,
    },
    contentOverlay: {
      backgroundColor: theme.colors.tableBackground,
      opacity: 0.8,
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    },
    containerOverlay: {
      backgroundColor: theme.colors.listItemHoverText,
      opacity: 0.2,
      position: 'absolute',
      width: 'calc(100% - 12px)',
      height: 'calc(100% - 12px)',
      top: 6,
      left: 6,
      padding: theme.spacing(0.75),
    },
    settingsButton: {
      position: 'absolute',
      top: theme.spacing(1.5),
      right: theme.spacing(1.5),
    },
    iconContainer: {
      width: 'auto',
      marginLeft: 'auto',
    },
    headerIconButton: {
      padding: 0,
      margin: theme.spacing(0, 0.5, 0, 1),
    },
  }),
  { name: 'LandingWidget' },
)

export interface LandingWidgetProps<T extends WidgetColumnRow>
  extends Omit<GridProps, 'columns'> {
  assignedModes?: Record<string, string>
  canExpand?: boolean
  canNavigateToDetails?: boolean
  canNavigateToItemDetails?: (item: T) => void
  columns?: WidgetColumn<T>[]
  component?: React.JSXElementConstructor<any>
  componentProps?: Record<string, any>
  data?: T[]
  getActions?: (item: T) => PopperAction[]
  headerComponent?: React.JSXElementConstructor<any>
  isExpanded?: boolean
  isLoading?: boolean
  isSettingsMode?: boolean
  meta?: any
  name?: string
  navigateToDetailsTooltip?: string
  navigateToItemDetailsTooltip?: string
  noRecords?: React.ReactNode
  onChangeWidgetSettings?: (newSettings: LandingSettingsItem) => void
  onDeleteWidget?: (i: string) => void
  onExpand?: () => void
  onNavigateToDetails?: () => void
  onNavigateToItemDetails?: (item: T) => void
  rowsCount?: number
  settings?: LandingSettingsItem
  showBorders?: boolean
  showHeader?: boolean
  showHeaderTitles?: boolean
  showPaddings?: boolean
  showSettings?: boolean
  tabSection?: JSX.Element
  totalCount?: number
  widgetKey?: string
  widthType?: WidgetWidthType
}

const LandingWidget = <T extends WidgetColumnRow>({
  isExpanded = false,
  data: dataProp,
  meta,
  isLoading,
  columns,
  getActions,
  canNavigateToItemDetails,
  showHeader = true,
  showHeaderTitles = false,
  showBorders = true,
  showPaddings = true,
  showSettings = true,
  component: Component,
  headerComponent: HeaderComponent,
  noRecords,
  widgetKey,
  name,
  widthType,
  isSettingsMode,
  rowsCount,
  settings,
  assignedModes,
  canExpand = true,
  canNavigateToDetails = true,
  navigateToDetailsTooltip = '',
  navigateToItemDetailsTooltip,
  totalCount,
  onExpand = R.F,
  onNavigateToItemDetails = R.F,
  onNavigateToDetails = R.F,
  onDeleteWidget = R.F,
  onChangeWidgetSettings = R.F,
  componentProps,
  tabSection,
  ...rest
}: LandingWidgetProps<T>) => {
  const data = dataProp || []
  const classes = useStyles()
  const { t } = useTranslation('Landing')

  const settingsIconRef = useRef<HTMLButtonElement>(null)

  const [settingsAnchorEl, setSettingsAnchorEl] = useState<HTMLButtonElement>()
  const [isHovering, setIsHovering] = useState(false)
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )
  const isEditing = isMobile || isHovering

  return (
    <Grid
      className={classNames(classes.widget, {
        [classes.widgetWithPaddings]: showPaddings,
      })}
      onMouseEnter={() => (isSettingsMode ? setIsHovering(true) : undefined)}
      onMouseLeave={() => {
        if (isSettingsMode) {
          setIsHovering(false)
          setSettingsAnchorEl(undefined)
        }
      }}
      onMouseOver={() => (isSettingsMode ? setIsHovering(true) : undefined)}
      {...rest}
    >
      <Grid
        container
        className={classNames(classes.innerContainer, {
          [classes.innerContainerBorder]: showBorders,
        })}
        direction="column"
        wrap="nowrap"
      >
        {!isExpanded && !HeaderComponent && (
          <Grid
            container
            alignItems="center"
            className={classes.heading}
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Text strong pl={1} variant="h4">
              {name}
            </Text>
            <Grid
              container
              item
              className={classes.iconContainer}
              mr={1}
              wrap="nowrap"
            >
              {!isSettingsMode && canNavigateToDetails && (
                <Tooltip placement="top" title={navigateToDetailsTooltip}>
                  <IconButton
                    className={classes.headerIconButton}
                    size="large"
                    onClick={onNavigateToDetails}
                  >
                    <ArrowForward />
                  </IconButton>
                </Tooltip>
              )}
              {!isSettingsMode && canExpand && (
                <IconButton
                  className={classes.headerIconButton}
                  size="large"
                  onClick={onExpand}
                >
                  <ExpandIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        )}
        {showHeader && HeaderComponent && (
          <HeaderComponent
            className={classes.heading}
            columns={columns}
            isSettingsMode={isSettingsMode}
            name={name}
            widthType={widthType}
            onExpand={onExpand}
          />
        )}
        {tabSection}
        <Grid
          container
          className={classes.widgetContent}
          justifyContent="space-between"
        >
          {Component ? (
            data.length === 0 && !isLoading && noRecords ? (
              noRecords
            ) : (
              <Component
                canNavigateToItemDetails={canNavigateToItemDetails}
                columns={columns}
                data={data}
                getActions={getActions}
                isLoading={isLoading}
                meta={meta}
                navigateToItemDetailsTooltip={navigateToItemDetailsTooltip}
                rowsCount={rowsCount}
                showHeaderTitles={showHeaderTitles && !HeaderComponent}
                widthType={widthType}
                onNavigateToItemDetails={onNavigateToItemDetails}
                {...(componentProps || {})}
              />
            )
          ) : null}
          {isSettingsMode && (
            <Grid container className={classes.contentOverlay} />
          )}
        </Grid>
        {isSettingsMode && isEditing && (
          <>
            <Grid container className={classes.containerOverlay} />
            <Grid className={classes.settingsButton}>
              <CustomizeWidgetPopper
                anchorEl={settingsAnchorEl}
                assignedMode={settings?.assignedMode}
                assignedModes={assignedModes}
                disablePortal={false}
                onChange={(assignedMode) =>
                  onChangeWidgetSettings({ assignedMode })
                }
                onClose={() => setSettingsAnchorEl(undefined)}
              />
              {showSettings && (
                <Tooltip
                  placement="top"
                  title={t('Landing:LANDING_WIDGET.CUSTOMIZE_WIDGET')}
                >
                  <IconButton
                    className={classes.hoverIconButton}
                    ref={settingsIconRef}
                    size="large"
                    onClick={() =>
                      setSettingsAnchorEl(settingsIconRef.current || undefined)
                    }
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton
                className={classes.hoverIconButton}
                size="large"
                onClick={() => {
                  if (widgetKey) {
                    onDeleteWidget(widgetKey)
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default LandingWidget
