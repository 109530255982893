import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { Defaults, Field, PuiTheme, Text } from '@pbt/pbt-ui-components'

import { Estimate as GraphqlEstimate } from '~/api/graphql/generated/types'
import { CompactAlert } from '~/components/elements/CompactAlert/CompactAlert'
import {
  fetchInvoicePage,
  fetchPaymentPage,
  getHasUnappliedPayments,
  getHasUnpaidInvoices,
  getUnappliedPaymentLoading,
  getUnpaidInvoiceLoading,
} from '~/store/duck/clientBillingActivityData'
import { swapElements } from '~/utils'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import { InvoicesInfiniteLoaderList } from '../../infiniteLoaderList/InvoicesInfiniteLoaderList'
import { PaymentsInfiniteLoaderList } from '../../infiniteLoaderList/PaymentsInfiniteLoaderList'
import {
  InvoiceTableColumnWidthWithCheckbox,
  PaymentTableColumnWidthWithCheckbox,
} from '../../table/common/tableConstants'
import { PaymentSection } from './PaymentSection'
import { PaymentSummary } from './PaymentSummary'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    table: {
      borderBottom: 0,
    },
    tableUnappliedPaymentCell: {
      '&:nth-child(1)': {
        minWidth: PaymentTableColumnWidthWithCheckbox.FIRST,
        maxWidth: PaymentTableColumnWidthWithCheckbox.FIRST,
      },
      '&:nth-child(2)': {
        minWidth: PaymentTableColumnWidthWithCheckbox.SECOND,
        maxWidth: PaymentTableColumnWidthWithCheckbox.SECOND,
      },
      '&:nth-child(3)': {
        minWidth: PaymentTableColumnWidthWithCheckbox.THIRD,
        maxWidth: PaymentTableColumnWidthWithCheckbox.THIRD,
      },
      '&:nth-child(4)': {
        minWidth: PaymentTableColumnWidthWithCheckbox.FOURTH,
        maxWidth: PaymentTableColumnWidthWithCheckbox.FOURTH,
      },
      '&:nth-child(5)': {
        minWidth: PaymentTableColumnWidthWithCheckbox.FIFTH,
        maxWidth: PaymentTableColumnWidthWithCheckbox.FIFTH,
      },
      '&:nth-child(6)': {
        minWidth: PaymentTableColumnWidthWithCheckbox.SIXTH,
        maxWidth: PaymentTableColumnWidthWithCheckbox.SIXTH,
      },
      '&:nth-child(7)': {
        minWidth: PaymentTableColumnWidthWithCheckbox.SEVENTH,
        maxWidth: PaymentTableColumnWidthWithCheckbox.SEVENTH,
      },
      '&:nth-child(8)': {
        minWidth: PaymentTableColumnWidthWithCheckbox.EIGHTH,
        maxWidth: PaymentTableColumnWidthWithCheckbox.EIGHTH,
      },
      '&:nth-child(9)': {
        minWidth: PaymentTableColumnWidthWithCheckbox.NINTH,
        maxWidth: '100%',
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(1),
      },
    },
    tableUnpaidInvoiceCell: {
      '&:nth-child(1)': {
        minWidth: InvoiceTableColumnWidthWithCheckbox.FIRST,
        maxWidth: InvoiceTableColumnWidthWithCheckbox.FIRST,
      },
      '&:nth-child(2)': {
        minWidth: InvoiceTableColumnWidthWithCheckbox.SECOND,
        maxWidth: InvoiceTableColumnWidthWithCheckbox.SECOND,
      },
      '&:nth-child(3)': {
        minWidth: InvoiceTableColumnWidthWithCheckbox.THIRD,
        maxWidth: InvoiceTableColumnWidthWithCheckbox.THIRD,
      },
      '&:nth-child(4)': {
        minWidth: InvoiceTableColumnWidthWithCheckbox.FOURTH,
        maxWidth: InvoiceTableColumnWidthWithCheckbox.FOURTH,
      },
      '&:nth-child(5)': {
        minWidth: InvoiceTableColumnWidthWithCheckbox.FIFTH,
        maxWidth: InvoiceTableColumnWidthWithCheckbox.FIFTH,
      },
      '&:nth-child(6)': {
        minWidth: InvoiceTableColumnWidthWithCheckbox.SIXTH,
        maxWidth: InvoiceTableColumnWidthWithCheckbox.SIXTH,
      },
      '&:nth-child(7)': {
        minWidth: InvoiceTableColumnWidthWithCheckbox.SEVENTH,
        maxWidth: '100%',
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(1),
      },
    },
    tableHeader: {
      top: 0,
    },
    tableWithError: {
      border: '1px solid red',
    },
  }),
  { name: 'AddClientPaymentInitialization' },
)

interface AddClientPaymentInitializationProps {
  clientId: string
  hasApplyPaymentsEnabled: boolean
  isOtherPaymentType: boolean
  isSaving: boolean
  onCheckInvoices: (invoiceId: string) => void
  onCheckPayments: (paymentId: string) => void
  onClickEstimate: (estimate: GraphqlEstimate) => void
  paymentAmountField: Field
  paymentTypeField: Field
  selectedInvoiceIds: Field
  selectedPaymentIds: Field
  serviceFeeAmount: number
  totals: {
    totalDueToPay: number
    totalSelectedUnappliedPayments: number
    totalSelectedUnpaidInvoices: number
  }
}

export const AddClientPaymentInitialization = ({
  clientId,
  hasApplyPaymentsEnabled,
  isOtherPaymentType,
  isSaving,
  paymentAmountField,
  paymentTypeField,
  selectedInvoiceIds,
  selectedPaymentIds,
  serviceFeeAmount,
  totals: {
    totalDueToPay,
    totalSelectedUnappliedPayments,
    totalSelectedUnpaidInvoices,
  },
  onClickEstimate,
  onCheckInvoices,
  onCheckPayments,
}: AddClientPaymentInitializationProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Invoices', 'Soap'])

  const isFetchingUnpaidInvoices = useSelector(getUnpaidInvoiceLoading)
  const hasUnpaidInvoices = useSelector(getHasUnpaidInvoices)
  const isFetchingUnappliedPayments = useSelector(getUnappliedPaymentLoading)
  const hasUnappliedPayments = useSelector(getHasUnappliedPayments)

  const [showUnappliedPaymentSection, setShowUnappliedPaymentSection] =
    useState(true)
  const [showUnpaidInvoiceSection, setShowUnpaidInvoiceSection] = useState(true)

  const hasInvoiceError =
    selectedInvoiceIds.errors.length > 0 && selectedInvoiceIds.open
  const hasPaymentError =
    selectedPaymentIds.errors.length > 0 && selectedPaymentIds.open

  useEffect(() => {
    paymentAmountField.setValue(totalDueToPay > 0 ? totalDueToPay : 0)
  }, [totalDueToPay])

  useEffectExceptOnMount(() => {
    if (!isFetchingUnappliedPayments && !hasUnappliedPayments) {
      setShowUnappliedPaymentSection(false)
    }
  }, [isFetchingUnappliedPayments, hasUnappliedPayments])

  useEffectExceptOnMount(() => {
    if (!isFetchingUnpaidInvoices && !hasUnpaidInvoices) {
      setShowUnpaidInvoiceSection(false)
    }
  }, [isFetchingUnpaidInvoices, hasUnpaidInvoices])

  useEffect(() => {
    if (clientId) {
      dispatch(
        fetchPaymentPage({
          clientId,
          unapplied: true,
          limit: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
          offset: 0,
        }),
      )
    }
  }, [clientId])

  useEffect(() => {
    if (clientId) {
      dispatch(
        fetchInvoicePage({
          unpaid: true,
          clientId,
          limit: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
          offset: 0,
          refundStateIds: [],
          stateIds: [],
        }),
      )
    }
  }, [clientId])

  return (
    <>
      {showUnpaidInvoiceSection && (
        <>
          <Text mb={1} mt={2} variant="h4">
            {t('Soap:SOAP_CLIENT_INVOICE_LIST.UNPAID_INVOICES')}
          </Text>
          {hasInvoiceError && (
            <CompactAlert mb={1} message={selectedInvoiceIds.message} />
          )}
          <Box>
            <InvoicesInfiniteLoaderList
              showDueToPay
              unpaid
              InfiniteLoaderListProps={{
                useWindowScroll: false,
              }}
              classes={{
                table: classNames(classes.table, {
                  [classes.tableWithError]: hasInvoiceError,
                }),
                tableCell: classes.tableUnpaidInvoiceCell,
                tableHeader: classes.tableHeader,
              }}
              clientId={clientId}
              hasError={hasInvoiceError}
              selectedInvoiceIds={selectedInvoiceIds.value}
              sortColumns={(columns) => swapElements(columns, 5, 6)}
              onCheckInvoices={onCheckInvoices}
            />
          </Box>
        </>
      )}

      {showUnappliedPaymentSection && (
        <>
          <Text mb={1} mt={3} variant="h4">
            {t('Common:UNAPPLIED_PAYMENTS')}
          </Text>
          {hasPaymentError && (
            <CompactAlert mb={1} message={selectedPaymentIds.message} />
          )}
          <PaymentsInfiniteLoaderList
            unapplied
            InfiniteLoaderListProps={{
              useWindowScroll: false,
            }}
            classes={{
              table: classNames(classes.table, {
                [classes.tableWithError]: hasPaymentError,
              }),
              tableCell: classes.tableUnappliedPaymentCell,
              tableHeader: classes.tableHeader,
            }}
            clientId={clientId}
            hasError={hasPaymentError}
            selectedPaymentIds={selectedPaymentIds.value}
            sortColumns={(columns) =>
              swapElements(swapElements(columns, 6, 8), 6, 7)
            }
            onCheckPayments={onCheckPayments}
            onClickEstimate={onClickEstimate}
          />
        </>
      )}
      {(hasUnappliedPayments || hasUnpaidInvoices) && (
        <PaymentSummary
          totalDueToPay={totalDueToPay}
          totalSelectedUnappliedPayments={totalSelectedUnappliedPayments}
          totalSelectedUnpaidInvoices={totalSelectedUnpaidInvoices}
        />
      )}
      <PaymentSection
        clientId={clientId}
        hasApplyPaymentsEnabled={hasApplyPaymentsEnabled}
        isLoading={isFetchingUnpaidInvoices || isSaving}
        isOtherPaymentType={isOtherPaymentType}
        paymentAmountField={paymentAmountField}
        paymentTypeField={paymentTypeField}
        serviceFeeAmount={serviceFeeAmount}
      />
    </>
  )
}
