import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { ApiError, Business } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import {
  MESSAGE_WARN_MISSING_MAPPING,
  MIGRATION_EXCEPTIONS_BATCH_SIZE,
  MigrationStatuses,
} from '~/constants/migration'
import {
  MigrationSession,
  MigrationStructure,
} from '~/types/entities/migration'

import { updateBusiness } from '../actions/businesses'
import {
  acceptUpdatedSession,
  applyMappings,
  applyMappingsFailure,
  applyMappingsSuccess,
  createImportSession,
  createImportSessionFailure,
  deleteSessionFile,
  deleteSessionFileFailure,
  deleteSessionFileSuccess,
  editImportSettings,
  editImportSettingsFailure,
  editImportSettingsSuccess,
  editMapping,
  editMappingFailure,
  editMappingSuccess,
  fetchActivationToken,
  fetchActivationTokenFailure,
  fetchActivationTokenSilentFailure,
  fetchActivationTokenSuccess,
  fetchBusinessRelationsMappings,
  fetchBusinessRelationsMappingsFailure,
  fetchBusinessRelationsMappingsSuccess,
  fetchExporterUrl,
  fetchExporterUrlFailure,
  fetchExporterUrlSuccess,
  fetchImportSettings,
  fetchImportSettingsFailure,
  fetchImportSettingsSuccess,
  fetchMappingStructures,
  fetchMappingStructuresFailure,
  fetchMappingStructuresSuccess,
  fetchMigrationAnalyzeStatus,
  fetchMigrationAnalyzeStatusFailure,
  fetchMigrationAnalyzeStatusSuccess,
  fetchMigrationImportStatus,
  fetchMigrationImportStatusFailure,
  fetchMigrationImportStatusSuccess,
  fetchMigrationSessions,
  fetchMigrationSessionsFailure,
  fetchMigrationSessionsSuccess,
  fetchMoreMappings,
  fetchMoreMappingsFailure,
  fetchMoreMappingsSuccess,
  fetchNonFixableLogs,
  fetchNonFixableLogsFailure,
  fetchNonFixableLogsSuccess,
  fetchPreprocessingStatus,
  fetchPreprocessingStatusFailure,
  fetchPreprocessingStatusSuccess,
  fetchSessionFiles,
  fetchSessionFilesFailure,
  fetchSessionFilesSuccess,
  fetchSessionFileUrl,
  fetchSessionFileUrlFailure,
  fetchSessionFileUrlSuccess,
  fetchSessionSupportedEntities,
  fetchSessionSupportedEntitiesFailure,
  fetchSessionSupportedEntitiesSuccess,
  finishReviewExceptions,
  focusMappingStructure,
  generateActivationToken,
  generateActivationTokenFailure,
  generateActivationTokenSuccess,
  preprocessingStarted,
  requestFillMissingMapping,
  startMigration,
  startMigrationAnalyze,
  startMigrationAnalyzeFailure,
  startMigrationAnalyzeSuccess,
  startMigrationFailure,
  startMigrationSuccess,
  startPreprocessing,
  startPreprocessingFailure,
  startPreprocessingSuccess,
  updateSessionStatus,
  updateSessionStatusFailure,
  updateSessionStatusSuccess,
  uploadSessionFile,
  uploadSessionFileFailure,
  uploadSessionFileSuccess,
} from '../actions/migration'
import {
  UPDATE_BUSINESS_FAILURE,
  UPDATE_BUSINESS_SUCCESS,
} from '../actions/types/businesses'
import {
  APPLY_MAPPINGS,
  CREATE_IMPORT_SESSION,
  DELETE_SESSION_FILE,
  EDIT_IMPORT_SETTINGS,
  EDIT_IMPORT_SETTINGS_FAILURE,
  EDIT_IMPORT_SETTINGS_SUCCESS,
  EDIT_MAPPING,
  FETCH_ACTIVATION_TOKEN,
  FETCH_BUSINESS_RELATIONS_MAPPINGS,
  FETCH_EXPORTER_URL,
  FETCH_IMPORT_SETTINGS,
  FETCH_MAPPING_STRUCTURES,
  FETCH_MIGRATION_ANALYZE_STATUS,
  FETCH_MIGRATION_IMPORT_STATUS,
  FETCH_MIGRATION_SESSIONS,
  FETCH_MORE_MAPPINGS,
  FETCH_NOFIX_LOGS,
  FETCH_PREPROCESSING_STATUS,
  FETCH_SESSION_FILE_URL,
  FETCH_SESSION_FILES,
  FETCH_SESSION_SUPPORTED_ENTITIES,
  GENERATE_ACTIVATION_TOKEN,
  START_MIGRATION,
  START_MIGRATION_ANALYZE,
  START_PREPROCESSING,
  UPDATE_SESSION_STATUS,
  UPLOAD_SESSION_FILE,
} from '../actions/types/migration'
import { registerWarnAlert } from '../duck/uiAlerts'
import { getBusiness } from '../reducers/businesses'
import {
  getCurrentSessionEntitiesList,
  getFocusedMappingStructureIndex,
  getMappingStructures,
  getSession,
} from '../reducers/migration'
import { waitForSubsequentCall } from './utils'
import requestAPI from './utils/requestAPI'

export function* applyMappingsSaga({
  sessionId,
  field,
  entity,
  isEnterprise,
}: ReturnType<typeof applyMappings>) {
  try {
    const applyApiMethod = isEnterprise
      ? API.applyDeltaMappings
      : API.applyMappings
    // TODO: listen real response when it's fixed
    yield requestAPI(applyApiMethod, sessionId, field, entity)
    const missingMappingIndex = null
    if (missingMappingIndex === 0) {
      const halfOfBatch = MIGRATION_EXCEPTIONS_BATCH_SIZE / 2
      const from = Math.max(0, missingMappingIndex - halfOfBatch)
      const to = missingMappingIndex + halfOfBatch
      yield put(fetchMoreMappings(sessionId, field, entity, from, to, false))
      yield put(requestFillMissingMapping(missingMappingIndex))
      yield put(registerWarnAlert(MESSAGE_WARN_MISSING_MAPPING))
    } else {
      const activeViewIndex: number = yield select(
        getFocusedMappingStructureIndex,
      )
      const updatedIndex = activeViewIndex + 1
      const structures: MigrationStructure[] = yield select(
        getMappingStructures,
      )
      if (structures?.length && updatedIndex >= structures.length) {
        if (!isEnterprise) {
          yield put(finishReviewExceptions(sessionId))
        }
      } else {
        yield put(focusMappingStructure(updatedIndex))
      }
    }
    yield put(applyMappingsSuccess())
  } catch (error) {
    yield put(applyMappingsFailure(error as ApiError))
  }
}

export function* editMappingSaga({
  sessionId,
  mapping,
  isEnterprise,
}: ReturnType<typeof editMapping>) {
  try {
    const updateApiMethod = isEnterprise
      ? API.updateDeltaMapping
      : API.updateMapping
    const updatedMapping = yield* requestAPI(
      updateApiMethod,
      sessionId,
      mapping,
    )
    yield put(editMappingSuccess(updatedMapping))
  } catch (error) {
    yield put(editMappingFailure(error as ApiError))
  }
}

export function* fetchMappingStructuresSaga({
  sessionId,
}: ReturnType<typeof fetchMappingStructures>) {
  try {
    const structures = yield* requestAPI(API.fetchFieldsBySessionId, sessionId)
    yield put(fetchMappingStructuresSuccess(structures))
  } catch (error) {
    yield put(fetchMappingStructuresFailure(error as ApiError))
  }
}

export function* editImportSettingsSaga({
  sessionId,
  settingsToUpdate,
}: ReturnType<typeof editImportSettings>) {
  try {
    const settings = yield* requestAPI(
      API.updateSettingBySessionId,
      sessionId,
      settingsToUpdate,
    )
    yield put(editImportSettingsSuccess(settings))
  } catch (error) {
    yield put(editImportSettingsFailure(error as ApiError))
  }
}

export function* updateSessionStatusSaga({
  sessionId,
  status,
}: ReturnType<typeof updateSessionStatus>) {
  try {
    const currentSession: MigrationSession = yield select(getSession(sessionId))
    const newSession: MigrationSession = {
      ...currentSession,
      status,
    }
    const updatedSession = yield* requestAPI(
      API.updateImportSession,
      newSession,
    )
    yield put(acceptUpdatedSession(updatedSession))
    yield put(updateSessionStatusSuccess())
  } catch (error) {
    yield put(updateSessionStatusFailure(error as ApiError))
  }
}

export function* fetchExporterUrlSaga({
  pimsId,
  selectedBusinessId,
  exporterType,
}: ReturnType<typeof fetchExporterUrl>) {
  try {
    const url = yield* requestAPI(
      API.fetchExporterUrl,
      pimsId,
      selectedBusinessId,
      exporterType,
    )
    yield put(fetchExporterUrlSuccess(url))
  } catch (error) {
    yield put(fetchExporterUrlFailure(error as ApiError))
  }
}

export function* fetchMigrationImportSessionsSaga({
  businessId,
}: ReturnType<typeof fetchMigrationSessions>) {
  try {
    const {
      result: list,
      entities: { importSession: map },
    } = yield* requestAPI(API.fetchSessionsByBusinessId, businessId)
    yield put(fetchMigrationSessionsSuccess(list, map))
  } catch (error) {
    yield put(fetchMigrationSessionsFailure(error as ApiError))
  }
}

export function* startMigrationAnalyzeSaga({
  sessionId,
  isEnterprise,
  entitiesList = [],
}: ReturnType<typeof startMigrationAnalyze>) {
  try {
    const startApiMethod = isEnterprise
      ? API.startAnalysisEnterprise
      : API.startAnalysis
    yield* requestAPI(startApiMethod, sessionId, entitiesList)
    yield put(
      acceptUpdatedSession({
        id: sessionId,
        status: MigrationStatuses.ANALYSIS_IN_PROGRESS,
      } as MigrationSession),
    )

    yield put(startMigrationAnalyzeSuccess())
  } catch (error) {
    yield put(startMigrationAnalyzeFailure(error as ApiError))
  }
}

export function* fetchMigrationAnalyzeStatusSaga({
  sessionId,
}: ReturnType<typeof fetchMigrationAnalyzeStatus>) {
  try {
    const { status, entities } = yield* requestAPI(
      API.fetchAnalysisStatus,
      sessionId,
    )
    const updatedSession: Partial<MigrationSession> = { id: sessionId, status }
    yield put(acceptUpdatedSession(updatedSession))
    yield put(fetchMigrationAnalyzeStatusSuccess(entities))
  } catch (error) {
    yield put(fetchMigrationAnalyzeStatusFailure(error as ApiError))
  }
}

export function* fetchMigrationImportStatusSaga({
  sessionId,
  skipSessionUpdate,
}: ReturnType<typeof fetchMigrationImportStatus>) {
  try {
    const { status, entities } = yield* requestAPI(
      API.fetchImportStatus,
      sessionId,
    )

    if (!skipSessionUpdate) {
      const updatedSession = {
        id: sessionId,
        status,
      } as Partial<MigrationSession>
      yield put(acceptUpdatedSession(updatedSession))
    }

    yield put(fetchMigrationImportStatusSuccess(entities))
  } catch (error) {
    yield put(fetchMigrationImportStatusFailure(error as ApiError))
  }
}

export function* fetchMoreMappingsSaga({
  sessionId,
  field,
  entity,
  from,
  to,
  includeResolved,
  isEnterprise,
}: ReturnType<typeof fetchMoreMappings>) {
  try {
    const fetchApiMethod = isEnterprise
      ? API.fetchEnterpriseMappings
      : API.fetchMappings
    const {
      result: { data: list, totalCount },
      entities: { importMapping: map },
    } = yield* requestAPI(
      fetchApiMethod,
      sessionId,
      field,
      entity,
      from,
      to,
      includeResolved,
    )
    yield put(fetchMoreMappingsSuccess(list, map, totalCount))
  } catch (error) {
    yield put(fetchMoreMappingsFailure(error as ApiError))
  }
}

export function* startMigrationSaga({
  sessionId,
  settings,
  isEnterprise,
}: ReturnType<typeof startMigration>) {
  try {
    const startApiMethod = isEnterprise
      ? API.startImportEnterprise
      : API.startImport
    const currentBusiness: Business | undefined = yield select(
      getBusiness(settings.businessId),
    )
    const newBusiness = {
      ...currentBusiness,
      timeZone: settings.practiceTimezone,
    }
    const entitiesList: string[] = yield select(getCurrentSessionEntitiesList)
    yield all([
      waitForSubsequentCall({
        action: editImportSettings(sessionId, {
          timeZone: settings.importTimezone,
        }),
        successType: EDIT_IMPORT_SETTINGS_SUCCESS,
        failureType: EDIT_IMPORT_SETTINGS_FAILURE,
      }),
      waitForSubsequentCall({
        action: updateBusiness(newBusiness as Business),
        successType: UPDATE_BUSINESS_SUCCESS,
        failureType: UPDATE_BUSINESS_FAILURE,
      }),
    ])
    yield* requestAPI(startApiMethod, sessionId, entitiesList)
    yield put(
      acceptUpdatedSession({
        id: sessionId,
        status: MigrationStatuses.IMPORT_IN_PROGRESS,
      } as Partial<MigrationSession>),
    )
    yield put(startMigrationSuccess())
  } catch (error) {
    yield put(startMigrationFailure(error as ApiError))
  }
}

export function* fetchNonFixableLogsSaga({
  sessionId,
}: ReturnType<typeof fetchNonFixableLogs>) {
  try {
    const logs = yield* requestAPI(API.fetchImportLogsBySessionId, sessionId)
    yield put(fetchNonFixableLogsSuccess(logs))
  } catch (error) {
    yield put(fetchNonFixableLogsFailure(error as ApiError))
  }
}

export function* fetchActivationTokenSaga({
  businessId,
  pimsId,
}: ReturnType<typeof fetchActivationToken>) {
  try {
    const token = yield* requestAPI(
      API.fetchActivationToken,
      businessId,
      pimsId,
    )
    yield put(fetchActivationTokenSuccess(token))
  } catch (error) {
    const err = error as ApiError
    const isSessionNotFound = err.status === 412
    const errorAction = isSessionNotFound
      ? fetchActivationTokenSilentFailure
      : fetchActivationTokenFailure

    yield put(errorAction(err))
  }
}

export function* fetchSessionFilesSaga({
  sessionId,
}: ReturnType<typeof fetchSessionFiles>) {
  try {
    const files = yield* requestAPI(API.fetchSessionFiles, sessionId)
    yield put(fetchSessionFilesSuccess(files))
  } catch (error) {
    yield put(fetchSessionFilesFailure(error as ApiError))
  }
}

export function* uploadSessionFileSaga({
  sessionId,
  file,
}: ReturnType<typeof uploadSessionFile>) {
  try {
    const files = yield* requestAPI(API.uploadSessionFile, sessionId, file)
    yield put(uploadSessionFileSuccess(files))
  } catch (error) {
    yield put(uploadSessionFileFailure(error as ApiError))
  }
}

export function* deleteSessionFileSaga({
  sessionId,
  name,
  extension,
}: ReturnType<typeof deleteSessionFile>) {
  try {
    const files = yield* requestAPI(
      API.deleteSessionFile,
      sessionId,
      name,
      extension,
    )
    yield put(deleteSessionFileSuccess(files))
  } catch (error) {
    yield put(deleteSessionFileFailure(error as ApiError))
  }
}

export function* fetchSessionFileUrlSaga({
  sessionId,
  name,
  extension,
}: ReturnType<typeof fetchSessionFileUrl>) {
  try {
    const files = yield* requestAPI(
      API.fetchSessionFileUrl,
      sessionId,
      name,
      extension,
    )
    yield put(fetchSessionFileUrlSuccess(files))
  } catch (error) {
    yield put(fetchSessionFileUrlFailure(error as ApiError))
  }
}

export function* startPreprocessingSaga({
  sessionId,
}: ReturnType<typeof startPreprocessing>) {
  try {
    yield* requestAPI(API.startPreprocessing, sessionId)
    yield put(startPreprocessingSuccess())
    if (sessionId) {
      yield put(preprocessingStarted(sessionId))
    }
  } catch (error) {
    yield put(startPreprocessingFailure(error as ApiError))
  }
}

export function* fetchPreprocessingStatusSaga({
  sessionId,
}: ReturnType<typeof fetchPreprocessingStatus>) {
  try {
    const status = yield* requestAPI(API.fetchPreprocessingStatus, sessionId)
    yield put(fetchPreprocessingStatusSuccess(status))
  } catch (error) {
    yield put(fetchPreprocessingStatusFailure(error as ApiError))
  }
}

export function* createImportSessionSaga({
  businessId,
  exporterPimsId,
  pimsId,
  sourcePimsId,
}: ReturnType<typeof createImportSession>) {
  try {
    yield* requestAPI(
      API.createImportSession,
      businessId,
      exporterPimsId,
      pimsId,
      sourcePimsId,
    )
    yield put(fetchMigrationSessions(businessId))
  } catch (error) {
    yield put(createImportSessionFailure(error as ApiError))
  }
}

export function* fetchImportSettingsSaga({
  sessionId,
}: ReturnType<typeof fetchImportSettings>) {
  try {
    const settings = yield* requestAPI(API.fetchSettingBySessionId, sessionId)
    yield put(fetchImportSettingsSuccess(settings))
  } catch (error) {
    yield put(fetchImportSettingsFailure(error as ApiError))
  }
}

export function* generateActivationTokenSaga({
  pimsId,
  selectedBusinessId,
  exporterType,
}: ReturnType<typeof generateActivationToken>) {
  try {
    const token = yield* requestAPI(
      API.generateActivationToken,
      pimsId,
      selectedBusinessId,
      exporterType,
    )
    yield put(generateActivationTokenSuccess(token))
  } catch (error) {
    yield put(generateActivationTokenFailure(error as ApiError))
  }
}

export function* fetchSessionSupportedEntitiesSaga({
  sessionId,
}: ReturnType<typeof fetchSessionSupportedEntities>) {
  try {
    const entities = yield* requestAPI(
      API.fetchSessionSupportedEntities,
      sessionId,
    )
    yield put(fetchSessionSupportedEntitiesSuccess(entities))
  } catch (error) {
    yield put(fetchSessionSupportedEntitiesFailure(error as ApiError))
  }
}

export function* fetchBusinessRelationsMappingsSaga({
  selectedBusinessId,
  relationType,
}: ReturnType<typeof fetchBusinessRelationsMappings>) {
  try {
    const mappings = yield* requestAPI(
      API.fetchBusinessRelationsMappings,
      selectedBusinessId,
      relationType,
    )
    yield put(fetchBusinessRelationsMappingsSuccess(mappings))
  } catch (error) {
    yield put(fetchBusinessRelationsMappingsFailure(error as ApiError))
  }
}

export default function* migrationSaga() {
  yield all([
    takeEvery(FETCH_EXPORTER_URL, fetchExporterUrlSaga),
    takeEvery(UPDATE_SESSION_STATUS, updateSessionStatusSaga),
    takeLatest(APPLY_MAPPINGS, applyMappingsSaga),
    takeLatest(EDIT_IMPORT_SETTINGS, editImportSettingsSaga),
    takeLatest(EDIT_MAPPING, editMappingSaga),
    takeLatest(FETCH_MAPPING_STRUCTURES, fetchMappingStructuresSaga),
    takeLatest(FETCH_MIGRATION_ANALYZE_STATUS, fetchMigrationAnalyzeStatusSaga),
    takeLatest(FETCH_MIGRATION_IMPORT_STATUS, fetchMigrationImportStatusSaga),
    takeLatest(FETCH_MIGRATION_SESSIONS, fetchMigrationImportSessionsSaga),
    takeLatest(FETCH_MORE_MAPPINGS, fetchMoreMappingsSaga),
    takeLatest(FETCH_NOFIX_LOGS, fetchNonFixableLogsSaga),
    takeLatest(START_MIGRATION, startMigrationSaga),
    takeLatest(START_MIGRATION_ANALYZE, startMigrationAnalyzeSaga),
    takeLatest(FETCH_ACTIVATION_TOKEN, fetchActivationTokenSaga),
    takeLatest(FETCH_SESSION_FILES, fetchSessionFilesSaga),
    takeLatest(UPLOAD_SESSION_FILE, uploadSessionFileSaga),
    takeLatest(DELETE_SESSION_FILE, deleteSessionFileSaga),
    takeLatest(FETCH_SESSION_FILE_URL, fetchSessionFileUrlSaga),
    takeLatest(START_PREPROCESSING, startPreprocessingSaga),
    takeLatest(FETCH_PREPROCESSING_STATUS, fetchPreprocessingStatusSaga),
    takeLatest(CREATE_IMPORT_SESSION, createImportSessionSaga),
    takeLatest(FETCH_IMPORT_SETTINGS, fetchImportSettingsSaga),
    takeLatest(GENERATE_ACTIVATION_TOKEN, generateActivationTokenSaga),
    takeLatest(
      FETCH_SESSION_SUPPORTED_ENTITIES,
      fetchSessionSupportedEntitiesSaga,
    ),
    takeLatest(
      FETCH_BUSINESS_RELATIONS_MAPPINGS,
      fetchBusinessRelationsMappingsSaga,
    ),
  ])
}
