import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ControlButtonGroup,
  ControlButtonGroupName,
  HtmlNotesPreview,
  LanguageUtils,
  LinkButton,
  Nil,
  PermissionArea,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import ControlledSubstanceLabel from '~/components/common/labels/ControlledSubstanceLabel'
import Link from '~/components/common/link/Link'
import ChewyPrescriptionPdfButton from '~/components/dashboard/prescription/ChewyPrescriptionPdfButton'
import PrescriptionChewyOrderInfo from '~/components/dashboard/prescription/prescription-editor/PrescriptionChewyOrderInfo'
import PrescriptionDetails from '~/components/dashboard/prescription/prescription-editor/PrescriptionDetails'
import {
  getIdFromTimelineItem,
  hasRefillChangedFromParentPrescription,
  timelinePrescriptionToRegularPrescription,
} from '~/components/dashboard/soap/utils/prescriptionUtils'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { PrescriptionType } from '~/constants/prescription'
import { OrderType } from '~/constants/SOAPStates'
import {
  cancelRefills,
  editOrder,
  setPrescriptionForPrint,
} from '~/store/actions/orders'
import { useGetChargeSheetId } from '~/store/hooks/chargeSheet'
import { useOpenInvoice } from '~/store/hooks/finance'
import {
  useLogItemStateGetter,
  useOpenPrescriptionDialogAfterFetchUnifiedOrder,
  useRefillDialog,
} from '~/store/hooks/orders'
import {
  useChewyPrescriptionStateLabelProps,
  useCreateOrUpdatePrescription,
  useGetApprovalOnBehalfOfString,
} from '~/store/hooks/prescription'
import { getCRUDByArea, getCurrentBusinessId } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getOrdersIsSending, getUnifiedOrder } from '~/store/reducers/orders'
import {
  CRUDOrderOptions,
  Order,
  Prescription,
  PrescriptionTimelineItem,
} from '~/types'
import { addOriginalBusinessId } from '~/utils'
import {
  getPrescriptionType,
  getPrescriptionTypeLabel,
  getPrescriptionWorkflowType,
} from '~/utils/prescription'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import TimelineCard, { TimelineCardProps } from '../TimelineCard'
import TimelineCardActions from '../TimelineCardActions'
import TimelineCardContent from '../TimelineCardContent'
import PrescriptionsCardDetails from './PrescriptionsCardDetails'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    substanceLabelRoot: {
      position: 'absolute',
      width: 165,
      right: 12,
      top: 12,
    },
    controlled: {
      fontSize: '1.4rem',
    },
    section: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    detailsFooter: {
      padding: theme.spacing(1, 2),
      borderTop: theme.constants.tableBorder,
    },
    iconButton: {
      margin: 0,
    },
    refillActions: {
      marginLeft: 'auto',
    },
    dispenseRefillAction: {
      marginLeft: theme.spacing(2),
    },
  }),
  { name: 'PrescriptionsCard' },
)

interface PrescriptionsCardProps extends TimelineCardProps {
  isInvoice?: boolean
  item: PrescriptionTimelineItem
}

const getPrescriptionWithOrigin = (itemProp: PrescriptionTimelineItem) => ({
  ...itemProp,
  prescription: {
    ...itemProp.prescription,
    origin: itemProp.origin,
  },
})

const PrescriptionsCard = ({
  item: itemProp,
  isInvoice,
  ...rest
}: PrescriptionsCardProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Timeline'])

  const { read: SOAPReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.SOAP),
  )
  const { create: prescriptionCreatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.PRESCRIPTION),
  )
  const { create: invoiceCreatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.INVOICE),
  )
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const unifiedOrder = useSelector(getUnifiedOrder)

  const item = getPrescriptionWithOrigin(itemProp)

  const isContextItem = useIsCurrentContextItem(item)

  const clientId = R.path(['prescription', 'clientId'], item)
  const outsideSoap = !item.soapId
  const refillsCount = item.prescription.refills?.length || 0
  const canceledRefills = Boolean(item.prescription.cancelledBy)
  const { isInHouse } = getPrescriptionType(item.prescription.prescriptionType)
  const chargeSheetId = useGetChargeSheetId(clientId)

  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const [openRefillDialog, closeRefillDialog] = useRefillDialog()
  const { fetchUnifiedOrderAndOpenPrescription } =
    useOpenPrescriptionDialogAfterFetchUnifiedOrder({
      overrideParams: {
        outsideSoap: true,
        skipOrderFetching: false,
        prescription: unifiedOrder,
      },
      prescriptionDialogProps: {
        selector: getOrdersIsSending,
      },
      isInvoice,
    })
  const createOrUpdatePrescription = useCreateOrUpdatePrescription()
  const logItemStateGetter = useLogItemStateGetter()
  const closeRefillDialogAfterSave = useCloseAfterCreation(
    closeRefillDialog,
    getOrdersIsSending,
  )

  const openInvoice = useOpenInvoice(
    item.prescription.clientId,
    openInvoiceDialog,
  )

  const handleViewInvoice = useCallback(
    (invoiceId?: string | Nil) =>
      openInvoice({
        clientId: item.prescription.clientId,
        patientId: item.prescription.patientId,
        soapId: item.soapId,
        logId: item.prescription.id,
        logType: OrderType.PRESCRIPTION,
        invoiceId,
      }),
    [item.prescription.clientId, item.prescription.patientId, item.soapId],
  )

  const refillsRemaining = item.prescription.quantityNumbRefills
    ? Math.max(item.prescription.quantityNumbRefills - refillsCount, 0)
    : 0

  const updateRefill = (refill: Order, options: CRUDOrderOptions) => {
    const orderOptions = {
      ...options,
      outsideSoap: true,
    }

    if (outsideSoap) {
      orderOptions.invoiceId = refill.invoiceId
    } else if (refill.soapId) {
      orderOptions.soapId = refill.soapId
    }

    dispatch(editOrder(refill, orderOptions))
  }

  const chargeSheetCreateRefill = (
    refill: Order,
    options: CRUDOrderOptions,
  ) => {
    createOrUpdatePrescription(logItemStateGetter(refill)!, {
      ...options,
      outsideSoap: true,
      invoiceId: chargeSheetId,
    })
    if (options.print) {
      closeRefillDialogAfterSave()
    }
  }

  const oldCreateRefill = (refill: Prescription) =>
    openInvoice({
      clientId: item.prescription.clientId,
      patientId: item.prescription.patientId,
      isOtcInvoice: true,
      create: true,
      refillItem: timelinePrescriptionToRegularPrescription(
        refill,
        item,
        isFoodCatalogEnabled,
      ),
    })

  const handlePreviewRefill = (refill: Prescription) => {
    // TODO used to recreate invoice for refill if initially it wasn't created or created wrong
    if (
      !isChargeSheetEnabled &&
      !refill.invoiceId &&
      refill.prescriptionType === PrescriptionType.IN_HOUSE
    ) {
      oldCreateRefill(refill)
    } else {
      openRefillDialog({
        outsideSoap: true,
        prescription: timelinePrescriptionToRegularPrescription(
          refill,
          item,
          isFoodCatalogEnabled,
        ),
        onSave: updateRefill,
      })
    }
  }

  const handleViewRefill = (refill: Prescription) =>
    openInvoice({
      clientId: refill.clientId,
      patientId: refill.patientId,
      logType: OrderType.PRESCRIPTION,
      logId: refill.id,
    })

  const handlePrint = (prescription: Prescription) => {
    dispatch(
      setPrescriptionForPrint(
        timelinePrescriptionToRegularPrescription(
          prescription,
          item,
          isFoodCatalogEnabled,
        ),
      ),
    )
  }

  const openPrescriptionPreview = () =>
    fetchUnifiedOrderAndOpenPrescription(item.prescription.id)

  const handlePreviewPrescription = useCallback(
    () => openPrescriptionPreview(),
    [
      item.prescription.clientId,
      item.prescription.patientId,
      item.prescription.invoiceId,
      item.prescription.id,
    ],
  )

  const handleCancelRefills = () =>
    dispatch(cancelRefills(item.prescription.id))

  const handleDispenseRefill = () => {
    const refillItem = timelinePrescriptionToRegularPrescription(
      item.prescription,
      item,
      isFoodCatalogEnabled,
    )
    if (isChargeSheetEnabled) {
      openRefillDialog({
        outsideSoap: true,
        isInvoice: false,
        prescription: {
          ...R.omit(['id', 'soapId'], refillItem),
          parent: { ...R.omit(['soapId'], refillItem) },
        },
        onSave: chargeSheetCreateRefill,
      })
    } else {
      oldCreateRefill(refillItem)
    }
  }

  const prescriptionTypeLabel = getPrescriptionTypeLabel({
    prescriptionType: item.prescription.prescriptionType,
    origin: item.prescription.origin,
  })
  const title = `${prescriptionTypeLabel}–${LanguageUtils.getTranslatedFieldName(
    item,
  )}`

  const stateLabelProps = useChewyPrescriptionStateLabelProps(
    item.prescription,
    item.cancellationReasonDetail,
  )

  const { isChewyReactiveRx, isChewyActiveRx } = getPrescriptionWorkflowType({
    orderType: item.prescription.type,
    prescriptionType: item.prescription.prescriptionType,
    origin: item.prescription.origin,
  })

  const isChewy = isChewyReactiveRx || isChewyActiveRx

  const doctor = item.prescription.doctor || item.vet

  const approvalOnBehalfOfString = useGetApprovalOnBehalfOfString({
    doctor,
    showDoctorOnly: !isChewy,
    signerId: item.signerId,
  })

  return (
    <TimelineCard
      noMarginBottom
      originBusinessId={item.businessId}
      title={title}
      {...item}
      {...rest}
    >
      <TimelineCardContent noTypography>
        {item.controlled && (
          <ControlledSubstanceLabel
            classes={{
              root: classes.substanceLabelRoot,
              controlled: classes.controlled,
            }}
          />
        )}
        {doctor && <Text strong>{approvalOnBehalfOfString}</Text>}

        <PrescriptionDetails
          showDispensed
          showRefills
          prescription={item.prescription}
        />
        {isChewy && (
          <Grid pt={2}>
            <PrescriptionChewyOrderInfo prescription={item.prescription} />
          </Grid>
        )}
      </TimelineCardContent>
      <PrescriptionsCardDetails
        date={item.prescription.creationDate}
        inHousePrescription={isInHouse}
        user={item.prescription.filledBy}
      >
        {item.soapId && SOAPReadPermissions && (
          <Link
            to={addOriginalBusinessId(
              `/soap/${item.soapId}`,
              isPatientSharingEnabled ? item.businessId : null,
            )}
          >
            {t('Common:VIEW_SOAP')}
          </Link>
        )}
        {isContextItem && !item.soapId && SOAPReadPermissions && (
          <ControlButtonGroup
            buttons={[
              !isChewy && {
                name: ControlButtonGroupName.PRINT,
                onClick: () => handlePrint(item.prescription),
              },
              Boolean(getIdFromTimelineItem(item)) && {
                name: ControlButtonGroupName.ESTIMATES,
                onClick: () => handleViewInvoice(getIdFromTimelineItem(item)),
              },
              {
                name: ControlButtonGroupName.PREVIEW,
                onClick: handlePreviewPrescription,
              },
            ]}
            classes={{
              iconButton: classes.iconButton,
            }}
          />
        )}
      </PrescriptionsCardDetails>
      {item.prescription.refills?.map((refill, index) => {
        const hasChanged = hasRefillChangedFromParentPrescription(
          item.prescription,
          refill,
        )

        const isContextRefillItem =
          !isPatientSharingEnabled || refill.businessId === currentBusinessId

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Grid className={classes.section} key={index}>
            <TimelineCardContent noTypography>
              <>
                <Grid container item alignItems="center" mt={2}>
                  <Text strong>{`${t('Common:REFILL_ONE')} ${
                    refill.refillOrdinalNumber
                  }`}</Text>
                  <BusinessShareIcon
                    businessIds={refill.businessId ? [refill.businessId] : []}
                  />
                </Grid>
                {hasChanged ? (
                  <PrescriptionDetails showDispensed prescription={refill} />
                ) : (
                  refill.notes && (
                    <HtmlNotesPreview
                      includeLabel
                      fontSize="1.4rem"
                      label={t(
                        'Clients:TIMELINE.PRESCRIPTION_CARD.ADMINISTRATION_NOTES',
                      )}
                      notes={refill.notes}
                    />
                  )
                )}
              </>
            </TimelineCardContent>
            <PrescriptionsCardDetails
              refill
              date={refill.creationDate}
              inHousePrescription={isInHouse}
              user={refill.filledBy}
            >
              {isContextRefillItem ? (
                <ControlButtonGroup
                  buttons={[
                    {
                      name: ControlButtonGroupName.PRINT,
                      onClick: () =>
                        handlePrint({ ...refill, parent: item.prescription }),
                    },
                    Boolean(refill.invoiceId) && {
                      name: ControlButtonGroupName.ESTIMATES,
                      onClick: () =>
                        isChargeSheetEnabled
                          ? handleViewRefill(refill)
                          : handleViewInvoice(refill.invoiceId),
                    },
                    {
                      name: ControlButtonGroupName.PREVIEW,
                      onClick: () => handlePreviewRefill(refill),
                    },
                  ]}
                  classes={{
                    iconButton: classes.iconButton,
                  }}
                />
              ) : null}
            </PrescriptionsCardDetails>
          </Grid>
        )
      })}
      {canceledRefills && (
        <PrescriptionsCardDetails
          cancelledRefills
          date={item.prescription.refillCancelDate}
          inHousePrescription={isInHouse}
          user={item.prescription.cancelledBy}
        />
      )}
      {refillsRemaining > 0 && !canceledRefills && isInHouse && (
        <TimelineCardContent noTypography className={classes.detailsFooter}>
          <Grid container alignItems="center">
            <Text strong>
              {t('Plurals:Z_ICU_WORKAROUND.REFILL_REMAINING', {
                refillsRemaining,
              })}
            </Text>
            {isContextItem && (
              <Grid item className={classes.refillActions}>
                <LinkButton onClick={handleCancelRefills}>
                  {t('Clients:TIMELINE.PRESCRIPTION_CARD.CANCEL_ALL_REFILLS')}
                </LinkButton>
                <LinkButton
                  className={classes.dispenseRefillAction}
                  disabled={
                    !invoiceCreatePermissions && !prescriptionCreatePermissions
                  }
                  onClick={handleDispenseRefill}
                >
                  {t('Common:DISPENSE_REFILL')}
                </LinkButton>
              </Grid>
            )}
          </Grid>
        </TimelineCardContent>
      )}
      <TimelineCardActions
        {...item}
        errorState={stateLabelProps.error}
        state={stateLabelProps.children}
        warningState={stateLabelProps.warning}
      >
        {isChewy && (
          <span>
            <ChewyPrescriptionPdfButton pdfUrl={item.prescription.pdfUrl} />
          </span>
        )}
      </TimelineCardActions>
    </TimelineCard>
  )
}

export default PrescriptionsCard
