import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Business,
  ButtonWithLoader,
  ErrorTooltip,
  PuiTextField,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import AddList from '~/components/common/AddList'
import ClinicLogo from '~/components/common/logos/ClinicLogo'
import { BusinessDto } from '~/store/dto/Business'
import { getBusinessIsLoading } from '~/store/reducers/businesses'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    title: {
      [theme.breakpoints.down('md')]: {
        fontSize: '2rem',
      },
    },
    mainContainer: {
      marginTop: theme.spacing(4),
      border: '1px solid #E5E5E5',
    },
    groupContainer: {
      padding: theme.spacing(2, 4),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
      },
      backgroundColor: '#FAFAFA',
    },
    fabContainer: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    practiceContainer: {
      padding: theme.spacing(2, 4),
      height: 264,
      overflow: 'auto',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1, 2),
      },
      [theme.breakpoints.down('sm')]: {
        height: 400,
      },
    },
    button: {
      width: 220,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    addRowContainer: {
      backgroundColor: theme.colors.panelRow,
    },
    practiceLogo: {
      padding: 0,
      width: 100,
      height: 56,
      boxShadow:
        '1px 1px 2px 0 rgba(60,56,56,0.14), 0 1px 4px 0 rgba(60,56,56,0.12)',
      marginLeft: theme.spacing(0.5),
    },
  }),
  { name: 'AddGroup' },
)

export interface AddGroupProps {
  groupName: string
  onAdd: () => void
  onAddPractice: () => void
  onDeletePractice: (practiceId: string) => void
  onGroupNameChange: (name: string) => void
  practices: (Business | BusinessDto)[]
}

const AddGroup = ({
  practices,
  groupName,
  onAdd,
  onDeletePractice,
  onAddPractice,
  onGroupNameChange,
}: AddGroupProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Admin', 'Businesses', 'Common'])
  const isLoading = useSelector(getBusinessIsLoading)

  const [touched, setTouched] = useState(false)

  const addGroup = () => {
    if (groupName && practices.length > 0) {
      onAdd()
      if (touched) {
        setTouched(false)
      }
    } else if (!touched) {
      setTouched(true)
    }
  }

  const list = practices.map(({ id, name, logo }) => ({
    id,
    logo,
    label: name,
  }))

  const map = practices.reduce(
    (acc, practice) => ({ ...acc, [practice.id]: practice }),
    {} as Record<string, Business | BusinessDto>,
  )

  const GetImageComponent = useCallback(
    (id: string) => (
      <ClinicLogo className={classes.practiceLogo} clinic={map[id]} />
    ),
    [map],
  )

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Text className={classes.title} variant="hero2">
          {t('Admin:PRACTICE.NEW_GROUP')}
        </Text>
      </Grid>
      <Grid container className={classes.mainContainer}>
        <Grid container className={classes.groupContainer}>
          <Grid item xs={12}>
            <ErrorTooltip
              message={t('Admin:PRACTICE.PLEASE_ENTER_GROUP_NAME')}
              open={touched && !groupName}
            >
              <PuiTextField
                error={touched && !groupName}
                inputProps={{ maxLength: 100 }}
                label={`${t('Businesses:GROUP_NAME')}*`}
                margin="none"
                value={groupName}
                onChange={Utils.handleFormTextInput(onGroupNameChange)}
              />
            </ErrorTooltip>
          </Grid>
        </Grid>
        <Grid container className={classes.practiceContainer}>
          <Grid item xs={12}>
            <ErrorTooltip
              message={t('Admin:PRACTICE.PLEASE_ADD_AT_LEAST_ONE_PRACTICE')}
              open={touched && practices.length === 0 && Boolean(groupName)}
            >
              <AddList
                addText={t('Common:ADD_PRACTICE')}
                classes={{
                  addRowContainer: classes.addRowContainer,
                }}
                getImage={GetImageComponent}
                items={list}
                onAdd={onAddPractice}
                onDelete={onDeletePractice}
              />
            </ErrorTooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.fabContainer} xs={12}>
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading}
          loading={isLoading}
          onClick={addGroup}
        >
          {t('Admin:PRACTICE.ADD_GROUP')}
        </ButtonWithLoader>
      </Grid>
    </Grid>
  )
}

export default AddGroup
