import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader, ClassesType, PuiTheme } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    button: {
      height: 40,
      minWidth: 152,
      padding: theme.spacing(1, 3),
    },
  }),
  { name: 'StepAddReminderInit' },
)

export interface StepAddReminderInitProps {
  classes?: ClassesType<typeof useStyles>
  onCreateFromProtocol?: () => void
  onCreateManually?: () => void
}

const StepAddReminderInit = ({
  classes: classesProp,
  onCreateManually,
  onCreateFromProtocol,
}: StepAddReminderInitProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Reminders')

  return (
    <Grid
      container
      className={classes.root}
      columnSpacing={2}
      p={2}
      wrap="nowrap"
    >
      <Grid item>
        <ButtonWithLoader
          className={classes.button}
          color="primary"
          onClick={onCreateFromProtocol}
        >
          {t('Reminders:STEP_ADD_REMINDER_INIT.CHOOSE_FROM_YOUR_PROTOCOLS')}
        </ButtonWithLoader>
      </Grid>
      <Grid item>
        <ButtonWithLoader
          className={classes.button}
          color="secondary"
          onClick={onCreateManually}
        >
          {t('Reminders:STEP_ADD_REMINDER_INIT.WRITE_MY_OWN_REMINDER')}
        </ButtonWithLoader>
      </Grid>
    </Grid>
  )
}

export default StepAddReminderInit
