import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { PuiTheme, Utils } from '@pbt/pbt-ui-components'
import {
  CheckCircle as CheckCircleIcon,
  Email,
  EmailOpened,
} from '@pbt/pbt-ui-components/src/icons'

import Avatar from '~/components/common/Avatar'
import QuickFilterButton from '~/components/common/buttons/QuickFilterButton'
import { ConversationStatus } from '~/constants/communications'
import { setConversationsListFilters } from '~/store/actions/conversations'
import { getCurrentUser } from '~/store/reducers/auth'
import { getConversationsListFilters } from '~/store/reducers/conversations'
import { TableFilter } from '~/types'

import CategoryButtonGroup from '../common/CategoryButtonGroup'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    quickFilterButton: {
      margin: theme.spacing(0.5, 1, 0.5, 0),
    },
    categoryButtonGroup: {
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'ConversationFilters' },
)

const ConversationFilters = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { clientId } = useParams()
  const currentUser = useSelector(getCurrentUser)
  const filters = useSelector(getConversationsListFilters)
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('sm'),
  )
  const useShinkFilters = Boolean(clientId)
  const { t } = useTranslation(['Common', 'Communications'])

  const statusFilterList = ((filters.status?.value as string) || '')
    .split(',')
    .filter(R.length)

  const setFilters = (newFilters: Record<string, TableFilter>) => {
    dispatch(setConversationsListFilters(newFilters))
  }

  const toggleStatusFilter = (status: string) =>
    Utils.toggleListItem(status, statusFilterList).join(',')

  const applyFilter = (filter: string, value: TableFilter) => {
    setFilters({ ...filters, [filter]: value })
  }

  const headerFilters = [
    {
      active: filters.assignee?.value === currentUser.id,
      iconComponent: Avatar,
      iconProps: { person: currentUser },
      text: t('Common:ASSIGNED_TO_ME'),
      onSelected: () => {
        setFilters({
          ...filters,
          assignee: {
            ...filters.assignee,
            value:
              filters.assignee?.value === currentUser.id
                ? null
                : currentUser.id,
            humanReadable: Utils.getPersonString(currentUser),
          },
        })
      },
    },
    {
      active: R.includes(ConversationStatus.ARCHIVED, statusFilterList),
      iconComponent: CheckCircleIcon,
      text: t('Communications:STATUS.CONVERSATION_ARCHIVED'),
      onSelected: () => {
        setFilters({
          ...filters,
          status: {
            ...filters.status,
            value: toggleStatusFilter(ConversationStatus.ARCHIVED),
          },
        })
      },
    },
    {
      active: R.includes(ConversationStatus.UNREAD, statusFilterList),
      text: t('Communications:STATUS.CONVERSATION_UNREAD'),
      iconComponent: Email,
      onSelected: () => {
        setFilters({
          ...filters,
          status: {
            ...filters.status,
            value: toggleStatusFilter(ConversationStatus.UNREAD),
          },
        })
      },
    },
    {
      active: R.includes(ConversationStatus.OPEN, statusFilterList),
      text: t('Communications:STATUS.CONVERSATION_OPEN'),
      iconComponent: EmailOpened,
      onSelected: () => {
        setFilters({
          ...filters,
          status: {
            ...filters.status,
            value: toggleStatusFilter(ConversationStatus.OPEN),
          },
        })
      },
    },
  ]

  return (
    <Grid
      container
      item
      xs
      alignItems="center"
      direction={isMobile ? 'column' : 'row'}
    >
      <CategoryButtonGroup
        applyFilter={applyFilter}
        className={classes.categoryButtonGroup}
        filter={filters.category}
      />
      {headerFilters
        .filter(
          (filter) =>
            !useShinkFilters || filter.text === t('Common:ASSIGNED_TO_ME'),
        )
        .map((filter) => (
          <Grid item key={filter.text}>
            <QuickFilterButton
              IconComponent={filter.iconComponent}
              IconProps={filter.iconProps}
              active={filter.active}
              className={classes.quickFilterButton}
              text={filter.text}
              onClick={filter.onSelected}
            />
          </Grid>
        ))}
    </Grid>
  )
}

export default ConversationFilters
