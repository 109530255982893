import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Icons, PuiTheme, Text, Utils } from '@pbt/pbt-ui-components'
import { MembershipStatus } from '@pbt/pbt-ui-components/src/constants/wellnessPlans'
import {
  Wellness as WellnessIcon,
  Wings as WingsIcon,
} from '@pbt/pbt-ui-components/src/icons'

import AlertIcon from '~/components/common/labels/AlertIcon'
import AlertLabel from '~/components/common/labels/AlertLabel'
import PatientInfoLabel from '~/components/common/labels/PatientInfoLabel'
import Link from '~/components/common/link/Link'
import PatientWarningAvatar from '~/components/common/PatientWarningAvatar'
import PuiTabs from '~/components/common/PuiTabs'
import useGetPreferredContactMethod from '~/components/dashboard/clients/details/new-client-and-patient/useGetPreferredContactMethod'
import { PatientPreferencesChip } from '~/components/dashboard/clients/patient/PatientPreferencesChip'
import { ClientPreferencesChip } from '~/components/dashboard/clients/preferences/ClientPreferencesChip'
import WellnessPlanMemberButton from '~/components/dashboard/wellness-plans/WellnessPlanMemberButton'
import { useClientMembershipStatus } from '~/components/dashboard/wellness-plans/wellnessPlanUtils'
import { AlertColorLevel } from '~/constants/alertColors'
import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusinessIsOmniChannel } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { getPatientStatus } from '~/utils'

import AppointmentLastVisitInfoLabel from './AppointmentLastVisitInfoLabel'
import AllReminders from './reminders/AllReminders'
import UpcomingAndOverdueReminders from './reminders/UpcomingAndOverdueReminders'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      height: '100%',
      backgroundColor: theme.colors.dialogHeaderBackground,
    },
    contactMethodText: {
      wordBreak: 'break-word',
      paddingLeft: theme.spacing(0.5),
    },
    link: {
      fontWeight: 500,
    },
    avatar: {
      width: 72,
      height: 72,
    },
    tabRoot: {
      padding: theme.spacing(1.5),
    },
    wpButton: {
      padding: theme.spacing(0, 1),
      width: 'fit-content',
    },
    chip: {
      width: 16,
      height: 16,
      marginRight: theme.spacing(0.5),
      overflow: 'visible',
    },
    largeChip: {
      width: 20,
      height: 20,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      overflow: 'visible',
    },
  }),
  { name: 'AppointmentClientAndPatientDetails' },
)

export interface AppointmentClientAndPatientDetailsProps {
  clientId: string
  onChangeRequested?: () => void
  patientId: string
}

const AppointmentClientAndPatientDetails = ({
  clientId,
  patientId,
  onChangeRequested,
}: AppointmentClientAndPatientDetailsProps) => {
  const classes = useStyles()

  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))
  const isOmnichannel = useSelector(getCurrentBusinessIsOmniChannel)
  const isWPSurfaceClientStatusEnabled = useSelector(
    getFeatureToggle(FeatureToggle.WP_SURFACE_CLIENT_STATUS),
  )

  const { t } = useTranslation(['Common', 'TimeTable'])
  const isTouch = useMediaQuery('(pointer:coarse)')

  const tabs = [
    {
      label: t('TimeTable:UPCOMING_OR_OVERDUE_REMINDERS'),
      component: UpcomingAndOverdueReminders,
    },
    {
      label: t('TimeTable:ALL_REMINDERS'),
      component: AllReminders,
    },
  ]

  const [selectedTab, setSelectedTab] = useState(0)

  const Component = tabs[selectedTab].component
  const patientStatus = getPatientStatus(patient)
  const preferredContactMethod = useGetPreferredContactMethod({ client })
  const membershipStatus = useClientMembershipStatus(clientId)

  return (
    <Grid container item className={classes.root} direction="column">
      <Grid container item direction="column" pt={5} px={2}>
        <Grid container item xs alignItems="center">
          {client?.alertText && (
            <AlertIcon
              alertColorId={client.alertColorId}
              alertColorLevel={AlertColorLevel.CLIENT}
              alertText={client.alertText}
              size={isTouch ? 'medium' : 'small'}
            />
          )}
          <ClientPreferencesChip
            className={isTouch ? classes.largeChip : classes.chip}
            clientId={clientId}
          />
          {isOmnichannel &&
            isWPSurfaceClientStatusEnabled &&
            (membershipStatus === MembershipStatus.PAUSED ||
              membershipStatus === MembershipStatus.ACTIVE) && (
              <WellnessIcon
                className={isTouch ? classes.largeChip : classes.chip}
                variant={
                  membershipStatus === MembershipStatus.PAUSED
                    ? Icons.IconVariant.PAUSED
                    : Icons.IconVariant.PRIMARY
                }
              />
            )}
          <Link
            className={classes.link}
            rel="noopener noreferrer"
            target="_blank"
            to={`/client/${clientId}`}
          >
            {Utils.getPersonString(client)}
          </Link>
          <Text className={classes.contactMethodText} variant="body">
            {preferredContactMethod.value}
          </Text>
        </Grid>
        <Grid container item alignItems="center" my={2} wrap="nowrap">
          <Grid item mr={1}>
            <PatientWarningAvatar
              classes={{
                avatar: classes.avatar,
              }}
              patientId={patientId}
            />
          </Grid>
          <Grid container item direction="column">
            <Grid container item alignItems="center" wrap="nowrap">
              <PatientPreferencesChip
                className={isTouch ? classes.largeChip : classes.chip}
                patientId={patientId}
              />
              <Link
                className={classes.link}
                rel="noopener noreferrer"
                target="_blank"
                to={`/patient/${patientId}`}
              >
                {patient?.name}
              </Link>
              {patient?.deceased && <WingsIcon />}
              {patient?.active === false && (
                <Grid item ml={1}>
                  <AlertLabel
                    small
                    message={t('Common:INACTIVE_ONE')}
                    variant="normal"
                  />
                </Grid>
              )}
              {onChangeRequested && (
                <Text
                  link
                  disabled={patientStatus.disabled}
                  ml={1}
                  variant="body2"
                  onClick={onChangeRequested}
                >
                  {t('Common:CHANGE_ACTION')}
                </Text>
              )}
            </Grid>
            <PatientInfoLabel patientId={patientId} />
            <Grid container item marginTop={0.5}>
              <WellnessPlanMemberButton
                className={classes.wpButton}
                clientId={client?.id}
                patientId={patient?.id}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item px={2}>
        <AppointmentLastVisitInfoLabel patientId={patientId} />
      </Grid>
      <PuiTabs
        classes={{
          tabRoot: classes.tabRoot,
        }}
        selectedTab={selectedTab}
        tabs={tabs}
        onSelectedTabChange={setSelectedTab}
      />
      <Component patientId={patientId} />
    </Grid>
  )
}

export default AppointmentClientAndPatientDetails
