import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import { AppointmentBlockSize } from '~/constants/landingConstants'

const useAppointmentBlockStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      border: theme.constants.tableBorder,
      height: '100%',
    },
    icon: {
      width: '2em',
      height: '2em',
      color: theme.colors.selectedOption,
      margin: theme.spacing(0, 2, 0, 0),
    },
    iconMid: {
      marginRight: 0,
      margin: theme.spacing(0, 0, 1, 0),
    },
    iconSmall: {
      width: '1.5em',
      height: '1.5em',
      margin: theme.spacing(-0.5, 1, 0, 0),
    },
    count: {
      fontSize: '4.2rem',
      lineHeight: '40px',
    },
    countSmall: {
      fontSize: '2.6rem',
      lineHeight: '22px',
    },
    label: {
      fontSize: '2.6rem',
    },
    labelMid: {
      fontSize: '2rem',
    },
    labelSmall: {
      fontSize: '1.6rem',
    },
    innerContainerSmall: {
      marginLeft: theme.spacing(4),
    },
    textContainerSmall: {
      minWidth: 100,
    },
  }),
  { name: 'AppointmentBlock' },
)

interface AppointmentBlockProps {
  Icon: React.JSXElementConstructor<any>
  centered?: boolean
  count?: number
  label: string
  size: AppointmentBlockSize
}

const AppointmentBlock = ({
  count,
  label,
  Icon,
  centered = false,
  size = AppointmentBlockSize.BIG,
}: AppointmentBlockProps) => {
  const classes = useAppointmentBlockStyles()
  const isSmall = size === AppointmentBlockSize.SMALL
  const isMedium = size === AppointmentBlockSize.MEDIUM

  const iconComponent = (
    <Icon
      className={classNames(classes.icon, {
        [classes.iconSmall]: isSmall,
        [classes.iconMid]: isMedium,
      })}
    />
  )

  const countComponent = (
    <Text
      className={classNames(classes.count, {
        [classes.countSmall]: isSmall,
      })}
    >
      {count}
    </Text>
  )

  const labelComponent = (
    <Dotdotdot clamp={1}>
      <Text
        className={classNames(classes.label, {
          [classes.labelSmall]: isSmall,
          [classes.labelMid]: isMedium,
        })}
      >
        {label}
      </Text>
    </Dotdotdot>
  )

  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      justifyContent="center"
    >
      {centered ? (
        <Grid
          container
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          {iconComponent}
          {countComponent}
          {labelComponent}
        </Grid>
      ) : (
        <Grid
          container
          className={classNames({
            [classes.innerContainerSmall]: isSmall,
          })}
          direction="row"
          justifyContent={isSmall ? 'flex-start' : 'center'}
        >
          {iconComponent}
          <Grid
            className={classNames({
              [classes.textContainerSmall]: isSmall,
            })}
            mt={0.5}
          >
            {countComponent}
            {labelComponent}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default AppointmentBlock
