import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  Grid,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ClassesType,
  Defaults,
  Nil,
  PuiTheme,
  Text,
  TextWithTooltip,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { PrescriptionWorkflowType } from '~/constants/PrescriptionWorkflow'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getOrderDetailsGrouped } from '~/store/reducers/orders'
import { OrderDetails, Prescription } from '~/types'
import { getWorkflowType } from '~/utils/prescription'

import DrugCompoundInfo, { DrugCompoundInfoHandle } from './DrugCompoundInfo'
import VariationsTableRow from './VariationsTableRow'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    tableContainer: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    cell: {
      padding: theme.spacing(1, 2),
      borderBottom: 'none',
      color: theme.colors.secondaryText,
      fontSize: '1.6rem',
    },
    customCompoundGreyRow: {
      backgroundColor: theme.colors.tableOddRowBackground,
    },
    radioCell: {
      width: 40,
      borderRight: theme.constants.tableBorder,
      padding: 0,
    },
    sectionTitle: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    link: {
      color: theme.colors.markerHighlighted,
      marginLeft: 'auto',
    },
  }),
  { name: 'VariationsTable' },
)

const getVariationId = (
  item: Prescription | OrderDetails | Nil,
  isFoodCatalogEnabled: boolean,
) =>
  item?.variation
    ? item?.variation.id
    : item?.[
        isFoodCatalogEnabled ? 'globalVariationMapping' : 'globalVariation'
      ]?.id

export interface VariationsTableProps {
  activeWorkflow?: PrescriptionWorkflowType
  classes?: ClassesType<typeof useStyles>
  disabled?: boolean
  drugCompoundInfoRef: React.Ref<DrugCompoundInfoHandle>
  isCustomCompound?: boolean
  onRefChange?: () => void
  onVariationChange: (
    priceVariation: OrderDetails | undefined,
    isCustom?: boolean,
  ) => void
  outsideSoap: boolean
  prescription: Prescription
  showAllVariationsToggle?: boolean
  showCustomCompound?: boolean
  showNationalInstituteOfHealthLink?: boolean
}

const VariationsTable = ({
  activeWorkflow,
  classes: classesProp,
  disabled,
  drugCompoundInfoRef,
  isCustomCompound,
  onRefChange,
  onVariationChange,
  outsideSoap,
  prescription,
  showAllVariationsToggle = true,
  showCustomCompound: showCustomCompoundProp = true,
  showNationalInstituteOfHealthLink = true,
}: VariationsTableProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Dialogs', 'Tooltips'])

  const variations = useSelector(getOrderDetailsGrouped)
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const { isOrder, isChewyActiveRx, isChewyReactiveRx } =
    getWorkflowType(activeWorkflow)

  const visibleVariations = variations
    // hide global variations for outside soap RX but display on chewy active RX workflow
    .filter(({ variation }) => variation?.id || isChewyActiveRx || !outsideSoap)

  const [allVariationsExpanded, setAllVariationsExpanded] = useState(
    !isCustomCompound || !showAllVariationsToggle,
  )

  const showCustomCompound = isChewyActiveRx
    ? false
    : !isOrder && showCustomCompoundProp
  const showCatalogIcon = !isOrder
  const currentVariationId = getVariationId(prescription, isFoodCatalogEnabled)

  return (
    <Grid container className={classes.root}>
      <Grid container item justifyContent="space-between">
        {showAllVariationsToggle && visibleVariations.length > 0 && (
          <Text
            strong
            className={classes.sectionTitle}
            variant="body2"
            onClick={() => setAllVariationsExpanded(!allVariationsExpanded)}
          >
            {allVariationsExpanded
              ? t(
                  'Dialogs:PRESCRIPTION_DIALOG.VARIATION_TABLE.HIDE_ALL_VARIATIONS',
                )
              : t(
                  'Dialogs:PRESCRIPTION_DIALOG.VARIATION_TABLE.SHOW_ALL_VARIATIONS',
                )}
            {allVariationsExpanded ? (
              <KeyboardArrowUp />
            ) : (
              <KeyboardArrowDown />
            )}
          </Text>
        )}
        {showNationalInstituteOfHealthLink &&
          visibleVariations.length > 0 &&
          allVariationsExpanded && (
            <a
              className={classes.link}
              href={`${Defaults.NIH_URL}${window.encodeURI(
                prescription?.name,
              )}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t(
                'Dialogs:PRESCRIPTION_DIALOG.VARIATION_TABLE.NATIONAL_INSTITUTE_OF_HEALTH_LINK',
              )}
            </a>
          )}
      </Grid>
      <Table className={classes.tableContainer}>
        <TableBody>
          {allVariationsExpanded &&
            visibleVariations.map((priceVariation) => {
              const variationId = getVariationId(
                priceVariation,
                isFoodCatalogEnabled,
              )
              return (
                <VariationsTableRow
                  activeWorkflow={activeWorkflow}
                  checked={
                    !isCustomCompound && currentVariationId === variationId
                  }
                  disabled={disabled}
                  key={variationId}
                  prescription={prescription}
                  priceVariation={priceVariation}
                  showCatalogIcon={showCatalogIcon}
                  onVariationChange={onVariationChange}
                />
              )
            })}
          {showCustomCompound && (
            <TableRow
              className={
                visibleVariations.length % 2 === 0
                  ? classes.customCompoundGreyRow
                  : ''
              }
            >
              <TableCell
                className={classNames(classes.cell, classes.radioCell)}
              >
                <Radio
                  disableRipple
                  checked={Boolean(isCustomCompound)}
                  disabled={isChewyReactiveRx}
                  onChange={() => {
                    setAllVariationsExpanded(false)
                    onVariationChange(undefined, true)
                  }}
                />
              </TableCell>
              <TableCell
                className={classes.cell}
                colSpan={showCatalogIcon ? 2 : 1}
              >
                <TextWithTooltip
                  tooltipText={t('Tooltips:CUSTOM_COMPOUND_RECIPE')}
                  variant="body"
                >
                  {t(
                    'Dialogs:PRESCRIPTION_DIALOG.VARIATION_TABLE.CUSTOM_COMPOUND',
                  )}
                </TextWithTooltip>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {showCustomCompound && isCustomCompound && (
        <Grid container mt={2}>
          <DrugCompoundInfo
            prescription={prescription}
            ref={drugCompoundInfoRef}
            onRefChange={onRefChange}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default VariationsTable
