/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, IconButton, Skeleton, Tooltip, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import {
  Field,
  Nil,
  PermissionArea,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'
import { Calendar as CalendarIcon } from '@pbt/pbt-ui-components/src/icons'

import { useSetOccupyTimeSlotField } from '~/components/dashboard/timetable/scheduler/appointment/appointmentUtils'
import { getCRUDByArea } from '~/store/reducers/auth'
import { SchedulerSettings } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    calendarIconSelected: {
      fill: theme.palette.primary.main,
    },
    calendarIconUnSelected: {
      color: theme.colors.selectedOption,
    },
    calendarTooltipText: {
      color: theme.colors.profileText,
    },
  }),
  { name: 'CalendarSwitch' },
)

interface CalendarSwitchItemProps {
  hasAppointmentTypeChanged: boolean
  isEdit: boolean
  isFirstRole: boolean
  occupyTimeSlotField: Field
  roleField: Field
  schedulerSettings: SchedulerSettings
}

const CalendarSwitchItem = ({
  hasAppointmentTypeChanged,
  isEdit,
  isFirstRole,
  occupyTimeSlotField,
  roleField,
  schedulerSettings,
}: CalendarSwitchItemProps) => {
  const classes = useStyles()
  const theme = useTheme<PuiTheme>()
  const { t } = useTranslation('Common')

  const appointmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )

  const onOccupyTimeSlot = useSetOccupyTimeSlotField({
    hasAppointmentTypeChanged,
    isEdit,
    isFirstRole,
    occupyTimeSlotField,
    roleField,
    schedulerSettings,
  })

  const editDisabled = !appointmentPermissions.update

  return (
    <Tooltip
      disableInteractive
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [32, -20],
            },
          },
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: theme.palette.secondary.main,
            padding: theme.spacing(0, 0.5),
          },
        },
      }}
      placement="top"
      title={
        <Text strong className={classes.calendarTooltipText} variant="body2">
          {t('Common:SHOW_ON_SCHEDULER')}
        </Text>
      }
    >
      <IconButton
        component={editDisabled ? 'div' : 'button'}
        disabled={editDisabled}
        onClick={editDisabled ? undefined : onOccupyTimeSlot}
      >
        <CalendarIcon
          className={classNames(classes.calendarIconUnSelected, {
            [classes.calendarIconSelected]: occupyTimeSlotField.value,
          })}
        />
      </IconButton>
    </Tooltip>
  )
}

export interface CalendarSwitchProps
  extends Omit<CalendarSwitchItemProps, 'schedulerSettings'> {
  isLoading?: boolean
  schedulerSettings: SchedulerSettings | Nil
}

const CalendarSwitch = ({
  hasAppointmentTypeChanged,
  isEdit,
  isFirstRole,
  isLoading = false,
  occupyTimeSlotField,
  roleField,
  schedulerSettings,
}: CalendarSwitchProps) => (
  <Grid container item alignItems="center" flex={0}>
    {isLoading || !schedulerSettings ? (
      <Skeleton height={24} sx={{ m: 1 }} variant="rounded" width={24} />
    ) : (
      <CalendarSwitchItem
        hasAppointmentTypeChanged={hasAppointmentTypeChanged}
        isEdit={isEdit}
        isFirstRole={isFirstRole}
        occupyTimeSlotField={occupyTimeSlotField}
        roleField={roleField}
        schedulerSettings={schedulerSettings}
      />
    )}
  </Grid>
)

export default CalendarSwitch
