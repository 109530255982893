import React from 'react'
import { TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { LanguageUtils, PuiTheme, Text } from '@pbt/pbt-ui-components'

import { DiagnosesState } from '~/types'

import DiagnoseStatusSelect from '../../rail/summary/DiagnoseStatusSelect'
import DifferentialDiagnoseContent from '../../rail/summary/diff-dx/content/DifferentialDiagnoseContent'
import DifferentialDiagnoseLocation from '../../rail/summary/DifferentialDiagnoseLocation'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tableRow: {
      height: 40,
      border: theme.constants.tabBorder,
    },
    tableCell: {
      verticalAlign: 'top',
      padding: theme.spacing(1, 2),
      border: theme.constants.tabBorder,
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
    },
    denseCell: {
      padding: theme.spacing(0, 1),
    },
  }),
  { name: 'DifferentialDiagnosesLockedItem' },
)

export interface DifferentialDiagnosesLockedItemProps {
  diagnose: DiagnosesState
}

const DifferentialDiagnosesLockedItem = ({
  diagnose,
}: DifferentialDiagnosesLockedItemProps) => {
  const classes = useStyles()

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.tableCell}>
        <Text strong>{LanguageUtils.getTranslatedFieldName(diagnose)}</Text>
        <DifferentialDiagnoseContent diagnose={diagnose} />
        {diagnose.locations?.map((locationItem) => (
          <DifferentialDiagnoseLocation
            editDisabled
            currentItem={locationItem}
            diagnoseData={diagnose}
            key={locationItem.id}
            showStatusSelect={false}
          />
        ))}
      </TableCell>
      <TableCell className={classNames(classes.tableCell, classes.denseCell)}>
        {diagnose.statusId && (
          <DiagnoseStatusSelect
            disabled
            currentItem={diagnose}
            diagnoseData={diagnose}
          />
        )}
        {diagnose.locations?.map((locationItem) => (
          <DifferentialDiagnoseLocation
            editDisabled
            currentItem={locationItem}
            diagnoseData={diagnose}
            key={locationItem.id}
            showNotes={false}
          />
        ))}
      </TableCell>
    </TableRow>
  )
}

export default DifferentialDiagnosesLockedItem
