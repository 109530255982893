import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Skeleton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import MenuDropdown from '~/components/common/inputs/MenuDropdown'
import { BillableInvoiceType } from '~/constants/invoice'
import i18n from '~/locales/i18n'
import { updateInvoiceCompletenessFromSpain } from '~/store/actions/finance'
import { useGetInvoiceCompletenessFromSpain } from '~/store/hooks/finance'
import { getFinanceIsLoading } from '~/store/reducers/finance'
import { BatchInvoice, InvoiceLineItem, InvoiceOrEstimate } from '~/types'

import { getIsBatchInvoice } from '../../invoiceUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    invoiceButton: {
      color: theme.colors.secondaryText,
      textDecoration: 'underline',
    },
  }),
  { name: 'InvoiceTypesFromSpain' },
)

export enum InvoiceCompletenessState {
  SIMPLE = 'SIMPLE',
  COMPLETE = 'COMPLETE',
}

export interface InvoiceTypesFromSpainProps {
  invoice: InvoiceOrEstimate | BatchInvoice
}

const InvoiceTypesMap = {
  [InvoiceCompletenessState.SIMPLE]: {
    name: i18n.t('Payments:INVOICE_TYPES.SIMPLE_INVOICE'),
    value: false,
  },
  [InvoiceCompletenessState.COMPLETE]: {
    name: i18n.t('Payments:INVOICE_TYPES.COMPLETE_INVOICE'),
    value: true,
  },
}

export const getInvoiceCompleteness = (
  invoice: InvoiceOrEstimate | BatchInvoice,
): boolean | null => {
  const PRODUCTS_THRESHOLD = 3000
  const SERVICES_THRESHOLD = 400

  // for multiple invoices (batch) - we just set true
  if (
    invoice.subtotal > PRODUCTS_THRESHOLD + SERVICES_THRESHOLD ||
    getIsBatchInvoice(invoice)
  ) {
    return true
  }

  if (!invoice.groups) {
    return null
  }

  const normalizedGroupedItems = R.pipe(
    R.pluck('groupedItems'),
    R.flatten,
  )(invoice.groups)

  const reduceByLogType = R.reduce(
    (acc: { [key: string]: number }, item: InvoiceLineItem) => {
      if (item.logType) {
        acc[item.logType] += item.declined ? 0 : item.subTotal || 0
      } else {
        reduceByLogType(item.items || [])
      }
      return acc
    },
    R.map(R.always(0), BillableInvoiceType),
  )

  const finalValuesByLogTypes = reduceByLogType(normalizedGroupedItems)

  const productsRuleIsCompletedType =
    R.sum([
      R.prop(BillableInvoiceType.INVENTORY, finalValuesByLogTypes),
      R.prop(BillableInvoiceType.PRESCRIPTION, finalValuesByLogTypes),
    ]) >= PRODUCTS_THRESHOLD
  const servicesRuleIsCompletedType =
    R.sum([
      R.prop(BillableInvoiceType.PROCEDURE, finalValuesByLogTypes),
      R.prop(BillableInvoiceType.LAB_TEST, finalValuesByLogTypes),
    ]) >= SERVICES_THRESHOLD

  return productsRuleIsCompletedType || servicesRuleIsCompletedType
}

const InvoiceTypesFromSpain = ({ invoice }: InvoiceTypesFromSpainProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const isLoading = useSelector(getFinanceIsLoading)

  const { t } = useTranslation(['Payments'])

  const isCompletedType = useGetInvoiceCompletenessFromSpain(invoice)
  const isCompleteInvoice = useMemo(
    () => getInvoiceCompleteness(invoice),
    [invoice],
  )

  const currentInvoiceType = isCompletedType
    ? InvoiceCompletenessState.COMPLETE
    : InvoiceCompletenessState.SIMPLE

  const updateLocationOptions = (value: boolean) => {
    dispatch(
      updateInvoiceCompletenessFromSpain(
        // when estimate is not created yet, we don't have any id
        R.isNil(invoice.id) ? invoice.invoiceNo : invoice.id,
        value,
      ),
    )
  }

  useEffect(() => {
    updateLocationOptions(Boolean(isCompleteInvoice))
  }, [isCompleteInvoice])

  if (!invoice || isLoading) {
    return (
      <Grid pr={2}>
        <Skeleton width={142} />
      </Grid>
    )
  }

  return (
    <Grid item>
      {isCompleteInvoice ? (
        <Text pr={2} variant="body2">
          {t('Payments:INVOICE_TYPES.COMPLETE_INVOICE')}
        </Text>
      ) : (
        <MenuDropdown
          linkButton
          classes={{
            button: classes.invoiceButton,
          }}
          disabled={!invoice || isLoading}
          items={R.values(InvoiceTypesMap)}
          title={InvoiceTypesMap[currentInvoiceType]?.name}
          onSelected={updateLocationOptions}
        />
      )}
    </Grid>
  )
}

export default InvoiceTypesFromSpain
