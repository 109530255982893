import { Nil } from '@pbt/pbt-ui-components'

import type { EntitiesToUpdate } from '~/store/sagas/utils/updateEntities'

import { SoapTask } from './tasks'

export enum WSTopicName {
  CHARGE_SHEET_INVOICE_LINE_ITEM = 'CHARGE_SHEET_INVOICE_LINE_ITEM',
  CHARGE_SHEET_SPECIAL_PROCEDURE = 'CHARGE_SHEET_SPECIAL_PROCEDURE',
  CHARGES_POSTED_ON_CHARGE_SHEET = 'CHARGES_POSTED_ON_CHARGE_SHEET',
  CHARGES_POSTED_ON_SOAP = 'CHARGES_POSTED_ON_SOAP',
  CHARGES_FINALIZED_ON_SOAP = 'CHARGES_FINALIZED_ON_SOAP',
  CHARGES_REOPEN_ON_SOAP = 'CHARGES_REOPEN_ON_SOAP',
  CHARGES_SPECIAL_PROCEDURE = 'CHARGES_SPECIAL_PROCEDURE',
  CHARGES_UNPOSTED_ON_SOAP = 'CHARGES_UNPOSTED_ON_SOAP',
  CHARGES_SECTION = 'CHARGES_SECTION',
  CHARGES_WIDGET_INVOICE_LINE_ITEM = 'CHARGES_WIDGET_INVOICE_LINE_ITEM',
  CLIENT_BALANCE = 'CLIENT_BALANCE',
  ERROR = 'ERROR',
  FINALIZE_OUTSTANDING_CHEWY_ACTIVE_RX = 'FINALIZE_OUTSTANDING_CHEWY_ACTIVE_RX',
  INVOICE = 'INVOICE',
  INVOICE_UNPOSTED = 'INVOICE_UNPOSTED',
  NOTIFICATIONS = 'NOTIFICATIONS',
  REFUND_INVOICE = 'REFUND_INVOICE',
  REMINDERS = 'REMINDERS',
  REPORT_AGGREGATION = 'REPORT_AGGREGATION',
  TO_DO = 'TO_DO',
}

export enum ContextType {
  FULL = 'full',
  PARTIAL = 'partial',
}

export type WSTopicContext<T extends ContextType> = {
  actionParams?: any
  retrySubscription: boolean
  topicParams: T extends ContextType.FULL
    ? FullTopicParams
    : OptionalTopicParams
}

export type FullTopicParams = OptionalTopicParams & RequiredTopicParams

export type OptionalTopicParams = {
  clientId?: string | Nil
  invoiceId?: string | Nil
  patientId?: string | Nil
  soapId?: string | Nil
}

export type RequiredTopicParams = {
  businessId: string | Nil
  personId: string | Nil
}

export type ReportPdfCreateMessage = {
  errorMessage: string | Nil
  extension: string
  fileUrl: string
  name: string
}

export type ReportEmailCreateMessage = {
  conversationId: string
  email: string
  errorMessage: string | Nil
  name: string
  patientName: string
}

export type WSTaskCUDBody = {
  tasks: SoapTask[]
}

export type WSTaskNormalizedBody = {
  entities: EntitiesToUpdate
  result: {
    tasks: string[]
  }
}

export type WsPatientUpdate = {
  patientId: string
  procedureLogId: string
  reason: string
  revert: boolean
}
