import React, { forwardRef } from 'react'
import { Grid, MenuItem, MenuItemProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme } from '@pbt/pbt-ui-components'

import AlertLabel from '../labels/AlertLabel'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tag: {
      flex: 0,
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'MenuItemWithTags' },
)

export interface MenuItemWithTagsProps {
  children: React.ReactNode
  classes: MenuItemProps['classes']
  disabled?: boolean
  onClick: (value: React.ReactNode) => void
  tags?: React.ReactNode[]
}

const MenuItemWithTags = forwardRef<HTMLLIElement, MenuItemWithTagsProps>(
  function MenuItemWithTags(
    {
      classes: classesProp,
      disabled,
      children: value,
      tags: tagsProp = [],
      onClick,
    },
    ref,
  ) {
    const classes = useStyles()
    const tags = tagsProp.filter(Boolean)

    return (
      <MenuItem
        classes={classesProp}
        disabled={disabled}
        ref={ref}
        onClick={() => onClick(value)}
      >
        <>
          {value}
          <Grid container>
            {tags.map((tag, index) => (
              <AlertLabel
                classes={{ root: classes.tag }}
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                message={tag}
                variant="normal"
              />
            ))}
          </Grid>
        </>
      </MenuItem>
    )
  },
)

export default MenuItemWithTags
