import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { Nil, PuiTheme, Text, TextWithTooltip } from '@pbt/pbt-ui-components'

import { ShippingAddress } from '~/api/graphql/generated/types'
import { CompactAlert } from '~/components/elements/CompactAlert/CompactAlert'
import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'

const useStyles = makeStyles((theme: PuiTheme) => ({
  data: {
    fontSize: '1.4rem',
    fontWeight: 400,
    color: theme.colors.paragraph,
  },
  clientWithAlert: {
    display: 'inline-flex',
  },
}))

interface ChargesHeaderProps {
  data: string
  isClient: boolean | undefined
  isInvoice: boolean
  isRefundInvoice: boolean
  name: string
  primaryShippingAddress: ShippingAddress | Nil
}

const ChargesHeaderClientInfo = ({
  data,
  isClient,
  isInvoice,
  isRefundInvoice,
  name,
  primaryShippingAddress,
}: ChargesHeaderProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Soap')
  const isIpoM1CheckoutEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M1_CHECKOUT),
  )

  const showShippingAddress =
    isIpoM1CheckoutEnabled && !isInvoice && !isRefundInvoice && isClient
  if (showShippingAddress && primaryShippingAddress) {
    return (
      <TextWithTooltip
        className={classes.data}
        tooltipText={t('Soap:CHEWY_SHIPPING_ADDRESS_TOOLTIP')}
      >
        {data}
      </TextWithTooltip>
    )
  }
  if (showShippingAddress) {
    return (
      <div className={classes.clientWithAlert}>
        <Text className={classes.data}>{data}</Text>
        <CompactAlert
          marginLeft="5px"
          message={t('Soap:PLEASE_UPDATE_CHEWY_PAYMENT_INFO', { name })}
          position="relative"
          top="-2px"
          variant="warning_light"
        />
      </div>
    )
  }
  return <Text className={classes.data}>{data}</Text>
}

export default ChargesHeaderClientInfo
