import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { Nil, PermissionArea, PuiTheme } from '@pbt/pbt-ui-components'

import TaskStatusSelect from '~/components/dashboard/tasks-dashboard/TaskStatusSelect'
import DialogNames from '~/constants/DialogNames'
import { RX_REQUEST_TASK_TYPE } from '~/constants/taskConstants'
import { fetchTask } from '~/store/actions/tasks'
import {
  fetchPrescription,
  getPrescriptionById,
} from '~/store/duck/prescriptions'
import { useGetPrescriptionStateType } from '~/store/hooks/prescription'
import { useGetTaskTypeByTaskId } from '~/store/hooks/tasks'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getTask } from '~/store/reducers/tasks'
import { Task, TaskTimelineItem } from '~/types'
import useDialog from '~/utils/useDialog'

import TimelineCard, { TimelineCardProps } from '../TimelineCard'
import TimelineCardActions from '../TimelineCardActions'
import TimelineCardContent from '../TimelineCardContent'
import MultipleTasks from './MultipleTasks'
import OneTask from './OneTask'

const useTaskIsEditable = (task?: Task | Nil): boolean => {
  const dispatch = useDispatch()
  const prescription = useSelector(getPrescriptionById(task?.prescriptionId))
  const { isDraft } = useGetPrescriptionStateType()(prescription?.state?.id)
  const taskType = useGetTaskTypeByTaskId()(task?.id)

  const isRxRequestTask = taskType === RX_REQUEST_TASK_TYPE

  useEffect(() => {
    if (isRxRequestTask && task?.prescriptionId) {
      dispatch(fetchPrescription(task.prescriptionId))
    }
  }, [])

  if (isRxRequestTask) {
    return isDraft
  }

  return Boolean(task)
}

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    taskStateLabel: {
      fontSize: '1.4rem',
    },
    taskStateButton: {
      margin: theme.spacing(0.5),
    },
  }),
  { name: 'TaskCard' },
)

export interface TaskCardProps extends TimelineCardProps {
  clientId: string
  item: TaskTimelineItem
  patientId: string
}

const TaskCard = ({ item, clientId, patientId, ...rest }: TaskCardProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const hasOneTask = item.entries.length === 1
  const taskId = item.entries[0]

  const firstTask = useSelector(getTask(taskId))
  const permissions = useSelector(getCRUDByArea(PermissionArea.TASK))

  const [openTaskDialog] = useDialog(DialogNames.TASK)
  const taskIsEditable = useTaskIsEditable(firstTask)

  const onEdit = (task: Task) => {
    dispatch(fetchTask(task.id))
    openTaskDialog({ clientId, patientId, taskId: task.id })
  }

  return (
    <TimelineCard
      originBusinessId={hasOneTask ? firstTask?.businessId : undefined}
      title={hasOneTask && firstTask?.name}
      onEdit={
        hasOneTask && permissions.update && firstTask && taskIsEditable
          ? () => onEdit(firstTask)
          : undefined
      }
      {...item}
      {...rest}
    >
      <TimelineCardContent noTypography>
        {hasOneTask ? (
          <OneTask showHistory={false} showState={false} taskId={taskId} />
        ) : (
          <MultipleTasks
            enableBusinessShareIcon
            item={item}
            onEdit={permissions.update ? onEdit : undefined}
          />
        )}
      </TimelineCardContent>
      {hasOneTask && (
        <TimelineCardActions
          customStateControl={
            <TaskStatusSelect
              className={classes.taskStateLabel}
              disabled={!permissions.update}
              fullWidth={false}
              task={firstTask}
            />
          }
          item={firstTask}
        />
      )}
    </TimelineCard>
  )
}

export default TaskCard
