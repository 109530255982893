import React, { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid, Skeleton, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import moment from 'moment'
import * as R from 'ramda'
import { useDebouncedCallback } from 'use-debounce'
import {
  ColorPicker,
  ControlButtonGroup,
  ControlButtonGroupName,
  Defaults,
  LanguageUtils,
  PermissionArea,
  PrimitiveTableRowProps,
  PuiSelect,
  PuiTextField,
  PuiTheme,
  TextInteractive,
  useFields,
} from '@pbt/pbt-ui-components'
import { getColumnStyles } from '@pbt/pbt-ui-components/src/utils/primitiveTableUtils'

import LeaveConfirmationDialog from '~/components/common/dialog/LeaveConfirmationDialog'
import DurationInput from '~/components/common/form-inputs/DurationInput'
import PuiSwitch from '~/components/common/PuiSwitch'
import ErrorTypes from '~/constants/apiErrorTypes'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  clearAppointmentTypesError,
  createAppointmentType,
  updateAppointmentType,
} from '~/store/actions/appointmentTypes'
import {
  getAppointmentType,
  getAppointmentTypesError,
  getAppointmentTypesErrorType,
  getAppointmentTypesIsCreating,
  getAppointmentTypesIsLoading,
  getAppointmentTypesUpdatingId,
} from '~/store/reducers/appointmentTypes'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getAppointmentTypes,
  getFeatureToggle,
} from '~/store/reducers/constants'
import { DEFAULT_APPOINTMENT_DURATION, minutesToHHMM } from '~/utils/time'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

export const NEW_TYPE_PLACEHOLDER_ID = 'NEW_TYPE_PLACEHOLDER_ID'
const MIN_DURATION_VALIDATOR_NAME = 'minDuration'
const MAX_DURATION_VALIDATOR_NAME = 'maxDuration'
const MIN_APPOINTMENT_DURATION_IN_MINUTES = 5
const MAX_APPOINTMENT_DURATION_IN_MINUTES = 60 * 24 // 1440 = 60 min * 24 h

const minDurationValidator = {
  validator: ({ value }: { value: string }) => {
    const durationInMinutes = moment.duration(value).asMinutes()
    return durationInMinutes >= MIN_APPOINTMENT_DURATION_IN_MINUTES
  },
  validatorName: MIN_DURATION_VALIDATOR_NAME,
}
const maxDurationValidator = {
  validator: ({ value }: { value: string }) => {
    const durationInMinutes = moment.duration(value).asMinutes()
    return durationInMinutes < MAX_APPOINTMENT_DURATION_IN_MINUTES
  },
  validatorName: MAX_DURATION_VALIDATOR_NAME,
}

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    container: {
      border: theme.constants.tableBorder,
      borderTop: 0,
      backgroundColor: theme.colors.tableBackground,
      minHeight: 40,
    },
    evenContainer: {
      backgroundColor: theme.colors.tableEvenItem,
    },
    newItemContainer: {
      border: theme.constants.tableBorderSelected,
      backgroundColor: theme.colors.tableLeftColumnBackground,
    },
    inputObfuscated: {
      '& > *:not(.Mui-focused)::after, & > *:not(.Mui-focused)::before': {
        display: 'none',
      },
    },
    spinner: {
      marginRight: theme.spacing(1.5),
    },
    disabledRow: {
      opacity: theme.constants.inactiveOpacity,
    },
  }),
  { name: 'AppointmentConfigurationRow' },
)

interface AppointmentConfigurationRowProps extends PrimitiveTableRowProps {
  index: number
  item?: string
  onCreateItemCancel?: (created?: boolean) => void
}

const AppointmentConfigurationRow = memo(
  ({
    item: appointmentTypeId,
    index,
    data: { mainColumnWidth, otherColumns },
    onCreateItemCancel = R.F,
  }: AppointmentConfigurationRowProps) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { t } = useTranslation(['Admin', 'Common', 'Validations', 'Tooltips'])

    const isMobile = useMediaQuery((theme: PuiTheme) =>
      theme.breakpoints.down('md'),
    )

    const AppointmentEventTypes = useSelector(getAppointmentTypes)
    const appointmentType = useSelector(getAppointmentType(appointmentTypeId))
    const isCreating = useSelector(getAppointmentTypesIsCreating)
    const isLoading = useSelector(getAppointmentTypesIsLoading)
    const updatingTypeId = useSelector(getAppointmentTypesUpdatingId)
    const error = useSelector(getAppointmentTypesError)
    const errorType = useSelector(getAppointmentTypesErrorType)
    const permissions = useSelector(
      getCRUDByArea(PermissionArea.BUSINESS_APPOINTMENT_TYPE),
    )
    const isCvcRolesEnabled = useSelector(
      getFeatureToggle(FeatureToggle.CVC_ROLES),
    )
    // Some event types should not be created by the user
    // TODO - consider moving into BE constants
    const selectableEventTypes = AppointmentEventTypes.filter(
      (type) => !type.name.toLocaleLowerCase().includes('migrated'),
    )
    const isTypeUpdating = updatingTypeId === appointmentTypeId
    const isNewTypeFlow = appointmentTypeId === NEW_TYPE_PLACEHOLDER_ID

    const setRemoveAddRowAfterCreation = useCloseAfterCreation(() => {
      if (!error) {
        onCreateItemCancel(true)
      }
    }, getAppointmentTypesIsCreating)

    const { fields, reset, validate } = useFields(
      [
        {
          name: 'color',
          label: t('Common:COLOR'),
          initialValue: appointmentType?.color || '',
        },
        {
          name: 'eventTypeId',
          type: 'select',
          label: t('Common:TYPE_ONE'),
          initialValue: appointmentType?.eventTypeId || null,
          validators: ['required'],
        },
        {
          name: 'name',
          label: t('Common:DISPLAY_NAME'),
          initialValue: appointmentType?.name || '',
          validators: [
            'required',
            {
              validator: () =>
                !(
                  error &&
                  errorType ===
                    ErrorTypes.BUSINESS_APPOINTMENT_TYPE_WITH_SUCH_NAME_ALREADY_EXISTS_ERROR_TYPE
                ),
              validatorName: 'serverError',
            },
          ],

          messages: {
            serverError: error!,
          },
        },
        {
          name: 'defaultDuration',
          messages: {
            [MIN_DURATION_VALIDATOR_NAME]: t(
              'Validations:MUST_BE_GREATER_THAN_OR_EQUAL_TO',
              {
                value: MIN_APPOINTMENT_DURATION_IN_MINUTES,
              },
            ),
            [MAX_DURATION_VALIDATOR_NAME]: t(
              'Validations:MUST_BE_LESS_THAN_ONE_DAY',
            ),
          },
          initialValue:
            appointmentType?.defaultDuration ||
            minutesToHHMM(DEFAULT_APPOINTMENT_DURATION),
          validators: [
            'required',
            'duration',
            minDurationValidator,
            maxDurationValidator,
          ],
        },
        {
          name: 'onlineSchedulingAllowed',
          label: t('Common:CLIENT_SCHEDULING'),
          initialValue: appointmentType?.onlineSchedulingAllowed || false,
          type: 'toggle',
        },
        {
          name: 'enabled',
          label: t('Common:ACTIVE_ONE'),
          initialValue: appointmentType?.enabled || false,
          type: 'toggle',
        },
      ],
      false,
    )

    const {
      color,
      eventTypeId,
      name,
      defaultDuration,
      onlineSchedulingAllowed,
      enabled,
    } = fields

    useEffect(() => {
      reset()
    }, [appointmentType])

    useEffect(() => {
      if (
        errorType ===
        ErrorTypes.BUSINESS_APPOINTMENT_TYPE_WITH_SUCH_NAME_ALREADY_EXISTS_ERROR_TYPE
      ) {
        dispatch(clearAppointmentTypesError())
      }
    }, [name.value])

    const handleCreateAppointmentType = () => {
      if (validate()) {
        setRemoveAddRowAfterCreation()
        dispatch(
          createAppointmentType({
            color: color.value,
            eventTypeId: eventTypeId.value,
            name: name.value,
            defaultDuration: defaultDuration.value,
            onlineSchedulingAllowed: onlineSchedulingAllowed.value,
            enabled: enabled.value,
          }),
        )
      }
    }

    const handleUpdateAppointmentType = useDebouncedCallback(
      (updatedFields) => {
        if (appointmentType && validate()) {
          dispatch(
            updateAppointmentType(appointmentType.id, {
              color: color.value,
              defaultDuration: defaultDuration.value,
              onlineSchedulingAllowed: onlineSchedulingAllowed.value,
              enabled: enabled.value,
              ...updatedFields,
            }),
          )
        }
      },
      Defaults.DEBOUNCE_ACTION_TIME,
    )

    return (
      <Grid
        container
        item
        alignItems="center"
        className={classNames(classes.container, {
          [classes.evenContainer]: index % 2 === 1,
          [classes.newItemContainer]: isNewTypeFlow,
          [classes.disabledRow]: appointmentType?.enabled === false,
        })}
        px={2}
        wrap="nowrap"
      >
        <Grid
          container
          item
          px={0.5}
          style={getColumnStyles(mainColumnWidth)}
          wrap="nowrap"
        >
          {isLoading ? (
            <Skeleton height={30} width={40} />
          ) : (
            <ColorPicker
              noColorValueEnabled
              checkBoxLabel={t('Admin:PRACTICE.REMOVE_COLOR')}
              disabled={!permissions.update && isCvcRolesEnabled}
              field={{
                ...color,
                setValue: (value) => {
                  color.setValue(value)
                  handleUpdateAppointmentType({ color: value })
                },
              }}
            />
          )}
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          style={getColumnStyles(12 - mainColumnWidth)}
        >
          <Grid item px={0.5} style={getColumnStyles(otherColumns[0].width)}>
            {isNewTypeFlow ? (
              <PuiSelect
                fullWidth
                disabled={(isCreating && isNewTypeFlow) || isTypeUpdating}
                field={eventTypeId}
                items={selectableEventTypes}
                placeholder={`${eventTypeId.label}*`}
              />
            ) : (
              <TextInteractive isLoading={isLoading}>
                {LanguageUtils.getConstantTranslatedName(
                  appointmentType?.eventTypeId,
                  AppointmentEventTypes,
                ) || '-'}
              </TextInteractive>
            )}
          </Grid>
          <Grid item px={0.5} style={getColumnStyles(otherColumns[1].width)}>
            {isNewTypeFlow ? (
              <PuiTextField
                disabled={isCreating && isNewTypeFlow}
                field={name}
                inputProps={{ maxLength: 100 }}
                margin="none"
                placeholder={`${name.label}*`}
              />
            ) : (
              <TextInteractive isLoading={isLoading}>
                {appointmentType?.name}
              </TextInteractive>
            )}
          </Grid>
          <Grid item px={0.5} style={getColumnStyles(otherColumns[2].width)}>
            {isLoading ? (
              <Skeleton height={20} variant="text" width={40} />
            ) : permissions.update || !isCvcRolesEnabled ? (
              <PuiTextField
                InputProps={{
                  // @ts-ignore
                  inputComponent: DurationInput,
                }}
                className={isNewTypeFlow ? undefined : classes.inputObfuscated}
                disabled={(isCreating && isNewTypeFlow) || isTypeUpdating}
                field={{
                  ...defaultDuration,
                  set: (event) => {
                    defaultDuration.set(event)
                    handleUpdateAppointmentType({
                      defaultDuration: event.target.value,
                    })
                  },
                }}
                margin="none"
                placeholder={`${defaultDuration.label}*`}
              />
            ) : (
              <TextInteractive isLoading={isLoading}>
                {appointmentType?.defaultDuration}
              </TextInteractive>
            )}
          </Grid>
          <Grid item px={0.5} style={getColumnStyles(otherColumns[3].width)}>
            {isLoading ? (
              <Skeleton height={30} width={40} />
            ) : (
              <PuiSwitch
                disabled={
                  isTypeUpdating ||
                  enabled.value === false ||
                  (!permissions.update && isCvcRolesEnabled)
                }
                field={{
                  ...onlineSchedulingAllowed,
                  set: (event) => {
                    onlineSchedulingAllowed.set(event)
                    handleUpdateAppointmentType({
                      onlineSchedulingAllowed: event.target.checked,
                    })
                  },
                }}
              />
            )}
          </Grid>
          <Grid item px={0.5} style={getColumnStyles(otherColumns[4].width)}>
            {isLoading ? (
              <Skeleton height={30} width={40} />
            ) : (
              <PuiSwitch
                data-testid={`${enabled.value ? 'enabled' : 'disabled'}-switch`}
                disabled={
                  isTypeUpdating || (!permissions.update && isCvcRolesEnabled)
                }
                field={{
                  ...enabled,
                  set: (event) => {
                    enabled.set(event)
                    handleUpdateAppointmentType({
                      enabled: event.target.checked,
                      onlineSchedulingAllowed: event.target.checked
                        ? onlineSchedulingAllowed.value
                        : false,
                    })
                  },
                }}
              />
            )}
          </Grid>

          <Grid item position={isMobile ? 'static' : 'absolute'} right={0}>
            {isNewTypeFlow ? (
              <ControlButtonGroup
                buttons={[
                  {
                    name: ControlButtonGroupName.CHECK,
                    onClick: handleCreateAppointmentType,
                    isLoading: isCreating && isNewTypeFlow,
                  },
                  {
                    name: ControlButtonGroupName.REMOVE,
                    onClick: () => onCreateItemCancel(),
                    isLoading: isCreating && isNewTypeFlow,
                  },
                ]}
              />
            ) : isTypeUpdating ? (
              <CircularProgress className={classes.spinner} size={16} />
            ) : null}
          </Grid>
        </Grid>
        {isNewTypeFlow && (
          <LeaveConfirmationDialog
            navigateOnProceed
            dialogName={DialogNames.APPOINTMENT_CONFIGURATION_CONFIRMATION}
            hasUnsavedData={isNewTypeFlow}
          />
        )}
      </Grid>
    )
  },
)

export default AppointmentConfigurationRow
