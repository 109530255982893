import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BackButton,
  BasePuiDialogProps,
  ButtonWithLoader,
  CurrencyTextField,
  DateUtils,
  Nil,
  NumberUtils,
  PuiDialog,
  PuiTextArea,
  PuiTheme,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import MembershipPaymentMethodCell from '~/components/common/lists/primitive-table/cells/MembershipPaymentMethodCell'
import {
  getMembershipPayment,
  getMembershipPaymentsIsLoading,
  refundMembershipPayment,
} from '~/store/duck/membershipPayments'
import { getUserName } from '~/store/reducers/users'
import { MembershipPayment } from '~/types'
import {
  getMembershipPaymentId,
  isRefundableMembershipPayment,
} from '~/utils/membershipPaymentUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import CancelMembershipPayment from './cancel/CancelMembershipPayment'
import PaidCell from './table/payments/PaidCell'
import StatusCell from './table/payments/StatusCell'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
    actions: {
      padding: theme.spacing(1, 2),
    },
    button: {
      minWidth: 120,
    },
    content: {
      padding: theme.spacing(3, 2),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
      },
    },
    item: {
      width: 425,
      borderBottom: theme.constants.tabBorder,
    },
    textArea: {
      width: 480,
    },
    currencyTextField: {
      maxWidth: 85,
    },
  }),
  { name: 'MembershipPaymentDetailsDialog' },
)

const Steps = {
  DETAILS: 'DETAILS',
  REFUND: 'REFUND',
}

interface MembershipPaymentDetailsDialogProps extends BasePuiDialogProps {
  clientId: string
  paymentId: string
  stripeSubscriptionId: string | Nil
}

const MembershipPaymentDetailsDialog = ({
  clientId,
  paymentId,
  stripeSubscriptionId,
  onClose,
  ...rest
}: MembershipPaymentDetailsDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Payments', 'Time'])

  const membershipPaymentId = getMembershipPaymentId({
    id: paymentId,
    stripeSubscriptionId,
  })

  const clientName: string = useSelector(getUserName(clientId))
  const isRefunding: boolean = useSelector(getMembershipPaymentsIsLoading)
  const membershipPayment: MembershipPayment = useSelector(
    getMembershipPayment(membershipPaymentId),
  )

  const setCloseOnRefunded = useCloseAfterCreation(
    onClose,
    getMembershipPaymentsIsLoading,
  )

  const [step, setStep] = useState(Steps.DETAILS)

  const availableRefundPayment = membershipPayment?.availableRefundPayment || 0

  const {
    fields: { refundableAmount, notes },
    validate,
  } = useFields([
    { name: 'refundableAmount', initialValue: availableRefundPayment },
    { name: 'notes', initialValue: '' },
  ])

  const showRefundPaymentInfo = isRefundableMembershipPayment(membershipPayment)
  const isAvailableRefundPayment =
    showRefundPaymentInfo && Boolean(membershipPayment?.availableRefundPayment)

  const isDetailsStep = step === Steps.DETAILS

  const additionalMembershipPaymentLabels = [
    {
      name: t('Payments:REFUND_CARD_ON_FILE'),
      value: (
        <MembershipPaymentMethodCell
          paymentMethod={membershipPayment.paymentMethod}
        />
      ),
    },
  ]

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const handleBack = () => {
    setStep(Steps.DETAILS)
  }

  const handleRefund = () => {
    if (isDetailsStep) {
      setStep(Steps.REFUND)
    } else if (validate()) {
      setCloseOnRefunded()
      dispatch(
        refundMembershipPayment({
          paymentId: membershipPayment.id,
          refundAmount: refundableAmount.value,
          notes: notes.value,
        }),
      )
    }
  }

  return (
    <PuiDialog
      actions={
        <>
          {!isDetailsStep && (
            <Grid item mr={1}>
              <BackButton
                label={t('Common:BACK_ACTION')}
                onClick={handleBack}
              />
            </Grid>
          )}
          {isDetailsStep && (
            <ButtonWithLoader
              className={classes.button}
              disabled={isRefunding}
              loading={isRefunding}
              onClick={handleClose}
            >
              {t('Common:CLOSE_ACTION')}
            </ButtonWithLoader>
          )}
          {isAvailableRefundPayment && (
            <ButtonWithLoader
              className={classes.button}
              color={isDetailsStep ? 'inverted' : 'primary'}
              disabled={isRefunding}
              loading={isRefunding}
              onClick={handleRefund}
            >
              {t('Common:PAYMENTS.REFUND_ACTION')}
            </ButtonWithLoader>
          )}
        </>
      }
      aria-labelledby="membership-payment-details-dialog"
      classes={{
        paper: classes.paper,
        actions: classes.actions,
      }}
      title={t('Common:PAYMENTS.PAYMENT')}
      onClose={handleClose}
      {...rest}
    >
      {isDetailsStep && (
        <Grid container className={classes.content} direction="column">
          <Grid container item direction="column">
            {membershipPayment.status && (
              <Grid container item className={classes.item} pb={1}>
                <Grid item xs={6}>
                  <Text strong variant="body2">
                    {t('Common:STATUS')}
                  </Text>
                </Grid>
                <Grid container item xs alignItems="center">
                  <StatusCell status={membershipPayment.status} />
                </Grid>
              </Grid>
            )}
            <Grid container item className={classes.item} py={1}>
              <Grid item xs={6}>
                <Text strong variant="body2">
                  {t('Common:PAYMENTS.PAYMENT')}
                </Text>
              </Grid>
              <Grid item xs>
                <Text variant="body2">
                  <PaidCell
                    amount={membershipPayment.amount}
                    status={membershipPayment.status}
                  />
                </Text>
              </Grid>
            </Grid>
            <Grid container item className={classes.item} py={1}>
              <Grid item xs={6}>
                <Text strong variant="body2">
                  {t('Common:PAYMENTS.PAID_BY')}
                </Text>
              </Grid>
              <Grid item xs>
                <Text variant="body2">{clientName}</Text>
              </Grid>
            </Grid>
            <Grid container item className={classes.item} py={1}>
              <Grid item xs={6}>
                <Text strong variant="body2">
                  {t('Time:LABEL.DATE_TIME')}
                </Text>
              </Grid>
              <Grid item xs>
                <Text variant="body2">
                  {DateUtils.formatDateWithHours(
                    membershipPayment.creationDate,
                  )}
                </Text>
              </Grid>
            </Grid>
            <Grid container item className={classes.item} py={1}>
              <Grid item xs={6}>
                <Text strong variant="body2">
                  {t('Common:METHOD')}
                </Text>
              </Grid>
              <Grid item xs>
                <Text variant="body2">
                  <MembershipPaymentMethodCell
                    paymentMethod={membershipPayment.paymentMethod}
                  />
                </Text>
              </Grid>
            </Grid>
          </Grid>
          {showRefundPaymentInfo && (
            <>
              <Text strong mb={1} mt={3} variant="subheading2">
                {t('Common:PAYMENTS.REFUND_DETAILS')}
              </Text>
              <Grid container item className={classes.item} py={1}>
                <Grid item xs={6}>
                  <Text strong variant="body2">
                    {t('Common:PAYMENTS.AMOUNT_REFUNDED')}
                  </Text>
                </Grid>
                <Grid container item xs alignItems="center">
                  <PaidCell
                    amount={membershipPayment.amount - availableRefundPayment}
                  />
                </Grid>
              </Grid>
              <Grid container item className={classes.item} py={1}>
                <Grid item xs={6}>
                  <Text strong variant="body2">
                    {t('Common:AVAILABLE_REFUND_AMOUNT')}
                  </Text>
                </Grid>
                <Grid container item xs alignItems="center">
                  {NumberUtils.formatMoney(
                    membershipPayment?.availableRefundPayment,
                  )}
                </Grid>
              </Grid>
              {isAvailableRefundPayment && (
                <>
                  <Grid container item className={classes.item} py={1}>
                    <Grid container item alignItems="center" xs={6}>
                      <Text strong variant="body2">
                        {t('Dialogs:CANCEL_MEMBERSHIP_DIALOG.REFUND_CLIENT')}
                      </Text>
                    </Grid>
                    <Grid container item xs alignItems="center">
                      <CurrencyTextField
                        className={classes.currencyTextField}
                        field={refundableAmount}
                        margin="none"
                        max={membershipPayment?.availableRefundPayment}
                        min={0}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" mt={1}>
                    <PuiTextArea
                      className={classes.textArea}
                      field={notes}
                      margin="none"
                      placeholder={t('Common:NOTES')}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      )}
      {step === Steps.REFUND && (
        <CancelMembershipPayment
          isRefund
          additionalLabels={additionalMembershipPaymentLabels}
          paymentAmount={refundableAmount.value}
        />
      )}
    </PuiDialog>
  )
}

export default MembershipPaymentDetailsDialog
