import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TooltipProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Nil, PuiTheme } from '@pbt/pbt-ui-components'
import { ChewyCircleLogo } from '@pbt/pbt-ui-components/src/icons'

import TooltipChip from '~/components/common/TooltipChip'
import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'

import ConnectChewyAccount from './tooltips/ConnectChewyAccount'
import ConnectChewyPet from './tooltips/ConnectChewyPet'
import DisconnectChewyAccount from './tooltips/DisconnectChewyAccount'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    icon: {
      width: '100%',
      height: '100%',
    },
    linkButton: {
      color: theme.colors.link,
    },
  }),
  { name: 'LinkedChewyAccountContainer' },
)

export enum KyriosAccountType {
  CLIENT,
  PATIENT,
}

interface LinkedChewyAccountProps {
  accountType: KyriosAccountType
  className?: string
  clientId: string | Nil
  patientId?: string | Nil
  tooltipPlacement?: TooltipProps['placement']
}

const LinkedChewyAccountContainer = ({
  accountType,
  clientId,
  className,
  patientId,
  tooltipPlacement = 'bottom-start',
}: LinkedChewyAccountProps) => {
  const classes = useStyles()
  const isLinkedChewyAccountEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHEWY_ACCOUNT_LINKER),
  )

  const business = useSelector(getCurrentBusiness)
  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))

  const [open, setOpen] = useState(false)

  const isBusinessChewyEnabled = business?.hasKyriosId || false
  const isClientLinkedToChewy = client?.hasKyriosId || false
  const isPatientLinkedToChewy = patient?.hasKyriosId || false

  // Feature toggle should be enabled and a business should have a kyriosId
  const displayLinkedChewyAccount =
    isLinkedChewyAccountEnabled && isBusinessChewyEnabled
  const clientLinked = displayLinkedChewyAccount && isClientLinkedToChewy
  const patientLinked = clientLinked && isPatientLinkedToChewy

  const { t } = useTranslation('Tooltips')

  let isLinked = false
  let textComponent: React.ReactNode
  switch (accountType) {
    case KyriosAccountType.CLIENT:
      isLinked = clientLinked
      textComponent = isLinked ? (
        <DisconnectChewyAccount
          className={classes.linkButton}
          clientId={clientId!}
          onOpenDialog={() => setOpen(false)}
        />
      ) : (
        <ConnectChewyAccount
          className={classes.linkButton}
          clientId={clientId!}
          tooltipText={t('Tooltips:CONNECT_CHEWY_ACCOUNT')}
          onOpenDialog={() => setOpen(false)}
        />
      )
      break
    case KyriosAccountType.PATIENT:
      // a client must have a kyrios id in order to assign one to their patients
      if (!clientLinked) {
        textComponent = (
          <ConnectChewyAccount
            className={classes.linkButton}
            clientId={clientId!}
            tooltipText={t('Tooltips:CONNECT_CLIENT_CHEWY_ACCOUNT_TO_PATIENT')}
            onOpenDialog={() => setOpen(false)}
          />
        )
        break
      }
      isLinked = patientLinked
      textComponent = isLinked ? null : (
        <ConnectChewyPet
          className={classes.linkButton}
          clientId={clientId!}
          patientId={patientId!}
          onOpenDialog={() => setOpen(false)}
        />
      )
      break
    default:
      isLinked = false
      break
  }

  if (!displayLinkedChewyAccount) {
    return null
  }

  return (
    <TooltipChip
      disableIconBranding
      disableTooltipBranding
      className={className}
      icon={<ChewyCircleLogo className={classes.icon} linked={isLinked} />}
      open={open}
      tooltipPlacement={tooltipPlacement}
      tooltipText={textComponent}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  )
}

export default LinkedChewyAccountContainer
