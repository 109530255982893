import React from 'react'
import { makeStyles } from '@mui/styles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import Link from '~/components/common/link/Link'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    link: {
      textDecorationColor: theme.colors.secondaryText,
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'TableTextNavigateCell' },
)

interface TableTextNavigateCellProps {
  openOnTheSameTab?: boolean
  to: string
  value: string
}

export const TableTextNavigateCell = ({
  value,
  to,
  openOnTheSameTab,
}: TableTextNavigateCellProps) => {
  const classes = useStyles()

  if (!value) {
    return null
  }

  return (
    <Link
      className={classes.link}
      id="table-text-cell-link"
      target={openOnTheSameTab ? '_self' : '_blank}'}
      to={to}
    >
      <Text variant="body2">{value}</Text>
    </Link>
  )
}
