import { Nil } from '@pbt/pbt-ui-components'

import { EmailEntityConfigSection } from '~/types'

export const updateChecked = (
  id: string,
  sections: EmailEntityConfigSection[] | Nil,
  checked: boolean,
  forceUpdate = false,
): EmailEntityConfigSection[] =>
  (sections || []).map((item: EmailEntityConfigSection) => {
    const newChildren = item.children
      ? updateChecked(id, item.children, checked, forceUpdate || item.id === id)
      : item.children

    const hasCheckedChildren = newChildren
      ? newChildren.some((child: EmailEntityConfigSection) => child.checked)
      : false

    return {
      ...item,
      checked:
        forceUpdate || item.id === id
          ? checked
          : newChildren?.length
          ? hasCheckedChildren || item.checked
          : item.checked,
      children: newChildren,
    }
  })
