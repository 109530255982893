import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Icons,
  LanguageUtils,
  PuiTheme,
  WellnessPlans,
} from '@pbt/pbt-ui-components'
import { Wellness as WellnessIcon } from '@pbt/pbt-ui-components/src/icons'
import BoopIcon from '@pbt/pbt-ui-components/src/icons/Boop'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import FeatureToggle from '~/constants/featureToggle'
import { MembershipFilters } from '~/constants/wellnessPlansConstants'
import { fetchClientsMembershipFilters } from '~/store/actions/clients'
import { getCurrentBusinessHasOpenBoop } from '~/store/reducers/auth'
import {
  getClientsIsFetchingFilters,
  getClientsMembershipFilters,
} from '~/store/reducers/clients'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Filter } from '~/types'
import { getUrlSearchParam } from '~/utils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    icon: {
      verticalAlign: 'middle',
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'MembershipFilter' },
)

const getIsCancelled = (filter: Filter) =>
  filter.param === MembershipFilters.WELLNESS_PLAN_CANCELLED
const getIsPaused = (filter: Filter) =>
  filter.param === MembershipFilters.WELLNESS_PLAN_STATUS &&
  filter.value === WellnessPlans.MembershipStatus.PAUSED
const getHasNoMembership = (filter: Filter) =>
  filter.param === MembershipFilters.MEMBERSHIP_STATUS && filter.value === false

export interface MembershipFilterProps
  extends Omit<CheckboxListFilterProps, 'value'> {}

const MembershipFilter = ({ open, ...rest }: MembershipFilterProps) => {
  const classes = useStyles()
  const location = useLocation()
  const dispatch = useDispatch()
  const membershipFilters: Filter[] = useSelector(getClientsMembershipFilters)
  const isFetching = useSelector(getClientsIsFetchingFilters)
  const openBoop = useSelector(getCurrentBusinessHasOpenBoop)
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
  )

  const fieldsQuery = getUrlSearchParam('fieldsQuery', location.search)
  const value = fieldsQuery?.split(';')

  const hasAllCancelledIconVariant = isBoopDisablementEnabled
    ? Icons.IconVariant.SUSPENSED
    : Icons.IconVariant.DISABLED

  const hasPausedIconVariant = isBoopDisablementEnabled
    ? Icons.IconVariant.SUSPENSED
    : Icons.IconVariant.WARNING

  const hasNoMembershipIconVariant = isBoopDisablementEnabled
    ? Icons.IconVariant.INACTIVE
    : Icons.IconVariant.SECONDARY

  const getIconVariantByFilter = R.cond([
    [getIsCancelled, R.always(hasAllCancelledIconVariant)],
    [getIsPaused, R.always(hasPausedIconVariant)],
    [getHasNoMembership, R.always(hasNoMembershipIconVariant)],
    [R.T, R.always(Icons.IconVariant.PRIMARY)],
  ])

  useEffect(() => {
    if (open) {
      dispatch(fetchClientsMembershipFilters())
    }
  }, [open])

  const items = membershipFilters.map((filter) => {
    const variant = getIconVariantByFilter(filter)
    const IconComponent =
      (openBoop && variant === 'primary') || isBoopDisablementEnabled
        ? WellnessIcon
        : BoopIcon

    return {
      ...filter,
      id: `${filter.param}=${filter.value}`,
      component: (
        <>
          <IconComponent className={classes.icon} variant={variant} />
          {LanguageUtils.getTranslatedFieldName(filter)}
        </>
      ),
    }
  })

  return (
    <CheckboxListFilter
      arrayFormat
      includeSearch={false}
      isLoading={isFetching}
      items={items}
      open={open}
      value={value}
      {...rest}
    />
  )
}

export default MembershipFilter
