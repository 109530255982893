import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AlertIconType, PuiAlert, PuiAlertProps } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import { useGetInvoicePosted } from '~/store/hooks/soap'
import { getIsFinalized } from '~/store/reducers/soap'

const INVOICE_POSTED_MESSAGE = i18n.t('Soap:ALERT.INVOICE_POSTED_MESSAGE')
const SOAP_FINALIZED_MESSAGE = i18n.t('Soap:ALERT.SOAP_FINALIZED_MESSAGE')

export interface SoapFinalizedAlertProps extends PuiAlertProps {
  error: {
    message: string
  }
}

const SoapFinalizedAlert = ({
  open,
  onClose,
  error,
  ...rest
}: SoapFinalizedAlertProps) => {
  const { t } = useTranslation('Common')

  const isSoapFinalized = useSelector(getIsFinalized)
  const isInvoicePosted = useGetInvoicePosted()

  const message = isInvoicePosted
    ? INVOICE_POSTED_MESSAGE
    : isSoapFinalized
    ? SOAP_FINALIZED_MESSAGE
    : error?.message || ''

  return (
    <PuiAlert
      iconType={AlertIconType.WARN}
      message={message}
      okButtonText={`${t('Common:GOT_IT')}!`}
      open={open}
      onClose={onClose}
      onOk={onClose}
      {...rest}
    />
  )
}

export default SoapFinalizedAlert
