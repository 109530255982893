import React from 'react'
import { useTranslation } from 'react-i18next'
import { Nil, Text } from '@pbt/pbt-ui-components'

import SoapWidget from '../SoapWidget'

export interface ReasonForVisitWidgetLockedProps {
  appointmentNotes: string | Nil
}

const ReasonForVisitWidgetLocked = ({
  appointmentNotes,
}: ReasonForVisitWidgetLockedProps) => {
  const { t } = useTranslation('Common')

  if (!appointmentNotes) {
    return null
  }

  return (
    <SoapWidget ContentBoxProps={{ p: 2 }} title={t('Common:REASON_FOR_VISIT')}>
      <Text>{appointmentNotes}</Text>
    </SoapWidget>
  )
}

export default ReasonForVisitWidgetLocked
