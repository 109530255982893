import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'

import {
  analyticsReportToPath,
  GeneralSupportedAnalyticsReport,
} from '~/constants/analytics'
import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'

import { MenuItem } from '../LeftMenu'

export const generalAnalyticsDashboardMapping: Record<
  GeneralSupportedAnalyticsReport,
  MenuItem
> = {
  [GeneralSupportedAnalyticsReport.BILLING_AGING]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.ACCOUNTING'),
    url: analyticsReportToPath[GeneralSupportedAnalyticsReport.BILLING_AGING],
  },
  [GeneralSupportedAnalyticsReport.ACQUISITION_AND_LIFETIME_VALUE]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.ACQUISITION_AND_LIFETIME_VALUE'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.ACQUISITION_AND_LIFETIME_VALUE
    ],
  },
  [GeneralSupportedAnalyticsReport.APPOINTMENT_MIX_BENCHMARK]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.APPOINTMENT_MIX_BENCHMARK'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.APPOINTMENT_MIX_BENCHMARK
    ],
  },
  [GeneralSupportedAnalyticsReport.BANK_RECONCILIATION]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.BANK_RECONCILIATION'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.BANK_RECONCILIATION
    ],
  },
  [GeneralSupportedAnalyticsReport.BUNDLES_AND_WELLNESS_PLANS]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.BUNDLES_AND_WELLNESS_PLANS'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.BUNDLES_AND_WELLNESS_PLANS
    ],
  },
  [GeneralSupportedAnalyticsReport.CANCELLATIONS_AND_NO_SHOWS]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.CANCELLATIONS_AND_NO_SHOWS'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.CANCELLATIONS_AND_NO_SHOWS
    ],
  },
  [GeneralSupportedAnalyticsReport.CLIENT_AND_PATIENT_STATUS]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.CLIENT_AND_PATIENT_STATUS'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.CLIENT_AND_PATIENT_STATUS
    ],
  },
  [GeneralSupportedAnalyticsReport.CLIENT_INITIATED_REQUESTS]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.CLIENT_INITIATED_REQUESTS'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.CLIENT_INITIATED_REQUESTS
    ],
  },
  [GeneralSupportedAnalyticsReport.CLIENT_LIST]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.CLIENT_LIST'),
    url: analyticsReportToPath[GeneralSupportedAnalyticsReport.CLIENT_LIST],
  },
  [GeneralSupportedAnalyticsReport.CONTROLLED_SUBSTANCE_LOG]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.CONTROLLED_SUBSTANCE_LOG'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.CONTROLLED_SUBSTANCE_LOG
    ],
  },
  [GeneralSupportedAnalyticsReport.DAILY_RECONCILIATION_NEW]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.DAILY_RECONCILIATION'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.DAILY_RECONCILIATION_NEW
    ],
  },
  [GeneralSupportedAnalyticsReport.DAILY_RECONCILIATION_OLD]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.DAILY_RECONCILIATION_OLD'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.DAILY_RECONCILIATION_OLD
    ],
  },
  [GeneralSupportedAnalyticsReport.DENTAL_AND_HEARTWORM_OVERVIEW]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.DENTAL_AND_HEARTWORM_OVERVIEW'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.DENTAL_AND_HEARTWORM_OVERVIEW
    ],
  },
  [GeneralSupportedAnalyticsReport.DISCOUNT_USAGE]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.DISCOUNT_USAGE'),
    url: analyticsReportToPath[GeneralSupportedAnalyticsReport.DISCOUNT_USAGE],
  },
  [GeneralSupportedAnalyticsReport.FORWARD_BOOKINGS]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.FORWARD_BOOKINGS'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.FORWARD_BOOKINGS
    ],
  },
  [GeneralSupportedAnalyticsReport.GROUP_PERFORMANCE]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.GROUP_PERFORMANCE'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.GROUP_PERFORMANCE
    ],
  },
  [GeneralSupportedAnalyticsReport.GROUP_PRICE_LISTS]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.GROUP_PRICE_LISTS'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.GROUP_PRICE_LISTS
    ],
  },
  [GeneralSupportedAnalyticsReport.INSURED_PATIENTS_VISITS]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.INSURED_PATIENTS_VISITS'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.INSURED_PATIENTS_VISITS
    ],
  },
  [GeneralSupportedAnalyticsReport.INVENTORY_USAGE]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.INVENTORY_USAGE'),
    url: analyticsReportToPath[GeneralSupportedAnalyticsReport.INVENTORY_USAGE],
  },
  [GeneralSupportedAnalyticsReport.INVENTORY_USAGE_AND_AVERAGE_INVOICE]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.INVENTORY_USAGE_AND_AVERAGE_INVOICE'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.INVENTORY_USAGE_AND_AVERAGE_INVOICE
    ],
  },
  [GeneralSupportedAnalyticsReport.INVOICE_ACTIVITY]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.INVOICE_ACTIVITY'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.INVOICE_ACTIVITY
    ],
  },
  [GeneralSupportedAnalyticsReport.INVOICES_AND_PAYMENTS]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.INVOICES AND PAYMENTS'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.INVOICES_AND_PAYMENTS
    ],
  },
  [GeneralSupportedAnalyticsReport.MAGIC_QUADRANT]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.MAGIC_QUADRANT'),
    url: analyticsReportToPath[GeneralSupportedAnalyticsReport.MAGIC_QUADRANT],
  },
  [GeneralSupportedAnalyticsReport.MARKETING_DATA_QUALITY]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.MARKETING_DATA_QUALITY'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.MARKETING_DATA_QUALITY
    ],
  },
  [GeneralSupportedAnalyticsReport.MEDICAL_INSIGHTS]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.MEDICAL_INSIGHTS'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.MEDICAL_INSIGHTS
    ],
  },
  [GeneralSupportedAnalyticsReport.MONTHLY_SALES_SUMMARY]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.MONTHLY_SALES_SUMMARY'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.MONTHLY_SALES_SUMMARY
    ],
  },
  [GeneralSupportedAnalyticsReport.NEW_CLIENT_AND_PATIENT_ACTIVITY]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.NEW_CLIENT_AND_PATIENT_ACTIVITY'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.NEW_CLIENT_AND_PATIENT_ACTIVITY
    ],
  },
  [GeneralSupportedAnalyticsReport.ONLINE_SICK_APPOINTMENTS]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.ONLINE_SICK_APPOINTMENTS'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.ONLINE_SICK_APPOINTMENTS
    ],
  },
  [GeneralSupportedAnalyticsReport.OPEN_SOAPS_AND_INVOICES]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.OPEN_SOAPS_AND_INVOICES'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.OPEN_SOAPS_AND_INVOICES
    ],
  },
  [GeneralSupportedAnalyticsReport.PRACTICE_PERFORMANCE]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.PRACTICE_PERFORMANCE'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.PRACTICE_PERFORMANCE
    ],
  },
  [GeneralSupportedAnalyticsReport.PRACTICE_SALES]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.PRACTICE_SALES'),
    url: analyticsReportToPath[GeneralSupportedAnalyticsReport.PRACTICE_SALES],
  },
  [GeneralSupportedAnalyticsReport.PRODUCER_SALES]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.PRODUCER_SALES'),
    url: analyticsReportToPath[GeneralSupportedAnalyticsReport.PRODUCER_SALES],
  },
  [GeneralSupportedAnalyticsReport.PRODUCTION]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.PRODUCTION'),
    url: analyticsReportToPath[GeneralSupportedAnalyticsReport.PRODUCTION],
  },
  [GeneralSupportedAnalyticsReport.RABIES_VACCINE_HISTORY]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.RABIES_VACCINE_HISTORY'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.RABIES_VACCINE_HISTORY
    ],
  },
  [GeneralSupportedAnalyticsReport.REFERRAL_BUSINESS]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.REFERRAL_BUSINESS'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.REFERRAL_BUSINESS
    ],
  },
  [GeneralSupportedAnalyticsReport.REMINDERS_COMPLIANCE]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.REMINDERS_COMPLIANCE'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.REMINDERS_COMPLIANCE
    ],
  },
  [GeneralSupportedAnalyticsReport.REPEAT_AND_RECHECK_PATIENTS]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.REPEAT_AND_RECHECK_PATIENTS'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.REPEAT_AND_RECHECK_PATIENTS
    ],
  },
  [GeneralSupportedAnalyticsReport.SALES_SUMMARY_EXCLUDING_UNPAID_OTC]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.SALES_SUMMARY_EXCLUDING_UNPAID_OTC'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.SALES_SUMMARY_EXCLUDING_UNPAID_OTC
    ],
  },
  [GeneralSupportedAnalyticsReport.SCHEDULE_MANAGEMENT_NEW]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.SCHEDULE_MANAGEMENT'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.SCHEDULE_MANAGEMENT_NEW
    ],
  },
  [GeneralSupportedAnalyticsReport.SCHEDULE_MANAGEMENT_OLD]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.SCHEDULE_MANAGEMENT_OLD'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.SCHEDULE_MANAGEMENT_OLD
    ],
  },
  [GeneralSupportedAnalyticsReport.TIME_TRACKING]: {
    id: uuid(),
    text: i18n.t('Menu:ANALYTICS.STAFF_TIME_TRACKING'),
    url: analyticsReportToPath[GeneralSupportedAnalyticsReport.TIME_TRACKING],
  },
  [GeneralSupportedAnalyticsReport.WELLNESS_PLANS]: {
    id: uuid(),
    text: i18n.t('Common:WELLNESS_PLANS'),
    url: analyticsReportToPath[GeneralSupportedAnalyticsReport.WELLNESS_PLANS],
  },
  [GeneralSupportedAnalyticsReport.OPEN_AND_UNAPPLIED_BILLING]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.OPEN_AND_UNAPPLIED_BILLING'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.OPEN_AND_UNAPPLIED_BILLING
    ],
  },
  [GeneralSupportedAnalyticsReport.DAILY_BILLING_ACTIVITY]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.DAILY_BILLING_ACTIVITY'),
    url: analyticsReportToPath[
      GeneralSupportedAnalyticsReport.DAILY_BILLING_ACTIVITY
    ],
  },
}

export const useGeneralAnalyticsMenu = () => {
  const chewyReportsSectionEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CLIENT_INITIATED_REQUEST_DASHBOARD),
  )
  const chewyVetCareReportsSectionEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHEWY_VET_CARE_REPORTS),
  )
  const isBankReconReportEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BANK_RECON_REPORT),
  )
  const currentBusiness = useSelector(getCurrentBusiness)

  const { t } = useTranslation(['Common', 'Menu'])

  const AnalyticsMenuFinancials: MenuItem[] = [
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.DAILY_RECONCILIATION_NEW
    ],
    ...(isBankReconReportEnabled
      ? [
          generalAnalyticsDashboardMapping[
            GeneralSupportedAnalyticsReport.BANK_RECONCILIATION
          ],
        ]
      : []),
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.INVOICES_AND_PAYMENTS
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.BILLING_AGING
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.SALES_SUMMARY_EXCLUDING_UNPAID_OTC
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.DISCOUNT_USAGE
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.OPEN_AND_UNAPPLIED_BILLING
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.DAILY_BILLING_ACTIVITY
    ],
  ]

  const AnalyticsMenuPerformance: MenuItem[] = [
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.PRACTICE_SALES
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.PRODUCER_SALES
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.INVOICE_ACTIVITY
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.BUNDLES_AND_WELLNESS_PLANS
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.NEW_CLIENT_AND_PATIENT_ACTIVITY
    ],
    {
      id: uuid(),
      text: t('Menu:ANALYTICS.PERFORMANCE_TRENDS_PA'),
      tooltipText: t('Menu:ANALYTICS.PERFORMANCE_TRENDS_PA_TOOLTIP'),
      disabled: true,
      showDisabled: true,
    },
  ]

  const AnalyticsMenuArchives: MenuItem[] = [
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.DAILY_RECONCILIATION_OLD
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.MONTHLY_SALES_SUMMARY
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.PRODUCTION
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.SCHEDULE_MANAGEMENT_OLD
    ],
  ]

  const AnalyticsMenuChewyReports: MenuItem[] = [
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.CLIENT_INITIATED_REQUESTS
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.INVENTORY_USAGE_AND_AVERAGE_INVOICE
    ],
  ]

  const AnalyticsMenuChewyVetCareReports: MenuItem[] = [
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.APPOINTMENT_MIX_BENCHMARK
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.DENTAL_AND_HEARTWORM_OVERVIEW
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.INSURED_PATIENTS_VISITS
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.REPEAT_AND_RECHECK_PATIENTS
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.ONLINE_SICK_APPOINTMENTS
    ],
  ]

  const AnalyticsMenuSchedule: MenuItem[] = [
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.SCHEDULE_MANAGEMENT_NEW
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.CANCELLATIONS_AND_NO_SHOWS
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.TIME_TRACKING
    ],
  ]

  return [
    {
      id: uuid(),
      text: t('Menu:ANALYTICS.FINANCIALS_NEW'),
      expanded: true,
      children: AnalyticsMenuFinancials,
    },
    {
      id: uuid(),
      text: t('Menu:ANALYTICS.PERFORMANCE_NEW'),
      expanded: true,
      children: AnalyticsMenuPerformance,
    },
    {
      id: uuid(),
      text: t('Menu:ANALYTICS.SCHEDULE'),
      expanded: true,
      children: AnalyticsMenuSchedule,
    },
    ...(chewyReportsSectionEnabled
      ? [
          {
            id: uuid(),
            text: t('Menu:ANALYTICS.CHEWY_REPORTS'),
            expanded: true,
            children: AnalyticsMenuChewyReports,
          },
        ]
      : []),
    ...(chewyVetCareReportsSectionEnabled && currentBusiness?.omniChannel
      ? [
          {
            id: uuid(),
            text: t('Menu:ANALYTICS.CHEWY_VET_CARE_REPORTS'),
            expanded: true,
            children: AnalyticsMenuChewyVetCareReports,
          },
        ]
      : []),
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.ACQUISITION_AND_LIFETIME_VALUE
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.CLIENT_AND_PATIENT_STATUS
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.CLIENT_LIST
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.CONTROLLED_SUBSTANCE_LOG
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.FORWARD_BOOKINGS
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.GROUP_PERFORMANCE
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.GROUP_PRICE_LISTS
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.INVENTORY_USAGE
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.MAGIC_QUADRANT
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.MARKETING_DATA_QUALITY
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.MEDICAL_INSIGHTS
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.OPEN_SOAPS_AND_INVOICES
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.PRACTICE_PERFORMANCE
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.RABIES_VACCINE_HISTORY
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.REMINDERS_COMPLIANCE
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.REFERRAL_BUSINESS
    ],
    generalAnalyticsDashboardMapping[
      GeneralSupportedAnalyticsReport.WELLNESS_PLANS
    ],
    {
      id: uuid(),
      text: t('Menu:ANALYTICS.ARCHIVES'),
      expanded: true,
      children: AnalyticsMenuArchives,
    },
  ]
}
