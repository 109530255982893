import { ApiError, Nil } from '@pbt/pbt-ui-components'

import { GroupIdentifier } from '~/api/graphql/generated/types'
import {
  BalanceListEntry,
  CheckoutInvoicesData,
  Estimate,
  Invoice,
  InvoiceLineItem,
  InvoiceLineItemPayload,
  InvoiceLineItemsArrangement,
  Payment,
  PrintInvoiceParams,
  UnsavedInvoice,
} from '~/types'

import { getInvoiceGroupId } from '../reducers/finance'
import {
  ADD_ESTIMATE_TO_SOAP_OR_EVENT,
  ADD_ESTIMATE_TO_SOAP_OR_EVENT_FAILURE,
  ADD_ESTIMATE_TO_SOAP_OR_EVENT_SUCCESS,
  ADD_ESTIMATE_TO_SOAP_OR_EVENT_VALIDATION_FAILURE,
  ADD_PATIENT_ESTIMATE_SUCCESS,
  ADD_PAYMENT_TO_INVOICES,
  CLEAR_APPOINTMENT_INVOICE_ITEMS,
  CLEAR_CLONED_ESTIMATE_ID,
  CLEAR_CURRENT_INVOICE_DATA,
  CLEAR_FINANCE_ERROR,
  CLEAR_INVOICE_GROUP_EXPANDED,
  CLEAR_INVOICE_PRINT_HTML,
  CLEAR_LINE_ITEM_CANDIDATES,
  CLEAR_LINE_ITEM_DELETED,
  CLONE_ESTIMATE,
  CLONE_ESTIMATE_FAILURE,
  CLONE_ESTIMATE_SUCCESS,
  CONVERT_TO_LINE_ITEMS,
  CONVERT_TO_LINE_ITEMS_FAILURE,
  CONVERT_TO_LINE_ITEMS_SUCCESS,
  DELETE_ESTIMATE,
  DELETE_ESTIMATE_FAILURE,
  DELETE_ESTIMATE_SUCCESS,
  DETACH_ESTIMATE_FROM_EVENT,
  DETACH_ESTIMATE_FROM_EVENT_FAILURE,
  DETACH_ESTIMATE_FROM_EVENT_SUCCESS,
  EDIT_INVOICE_LINE_ITEM,
  EDIT_INVOICE_LINE_ITEM_FAILURE,
  EDIT_INVOICE_LINE_ITEM_SUCCESS,
  FETCH_APPOINTMENT_INVOICE_ITEMS,
  FETCH_APPOINTMENT_INVOICE_ITEMS_FAILURE,
  FETCH_APPOINTMENT_INVOICE_ITEMS_SUCCESS,
  FETCH_BALANCE_HISTORY,
  FETCH_BALANCE_HISTORY_FAILURE,
  FETCH_BALANCE_HISTORY_SUCCESS,
  FETCH_BATCH_INVOICE,
  FETCH_BATCH_INVOICE_FAILURE,
  FETCH_BATCH_INVOICE_SUCCESS,
  FETCH_CHECKOUT_INVOICES,
  FETCH_CHECKOUT_INVOICES_FAILURE,
  FETCH_CHECKOUT_INVOICES_SUCCESS,
  FETCH_ESTIMATE_TEMPLATE,
  FETCH_ESTIMATE_TEMPLATE_FAILURE,
  FETCH_ESTIMATE_TEMPLATE_SUCCESS,
  FETCH_ESTIMATES,
  FETCH_ESTIMATES_FAILURE,
  FETCH_ESTIMATES_SUCCESS,
  FETCH_INVOICE,
  FETCH_INVOICE_BY_EVENT_ID,
  FETCH_INVOICE_BY_EVENT_ID_FAILURE,
  FETCH_INVOICE_BY_EVENT_ID_SUCCESS,
  FETCH_INVOICE_FAILURE,
  FETCH_INVOICE_SUCCESS,
  FETCH_INVOICE_TEMPLATE,
  FETCH_INVOICE_TEMPLATE_FAILURE,
  FETCH_INVOICE_TEMPLATE_SUCCESS,
  FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY,
  FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY_FAILURE,
  FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY_SUCCESS,
  FETCH_MORE_PATIENT_ESTIMATES,
  FETCH_MORE_PATIENT_ESTIMATES_FAILURE,
  FETCH_MORE_PATIENT_ESTIMATES_SUCCESS,
  FETCH_PATIENT_ESTIMATES,
  FETCH_PATIENT_ESTIMATES_FAILURE,
  FETCH_PATIENT_ESTIMATES_SUCCESS,
  FETCH_UNPAID_INVOICES,
  FETCH_UNPAID_INVOICES_FAILURE,
  FETCH_UNPAID_INVOICES_SUCCESS,
  PRINT_INVOICE,
  PRINT_INVOICE_FAILURE,
  PRINT_INVOICE_SUCCESS,
  PRINT_INVOICE_V2,
  REARRANGE_INVOICE_LINE_ITEMS,
  REARRANGE_INVOICE_LINE_ITEMS_FAILURE,
  REARRANGE_INVOICE_LINE_ITEMS_SUCCESS,
  REDEEM_LOYALTY_POINTS,
  REDEEM_LOYALTY_POINTS_FAILURE,
  REDEEM_LOYALTY_POINTS_SUCCESS,
  REFRESH_BALANCE_HISTORY,
  REFRESH_BALANCE_HISTORY_FAILURE,
  REFRESH_BALANCE_HISTORY_SUCCESS,
  REMOVE_LINE_ITEMS,
  SAVE_ESTIMATE_TO_EVENT,
  SAVE_ESTIMATE_TO_EVENT_FAILURE,
  SAVE_ESTIMATE_TO_EVENT_SUCCESS,
  SAVE_ESTIMATE_TO_SOAP,
  SAVE_ESTIMATE_TO_SOAP_FAILURE,
  SAVE_ESTIMATE_TO_SOAP_SUCCESS,
  SAVE_INVOICE,
  SAVE_INVOICE_FAILURE,
  SAVE_INVOICE_SUCCESS,
  SET_INVOICE_AUTO_SAVE,
  SIGN_ESTIMATE,
  SIGN_ESTIMATE_FAILURE,
  SIGN_ESTIMATE_SUCCESS,
  TOGGLE_INVOICE_GROUP_EXPANDED,
  UPDATE_BATCH_INVOICE,
  UPDATE_INVOICE_COMPLETENESS_FROM_SPAIN,
  UPDATE_INVOICE_TEMPLATE,
  UPDATE_INVOICES,
} from './types/finance'

export const fetchBalanceHistory = (
  clientId: string,
  from?: number,
  to?: number,
) => ({
  type: FETCH_BALANCE_HISTORY,
  clientId,
  from,
  to,
})
export const fetchBalanceHistorySuccess = (
  list: BalanceListEntry[],
  totalCount: number,
) => ({
  type: FETCH_BALANCE_HISTORY_SUCCESS,
  list,
  totalCount,
})
export const fetchBalanceHistoryFailure = (error: ApiError) => ({
  type: FETCH_BALANCE_HISTORY_FAILURE,
  error,
})

export const refreshBalanceHistory = (clientId: string, to?: number) => ({
  type: REFRESH_BALANCE_HISTORY,
  clientId,
  to,
})
export const refreshBalanceHistorySuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: REFRESH_BALANCE_HISTORY_SUCCESS,
  list,
  totalCount,
})
export const refreshBalanceHistoryFailure = (error: ApiError) => ({
  type: REFRESH_BALANCE_HISTORY_FAILURE,
  error,
})

export const fetchMoreItemsForBalanceHistory = (
  clientId: string,
  from: number,
  to: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY,
  clientId,
  from,
  to,
})
export const fetchMoreItemsForBalanceHistorySuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY_SUCCESS,
  list,
  totalCount,
  from,
})

export const fetchMoreItemsForBalanceHistoryFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY_FAILURE,
  error,
})

export const fetchInvoice = (invoiceId: string) => ({
  type: FETCH_INVOICE,
  invoiceId,
})
export const fetchInvoiceFailure = (error: ApiError) => ({
  type: FETCH_INVOICE_FAILURE,
  error,
})
export const fetchInvoiceSuccess = (invoiceId: string) => ({
  type: FETCH_INVOICE_SUCCESS,
  invoiceId,
})

export const fetchInvoiceByEventId = (
  clientId: string | Nil,
  eventId: string,
) => ({
  type: FETCH_INVOICE_BY_EVENT_ID,
  clientId,
  eventId,
})
export const fetchInvoiceByEventIdSuccess = (invoiceId: string) => ({
  type: FETCH_INVOICE_BY_EVENT_ID_SUCCESS,
  invoiceId,
})
export const fetchInvoiceByEventIdFailure = (error: ApiError) => ({
  type: FETCH_INVOICE_BY_EVENT_ID_FAILURE,
  error,
})

export const fetchInvoiceTemplate = (
  clientId: string | Nil,
  patientId: string,
  eventId?: string | null,
) => ({
  type: FETCH_INVOICE_TEMPLATE,
  clientId,
  patientId,
  eventId,
})
export const fetchInvoiceTemplateFailure = (error: ApiError) => ({
  type: FETCH_INVOICE_TEMPLATE_FAILURE,
  error,
})
export const fetchInvoiceTemplateSuccess = (template: Invoice) => ({
  type: FETCH_INVOICE_TEMPLATE_SUCCESS,
  template,
})

export const updateInvoices = (invoices: Record<string, Invoice>) => ({
  type: UPDATE_INVOICES,
  invoices,
})
export const updateBatchInvoice = (invoice: Invoice) => ({
  type: UPDATE_BATCH_INVOICE,
  invoice,
})

export const updateInvoiceTemplate = (template: Invoice) => ({
  type: UPDATE_INVOICE_TEMPLATE,
  template,
})

export const fetchEstimateTemplate = (
  clientId: string | Nil,
  patientId: string,
) => ({
  type: FETCH_ESTIMATE_TEMPLATE,
  clientId,
  patientId,
})
export const fetchEstimateTemplateFailure = (error: ApiError) => ({
  type: FETCH_ESTIMATE_TEMPLATE_FAILURE,
  error,
})
export const fetchEstimateTemplateSuccess = (template: Estimate) => ({
  type: FETCH_ESTIMATE_TEMPLATE_SUCCESS,
  template,
})

export const saveInvoice = (invoice: UnsavedInvoice, isNew?: boolean) => ({
  type: SAVE_INVOICE,
  invoice,
  isNew,
})
export const saveInvoiceFailure = (error: ApiError) => ({
  type: SAVE_INVOICE_FAILURE,
  error,
})
export const saveInvoiceSuccess = (invoice: Invoice) => ({
  type: SAVE_INVOICE_SUCCESS,
  invoice,
})

export const printInvoice = (
  invoiceIds: string[],
  params?: PrintInvoiceParams,
) => ({
  type: PRINT_INVOICE,
  invoiceIds,
  params,
})

export const printInvoiceV2 = (
  invoiceId: string,
  expandedGroups: GroupIdentifier[] | Nil,
  includeServiceFee?: boolean,
) => ({
  type: PRINT_INVOICE_V2,
  invoiceId,
  expandedGroups,
  includeServiceFee,
})

export const printInvoiceFailure = (error: ApiError) => ({
  type: PRINT_INVOICE_FAILURE,
  error,
})
export const printInvoiceSuccess = (printHtml: string) => ({
  type: PRINT_INVOICE_SUCCESS,
  printHtml,
})

export const convertToLineItems = ({
  invoiceId,
  clientId,
  patientId,
  invoiceType,
  otcItems,
  bundleDiscount,
}: {
  bundleDiscount?: number
  clientId: string | Nil
  invoiceId: string
  invoiceType: string
  otcItems: InvoiceLineItem[]
  patientId: string | Nil
}) => ({
  type: CONVERT_TO_LINE_ITEMS,
  invoiceId,
  clientId,
  patientId,
  invoiceType,
  otcItems,
  bundleDiscount,
})
export const convertToLineItemsFailure = (error: ApiError) => ({
  type: CONVERT_TO_LINE_ITEMS_FAILURE,
  error,
})
export const convertToLineItemsSuccess = (lineItems: InvoiceLineItem[]) => ({
  type: CONVERT_TO_LINE_ITEMS_SUCCESS,
  lineItems,
})

export const clearInvoicePrintHtml = () => ({ type: CLEAR_INVOICE_PRINT_HTML })

export const clearLineItemCandidates = () => ({
  type: CLEAR_LINE_ITEM_CANDIDATES,
})
export const clearLineItemDeleted = () => ({ type: CLEAR_LINE_ITEM_DELETED })
export const clearCurrentInvoiceData = () => ({
  type: CLEAR_CURRENT_INVOICE_DATA,
})

export const removeLineItems = (lineItemIds: string[], invoiceId: string) => ({
  type: REMOVE_LINE_ITEMS,
  lineItemIds,
  invoiceId,
})

export const deleteEstimate = (estimateId: string) => ({
  type: DELETE_ESTIMATE,
  estimateId,
})
export const deleteEstimateFailure = (error: ApiError) => ({
  type: DELETE_ESTIMATE_FAILURE,
  error,
})
export const deleteEstimateSuccess = (estimateId: string) => ({
  type: DELETE_ESTIMATE_SUCCESS,
  estimateId,
})

export const fetchEstimates = (patientId: string) => ({
  type: FETCH_ESTIMATES,
  patientId,
})
export const fetchEstimatesFailure = (error: ApiError) => ({
  type: FETCH_ESTIMATES_FAILURE,
  error,
})
export const fetchEstimatesSuccess = (list: string[]) => ({
  type: FETCH_ESTIMATES_SUCCESS,
  list,
})

export const fetchPatientEstimates = (
  patientId: string,
  soapId: string,
  offset: number,
  limit: number,
) => ({
  type: FETCH_PATIENT_ESTIMATES,
  patientId,
  soapId,
  offset,
  limit,
})
export const fetchPatientEstimatesSuccess = (
  patientEstimatesIdsList: string[],
  totalCount: number,
) => ({
  type: FETCH_PATIENT_ESTIMATES_SUCCESS,
  patientEstimatesIdsList,
  totalCount,
})
export const fetchPatientEstimatesFailure = (error: ApiError) => ({
  type: FETCH_PATIENT_ESTIMATES_FAILURE,
  error,
})

export const fetchMorePatientEstimates = (
  patientId: string,
  soapId: string,
  offset: number,
  limit: number,
) => ({
  type: FETCH_MORE_PATIENT_ESTIMATES,
  patientId,
  soapId,
  offset,
  limit,
})
export const fetchMorePatientEstimatesSuccess = (
  patientEstimatesIdsList: string[],
  totalCount: number,
) => ({
  type: FETCH_MORE_PATIENT_ESTIMATES_SUCCESS,
  patientEstimatesIdsList,
  totalCount,
})
export const fetchMorePatientEstimatesFailure = (error: ApiError) => ({
  type: FETCH_MORE_PATIENT_ESTIMATES_FAILURE,
  error,
})

export const cloneEstimate = (
  estimateId: string,
  checkInactiveLineItems: boolean,
) => ({
  type: CLONE_ESTIMATE,
  estimateId,
  checkInactiveLineItems,
})
export const cloneEstimateSuccess = (newEstimateId: string) => ({
  type: CLONE_ESTIMATE_SUCCESS,
  newEstimateId,
})
export const cloneEstimateFailure = (error: ApiError) => ({
  type: CLONE_ESTIMATE_FAILURE,
  error,
})

export const addPatientEstimateSuccess = (newEstimateId: string) => ({
  type: ADD_PATIENT_ESTIMATE_SUCCESS,
  newEstimateId,
})

export type CloningOptions = {
  allowClone: boolean
  checkInactiveLineItems: boolean
  refetchTimelineAfterCloning?: boolean
}

export const addEstimateToSOAPOrEvent = ({
  allowNewSoap,
  estimateId,
  soapId,
  eventId,
  copyItems,
  cloningOptions,
}: {
  allowNewSoap?: boolean
  cloningOptions?: CloningOptions
  copyItems: boolean
  estimateId: string
  eventId: string | Nil
  soapId: string | Nil
}) => ({
  type: ADD_ESTIMATE_TO_SOAP_OR_EVENT,
  allowNewSoap,
  estimateId,
  soapId,
  eventId,
  copyItems,
  cloningOptions,
})
export const addEstimateToSOAPOrEventFailure = (error: ApiError) => ({
  type: ADD_ESTIMATE_TO_SOAP_OR_EVENT_FAILURE,
  error,
})
export const addEstimateToSOAPOrEventValidationFailure = (error: ApiError) => ({
  type: ADD_ESTIMATE_TO_SOAP_OR_EVENT_VALIDATION_FAILURE,
  error,
})
export const addEstimateToSOAPOrEventSuccess = () => ({
  type: ADD_ESTIMATE_TO_SOAP_OR_EVENT_SUCCESS,
})

export const saveEstimateToSOAP = (estimate: Estimate, soapId: string) => ({
  type: SAVE_ESTIMATE_TO_SOAP,
  estimate,
  soapId,
})
export const saveEstimateToSOAPFailure = (error: ApiError) => ({
  type: SAVE_ESTIMATE_TO_SOAP_FAILURE,
  error,
})
export const saveEstimateToSOAPSuccess = (estimate: Estimate) => ({
  type: SAVE_ESTIMATE_TO_SOAP_SUCCESS,
  estimate,
})

export const saveEstimateToEvent = ({
  estimate,
  eventId,
  allowNewSoap,
}: {
  allowNewSoap?: boolean
  estimate: Estimate
  eventId: string
}) => ({
  type: SAVE_ESTIMATE_TO_EVENT,
  allowNewSoap,
  estimate,
  eventId,
})
export const saveEstimateToEventFailure = (error: ApiError) => ({
  type: SAVE_ESTIMATE_TO_EVENT_FAILURE,
  error,
})
export const saveEstimateToEventSuccess = (estimate: Estimate) => ({
  type: SAVE_ESTIMATE_TO_EVENT_SUCCESS,
  estimate,
})

export const detachEstimateFromEvent = (
  estimateId: string,
  eventId: string,
) => ({
  type: DETACH_ESTIMATE_FROM_EVENT,
  estimateId,
  eventId,
})
export const detachEstimateFromEventFailure = (error: ApiError) => ({
  type: DETACH_ESTIMATE_FROM_EVENT_FAILURE,
  error,
})
export const detachEstimateFromEventSuccess = () => ({
  type: DETACH_ESTIMATE_FROM_EVENT_SUCCESS,
})

export const setInvoiceAutoSave = (
  invoiceNo?: string,
  items?: InvoiceLineItem[],
) => ({
  type: SET_INVOICE_AUTO_SAVE,
  invoiceNo,
  items,
})

export const clearFinanceError = () => ({ type: CLEAR_FINANCE_ERROR })

export const signEstimate = (
  estimateId: string,
  signature: string,
  signerId: string,
  signerName: string,
  options: { fromTimeline?: boolean },
) => ({
  type: SIGN_ESTIMATE,
  estimateId,
  signature,
  signerId,
  signerName,
  options,
})
export const signEstimateSuccess = () => ({ type: SIGN_ESTIMATE_SUCCESS })
export const signEstimateFailure = (error: ApiError) => ({
  type: SIGN_ESTIMATE_FAILURE,
  error,
})

export const fetchCheckoutInvoices = (eventId: string) => ({
  type: FETCH_CHECKOUT_INVOICES,
  eventId,
})
export const fetchCheckoutInvoicesFailure = (error: ApiError) => ({
  type: FETCH_CHECKOUT_INVOICES_FAILURE,
  error,
})
export const fetchCheckoutInvoicesSuccess = (data: CheckoutInvoicesData) => ({
  type: FETCH_CHECKOUT_INVOICES_SUCCESS,
  data,
})

export const fetchUnpaidInvoices = (clientId: string) => ({
  type: FETCH_UNPAID_INVOICES,
  clientId,
})
export const fetchUnpaidInvoicesFailure = (error: ApiError) => ({
  type: FETCH_UNPAID_INVOICES_FAILURE,
  error,
})
export const fetchUnpaidInvoicesSuccess = (invoiceIds: string[]) => ({
  type: FETCH_UNPAID_INVOICES_SUCCESS,
  invoiceIds,
})

export const fetchBatchInvoice = (invoiceIds: string[]) => ({
  type: FETCH_BATCH_INVOICE,
  invoiceIds,
})
export const fetchBatchInvoiceFailure = (error: ApiError) => ({
  type: FETCH_BATCH_INVOICE_FAILURE,
  error,
})
export const fetchBatchInvoiceSuccess = (batchInvoice: Invoice) => ({
  type: FETCH_BATCH_INVOICE_SUCCESS,
  batchInvoice,
})

export const redeemLoyaltyPoints = (amount: number, invoiceId: string) => ({
  type: REDEEM_LOYALTY_POINTS,
  amount,
  invoiceId,
})
export const redeemLoyaltyPointsFailure = (error: ApiError) => ({
  type: REDEEM_LOYALTY_POINTS_FAILURE,
  error,
})
export const redeemLoyaltyPointsSuccess = () => ({
  type: REDEEM_LOYALTY_POINTS_SUCCESS,
})

export const fetchAppointmentInvoiceItems = (eventId: string) => ({
  type: FETCH_APPOINTMENT_INVOICE_ITEMS,
  eventId,
})
export const fetchAppointmentInvoiceItemsSuccess = (
  items: InvoiceLineItem[],
) => ({
  type: FETCH_APPOINTMENT_INVOICE_ITEMS_SUCCESS,
  items,
})
export const fetchAppointmentInvoiceItemsFailure = (error: ApiError) => ({
  type: FETCH_APPOINTMENT_INVOICE_ITEMS_FAILURE,
  error,
})
export const clearAppointmentInvoiceItems = () => ({
  type: CLEAR_APPOINTMENT_INVOICE_ITEMS,
})

export const addPaymentToInvoices = (payment: Payment) => ({
  type: ADD_PAYMENT_TO_INVOICES,
  payment,
})

export const updateInvoiceCompletenessFromSpain = (
  invoiceId: string,
  isCompleted: boolean,
) => ({
  type: UPDATE_INVOICE_COMPLETENESS_FROM_SPAIN,
  invoiceId,
  isCompleted,
})

export const editInvoiceLineItem = (
  id: string,
  input: InvoiceLineItemPayload,
  invoiceId: string | Nil,
) => ({
  type: EDIT_INVOICE_LINE_ITEM,
  id,
  input,
  invoiceId,
})
export const editInvoiceLineItemSuccess = (
  id: string,
  invoiceId: string | Nil,
  newData: Partial<InvoiceLineItem>,
) => ({
  type: EDIT_INVOICE_LINE_ITEM_SUCCESS,
  id,
  invoiceId,
  newData,
})
export const editInvoiceLineItemFailure = (error: ApiError) => ({
  type: EDIT_INVOICE_LINE_ITEM_FAILURE,
  error,
})

export const rearrangeInvoiceLineItems = (
  invoiceId: string,
  newArrangement: InvoiceLineItemsArrangement[],
) => ({
  type: REARRANGE_INVOICE_LINE_ITEMS,
  invoiceId,
  newArrangement,
})
export const rearrangeInvoiceLineItemsSuccess = (
  invoiceId: string,
  newArrangement: InvoiceLineItemsArrangement[],
) => ({
  type: REARRANGE_INVOICE_LINE_ITEMS_SUCCESS,
  invoiceId,
  newArrangement,
})
export const rearrangeInvoiceLineItemsFailure = (error: ApiError) => ({
  type: REARRANGE_INVOICE_LINE_ITEMS_FAILURE,
  error,
})

export const toggleInvoiceGroupExpanded = (
  invoiceGroupId: string,
  soapId?: string,
) => ({
  type: TOGGLE_INVOICE_GROUP_EXPANDED,
  invoiceGroupId: getInvoiceGroupId(invoiceGroupId, soapId),
})

export const clearInvoiceGroupExpanded = () => ({
  type: CLEAR_INVOICE_GROUP_EXPANDED,
})

export const clearClonedEstimateId = () => ({ type: CLEAR_CLONED_ESTIMATE_ID })
