import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Calendar,
  moment,
  Nil,
  PermissionArea,
  PuiTextField,
  PuiTheme,
  useFields,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { DataHandle, NoteHistoryItem } from '~/types'

import NotesTemplateInput from '../../template-inputs/NotesTemplateInput'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    notesTemplateInput: {
      marginTop: theme.spacing(1),
    },
  }),
  { name: 'NoteHistory' },
)

export interface NoteHistoryProps {
  className?: string
  clientId: string | Nil
  note: NoteHistoryItem | Nil
  onDelete?: () => void
  patientId: string | Nil
}

export interface NoteHistoryHandle extends DataHandle<NoteHistoryItem> {}

const NoteHistory = forwardRef<NoteHistoryHandle, NoteHistoryProps>(
  function NoteHistory(
    { note, clientId, patientId, className, onDelete },
    ref,
  ) {
    const classes = useStyles()
    const { t } = useTranslation(['Common', 'MedicalHistory'])

    const isCvcRolesEnabled = useSelector(
      getFeatureToggle(FeatureToggle.CVC_ROLES),
    )
    const formPermissions = useSelector(getCRUDByArea(PermissionArea.FORM))

    const {
      fields: { date, practiceFrom, notes },
      validate,
    } = useFields(
      [
        {
          name: 'date',
          label: t('Common:DATE_TIME'),
          validators: ['required'],
          initialValue: note?.date,
        },
        {
          name: 'practiceFrom',
          label: t('MedicalHistory:NOTE_HISTORY.PRACTICE_NOTE_FROM'),
          initialValue: note?.practiceFrom,
        },
        {
          name: 'notes',
          label: t('Common:NOTES'),
          initialValue: note?.notes || '',
        },
      ],
      false,
    )

    useImperativeHandle(ref, () => ({
      validate,
      get: () => {
        const momentDate = moment(date.value)
        const todayDate = moment()

        return {
          ...note,
          date: momentDate.isSame(todayDate, 'day')
            ? momentDate
                .set({
                  h: todayDate.get('h'),
                  m: todayDate.get('m'),
                  s: todayDate.get('s'),
                })
                .toISOString()
            : date.value,
          practiceFrom: practiceFrom.value,
          notes: notes.value,
        }
      },
    }))

    return (
      <Grid container item className={className} direction="column">
        <Stack alignItems="center" direction="row" spacing={2}>
          <Grid item>
            <Calendar field={date} label={`${date.label}*`} />
          </Grid>
          <Grid item xs>
            <PuiTextField
              fullWidth
              field={practiceFrom}
              label={practiceFrom.label}
            />
          </Grid>
        </Stack>
        <NotesTemplateInput
          className={classes.notesTemplateInput}
          clientId={clientId}
          field={notes}
          hidePlusButtonBlock={isCvcRolesEnabled && !formPermissions.read}
          patientId={patientId}
          onRemoveRequested={onDelete}
        />
      </Grid>
    )
  },
)

export default NoteHistory
