import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ApiError,
  BackButton,
  BillingAddress,
  ButtonWithLoader,
  ErrorTooltip,
  Nil,
  PuiTheme,
  StripePaymentForm,
} from '@pbt/pbt-ui-components'
import { StripePaymentFormHandle } from '@pbt/pbt-ui-components/src/components/payments/stripe/StripePaymentForm'

import { getUser } from '~/store/reducers/users'
import { getErrorMessage } from '~/utils/errors'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    footer: {
      borderTop: theme.constants.tabBorder,
    },
    button: {
      height: 40,
      width: 220,
    },
    inputContainer: {
      marginTop: 0,
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(5),
      },
    },
  }),
  { name: 'StripePaymentCardDetails' },
)

interface StripePaymentCardDetailsProps {
  billingAddress: BillingAddress
  buttonLabel: string
  clientId: string | Nil
  clientSecret: string | Nil
  isLoading?: boolean
  onBack: () => void
  onProceed: () => void
  publicKey: string | Nil
  setIsLoading: (loading: boolean) => void
}

const StripePaymentCardDetails = ({
  clientId,
  billingAddress,
  buttonLabel,
  onBack,
  onProceed,
  isLoading,
  setIsLoading,
  clientSecret,
  publicKey,
}: StripePaymentCardDetailsProps) => {
  const classes = useStyles()
  const client = useSelector(getUser(clientId))
  const { t } = useTranslation('Common')

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [formComplete, setFormComplete] = useState(false)

  const paymentFormRef = useRef<StripePaymentFormHandle>(null)

  const proceed = async () => {
    try {
      setIsLoading(true)
      const { setupIntent, error } =
        (await paymentFormRef.current?.createPayment({
          client,
          billingAddress,
          clientSecret,
        })) || {}

      if (error) {
        setIsLoading(false)
        setErrorMessage(
          typeof error === 'string' ? getErrorMessage(error) : null,
        )
      } else if (setupIntent?.status === 'succeeded') {
        onProceed()
      }
    } catch (e) {
      setIsLoading(false)
      setErrorMessage(getErrorMessage(e as ApiError))
    }
  }

  const handleBack = () => {
    setErrorMessage(null)
    onBack()
  }

  if (!publicKey) {
    return (
      <Grid container item justifyContent="center" px={3} py={2}>
        <CircularProgress />
      </Grid>
    )
  }

  return (
    <>
      <Grid container px={3} py={2}>
        <StripePaymentForm
          classes={{
            inputContainer: classes.inputContainer,
          }}
          publicKey={publicKey}
          ref={paymentFormRef}
          onCompleteStateChange={(complete) => {
            setFormComplete(complete)
            setErrorMessage(null)
          }}
        />
      </Grid>
      <ErrorTooltip
        message={errorMessage}
        open={Boolean(errorMessage)}
        placement="bottom"
      >
        <Grid container className={classes.footer} px={3} py={2}>
          <Grid item mr={3}>
            <BackButton label={t('Common:BACK_ACTION')} onClick={handleBack} />
          </Grid>
          <Grid item>
            <ButtonWithLoader
              className={classes.button}
              disabled={Boolean(errorMessage) || !formComplete}
              loading={isLoading}
              type="submit"
              onClick={proceed}
            >
              {buttonLabel}
            </ButtonWithLoader>
          </Grid>
        </Grid>
      </ErrorTooltip>
    </>
  )
}

export default StripePaymentCardDetails
