import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  NumberUtils,
  PermissionArea,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'
import { Chain as ChainIcon } from '@pbt/pbt-ui-components/src/icons'

import { PaymentDetailsItem } from '~/components/dashboard/invoices/payment/payment-details-dialog/PaymentDetailsItem'
import FeatureToggle from '~/constants/featureToggle'
import { unlinkPaymentToInvoice } from '~/store/actions/payments'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Invoice } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    iconButton: {
      marginLeft: theme.spacing(0.5),
    },
    chainIcon: {
      width: 16,
      height: 16,
      color: theme.colors.tabLabel,
    },
    invoiceNameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
  { name: 'PaymentDetailsInvoicesItem' },
)

type PaymentDetailsInvoicesItemProps = {
  invoices: Invoice[]
  loading: boolean
  onInvoiceClick: (invoice: Invoice) => void
  paymentId: string
}

export const PaymentDetailsInvoicesItem = ({
  invoices,
  loading,
  paymentId,
  onInvoiceClick,
}: PaymentDetailsInvoicesItemProps) => {
  const { t } = useTranslation('Common')

  const classes = useStyles()
  const dispatch = useDispatch()

  const isUnlinkInvoicesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.UNLINK_INVOICES),
  )
  const creditAdjustmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.CREDIT_ADJUSTMENT),
  )
  const onUnlinkPaymentFromInvoice = (invoiceId: string) =>
    dispatch(unlinkPaymentToInvoice(paymentId, invoiceId))

  return (
    <PaymentDetailsItem label={t('Common:INVOICE')}>
      <Grid item xs>
        {invoices.map((invoice) => (
          <Grid
            container
            item
            alignContent="center"
            alignItems="center"
            direction="row"
            key={invoice.id}
          >
            <Text link variant="body2" onClick={() => onInvoiceClick(invoice)}>
              <span className={classes.invoiceNameContainer}>
                {invoice.invoiceNo}
                {loading && (
                  <CircularProgress size={20} sx={{ marginLeft: 0.5 }} />
                )}
              </span>
              {invoice.amount
                ? ` ${NumberUtils.formatMoney(invoice.amount, true)}`
                : ''}
            </Text>
            {isUnlinkInvoicesEnabled && creditAdjustmentPermissions.update && (
              <IconButton
                className={classes.iconButton}
                size="small"
                type="button"
                onClick={() => onUnlinkPaymentFromInvoice(invoice.id)}
              >
                <ChainIcon className={classes.chainIcon} />
              </IconButton>
            )}
          </Grid>
        ))}
      </Grid>
    </PaymentDetailsItem>
  )
}
