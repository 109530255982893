import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CircularProgress, Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  Icons,
  Nil,
  PermissionArea,
  PuiHighlight,
  PuiTheme,
  TextInteractive,
} from '@pbt/pbt-ui-components'
import { MembershipStatus } from '@pbt/pbt-ui-components/src/constants/wellnessPlans'
import { Wellness as WellnessIcon } from '@pbt/pbt-ui-components/src/icons'
import { getHighlightedText } from '@pbt/pbt-ui-components/src/utils'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import FeatureToggle from '~/constants/featureToggle'
import {
  getCurrentBusinessIsOmniChannel,
  getGroupCRUDByArea,
} from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatientsForUser, getUser } from '~/store/reducers/users'
import useTransitioningList from '~/utils/useTransitioningList'

import { useClientMembershipStatus } from '../../wellness-plans/wellnessPlanUtils'
import PatientRow, { PatientRowActionButton } from './PatientRow'
import PetFriendsLabel from './PetFriendsLabel'

const TRANSITION_RENDER_STEP = 20

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    noPatientsText: {
      padding: theme.spacing(0, 8, 2),
    },
    container: {
      cursor: 'pointer',
      borderRadius: 2,
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    listShadow: {
      '&:hover': {
        boxShadow: theme.constants.listItemShadow,
      },
    },
    patientColumn: {
      [theme.breakpoints.up('md')]: {
        borderLeft: theme.constants.tableBorder,
      },
    },
    chip: {
      overflow: 'visible',
    },
    clientColumn: {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 1.5),
      },
      [theme.breakpoints.down('md')]: {
        padding: '3px 12px',
      },
      padding: theme.spacing(1, 2),
      backgroundColor: theme.colors.tableLeftColumnBackground,
    },
    inactiveClientColumn: {
      opacity: theme.constants.inactiveOpacity,
      backgroundColor: theme.colors.inactive,
    },
    wpActiveClientColumn: {
      backgroundColor: theme.colors.completedTextColor,
    },
    wpPausedClientColumn: {
      backgroundColor: theme.colors.actionNeededBackground,
    },
    text: {
      lineHeight: '2.7rem',
      wordBreak: 'break-all',
    },
    subtext: {
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(4),
      },
    },
  }),
  { name: 'ClientsTableRow' },
)

const FAKE_PATIENTS_ARRAY = ['fakeId']

interface ClientsTableRowProps {
  actionButtons?: PatientRowActionButton[]
  hideActionsColumn?: boolean
  hideFlagsColumn?: boolean
  hideLastAppointmentColumn?: boolean
  hideMembershipColumn?: boolean
  hideScheduleColumn?: boolean
  hideSharedColumn?: boolean
  highlightProps: Record<string, string[]>
  item: string
  onClick: (clientId: string | Nil, patientId?: string | Nil) => void
}

const ClientsTableRow = ({
  item: clientId,
  actionButtons,
  hideFlagsColumn,
  hideScheduleColumn,
  hideLastAppointmentColumn,
  hideActionsColumn,
  hideMembershipColumn,
  hideSharedColumn,
  highlightProps,
  onClick,
}: ClientsTableRowProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const client = useSelector(getUser(clientId))
  const sortedPatientsList = useSelector(getPatientsForUser(clientId), R.equals)
  const { read: patientGroupReadPermissions } = useSelector(
    getGroupCRUDByArea(PermissionArea.PATIENT),
  )
  const isWPSurfaceClientStatusEnabled = useSelector(
    getFeatureToggle(FeatureToggle.WP_SURFACE_CLIENT_STATUS),
  )
  const membershipStatus = useClientMembershipStatus(clientId)
  const isOmnichannel = useSelector(getCurrentBusinessIsOmniChannel)

  const isLoading = !client
  const patientsList = isLoading ? FAKE_PATIENTS_ARRAY : sortedPatientsList

  const { transitioningList, isPending } = useTransitioningList(
    patientsList,
    TRANSITION_RENDER_STEP,
  )

  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )
  const patientsCount = patientsList.length
  const clientColumnJustify =
    patientsCount > 1 || isLoading ? 'flex-start' : 'center'

  const WpIcon = isOmnichannel &&
    isWPSurfaceClientStatusEnabled &&
    (membershipStatus === MembershipStatus.PAUSED ||
      membershipStatus === MembershipStatus.ACTIVE) && (
      <Grid container item flex="0 0 24px" ml={1}>
        <WellnessIcon
          className={classes.chip}
          variant={
            membershipStatus === MembershipStatus.PAUSED
              ? Icons.IconVariant.PAUSED
              : Icons.IconVariant.PRIMARY
          }
        />
      </Grid>
    )

  return (
    <Grid
      container
      className={classNames(
        classes.container,
        patientsCount === 1 ? classes.listShadow : {},
      )}
      direction={isMobile ? 'column' : 'row'}
      wrap="nowrap"
    >
      <Grid
        container
        item
        alignItems={isMobile ? 'stretch' : 'flex-start'}
        className={classNames(classes.clientColumn, {
          [classes.inactiveClientColumn]: client?.active === false,
          [classes.wpActiveClientColumn]:
            membershipStatus === MembershipStatus.ACTIVE &&
            isOmnichannel &&
            isWPSurfaceClientStatusEnabled,
          [classes.wpPausedClientColumn]:
            membershipStatus === MembershipStatus.PAUSED &&
            isOmnichannel &&
            isWPSurfaceClientStatusEnabled,
        })}
        direction="column"
        justifyContent={clientColumnJustify}
        md={hideMembershipColumn ? 3 : 2}
        onClick={() => onClick(clientId)}
      >
        {isMobile ? (
          <Grid container item alignItems="center" wrap="nowrap">
            <Dotdotdot clamp={1}>
              <TextInteractive
                strong
                className={classes.text}
                isLoading={isLoading}
                loaderWidth={180}
                variant="subheading"
              >
                {client?.firstName && (
                  <PuiHighlight
                    page
                    searchTerm={getHighlightedText(
                      client.firstName,
                      highlightProps.firstName,
                    )}
                  >
                    {client.firstName}
                  </PuiHighlight>
                )}
                {client?.lastName && (
                  <>
                    {' '}
                    <PuiHighlight
                      page
                      searchTerm={getHighlightedText(
                        client.lastName,
                        highlightProps.lastName,
                      )}
                    >
                      {client.lastName}
                    </PuiHighlight>
                  </>
                )}{' '}
                <PetFriendsLabel
                  inline
                  classes={{ subtext: classes.subtext }}
                  clientId={clientId}
                  highlights={highlightProps.petFriendString}
                />
              </TextInteractive>
            </Dotdotdot>
            {patientGroupReadPermissions && (
              <BusinessShareIcon
                businessIds={client?.clientInContextBusinesses}
              />
            )}
            {WpIcon}
          </Grid>
        ) : (
          <>
            <Grid container item alignItems="center" wrap="nowrap">
              <Dotdotdot clamp={1}>
                <TextInteractive
                  strong
                  className={classes.text}
                  isLoading={isLoading}
                  variant="subheading"
                >
                  {client?.firstName && (
                    <PuiHighlight
                      page
                      searchTerm={getHighlightedText(
                        client.firstName,
                        highlightProps.firstName,
                      )}
                    >
                      {client.firstName}
                    </PuiHighlight>
                  )}
                  {client?.lastName && (
                    <>
                      {' '}
                      <PuiHighlight
                        page
                        searchTerm={getHighlightedText(
                          client.lastName,
                          highlightProps.lastName,
                        )}
                      >
                        {client.lastName}
                      </PuiHighlight>
                    </>
                  )}
                </TextInteractive>
              </Dotdotdot>
              {patientGroupReadPermissions && (
                <BusinessShareIcon
                  businessIds={client?.clientInContextBusinesses}
                />
              )}
              {WpIcon}
            </Grid>
            <Dotdotdot clamp={2}>
              <PetFriendsLabel
                classes={{ subtext: classes.subtext }}
                clientId={clientId}
                highlights={highlightProps.petFriendString}
              />
            </Dotdotdot>
          </>
        )}
      </Grid>
      <Grid
        container
        item
        className={classes.patientColumn}
        direction="column"
        justifyContent="center"
      >
        {patientsCount > 0 ? (
          transitioningList.map((patientId) => (
            <PatientRow
              actionButtons={actionButtons}
              clientId={clientId}
              hideActionsColumn={hideActionsColumn}
              hideFlagsColumn={hideFlagsColumn}
              hideLastAppointmentColumn={hideLastAppointmentColumn}
              hideMembershipColumn={hideMembershipColumn}
              hideScheduleColumn={hideScheduleColumn}
              hideSharedColumn={hideSharedColumn}
              highlightProps={highlightProps}
              isLoading={isLoading}
              key={patientId}
              patientId={patientId}
              patientsCount={patientsCount}
              onClick={onClick}
            />
          ))
        ) : (
          <TextInteractive
            strong
            className={classNames(classes.text, classes.noPatientsText)}
            isLoading={isLoading}
            variant="subheading"
            onClick={() => onClick(clientId)}
          >
            {t('Common:NO_PATIENTS')}
          </TextInteractive>
        )}
        {isPending && (
          <Grid container alignItems="center" justifyContent="center" p={2}>
            <CircularProgress size={20} />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default ClientsTableRow
