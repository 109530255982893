import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  Nil,
  PuiTheme,
  TextWithTooltip,
  TextWithTooltipProps,
} from '@pbt/pbt-ui-components'
import { WarnAlert } from '@pbt/pbt-ui-components/src/icons'

import WarningRowLabel from './WarningRowLabel'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    text: {},
    infoIcon: {
      color: theme.colors.alertWarning,
      fontSize: '1.5rem',
    },
    tooltip: {
      borderColor: theme.colors.alertWarning,
      '&::before': {
        borderTopColor: theme.colors.alertWarning,
        marginLeft: 5,
      },
      '&::after': {
        marginLeft: 8,
      },
    },
  }),
  { name: 'ApproximateAgeLabel' },
)

export interface ApproximateAgeLabelProps extends TextWithTooltipProps {
  children: React.ReactNode
  classes?: ClassesType<typeof useStyles>
  isApproximate: boolean | Nil
  showTooltip?: boolean
}
const ApproximateAgeLabel = ({
  isApproximate,
  showTooltip,
  children,
  classes: classesProp,
  ...rest
}: ApproximateAgeLabelProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Common', 'Tooltips'])

  return showTooltip ? (
    <TextWithTooltip
      Icon={WarnAlert}
      TooltipProps={{
        classes: {
          tooltip: classes.tooltip,
        },
      }}
      className={classes.text}
      classes={{
        infoIcon: classes.infoIcon,
      }}
      iconPlacement="left"
      tooltipText={isApproximate ? t('Tooltips:APPROXIMATE_AGE_LABEL') : ''}
      {...rest}
    >
      {children}
    </TextWithTooltip>
  ) : (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      columnSpacing={2}
      wrap="nowrap"
    >
      <Grid item xs>
        {children}
      </Grid>
      {isApproximate && (
        <Grid item>
          <WarningRowLabel message={t('Common:APPROXIMATE_AGE')} />
        </Grid>
      )}
    </Grid>
  )
}

export default ApproximateAgeLabel
