import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ClickAwayListener, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useDebounce } from 'use-debounce'
import {
  ClassesType,
  Defaults,
  InlineSearch,
  PermissionArea,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import SearchResults from '~/components/common/lists/SearchResults'
import SearchContext from '~/constants/searchContext'
import {
  clearSuggestionResults,
  fetchSuggestionResults,
} from '~/store/actions/search'
import { getGroupCRUDByArea } from '~/store/reducers/auth'
import {
  getSuggestionIsReceiving,
  getSuggestionResults,
} from '~/store/reducers/search'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      marginTop: theme.spacing(2),
      position: 'relative',
    },
    searchRoot: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above', 2),
    },
  }),
  { name: 'BaseSearch' },
)

export interface BaseSearchProps {
  autoFocus?: boolean
  classes?: ClassesType<typeof useStyles>
  closeOnSelect?: boolean
  onClear?: () => void
  onExpand?: (searchTerm: string) => void
  onItemClick: (itemId: string, subItemId: string) => void
  placeholder?: string
  searchContext: SearchContext
  visibleResults?: number
}

const BaseSearch = ({
  autoFocus = false,
  closeOnSelect = false,
  searchContext,
  onExpand,
  onItemClick,
  onClear,
  visibleResults = 10,
  placeholder,
  classes: classesProp,
}: BaseSearchProps) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const searchResults = useSelector(getSuggestionResults)
  const isReceiving = useSelector(getSuggestionIsReceiving)
  const { read: patientGroupReadPermissions } = useSelector(
    getGroupCRUDByArea(PermissionArea.PATIENT),
  )

  const [searchResultsOpen, setSearchResultsOpen] = useState(false)
  const [rawSearchTerm, setRawSearchTerm] = useState('')
  const [active, setActive] = useState(false)

  const [searchTerm] = useDebounce(rawSearchTerm, Defaults.DEBOUNCE_ACTION_TIME)

  const getIfOpen = () =>
    searchTerm.length >= Defaults.MIN_SEARCH_LENGTH && !isReceiving && active

  const isClientSearchContext = searchContext === SearchContext.CLIENT_PATIENTS
  const baseSearchPlaceholder = placeholder || t<string>('Common:SEARCH_ACTION')

  useEffect(() => {
    setSearchResultsOpen(getIfOpen())
  }, [isReceiving])

  useEffect(() => {
    setSearchResultsOpen(false)
    dispatch(clearSuggestionResults())
    if (searchTerm && searchTerm.length >= Defaults.MIN_SEARCH_LENGTH) {
      dispatch(
        fetchSuggestionResults({
          searchContext,
          searchTerm,
        }),
      )
    }

    return () => {
      dispatch(clearSuggestionResults())
    }
  }, [searchTerm])

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' && onExpand) {
        onExpand(rawSearchTerm)
      }
    },
    [rawSearchTerm],
  )

  return (
    <ClickAwayListener
      onClickAway={() => {
        setSearchResultsOpen(false)
        setActive(false)
      }}
    >
      <Grid container item className={classes.root}>
        <InlineSearch
          autoFocus={autoFocus}
          classes={{ root: classes.searchRoot }}
          isLoading={isReceiving}
          placeholder={baseSearchPlaceholder}
          search={rawSearchTerm}
          onBlur={() => setActive(false)}
          onChange={(term: string) => {
            setRawSearchTerm(term)
            if (!term) {
              setSearchResultsOpen(false)
              if (onClear) {
                onClear()
              }
            }
          }}
          onFocus={() => {
            setSearchResultsOpen(getIfOpen())
            setActive(true)
          }}
          onKeyPress={handleKeyPress}
        />
        <SearchResults
          hasSubItems
          open={searchResultsOpen}
          results={searchResults}
          searchTerm={searchTerm}
          showContextIds={patientGroupReadPermissions && isClientSearchContext}
          visibleResults={visibleResults}
          onItemClick={(itemId: string, subItemId: string) => {
            if (closeOnSelect) {
              setSearchResultsOpen(false)
            }
            onItemClick(itemId, subItemId)
          }}
        />
      </Grid>
    </ClickAwayListener>
  )
}

export default BaseSearch
