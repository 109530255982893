import { all, call, put, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { MembershipPayment } from '~/types'
import { getMembershipPaymentId } from '~/utils/membershipPaymentUtils'

import {
  FETCH_MEMBERSHIP_PAYMENTS,
  FETCH_MORE_MEMBERSHIP_PAYMENTS,
  FETCH_REFUND_MEMBERSHIP_PAYMENT_INFO,
  fetchMembershipPayments,
  fetchMembershipPaymentsFailure,
  fetchMembershipPaymentsSuccess,
  fetchMoreMembershipPayments,
  fetchMoreMembershipPaymentsFailure,
  fetchMoreMembershipPaymentsSuccess,
  fetchRefundMembershipPaymentInfo,
  fetchRefundMembershipPaymentInfoFailure,
  fetchRefundMembershipPaymentInfoSuccess,
  REFRESH_MEMBERSHIP_PAYMENTS,
  refreshMembershipPayments,
  refreshMembershipPaymentsFailure,
  refreshMembershipPaymentsSuccess,
  REFUND_MEMBERSHIP_PAYMENT,
  refundMembershipPayment,
  refundMembershipPaymentFailure,
  refundMembershipPaymentSuccess,
} from '../duck/membershipPayments'
import { finishLoading, startLoading } from '../duck/progress'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchMembershipPaymentsSaga({
  from,
  to,
  clientId,
}: ReturnType<typeof fetchMembershipPayments>) {
  try {
    yield put(startLoading('membershipPayments'))
    const response = yield* requestAPI(
      API.fetchMembershipPaymentsList,
      from,
      to,
      clientId,
    )
    const {
      result: { data: list, totalCount },
      entities,
    } = response
    yield call(updateEntities, entities)
    yield put(fetchMembershipPaymentsSuccess(list, totalCount))
  } catch (error) {
    yield put(fetchMembershipPaymentsFailure(error as ApiError))
  } finally {
    yield put(finishLoading('membershipPayments'))
  }
}

export function* fetchMoreMembershipPaymentsSaga({
  from,
  to,
  clientId,
}: ReturnType<typeof fetchMoreMembershipPayments>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchMembershipPaymentsList, from, to, clientId)
    yield call(updateEntities, entities)
    yield put(fetchMoreMembershipPaymentsSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreMembershipPaymentsFailure(error as ApiError))
  }
}

export function* refreshMembershipPaymentsSaga({
  from,
  to,
  clientId,
}: ReturnType<typeof refreshMembershipPayments>) {
  try {
    const response = yield* requestAPI(
      API.fetchMembershipPaymentsList,
      from,
      to,
      clientId,
    )
    const {
      result: { data: list, totalCount },
      entities,
    } = response
    yield call(updateEntities, entities)
    yield put(refreshMembershipPaymentsSuccess(list, totalCount))
  } catch (error) {
    yield put(refreshMembershipPaymentsFailure(error as ApiError))
  }
}

export function* refundMembershipPaymentSaga({
  paymentId,
  refundAmount,
  notes,
}: ReturnType<typeof refundMembershipPayment>) {
  try {
    const { entities } = yield* requestAPI(
      API.refundMembershipPayment,
      paymentId,
      refundAmount,
      notes,
    )
    yield call(updateEntities, entities)
    yield put(refundMembershipPaymentSuccess(paymentId))
  } catch (error) {
    yield put(refundMembershipPaymentFailure(error as ApiError))
  }
}

export function* fetchRefundMembershipPaymentInfoSaga({
  paymentId,
  stripeSubscriptionId,
}: ReturnType<typeof fetchRefundMembershipPaymentInfo>) {
  try {
    const availableRefundPayment = yield* requestAPI(
      API.fetchRefundMembershipPaymentInfo,
      paymentId,
    )
    const membershipPaymentId = getMembershipPaymentId({
      id: paymentId,
      stripeSubscriptionId,
    })

    yield call(updateEntities, {
      membershipPayments: {
        [membershipPaymentId]: { availableRefundPayment } as MembershipPayment,
      },
    })
    yield put(fetchRefundMembershipPaymentInfoSuccess(paymentId))
  } catch (error) {
    yield put(fetchRefundMembershipPaymentInfoFailure(error as ApiError))
  }
}

function* watchFetchMembershipPayments() {
  yield takeLeading(FETCH_MEMBERSHIP_PAYMENTS, fetchMembershipPaymentsSaga)
}

function* watchFetchMoreMembershipPayments() {
  yield takeLeading(
    FETCH_MORE_MEMBERSHIP_PAYMENTS,
    fetchMoreMembershipPaymentsSaga,
  )
}

function* watchRefreshMembershipPayments() {
  yield takeLeading(REFRESH_MEMBERSHIP_PAYMENTS, refreshMembershipPaymentsSaga)
}

function* watchRefundMembershipPayment() {
  yield takeLeading(REFUND_MEMBERSHIP_PAYMENT, refundMembershipPaymentSaga)
}

function* watchFetchRefundMembershipPaymentInfo() {
  yield takeLeading(
    FETCH_REFUND_MEMBERSHIP_PAYMENT_INFO,
    fetchRefundMembershipPaymentInfoSaga,
  )
}

export default function* membershipPaymentsSaga() {
  yield all([
    watchFetchMembershipPayments(),
    watchFetchMoreMembershipPayments(),
    watchRefreshMembershipPayments(),
    watchRefundMembershipPayment(),
    watchFetchRefundMembershipPaymentInfo(),
  ])
}
