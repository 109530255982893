import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, FormControl, Grid, InputLabel, Radio } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AlertIconType,
  NumberUtils,
  PuiSelect,
  PuiTheme,
  PuiTooltip,
  Text,
  TextWithTooltip,
  Utils,
} from '@pbt/pbt-ui-components'
import { Info as InfoIcon } from '@pbt/pbt-ui-components/src/icons'

import NumericInput from '~/components/common/inputs/NumericInput'
import PuiSwitch from '~/components/common/PuiSwitch'
import DialogNames from '~/constants/DialogNames'
import {
  getAdjustByPercent,
  getAdjustRoundup,
  getAdjustValue,
  getBulkPricesFilter,
  getHasManualPriceChanges,
  setAdjustByPercent,
  setAdjustRoundup,
  setAdjustValue,
  setBulkPricesFilter,
} from '~/store/duck/bulkPrices'
import { getConstant } from '~/store/reducers/constants'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
      borderRadius: 4,
      position: 'relative',
    },
    topRight: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    selectInput: {
      width: 400,
    },
    numericInput: {
      width: 64,
    },
    icon: {
      color: theme.colors.link,
      cursor: 'pointer',
    },
  }),
  { name: 'BulkPricesEditFilters' },
)

export enum BulkPricesCategoryConstant {
  PROCEDURE = 'ProcedureCategory',
  INVENTORY = 'InventoryCategory',
  LAB_VENDORS = 'LabVendors',
}

export enum BulkPricesSubCategoryConstant {
  INVENTORY = 'InventorySubCategory',
}

interface BulkPricesEditFiltersProps {
  categoryLabel?: string
  categoryName: BulkPricesCategoryConstant
  subCategoryName?: BulkPricesSubCategoryConstant
}

const BulkPricesEditFilters = ({
  categoryLabel,
  categoryName,
  subCategoryName,
}: BulkPricesEditFiltersProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Prices')
  const hasManualPriceChanges = useSelector(getHasManualPriceChanges)
  const CategoryConstant = useSelector(getConstant(categoryName))
  const SubCategoryConstant = useSelector(getConstant(subCategoryName!))
  const categoryValue = useSelector(getBulkPricesFilter(categoryName))
  const subCategoryValue = useSelector(getBulkPricesFilter(subCategoryName))
  const roundUp = useSelector(getAdjustRoundup)
  const adjustByPercent = useSelector(getAdjustByPercent)
  const adjustValue = useSelector(getAdjustValue)

  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const sortedCategories = R.sortBy(
    ({ nameTranslation }) => nameTranslation || '',
    CategoryConstant,
  )
  const subCategories =
    (categoryValue && SubCategoryConstant?.[categoryValue as string]) || []
  const sortedSubCategories = R.sortBy(
    ({ nameTranslation }) => nameTranslation || '',
    subCategories,
  )

  const handleChangeRoundup = () => {
    dispatch(setAdjustRoundup({ value: !roundUp }))
  }

  const handleChangeCategory = (category: BulkPricesCategoryConstant) => {
    dispatch(setBulkPricesFilter({ filter: categoryName, value: category }))
  }

  const handleChangeSubCategory = (
    subCategory: BulkPricesSubCategoryConstant,
  ) => {
    dispatch(
      setBulkPricesFilter({ filter: subCategoryName, value: subCategory }),
    )
  }

  const handleAdjustModeChange = (value: boolean) => () => {
    if (hasManualPriceChanges) {
      openAlert({
        iconType: AlertIconType.WARN,
        cancelButtonText: t('Common:NO_RETURN'),
        okButtonText: t('Common:YES_UPDATE'),
        message: t('Prices:ALL_MANUALLY_ENTERED_PRICES_WILL_BE_OVERRIDDEN'),
        onCancel: closeAlert,
        onOk: () => {
          dispatch(setAdjustByPercent({ value }))
          closeAlert()
        },
      })
    } else {
      dispatch(setAdjustByPercent({ value }))
    }
  }

  const handleChangeAdjustedValue = (value: string | number) => {
    const newValue = value || null
    if (hasManualPriceChanges) {
      openAlert({
        iconType: AlertIconType.WARN,
        cancelButtonText: t('Common:NO_RETURN'),
        okButtonText: t('Common:YES_UPDATE'),
        message: t('Prices:ALL_MANUALLY_ENTERED_PRICES_WILL_BE_OVERRIDDEN'),
        onCancel: closeAlert,
        onOk: () => {
          dispatch(setAdjustValue({ value: newValue }))
          closeAlert()
        },
      })
    } else {
      dispatch(setAdjustValue({ value: newValue }))
    }
  }

  return (
    <Grid
      container
      item
      className={classes.paper}
      direction="column"
      mb={2}
      p={2}
      pb={1}
    >
      <Box className={classes.topRight} p={1}>
        <PuiTooltip
          tooltipPlacement="top-end"
          tooltipText={t('Prices:TOOLTIP.MARKUP_PRICES_HINT')}
        >
          <InfoIcon className={classes.icon} />
        </PuiTooltip>
      </Box>
      <Grid container item columnSpacing={3} mb={2}>
        <Grid item>
          <FormControl className={classes.selectInput}>
            <InputLabel id="bulk-prices-category-select-label">
              {categoryLabel || t('Prices:SELECT_CATEGORY')}
            </InputLabel>
            <PuiSelect
              items={sortedCategories}
              labelId="bulk-prices-category-select-label"
              value={categoryValue}
              onChange={Utils.handleFormSelectInput(handleChangeCategory)}
            />
          </FormControl>
        </Grid>
        {subCategoryName && (
          <Grid item>
            <FormControl className={classes.selectInput}>
              <InputLabel id="bulk-prices-subcategory-select-label">
                {t('Prices:SELECT_SUB_CATEGORY')}
              </InputLabel>
              <PuiSelect
                disabled={!categoryValue}
                items={sortedSubCategories}
                labelId="bulk-prices-subcategory-select-label"
                value={subCategoryValue}
                onChange={Utils.handleFormSelectInput(handleChangeSubCategory)}
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Grid container item alignItems="center">
        <Text disabled={!categoryValue} variant="body">
          {t('Prices:ADJUST_PRICES_BY')}
        </Text>
        <Box ml={2}>
          <Radio
            checked={Boolean(adjustByPercent)}
            disabled={!categoryValue}
            onChange={handleAdjustModeChange(true)}
          />
          <NumericInput
            allowDecimal
            nullable
            InputProps={{ endAdornment: '%' }}
            className={classes.numericInput}
            disabled={!adjustByPercent || !categoryValue}
            fullWidth={false}
            margin="none"
            max={999}
            min={-100}
            value={adjustByPercent ? adjustValue : null}
            onChange={handleChangeAdjustedValue}
          />
        </Box>
        <Box ml={2}>
          <Radio
            checked={!adjustByPercent}
            disabled={!categoryValue}
            onChange={handleAdjustModeChange(false)}
          />
          <NumericInput
            allowDecimal
            nullable
            InputProps={{ endAdornment: NumberUtils.getCurrencySymbol() }}
            className={classes.numericInput}
            disabled={Boolean(adjustByPercent) || !categoryValue}
            fullWidth={false}
            margin="none"
            max={999}
            min={-999}
            value={adjustByPercent ? null : adjustValue}
            onChange={handleChangeAdjustedValue}
          />
        </Box>
        <Grid container item alignItems="center" ml={3} width="auto">
          <PuiSwitch
            checked={roundUp}
            disabled={!categoryValue}
            label={
              <TextWithTooltip
                disabled={!categoryValue}
                tooltipText={t('Prices:TOOLTIP.ROUND_UP')}
                variant="body"
              >
                {t('Prices:ROUND_UP')}
              </TextWithTooltip>
            }
            onChange={handleChangeRoundup}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BulkPricesEditFilters
