import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  DateUtils,
  Nil,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import CategoryButtonGroup, {
  CategoryButtonGroupVariant,
} from '~/components/dashboard/communications/common/CategoryButtonGroup'
import { CreatedConversationFlow } from '~/components/dashboard/communications/common/CreatedConversationsInfoDialog'
import NoConversationsScreen from '~/components/dashboard/communications/table/NoConversationsScreen'
import { ConversationCategory } from '~/constants/communications'
import DialogNames from '~/constants/DialogNames'
import i18n from '~/locales/i18n'
import { fetchClient } from '~/store/actions/clients'
import { navigateToSoapConversation } from '~/store/actions/soap'
import { useShouldDisplaySmsConsent } from '~/store/hooks/clients'
import { useConversationList } from '~/store/hooks/conversations'
import { getClientIsLoading } from '~/store/reducers/clients'
import {
  getConversationListFetching,
  getConversationsListObjects,
  getConversationsTotalCount,
} from '~/store/reducers/conversations'
import { getPatient } from '~/store/reducers/patients'
import { getAppointment } from '~/store/reducers/soap'
import { TableFilter } from '~/types'
import {
  getConversationRowColoring,
  hasAppliedFilters,
  mapDateToConversationDetailsDate,
} from '~/utils/communicationsUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  () => ({
    root: {
      height: '100%',
    },
    button: {
      minWidth: 162,
      height: 40,
    },
  }),
  { name: 'ChatTable' },
)

export interface ChatTableProps {
  clientId?: string | Nil
  editDisabled?: boolean
  expanded: boolean
  patientId?: string | Nil
  soapId?: string | Nil
}

const ChatTable = ({
  expanded,
  editDisabled,
  soapId,
  clientId,
  patientId,
}: ChatTableProps) => {
  const dispatch = useDispatch()
  const theme = useTheme<PuiTheme>()
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const columns = [
    {
      prop: 'subject',
      width: expanded ? 10 : 7,
      label: i18n.t('Common:SUBJECT'),
    },
    {
      prop: 'lastMessageDate',
      width: expanded ? 2 : 5,
      label: i18n.t('Common:LAST_MESSAGE'),
    },
  ]

  const conversations = useSelector(getConversationsListObjects)
  const conversationsTotalCount = useSelector(getConversationsTotalCount)
  const isLoading = useSelector(getConversationListFetching)
  const patient = useSelector(getPatient(patientId))
  const appointment = useSelector(getAppointment)
  const isLoadingClient = useSelector(getClientIsLoading)

  const isMobile = useMediaQuery((themeInstance: PuiTheme) =>
    themeInstance.breakpoints.down('sm'),
  )

  const [openNewConversationDialog] = useDialog(
    DialogNames.NEW_CONVERSATION_WITH_STEPS,
  )
  const [openCreatedConversationsInfoDialog] = useDialog(
    DialogNames.CREATED_CONVERSATIONS_INFO_DIALOG,
  )

  const { loadMoreItems, clearFilters, applyFilter, filters } =
    useConversationList({ clientId, patientId })
  const shouldDisplaySmsConsent = useShouldDisplaySmsConsent()

  const tableConversations = conversations.map((conversation) =>
    conversation
      ? {
          id: conversation.id,
          lastMessageDate: mapDateToConversationDetailsDate(
            conversation?.lastMessageDate,
          ),
          subject: conversation.title,
        }
      : undefined,
  )

  const getActualRowColoring = R.curry(getConversationRowColoring)(theme)

  const navigateToConversation = (id: string) => {
    dispatch(navigateToSoapConversation(id))
  }

  const handleConversationCreated = (flow: CreatedConversationFlow) => {
    if (flow === CreatedConversationFlow.DRAFT_MESSAGE_SENT) {
      applyFilter('category', { value: ConversationCategory.DRAFT })
    }

    openCreatedConversationsInfoDialog({
      flow,
      handleViewConversation: navigateToConversation,
    })
  }

  const date =
    appointment?.startDate && DateUtils.formatDate(appointment.startDate)
  const defaultSubjectName = t('Common:PET').toLowerCase()
  const initialSubject = t('Common:PATIENT_VISIT_ON_DATE', {
    patientName: patient?.name || defaultSubjectName,
    date,
  })

  const handleOpenNewConversationDialog = () => {
    openNewConversationDialog({
      clientId,
      patientId,
      initialSubject,
      handleConversationCreated,
      appointmentId: appointment?.appointmentId,
    })
  }

  const openNewConversationDialogAfterClientFetch = useCloseAfterCreation(
    handleOpenNewConversationDialog,
    getClientIsLoading,
  )

  const onNewConversationClick = () => {
    if (shouldDisplaySmsConsent) {
      dispatch(fetchClient({ clientId, withGlobalClientPreferences: true }))
      openNewConversationDialogAfterClientFetch()
    } else {
      handleOpenNewConversationDialog()
    }
  }

  const isItemLoaded = (index: number) => Boolean(tableConversations[index])
  const hasSelectedFilters = hasAppliedFilters(
    R.omit(['category'], filters) as Record<string, TableFilter[]>,
  )

  useEffect(() => {
    clearFilters()
  }, [soapId])

  const headerButtons = (
    <CategoryButtonGroup
      applyFilter={applyFilter}
      filter={filters.category}
      variant={CategoryButtonGroupVariant.SMALL}
    />
  )

  const wrappedHeaderButtons = (
    <Grid
      container
      item
      alignItems="center"
      direction={isMobile ? 'column' : 'row'}
      justifyContent={isMobile ? 'flex-start' : 'space-between'}
      py={2}
      rowSpacing={2}
    >
      <Grid item>{headerButtons}</Grid>
      <Grid item>
        {!editDisabled && (
          <ButtonWithLoader
            className={classes.button}
            loading={isLoadingClient}
            onClick={onNewConversationClick}
          >
            {t('Common:NEW_CLIENT_CONVERSATION')}
          </ButtonWithLoader>
        )}
      </Grid>
    </Grid>
  )

  return (
    <Grid container className={classes.root}>
      <ExpandableTable
        NoItemsScreen={NoConversationsScreen}
        NoItemsScreenProps={{
          Header: headerButtons,
          categoryFilter: filters?.category?.value,
          onSentClick: () =>
            applyFilter('category', { value: ConversationCategory.SENT }),
          onNewConversationClick: !editDisabled
            ? onNewConversationClick
            : undefined,
        }}
        hasSelectedFilters={hasSelectedFilters}
        headerButtons={wrappedHeaderButtons}
        isLoading={isLoading}
        list={tableConversations}
        onSelected={navigateToConversation}
      >
        <PrimitiveTableWithSearchHighlights
          hideHeader
          columns={columns}
          filters={filters}
          getRowColoring={getActualRowColoring}
          isItemLoaded={isItemLoaded}
          list={tableConversations}
          loadMoreItems={loadMoreItems}
          mainColumnCount={2}
          totalCount={conversationsTotalCount}
          onApplyFilter={applyFilter}
          onClearFilters={clearFilters}
        />
      </ExpandableTable>
    </Grid>
  )
}

export default ChatTable
