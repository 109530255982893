import * as R from 'ramda'
import { Nil } from '@pbt/pbt-ui-components'

import { TopicContext } from '~/constants/websocket'
import { ContextType, WSTopic, WSTopicContext } from '~/types'

const getPathByTopic = (
  condition: boolean,
  path: string,
  param: string | Nil,
) => (condition && Boolean(param) ? `${path}.${param}.` : '')

export const getFullTopic = (
  { topic, topicContext, useUserPrefix }: WSTopic,
  context: WSTopicContext<ContextType.FULL>,
) => {
  const { businessId, patientId, personId, soapId, clientId, invoiceId } =
    context?.topicParams || {}

  const useBusinessTopicPath = [
    TopicContext.BUSINESS,
    TopicContext.BUSINESS_PERSON,
    TopicContext.BUSINESS_SOAP,
    TopicContext.BUSINESS_PATIENT,
    TopicContext.CLIENT,
    TopicContext.INVOICE,
    TopicContext.REFUND_INVOICE,
    TopicContext.CLIENT_SOAP,
  ].includes(topicContext)
  const usePersonTopicPath = [TopicContext.BUSINESS_PERSON].includes(
    topicContext,
  )
  const useSoapTopicPath = [
    TopicContext.BUSINESS_SOAP,
    TopicContext.CLIENT_SOAP,
  ].includes(topicContext)
  const usePatientTopicPath = [TopicContext.BUSINESS_PATIENT].includes(
    topicContext,
  )
  const useClientTopicPath = [
    TopicContext.CLIENT,
    TopicContext.CLIENT_SOAP,
  ].includes(topicContext)
  const useInvoiceTopicPath = [
    TopicContext.REFUND_INVOICE,
    TopicContext.INVOICE,
  ].includes(topicContext)

  const businessTopicPath = getPathByTopic(
    useBusinessTopicPath,
    'business',
    businessId,
  )
  const personTopicPath = getPathByTopic(usePersonTopicPath, 'person', personId)
  const patientTopicPath = getPathByTopic(
    usePatientTopicPath,
    'patient',
    patientId,
  )
  const soapTopicPath = getPathByTopic(useSoapTopicPath, 'soap', soapId)
  const clientTopicPath = getPathByTopic(useClientTopicPath, 'client', clientId)
  const invoiceTopicPath = getPathByTopic(
    useInvoiceTopicPath,
    'invoice',
    invoiceId,
  )

  const userPrefix = useUserPrefix ? '/user' : ''

  return `${userPrefix}/topic/portal.${businessTopicPath}${personTopicPath}${patientTopicPath}${clientTopicPath}${soapTopicPath}${invoiceTopicPath}${topic}`
}

export const getWSStatus = (action: any) => {
  const status = R.path(['error', 'headers', 'code'])(action) as string
  return parseInt(status, 10)
}
