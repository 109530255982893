import React, { JSXElementConstructor } from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import {
  AutoshipInfoWithDetails,
  ClassesType,
  Nil,
} from '@pbt/pbt-ui-components'

import PuiInformationPanel from '~/components/common/PuiInformationPanel'
import FeatureToggle from '~/constants/featureToggle'
import { OrderType } from '~/constants/SOAPStates'
import { getCurrentBusinessIsOmniChannel } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Invoice, InvoiceLineItem, Order } from '~/types'
import { InvoiceV3 } from '~/types/entities/invoiceV3'

import ChargeDetailsLineItem from './ChargeDetailsLineItem'
import ChargeItemButtonActions from './ChargeItemButtonActions'
import ChargeItemNote from './ChargeItemNote'
import {
  ChargeItemRefundCandidate,
  ChargeItemRefundCandidateProps,
} from './ChargeItemRefundCandidate'
import { ChargeItemRefundDetails } from './ChargeItemRefundDetails'
import ChargeItemTitle from './ChargeItemTitle'

export interface ChargeInformationPanelProps {
  ComponentsActions?: Partial<Record<OrderType, JSXElementConstructor<any>>>
  classes?: ClassesType<any>
  editDisabled: boolean
  invoice: Invoice | InvoiceV3 | Nil
  isInvoice?: boolean
  isLoading: boolean
  isOpened: boolean
  isSoap?: boolean
  item: InvoiceLineItem | Nil
  onClose: () => void
  onSelectItem: (item: InvoiceLineItem | Order) => void
  refundCandidate?: ChargeItemRefundCandidateProps
}

const ChargeInformationPanel = ({
  classes,
  editDisabled,
  isOpened,
  invoice,
  isLoading,
  isSoap,
  item,
  isInvoice,
  refundCandidate,
  onClose,
  onSelectItem,
  ComponentsActions,
}: ChargeInformationPanelProps) => {
  const noteEditDisabled =
    !R.isNil(item) && (R.isNil(item.logId) || R.isEmpty(item.logId))

  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )

  const isIpoM0InvoiceRefundsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.INVOICE_BASED_REFUNDS),
  )
  const isIpoM1CheckoutEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M1_CHECKOUT),
  )

  const isOmnichannel = useSelector(getCurrentBusinessIsOmniChannel)

  const isChewyAutoship = false // TODO: Default to false for now, integrate with BE to be after complete in: https://chewyinc.atlassian.net/browse/CVC-6633
  const displayAutoshipDetails =
    isIpoM1CheckoutEnabled && isOmnichannel && isChewyAutoship

  const areChargesPostedAndEditable = isInvoice && isEditPostedChargesEnabled

  const hasRefunds = !R.isEmpty(item?.refundLineItems ?? [])

  const showRefundSection = isIpoM0InvoiceRefundsEnabled && hasRefunds

  return (
    <PuiInformationPanel
      classes={{ informationPanel: classes?.chargeInformationPanel }}
      isLoading={isLoading}
      isOpened={isOpened}
      onClose={onClose}
    >
      {item ? (
        <>
          <ChargeItemTitle item={item} />

          <ChargeItemButtonActions
            ComponentsActions={ComponentsActions}
            disabled={editDisabled}
            invoice={invoice}
            isInvoice={isInvoice}
            isLoading={isLoading}
            item={item}
            onDelete={onClose}
            onUpdateItem={onSelectItem}
          />

          {displayAutoshipDetails && (
            <AutoshipInfoWithDetails
              frequency={{
                uom: 'WEEK', // TODO: Default for now, integrate with BE to be after complete in: https://chewyinc.atlassian.net/browse/CVC-6633
                value: 12,
              }}
            />
          )}

          <ChargeDetailsLineItem
            areChargesPostedAndEditable={Boolean(areChargesPostedAndEditable)}
            editDisabled={editDisabled}
            invoice={invoice}
            item={item}
          />

          {!refundCandidate && (
            <ChargeItemNote
              disabled={editDisabled || noteEditDisabled}
              isSoap={isSoap}
              item={item}
            />
          )}
          {showRefundSection && <ChargeItemRefundDetails item={item} />}
          {refundCandidate && (
            <ChargeItemRefundCandidate {...refundCandidate} />
          )}
        </>
      ) : null}
    </PuiInformationPanel>
  )
}

export default ChargeInformationPanel
