import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AppointmentConfiguration as AppointmentConfigurationType,
  ColorPicker,
  FieldProp,
  LanguageUtils,
  moment,
  NamedEntity,
  PuiCheckbox,
  PuiTextField,
  PuiTheme,
  Text,
  TextWithTooltip,
  useFields,
} from '@pbt/pbt-ui-components'

import DurationInput from '~/components/common/form-inputs/DurationInput'
import PuiSwitch from '~/components/common/PuiSwitch'
import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import {
  BusinessSettingsSections,
  getSectionFields,
} from '~/store/duck/businessSettings'
import {
  getAppointmentTypes,
  getFeatureToggle,
} from '~/store/reducers/constants'
import {
  BasePracticeDetailsSectionProps,
  PracticeAppointmentConfigurationFields,
} from '~/types'
import { DEFAULT_APPOINTMENT_DURATION, minutesToHHMM } from '~/utils/time'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'
import OccupyAllStaffTimeSlotSwitch from './OccupyAllStaffTimeSlotSwitch'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tableHeading: {
      verticalAlign: 'top',
      padding: theme.spacing(1, 0, 1, 2),
      borderRight: theme.constants.tableBorder,
      borderBottom: theme.constants.stripedTableHeaderBorder,
      width: '50%',
    },
    typeCell: {
      width: '80%',
    },
    tableViewport: {
      border: theme.constants.tableBorder,
      marginBottom: theme.spacing(2),
      tableLayout: 'fixed',
    },
    tableCell: {
      height: 40,
      padding: theme.spacing(0, 2),
      border: 'none',
      borderRight: theme.constants.tableBorder,
    },
    colorTableCell: {
      width: 80,
    },
    tableRow: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    checkboxLabel: {
      marginLeft: theme.spacing(1),
    },
    checkbox: {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(0.5),
    },
    durationField: {
      width: 50,
    },
    colorCell: {
      width: 80,
      padding: theme.spacing(1, 1, 1, 0),
    },
  }),
  { name: 'AppointmentConfiguration' },
)

const DURATION_VALIDATOR_NAME = 'minDuration'
const MIN_APPOINTMENT_DURATION_IN_MINUTES = 5

const durationValidator = {
  validator: ({ value }: { value: string }) => {
    const durationInMinutes = moment.duration(value).asMinutes()
    return durationInMinutes >= MIN_APPOINTMENT_DURATION_IN_MINUTES
  },
  validatorName: DURATION_VALIDATOR_NAME,
}

const getFields = (
  configuration: AppointmentConfigurationType[],
  AppointmentTypes: NamedEntity[],
) =>
  R.pipe(
    R.map((type: NamedEntity) => {
      const configurationItem = configuration.find(
        (item) => item.appointmentTypeId === type.id,
      )

      return [
        {
          name: `defaultDuration_id_${type.id}`,
          messages: {
            [DURATION_VALIDATOR_NAME]: i18n.t(
              'Validations:MUST_BE_GREATER_THAN_OR_EQUAL_TO',
              {
                value: MIN_APPOINTMENT_DURATION_IN_MINUTES,
              },
            ),
          },

          validators: ['required', 'duration', durationValidator],
          initialValue:
            configurationItem?.defaultDuration ||
            minutesToHHMM(DEFAULT_APPOINTMENT_DURATION),
        },
        {
          name: `color_${type.id}`,
          label: '',
          initialValue: configurationItem?.color || '',
        },
        {
          name: `enabled_id_${type.id}`,
          type: 'toggle',
          initialValue: configurationItem?.enabled ?? true,
        },
        {
          name: `onlineSchedulingAllowed_id_${type.id}`,
          type: 'toggle',
          initialValue: configurationItem?.onlineSchedulingAllowed ?? true,
        },
      ]
    }),
    R.flatten,
  )(AppointmentTypes) as FieldProp[]

const AppointmentConfiguration = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation([
    'Abbreviations',
    'Admin',
    'Businesses',
    'Common',
  ])

  const AppointmentTypes = useSelector(getAppointmentTypes)
  const appointmentConfigurationFields = useSelector(
    getSectionFields<
      BusinessSettingsSections['APPOINTMENT_CONFIGURATION']['fields']
    >(business.id, PracticeDetailsPanels.APPOINTMENT_CONFIGURATION),
  )
  const isAppointmentConfigHidden = useSelector(
    getFeatureToggle(
      FeatureToggle.HIDE_APPOINTMENT_CONFIGURATION_IN_PRACTICE_SETTINGS_TAB,
    ),
  )

  const configuration = business.appointmentConfiguration || []

  const { fields, validate, reset } = useFields(
    getFields(configuration, AppointmentTypes),
    false,
  )

  usePracticeFieldsSection<PracticeAppointmentConfigurationFields>({
    business,
    fields,
    parsedFields: {
      ...appointmentConfigurationFields,
      appointmentConfiguration: AppointmentTypes.map((type) => ({
        appointmentTypeId: type.id,
        defaultDuration: fields[`defaultDuration_id_${type.id}`].value,
        enabled: fields[`enabled_id_${type.id}`].value,
        onlineSchedulingAllowed:
          fields[`onlineSchedulingAllowed_id_${type.id}`].value,
        color: fields[`color_${type.id}`].value,
      })),
    },
    sectionName: PracticeDetailsPanels.APPOINTMENT_CONFIGURATION,
    validate,
    reset,
    resetDependencies: [AppointmentTypes, configuration],
  })

  const areAllItemsSelected = AppointmentTypes.every(
    (type) => fields[`enabled_id_${type.id}`].value,
  )

  const toggleSelectAll = () => {
    AppointmentTypes.forEach((type) =>
      fields[`enabled_id_${type.id}`].setValue(!areAllItemsSelected),
    )
  }

  return (
    <>
      <OccupyAllStaffTimeSlotSwitch businessId={business.id} />
      {!isAppointmentConfigHidden && (
        <Box>
          <Text strong my={1} variant="subheading3">
            {t(
              'Businesses:APPOINTMENT_CONFIGURATION.TYPE_AND_COLOR_CUSTOMIZATION',
            )}
          </Text>
          <Table className={classes.tableViewport}>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classNames(classes.typeCell, classes.tableHeading)}
                >
                  <TextWithTooltip
                    allowWrap
                    strong
                    tooltipText={t('Admin:PRACTICE.APPOINTMENT_TYPES_TOOLTIP')}
                    variant="lowAccent2"
                  >
                    {t('Admin:PRACTICE.AVAILABLE_APPOINTMENT_TYPES')}
                  </TextWithTooltip>
                  <Grid container item alignItems="center">
                    <PuiCheckbox
                      checkboxClasses={{
                        root: classes.checkbox,
                      }}
                      checked={areAllItemsSelected}
                      className={classes.checkboxLabel}
                      label={t('Common:SELECT_ALL')}
                      onClick={toggleSelectAll}
                    />
                  </Grid>
                </TableCell>
                <TableCell
                  className={classNames(
                    classes.tableHeading,
                    classes.colorTableCell,
                  )}
                >
                  <TextWithTooltip
                    allowWrap
                    strong
                    tooltipText={t('Admin:PRACTICE.COLOR_TOOLTIP')}
                    variant="lowAccent2"
                  >
                    {t('Admin:PRACTICE.COLOR')}
                  </TextWithTooltip>
                </TableCell>
                <TableCell className={classes.tableHeading}>
                  <TextWithTooltip
                    allowWrap
                    strong
                    tooltipText={t('Admin:PRACTICE.DEFAULT_DURATION_TOOLTIP')}
                    variant="lowAccent2"
                  >
                    {t('Admin:PRACTICE.DEFAULT_DURATION')}
                  </TextWithTooltip>
                  <Text mt={0.75} variant="body">
                    {t('Admin:PRACTICE.HOURS_MINUTES')}
                  </Text>
                </TableCell>
                <TableCell className={classes.tableHeading}>
                  <TextWithTooltip
                    allowWrap
                    strong
                    tooltipText={t(
                      'Admin:PRACTICE.ALLOW_CLIENT_SCHEDULING_TOOLTIP',
                    )}
                    variant="lowAccent2"
                  >
                    {t('Admin:PRACTICE.ALLOW_CLIENT_SCHEDULING')}
                  </TextWithTooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {AppointmentTypes.map((type) => {
                const enabled = fields[`enabled_id_${type.id}`]
                const enabledValue = enabled.value ?? true
                const defaultDuration = fields[`defaultDuration_id_${type.id}`]
                const onlineSchedulingAllowed =
                  fields[`onlineSchedulingAllowed_id_${type.id}`]
                const onlineSchedulingAllowedValue =
                  onlineSchedulingAllowed.value ?? true
                const color = fields[`color_${type.id}`]

                return (
                  <TableRow className={classes.tableRow} key={type.id}>
                    <TableCell className={classes.tableCell}>
                      <PuiCheckbox
                        checkboxClasses={{
                          root: classes.checkbox,
                        }}
                        className={classes.checkboxLabel}
                        field={{ ...enabled, value: enabledValue }}
                        label={LanguageUtils.getTranslatedFieldName(type)}
                      />
                    </TableCell>
                    <TableCell
                      className={classNames(
                        classes.tableCell,
                        classes.colorCell,
                      )}
                    >
                      {enabledValue && (
                        <ColorPicker
                          noColorValueEnabled
                          checkBoxLabel={t('Admin:PRACTICE.REMOVE_COLOR')}
                          field={color}
                          label={`${color.label}`}
                        />
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {enabledValue && (
                        <PuiTextField
                          InputProps={{
                            // @ts-ignore
                            inputComponent: DurationInput,
                          }}
                          className={classes.durationField}
                          field={defaultDuration}
                          margin="none"
                        />
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {enabledValue && (
                        <PuiSwitch
                          field={{
                            ...onlineSchedulingAllowed,
                            value: onlineSchedulingAllowedValue,
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      )}
    </>
  )
}

export default memo(AppointmentConfiguration, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
