import * as R from 'ramda'
import {
  Amount,
  AmountObj,
  NamedEntity,
  Nil,
  NumberUtils,
  Utils,
} from '@pbt/pbt-ui-components'

import {
  Charges,
  Invoice as GraphqlInvoice,
} from '~/api/graphql/generated/types'
import { PaymentTypes } from '~/constants/financeConstants'
import InvoiceType from '~/constants/InvoiceType'
import { PrescriptionType } from '~/constants/prescription'
import { RESTOCKABLE_TYPES } from '~/constants/refund'
import { OrderType } from '~/constants/SOAPStates'
import {
  AssignedSoapDetail,
  BasePrice,
  BatchInvoice,
  BundleItem,
  Estimate,
  Invoice,
  InvoiceLineItem,
  InvoiceLineItemGroup,
  InvoiceOrEstimate,
  InvoiceTotals,
  Order,
} from '~/types'
import { ChargeSheetLineItem } from '~/types/entities/chargesSheet'
import { arrayToMap } from '~/utils'
import { getInvoiceType } from '~/utils/refundUtils'

export function getLowValue(range: Amount | Nil) {
  return R.is(Object, range) ? (range as AmountObj).low || 0 : range || 0
}

export function getHighValue(range: Amount | Nil) {
  return R.is(Object, range) ? (range as AmountObj).high || 0 : range || 0
}

export const roundRange = (range: Amount, base: number): Amount => {
  const low = Utils.round(getLowValue(range), base) as number
  const high = Utils.round(getHighValue(range), base) as number

  return low === high ? low : ({ low, high } as AmountObj)
}

export const multRange = (
  range: Amount | Nil,
  coefficient: number,
): Amount | number => {
  const low = getLowValue(range)
  const high = getHighValue(range)
  const rawRange =
    low === high
      ? low * coefficient
      : {
          low: low * coefficient,
          high: high * coefficient,
        }

  return roundRange(rawRange, 4)
}

export const aggregationOverRange = (
  rangeA: Amount | Nil,
  rangeB: Amount | Nil,
  aggregator: Function,
): Amount => {
  const low = aggregator(getLowValue(rangeA), getLowValue(rangeB))
  const high = aggregator(getHighValue(rangeA), getHighValue(rangeB))
  return low === high ? low : { low, high }
}

export const addRange = (rangeA: Amount | Nil, rangeB: Amount | Nil) =>
  aggregationOverRange(rangeA, rangeB, R.add)

export const subtractRange = (rangeA: Amount | Nil, rangeB: Amount | Nil) =>
  aggregationOverRange(rangeA, rangeB, R.subtract)

export const minRange = (rangeA: Amount | Nil, rangeB: Amount | Nil) =>
  aggregationOverRange(rangeA, rangeB, Math.min)

export const maxRange = (rangeA: Amount | Nil, rangeB: Amount | Nil) =>
  aggregationOverRange(rangeA, rangeB, Math.max)

export const isRange = (range: Amount | Nil) =>
  Number(getLowValue(range)) !== Number(getHighValue(range))

export const setLowValue = (
  range: Amount | Nil,
  low: number,
  normalize?: boolean,
): Amount => {
  const high = normalize
    ? Math.max(getHighValue(range), low)
    : getHighValue(range)

  return low === high ? low : { low, high }
}

export const setHighValue = (
  range: Amount | Nil,
  high: number,
  normalize?: boolean,
): Amount => {
  const low = normalize
    ? Math.min(getLowValue(range), high)
    : getLowValue(range)

  return low === high ? low : { low, high }
}

export function isEveryItemOfSingleQuantity(
  items: (InvoiceLineItem | BundleItem)[],
): boolean {
  return items.every((item) =>
    R.has('items', item)
      ? isEveryItemOfSingleQuantity((item as InvoiceLineItem).items || [])
      : !isRange(item.quantity),
  )
}

export function isEveryGroupOfSingleQuantity(groups: InvoiceLineItemGroup[]) {
  return groups.every((group) =>
    isEveryItemOfSingleQuantity(group.groupedItems),
  )
}

function sumUpRangeProps(items: InvoiceLineItem[], getProp: (arg: any) => any) {
  return items.reduce(
    (acc, currentItem) => addRange(acc, getProp(currentItem)),
    0 as Amount,
  )
}

export function getExtendedPrice(item: any, base = 5): Amount {
  const { quantity = 1, declined } = item

  if (declined) {
    return 0
  }

  const itemPrice = item.price as BasePrice
  const minCharge = itemPrice?.minCharge ?? item.minCharge ?? 0
  const dispensingFee = itemPrice?.dispensingFee ?? item.dispensingFee ?? 0
  const price = itemPrice?.price ?? item.price ?? 0

  const quantityPrice = roundRange(multRange(quantity, price), base)
  const chargePrice = maxRange(minCharge, quantityPrice)

  if (item.items) {
    return sumUpRangeProps(item.items, (currentItem) =>
      getExtendedPrice(currentItem, base),
    )
  }

  const priceWithFee = addRange(chargePrice, dispensingFee)

  if (getLowValue(quantity) === 0) {
    return getHighValue(quantity) === 0 ? 0 : setLowValue(priceWithFee, 0)
  }

  return priceWithFee
}

export const discountPercToRangeAmount = (item: InvoiceLineItem | BundleItem) =>
  roundRange(multRange(getExtendedPrice(item), item.discountPerc!), 2)

export const discountPercToAmount = (item: InvoiceLineItem | BundleItem) =>
  getLowValue(discountPercToRangeAmount(item))

export const discountAmountToRangePercent = (
  item: InvoiceLineItem | BundleItem,
) => {
  const price = getExtendedPrice(item)
  const percentRangeLowValue = setLowValue(
    0,
    getLowValue(price) > 0 ? item.discountAmount! / getLowValue(price) : 0,
  )
  const percentRange = setHighValue(
    percentRangeLowValue,
    getHighValue(price) > 0 ? item.discountAmount! / getHighValue(price) : 0,
  )
  return minRange(percentRange, 1)
}

export const getDiscountAmount = (item: InvoiceLineItem) => {
  const amount = item.discountPerc
    ? (item.discountPerc && discountPercToRangeAmount(item)) || 0
    : item.discountAmount
  return item.items ? sumUpRangeProps(item.items, getDiscountAmount) : amount
}

export const getDiscountPerc = (item: InvoiceLineItem | BundleItem) => {
  const perc =
    item.discountPerc ||
    (item.discountAmount && discountAmountToRangePercent(item)) ||
    0

  return R.has('items', item) && item.items
    ? null
    : roundRange(multRange(perc, 100), 2)
}

export function getLimitedDiscountPercent(item: InvoiceLineItem) {
  return Math.min(getLowValue(getDiscountPerc(item)), 100)
}

export function getLimitedDiscountAmount(item: any): Amount {
  return item.items
    ? sumUpRangeProps(
        item.items,
        (currentItem) => getLimitedDiscountAmount(currentItem) || 0,
      )
    : minRange(getDiscountAmount(item), getExtendedPrice(item))
}

export function getLimitedDiscountAmountWithoutBundleDiscount(
  item: any,
): Amount {
  const discount = item.items
    ? sumUpRangeProps(
        item.items,
        (currentItem) => getLimitedDiscountAmount(currentItem) || 0,
      )
    : minRange(getDiscountAmount(item), getExtendedPrice(item))
  return addRange(discount, item.items ? item.additionalDiscount || 0 : 0)
}

export function getDiscountedPrice(item: any) {
  const extendedPrice = getExtendedPrice(item)
  const limitedDiscountAmount = getLimitedDiscountAmount(item)

  return subtractRange(extendedPrice, limitedDiscountAmount)
}

export function getTaxAmount(
  item: InvoiceLineItem | ChargeSheetLineItem,
  invoice: BatchInvoice | InvoiceOrEstimate,
): Amount {
  const { taxRate, taxed } = item
  return item.items
    ? sumUpRangeProps(item.items, (currentItem) =>
        getTaxAmount(currentItem, invoice),
      )
    : taxed
    ? roundRange(
        multRange(
          getDiscountedPrice(item),
          taxRate ?? invoice.business?.taxRateTotal ?? 0,
        ),
        2,
      )
    : 0
}

export function getItemCoveredByWplan(item: InvoiceLineItem) {
  return item.wplanCharged === false
}

export function getTotal(
  item: InvoiceLineItem,
  invoice: BatchInvoice | InvoiceOrEstimate,
): Amount {
  const discountedPrice = getDiscountedPrice(item)
  const taxAmount = getTaxAmount(item, invoice)

  return item.items
    ? sumUpRangeProps(item.items, (currentItem) =>
        getTotal(currentItem, invoice),
      )
    : getItemCoveredByWplan(item)
    ? 0
    : roundRange(addRange(discountedPrice, taxAmount), 2)
}

export function getInvoiceItemCalculatedFields(
  item: InvoiceLineItem,
  invoice: BatchInvoice | InvoiceOrEstimate,
) {
  const extendedPrice = getExtendedPrice(item)
  const limitedDiscountAmount = getLimitedDiscountAmount(item)
  const limitedDiscountPercent = getLimitedDiscountPercent(item)
  const taxAmount = getTaxAmount(item, invoice)
  const total = getTotal(item, invoice)

  return {
    extendedPrice,
    discountAmount: limitedDiscountAmount,
    discountPerc: limitedDiscountPercent / 100,
    taxAmount,
    total,
  }
}

export const getMoneyRange = (value: Amount | Nil) =>
  `${NumberUtils.formatMoney(getLowValue(value))}-${NumberUtils.formatMoney(
    getHighValue(value),
  )}`

export function formatPositiveMoney(value: number | Nil) {
  return NumberUtils.formatMoney(Math.abs(value ?? 0))
}

export function formatMoneyRange(
  value: Amount | Nil,
  convertToNegative = false,
) {
  const defaultValue = getLowValue(value)

  return isRange(value)
    ? convertToNegative
      ? `(${getMoneyRange(value)})`
      : getMoneyRange(value)
    : NumberUtils.formatMoney(
        convertToNegative && defaultValue > 0 ? -defaultValue : defaultValue,
      )
}

const getPriceId = (item: InvoiceLineItem) =>
  R.path(['price', 'id'], item) || R.prop('priceId', item)
export const getLineItemUniqId = (item: InvoiceLineItem) =>
  `${R.prop('logType', item)}-${getPriceId(item)}-${R.prop('group', item)}`

// TODO: need to refactor and cover with tests
export function mergeItemsWithQuantitySum(
  array1: any[],
  array2: any[],
  idFieldOrGetter: string | Function = 'priceId',
): any[] {
  const getId =
    typeof idFieldOrGetter === 'function'
      ? idFieldOrGetter
      : R.prop(idFieldOrGetter)

  return Object.values(
    array1.concat(array2).reduce((acc, item) => {
      const id = getId(item)
      const oldItem = acc[id]
      const oldQuantity = oldItem ? oldItem.quantity : 0
      const oldUsedQuantity = oldItem?.usedQuantity
      const quantity = addRange(oldQuantity, item.quantity)
      const prepaid = oldItem?.prepaid || item.prepaid
      const usedQuantity = R.isNil(oldUsedQuantity)
        ? R.isNil(item.usedQuantity)
          ? null
          : item.usedQuantity
        : R.isNil(item.usedQuantity)
        ? oldUsedQuantity
        : oldUsedQuantity + item.usedQuantity

      return {
        ...acc,
        [id]: {
          ...(oldItem || item),
          quantity,
          ...(R.isNil(usedQuantity) ? {} : { usedQuantity }),
          ...(prepaid ? { prepaid } : {}),
        },
      }
    }, {}),
  )
}

export const deleteItemByPrice = (oldItems: any[] = [], newItems: any[] = []) =>
  R.filter(
    (item) =>
      !newItems.some(R.pathEq(['price', 'id'], R.path(['price', 'id'], item))),
    oldItems,
  )

export const markManualDiscountSource = (
  DiscountSource: NamedEntity[],
  lineItem: InvoiceLineItem,
) => {
  lineItem.discountSourceId = Utils.findByName('Manual', DiscountSource)?.id
}

export const discountAmountFieldChanged = (
  item: InvoiceLineItem | BundleItem,
  newItem: InvoiceLineItem | BundleItem,
) => {
  newItem.discountPerc = null
}

export const producerFieldChanged = (
  item: InvoiceLineItem,
  newItem: InvoiceLineItem,
) => {
  newItem.producerIdChanged = true
}

export const discountPercFieldChanged = (
  item: InvoiceLineItem | BundleItem,
  newItem: InvoiceLineItem | BundleItem,
) => {
  ;(newItem.discountPerc as number) /= 100
  newItem.discountAmount = null
}

export const calculatePrepaidColumns = (
  item: InvoiceLineItem | BundleItem | ChargeSheetLineItem,
  newItem: InvoiceLineItem | BundleItem,
  isEstimate?: boolean,
) => {
  const hasId = Boolean(R.has('id', item) && item.id)
  const diffPaidQuantity = newItem.quantity - item.quantity
  const diffUsedQuantity =
    Number(newItem.usedQuantity) - Number(item.usedQuantity)
  const updatedPrepaidRemaining = Number(
    (
      (newItem?.prepaidRemaining || 0) +
      diffPaidQuantity -
      diffUsedQuantity
    ).toFixed(2),
  )
  if (isEstimate && !hasId && newItem.quantity < Number(newItem.usedQuantity)) {
    newItem.usedQuantity = newItem.quantity
  }
  if (!isEstimate || hasId) {
    if (updatedPrepaidRemaining < 0) {
      newItem.prepaidRemaining = 0
      newItem.quantity -= updatedPrepaidRemaining
    } else {
      newItem.prepaidRemaining = updatedPrepaidRemaining
    }
  }
}

export const quantityFieldChanged = (
  item: InvoiceLineItem | BundleItem,
  newItem: InvoiceLineItem | BundleItem,
  isEstimate?: boolean,
) => {
  newItem.extendedPrice = getExtendedPrice({
    ...newItem,
    price: item.price,
  }) as number
  if (!isRange(newItem.quantity)) {
    if (item.discountPerc) {
      newItem.discountAmount = discountPercToAmount(newItem)
    } else if (item.discountAmount) {
      newItem.discountPerc = getLowValue(discountAmountToRangePercent(newItem))
    }
  } else if (item.discountPerc) {
    newItem.discountAmount = null
  }
  if (item.prepaid) {
    calculatePrepaidColumns(item, newItem, isEstimate)
  }
}

export const prepaidFieldChanged = (
  item: InvoiceLineItem | BundleItem,
  newItem: InvoiceLineItem | BundleItem,
) => {
  if (newItem.prepaid) {
    newItem.usedQuantity = 1
  } else {
    newItem.usedQuantity = null
  }
}

export const dropPrePaidForItem = ({
  prepaid,
  usedQuantity,
  ...orderedItem
}: InvoiceLineItem | BundleItem) => ({
  ...orderedItem,
  quantity:
    orderedItem.quantity > 0
      ? orderedItem.quantity
      : Number(usedQuantity) > 0
      ? usedQuantity
      : 1,
})

export const dropPrepaidState = R.map(dropPrePaidForItem)

export const restorePrePaidForItem = (orderedItem: Order) => ({
  ...orderedItem,
  ...(Number(orderedItem.prepaidBox?.remaining) > 0
    ? {
        prepaid: true,
        usedQuantity: 0,
      }
    : {}),
})

export const restorePrepaidState = R.map(restorePrePaidForItem)

export const insertCandidatesIntoGroup = (
  candidates: InvoiceLineItem[],
  group: InvoiceLineItemGroup,
) => {
  const newGroup = R.clone(group)

  candidates.forEach((item) => {
    const isGrouped = Boolean(item.group)

    if (isGrouped) {
      const groupIndex = newGroup.groupedItems.findIndex(
        (groupedItem: InvoiceLineItem) => groupedItem.group === item.group,
      )

      if (groupIndex > -1) {
        newGroup.groupedItems[groupIndex].items = mergeItemsWithQuantitySum(
          newGroup.groupedItems[groupIndex].items || [],
          [item],
          getLineItemUniqId,
        )
      } else {
        newGroup.groupedItems.push({
          group: item.group,
          groupName: item.groupName,
          items: item.items || [item],
        } as InvoiceLineItem)
      }
    } else {
      newGroup.groupedItems = mergeItemsWithQuantitySum(
        newGroup.groupedItems,
        [item],
        getLineItemUniqId,
      )
    }
  })

  return newGroup
}

const isDefinitelyZero = (number: number | Nil) => Number(number) === 0

export const getItemHasZeroQuantity = (item: InvoiceLineItem) =>
  item.items
    ? item.items.some(getItemHasZeroQuantity)
    : item.prepaid
    ? isDefinitelyZero(item.quantity) && isDefinitelyZero(item.usedQuantity)
    : isDefinitelyZero(item.quantity)

export const getItemsHaveZeroQuantity = (groups: InvoiceLineItemGroup[]) =>
  groups.some((group) => group.groupedItems.some(getItemHasZeroQuantity))

export const restrictUsedQuantity = (
  usedQuantity: number,
  item: Order | BundleItem,
) =>
  Math.min(
    usedQuantity,
    getLowValue(item.quantity) + ((item as Order).prepaidBox?.remaining || 0),
  )

export const updateItemsToUseSingleQuantity = (
  items: InvoiceLineItem[],
): InvoiceLineItem[] =>
  items.map((item) =>
    item.items
      ? { ...item, items: updateItemsToUseSingleQuantity(item.items) }
      : { ...item, quantity: Math.max(getLowValue(item.quantity), 1) },
  )

export const mergeOrderedItems = R.curry(
  (
    getUniqId: (item: Order) => string,
    newOrderedItems: Order[],
    prevOrderedItems: Order[],
  ) => {
    const orderedItemsMap = arrayToMap(prevOrderedItems, getUniqId)
    newOrderedItems.forEach((newItem) => {
      const itemId = getUniqId(newItem)
      const oldItem = orderedItemsMap[itemId]
      orderedItemsMap[itemId] = R.mergeRight(oldItem, newItem)
    })

    return R.values(orderedItemsMap)
  },
)

export const getGroupId = (group: InvoiceLineItemGroup) =>
  group.soap?.id || 'otc'

export const getIsBatchInvoice = (
  invoice: BatchInvoice | InvoiceOrEstimate | GraphqlInvoice | Nil,
) => Boolean(invoice?.invoices && invoice.invoices.length > 0)

export const getInvoiceDueToPayNoFee = (
  invoice: BatchInvoice | InvoiceOrEstimate | GraphqlInvoice | Nil,
) =>
  getIsBatchInvoice(invoice)
    ? R.sum(R.pluck('dueToPayNoFee', (invoice as BatchInvoice)?.invoices))
    : invoice?.dueToPayNoFee

export const getInvoiceAmountNoFee = (
  invoice: BatchInvoice | InvoiceOrEstimate,
) =>
  getIsBatchInvoice(invoice)
    ? R.sum(R.pluck('amountNoFee', (invoice as BatchInvoice).invoices))
    : invoice.amountNoFee

export const getInvoiceTotals = (invoice: BatchInvoice | InvoiceOrEstimate) => {
  const groups = getIsBatchInvoice(invoice)
    ? R.flatten(R.pluck('groups', (invoice as BatchInvoice)?.invoices))
    : invoice?.groups || []

  const flatItems = groups.reduce(
    (acc, group) =>
      acc.concat(group.groupedItems.filter((item) => !item.declined)),
    [] as InvoiceLineItem[],
  )
  const totals = flatItems.reduce(
    (acc: Partial<InvoiceTotals>, item) => {
      const { discountAmount, discountPerc, total, extendedPrice, taxAmount } =
        getInvoiceItemCalculatedFields(item, invoice)
      const { subTotal, totalDiscount, totalTax } = acc
      const itemDiscount =
        discountAmount ||
        (discountPerc && multRange(extendedPrice, discountPerc)) ||
        0
      return {
        subTotal: addRange(subTotal, total),
        totalDiscount: roundRange(addRange(totalDiscount, itemDiscount), 2),
        totalTax: addRange(totalTax, taxAmount),
      }
    },
    {
      subTotal: 0,
      totalDiscount: 0,
      totalTax: 0,
    },
  ) as InvoiceTotals

  const additionalDiscountPiece = maxRange(
    addRange(
      invoice.additionalDiscount || 0,
      invoice.bundleAdditionalDiscount || 0,
    ),
    0,
  )

  const totalDiscountWithAdditional = addRange(
    totals.totalDiscount,
    additionalDiscountPiece,
  )
  const totalWithoutFee = maxRange(
    subtractRange(totals.subTotal, additionalDiscountPiece),
    0,
  )
  totals.totalWithoutFee = totalWithoutFee
  totals.totalWithoutTax = maxRange(
    addRange(
      totals.totalDiscount,
      subtractRange(totals.subTotal, totals.totalTax),
    ),
    0,
  )
  totals.totalDiscountWithAdditional =
    totalDiscountWithAdditional || totals.totalDiscount

  totals.serviceFee = multRange(totalWithoutFee, invoice.serviceFee || 0)
  totals.total = addRange(totalWithoutFee, totals.serviceFee)

  const hasDeposit = !R.isNil(invoice.requiredDeposit)

  totals.requiredDepositWithoutFee = hasDeposit
    ? getLowValue(totals.totalWithoutFee) * invoice.requiredDeposit
    : 0
  totals.requiredDeposit = hasDeposit
    ? getLowValue(totals.total) * invoice.requiredDeposit
    : 0

  return totals
}

export const getInvoiceDate = (
  invoice: InvoiceOrEstimate | Nil | GraphqlInvoice,
) =>
  invoice?.invoiceDate ||
  (invoice as InvoiceOrEstimate)?.event?.scheduledStartDatetime

export const isGroupedInvoiceItem = ({
  items,
}: InvoiceLineItem | ChargeSheetLineItem | Order) => !R.isEmpty(items || [])

export const isUnrefundable = (lineItem: InvoiceLineItem) =>
  lineItem.totalRefundEligibleQty === 0

export const canRestockInvoiceItem = (item: InvoiceLineItem) =>
  RESTOCKABLE_TYPES.includes(item.logType) &&
  Number(item.totalRestockEligibleQty) > 0 &&
  !item.inventoryControlled &&
  // Only in-house prescriptions are restockable, skip this condition if not a prescription item
  (item.logType === OrderType.PRESCRIPTION
    ? item.prescriptionType === PrescriptionType.IN_HOUSE
    : true)

export const getInvoiceGroupedItemsBySoapId = (
  invoice: InvoiceOrEstimate | Nil,
  soapId: string | Nil,
) =>
  invoice?.groups?.find(
    (group) =>
      group?.soap?.id === soapId ||
      group?.groupedItems.some((groupedItem) =>
        groupedItem.items
          ? groupedItem.items.some(
              (bundleChildItem) => bundleChildItem.soapId === soapId,
            )
          : groupedItem.soapId === soapId,
      ),
  )?.groupedItems || []

export const setInvoiceGroupedItemsBySoapId = (
  invoice: InvoiceOrEstimate,
  soapId: string | Nil,
  newGroupedItems: InvoiceLineItem[],
): InvoiceOrEstimate => ({
  ...invoice,
  groups: invoice?.groups.map((group, index) => {
    if ((!soapId && index === 0) || group?.soap?.id === soapId) {
      return {
        ...group,
        groupedItems: newGroupedItems,
      }
    }
    return group
  }),
})

export const getUsedQuantityBlocked = (
  invoice: InvoiceOrEstimate | BatchInvoice | Nil,
  item: InvoiceLineItem,
) => {
  const originalItem = Utils.findById(
    item.id,
    R.flatten(R.pluck('groupedItems', invoice?.groups || [])),
  )
  return (
    originalItem?.prepaid &&
    originalItem.soapId &&
    originalItem.usedQuantity === 0
  )
}

export const getInvoiceGroupSubTotal = (items: InvoiceLineItem[]) =>
  items.reduce((total, item) => {
    if (!item.declined) {
      total += item.subTotal || 0
      total +=
        item.items && item.subTotal === 0
          ? getInvoiceGroupSubTotal(item.items)
          : 0
    }
    return total
  }, 0)

export const getRecordPaymentProps = (
  invoice: InvoiceOrEstimate | BatchInvoice | Nil,
  includeServiceFee?: boolean,
) => ({
  invoiceAmount: getInvoiceDueToPayNoFee(invoice),
  assignedInvoiceId: invoice?.id,
  invoiceIds: R.pluck('id', (invoice?.invoices as Invoice[]) || []),
  paymentType: includeServiceFee
    ? PaymentTypes.CREDIT_CARD
    : PaymentTypes.OTHER,
})

export const getRecordDepositProps = (
  invoice: InvoiceOrEstimate | BatchInvoice | Nil,
  includeServiceFee?: boolean,
) => ({
  ...getRecordPaymentProps(invoice, includeServiceFee),
  paymentTypeName: 'Deposit',
})

export const getIsEstimateAttachedToThisSoap = (
  estimate: Estimate | Nil,
  soapId: string | Nil,
) => {
  if (!soapId || !estimate?.assignedSoapDetails?.length) {
    return false
  }
  return estimate?.assignedSoapDetails?.some(
    (soap: AssignedSoapDetail) => soap?.soapId === soapId,
  )
}

export const isUnpostDisabled = (invoice: Invoice | Charges) =>
  !R.isNil(invoice) &&
  !R.isNil(R.prop('payments', invoice)) &&
  !R.isNil(R.path(['payments', 'length'], invoice)) &&
  (R.path(['payments', 'length'], invoice) as number) > 0

export const isAnyItemPrepaid = (
  invoiceLineItems: InvoiceLineItem[],
): boolean =>
  invoiceLineItems.some(
    (ili) =>
      (ili.prepaid && !ili.declined) ||
      (!R.isNil(ili.items) &&
        ili.items.some((subIli) => subIli.prepaid && !subIli.declined)),
  )

export const calculateInvoiceTotal = (invoices: Invoice[]) =>
  invoices
    .filter(
      (invoice) => getInvoiceType(invoice.invoiceNo) === InvoiceType.INVOICE,
    )
    .reduce(
      (total: number, invoice) =>
        total + Math.abs(R.prop('paidAmountNoFee', invoice) ?? 0),
      0,
    )
