import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  LanguageUtils,
  PuiTheme,
  StateLabel,
  StateLabelProps,
  Utils,
} from '@pbt/pbt-ui-components'

import { ColorNames } from '~/constants/colors'
import { LabOrdersStatus } from '~/constants/SOAPStates'
import { getDashboardLabTestStates } from '~/store/reducers/constants'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    stateLabel: {
      width: 'fit-content',
      textAlign: 'center',
      margin: theme.spacing(1, 1, 1, 0),
    },
  }),
  { name: 'LabOrderStatusLabel' },
)

export interface LabOrderStatusLabelProps extends StateLabelProps {
  stateId: string
}

const LabOrderStatusLabel = ({
  stateId,
  ...rest
}: LabOrderStatusLabelProps) => {
  const classes = useStyles()
  const DashboardLabTestStates = useSelector(getDashboardLabTestStates)
  const orderState = Utils.findByProp(
    stateId,
    DashboardLabTestStates,
    'orderStateId',
  )

  if (!orderState) {
    return null
  }

  return (
    <Grid container alignItems="center" direction="column">
      <StateLabel
        className={classes.stateLabel}
        completed={orderState.name === LabOrdersStatus.COMPLETED}
        deleted={orderState.color === ColorNames.RED}
        disabled={orderState.color === ColorNames.GREY}
        success={
          orderState.color === ColorNames.GREEN &&
          orderState.name !== LabOrdersStatus.COMPLETED
        }
        variant="small"
        warning={orderState.color === ColorNames.YELLOW}
        {...rest}
      >
        {LanguageUtils.getTranslatedFieldName(orderState)}
      </StateLabel>
    </Grid>
  )
}

export default LabOrderStatusLabel
