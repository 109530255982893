import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Grid, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { includes } from 'ramda'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import ChooseColumnsPopper from '~/components/common/ChooseColumnsPopper'
import { Invoice } from '~/types'

import {
  ColumnNames,
  getInvoiceSelectableColumns,
  HeadColumnComponents,
} from '../../../invoiceTableConstants'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    subHeaderCell: {
      paddingTop: 0,
      paddingBottom: 0,
      color: theme.colors.tabLabel,
      fontSize: '1.4rem',
    },
    columnsContainer: {
      pointerEvents: 'auto',
      position: 'relative',
      cursor: 'pointer',
    },
    borderCell: {
      borderRight: theme.constants.tabBorder,
    },
    hidden: {
      visibility: 'hidden',
    },
    borderBottom: {
      borderBottom: theme.constants.tabBorder,
    },
    tableCell: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    headerCell: {
      paddingTop: 0,
      paddingBottom: 0,
      height: 24,
      color: theme.colors.tabLabel,
      fontSize: '1.4rem',
      fontWeight: 500,
      borderBottom: 'none',
    },
    estimateHeaderCell: {
      fontWeight: 400,
    },
  }),
  { name: 'InvoiceTableHeadRow' },
)

export interface InvoiceTableHeadRowProps {
  invoice: Invoice
  isEstimate: boolean
  onPopperApplyToAll: (fieldName: string, value: boolean | '') => void
  onPopperClose: (event: React.MouseEvent<HTMLDivElement>) => void
  openPopperId: string | null
  prePaidEnabled: boolean
  rangeEnabled: boolean
  selectedColumns: string[]
  setRangeEnabled: (value: boolean) => void
  setSelectedColumns: (columns: string[]) => void
  toggleApplierPopperOpen: (event: React.MouseEvent<HTMLDivElement>) => void
  updateInvoice: (invoice: Invoice) => void
}

const InvoiceTableHeadRow = ({
  isEstimate,
  rangeEnabled,
  setSelectedColumns,
  selectedColumns,
  ...rest
}: InvoiceTableHeadRowProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const [columnsPopperOpen, setColumnsPopperOpen] = useState(false)

  const columnsContainerRef = useRef<HTMLDivElement>(null)

  const showPriceColumn = includes(ColumnNames.UNIT_PRICE, selectedColumns)

  const toggleColumnPopperOpen = () => {
    setColumnsPopperOpen(!columnsPopperOpen)
  }

  return (
    <TableRow>
      <TableCell
        className={classNames(classes.tableCell, classes.headerCell, {
          [classes.borderCell]: !showPriceColumn || isEstimate,
        })}
        rowSpan={isEstimate && rangeEnabled ? 3 : 2}
      >
        <Grid
          container
          item
          alignItems="center"
          className={classes.columnsContainer}
          ref={columnsContainerRef}
          onClick={toggleColumnPopperOpen}
        >
          <Text underline variant="body2">
            {t('Common:CHOOSE_COLUMNS')}
          </Text>
          {columnsPopperOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <ChooseColumnsPopper
            anchorEl={columnsContainerRef.current}
            open={columnsPopperOpen}
            possibleColumns={getInvoiceSelectableColumns(isEstimate)}
            value={selectedColumns}
            onChange={({ value }) => setSelectedColumns(value)}
            onClose={(event) => {
              if (
                !event?.target ||
                !columnsContainerRef.current?.contains(event.target)
              ) {
                setColumnsPopperOpen(false)
              }
            }}
          />
        </Grid>
      </TableCell>
      {selectedColumns.map((column) => {
        const CellComponent = HeadColumnComponents[column]

        if (!CellComponent) {
          return null
        }

        return (
          <CellComponent
            className={classNames(
              classes.tableCell,
              classes.borderCell,
              classes.headerCell,
            )}
            isEstimate={isEstimate}
            key={column}
            rangeEnabled={rangeEnabled}
            {...rest}
          />
        )
      })}
    </TableRow>
  )
}

export default InvoiceTableHeadRow
