import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ButtonWithLoader,
  CircularProgressOverlay,
  PuiTheme,
  Text,
  useInterval,
} from '@pbt/pbt-ui-components'

import { MIGRATION_STATUS_POLLING_INTERVAL } from '~/constants/migration'
import i18n from '~/locales/i18n'
import {
  fetchPreprocessingStatus,
  startMigrationAnalyze,
} from '~/store/actions/migration'
import {
  getCurrentSessionId,
  getIsLoadingStartImport,
  getPreprocessingStatus,
} from '~/store/reducers/migration'

import CoreMigrationProgressTable from './CoreMigrationProgressTable'

const PreprocessingStatusesMap = {
  IN_QUEUE: 'IN_QUEUE',
  STARTING: 'STARTING',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  DONE: 'DONE',
}

const TextStatusesMap = {
  [PreprocessingStatusesMap.IN_QUEUE]: i18n.t(
    'Businesses:MIGRATION.PREPROCESSING_STATUSES.IN_QUEUE',
  ),
  [PreprocessingStatusesMap.STARTING]: i18n.t(
    'Businesses:MIGRATION.PREPROCESSING_STATUSES.STARTING',
  ),
  [PreprocessingStatusesMap.IN_PROGRESS]: i18n.t(
    'Businesses:MIGRATION.PREPROCESSING_STATUSES.IN_PROGRESS',
  ),
  [PreprocessingStatusesMap.FAILED]: i18n.t(
    'Businesses:MIGRATION.PREPROCESSING_STATUSES.FAILED',
  ),
  [PreprocessingStatusesMap.DONE]: i18n.t(
    'Businesses:MIGRATION.PREPROCESSING_STATUSES.DONE',
  ),
}

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      backgroundColor: theme.colors.contentBackground,
      padding: theme.spacing(3),
    },
    container: {
      border: theme.constants.tableBorder,
      height: 320,
      overflow: 'auto',
      position: 'relative',
    },
    button: {
      height: 40,
      minWidth: 150,
      marginTop: theme.spacing(2),
    },
    statusContainer: {
      height: theme.spacing(5),
      borderBottom: theme.constants.tableBorder,
      padding: theme.spacing(0, 2),
    },
    statusText: {
      marginLeft: theme.spacing(1),
    },
    spinner: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(-0.5),
    },
    tableContainer: {
      padding: theme.spacing(1),
      maxHeight: `calc(100% - ${theme.spacing(5)})`,
      overflowY: 'auto',
    },
    failed: {
      color: theme.colors.errorColor,
    },
    done: {
      color: theme.colors.primaryText,
    },
  }),
  { name: 'StepPreprocessingInProgress' },
)

interface StepPreprocessingInProgressProps {
  isEnterprise: boolean
}

const StepPreprocessingInProgress = ({
  isEnterprise,
}: StepPreprocessingInProgressProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const sessionId = useSelector(getCurrentSessionId)
  const { status, entities } = useSelector(getPreprocessingStatus)
  const isLoading = useSelector(getIsLoadingStartImport)

  const isInQueue = status === PreprocessingStatusesMap.IN_QUEUE
  const isStarting = status === PreprocessingStatusesMap.STARTING
  const isInProgress = status === PreprocessingStatusesMap.IN_PROGRESS
  const isFailed = status === PreprocessingStatusesMap.FAILED
  const isDone = status === PreprocessingStatusesMap.DONE
  const textStatus = status
    ? t('Businesses:MIGRATION.PREPROCESSING_STATUS', {
        status: TextStatusesMap[status],
      })
    : t('Businesses:MIGRATION.LOADING_STATUS')
  const isSpinnerShown = isInQueue || isStarting || isInProgress

  const fetchStatus = useCallback(() => {
    if (sessionId) {
      dispatch(fetchPreprocessingStatus(sessionId))
    }
  }, [sessionId])

  useEffect(() => {
    fetchStatus()
  }, [])

  useInterval(() => {
    if (!isDone) {
      fetchStatus()
    }
  }, MIGRATION_STATUS_POLLING_INTERVAL)

  const handleStartAnalysis = () => {
    dispatch(startMigrationAnalyze(sessionId, isEnterprise))
  }

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.container}>
        {!status ? (
          <CircularProgressOverlay open preloaderText={textStatus} />
        ) : (
          <>
            <Grid
              container
              alignItems="center"
              className={classes.statusContainer}
            >
              <Text>
                {t('Common:STATUS')}:
                <span
                  className={classNames(classes.statusText, {
                    [classes.failed]: isFailed,
                    [classes.done]: isDone,
                  })}
                >
                  {textStatus}
                </span>
                {isSpinnerShown && (
                  <CircularProgress className={classes.spinner} size={20} />
                )}
              </Text>
            </Grid>
            <Grid container className={classes.tableContainer}>
              <CoreMigrationProgressTable
                countKey="count"
                rows={entities || []}
              />
            </Grid>
          </>
        )}
      </Grid>
      <ButtonWithLoader
        className={classes.button}
        disabled={!isDone}
        loading={isLoading}
        onClick={handleStartAnalysis}
      >
        {t('Businesses:MIGRATION.START_ANALYSIS')}
      </ButtonWithLoader>
    </Grid>
  )
}

export default StepPreprocessingInProgress
