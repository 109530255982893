import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Defaults, Nil } from '@pbt/pbt-ui-components'

import { InventoryItem, TableFilter } from '~/types'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import {
  CREATE_INVENTORY,
  CREATE_INVENTORY_FAILURE,
  CREATE_INVENTORY_SUCCESS,
  DELETE_INVENTORY,
  DELETE_INVENTORY_FAILURE,
  DELETE_INVENTORY_SUCCESS,
  EDIT_INVENTORY,
  EDIT_INVENTORY_FAILURE,
  EDIT_INVENTORY_SUCCESS,
  FETCH_INVENTORIES_LIST,
  FETCH_INVENTORIES_LIST_FAILURE,
  FETCH_INVENTORIES_LIST_SUCCESS,
  FETCH_INVENTORY,
  FETCH_INVENTORY_FAILURE,
  FETCH_INVENTORY_SUCCESS,
  FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST,
  FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST_SUCCESS,
  RESET_INVENTORIES,
  SET_INVENTORIES_LIST_FILTERS,
  UPDATE_INVENTORIES,
} from '../actions/types/inventories'
import {
  CREATE_VARIATION_SUCCESS,
  CREATE_VARIATIONS_SUCCESS,
  DELETE_VARIATION_SUCCESS,
  UPDATE_VARIATION_SUCCESS,
} from '../actions/types/variations'
import type { RootState } from '../index'

export type InventoriesState = {
  error: string | null
  filters: Record<string, TableFilter>
  isDeleting: boolean
  isFetching: boolean
  isLoading: boolean
  isReceiving: boolean
  isSending: boolean
  lastCreatedInventory: InventoryItem | null
  list: string[]
  map: Record<string, InventoryItem>
  totalCount: number
  validationError: string | null
}

export const INITIAL_STATE: InventoriesState = {
  map: {},
  list: [],
  isReceiving: false,
  isLoading: false,
  isSending: false,
  isDeleting: false,
  isFetching: false,
  error: null,
  lastCreatedInventory: null,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  filters: {},
  validationError: null,
}

const inventories = (
  state: InventoriesState = INITIAL_STATE,
  action: AnyAction,
): InventoriesState => {
  switch (action.type) {
    case RESET_INVENTORIES:
      return INITIAL_STATE
    case FETCH_INVENTORIES_LIST:
      return {
        ...state,
        isReceiving: true,
        list: [],
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
      }
    case FETCH_INVENTORIES_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isReceiving: false,
      }
    case FETCH_INVENTORIES_LIST_SUCCESS:
      return {
        ...state,
        list: action.list,
        totalCount: action.totalCount,
        isReceiving: false,
      }
    case FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST:
      return { ...state, isReceiving: true }
    case FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isReceiving: false,
      }
    case FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isReceiving: false,
        totalCount: action.totalCount,
      }
    case CREATE_INVENTORY:
      return { ...state, isSending: true, error: null }
    case CREATE_INVENTORY_SUCCESS:
      return {
        ...state,
        isSending: false,
        lastCreatedInventory: action.inventory,
        error: null,
      }
    case CREATE_INVENTORY_FAILURE:
      return {
        ...state,
        isSending: false,
        error: getErrorMessage(action.error),
      }
    case EDIT_INVENTORY:
      return { ...state, isSending: true, error: null, validationError: null }
    case EDIT_INVENTORY_SUCCESS:
      return { ...state, isSending: false }
    case EDIT_INVENTORY_FAILURE:
      return {
        ...state,
        isSending: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_INVENTORIES:
      return { ...state, map: secondLevelMerge(state.map, action.inventories) }
    case FETCH_INVENTORY:
      return { ...state, isLoading: true, isFetching: true }
    case FETCH_INVENTORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_INVENTORY_SUCCESS:
      return { ...state, isLoading: false, isFetching: false }
    case DELETE_INVENTORY:
      return { ...state, isDeleting: true }
    case DELETE_INVENTORY_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_INVENTORY_SUCCESS:
      return {
        ...state,
        list: R.without([action.inventoryId], state.list),
        map: R.omit([action.inventoryId], state.map),
        isDeleting: false,
        totalCount: Math.max(state.totalCount - 1, 0),
      }
    case CREATE_VARIATIONS_SUCCESS:
      const currentInventory = state.map[action.inventoryId] || {}
      const currentVariations = currentInventory.variations || []
      const lastCreatedInventory = {
        ...currentInventory,
        variations: [...currentVariations, ...Object.values(action.variations)],
      } as InventoryItem
      return {
        ...state,
        map: R.assoc(lastCreatedInventory.id, lastCreatedInventory, state.map),
        lastCreatedInventory,
      }
    case CREATE_VARIATION_SUCCESS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.inventoryId]: {
            ...state.map[action.inventoryId],
            variations: [
              ...(state.map[action.inventoryId].variations || []),
              action.variation,
            ],
          },
        },
      }
    case UPDATE_VARIATION_SUCCESS:
      const inventoryItem = state.map[action.inventoryId]

      if (!inventoryItem) {
        return state
      }

      const variations = inventoryItem.variations || []
      const changedVariation = variations.find(
        R.propEq('id', action.variation.id),
      )
      const notChangedVariations = variations.filter(
        ({ id }) => id !== action.variation.id,
      )

      return {
        ...state,
        map: {
          ...state.map,
          [action.inventoryId]: {
            ...state.map[action.inventoryId],
            variations: [
              ...notChangedVariations,
              { ...changedVariation, ...action.variation },
            ],
          },
        },
      }
    case DELETE_VARIATION_SUCCESS:
      const notDeletedVariations = state.map[
        action.inventoryId
      ].variations.filter(({ id }) => id !== action.variationId)
      return {
        ...state,
        map: {
          ...state.map,
          [action.inventoryId]: {
            ...state.map[action.inventoryId],
            variations: [...notDeletedVariations],
          },
        },
      }
    case SET_INVENTORIES_LIST_FILTERS:
      return {
        ...state,
        filters: action.filters,
      }
    default:
      return state
  }
}

export default inventories

export const getInventories = (state: RootState): InventoriesState =>
  state.inventories
export const getInventoriesError = (state: RootState) =>
  getInventories(state).error
export const getInventoryIsSending = (state: RootState) =>
  getInventories(state).isSending
export const getInventoryIsReceiving = (state: RootState) =>
  getInventories(state).isReceiving
export const getInventoryIsDeleting = (state: RootState) =>
  getInventories(state).isDeleting
export const getInventoryIsFetching = (state: RootState) =>
  getInventories(state).isFetching
export const getInventoriesList = (state: RootState) =>
  getInventories(state).list
export const getInventoriesMap = (state: RootState) => getInventories(state).map
export const getInventory = (inventoryId: string | Nil) =>
  createSelector(getInventoriesMap, (map) =>
    inventoryId ? map[inventoryId] : undefined,
  )
export const getMultipleInventories = (ids: string[]) =>
  createSelector(getInventoriesMap, R.props(ids))
export const getLastCreatedInventory = (state: RootState) =>
  getInventories(state).lastCreatedInventory
export const getTotalInventoryCount = (state: RootState) =>
  getInventories(state).totalCount
export const getInventoriesListFilters = (state: RootState) =>
  getInventories(state).filters
export const getInventoryVariation =
  (inventoryId: string | Nil, variationId: string | Nil) =>
  (state: RootState) => {
    const inventory = getInventory(inventoryId)(state)
    const variations = inventory?.variations || []
    return R.find(R.propEq('id', variationId), variations)
  }
