import { AnyAction } from 'redux'
import { all, put, takeLatest } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'
import requestAPI from '../sagas/utils/requestAPI'
import { finishLoading, startLoading } from './progress'

export const FETCH_ANALYTICS_URL = 'analytics/FETCH_ANALYTICS_URL'
export const FETCH_ANALYTICS_URL_SUCCESS =
  'analytics/FETCH_ANALYTICS_URL_SUCCESS'
export const FETCH_ANALYTICS_URL_FAILURE =
  'analytics/FETCH_ANALYTICS_URL_FAILURE'
export const CLEAN_UP = 'analytics/CLEAN_UP_LAST_APPOINTMENTS'

export const fetchAnalyticsUrl = (
  dashboard: number,
  signedParams?: Record<string, string>,
) => ({
  type: FETCH_ANALYTICS_URL,
  dashboard,
  signedParams,
})
export const fetchAnalyticsUrlSuccess = (dashboard: number, url: string) => ({
  type: FETCH_ANALYTICS_URL_SUCCESS,
  dashboard,
  url,
})
export const fetchAnalyticsUrlFailure = (error: ApiError) => ({
  type: FETCH_ANALYTICS_URL_FAILURE,
  error,
})

export const cleanUp = () => ({ type: CLEAN_UP })

export type AnalyticsState = {
  error: string | null
  isLoading: boolean
  urls: Record<number, string>
}

export const INITIAL_STATE: AnalyticsState = {
  urls: {},
  isLoading: false,
  error: null,
}

export const analyticsReducer = (
  state: AnalyticsState = INITIAL_STATE,
  action: AnyAction,
): AnalyticsState => {
  switch (action.type) {
    case FETCH_ANALYTICS_URL:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case FETCH_ANALYTICS_URL_SUCCESS:
      return {
        ...state,
        urls: { ...state.urls, [action.dashboard]: action.url },
        isLoading: false,
        error: null,
      }
    case FETCH_ANALYTICS_URL_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case CLEAN_UP:
      return INITIAL_STATE
    default:
      return state
  }
}

export const getAnalytics = (state: RootState): AnalyticsState =>
  state.analytics
export const getUrls = (state: RootState) => getAnalytics(state).urls
export const getUrl = (dashboard: number) =>
  createSelector(getUrls, (urls) => urls[dashboard])
export const getIsLoading = (state: RootState) => getAnalytics(state).isLoading
export const getError = (state: RootState) => getAnalytics(state).error

export function* fetchAnalyticsUrlSaga({
  dashboard,
  signedParams,
}: ReturnType<typeof fetchAnalyticsUrl>) {
  try {
    yield put(startLoading('analytics'))
    const url = yield* requestAPI(
      API.fetchAnalyticsReportUrl,
      dashboard,
      signedParams,
    )
    yield put(fetchAnalyticsUrlSuccess(dashboard, url))
    yield put(finishLoading('analytics'))
  } catch (error) {
    yield put(fetchAnalyticsUrlFailure(error as ApiError))
    yield put(finishLoading('analytics'))
  }
}

function* watchFetchUrl() {
  yield takeLatest(FETCH_ANALYTICS_URL, fetchAnalyticsUrlSaga)
}

export function* analyticsSaga() {
  yield all([watchFetchUrl()])
}
