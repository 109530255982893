import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { AddButton, PuiTheme } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import useDialog from '~/utils/useDialog'

import { getGroupId } from '../../invoiceUtils'
import InvoiceItemGroup, { InvoiceItemGroupProps } from './InvoiceItemGroup'
import InvoiceSummary from './InvoiceSummary'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    addButtonCell: {
      padding: theme.spacing(1, 2),
    },
    summaryCell: {
      padding: 0,
    },
  }),
  { name: 'InvoiceItemGroups' },
)

export interface InvoiceItemGroupsProps
  extends Omit<InvoiceItemGroupProps, 'group'> {
  isEstimate: boolean
  isOtcInvoice?: boolean
  isReadOnly: boolean
  showSummary?: boolean
}

const InvoiceItemGroups = ({
  showSummary,
  isReadOnly,
  invoice,
  isEstimate,
  isOtcInvoice,
  ...rest
}: InvoiceItemGroupsProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Invoices'])

  const groups = (invoice && invoice.groups) || []

  const [openAddInvoiceItemDialog] = useDialog(
    DialogNames.ADD_INVOICE_ITEM,
    null,
    {
      invoice,
      isEstimate,
      isOtcInvoice,
    },
  )

  const onAdd = () => {
    openAddInvoiceItemDialog({
      invoice,
      isEstimate,
      isOtcInvoice,
    })
  }

  return (
    <>
      {groups.map((group) => (
        <InvoiceItemGroup
          group={group}
          invoice={invoice}
          isEstimate={isEstimate}
          isReadOnly={isReadOnly || group?.soap?.finalized || false}
          key={getGroupId(group)}
          {...rest}
        />
      ))}
      {!isReadOnly && (
        <TableRow>
          <TableCell className={classes.addButtonCell} colSpan={100}>
            <AddButton
              addText={
                isEstimate ? t('Common:ADD_ITEM') : t('Invoices:ADD_OTC_ITEM')
              }
              onAdd={onAdd}
            />
          </TableCell>
        </TableRow>
      )}
      {showSummary && (
        <TableRow>
          <TableCell className={classes.summaryCell} colSpan={100}>
            <InvoiceSummary invoice={invoice} />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default InvoiceItemGroups
