import {
  MutationUpdateChargeSheetLineItemProducerBatchArgs,
  MutationUpdateInvoiceStateAndInternalNoteArgs,
  MutationVoidInvoiceArgs,
} from './graphql/generated/types'
import { requestMutation, requestQuery } from './graphql/graphqlCore'
import {
  POST_INVOICE,
  UPDATE_INVOICE_LINE_ITEM_PRODUCER_BATCH,
  UPDATE_INVOICE_STATE_AND_INTERNAL_NOTE,
  VOID_INVOICE,
} from './graphql/mutations/invoice'
import { FETCH_INVOICE } from './graphql/queries'
import * as Schema from './schemas'

export const fetchInvoiceV3 = requestQuery({
  query: FETCH_INVOICE,
  variablesHandler: (businessId, id, includeDeleted) => ({
    businessId,
    id,
    includeDeleted,
  }),
  schema: Schema.invoicesV3,
})

export const postInvoice = requestMutation({
  mutation: POST_INVOICE,
  variablesHandler: (businessId, input) => ({
    businessId,
    input,
  }),
  schema: Schema.invoicesV3,
})

export const updateInvoiceLineItemProducerBatch = requestMutation({
  mutation: UPDATE_INVOICE_LINE_ITEM_PRODUCER_BATCH,
  variablesHandler: (
    businessId: string,
    { input }: MutationUpdateChargeSheetLineItemProducerBatchArgs,
  ) => ({
    businessId,
    input,
  }),
})

export const voidInvoice = requestMutation({
  mutation: VOID_INVOICE,
  variablesHandler: (businessId: string, args: MutationVoidInvoiceArgs) => ({
    businessId,
    ...args,
  }),
  schema: Schema.invoicesV3,
})

export const udpateInvoiceStatusAndNotes = requestMutation({
  mutation: UPDATE_INVOICE_STATE_AND_INTERNAL_NOTE,
  variablesHandler: (
    businessId: string,
    args: MutationUpdateInvoiceStateAndInternalNoteArgs,
  ) => ({
    businessId,
    ...args,
  }),
  schema: Schema.invoicesV3,
})
