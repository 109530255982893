import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  Amount,
  CurrencyTextField,
  PuiTheme,
  PuiTooltip,
} from '@pbt/pbt-ui-components'
import { Edit as EditIcon } from '@pbt/pbt-ui-components/src/icons'

import PercentTextField from '~/components/common/inputs/PercentTextField'
import {
  addRange,
  formatMoneyRange,
  getDiscountedPrice,
  getExtendedPrice,
  getHighValue,
  getLimitedDiscountAmount,
  getLowValue,
  maxRange,
  minRange,
  subtractRange,
} from '~/components/dashboard/invoices/invoiceUtils'
import DialogNames from '~/constants/DialogNames'
import { Bundle, BundleItem } from '~/types'
import useDialog from '~/utils/useDialog'

const applyCustomBundlePriceDiscount =
  (discountPerc: number) =>
  (item = {} as BundleItem) => ({
    ...item,
    discountAmount: null,
    discountPerc,
  })

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tableCell: {
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
      height: 50,
      padding: theme.spacing(0, 1),
      borderBottom: 'none',
      fontWeight: 500,
    },
    lightText: {
      fontWeight: 400,
    },
    discountInput: {
      minWidth: 50,
      maxWidth: 65,
    },
    footerTableRow: {
      borderTop: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    editButton: {
      padding: theme.spacing(1),
    },
    editIcon: {
      color: theme.colors.editIconColor,
    },
  }),
  { name: 'BundleItemsTableFooter' },
)

export interface BundleItemsTableFooterProps {
  bundle: Bundle
  isPrePaidEnabled: boolean
  isRangeEnabled: boolean
  onBundleChange: (bundle: Bundle) => void
}

const BundleItemsTableFooter = ({
  bundle,
  isRangeEnabled,
  isPrePaidEnabled,
  onBundleChange,
}: BundleItemsTableFooterProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Admin')

  const [discountPercent, setDiscountPercent] = useState<number | ''>('')

  const [openBundleCustomPriceDialog] = useDialog(
    DialogNames.BUNDLE_CUSTOM_PRICE,
  )

  const bundleItems = bundle.items || []

  const bundlePrice = bundleItems.reduce(
    (acc: Amount, item: BundleItem) => addRange(acc, getExtendedPrice(item)),
    0,
  )
  const discountedBundlePrice = bundleItems.reduce(
    (acc: Amount, item: BundleItem) => addRange(acc, getDiscountedPrice(item)),
    0,
  )
  const itemsDiscount = bundleItems.reduce(
    (acc: Amount, item: BundleItem) =>
      addRange(acc, getLimitedDiscountAmount(item)),
    0,
  )
  const totalDiscount = minRange(
    addRange(itemsDiscount, bundle.additionalDiscount),
    bundlePrice,
  )
  const bundleTotalPrice = maxRange(
    subtractRange(discountedBundlePrice, bundle.additionalDiscount),
    0,
  )

  const onDiscountAmountChange = (value: number) => {
    onBundleChange({ ...bundle, additionalDiscount: value })
  }

  const onDiscountPercChange = (value: number) => {
    onBundleChange({
      ...bundle,
      additionalDiscount: (getLowValue(discountedBundlePrice) * value) / 100,
    })
    setDiscountPercent(value)
  }

  const applyCustomPrice = (customBundlePrice: number) => {
    const oldBundlePrice = getHighValue(bundlePrice)
    const perItemDiscount = 1 - customBundlePrice / oldBundlePrice

    onBundleChange({
      ...bundle,
      items: bundleItems.map(applyCustomBundlePriceDiscount(perItemDiscount)),
      additionalDiscount: 0,
    })
    setDiscountPercent('')
  }

  const onBundlePriceEdit = () => {
    openBundleCustomPriceDialog({
      onOk: applyCustomPrice,
      extendedBundlePrice: bundlePrice,
    })
  }

  const quantityOffset =
    isPrePaidEnabled && !isRangeEnabled ? 3 : isRangeEnabled ? 2 : 1
  const colOffset = 1 + quantityOffset

  const isBundlePriceEditable = useMemo(
    () => bundle?.items?.every((item) => item.price.discountAllowed),
    [bundle],
  )

  return (
    <>
      <TableRow className={classes.footerTableRow}>
        <TableCell className={classes.tableCell} colSpan={colOffset}>
          {t('Admin:CATALOG.BUNDLE_ITEMS_TABLE_FOOTER.ADDITIONAL_DISCOUNTS')}
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell className={classes.tableCell}>
          <PercentTextField
            className={classes.discountInput}
            placeholder="&nbsp;"
            value={discountPercent}
            onChange={onDiscountPercChange}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <CurrencyTextField
            className={classes.discountInput}
            value={bundle.additionalDiscount}
            onChange={(event) =>
              onDiscountAmountChange(Number(event.target.value.trim()))
            }
          />
        </TableCell>
        <TableCell className={classNames(classes.tableCell, classes.lightText)}>
          {formatMoneyRange(bundle.additionalDiscount, true)}
        </TableCell>
      </TableRow>

      <TableRow className={classes.footerTableRow}>
        <TableCell className={classes.tableCell} colSpan={colOffset}>
          {t('Admin:CATALOG.BUNDLE_ITEMS_TABLE_FOOTER.BUNDLE_TOTAL')}
        </TableCell>
        <TableCell />
        <TableCell className={classes.tableCell}>
          {formatMoneyRange(bundlePrice)}
        </TableCell>
        <TableCell />
        <TableCell className={classNames(classes.tableCell, classes.lightText)}>
          {formatMoneyRange(totalDiscount)}
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Grid container alignItems="center" wrap="nowrap">
            {formatMoneyRange(bundleTotalPrice)}
            <PuiTooltip
              disabled={isBundlePriceEditable}
              tooltipPlacement="top-end"
              tooltipText={t(
                'Admin:CATALOG.BUNDLE_ITEMS_TABLE_FOOTER.EDIT_TOOLTIP',
              )}
            >
              <span>
                <IconButton
                  className={classes.editButton}
                  disabled={!isBundlePriceEditable}
                  onClick={onBundlePriceEdit}
                >
                  <EditIcon
                    className={classNames({
                      [classes.editIcon]: isBundlePriceEditable,
                    })}
                    fontSize="small"
                  />
                </IconButton>
              </span>
            </PuiTooltip>
          </Grid>
        </TableCell>
      </TableRow>
    </>
  )
}

export default BundleItemsTableFooter
