import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, LinearProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  BackButton,
  ButtonWithLoader,
  CircularProgressOverlay,
  LinkButton,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

// @ts-ignore
import SwitchingView from '~/components/common/SwitchingView'
import DialogNames from '~/constants/DialogNames'
import i18n from '~/locales/i18n'
import {
  applyMappings,
  fetchMappingStructures,
  fetchNonFixableLogs,
  finishReviewExceptions,
  focusMappingStructure,
} from '~/store/actions/migration'
import {
  getCurrentSessionId,
  getFocusedMappingStructureIndex,
  getIsApplyingMappings,
  getMappingsIsLoading,
  getMappingsList,
  getMappingStructures,
  getMappingStructuresIsLoading,
  getMultipleMappings,
  getNonFixableLogs,
} from '~/store/reducers/migration'
import useDialog from '~/utils/useDialog'

import MigrationExceptionsTable from './MigrationExceptionsTable'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    progress: {
      position: 'sticky',
      height: theme.constants.progressBarHeight,
    },
    hidden: {
      visibility: 'hidden',
    },
    dialogContentRoot: {
      height: '100%',
      position: 'relative',
    },
    content: {
      minHeight: 240,
      maxHeight: 610,
      overflow: 'auto',
      position: 'relative',
      padding: theme.spacing(2),
    },
    footer: {
      borderTop: theme.constants.tableBorder,
      padding: theme.spacing(2),
    },
    spinner: {
      marginRight: theme.spacing(0.5),
    },
    button: {
      height: 40,
      minWidth: 150,
    },
    fillHeight: {
      height: '100%',
    },
  }),
  { name: 'StepReviewExceptions' },
)

interface StepReviewExceptionsProps {
  finishLabel?: string
  isEnterprise: boolean
  onFinishClose?: () => void
}

const StepReviewExceptions = ({
  isEnterprise,
  finishLabel = i18n.t('Common:FINISH_ACTION'),
  onFinishClose,
}: StepReviewExceptionsProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const mappingsStructures = useSelector(getMappingStructures)
  const mappingIds = useSelector(getMappingsList)
  const exceptions = useSelector(getMultipleMappings(mappingIds))
  const isLoading = useSelector(getMappingsIsLoading)
  const isLoadingStructures = useSelector(getMappingStructuresIsLoading)
  const sessionId = useSelector(getCurrentSessionId)
  const isApplyingMappings = useSelector(getIsApplyingMappings)
  const activeViewIndex = useSelector(getFocusedMappingStructureIndex)

  const [isInitialized, setIsInitialized] = useState(false)
  const [openTypographyDialog] = useDialog(DialogNames.TYPOGRAPHY_DIALOG)

  const isLastGroup =
    isInitialized && activeViewIndex >= mappingsStructures.length - 1
  const nextButtonLabel = isLastGroup ? finishLabel : t('Common:NEXT')
  const isBackEnabled = Boolean(activeViewIndex)

  useEffect(() => {
    if (!isInitialized && sessionId) {
      dispatch(fetchMappingStructures(sessionId))
      setIsInitialized(true)
    }
  }, [isInitialized, sessionId])

  const handleNext = () => {
    if (isEnterprise || mappingsStructures?.length) {
      const currentStructure = mappingsStructures[activeViewIndex] || {}
      const { field, entity } = currentStructure
      if (field && entity) {
        dispatch(applyMappings(sessionId, field, entity, isEnterprise))

        if (isLastGroup && onFinishClose) {
          onFinishClose()
        }
      }
    } else {
      dispatch(finishReviewExceptions(sessionId))
    }
  }

  const handleBack = () => {
    dispatch(focusMappingStructure(Math.max(0, activeViewIndex - 1)))
  }

  const viewNonFixableExceptions = () => {
    dispatch(fetchNonFixableLogs(sessionId))
    openTypographyDialog({
      title: t('Businesses:MIGRATION.NON_FIXABLE_EXCEPTIONS'),
      contentSelector: getNonFixableLogs,
      placeholder: t('Businesses:MIGRATION.NO_EXCEPTIONS_FOUND'),
    })
  }

  return (
    <>
      <LinearProgress
        className={classNames(classes.progress, {
          [classes.hidden]: !isLoading,
        })}
      />
      <CircularProgressOverlay
        open={isLoadingStructures}
        preloaderText={t('Businesses:MIGRATION.LOADING_EXCEPTIONS')}
      />
      <CircularProgressOverlay
        open={isApplyingMappings}
        preloaderText={t('Businesses:MIGRATION.APPLYING_PROPOSED_VALUES')}
      />
      <Grid container className={classes.fillHeight} direction="column">
        <Grid item className={classes.content}>
          {Boolean(!mappingsStructures?.length) && (
            <Grid
              container
              alignItems="center"
              className={classes.fillHeight}
              direction="column"
              justifyContent="center"
            >
              <Text variant="body2">
                {t('Businesses:MIGRATION.NO_EXCEPTIONS_FOUND')}!
              </Text>
            </Grid>
          )}
          {Boolean(mappingsStructures?.length) && (
            <SwitchingView activeIndex={activeViewIndex}>
              {mappingsStructures.map((exceptionGroupConfig) => (
                <MigrationExceptionsTable
                  config={exceptionGroupConfig}
                  exceptions={exceptions}
                  isEnterprise={isEnterprise}
                  key={`${exceptionGroupConfig.entity}-${exceptionGroupConfig.field}`}
                />
              ))}
            </SwitchingView>
          )}
        </Grid>
        <Grid
          container
          className={classes.footer}
          justifyContent="space-between"
        >
          <Grid>
            <Grid item>
              <BackButton
                disabled={!isBackEnabled || isLoading}
                label={t('Common:BACK_ACTION')}
                onClick={isBackEnabled ? handleBack : undefined}
              />
            </Grid>
            <LinkButton onClick={viewNonFixableExceptions}>
              {t('Businesses:MIGRATION.NON_FIXABLE_EXCEPTIONS')}
            </LinkButton>
          </Grid>
          <ButtonWithLoader
            className={classes.button}
            color="primary"
            disabled={isLoading}
            loading={isLoading}
            onClick={handleNext}
          >
            {nextButtonLabel}
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </>
  )
}

export default StepReviewExceptions
