import React from 'react'
import { To } from 'react-router-dom'
import { KeyboardArrowRight } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, PuiTheme, Text } from '@pbt/pbt-ui-components'
import { Eye } from '@pbt/pbt-ui-components/src/icons'

import Link from '~/components/common/link/Link'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    link: {
      textDecoration: 'none',
    },
    eyeIcon: {
      color: theme.colors.primaryText,
    },
  }),
  { name: 'ClientDetailsLink' },
)

interface ClientDetailsLinkProps {
  actionComponent?: React.ReactNode
  classes?: ClassesType<typeof useStyles>
  label: string
  to: To
}

const ClientDetailsLink = ({
  to,
  label,
  classes: classesProp,
  actionComponent,
}: ClientDetailsLinkProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Link className={classes.link} to={to}>
      <Grid container justifyContent="space-between">
        <Grid container item xs alignItems="center">
          <Eye className={classes.eyeIcon} />
          <Text strong align="left" variant="subheading2">
            {label}
          </Text>
        </Grid>
        <Grid item>
          {actionComponent || (
            <IconButton size="large">
              <KeyboardArrowRight />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Link>
  )
}

export default ClientDetailsLink
