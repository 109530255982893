import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  LanguageUtils,
  PuiTheme,
  SentenceFormatter,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import InlineAttachment from '~/components/common/attachments/InlineAttachment'
import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import DialogNames from '~/constants/DialogNames'
import { deleteAttachmentHistory } from '~/store/actions/medicalHistory'
import { deleteLabTestAttachmentFile } from '~/store/actions/timeline'
import { deleteAttachmentFile } from '~/store/duck/files'
import { print } from '~/store/duck/print'
import {
  GroupedAttachmentTimelineItemEntity,
  IdentifiedAttachmentOrigin,
} from '~/types'
import { getDeleteConfirmMessage } from '~/utils'
import useDialog from '~/utils/useDialog'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      borderRadius: 2,
      border: theme.constants.tableBorder,
    },
  }),
  { name: 'GroupedAttachmentItem' },
)

export interface GroupedAttachmentItemProps {
  clientId: string
  entry: GroupedAttachmentTimelineItemEntity
  patientId: string
}

const GroupedAttachmentItem = ({
  entry,
  clientId,
  patientId,
}: GroupedAttachmentItemProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const [openEmailAttachmentDialog] = useDialog(
    DialogNames.EMAIL_ATTACHMENT_DIALOG,
  )
  const [openDeleteAttachmentAlert, closeDeleteAttachmentAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const isContextItem = useIsCurrentContextItem(entry)

  const handlePrint = (attachment: IdentifiedAttachmentOrigin) => {
    dispatch(print(attachment?.fileUrl))
  }

  const handleEmail = (attachment: IdentifiedAttachmentOrigin) => {
    openEmailAttachmentDialog({
      selectedDocuments: [attachment.id],
      soapId: entry.soapId,
      patientId,
      clientId,
      scopedAttachments: [attachment],
    })
  }

  const handleDelete = (attachment: IdentifiedAttachmentOrigin) => {
    openDeleteAttachmentAlert({
      message: getDeleteConfirmMessage('attachment'),
      cancelButtonText: t('Common:NO_KEEP'),
      okButtonText: t('Common:YES_DELETE'),
      onCancel: () => closeDeleteAttachmentAlert(),
      onOk: () => {
        if (entry.soapFile) {
          dispatch(deleteAttachmentFile(attachment.id))
        } else if (entry.labTestLogFile) {
          dispatch(
            deleteLabTestAttachmentFile(
              entry.labTestLogId as string,
              entry.id,
              attachment.id,
            ),
          )
        } else {
          dispatch(deleteAttachmentHistory(attachment.id, patientId))
        }
        closeDeleteAttachmentAlert()
      },
    })
  }

  const authorName = Utils.getPersonString(entry.author)
  const addedLabel = t('Common:ADDED_BY_PERSON_NAME', {
    personName: authorName,
  })

  return (
    <Grid
      container
      item
      className={classes.root}
      direction="column"
      mb={1}
      px={2}
      py={0.5}
    >
      <Grid container item alignItems="center">
        <Text variant="h4">{entry.title}</Text>
        <BusinessShareIcon
          businessIds={entry.businessId ? [entry.businessId] : []}
          noMargin={!entry.title}
        />
      </Grid>
      <Text strong variant="body2">
        {LanguageUtils.getSentence(
          [
            entry.recordFrom &&
              t('Common:FROM_RECORD', { fromRecord: entry.recordFrom }),
            entry.recordFrom && authorName && ' | ',
            addedLabel,
          ],
          SentenceFormatter.REGULAR,
        )}
      </Text>
      <Text variant="body2">{entry.description}</Text>
      <Grid container item direction="column">
        {entry.files?.map((file) => (
          <InlineAttachment
            attachment={file}
            hideDelete={!isContextItem}
            key={file.id}
            onDelete={() => handleDelete(file)}
            onEmail={() => handleEmail(file)}
            onPrint={() => handlePrint(file)}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default GroupedAttachmentItem
