enum SnapshotsAliasTypes {
  Reminders = 'REMINDERS',
  Diagnoses = 'DIAGNOSES',
  Prescriptions = 'PRESCRIPTIONS',
  Appointments = 'FUTURE_APPOINTMENTS',
  Labs = 'LABS',
  Vitals = 'VITALS',
  Estimates = 'ESTIMATES',
  PrepaidItems = 'PREPAID_ITEMS',
  Communications = 'COMMUNICATIONS',
  Vaccines = 'VACCINES',
}

export default SnapshotsAliasTypes
