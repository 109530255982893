import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, TextInteractive } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      height: '100%',
      border: theme.constants.tableBorder,
      borderRadius: 2,
    },
  }),
  { name: 'DirectCommunicationCard' },
)

export interface DirectCommunicationCardProps {
  recipientEmail?: string | null
  recipientPhoneNumber?: string
}

const DirectCommunicationCard = ({
  recipientEmail,
  recipientPhoneNumber,
}: DirectCommunicationCardProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common'])

  const isPhoneDirectCommunication = Boolean(recipientPhoneNumber)
  const isLoading = !recipientEmail && !recipientPhoneNumber

  return (
    <Grid
      container
      item
      className={classes.root}
      direction="column"
      px={2}
      py={1}
    >
      {isPhoneDirectCommunication ? (
        <TextInteractive isLoading={isLoading} loaderWidth={90} variant="body2">
          {`${t('Common:PHONE')}: ${recipientPhoneNumber}`}
        </TextInteractive>
      ) : (
        <TextInteractive isLoading={isLoading} variant="body2">
          {recipientEmail}
        </TextInteractive>
      )}
    </Grid>
  )
}

export default DirectCommunicationCard
