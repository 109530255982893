import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import Cropper from 'react-cropper'
import { useTranslation } from 'react-i18next'
import { ClickAwayListener, Fab, Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  BlobWithName,
  ButtonWithLoader,
  ButtonWithLoaderProps,
  ClassesType,
  ErrorTooltip,
  FileTemplate,
  PuiTheme,
} from '@pbt/pbt-ui-components'
import {
  RotateImage,
  UploadImage as UploadImageIcon,
} from '@pbt/pbt-ui-components/src/icons'

import SingleFileSelector from '../SingleFileSelector'
import FileInputArea from './FileInputArea'
import useFileInputHelper from './useFileInputHelper'

import 'cropperjs/dist/cropper.css'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    logoArea: {
      [theme.breakpoints.down('md')]: {
        height: 420,
      },
      height: 342,
      border: theme.constants.handwritingBorder,
      borderRadius: 4,
    },
    button: {
      width: 300,
      height: 50,
    },
    rotationButton: {
      width: 40,
      height: 40,
      top: 80,
      right: 35,
      position: 'absolute',
      backgroundColor: '#EAEAEA',
      '&:hover': {
        backgroundColor: '#DADADA',
      },
    },
    normalButton: {
      [theme.breakpoints.down('md')]: {
        margin: `0 5% ${theme.spacing(2)}`,
        float: 'none',
      },
    },
    smallButton: {
      margin: `0 5% ${theme.spacing(2)}`,
      float: 'none',
    },
    yesButton: {
      width: '100%',
    },
    normalButtons: {
      marginTop: theme.spacing(6),
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        marginTop: theme.spacing(3),
      },
    },
    smallButtons: {
      justifyContent: 'center',
      marginTop: theme.spacing(3),
    },
    cropper: {
      width: '100%',
      height: '100%',
    },
    inputArea: {},
    logoAreaClassName: {},
    buttonsClassName: {},
    buttonsContainerClassName: {},
    hidden: {
      display: 'none',
    },
    text: {},
  }),
  { name: 'FileInput' },
)

export interface FileInputHandle {
  crop: () => void
  open: () => void
}

export interface FileInputProps {
  Icon?: React.JSXElementConstructor<any>
  accept?: string
  aspectRatio?: number
  buttonsHidden?: boolean
  children?: React.ReactNode
  className?: string
  classes?: ClassesType<typeof useStyles>
  clearOnCancel?: boolean
  disableCropper?: boolean
  forceButtonsHidden?: boolean
  isLoading?: boolean
  mobileText?: string
  onCancel?: () => void
  onFileReady?: ({ data, blob }: { blob: BlobWithName; data?: string }) => void
  onFileSelected?: (file: FileTemplate) => void
  onlyImages?: boolean
  size?: string
  source?: string
  text?: string
  uploadButtonColor?: ButtonWithLoaderProps['color']
  viewMode?: Cropper.ViewMode
}

const FileInput = forwardRef<FileInputHandle, FileInputProps>(
  function FileInput(
    {
      accept,
      classes: classesProp,
      onFileReady,
      onFileSelected,
      onCancel,
      text,
      mobileText,
      aspectRatio,
      isLoading = false,
      onlyImages = false,
      className,
      uploadButtonColor = 'secondary',
      size = 'normal',
      buttonsHidden = false,
      forceButtonsHidden = false,
      clearOnCancel = false,
      source,
      children,
      disableCropper,
      viewMode = 0,
      Icon = UploadImageIcon,
    },
    ref,
  ) {
    const classes = useStyles({ classes: classesProp })
    const { t } = useTranslation('Common')
    const fileInputText = text || t('Common:INPUTS.FILE_INPUT.TEXT.DEFAULT')
    const fileInputMobileText =
      mobileText || t('Common:INPUTS.FILE_INPUT.TEXT.MOBILE')

    const [cropperReady, setCropperReady] = useState(false)
    const [cropper, setCropper] = useState<Cropper>()
    const [isProcessing, setIsProcessing] = useState(false)
    const [isDragOver, setIsDragOver] = useState(false)
    const [fileName, setFileName] = useState<string>()
    const [imageFileSize, setImageFileSize] = useState<number>(0)

    const fileSelectorRef = useRef<HTMLInputElement>()
    const maxImageFileSize = 10 // mb

    const IMAGE_LOADING_ERROR = t(
      'Common:INPUTS.FILE_INPUT.ERROR.IMAGE_LOADING',
    )
    const DOCUMENT_LOADING_ERROR = t(
      'Common:INPUTS.FILE_INPUT.ERROR.DOCUMENT_LOADING',
    )
    const FILE_TOO_LARGE_ERROR = t(
      'Common:INPUTS.FILE_INPUT.ERROR.IMAGE_TOO_LARGE',
    )

    const cropperEnable = (src: string | undefined) => {
      if (cropper && src) {
        cropper?.enable()
      }
    }

    const {
      updateSource,
      imageSource,
      setImageSource,
      onSelected: onSelectedHelper,
      isError,
      setIsError,
    } = useFileInputHelper(onFileSelected, cropperEnable)

    useEffect(() => {
      updateSource(source)
    }, [source])

    useEffect(() => {
      setCropperReady(false)
    }, [imageSource])

    useEffect(() => {
      if (cropper && imageSource) {
        if (disableCropper) {
          try {
            cropper.clear()
            cropper.disable()
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error during the cropper annihilation:', error)
          }
        } else if (imageSource) {
          try {
            cropper.enable()
            cropper.crop()
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error during the cropper enable process:', error)
          }
        }
      }
    }, [imageSource, cropperReady, disableCropper])

    const onSelected = (file: FileTemplate) => {
      setFileName(file.raw?.name)
      onSelectedHelper(file)
    }

    const bytesToMb = (bytes: number) => bytes / (1024 * 1024)

    const crop = () => {
      const canvas = cropper?.getCroppedCanvas()
      if (canvas) {
        setIsProcessing(true)
        canvas.toBlob((blobProp) => {
          if (!blobProp) {
            return
          }

          const blobWithName = blobProp as BlobWithName

          if (!blobWithName.name && fileName) {
            blobWithName.name = fileName
          }

          const imageSize = bytesToMb(blobWithName.size)
          setImageFileSize(imageSize)

          // verify file not over maxImageFileSize in size
          if (imageSize > maxImageFileSize) {
            setIsError(true)
            setIsProcessing(false)
          } else if (onFileReady) {
            const data = canvas.toDataURL()
            onFileReady({ data, blob: blobWithName })
          }
        })
      }
    }

    useImperativeHandle(ref, () => ({
      crop,
      open: () => fileSelectorRef.current?.click(),
    }))

    return (
      <Grid container className={className} direction="column">
        <ErrorTooltip
          message={
            onlyImages
              ? imageFileSize > maxImageFileSize
                ? FILE_TOO_LARGE_ERROR
                : IMAGE_LOADING_ERROR
              : DOCUMENT_LOADING_ERROR
          }
          open={isError}
        >
          <Grid container>
            <ClickAwayListener onClickAway={() => setIsError(false)}>
              <Grid
                container
                item
                alignItems="center"
                className={classNames(
                  classes.logoArea,
                  classes.logoAreaClassName,
                )}
                direction="column"
                justifyContent="center"
              >
                <Cropper
                  responsive
                  aspectRatio={aspectRatio}
                  className={classNames(classes.cropper, {
                    [classes.hidden]: !imageSource,
                  })}
                  guides={false}
                  initialAspectRatio={NaN}
                  ready={() => {
                    setCropperReady(true)
                  }}
                  src={imageSource}
                  viewMode={viewMode}
                  onInitialized={setCropper}
                />
                {Boolean(imageSource) && (
                  <IconButton
                    className={classes.rotationButton}
                    size="large"
                    onClick={() => cropper && cropper.rotate(90)}
                  >
                    <RotateImage />
                  </IconButton>
                )}
                <SingleFileSelector
                  accept={accept}
                  classes={{
                    file: classNames({
                      [classes.hidden]: Boolean(imageSource),
                    }),
                  }}
                  onlyImages={onlyImages}
                  ref={fileSelectorRef}
                  onDragOverChange={setIsDragOver}
                  onSelected={onSelected}
                >
                  <Grid item>
                    <FileInputArea
                      Icon={Icon}
                      classes={{
                        inputArea: classes.inputArea,
                        text: classes.text,
                      }}
                      fileInputMobileText={fileInputMobileText}
                      fileInputText={fileInputText}
                      hidden={Boolean(imageSource)}
                      isDragOver={isDragOver}
                    />
                  </Grid>
                </SingleFileSelector>
              </Grid>
            </ClickAwayListener>
          </Grid>
        </ErrorTooltip>
        {children}
        {!forceButtonsHidden && (
          <Grid
            container
            item
            className={classNames(
              size === 'small' ? classes.smallButtons : classes.normalButtons,
              classes.buttonsContainerClassName,
            )}
          >
            {(imageSource || !buttonsHidden) && (
              <>
                <Fab
                  className={classNames(
                    classes.button,
                    size === 'small'
                      ? classes.smallButton
                      : classes.normalButton,
                    classes.buttonsClassName,
                  )}
                  color="primary"
                  disabled={isLoading || isProcessing}
                  variant="extended"
                  onClick={() => {
                    setIsError(false)
                    if (clearOnCancel) {
                      setImageSource(undefined)
                    }
                    if (onCancel) {
                      onCancel()
                    }
                  }}
                >
                  {t('Common:CANCEL_ACTION')}
                </Fab>
                <div
                  className={classNames(
                    classes.button,
                    size === 'small'
                      ? classes.smallButton
                      : classes.normalButton,
                    classes.buttonsClassName,
                  )}
                >
                  <ButtonWithLoader
                    className={classes.yesButton}
                    color={uploadButtonColor}
                    disabled={isLoading || isProcessing}
                    loading={isLoading || isProcessing}
                    onClick={crop}
                  >
                    {t('Common:SAVE_ACTION')}
                  </ButtonWithLoader>
                </div>
              </>
            )}
          </Grid>
        )}
      </Grid>
    )
  },
)

export default FileInput
