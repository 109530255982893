import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { Nil, PuiTheme } from '@pbt/pbt-ui-components'

import { getPatientName } from '~/store/reducers/patients'
import { getUserLastName } from '~/store/reducers/users'

import Link from './Link'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    linkText: {
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
    },
  }),
  { name: 'PatientLink' },
)

export interface PatientLinkProps {
  clientId: string | Nil
  patientId: string | Nil
}

const PatientLink = ({ clientId, patientId }: PatientLinkProps) => {
  const classes = useStyles()
  const patientName = useSelector(getPatientName(patientId))
  const clientName = useSelector(getUserLastName(clientId))

  return (
    <Link
      className={classes.linkText}
      to={`/client/${clientId}/patient/${patientId}`}
    >
      <>
        {patientName || ''}
        <br />
        {clientName || ''}
      </>
    </Link>
  )
}

export default PatientLink
