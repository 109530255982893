import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import { useCanSubmitApprovedChewyActiveRx } from '~/components/dashboard/prescription/prescription-editor/hooks/useCanSubmitApprovedChewyActiveRx'
import { FinalizeChewyActiveRxTable } from '~/components/dashboard/soapV2/finalize-orders/chewy-active-rx/FinalizeChewyActiveRxTable'
import {
  getChargeSheetPendingChewyActiveRxList,
  getIsFetchingAllPendingActiveRxForChargeSheet,
  getIsSubmittingAllActiveRx,
  getSubmittingAllActiveRxError,
  submitAllActiveRxForChargeSheet,
} from '~/store/duck/prescriptions'
import { useHasChewyActiveRxApproved } from '~/store/hooks/prescription'
import { removeServerErrorPrefix } from '~/utils/errors'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      maxWidth: 800,
    },
    actions: {
      borderTop: 0,
      justifyContent: 'flex-start',
      paddingBottom: theme.spacing(2),
      gap: theme.spacing(2),
    },
    content: {
      minHeight: 116,
    },
    title: {
      borderBottom: 0,
    },
    table: {
      border: theme.constants.tableBorder,
    },
  }),
  { name: 'FinalizeChewyActiveRxDialog' },
)

interface FinalizeChewyActiveRxDialogProps extends BasePuiDialogProps {
  businessId: string
  clientId: string
}

export const FinalizeChewyActiveRxDialog = ({
  businessId,
  clientId,
  onClose,
  open,
}: FinalizeChewyActiveRxDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common'])
  const dispatch = useDispatch()

  const error = useSelector(getSubmittingAllActiveRxError)
  const isSubmitting = useSelector(getIsSubmittingAllActiveRx)
  const isFetchingAllPendingActiveRxForChargeSheet = useSelector(
    getIsFetchingAllPendingActiveRxForChargeSheet,
  )
  const chargeSheetPendingChewyActiveRxList = useSelector(
    getChargeSheetPendingChewyActiveRxList,
  )

  const canSubmitApprovedChewyActiveRx = useCanSubmitApprovedChewyActiveRx()
  const hasChewyActiveRxApproved = useHasChewyActiveRxApproved(
    chargeSheetPendingChewyActiveRxList,
  )

  const canFinalize = canSubmitApprovedChewyActiveRx && hasChewyActiveRxApproved

  const closeOnUpdate = useCloseAfterCreation(() => {
    if (onClose && !error) {
      onClose()
    }
  }, getIsSubmittingAllActiveRx)

  const sendToChewy = () => {
    dispatch(submitAllActiveRxForChargeSheet({ businessId, clientId }))
    closeOnUpdate()
  }

  return (
    <PuiDialog
      actions={
        <>
          {canFinalize && (
            <ButtonWithLoader
              loading={isSubmitting}
              variant="contained"
              onClick={sendToChewy}
            >
              {t('Common:SEND_TO_CHEWY')}
            </ButtonWithLoader>
          )}
          <Grid>{t('Soap:FINALIZE_CHEWY_ACTIVE_RX_DESCRIPTION')}</Grid>
        </>
      }
      aria-labelledby="finalize-chewy-active-rx-dialog"
      classes={{
        dialogTitle: classes.title,
        paper: classes.paper,
        actions: classes.actions,
        dialogContentRoot: classes.content,
      }}
      open={open}
      title={t('Common:FINALIZE_AND_SUBMIT_CHEWY_RX')}
      onClose={onClose}
    >
      <Grid className={classes.table} m={2} my={1}>
        <FinalizeChewyActiveRxTable
          context="Charge-sheet"
          data={chargeSheetPendingChewyActiveRxList}
          isLoading={isFetchingAllPendingActiveRxForChargeSheet}
        />
      </Grid>
      {error && (
        <Alert severity="error" sx={{ m: 2 }}>
          {removeServerErrorPrefix(error)}
        </Alert>
      )}
    </PuiDialog>
  )
}
