import * as R from 'ramda'
import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import {
  CreateLabTestLogFileGroupInput,
  CreateLabTestLogFileInput,
  LabTestLog,
  LabTestLogFile,
  LabTestLogFileGroup,
} from '~/api/graphql/generated/types'
import { getLabTestSimpleId } from '~/components/dashboard/lab-tests-dashboard/lab-tests-table/labTestUtils'
import AssetDestination from '~/constants/AssetDestination'
import { FilesWithDetails, LabTestDashboardItem, TableFilter } from '~/types'
import { mapFilesToObjects } from '~/utils/file'

import { fetchTimeline as fetchTimelineAction } from '../actions/timeline'
import { uploadAssetList } from '../duck/files'
import {
  ASSIGN_LAB_TEST,
  assignLabTest,
  assignLabTestFailure,
  assignLabTestSuccess,
  CREATE_LAB_TEST_ATTACHMENT_FILE_GROUP,
  createLabTestAttachmentFileGroup,
  createLabTestAttachmentFileGroupFailure,
  createLabTestAttachmentFileGroupSuccess,
  DELETE_LAB_TEST_ATTACHMENT_FILE,
  deleteLabTestAttachmentFile,
  deleteLabTestAttachmentFileFailure,
  deleteLabTestAttachmentFileSuccess,
  FETCH_LAB_TEST_DETAILS,
  FETCH_LAB_TESTS_LIST,
  FETCH_MORE_LAB_TESTS,
  fetchLabTestDetails,
  fetchLabTestDetailsFailure,
  fetchLabTestDetailsSuccess,
  fetchLabTestsList,
  fetchLabTestsListFailure,
  fetchLabTestsListSuccess,
  fetchMoreLabTests,
  fetchMoreLabTestsFailure,
  fetchMoreLabTestsSuccess,
  getLabTestsFilters,
  optimisticDeleteLabTestAttachmentFile,
  REFRESH_LAB_TESTS_LIST,
  refreshLabTestsList,
  refreshLabTestsListFailure,
  refreshLabTestsListSuccess,
  SAVE_LAB_ORDER,
  saveLabOrder,
  saveLabOrderFailure,
  saveLabOrderSuccess,
} from '../duck/labTestsDashboard'
import { finishLoading, startLoading } from '../duck/progress'
import { getCurrentUserId } from '../reducers/auth'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

function* getFilters() {
  const labTestsFilters: Record<string, TableFilter> = yield select(
    getLabTestsFilters,
  )
  const [fromDate, toDate] = (labTestsFilters.date?.value as string[]) || []
  const vetIds = labTestsFilters.vetIds?.value as string
  const status = labTestsFilters.status?.value as string[]
  const typeIds = labTestsFilters.typeIds?.value

  const filters: Record<any, TableFilter['value']> = {}

  if (typeIds) {
    filters.typeIds = typeIds
  }

  if (fromDate) {
    filters.orderFromDate = fromDate
  }

  if (toDate) {
    filters.orderToDate = toDate
  }

  if (vetIds) {
    filters.vetIds = vetIds.split(',')
  }

  if (status) {
    filters.statusIds = status
      .map((complexId) => getLabTestSimpleId(complexId)[0])
      .filter(Boolean)
    filters.orderStatusIds = status
      .map((complexId) => getLabTestSimpleId(complexId)[1])
      .filter(Boolean)
  }

  return filters
}

function prepareLabOrderForSaving(labOrder: LabTestDashboardItem) {
  return R.omit(['client', 'patient'], labOrder)
}

export function* fetchLabTestsListSaga({
  from,
  to,
}: ReturnType<typeof fetchLabTestsList>) {
  try {
    const filters: Record<any, TableFilter['value']> = yield call(getFilters)
    yield put(startLoading('lab-test-dashboard'))
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchLabTestsForDashboard, filters, from, to)
    yield call(updateEntities, entities)
    yield put(fetchLabTestsListSuccess(list, totalCount))
  } catch (error) {
    yield put(fetchLabTestsListFailure(error as ApiError))
  } finally {
    yield put(finishLoading('lab-test-dashboard'))
  }
}

export function* fetchMoreLabTestsSaga({
  from,
  to,
}: ReturnType<typeof fetchMoreLabTests>) {
  try {
    const filters: Record<any, TableFilter['value']> = yield call(getFilters)
    yield put(startLoading('lab-test-dashboard'))
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchLabTestsForDashboard, filters, from, to)
    yield call(updateEntities, entities)
    yield put(fetchMoreLabTestsSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreLabTestsFailure(error as ApiError))
  } finally {
    yield put(finishLoading('lab-test-dashboard'))
  }
}

export function* refreshLabTestsListSaga({
  from,
  to,
}: ReturnType<typeof refreshLabTestsList>) {
  try {
    const filters: Record<any, TableFilter['value']> = yield call(getFilters)

    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchLabTestsForDashboard, filters, from, to)
    yield call(updateEntities, entities)
    yield put(refreshLabTestsListSuccess(list, totalCount, from))
  } catch (error) {
    yield put(refreshLabTestsListFailure(error as ApiError))
  }
}

export function* assignLabTestSaga({
  labTestToAssign,
}: ReturnType<typeof assignLabTest>) {
  try {
    const { orderId, resultId, vendorId, vetId, patientId, clientId, eventId } =
      labTestToAssign
    yield put(startLoading('lab-test-assign-orphan'))
    yield* requestAPI(
      API.assignLabResult,
      orderId,
      resultId,
      vendorId,
      vetId,
      patientId,
      clientId,
      eventId,
    )
    yield put(assignLabTestSuccess())
    // TODO: find out how to update lab test here
    // yield put(fetchLabTestsListSaga())
  } catch (error) {
    yield put(assignLabTestFailure(error as ApiError))
  } finally {
    yield put(finishLoading('lab-test-assign-orphan'))
  }
}

export function* fetchLabTestDetailsSaga({
  vendorId,
  orderId,
  soapId,
  invoiceId,
  patientId,
  vetId,
}: ReturnType<typeof fetchLabTestDetails>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.fetchLabTestDetails,
      vendorId,
      orderId,
      soapId,
      invoiceId,
      patientId,
      vetId,
    )

    const labTestLogs = entities.labTestsDashboardRecords[result].tests

    const fileGroups: LabTestLogFileGroup[][] = yield all(
      labTestLogs.map((log: LabTestLog) =>
        // @ts-ignore
        call(requestAPI, API.fetchLabTestLogFileGroups, log.id),
      ),
    )

    const map = labTestLogs.reduce(
      (
        acc: Record<string, LabTestLogFile[]>,
        log: LabTestLog,
        index: number,
      ) => {
        const files = fileGroups[index].flatMap((group) =>
          group.files.map((file) => ({
            ...file,
            groupId: group.id,
          })),
        )
        acc[log.id] = files
        return acc
      },
      {},
    )

    entities.labTestsDashboardRecords[result].labTestLogFileGroupMap = map

    yield call(updateEntities, entities)
    yield put(fetchLabTestDetailsSuccess(result))
  } catch (error) {
    yield put(fetchLabTestDetailsFailure(error as ApiError))
  }
}

export function* saveLabOrderSaga({
  labOrder,
}: ReturnType<typeof saveLabOrder>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.saveLabOrder,
      prepareLabOrderForSaving(labOrder),
    )
    yield call(updateEntities, entities)
    // @ts-ignore
    yield call(fetchLabTestDetailsSaga, {
      vendorId: labOrder.vendorId,
      orderId: labOrder.order?.id,
      soapId: labOrder.soapId,
      invoiceId: labOrder.invoiceId,
      patientId: labOrder.patient,
      vetId: labOrder.vet?.id,
    })
    yield put(saveLabOrderSuccess(result))
  } catch (error) {
    yield put(saveLabOrderFailure(error as ApiError))
  }
}

function* getLabTestLogFileGroupInputSaga(
  attachmentGroup: FilesWithDetails,
  fileUrls: string[],
) {
  const files: CreateLabTestLogFileInput[] = yield call(
    mapFilesToObjects,
    attachmentGroup.files,
    fileUrls,
  )

  const authorId: string = yield select(getCurrentUserId)
  return {
    files,
    authorId,
    uploadDate: attachmentGroup.date,
    diagnosedIn: attachmentGroup.diagnosedIn,
    description: attachmentGroup.description,
    title: attachmentGroup.title,
  }
}

export function* createLabTestAttachmentFileGroupSaga({
  labTestLogId,
  attachmentGroup,
  clientId,
  patientId,
  labTestIdentifier,
}: ReturnType<typeof createLabTestAttachmentFileGroup>) {
  try {
    const fileUrls: string[] = yield uploadAssetList({
      files: attachmentGroup.files,
      destination: AssetDestination.PATIENT,
      payload: {
        personId: clientId,
        patientId,
        isPublic: false,
      },
    })

    const createLabTestLogFileGroupInput: CreateLabTestLogFileGroupInput =
      yield getLabTestLogFileGroupInputSaga(attachmentGroup, fileUrls)

    const attachment: LabTestLogFileGroup = yield* requestAPI(
      API.saveLabTestFileGroup,
      labTestLogId,
      createLabTestLogFileGroupInput,
    )

    const filesWithGroup = attachment.files.map((file) => ({
      ...file,
      groupId: attachment.id,
    }))

    yield put(
      createLabTestAttachmentFileGroupSuccess(
        labTestIdentifier,
        labTestLogId,
        filesWithGroup,
      ),
    )
  } catch (error) {
    yield put(createLabTestAttachmentFileGroupFailure(error as ApiError))
  }
}

export function* deleteLabTestAttachmentFileSaga({
  labTestIdentifier,
  labTestLogId,
  groupId,
  fileId,
}: ReturnType<typeof deleteLabTestAttachmentFile>) {
  try {
    yield put(
      optimisticDeleteLabTestAttachmentFile({
        labTestIdentifier,
        labTestLogId,
        fileId,
      }),
    )
    yield* requestAPI(API.deleteLabTestFile, labTestLogId, groupId, fileId)
    yield put(fetchTimelineAction())
    yield put(deleteLabTestAttachmentFileSuccess())
  } catch (error) {
    yield put(deleteLabTestAttachmentFileFailure(error as ApiError))
  }
}

function* watchFetchLabTestsList() {
  yield takeLatest(FETCH_LAB_TESTS_LIST, fetchLabTestsListSaga)
}

function* watchFetchMoreLabTests() {
  yield takeLatest(FETCH_MORE_LAB_TESTS, fetchMoreLabTestsSaga)
}

function* watchRefreshLabTestsList() {
  yield takeLatest(REFRESH_LAB_TESTS_LIST, refreshLabTestsListSaga)
}

function* watchAssignLabTest() {
  yield takeLeading(ASSIGN_LAB_TEST, assignLabTestSaga)
}

function* watchUpdateLabTestsLogs() {
  yield takeLeading(SAVE_LAB_ORDER, saveLabOrderSaga)
}

function* watchFetchLabTestsDetails() {
  yield takeLeading(FETCH_LAB_TEST_DETAILS, fetchLabTestDetailsSaga)
}

function* watchCreateLabTestAttachmentFileGroup() {
  yield takeLeading(
    CREATE_LAB_TEST_ATTACHMENT_FILE_GROUP,
    createLabTestAttachmentFileGroupSaga,
  )
}

function* watchDeleteLabTestAttachmentFile() {
  yield takeLeading(
    DELETE_LAB_TEST_ATTACHMENT_FILE,
    deleteLabTestAttachmentFileSaga,
  )
}

export default function* labTestsDashboardSaga() {
  yield all([
    watchAssignLabTest(),
    watchFetchLabTestsList(),
    watchFetchMoreLabTests(),
    watchRefreshLabTestsList(),
    watchUpdateLabTestsLogs(),
    watchFetchLabTestsDetails(),
    watchCreateLabTestAttachmentFileGroup(),
    watchDeleteLabTestAttachmentFile(),
  ])
}
