import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { PuiTheme } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import DocumentDialogStates from '~/constants/DocumentDialogStates'
import { fileFormat, getFileFormat } from '~/constants/extensions'
import { ConversationFile } from '~/types'
import useDialog from '~/utils/useDialog'

import MessagePlainAttachment from './MessagePlainAttachment'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    image: {
      marginTop: theme.spacing(1),
      maxHeight: 300,
      objectFit: 'scale-down',
      width: '100%',
    },
  }),
  { name: 'MessageAttachments' },
)

export interface MessageAttachmentsProps {
  attachments: ConversationFile[]
  isCurrentUserMessage?: boolean
}

const MessageAttachments = ({
  attachments,
  isCurrentUserMessage,
}: MessageAttachmentsProps) => {
  const classes = useStyles()
  const [openDocumentDialog] = useDialog(DialogNames.DOCUMENT)

  const attachmentsWithFormat = attachments.map((file) => ({
    ...file,
    format: getFileFormat(file.extension),
  }))

  const plainFiles = R.reject(
    R.propEq('format', fileFormat.IMAGE),
    attachmentsWithFormat,
  )
  const imageFiles = R.filter(
    R.propEq('format', fileFormat.IMAGE),
    attachmentsWithFormat,
  )

  const onPreviewRequested = (file: ConversationFile) => {
    openDocumentDialog({
      previewOnly: true,
      document: file,
      PreviewProps: {
        hideTopButtons: true,
        view: true,
        showChangeFile: false,
      },
      step: DocumentDialogStates.PREVIEW,
      conversationId: file.conversationId,
    })
  }

  const contentJustify = isCurrentUserMessage ? 'flex-end' : 'flex-start'

  return (
    <Grid container>
      {!R.isEmpty(plainFiles) && (
        <Grid container item justifyContent="space-between">
          {plainFiles.map((file) => (
            <Grid item key={file.id} xs={plainFiles.length === 1 ? 12 : 6}>
              <MessagePlainAttachment
                file={file}
                onPreviewRequested={() => onPreviewRequested(file)}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {!R.isEmpty(imageFiles) && (
        <Grid container item justifyContent={contentJustify}>
          {imageFiles.map((file) => (
            <Grid item key={file.id} xs={imageFiles.length === 1 ? 12 : 6}>
              <img
                alt={file.fileName}
                className={classes.image}
                src={file.fileUrl}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  )
}

export default MessageAttachments
