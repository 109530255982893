import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { PuiTheme } from '@pbt/pbt-ui-components'

import InfoList from '~/components/common/InfoList'

export type InfoListItem = {
  component?: React.ReactNode
  name: string
  value?: string | number
}

export interface ReadOnlyGlobalItemInfoListProps {
  items: InfoListItem[]
  size?: number
}

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tableItem: {
      margin: theme.spacing(0, 2, 0, 2),
      flexGrow: 1,
    },
  }),
  { name: 'ReadOnlyGlobalItemInfoList' },
)

const ReadOnlyGlobalItemInfoList = ({
  items,
  size = 4,
}: ReadOnlyGlobalItemInfoListProps) => {
  const classes = useStyles()
  const chunkedVariationInfoItems = R.splitEvery(size, items).map(
    (chunk, index) => ({ id: index, chunk }),
  )

  return (
    <Grid container alignItems="flex-start" direction="row">
      {chunkedVariationInfoItems.map(({ chunk, id }) => (
        <InfoList
          className={classes.tableItem}
          container={false}
          items={chunk}
          key={id}
        />
      ))}
    </Grid>
  )
}

export default ReadOnlyGlobalItemInfoList
