import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  PersonOutlined as PersonOutlinedIcon,
  Tune as TuneIcon,
} from '@mui/icons-material'
import { Grid, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Nil,
  PermissionArea,
  PuiTextArea,
  PuiTextField,
  PuiTheme,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import UserSelect from '~/components/common/inputs/UserSelect'
import { DEFAULT_APPOINTMENT_NAME } from '~/constants/schedulerConstants'
import { getCRUDByArea } from '~/store/reducers/auth'
import { DataHandle, TeamFilter, TimetableEvent } from '~/types'
import useFieldsChanged, { FieldCache } from '~/utils/useFieldsChanged'

import AppointmentDateSection, {
  AppointmentDateSectionHandle,
} from './AppointmentDateSection'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
    },
    icon: {
      color: theme.colors.selectedOption,
    },
  }),
  { name: 'Busy' },
)

export interface BusyProps {
  appointment: TimetableEvent | Nil
  appointmentTypeId: string
  onFieldsChange?: (changedFields: FieldCache) => void
  personId?: string
}

export interface BusyHandle extends DataHandle {}

const Busy = forwardRef(function Busy(
  { appointment, appointmentTypeId, personId, onFieldsChange = R.F }: BusyProps,
  ref: ForwardedRef<BusyHandle>,
) {
  const classes = useStyles()
  const appointmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )
  const { t } = useTranslation('TimeTable')

  const appointmentDateSectionRef = useRef<AppointmentDateSectionHandle>(null)

  const { fields, validate } = useFields(
    [
      {
        name: 'title',
        initialValue:
          !appointment?.name || appointment?.name === DEFAULT_APPOINTMENT_NAME
            ? ''
            : appointment?.name,
      },
      {
        name: 'person',
        label: t('Common:TEAM_MEMBER'),
        initialValue: appointment?.personId || personId || '',
      },
      { name: 'notes', initialValue: appointment?.notes || '' },
    ],
    false,
  )

  const { title, person, notes } = fields

  useFieldsChanged(onFieldsChange, fields)

  useImperativeHandle(ref, () => ({
    validate: () =>
      validate() && (appointmentDateSectionRef.current?.validate() ?? true),
    get: () => ({
      type: appointmentTypeId,
      name: title.value || DEFAULT_APPOINTMENT_NAME,
      person: person.value || null,
      notes: notes.value,
      ...appointmentDateSectionRef.current?.get(),
    }),
  }))

  return (
    <Grid container item className={classes.root} pb={2} px={3} rowSpacing={1}>
      <Grid item xs={12}>
        <Grid
          container
          item
          alignItems="center"
          columnSpacing={1}
          wrap="nowrap"
          xs={5}
        >
          <Grid item mt={3}>
            <TuneIcon className={classes.icon} />
          </Grid>
          <Grid item xs>
            <PuiTextField
              disabled={!appointmentPermissions.update}
              field={title}
              label={t('Common:TITLE')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          item
          alignItems="center"
          columnSpacing={1}
          wrap="nowrap"
          xs={3}
        >
          <Grid item mt={1}>
            <PersonOutlinedIcon className={classes.icon} />
          </Grid>
          <Grid item xs>
            <UserSelect
              disabled={!appointmentPermissions.update}
              field={person}
              label={person.label}
              teamFilter={TeamFilter.ALL}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={6}>
          <AppointmentDateSection
            appointment={appointment}
            appointmentTypeId={appointmentTypeId}
            ref={appointmentDateSectionRef}
            onFieldsChange={onFieldsChange}
          />
        </Grid>
      </Grid>
      <Grid item mt={1} xs={12}>
        <InputLabel htmlFor="notes-input">
          <Text strong variant="subheading3">
            {t('Common:NOTES')}
          </Text>
        </InputLabel>
        <PuiTextArea
          multiline
          disabled={!appointmentPermissions.update}
          field={notes}
          id="notes-input"
          margin="none"
          maxRows={6}
          minRows={1}
        />
      </Grid>
    </Grid>
  )
})

export default Busy
