import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ControlButtonGroup,
  ControlButtonGroupName,
  DateFormat,
  LanguageUtils,
  moment,
  PermissionArea,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import Link from '~/components/common/link/Link'
import { vitalsTableConfig } from '~/components/dashboard/soapV2/vitals/config/vitalsTableConfig'
import FeatureToggle from '~/constants/featureToggle'
import { getMultipleVitals } from '~/store/duck/vitals'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { VitalTimelineItem } from '~/types'
import { addOriginalBusinessId } from '~/utils'

import VitalValueLabel from './VitalValueLabel'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tableCellBody: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
    },
    soapLink: {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    headerCell: {
      fontWeight: 500,
    },
    cell: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
      padding: theme.spacing(1, 2),
    },
    timeCell: {
      borderRight: theme.constants.tableBorder,
      width: 150,
      color: theme.colors.primaryText,
    },
    highlightedRow: {
      backgroundColor: theme.colors.tableOddRowBackground,
    },
  }),
  { name: 'MultipleVitals' },
)

export interface MultipleVitalsProps {
  enableBusinessShareIcon?: boolean
  item: VitalTimelineItem
  onDelete: (id: string) => void
  onEdit: (id: string) => void
}

const MultipleVitals = ({
  item: { entries, date },
  onDelete,
  onEdit,
  enableBusinessShareIcon,
}: MultipleVitalsProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const permissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const vitals = useSelector(getMultipleVitals(entries))
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  return (
    <Table>
      <TableBody>
        {vitalsTableConfig.map((configItem) => {
          const filteredEntries = vitals.filter((entry) => entry[configItem.id])
          const hasFilteredEntries = filteredEntries.length > 0
          return (
            <React.Fragment key={configItem.id}>
              {hasFilteredEntries && (
                <TableRow>
                  <TableCell
                    className={classNames(classes.cell, classes.headerCell)}
                    colSpan={3}
                  >
                    {LanguageUtils.getTranslatedFieldName(configItem)}
                  </TableCell>
                </TableRow>
              )}
              {filteredEntries.map((entry, index) => {
                const momentDate = moment(entry.date)
                const isDifferentDay = !momentDate.isSame(moment(date), 'day')
                const formattedDate = momentDate.format(
                  DateFormat.FULL_TIME_WITH_MERIDIAN,
                )

                const vitalSection = entry[configItem.id]
                const hasSoap = Boolean(entry.soapId)
                return (
                  <TableRow
                    className={classNames({
                      [classes.highlightedRow]: index % 2 !== 0,
                    })}
                    key={entry.id}
                  >
                    <TableCell
                      className={classNames(classes.cell, classes.timeCell)}
                    >
                      {isDifferentDay && (
                        <Text strong variant="subheading5">
                          {momentDate.format(DateFormat.DATE_WITHOUT_YEAR)}
                        </Text>
                      )}
                      {formattedDate}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Grid container alignItems="center" wrap="nowrap">
                        <Grid item>
                          <VitalValueLabel
                            vital={entry}
                            vitalConfigItem={configItem}
                          />
                          {vitalSection?.notes && (
                            <Text variant="body2">{`${t('Common:NOTES')}: ${
                              vitalSection.notes
                            }`}</Text>
                          )}
                        </Grid>
                        {enableBusinessShareIcon && (
                          <BusinessShareIcon
                            businessIds={
                              entry.businessId ? [entry.businessId] : []
                            }
                          />
                        )}
                      </Grid>
                    </TableCell>
                    <TableCell align="right" className={classes.cell}>
                      {hasSoap && permissions.read && (
                        <Link
                          className={classNames(
                            classes.soapLink,
                            classes.tableCellBody,
                          )}
                          to={addOriginalBusinessId(
                            `/soap/${entry.soapId}`,
                            isPatientSharingEnabled ? entry.businessId : null,
                          )}
                        >
                          {t('Common:VIEW_SOAP')}
                        </Link>
                      )}
                      {!hasSoap && permissions.read && (
                        <Grid>
                          <ControlButtonGroup
                            buttons={[
                              onDelete && {
                                name: ControlButtonGroupName.DELETE,
                                onClick: () => onDelete(entry.id),
                              },
                              onEdit && {
                                name: ControlButtonGroupName.EDIT,
                                onClick: () => onEdit(entry.id),
                              },
                            ]}
                          />
                        </Grid>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </React.Fragment>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default MultipleVitals
