import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, PuiTheme } from '@pbt/pbt-ui-components'
import { Flag as FlagIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    flagIcon: {
      color: theme.colors.important,
    },
    controlled: {
      color: theme.colors.important,
    },
  }),
  { name: 'ControlledSubstanceLabel' },
)

export interface ControlledSubstanceLabelProps {
  classes?: ClassesType<typeof useStyles>
}

const ControlledSubstanceLabel = ({
  classes: classesProp,
}: ControlledSubstanceLabelProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  return (
    <Grid container alignItems="center" className={classes.root}>
      <FlagIcon className={classes.flagIcon} />
      <span className={classes.controlled}>
        {t('Common:CONTROLLED_SUBSTANCE')}
      </span>
    </Grid>
  )
}

export default ControlledSubstanceLabel
