/* eslint-disable react/no-danger */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { sanitize } from 'dompurify'
import { ClassesType, PuiTheme, Text } from '@pbt/pbt-ui-components'

import TermsAndConditionsItems from './TermsAndConditionsItems'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    firstSection: {},
    firstText: {},
    title: {},
    subtitle: {},
    text: {},
    sections: {},
    table: {
      borderCollapse: 'collapse',
      marginTop: theme.spacing(3),
    },
    cell: {
      border: '1px solid black',
      padding: theme.spacing(0, 1),
    },
    wideCell: {
      width: '60%',
    },
  }),
  { name: 'TermsAndConditionsContentSpain' },
)

const getArrayTextJoined = (arrayText: string | string[]) =>
  (Array.isArray(arrayText) && arrayText.join(' ')) || ''

export interface TermsAndConditionsContentSpainProps {
  classes: ClassesType<typeof useStyles>
  showTitle?: boolean
}

interface CookiesTableRow {
  CONSERVATION: string
  DESCRIPTION: string
  ID: string
  NAME: string
  SCOPE: string
  TRANSFER: string
  TYPE: string
}

interface TPCookiesTableRow extends CookiesTableRow {
  SUPPLIER: string
}

const TermsAndConditionsContentSpain = ({
  classes: classesProp,
  showTitle,
}: TermsAndConditionsContentSpainProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['TermsAndConditionsSpain', 'PrivacyNotice'])

  const TermsAndConditionsList = [
    {
      id: 1,
      subtitle: t(
        'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Definiciones.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Definiciones.TEXT',
        ),
      ),
    },
    {
      id: 2,
      subtitle: t(
        'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Servicios.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Servicios.TEXT',
        ),
      ),
    },
    {
      id: 3,
      subtitle: t(
        'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Restricciones.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Restricciones.TEXT',
        ),
      ),
    },
    {
      id: 4,
      subtitle: t(
        'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Obligaciones.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Obligaciones.TEXT',
        ),
      ),
    },
    {
      id: 5,
      subtitle: t(
        'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Niveles.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Niveles.TEXT',
        ),
      ),
    },
    {
      id: 6,
      subtitle: t(
        'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.TARIFAS.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.TARIFAS.TEXT',
        ),
      ),
    },
    {
      id: 7,
      subtitle: t(
        'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Confidencialidad.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Confidencialidad.TEXT',
        ),
      ),
    },
    {
      id: 8,
      subtitle: t(
        'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Derechos.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Derechos.TEXT',
        ),
      ),
    },
    {
      id: 9,
      subtitle: t(
        'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Tratamiento.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Tratamiento.TEXT',
        ),
      ),
    },
    {
      id: 10,
      subtitle: t(
        'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Manifestaciones.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Manifestaciones.TEXT',
        ),
      ),
    },
    {
      id: 11,
      subtitle: t(
        'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Indemnizacion.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Indemnizacion.TEXT',
        ),
      ),
    },
    {
      id: 12,
      subtitle: t(
        'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Limitacion.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Limitacion.TEXT',
        ),
      ),
    },
    {
      id: 13,
      subtitle: t(
        'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Vigencia.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Vigencia.TEXT',
        ),
      ),
    },
    {
      id: 14,
      subtitle: t(
        'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Disposiciones.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.Disposiciones.TEXT',
        ),
      ),
    },
  ]

  const PrivacyNoticeList = [
    {
      id: 1,
      subtitle: t(
        'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Hola.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t('PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Hola.TEXT'),
      ),
    },
    {
      id: 2,
      subtitle: t(
        'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Que.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t('PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Que.TEXT'),
      ),
    },
  ]

  const cookiesTableHeaders: string[] = t(
    'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.COOKIES_TABLE.HEADERS',
  )
  const cookiesTableRows: CookiesTableRow[] = t(
    'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.COOKIES_TABLE.ROWS',
  )

  const PrivacyNoticeList2 = [
    {
      id: 3,
      text: getArrayTextJoined(
        t('PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Si.TEXT'),
      ),
    },
  ]

  const tpCookiesTableHeaders: string[] = t(
    'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.THIRD_PARTY_COOKIES_TABLE.HEADERS',
  )

  const tpCookiesTableRows: TPCookiesTableRow[] = t(
    'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.THIRD_PARTY_COOKIES_TABLE.ROWS',
  )

  const PrivacyNoticeList3 = [
    {
      id: 4,
      text: getArrayTextJoined(
        t('PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Nosotros.TEXT'),
      ),
    },
    {
      id: 5,
      subtitle: t(
        'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Finalidad.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t('PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Finalidad.TEXT'),
      ),
    },
    {
      id: 6,
      subtitle: t(
        'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Divulgacion.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Divulgacion.TEXT',
        ),
      ),
    },
    {
      id: 7,
      subtitle: t(
        'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Transferencias.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Transferencias.TEXT',
        ),
      ),
    },
    {
      id: 8,
      subtitle: t(
        'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Durante.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t('PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Durante.TEXT'),
      ),
    },
    {
      id: 9,
      subtitle: t(
        'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Datos.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t('PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Datos.TEXT'),
      ),
    },
    {
      id: 10,
      subtitle: t(
        'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Otros.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t('PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Otros.TEXT'),
      ),
    },
    {
      id: 11,
      subtitle: t(
        'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Seguridad.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t('PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Seguridad.TEXT'),
      ),
    },
    {
      id: 12,
      subtitle: t(
        'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Modificaciones.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Modificaciones.TEXT',
        ),
      ),
    },
    {
      id: 13,
      subtitle: t(
        'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Menores.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t('PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Menores.TEXT'),
      ),
    },
    {
      id: 14,
      subtitle: t(
        'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Informacion.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.SECTIONS.Informacion.TEXT',
        ),
      ),
    },
  ]
  return (
    <Grid container direction="column">
      <Grid container className={classes.firstSection} direction="column">
        {showTitle && (
          <>
            <Text align="left" className={classes.title} variant="hero">
              {t('TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.TITLE')}
            </Text>
            <Text align="left" className={classes.title} variant="subheading">
              {t(
                'TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.UNDER_TITLE',
              )}
            </Text>
          </>
        )}

        <Text
          strong
          align="left"
          className={classes.subtitle}
          variant="subheading"
        >
          {t('TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.SUBTITLE')}
        </Text>

        <Text
          className={classNames(classes.text, {
            [classes.firstText]: showTitle,
          })}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: sanitize(
                t('TermsAndConditionsSpain:TERMS_AND_CONDITIONS_CONTENT.TEXT'),
              ),
            }}
          />
        </Text>
      </Grid>

      <Grid container className={classes.sections} direction="column">
        <TermsAndConditionsItems
          classes={classes}
          list={TermsAndConditionsList}
        />
      </Grid>
      <Grid container className={classes.sections} direction="column" pt={6}>
        <Text align="left" className={classes.title} variant="hero">
          {t('PrivacyNoticeSpain:PRIVACY_NOTICE_CONTENT.TITLE')}
        </Text>
        <TermsAndConditionsItems classes={classes} list={PrivacyNoticeList} />

        <table className={classes.table}>
          <tr>
            {cookiesTableHeaders.map((header) => (
              <th className={classes.cell} key={header}>
                <Text strong variant="body2">
                  {header}
                </Text>
              </th>
            ))}
          </tr>
          {cookiesTableRows.map((row) => (
            <tr key={row.ID}>
              <td className={classes.cell}>
                <Text variant="body2">{row.TYPE}</Text>
              </td>
              <td className={classes.cell}>
                <Text variant="body2">{row.SCOPE}</Text>
              </td>
              <td className={classes.cell}>
                <Text variant="body2">{row.NAME}</Text>
              </td>
              <td className={classes.cell}>
                <Text variant="body2">{row.CONSERVATION}</Text>
              </td>
              <td className={classes.cell}>
                <Text variant="body2">{row.DESCRIPTION}</Text>
              </td>
              <td className={classes.cell}>
                <Text variant="body2">{row.TRANSFER}</Text>
              </td>
            </tr>
          ))}
        </table>

        <TermsAndConditionsItems classes={classes} list={PrivacyNoticeList2} />

        <table className={classes.table}>
          <tr>
            {tpCookiesTableHeaders.map((header) => (
              <th className={classes.cell} key={header}>
                <Text strong variant="body2">
                  {header}
                </Text>
              </th>
            ))}
          </tr>
          {tpCookiesTableRows.map((row) => (
            <tr key={row.ID}>
              <td className={classes.cell}>
                <Text variant="body2">{row.TYPE}</Text>
              </td>
              <td className={classes.cell}>
                <Text variant="body2">{row.SUPPLIER}</Text>
              </td>
              <td className={classes.cell}>
                <Text variant="body2">{row.SCOPE}</Text>
              </td>
              <td className={classes.cell}>
                <Text variant="body2">{row.NAME}</Text>
              </td>
              <td className={classes.cell}>
                <Text variant="body2">{row.CONSERVATION}</Text>
              </td>
              <td className={classes.cell}>
                <Text variant="body2">{row.DESCRIPTION}</Text>
              </td>
              <td className={classes.cell}>
                <Text variant="body2">{row.TRANSFER}</Text>
              </td>
            </tr>
          ))}
        </table>

        <TermsAndConditionsItems classes={classes} list={PrivacyNoticeList3} />
      </Grid>
    </Grid>
  )
}

export default TermsAndConditionsContentSpain
