import { DashboardLabTestStateConstant } from '@pbt/pbt-ui-components'

import { LabTestDashboardItem } from '~/types'

export const getLabTestComplexId = (
  dashboardLabTestStateConstant: DashboardLabTestStateConstant,
) =>
  `${dashboardLabTestStateConstant?.labTestStateId || ''}|${
    dashboardLabTestStateConstant?.orderStateId || ''
  }`

export const getLabTestSimpleId = (complexId: string) => complexId.split('|')

export const getLabTestIdentifier = ({
  order,
  vendorId,
  soapId,
  invoiceId,
  patient: patientId,
  vet,
}: LabTestDashboardItem) =>
  order?.id
    ? `V:${vendorId}-O:${order.id}`
    : soapId
    ? `V:${vendorId}-S:${soapId}`
    : `V:${vendorId}-I:${invoiceId}-P:${patientId}${
        vet?.id ? `-AV:${vet.id}` : ''
      }`

export const parseLabTestIdentifier = (str: string) => {
  const [part1, part2, part3, part4] = str.split('-')
  return {
    vendorId: /^V:(.*)$/.exec(part1)?.[1],
    orderId: part2[0] === 'O' ? /^O:(.*)$/.exec(part2)?.[1] : undefined,
    soapId: part2[0] === 'S' ? /^S:(.*)$/.exec(part2)?.[1] : undefined,
    invoiceId: part2[0] === 'I' ? /^I:(.*)$/.exec(part2)?.[1] : undefined,
    patientId: part3?.[0] === 'P' ? /^P:(.*)$/.exec(part3)?.[1] : undefined,
    vetId: part4?.[0] === 'A' ? /^AV:(.*)$/.exec(part4)?.[1] : undefined,
  }
}
