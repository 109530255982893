import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import { ControlButtonGroupName, PuiTheme } from '@pbt/pbt-ui-components'
import {
  Download as DownloadIcon,
  Email as EmailIcon,
  Print as PrintIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { PopperAction } from '~/components/common/ActionsPopper'
import ActionsButton from '~/components/common/buttons/ActionsButton'
import { GeneratingPdfContentKind } from '~/constants/communications'
import DialogNames from '~/constants/DialogNames'
import i18n from '~/locales/i18n'
import { getExpandedGroups } from '~/store/duck/clientFinanceData'
import { useDownloadContentAsPdf } from '~/store/hooks/finance'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    dotsButton: {
      width: 40,
      height: 40,
      border: theme.constants.filterBorder,
      color: theme.colors.searchButton,
      boxShadow: theme.constants.buttonBlockShadow,
    },
  }),
  { name: 'FinanceDropdownActions' },
)

interface FinanceDropdownActionsProps {
  actions: PopperAction[]
  showDefaultActions?: boolean
}

const FinanceDropdownActions = ({
  actions,
  showDefaultActions = true,
}: FinanceDropdownActionsProps) => {
  const classes = useStyles()
  const { clientId } = useParams()
  const expandedGroups = useSelector(getExpandedGroups)

  const [openEmailChargeSheetDialog] = useDialog(DialogNames.EMAIL_CHARGE_SHEET)
  const [openPrintChargeSheetDialog] = useDialog(DialogNames.PRINT_CHARGE_SHEET)

  const [generatePdf, isGenerating] = useDownloadContentAsPdf(
    GeneratingPdfContentKind.CHARGE_SHEET,
  )

  const defaultActions: PopperAction = {
    id: 'finance-drop-down-actions-default',
    isGroup: true,
    title: i18n.t('Common:MANAGE'),
    items: [
      {
        id: ControlButtonGroupName.DOWNLOAD,
        label: i18n.t('Common:DOWNLOAD_ACTION'),
        Icon: DownloadIcon,
        onClick: () => {
          generatePdf({ clientId, expandedGroups })
        },
      },
      {
        id: ControlButtonGroupName.EMAIL,
        label: i18n.t('Common:EMAIL_ACTION'),
        Icon: EmailIcon,
        onClick: () => {
          openEmailChargeSheetDialog({
            clientId,
            expandedGroups,
          })
        },
      },
      {
        id: ControlButtonGroupName.PRINT,
        label: i18n.t('Common:PRINT_ACTION'),
        Icon: PrintIcon,
        onClick: () => {
          openPrintChargeSheetDialog({ clientId, expandedGroups })
        },
      },
    ],
  }

  return (
    <ActionsButton
      actions={
        showDefaultActions ? [...actions, { ...defaultActions }] : actions
      }
      className={classes.dotsButton}
      isLoading={isGenerating}
      placement="bottom-end"
    />
  )
}

export default FinanceDropdownActions
