import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  Business,
  ClassesType,
  Nil,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'
import {
  Edit as EditIcon,
  UploadImage as UploadImageIcon,
} from '@pbt/pbt-ui-components/src/icons'

import DialogNames from '~/constants/DialogNames'
import { BusinessDto } from '~/store/dto/Business'
import useDialog from '~/utils/useDialog'

import ClinicLogoUpload from '../../registration/ClinicLogoUpload'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      border: '1px solid rgba(229,229,229,0.5)',
      fontSize: 14,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.down('md')]: {
        margin: '0 auto',
        padding: 0,
      },
      textAlign: 'center',
    },
    uploadRoot: {
      backgroundColor: '#E5E5E5',
      padding: '22px 15px 22px 15px',
      [theme.breakpoints.down('md')]: {
        padding: '11px 7px 11px 7px',
      },
    },
    uploadImageIcon: {
      fontSize: '4rem',
      marginBottom: 10,
    },
    uploadImageText: {
      userSelect: 'none',
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    uploadSuggestGrid: {
      cursor: 'pointer',
    },
    logoContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      padding: theme.spacing(0.5),
    },
    iconButton: {
      position: 'absolute',
      top: -7,
      left: -15,
      borderRadius: '50%',
      backgroundColor: theme.colors.editIconColor,
      padding: theme.spacing(0.5),
      color: theme.colors.badgeColor,
    },
  }),
  { name: 'ClinicLogo' },
)

export interface ClinicLogoProps {
  allowUpload?: boolean
  className?: string
  classes?: ClassesType<typeof useStyles>
  clinic: Business | BusinessDto | Nil
  component?: React.ElementType
  height?: number
  isLoading?: boolean
  onLogoUploaded?: (logo: string) => void
  onUploadRequested?: () => void
  width?: number
}

const ClinicLogo = ({
  classes: classesProp,
  className,
  clinic,
  allowUpload,
  onUploadRequested: onUploadRequestedProp,
  component: Component = 'div',
  isLoading,
  onLogoUploaded,
  width = 104,
  height = 56,
}: ClinicLogoProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const [openUploadImageDialog] = useDialog(DialogNames.UPLOAD_IMAGE)

  const onUploadRequested =
    onUploadRequestedProp ||
    (() => {
      openUploadImageDialog({
        Component: ClinicLogoUpload,
        onUploaded: onLogoUploaded,
      })
    })

  if (isLoading) {
    return <Skeleton height={height} variant="rectangular" width={width} />
  }

  let content
  if (clinic?.logo) {
    content = (
      <Grid
        container
        alignItems="center"
        className={classes.logoContainer}
        justifyContent="center"
      >
        <img alt="logotype" className={classes.image} src={clinic?.logo} />
        {allowUpload && (
          <IconButton
            className={classes.iconButton}
            color="inherit"
            size="large"
            onClick={onUploadRequested}
          >
            <EditIcon />
          </IconButton>
        )}
      </Grid>
    )
  } else if (allowUpload) {
    content = (
      <Grid
        container
        alignItems="center"
        className={classes.uploadSuggestGrid}
        direction="column"
        onClick={onUploadRequested}
      >
        <UploadImageIcon className={classes.uploadImageIcon} />
        <Text strong className={classes.uploadImageText} variant="lowAccent2">
          {t('Common:CHOOSE_YOUR_LOGO')}
        </Text>
      </Grid>
    )
  } else {
    content = (
      <Dotdotdot clamp={2}>
        <Text strong variant="body2">
          {clinic?.name}
        </Text>
      </Dotdotdot>
    )
  }

  return (
    <Component
      className={classNames(classes.root, className, {
        [classes.uploadRoot]: allowUpload && !clinic?.logo,
      })}
    >
      {content}
    </Component>
  )
}

export default ClinicLogo
