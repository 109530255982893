import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Constant,
  LanguageUtils,
  PuiTheme,
  Text,
  TextWithTooltip,
  useFields,
} from '@pbt/pbt-ui-components'

import { getAlertColor } from '~/store/reducers/constants'
import {
  BasePracticeDetailsSectionProps,
  PracticeAlertConfigurationFields,
} from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    labelRoot: {
      marginLeft: 0,
    },
    radio: {
      padding: theme.spacing(0.75),
    },
  }),
  { name: 'AlertConfiguration' },
)

const AlertConfiguration = ({ business }: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Businesses')

  const AlertColor: Constant[] = useSelector(getAlertColor)

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'patientAlertColorId',
        label: t('Businesses:ALERT_CONFIGURATION.PATIENT_ALERT_DEFAULT_COLOR'),
        initialValue: business.patientAlertColorId || '',
      },
      {
        name: 'clientAlertColorId',
        label: t('Businesses:ALERT_CONFIGURATION.CLIENT_ALERT_DEFAULT_COLOR'),
        initialValue: business.clientAlertColorId || '',
      },
    ],
    false,
  )

  const { patientAlertColorId, clientAlertColorId } = fields

  usePracticeFieldsSection<PracticeAlertConfigurationFields>({
    business,
    fields,
    sectionName: PracticeDetailsPanels.ALERT_CONFIGURATION,
    validate,
    reset,
  })

  return (
    <Grid container wrap="nowrap">
      <Grid container item direction="column">
        <TextWithTooltip
          allowWrap
          strong
          tooltipText={t(
            'Businesses:ALERT_CONFIGURATION.YOU_CAN_CHOOSE_WHAT_COLOR_YOUR_ALERTS_ARE_BY_DEFAULT',
          )}
          variant="body2"
        >
          {patientAlertColorId.label}
        </TextWithTooltip>
        <RadioGroup
          value={patientAlertColorId.value}
          onChange={(_, value) => patientAlertColorId.setValue(value)}
        >
          {AlertColor.map((color) => (
            <FormControlLabel
              classes={{ root: classes.labelRoot }}
              control={<Radio className={classes.radio} />}
              key={color.id}
              label={LanguageUtils.capitalize(
                LanguageUtils.getTranslatedFieldName(color),
              )}
              value={color.id}
            />
          ))}
        </RadioGroup>
      </Grid>
      <Grid container item direction="column">
        <Text strong variant="body2">
          {clientAlertColorId.label}
        </Text>
        <RadioGroup
          value={clientAlertColorId.value}
          onChange={(_, value) => clientAlertColorId.setValue(value)}
        >
          {AlertColor.map((color) => (
            <FormControlLabel
              classes={{ root: classes.labelRoot }}
              control={<Radio className={classes.radio} />}
              key={color.id}
              label={LanguageUtils.capitalize(
                LanguageUtils.getTranslatedFieldName(color),
              )}
              value={color.id}
            />
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  )
}

export default memo(AlertConfiguration, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
