import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, PuiAlert, PuiTheme } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    containerTextOnly: {
      paddingTop: theme.spacing(3),
    },
  }),
  { name: 'SaveAsClientEmailDialog' },
)

interface SaveAsClientEmailDialogProps extends BasePuiDialogProps {
  email: string
  handleSaveEmailAndSend: () => void
  handleSendEmail: () => void
}

const SaveAsClientEmailDialog = ({
  onClose,
  email,
  handleSaveEmailAndSend,
  handleSendEmail,
  ...rest
}: SaveAsClientEmailDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const handleActionClick = () => {
    handleSaveEmailAndSend()
    if (onClose) {
      onClose()
    }
  }
  const handleCancelClick = () => {
    handleSendEmail()
    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiAlert
      cancelButtonText={t('Common:SEND_EMAIL')}
      classes={{ containerTextOnly: classes.containerTextOnly }}
      message={t('Dialogs:SAVE_AS_CLIENT_EMAIL_DIALOG.MESSAGE', {
        email: email || '',
      })}
      okButtonText={t('Common:SAVE_AND_SEND_EMAIL')}
      title={t('Dialogs:SAVE_AS_CLIENT_EMAIL_DIALOG.TITLE')}
      onCancel={handleCancelClick}
      onClose={onClose}
      onOk={handleActionClick}
      {...rest}
    />
  )
}

export default SaveAsClientEmailDialog
