import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PermissionArea,
  PuiDialog,
  PuiTextField,
  PuiTheme,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import TeamMemberSelect from '~/components/common/inputs/TeamMemberSelect'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { PrescriptionType } from '~/constants/prescription'
import { OrderType } from '~/constants/SOAPStates'
import {
  clearCurrentOrderDetails,
  clearPrescriptionForPrint,
  fetchOrderDetailsGrouped,
} from '~/store/actions/orders'
import { useGetVariationDescriptionString } from '~/store/hooks/orders'
import { useMainStaffRoles } from '~/store/hooks/useMainStaffRoles'
import { getCRUDByArea, getCurrentBusiness } from '~/store/reducers/auth'
import {
  getDrugDeliveryMethod,
  getDrugStrengthUnit,
  getFeatureToggle,
  getProductForm,
} from '~/store/reducers/constants'
import { getFinanceIsLoading } from '~/store/reducers/finance'
import {
  getCreatedOrder,
  getOrderDetailsGrouped,
  getOrderIsCreating,
  getOrdersIsSending,
  getPrescriptionForPrint,
} from '~/store/reducers/orders'
import { Order, Prescription } from '~/types'
import { useGetAreChargesPostedAndEditable } from '~/utils/finance'
import { getPrescriptionGlobalInventoryId } from '~/utils/prescription'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import {
  getOrderName,
  hasRefillChangedFromParentPrescription,
} from '../../soap/utils/prescriptionUtils'
import AdministrationSectionSimple, {
  AdministrationSectionSimpleHandle,
  copyAdministrationSimpleInstruction,
} from '../prescription-editor/AdministrationSectionSimple'
import ChargeSection, {
  ChargeSectionHandle,
} from '../prescription-editor/ChargeSection'
import { DispensedFromSectionHandle } from '../prescription-editor/DispensedFromSection'
import ParentPrescriptionSection from '../prescription-editor/ParentPrescriptionSection'
import PrescriptionDetailsSection, {
  copyPrescriptionDetailsSection,
  PrescriptionDetailsSectionHandle,
} from '../prescription-editor/PrescriptionDetailsSection'
import QuantitySection, {
  QuantitySectionHandle,
} from '../prescription-editor/QuantitySection'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
    parentSection: {
      paddingBottom: theme.spacing(1),
    },
    actions: {
      paddingLeft: theme.spacing(2),
    },
    button: {
      minWidth: 120,
      height: 40,
      marginRight: theme.spacing(1),
    },
    divider: {
      borderTop: theme.constants.tableBorder,
      width: `calc(100% + ${theme.spacing(6)})`,
      margin: theme.spacing(0, -3),
    },
  }),
  { name: 'BaseRefillDialog' },
)

export interface BaseRefillDialogProps extends BasePuiDialogProps {
  autoClose?: boolean
  handlePrint?: boolean
  onSave: (prescription: Prescription, options: any) => void
  outsideSoap?: boolean
  prescription: Prescription
  readonly?: boolean
  simpleAdministrationInstructions?: boolean
  simpleInstructions?: boolean
}

const BaseRefillDialog = ({
  open,
  outsideSoap,
  handlePrint = false,
  prescription,
  autoClose = true,
  readonly = false,
  simpleAdministrationInstructions = false,
  simpleInstructions = false,
  onClose,
  onSave,
}: BaseRefillDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const parent = prescription?.parent || prescription || {}
  const isCustomCompound = Boolean(prescription.drugInfo?.customCompound)

  const isInvoiceSending = useSelector(getFinanceIsLoading) && outsideSoap
  const isSending = useSelector(getOrdersIsSending) || isInvoiceSending
  const prescriptionForPrint = useSelector(getPrescriptionForPrint)
  const business = useSelector(getCurrentBusiness)
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const RolesList = useMainStaffRoles()

  const areChargesPostedAndEditable = useGetAreChargesPostedAndEditable(
    prescription.type,
    prescription.id,
  )
  const [openPrintInHouseOrderLabelDialog] = useDialog(
    DialogNames.PRINT_IN_HOUSE_ORDER_LABEL,
    () => dispatch(clearPrescriptionForPrint()),
  )

  const administrationSectionRef = useRef<
    AdministrationSectionSimpleHandle | PrescriptionDetailsSectionHandle
  >(null)
  const dispensedFromSectionRef = useRef<DispensedFromSectionHandle>(null)
  const quantitySectionRef = useRef<QuantitySectionHandle>(null)
  const chargeSectionRef = useRef<ChargeSectionHandle>(null)

  const copyAdministrationInstruction = simpleAdministrationInstructions
    ? copyAdministrationSimpleInstruction
    : copyPrescriptionDetailsSection

  const closeFoodRefillDialogAfterSave = useCloseAfterCreation(
    onClose,
    getOrdersIsSending,
  )

  const DrugStrengthUnit = useSelector(getDrugStrengthUnit)
  const ProductForm = useSelector(getProductForm)
  const DrugDeliveryMethod = useSelector(getDrugDeliveryMethod)
  const { create: prescriptionCreatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.PRESCRIPTION),
  )

  const inventoryId = prescription.inventory?.id
  const name =
    R.path(['inventory', 'name'], prescription) ||
    R.path(['name'], prescription) ||
    ''
  const title = t('Dialogs:PRESCRIPTION_DIALOG.REFILL_ACTION_NAME', { name })

  const orderDetailsGrouped = useSelector(getOrderDetailsGrouped)
  const variationObject = orderDetailsGrouped.find(
    ({ variation }) => variation && variation.id === prescription.variation?.id,
  )
  const { variation } = variationObject || {}
  const chargePrices = variationObject?.prices || []
  // in case current price was disabled and we are on refills section
  if (
    prescription?.price?.active === false &&
    chargePrices.some(({ id }) => id === prescription.price?.id)
  ) {
    chargePrices.push(prescription.price)
  }

  const subTitle = useGetVariationDescriptionString(
    variation,
    prescription.drugInfo?.flavor,
  )

  const [showRefillInstructionSection, setShowRefillInstructionSection] =
    useState(hasRefillChangedFromParentPrescription(parent, prescription))

  const lastCreatedPrescription = useSelector(getCreatedOrder)
  const [createdPrescription, setCreatedPrescription] = useState<Order | null>(
    null,
  )

  const handleAfterCreation = () => {
    setCreatedPrescription(lastCreatedPrescription)
  }

  const setPrescriptionAfterCreation = useCloseAfterCreation(
    handleAfterCreation,
    getOrderIsCreating,
    true,
  )

  useEffect(() => {
    const globalInventoryCommonOrMappingId = getPrescriptionGlobalInventoryId(
      prescription,
      isFoodCatalogEnabled,
    )
    if (inventoryId || globalInventoryCommonOrMappingId) {
      dispatch(
        fetchOrderDetailsGrouped({
          businessItemId: inventoryId,
          globalInventoryCommonOrMappingId,
        }),
      )
    }

    // cleanup current order on opening
    dispatch(clearCurrentOrderDetails())

    return () => {
      // cleanup current order on closing
      dispatch(clearCurrentOrderDetails())
    }
  }, [])

  useEffect(() => {
    if (handlePrint && prescriptionForPrint) {
      openPrintInHouseOrderLabelDialog({
        printInfo: {
          ...prescriptionForPrint,
          business,
        },
      })
    }
  }, [prescriptionForPrint])

  const {
    fields: { refillNotes, filledById },
    validate: validateFields,
  } = useFields(
    [
      {
        name: 'refillNotes',
        initialValue: prescription.refillNotes,
      },
      {
        name: 'filledById',
        label: t('Common:FILLED_BY'),
        initialValue: prescription.filledBy?.id,
      },
    ],
    false,
  )

  const validate = () => {
    const adminSectionValid = administrationSectionRef.current
      ? administrationSectionRef.current.validate()
      : true
    const quantitySectionValid = quantitySectionRef.current
      ? quantitySectionRef.current.validate()
      : true
    const chargeSectionValid = chargeSectionRef.current
      ? chargeSectionRef.current.validate()
      : true
    const fieldsAreValid = validateFields()

    return (
      adminSectionValid &&
      quantitySectionValid &&
      chargeSectionValid &&
      fieldsAreValid
    )
  }

  const handleSave = (
    options = {
      print: false,
    },
  ) => {
    if (validate()) {
      const prescriptionName = getOrderName(
        parent,
        ProductForm,
        DrugStrengthUnit,
        DrugDeliveryMethod,
        isFoodCatalogEnabled,
      )
      const prescriptionId =
        prescription?.id ||
        (createdPrescription?.name === prescriptionName
          ? createdPrescription?.id
          : undefined)

      const { quantity, priceId } = chargeSectionRef.current?.get() || {}

      const newPrescription = {
        id: prescriptionId,
        parentId: parent.id,
        name: prescriptionName,
        type: OrderType.PRESCRIPTION,
        prescriptionType: PrescriptionType.IN_HOUSE,
        inventoryId: parent.inventory?.id,
        variationId: parent.variation?.id,
        globalInventoryId: parent.globalInventory?.id,
        globalVariationId: parent.globalVariation?.id,
        ...(isFoodCatalogEnabled
          ? {
              globalInventoryMappingId: parent.globalInventoryMapping?.id,
              globalVariationMappingId: parent.globalVariationMapping?.id,
            }
          : {}),
        customCompound: false,
        discussedWithClient: parent.discussedWithClient || false,

        quantity,
        priceId,

        ...(quantitySectionRef.current?.get() || {}),
        ...(showRefillInstructionSection
          ? administrationSectionRef.current?.get() || {}
          : copyAdministrationInstruction(prescription)),

        doctorId: prescription.doctorId,
        patientId: prescription.patientId,
        clientId: prescription.clientId,

        ...(dispensedFromSectionRef.current?.get() || {}),
        soapId: parent.soapId,
        refillNotes: refillNotes.value,
        filledById: filledById.value,
        modificationDate: R.prop('modificationDate', prescription),
      }

      if (prescriptionId) {
        // add for compatibility with unified inventory
        newPrescription.previousEntityType = OrderType.PRESCRIPTION
      }

      if (prescription?.lineItem) {
        newPrescription.lineItem = { ...prescription.lineItem }

        if (prescription.lineItem.prepaid) {
          newPrescription.lineItem.usedQuantity = quantity
        } else {
          newPrescription.lineItem.quantity = quantity
        }
      }

      if (autoClose && !options.print) {
        closeFoodRefillDialogAfterSave()
      }

      setPrescriptionAfterCreation()
      onSave(newPrescription, {
        ...options,
        allowEditPostedItem: areChargesPostedAndEditable,
        doctorId: prescription.doctorId,
      })
    }
  }

  const hasNoPrices = chargePrices.length === 0
  const buttonsDisabled =
    !prescriptionCreatePermissions || isSending || hasNoPrices

  return (
    <PuiDialog
      actions={
        <>
          <ButtonWithLoader
            className={classes.button}
            disabled={buttonsDisabled || readonly}
            loading={isSending}
            onClick={() => handleSave()}
          >
            {t('Common:SAVE_ACTION')}
          </ButtonWithLoader>
          <ButtonWithLoader
            className={classes.button}
            disabled={buttonsDisabled}
            loading={isSending}
            onClick={() => handleSave({ print: true })}
          >
            {t('Common:PRINT_ACTION')}
          </ButtonWithLoader>
        </>
      }
      aria-labelledby="refill-dialog"
      classes={{
        actions: classes.actions,
        paper: classes.paper,
      }}
      open={open}
      scroll="paper"
      title={title}
      onClose={onClose}
    >
      <Grid container direction="column" px={3} py={2}>
        <Text mb={2} variant="body">
          {subTitle}
        </Text>

        <div className={classes.divider} />

        <Grid container>
          <ParentPrescriptionSection
            className={classes.parentSection}
            disabled={readonly}
            prescription={parent}
            showEditButton={!showRefillInstructionSection}
            simpleInstructions={simpleInstructions}
            onEdit={() => setShowRefillInstructionSection(true)}
          />

          <div className={classes.divider} />

          {showRefillInstructionSection && (
            <>
              <Text strong mt={2} variant="subheading3">
                {t('Dialogs:PRESCRIPTION_DIALOG.PRESCRIPTION_DETAILS')}
              </Text>
              {simpleAdministrationInstructions ? (
                <AdministrationSectionSimple
                  prescription={prescription}
                  ref={administrationSectionRef}
                />
              ) : (
                <PrescriptionDetailsSection
                  expandFormByDefault
                  isExpandable
                  prescription={prescription}
                  ref={administrationSectionRef}
                />
              )}
            </>
          )}

          <Grid container item>
            <Text strong mt={2} variant="subheading3">
              {t('Dialogs:PRESCRIPTION_DIALOG.REFILL_QUANTITY')}
            </Text>

            <QuantitySection
              isEdit
              dispensedFromSectionRef={dispensedFromSectionRef}
              isCustomCompound={isCustomCompound}
              prescription={prescription}
              ref={quantitySectionRef}
            />
          </Grid>

          <Text strong mb={1} mt={2} variant="subheading3">
            {t('Common:CHARGE_ACTION')}*
          </Text>

          <ChargeSection
            prescription={prescription}
            readonly={readonly}
            ref={chargeSectionRef}
          />

          <Grid container item>
            <PuiTextField
              multiline
              disabled={readonly}
              field={refillNotes}
              inputProps={{ maxLength: 1000 }}
              minRows={2}
              placeholder={t('Common:NOTES')}
              variant="outlined"
            />
          </Grid>

          <Grid container item>
            <Grid item xs={4}>
              <TeamMemberSelect
                disabled={readonly}
                field={filledById}
                label={filledById.label}
                roles={RolesList.map((role) => role.name)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default BaseRefillDialog
