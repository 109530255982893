import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme } from '@pbt/pbt-ui-components'
import { Flag as FlagIcon } from '@pbt/pbt-ui-components/src/icons'

import { InventoryItem } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    flagIcon: {
      color: theme.colors.important,
      width: 32,
      height: 32,
    },
  }),
  { name: 'InventoryTableFlagIcon' },
)

const InventoryTableFlagIcon = (inventory: InventoryItem) => {
  const classes = useStyles()

  if (!inventory.controlled) {
    return null
  }

  return <FlagIcon className={classes.flagIcon} />
}

export default InventoryTableFlagIcon
