import React, { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  LanguageUtils,
  PuiCheckbox,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    container: {
      '& > *': {
        flexShrink: 0,
      },
    },
    gropedCheckboxItem: {
      margin: theme.spacing(0, 1, 0, 0),
    },
  }),
  { name: 'PuiCheckboxList' },
)

type PuiCheckboxItem = {
  component?: React.ReactNode
  disabled?: boolean
  groupName?: string
  id: string
  name: string
}

interface PuiCheckboxListProps {
  allButtonLabel?: string
  className?: string
  disabled?: boolean
  grouped?: boolean
  hideAllButton?: boolean
  initialState?: string[]
  items?: PuiCheckboxItem[]
  onChange: (selection: string[]) => void
  singleSelection?: boolean
}

const groupItems = R.pipe(
  R.groupBy<any>(R.prop('groupName')),
  R.toPairs,
  R.map(([key, value]: [string, PuiCheckboxItem[]]) => [
    { groupName: key },
    ...value,
  ]),
  R.flatten,
)

const PuiCheckboxList = forwardRef<HTMLDivElement, PuiCheckboxListProps>(
  function PuiCheckboxList(
    {
      className,
      allButtonLabel,
      hideAllButton = false,
      grouped = false,
      initialState = [],
      items = [],
      onChange,
      singleSelection = false,
      disabled: disabledProp = false,
      ...rest
    },
    ref,
  ) {
    const classes = useStyles()
    const { t } = useTranslation('Common')
    const puiCheckboxListAllButtonLabel = allButtonLabel || t('Common:ALL')

    const [selectedOptions, setSelectedOptions] = useState(
      new Set(initialState),
    )
    const [allAreSelected, setAllAreSelected] = useState(
      selectedOptions.size === items.length,
    )

    const ids = R.pluck('id', items)

    useEffect(() => {
      setSelectedOptions(new Set(initialState))
    }, [initialState])

    const updateSelectionAndNotify = (newSelectionSet: Set<string>) => {
      setSelectedOptions(newSelectionSet)
      setAllAreSelected(newSelectionSet.size === items.length)

      // use same order as in items list
      onChange(ids.filter((id) => newSelectionSet.has(id)))
    }

    const handleSelectAllToggle = () => {
      const newSelectionSet = allAreSelected ? new Set([]) : new Set(ids)

      updateSelectionAndNotify(newSelectionSet)
    }

    const handleSelectionChange = (id: string) => {
      const newSelectionSet = new Set(singleSelection ? [] : selectedOptions)
      if (selectedOptions.has(id)) {
        newSelectionSet.delete(id)
      } else {
        newSelectionSet.add(id)
      }

      updateSelectionAndNotify(newSelectionSet)
    }

    const groupedItems = grouped ? groupItems(items) : null

    return (
      <Grid
        container
        className={classNames(className, classes.container)}
        direction="column"
        px={2}
        py={1}
        ref={ref}
        wrap="nowrap"
        {...rest}
      >
        {!hideAllButton && (
          <PuiCheckbox
            checked={allAreSelected}
            label={puiCheckboxListAllButtonLabel}
            onChange={handleSelectAllToggle}
          />
        )}
        {grouped
          ? groupedItems?.map((groupedItem: Partial<PuiCheckboxItem>) => {
              const { id, groupName, component, disabled } = groupedItem
              return id ? (
                <PuiCheckbox
                  checked={selectedOptions.has(id)}
                  classes={{ labelRoot: classes.gropedCheckboxItem }}
                  disabled={disabled || disabledProp}
                  key={id}
                  label={
                    component ||
                    LanguageUtils.getTranslatedFieldName(groupedItem)
                  }
                  onChange={() => handleSelectionChange(id)}
                />
              ) : (
                <Text key={groupName} variant="lowAccent2">
                  {LanguageUtils.getTranslatedFieldName(
                    groupedItem,
                    'groupName',
                    groupName,
                  )}
                </Text>
              )
            })
          : items.map((item) => {
              const { id, component, disabled } = item
              return (
                <PuiCheckbox
                  checked={selectedOptions.has(id)}
                  disabled={disabled || disabledProp}
                  key={id}
                  label={
                    component || LanguageUtils.getTranslatedFieldName(item)
                  }
                  onChange={() => handleSelectionChange(id)}
                />
              )
            })}
      </Grid>
    )
  },
)

export default PuiCheckboxList
