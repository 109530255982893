import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    quantityCell: {
      fontWeight: 400,
      paddingTop: 0,
      paddingBottom: 0,
      height: 24,
      color: theme.colors.tabLabel,
      fontSize: '1.4rem',
      borderBottom: theme.constants.tabBorder,
    },
    lowCell: {
      borderRight: 'none !important',
    },
  }),
  { name: 'QuantityCellEstimateRange' },
)

export interface QuantityCellEstimateRangeProps {
  className?: string
}

const QuantityCellEstimateRange = ({
  className,
}: QuantityCellEstimateRangeProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  return (
    <>
      <TableCell
        className={classNames(className, classes.quantityCell, classes.lowCell)}
      >
        {t('Common:LOW')}
      </TableCell>
      <TableCell className={classNames(className, classes.quantityCell)}>
        {t('Common:HIGH')}
      </TableCell>
    </>
  )
}

export default QuantityCellEstimateRange
