import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  Constant,
  Field,
  PuiSelect,
  PuiTextField,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import { isOtherUnitSelected } from '~/utils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    inputLabel: {
      width: '100%',
      fontWeight: 400,
    },
    other: {
      paddingLeft: theme.spacing(3),
    },
    unit: {},
  }),
  { name: 'ManualInputSelect' },
)

export interface ManualInputSelectProps {
  UnitComponent?: React.JSXElementConstructor<any>
  classes?: ClassesType<typeof useStyles>
  disabled?: boolean
  fields: {
    customUnit: Field
    unitId: Field
  }
  grid?: boolean
  isLoading?: boolean
  label?: string
  options?: Constant[]
  otherGridWidth?: number
  otherLabel?: string
  unitGridWidth?: number
}

const ManualInputSelect = ({
  UnitComponent,
  disabled,
  grid = true,
  fields: { customUnit, unitId },
  label,
  options: optionsProp,
  otherGridWidth,
  otherLabel: otherLabelProp,
  classes: classesProp,
  unitGridWidth = 3,
  isLoading,
}: ManualInputSelectProps) => {
  const classes = useStyles({ classes: classesProp })
  const options = optionsProp || []
  const isOtherUnit = isOtherUnitSelected(unitId, options)
  const { t } = useTranslation('Common')
  const otherLabel =
    otherLabelProp || `${t('Common:OTHER')} ${label?.toLowerCase() || ''}`

  return (
    <>
      <Grid item {...(grid ? { xs: unitGridWidth } : {})}>
        {UnitComponent ? (
          <UnitComponent
            className={classes.unit}
            disabled={disabled}
            field={unitId}
            label={label}
            options={options}
          />
        ) : (
          <FormControl
            fullWidth
            className={classes.unit}
            disabled={disabled}
            margin="none"
          >
            <InputLabel htmlFor={label}>{label}</InputLabel>
            <PuiSelect
              disabled={disabled}
              field={unitId}
              input={<Input id={label} />}
              isLoading={isLoading}
              items={options}
            />
          </FormControl>
        )}
      </Grid>
      {isOtherUnit && (
        <Grid
          item
          {...(grid ? { xs: otherGridWidth || true } : {})}
          className={classes.other}
        >
          <PuiTextField
            InputLabelProps={{
              className: classes.inputLabel,
              shrink: true,
            }}
            disabled={disabled}
            field={customUnit}
            inputProps={{ maxLength: 20 }}
            label={otherLabel}
            margin="none"
          />
        </Grid>
      )}
    </>
  )
}

export default ManualInputSelect
