import React, { forwardRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  PopperProps,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme } from '@pbt/pbt-ui-components'

import {
  disableTimetableAutoRefresh,
  enableTimetableAutoRefresh,
} from '~/store/actions/timetable'
import { getTimetableAutoRefreshEnabled } from '~/store/reducers/timetable'

import AppointmentPreview from './AppointmentPreview'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    popper: {
      minWidth: 315,
      maxWidth: 360,
      zIndex: theme.zIndex.modal,
      boxShadow: theme.constants.blockShadow,
    },
    popperClosed: {
      pointerEvents: 'none',
    },
    scrollablePaper: {
      overflowY: 'auto',
      overflowX: 'hidden',
      maxHeight: 700,
    },
    iconButton: {
      top: 2,
    },
    iconAlert: {
      position: 'relative',
      marginBottom: theme.spacing(-2),
      marginLeft: 'auto',
      display: 'flex',
    },
    addAppointment: {
      paddingBottom: 0,
    },
  }),
  { name: 'AppointmentPopper' },
)

export interface AppointmentPopperProps extends PopperProps {
  appointmentId: string
  isNewAppointmentBoxVisible?: boolean
  onClose: () => void
}

const AppointmentPopper = forwardRef<HTMLDivElement, AppointmentPopperProps>(
  function AppointmentPopper(
    {
      anchorEl,
      appointmentId,
      open,
      onClose,
      onMouseLeave,
      isNewAppointmentBoxVisible = true,
      onChange,
      ...rest
    },
    ref,
  ) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const timetableAutoRefreshEnabled = useSelector(
      getTimetableAutoRefreshEnabled,
    )

    const onClickAway = (event: MouseEvent | TouchEvent) => {
      const root = document.getElementById('root') as HTMLDivElement
      const target = event.target as HTMLDivElement
      const anchorElement = anchorEl as HTMLDivElement

      if (
        !event ||
        (!anchorElement.contains(target) && root.contains(target))
      ) {
        onClose()
      }
    }

    const onMouseLeaveCheck = (event: React.MouseEvent<HTMLDivElement>) => {
      // Don't trigger event when moving to child elements in Safari
      if (
        onMouseLeave &&
        event.relatedTarget instanceof Node &&
        !event.currentTarget.contains(event.relatedTarget)
      ) {
        onMouseLeave(event)
      }
    }

    useEffect(
      () => () => {
        // unmounted since the appointment was deleted
        onClose()
      },
      [],
    )

    useEffect(() => {
      if (open && timetableAutoRefreshEnabled) {
        dispatch(disableTimetableAutoRefresh())
      } else if (!open && !timetableAutoRefreshEnabled) {
        dispatch(enableTimetableAutoRefresh())
      }
    }, [open])

    return (
      <Popper
        transition
        anchorEl={anchorEl}
        className={classNames(classes.popper, {
          [classes.popperClosed]: !open,
        })}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [8, 8],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              altAxis: true,
              tether: false,
            },
          },
        ]}
        open={open}
        placement="right"
        onMouseLeave={onMouseLeaveCheck}
        {...rest}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={onClickAway}
          >
            <Grow
              {...TransitionProps}
              id="appointment-popper-grow"
              style={{ transformOrigin: 'center bottom' }}
            >
              <Paper
                className={classNames({
                  [classes.scrollablePaper]: !isNewAppointmentBoxVisible,
                })}
              >
                <AppointmentPreview
                  appointmentId={appointmentId}
                  ref={ref}
                  onClose={onClose}
                />
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    )
  },
)

export default AppointmentPopper
