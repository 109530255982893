import React from 'react'
import { Input } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ClassesType,
  Nil,
  PuiSelect,
  PuiSelectProps,
  PuiTheme,
  Utils,
} from '@pbt/pbt-ui-components'

import PuiStateSelectItem from './PuiStateSelectItem'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      padding: theme.spacing(0.25, 0.5),
      fontSize: '1.6rem',
      fontWeight: 500,
      borderRadius: 2,
    },
    inProgress: {
      backgroundColor: theme.colors.newLabel,
      color: theme.colors.title,
    },
    warning: {
      backgroundColor: theme.colors.actionNeededBackground,
      color: theme.colors.important,
    },
    success: {
      backgroundColor: theme.colors.successBackground,
      color: theme.colors.success,
    },
  }),
  { name: 'PuiStateSelect' },
)

export interface PuiStateSelectProps extends Omit<PuiSelectProps, 'classes'> {
  classes?: ClassesType<typeof useStyles>
  value: string | Nil
}

const PuiStateSelect = ({
  className,
  items,
  value,
  classes: classesProp,
  ...rest
}: PuiStateSelectProps) => {
  const classes = useStyles({ classes: classesProp })

  const { isDone, hasIssue } = Utils.findById(value, items || []) || {}

  return (
    <PuiSelect
      // @ts-ignore
      Item={PuiStateSelectItem}
      className={classNames(
        className,
        classes.root,
        isDone && classes.success,
        hasIssue && classes.warning,
        !isDone && !hasIssue && classes.inProgress,
      )}
      input={<Input disableUnderline id="state-select" />}
      items={items}
      renderEmpty={false}
      value={value}
      {...rest}
    />
  )
}

export default PuiStateSelect
