import React from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'
import { Plus as PlusIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    rangeSpan: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inlineSpan: {
      verticalAlign: 'middle',
      display: 'inline-flex',
    },
    gutter: {
      marginLeft: theme.spacing(1),
    },
    icon: {
      color: theme.colors.title,
    },
    closeIcon: {
      fontSize: '1.6rem',
    },
  }),
  { name: 'PrePaidButton' },
)

export interface PrePaidButtonProps {
  inline?: boolean
  isPrePaid?: boolean
  togglePrePaid: () => void
}

const PrePaidButton = ({
  inline,
  isPrePaid,
  togglePrePaid,
}: PrePaidButtonProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Tooltips'])

  const title: string = isPrePaid
    ? t('Tooltips:REMOVE_PRE-PAID')
    : t('Tooltips:ADD_PRE-PAID')

  return (
    <Tooltip placement="top" title={title}>
      <Text
        link
        className={classNames(classes.rangeSpan, {
          [classes.gutter]: isPrePaid,
          [classes.inlineSpan]: inline,
        })}
        variant="h5"
        onClick={togglePrePaid}
      >
        {isPrePaid ? (
          <CloseIcon className={classNames(classes.closeIcon, classes.icon)} />
        ) : (
          <PlusIcon className={classes.icon} />
        )}
        {t('Common:PRE-PAID').toLowerCase()}
      </Text>
    </Tooltip>
  )
}

export default PrePaidButton
