import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

import { NumberStepper } from '~/components/common/NumberStepper'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { useIsDrug } from '~/store/hooks/orders'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Prescription, ShipmentItem, Variation } from '~/types'
import { getPrescriptionName } from '~/utils/prescription'
import useDialog from '~/utils/useDialog'

import { ShipmentItemSearchCriteria } from '../../soap/order/vaccine/ChooseShipmentItemDialog'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tableHeading: {
      verticalAlign: 'top',
      padding: theme.spacing(1, 2),
    },
    tableViewport: {
      border: theme.constants.tableBorder,
    },
    tableCell: {
      padding: theme.spacing(0.5, 2),
      border: 'none',
    },
    tableRow: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
      '&:last-of-type': {
        borderTop: theme.constants.tableBorder,
        backgroundColor: theme.colors.tableBackground,
      },
    },
  }),
  { name: 'DispensedFromSectionNew' },
)

export interface DispensedFromSectionProps {
  onFieldsChange: (buttonVisible?: boolean) => void
  prescription: Prescription
}

export interface DispensedFromSectionHandleNew {
  chooseFromInventory: () => void
  get: () => DispensedItem[]
}

export type DispensedItem = {
  expirationDate?: string
  inventoryLineItemQuantity?: number | string
  inventoryShipmentItemId: string
  lotNumber?: string
  serialNumber?: string
}

const DispensedFromSectionNew = forwardRef<
  DispensedFromSectionHandleNew,
  DispensedFromSectionProps
>(function DispensedFromSection({ onFieldsChange, prescription }, ref) {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const [openShipmentItemsDialog] = useDialog(
    DialogNames.CHOOSE_SHIPMENT_ITEM_DIALOG,
  )

  const [items, setItems] = useState<DispensedItem[]>([])

  useEffect(() => {
    onFieldsChange()
  }, [items])

  const variationId = prescription?.variation?.id

  const onInventoryChange = (props: ShipmentItem[] | ShipmentItem) => {
    if (!Array.isArray(props)) return
    const lineItems = props.map((item) => ({
      inventoryShipmentItemId: item?.id,
      inventoryLineItemQuantity: item?.amount,
      lotNumber: item?.lotNumber,
      serialNumber: item?.serialNumber,
      expirationDate: item?.expirationDate,
    }))
    setItems(lineItems)
  }
  const isDrug = useIsDrug(prescription)

  const handleChooseFromInventory = () => {
    openShipmentItemsDialog({
      order: {
        name: getPrescriptionName(prescription, isFoodCatalogEnabled) || '',
        variation: { id: variationId } as Variation,
      },
      showToggle: !isDrug,
      isMultiSelect: true,
      selectedItems: (items || []).map((item) => ({
        id: item?.inventoryShipmentItemId,
        amount: Number(item?.inventoryLineItemQuantity) || 0,
        lotNumber: item?.lotNumber,
        serialNumber: item?.serialNumber,
        expirationDate: item?.expirationDate,
      })),
      onSelect: onInventoryChange,
      searchCriteria: ShipmentItemSearchCriteria.INVENTORY,
    })
  }

  const handleAmountChange = (id: string, amount: number) => {
    setItems((prevItems) =>
      prevItems?.map((item) =>
        item.inventoryShipmentItemId === id
          ? { ...item, inventoryLineItemQuantity: amount }
          : item,
      ),
    )
  }

  const handleDelete = (id: string) => {
    setItems((prevItems) =>
      prevItems.filter((item) => item.inventoryShipmentItemId !== id),
    )
  }

  useImperativeHandle(ref, () => ({
    get: () => items,
    chooseFromInventory: handleChooseFromInventory,
  }))

  if (!items?.length) {
    return null
  }

  const totalAmount = items?.reduce(
    (acc, item) => acc + (Number(item.inventoryLineItemQuantity) || 0),
    0,
  )

  return (
    <Table className={classes.tableViewport}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeading}>
            <Text strong variant="lowAccent2">
              {t('Common:LOT_SHIPMENT_NUMBER')}
            </Text>
          </TableCell>
          <TableCell className={classes.tableHeading}>
            <Text strong variant="lowAccent2">
              {t('Common:SERIAL_NUMBER_SIGN')}
            </Text>
          </TableCell>
          <TableCell />
          <TableCell align="right" className={classes.tableHeading}>
            <Text strong variant="lowAccent2">
              {t('Common:QUANTUTY_DISPENSED')}
            </Text>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((row) => (
          <TableRow className={classes.tableRow} key={row.lotNumber}>
            <TableCell className={classes.tableCell}>{row.lotNumber}</TableCell>
            <TableCell className={classes.tableCell}>
              {row.serialNumber}
            </TableCell>
            <TableCell className={classes.tableCell}>
              <IconButton
                aria-label={t('Common:DELETE_ACTION')}
                onClick={() => handleDelete(row.inventoryShipmentItemId)}
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
            <TableCell
              className={classes.tableCell}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <NumberStepper
                value={Number(row.inventoryLineItemQuantity) || 0}
                onChange={(newValue: number) =>
                  handleAmountChange(row.inventoryShipmentItemId, newValue)
                }
              />
            </TableCell>
          </TableRow>
        ))}
        <TableRow className={classes.tableRow}>
          <TableCell align="right" className={classes.tableCell} colSpan={4}>
            <Text strong m={0.5} variant="subheading3">
              {t('Common:TOTAL_DISPENSED')}: {totalAmount}
            </Text>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
})

export default DispensedFromSectionNew
