import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import DateTimeLabel from '~/components/common/DateTimeLabel'
import { getSoapVitals } from '~/store/reducers/soap'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    leftCell: {
      borderLeft: theme.constants.tableBorder,
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      minWidth: 150,
      width: 150,
      position: 'sticky',
      left: 0,
    },
    cell: {
      borderTop: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
      padding: theme.spacing(1.5, 1),
      borderRight: theme.constants.tableBorder,
      width: 100,
      minWidth: 100,
    },
    dateLabel: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.8rem',
      color: theme.colors.secondaryText,
    },
    timeLabel: {
      fontSize: '1.4rem',
      fontWeight: 400,
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'SoapVitalsLockedTableHeader' },
)

const SoapVitalsLockedTableHeader = () => {
  const classes = useStyles()
  const vitals = useSelector(getSoapVitals)
  const { t } = useTranslation('Time')

  return (
    <TableRow>
      <TableCell
        className={classNames(classes.leftCell, classes.cell)}
        component="th"
        scope="row"
      >
        <Text strong variant="body2">
          {t('Time:LABEL.DATE_TIME')}
        </Text>
      </TableCell>
      {vitals.map(({ id, creationDate, recordedDate }) => {
        const date = recordedDate || creationDate
        return (
          <TableCell
            className={classes.cell}
            component="th"
            key={id}
            scope="row"
          >
            <Grid container alignItems="center" wrap="nowrap">
              {date && (
                <DateTimeLabel
                  classes={{
                    date: classes.dateLabel,
                    time: classes.timeLabel,
                  }}
                  dateTime={date}
                  direction="column"
                />
              )}
            </Grid>
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export default SoapVitalsLockedTableHeader
