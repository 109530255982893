import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  LanguageUtils,
  LinkButton,
  Nil,
  PuiTheme,
  StateLabel,
  Text,
} from '@pbt/pbt-ui-components'

import WarningRowLabel from '~/components/common/labels/WarningRowLabel'
import LabOrderPreviewButtons from '~/components/dashboard/lab-tests-dashboard/LabOrderPreviewButtons'
import LabResultPreviewButtons from '~/components/dashboard/lab-tests-dashboard/LabResultPreviewButtons'
import FeatureToggle from '~/constants/featureToggle'
import LabVendorNames from '~/constants/labVendorName'
import {
  CancellableLabOrderStates,
  DeletableLabOrderStates,
  LabOrdersStatus,
} from '~/constants/SOAPStates'
import {
  getFeatureToggle,
  getLabOrderStatuses,
  getLabVendors,
} from '~/store/reducers/constants'
import { LabOrder, Order } from '~/types'

import { LabTestGroupKeys } from './labTestUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      borderTop: theme.constants.tabBorder,
    },
    labRow: {
      padding: theme.spacing(1),
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tabBorder,
      },
    },
    leftInfoContainer: {
      width: 'auto',
    },
    leftInfoContainerLimited: {
      width: 280,
    },
    statusContainer: {
      minWidth: 96,
      display: 'flex',
      marginRight: 'auto',
    },
    statusLabel: {
      padding: theme.spacing(0, 0.5),
      fontSize: '1.6rem',
    },
    buttonsContainer: {
      minWidth: 112,
    },
    button: {
      margin: theme.spacing(0, 2),
    },
  }),
  { name: 'OutstandingLabOrders' },
)

interface LabOrderRowProps {
  clientId: string | Nil
  currentOrderLabTestMap: Record<string, Record<string, Order[]>>
  handleEdit: (orderVendorId: string) => void
  handlePrintLabel: (
    labOrderId: string | undefined,
    orderDate: string | undefined,
    vendorId: string,
  ) => void
  hasIntegrationMap: Record<string, boolean>
  keysToPick: LabTestGroupKeys[]
  onCancelOrder: any
  order: LabOrder
  patientId: string | Nil
  soapBusinessId: string | Nil
  soapId: string | Nil
}

function LabOrderRow({
  soapId,
  soapBusinessId,
  order,
  onCancelOrder,
  clientId,
  patientId,
  handleEdit,
  handlePrintLabel,
  currentOrderLabTestMap,
  keysToPick,
  hasIntegrationMap,
}: LabOrderRowProps) {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Soap'])
  const LabOrdersStatusesList = useSelector(getLabOrderStatuses)

  const LabVendors = useSelector(getLabVendors)

  const isDeleteFinalizedLabTestsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DELETE_FINALIZED_LAB_TESTS),
  )

  const {
    cancellable = true,
    id: orderId,
    iframeUrl,
    labOrderId,
    statusId,
    vendorId,
  } = order

  const orderLabTests = currentOrderLabTestMap[vendorId]
    ? orderId
      ? R.pathOr<(Order | undefined)[]>(
          [],
          [vendorId, orderId],
          currentOrderLabTestMap,
        )
      : R.flatten<Order[]>(
          Object.values(R.pick(keysToPick, currentOrderLabTestMap[vendorId])),
        )
    : ([] as undefined[])
  const vendorName = LanguageUtils.getConstantTranslatedName(
    vendorId,
    LabVendors,
  )
  const orderStatus = LanguageUtils.getConstantTranslatedName(
    statusId,
    LabOrdersStatusesList,
  )
  const orderCancellable =
    cancellable && CancellableLabOrderStates.includes(orderStatus)
  const orderDeletable =
    cancellable && DeletableLabOrderStates.includes(orderStatus)

  const hasIntegration = hasIntegrationMap[vendorId]
  const isInternalVendor = vendorName === LabVendorNames.INTERNAL

  const canEdit = Boolean(iframeUrl)

  return (
    <Grid
      container
      className={classes.labRow}
      direction="column"
      key={`${vendorId}_${orderId}`}
    >
      <Grid container item alignItems="center">
        <Grid
          container
          item
          alignItems="center"
          className={classNames(classes.leftInfoContainer, {
            [classes.leftInfoContainerLimited]: hasIntegration,
          })}
        >
          <Text strong>
            {vendorName}
            {labOrderId ? `: ${labOrderId}` : ''}
          </Text>
          {!hasIntegration && !isInternalVendor && (
            <WarningRowLabel
              message={t('Soap:MAKE_SURE_TO_COMPLETE_ORDER_WARNING_MESSAGE', {
                vendorName,
              })}
            />
          )}
        </Grid>
        <Grid item className={classes.statusContainer}>
          {orderStatus && (
            <StateLabel
              className={classes.statusLabel}
              completed={orderStatus === LabOrdersStatus.COMPLETED}
              warning={orderStatus === LabOrdersStatus.CREATED}
            >
              {orderStatus}
            </StateLabel>
          )}
        </Grid>
        {isDeleteFinalizedLabTestsEnabled
          ? orderDeletable && (
              <Grid item className={classes.buttonsContainer}>
                <LinkButton onClick={() => onCancelOrder(vendorId, orderId)}>
                  {t('Common:DELETE_ORDER')}
                </LinkButton>
              </Grid>
            )
          : orderCancellable && (
              <Grid item className={classes.buttonsContainer}>
                <LinkButton onClick={() => onCancelOrder(vendorId, orderId)}>
                  {t('Common:CANCEL_ORDER')}
                </LinkButton>
              </Grid>
            )}
        <LabResultPreviewButtons
          clientId={clientId}
          order={order}
          patientId={patientId}
          soapId={soapId}
          vendorId={vendorId}
        />
        <LabOrderPreviewButtons
          order={order}
          soapBusinessId={soapBusinessId}
          soapId={soapId}
          vendorId={vendorId}
          onEdit={canEdit ? () => handleEdit(order.vendorId) : undefined}
          onLabelPrint={() =>
            handlePrintLabel(order.labOrderId, order.date, vendorId)
          }
        />
        {/* NOTE:
              Show "Print label" button independently of LabOrderPreviewButtons
              in case when pdf print is not possible
          */}
        {!order?.pdfUrl && (
          <Grid item className={classes.button}>
            <LinkButton
              onClick={() =>
                handlePrintLabel(order.labOrderId, order.date, vendorId)
              }
            >
              {t('Common:PRINT_LABEL')}
            </LinkButton>
          </Grid>
        )}
      </Grid>
      {orderLabTests.map((labOrder) => (
        <Grid container item key={labOrder?.id}>
          <Text>{labOrder?.name}</Text>
        </Grid>
      ))}
    </Grid>
  )
}

export default LabOrderRow
