import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Table, TableBody, TableCell, TableRow, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ClassesType,
  Nil,
  PuiTextArea,
  PuiTheme,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'
import { UnitTypes, WeightTypes } from '@pbt/pbt-ui-components/src/localization'

import { changeUnits, getUnitsState } from '~/store/duck/settings'
import { getConstantMap } from '~/store/reducers/constants'
import { Vital, VitalValue } from '~/types'

import { vitalsTableConfig } from '../../soapV2/vitals/config/vitalsTableConfig'
import VitalsFormInput from './VitalsFormInput'
import {
  getVitalNotesField,
  getVitalsFields,
  getVitalsState,
  getVitalValueField,
  // @ts-ignore
} from './vitalUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tableTitleCell: {
      height: 51,
      padding: 0,
      borderBottom: theme.constants.tableBorder,
    },
    tableRow: {
      height: theme.spacing(7),
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
    expandedRow: {
      height: theme.spacing(13),
    },
    formInputCellV2: {
      paddingLeft: 0,
      width: 170,
    },
    textArea: {
      width: 170,
    },
    formInputCell: {
      paddingLeft: 0,
    },
    textAreaInput: {
      height: 40,
      margin: 0,
    },
    textAreaInputExpanded: {
      height: 85,
      margin: 0,
    },
    cell: {
      borderBottom: 'none',
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
      padding: theme.spacing(1),
      verticalAlign: 'top',
    },
    rowTitleCell: {
      padding: theme.spacing(2),
    },
    button: {
      minWidth: 136,
      height: theme.spacing(4),
    },
    notesInput: {
      maxHeight: 40,
    },
    notesInputExpanded: {
      maxHeight: 85,
    },
  }),
  { name: 'VitalsControlTable' },
)

export interface VitalsControlTableProps {
  classes?: ClassesType<typeof useStyles>
  editDisabled?: boolean
  soapId?: string | Nil
  vital?: Vital
}

export interface VitalsControlTableHandle {
  getVitalsState: () => Record<string, VitalValue>
}

const VitalsControlTable = forwardRef<
  VitalsControlTableHandle,
  VitalsControlTableProps
>(({ classes: classesProp, editDisabled, vital, soapId: soapIdProp }, ref) => {
  const dispatch = useDispatch()
  const unitsState = useSelector(getUnitsState)
  const constantMap = useSelector(getConstantMap)
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const soapId = vital?.id ? vital?.soapId : soapIdProp

  const { fields } = useFields(
    getVitalsFields(unitsState, vitalsTableConfig, vital),
    false,
  )

  useImperativeHandle(ref, () => ({
    getVitalsState: () => getVitalsState(fields, soapId, unitsState),
  }))

  return (
    <Table id={`vitals-add-edit-table-soapId-${soapId}-recordId-${vital?.id}`}>
      <TableBody>
        {vitalsTableConfig.map((configItem) => {
          const valueField = getVitalValueField(configItem.id, fields)
          const notesField = getVitalNotesField(configItem.id, fields)
          const isExpanded =
            configItem.id === UnitTypes.WEIGHT &&
            unitsState.weight === WeightTypes.LBS
          return (
            <TableRow
              className={classNames(classes.tableRow, {
                [classes.expandedRow]: isExpanded,
              })}
              id={`vitals-add-edit-table-row-${configItem.id}`}
              key={configItem.name}
            >
              <TableCell
                className={classNames(
                  classes.rowTitleCell,
                  classes.cell,
                  classes.tableTitleCell,
                )}
                component="th"
                id={`vitals-add-edit-table-row-${configItem.id}-title-cell`}
                scope="row"
              >
                <Tooltip title={configItem.tooltip || ''}>
                  <Text variant="h4">{configItem.name}</Text>
                </Tooltip>
              </TableCell>
              <TableCell
                className={classNames(classes.cell, classes.formInputCellV2)}
                id={`vitals-add-edit-table-row-${configItem.id}-input-cell`}
              >
                <VitalsFormInput
                  editDisabled={editDisabled}
                  field={valueField}
                  formInput={configItem.formInput}
                  types={constantMap}
                  unitsState={unitsState}
                  onChangeUnits={(key, value) =>
                    dispatch(changeUnits({ [key]: value }))
                  }
                />
              </TableCell>
              <TableCell
                className={classNames(classes.cell, classes.formInputCell)}
                id={`vitals-add-edit-table-row-${configItem.id}-notes-cell`}
              >
                <PuiTextArea
                  InputProps={{
                    classes: {
                      multiline: isExpanded
                        ? classes.textAreaInputExpanded
                        : classes.textAreaInput,
                    },
                  }}
                  className={classes.textArea}
                  classes={{
                    input: isExpanded
                      ? classes.notesInputExpanded
                      : classes.notesInput,
                  }}
                  disabled={editDisabled}
                  field={notesField}
                  margin="none"
                  minRows={isExpanded ? 4 : 2}
                  placeholder={t('Common:NOTES')}
                />
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
})

export default VitalsControlTable
