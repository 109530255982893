import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  DateFormat,
  DateUtils,
  ImageScalingUtils,
  LanguageUtils,
  PatientAvatar,
  PuiTheme,
  TextWithTooltip,
  Utils,
} from '@pbt/pbt-ui-components'
import ResponsibilityName from '@pbt/pbt-ui-components/src/constants/responsibilityNames'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import RoleName from '~/constants/roleNames'
import { getSpecies } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import {
  getAppointmentId,
  getClientId,
  getIsCurrentContextSoap,
  getPatientId,
  getSoapBusinessId,
  getSoapPrimaryComplaint,
} from '~/store/reducers/soap'
import { getSpace } from '~/store/reducers/spaces'
import { getTimetableEvent } from '~/store/reducers/timetable'
import useWindowResize from '~/utils/useWindowResize'

import ClientAndPatient from './ClientAndPatient'
import SoapHeaderMenu from './SoapHeaderMenu'

const AVATAR_SIZE = 56

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    avatar: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
    },
    truncated: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    inline: {
      display: 'inline',
    },
    nowrap: {
      whiteSpace: 'nowrap',
    },
    lineTextBlock: {
      '&::before': {
        content: '"|"',
        marginRight: theme.spacing(1),
      },
      whiteSpace: 'nowrap',
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'SoapHeader' },
)

const SoapHeader = () => {
  const classes = useStyles()

  const patientId = useSelector(getPatientId)
  const clientId = useSelector(getClientId)
  const patient = useSelector(getPatient(patientId))
  const species = useSelector(getSpecies)
  const appointmentId = useSelector(getAppointmentId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const space = useSelector(getSpace(appointment?.assignedSpace))
  const visitReason = useSelector(getSoapPrimaryComplaint)
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)
  const soapBusinessId = useSelector(getSoapBusinessId)

  const visitReasonRef = useRef<HTMLDivElement>(null)
  const [visitReasonTruncated, setVisitReasonTruncated] = useState(false)

  const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
    patient?.photo,
    patient?.photoThumbnail,
    AVATAR_SIZE,
  )

  const currentAppointmentTypeDisplayName =
    LanguageUtils.getTranslatedFieldName(appointment?.businessAppointmentType)
  const doctor =
    appointment?.personResponsibilities?.filter(
      (pr) => pr.responsibility?.name === ResponsibilityName.Veterinarian,
    )?.[0]?.person ||
    appointment?.personRoles?.filter(
      (pr) => pr.role?.name === RoleName.Veterinarian,
    )?.[0]?.person

  const handleResize = useCallback(() => {
    const visitReasonDiv = visitReasonRef.current
    if (visitReasonDiv) {
      setVisitReasonTruncated(
        visitReasonDiv.offsetWidth < visitReasonDiv.scrollWidth,
      )
    }
  }, [visitReasonRef])

  useEffect(() => {
    handleResize()
  }, [visitReason, handleResize])

  useWindowResize(handleResize)

  return (
    <Grid
      container
      alignItems="center"
      flexDirection="row"
      flexWrap="nowrap"
      justifyContent="space-between"
      mt={1}
      mx={2}
      width="auto"
    >
      <Grid container item flexWrap="nowrap">
        <PatientAvatar
          small
          animal={Utils.getConstantName(patient?.species, species)}
          className={classes.avatar}
          showBoopBadge={patient?.isBoop}
          src={avatarSrc}
        />
        <Grid
          container
          flexDirection="column"
          flexWrap="nowrap"
          justifyContent="space-evenly"
        >
          <Grid container flexDirection="row" flexWrap="nowrap" ml={1}>
            <Grid
              container
              item
              alignItems="center"
              className={classes.nowrap}
              flexWrap="nowrap"
              width="fit-content"
            >
              {currentAppointmentTypeDisplayName}
              <BusinessShareIcon
                businessIds={soapBusinessId ? [soapBusinessId] : []}
              />
            </Grid>
            {appointment?.scheduledStartDatetime && (
              <Grid item className={classes.lineTextBlock} flexWrap="nowrap">
                {DateUtils.formatDate(
                  appointment?.scheduledStartDatetime,
                  false,
                  DateFormat.FULL_DATE_TIME_AT,
                )}
              </Grid>
            )}
            {space && (
              <Grid item className={classes.lineTextBlock} flexWrap="nowrap">
                {space?.name}
              </Grid>
            )}
            {doctor && (
              <Grid item className={classes.lineTextBlock} flexWrap="nowrap">
                {doctor?.firstName} {doctor?.lastName}
              </Grid>
            )}
            {visitReason && (
              <Grid item flex={1} flexWrap="nowrap" width={0}>
                <Grid item className={classes.truncated} ref={visitReasonRef}>
                  <TextWithTooltip
                    noIcon
                    TooltipProps={{ tooltipPlacement: 'bottom' }}
                    className={classes.inline}
                    tooltipText={visitReasonTruncated ? visitReason : null}
                  >
                    : {visitReason}
                  </TextWithTooltip>
                </Grid>
              </Grid>
            )}
          </Grid>
          <ClientAndPatient clientId={clientId} patientId={patientId} />
        </Grid>
      </Grid>
      {isCurrentContextSoap && (
        <Grid item pl={2}>
          <SoapHeaderMenu />
        </Grid>
      )}
    </Grid>
  )
}

export default SoapHeader
