import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid, IconButton, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  BlobWithName,
  CustomFieldValidatorState,
  DateUtils,
  ImageScalingUtils,
  LanguageUtils,
  Nil,
  Patient,
  PatientAvatar,
  PermissionArea,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { MembershipStatus } from '@pbt/pbt-ui-components/src/constants/wellnessPlans'
import {
  Edit as EditIcon,
  Wings as WingsIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { getSpayedNeuteredIntactWithGenderString } from '~/components/common/inputs/gender/genderUtils'
import AlertLabel from '~/components/common/labels/AlertLabel'
import { getDOBLabelString } from '~/components/common/labels/PatientDOBLabel'
import PatientInfoLabel from '~/components/common/labels/PatientInfoLabel'
import WarningRowLabel from '~/components/common/labels/WarningRowLabel'
import LinkedChewyAccountContainer, {
  KyriosAccountType,
} from '~/components/dashboard/link-chewy-account/LinkedChewyAccountContainer'
import WellnessPlanMemberButton from '~/components/dashboard/wellness-plans/WellnessPlanMemberButton'
import WellnessPlanMemberChip from '~/components/dashboard/wellness-plans/WellnessPlanMemberChip'
import { getPatientHasMembership } from '~/components/dashboard/wellness-plans/wellnessPlanUtils'
import { useGetAcquiredTypeList } from '~/constants/acquiredTypes'
import { AlertColorLevel } from '~/constants/alertColors'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { editPatient } from '~/store/actions/patients'
import {
  useAlertType,
  useCreatePatient,
  usePatientGoodToKnow,
  usePatientWeight,
} from '~/store/hooks/patient'
import {
  getCRUDByArea,
  getCurrentBusiness,
  getCurrentBusinessHasActiveWellnessPlans,
  getCurrentBusinessHasWellnessPackages,
  getCurrentBusinessSendRemindersEnabled,
  getCurrentBusinessWellnessPlansEnabled,
} from '~/store/reducers/auth'
import {
  getActivityLevel,
  getAnimalEnvironment,
  getBreed,
  getCountryInsuranceProviderByCode,
  getFeatureToggle,
  getGender,
  getServiceAnimal,
  getSize,
  getSpecies,
} from '~/store/reducers/constants'
import { getPatient, getPatientsIsLoading } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { getFullBreedName } from '~/utils'
import { useBoopEnabled } from '~/utils/boop'
import useDialog from '~/utils/useDialog'

import { PatientPreferencesChip } from '../../patient/PatientPreferencesChip'
import { CancelFutureAppointmentsAndMembershipAlert } from './CancelFutureAppointmentsAndMembershipAlert'
import ClientAndPatientDetailsRow, {
  ClientAndPatientDetailsRowProps,
} from './ClientAndPatientDetailsRow'
import { InlineEditInputType } from './InlineEditPopper'

const AVATAR_SIZE = 200

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      width: '100%',
    },
    imageAvatar: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
    },
    notActiveAvatar: {
      opacity: 0.5,
    },
    noImageAvatar: {
      width: 88,
      height: 88,
    },
    noImageAvatarIcon: {
      width: 64,
      height: 64,
    },
    imageAvatarBadge: {
      top: '20%',
      right: 16,
    },
    noImageAvatarBadge: {
      top: '20%',
      right: 8,
    },
    nameContainer: {
      borderBottom: theme.constants.tableBorder,
    },
    editIconButton: {
      padding: theme.spacing(0.5),
    },
    editIcon: {
      color: theme.colors.title,
    },
    patientDetailsRowLabel: {
      minWidth: 74,
    },
    actionContainer: {
      borderTop: theme.constants.tableBorder,
    },
    alertLabelRoot: {
      borderRadius: 0,
    },
    alertLabelText: {
      lineHeight: '1.7rem',
      padding: theme.spacing(0.5, 0),
    },
    alertLabelBadgeRoot: {
      justifyContent: 'flex-start',
      margin: theme.spacing(0, 2, 1),
    },
    planMemberButtonContainer: {
      width: 'auto',
    },
    planMemberButton: {
      width: '100%',
      margin: 0,
      justifyContent: 'flex-start',
    },
    withBottomBorder: {
      borderBottom: theme.constants.tableBorder,
    },
    wingsIcon: {
      color: theme.colors.title,
      width: '1.5em',
      height: '1.5em',
      margin: theme.spacing(0.5, 0, -1, 1),
      opacity: 0.5,
    },
    chip: {
      width: 16,
      height: 16,
      marginRight: theme.spacing(0.5),
    },
    chipMedium: {
      width: 24,
      height: 24,
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'PatientSection' },
)

interface PatientSectionProps {
  clientId: string | Nil
  patientId: string | Nil
}

const PatientSection = ({ clientId, patientId }: PatientSectionProps) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation([
    'Abbreviations',
    'Common',
    'Clients',
    'Dialogs',
    'Validations',
    'Utils',
  ])

  const isXs = useMediaQuery((theme: PuiTheme) => theme.breakpoints.down('sm'))

  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))
  const { createPatient } = useCreatePatient()
  const currentBusiness = useSelector(getCurrentBusiness)
  const Species = useSelector(getSpecies)
  const Breed = useSelector(getBreed)
  const Gender = useSelector(getGender)
  const AnimalEnvironment = useSelector(getAnimalEnvironment)
  const ActivityLevel = useSelector(getActivityLevel)
  const Size = useSelector(getSize)
  const isLoading = useSelector(getPatientsIsLoading)
  const showSendRemindersBusinessLevel = useSelector(
    getCurrentBusinessSendRemindersEnabled,
  )
  const InsuranceProvider = useSelector(
    getCountryInsuranceProviderByCode(currentBusiness?.countryCatalogCode),
  )
  const ServiceAnimal = useSelector(getServiceAnimal)
  const wellnessPlansEnabled = useSelector(
    getCurrentBusinessWellnessPlansEnabled,
  )
  const hasActiveWellnessPlans = useSelector(
    getCurrentBusinessHasActiveWellnessPlans,
  )
  const hasWellnessPackages = useSelector(getCurrentBusinessHasWellnessPackages)
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
  )
  const isCancelAppointmentsAndWplanForDeceasedPetEnabled = useSelector(
    getFeatureToggle(
      FeatureToggle.CANCEL_APPOINTMENTS_AND_WPLAN_FOR_DECEASED_PET,
    ),
  )
  const isWellnessplanSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.WELLNESSPLAN_SHARING),
  )
  const { update: patientUpdatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.PATIENT),
  )
  const { create: communicationCreatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.COMMUNICATION),
  )
  const { create: wellnessPlanCreatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.WELLNESS_PLAN),
  )

  const { alertsToRender } = useAlertType(patientId)
  const { AcquiredTypeItemsList } = useGetAcquiredTypeList()

  const patientWeight = usePatientWeight(patientId)
  const { goodToKnow: initialGoodToKnow, goodToKnowEditable } =
    usePatientGoodToKnow(patientId)

  const [openClientDetailsEditDialog] = useDialog(
    DialogNames.CLIENT_DETAILS_EDIT,
  )
  const [openChangePetParentDialog] = useDialog(DialogNames.CHANGE_PET_PARENT)
  const [openPatientDialog] = useDialog(DialogNames.PATIENT)
  const [openChooseAvatarDialog] = useDialog(DialogNames.CHOOSE_AVATAR)
  const [openSendBoopLinkDialog] = useDialog(DialogNames.SEND_BOOP_LINK)
  const [openMembershipSignUpDialog] = useDialog(DialogNames.MEMBERSHIP_SIGN_UP)
  const boopFlowEnabled = useBoopEnabled()

  if (!patient || !client) {
    return null
  }

  const patientHasWellnessPlansInAnotherBusiness =
    isWellnessplanSharingEnabled &&
    (patient?.plans || []).some(
      (plan) =>
        plan.wplanStatus === MembershipStatus.ACTIVE &&
        plan.wplanBusinessId !== currentBusiness?.id,
    )

  const showAddPlanButton =
    !isBoopDisablementEnabled &&
    wellnessPlansEnabled &&
    hasActiveWellnessPlans &&
    hasWellnessPackages &&
    wellnessPlanCreatePermissions &&
    !patientHasWellnessPlansInAnotherBusiness

  const showSendLinkToBoop = boopFlowEnabled && getPatientHasMembership(patient)
  const patientGender = getSpayedNeuteredIntactWithGenderString(
    patient.gender,
    patient.spayedNeutered,
    Gender,
  )

  const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
    patient.photo,
    patient.photoThumbnail,
    AVATAR_SIZE,
  )

  const chipClassName = isBoopDisablementEnabled
    ? classes.chipMedium
    : classes.chip

  const handleAvatarEdit = () => {
    openChooseAvatarDialog({
      title: t('Dialogs:EDIT_PATIENT_AVATAR_DIALOG.TITLE', {
        patientName: patient?.name || t('Common:PET'),
      }),
      onOk: (blob: BlobWithName) =>
        dispatch(editPatient(client.id, patient, blob)),
    })
  }

  const handleEdit = () => {
    if (isXs) {
      navigate(`/client/${client.id}/patient/${patient.id}/edit`)
    } else {
      openPatientDialog({
        clientId: client.id,
        patientId: patient.id,
        createPatient,
      })
    }
  }

  const handleEditClientDetails = () => {
    if (isXs) {
      navigate(`/client/${client.id}/edit`, {
        state: {
          title: t('Common:CLIENT_DETAILS'),
          renderCoparents: false,
        },
      })
    } else {
      openClientDetailsEditDialog({
        clientId: client.id,
      })
    }
  }

  const handleChangePetParent = () => {
    openChangePetParentDialog({
      patientId: patient.id,
      clientId: client.id,
      onAddClientDetails: handleEditClientDetails,
    })
  }

  const ApproximateAgeLabel = (
    <WarningRowLabel message={t('Common:APPROXIMATE_AGE')} />
  )

  const updatePatient = (newPatient: Partial<Patient>) =>
    dispatch(editPatient(client.id, { id: patient.id, ...newPatient }))

  const update = R.curry((fieldName, newValue) => {
    updatePatient({ [fieldName]: newValue })
  })

  const handleUpdateAge = ({
    dob,
    approximateAge,
  }: {
    approximateAge: Patient['approximateAge']
    dob: Patient['dateOfBirth']
  }) => {
    updatePatient({ dateOfBirth: dob, approximateAge })
  }

  const handleUpdateGender = ({
    gender,
    spayedNeutered,
  }: {
    gender: Patient['gender']
    spayedNeutered: Patient['spayedNeutered']
  }) => {
    updatePatient({ gender, spayedNeutered })
  }

  const validateGender = ({ state: { gender } }: CustomFieldValidatorState) =>
    Boolean(Utils.getConstantName(gender, Gender))

  const patientDetails = [
    {
      label: t('Common:NOTES'),
      value: patient.notes,
      onEdit: update('notes'),
      inputType: InlineEditInputType.MULTILINE,
      fieldProps: { name: 'notes', initialValue: patient.notes || '' },
      inputProps: { minRows: 5 },
    },
    patient.externalSource
      ? {
          label: patient.externalSource,
          value: patient.externalPatientId,
          placeholder: '',
        }
      : undefined,
    {
      label: t('Common:AGE'),
      value: getDOBLabelString(patient),
      subContent: patient.approximateAge ? ApproximateAgeLabel : null,
      inputType: InlineEditInputType.AGE,
      onEdit: handleUpdateAge,
      fieldProps: [
        {
          name: 'dob',
          validators: ['timestamp'],
          initialValue: patient.dateOfBirth,
        },
        {
          name: 'approximateAge',
          type: 'toggle',
          initialValue: patient.approximateAge,
        },
      ],
      inputProps: { minRows: 5 },
    },
    {
      label: t('Clients:PATIENT_SECTION.LABEL.SEX'),
      value: patientGender,
      onEdit: handleUpdateGender,
      inputType: InlineEditInputType.GENDER,
      fieldProps: [
        {
          name: 'gender',
          label: t('Common:GENDER'),
          validators: [{ validator: validateGender, validatorName: 'gender' }],
          initialValue: patient.gender,
          messages: {
            gender: t('Validations:SELECT_GENDER'),
          },
        },
        {
          label: t('Utils:GENDER_UTILS.SPAYED_OR_NEUTERED'),
          name: 'spayedNeutered',
          validators: ['required'],
          initialValue: patient.spayedNeutered,
        },
      ],
    },
    {
      label: t('Common:SPECIES'),
      value: Utils.getConstantName(patient.species, Species),
      displayValue: LanguageUtils.getConstantTranslatedName(
        patient.species,
        Species,
      ),
      onEdit: update('species'),
      inputType: InlineEditInputType.SPECIES,
      inputProps: { items: Species },
      fieldProps: {
        name: 'species',
        label: t('Common:SPECIES'),
        type: 'select',
        validators: ['required'],
        initialValue: patient.species,
      },
    },
    {
      label: t('Common:BREED'),
      value: getFullBreedName(patient.species, patient.breeds, Breed),
      onEdit: update('breeds'),
      inputType: InlineEditInputType.BREED,
      inputProps: { species: patient.species },
      fieldProps: {
        name: 'breed',
        type: 'select',
        initialValue: patient.breeds,
      },
    },
    {
      label: t('Common:WEIGHT'),
      value: patientWeight,
      placeholder: t('Clients:PATIENT_SECTION.LABEL.NO_RECENT_WEIGHT'),
    },
    {
      label: t('Common:ALLERGIES'),
      value: patient.allergies,
      onEdit: update('allergies'),
      fieldProps: { name: 'allergies', initialValue: patient.allergies || '' },
    },
    {
      label: t('Common:PET_ENVIRONMENT'),
      value: Utils.getConstantName(patient.environment, AnimalEnvironment),
      displayValue: LanguageUtils.getConstantTranslatedName(
        patient.environment,
        AnimalEnvironment,
      ),
      onEdit: update('environment'),
      inputType: InlineEditInputType.SELECT,
      inputProps: { items: AnimalEnvironment },
      fieldProps: {
        name: 'environment',
        type: 'select',
        initialValue: patient.environment,
      },
    },
    {
      label: t('Common:RABIES_TAG'),
      value: patient.rabiesTag,
      onEdit: update('rabiesTag'),
      fieldProps: { name: 'rabiesTag', initialValue: patient.rabiesTag || '' },
    },
    {
      label: t('Abbreviations:COMMON.RABIES_EXPIRE_DATE'),
      value: patient.rabiesTagExpiration
        ? DateUtils.formatDate(patient.rabiesTagExpiration)
        : '',
      onEdit: update('rabiesTagExpiration'),
      inputType: InlineEditInputType.CALENDAR,
      fieldProps: {
        name: 'rabiesTagExpiration',
        validators: ['timestamp'],
        initialValue: patient.rabiesTagExpiration || '',
      },
    },
    {
      label: t('Common:MICROCHIP'),
      value: patient.microchipNumber,
      onEdit: update('microchipNumber'),
      fieldProps: {
        name: 'microchipNumber',
        initialValue: patient.microchipNumber || '',
      },
    },
    {
      label: t('Common:DIET_PATIENT'),
      value: patient.diet,
      showBorder: false,
      onEdit: update('diet'),
      fieldProps: { name: 'diet', initialValue: patient.diet || '' },
    },
  ].filter(Boolean) as ClientAndPatientDetailsRowProps[]

  const patientLongDetails: ClientAndPatientDetailsRowProps[] = [
    {
      label: t('Common:LICENSE_NUMBER'),
      value: patient.license,
      onEdit: update('license'),
      fieldProps: { name: 'license', initialValue: patient.license || '' },
    },
    {
      label: t('Common:INSURANCE'),
      value: patient.insuranceProvider,
      onEdit: update('insuranceProvider'),
      inputType: InlineEditInputType.SELECT,
      inputProps: { items: InsuranceProvider },
      fieldProps: {
        name: 'insuranceProvider',
        type: 'select',
        initialValue: patient.insuranceProvider || '',
      },
    },
    {
      label: t('Clients:PATIENT_SECTION.LABEL.POLICY_NUMBER_INSURANCE'),
      value: patient.insurancePolicyNumber,
      onEdit: update('insurancePolicyNumber'),
      fieldProps: {
        name: 'insurancePolicyNumber',
        initialValue: patient.insurancePolicyNumber || '',
      },
    },
    {
      label: t('Clients:PATIENT_SECTION.LABEL.FITNESS_PHYSICAL_CONDITION'),
      value: Utils.getConstantName(patient.activityLevel, ActivityLevel),
      displayValue: LanguageUtils.getConstantTranslatedName(
        patient.activityLevel,
        ActivityLevel,
      ),
      onEdit: update('activityLevel'),
      inputType: InlineEditInputType.SELECT,
      inputProps: { items: ActivityLevel },
      fieldProps: {
        name: 'activityLevel',
        type: 'select',
        initialValue: patient.activityLevel,
      },
    },
    {
      label: t('Common:MARKINGS_OR_TATTOO'),
      value: patient.markingsTattoo,
      onEdit: update('markingsTattoo'),
      fieldProps: {
        name: 'markingsTattoo',
        initialValue: patient.markingsTattoo || '',
      },
    },
    {
      label: t('Common:COLOR'),
      value: patient.color,
      onEdit: update('color'),
      fieldProps: { name: 'color', initialValue: patient.color || '' },
    },
    {
      label: t('Common:SIZE'),
      value: Utils.getConstantName(patient.size, Size),
      displayValue: LanguageUtils.getConstantTranslatedName(patient.size, Size),
      onEdit: update('size'),
      inputType: InlineEditInputType.SELECT,
      inputProps: { items: Size },
      fieldProps: { name: 'size', type: 'select', initialValue: patient.size },
    },
    {
      label: t('Clients:PATIENT_SECTION.LABEL.SERVICE_ANIMAL_DESIGNATION'),
      value: Utils.getConstantName(patient.serviceAnimalType, ServiceAnimal),
      displayValue: LanguageUtils.getConstantTranslatedName(
        patient.serviceAnimalType,
        ServiceAnimal,
      ),
      onEdit: update('serviceAnimalType'),
      inputType: InlineEditInputType.SELECT,
      inputProps: { items: ServiceAnimal },
      fieldProps: {
        name: 'serviceAnimalType',
        type: 'select',
        initialValue: patient.serviceAnimalType,
      },
    },
    {
      label: t('Clients:PATIENT_SECTION.LABEL.GOOD_TO_KNOW'),
      value: initialGoodToKnow,
      onEdit: goodToKnowEditable ? update('goodToKnow') : undefined,
      fieldProps: { name: 'goodToKnow', initialValue: initialGoodToKnow || '' },
    },
    {
      label: t('Clients:PATIENT_SECTION.LABEL.SCHEDULE'),
      value: patient.schedule,
      onEdit: update('schedule'),
      fieldProps: { name: 'schedule', initialValue: patient.schedule || '' },
    },
    {
      label: t('Common:WHERE_CLIENT_GET_PATIENT', {
        clientFirstName: client.firstName,
        patientName: patient.name || t('Common:NEW_PATIENT'),
      }),
      value: Utils.getConstantName(patient.acquiredType, AcquiredTypeItemsList),
      displayValue: LanguageUtils.getConstantTranslatedName(
        patient.acquiredType,
        AcquiredTypeItemsList,
      ),
      onEdit: update('acquiredType'),
      inputType: InlineEditInputType.SELECT,
      inputProps: { items: AcquiredTypeItemsList },
      fieldProps: {
        name: 'acquiredType',
        type: 'select',
        initialValue: patient.acquiredType,
      },
    },
    {
      label: t('Common:WHEN_CLIENT_GET_PATIENT', {
        clientFirstName: client.firstName,
        patientName: patient.name || t('Common:NEW_PATIENT'),
      }),
      value: patient.acquiredDate
        ? DateUtils.formatDate(patient.acquiredDate)
        : undefined,
      onEdit: update('acquiredDate'),
      inputType: InlineEditInputType.CALENDAR,
      fieldProps: {
        name: 'acquiredDate',
        validators: ['timestamp'],
        initialValue: patient.acquiredDate || '',
      },
      showBorder: false,
    },
  ]

  const handleSendLink = () => {
    openSendBoopLinkDialog({
      clientId: client.id,
      patientId: patient.id,
      businessId: currentBusiness?.id,
    })
  }

  const handleAddPlan = () => {
    openMembershipSignUpDialog({ clientId: client.id, patientId: patient.id })
  }

  return (
    <Grid className={classes.root}>
      {isCancelAppointmentsAndWplanForDeceasedPetEnabled && (
        <Grid p={1}>
          <CancelFutureAppointmentsAndMembershipAlert
            active={patient.active}
            alertColorId={patient.alertColorId}
            clientId={client.id}
            deceased={Boolean(patient.deceased)}
            patientId={patient.id}
            patientName={patient.name}
          />
        </Grid>
      )}
      <Grid container justifyContent="center">
        <PatientAvatar
          animal={Utils.getConstantName(patient.species, Species)}
          className={classNames({
            [classes.imageAvatar]: avatarSrc && patient.active,
            [classes.notActiveAvatar]: !patient.active,
            [classes.noImageAvatar]: !avatarSrc || !patient.active,
          })}
          classes={{
            defaultIcon: !avatarSrc ? classes.noImageAvatarIcon : undefined,
            badge:
              avatarSrc && patient.active
                ? classes.imageAvatarBadge
                : classes.noImageAvatarBadge,
          }}
          isLoading={isLoading}
          showEditIcon={patientUpdatePermissions}
          src={avatarSrc}
          onEdit={handleAvatarEdit}
        />
      </Grid>
      <Grid container direction="column">
        <Grid
          container
          className={classes.nameContainer}
          direction="column"
          pb={0.5}
          px={2}
        >
          <Grid
            container
            item
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Grid container item direction="column" spacing={1}>
              <Grid container item direction="column">
                <Text inline variant="h1">
                  {patient.name}
                </Text>
                <Grid container>
                  <PatientPreferencesChip
                    className={chipClassName}
                    patientId={patientId}
                  />
                  <LinkedChewyAccountContainer
                    accountType={KyriosAccountType.PATIENT}
                    className={chipClassName}
                    clientId={clientId}
                    patientId={patientId}
                  />
                  {isBoopDisablementEnabled && (
                    <WellnessPlanMemberChip
                      className={chipClassName}
                      clientId={clientId}
                      patientId={patientId}
                    />
                  )}
                </Grid>
              </Grid>
              {patient.deceased && <WingsIcon className={classes.wingsIcon} />}
            </Grid>
            {patientUpdatePermissions && (
              <IconButton
                className={classes.editIconButton}
                data-testid="PatientSection-editPatient"
                size="large"
                onClick={handleEdit}
              >
                <EditIcon className={classes.editIcon} />
              </IconButton>
            )}
          </Grid>
          <Grid item mt={0.5}>
            <PatientInfoLabel patientId={patientId} variant="body2" />
          </Grid>
        </Grid>
        {!R.isEmpty(alertsToRender) && (
          <AlertLabel
            alertColorId={patient.alertColorId}
            alertColorLevel={AlertColorLevel.PATIENT}
            alertTypes={alertsToRender}
            aria-label="pet-alert"
            clamp={Number.MAX_SAFE_INTEGER}
            classes={{
              root: classes.alertLabelRoot,
              text: classes.alertLabelText,
            }}
            variant="attention"
          />
        )}
        {!isBoopDisablementEnabled && (
          <Grid
            container
            className={classNames(classes.planMemberButtonContainer, {
              [classes.withBottomBorder]: patient.active && !patient.alertTypes,
            })}
            mx={2}
            pt={1}
          >
            <WellnessPlanMemberButton
              className={classes.planMemberButton}
              clientId={clientId}
              patientId={patientId}
            />
          </Grid>
        )}
        <Grid container my={1} px={2}>
          {showSendLinkToBoop && communicationCreatePermissions && (
            <Text link mr={2} variant="body2" onClick={handleSendLink}>
              {t('Clients:PATIENT_SECTION.LABEL.SEND_LINK_FOR_BOOP')}
            </Text>
          )}
          {showAddPlanButton && (
            <Text link mr={2} variant="body2" onClick={handleAddPlan}>
              {t('Common:ADD_PLAN')}
            </Text>
          )}
        </Grid>
        <AlertLabel
          classes={{
            root: classes.alertLabelBadgeRoot,
            text: classes.alertLabelText,
          }}
          message={
            patient.active ? t('Common:ACTIVE_ONE') : t('Common:INACTIVE_ONE')
          }
          variant={patient.active ? 'active' : 'normal'}
        />
        {patient.deceased && (
          <AlertLabel
            classes={{
              root: classes.alertLabelBadgeRoot,
              text: classes.alertLabelText,
            }}
            message={
              patient.deceasedDate
                ? `${t('Common:DECEASED')} (${DateUtils.formatDate(
                    patient.deceasedDate,
                    true,
                  )})`
                : t('Common:DECEASED')
            }
            variant="normal"
          />
        )}
        {showSendRemindersBusinessLevel &&
          (!patient.sendReminders || !client.sendReminders) && (
            <AlertLabel
              classes={{
                root: classes.alertLabelBadgeRoot,
                text: classes.alertLabelText,
              }}
              message={t(
                'Clients:CLIENT_SECTION.ALERT_LABEL.NO_REMINDER_COMMUNICATIONS',
              )}
              variant="normal"
            />
          )}
        <Grid container px={2}>
          {patientDetails.map((props) => (
            <ClientAndPatientDetailsRow
              classes={{ label: classes.patientDetailsRowLabel }}
              key={patient.id + (props?.label || '')}
              {...props}
            />
          ))}
        </Grid>
      </Grid>
      <Grid container px={2}>
        {patientLongDetails.map((props) => (
          <ClientAndPatientDetailsRow
            long
            entityType="patient"
            key={patient.id + props.label}
            {...props}
          />
        ))}
      </Grid>
      <Grid className={classes.actionContainer} p={2}>
        <Text
          link
          disabled={!patientUpdatePermissions}
          variant="body"
          onClick={patientUpdatePermissions ? handleChangePetParent : undefined}
        >
          {t('Common:CHANGE_PET_PARENT')}
        </Text>
      </Grid>
    </Grid>
  )
}

export default PatientSection
