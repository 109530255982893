import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Hidden } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BusinessRole,
  ButtonWithLoader,
  Defaults,
  PermissionArea,
  PhoneUtils,
  PuiAlert,
  PuiTextField,
  PuiTheme,
  Text,
  TextWithTooltip,
  useFields,
  User,
  UserInvitationStatus,
  Utils,
  ValidateHandle,
} from '@pbt/pbt-ui-components'
import { DefaultAvatar } from '@pbt/pbt-ui-components/src/icons'

import Avatar from '~/components/common/Avatar'
import PhoneInput from '~/components/common/form-inputs/PhoneInput'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import Expander from '~/components/common/lists/Expander'
import PanelAccordion from '~/components/common/PanelAccordion'
import TimezoneWarningLabel from '~/components/dashboard/alerts/TimezoneWarningLabel'
import DialogNames from '~/constants/DialogNames'
import LabVendorName from '~/constants/labVendorName'
import i18n from '~/locales/i18n'
import {
  clearMembersError,
  fetchMember,
  inviteMember,
  updateActiveStatus,
  updateMember,
} from '~/store/actions/members'
import { useGetLabVendorConfig } from '~/store/hooks/labVendorConfig'
import {
  getCRUDByArea,
  getCurrentBusiness,
  getCurrentUserId,
} from '~/store/reducers/auth'
import {
  getMembersError,
  getMembersIsActivating,
  getMembersIsDeleting,
  getMembersIsInviting,
  getMembersIsUpdating,
} from '~/store/reducers/members'
import { getUser } from '~/store/reducers/users'
import { isFieldValuesChanged } from '~/utils'
import {
  getLockOutsideWorkingHoursEnabled,
  getTimeTrackerEnabled,
} from '~/utils/time'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import useFieldsChanged from '~/utils/useFieldsChanged'
import useGetAssignedRoles from '~/utils/useGetAssignedRoles'
import useHasRole from '~/utils/useHasRole'

import MemberDepartmentSection from './MemberDetailsPanelsSections/MemberDepartmentSection'
import MemberEmployeeIdSection from './MemberDetailsPanelsSections/MemberEmployeeIDSection'
import MemberLicensesSection from './MemberDetailsPanelsSections/MemberLicensesSection'
import MemberLockAccessSection from './MemberDetailsPanelsSections/MemberLockAccessSection'
import MemberNotificationSettingsSection from './MemberDetailsPanelsSections/MemberNotificationSettingsSection'
import MemberRolesSectionContainer from './MemberDetailsPanelsSections/MemberRolesSectionContainer'
import MemberTimeTrackingSection from './MemberDetailsPanelsSections/MemberTimeTrackingSection'
import MemberZoetisReferenceSection from './MemberDetailsPanelsSections/MemberZoetisReferenceSection'
import { MemberDetailsPanels, MemberDetailsPanelsLabels } from './members'

const AVATAR_SIZE = 144

const equalsByRoleAndBusiness: (a: BusinessRole, b: BusinessRole) => boolean =
  R.eqBy(R.pick(['role', 'business']))

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    topContainer: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
    },
    avatar: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
    },
    defaultAvatar: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
      fontSize: '5rem',
    },
    defaultAvatarBackground: {
      backgroundColor: theme.colors.searchPicker,
    },
    button: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    resendInvitationNotice: {
      [theme.breakpoints.down('lg')]: {
        fontSize: '1.2rem',
      },
    },
    panelContainer: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
  }),
  { name: 'TeamMemberDetails' },
)

const sendInviteButtonLabel: Partial<Record<UserInvitationStatus, string>> = {
  [UserInvitationStatus.PENDING]: i18n.t('Admin:MEMBER.INVITE.RESEND_INVITE'),
  [UserInvitationStatus.EXPIRED]: i18n.t('Admin:MEMBER.INVITE.RESEND_INVITE'),
  [UserInvitationStatus.IS_NOT_SENT]: i18n.t('Admin:MEMBER.INVITE.SEND_INVITE'),
}

interface RolesSectionHandle extends ValidateHandle {
  getBusinessRoleList: () => BusinessRole[]
}

interface TeamMemberDetailsProps {
  itemId: string
  onClose: () => void
}

const TeamMemberDetails = ({ itemId, onClose }: TeamMemberDetailsProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Admin', 'Common', 'Dialogs'])
  const dispatch = useDispatch()
  const teamMemberFromStore = useSelector(getUser(itemId))
  const isUpdating = useSelector(getMembersIsUpdating)
  const isActivating = useSelector(getMembersIsActivating)
  const isDeleting = useSelector(getMembersIsDeleting)
  const isInviting = useSelector(getMembersIsInviting)
  const error = useSelector(getMembersError)
  const personPermissions = useSelector(getCRUDByArea(PermissionArea.PERSON))
  const currentBusiness = useSelector(getCurrentBusiness)
  const currentUserId = useSelector(getCurrentUserId)

  const teamMember = teamMemberFromStore || ({} as User)
  const isVeterinarian = useHasRole('Veterinarian', teamMember)
  const getAssignedRoles = useGetAssignedRoles()

  const teamMemberIsLoaded = Boolean(teamMember.licenses)

  const znLabsConfig = useGetLabVendorConfig(LabVendorName.ZN_LABS)

  useEffect(() => {
    if (itemId) {
      dispatch(fetchMember(itemId))
    }
  }, [itemId])

  const tryDoAction = (action: typeof invite) => () => {
    if (!error) {
      action()
    }
  }

  const sendInviteLabel =
    teamMember.invitationStatus &&
    sendInviteButtonLabel[teamMember.invitationStatus]

  const [openChooseAvatarDialog] = useDialog(DialogNames.CHOOSE_AVATAR)
  const [openRemoveMemberDialog] = useDialog(DialogNames.REMOVE_MEMBER)

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'firstName',
        label: t('Common:FIRST_NAME'),
        validators: ['required'],
        initialValue: teamMember.firstName,
      },
      {
        name: 'lastName',
        label: t('Common:LAST_NAME'),
        validators: ['required'],
        initialValue: teamMember.lastName,
      },
      {
        name: 'email',
        label: t('Common:EMAIL'),
        validators: teamMember.email ? ['required', 'email'] : ['email'],
        initialValue: teamMember.email,
      },
      {
        name: 'mobilePhone',
        label: t('Common:MOBILE_PHONE_NUMBER'),
        validators: ['phone'],
        initialValue: teamMember.mobilePhone,
      },
      {
        name: 'timeTrackingEnabled',
        type: 'toggle',
        initialValue: getTimeTrackerEnabled(
          teamMember,
          currentBusiness?.id || '',
        ),
      },
      {
        name: 'lockOutsideWorkingHours',
        type: 'toggle',
        initialValue: getLockOutsideWorkingHoursEnabled(
          teamMember,
          currentBusiness?.id || '',
        ),
      },
      {
        name: 'znlabsProviderIdentifier',
        initialValue: teamMember.znlabsProviderIdentifier,
      },
      {
        name: 'departmentId',
        type: 'select',
        initialValue: teamMember.departmentId || '',
      },
    ],
    false,
  )

  const {
    firstName,
    lastName,
    email,
    mobilePhone,
    timeTrackingEnabled,
    lockOutsideWorkingHours,
    znlabsProviderIdentifier,
    departmentId,
  } = fields

  const rolesSectionRef = useRef<RolesSectionHandle>(null)

  const [avatarBlob, setAvatarBlob] = useState<Blob | null>(null)
  const [resendInvitationPossibility, setResendInvitationPossibility] =
    useState(true)
  const [sentInvitationsCounter, setSentInvitationsCounter] = useState(0)
  const [resendButtonStatusCounter, setResendButtonStatusCounter] = useState(60)
  const [resendPossibilityTimer, setResendPossibilityTimer] = useState<number>()
  const [expandedPanels, setExpandedPanels] = useState<MemberDetailsPanels[]>(
    [],
  )
  const [isMemberChanged, setIsMemberChanged] = useState(false)

  const setCloseAfterMemberUpdate = useCloseAfterCreation(
    ({ afterUpdate, data: { newMember, avatarBlob: newAvatarBlob } }) => {
      dispatch(updateMember(newMember, newAvatarBlob))
      if (afterUpdate) {
        afterUpdate()
      }
    },
    getMembersIsUpdating,
  )

  const invite = () => {
    setSentInvitationsCounter(sentInvitationsCounter + 1)
    dispatch(
      inviteMember({
        id: teamMember.id,
        email: email.value,
        businessToRoleList: teamMember.businessToRoleList,
      }),
    )
  }

  const setInviteAfterUpdateOn = useCloseAfterCreation(
    tryDoAction(invite),
    getMembersIsUpdating,
  )

  useEffect(() => {
    if (teamMember.firstName) {
      reset()
    }
  }, [teamMemberFromStore])

  const counterDecreasing = () => {
    if (resendButtonStatusCounter !== 0 && resendButtonStatusCounter !== 60) {
      setResendPossibilityTimer(
        window.setTimeout(() => {
          setResendButtonStatusCounter(resendButtonStatusCounter - 1)
        }, 1000),
      )
    }
  }

  const checkResendPossibility = () => {
    if (sentInvitationsCounter === Defaults.RESEND_INVITATION_MAX_TIMES) {
      setResendInvitationPossibility(false)

      setResendPossibilityTimer(
        window.setTimeout(() => {
          setResendButtonStatusCounter(resendButtonStatusCounter - 1)
        }, 1000),
      )
    }
  }

  const resetResendButtonStatus = () => {
    setResendInvitationPossibility(true)
    setResendButtonStatusCounter(60)
    setSentInvitationsCounter(0)
  }

  const checkResendButtonStatus = () => {
    if (resendButtonStatusCounter === 0) {
      resetResendButtonStatus()
    }
  }

  useEffect(() => {
    checkResendPossibility()

    return () => clearTimeout(resendPossibilityTimer)
  }, [sentInvitationsCounter])

  useEffect(() => {
    counterDecreasing()
    checkResendButtonStatus()
  }, [resendButtonStatusCounter])

  const update = (afterUpdate?: ReturnType<typeof useCloseAfterCreation>) => {
    const isValid = validate()
    const validateRoles = rolesSectionRef?.current?.validate
    if (isValid && validateRoles?.()) {
      const newMember = {
        id: teamMember.id,
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        mobilePhone: PhoneUtils.parsePhoneNumber(mobilePhone.value),
        businessToRoleList: rolesSectionRef?.current?.getBusinessRoleList(),
        licenses: teamMember.licenses,
        employeeIdInfos: teamMember.employeeIdInfos,
        avatar: teamMember.avatar,
        timeTrackingSettings: (teamMember.timeTrackingSettings || []).map((_) =>
          _.business === currentBusiness?.id
            ? { ..._, trackingEnabled: timeTrackingEnabled.value }
            : _,
        ),
        lockWorkingHoursSettings: [
          {
            businessId: currentBusiness?.id,
            lockOutsideWorkingHours: lockOutsideWorkingHours.value,
          },
        ],
        departmentId: departmentId.value,
      }
      setCloseAfterMemberUpdate({
        afterUpdate,
        data: { newMember, avatarBlob },
      })
    }
  }

  const onSaveRequested = () => {
    update()
  }

  const updateAndInvite = () => {
    update(setInviteAfterUpdateOn)
  }

  const onAvatarEditRequested = () => {
    openChooseAvatarDialog({
      title: t('Dialogs:CHOOSE_AVATAR_DIALOG.TITLE_TEAM_MEMBER', {
        teamMember: teamMember.firstName,
      }),
      onOk: (blob: Blob) => setAvatarBlob(blob),
    })
  }

  const handleTeamMemberRemove = () => {
    openRemoveMemberDialog({ memberId: teamMember.id, onDeleted: onClose })
  }

  const onEmailFocus = () => {
    if (error) {
      dispatch(clearMembersError())
    }
  }

  const getUnsavedData = () => {
    if (R.isEmpty(teamMember) || !teamMemberIsLoaded) {
      return false
    }

    const originalRoles = getAssignedRoles(teamMember?.businessToRoleList || [])

    const newRoles = rolesSectionRef.current?.getBusinessRoleList() || []

    const isMemberRolesChanged =
      R.symmetricDifferenceWith(
        equalsByRoleAndBusiness,
        originalRoles,
        newRoles,
      ).length > 0

    return (
      isFieldValuesChanged(fields) ||
      isMemberRolesChanged ||
      Boolean(avatarBlob)
    )
  }

  useFieldsChanged(() => {
    setIsMemberChanged(getUnsavedData())
  }, fields)

  useEffect(() => {
    setIsMemberChanged(getUnsavedData())
  }, [avatarBlob])

  const avatarSrc = avatarBlob ? URL.createObjectURL(avatarBlob) : undefined

  return (
    <Expander
      expandedItemClass={t('Common:TEAM_MEMBER').toLowerCase()}
      getUnsavedData={getUnsavedData}
      hasUnsavedData={isMemberChanged}
      isDeleting={isDeleting}
      isSaving={isUpdating || !teamMemberIsLoaded}
      showButtons={personPermissions.update}
      onBack={onClose}
      onSaveRequested={onSaveRequested}
    >
      <Grid container item direction="column">
        <Grid container item className={classes.topContainer} wrap="nowrap">
          <Grid item mr={6}>
            <Avatar
              alt="userpic"
              className={classes.avatar}
              classes={{
                childrenBackgroundColor: classes.defaultAvatarBackground,
              }}
              person={{ ...teamMember, avatar: teamMember.avatar }}
              size="normal"
              src={avatarSrc}
              onEditClick={
                personPermissions.update ? onAvatarEditRequested : undefined
              }
            >
              <DefaultAvatar className={classes.defaultAvatar} />
            </Avatar>
          </Grid>
          <Grid container item columnSpacing={2} rowSpacing={1}>
            <Grid item sm={6} xs={12}>
              <PuiTextField
                disabled={!personPermissions.update}
                field={firstName}
                inputProps={{ maxLength: 100 }}
                label={`${firstName.label}*`}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <PuiTextField
                disabled={!personPermissions.update}
                field={lastName}
                inputProps={{ maxLength: 100 }}
                label={`${lastName.label}*`}
              />
            </Grid>
            <Grid item sm={7} xs={12}>
              <PuiTextField
                autoComplete="email"
                disabled={
                  !personPermissions.update || Boolean(teamMember.email)
                }
                field={email}
                inputProps={{ maxLength: 100 }}
                label={email.label}
                type="email"
                onFocus={onEmailFocus}
              />
            </Grid>
            <Grid item sm={5} xs={12}>
              <PhoneInput
                disabled={!personPermissions.update}
                field={mobilePhone}
              />
            </Grid>
            <Grid container item xs mt={0} spacing={2}>
              {!currentBusiness?.ssoConfigured && personPermissions.update && (
                <>
                  <Grid item md={3} sm={6} xs={12}>
                    <ButtonWithLoader
                      className={classes.button}
                      color="secondary"
                      loading={isActivating}
                      onClick={() => {
                        dispatch(
                          updateActiveStatus(teamMember.id, !teamMember.active),
                        )
                      }}
                    >
                      {teamMember.active
                        ? t('Common:DEACTIVATE_ACTION')
                        : t('Common:ACTIVATE_ACTION')}
                    </ButtonWithLoader>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <ButtonWithLoader
                      className={classes.button}
                      color="secondary"
                      loading={isDeleting}
                      onClick={handleTeamMemberRemove}
                    >
                      {t('Common:REMOVE_ACTION')}
                    </ButtonWithLoader>
                  </Grid>
                </>
              )}
              <Hidden mdUp smDown>
                <Grid item sm={6} />
              </Hidden>
              {sendInviteLabel && personPermissions.update && (
                <Grid item md={5} sm={6} xs={12}>
                  <ButtonWithLoader
                    className={classes.button}
                    disabled={isInviting || !resendInvitationPossibility}
                    loading={isInviting}
                    onClick={updateAndInvite}
                  >
                    {sendInviteLabel}
                  </ButtonWithLoader>
                </Grid>
              )}
              {!resendInvitationPossibility && (
                <Grid item md={12}>
                  <Text
                    align="center"
                    className={classes.resendInvitationNotice}
                    variant="h4"
                  >
                    {t('Admin:MEMBER.RESEND_INVITE_TEXT', {
                      resendButtonStatusCounter,
                    })}
                  </Text>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          className={classes.panelContainer}
          direction="column"
        >
          <PanelAccordion
            expanded={expandedPanels.includes(
              MemberDetailsPanels.USER_DEPARTMENT,
            )}
            title={MemberDetailsPanelsLabels.USER_DEPARTMENT}
            onChange={() =>
              setExpandedPanels(
                Utils.toggleListItem(
                  MemberDetailsPanels.USER_DEPARTMENT,
                  expandedPanels,
                ),
              )
            }
          >
            <MemberDepartmentSection departmentId={departmentId} />
          </PanelAccordion>
          <PanelAccordion
            expanded={expandedPanels.includes(MemberDetailsPanels.EMPLOYEE_ID)}
            title={MemberDetailsPanelsLabels.EMPLOYEE_ID}
            onChange={() =>
              setExpandedPanels(
                Utils.toggleListItem(
                  MemberDetailsPanels.EMPLOYEE_ID,
                  expandedPanels,
                ),
              )
            }
          >
            <MemberEmployeeIdSection
              teamMember={teamMember}
              onEmployeeIdChange={() => setIsMemberChanged(true)}
            />
          </PanelAccordion>
          <PanelAccordion
            expanded={expandedPanels.includes(MemberDetailsPanels.LICENSES)}
            title={MemberDetailsPanelsLabels.LICENSES}
            onChange={() =>
              setExpandedPanels(
                Utils.toggleListItem(
                  MemberDetailsPanels.LICENSES,
                  expandedPanels,
                ),
              )
            }
          >
            <MemberLicensesSection
              teamMember={teamMember}
              onLicenseChange={() => setIsMemberChanged(true)}
            />
          </PanelAccordion>
          {(personPermissions.update || teamMember.id === currentUserId) && (
            <PanelAccordion
              expanded={expandedPanels.includes(
                MemberDetailsPanels.LOCK_ACCESS,
              )}
              titleComponent={
                <TextWithTooltip
                  tooltipText={t('Admin:MEMBER.TIME_TRACKING_TOOLTIP')}
                  variant="body"
                >
                  {MemberDetailsPanelsLabels.LOCK_ACCESS}
                </TextWithTooltip>
              }
              onChange={() =>
                setExpandedPanels(
                  Utils.toggleListItem(
                    MemberDetailsPanels.LOCK_ACCESS,
                    expandedPanels,
                  ),
                )
              }
            >
              <MemberLockAccessSection
                lockOutsideWorkingHours={lockOutsideWorkingHours}
              />
            </PanelAccordion>
          )}
          <PanelAccordion
            expanded={expandedPanels.includes(
              MemberDetailsPanels.NOTIFICATION_SETTINGS,
            )}
            title={MemberDetailsPanelsLabels.NOTIFICATION_SETTINGS}
            onChange={() =>
              setExpandedPanels(
                Utils.toggleListItem(
                  MemberDetailsPanels.NOTIFICATION_SETTINGS,
                  expandedPanels,
                ),
              )
            }
          >
            <MemberNotificationSettingsSection teamMember={teamMember} />
          </PanelAccordion>
          <PanelAccordion
            expanded={expandedPanels.includes(MemberDetailsPanels.ROLES)}
            title={MemberDetailsPanelsLabels.ROLES}
            onChange={() =>
              setExpandedPanels(
                Utils.toggleListItem(MemberDetailsPanels.ROLES, expandedPanels),
              )
            }
          >
            <MemberRolesSectionContainer
              ref={rolesSectionRef}
              teamMember={teamMember}
              onRoleListChange={() => setIsMemberChanged(getUnsavedData())}
            />
          </PanelAccordion>
          {(personPermissions.update || teamMember.id === currentUserId) && (
            <PanelAccordion
              expanded={expandedPanels.includes(
                MemberDetailsPanels.TIME_TRACKING,
              )}
              titleComponent={
                <TimezoneWarningLabel iconPlacement="right" variant="h4">
                  {MemberDetailsPanelsLabels.TIME_TRACKING}
                </TimezoneWarningLabel>
              }
              onChange={() =>
                setExpandedPanels(
                  Utils.toggleListItem(
                    MemberDetailsPanels.TIME_TRACKING,
                    expandedPanels,
                  ),
                )
              }
            >
              <MemberTimeTrackingSection
                teamMember={teamMember}
                timeTrackingEnabled={timeTrackingEnabled}
              />
            </PanelAccordion>
          )}
          {znLabsConfig?.active && isVeterinarian && (
            <PanelAccordion
              expanded={expandedPanels.includes(
                MemberDetailsPanels.ZOETIS_REFERENCE,
              )}
              title={MemberDetailsPanelsLabels.ZOETIS_REFERENCE}
              onChange={() =>
                setExpandedPanels(
                  Utils.toggleListItem(
                    MemberDetailsPanels.ZOETIS_REFERENCE,
                    expandedPanels,
                  ),
                )
              }
            >
              <MemberZoetisReferenceSection
                teamMember={teamMember}
                znlabsProviderIdentifier={znlabsProviderIdentifier}
              />
            </PanelAccordion>
          )}
          <Grid container>
            <Grid item mt={2} xs={12}>
              <RequiredFieldsNotice />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <PuiAlert
        message={error}
        open={Boolean(error)}
        onClose={() => dispatch(clearMembersError())}
      />
    </Expander>
  )
}

export default TeamMemberDetails
