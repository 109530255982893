import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Nil,
  PatientAvatar,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import { getSpayedNeuteredIntactWithGenderString } from '~/components/common/inputs/gender/genderUtils'
import { getBreed, getGender, getSpecies } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { getFullBreedName } from '~/utils'

import { joinLineParts } from '../../labels/labelsUtils'

const AVATAR_MAX_SIZE = 72

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    avatar: {
      width: AVATAR_MAX_SIZE,
      height: AVATAR_MAX_SIZE,
      marginRight: theme.spacing(2),
    },
    column: {
      '&:first-child': {
        borderRight: theme.constants.tabBorder,
      },
    },
  }),
  { name: 'RxRequestLinkCard' },
)

export interface RxRequestLinkCardProps {
  clientId: string | Nil
  patientId: string | Nil
}

const RxRequestLinkCard = ({ clientId, patientId }: RxRequestLinkCardProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))
  const Breed = useSelector(getBreed)
  const Species = useSelector(getSpecies)
  const Gender = useSelector(getGender)

  const speciesName = Utils.getConstantName(patient?.species, Species, '')
  const breedFullName = getFullBreedName(
    patient?.species,
    patient?.breeds,
    Breed,
  )
  const patientSpeciesAndBreedLine = joinLineParts([speciesName, breedFullName])
  const spayedNeuteredGender = getSpayedNeuteredIntactWithGenderString(
    patient?.gender,
    patient?.spayedNeutered,
    Gender,
  )

  return (
    <Grid container item wrap="nowrap">
      <Grid
        container
        item
        className={classes.column}
        p={2}
        wrap="nowrap"
        xs={6}
      >
        <PatientAvatar
          className={classes.avatar}
          src={patient?.photoThumbnail}
        />
        <Grid item>
          <Text mb={1} variant="h1">
            {patient?.name}
          </Text>
          <Text variant="body2">{patient?.approximateAge}</Text>
          <Text variant="body2">{spayedNeuteredGender}</Text>
          <Text variant="body2">{patientSpeciesAndBreedLine}</Text>
        </Grid>
      </Grid>
      <Grid item p={2} xs={6}>
        <Text mb={1} variant="h1">
          {Utils.getPersonString(client)}
        </Text>
        <Text variant="body2">{client?.mobilePhone}</Text>
        <Text variant="body2">{`${t('Common:BALANCE')}: ${
          client?.balance
        }`}</Text>
      </Grid>
    </Grid>
  )
}

export default RxRequestLinkCard
