import React from 'react'
import { styled } from '@mui/material/styles'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import { Moment } from 'moment'
import { moment, PuiTheme } from '@pbt/pbt-ui-components'

interface CustomPickerDayProps extends PickersDayProps<Moment> {
  pastNotSelected: boolean
  selectedPast: boolean
}

// TODO Failed on changing months because of 'hooks changed' error => switched to this instead of 'useStyles()'
// hook with classes, which otherwise is surely more concise; Check if works with useStyles and switch to classes
// MUI DayPicker must be conditionally using this function for rendering days, hence hooks are undetermined
const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'selectedPast' &&
    prop !== 'pastNotSelected' &&
    prop !== 'renderDay',
})<CustomPickerDayProps & { theme: PuiTheme }>(
  ({ theme, selectedPast, pastNotSelected }) => ({
    ...(selectedPast && {
      backgroundColor: `${theme.colors.alertError} !important`,
    }),
    ...(pastNotSelected && {
      color: theme.colors.tabLabel,
    }),
  }),
) as unknown as React.ComponentType<CustomPickerDayProps>

const TimetableCalendarDayPicker = (
  date: Moment,
  selectedDates: Array<Moment | null>,
  pickersDayProps: PickersDayProps<Moment>,
) => {
  const now = moment()
  const selected = selectedDates?.[0]
  const selectedPast = selected
    ? date.isSame(selected, 'day') && selected.isBefore(now, 'day')
    : false
  const pastNotSelected = date.isBefore(now, 'day') && !selectedPast

  return (
    <CustomPickersDay
      {...pickersDayProps}
      pastNotSelected={pastNotSelected}
      selectedPast={selectedPast}
    />
  )
}

export default TimetableCalendarDayPicker
