import React from 'react'
import { Grid, Hidden, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  ControlButtonGroup,
  ControlButtonGroupName,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      height: 65,
      backgroundColor: theme.colors.tableLeftColumnBackground,
      border: theme.constants.tableBorder,
    },
  }),
  { name: 'AddRow' },
)

export interface AddRowProps {
  ImageComponent?: () => React.ReactNode | null
  classes?: ClassesType<typeof useStyles>
  label: React.ReactNode
  onDelete?: () => void
  onEdit?: () => void
  sublabel: React.ReactNode
}

const AddRow = ({
  classes: classesProp,
  ImageComponent,
  label,
  sublabel,
  onDelete,
  onEdit,
}: AddRowProps) => {
  const classes = useStyles({ classes: classesProp })

  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.root}
      py={2}
      wrap="nowrap"
    >
      {ImageComponent && (
        <Hidden mdDown>
          {/* @ts-ignore */}
          <ImageComponent />
        </Hidden>
      )}
      <Grid item xs px={2}>
        <Text noWrap variant={isMobile ? 'body2' : 'h3'}>
          {label}&nbsp;
          {sublabel && (
            <Text
              component="span"
              strong={false}
              variant={isMobile ? 'body2' : 'h3'}
            >
              {sublabel}
            </Text>
          )}
        </Text>
      </Grid>
      <Grid item>
        <ControlButtonGroup
          buttons={[
            onDelete && {
              name: ControlButtonGroupName.DELETE,
              onClick: onDelete,
            },
            onEdit && {
              name: ControlButtonGroupName.EDIT,
              onClick: onEdit,
            },
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default AddRow
