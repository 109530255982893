/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  ControlButtonGroup,
  ControlButtonGroupName,
  Nil,
  Text,
} from '@pbt/pbt-ui-components'

import MenuDropdown from '~/components/common/inputs/MenuDropdown'
import PrintLabOrderType from '~/constants/PrintLabOrderType'
import i18n from '~/locales/i18n'
import { useGetOrderPreviewActions } from '~/store/hooks/labTestsDashboard'
import { LabOrder, LabTestDashboardOrder } from '~/types'

const renderPrintDropdownWithHandler =
  (handler: (printType: string) => void) => (button: JSX.Element) =>
    (
      <MenuDropdown
        Button={button}
        items={[
          {
            name: i18n.t('Common:PRINT_ORDER'),
            value: PrintLabOrderType.Order,
          },
          {
            name: i18n.t('Common:PRINT_LAB_LABEL'),
            value: PrintLabOrderType.Label,
          },
        ]}
        onSelected={handler}
      />
    )

const useStyles = makeStyles(
  () => ({
    root: {},
    label: {},
  }),
  { name: 'LabOrderPreviewButtons' },
)

export interface LabOrderPreviewButtonsProps {
  classes?: ClassesType<typeof useStyles>
  hidePreview?: boolean
  onEdit?: () => void
  onLabelPrint?: () => void
  order: LabTestDashboardOrder | LabOrder | Nil
  soapBusinessId: string | Nil
  soapId: string | Nil
  vendorId: string | Nil
}

const LabOrderPreviewButtons = ({
  classes: classesProp,
  onEdit,
  onLabelPrint,
  order,
  soapId,
  soapBusinessId,
  vendorId,
  hidePreview,
}: LabOrderPreviewButtonsProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const getOrderPreviewActions = useGetOrderPreviewActions({
    renderPrintDropdown: renderPrintDropdownWithHandler,
  })

  const availableActions = getOrderPreviewActions({
    onEdit,
    onLabelPrint,
    order,
    soapId,
    soapBusinessId,
    vendorId,
  })

  if (!availableActions) {
    return null
  }

  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      width="auto"
      wrap="nowrap"
    >
      <Text className={classes.label}>{t('Common:ORDER')}:</Text>
      <ControlButtonGroup
        buttons={[
          availableActions.edit && {
            name: ControlButtonGroupName.EDIT,
            onClick: availableActions.edit.handle,
          },
          availableActions.print && {
            name: ControlButtonGroupName.PRINT,
            onClick: availableActions.print.handle,
            renderer: availableActions.print.renderer,
          },
          !hidePreview &&
            availableActions.preview && {
              name: ControlButtonGroupName.PREVIEW,
              onClick: availableActions.preview.handle,
            },
        ]}
      />
    </Grid>
  )
}

export default LabOrderPreviewButtons
