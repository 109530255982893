import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  LanguageUtils,
  PuiTextField,
  PuiTheme,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import { BusinessDto } from '~/store/dto/Business'
import { PersonBusinessRole, PersonDto } from '~/store/dto/Person'
import {
  getIsActivationKeyFlow,
  getValidationMetadata,
} from '~/store/reducers/registration'
import {
  getMultipleRoles,
  getRole,
  getStaffRolesList,
} from '~/store/reducers/roles'
import useFieldsChanged from '~/utils/useFieldsChanged'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      [theme.breakpoints.down('md')]: {
        backgroundColor: '#F7F7F7',
        paddingBottom: 24,
        paddingTop: 20,
        marginTop: 16,
      },
    },
    input: {
      marginTop: 0,
    },
    expanded: {
      backgroundColor: '#F7F7F7',
      borderRadius: 4,
      marginBottom: 12,
    },
    inputContainer: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    select: {
      [theme.breakpoints.down('md')]: {
        maxWidth: 270,
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    menuItem: {
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
      '&:focus': {
        backgroundColor: '#FFFFFF',
      },
    },
    menuItemSelected: {
      backgroundColor: '#FFFFFF !important',
    },
  }),
  { name: 'MemberInfo' },
)

interface MemberInfoProps {
  businesses: Record<string, BusinessDto>
  expanded: boolean
  focused: boolean
  highlightErrors: boolean
  member: PersonDto
  onChange: (newMember: PersonDto) => void
}

const MemberInfo = ({
  member,
  businesses,
  expanded,
  focused,
  onChange,
  highlightErrors,
}: MemberInfoProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Registration', 'Validations'])

  const staffRoles = useSelector(getStaffRolesList)
  const metadata = useSelector(getValidationMetadata)
  const isActivationFlow = useSelector(getIsActivationKeyFlow)
  const currentUserRole = useSelector(
    getRole(metadata?.defaultAdministratorRoleId.toString() || ''),
  )
  const allowedToCreateRoles = useSelector(
    getMultipleRoles(currentUserRole?.allowedRolesForOtherPersonSet || []),
  )

  const roles = isActivationFlow
    ? allowedToCreateRoles
    : R.filter(({ hidden }) => !hidden, staffRoles)

  const getInitialBusinesses = () => {
    const values = Object.values(businesses)
    if (values.length === 1) {
      return [values[0].id]
    }
    return member?.businessRoles?.map(
      ({ businessId }: PersonBusinessRole) => businessId,
    )
  }

  const { fields, validate } = useFields(
    [
      {
        name: 'email',
        initialValue: member.email ?? '',
        validators: ['email'],
      },
      {
        name: 'jobId',
        initialValue:
          member.jobId ??
          member.businessRoles.reduce(
            (acc, _) => (_.jobId as string) || acc,
            '',
          ) ??
          '',
        validators: ['required'],
      },
      {
        name: 'assignedBusinesses',
        initialValue: getInitialBusinesses(),
      },
    ],
    false,
  )

  const { email, jobId, assignedBusinesses } = fields

  const [assignedBusinessSelectOpen, setAssignedBusinessSelectOpen] =
    useState(false)
  const [jobSelectOpen, setJobSelectOpen] = useState(false)

  useFieldsChanged(() => {
    validate()
    onChange({
      ...member,
      jobId: jobId.value,
      email: email.value,
      businessRoles: assignedBusinesses.value.map((businessId: string) => ({
        businessId,
        jobId: jobId.value,
      })),
    })
  }, fields)

  return (
    <Grid
      container
      className={classNames(classes.root, expanded && classes.expanded)}
    >
      <Grid item md={7} xs={12}>
        <div className={classes.inputContainer}>
          <PuiTextField
            TooltipProps={{
              disablePortal: true,
              message: t('Validations:EMAIL_INVALID'),
              open: focused && highlightErrors && !email.valid,
            }}
            autoComplete="off"
            autoFocus={focused}
            className={classes.input}
            error={highlightErrors && !email.valid}
            field={email}
            label={`${t('Registration:MEMBER_INFO.TEAM_MEMBER_EMAIL')}*`}
            type="email"
          />
        </div>
      </Grid>
      <Grid item className={classes.inputContainer} md={5} xs={12}>
        <FormControl fullWidth className={classes.input} margin="normal">
          <InputLabel htmlFor="job-type-select">
            {t('Common:JOB_TYPE')}*
          </InputLabel>
          <Select
            classes={{
              select: classes.select,
            }}
            error={highlightErrors && !jobId.valid}
            input={<Input id="job-type-select" />}
            open={jobSelectOpen}
            value={jobId.value}
            onChange={(event) => jobId.setValue(event.target.value)}
            onClose={() => setJobSelectOpen(false)}
            onOpen={() => setJobSelectOpen(true)}
          >
            <MenuItem value="" />
            {roles.map((role) => (
              <MenuItem key={role.id} value={role.id}>
                {LanguageUtils.getTranslatedFieldName(role)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {expanded && (
        <Grid item xs={12}>
          <div className={classes.inputContainer}>
            <FormControl fullWidth className={classes.input} margin="normal">
              <InputLabel htmlFor="practice-select">
                {t('Common:ASSIGNED_PRACTICE_ONE_OR_OTHER')}*
              </InputLabel>
              <Select
                multiple
                autoWidth={false}
                classes={{
                  select: classes.select,
                }}
                error={highlightErrors && !assignedBusinesses.value.length}
                input={<Input id="practice-select" />}
                open={assignedBusinessSelectOpen}
                renderValue={(selected) => (
                  <Text noWrap variant="body2">
                    {selected
                      .map((id: string) => businesses[id].name)
                      .join(', ')}
                  </Text>
                )}
                value={assignedBusinesses.value}
                onChange={(event) => {
                  assignedBusinesses.setValue(event.target.value)
                }}
                onClose={() => setAssignedBusinessSelectOpen(false)}
                onOpen={() => setAssignedBusinessSelectOpen(true)}
              >
                {Object.values(businesses).map((business) => {
                  const selected =
                    assignedBusinesses.value.indexOf(business.id) > -1
                  return (
                    <MenuItem
                      classes={{
                        root: classes.menuItem,
                        selected: classes.menuItemSelected,
                      }}
                      key={business.id}
                      selected={selected}
                      value={business.id}
                    >
                      <Checkbox checked={selected} />
                      <ListItemText
                        primary={
                          <Text noWrap variant="body2">
                            {business.name}
                          </Text>
                        }
                      />
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
        </Grid>
      )}
    </Grid>
  )
}

export default MemberInfo
