import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Fab, Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PermissionArea, PuiTheme } from '@pbt/pbt-ui-components'

import ListSearchFilterPanel from '~/components/common/lists/ListSearchFilterPanel'
import DialogNames from '~/constants/DialogNames'
import {
  getContactsIsLoading,
  getContactsTotalCount,
} from '~/store/duck/contacts'
import { getCRUDByArea } from '~/store/reducers/auth'
import { addSearch } from '~/utils'
import useDialog from '~/utils/useDialog'

import ContactDetails from './ContactDetails'
import ContactsTableComponent from './ContactsTableComponent'

const useStyles = makeStyles(
  () => ({
    addButton: {
      minWidth: 120,
      height: 40,
    },
  }),
  { name: 'ContactsPage' },
)

const ContactsPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { contactId } = useParams()
  const location = useLocation()
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )
  const { t } = useTranslation('Common')

  const permissions = useSelector(getCRUDByArea(PermissionArea.CONTACT))
  const totalCount = useSelector(getContactsTotalCount)
  const isLoading = useSelector(getContactsIsLoading)

  const [openNewContactDialog] = useDialog(DialogNames.CONTACT)

  const onDetailsClose = () => {
    navigate(addSearch(location, '/admin/general/contacts'))
  }

  if (isMobile && typeof contactId !== 'undefined') {
    return <ContactDetails itemId={contactId} onClose={onDetailsClose} />
  }

  const headerButtons = (
    <Grid
      container
      item
      alignItems="center"
      justifyContent="space-between"
      px={3}
      py={2}
    >
      <Grid item>
        <ListSearchFilterPanel isLoading={isLoading} searchCount={totalCount} />
      </Grid>
      <Grid item>
        {permissions.create && (
          <Fab
            className={classes.addButton}
            color="inherit"
            variant="extended"
            onClick={() => openNewContactDialog()}
          >
            {t('Common:ADD_CONTACT')}
          </Fab>
        )}
      </Grid>
    </Grid>
  )

  return (
    <Grid container item flex={1} wrap="nowrap">
      <ContactsTableComponent
        contactId={contactId}
        headerButtons={permissions.create && headerButtons}
        onDetailsClose={onDetailsClose}
      />
    </Grid>
  )
}

export default ContactsPage
