import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme } from '@pbt/pbt-ui-components'

import RhapsodyLogo from '~/components/common/RhapsodyLogo'

import TermsAndConditionsContent from './TermsAndConditionsContent'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    formContainer: {
      height: 150,
      background: theme.colors.loginPageBackground,
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
        height: 110,
      },
    },
    rhapsodyLogo: {
      marginLeft: 130,
      marginTop: 40,
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
        marginLeft: 0,
        marginTop: 22,
      },
    },
    pageContainer: {
      maxWidth: '1400px',
    },
    textContainer: {
      paddingBottom: 140,
      [theme.breakpoints.down('lg')]: {
        paddingBottom: 20,
      },
    },
    textOffsets: {
      paddingLeft: 140,
      paddingRight: 275,
      [theme.breakpoints.down('lg')]: {
        paddingLeft: 38,
        paddingRight: 38,
      },
    },
    firstSection: {
      paddingTop: 55,
      paddingBottom: 60,
      background: theme.colors.eulaPageSection,
      boxShadow: 'inset 0 4px 4px 0 rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.down('lg')]: {
        paddingTop: 30,
        paddingBottom: 20,
      },
    },
    firstSubtitle: {
      paddingTop: 50,
      [theme.breakpoints.down('lg')]: {
        paddingTop: '30px !important',
      },
    },
    text: {
      paddingTop: theme.spacing(0.5),
    },
    subtitle: {
      paddingTop: theme.spacing(6),
    },
  }),
  { name: 'TermsAndConditions' },
)

const TermsAndConditions = () => {
  const classes = useStyles()

  return (
    <Grid container justifyContent="center">
      <Grid container className={classes.pageContainer}>
        <Grid container className={classes.formContainer}>
          <RhapsodyLogo className={classes.rhapsodyLogo} />
        </Grid>
        <Grid
          container
          alignItems="flex-start"
          className={classes.textContainer}
          direction="column"
        >
          <TermsAndConditionsContent
            showTitle
            classes={{
              sections: classes.textOffsets,
              firstSection: classNames(
                classes.textOffsets,
                classes.firstSection,
              ),
              firstSubtitle: classes.firstSubtitle,
              text: classes.text,
              subtitle: classes.subtitle,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TermsAndConditions
