import React from 'react'
import { Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { DateFormat, moment, PuiTheme, Text } from '@pbt/pbt-ui-components'

import useTimetableDate from '~/utils/useTimetableDate'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      width: theme.constants.boardingColumnWidth,
      padding: theme.spacing(0, 1),
      border: theme.constants.tableBorder,
      height: 40,
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(1),
      borderRadius: 2,
    },
    todayRoot: {
      border: '1px solid #5798B2',
    },
    day: {
      color: theme.colors.sideText,
    },
  }),
  { name: 'BoardingColumnHeader' },
)

export interface BoardingColumnHeaderProps {
  column: string
}

const BoardingColumnHeader = ({ column }: BoardingColumnHeaderProps) => {
  const classes = useStyles()

  const { selectedDate } = useTimetableDate()

  const date = moment(column, 'YYYY-MM-DD')
  const isToday = date.isSame(selectedDate, 'day')

  return (
    <Paper
      className={classNames(classes.root, {
        [classes.todayRoot]: isToday,
      })}
      elevation={0}
    >
      <Text strong variant="subheading3">
        {date.format(DateFormat.FULL_DATE_YEAR_ABBREV)}
      </Text>
      <Text strong className={classes.day} variant="lowAccent5">
        {date.format(DateFormat.ONLY_FULL_DAY)}
      </Text>
    </Paper>
  )
}

export default BoardingColumnHeader
