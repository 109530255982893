import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Collapse, Grid, LinearProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'
import {
  AlertIconType,
  BasePuiDialogProps,
  ButtonWithLoader,
  LinkButton,
  Nil,
  PuiDialog,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { EventTypeName } from '@pbt/pbt-ui-components/src/types/entities'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  PrescriptionDeclineReasonName,
  PrescriptionDeclineReasonType,
} from '~/constants/PrescriptionWorkflow'
import SnackNotificationType from '~/constants/SnackNotificationType'
import { PrescriptionItemState } from '~/constants/SOAPStates'
import { editTaskState } from '~/store/actions/tasks'
import {
  declineReactiveRx,
  fetchPrescription,
  getIsReactiveRxUnlinkedDetailsLoading,
  getPrescriptionById,
  getPrescriptionError,
  getPrescriptionIsAddingPatientToRx,
  getReactiveRxUnlinkedDetailsByPrescriptionId,
  linkReactiveRx,
  reactiveRxUnlinkedClientDetails,
} from '~/store/duck/prescriptions'
import { addUiNotification } from '~/store/duck/uiNotifications'
import { useGetPrescriptionStateIdByName } from '~/store/hooks/prescription'
import { getCurrentUserId } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getTask } from '~/store/reducers/tasks'
import { ConversationRecipientContext } from '~/types'
import { removeServerErrorPrefix } from '~/utils/errors'
import {
  convertOrderToRxInput,
  convertPrescriptionV2ToPrescription,
} from '~/utils/prescription'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import { useEventType } from '~/utils/useEventType'

import DetailsBackButton from '../../clients/DetailsBackButton'
import ClientSelector from '../../timetable/scheduler/appointment/ClientSelector'
import RxRequestLinkCard from './RxRequestLinkCard'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 550,
      maxWidth: 550,
      overflowY: 'visible',
    },
    button: {
      width: 165,
      marginRight: theme.spacing(2),
    },
    backButton: {
      marginLeft: theme.spacing(-1.5),
    },
    dialogContentRoot: {
      overflowY: 'visible',
    },
  }),
  { name: 'AddClientPatientDialog' },
)

export interface AddClientPatientToRxRequestDialogProps
  extends BasePuiDialogProps {
  onAssign: (clientId: string, patientId: string | Nil) => void
  prescriptionId: string | Nil
  taskId: string | Nil
}

const AddClientPatientToRxRequestDialog = ({
  onAssign,
  onClose,
  open,
  prescriptionId,
  taskId,
}: AddClientPatientToRxRequestDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Tasks'])

  const prescriptionV2 = useSelector(getPrescriptionById(prescriptionId))
  const prescription = convertPrescriptionV2ToPrescription(prescriptionV2)
  const unlinkedDetails = useSelector(
    getReactiveRxUnlinkedDetailsByPrescriptionId(prescriptionId),
  )
  const isReactiveRxUnlinkedDetailsLoading = useSelector(
    getIsReactiveRxUnlinkedDetailsLoading,
  )
  const currentUserId = useSelector(getCurrentUserId)
  const isAddingPatientToRx = useSelector(getPrescriptionIsAddingPatientToRx)
  const prescriptionError = useSelector(getPrescriptionError)
  const task = useSelector(getTask(taskId))
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const [clientPatientSlot, setClientPatientSlot] =
    useState<ConversationRecipientContext>({})

  const getPrescriptionStateIdByName = useGetPrescriptionStateIdByName()

  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const slotSelected = Boolean(clientPatientSlot.clientId)

  const onClientPatientSelected = (
    clientId: string | Nil,
    patientId: string | Nil,
  ) => {
    setClientPatientSlot({ clientId, patientId })
  }

  const handleBack = () => {
    setClientPatientSlot({})
  }

  const taskStates = useEventType(EventTypeName.Task, 'states')
  const doneState = Utils.findConstantIdByName('Done', taskStates)

  const setTaskState = (stateId?: string) => {
    if (taskId && stateId) {
      dispatch(editTaskState(taskId, stateId))
    }
  }

  const handleErrorOrClose = (taskStateId?: string) => {
    if (prescriptionError) {
      openAlert({
        iconType: AlertIconType.WARN,
        content: (
          <Grid mb={3} mt={1}>
            {removeServerErrorPrefix(prescriptionError)}
          </Grid>
        ),
        onOk: closeAlert,
      })
    } else if (onClose) {
      setTaskState(taskStateId)
      onClose()
    }
  }

  const notAClinicPatientDeclineReason =
    PrescriptionDeclineReasonName[
      PrescriptionDeclineReasonType.NOT_A_CLINIC_PATIENT
    ]

  const handleAfterDecline = () => {
    handleErrorOrClose(doneState)
    if (!prescriptionError) {
      dispatch(
        addUiNotification({
          id: uuid(),
          message: t('Tasks:TASK_NOTIFICATIONS.DECLINED', {
            taskName: task?.name || t('Common:TASK'),
            forPatient: '',
            declineReason: notAClinicPatientDeclineReason,
          }),
          type: SnackNotificationType.TASK_ACTION,
          params: {
            taskId,
          },
        }),
      )
    }
  }

  const setCloseAfterLinking = useCloseAfterCreation(
    (clientId: string, patientId: string | Nil) => {
      handleErrorOrClose()

      if (onAssign && !prescriptionError) {
        onAssign(clientId, patientId)
      }
    },
    getPrescriptionIsAddingPatientToRx,
  )

  const setCloseAfterDecline = useCloseAfterCreation(
    handleAfterDecline,
    getPrescriptionIsAddingPatientToRx,
  )

  const handleLinkClientPatient = () => {
    const { clientId, patientId } = clientPatientSlot
    if (prescriptionId && clientId && patientId) {
      dispatch(linkReactiveRx({ prescriptionId, clientId, patientId }))
      setCloseAfterLinking(prescriptionId, clientId, patientId)
    }
  }

  const handleDeclineRequest = () => {
    if (prescription) {
      dispatch(
        declineReactiveRx({
          prescriptionId: prescription.id,
          input: {
            ...convertOrderToRxInput(
              {
                ...prescription,
                rejectionReason: notAClinicPatientDeclineReason,
                stateId: getPrescriptionStateIdByName(
                  PrescriptionItemState.DECLINED,
                ),
              },
              isFoodCatalogEnabled,
            ),
            signerId: currentUserId,
          },
        }),
      )
      setCloseAfterDecline()
    }
  }

  useEffect(() => {
    if (prescriptionId) {
      dispatch(reactiveRxUnlinkedClientDetails({ prescriptionId }))
      dispatch(fetchPrescription(prescriptionId))
    }
  }, [prescriptionId])

  return (
    <PuiDialog
      actions={
        <Grid item>
          {slotSelected && (
            <ButtonWithLoader
              className={classes.button}
              loading={isAddingPatientToRx}
              onClick={handleLinkClientPatient}
            >
              {t('Common:ASSIGN_RX_REQUEST')}
            </ButtonWithLoader>
          )}
          <LinkButton
            loading={isAddingPatientToRx}
            onClick={handleDeclineRequest}
          >
            {t('Common:DECLINE_REQUEST')}
          </LinkButton>
        </Grid>
      }
      aria-labelledby="add-client-patient-to-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      header={
        slotSelected && (
          <DetailsBackButton
            className={classes.backButton}
            onClick={handleBack}
          >
            {t('Common:BACK_TO_SEARCH')}
          </DetailsBackButton>
        )
      }
      open={open}
      title={t('Common:ASSIGN_RX_REQUEST_TO_PATIENT')}
      onClose={onClose}
    >
      {slotSelected ? (
        <RxRequestLinkCard
          clientId={clientPatientSlot.clientId}
          patientId={clientPatientSlot.patientId}
        />
      ) : (
        <Grid p={2}>
          <Collapse
            in={isReactiveRxUnlinkedDetailsLoading && R.isNil(unlinkedDetails)}
          >
            {' '}
            <LinearProgress />
          </Collapse>
          <Collapse in={Boolean(unlinkedDetails)}>
            <Text>
              {Utils.getPersonString({
                firstName: unlinkedDetails?.clientFirstName || undefined,
                lastName: unlinkedDetails?.clientLastName || undefined,
              })}
            </Text>
            <Text>{unlinkedDetails?.patientName}</Text>
            <Text>{prescription?.drugMasterName}</Text>
          </Collapse>

          <ClientSelector
            maxSearchValuesInList={3}
            resultsTitle={t('Common:ADD_CLIENT_AND_PATIENT')}
            onSelected={onClientPatientSelected}
          />
        </Grid>
      )}
    </PuiDialog>
  )
}

export default AddClientPatientToRxRequestDialog
