import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { IdObject, Nil, PuiTheme } from '@pbt/pbt-ui-components'

import FileInput, { FileInputProps } from '../../file-input/FileInput'
import AttachmentListWithFilters, {
  AttachmentListWithFiltersProps,
} from './AttachmentListWithFilters'
import AttachmentUploadIcon from './AttachmentUploadIcon'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      padding: theme.spacing(2),
    },
    imageInputInputArea: {
      padding: theme.spacing(1),
    },
    imageInputLogoArea: {
      height: 118,
    },
    imageInputText: {
      fontSize: '1.6rem',
    },
  }),
  { name: 'Attachment' },
)

export interface AttachmentProps {
  ListWithFiltersProps?: Partial<AttachmentListWithFiltersProps>
  eventId?: string | Nil
  onFileSelected: FileInputProps['onFileSelected']
  onProceed: (items: IdObject[]) => void
  patientId?: string | Nil
}

const Attachment = ({
  patientId,
  eventId,
  onFileSelected,
  onProceed,
  ListWithFiltersProps,
}: AttachmentProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Dialogs')

  return (
    <Grid container item className={classes.root} direction="column">
      <FileInput
        clearOnCancel
        forceButtonsHidden
        Icon={AttachmentUploadIcon}
        aspectRatio={1}
        classes={{
          text: classes.imageInputText,
          inputArea: classes.imageInputInputArea,
          logoAreaClassName: classes.imageInputLogoArea,
        }}
        size="normal"
        text={t('Dialogs:ATTACHMENT_DIALOG.CLICK_HERE_TO_UPLOAD_A_FILE')}
        uploadButtonColor="primary"
        onFileSelected={onFileSelected}
      />
      <Grid container item direction="column" mt={2}>
        <AttachmentListWithFilters
          eventId={eventId}
          patientId={patientId}
          onProceed={onProceed}
          {...(ListWithFiltersProps || {})}
        />
      </Grid>
    </Grid>
  )
}

export default Attachment
