import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Fab, Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PermissionArea, PuiTheme } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { getCRUDByArea, getCurrentBusiness } from '~/store/reducers/auth'
import useDialog from '~/utils/useDialog'

import MembersTableComponent from './MembersTableComponent'
import TeamMemberDetails from './TeamMemberDetails'

const useStyles = makeStyles(
  () => ({
    addButton: {
      minWidth: 160,
      height: 40,
    },
  }),
  { name: 'MembersPage' },
)

interface MembersPageLocationState {
  openTeamMemberDialog?: boolean
}

const MembersPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const { openTeamMemberDialog: openTeamMemberDialogProp = false } =
    (location.state as MembersPageLocationState) || {}
  const { memberId } = useParams()
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )
  const { t } = useTranslation('Admin')

  const currentBusiness = useSelector(getCurrentBusiness)
  const permissions = useSelector(getCRUDByArea(PermissionArea.PERSON))

  const [openTeamMemberDialog] = useDialog(DialogNames.TEAM_MEMBER)

  const onDetailsClose = () => {
    navigate('/admin/general/members')
  }

  const tryOpenTeamMemberDialog = () => {
    if (permissions.create) {
      openTeamMemberDialog()
    }
  }

  useEffect(() => {
    if (openTeamMemberDialogProp) {
      tryOpenTeamMemberDialog()
    }
  }, [openTeamMemberDialogProp])

  if (isMobile && typeof memberId !== 'undefined') {
    return <TeamMemberDetails itemId={memberId} onClose={onDetailsClose} />
  }

  const headerButtons = (
    <Grid container item justifyContent="flex-end" px={3} py={2}>
      <Fab
        className={classes.addButton}
        color="inherit"
        variant="extended"
        onClick={tryOpenTeamMemberDialog}
      >
        {t('Admin:MEMBER.ADD_TEAM_MEMBER')}
      </Fab>
    </Grid>
  )

  return (
    <Grid container item flex={1} wrap="nowrap">
      <MembersTableComponent
        headerButtons={
          permissions.create && !currentBusiness?.ssoConfigured && headerButtons
        }
        memberId={memberId}
        onDetailsClose={onDetailsClose}
      />
    </Grid>
  )
}

export default MembersPage
