import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Star } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  PhoneUtils,
  PuiTheme,
  TextInteractive,
  Utils,
} from '@pbt/pbt-ui-components'
import { Delete } from '@pbt/pbt-ui-components/src/icons'

import useGetPreferredContactMethod from '~/components/dashboard/clients/details/new-client-and-patient/useGetPreferredContactMethod'
import { ClientPreferencesChip } from '~/components/dashboard/clients/preferences/ClientPreferencesChip'
import { AlertColorLevel } from '~/constants/alertColors'
import { getClientIsLoading } from '~/store/reducers/clients'
import { getContactMethod } from '~/store/reducers/constants'
import { getUser } from '~/store/reducers/users'
import { ContactMethodName } from '~/types'

import AlertIcon from '../labels/AlertIcon'
import Link from '../link/Link'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      height: '100%',
      border: theme.constants.tableBorder,
      borderRadius: 2,
    },
    link: {
      color: theme.colors.primaryText,
    },
    text: {
      fontSize: '1.4rem',
    },
    deleteIcon: {
      padding: theme.spacing(0.5),
    },
    star: {
      color: '#5a98b1',
      width: 15,
      height: 15,
      marginLeft: theme.spacing(0.5),
    },
    chip: {
      width: 16,
      height: 16,
      marginRight: 4,
      marginLeft: 4,
    },
  }),
  { name: 'ClientCard' },
)

export interface ClientCardProps {
  clientId: string
  onRemove?: () => void
  showPreferencesChip?: boolean
  title?: string
}

const ClientCard = ({
  clientId,
  title,
  onRemove,
  showPreferencesChip = true,
}: ClientCardProps) => {
  const classes = useStyles()
  const isLoading: boolean = useSelector(getClientIsLoading)
  const client = useSelector(getUser(clientId))
  const { t } = useTranslation('Common')
  const ContactMethod = useSelector(getContactMethod)

  const { value, label, fieldName } = useGetPreferredContactMethod({ client })
  const preferredFieldName = client?.preferredContactMethodId && fieldName

  const preferredIsPhone =
    client?.preferredContactMethodId && preferredFieldName !== 'email'
  const preferredIsText =
    client?.preferredContactMethodId ===
    Utils.findConstantIdByName(ContactMethodName.TEXT_MESSAGE, ContactMethod)

  const preferredText =
    client?.preferredContactMethodId && value
      ? preferredIsPhone
        ? `${label} ${
            preferredIsText ? t('Common:TEXT') : t('Common:CALL_NOUN')
          } ${value}`
        : `${value}`
      : ''

  return (
    <Grid container item className={classes.root} p={1} wrap="nowrap">
      <Grid container direction="column" pl={client?.alertText ? 0 : 1}>
        <Grid container wrap="nowrap">
          <Grid container alignItems="center" pb={1.25}>
            {client?.alertText && (
              <AlertIcon
                alertColorId={client?.alertColorId}
                alertColorLevel={AlertColorLevel.CLIENT}
                alertText={client?.alertText}
                size="small"
              />
            )}
            {showPreferencesChip && (
              <ClientPreferencesChip
                className={classes.chip}
                clientId={clientId}
              />
            )}
            <TextInteractive
              strong
              isLoading={isLoading}
              loaderWidth={50}
              variant="subheading2"
            >
              <Link
                className={classes.link}
                rel="noopener noreferrer"
                target="_blank"
                to={`/client/${clientId}`}
              >
                {Utils.getPersonString(client)}
              </Link>
              {title && ` | ${title}`}
            </TextInteractive>
          </Grid>
          {onRemove && (
            <Grid item>
              <IconButton
                aria-label={t('Common:DELETE_ACTION').toLowerCase()}
                className={classes.deleteIcon}
                size="large"
                onClick={onRemove}
              >
                <Delete />
              </IconButton>
            </Grid>
          )}
        </Grid>

        {preferredText && (
          <Grid container alignItems="center">
            <TextInteractive strong isLoading={isLoading} variant="body2">
              {preferredText}
            </TextInteractive>
            <Star className={classes.star} />
          </Grid>
        )}

        {client?.email && preferredFieldName !== 'email' && (
          <TextInteractive isLoading={isLoading} variant="body2">
            {client?.email}
          </TextInteractive>
        )}

        {client?.mobilePhone && preferredFieldName !== 'mobilePhone' && (
          <TextInteractive
            isLoading={isLoading}
            loaderWidth={90}
            variant="body2"
          >
            {`${t('Common:MOBILE')}: ${PhoneUtils.formatPhoneNumber(
              client?.mobilePhone,
            )}`}
          </TextInteractive>
        )}

        {client?.homePhone && preferredFieldName !== 'homePhone' && (
          <TextInteractive
            isLoading={isLoading}
            loaderWidth={120}
            variant="body2"
          >
            {`${t('Common:HOME_PHONE')}: ${PhoneUtils.formatPhoneNumber(
              client?.homePhone,
            )}`}
          </TextInteractive>
        )}

        {client?.workPhone && preferredFieldName !== 'workPhone' && (
          <TextInteractive
            isLoading={isLoading}
            loaderWidth={120}
            variant="body2"
          >
            {`${t('Common:WORK_PHONE')}: ${PhoneUtils.formatPhoneNumber(
              client?.workPhone,
            )}`}
          </TextInteractive>
        )}

        {client?.otherPhone && preferredFieldName !== 'otherPhone' && (
          <TextInteractive
            isLoading={isLoading}
            loaderWidth={120}
            variant="body2"
          >
            {`${t('Common:OTHER_PHONE')}: ${PhoneUtils.formatPhoneNumber(
              client?.otherPhone,
            )}`}
          </TextInteractive>
        )}
      </Grid>
    </Grid>
  )
}

export default ClientCard
