import { LanguageUtils, Nil, SentenceFormatter } from '@pbt/pbt-ui-components'

import {
  CardPaymentMethod,
  PayPalPaymentMethod,
  ShippingAddress,
} from '~/api/graphql/generated/types'
import i18n from '~/locales/i18n'

export function getPrimary<T = any>(items: T[] = []) {
  if (items.length === 0) {
    return null
  }
  return items.find((item) => (item as any)?.primary) ?? items[0]
}

export function formatShippingAddress(shippingAddress: ShippingAddress | Nil) {
  return LanguageUtils.getSentence(
    [shippingAddress?.address?.street1, shippingAddress?.address?.city],
    SentenceFormatter.REGULAR,
    ', ',
  )
}

export const formatCardPaymentMethod = (cardPaymentMethod: CardPaymentMethod) =>
  LanguageUtils.getSentence(
    [
      LanguageUtils.getSentence(
        [
          cardPaymentMethod.cardHolderName,
          cardPaymentMethod.paymentCardType?.name,
        ],
        SentenceFormatter.REGULAR,
        ' | ',
      ),
      cardPaymentMethod.lastFour,
    ],
    SentenceFormatter.REGULAR,
  )

export const formatPayPalPaymentMethod = (
  payPalPaymentMethod: PayPalPaymentMethod,
) =>
  LanguageUtils.getSentence(
    [i18n.t<string>('Payments:PAY_PAL'), payPalPaymentMethod.email],
    SentenceFormatter.REGULAR,
  )

export const formatPaymentMethod = (
  paymentMethod: CardPaymentMethod | PayPalPaymentMethod,
) => {
  switch (paymentMethod.__typename) {
    case 'CardPaymentMethod':
      return formatCardPaymentMethod(paymentMethod)
    case 'PayPalPaymentMethod':
      return formatPayPalPaymentMethod(paymentMethod)
    default:
      return ''
  }
}
