import React, { useEffect, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Mustache from 'mustache'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  LabelTemplates,
  LabelTemplatesHtmlConstant,
  PuiDialog,
  PuiTextField,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { getDeviceType } from '@pbt/pbt-ui-components/src/utils/browserUtils'

import { DymoLabelType } from '~/constants/dymo'
import {
  fetchDymoLabelXML,
  fetchHtmlLabel,
  getDymoLabelIsLoading,
  getDymoLabelProps,
  getHtmlLabelIsLoading,
  getHtmlLabelProps,
} from '~/store/duck/labelPrinting'
import { getCurrentBusiness } from '~/store/reducers/auth'
import {
  getLabelTemplates,
  getLabelTemplatesFormatted,
  getLabelTemplatesHtml,
} from '~/store/reducers/constants'
import { PrintFormattedData } from '~/types/entities/print'
import { arrayToMap, replaceLineBreaksWithTag, stringDivider } from '~/utils'
import useIsDymoConnectEnabled from '~/utils/useIsDymoEnabled'

import FormattedLabel from '../labelPrinting/FormattedLabel'
import PrintLabelSection from '../labelPrinting/PrintLabelSection'
import {
  applyRestrictions,
  enableFitMode,
  getFormattedLabelClipboardContent,
  getScale,
} from '../labelPrinting/utils'
import PrintBrotherPrinterPDF from '../print/PrintBrotherPrinterPDF'
import PrintHtml from '../print/PrintHtml'
// @ts-ignore
import PrintHtmlLabel from '../print/PrintHtmlLabel'

const LINE_WIDTH = 70

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
    textArea: {
      color: theme.colors.secondaryText,
    },
    spinner: {
      position: 'absolute',
      top: 'calc(50% - 16px)',
      left: 'calc(50% - 16px)',
    },
    labelRoot: {
      marginBottom: theme.spacing(1.5),
    },
    radioLabel: {
      color: theme.colors.secondaryText,
      fontSize: '1.6rem',
    },
    formattedLabelPreview: {
      zoom: 0.64,
    },
  }),
  { name: 'PrintLabelDialog' },
)

const LINE_LENGTH_COEFFICIENT = 60
const DEFAULT_LABEL_WIDTH = 3000

interface PrintHtmlLabelHandle {
  clear: () => void
  print: () => void
}

interface PrintLabelDialogProps extends BasePuiDialogProps {
  customPrintSize?: boolean
  description?: string
  editable?: boolean
  formattedLabelData?: PrintFormattedData
  labelLines: string[]
  labelType: DymoLabelType
  loading?: boolean
  removeEmptyLabelSections?: boolean
  showCopyToClipboard?: boolean
  showFormattedLabels?: boolean
  showHtmlLabels?: boolean
  showPdfPrint?: boolean
  showPrintLabelContent?: boolean
  showSimpleTextLabels?: boolean
  title: string
}

const PrintLabelDialog = ({
  editable,
  open,
  showFormattedLabels = false,
  showHtmlLabels = false,
  showSimpleTextLabels = true,
  showPdfPrint = true,
  showPrintLabelContent = true,
  showCopyToClipboard = true,
  onClose,
  labelLines,
  formattedLabelData: formattedLabelDataProp,
  title,
  description,
  labelType,
  customPrintSize,
  loading = false,
  removeEmptyLabelSections = false,
}: PrintLabelDialogProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const LabelSectionType = {
    FORMATTED: t('Common:FORMATTED_LABEL'),
    SIMPLE_TEXT: t('SIMPLE_TEXT_LABEL'),
  }

  const [printSource, setPrintSource] = useState<string>()
  const [brotherPrinterPDFSource, setBrotherPrinterPDFSource] =
    useState<string>()
  const [label, setLabel] = useState(labelLines.join('\n'))
  const [lineLength, setLineLength] = useState(LINE_WIDTH)
  const [activeLabel, setActiveLabel] = useState<LabelTemplatesHtmlConstant>()
  const [initialized, setInitialized] = useState(false)
  const [activeLabelSectionType, setActiveLabelSectionType] = useState(
    showFormattedLabels || showHtmlLabels || !showSimpleTextLabels
      ? LabelSectionType.FORMATTED
      : LabelSectionType.SIMPLE_TEXT,
  )

  const isDymoConnectEnabled = useIsDymoConnectEnabled()
  const isDymoLabelLoading = useSelector(getDymoLabelIsLoading)
  const isHtmlLabelLoading = useSelector(getHtmlLabelIsLoading)
  const labels: LabelTemplatesHtmlConstant[] = useSelector(getLabelTemplates)
  const labelsMap = arrayToMap(labels, R.prop('id'), R.identity)
  const formattedLabels: LabelTemplatesHtmlConstant[] = useSelector(
    getLabelTemplatesFormatted,
  )
  const formattedLabelsMap = arrayToMap(
    formattedLabels,
    R.prop('id'),
    R.identity,
  )
  const formattedHtmlLabels: LabelTemplatesHtmlConstant[] = useSelector(
    getLabelTemplatesHtml,
  )
  const formattedHtmlLabelsMap = arrayToMap(
    formattedHtmlLabels,
    R.prop('id'),
    R.identity,
  )
  const currentBusiness = useSelector(getCurrentBusiness)

  const defaultLabels = currentBusiness?.labelTemplates

  const printHtmlLabelRef = useRef<PrintHtmlLabelHandle>(null)

  const fullLabelsMap = {
    ...labelsMap,
    ...formattedLabelsMap,
    ...formattedHtmlLabelsMap,
  }

  const labelProps = useSelector(getDymoLabelProps(activeLabel?.id))
  const labelXML = labelProps?.xml
  const formattedLabelXML = labelProps?.formattedXml
  const labelRestrictions =
    labelProps && R.omit(['xml', 'formattedXml'], labelProps)

  const htmlLabelProps = useSelector(
    getHtmlLabelProps(activeLabel?.id, labelType),
  )

  const deviceType = getDeviceType()

  const isSimpleTextLabel =
    isDymoConnectEnabled &&
    activeLabelSectionType === LabelSectionType.SIMPLE_TEXT

  const isHtmlLabel =
    !isDymoConnectEnabled ||
    (showHtmlLabels &&
      activeLabelSectionType === LabelSectionType.FORMATTED &&
      Array.isArray(activeLabel?.supportedTypes))

  const isFormattedLabel =
    isDymoConnectEnabled &&
    showFormattedLabels &&
    activeLabelSectionType === LabelSectionType.FORMATTED &&
    !isHtmlLabel

  useEffect(() => {
    setLabel(labelLines.join('\n'))
  }, [labelLines])

  useEffect(() => {
    if (activeLabel?.id) {
      if (isDymoConnectEnabled && !isHtmlLabel && !labelXML) {
        dispatch(fetchDymoLabelXML(activeLabel?.id))
      }

      if (isHtmlLabel && !htmlLabelProps) {
        dispatch(fetchHtmlLabel(activeLabel?.id, deviceType, labelType))
      }
    }

    if (labelXML) {
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(labelXML, 'text/xml')
      const bounds = xmlDoc?.querySelector('DieCutLabel > ObjectInfo > Bounds')

      const width = Number(bounds?.getAttribute('Width')) || DEFAULT_LABEL_WIDTH
      setLineLength(parseInt(`${width / LINE_LENGTH_COEFFICIENT}`, 10))
    }
  }, [activeLabel?.id, labelXML])

  useEffect(() => {
    // init section based by default label
    if (!activeLabel?.id && labelType && defaultLabels && !initialized) {
      // cageCard and cageCardAlert share the same defaults
      const adjustedLabelType =
        labelType === DymoLabelType.CAGE_CARD_ALERT
          ? DymoLabelType.CAGE_CARD
          : labelType
      const labelTemplate =
        defaultLabels[adjustedLabelType as keyof LabelTemplates]
      const defaultLabel = labelTemplate
        ? fullLabelsMap[labelTemplate]
        : undefined
      const defaultLabelId = defaultLabel?.id
      // in case we do not support DYMO we need to check if we are setting only html labels as default
      if (
        isDymoConnectEnabled ||
        (defaultLabelId && formattedHtmlLabelsMap[defaultLabelId])
      ) {
        setActiveLabel(defaultLabel)
      }

      const isActiveLabelFormatted = defaultLabelId
        ? Boolean(
            formattedLabelsMap[defaultLabelId] ||
              formattedHtmlLabelsMap[defaultLabelId],
          )
        : false

      setActiveLabelSectionType(
        isActiveLabelFormatted || !showSimpleTextLabels
          ? LabelSectionType.FORMATTED
          : LabelSectionType.SIMPLE_TEXT,
      )

      setInitialized(true)
    }
  }, [defaultLabels, activeLabel?.id, labelType])

  const formattedLabelData = {
    ...(formattedLabelDataProp || {}),
    bottomWarning: currentBusiness?.prescriptionLabelDisclaimer || '',
  }

  const formattedLblDataWithRest =
    isFormattedLabel && labelRestrictions && formattedLabelData
      ? applyRestrictions(formattedLabelData, labelRestrictions)
      : formattedLabelData

  const labelRawSource = isFormattedLabel
    ? renderToString(<FormattedLabel data={formattedLblDataWithRest} />)
    : stringDivider(label, lineLength)

  const labelXMLInstructionsFit =
    getScale(
      formattedLabelData.instructions || '',
      labelRestrictions?.instructions,
    ) > 1
      ? enableFitMode(formattedLabelXML || '', 'RX_INSTRUCTIONS')
      : formattedLabelXML

  const labelXMLAddressFit =
    getScale(
      formattedLabelData.businessAddress || '',
      labelRestrictions?.address,
    ) > 1
      ? enableFitMode(labelXMLInstructionsFit || '', 'ADDRESS')
      : labelXMLInstructionsFit

  const resolvedLabelXML = isFormattedLabel
    ? formattedLabelXML &&
      formattedLblDataWithRest &&
      Mustache.render(labelXMLAddressFit || '', formattedLblDataWithRest)
    : // eslint-disable-next-line no-template-curly-in-string
      labelXML && label && labelXML.replace('${text}', `<![CDATA[${label}]]>`)

  const copyToClipBoardText = isFormattedLabel
    ? getFormattedLabelClipboardContent(formattedLblDataWithRest)
    : label

  const handleLabelSectionTypeChange = (newSectionType: string) => {
    setActiveLabelSectionType(newSectionType)
    setActiveLabel(undefined)
  }

  const handleActiveLabelChange = (newLabelId: string) => {
    if (isHtmlLabel) {
      printHtmlLabelRef.current?.clear()
    }
    setActiveLabel(fullLabelsMap[newLabelId])
  }

  return (
    <PuiDialog
      aria-labelledby="print-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <Grid container direction="column" p={3}>
        {loading && (
          <CircularProgress
            className={classes.spinner}
            color="secondary"
            size={32}
          />
        )}
        <Grid item mb={1}>
          <Text variant="h2">{title}</Text>
        </Grid>
        {description && (
          <Grid item mb={1.5}>
            <Text>{description}</Text>
          </Grid>
        )}
        {isDymoConnectEnabled &&
          (showFormattedLabels || showHtmlLabels) &&
          showSimpleTextLabels && (
            <RadioGroup
              row
              aria-label="label-section"
              name="label-type-selection"
              value={activeLabelSectionType}
              onChange={(_, value) => handleLabelSectionTypeChange(value)}
            >
              {Object.values(LabelSectionType).map((name) => (
                <FormControlLabel
                  classes={{
                    root: classes.labelRoot,
                    label: classes.radioLabel,
                  }}
                  control={<Radio />}
                  key={name}
                  label={name}
                  value={name}
                />
              ))}
            </RadioGroup>
          )}
        <Grid item>
          {isHtmlLabel ? (
            <PrintHtmlLabel
              data={formattedLblDataWithRest}
              htmlLabel={htmlLabelProps}
              labelType={labelType}
              name={title}
              ref={printHtmlLabelRef}
              removeEmptySections={removeEmptyLabelSections}
            />
          ) : isFormattedLabel ? (
            <Grid item className={classes.formattedLabelPreview}>
              <FormattedLabel data={formattedLblDataWithRest} />
            </Grid>
          ) : (
            <PuiTextField
              multiline
              InputProps={{
                inputProps: { className: classes.textArea },
              }}
              disabled={!editable}
              margin="none"
              minRows={labelLines.length}
              value={label}
              variant="outlined"
              onChange={Utils.handleFormTextInput(setLabel)}
            />
          )}
        </Grid>
        <PrintLabelSection
          activeLabel={activeLabel}
          copyToClipBoardText={copyToClipBoardText}
          customPrintSize={customPrintSize}
          isFormattedLabel={isFormattedLabel}
          isHtmlLabel={isHtmlLabel}
          isLoading={isDymoLabelLoading || isHtmlLabelLoading}
          labelType={labelType}
          labelXML={resolvedLabelXML}
          labels={labels}
          showCopyToClipboard={showCopyToClipboard}
          showFormattedLabels={showFormattedLabels && !isSimpleTextLabel}
          showHtmlLabels={showHtmlLabels && !isSimpleTextLabel}
          showPdfPrint={showPdfPrint}
          showPrintLabelContent={showPrintLabelContent}
          onHtmlPrint={() => {
            if (isHtmlLabel) {
              printHtmlLabelRef.current?.print()
            } else {
              setPrintSource(replaceLineBreaksWithTag(labelRawSource))
            }
          }}
          onLabelChange={handleActiveLabelChange}
          onPrintPdf={() =>
            setBrotherPrinterPDFSource(stringDivider(label, lineLength))
          }
        />
      </Grid>
      {printSource && (
        <PrintHtml
          name={title}
          source={printSource}
          onOk={() => setPrintSource(undefined)}
        />
      )}
      <PrintBrotherPrinterPDF
        name={title}
        source={brotherPrinterPDFSource}
        onPrinted={() => setBrotherPrinterPDFSource(undefined)}
      />
    </PuiDialog>
  )
}

export default PrintLabelDialog
