import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, PuiDialog, PuiTheme } from '@pbt/pbt-ui-components'

import {
  UserSelectAutocomplete,
  UserSelectAutocompleteProps,
} from '../inputs/UserSelect'

const MEMBER_ASSIGNMENT_WIDTH = 300

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      verticalAlign: 'top',
      width: MEMBER_ASSIGNMENT_WIDTH,
    },
    listbox: {
      // 3 spacing units (search padding (1) + search margin (2)), 4px - misc.
      maxHeight: `calc(80vh - ${theme.spacing(3)} - 4px)`,
    },
  }),
  { name: 'TeamMemberAssignmentDialog' },
)

export interface TeamMemberAssignmentDialogProps
  extends BasePuiDialogProps,
    Omit<UserSelectAutocompleteProps, 'onClose' | 'open'> {
  title: string
}

const TeamMemberAssignmentDialog = ({
  title,
  open,
  onClose,
  ...rest
}: TeamMemberAssignmentDialogProps) => {
  const classes = useStyles()

  return (
    <PuiDialog
      aria-labelledby="team-member-assignment-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      title={title}
      onClose={onClose}
    >
      <UserSelectAutocomplete
        classes={{
          listbox: classes.listbox,
        }}
        {...rest}
      />
    </PuiDialog>
  )
}

export default TeamMemberAssignmentDialog
