import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import {
  Defaults,
  Nil,
  WellnessPlan,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'

import {
  WellnessPlanGlobalBenefitGroup,
  WellnessPlanPaymentMethod,
  WellnessPlanSavings,
  WellnessPlanSavingsData,
  WellnessPlanSettings,
  WellnessPlanValidationError,
} from '~/types'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import {
  ACTIVE_WELLNESS_PLAN_VERSION_CHANGED,
  CANCEL_WELLNESS_PLAN,
  CANCEL_WELLNESS_PLAN_FAILURE,
  CANCEL_WELLNESS_PLAN_OLD,
  CANCEL_WELLNESS_PLAN_OLD_FAILURE,
  CANCEL_WELLNESS_PLAN_OLD_SUCCESS,
  CANCEL_WELLNESS_PLAN_OLD_VALIDATION_FAILURE,
  CANCEL_WELLNESS_PLAN_SUCCESS,
  CLEAR_CANCEL_WELLNESS_PLAN_OLD_VALIDATION_ERROR,
  CLEAR_WELLNESS_PLAN_ERROR_STATUS,
  CLEAR_WELLNESS_PLAN_SIGN_UP_TOKEN,
  CLEAR_WELLNESS_PLAN_VERSION_TEMPLATE,
  CLEAR_WELLNESS_PLAN_VERSION_VALIDATION_ERROR,
  CREATE_WELLNESS_PLAN_PAYMENT_METHOD,
  CREATE_WELLNESS_PLAN_PAYMENT_METHOD_FAILURE,
  CREATE_WELLNESS_PLAN_PAYMENT_METHOD_SUCCESS,
  CREATE_WELLNESS_PLAN_VERSION,
  CREATE_WELLNESS_PLAN_VERSION_FAILURE,
  CREATE_WELLNESS_PLAN_VERSION_SUCCESS,
  CREATE_WELLNESS_PLAN_VERSION_VALIDATION_FAILURE,
  DELETE_WELLNESS_PLAN_VERSION,
  DELETE_WELLNESS_PLAN_VERSION_FAILURE,
  DELETE_WELLNESS_PLAN_VERSION_SUCCESS,
  EDIT_WELLNESS_PLAN_VERSION,
  EDIT_WELLNESS_PLAN_VERSION_FAILURE,
  EDIT_WELLNESS_PLAN_VERSION_SUCCESS,
  EDIT_WELLNESS_PLAN_VERSION_VALIDATION_FAILURE,
  EDIT_WELLNESS_PLANS_SETTINGS,
  EDIT_WELLNESS_PLANS_SETTINGS_FAILURE,
  EDIT_WELLNESS_PLANS_SETTINGS_SUCCESS,
  FETCH_ACTIVE_WELLNESS_PLAN_VERSION,
  FETCH_ACTIVE_WELLNESS_PLAN_VERSION_FAILURE,
  FETCH_ACTIVE_WELLNESS_PLAN_VERSION_SUCCESS,
  FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE,
  FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE_FAILURE,
  FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE_SUCCESS,
  FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST,
  FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST_SUCCESS,
  FETCH_WELLNESS_PLAN,
  FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE,
  FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE_FAILURE,
  FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE_SUCCESS,
  FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE,
  FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE_FAILURE,
  FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE_SUCCESS,
  FETCH_WELLNESS_PLAN_FAILURE,
  FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS,
  FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS_FAILURE,
  FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS_SUCCESS,
  FETCH_WELLNESS_PLAN_SAVINGS,
  FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE,
  FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE_FAILURE,
  FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE_SUCCESS,
  FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP,
  FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP_FAILURE,
  FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP_SUCCESS,
  FETCH_WELLNESS_PLAN_SAVINGS_FAILURE,
  FETCH_WELLNESS_PLAN_SAVINGS_SUCCESS,
  FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN,
  FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN_FAILURE,
  FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN_SUCCESS,
  FETCH_WELLNESS_PLAN_SUCCESS,
  FETCH_WELLNESS_PLAN_VERSION,
  FETCH_WELLNESS_PLAN_VERSION_FAILURE,
  FETCH_WELLNESS_PLAN_VERSION_SUCCESS,
  FETCH_WELLNESS_PLAN_VERSION_TEMPLATE,
  FETCH_WELLNESS_PLAN_VERSION_TEMPLATE_FAILURE,
  FETCH_WELLNESS_PLAN_VERSION_TEMPLATE_SUCCESS,
  FETCH_WELLNESS_PLAN_WELCOME_MESSAGE,
  FETCH_WELLNESS_PLAN_WELCOME_MESSAGE_FAILURE,
  FETCH_WELLNESS_PLAN_WELCOME_MESSAGE_SUCCESS,
  FETCH_WELLNESS_PLANS_LIST,
  FETCH_WELLNESS_PLANS_LIST_FAILURE,
  FETCH_WELLNESS_PLANS_LIST_SUCCESS,
  FETCH_WELLNESS_PLANS_SETTINGS,
  FETCH_WELLNESS_PLANS_SETTINGS_FAILURE,
  FETCH_WELLNESS_PLANS_SETTINGS_SUCCESS,
  FETCH_WP_CANCELLATION_INFO,
  FETCH_WP_CANCELLATION_INFO_FAILURE,
  FETCH_WP_CANCELLATION_INFO_SUCCESS,
  GET_WELLNESS_PLAN_PAYMENT_METHOD,
  GET_WELLNESS_PLAN_PAYMENT_METHOD_FAILURE,
  GET_WELLNESS_PLAN_PAYMENT_METHOD_SUCCESS,
  REPLACE_WELLNESS_PLAN_PAYMENT_METHOD,
  REPLACE_WELLNESS_PLAN_PAYMENT_METHOD_FAILURE,
  REPLACE_WELLNESS_PLAN_PAYMENT_METHOD_SUCCESS,
  SEND_WELCOME_EMAIL,
  SEND_WELCOME_EMAIL_FAILURE,
  SEND_WELCOME_EMAIL_SUCCESS,
  TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN,
  TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN_FAILURE,
  TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN_SUCCESS,
  UPDATE_WELLNESS_PLANS_VERSIONS,
} from '../actions/types/wellnessPlans'
import type { RootState } from '../index'

export type WellnessPlansState = {
  activeVersionId: string | null
  boopSignUpMessage: string | null
  boopSignUpMessageTemplate: string | null
  cancelMembershipValidationError: string | null
  error: string | null
  errorStatus: number | null
  globalBenefitGroups: WellnessPlanGlobalBenefitGroup[]
  isCancelling: boolean
  isDeleting: boolean
  isFetching: boolean
  isFetchingBenefits: boolean
  isFetchingList: boolean
  isLoading: boolean
  isSaving: boolean
  isSettingsLoading: boolean
  lastCreatedWellnessPlanVersionId: string | null
  list: string[]
  map: Record<string, WellnessPlanVersion>
  pendingPaymentMethod: WellnessPlanPaymentMethod | null
  publicKey: string | null
  savings: Record<string, WellnessPlanSavings>
  savingsData: WellnessPlanSavingsData | null
  settingsMap: Record<string, WellnessPlanSettings>
  signUpToken: string | null
  totalCount: number
  validationError: WellnessPlanValidationError | null
  versionTemplate: WellnessPlanVersion | null
  welcomeMessageTemplate: string | null
  wellnessPlan: WellnessPlan | null
}
export const INITIAL_STATE: WellnessPlansState = {
  list: [],
  map: {},
  isLoading: false,
  isDeleting: false,
  isFetching: false,
  isFetchingList: false,
  isFetchingBenefits: false,
  isSaving: false,
  isCancelling: false,
  isSettingsLoading: false,
  error: null,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  lastCreatedWellnessPlanVersionId: null,
  versionTemplate: null,
  validationError: null,
  globalBenefitGroups: [],
  settingsMap: {},
  wellnessPlan: null,
  signUpToken: null,
  publicKey: null,
  pendingPaymentMethod: null,
  savings: {},
  cancelMembershipValidationError: null,
  welcomeMessageTemplate: null,
  boopSignUpMessageTemplate: null,
  boopSignUpMessage: null,
  savingsData: null,
  activeVersionId: null,
  errorStatus: null,
}

const wellnessPlans = (
  state: WellnessPlansState = INITIAL_STATE,
  action: AnyAction,
): WellnessPlansState => {
  switch (action.type) {
    case FETCH_WELLNESS_PLANS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
      }
    case FETCH_WELLNESS_PLANS_LIST_SUCCESS:
      return {
        ...state,
        list: R.uniq(action.list),
        totalCount: action.totalCount,
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
      }
    case FETCH_WELLNESS_PLANS_LIST:
      return {
        ...state,
        isLoading: true,
        isFetching: true,
        isFetchingList: true,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        list: [],
      }
    case FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST:
      return { ...state, isFetching: true, isLoading: true }
    case FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isFetching: false,
        isLoading: false,
      }
    case FETCH_MORE_ITEMS_FOR_WELLNESS_PLANS_LIST_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        isFetching: false,
        totalCount: action.totalCount,
      }
    case UPDATE_WELLNESS_PLANS_VERSIONS:
      return {
        ...state,
        map: secondLevelMerge(state.map, action.wellnessPlansVersions),
      }
    case FETCH_WELLNESS_PLAN_VERSION:
      return { ...state, isLoading: true, isFetching: true, errorStatus: null }
    case FETCH_WELLNESS_PLAN_VERSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        globalBenefitGroups:
          action.wellnessPlanVersion.globalBenefitGroups ||
          state.globalBenefitGroups,
      }
    case FETCH_WELLNESS_PLAN_VERSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
        errorStatus: action.error?.status,
      }
    case FETCH_ACTIVE_WELLNESS_PLAN_VERSION:
      return { ...state, isLoading: true, isFetching: true }
    case FETCH_ACTIVE_WELLNESS_PLAN_VERSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        activeVersionId: action.wellnessPlanVersionId,
      }
    case FETCH_ACTIVE_WELLNESS_PLAN_VERSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN:
    case EDIT_WELLNESS_PLAN_VERSION:
      return {
        ...state,
        isLoading: true,
        isSaving: true,
        errorStatus: null,
        error: null,
      }
    case TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN_FAILURE:
    case EDIT_WELLNESS_PLAN_VERSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        error: getErrorMessage(action.error),
        errorStatus: action.error?.status,
      }
    case EDIT_WELLNESS_PLAN_VERSION_SUCCESS:
      return { ...state, isLoading: false, isSaving: false }
    case TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN_SUCCESS:
      if (action.versionId && action.plan) {
        return {
          ...state,
          isLoading: false,
          isSaving: false,
        }
      }
      return { ...state, isLoading: false, isSaving: false }
    case CLEAR_WELLNESS_PLAN_ERROR_STATUS: {
      return { ...state, errorStatus: null }
    }
    case EDIT_WELLNESS_PLAN_VERSION_VALIDATION_FAILURE:
    case CREATE_WELLNESS_PLAN_VERSION_VALIDATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        validationError: action.error,
      }
    case DELETE_WELLNESS_PLAN_VERSION_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_WELLNESS_PLAN_VERSION_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        map: R.omit([action.wellnessPlanVersionId], state.map),
        list: R.without([action.wellnessPlanVersionId], state.list),
        totalCount: Math.max(state.totalCount - 1, 0),
      }
    case DELETE_WELLNESS_PLAN_VERSION:
      return { ...state, isDeleting: true }
    case CREATE_WELLNESS_PLAN_VERSION:
      return { ...state, isSaving: true, errorStatus: null }
    case CREATE_WELLNESS_PLAN_VERSION_SUCCESS:
      return {
        ...state,
        lastCreatedWellnessPlanVersionId: action.wellnessPlanVersionId,
        isSaving: false,
      }
    case CREATE_WELLNESS_PLAN_VERSION_FAILURE:
      return {
        ...state,
        isSaving: false,
        lastCreatedWellnessPlanVersionId: null,
        error: getErrorMessage(action.error),
      }
    case FETCH_WELLNESS_PLAN_VERSION_TEMPLATE:
      return { ...state, isLoading: true }
    case FETCH_WELLNESS_PLAN_VERSION_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        versionTemplate: action.template,
        globalBenefitGroups:
          action.template.globalBenefitGroups || state.globalBenefitGroups,
      }
    case FETCH_WELLNESS_PLAN_VERSION_TEMPLATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case CLEAR_WELLNESS_PLAN_VERSION_TEMPLATE:
      return { ...state, versionTemplate: null }
    case CLEAR_WELLNESS_PLAN_VERSION_VALIDATION_ERROR:
      return { ...state, validationError: null }
    case EDIT_WELLNESS_PLANS_SETTINGS:
      return { ...state, isSettingsLoading: true }
    case EDIT_WELLNESS_PLANS_SETTINGS_SUCCESS:
      return {
        ...state,
        isSettingsLoading: false,
        settingsMap: {
          ...state.settingsMap,
          [action.businessId]: action.settings,
        },
      }
    case EDIT_WELLNESS_PLANS_SETTINGS_FAILURE:
      return {
        ...state,
        isSettingsLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_WELLNESS_PLANS_SETTINGS:
      return { ...state, isSettingsLoading: true }
    case FETCH_WELLNESS_PLANS_SETTINGS_SUCCESS:
      return {
        ...state,
        isSettingsLoading: false,
        settingsMap: {
          ...state.settingsMap,
          [action.businessId]: action.settings,
        },
      }
    case FETCH_WELLNESS_PLANS_SETTINGS_FAILURE:
      return {
        ...state,
        isSettingsLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_WELLNESS_PLAN:
      return { ...state, isLoading: true, isFetching: true }
    case FETCH_WELLNESS_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        wellnessPlan: action.wellnessPlan,
      }
    case FETCH_WELLNESS_PLAN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS:
      return {
        ...state,
        isLoading: true,
        isFetching: true,
        isFetchingBenefits: true,
      }
    case FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        isFetchingBenefits: false,
        globalBenefitGroups: action.globalBenefitGroups,
      }
    case FETCH_WELLNESS_PLAN_GLOBAL_BENEFIT_GROUPS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        isFetchingBenefits: false,
        error: getErrorMessage(action.error),
      }
    case ACTIVE_WELLNESS_PLAN_VERSION_CHANGED:
      return {
        ...state,
        map: R.map(
          (wellnessPlanVersion) => ({
            ...wellnessPlanVersion,
            active:
              wellnessPlanVersion.id === action.wellnessPlanVersionId
                ? wellnessPlanVersion.active
                : false,
          }),
          state.map,
        ),
      }
    case FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN:
      return { ...state, isLoading: true }
    case FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN_SUCCESS:
      return { ...state, isLoading: false, signUpToken: action.token }
    case CLEAR_WELLNESS_PLAN_SIGN_UP_TOKEN:
      return { ...state, signUpToken: null }
    case FETCH_WELLNESS_PLAN_SIGN_UP_TOKEN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case CANCEL_WELLNESS_PLAN:
    case FETCH_WP_CANCELLATION_INFO:
    case CANCEL_WELLNESS_PLAN_OLD:
      return { ...state, isLoading: true, isCancelling: true }
    case CANCEL_WELLNESS_PLAN_SUCCESS:
    case FETCH_WP_CANCELLATION_INFO_SUCCESS:
    case CANCEL_WELLNESS_PLAN_OLD_SUCCESS:
      return { ...state, isLoading: false, isCancelling: false }
    case CANCEL_WELLNESS_PLAN_FAILURE:
    case FETCH_WP_CANCELLATION_INFO_FAILURE:
    case CANCEL_WELLNESS_PLAN_OLD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isCancelling: false,
        error: getErrorMessage(action.error),
      }
    case CANCEL_WELLNESS_PLAN_OLD_VALIDATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        isCancelling: false,
        cancelMembershipValidationError: action.error?.response?.data?.error,
      }
    case CLEAR_CANCEL_WELLNESS_PLAN_OLD_VALIDATION_ERROR:
      return { ...state, cancelMembershipValidationError: null }
    case CREATE_WELLNESS_PLAN_PAYMENT_METHOD:
      return { ...state, isLoading: true }
    case CREATE_WELLNESS_PLAN_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pendingPaymentMethod: action.data,
        publicKey: action.data.publicKey,
      }
    case CREATE_WELLNESS_PLAN_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case GET_WELLNESS_PLAN_PAYMENT_METHOD:
      return { ...state, isLoading: true }
    case GET_WELLNESS_PLAN_PAYMENT_METHOD_SUCCESS:
      return { ...state, isLoading: false, pendingPaymentMethod: action.data }
    case GET_WELLNESS_PLAN_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case REPLACE_WELLNESS_PLAN_PAYMENT_METHOD:
      return { ...state, isLoading: true }
    case REPLACE_WELLNESS_PLAN_PAYMENT_METHOD_SUCCESS:
      return { ...state, isLoading: false, pendingPaymentMethod: null }
    case REPLACE_WELLNESS_PLAN_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_WELLNESS_PLAN_SAVINGS:
      return { ...state, isLoading: true }
    case FETCH_WELLNESS_PLAN_SAVINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        savings: { ...state.savings, [action.clientId]: action.savings },
      }
    case FETCH_WELLNESS_PLAN_SAVINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE:
    case FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE:
    case FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE:
    case FETCH_WELLNESS_PLAN_WELCOME_MESSAGE:
      return { ...state, isLoading: true }
    case FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE_SUCCESS:
    case FETCH_WELLNESS_PLAN_WELCOME_MESSAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        welcomeMessageTemplate: action.template,
      }
    case FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE_FAILURE:
    case FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE_FAILURE:
    case FETCH_CURRENT_WELLNESS_PLAN_WELCOME_MESSAGE_FAILURE:
    case FETCH_WELLNESS_PLAN_WELCOME_MESSAGE_FAILURE:
      return { ...state, isLoading: false, error: action.error }
    case FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        boopSignUpMessageTemplate: action.template,
      }
    case FETCH_WELLNESS_PLAN_BOOP_SIGN_UP_MESSAGE_SUCCESS:
      return { ...state, isLoading: false, boopSignUpMessage: action.message }
    case SEND_WELCOME_EMAIL:
      return { ...state, isLoading: true }
    case SEND_WELCOME_EMAIL_SUCCESS:
      return { ...state, isLoading: false }
    case SEND_WELCOME_EMAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP:
    case FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE:
      return { ...state, isLoading: true, isFetching: true }
    case FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP_SUCCESS:
    case FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        savingsData: action.savingsData,
      }
    case FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_SOAP_FAILURE:
    case FETCH_WELLNESS_PLAN_SAVINGS_DATA_FOR_INVOICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

export default wellnessPlans
export const getWellnessPlans = (state: RootState): WellnessPlansState =>
  state.wellnessPlans
export const getWellnessPlansVersionsList = (state: RootState) =>
  getWellnessPlans(state).list
export const getWellnessPlansVersionsMap = (state: RootState) =>
  getWellnessPlans(state).map
export const getWellnessPlanVersion = (id: string | Nil) =>
  createSelector(getWellnessPlansVersionsMap, (map) =>
    id ? map[id] : undefined,
  )
export const getMultipleWellnessPlansVersions = (ids: string[]) =>
  createSelector(getWellnessPlansVersionsMap, (map) => R.props(ids, map))
export const getWellnessPlansIsLoading = (state: RootState) =>
  getWellnessPlans(state).isLoading
export const getWellnessPlansIsFetching = (state: RootState) =>
  getWellnessPlans(state).isFetching
export const getWellnessPlansIsFetchingList = (state: RootState) =>
  getWellnessPlans(state).isFetchingList
export const getWellnessPlansIsFetchingBenefits = (state: RootState) =>
  getWellnessPlans(state).isFetchingBenefits
export const getWellnessPlansIsDeleting = (state: RootState) =>
  getWellnessPlans(state).isDeleting
export const getWellnessPlansIsSaving = (state: RootState) =>
  getWellnessPlans(state).isSaving
export const getWellnessPlansIsCancelling = (state: RootState) =>
  getWellnessPlans(state).isCancelling
export const getWellnessPlansError = (state: RootState) =>
  getWellnessPlans(state).error
export const getWellnessPlansErrorStatus = (state: RootState) =>
  getWellnessPlans(state).errorStatus
export const getWellnessPlansVersionsTotalCount = (state: RootState) =>
  getWellnessPlans(state).totalCount
export const getLastCreatedWellnessPlanVersionId = (state: RootState) =>
  getWellnessPlans(state).lastCreatedWellnessPlanVersionId
export const getWellnessPlanVersionTemplate = (state: RootState) =>
  getWellnessPlans(state).versionTemplate
export const getWellnessPlanValidationError = (state: RootState) =>
  getWellnessPlans(state).validationError
export const getWellnessPlanGlobalBenefitGroups = (state: RootState) =>
  getWellnessPlans(state).globalBenefitGroups
export const getWellnessPlansSettingsMap = (state: RootState) =>
  getWellnessPlans(state).settingsMap
export const getWellnessPlansSettings = (businessId: string) =>
  createSelector(getWellnessPlansSettingsMap, (map) => R.prop(businessId, map))
export const getWellnessPlansSettingsIsLoading = (state: RootState) =>
  getWellnessPlans(state).isSettingsLoading
export const getWellnessPlan = (state: RootState) =>
  getWellnessPlans(state).wellnessPlan
export const getWellnessPlanSignUpToken = (state: RootState) =>
  getWellnessPlans(state).signUpToken
export const getWellnessPlanPendingPaymentMethod = (state: RootState) =>
  getWellnessPlans(state).pendingPaymentMethod
export const getWellnessPlanPublicKey = (state: RootState) =>
  getWellnessPlans(state).publicKey
export const getWellnessPlanSavings = (state: RootState) =>
  getWellnessPlans(state).savings
export const getWellnessPlanSavingsForClient = (clientId: string) =>
  createSelector(getWellnessPlanSavings, (map) => R.prop(clientId, map))
export const getCancelMembershipValidationError = (state: RootState) =>
  getWellnessPlans(state).cancelMembershipValidationError
export const getWellnessPlanWelcomeTemplate = (state: RootState) =>
  getWellnessPlans(state).welcomeMessageTemplate
export const getWellnessPlanBoopSignUpTemplate = (state: RootState) =>
  getWellnessPlans(state).boopSignUpMessageTemplate
export const getWellnessPlanBoopSignUpMessage = (state: RootState) =>
  getWellnessPlans(state).boopSignUpMessage
export const getWellnessPlanSavingsData = (state: RootState) =>
  getWellnessPlans(state).savingsData
export const getWellnessPlanActiveVersionId = (state: RootState) =>
  getWellnessPlans(state).activeVersionId
export const getWellnessPlansSanitized = (state: RootState) => ({
  ...state.wellnessPlans,
  signUpToken: '*',
})
