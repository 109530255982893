import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  HtmlNotesPreview,
  LanguageUtils,
  LinkButton,
  PermissionArea,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import { LabTestLogFileGroup } from '~/api/graphql/generated/types'
import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import Link from '~/components/common/link/Link'
import LabTestAttachmentList from '~/components/dashboard/lab-tests-dashboard/lab-tests-table/LabTestAttachmentList'
import LabTestsStateSelect from '~/components/dashboard/lab-tests-dashboard/lab-tests-table/LabTestsStateSelect'
import LabOrderPreviewButtons from '~/components/dashboard/lab-tests-dashboard/LabOrderPreviewButtons'
import LabResultPreviewButtons from '~/components/dashboard/lab-tests-dashboard/LabResultPreviewButtons'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  CancellableLabOrderStates,
  DeletableLabOrderStates,
  isSubmittedOrCompletedOrder,
  orderHasResults,
  OrderType,
} from '~/constants/SOAPStates'
import { updateOrderStatus } from '~/store/actions/orders'
import { deleteLabTestAttachmentFile } from '~/store/actions/timeline'
import { cancelLabOrder } from '~/store/duck/labOrders'
import { useOpenInvoice } from '~/store/hooks/finance'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getFeatureToggle,
  getLabOrderStatuses,
  getLabTestsStates,
} from '~/store/reducers/constants'
import { LabTestTimelineItem } from '~/types'
import { addOriginalBusinessId, getPropFromFirstItemOfProp } from '~/utils'
import { isDifferedBusinessList } from '~/utils/businessUtils'
import { isSoapOutsidePharmacyOrder } from '~/utils/orderUtils'
import useDialog from '~/utils/useDialog'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import TimelineCard, { TimelineCardProps } from '../TimelineCard'
import TimelineCardActions from '../TimelineCardActions'
import TimelineCardContent from '../TimelineCardContent'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    name: {
      lineHeight: '16px',
    },
    notes: {
      marginTop: theme.spacing(1),
      lineHeight: '16px',
    },
    entryRow: {
      padding: theme.spacing(2),
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
    controlLabel: {
      fontSize: '1.6rem',
    },
    root: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    actionsContainer: {
      justifyContent: 'flex-start',
    },
  }),
  { name: 'LabTestCard' },
)

interface LabTestCardProps extends TimelineCardProps {
  clientId: string
  item: LabTestTimelineItem
  patientId: string
}

const LabTestCard = ({
  item,
  clientId,
  patientId,
  ...rest
}: LabTestCardProps) => {
  const { businessId, soapId, invoiceId, vendorId, entries, order } = item

  const {
    id,
    labOrderId,
    date,
    statusId,
    cancellable = true,
    vendorOrderIdentifier,
  } = order || {}

  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Timeline'])

  const LabTestStates = useSelector(getLabTestsStates)
  const LabOrderStatusesList = useSelector(getLabOrderStatuses)
  const soapPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const invoicePermissions = useSelector(getCRUDByArea(PermissionArea.INVOICE))
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isDeleteFinalizedLabTestsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DELETE_FINALIZED_LAB_TESTS),
  )
  const isAttachingFilesToLabOrdersEnabled = useSelector(
    getFeatureToggle(FeatureToggle.ATTACH_FILES_TO_LAB_ORDERS),
  )

  const [openPrintLabOrderLabelDialog] = useDialog(
    DialogNames.PRINT_LAB_ORDER_LABEL,
  )
  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const [openRemoveLabOrderDialog, closeRemoveLabOrderDialog] = useDialog(
    DialogNames.REMOVE_LAB_ORDER,
  )

  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)

  const hasOrder = Boolean(vendorOrderIdentifier)

  const firstEntry = entries[0] || {}
  const title = hasOrder
    ? t('Common:LAB_ORDER')
    : LanguageUtils.getTranslatedFieldName(firstEntry)

  const isContextItem = useIsCurrentContextItem(item)

  const hasDifferedBusinessEntries = isDifferedBusinessList(entries)

  const state = hasOrder
    ? Utils.getConstantName(statusId, LabOrderStatusesList)
    : Utils.getConstantName(firstEntry.stateId, LabTestStates)

  const stateDisplayName = hasOrder
    ? LanguageUtils.getConstantTranslatedName(statusId, LabOrderStatusesList)
    : LanguageUtils.getConstantTranslatedName(firstEntry.stateId, LabTestStates)

  const handleCancelFinalizedOrder = () => {
    openRemoveLabOrderDialog({
      vendorId,
      labOrderId: id,
      onClose: closeRemoveLabOrderDialog,
    })
  }

  const handleCancelOrder = () => {
    dispatch(cancelLabOrder(vendorId, id))
  }

  const handleOpenInvoice = () => {
    openInvoice({
      clientId,
      patientId,
      invoiceId,
      soapId,
      logId: getPropFromFirstItemOfProp({
        parentProp: 'entries',
        secondaryProp: 'id',
        data: item,
      }),
      logType: OrderType.LAB_TEST,
    })
  }

  const handleLabelPrint = () => {
    openPrintLabOrderLabelDialog({
      labOrderId,
      orderDate: date,
      vendorId,
      clientId,
      patientId,
    })
  }

  const displayAttachments = (
    groups: LabTestLogFileGroup[],
    labTestLogId: string,
  ) =>
    groups?.map(
      (group) =>
        isAttachingFilesToLabOrdersEnabled &&
        Boolean(group.files?.length) && (
          <LabTestAttachmentList
            clientId={clientId}
            files={group.files.map((file) => ({
              ...file,
              groupId: group.id,
            }))}
            key={group.id}
            patientId={patientId}
            onDelete={(groupId: string, fileId: string) => {
              dispatch(
                deleteLabTestAttachmentFile(labTestLogId, groupId, fileId),
              )
            }}
          />
        ),
    )

  const handleStateChange = (logEntryId: string, stateId: string) => {
    dispatch(updateOrderStatus(logEntryId, stateId, true))
  }

  const hasAllActions =
    hasOrder &&
    cancellable &&
    orderHasResults(state) &&
    isSubmittedOrCompletedOrder(state)

  return (
    <TimelineCard
      originBusinessId={
        hasOrder && hasDifferedBusinessEntries ? undefined : businessId
      }
      title={title}
      {...item}
      {...rest}
    >
      {hasOrder ? (
        entries.map((entry, index) => (
          <TimelineCardContent
            noTypography
            className={classes.entryRow}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <Grid container alignItems="center" wrap="nowrap">
              <Text strong className={classes.name}>
                {LanguageUtils.getTranslatedFieldName(entry)}
              </Text>
              {hasDifferedBusinessEntries && (
                <BusinessShareIcon businessIds={[entry.businessId]} />
              )}
            </Grid>
            {entry.notes && <HtmlNotesPreview notes={entry.notes} />}
            {displayAttachments(entry.fileGroups, entry?.id)}
          </TimelineCardContent>
        ))
      ) : (
        <TimelineCardContent noTypography>
          {firstEntry.notes && <HtmlNotesPreview notes={firstEntry.notes} />}
          {displayAttachments(firstEntry.fileGroups, firstEntry?.id)}
        </TimelineCardContent>
      )}
      <TimelineCardActions
        classes={{
          root: hasAllActions ? classes.root : undefined,
          actionsContainer: hasAllActions
            ? classes.actionsContainer
            : undefined,
        }}
        customStateControl={
          hasOrder || !firstEntry.id ? undefined : (
            <LabTestsStateSelect
              disabled={!isContextItem || !soapPermissions.update}
              value={firstEntry.stateId}
              onChange={Utils.handleFormSelectInput((stateId) =>
                handleStateChange(firstEntry.id, stateId),
              )}
            />
          )
        }
        state={stateDisplayName}
      >
        {isContextItem && (
          <LinkButton onClick={handleLabelPrint}>
            {t('Common:PRINT_LABEL')}
          </LinkButton>
        )}
        {isDeleteFinalizedLabTestsEnabled
          ? isContextItem &&
            hasOrder &&
            cancellable &&
            DeletableLabOrderStates.includes(state) && (
              <LinkButton onClick={handleCancelFinalizedOrder}>
                {t('Common:DELETE_ORDER')}
              </LinkButton>
            )
          : isContextItem &&
            hasOrder &&
            cancellable &&
            CancellableLabOrderStates.includes(state) && (
              <LinkButton onClick={handleCancelOrder}>
                {t('Common:CANCEL_ORDER')}
              </LinkButton>
            )}
        <LabResultPreviewButtons
          clientId={clientId}
          hidePreview={!isContextItem}
          order={order}
          patientId={patientId}
          soapId={soapId}
          vendorId={vendorId}
        />
        <LabOrderPreviewButtons
          hidePreview={!isContextItem}
          order={order}
          soapBusinessId={businessId}
          soapId={soapId}
          vendorId={vendorId}
        />
        {isContextItem && invoicePermissions.read && invoiceId && (
          <LinkButton onClick={handleOpenInvoice}>
            {t('Common:VIEW_INVOICE')}
          </LinkButton>
        )}
        {soapPermissions.read && isSoapOutsidePharmacyOrder(item) && (
          <Link
            to={addOriginalBusinessId(
              `/soap/${soapId}`,
              isPatientSharingEnabled ? businessId : null,
            )}
          >
            {t('Common:VIEW_SOAP')}
          </Link>
        )}
      </TimelineCardActions>
    </TimelineCard>
  )
}

export default LabTestCard
