import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  Nil,
  PuiDialog,
  PuiTheme,
  Utils,
} from '@pbt/pbt-ui-components'

import {
  clearSchedulingAssignment,
  fetchAppointment,
} from '~/store/actions/timetable'
import { getEventType } from '~/store/reducers/constants'
import { getRolesMap } from '~/store/reducers/roles'
import {
  getSchedulingAssignmentRedirectUrl,
  getTimetableError,
  getTimetableEvent,
  getTimetableIsLoading,
} from '~/store/reducers/timetable'
import { TimetableEvent } from '~/types'

import { checkEventAppointmentType } from '../../timetableUtils'
import AppointmentComponent, {
  AppointmentComponentHandle,
  AppointmentComponentProps,
} from './AppointmentComponent'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      overflowY: 'visible',
      maxWidth: 1024,
      width: 'calc(100% - 32px)',
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
    },
    spinnerContainer: {
      textAlign: 'center',
      height: 200,
    },
    rootContent: {
      overflowY: 'visible',
    },
  }),
  { name: 'AppointmentDialog' },
)

interface AppointmentDialogProps
  extends BasePuiDialogProps,
    Omit<AppointmentComponentProps, 'onOk' | 'appointment'> {
  appointment?: TimetableEvent | Nil
  appointmentId?: string | Nil
  isAppointmentType?: boolean
  onOk?: () => void
}

const AppointmentDialog = ({
  open,
  onClose,
  onOk,
  clientId,
  patientId,
  appointmentId,
  isAppointmentType,
  ...rest
}: AppointmentDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const appointment = useSelector(getTimetableEvent(appointmentId))
  const isLoading = useSelector(getTimetableIsLoading)
  const roles = useSelector(getRolesMap)
  const error = useSelector(getTimetableError)
  const schedulingAssignmentRedirectUrl = useSelector(
    getSchedulingAssignmentRedirectUrl,
  )
  const EventType = useSelector(getEventType)
  const AppointmentType =
    Utils.findConstantByName('Appointment', EventType) || {}
  const isAllowedAppointmentFetchByType =
    isAppointmentType || checkEventAppointmentType(appointment, AppointmentType)
  const appointmentRef = useRef<AppointmentComponentHandle>(null)

  useEffect(() => {
    if (!error && appointmentId && isAllowedAppointmentFetchByType) {
      dispatch(fetchAppointment(appointmentId))
    }
  }, [appointmentId, isAllowedAppointmentFetchByType])

  const handleSave = () => {
    appointmentRef.current?.save()
  }

  return (
    <PuiDialog
      confirmSaveOnClose
      disableEnforceFocus
      ConfirmCloseDialogProps={{
        onOk: handleSave,
      }}
      aria-labelledby="appointment-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.rootContent,
      }}
      disableClose={isLoading}
      hasUnsavedChanges={() =>
        Boolean(appointmentRef.current?.hasUnsavedChanges())
      }
      open={open}
      onClose={() => {
        if (onClose) {
          onClose()
        }
        dispatch(clearSchedulingAssignment())
      }}
    >
      {appointmentId && ((!appointment && isLoading) || R.isEmpty(roles)) ? (
        <Grid
          container
          alignItems="center"
          className={classes.spinnerContainer}
          justifyContent="center"
        >
          <CircularProgress size={32} />
        </Grid>
      ) : (
        <AppointmentComponent
          appointment={appointment}
          clientId={clientId}
          patientId={patientId}
          ref={appointmentRef}
          onOk={() => {
            if (onClose) {
              onClose()
            }
            if (onOk) {
              onOk()
            }
            dispatch(clearSchedulingAssignment())
            if (schedulingAssignmentRedirectUrl) {
              navigate(schedulingAssignmentRedirectUrl)
            }
          }}
          {...rest}
        />
      )}
    </PuiDialog>
  )
}

export default AppointmentDialog
