import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Fab, Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { parse, stringify } from 'query-string'
import { omit } from 'ramda'
import {
  AlertIconType,
  PermissionArea,
  PuiAlert,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import ListSearchFilterPanel from '~/components/common/lists/ListSearchFilterPanel'
import { ALERT_TYPES } from '~/constants/alertTypes'
import DialogNames from '~/constants/DialogNames'
import RoleName from '~/constants/roleNames'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getBusinessesTotalCount,
  getBusinessIsLoading,
} from '~/store/reducers/businesses'
import { getRoleIdByName } from '~/store/reducers/roles'
import { addSearch } from '~/utils'
import useDialog from '~/utils/useDialog'

import PracticeDetails from './PracticeDetails'
import PracticesTableComponent from './PracticesTableComponent'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    button: {
      height: 38,
      width: 160,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  }),
  { name: 'PracticesPage' },
)

const PracticesPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation(['Admin', 'Common'])
  const location = useLocation()
  const { practiceId } = useParams()
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )
  const permissions = useSelector(getCRUDByArea(PermissionArea.BUSINESS))
  const creatorRoleId = useSelector(
    getRoleIdByName(RoleName.PracticeAdministrator),
  )
  const totalCount = useSelector(getBusinessesTotalCount)
  const isLoading = useSelector(getBusinessIsLoading)

  const [openCreatePracticeDialog] = useDialog(DialogNames.CREATE_PRACTICE)
  const [openCreatePracticeGroupDialog] = useDialog(
    DialogNames.CREATE_PRACTICE_GROUP,
  )

  const onDetailsClose = () => {
    navigate(addSearch(location, '/admin/general/practices'))
  }

  if (isMobile && typeof practiceId !== 'undefined') {
    return <PracticeDetails itemId={practiceId} onClose={onDetailsClose} />
  }

  const queryParams = parse(location.search)

  const cleanUpAlert = () => {
    const updatedQueryParams = omit(['alert'], queryParams)
    navigate(`${location.pathname}?${stringify(updatedQueryParams)}`, {
      replace: true,
    })
  }

  const headerButtons = (
    <Grid
      container
      item
      justifyContent="space-between"
      px={3}
      py={2}
      wrap={isMobile ? 'wrap' : 'nowrap'}
    >
      <ListSearchFilterPanel isLoading={isLoading} searchCount={totalCount} />
      <Grid
        container
        item
        columnSpacing={2}
        justifyContent="flex-end"
        wrap="nowrap"
      >
        <Grid item xs={isMobile}>
          <Fab
            className={classes.button}
            color="inherit"
            variant="extended"
            onClick={() => openCreatePracticeGroupDialog({ creatorRoleId })}
          >
            {t('Admin:PRACTICE.ADD_GROUP')}
          </Fab>
        </Grid>
        <Grid item xs={isMobile}>
          <Fab
            className={classes.button}
            color="inherit"
            variant="extended"
            onClick={() => openCreatePracticeDialog({ creatorRoleId })}
          >
            {t('Common:ADD_PRACTICE')}
          </Fab>
        </Grid>
      </Grid>
    </Grid>
  )

  return (
    <Grid container item flex={1} wrap="nowrap">
      {queryParams.alert && (
        <PuiAlert
          iconType={AlertIconType.WARN}
          message={t('Admin:PRACTICE.PLEASE_REVIEW_YOUR_CURRENT_PRACTICES')}
          okButtonText={t('Common:CONTINUE_ACTION')}
          open={queryParams.alert === ALERT_TYPES.ADD_PRACTICE}
          onClose={cleanUpAlert}
          onOk={cleanUpAlert}
        />
      )}
      <PracticesTableComponent
        headerButtons={permissions.create && headerButtons}
        practiceId={practiceId}
        onDetailsClose={onDetailsClose}
      />
    </Grid>
  )
}

export default PracticesPage
