import React, { useEffect, useState } from 'react'

import InputWithArrows from './InputWithArrows'

const HOUR_STEP = 1
const MINUTE_STEP = 5

export enum TimeInputTypes {
  HOUR = 'hour',
  MINUTE = 'minute',
}

export interface TimeInputProps {
  enableMeridian?: boolean
  offsetArrows?: boolean
  onChange: (newValue: number, periodFromHour?: boolean) => void
  type: TimeInputTypes
  value: number
}

const time24to12 = (value: number) => value % 12 || 12

const TimeInput = ({
  offsetArrows,
  value,
  type,
  onChange,
  enableMeridian = true,
}: TimeInputProps) => {
  const isHourType = type === TimeInputTypes.HOUR

  const formatNumberTimeValue = (newValue: number) => {
    const dayTime =
      isHourType && enableMeridian ? time24to12(newValue) : newValue
    return dayTime < 10 ? `0${dayTime}` : dayTime
  }

  const [inputValue, setInputValue] = useState(formatNumberTimeValue(value))
  const [inputFocused, setInputFocused] = useState(false)

  useEffect(() => {
    setInputValue(formatNumberTimeValue(value))
  }, [value])

  const saveTimeValue = (
    newValue: number | string,
    periodFromHour?: boolean,
  ) => {
    const newValueToSave = isHourType
      ? Number(newValue) % 24
      : Number(newValue) % 60
    if (newValueToSave !== value) {
      onChange(newValueToSave, periodFromHour)
    }
  }

  const increment = () => {
    saveTimeValue(isHourType ? value + HOUR_STEP : value + MINUTE_STEP, true)
  }

  const decrement = () => {
    saveTimeValue(
      isHourType
        ? value >= HOUR_STEP
          ? value - HOUR_STEP
          : value + 24 - HOUR_STEP
        : value > MINUTE_STEP
        ? value - MINUTE_STEP
        : value + 60 - MINUTE_STEP,
      true,
    )
  }

  const onBlur = () => {
    setInputFocused(false)
    saveTimeValue(inputValue)
  }

  const onEnter = () => {
    saveTimeValue(inputValue)
  }

  const onFocus = () => {
    setInputValue(formatNumberTimeValue(value))
    setInputFocused(true)
  }

  return (
    <InputWithArrows
      decrement={decrement}
      increment={increment}
      maxLength={2}
      offsetArrows={offsetArrows}
      value={inputFocused ? inputValue : formatNumberTimeValue(value)}
      onBlur={onBlur}
      onEnter={onEnter}
      onFocus={onFocus}
      onTextFieldChange={setInputValue}
    />
  )
}

export default TimeInput
