import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { NumberUtils, PuiTheme, Text } from '@pbt/pbt-ui-components'
import { DefaultPrices } from '@pbt/pbt-ui-components/src/localization'

import { RHAPSODY_PAY_RATE } from '~/constants/financeConstants'
import i18n from '~/locales/i18n'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    text: {
      lineHeight: '2.2rem',
    },
    ratesWrapper: {
      boxShadow: theme.constants.blockShadow,
    },
    withBorders: {
      borderBottom: theme.constants.filterBorder,
      borderTop: theme.constants.filterBorder,
    },
    button: {
      width: 240,
      height: 40,
      margin: theme.spacing(2, 0),
    },
    annotations: {
      lineHeight: '1.2rem',
    },
  }),
  { name: 'RhapsodyPayRates' },
)

const RATE_ANNOTATIONS = i18n.t('Prices:CURRENCY.RATES_ARE_SUBJECT_TO_CHANGE', {
  chargebacksFee: NumberUtils.formatMoney(DefaultPrices.CHARGEBACKS_FEE),
  creditCardVoiceAuthorization: NumberUtils.formatMoney(
    DefaultPrices.CREDIT_CARD_VOICE_AUTHORIZATION,
  ),
  insufficientFunds: NumberUtils.formatMoney(DefaultPrices.INSUFFICIENT_FUNDS),
  perTransaction: NumberUtils.formatMoney(DefaultPrices.PER_TRANSACTION),
})

interface RhapsodyPayRatesProps {
  onProceed: () => void
}

const RhapsodyPayRates = ({ onProceed }: RhapsodyPayRatesProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Businesses'])

  return (
    <Grid container className={classes.root} direction="column" pb={1} px={3}>
      <Text align="left" className={classes.text} variant="body">
        {t(
          'Businesses:RHAPSODY_PAY.EVERY_PRACTICE_USING_RHAPSODY_GETS_COMPETITIVE_FLAT_RATE',
        )}
      </Text>
      <Grid
        container
        item
        className={classes.ratesWrapper}
        direction="column"
        mx={0}
        my={2}
        p={3}
      >
        <Grid container item justifyContent="space-between" p={1}>
          <Text align="left" className={classes.text} variant="body">
            {t('Businesses:RHAPSODY_PAY.CREDIT_CARD_TRANSACTIONS')}
          </Text>
          <Text align="left" className={classes.text} variant="body">
            {t('Businesses:RHAPSODY_PAY.PERCENT_FLAT', {
              percent: RHAPSODY_PAY_RATE,
            })}
          </Text>
        </Grid>
        <Grid
          container
          item
          className={classes.withBorders}
          justifyContent="space-between"
          p={1}
        >
          <Text align="left" className={classes.text} variant="body">
            {t('Businesses:RHAPSODY_PAY.CHECK_CARD_TRANSACTIONS')}
          </Text>
          <Text align="left" className={classes.text} variant="body">
            {t('Businesses:RHAPSODY_PAY.PERCENT_FLAT', {
              percent: RHAPSODY_PAY_RATE,
            })}
          </Text>
        </Grid>
        <Grid container item justifyContent="space-between" p={1}>
          <Text align="left" className={classes.text} variant="body">
            {t('Businesses:RHAPSODY_PAY.DEBIT_CARD_TRANSACTIONS')}
          </Text>
          <Text align="left" className={classes.text} variant="body">
            {t('Businesses:RHAPSODY_PAY.PERCENT_FLAT', {
              percent: RHAPSODY_PAY_RATE,
            })}
          </Text>
        </Grid>
      </Grid>
      <Grid container item direction="column">
        <Text align="left" className={classes.text} variant="body">
          {t('Businesses:RHAPSODY_PAY.WE_OFFER_TWO_ADDITIONAL_OPTIONS')}:
        </Text>
        <Text align="left" className={classes.text} variant="body">
          <Trans
            components={{
              boldText: <Text inline strong component="span" variant="body2" />,
            }}
            i18nKey="Businesses:RHAPSODY_PAY.RHAPSODY_PASS_HELPS_YOU_RECOUP_PRICE"
          />
        </Text>
        <Text align="left" className={classes.text} variant="body">
          <Trans
            components={{
              boldText: <Text inline strong component="span" variant="body2" />,
            }}
            i18nKey="Businesses:RHAPSODY_PAY.RHAPSODY_GO_ENABLES_CARDLESS_CHECKOUT"
          />
        </Text>
      </Grid>
      <Grid container item justifyContent="flex-end" mb={2} mt={3}>
        <Fab
          className={classes.button}
          color="inherit"
          variant="extended"
          onClick={onProceed}
        >
          {t('Common:CONTINUE_ACTION')}
        </Fab>
      </Grid>
      <Grid container item>
        <Text className={classes.annotations} variant="lowAccent4">
          {RATE_ANNOTATIONS}
        </Text>
      </Grid>
    </Grid>
  )
}

export default RhapsodyPayRates
