import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  BlobWithName,
  ButtonWithLoader,
  Nil,
  Patient as PatientType,
  PuiDialog,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import { getPatient, getPatientsIsLoading } from '~/store/reducers/patients'

import Patient, { PatientHandle } from './Patient'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    actions: {
      padding: theme.spacing(1, 3),
    },
    button: {
      minWidth: 100,
    },
    buttonWithLoader: {
      marginLeft: theme.spacing(2),
    },
  }),
  { name: 'PatientDialog' },
)

interface PatientDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  createPatient: (
    clientId: string,
    patient: Omit<PatientType, 'id'>,
    avatarBlob?: BlobWithName | Nil,
  ) => void
  patient?: Partial<PatientType>
  patientId?: string | Nil
  saveAndAddAnother?: boolean
}

const PatientDialog = ({
  open,
  onClose,
  createPatient,
  clientId,
  patient: inputPatient,
  patientId = '',
  saveAndAddAnother = true,
}: PatientDialogProps) => {
  const classes = useStyles()
  const patientRef = useRef<PatientHandle>(null)
  const { t } = useTranslation('Common')

  const patient = useSelector(getPatient(patientId)) || ({} as PatientType)
  const isLoading = useSelector(getPatientsIsLoading)

  const isEdit = !R.isEmpty(patient)

  const onSave = () => {
    patientRef.current?.addPatient()
  }

  return (
    <PuiDialog
      confirmSaveOnClose
      disableEnforceFocus
      ConfirmCloseDialogProps={{
        onOk: () => patientRef.current?.addPatient(),
      }}
      actions={
        <>
          <Grid item>
            <ButtonWithLoader
              className={classes.button}
              disabled={isLoading}
              loading={isLoading}
              onClick={onSave}
            >
              {t('Common:SAVE_ACTION')}
            </ButtonWithLoader>
          </Grid>
          {!isEdit && saveAndAddAnother && (
            <Grid item>
              <ButtonWithLoader
                className={classes.buttonWithLoader}
                disabled={isLoading}
                loading={isLoading}
                onClick={() => patientRef.current?.addPatient(true)}
              >
                {t('Common:SAVE_AND_ADD_ANOTHER')}
              </ButtonWithLoader>
            </Grid>
          )}
        </>
      }
      aria-labelledby="patient-dialog"
      classes={{
        paper: classes.paper,
        actions: classes.actions,
      }}
      hasUnsavedChanges={() =>
        Boolean(patientRef.current?.getHasUnsavedChanges())
      }
      open={open}
      scroll="paper"
      title={
        isEdit
          ? t('Clients:PATIENT_SECTION.BUTTON.ADD_INFORMATION_ABOUT_PATIENT', {
              patientName: patient.name,
            })
          : t('Common:NEW_PATIENT')
      }
      onClose={onClose}
    >
      {patientId ? (
        <Patient
          clientId={clientId}
          createPatient={createPatient}
          patientId={patientId}
          ref={patientRef}
          onOk={onClose}
        />
      ) : (
        <Patient
          clientId={clientId}
          createPatient={createPatient}
          patient={inputPatient!}
          ref={patientRef}
          onOk={onClose}
        />
      )}
    </PuiDialog>
  )
}

export default PatientDialog
