import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PuiTheme } from '@pbt/pbt-ui-components'

import { useGetImagingOrdersData } from '~/components/dashboard/imaging-procedures/useGetImagingOrdersData'

import FinalizeOrdersSection from '../FinalizeOrdersSection'
import FinalizeImagingOrdersRow from './FinalizeImagingOrdersRow'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      borderTop: theme.constants.tabBorder,
    },
    rows: {
      '&:nth-child(even)': {
        background: theme.colors.divider,
      },
    },
  }),
  { name: 'FinalizeImagingOrdersSection' },
)

const FinalizeImagingOrdersSection = () => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Soap'])

  const {
    imagingOrders,
    hasOrders,
    outstandingImagingOrders,
    hasOutstandingOrders,
    handleFinalizeOrders,
  } = useGetImagingOrdersData()

  if (!hasOrders && !hasOutstandingOrders) {
    return null
  }

  return (
    <Grid container className={classes.root}>
      <FinalizeOrdersSection
        buttonLabel={t('Common:FINALIZE_ACTION')}
        description={t('Soap:FINALIZE_IMAGING_ORDERS_DESCRIPTION')}
        isFinalized={!hasOutstandingOrders}
        title={t('Soap:FINALIZE_IMAGING_ORDERS_TITLE')}
        onClick={handleFinalizeOrders}
      >
        {imagingOrders.map((imagingOrder) => (
          <FinalizeImagingOrdersRow
            imagingOrder={imagingOrder}
            key={imagingOrder.id}
          />
        ))}
        {outstandingImagingOrders.map((outstandingImagingOrder) => (
          <FinalizeImagingOrdersRow
            key={outstandingImagingOrder.id}
            outstandingImagingOrder={outstandingImagingOrder}
          />
        ))}
      </FinalizeOrdersSection>
    </Grid>
  )
}

export default FinalizeImagingOrdersSection
