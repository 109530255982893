import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  PuiDialog,
  PuiTheme,
  User,
} from '@pbt/pbt-ui-components'

import SwitchUser from './SwitchUser'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    dialogPaper: {
      width: 358,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
  }),
  { name: 'SwitchUserDialog' },
)

export interface SwitchUserDialogProps extends BasePuiDialogProps {
  showLogOutLink?: boolean
  user: User | Partial<User>
}

const SwitchUserDialog = ({
  onClose,
  user,
  showLogOutLink,
  open,
  ...rest
}: SwitchUserDialogProps) => {
  const classes = useStyles()

  return (
    <PuiDialog
      disableEnforceFocus
      aria-labelledby="switch-user-dialog"
      classes={{
        paper: classes.dialogPaper,
      }}
      open={open}
      onClose={onClose}
      {...rest}
    >
      <SwitchUser showLogOutLink={showLogOutLink} user={user} onOk={onClose} />
    </PuiDialog>
  )
}

export default SwitchUserDialog
