import React from 'react'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Constant,
  Field,
  LanguageUtils,
  PuiTheme,
  PuiTooltip,
  Text,
} from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    radioLabel: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
      marginRight: theme.spacing(3),
    },
  }),
  { name: 'EnumRadioGroup' },
)

export interface EnumRadioGroupsProps {
  Constant: Constant[]
  disabled?: boolean
  field: Field
  inline?: boolean
  label?: string
  tooltipText?: {
    [x: string]: string
  }
}
const EnumRadioGroup = ({
  field,
  inline,
  label,
  Constant: ConstantProp,
  tooltipText,
  disabled,
}: EnumRadioGroupsProps) => {
  const classes = useStyles()

  return (
    <Grid container direction="column">
      {label && (
        <Text strong variant="subheading3">
          {label}
        </Text>
      )}
      <RadioGroup
        aria-label="enum-radio-group"
        name="enum-radio-group"
        row={inline}
        value={field.value}
        onChange={field.set}
      >
        {ConstantProp.map((item) => (
          <PuiTooltip
            key={item.id}
            tooltipText={tooltipText?.[item.name] || ''}
          >
            <FormControlLabel
              classes={{ label: classes.radioLabel }}
              control={<Radio />}
              disabled={disabled}
              key={item.id}
              label={LanguageUtils.getTranslatedFieldName(item)}
              value={item.id}
            />
          </PuiTooltip>
        ))}
      </RadioGroup>
    </Grid>
  )
}

export default EnumRadioGroup
