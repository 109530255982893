import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { LanguageUtils, PuiTheme, Text } from '@pbt/pbt-ui-components'

import { FindingState, StaticFinding } from '~/types'

import NotesTemplateInput from '../../template-inputs/NotesTemplateInput'
// @ts-ignore
import CheckboxNode from './CheckboxNode'
// @ts-ignore
import TreeViewNode from './TreeViewNode'

const useStyles = makeStyles((theme: PuiTheme) => ({
  notesInput: {
    padding: theme.spacing(0.5, 0.5, 1, 0.5),
  },
}))

const hasCheckedChild = (
  state: Partial<FindingState>,
  findingChildren: StaticFinding[],
): boolean | undefined =>
  state.findings &&
  findingChildren &&
  findingChildren.some(({ id, children }) =>
    Boolean(
      state.findings?.[id] || (children && hasCheckedChild(state, children)),
    ),
  )

type NodeRef = React.RefObject<HTMLDivElement>

interface RenderChildrenProps
  extends Pick<
    SubCategoriesViewProps,
    | 'items'
    | 'expansionState'
    | 'onCheckChange'
    | 'onExpandChange'
    | 'onSelectChange'
    | 'selectedId'
    | 'setRef'
  > {
  findingState?: Partial<FindingState>
  hasTranslation: boolean
  level: number
  parents: string[]
}

const renderChildren = ({
  parents = [],
  items: children,
  expansionState = {},
  findingState = {},
  selectedId,
  onSelectChange,
  onExpandChange,
  onCheckChange,
  level,
  setRef,
  hasTranslation,
}: RenderChildrenProps) =>
  children.map((node) =>
    node.children && node.children.length > 0 ? (
      <TreeViewNode
        activated={hasCheckedChild(findingState, node.children)}
        content={renderChildren({
          parents: [...parents, node.id],
          items: node.children,
          expansionState: expansionState[node.id],
          findingState,
          selectedId,
          onSelectChange,
          onExpandChange,
          onCheckChange,
          level: level + 1,
          setRef,
          hasTranslation,
        })}
        expanded={Boolean(expansionState[node.id])}
        key={node.id}
        title={
          hasTranslation
            ? LanguageUtils.getTranslatedFieldName(node)
            : node.name
        }
        onChange={(nodeRef: NodeRef) =>
          onExpandChange([...parents, node.id], nodeRef)
        }
      />
    ) : (
      <CheckboxNode
        hasItems
        checked={Boolean(findingState.findings?.[node.id])}
        key={node.id}
        level={level as 0 | 1 | 2}
        selected={selectedId === node.id}
        setRef={(ref: NodeRef) => setRef(node.id, ref)}
        title={
          hasTranslation
            ? LanguageUtils.getTranslatedFieldName(node)
            : node.name
        }
        onCheckChange={(nodeRef: NodeRef) =>
          onCheckChange(
            parents[0],
            node,
            nodeRef,
            !findingState.findings?.[node.id],
          )
        }
        onSelectChange={(nodeRef: NodeRef) =>
          onSelectChange(parents[0], node, nodeRef)
        }
      />
    ),
  )

interface SubCategoriesViewProps {
  bodySystemId: string
  bodySystemName: string
  bodySystemNotes: string
  expansionState: any
  findingsState: Record<string, FindingState>
  items: StaticFinding[]
  onBodySystemNotesChange: (value: string) => void
  onBodySystemNotesSave: (value: string) => void
  onCheckChange: (
    bodySystemId: string,
    finding: StaticFinding,
    nodeRef: NodeRef,
    newState: boolean,
  ) => void
  onExpandChange: (pathList: string[], nodeRef: NodeRef) => void
  onSelectChange: (
    bodySystemId: string,
    finding: StaticFinding,
    nodeRef: NodeRef,
  ) => void
  selectedId?: string
  setRef: (id: string, ref: NodeRef) => void
}

const SubCategoriesView = ({
  items,
  bodySystemId,
  bodySystemName,
  findingsState,
  expansionState,
  selectedId,
  onSelectChange,
  onExpandChange,
  onCheckChange,
  bodySystemNotes,
  onBodySystemNotesChange,
  onBodySystemNotesSave,
  setRef,
}: SubCategoriesViewProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Soap'])

  return (
    <>
      <NotesTemplateInput
        hidePanel
        isSoap
        resetStateOnValueChange
        className={classes.notesInput}
        key={bodySystemId}
        maxHeight={332}
        title={t('Soap:SUB_CATEGORIES_VIEW.NOTES_TEMPLATE_INPUT_TITLE', {
          bodySystemName,
        })}
        value={bodySystemNotes || ''}
        onChange={onBodySystemNotesChange}
        onSave={onBodySystemNotesSave}
      />
      <Text strong ml={0.5} variant="subheading3">
        {t('Common:FINDINGS_NOUN')}
      </Text>
      {renderChildren({
        parents: [bodySystemId],
        items,
        findingState: findingsState[bodySystemId],
        expansionState: expansionState[bodySystemId],
        selectedId,
        onSelectChange,
        onExpandChange,
        onCheckChange,
        level: 0,
        setRef,
        hasTranslation: true,
      })}
    </>
  )
}

export default SubCategoriesView
