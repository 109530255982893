import React, { useRef } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme } from '@pbt/pbt-ui-components'

import ClientDetailsFooter from './ClientDetailsFooter'
import CreateClient, { CreateClientHandle } from './CreateClient'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    createClient: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'CreateClientPage' },
)

const CreateClientPage = () => {
  const classes = useStyles()

  const createClientRef = useRef<CreateClientHandle>(null)

  const continueToDetails = () => {
    if (createClientRef.current?.validate()) {
      createClientRef.current?.proceed()
    }
  }

  const addAppointment = () => {
    if (createClientRef.current?.validate()) {
      createClientRef.current?.proceed(true)
    }
  }

  return (
    <Grid container item direction="column" p={2}>
      <CreateClient className={classes.createClient} ref={createClientRef} />
      <ClientDetailsFooter
        addAppointment={addAppointment}
        continueToDetails={continueToDetails}
      />
    </Grid>
  )
}

export default CreateClientPage
