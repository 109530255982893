import React from 'react'
import { useDispatch } from 'react-redux'
import { Table, TableBody, TableHead, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { PuiTheme } from '@pbt/pbt-ui-components'

import {
  GroupedRefundItems,
  RefundInvoiceLineItem,
} from '~/api/graphql/generated/types'
import {
  FINANCE_TABLE_CELL_HEIGHT,
  FINANCE_TABLE_PADDING_X_SPACING_VALUE,
} from '~/constants/financeTable'
import {
  expandGroups,
  getGroupIdIsExpanded,
} from '~/store/duck/clientFinanceData'
import { InvoiceLineItem } from '~/types'

import InvoiceTableSubHeader from './InvoiceTableSubHeader'
import { RefundInvoiceTableRow } from './RefundInvoiceTableRow'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    footerCell: {
      padding: `${theme.spacing(1)} ${theme.spacing(1)} 0`,
    },
    selectedRow: {
      outline: `1px solid ${theme.colors.tabSelected}`,
      backgroundColor: `${theme.colors.tableLeftColumnBackground} !important`,
    },
    table: {
      border: 'none',
      backgroundColor: theme.colors.contentBackground,
    },
    tableTitle: {
      height: theme.spacing(4),
      padding: `0 ${theme.spacing(0, 1)}`,
      borderBottom: 'none',
      '&:first-of-type': {
        paddingLeft: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE + 1.5),
      },
      '&:last-of-type': {
        textAlign: 'right',
        paddingRight: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
    },
    tableRow: {
      backgroundColor: theme.colors.tableBackground,
      '&:hover': {
        backgroundColor: theme.colors.panelRow,
        outline: theme.constants.hoverBorder,
        boxShadow:
          '0 2px 4px 0 rgba(0,0,0,0.1), 3px 3px 20px 0 rgba(168,163,163,0.2)',
      },
      '&:first-of-type': {
        borderTop: theme.constants.tabBorder,
      },
      '&:nth-of-type(even)': {
        backgroundColor: 'inherit',
      },
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
      '&:last-of-type': {
        borderBottom: theme.constants.tabBorder,
      },
    },
    summaryTableCell: {
      height: FINANCE_TABLE_CELL_HEIGHT,
      '&:last-of-type': {
        paddingRight: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
    },
  }),
  { name: 'RefundInvoiceTable' },
)

export interface RefundInvoiceTableProps {
  items: GroupedRefundItems[]
  sectionId: string
}

export const RefundInvoiceTable = ({
  items = [],
  sectionId,
}: RefundInvoiceTableProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const isExpandedSelector = (item: InvoiceLineItem) => {
    const { patientId, soapId: soapIdProp, id: group } = item
    return getGroupIdIsExpanded({ patientId, soapId: soapIdProp, group })
  }

  const toggle = (item: InvoiceLineItem) =>
    dispatch(
      expandGroups({
        groupId: {
          patientId: item.patientId,
          soapId: item.soapId,
          group: item.id,
        },
      }),
    )

  return (
    <Table>
      <TableHead>
        <InvoiceTableSubHeader currentItemId={sectionId} />
      </TableHead>
      <TableBody>
        {items.map((item) => (
          <TableRow
            className={classes.tableRow}
            key={R.pathOr(
              (item as RefundInvoiceLineItem).id,
              ['groupSnapshot', 'id'],
              item,
            )}
          >
            <RefundInvoiceTableRow
              isExpandedSelector={isExpandedSelector}
              item={item}
              tableCellClassName={classes.summaryTableCell}
              toggleExpand={toggle}
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
