/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ControlButtonGroup,
  ControlButtonGroupName,
  DefaultReminderExpirationOffset,
  PermissionArea,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import { getCRUDByArea } from '~/store/reducers/auth'
import { ReminderTemplate } from '~/types'

import ReminderSetUpDueDateOffsetCell from './ReminderSetUpDueDateOffsetCell'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    label: {
      color: theme.colors.tabLabel,
      fontSize: '1.4rem',
      weight: 500,
    },
    table: {
      border: theme.constants.tableBorder,
    },
    header: {
      padding: theme.spacing(1, 2),
    },
    row: {
      borderTop: theme.constants.tableBorder,
      padding: theme.spacing(0, 2),
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    buttons: {},
  }),
  { name: 'ReminderTemplatesTable' },
)

interface ReminderTemplatesTableProps {
  onChangeActive: (index: number, isActive: boolean) => void
  onDelete: (index: number) => void
  onEdit: (template: ReminderTemplate, index: number) => void
  onPreview: (template: ReminderTemplate) => void
  templates: ReminderTemplate[]
}

interface ReminderTemplateRowProps
  extends Omit<ReminderTemplatesTableProps, 'templates' | 'onChangeActive'> {
  active: boolean
  dueDateOffset: DefaultReminderExpirationOffset
  onChange: () => void
  onDelete: () => void
  onEdit: () => void
  onPreview: () => void
}

const ReminderTemplateRow = ({
  active,
  dueDateOffset,
  onChange,
  onEdit,
  onPreview,
  onDelete,
}: ReminderTemplateRowProps) => {
  const classes = useStyles()
  const permissions = useSelector(
    getCRUDByArea(PermissionArea.REMINDER_PROTOCOLS),
  )

  return (
    <Grid container alignItems="center" className={classes.row}>
      <Grid item xs={2}>
        <PuiSwitch checked={active} onChange={onChange} />
      </Grid>
      <Grid item xs={7}>
        {dueDateOffset && (
          <ReminderSetUpDueDateOffsetCell dueDateOffset={dueDateOffset} />
        )}
      </Grid>
      <Grid item xs={3}>
        <Grid
          container
          alignItems="center"
          className={classes.buttons}
          justifyContent="flex-end"
        >
          <ControlButtonGroup
            buttons={[
              permissions?.delete && {
                name: ControlButtonGroupName.DELETE,
                onClick: onDelete,
              },
              permissions?.read && {
                name: ControlButtonGroupName.PREVIEW,
                onClick: onPreview,
              },
              permissions?.update && {
                name: ControlButtonGroupName.EDIT,
                onClick: onEdit,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

const ReminderTemplatesTable = ({
  templates,
  onChangeActive,
  onEdit,
  onPreview,
  onDelete,
}: ReminderTemplatesTableProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  if (!templates?.length) {
    return null
  }

  return (
    <Grid container className={classes.table} direction="column">
      <Grid container className={classes.header}>
        <Grid item className={classes.label} xs={2}>
          {t('Common:ON')}
        </Grid>
        <Grid item className={classes.label} xs={7}>
          {t('Common:WHEN')}
        </Grid>
        <Grid item xs={3} />
      </Grid>
      {templates.map((template, index) => (
        <ReminderTemplateRow
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          {...template}
          onChange={() => onChangeActive(index, !template.active)}
          onDelete={() => onDelete(index)}
          onEdit={() => onEdit(template, index)}
          onPreview={() => onPreview(template)}
        />
      ))}
    </Grid>
  )
}

export default ReminderTemplatesTable
