import { gql } from '@apollo/client'

import { PATIENT_FIELDS } from './clientAndPatient'
import {
  INVOICE_LINE_ITEM_FIELDS,
  INVOICE_LINE_ITEM_TOOLTIP_FIELDS,
} from './finance'
import { INVOICE_FIELDS } from './invoice'
import { PAYMENT_FIELDS } from './payments'

export const REFUND_CALCULATION_FIELDS = gql`
  fragment RefundLineItemCalculationFields on RefundInvoiceLineItemCalculation {
    originalLineItem {
      id
      name
    }
    refundAmount
    dispensingFee
    discount
    additionalDiscount
    taxAmount
    serviceFeeAmount
  }
`

export const REFUND_INVOICE_LINE_ITEM_FIELDS = gql`
  ${INVOICE_LINE_ITEM_FIELDS}
  ${INVOICE_LINE_ITEM_TOOLTIP_FIELDS}
  fragment RefundInvoiceLineItemFields on RefundInvoiceLineItem {
    id
    name
    refundedInvoice {
      id
      creationDate
    }
    patient {
      id
      name
    }
    price
    dispensingFee
    discount
    additionalDiscount
    taxAmount
    originalLineItem {
      ...InvoiceLineItemFields
      ...InvoiceLineItemToolTipFields
    }
    refundQty
    restockQty
    refundReason {
      id
    }
    restockedAsPackage
    subTotal
  }
`

export const REFUND_INVOICE_FIELDS = gql`
  ${INVOICE_FIELDS}
  ${PATIENT_FIELDS}
  ${REFUND_INVOICE_LINE_ITEM_FIELDS}
  ${PAYMENT_FIELDS}
  fragment RefundInvoiceFields on RefundInvoice {
    id
    originalInvoiceId
    invoiceNo
    internalNote
    additionalDiscount
    amount
    amountNoFee
    subtotal
    totalTax
    totalDiscount
    serviceFeeAmount
    paidAmount
    paidAmountNoFee
    state {
      id
      name
    }
    currency {
      id
    }
    client {
      id
      firstName
      lastName
      homePhone
      mobilePhone
      email
      patients {
        ...PatientFields
      }
    }
    sections {
      id
      soap {
        id
      }
      patient {
        id
      }
      subTotal
      groupedItems {
        ... on GroupedRefundItem {
          groupSnapshot {
            id
            name
          }
          extendedPrice
          chargedPrice
          itemDiscount
          taxAmount
          itemSubTotal
          discount
          subTotal
          items {
            ...RefundInvoiceLineItemFields
          }
        }
        ... on RefundInvoiceLineItem {
          ...RefundInvoiceLineItemFields
        }
      }
    }
    originalInvoice {
      ...InvoiceFields
    }
    payments {
      ...PaymentFields
    }
  }
`
