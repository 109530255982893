import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Table, TableBody } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  LanguageUtils,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import { useGetAppointmentStaffRoles } from '~/store/hooks/timetable'
import { getAppointmentId } from '~/store/reducers/soap'
import { getTimetableEvent } from '~/store/reducers/timetable'

import AssignedToSoapDetailsLockedItem from './AssignedToSoapDetailsLockedItem'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    titleContainer: {
      borderRight: theme.constants.tabBorder,
    },
    table: {
      border: theme.constants.tabBorder,
      borderRadius: 2,
    },
  }),
  { name: 'AssignedToSoapDetails' },
)

export interface AssignedToSoapDetailsProps {
  classes: ClassesType<typeof useStyles>
}

const AssignedToSoapDetails = ({
  classes: classesProp,
}: AssignedToSoapDetailsProps) => {
  const classes = useStyles({ classes: classesProp })
  const appointmentId = useSelector(getAppointmentId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const { t } = useTranslation('Common')

  const appointmentTypeId = appointment?.type?.id

  const staffRoles = useGetAppointmentStaffRoles(appointmentTypeId)

  return (
    <Grid container item className={classes.root} wrap="nowrap">
      <Grid item className={classes.titleContainer} px={1.5} py={2} xs={2}>
        <Text variant="h4">{t('Common:ASSIGNED_TO_SOAP')}</Text>
      </Grid>
      <Grid container item xs pb={3} pl={1} pr={2} pt={1}>
        <Table className={classes.table}>
          <TableBody>
            {staffRoles.map((role) => (
              <AssignedToSoapDetailsLockedItem
                displayName={LanguageUtils.getTranslatedFieldName(role)}
                key={role.id}
                name={role.name}
              />
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

export default AssignedToSoapDetails
