import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  Field,
  PuiTextField,
  PuiTheme,
  UnitUtils,
  Utils,
} from '@pbt/pbt-ui-components'
import {
  TemperatureTypes,
  UnitTypes,
} from '@pbt/pbt-ui-components/src/localization'

import UnitSelect from '~/components/common/inputs/UnitSelect'
import { getUnitsState } from '~/store/duck/settings'
import { getTemperatureUnits } from '~/store/reducers/constants'
import { useGetBusinessEnabledUnits } from '~/utils/measurementUnitsUtils'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

// @ts-ignore
import { handleUnitValueChange } from './vitalUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    input: {
      padding: theme.spacing(1),
      fontSize: '1.5rem',
    },
    textInput: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.controlBorder,
      borderTopLeftRadius: 2,
      borderBottomLeftRadius: 2,
    },
    textInputWidth: {
      width: 72,
    },
    select: {
      width: 95,
    },
  }),
  { name: 'TemperatureInput' },
)

export interface TemperatureInputProps {
  activeUnit?: string
  disabled?: boolean
  field: Field
  onUnitsChange: (type: UnitTypes, value: TemperatureTypes) => void
}

const TemperatureInput = ({
  field,
  activeUnit,
  onUnitsChange,
  disabled,
}: TemperatureInputProps) => {
  const classes = useStyles()
  const TemperatureUnits = useSelector(getTemperatureUnits)
  const unitsState = useSelector(getUnitsState)

  const items = useGetBusinessEnabledUnits(TemperatureUnits)

  useEffectExceptOnMount(() => {
    if (field && field.value !== '') {
      const newValue =
        unitsState[UnitTypes.TEMPERATURE] === TemperatureTypes.C
          ? Utils.round(UnitUtils.fahrenheitToCelsius(field.value))
          : Utils.round(UnitUtils.celsiusToFahrenheit(field.value))
      field.setValue(newValue)
    }
  }, [unitsState[UnitTypes.TEMPERATURE]])

  const onTemperatureChange = Utils.handleFormTextInput((str) =>
    handleUnitValueChange(field.setValue, str),
  )

  return (
    <Grid
      container
      flexWrap="nowrap"
      id="vitals-inputs-container-temperature-input"
    >
      <PuiTextField
        InputProps={{
          disableUnderline: true,
          inputProps: {
            className: classes.input,
          },
        }}
        className={classNames(classes.textInput)}
        disabled={disabled}
        margin="none"
        value={field.value}
        onChange={onTemperatureChange}
      />
      <UnitSelect
        classes={{ select: classes.select }}
        items={items}
        value={activeUnit}
        onChange={Utils.handleFormSelectInput(
          R.curry(onUnitsChange)(UnitTypes.TEMPERATURE),
        )}
      />
    </Grid>
  )
}

export default TemperatureInput
