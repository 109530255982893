import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  DateUtils,
  LanguageUtils,
  PuiTheme,
  StateLabel,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import PatientInfoLabel from '~/components/common/labels/PatientInfoLabel'
import Link from '~/components/common/link/Link'
import Expander from '~/components/common/lists/Expander'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  LabTestState,
  LabTestStateWithIssue,
  OrderType,
} from '~/constants/SOAPStates'
import { fetchDevices } from '~/store/duck/labOrders'
import {
  fetchLabTestDetails,
  getAllLabTestsIsLoading,
  getLabTest,
  saveLabOrder,
} from '~/store/duck/labTestsDashboard'
import { useOpenInvoice } from '~/store/hooks/finance'
import {
  getFeatureToggle,
  getLabOrderStatuses,
  getLabTestsStates,
} from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { LabTestDashboardTest } from '~/types'
import { addOriginalBusinessId } from '~/utils'
import useDialog from '~/utils/useDialog'

import useGetPreferredContactMethod from '../../clients/details/new-client-and-patient/useGetPreferredContactMethod'
import { PatientPreferencesChip } from '../../clients/patient/PatientPreferencesChip'
import { ClientPreferencesChip } from '../../clients/preferences/ClientPreferencesChip'
import LabOrderPreviewButtons from '../LabOrderPreviewButtons'
import LabResultPreviewButtons from '../LabResultPreviewButtons'
import LabOrderCell from './LabOrderCell'
import LabTestItem from './LabTestItem'
import { parseLabTestIdentifier } from './labTestUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    labOrderCellTitle: {
      fontSize: '2rem',
    },
    link: {
      color: theme.colors.primaryText,
      fontWeight: 500,
      fontSize: '1.8rem',
      marginRight: theme.spacing(1.5),
    },
    testItem: {
      border: theme.constants.tableBorder,
      padding: theme.spacing(1, 2),
      '&:not(:first-of-type)': {
        borderTop: 'none',
      },
    },
    stateLabel: {
      fontSize: '1.6rem',
    },
    footerItem: {
      height: 40,
      marginRight: theme.spacing(2),
    },
    button: {
      height: 40,
      minWidth: 115,
    },
    chip: {
      width: 16,
      height: 16,
      marginRight: 4,
    },
  }),
  { name: 'LabTestDetails' },
)

export interface LabTestDetailsProps {
  itemId: string
  onClose: () => void
}

const LabTestDetails = ({
  itemId: labTestIdentifier,
  onClose,
}: LabTestDetailsProps) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()

  const item = useSelector(getLabTest(labTestIdentifier))

  const { t } = useTranslation(['Common', 'LabTests'])

  useEffect(() => {
    const { vendorId, orderId, soapId, invoiceId, patientId, vetId } =
      parseLabTestIdentifier(labTestIdentifier)
    dispatch(
      fetchLabTestDetails(
        vendorId,
        orderId,
        soapId,
        invoiceId,
        patientId,
        vetId,
      ),
    )
  }, [labTestIdentifier])

  const {
    businessId,
    client: clientId,
    patient: patientId,
    vet,
    order,
    vendorId,
    tests,
    soapId,
    invoiceId,
  } = item || {}

  const LabTestStates = useSelector(getLabTestsStates)
  const LabOrderStatusesList = useSelector(getLabOrderStatuses)
  const isLoading = useSelector(getAllLabTestsIsLoading)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isHandleDeclinedLabOrderResultsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.HANDLE_DECLINED_LAB_ORDER_RESULTS),
  )

  const [openPrintLabOrderLabelDialog] = useDialog(
    DialogNames.PRINT_LAB_ORDER_LABEL,
  )
  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)

  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)

  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))

  const { value: contactMethod } = useGetPreferredContactMethod({ client })

  const [updatedTests, setUpdatedTests] = useState(tests || [])

  useEffect(() => {
    setUpdatedTests(tests || [])
  }, [tests])

  useEffect(() => {
    dispatch(fetchDevices())
  }, [])

  const hasOrder = Boolean(order?.vendorOrderIdentifier)
  const state = hasOrder
    ? Utils.getConstantName(order?.statusId, LabOrderStatusesList)
    : Utils.getConstantName(updatedTests[0]?.statusId, LabTestStates)
  const stateDisplayName = hasOrder
    ? LanguageUtils.getConstantTranslatedName(
        order?.statusId,
        LabOrderStatusesList,
      )
    : LanguageUtils.getConstantTranslatedName(
        updatedTests[0]?.statusId,
        LabTestStates,
      )
  const SelectedStateId = Utils.findConstantIdByName(
    LabTestState.SELECTED,
    LabTestStates,
  )
  const ReceivedStateId = Utils.findConstantIdByName(
    LabTestState.RESULTS_RECEIVED,
    LabTestStates,
  )
  const CompletedStateId = Utils.findConstantIdByName(
    LabTestState.COMPLETED,
    LabTestStates,
  )
  const UpdatedStateId = Utils.findConstantIdByName(
    LabTestState.UPDATED_RESULTS,
    LabTestStates,
  )

  const selectedTests = updatedTests.filter(
    (test) => test.statusId === SelectedStateId,
  )
  const otherTests = updatedTests.filter(
    (test) => test.statusId !== SelectedStateId,
  )
  const showPreview =
    state !== LabTestState.DELETED && state !== LabTestState.DECLINED

  const updateTest = (
    value: string,
    field: keyof LabTestDashboardTest,
    test: LabTestDashboardTest,
  ) => {
    const newTest = { ...test, [field]: value }
    const index = updatedTests.indexOf(test)
    const newUpdatedTests = R.update(index, newTest, updatedTests)

    setUpdatedTests(newUpdatedTests)
  }

  const onNotesChange = (notes: string, test: LabTestDashboardTest) => {
    updateTest(notes, 'notes', test)
  }

  const onStateChange = (newState: string, test: LabTestDashboardTest) => {
    updateTest(newState, 'statusId', test)
  }

  const save = () => {
    dispatch(saveLabOrder({ ...item, tests: updatedTests }))
  }

  const handleLabelPrint = () => {
    openPrintLabOrderLabelDialog({
      labOrderId: order?.vendorOrderIdentifier,
      orderDate: order?.date,
      vendorId,
      clientId,
      patientId,
    })
  }

  const handleOpenInvoice = () => {
    openInvoice({
      clientId,
      patientId,
      invoiceId,
      logId: R.head(tests)?.id,
      logType: OrderType.LAB_TEST,
    })
  }

  const viewSoap = () => {
    navigate(
      addOriginalBusinessId(
        `/soap/${soapId}`,
        isPatientSharingEnabled ? businessId : null,
      ),
    )
  }

  const stateIdsToShow = [CompletedStateId, ReceivedStateId, UpdatedStateId]
  const showResultsForDecline = isHandleDeclinedLabOrderResultsEnabled
    ? true
    : showPreview

  return (
    <Expander
      additionalButtons={[
        showResultsForDecline && (
          <LabResultPreviewButtons
            classes={{ root: classes.footerItem }}
            clientId={clientId}
            order={order}
            patientId={patientId}
            soapId={soapId}
            vendorId={vendorId}
          />
        ),
        showPreview && (
          <LabOrderPreviewButtons
            classes={{ root: classes.footerItem }}
            order={order}
            soapBusinessId={businessId}
            soapId={soapId}
            vendorId={vendorId}
          />
        ),
        <ButtonWithLoader
          className={classes.button}
          key="print-label"
          onClick={handleLabelPrint}
        >
          {t('Common:PRINT_LABEL')}
        </ButtonWithLoader>,
        Boolean(invoiceId) && (
          <ButtonWithLoader key="view-invoice" onClick={handleOpenInvoice}>
            {t('Common:VIEW_INVOICE')}
          </ButtonWithLoader>
        ),
        !invoiceId && Boolean(soapId) && (
          <ButtonWithLoader key="view-soap" onClick={viewSoap}>
            {t('Common:VIEW_SOAP')}
          </ButtonWithLoader>
        ),
      ]}
      expandedItemClass={t('Common:LAB_TEST').toLowerCase()}
      isSaving={isLoading}
      onBack={onClose}
      onSaveRequested={save}
    >
      {order?.vendorOrderIdentifier && (
        <LabOrderCell
          classes={{
            title: classes.labOrderCellTitle,
          }}
          labTest={item}
        />
      )}
      {vet?.name && order?.orderDate && (
        <Text variant="lowAccent2">
          {t('LabTests:LAB_TEST_DETAILS.ORDERED_BY_VET_NAME_ON_DATE', {
            vetName: vet?.name || '',
            date: DateUtils.formatDate(order?.orderDate),
          })}
        </Text>
      )}
      {!R.isEmpty(patient) && (
        <>
          <Grid container alignItems="center" mt={2}>
            <PatientPreferencesChip
              className={classes.chip}
              patientId={patientId}
            />
            <Text>
              <Link className={classes.link} to={`/patient/${patientId}`}>
                {patient?.name || ''}
              </Link>
            </Text>
          </Grid>
          <PatientInfoLabel patientId={patientId} />
        </>
      )}
      {!R.isEmpty(client) && (
        <Grid container alignItems="center" mt={2}>
          <ClientPreferencesChip className={classes.chip} clientId={clientId} />
          <Text>
            <Link className={classes.link} to={`/client/${clientId}`}>
              {Utils.getPersonString(client)}
            </Link>
            {contactMethod}
          </Text>
        </Grid>
      )}
      <Grid container item direction="column" mt={2}>
        {selectedTests.map((test) => (
          <LabTestItem
            clientId={clientId}
            key={test.id}
            labTestIdentifier={labTestIdentifier}
            patientId={patientId}
            test={test}
            vendorId={item.vendorId}
            onNotesChange={onNotesChange}
          />
        ))}
      </Grid>
      {!hasOrder &&
        otherTests.map((test) => (
          <Grid container item direction="column" key={test.id} mt={2}>
            <LabTestItem
              showStatusSelect
              clientId={clientId}
              labTestIdentifier={labTestIdentifier}
              patientId={patientId}
              test={test}
              vendorId={item.vendorId}
              onNotesChange={onNotesChange}
              onStateChange={onStateChange}
            />
          </Grid>
        ))}
      {hasOrder && otherTests.length > 0 && (
        <Grid container item direction="column" mt={2}>
          {otherTests.map((test) => (
            <LabTestItem
              clientId={clientId}
              key={test.id}
              labTestIdentifier={labTestIdentifier}
              patientId={patientId}
              showStatusSelect={stateIdsToShow.includes(test.statusId)}
              statusesToShow={stateIdsToShow}
              test={test}
              vendorId={item.vendorId}
              onNotesChange={onNotesChange}
              onStateChange={onStateChange}
            />
          ))}
          <Grid container item alignItems="center" className={classes.testItem}>
            <StateLabel
              className={classes.stateLabel}
              completed={state === LabTestState.COMPLETED}
              success={
                state === LabTestState.RESULTS_RECEIVED ||
                state === LabTestState.UPDATED_RESULTS
              }
              warning={LabTestStateWithIssue.includes(state)}
            >
              {stateDisplayName}
            </StateLabel>
            {order?.resultDate && (
              <Text ml={2} variant="lowAccent4">
                {`${t('Common:LAST_UPDATED')} ${DateUtils.formatDateWithHours(
                  order?.resultDate,
                )}`}
              </Text>
            )}
          </Grid>
        </Grid>
      )}
    </Expander>
  )
}

export default LabTestDetails
