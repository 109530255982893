import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Checkbox, CheckboxProps, Collapse, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { PuiTheme, Text, TextInteractive, Utils } from '@pbt/pbt-ui-components'

import { OrderType } from '~/constants/SOAPStates'
import { useGetIsSendingOrder } from '~/store/hooks/orders'
import { Order, OrderCallbackWithTypeCheck } from '~/types'
import {
  aggregateCheckboxColor,
  CheckboxColor,
  hasSelectedAllFromBundle,
} from '~/utils/orderUtils'

import OrderListItem, { OrderListItemProps } from './OrderListItem'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    bundleNameText: {
      flexWrap: 'nowrap',
      cursor: 'pointer',
    },
    caret: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    checkbox: {
      padding: 0,
      '&&:hover': {
        backgroundColor: 'transparent',
      },
      color: theme.colors.secondaryText,
      margin: theme.spacing(0, 1),
    },
    listItemInnerContainer: {
      padding: theme.spacing(0.5, 0.75),
      '&:hover': {
        backgroundColor: theme.colors.soapListItemHover,
      },
    },
    collapse: {
      width: '100%',
      maxWidth: `calc(100% - ${theme.spacing(3)})`,
      paddingLeft: theme.spacing(3),
    },
  }),
  { name: 'BundleListItem' },
)

export interface BundleListItemProps extends OrderListItemProps {
  isLoading?: boolean
  isSelected: boolean
  onCheckAll: (order: Order, checked: boolean) => void
  selectedSubItem: Order
  showCategory: boolean
  updateSelectedItem: (order: Order) => void
  updateSelectedSubItem: (order: Order) => void
}

const BundleListItem = forwardRef<HTMLDivElement, BundleListItemProps>(
  function BundleListItem(
    {
      searchTerm,
      showCategory,
      order,
      getItemColor,
      isLoading,
      isSelected,
      selectedSubItem,
      isCheckedItem,
      updateSelectedItem,
      updateSelectedSubItem,
      onCheckItem,
      onUncheckItem,
      onCheckAll,
      ...rest
    },
    ref,
  ) {
    const classes = useStyles()
    const { t } = useTranslation('Common')

    const getIsSendingOrder = useGetIsSendingOrder()

    const bundleItems = order.items || []

    const isBundleChecked = hasSelectedAllFromBundle(order, isCheckedItem)
    const bundleCheckboxColor =
      typeof getItemColor === 'function'
        ? aggregateCheckboxColor(getItemColor)(bundleItems || [])
        : CheckboxColor.PRIMARY

    const isBundleOrderPending = bundleItems.some(getIsSendingOrder)
    const hasCheckedItems = bundleItems.some(isCheckedItem)

    const onSelectAll = (checked: boolean) => {
      onCheckAll(order, checked)
    }

    const handleOrderChecked: OrderCallbackWithTypeCheck = (...args) => {
      if (bundleItems?.length > 1) {
        onCheckItem(...args)
      } else {
        onSelectAll(true)
      }
    }

    const handleOrderUnchecked: OrderCallbackWithTypeCheck = (...args) => {
      if (bundleItems?.length > 1) {
        onUncheckItem(...args)
      } else {
        onSelectAll(false)
      }
    }

    const expand = () => {
      updateSelectedItem(order)
    }

    const [tasks, orders] = R.partition(
      R.propEq('type', OrderType.TASK),
      bundleItems,
    )

    const disabled = isBundleOrderPending || isLoading

    return (
      <Grid container item direction="column" p={0.5} ref={ref}>
        <Grid
          container
          item
          alignItems="center"
          className={classes.bundleNameText}
          onClick={expand}
        >
          {isSelected ? (
            <ExpandLess className={classes.caret} />
          ) : (
            <ExpandMore className={classes.caret} />
          )}
          <TextInteractive
            highlight={searchTerm}
            strong={hasCheckedItems}
            variant={hasCheckedItems ? 'h5' : 'body2'}
          >
            {order.name}
          </TextInteractive>
        </Grid>
        <Collapse
          mountOnEnter
          unmountOnExit
          className={classes.collapse}
          in={isSelected}
        >
          {bundleItems.length > 1 && (
            <Grid
              container
              item
              alignItems="center"
              className={classes.listItemInnerContainer}
            >
              <Checkbox
                checked={isBundleChecked}
                className={classes.checkbox}
                color={bundleCheckboxColor as CheckboxProps['color']}
                disabled={disabled}
                id={`bundle-list-item-${order.type}_${order.name}_${order.id}_${order.logId}`}
                onChange={Utils.handleFormCheckboxInput(onSelectAll)}
              />
              <Text variant="body2">{t('Common:SELECT_ALL')}</Text>
            </Grid>
          )}
          {orders.map((item) => (
            <OrderListItem
              {...rest}
              getItemColor={getItemColor}
              isCheckedItem={isCheckedItem}
              isDisabled={disabled}
              key={item.price.id}
              order={item}
              onCheckItem={handleOrderChecked}
              onUncheckItem={handleOrderUnchecked}
            />
          ))}
          {tasks.length > 0 && (
            <>
              <Text ml={2} mt={1} variant="body2">
                {t('Common:INCLUDED_TASKS')}:
              </Text>
              {tasks.map((item) => (
                <OrderListItem
                  {...rest}
                  getItemColor={getItemColor}
                  isCheckedItem={isCheckedItem}
                  isDisabled={disabled}
                  key={`${item.bundleId}-${item.id}-${item.taskTemplate?.id}`}
                  order={item}
                  onCheckItem={onCheckItem}
                  onUncheckItem={onUncheckItem}
                />
              ))}
            </>
          )}
        </Collapse>
      </Grid>
    )
  },
)

export default BundleListItem
