import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {
  AlertIconType,
  CircularProgressOverlay,
  PermissionArea,
  PuiAlert,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import MenuDropdown from '~/components/common/inputs/MenuDropdown'
import MarketplaceItemIframe from '~/components/common/marketplaceIframes/MarkeplaceItemIframe'
import PuiTabs from '~/components/common/PuiTabs'
import ValidationErrorPopper from '~/components/common/validation/ValidationErrorPopper'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { MarketplaceWorkflowNames } from '~/constants/marketplaceConstants'
import { SOAPStep, SOAPStepsList } from '~/constants/SOAPStates'
import {
  clearItemExistError,
  clearOrderValidationErrorMessage,
} from '~/store/actions/orders'
import {
  addSOAP,
  clearFindingLogsCrcValidationError,
  clearNotesValidationError,
  closeSoap,
  createSOAP,
  dismissSoapAlert,
  fetchSoap,
} from '~/store/actions/soap'
import { clearSoapTasks } from '~/store/actions/tasks'
import {
  clearAppointmentValidationError,
  patchAppointment,
} from '~/store/actions/timetable'
import { fetchModality } from '~/store/duck/imagingOrders'
import { getExpandedIFrame } from '~/store/duck/marketplace'
import { getIsAreaLoading } from '~/store/duck/progress'
import { useGetWorkflowIFrames } from '~/store/hooks/marketplace'
import { useIsSoapLocked } from '~/store/hooks/soap'
import {
  getCRUDByArea,
  getCurrentBusiness,
  getCurrentBusinessId,
} from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getItemExistError,
  getOrderValidationErrorMessage,
  getUnassignedDoctorOrderError,
} from '~/store/reducers/orders'
import { getPatient } from '~/store/reducers/patients'
import {
  getAppointmentId,
  getDiagnosesState,
  getError,
  getFindingLogsCrcValidationError,
  getFindingsState,
  getIsCurrentContextSoap,
  getIsFinalized,
  getLastSoapVital,
  getNotesValidationError,
  getPetParents,
  getSoapAlert,
  getSoapBusinessId,
  getSoapId,
  getSOAPisFetching,
  getSOAPisFetchingUsersOnCollaboration,
  getSOAPisLoading,
} from '~/store/reducers/soap'
import {
  getTimetableEvent,
  getTimetableValidationError,
} from '~/store/reducers/timetable'
import { getUser } from '~/store/reducers/users'
import { addOriginalBusinessId, getUrlSearchParam } from '~/utils'
import useDialog from '~/utils/useDialog'

import MarketplaceWidget from '../soapV2/marketplace/MarketplaceWidget'
import SoapProblemsWidgetWrapper from '../soapV2/problems/SoapProblemsWidgetWrapper'
import SoapVitalsWidget from '../soapV2/vitals/SoapVitalsWidget'
import { AppointmentHandle } from './appointment/Appointment'
import AppointmentPage from './appointment/AppointmentPage'
import SoapRecordSection from './appointment/SoapRecordSection'
import CheckOutPage from './check-out/CheckOutPage'
import SoapSavingsBanner from './check-out/SoapSavingsBanner'
// @ts-ignore
import DiffDxPage from './diff-dx/DiffDxPage'
import ExaminationPage from './examination/ExaminationPage'
import OrdersPage from './order/OrdersPage'
import QuestionsPage from './questions/QuestionsPage'
import SoapRail from './rail/SoapRail'
import SoapHeader from './SoapHeader'
import ToDoPage from './to-do/ToDoPage'
import AddendumsSection from './wrap-up/addendum/AddendumsSection'
import WrapUpPage from './wrap-up/WrapUpPage'

interface UseStylesProps {
  rightRailTop: number
}

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      position: 'relative',
    },
    rootWithRail: {
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${theme.constants.rightRailCollapsedWidth}px)`,
      },
    },
    rootWithOverlay: {
      width: '100%',
      overflow: 'hidden',
      height: `calc(100vh - ${
        theme.mixins.toolbar.minHeight + theme.constants.progressBarHeight
      }px)`,
    },
    rootWithIframe: {
      height: '100%',
    },
    overlay: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.headerTopBar, 'below'),
    },
    contentRoot: {
      minWidth: 825,
    },
    contentWidth: {
      [theme.breakpoints.up('md')]: {
        maxWidth: `calc(100vw - ${theme.constants.leftMenuWidth}px)`,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: `calc(100vw - ${
          theme.constants.leftMenuWidth +
          theme.constants.rightRailCollapsedWidth
        }px)`,
      },
    },
    contentWidthWide: {
      [theme.breakpoints.up('md')]: {
        maxWidth: `calc(100vw - ${theme.constants.leftMenuWidth}px)`,
      },
    },
    content: {
      padding: theme.spacing(0, 3, 3),
    },
    headingContainer: {
      [theme.breakpoints.down('md')]: {
        top: 0,
      },
      top: theme.mixins.toolbar.minHeight,
      position: 'sticky',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
      backgroundColor: theme.colors.contentBackground,
      zIndex: theme.utils.modifyZIndex(theme.zIndex.progress, 'below'),
    },
    chips: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    soapDropdown: {
      '&:hover': {
        textDecoration: 'underline',
      },
      color: theme.colors.secondaryText,
      paddingBottom: 0,
      marginRight: `${theme.spacing(1)} !important`,
      marginLeft: `${theme.spacing(1)} !important`,
      whiteSpace: 'nowrap',
    },
    tabs: {
      marginTop: 0,
      height: 35,
    },
    tabRoot: {
      fontSize: '1.4rem',
      color: theme.colors.secondaryText,
    },
    expandedRail: {
      width: theme.constants.rightRailExpandedWidth,
    },
    contentWithRail: {
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${theme.constants.rightRailCollapsedWidth}px)`,
      },
    },
    hidden: {
      width: 0,
      height: 0,
    },
    rightRail: {
      top: ({ rightRailTop }: UseStylesProps) =>
        rightRailTop ||
        theme.constants.soapHeaderHeight + theme.constants.appHeader.heightMdUp,
      [theme.breakpoints.down('md')]: {
        paddingTop: 0,
        top: ({ rightRailTop }: UseStylesProps) =>
          rightRailTop ||
          theme.constants.soapHeaderHeight +
            theme.constants.appHeader.heightMdDown,
      },
      bottom: 0,
      height: 'auto',
    },
  }),
  { name: 'SoapPage' },
)

type SoapComponent = {
  component: React.JSXElementConstructor<any>
  label: string
  tooltipText?: string
}

const SoapPage = () => {
  const navigate = useNavigate()
  const {
    soapId: urlSoapId,
    appointmentId: urlAppointmentId,
    step = SOAPStep.APPOINTMENT,
  } = useParams()
  const location = useLocation()

  const activeStep = step.toUpperCase() as SOAPStep
  const { t } = useTranslation(['Common', 'Soap'])

  const dispatch = useDispatch()

  const isMedium = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('lg'),
  )

  const validationError = useSelector(getTimetableValidationError)
  const isLoading = useSelector(getSOAPisLoading)
  const isFetching = useSelector(getSOAPisFetching)
  const error = useSelector(getError)
  const soapId = useSelector(getSoapId)
  const soapBusinessId = useSelector(getSoapBusinessId)
  const appointmentId = useSelector(getAppointmentId)
  const findingsState = useSelector(getFindingsState)
  const diagnosesState = useSelector(getDiagnosesState)
  const lastSoapVital = useSelector(getLastSoapVital)
  const SOAPPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const itemExistError = useSelector(getItemExistError)
  const business = useSelector(getCurrentBusiness)
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const soapAlert = useSelector(getSoapAlert)
  const notesValidationError = useSelector(getNotesValidationError)
  const petParents = useSelector(getPetParents)
  const newClientId = petParents && petParents[0]
  const patient = useSelector(getPatient(appointment?.patient))
  const client = useSelector(getUser(appointment?.client))
  const newClient = useSelector(getUser(newClientId))
  const isSoapFinalized = useSelector(getIsFinalized)
  const findingLogsCrcValidationError = useSelector(
    getFindingLogsCrcValidationError,
  )
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)
  const iFrame = useSelector(getExpandedIFrame)
  const isFetchingUsersOnCollaboration = useSelector(
    getSOAPisFetchingUsersOnCollaboration,
  )
  const isFinalizing = useSelector(getIsAreaLoading('soap-lock'))
  const orderValidationErrorMessage = useSelector(
    getOrderValidationErrorMessage,
  )
  const unassignedDoctorError = useSelector(getUnassignedDoctorOrderError)
  const isProblemWidgetFTEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PROBLEMS_SOAP_WIDGET),
  )
  const isProblemsWidgetOpened = activeStep === SOAPStep.PROBLEMS

  const isLockable = useIsSoapLocked()
  const isReadOnly = isSoapFinalized || !isCurrentContextSoap
  const originalBusinessId = getUrlSearchParam(
    'originalBusinessId',
    location.search,
  )

  const components: Record<SOAPStep, SoapComponent> = {
    [SOAPStep.APPOINTMENT]: {
      label: appointmentId ? t('Common:APPOINTMENT_ONE') : t('Common:REASON'),
      component: AppointmentPage,
    },
    [SOAPStep.QUESTIONS]: {
      label: t('Common:QUESTION_OTHER'),
      component: QuestionsPage,
    },
    [SOAPStep.VITALS]: {
      label: t('Common:VITALS'),
      component: SoapVitalsWidget,
    },
    [SOAPStep.EXAMINATION]: {
      label: t('Common:EXAM_NOUN'),
      component: ExaminationPage,
    },
    [SOAPStep.DIFFERENTIAL_DX]: {
      label: t('Soap:SOAP_PAGE.STEP.DIFFERENTIAL_DELTA_X.LABEL'),
      component: DiffDxPage,
      tooltipText: t('Soap:SOAP_PAGE.STEP.DIFFERENTIAL_DELTA_X.TEXT'),
    },
    [SOAPStep.ORDER]: {
      label: t('Payments:CHARGES'),
      component: OrdersPage,
    },
    [SOAPStep.TO_DO]: {
      label: t('Tasks:LABEL.TO_DO'),
      component: ToDoPage,
    },
    [SOAPStep.WRAP_UP]: {
      label: t('Common:WRAP_UP_ACTION'),
      component: WrapUpPage,
    },
    [SOAPStep.CHECK_OUT]: {
      label: t('Common:CHECK-OUT_ACTION'),
      component: CheckOutPage,
    },
    [SOAPStep.MARKETPLACE]: {
      label: t('Common:MARKETPLACE'),
      component: MarketplaceWidget,
    },
    ...(isProblemWidgetFTEnabled
      ? {
          [SOAPStep.PROBLEMS]: {
            label: t('Soap:PROBLEMS.PROBLEMS'),
            component: SoapProblemsWidgetWrapper,
          },
        }
      : ({} as { [SOAPStep.PROBLEMS]: SoapComponent })),
  }

  const rootRef = useRef<HTMLDivElement>(null)
  const appointmentRef = useRef<AppointmentHandle>(null)

  const [soapRailTop, setSoapRailTop] = useState(0)

  const classes = useStyles({ rightRailTop: soapRailTop })

  const [openSoapExamFindingsValidationErrorDialog] = useDialog(
    DialogNames.SOAP_EXAM_FINDINGS_VALIDATION_ERROR,
    () => dispatch(clearFindingLogsCrcValidationError()),
  )

  // Not needed
  useEffect(() => {
    const handleSetSoapRailTop = () => {
      const rootSoapTop = rootRef.current?.getBoundingClientRect().bottom || 0
      if (rootRef.current) {
        setSoapRailTop(rootSoapTop)
      }
    }

    handleSetSoapRailTop()
    window.addEventListener('scroll', handleSetSoapRailTop)
    window.addEventListener('resize', handleSetSoapRailTop)

    return () => {
      window.removeEventListener('scroll', handleSetSoapRailTop)
      window.removeEventListener('resize', handleSetSoapRailTop)
    }
  }, [rootRef.current, isLoading])

  const [expanded, setExpanded] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const [soapCreation, setSoapCreation] = useState(false)
  const [soapWasCreated, setSoapWasCreated] = useState(false)
  const [appointmentWithChangedClient, setAppointmentWithChangedClient] =
    useState<string>()
  const [missingFieldsWarningWasShown, setMissingFieldsWarningWasShown] =
    useState(false)

  const iFrames = useGetWorkflowIFrames(MarketplaceWorkflowNames.SOAP)
  const showMarketplace = iFrames?.length > 0

  const SOAPStepsToExclude: SOAPStep[] = [
    ...(isProblemWidgetFTEnabled
      ? [SOAPStep.EXAMINATION, SOAPStep.DIFFERENTIAL_DX]
      : [SOAPStep.PROBLEMS]),
    ...(showMarketplace ? [] : [SOAPStep.MARKETPLACE]),
  ]

  const preparedSOAPStepsList = R.without(SOAPStepsToExclude, SOAPStepsList)

  const tabs = preparedSOAPStepsList.map((soapStep) => ({
    id: soapStep,
    label: components[soapStep].label,
  }))
  const selectedTabIdx = R.findIndex((tab) => tab.id === activeStep, tabs)

  const soaps =
    appointment?.soaps?.map((s, idx) => ({
      name: `${t('Common:SOAP')} ${idx + 1}`,
      value: s.id,
    })) || []
  const currentSoapIdx = R.findIndex(
    (s) => s.id === urlSoapId,
    appointment?.soaps ?? [],
  )

  useEffect(() => {
    if (findingLogsCrcValidationError && !isProblemWidgetFTEnabled) {
      openSoapExamFindingsValidationErrorDialog()
    }
  }, [findingLogsCrcValidationError, isProblemWidgetFTEnabled])

  const [openSoapNotesValidationErrorDialog] = useDialog(
    DialogNames.SOAP_NOTES_VALIDATION_ERROR,
    () => dispatch(clearNotesValidationError()),
  )

  const [openItemDoesNotExistErrorDialog] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
    () => {
      dispatch(clearItemExistError())
      if (urlSoapId) {
        dispatch(fetchSoap(urlSoapId, originalBusinessId))
      }
    },
  )

  const [openPetParentChangedAlertDialog] = useDialog(
    DialogNames.PET_PARENT_CHANGED_DIALOG,
  )

  const [openMissingFieldDialog] = useDialog(
    DialogNames.PATIENT_MISSING_FIELDS_DIALOG,
  )

  const [rightRailVisible, setRightRailVisible] = useState(true)

  useEffect(() => {
    if (currentBusinessId) {
      dispatch(fetchModality(currentBusinessId))
    }
  }, [currentBusinessId])

  // Covered by useSoapRail
  useEffect(() => {
    if (isReadOnly) {
      setRightRailVisible(false)
    } else if (!isReadOnly && !isMedium) {
      setRightRailVisible(true)
    }
  }, [isReadOnly])

  // Covered by useSoapRail
  useEffect(() => {
    if (itemExistError) {
      openItemDoesNotExistErrorDialog({
        message: itemExistError,
      })
    }
  }, [itemExistError])

  // Not used
  useEffect(() => {
    if (notesValidationError) {
      openSoapNotesValidationErrorDialog()
    }
  }, [notesValidationError])

  // Covered by useAddOriginalBusinessId
  useEffect(() => {
    if (!components[activeStep] && soapId) {
      navigate(
        addOriginalBusinessId(
          `/soap/${soapId}/appointment`,
          isPatientSharingEnabled ? soapBusinessId : null,
        ),
        {
          replace: true,
        },
      )
    }
  }, [soapId])

  // Covered by usePetParentChangeAlert
  useEffect(() => {
    if (
      appointmentId &&
      appointmentWithChangedClient !== appointmentId &&
      client &&
      patient &&
      newClientId &&
      newClient &&
      client.id !== newClient.id
    ) {
      openPetParentChangedAlertDialog({ patient, client, newClient })
      setAppointmentWithChangedClient(appointmentId)
    }
  }, [appointmentId, client, newClient, patient])

  // Covered by useSoapRail
  useEffect(() => {
    if (isProblemWidgetFTEnabled && isProblemsWidgetOpened) {
      setRightRailVisible(false)
    }
  }, [isProblemsWidgetOpened])

  const onStepperStepChangeIdx = (stepIdx: number) => {
    if (soapId) {
      navigate(
        addOriginalBusinessId(
          `/soap/${soapId}/${tabs[stepIdx].id.toLowerCase()}`,
          isPatientSharingEnabled ? soapBusinessId : null,
        ),
      )
    }
  }

  // Covered by useInitializeSoap
  useEffect(() => {
    if (!isLoading && !urlSoapId) {
      if (soapId && soapCreation) {
        setSoapCreation(false)
        setSoapWasCreated(true)
        navigate(
          addOriginalBusinessId(
            `/soap/${soapId}/${step.toLowerCase()}`,
            isPatientSharingEnabled ? soapBusinessId : null,
          ),
          { replace: true },
        )
      } else if (urlAppointmentId && !error) {
        setSoapCreation(true)
        if (location.pathname.startsWith('/soap/add/')) {
          dispatch(
            addSOAP(
              urlAppointmentId,
              isPatientSharingEnabled ? originalBusinessId : null,
            ),
          )
        } else {
          dispatch(
            createSOAP(
              urlAppointmentId,
              isPatientSharingEnabled ? originalBusinessId : null,
            ),
          )
        }
      } else {
        navigate('/')
      }
    }
  }, [urlSoapId, soapId, isLoading, urlAppointmentId, soapCreation])

  // Covered by useSoapRail
  useEffect(() => {
    setSelectedTab(0)
  }, [step, findingsState, diagnosesState])

  // Covered by useSoapRail
  useDeepCompareEffect(() => {
    if (!R.isEmpty(lastSoapVital)) {
      setSelectedTab(0)
    }
  }, [lastSoapVital])

  // Covered by useInitializeSoap
  useEffect(() => {
    if (urlSoapId) {
      dispatch(clearSoapTasks())
      if (!soapWasCreated || soapId !== urlSoapId) {
        dispatch(fetchSoap(urlSoapId, originalBusinessId))
      }
    }
  }, [urlSoapId])

  // Covered by useOpenMissingClientPatientMissingFields
  useEffect(() => {
    if (appointmentId) {
      setMissingFieldsWarningWasShown(false)
    }
  }, [appointmentId])

  // Covered by useOpenMissingClientPatientMissingFields
  useEffect(() => {
    const hasMissingFields =
      client &&
      patient &&
      (!patient.species ||
        !patient.gender ||
        R.isNil(patient.spayedNeutered) ||
        !patient.dateOfBirth)
    if (hasMissingFields && !missingFieldsWarningWasShown) {
      setMissingFieldsWarningWasShown(true)
      openMissingFieldDialog({ clientId: client.id, patient })
    }
  }, [patient, client])

  // Covered on SoapPageV2
  useEffect(
    () => () => {
      dispatch(closeSoap())
    },
    [],
  )

  if ((isLoading && !soapId) || !urlSoapId) {
    return null
  }

  const onRailNavigation = (tab: number) => {
    setExpanded(true)
    setSelectedTab(tab)
  }

  const handleSoapChange = (currentSoapId: string) => {
    navigate(
      addOriginalBusinessId(
        `/soap/${currentSoapId}`,
        isPatientSharingEnabled ? soapBusinessId : null,
      ),
    )
  }

  // ToDo replace this with SOAP order filters fetching when DEV-4428 will be done.
  const refreshSoap = () => {
    if (soapId) {
      dispatch(fetchSoap(soapId, originalBusinessId))
    }
  }

  const clearOrderValidationError = () => {
    dispatch(clearOrderValidationErrorMessage())
  }

  const Page = (components[activeStep] || components[SOAPStep.APPOINTMENT])
    .component
  const showOverlay =
    isFetching || isFetchingUsersOnCollaboration || isFinalizing

  return (
    <Grid
      container
      className={classNames(classes.root, {
        [classes.rootWithOverlay]: showOverlay,
        [classes.rootWithIframe]: Boolean(iFrame),
      })}
    >
      <CircularProgressOverlay className={classes.overlay} open={showOverlay} />
      <Grid
        container
        item
        className={classes.contentRoot}
        id="soap-page-content"
      >
        {iFrame ? (
          <MarketplaceItemIframe
            id={iFrame.id}
            params={{
              businessId: business?.id,
              soapId,
              appointmentId,
              clientId: client?.id,
              patientId: patient?.id,
            }}
            title={iFrame.name}
            url={iFrame.expandedUrl}
          />
        ) : (
          <>
            <Grid
              container
              item
              alignItems="flex-start"
              className={classNames(classes.headingContainer)}
              direction="column"
              ref={rootRef}
              zIndex={11}
            >
              <Grid item width="100%">
                <SoapHeader />
              </Grid>
              <Grid container flexWrap="nowrap" id="soap-header-tabs-container">
                {soaps.length > 1 && (
                  <MenuDropdown
                    linkButton
                    className={classes.soapDropdown}
                    items={soaps}
                    title={`${t('Common:SOAP')} ${currentSoapIdx + 1}`}
                    onSelected={handleSoapChange}
                  />
                )}
                <PuiTabs
                  classes={{ tabs: classes.tabs, tabRoot: classes.tabRoot }}
                  selectedTab={selectedTabIdx}
                  tabs={tabs}
                  variant="standard"
                  onSelectedTabChange={onStepperStepChangeIdx}
                />
              </Grid>
              <ValidationErrorPopper
                anchorEl={rootRef.current}
                error={validationError}
                open={Boolean(validationError)}
                placement="bottom"
                proceedButtonActionName={t('Common:EDIT_ACTION')}
                onBack={() => {
                  appointmentRef.current?.discardChanges()
                  dispatch(clearAppointmentValidationError())
                }}
                onProceed={() => {
                  dispatch(
                    patchAppointment({
                      id: appointment?.id!,
                      bypassValidation: true,
                    }),
                  )
                }}
              />
            </Grid>
            <Grid
              container
              item
              className={classNames(classes.contentRoot, {
                [classes.contentWithRail]: rightRailVisible,
              })}
              pt={2}
            >
              <>
                {activeStep === SOAPStep.CHECK_OUT && <SoapSavingsBanner />}
                <SoapRecordSection />
                {activeStep === SOAPStep.WRAP_UP && <AddendumsSection />}
              </>
            </Grid>
            <Grid
              container
              className={classNames(classes.content, classes.contentRoot, {
                [classes.contentWidth]: rightRailVisible,
                [classes.contentWidthWide]: !rightRailVisible,
                [classes.contentWithRail]: rightRailVisible,
              })}
              flexWrap="nowrap"
            >
              <Grid
                container
                item
                flexDirection="column"
                flexGrow={1}
                id="soap-page-wrapper"
                width={0}
              >
                <Page
                  editDisabled={!SOAPPermissions.update}
                  ref={
                    activeStep === SOAPStep.APPOINTMENT && !isLockable
                      ? appointmentRef
                      : undefined
                  }
                  onRailNavigation={onRailNavigation}
                />
              </Grid>
              <SoapRail
                className={classNames(classes.rightRail, {
                  [classes.expandedRail]: expanded,
                  [classes.hidden]: !rightRailVisible,
                })}
                editDisabled={!SOAPPermissions.update}
                expanded={expanded}
                selectedTab={selectedTab}
                setExpanded={setExpanded}
                setSelectedTab={setSelectedTab}
                visible={rightRailVisible}
                onVisibilityChange={
                  isReadOnly ? undefined : setRightRailVisible
                }
              />
            </Grid>
          </>
        )}
      </Grid>
      {soapAlert && (
        <PuiAlert
          hideCloseButton
          iconType={AlertIconType.WARN}
          message={soapAlert}
          okButtonText={`${t('Common:GOT_IT')}!`}
          open={Boolean(soapAlert)}
          onClose={() => dispatch(dismissSoapAlert)}
          onOk={() => dispatch(dismissSoapAlert)}
        />
      )}
      <PuiAlert
        message={orderValidationErrorMessage}
        open={Boolean(orderValidationErrorMessage) && !unassignedDoctorError}
        onClose={() => {
          clearOrderValidationError()
          refreshSoap()
        }}
        onOk={() => {
          clearOrderValidationError()
          refreshSoap()
        }}
      />
    </Grid>
  )
}

export default SoapPage
