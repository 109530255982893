import { gql } from '@apollo/client'

import { INVOICE_FIELDS } from '../fragments/invoice'

export const FETCH_INVOICE = gql`
  ${INVOICE_FIELDS}
  query FetchInvoice($businessId: ID, $id: ID!, $includeDeleted: Boolean) {
    invoice(businessId: $businessId, id: $id, includeDeleted: $includeDeleted) {
      ...InvoiceFields
      serviceFee
      paid
    }
  }
`
