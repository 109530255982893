/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Box } from '@mui/material'
import * as R from 'ramda'
import { BaseUser, Text, Utils } from '@pbt/pbt-ui-components'

import {
  Invoice as GraphqlInvoice,
  RefundInvoice,
} from '~/api/graphql/generated/types'
import { Invoice } from '~/types'

import { CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT } from '../common/tableConstants'

interface InvoiceTableDoctorCellProps {
  invoice: Invoice | GraphqlInvoice | RefundInvoice
  multipleRowsLimit?: number
}

const boxProps = {
  alignItems: 'center',
  display: 'flex',
  minHeight: CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT,
  width: '100%',
}

export const InvoiceTableDoctorCell = ({
  invoice,
  multipleRowsLimit,
}: InvoiceTableDoctorCellProps) => {
  if ('event' in invoice && invoice.event?.assignedVet) {
    return (
      <Text variant="body2">
        {Utils.getPersonString(invoice.event.assignedVet)}
      </Text>
    )
  }

  if ('sections' in invoice) {
    const sections = R.isNil(multipleRowsLimit)
      ? invoice.sections
      : invoice.sections.slice(0, multipleRowsLimit)

    return (
      <>
        {sections.map(({ event, id }, index) => {
          const assignedVetId = event?.assignedVetId
          const veterinarian = event?.resources.find(
            (resource) => resource.employee.id === assignedVetId,
          )?.employee
          const assignedVet = Utils.getPersonString(veterinarian as BaseUser)

          if (!veterinarian) {
            return <Box {...boxProps} key={`${id}_${index}`} />
          }

          return (
            <Box {...boxProps} key={`${id}_${event.id}_${index}`}>
              <Text variant="body2">{assignedVet}</Text>
            </Box>
          )
        })}
      </>
    )
  }

  return null
}
