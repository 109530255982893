import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import { useGetChargeSheetItemHeader } from '~/store/hooks/chargeSheet'
import { useGetInvoiceV3ItemHeader } from '~/store/hooks/invoiceV3'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    orderNameTitle: {
      color: theme.colors.primaryText,
    },
  }),
  { name: 'OrderNameTitle' },
)

export interface OrderNameTitleProps {
  sectionId: string
}

const OrderNameTitle = ({ sectionId }: OrderNameTitleProps) => {
  const classes = useStyles()

  const titleInvoice = useGetInvoiceV3ItemHeader(sectionId)
  const titleChargeSheet = useGetChargeSheetItemHeader(sectionId)
  const title = titleInvoice || titleChargeSheet
  return (
    <Text strong className={classes.orderNameTitle}>
      {title}
    </Text>
  )
}

export default OrderNameTitle
