/* eslint-disable react/no-multi-comp */
import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { Grid, Hidden } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  InfiniteLoaderList,
  PuiTheme,
  Text,
  TextInteractive,
} from '@pbt/pbt-ui-components'

import { AvailabilityRuleGroup } from '~/types'

import AvailabilityRulesTableRow, {
  AvailabilityRulesTableRowProps,
} from './AvailabilityRulesTableRow'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    container: {
      userSelect: 'none',
      cursor: 'pointer',
      backgroundColor: theme.colors.tableBackground,
    },
    groupHeaderContainer: {
      minHeight: 25,
      backgroundColor: theme.colors.tableLeftColumnBackground,
      borderRadius: 2,
      border: theme.constants.tableBorder,
    },
  }),
  { name: 'AvailabilityRulesTableRowWrapper' },
)

export interface AvailabilityRulesTableRowWrapperProps {
  data: {
    onClick: AvailabilityRulesTableRowProps['onClick']
    rowFocused?: string
  }
  item?: AvailabilityRuleGroup
}

const AvailabilityRulesTableRowWrapper = ({
  item,
  data,
}: AvailabilityRulesTableRowWrapperProps) => {
  const classes = useStyles()
  const isLoading = !item

  return (
    <Grid
      container
      className={classes.container}
      direction="column"
      mb={2}
      wrap="nowrap"
    >
      <Grid item className={classes.groupHeaderContainer} px={2}>
        <Dotdotdot clamp={1}>
          <TextInteractive strong isLoading={isLoading} variant="subheading2">
            {item?.name}
          </TextInteractive>
        </Dotdotdot>
      </Grid>
      {isLoading ? (
        <Grid item>
          <AvailabilityRulesTableRow isLoading={isLoading} {...data} />
        </Grid>
      ) : (
        item?.rules?.map((ruleId) => (
          <Grid item key={ruleId}>
            <AvailabilityRulesTableRow availabilityRuleId={ruleId} {...data} />
          </Grid>
        ))
      )}
    </Grid>
  )
}

const useTableStyles = makeStyles(
  (theme: PuiTheme) => ({
    expandedColumn: {
      maxWidth: '66.666%',
      transition: theme.transitions.create(['max-width', 'flex-basis'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    collapsedColumn: {
      maxWidth: '0%',
      transition: theme.transitions.create(['max-width', 'flex-basis'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    expandedMainColumn: {
      transition: theme.transitions.create(['max-width', 'flex-basis'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen / 2,
        delay: 100,
      }),
    },
    noOverflow: {
      overflow: 'hidden',
      height: '100%',
    },
    mainContainer: {
      height: '100%',
    },
  }),
  { name: 'AvailabilityRulesTable' },
)

export interface AvailabilityRulesTableProps {
  availabilityRules: AvailabilityRuleGroup[]
  isItemLoaded: (index: number) => boolean
  loadMoreItems: (startIndex: number, endIndex: number) => void
  onItemClick?: () => void
  selectedItemId?: string
  totalCount: number
}

const AvailabilityRulesTable = ({
  availabilityRules,
  selectedItemId,
  isItemLoaded,
  totalCount,
  loadMoreItems,
  onItemClick = R.F,
}: AvailabilityRulesTableProps) => {
  const classes = useTableStyles()
  const { t } = useTranslation(['Admin', 'Common'])

  const data = {
    onClick: onItemClick,
    rowFocused: selectedItemId,
  }
  const isExpanded = !R.isNil(selectedItemId)

  return (
    <Grid
      container
      className={classes.mainContainer}
      direction="column"
      wrap="nowrap"
    >
      <Hidden mdDown>
        <Grid container pb={0.5} px={3}>
          <Grid
            container
            item
            alignItems="center"
            className={classes.noOverflow}
          >
            <Grid
              container
              item
              className={classNames({
                [classes.expandedMainColumn]: !isExpanded,
              })}
              md={isExpanded ? 12 : 4}
              wrap="nowrap"
            >
              <Grid item md>
                <Text noWrap strong align="left" variant="lowAccent2">
                  {t('Common:NAME')}
                </Text>
              </Grid>
            </Grid>
            <Grid
              container
              item
              className={classNames({
                [classes.collapsedColumn]: isExpanded,
                [classes.expandedColumn]: !isExpanded,
              })}
              md={8}
              wrap="nowrap"
            >
              <Grid item md={3}>
                <Text noWrap strong align="left" variant="lowAccent2">
                  {t('Common:TIME_COLUMN')}
                </Text>
              </Grid>
              <Grid item md={3}>
                <Text noWrap strong align="left" variant="lowAccent2">
                  {t(
                    'Admin:SCHEDULING.AVAILABILITY_RULES_TABLE.TABLE_HEADER_REPEAT',
                  )}
                </Text>
              </Grid>
              <Grid item md={3}>
                <Text noWrap strong align="left" variant="lowAccent2">
                  {t(
                    'Admin:SCHEDULING.AVAILABILITY_RULES_TABLE.TABLE_HEADER_RULE_START',
                  )}
                </Text>
              </Grid>
              <Grid item md={3}>
                <Text noWrap strong align="left" variant="lowAccent2">
                  {t(
                    'Admin:SCHEDULING.AVAILABILITY_RULES_TABLE.TABLE_HEADER_RULE_END',
                  )}
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <InfiniteLoaderList
        isItemLoaded={isItemLoaded}
        itemCount={totalCount}
        itemData={availabilityRules}
        loadMoreItems={loadMoreItems}
      >
        {(item) => <AvailabilityRulesTableRowWrapper data={data} item={item} />}
      </InfiniteLoaderList>
    </Grid>
  )
}

export default AvailabilityRulesTable
