import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTextArea, PuiTheme, Text, useFields } from '@pbt/pbt-ui-components'

import SpaceSelect from '~/components/common/inputs/SpaceSelect'
import { soapPartialUpdate } from '~/store/actions/soap'
import {
  getPatientId,
  getSOAPisFetching,
  getSoapPrimaryComplaint,
} from '~/store/reducers/soap'
import { Soap } from '~/types'
import useFieldsChanged from '~/utils/useFieldsChanged'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    titleContainer: {
      borderRight: theme.constants.tableBorder,
    },
    textArea: {
      paddingLeft: theme.spacing(0.5),
      scrollbarWidth: 'auto',
      '&::-webkit-scrollbar': {
        display: 'auto',
      },
    },
  }),
  { name: 'SoapInformation' },
)

export interface SoapInformationProps {
  editDisabled?: boolean
}

const SoapInformation = ({ editDisabled }: SoapInformationProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const patientId = useSelector(getPatientId)
  const soapPrimaryComplaint = useSelector(getSoapPrimaryComplaint)
  const isFetching = useSelector(getSOAPisFetching)
  const { t } = useTranslation('Common')

  const { fields, reset, validate } = useFields(
    [
      {
        name: 'assignedSpace',
        initialValue: '',
      },
      {
        name: 'primaryComplaint',
        label: t('Common:REASON_FOR_VISIT'),
        initialValue: soapPrimaryComplaint || '',
      },
    ],
    false,
  )

  const { assignedSpace, primaryComplaint } = fields

  useEffect(() => {
    if (!isFetching) {
      reset()
    }
  }, [isFetching])

  useFieldsChanged((changedFields) => {
    if (!validate()) {
      return
    }
    const data = changedFields.reduce((diff, { name, value }) => {
      diff[name as keyof Soap] = value
      return diff
    }, {} as Partial<Soap>)
    dispatch(soapPartialUpdate(data))
  }, fields)

  return (
    <Grid container item className={classes.root} md={12} wrap="nowrap">
      <Grid item className={classes.titleContainer} p={2} xs={2}>
        <Text mb={2} variant="h4">
          {t('Common:INFORMATION')}
        </Text>
      </Grid>
      <Grid container item xs direction="column" p={2} wrap="nowrap">
        <Grid container item direction="column">
          <Text strong variant="subheading3">
            {primaryComplaint.label}
          </Text>
          <PuiTextArea
            multiline
            InputProps={{
              inputProps: {
                className: classes.textArea,
              },
            }}
            disabled={editDisabled}
            field={primaryComplaint}
            id="primary-complaint-input"
            maxRows={6}
            minRows={1}
            variant="outlined"
          />
        </Grid>
        <Grid item width={240}>
          <SpaceSelect
            disabled={editDisabled}
            field={assignedSpace}
            margin="normal"
            patientId={patientId}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SoapInformation
