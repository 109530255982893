import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Fab, Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { parse, stringify } from 'query-string'
import * as R from 'ramda'
import { PermissionArea, PuiTheme } from '@pbt/pbt-ui-components'

import PuiButtonGroup, {
  PuiButtonGroupItem,
} from '~/components/common/buttons/PuiButtonGroup'
import ListSearchFilterPanel from '~/components/common/lists/ListSearchFilterPanel'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { MembershipFilters } from '~/constants/wellnessPlansConstants'
import {
  getCRUDByArea,
  getCurrentBusinessIsOmniChannel,
  getCurrentBusinessWellnessPlansEnabled,
  getGroupCRUDByArea,
} from '~/store/reducers/auth'
import {
  getClientIsLoading,
  getClientIsSearching,
  getHasNoClients,
  getTotalClientCount,
} from '~/store/reducers/clients'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getUrlSearchParam } from '~/utils'
import { useGetGroupSharingEnabled } from '~/utils/groupSharingUtils'
import useDialog from '~/utils/useDialog'

import {
  GROUP_SEARCH_REQUIRE,
  INCLUDE_INACTIVE_QUERY_KEY,
  ONLY_SHARED_QUERY_KEY,
} from '../header/Search'
import NoClientsScreen from './NoClientsScreen'
import ClientsTableComponent from './table/ClientsTableComponent'

const EXCEPTION_QUERY_KEYS = [
  INCLUDE_INACTIVE_QUERY_KEY,
  ONLY_SHARED_QUERY_KEY,
  GROUP_SEARCH_REQUIRE,
]

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    container: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      height: '100%',
    },
    addButton: {
      minWidth: 160,
      height: 40,
    },
    titleSearchContainer: {
      marginBottom: 0,
      marginTop: 0,
      minHeight: `calc(${theme.constants.tableHeaderHeight}px + ${theme.spacing(
        1,
      )})`,
    },
  }),
  { name: 'ClientsList' },
)

const ClientsList = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const permissions = useSelector(getCRUDByArea(PermissionArea.PATIENT))
  const groupPermissions = useSelector(
    getGroupCRUDByArea(PermissionArea.PATIENT),
  )
  const clientsCount = useSelector(getTotalClientCount)
  const hasNoClients = useSelector(getHasNoClients)
  const isSearching = useSelector(getClientIsSearching)
  const wellnessPlansEnabled = useSelector(
    getCurrentBusinessWellnessPlansEnabled,
  )
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isGroupClientSharingFeatureEnabled = useSelector(
    getFeatureToggle(FeatureToggle.GROUP_CLIENT_SHARING),
  )
  const isCurrentBusinessOmniChannel = useSelector(
    getCurrentBusinessIsOmniChannel,
  )
  const isSuppressAddClientsAndPatientsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SUPPRESS_ADD_CLIENTS_AND_PATIENTS),
  )

  const includeInactive =
    getUrlSearchParam(INCLUDE_INACTIVE_QUERY_KEY, location.search) === 'true'
  const sharedOnly =
    getUrlSearchParam(ONLY_SHARED_QUERY_KEY, location.search) === 'true'
  const groupSearchRequire =
    getUrlSearchParam(GROUP_SEARCH_REQUIRE, location.search) === 'true'
  const isLoading = useSelector(getClientIsLoading)

  const showAddClient = !(
    isCurrentBusinessOmniChannel && isSuppressAddClientsAndPatientsEnabled
  )

  const groupSharingEnabled = useGetGroupSharingEnabled()

  const { t } = useTranslation(['Common', 'Clients'])

  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('sm'),
  )

  const [openNewClientDialog] = useDialog(DialogNames.CLIENT_NEW)

  const MembershipFiltersList = Object.values(MembershipFilters)
  const queryParams = parse(location.search)
  const membersFilterSelected = MembershipFiltersList.some((filter) =>
    R.includes(filter, (queryParams.fieldsQuery as string) || ''),
  )

  const allFalse = R.all(R.F)

  const ClientsFilterStates = [
    {
      label: t('Common:ACTIVE_OTHER'),
      param: {
        [INCLUDE_INACTIVE_QUERY_KEY]: false,
      },
      selected: allFalse([includeInactive, membersFilterSelected, sharedOnly]),
    },
    wellnessPlansEnabled
      ? {
          label: t('Common:MEMBERS'),
          param: {
            fieldsQuery: `${MembershipFilters.MEMBERSHIP_STATUS}=true`,
          },
          selected: membersFilterSelected,
        }
      : undefined,
    {
      label: t('Common:ALL'),
      param: {
        [INCLUDE_INACTIVE_QUERY_KEY]: true,
      },
      selected: includeInactive && !groupSearchRequire,
    },
    (isGroupClientSharingFeatureEnabled || groupSharingEnabled) &&
    !isPatientSharingEnabled
      ? {
          label: t('Common:SHARED'),
          param: {
            [ONLY_SHARED_QUERY_KEY]: true,
          },
          selected: sharedOnly,
        }
      : undefined,
    isPatientSharingEnabled && groupPermissions.read
      ? {
          label: t('Common:GROUP'),
          param: {
            [GROUP_SEARCH_REQUIRE]: !groupSearchRequire,
          },
          selected: groupSearchRequire,
        }
      : undefined,
  ].filter(Boolean) as PuiButtonGroupItem[]

  const addNewClient = () => {
    if (isMobile) {
      navigate('/client/new')
    } else {
      openNewClientDialog()
    }
  }

  const createSearchParams = (params: Record<string, boolean>) =>
    Object.keys({ ...queryParams, ...params }).reduce((acc, key) => {
      if (
        EXCEPTION_QUERY_KEYS.some(
          (exceptionKey) => key === exceptionKey && !params[exceptionKey],
        )
      ) {
        return acc
      }

      if (key === 'fieldsQuery') {
        const fieldsQuery = params.fieldsQuery
          ? queryParams.fieldsQuery
            ? `${queryParams.fieldsQuery};${params.fieldsQuery}`
            : params.fieldsQuery
          : Object.values(MembershipFilters).reduce(
              (innerAcc, filter) =>
                innerAcc.replace(new RegExp(`${filter}=[^;]+;?`, 'g'), ''),
              (queryParams.fieldsQuery as string) || '',
            )

        return fieldsQuery ? { ...acc, fieldsQuery } : acc
      }

      return {
        ...acc,
        [key]: `${params[key] ?? queryParams[key]}`,
      }
    }, {})

  const handleClientFilterStates = (param: Record<string, boolean>) => {
    navigate(`/clients?${stringify(createSearchParams(param))}`)
  }

  const selectedFilter = ClientsFilterStates.find(
    (filterState) => filterState?.selected,
  )

  return hasNoClients && !isSearching ? (
    <NoClientsScreen />
  ) : (
    <Grid
      container
      className={classes.container}
      direction="column"
      wrap="nowrap"
    >
      <Grid
        container
        item
        alignItems="center"
        columnSpacing={2}
        justifyContent="space-between"
      >
        <Grid
          container
          item
          xs
          alignItems="center"
          className={classes.titleSearchContainer}
          columnSpacing={{ xs: 2, md: 6 }}
        >
          <Grid item>
            <PuiButtonGroup
              items={ClientsFilterStates}
              selectedItem={selectedFilter || ClientsFilterStates[0]}
              onItemSelected={({ param }) => handleClientFilterStates(param)}
            />
          </Grid>
          <Grid container item xs>
            <ListSearchFilterPanel
              isLoading={isLoading}
              searchCount={clientsCount}
            />
          </Grid>
        </Grid>
        <Grid item>
          {showAddClient && permissions.create ? (
            <Fab
              className={classes.addButton}
              color="inherit"
              variant="extended"
              onClick={addNewClient}
            >
              {t('Common:ADD_NEW_CLIENT')}
            </Fab>
          ) : (
            <div className={classes.addButton} />
          )}
        </Grid>
      </Grid>
      <ClientsTableComponent
        groupSearchRequire={groupSearchRequire}
        includeInactive={includeInactive}
        sharedOnly={sharedOnly}
      />
    </Grid>
  )
}

export default ClientsList
