import React, { useRef } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, PuiTheme, Text } from '@pbt/pbt-ui-components'

import PuiDataTableColumnFilter from './PuiDataTableColumnFilter'
import { PuiDataTableColumn } from './puiDataTableType'

const scrollbarWidth = window.innerWidth - document.body.clientWidth

export const usePuiDataTableHeaderStyles = makeStyles(
  (theme: PuiTheme) => ({
    dynamicHeadingRow: {
      paddingRight: scrollbarWidth,
    },
    headingRow: {
      borderBottom: theme.constants.tableBorder,
      height: 36,
    },
    large: {
      height: 52,
    },
    headingCell: {
      height: '100%',
    },
    mainColumn: {
      borderRight: theme.constants.tableBorder,
    },
  }),
  { name: 'PuiDataTableHeader' },
)

const DEFAULT_MAIN_COLUMN_WIDTH = 256

export interface PuiDataTableHeaderProps {
  classes?: ClassesType<typeof usePuiDataTableHeaderStyles>
  columns: PuiDataTableColumn[]
  densed?: boolean
  hasScrollSpace?: boolean
  mainColumn?: PuiDataTableColumn
}

const columnHasPercentageWidth = (column?: PuiDataTableColumn) =>
  column?.width && String(column.width).includes('%')

const PuiDataTableHeader = ({
  classes: classesProp,
  columns,
  densed,
  hasScrollSpace,
  mainColumn,
}: PuiDataTableHeaderProps) => {
  const classes = usePuiDataTableHeaderStyles({ classes: classesProp })

  const anchorRef = useRef<HTMLDivElement>(null)

  return (
    <Grid
      container
      alignItems="center"
      className={classNames(classes.headingRow, {
        [classes.large]: !densed,
        // Hack to adjust width value when receiving %, so we remove the scrollbar width
        [classes.dynamicHeadingRow]:
          hasScrollSpace &&
          (columnHasPercentageWidth(mainColumn) ||
            columns.some(columnHasPercentageWidth)),
      })}
      wrap="nowrap"
    >
      {mainColumn && (
        <Grid
          container
          item
          alignItems="center"
          className={classNames(
            classes.headingCell,
            classes.mainColumn,
            mainColumn.classNameHeader,
          )}
          flexShrink={mainColumn.width ? 0 : 1}
          pl={2}
          style={{ width: mainColumn.width || DEFAULT_MAIN_COLUMN_WIDTH }}
        >
          <>
            {mainColumn.filter ? (
              <PuiDataTableColumnFilter
                anchorRef={anchorRef}
                columnLabel={mainColumn.label}
                filter={mainColumn.filter}
              />
            ) : (
              <Dotdotdot clamp={2}>
                <Text disabled strong variant="body2">
                  {mainColumn.label}
                </Text>
              </Dotdotdot>
            )}
          </>
        </Grid>
      )}
      {columns.map((column) => {
        const { classNameHeader, label, width, filter } = column

        const props = {
          className: classNames(classes.headingCell, classNameHeader),
          style: width ? { width } : undefined,
          xs: width ? undefined : true,
        }

        return (
          <Grid
            container
            item
            alignItems="center"
            flexShrink={width ? 0 : 1}
            justifyContent="space-between"
            key={`header-${label}`}
            pl={2}
            wrap="nowrap"
            {...props}
          >
            {filter ? (
              <PuiDataTableColumnFilter
                anchorRef={anchorRef}
                columnLabel={label}
                filter={filter}
              />
            ) : (
              <Dotdotdot clamp={2}>
                <Text disabled strong variant="body2" whiteSpace="nowrap">
                  {label}
                </Text>
              </Dotdotdot>
            )}
          </Grid>
        )
      })}
    </Grid>
  )
}

export default PuiDataTableHeader
