import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Decline as DeclineIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Eye as EyeIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { PrescriptionType } from '~/constants/prescription'
import { OrderType } from '~/constants/SOAPStates'
import { declineActiveRx } from '~/store/duck/prescriptions'
import { useOpenPrescriptionDialogAfterFetchUnifiedOrder } from '~/store/hooks/orders'
import {
  useDeletePrescription,
  useGetPrescriptionStateType,
  useIsDeclinableOrDeletable,
} from '~/store/hooks/prescription'
import { getOrdersIsSending, getSelectedOrder } from '~/store/reducers/orders'
import { PrescriptionV2 } from '~/types'

import { useDeclineOrderPrescriptionItem } from '../../charges/utils/useDeclineOrderItem'
import { FinalizeOrdersActionButton } from '../actions/FinalizeOrdersActionButton'

export interface FinalizeChewyActiveRxActionsProps {
  context: 'Charge-sheet' | 'SOAP'
  disabled: boolean
  prescription: PrescriptionV2
  soapId?: string
  stateId: string
}

export const FinalizeChewyActiveRxActions = ({
  context,
  disabled: disabledProp,
  prescription,
  stateId,
}: FinalizeChewyActiveRxActionsProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const {
    id: prescriptionId,
    name: prescriptionName,
    origin,
    soapId,
    state,
  } = prescription
  const orderType = OrderType.PRESCRIPTION
  const isFromChargeSheet = context === 'Charge-sheet'

  const order = useSelector(getSelectedOrder(orderType, prescriptionId))

  const { isApproved, isDraft, isSubmitted } =
    useGetPrescriptionStateType()(stateId)
  const isDeclinableOrDeletable = useIsDeclinableOrDeletable({
    orderType,
    origin,
    prescriptionType: PrescriptionType.CHEWY,
    stateId: state?.id,
  })

  const handleDeclineOrder = useDeclineOrderPrescriptionItem({
    id: prescriptionId,
    prescriptionType: PrescriptionType.CHEWY,
    stateId: state?.id,
    inventoryLogStatusId: order?.inventoryLogStatusId,
    origin,
  })

  const handleDecline = () => {
    if (context === 'SOAP') {
      handleDeclineOrder()
    } else {
      dispatch(declineActiveRx({ prescriptionId }))
    }
  }

  const handleDelete = useDeletePrescription({
    hasRefills: false,
    name: prescriptionName ?? order?.name,
    prescriptionId,
    isChewyActiveRx: true,
  })

  const { fetchUnifiedOrderAndOpenPrescription } =
    useOpenPrescriptionDialogAfterFetchUnifiedOrder({
      overrideParams: {
        outsideSoap: isFromChargeSheet,
        skipOrderFetching: false,
        soapId,
      },
      prescriptionDialogProps: {
        selector: getOrdersIsSending,
      },
    })

  const openPrescription = () => {
    fetchUnifiedOrderAndOpenPrescription(prescriptionId)
  }

  const declineLabel = t('Common:CLIENT_DECLINE')
  const disabled = disabledProp || !isDeclinableOrDeletable

  return (
    <>
      {isApproved && (
        <>
          <FinalizeOrdersActionButton
            handler={openPrescription}
            icon={<EyeIcon />}
            text={t('Common:VIEW_ACTION')}
          />
          <FinalizeOrdersActionButton
            disabled={disabled}
            handler={handleDecline}
            icon={<DeclineIcon />}
            text={declineLabel}
          />
          <FinalizeOrdersActionButton
            disabled={disabled}
            handler={handleDelete}
            icon={<DeleteIcon />}
            text={t('Common:DELETE_ACTION')}
          />
        </>
      )}
      {isDraft && (
        <>
          <FinalizeOrdersActionButton
            disabled={disabled}
            handler={handleDecline}
            icon={<DeclineIcon />}
            text={declineLabel}
          />
          <FinalizeOrdersActionButton
            disabled={disabled}
            handler={handleDelete}
            icon={<DeleteIcon />}
            text={t('Common:DELETE_ACTION')}
          />
          <FinalizeOrdersActionButton
            handler={openPrescription}
            icon={<EditIcon />}
            text={t('Common:EDIT_ACTION')}
          />
        </>
      )}
      {isSubmitted && (
        <>
          <FinalizeOrdersActionButton
            handler={openPrescription}
            icon={<EyeIcon />}
            text={t('Common:VIEW_PRESCRIPTION')}
          />
        </>
      )}
    </>
  )
}
