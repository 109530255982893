import React, { MouseEvent } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Nil, PuiTheme, Text, TextVariant } from '@pbt/pbt-ui-components'
import { WarningRound as WarningRoundIcon } from '@pbt/pbt-ui-components/src/icons'

import DialogNames from '~/constants/DialogNames'
import { fetchTask } from '~/store/actions/tasks'
import { getTask } from '~/store/reducers/tasks'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    warningIcon: {
      width: '2.4rem',
      height: '2.4rem',
      color: theme.colors.important,
    },
  }),
  { name: 'RxRequestUnlinkedLabel' },
)

export interface RxRequestUnlinkedLabelProps {
  showWarningIcon?: boolean
  taskId: string | Nil
  textVariant?: keyof typeof TextVariant
}

const RxRequestUnlinkedLabel = ({
  showWarningIcon = false,
  taskId,
  textVariant = 'body',
}: RxRequestUnlinkedLabelProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const task = useSelector(getTask(taskId))

  const [openAddClientPatientToRxRequestDialog] = useDialog(
    DialogNames.ADD_CLIENT_PATIENT_TO_RX_REQUEST,
  )

  const handleRefetchTask = () => {
    if (taskId) {
      dispatch(fetchTask(taskId))
    }
  }

  const handleChoosePatient = (event: MouseEvent<any>) => {
    event.stopPropagation()
    openAddClientPatientToRxRequestDialog({
      onAssign: handleRefetchTask,
      prescriptionId: task?.prescriptionId,
      taskId: task?.id,
    })
  }

  return (
    <Grid container alignItems="center" gap={1} wrap="nowrap">
      {showWarningIcon && <WarningRoundIcon className={classes.warningIcon} />}
      <Grid container columnSpacing={0.5}>
        <Grid item>
          <Dotdotdot clamp={1}>
            <Text variant={textVariant}>{t('Common:UNASSIGNED')}</Text>
          </Dotdotdot>
        </Grid>
        <Grid item>
          <Dotdotdot clamp={1}>
            <Text link variant={textVariant} onClick={handleChoosePatient}>
              {t('Common:CHOOSE_A_PATIENT')}
            </Text>
          </Dotdotdot>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default RxRequestUnlinkedLabel
