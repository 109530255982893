import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, GridDirection } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ClassesType,
  PermissionArea,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import { getCRUDByArea } from '~/store/reducers/auth'
import { getClientIsLoading } from '~/store/reducers/clients'
import { getCoparentsIsLoading } from '~/store/reducers/coparents'
import { getPatientsIsLoading } from '~/store/reducers/patients'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import InlineEditPopper, { InlineEditPopperProps } from './InlineEditPopper'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    rootBorder: {
      borderBottom: theme.constants.tableBorder,
    },
    label: {
      lineHeight: '1.4rem',
      minWidth: 76,
    },
    labelLong: {
      marginRight: 0,
      minWidth: 'unset',
      marginTop: theme.spacing(1.5),
    },
    value: {
      wordBreak: 'break-word',
      lineHeight: '1.6rem',
    },
    valueLong: {
      padding: theme.spacing(1, 0),
      minHeight: 30,
    },
    editable: {
      cursor: 'pointer',
      width: '100%',
    },
  }),
  { name: 'ClientAndPatientDetailsRow' },
)

const LoadingStateSelectorsMap = {
  client: getClientIsLoading,
  patient: getPatientsIsLoading,
  petFriends: getCoparentsIsLoading,
}

export interface ClientAndPatientDetailsRowProps
  extends Pick<
    InlineEditPopperProps,
    'fieldProps' | 'inputProps' | 'inputType'
  > {
  classes?: ClassesType<typeof useStyles>
  containerDirection?: Extract<GridDirection, 'row' | 'column'>
  displayValue?: string
  entityType?: keyof typeof LoadingStateSelectorsMap
  label?: string
  long?: boolean
  onEdit?: (arg: any) => void
  placeholder?: string
  showBorder?: boolean
  subContent?: React.ReactNode
  value?: string | number
}

const ClientAndPatientDetailsRow = ({
  classes: classesProp,
  label,
  value,
  displayValue,
  showBorder = true,
  subContent,
  placeholder,
  inputType,
  inputProps = {},
  fieldProps,
  onEdit,
  containerDirection = 'column',
  entityType = 'patient',
  long = false,
}: ClientAndPatientDetailsRowProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')
  const clientAndPatientDetailsRowPlaceholder =
    placeholder || t('Common:CLICK_TO_EDIT')

  const { update: patientUpdatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.PATIENT),
  )

  const noValue = !value && value !== 0
  const editable = Boolean(onEdit) && patientUpdatePermissions

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const selector = LoadingStateSelectorsMap[entityType]

  const setLoadingToFalseAfterUpdate = useCloseAfterCreation(() => {
    setIsLoading(false)
    setAnchorEl(null)
  }, selector)

  const update = (newValue: any) => {
    if (onEdit) {
      onEdit(newValue)
    }
    setIsLoading(true)
    setLoadingToFalseAfterUpdate()
  }

  return (
    <Grid
      container
      alignItems="flex-start"
      className={classNames(classes.root, {
        [classes.rootBorder]: showBorder,
      })}
      direction={long ? 'column' : 'row'}
      py={long ? 0 : 1}
      wrap="nowrap"
    >
      <Text
        strong
        className={classNames(classes.label, { [classes.labelLong]: long })}
        mr={1}
        variant="body3"
      >
        {label}
      </Text>
      <Grid
        container
        item
        direction={containerDirection}
        style={{ position: 'relative' }}
        wrap={containerDirection === 'row' ? 'nowrap' : 'wrap'}
        onClick={({ currentTarget }) =>
          editable ? setAnchorEl(currentTarget) : undefined
        }
      >
        <Text
          className={classNames(classes.value, {
            [classes.editable]: editable,
            [classes.valueLong]: long,
          })}
          variant={noValue ? 'lowAccent2' : 'body2'}
        >
          {displayValue ||
            value ||
            (editable ? clientAndPatientDetailsRowPlaceholder : '')}
        </Text>
        {subContent}
        <InlineEditPopper
          anchorEl={anchorEl}
          fieldProps={fieldProps}
          inputProps={inputProps}
          inputType={inputType}
          isLoading={isLoading}
          offset={{ top: long ? 10 : 0 }}
          placement="right"
          onClose={() => setAnchorEl(null)}
          onSave={update}
        />
      </Grid>
    </Grid>
  )
}

export default ClientAndPatientDetailsRow
