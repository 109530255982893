import React, { forwardRef, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AddButton,
  InfiniteLoaderList,
  PermissionArea,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import { fetchTeamsList } from '~/store/actions/members'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import { getTeamsList } from '~/store/reducers/members'
import { getMultipleUsers } from '~/store/reducers/users'
import { BasePracticeDetailsSectionProps } from '~/types'

import TeamConfigurationAdd from './TeamConfigurationAdd'
import TeamConfigurationItem from './TeamConfigurationItem'

const TEAM_HEADER_HEIGHT = 30
const TEAM_ITEM_HEIGHT = 40
const COUNT_VISIBLE_TEAMS = 10
const MAX_TEAM_LIST_HEIGHT = COUNT_VISIBLE_TEAMS * TEAM_ITEM_HEIGHT

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    table: {
      border: theme.constants.tableBorder,
    },
    tableHeader: {
      height: TEAM_HEADER_HEIGHT,
      borderBottom: theme.constants.tableBorder,
    },
    tableAdd: {
      height: TEAM_ITEM_HEIGHT,
    },
    tableItem: {
      height: TEAM_ITEM_HEIGHT,
    },
    tableLastItem: {
      height: TEAM_ITEM_HEIGHT - 1,
      borderBottom: 0,
    },
    addItem: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1.5),
    },
  }),
  { name: 'TeamConfigurationSection' },
)

const TeamConfigurationSection = forwardRef<
  HTMLDivElement,
  BasePracticeDetailsSectionProps
>(function TeamConfigurationSection({ business }, ref) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.PERSON, business),
  )
  const teamsList = useSelector(getTeamsList)
  const teams = useSelector(getMultipleUsers(teamsList))

  const [addTeamEnabled, setAddTeamEnabled] = useState(false)

  const businessId = business?.id

  const teamListHeightByItemLength =
    teams?.length && teams.length * TEAM_ITEM_HEIGHT
  const teamListHeight = Math.min(
    MAX_TEAM_LIST_HEIGHT,
    teamListHeightByItemLength,
  )

  useEffect(() => {
    if (businessId) {
      dispatch(fetchTeamsList({ businessId, includeInactive: true }))
    }
  }, [businessId])

  return (
    <Grid className={classes.root} ref={ref}>
      <Grid className={classes.table}>
        <Grid container item className={classes.tableHeader} wrap="nowrap">
          <Grid container item alignItems="center" px={1.5} xs={2}>
            <Text strong variant="lowAccent2">
              {t('Common:ACTIVE_ONE')}
            </Text>
          </Grid>
          <Grid container item alignItems="center" px={1.5} xs={3}>
            <Text strong variant="lowAccent2">
              {t('Common:TEAM_NAME')}
            </Text>
          </Grid>
          <Grid container item alignItems="center" px={1.5} xs={5}>
            <Text strong variant="lowAccent2">
              {t('Common:ROLE')}
            </Text>
          </Grid>
          <Grid container item px={1.5} xs={3} />
        </Grid>
        <InfiniteLoaderList
          isItemLoaded={R.F}
          itemCount={teams.length}
          itemData={teams}
          itemSpacing={0}
          loadMoreItems={R.F}
          style={{ height: teamListHeight }}
        >
          {(team = {}, index) => (
            <TeamConfigurationItem
              businessId={businessId}
              className={classNames(classes.tableItem, {
                [classes.tableLastItem]: index === teams.length - 1,
              })}
              editable={permissions.update}
              key={team.id}
              team={team}
            />
          )}
        </InfiniteLoaderList>
        {permissions.update && addTeamEnabled && (
          <TeamConfigurationAdd
            businessId={businessId}
            className={classes.tableAdd}
            onDiscard={() => setAddTeamEnabled(false)}
          />
        )}
      </Grid>
      {permissions.update && !addTeamEnabled && (
        <AddButton
          addText={t('Common:ADD_TEAM')}
          classes={{ addItem: classes.addItem }}
          onAdd={() => setAddTeamEnabled(true)}
        />
      )}
    </Grid>
  )
})

export default memo(TeamConfigurationSection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
