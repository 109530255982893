import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Fab } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { PuiTheme } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { InventoryItemState, OrderType } from '~/constants/SOAPStates'
import { partialEditOrder } from '~/store/actions/orders'
import { editChargeSheetOrder } from '~/store/duck/clientFinanceData'
import { useGetInventoryStateId, useOrderStateId } from '~/store/hooks/orders'
import { getFeatureToggle } from '~/store/reducers/constants'
import { InvoiceLineItem, Order } from '~/types'
import { getOrderStateKey, OrderStateKeys } from '~/utils/orderUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    fab: {
      height: 33,
      width: '100%',
      fontSize: 12,
      fontWeight: 500,
      padding: theme.spacing(0, 1),
    },
    grid: {
      width: 'calc(100% + 20px)',
      marginLeft: -20,
    },
    fabSelected: {
      '&&&&:hover': {
        backgroundColor: theme.colors.selectedOption,
      },
      backgroundColor: theme.colors.selectedOption,
      color: theme.colors.primaryText,
    },
    statusSelect: {
      width: 'fit-content',
      fontSize: '1rem',
    },
  }),
  { name: 'PrescriptionItemDispenseButton' },
)

export interface PrescriptionItemDispenseButtonProps {
  disabled?: boolean
  order: Order | InvoiceLineItem
}

const PrescriptionItemDispenseButton = ({
  order,
  disabled,
}: PrescriptionItemDispenseButtonProps) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const { t } = useTranslation('Common')

  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )

  const getInventoryStateId = useGetInventoryStateId()

  const stateKey = R.has(OrderStateKeys.INVENTORY_LOG_STATUS, order)
    ? getOrderStateKey(order.prescriptionType)
    : OrderStateKeys.STATE

  const stateId = useOrderStateId(order)
  const isDispensed =
    stateId === getInventoryStateId(InventoryItemState.DISPENSED)

  const onDispense = () => {
    const stateValue =
      getInventoryStateId(InventoryItemState.DISPENSED) === stateId
        ? getInventoryStateId(InventoryItemState.ORDERED)
        : getInventoryStateId(InventoryItemState.DISPENSED)
    const { lineItem } = order as Order
    const logId = R.prop('logId', lineItem || order)
    const soapLogModificationDate =
      R.prop('soapLogModificationDate', order) ||
      R.prop('modificationDate', order)

    if (isChargeSheetEnabled && soapLogModificationDate && logId) {
      dispatch(
        editChargeSheetOrder({
          id: logId,
          type: OrderType.PRESCRIPTION,
          order: {
            stateId: stateValue,
          },
          soapLogModificationDate,
        }),
      )
    } else {
      dispatch(
        partialEditOrder({
          id: order.id,
          type: order.type as OrderType,
          [stateKey]: stateValue,
        }),
      )
    }
  }

  return (
    <Fab
      className={classNames(classes.fab, {
        [classes.fabSelected]: isDispensed,
      })}
      color="primary"
      disabled={disabled}
      type="button"
      variant="extended"
      onClick={onDispense}
    >
      {isDispensed ? t('Common:DISPENSED') : t('Common:DISPENSE_ACTION')}
    </Fab>
  )
}

export default PrescriptionItemDispenseButton
