import React from 'react'
import { makeStyles } from '@mui/styles'
import { Field } from '@pbt/pbt-ui-components'

import { InvoiceLineItem } from '~/types'

import ChargeDetailsCurrencyField from './ChargeDetailsCurrencyField'

const useStyles = makeStyles(
  () => ({
    text: {
      fontWeight: 500,
    },
  }),
  { name: 'ChargeFieldSubtotal' },
)

export interface ChargeFieldSubtotalProps {
  className?: string
  inputClassName?: string
  item: InvoiceLineItem
  itemSubtotalField: Field
}

const ChargeFieldSubtotal = ({
  className: classNameProp,
  inputClassName,
  item,
  itemSubtotalField,
}: ChargeFieldSubtotalProps) => {
  const classes = useStyles()

  return (
    <ChargeDetailsCurrencyField
      className={classNameProp}
      disabled={false}
      field={itemSubtotalField}
      inputClassName={inputClassName}
      item={item}
      wrapperClassName={classes.text}
    />
  )
}

export default ChargeFieldSubtotal
