import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AddButton,
  ClassesType,
  CopyToClipboard,
  LinkButton,
  Nil,
  PuiCheckbox,
  PuiSelect,
  PuiTextField,
  PuiTheme,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

import DialogNames from '~/constants/DialogNames'
import EmailAppointmentFlow from '~/constants/EmailAppointmentFlow'
import {
  createZoomLink as createZoomLinkAction,
  deleteZoomLink,
  fetchZoomUsers,
  getIsZoomLinkCreating,
  getIsZoomUsersLoading,
  getZoomUsers,
} from '~/store/duck/conferencing'
import { useGetIsZoomEnabled } from '~/store/hooks/conferencing'
import { ConferencingData, TimetableEvent } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import useFieldsChanged, { FieldCache } from '~/utils/useFieldsChanged'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    conferencingContainer: {
      border: theme.constants.tableBorder,
      borderRadius: 2,
    },
    deleteIconButton: {
      padding: theme.spacing(0.5),
    },
    zoomLinkInnerContainer: {
      border: theme.constants.tableBorder,
      borderRadius: 2,
    },
    zoomLinkButtonContainer: {
      border: theme.constants.tableBorder,
      borderRadius: 2,
    },
    zoomDeleteIconButton: {
      padding: theme.spacing(0.5),
      marginLeft: 'auto',
      alignSelf: 'flex-start',
      margin: theme.spacing(-0.5, -0.5, 0, 0),
    },
    zoomUserSelect: {
      width: 216,
      margin: theme.spacing(2, 0, 0, 1),
    },
    zoomUserSelectWithCheckBox: {
      margin: theme.spacing(0, 0, 0, 4),
    },
    checkbox: {
      padding: theme.spacing(1),
      marginLeft: theme.spacing(-0.5),
    },
    checkboxLabel: {
      marginLeft: theme.spacing(-0.5),
    },
    meetingDetails: {
      borderTop: theme.constants.tableBorder,
      padding: theme.spacing(0.5, 1),
      color: theme.colors.secondaryText,
    },
    meetingCollapse: {
      width: '100%',
      overflowX: 'auto',
    },
    expandButton: {
      color: theme.colors.link,
      display: 'flex',
      alignItems: 'center',
    },
    meetingNotes: {
      whiteSpace: 'pre',
    },
  }),
  { name: 'Conferencing' },
)

interface ConferencingProps {
  appointment: TimetableEvent | Nil
  classes?: ClassesType<typeof useStyles>
  deleteDisabled?: boolean
  editDisabled?: boolean
  expandZoomDetailsLabel?: string
  onFieldsChange?: (changedFields: FieldCache) => void
  onFocusedFieldChange?: (value: boolean) => void
  showUserSelect?: boolean
  title?: string
}

const Conferencing = forwardRef(function Conferencing(
  {
    appointment,
    editDisabled,
    deleteDisabled,
    showUserSelect = false,
    onFocusedFieldChange = R.F,
    classes: classesProp,
    title,
    expandZoomDetailsLabel,
    onFieldsChange = R.F,
  }: ConferencingProps,
  ref,
) {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Soap'])
  const conferencingTitle = title || t('Soap:CONFERENCING.TITLE')
  const conferencingExpandZoomDetailsLabel =
    expandZoomDetailsLabel || t('Common:DETAILS').toLowerCase()

  const zoomUsers = useSelector(getZoomUsers)
  const isZoomLinkCreating = useSelector(getIsZoomLinkCreating)
  const isZoomUsersLoading = useSelector(getIsZoomUsersLoading)

  const isZoomEnabled = useGetIsZoomEnabled()

  const [openEmailAppointmentDialog] = useDialog(DialogNames.EMAIL_APPOINTMENT)

  const { fields, validate, reset } = useFields(
    [
      { name: 'meetingLink', initialValue: appointment?.meetingLink || '' },
      { name: 'dialIn', initialValue: appointment?.dialIn || '' },
      { name: 'meetingNotes', initialValue: appointment?.meetingNotes || '' },
      {
        name: 'includeZoomLink',
        type: 'toggle',
        initialValue: Boolean(appointment?.includeZoomLink),
      },
      { name: 'zoomUser', type: 'select', initialValue: zoomUsers[0]?.id },
    ],
    false,
  )

  const { meetingLink, dialIn, meetingNotes, includeZoomLink, zoomUser } =
    fields

  useFieldsChanged(onFieldsChange, fields)

  useEffect(() => {
    reset()
  }, [appointment])

  useEffect(() => {
    const shouldFetchForCurrentAppointmentFlow =
      appointment?.id || includeZoomLink.value

    if (
      isZoomEnabled &&
      !zoomUsers.length &&
      !isZoomUsersLoading &&
      shouldFetchForCurrentAppointmentFlow
    ) {
      dispatch(fetchZoomUsers())
    }
  }, [includeZoomLink.value, isZoomEnabled])

  useEffect(() => {
    if (!zoomUser.value && zoomUsers.length) {
      zoomUser.setValue(zoomUsers[0].id)
    }
  }, [zoomUsers.length])

  const zoomUsersList = zoomUsers.map((person) => ({
    id: person.id,
    name: Utils.getPersonString(person),
  }))

  const [showConferencing, setShowConferencing] = useState(
    meetingLink.value || dialIn.value,
  )
  const [showZoomLink, setShowZoomLink] = useState(meetingLink.value)
  const [zoomMeetingDetailsExpanded, setZoomMeetingDetailsExpanded] =
    useState(false)

  const showZoomLinkAfterCreation = useCloseAfterCreation(
    () => setShowZoomLink(true),
    getIsZoomLinkCreating,
  )
  const hideZoomLinkAfterDelete = useCloseAfterCreation(
    () => setShowZoomLink(false),
    getIsZoomLinkCreating,
  )

  const [openChooseUserDialog] = useDialog(DialogNames.CHOOSE_ZOOM_USER_DIALOG)

  const handleOpenZoomMeeting = () => {
    Utils.openInNewTab({ href: Utils.toExternalUrl(meetingLink.value) })
  }

  const handleSendZoomLink = () => {
    openEmailAppointmentDialog({
      appointmentId: appointment?.id,
      appointment,
      flow: EmailAppointmentFlow.ZOOM_LINK,
    })
  }

  const clearMeetingInfo = () => {
    if (appointment?.id) {
      dispatch(deleteZoomLink(appointment.id))
      hideZoomLinkAfterDelete()
    }
  }

  const createZoomLink = (zoomUserId: string) => {
    if (appointment?.id) {
      dispatch(createZoomLinkAction(appointment.id, zoomUserId))
      showZoomLinkAfterCreation()
    }
  }

  const handleAddZoomLink = () => {
    if (zoomUsers.length === 1 || showUserSelect) {
      createZoomLink(zoomUser.value)
    } else {
      openChooseUserDialog({
        onOk: createZoomLink,
      })
    }
  }

  useImperativeHandle(ref, () => ({
    validate,
    get: () => {
      const data: ConferencingData = {
        meetingLink: meetingLink.value,
        meetingNotes: meetingNotes.value,
        dialIn: dialIn.value,
        includeZoomLink: includeZoomLink.value,
      }

      if (includeZoomLink.value) {
        data.zoomUserId = zoomUser.value
      }

      return data
    },
  }))

  return (
    <>
      {!isZoomEnabled && (
        <Grid
          container
          item
          alignItems="flex-start"
          className={classes.conferencingContainer}
          mt={1}
          p={1}
        >
          {showConferencing ? (
            <Grid
              container
              item
              alignItems="center"
              columnSpacing={2}
              wrap="nowrap"
            >
              <Grid item xs>
                <PuiTextField
                  disabled={editDisabled}
                  field={meetingLink}
                  label={t('Soap:CONFERENCING.MEETING_LINK')}
                  onBlur={() => onFocusedFieldChange(false)}
                  onFocus={() => onFocusedFieldChange(true)}
                />
              </Grid>
              <Grid item xs>
                <PuiTextField
                  disabled={editDisabled}
                  field={dialIn}
                  label={t('Soap:CONFERENCING.DIAL_IN')}
                  onBlur={() => onFocusedFieldChange(false)}
                  onFocus={() => onFocusedFieldChange(true)}
                />
              </Grid>
              {!deleteDisabled && (
                <IconButton
                  aria-label={t('Common:DELETE_ACTION')}
                  className={classes.deleteIconButton}
                  disabled={editDisabled}
                  size="large"
                  onClick={() => {
                    setShowConferencing(false)
                    meetingLink.setValue('')
                    dialIn.setValue('')
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
          ) : (
            <AddButton
              addText={t('Common:ADD_CONFERENCING')}
              disabled={editDisabled}
              onAdd={() => setShowConferencing(true)}
            />
          )}
        </Grid>
      )}
      {isZoomEnabled && (
        <Grid container item alignItems="flex-start">
          {showZoomLink ? (
            <Grid container direction="column">
              <Grid container mb={1} mt={2.5}>
                <Text strong variant="subheading3">
                  {conferencingTitle}
                </Text>
              </Grid>
              <Grid
                container
                className={classes.zoomLinkInnerContainer}
                direction="column"
              >
                <Grid container pt={0.5} px={1}>
                  <Link
                    href={Utils.toExternalUrl(meetingLink.value)}
                    target="_blank"
                  >
                    {meetingLink.value}
                  </Link>
                  {!deleteDisabled && (
                    <IconButton
                      aria-label={t('Common:DELETE_ACTION')}
                      className={classes.zoomDeleteIconButton}
                      disabled={editDisabled}
                      size="large"
                      onClick={clearMeetingInfo}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid>
                <Grid container columnSpacing={2} p={1}>
                  <Grid item>
                    <LinkButton onClick={handleOpenZoomMeeting}>
                      {t('Soap:CONFERENCING.LAUNCH_ZOOM_MEETING')}
                    </LinkButton>
                  </Grid>
                  <Grid item>
                    <CopyToClipboard text={meetingLink.value} />
                  </Grid>
                  <Grid item>
                    <LinkButton onClick={handleSendZoomLink}>
                      {t('Soap:CONFERENCING.EMAIL_TEXT_LINK')}
                    </LinkButton>
                  </Grid>
                </Grid>
                <Grid container item alignItems="center" mt={1} px={1}>
                  <Text strong mr={2} variant="body2">
                    {t('Soap:CONFERENCING.DIAL_IN_INFO')}
                  </Text>
                  <Text variant="body2">{dialIn.value}</Text>
                </Grid>
                {meetingNotes.value && (
                  <Grid
                    container
                    className={classes.meetingDetails}
                    direction="column"
                  >
                    <Text
                      link
                      strong
                      className={classes.expandButton}
                      variant="body2"
                      onClick={() =>
                        setZoomMeetingDetailsExpanded(
                          !zoomMeetingDetailsExpanded,
                        )
                      }
                    >
                      {`${
                        zoomMeetingDetailsExpanded
                          ? t('Common:HIDE_ACTION')
                          : t('Common:EXPAND_ACTION')
                      } ${conferencingExpandZoomDetailsLabel}`}
                      &nbsp;
                      {zoomMeetingDetailsExpanded ? (
                        <KeyboardArrowUp />
                      ) : (
                        <KeyboardArrowDown />
                      )}
                    </Text>
                    <Collapse
                      className={classes.meetingCollapse}
                      in={zoomMeetingDetailsExpanded}
                    >
                      <Text className={classes.meetingNotes} variant="body2">
                        {meetingNotes.value}
                      </Text>
                    </Collapse>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              className={classes.zoomLinkButtonContainer}
              direction="column"
              mt={1}
              p={1}
            >
              {appointment?.id ? (
                <AddButton
                  addText={t('Common:ADD_ZOOM_LINK')}
                  disabled={editDisabled || isZoomLinkCreating}
                  onAdd={handleAddZoomLink}
                />
              ) : (
                <PuiCheckbox
                  checkboxClasses={{ root: classes.checkbox }}
                  classes={{ labelRoot: classes.checkboxLabel }}
                  disabled={editDisabled}
                  field={includeZoomLink}
                  label={t('Soap:CONFERENCING.INCLUDE_ZOOM_LINK')}
                />
              )}
              {zoomUsers.length > 1 &&
                showUserSelect &&
                includeZoomLink.value && (
                  <FormControl
                    className={classNames(classes.zoomUserSelect, {
                      [classes.zoomUserSelectWithCheckBox]: !appointment?.id,
                    })}
                    margin="none"
                  >
                    <InputLabel>{t('Common:ASSIGN_ZOOM_USER')}</InputLabel>
                    <PuiSelect
                      disabled={editDisabled || isZoomLinkCreating}
                      field={zoomUser}
                      items={zoomUsersList}
                      renderEmpty={false}
                    />
                  </FormControl>
                )}
            </Grid>
          )}
        </Grid>
      )}
    </>
  )
})

export default Conferencing
