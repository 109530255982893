import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { BillingAddress, Nil, Utils } from '@pbt/pbt-ui-components'

import { FAILED } from '~/constants/transactionStatus'
import { ExtendPayment, TransactionData } from '~/types'
import { secondLevelMerge } from '~/utils'
import { getErrorMessage, getServerValidationError } from '~/utils/errors'

import {
  APPLY_PAYMENT_TO_INVOICE,
  APPLY_PAYMENT_TO_INVOICE_FAILURE,
  APPLY_PAYMENT_TO_INVOICE_SUCCESS,
  CHARGE_PAYMENT,
  CHARGE_PAYMENT_FAILURE,
  CHARGE_PAYMENT_SUCCESS,
  CLEAR_CLIENT_BILLING_ADDRESSES,
  CLEAR_PROCESSED_BILLING_ADDRESS,
  CLEAR_RHAPSODY_GO_ERROR,
  CLEAR_TRANSACTION_INFO,
  CREATE_BILLING_ADDRESS,
  CREATE_BILLING_ADDRESS_FAILURE,
  CREATE_BILLING_ADDRESS_SUCCESS,
  CREATE_CREDIT_ADJUSTMENT,
  CREATE_CREDIT_ADJUSTMENT_FAILURE,
  CREATE_CREDIT_ADJUSTMENT_SUCCESS,
  CREATE_MANUAL_PAYMENT,
  CREATE_MANUAL_PAYMENT_FAILURE,
  CREATE_MANUAL_PAYMENT_SUCCESS,
  CREATE_PAYMENT,
  CREATE_PAYMENT_FAILURE,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_TRANSACTION,
  CREATE_PAYMENT_TRANSACTION_FAILURE,
  CREATE_PAYMENT_TRANSACTION_SUCCESS,
  CREATE_STRIPE_PAYMENT,
  CREATE_STRIPE_PAYMENT_FAILURE,
  CREATE_STRIPE_PAYMENT_SUCCESS,
  EDIT_GO_PAYMENT,
  EDIT_GO_PAYMENT_FAILURE,
  EDIT_GO_PAYMENT_SUCCESS,
  EDIT_GO_STRIPE_PAYMENT,
  EDIT_GO_STRIPE_PAYMENT_FAILURE,
  EDIT_GO_STRIPE_PAYMENT_SUCCESS,
  EDIT_PAYMENT,
  EDIT_PAYMENT_FAILURE,
  EDIT_PAYMENT_SUCCESS,
  FETCH_BILLING_ADDRESSES,
  FETCH_BILLING_ADDRESSES_FAILURE,
  FETCH_BILLING_ADDRESSES_SUCCESS,
  FETCH_GO_PAYMENT,
  FETCH_GO_PAYMENT_FAILURE,
  FETCH_GO_PAYMENT_SUCCESS,
  FETCH_PAYMENT,
  FETCH_PAYMENT_FAILURE,
  FETCH_PAYMENT_SUCCESS,
  GET_TRANSACTION_STATUS_FAILURE,
  GET_TRANSACTION_STATUS_SUCCESS,
  LINK_UNAPPLIED_PAYMENT,
  LINK_UNAPPLIED_PAYMENT_FAILURE,
  LINK_UNAPPLIED_PAYMENT_SUCCESS,
  LINK_UNAPPLIED_PAYMENTS,
  LINK_UNAPPLIED_PAYMENTS_FAILURE,
  LINK_UNAPPLIED_PAYMENTS_SUCCESS,
  REFUND_RHAPSODY_GO_STRIPE_TRANSACTION,
  REFUND_RHAPSODY_GO_STRIPE_TRANSACTION_FAILURE,
  REFUND_RHAPSODY_GO_STRIPE_TRANSACTION_SUCCESS,
  REFUND_RHAPSODY_GO_TRANSACTION,
  REFUND_RHAPSODY_GO_TRANSACTION_FAILURE,
  REFUND_RHAPSODY_GO_TRANSACTION_SUCCESS,
  RHAPSODY_GO_APPLY_PAYMENT,
  RHAPSODY_GO_APPLY_PAYMENT_FAILURE,
  RHAPSODY_GO_APPLY_PAYMENT_SUCCESS,
  RHAPSODY_GO_AUTHORIZATION,
  RHAPSODY_GO_AUTHORIZATION_FAILURE,
  RHAPSODY_GO_AUTHORIZATION_SUCCESS,
  SETTLE_GO_STRIPE_TRANSACTION,
  SETTLE_GO_STRIPE_TRANSACTION_FAILURE,
  SETTLE_GO_STRIPE_TRANSACTION_SUCCESS,
  SETTLE_GO_TRANSACTION,
  SETTLE_GO_TRANSACTION_FAILURE,
  SETTLE_GO_TRANSACTION_SUCCESS,
  UNLINK_PAYMENT_TO_INVOICE,
  UNLINK_PAYMENT_TO_INVOICE_FAILURE,
  UNLINK_PAYMENT_TO_INVOICE_SUCCESS,
  UPDATE_BILLING_ADDRESS,
  UPDATE_BILLING_ADDRESS_FAILURE,
  UPDATE_BILLING_ADDRESS_SUCCESS,
  UPDATE_GO_PAYMENTS,
  UPDATE_GO_STRIPE_PAYMENTS,
  UPDATE_PAYMENTS,
  UPDATE_PENDING_PAYMENT_STATE,
  VOID_RHAPSODY_GO_STRIPE_TRANSACTION,
  VOID_RHAPSODY_GO_STRIPE_TRANSACTION_FAILURE,
  VOID_RHAPSODY_GO_STRIPE_TRANSACTION_SUCCESS,
  VOID_RHAPSODY_GO_TRANSACTION,
  VOID_RHAPSODY_GO_TRANSACTION_FAILURE,
  VOID_RHAPSODY_GO_TRANSACTION_SUCCESS,
} from '../actions/types/payments'
import type { RootState } from '../index'

const initialTransactionData = {
  posPayTransaction: {},
  pendingPayment: {},
  transactionStatusChecks: 0,
  posPayError: null,
  rhapsodyGoProcessedPayment: null,
  rhapsodyGoError: null,
}

export type PaymentsState = {
  billingAddresses: BillingAddress[]
  error: string | null
  goPaymentsMap: Record<string, ExtendPayment>
  goStripePaymentsMap: Record<string, ExtendPayment>
  isFetching: boolean
  isLinkingUnappliedPayments: boolean
  isLoading: boolean
  isSaving: boolean
  lastCreatedPaymentId: string | null
  paymentsMap: Record<string, ExtendPayment>
  processedBillingAddress: BillingAddress
  stripeClientSecret?: string
  transactionData: TransactionData
}

export const INITIAL_STATE = {
  paymentsMap: {},
  goPaymentsMap: {},
  goStripePaymentsMap: {},
  isLinkingUnappliedPayments: false,
  isLoading: false,
  isSaving: false,
  isFetching: false,
  error: null,
  billingAddresses: [],
  processedBillingAddress: {},
  lastCreatedPaymentId: null,
  transactionData: initialTransactionData,
  stripeClientSecret: undefined,
}

const payments = (
  state: PaymentsState = INITIAL_STATE,
  action: AnyAction,
): PaymentsState => {
  switch (action.type) {
    case CREATE_CREDIT_ADJUSTMENT:
      return {
        ...state,
        isLoading: true,
        isSaving: true,
        error: null,
      }
    case CREATE_MANUAL_PAYMENT:
    case CREATE_PAYMENT:
      return {
        ...state,
        isLoading: true,
        isSaving: true,
        lastCreatedPaymentId: null,
      }
    case CREATE_CREDIT_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        paymentsMap: secondLevelMerge(state.paymentsMap, action.payment),
        lastCreatedPaymentId: action.payment.id,
      }
    case CREATE_MANUAL_PAYMENT_SUCCESS:
    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        lastCreatedPaymentId: action.paymentId,
      }
    case UPDATE_PAYMENTS:
      return {
        ...state,
        paymentsMap: secondLevelMerge(state.paymentsMap, action.payments),
      }
    case UPDATE_GO_PAYMENTS:
      return {
        ...state,
        goPaymentsMap: secondLevelMerge(state.goPaymentsMap, action.goPayments),
      }
    case UPDATE_GO_STRIPE_PAYMENTS:
      return {
        ...state,
        goStripePaymentsMap: secondLevelMerge(
          state.goStripePaymentsMap,
          action.goStripePayments,
        ),
      }
    case CHARGE_PAYMENT:
    case SETTLE_GO_TRANSACTION:
    case SETTLE_GO_STRIPE_TRANSACTION:
    case RHAPSODY_GO_AUTHORIZATION:
    case RHAPSODY_GO_APPLY_PAYMENT:
    case REFUND_RHAPSODY_GO_STRIPE_TRANSACTION:
    case REFUND_RHAPSODY_GO_TRANSACTION:
      return { ...state, isLoading: true }
    case CHARGE_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transactionData: {
          ...state.transactionData,
          rhapsodyGoProcessedPayment: state.paymentsMap[action.paymentId],
        },
      }
    case RHAPSODY_GO_APPLY_PAYMENT_SUCCESS:
    case RHAPSODY_GO_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transactionData: {
          ...state.transactionData,
          rhapsodyGoProcessedPayment: state.goPaymentsMap[action.paymentId],
        },
      }
    case CREATE_PAYMENT_TRANSACTION:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_PAYMENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactionData: {
          ...state.transactionData,
          transactionStatusChecks: 0,
        },
      }
    case CREATE_PAYMENT_TRANSACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        transactionData: {
          ...state.transactionData,
          posPayError: getErrorMessage(action.error),
          transactionStatusChecks: 0,
        },
      }
    case GET_TRANSACTION_STATUS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        transactionData: {
          ...state.transactionData,
          posPayTransaction: action.transaction,
        },
      }
    }
    case GET_TRANSACTION_STATUS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        transactionData: {
          ...state.transactionData,
          posPayError: getErrorMessage(action.error),
          transactionStatusChecks: 0,
        },
      }
    }
    case UPDATE_PENDING_PAYMENT_STATE:
      return {
        ...state,
        isLoading: true,
        transactionData: {
          ...state.transactionData,
          transactionStatusChecks:
            state.transactionData.transactionStatusChecks + 1,
          pendingPayment: action.payment,
        },
      }
    case CLEAR_TRANSACTION_INFO:
      return {
        ...state,
        isLoading: false,
        transactionData: {
          ...initialTransactionData,
        },
      }
    case SETTLE_GO_TRANSACTION_FAILURE:
    case SETTLE_GO_STRIPE_TRANSACTION_FAILURE:
    case CREATE_PAYMENT_FAILURE:
    case CREATE_MANUAL_PAYMENT_FAILURE:
    case CREATE_CREDIT_ADJUSTMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_BILLING_ADDRESSES:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_BILLING_ADDRESSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        billingAddresses: action.billingAddresses,
      }
    case FETCH_BILLING_ADDRESSES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case CREATE_BILLING_ADDRESS:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_BILLING_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        billingAddresses: [action.address, ...state.billingAddresses],
        processedBillingAddress: action.address,
      }
    case CREATE_BILLING_ADDRESS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_BILLING_ADDRESS:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_BILLING_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        billingAddresses: Utils.updateById(
          action.address,
          state.billingAddresses,
        ),
        processedBillingAddress: action.address,
      }
    case UPDATE_BILLING_ADDRESS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case CHARGE_PAYMENT_FAILURE:
    case RHAPSODY_GO_APPLY_PAYMENT_FAILURE:
    case RHAPSODY_GO_AUTHORIZATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        processedBillingAddress: {},
        transactionData: {
          ...state.transactionData,
          rhapsodyGoError: getServerValidationError(action.error),
        },
      }
    case REFUND_RHAPSODY_GO_TRANSACTION_FAILURE:
    case REFUND_RHAPSODY_GO_STRIPE_TRANSACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        transactionData: {
          ...state.transactionData,
          rhapsodyGoError: getServerValidationError(action.error),
        },
      }
    case REFUND_RHAPSODY_GO_TRANSACTION_SUCCESS:
    case REFUND_RHAPSODY_GO_STRIPE_TRANSACTION_SUCCESS:
    case SETTLE_GO_TRANSACTION_SUCCESS:
    case SETTLE_GO_STRIPE_TRANSACTION_SUCCESS:
    case VOID_RHAPSODY_GO_TRANSACTION_SUCCESS:
    case VOID_RHAPSODY_GO_STRIPE_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transactionData: {
          ...state.transactionData,
          rhapsodyGoProcessedPayment: action.processedPayment,
        },
      }
    case VOID_RHAPSODY_GO_STRIPE_TRANSACTION:
    case VOID_RHAPSODY_GO_TRANSACTION:
      return {
        ...state,
        isLoading: true,
      }
    case VOID_RHAPSODY_GO_TRANSACTION_FAILURE:
    case VOID_RHAPSODY_GO_STRIPE_TRANSACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        transactionData: {
          ...state.transactionData,
          rhapsodyGoError: getServerValidationError(action.error),
        },
      }
    case CLEAR_PROCESSED_BILLING_ADDRESS:
      return {
        ...state,
        processedBillingAddress: {},
      }
    case CLEAR_CLIENT_BILLING_ADDRESSES:
      return {
        ...state,
        billingAddresses: [],
      }
    case CLEAR_RHAPSODY_GO_ERROR:
      return {
        ...state,
        transactionData: {
          ...state.transactionData,
          rhapsodyGoError: null,
        },
      }
    case FETCH_PAYMENT:
    case FETCH_GO_PAYMENT:
      return { ...state, isLoading: true, isFetching: true }
    case FETCH_PAYMENT_SUCCESS:
    case FETCH_GO_PAYMENT_SUCCESS:
      return { ...state, isLoading: false, isFetching: false }
    case FETCH_PAYMENT_FAILURE:
    case FETCH_GO_PAYMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case LINK_UNAPPLIED_PAYMENTS: {
      return {
        ...state,
        error: null,
        isLinkingUnappliedPayments: true,
      }
    }
    case LINK_UNAPPLIED_PAYMENTS_SUCCESS: {
      return {
        ...state,
        isLinkingUnappliedPayments: false,
      }
    }
    case LINK_UNAPPLIED_PAYMENTS_FAILURE: {
      return {
        ...state,
        isLinkingUnappliedPayments: false,
        error: getErrorMessage(action.error),
      }
    }
    case APPLY_PAYMENT_TO_INVOICE:
    case LINK_UNAPPLIED_PAYMENT:
    case UNLINK_PAYMENT_TO_INVOICE:
    case EDIT_PAYMENT:
    case EDIT_GO_PAYMENT:
    case EDIT_GO_STRIPE_PAYMENT:
      return { ...state, isLoading: true }
    case APPLY_PAYMENT_TO_INVOICE_SUCCESS:
    case LINK_UNAPPLIED_PAYMENT_SUCCESS:
    case UNLINK_PAYMENT_TO_INVOICE_SUCCESS:
    case EDIT_PAYMENT_SUCCESS:
    case EDIT_GO_PAYMENT_SUCCESS:
    case EDIT_GO_STRIPE_PAYMENT_SUCCESS:
      return { ...state, isLoading: false }
    case APPLY_PAYMENT_TO_INVOICE_FAILURE:
    case LINK_UNAPPLIED_PAYMENT_FAILURE:
    case UNLINK_PAYMENT_TO_INVOICE_FAILURE:
    case EDIT_PAYMENT_FAILURE:
    case EDIT_GO_PAYMENT_FAILURE:
    case EDIT_GO_STRIPE_PAYMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case CREATE_STRIPE_PAYMENT:
      return { ...state, isLoading: true, stripeClientSecret: undefined }
    case CREATE_STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stripeClientSecret: action.clientSecret,
      }
    case CREATE_STRIPE_PAYMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        stripeClientSecret: undefined,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

export default payments
export const getPayments = (state: RootState): PaymentsState => state.payments
export const getPaymentsMap = (state: RootState) =>
  getPayments(state).paymentsMap
export const getPayment = (id: string | Nil) =>
  createSelector(getPaymentsMap, (map) => (id ? map[id] : undefined))
export const getGoPaymentsMap = (state: RootState) =>
  getPayments(state).goPaymentsMap
export const getGoStripePaymentsMap = (state: RootState) =>
  getPayments(state).goStripePaymentsMap
export const getGoPayment = (id: string | Nil) =>
  createSelector(getGoPaymentsMap, (map) => (id ? map[id] : undefined))
export const getGoStripePayment = (id: string | Nil) =>
  createSelector(getGoStripePaymentsMap, (map) => (id ? map[id] : undefined))
export const getPaymentsIsLoading = (state: RootState) =>
  getPayments(state).isLoading
export const getPaymentsIsSaving = (state: RootState) =>
  getPayments(state).isSaving
export const getPaymentsIsLinkingUnappliedPayments = (state: RootState) =>
  getPayments(state).isLinkingUnappliedPayments
export const getPaymentsIsFetching = (state: RootState) =>
  getPayments(state).isFetching
export const getPaymentTransactionData = (state: RootState) =>
  getPayments(state).transactionData
export const getPosPayTransaction = (state: RootState) =>
  getPaymentTransactionData(state).posPayTransaction
export const getIsPosPayInTransaction = createSelector(
  getPosPayTransaction,
  (posPayTransaction) =>
    !R.isEmpty(posPayTransaction) && posPayTransaction.state !== FAILED,
)
export const getPendingPayment = (state: RootState) =>
  getPaymentTransactionData(state).pendingPayment
export const getTransactionStatusChecks = (state: RootState) =>
  getPaymentTransactionData(state).transactionStatusChecks
export const getPosPayError = (state: RootState) =>
  getPaymentTransactionData(state).posPayError
export const getBillingAddresses = (state: RootState) =>
  getPayments(state).billingAddresses
export const getProcessedBillingAddress = (state: RootState) =>
  getPayments(state).processedBillingAddress
export const getRhapsodyGoError = (state: RootState) =>
  getPaymentTransactionData(state).rhapsodyGoError
export const getRhapsodyGoProcessedPayment = (state: RootState) =>
  getPaymentTransactionData(state).rhapsodyGoProcessedPayment
export const getPaymentsError = (state: RootState) => getPayments(state).error
export const getLastCreatedPaymentId = (state: RootState) =>
  getPayments(state).lastCreatedPaymentId
export const getLastCreatedPayment = (state: RootState) =>
  getPayment(getLastCreatedPaymentId(state) || '')(state)
export const getStripeClientSecret = (state: RootState) =>
  getPayments(state).stripeClientSecret
