import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { LinkButton, PuiTheme, Text, Utils } from '@pbt/pbt-ui-components'
import {
  Edit as EditIcon,
  Eye as EyeIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { getImagingOrderStatuses } from '~/store/reducers/constants'
import { ImagingOrder } from '~/types'

import { isCompletedImagingStatus } from './imagingProceduresUtils'
import ImagingStatusLabel from './ImagingStatusLabel'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    title: {
      width: '100%',
    },
    labRow: {
      padding: theme.spacing(1, 1, 1, 2),
      borderBottom: theme.constants.tableBorder,
    },
    name: {
      width: 360,
    },
    iconButton: {
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.5),
    },
  }),
  { name: 'ImagingOrdersRow' },
)

export interface ImagingOrdersRowProps {
  onHandleEdit: (order: ImagingOrder) => void
  onHandleOrderCancel: (order: ImagingOrder) => void
  onHandlePreview: (order: ImagingOrder) => void
  order: ImagingOrder
}

export default function ImagingOrdersRow({
  order,
  onHandleOrderCancel,
  onHandlePreview,
  onHandleEdit,
}: ImagingOrdersRowProps) {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const { statusId, name: orderName } = order

  const ImagingOrderStatuses = useSelector(getImagingOrderStatuses)

  const orderStatusName = Utils.getConstantName(statusId, ImagingOrderStatuses)
  const hasResult = isCompletedImagingStatus(orderStatusName)

  return (
    <Grid container className={classes.labRow} direction="column">
      <Grid container item alignItems="center">
        <Text className={classes.name}>{orderName}</Text>
        <Grid item xs>
          {statusId && <ImagingStatusLabel statusId={statusId} />}
        </Grid>
        {!hasResult && (
          <Grid item>
            <LinkButton onClick={() => onHandleOrderCancel(order)}>
              {t('Common:CANCEL_ORDER')}
            </LinkButton>
          </Grid>
        )}
        {hasResult ? (
          <Grid item>
            <IconButton
              className={classes.iconButton}
              size="large"
              onClick={() => onHandlePreview(order)}
            >
              <EyeIcon />
            </IconButton>
          </Grid>
        ) : (
          <Grid item>
            <IconButton
              className={classes.iconButton}
              size="large"
              onClick={() => onHandleEdit(order)}
            >
              <EditIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
