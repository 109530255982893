import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import deepEqual from 'fast-deep-equal'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  CopyToClipboard,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import Expander from '~/components/common/lists/Expander'
import { BenchmarkingKeyStatus } from '~/constants/benchmarkingsTypes'
import {
  editBenchmarkingKey,
  getBenchmarkingKey,
  getIsBenchmarkingItemEditing,
} from '~/store/duck/benchmarking'
import { DataHandleWithUnsavedChanges } from '~/types'

import BenchmarkingActivationKeyForm from './BenchmarkingActivationKeyForm'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    apiKeyWrapper: {
      borderBottom: theme.constants.tableBorder,
    },
    button: {
      minWidth: 100,
      margin: theme.spacing(2, 0, 0),
    },
  }),
  { name: 'BenchmarkingApiAccessPage' },
)

export interface LabTestDetailsProps {
  itemId: string
  onClose: () => void
}

const BenchmarkingActivationKeyDetails = ({
  itemId,
  onClose,
}: LabTestDetailsProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Admin', 'Common'])

  const activationKey = useSelector(getBenchmarkingKey(itemId))
  const isKeyEditing = useSelector(getIsBenchmarkingItemEditing)

  const [isKeyChanged, setIsKeyChanged] = useState(false)

  const activationKeyFormRef = useRef<DataHandleWithUnsavedChanges>()

  const getNewkey = () => activationKeyFormRef.current?.get()
  const getUnsavedData = () => {
    const newKey = getNewkey()
    return (
      Boolean(activationKey) &&
      !R.isEmpty(activationKey) &&
      !deepEqual(activationKey, newKey)
    )
  }

  const onChange = () => {
    setIsKeyChanged(getUnsavedData())
  }

  const onDeactivateRequested =
    activationKey?.status === BenchmarkingKeyStatus.REGISTERED ||
    !activationKey?.active
      ? undefined
      : () => {
          if (activationKey && !isKeyChanged) {
            dispatch(
              editBenchmarkingKey({
                ...activationKey,
                active: false,
              }),
            )
          }
        }

  const onSetNonUsedStatus = () => {
    if (activationKey && !isKeyChanged) {
      dispatch(
        editBenchmarkingKey({
          ...activationKey,
          status: BenchmarkingKeyStatus.NOT_USED,
        }),
      )
    }
  }

  const onSaveRequested = () => {
    if (activationKeyFormRef.current?.validate() && isKeyChanged) {
      const newKey = getNewkey()
      dispatch(editBenchmarkingKey(newKey))
    }
  }

  const activationKeyStatus = {
    [BenchmarkingKeyStatus.NOT_USED]: t('Admin:NOT_USED'),
    [BenchmarkingKeyStatus.REGISTRATION_IN_PROGRESS]: t(
      'Admin:REGISTRATION_IN_PROGRESS',
    ),
    [BenchmarkingKeyStatus.REGISTERED]: t('Admin:REGISTERED'),
  }

  return (
    <Expander
      showButtons
      expandedItemClass={t('Common:BENCHMARKING_API_ACCESS').toLowerCase()}
      getUnsavedData={getUnsavedData}
      hasUnsavedData={isKeyChanged}
      isDeactivating={isKeyEditing}
      isSaving={isKeyEditing}
      onBack={onClose}
      onDeactivateRequested={onDeactivateRequested}
      onSaveRequested={onSaveRequested}
    >
      <Grid container pl={2} pr={2} spacing={2}>
        <BenchmarkingActivationKeyForm
          item={activationKey}
          ref={activationKeyFormRef}
          onChange={onChange}
        />
        <Grid
          container
          className={classes.apiKeyWrapper}
          direction="row"
          wrap="nowrap"
        >
          <Text strong mb={1} mr={5} mt={2}>
            {t('Admin:API_ACCESS_KEY')}
          </Text>
          <Text mb={1} mt={2}>
            {activationKey?.id}
          </Text>
        </Grid>
        <CopyToClipboard
          isButton
          classes={{ button: classes.button }}
          label={t('Admin:COPY_KEY')}
          text={activationKey?.id || ''}
        />
        <Grid
          container
          className={classes.apiKeyWrapper}
          direction="row"
          wrap="nowrap"
        >
          <Text strong mb={1} mr={5} mt={2}>
            {t('Common:STATUS')}
          </Text>
          <Text mb={1} mt={2}>
            {
              activationKeyStatus[
                activationKey?.status as BenchmarkingKeyStatus
              ]
            }
          </Text>
        </Grid>
        {activationKey?.status ===
          BenchmarkingKeyStatus.REGISTRATION_IN_PROGRESS && (
          <ButtonWithLoader
            classes={{ button: classes.button }}
            onClick={onSetNonUsedStatus}
          >
            {t('Admin:SET_NOT_USED_STATUS')}
          </ButtonWithLoader>
        )}
      </Grid>
    </Expander>
  )
}

export default BenchmarkingActivationKeyDetails
