import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  Radio,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import deepEqual from 'fast-deep-equal'
import {
  AddressUtils,
  ButtonWithLoader,
  CityTextField,
  CountrySelect,
  Nil,
  PhoneUtils,
  PuiSelect,
  PuiTextField,
  PuiTheme,
  RegionUtils,
  StateSelect,
  Text,
  useFields,
  User,
  ZipInput,
} from '@pbt/pbt-ui-components'

import { ContactMethodSelect } from '~/components/common/ContactMethodSelect'
import PhoneInput from '~/components/common/form-inputs/PhoneInput'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import { createCoparent, updateCoparent } from '~/store/actions/coparents'
import {
  getCurrentBusiness,
  getCurrentBusinessIsOmniChannel,
} from '~/store/reducers/auth'
import { getTag } from '~/store/reducers/constants'
import { getCoparentsIsLoading } from '~/store/reducers/coparents'
import { getUser } from '~/store/reducers/users'
import { isFieldValuesChanged } from '~/utils'

const useStyles = makeStyles(
  () => ({
    button: {
      width: 220,
    },
    notesLabel: {
      fontWeight: 500,
    },
    address: {
      textOverflow: 'initial',
      whiteSpace: 'normal',
    },
  }),
  { name: 'CoPetParent' },
)

export interface CoPetParentHandle {
  getHasUnsavedChanges: () => boolean
  save: () => void
}

export interface CoPetParentProps {
  clientId: string | Nil
  coparentId?: string | Nil
  onOk?: () => void
  showTitle?: boolean
}

const CoPetParent = forwardRef<CoPetParentHandle, CoPetParentProps>(
  ({ clientId, coparentId, showTitle, onOk }, ref) => {
    const navigate = useNavigate()
    const goBack = () => navigate(-1)
    const classes = useStyles()
    const isMobile = useMediaQuery((theme: PuiTheme) =>
      theme.breakpoints.down('sm'),
    )
    const { t } = useTranslation(['Common', 'Clients'])

    const dispatch = useDispatch()
    const Tag = useSelector(getTag)
    const client = useSelector(getUser(clientId))
    const coparent = useSelector(getUser(coparentId))
    const coparentIsLoading = useSelector(getCoparentsIsLoading)
    const currentBusiness = useSelector(getCurrentBusiness)
    const isCurrentBusinessOmniChannel = useSelector(
      getCurrentBusinessIsOmniChannel,
    )

    const isEdit = Boolean(coparentId)

    const [showCustomAddress, setShowCustomAddress] = useState(isEdit)
    const { fields, validate } = useFields(
      [
        {
          name: 'firstName',
          label: t('Clients:COPET_PARENT.INPUT.FIRST_NAME'),
          validators: ['required'],
          initialValue: coparent?.firstName || '',
        },
        {
          name: 'lastName',
          label: t('Clients:COPET_PARENT.INPUT.LAST_NAME'),
          validators: ['required'],
          initialValue: coparent?.lastName || '',
        },
        {
          name: 'mobilePhone',
          label: t('Common:MOBILE_PHONE_NUMBER'),
          validators: ['phone'],
          initialValue: coparent?.mobilePhone || '',
        },
        {
          name: 'workPhone',
          label: t('Common:WORK_PHONE_NUMBER'),
          validators: ['phone'],
          initialValue: coparent?.workPhone || '',
        },
        {
          name: 'homePhone',
          label: t('Common:HOME_PHONE_NUMBER'),
          validators: ['phone'],
          initialValue: coparent?.homePhone || '',
        },
        {
          name: 'otherPhone',
          label: t('Common:OTHER_PHONE_NUMBER'),
          validators: ['phone'],
          initialValue: coparent?.otherPhone || '',
        },
        {
          name: 'email',
          label: t('Common:EMAIL'),
          validators: ['email'],
          initialValue: coparent?.email || '',
        },
        {
          name: 'preferredContactMethodId',
          label: t('Common:PREFERRED_CONTACT_METHOD'),
          type: 'select',
          initialValue: coparent?.preferredContactMethodId || '',
        },
        {
          name: 'address',
          label: t('Common:STREET_ADDRESS'),
          initialValue: coparent?.address || '',
        },
        {
          name: 'suite',
          label: t('Common:APARTMENT_NUMBER_SIGN'),
          initialValue: coparent?.suite || '',
        },
        {
          name: 'country',
          label: t('Common:COUNTRY'),
          initialValue: RegionUtils.getAvailableCountry(
            coparent?.country || currentBusiness?.country,
          ),
        },
        {
          name: 'city',
          label: t('Common:CITY'),
          initialValue: coparent?.city || '',
        },
        {
          name: 'state',
          label: t('Common:STATE'),
          type: 'select',
          initialValue: coparent?.state || '',
        },
        {
          name: 'zip',
          label: t('Common:ZIP_CODE'),
          validators: showCustomAddress ? ['zip'] : [],
          initialValue: coparent?.zip || '',
        },
        {
          name: 'tag',
          label: t('Common:TAG'),
          type: 'select',
          initialValue: coparent?.tag || '',
        },
        {
          name: 'notes',
          label: t('Common:NOTES'),
          initialValue: coparent?.notes || '',
        },
      ],
      false,
    )

    const {
      firstName,
      lastName,
      mobilePhone,
      workPhone,
      homePhone,
      otherPhone,
      email,
      preferredContactMethodId,
      address,
      suite,
      country,
      city,
      state,
      zip,
      tag,
      notes,
    } = fields

    const [closeAfterUpdate, setCloseAfterUpdate] = useState(false)

    useEffect(() => {
      if (!client?.address) {
        setShowCustomAddress(true)
      }
    }, [client])

    useEffect(() => {
      zip.setValue('')
      state.setValue('')
    }, [country.value])

    useEffect(() => {
      zip.setValue(coparent?.zip || '')
      state.setValue(coparent?.state || '')
    }, [])

    const handleNavigate = () => {
      if (onOk) {
        onOk()
      }

      if (isMobile) {
        goBack()
      }
    }

    useEffect(() => {
      if (closeAfterUpdate && !coparentIsLoading) {
        handleNavigate()
      }
    }, [closeAfterUpdate, coparentIsLoading])

    const saveCoparent = () => {
      if (validate()) {
        const newCoparent: Omit<User, 'id'> = {
          firstName: firstName.value,
          lastName: lastName.value,
          mobilePhone: PhoneUtils.parsePhoneNumber(mobilePhone.value),
          workPhone: PhoneUtils.parsePhoneNumber(workPhone.value),
          homePhone: PhoneUtils.parsePhoneNumber(homePhone.value),
          otherPhone: PhoneUtils.parsePhoneNumber(otherPhone.value),
          email: email.value,
          preferredContactMethodId: preferredContactMethodId.value,
          ...(showCustomAddress
            ? {
                address: address.value,
                suite: suite.value,
                country: country.value,
                city: city.value,
                state: state.value,
                zip: zip.value,
              }
            : {
                address: client?.address,
                suite: client?.suite,
                country: client?.country,
                city: client?.city,
                state: client?.state,
                zip: client?.zip,
              }),
          tag: tag.value,
          notes: notes.value,
        }

        if (deepEqual(coparent, { ...newCoparent, id: coparent?.id })) {
          handleNavigate()
        } else {
          setCloseAfterUpdate(true)
          if (clientId) {
            if (isEdit && coparentId) {
              dispatch(
                updateCoparent(clientId, { ...newCoparent, id: coparentId }),
              )
            } else {
              dispatch(createCoparent(clientId, newCoparent))
            }
          }
        }
      }
    }

    useImperativeHandle(ref, () => ({
      getHasUnsavedChanges: () => isFieldValuesChanged(fields),
      save: saveCoparent,
    }))

    return (
      <Grid container alignItems="center" spacing={2}>
        {showTitle && (
          <Grid item xs>
            <Text variant="hero2">
              {isEdit
                ? t('Clients:COPET_PARENT.TITLE_EDIT_COPET')
                : t('Clients:COPET_PARENT.TITLE_NEW_COPET')}
            </Text>
          </Grid>
        )}
        <Grid item md={6} xs={12}>
          <PuiTextField
            field={firstName}
            inputProps={{ maxLength: 100 }}
            label={`${firstName.label}*`}
            margin="none"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <PuiTextField
            field={lastName}
            inputProps={{ maxLength: 100 }}
            label={`${lastName.label}*`}
            margin="none"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <PhoneInput field={mobilePhone} margin="none" />
        </Grid>
        <Grid item md={6} xs={12}>
          <PhoneInput field={workPhone} margin="none" />
        </Grid>
        <Grid item md={6} xs={12}>
          <PhoneInput field={homePhone} margin="none" />
        </Grid>
        <Grid item md={6} xs={12}>
          <PhoneInput field={otherPhone} margin="none" />
        </Grid>
        <Grid item md={6} xs={12}>
          <PuiTextField
            field={email}
            inputProps={{ maxLength: 100 }}
            label={email.label}
            margin="none"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="none">
            <InputLabel htmlFor="contact-method-select">
              {preferredContactMethodId.label}
            </InputLabel>
            <ContactMethodSelect
              clientId={null}
              // For co-pet parent we don't want to allow selecting "Text message" option for omni channel businesses
              // as SMS consent flag for those clients would be always false
              communicationSmsOptInValue={!isCurrentBusinessOmniChannel}
              field={preferredContactMethodId}
              inputId="contact-method-select"
            />
          </FormControl>
        </Grid>
        {showCustomAddress ? (
          <>
            <Grid item md={7} xs={12}>
              <PuiTextField
                field={address}
                inputProps={{ maxLength: 100 }}
                label={address.label}
                margin="none"
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <PuiTextField
                field={suite}
                inputProps={{ maxLength: 100 }}
                label={suite.label}
                margin="none"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CityTextField country={country.value} field={city} />
            </Grid>
            <Grid item md={3} xs={12}>
              <CountrySelect field={country} />
            </Grid>
            <Grid item md={3} xs={12}>
              <StateSelect country={country.value} field={state} />
            </Grid>
            <Grid item md={3} xs={12}>
              <PuiTextField
                InputProps={{
                  inputComponent: ZipInput,
                  inputProps: { country: country.value },
                }}
                field={zip}
                label={zip.label}
                margin="none"
              />
            </Grid>
          </>
        ) : (
          <Grid container item>
            <Grid item mb={1} md={12}>
              <Text strong variant="body">
                {t('Clients:COPET_PARENT.SAME_ADDRESS_AS_CLIENT', {
                  firstName: client?.firstName,
                })}
              </Text>
            </Grid>
            <Grid container item alignItems="center" md={6} wrap="nowrap">
              <Radio
                checked={!showCustomAddress}
                id="same-address"
                name="address"
                onChange={() => setShowCustomAddress(false)}
              />
              <InputLabel className={classes.address}>
                {AddressUtils.formatAddress(client || {}, true)}
              </InputLabel>
            </Grid>
            <Grid container item alignItems="center" md={6} wrap="nowrap">
              <Radio
                checked={showCustomAddress}
                id="custom-address"
                name="address"
                onChange={() => setShowCustomAddress(true)}
              />
              <InputLabel>{t('Common:ADD_NEW_ADDRESS')}</InputLabel>
            </Grid>
          </Grid>
        )}
        <Grid item md={12} xs={12}>
          <FormControl fullWidth margin="none">
            <InputLabel htmlFor="tags-select">{tag.label}</InputLabel>
            <PuiSelect
              field={tag}
              input={<Input id="tags-select" />}
              items={Tag}
            />
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12}>
          <InputLabel htmlFor="notes-input">
            <b className={classes.notesLabel}>{notes.label}:&nbsp;</b>
            {t('Common:NOTE_NOT_SHARED_WITH_CLIENT')}
          </InputLabel>
          <PuiTextField
            multiline
            field={notes}
            id="notes-input"
            inputProps={{ maxLength: 1000 }}
            margin="none"
            minRows={3}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <RequiredFieldsNotice />
        </Grid>
        <Grid item mt={4}>
          <ButtonWithLoader
            className={classes.button}
            disabled={coparentIsLoading}
            loading={coparentIsLoading}
            onClick={saveCoparent}
          >
            {isEdit ? t('Common:SAVE_ACTION') : t('Common:ADD_ACTION')}
          </ButtonWithLoader>
        </Grid>
      </Grid>
    )
  },
)

export default CoPetParent
