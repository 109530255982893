import React, { createRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  DebouncedInlineSearch,
  InlineSearchHandle,
  PuiTheme,
  TextWithTooltip,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import { applyPatientRestrictions } from '~/store/actions/orders'
import {
  getAppliedPatientRestrictions,
  getOrdersSearchResults,
} from '~/store/reducers/orders'
import {
  getAppointmentId,
  getClientId,
  getOrderFilters,
  getPatientId,
} from '~/store/reducers/soap'
import { BaseChargeTabProps } from '~/types/entities/charges'

import { useChargeInlineSearch } from '../../utils/useChargeInlineSearch'
import ChargeSearchResults from './ChargeSearchResults'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    searchRoot: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
    },
    switch: {
      whiteSpace: 'nowrap',
    },
    switchRoot: {
      marginLeft: 0,
    },
  }),
  { name: 'SearchChargeTab' },
)

export interface SearchChargeTabProps extends BaseChargeTabProps {
  clearSearch: () => void
  patientId?: string
  searchRef: React.Ref<InlineSearchHandle> | undefined
}

const SearchChargeTab = ({
  clearSearch,
  disabled,
  handleEditListItem,
  handleRefill,
  handleSavePrescription,
  logItemStateGetter,
  openPrescriptionDialog,
  searchRef,
  patientId: patientIdProp,
}: SearchChargeTabProps) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const { t } = useTranslation(['Search', 'Soap', 'Tooltips'])

  const appointmentId = useSelector(getAppointmentId)
  const clientId = useSelector(getClientId)
  const orderFilters = useSelector(getOrderFilters)
  const soapPatientId = useSelector(getPatientId)
  const searchResults = useSelector(getOrdersSearchResults)
  const appliedPatientRestrictions = useSelector(getAppliedPatientRestrictions)

  const isMedium = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('lg'),
  )

  const itemsRefs = Array.from({ length: searchResults.length }, () =>
    createRef<any>(),
  )

  const validate = () =>
    itemsRefs.every((ref) =>
      ref.current?.validate ? ref.current.validate() : true,
    )

  const patientId = soapPatientId || patientIdProp

  const {
    onSearchChange,
    onSearchBlur,
    onSearchFocus,
    searchDisabled,
    searchTerm,
  } = useChargeInlineSearch({
    validate,
    patientId,
  })

  return (
    <Grid container p={2}>
      <Grid
        container
        item
        alignItems="baseline"
        width="100%"
        wrap={isMedium ? 'wrap-reverse' : 'nowrap'}
        zIndex={1}
      >
        <Grid container position="relative">
          <DebouncedInlineSearch
            classes={{ root: classes.searchRoot }}
            disabled={disabled || searchDisabled}
            initialValue={searchTerm}
            placeholder={t('Search:SEARCH_TO_ADD')}
            ref={searchRef}
            showCloseButton={Boolean(searchTerm)}
            onBlur={onSearchBlur}
            onChange={onSearchChange}
            onFocus={onSearchFocus}
          />
          {patientId && clientId && (
            <ChargeSearchResults
              appointmentId={appointmentId}
              clearSearch={clearSearch}
              clientId={clientId}
              disabled={disabled}
              handleEditListItem={handleEditListItem}
              handleRefill={handleRefill}
              handleSavePrescription={handleSavePrescription}
              itemsRefs={itemsRefs}
              logItemStateGetter={logItemStateGetter}
              openPrescriptionDialog={openPrescriptionDialog}
              orderFilters={orderFilters}
              patientId={patientId}
              searchTerm={searchTerm}
              onSearch={onSearchChange}
            />
          )}
        </Grid>
        <Grid width={396}>
          <PuiSwitch
            checked={appliedPatientRestrictions}
            classes={{
              root: classes.switchRoot,
              switchLabel: classes.switch,
            }}
            label={
              <TextWithTooltip
                tooltipText={t('Tooltips:ORDER_LIST_WITH_FILTERS_TOGGLE')}
                variant="body2"
              >
                {t('Soap:LIST_WITH_FILTERS.ORDER_LIST_WITH_FILTERS_LABEL')}
              </TextWithTooltip>
            }
            onChange={() =>
              dispatch(applyPatientRestrictions(!appliedPatientRestrictions))
            }
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SearchChargeTab
