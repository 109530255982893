import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError, CountryCode, Defaults, Nil } from '@pbt/pbt-ui-components'

import {
  getLineItemUniqId,
  mergeItemsWithQuantitySum,
} from '~/components/dashboard/invoices/invoiceUtils'
import {
  AssignedSoapDetail,
  BalanceListEntry,
  CheckoutInvoicesData,
  Estimate,
  ExtendPayment,
  Invoice,
  InvoiceLineItem,
  InvoiceLocaleOptions,
  InvoiceOrEstimate,
  Payment,
} from '~/types'
import { arrayToMap, mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import {
  EMAIL_INVOICE,
  EMAIL_INVOICE_FAILURE,
  EMAIL_INVOICE_SUCCESS,
  EMAIL_PAYMENT,
  EMAIL_PAYMENT_FAILURE,
  EMAIL_PAYMENT_SUCCESS,
  GENERATE_PDF_FOR_INVOICE,
  GENERATE_PDF_FOR_INVOICE_FAILURE,
  GENERATE_PDF_FOR_INVOICE_SUCCESS,
  GENERATE_PDF_FOR_INVOICE_V2,
} from '../actions/types/communications'
import {
  ADD_ESTIMATE_TO_SOAP_OR_EVENT,
  ADD_ESTIMATE_TO_SOAP_OR_EVENT_FAILURE,
  ADD_ESTIMATE_TO_SOAP_OR_EVENT_SUCCESS,
  ADD_ESTIMATE_TO_SOAP_OR_EVENT_VALIDATION_FAILURE,
  ADD_PATIENT_ESTIMATE_SUCCESS,
  ADD_PAYMENT_TO_INVOICES,
  CLEAR_APPOINTMENT_INVOICE_ITEMS,
  CLEAR_CLONED_ESTIMATE_ID,
  CLEAR_CURRENT_INVOICE_DATA,
  CLEAR_FINANCE_ERROR,
  CLEAR_INVOICE_GROUP_EXPANDED,
  CLEAR_INVOICE_PRINT_HTML,
  CLEAR_LINE_ITEM_CANDIDATES,
  CLEAR_LINE_ITEM_DELETED,
  CLONE_ESTIMATE,
  CLONE_ESTIMATE_FAILURE,
  CLONE_ESTIMATE_SUCCESS,
  CONVERT_TO_LINE_ITEMS,
  CONVERT_TO_LINE_ITEMS_FAILURE,
  CONVERT_TO_LINE_ITEMS_SUCCESS,
  DELETE_ESTIMATE,
  DELETE_ESTIMATE_FAILURE,
  DELETE_ESTIMATE_SUCCESS,
  DETACH_ESTIMATE_FROM_EVENT,
  DETACH_ESTIMATE_FROM_EVENT_FAILURE,
  DETACH_ESTIMATE_FROM_EVENT_SUCCESS,
  EDIT_INVOICE_LINE_ITEM,
  EDIT_INVOICE_LINE_ITEM_FAILURE,
  EDIT_INVOICE_LINE_ITEM_SUCCESS,
  FETCH_APPOINTMENT_INVOICE_ITEMS,
  FETCH_APPOINTMENT_INVOICE_ITEMS_FAILURE,
  FETCH_APPOINTMENT_INVOICE_ITEMS_SUCCESS,
  FETCH_BALANCE_HISTORY,
  FETCH_BALANCE_HISTORY_FAILURE,
  FETCH_BALANCE_HISTORY_SUCCESS,
  FETCH_BATCH_INVOICE,
  FETCH_BATCH_INVOICE_FAILURE,
  FETCH_BATCH_INVOICE_SUCCESS,
  FETCH_CHECKOUT_INVOICES,
  FETCH_CHECKOUT_INVOICES_FAILURE,
  FETCH_CHECKOUT_INVOICES_SUCCESS,
  FETCH_ESTIMATE_TEMPLATE,
  FETCH_ESTIMATE_TEMPLATE_FAILURE,
  FETCH_ESTIMATE_TEMPLATE_SUCCESS,
  FETCH_ESTIMATES,
  FETCH_ESTIMATES_FAILURE,
  FETCH_ESTIMATES_SUCCESS,
  FETCH_INVOICE,
  FETCH_INVOICE_BY_EVENT_ID,
  FETCH_INVOICE_BY_EVENT_ID_FAILURE,
  FETCH_INVOICE_BY_EVENT_ID_SUCCESS,
  FETCH_INVOICE_FAILURE,
  FETCH_INVOICE_SUCCESS,
  FETCH_INVOICE_TEMPLATE,
  FETCH_INVOICE_TEMPLATE_FAILURE,
  FETCH_INVOICE_TEMPLATE_SUCCESS,
  FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY,
  FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY_FAILURE,
  FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY_SUCCESS,
  FETCH_MORE_PATIENT_ESTIMATES,
  FETCH_MORE_PATIENT_ESTIMATES_FAILURE,
  FETCH_MORE_PATIENT_ESTIMATES_SUCCESS,
  FETCH_PATIENT_ESTIMATES,
  FETCH_PATIENT_ESTIMATES_FAILURE,
  FETCH_PATIENT_ESTIMATES_SUCCESS,
  FETCH_UNPAID_INVOICES,
  FETCH_UNPAID_INVOICES_FAILURE,
  FETCH_UNPAID_INVOICES_SUCCESS,
  PRINT_INVOICE,
  PRINT_INVOICE_FAILURE,
  PRINT_INVOICE_SUCCESS,
  PRINT_INVOICE_V2,
  REARRANGE_INVOICE_LINE_ITEMS,
  REARRANGE_INVOICE_LINE_ITEMS_FAILURE,
  REARRANGE_INVOICE_LINE_ITEMS_SUCCESS,
  REDEEM_LOYALTY_POINTS,
  REDEEM_LOYALTY_POINTS_FAILURE,
  REDEEM_LOYALTY_POINTS_SUCCESS,
  REFRESH_BALANCE_HISTORY_FAILURE,
  REFRESH_BALANCE_HISTORY_SUCCESS,
  REMOVE_LINE_ITEMS,
  SAVE_ESTIMATE_TO_EVENT,
  SAVE_ESTIMATE_TO_EVENT_FAILURE,
  SAVE_ESTIMATE_TO_EVENT_SUCCESS,
  SAVE_ESTIMATE_TO_SOAP,
  SAVE_ESTIMATE_TO_SOAP_FAILURE,
  SAVE_ESTIMATE_TO_SOAP_SUCCESS,
  SAVE_INVOICE,
  SAVE_INVOICE_FAILURE,
  SAVE_INVOICE_SUCCESS,
  SET_INVOICE_AUTO_SAVE,
  SIGN_ESTIMATE,
  SIGN_ESTIMATE_FAILURE,
  SIGN_ESTIMATE_SUCCESS,
  TOGGLE_INVOICE_GROUP_EXPANDED,
  UPDATE_BATCH_INVOICE,
  UPDATE_INVOICE_COMPLETENESS_FROM_SPAIN,
  UPDATE_INVOICE_TEMPLATE,
  UPDATE_INVOICES,
} from '../actions/types/finance'
import type { RootState } from '../index'
import {
  getGoPaymentsMap,
  getGoStripePaymentsMap,
  getPaymentsMap,
} from './payments'

export type FinanceState = {
  appointmentLineItemsMap: Record<string, InvoiceLineItem> | null
  batchInvoice: Invoice | Nil
  bundleDiscount: number
  checkoutInvoicesData: CheckoutInvoicesData
  clonedEstimateId: string | null
  deletedLineItemsMap: Record<string, InvoiceLineItem[]>
  error: ApiError | string | null
  errorRearranging: ApiError | string | null
  estimatesList: string[]
  expandedGroupInvoicesIds: {}
  invoiceAutoSave: { items: any; no: string } | undefined
  invoiceLocaleOptions?: InvoiceLocaleOptions
  invoicesMap: Record<string, InvoiceOrEstimate>
  isConvertingToLineItems: boolean
  isEstimateDeleting: boolean
  isFetching: boolean
  isFetchingSoapInvoices: boolean
  isGeneratingInvoicePdf: boolean
  isGeneratingPdf: boolean
  isLoading: boolean
  isRearranging: boolean
  isSaving: boolean
  lastCreatedPaymentId: string | null
  lineItemsCandidates: InvoiceLineItem[]
  list: BalanceListEntry[]
  patientEstimatesIdsList: string[]
  patientEstimatesTotalCount: number
  printHtml: string | null
  template: Invoice | null
  totalCount: number
  unpaidInvoiceIds: string[]
  validationErrorType: string | null
}

export const INITIAL_STATE: FinanceState = {
  list: [],
  patientEstimatesIdsList: [],
  patientEstimatesTotalCount: 0,
  estimatesList: [],
  invoicesMap: {},
  isLoading: false,
  isEstimateDeleting: false,
  isSaving: false,
  isConvertingToLineItems: false,
  isFetching: false,
  isGeneratingPdf: false,
  isFetchingSoapInvoices: false,
  validationErrorType: null,
  error: null,
  errorRearranging: null,
  expandedGroupInvoicesIds: {},
  isRearranging: false,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  template: null,
  lineItemsCandidates: [],
  deletedLineItemsMap: {},
  printHtml: null,
  invoiceAutoSave: undefined,
  bundleDiscount: 0,
  checkoutInvoicesData: {
    forDate: [],
    others: [],
  },
  unpaidInvoiceIds: [],
  batchInvoice: null,
  lastCreatedPaymentId: null,
  appointmentLineItemsMap: null,
  isGeneratingInvoicePdf: false,
  invoiceLocaleOptions: {},
  clonedEstimateId: null,
}

const addPaymentToInvoices = (
  payment: Payment,
  state: FinanceState,
): Partial<FinanceState> => {
  const paymentInvoiceIds = payment.invoiceIds || []
  const batchInvoiceChildren = state?.batchInvoice?.invoices || []
  const shouldUpdateBatch =
    Boolean(state.batchInvoice) &&
    R.difference(batchInvoiceChildren, paymentInvoiceIds).length === 0
  const invoicesMap = payment.invoiceId
    ? {
        ...state.invoicesMap,
        [payment.invoiceId]: payment.invoiceId
          ? {
              ...state.invoicesMap[payment.invoiceId],
              payments: (
                state.invoicesMap[payment.invoiceId]?.payments || []
              ).concat(payment),
            }
          : state.invoicesMap[payment.invoiceId],
      }
    : state.invoicesMap
  return {
    list: [{ id: payment.id, schema: payment.type }, ...state.list],
    invoicesMap,
    batchInvoice:
      state.batchInvoice && shouldUpdateBatch
        ? {
            ...state.batchInvoice,
            payments: (state.batchInvoice?.payments || []).concat(payment),
          }
        : state.batchInvoice,
    totalCount: state.totalCount + 1,
  }
}

const finance = (
  state: FinanceState = INITIAL_STATE,
  action: AnyAction,
): FinanceState => {
  switch (action.type) {
    case FETCH_BALANCE_HISTORY_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case REFRESH_BALANCE_HISTORY_FAILURE:
      return { ...state, error: action.error }
    case FETCH_BALANCE_HISTORY_SUCCESS:
      return {
        ...state,
        list: R.uniq(action.list),
        totalCount: action.totalCount,
        isLoading: false,
      }
    case REFRESH_BALANCE_HISTORY_SUCCESS:
      return { ...state, list: action.list, totalCount: action.totalCount }
    case FETCH_BALANCE_HISTORY:
      return {
        ...state,
        isLoading: true,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        list: [],
      }
    case FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY:
      return { ...state, isLoading: true }
    case FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        totalCount: action.totalCount,
      }
    case UPDATE_INVOICES:
      return {
        ...state,
        invoicesMap: secondLevelMerge(state.invoicesMap, action.invoices),
      }
    case UPDATE_BATCH_INVOICE:
      return { ...state, batchInvoice: action.invoice }
    case EMAIL_INVOICE_FAILURE:
    case EMAIL_PAYMENT_FAILURE:
    case PRINT_INVOICE_FAILURE:
    case DELETE_ESTIMATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isEstimateDeleting: false,
        isSaving: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_INVOICE:
    case FETCH_INVOICE_BY_EVENT_ID:
    case FETCH_INVOICE_TEMPLATE:
    case FETCH_ESTIMATE_TEMPLATE:
      return { ...state, isFetching: true, isLoading: true, error: null }
    case FETCH_INVOICE_SUCCESS:
    case FETCH_INVOICE_BY_EVENT_ID_SUCCESS:
      return { ...state, isFetching: false, isLoading: false }
    case FETCH_INVOICE_FAILURE:
    case FETCH_INVOICE_BY_EVENT_ID_FAILURE:
    case FETCH_INVOICE_TEMPLATE_FAILURE:
    case FETCH_ESTIMATE_TEMPLATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_ESTIMATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEstimateDeleting: false,
        error: null,
        invoicesMap: R.omit([action.estimateId], state.invoicesMap),
        estimatesList: R.without([action.estimateId], state.estimatesList),
        patientEstimatesIdsList: R.without(
          [action.estimateId],
          state.patientEstimatesIdsList,
        ),
        patientEstimatesTotalCount: state.patientEstimatesTotalCount - 1,
      }
    case UPDATE_INVOICE_TEMPLATE:
      return { ...state, template: action.template, invoiceAutoSave: undefined }
    case FETCH_INVOICE_TEMPLATE_SUCCESS:
    case FETCH_ESTIMATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        template: action.template,
        invoiceAutoSave: undefined,
      }
    case CLEAR_LINE_ITEM_CANDIDATES:
      return { ...state, lineItemsCandidates: [], bundleDiscount: 0 }
    case CLEAR_LINE_ITEM_DELETED:
      return { ...state, deletedLineItemsMap: {} }
    case CLEAR_CURRENT_INVOICE_DATA:
      return {
        ...state,
        lineItemsCandidates: [],
        bundleDiscount: 0,
        deletedLineItemsMap: {},
        template: null,
        invoiceAutoSave: undefined,
      }
    case REMOVE_LINE_ITEMS:
      return {
        ...state,
        deletedLineItemsMap: {
          ...state.deletedLineItemsMap,
          [action.invoiceId]: R.uniq([
            ...(state.deletedLineItemsMap[action.invoiceId] || []),
            ...action.lineItemIds,
          ]).filter(Boolean),
        },
      }
    case SAVE_INVOICE:
      return { ...state, isLoading: true, isSaving: true }
    case SAVE_INVOICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        template: action.invoice,
        deletedLineItemsMap: {},
      }
    case SAVE_INVOICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        error: getErrorMessage(action.error),
      }
    case PRINT_INVOICE:
    case PRINT_INVOICE_V2:
      return { ...state, isLoading: true, printHtml: null }
    case EMAIL_PAYMENT:
    case EMAIL_INVOICE:
      return { ...state, isLoading: true }
    case DELETE_ESTIMATE:
      return { ...state, isEstimateDeleting: true }
    case EMAIL_PAYMENT_SUCCESS:
    case EMAIL_INVOICE_SUCCESS:
      return { ...state, isLoading: false }
    case PRINT_INVOICE_SUCCESS:
      return { ...state, isLoading: false, printHtml: action.printHtml }
    case CLEAR_INVOICE_PRINT_HTML:
      return { ...state, printHtml: null }
    case FETCH_ESTIMATES:
      return { ...state, isFetching: true, error: null, estimatesList: [] }
    case FETCH_ESTIMATES_SUCCESS:
      return { ...state, isFetching: false, estimatesList: action.list }
    case FETCH_ESTIMATES_FAILURE:
      return { ...state, isFetching: false, error: action.error }
    case FETCH_PATIENT_ESTIMATES:
      return {
        ...state,
        isFetching: true,
        error: null,
        patientEstimatesIdsList: [],
      }
    case FETCH_PATIENT_ESTIMATES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        patientEstimatesIdsList: action.patientEstimatesIdsList,
        patientEstimatesTotalCount: action.totalCount,
      }
    case FETCH_MORE_PATIENT_ESTIMATES:
      return { ...state, isFetching: true, error: null }
    case FETCH_MORE_PATIENT_ESTIMATES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        patientEstimatesIdsList: R.uniq([
          ...state.patientEstimatesIdsList,
          ...action.patientEstimatesIdsList,
        ]),
        patientEstimatesTotalCount: action.totalCount,
      }
    case FETCH_PATIENT_ESTIMATES_FAILURE:
    case FETCH_MORE_PATIENT_ESTIMATES_FAILURE:
      return { ...state, isFetching: false, error: action.error }
    case CLONE_ESTIMATE:
      return {
        ...state,
        isFetching: true,
        error: null,
        validationErrorType: null,
        clonedEstimateId: null,
      }
    case ADD_PATIENT_ESTIMATE_SUCCESS:
      const stateArrayIncludeNewsEstimateId = R.includes(
        action.newEstimateId,
        state.patientEstimatesIdsList,
      )
      return {
        ...state,
        isFetching: false,
        patientEstimatesIdsList: stateArrayIncludeNewsEstimateId
          ? state.patientEstimatesIdsList
          : [action.newEstimateId, ...state.patientEstimatesIdsList],
        patientEstimatesTotalCount: stateArrayIncludeNewsEstimateId
          ? state.patientEstimatesTotalCount
          : state.patientEstimatesTotalCount + 1,
      }
    case CLONE_ESTIMATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        patientEstimatesIdsList: [
          action.newEstimateId,
          ...state.patientEstimatesIdsList,
        ],
        patientEstimatesTotalCount: state.patientEstimatesTotalCount + 1,
        clonedEstimateId: action.newEstimateId,
      }
    case CLONE_ESTIMATE_FAILURE:
      const cloneEstimateError = action.error.responseBody
      return {
        ...state,
        isFetching: false,
        error: cloneEstimateError,
        validationErrorType: cloneEstimateError.type,
      }
    case CLEAR_CLONED_ESTIMATE_ID:
      return { ...state, clonedEstimateId: null }
    case DETACH_ESTIMATE_FROM_EVENT:
    case SAVE_ESTIMATE_TO_SOAP:
    case SAVE_ESTIMATE_TO_EVENT:
    case ADD_ESTIMATE_TO_SOAP_OR_EVENT:
      return {
        ...state,
        isLoading: true,
        error: null,
        validationErrorType: null,
      }
    case SAVE_ESTIMATE_TO_SOAP_SUCCESS:
    case SAVE_ESTIMATE_TO_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        template: action.estimate,
        deletedLineItemsMap: {},
      }
    case ADD_ESTIMATE_TO_SOAP_OR_EVENT_SUCCESS:
    case DETACH_ESTIMATE_FROM_EVENT_SUCCESS:
      return { ...state, isLoading: false }
    case DETACH_ESTIMATE_FROM_EVENT_FAILURE:
    case SAVE_ESTIMATE_TO_SOAP_FAILURE:
    case SAVE_ESTIMATE_TO_EVENT_FAILURE:
    case ADD_ESTIMATE_TO_SOAP_OR_EVENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
        validationErrorType: action.error.responseBody.type,
      }
    case ADD_ESTIMATE_TO_SOAP_OR_EVENT_VALIDATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        validationErrorType: action.error.responseBody.type,
      }
    case CONVERT_TO_LINE_ITEMS:
      return {
        ...state,
        isConvertingToLineItems: true,
        bundleDiscount: action.bundleDiscount,
      }
    case CONVERT_TO_LINE_ITEMS_FAILURE:
      return {
        ...state,
        isConvertingToLineItems: false,
        error: getErrorMessage(action.error),
      }
    case CONVERT_TO_LINE_ITEMS_SUCCESS:
      return {
        ...state,
        isConvertingToLineItems: false,
        lineItemsCandidates: mergeItemsWithQuantitySum(
          state.lineItemsCandidates,
          action.lineItems,
          getLineItemUniqId,
        ),
      }
    case CLEAR_FINANCE_ERROR:
      return { ...state, error: null }
    case SET_INVOICE_AUTO_SAVE:
      return {
        ...state,
        invoiceAutoSave: action.invoiceNo
          ? {
              no: action.invoiceNo,
              items: action.items,
            }
          : undefined,
      }
    case SIGN_ESTIMATE:
      return {
        ...state,
        isLoading: true,
      }
    case SIGN_ESTIMATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case SIGN_ESTIMATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_CHECKOUT_INVOICES:
      return {
        ...state,
        isLoading: true,
        isFetching: true,
        isFetchingSoapInvoices: true,
        error: null,
      }
    case FETCH_CHECKOUT_INVOICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        isFetchingSoapInvoices: false,
        checkoutInvoicesData: action.data,
      }
    case FETCH_CHECKOUT_INVOICES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        isFetchingSoapInvoices: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_UNPAID_INVOICES:
      return { ...state, isLoading: true, isFetching: true, error: null }
    case FETCH_UNPAID_INVOICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        unpaidInvoiceIds: action.invoiceIds,
      }
    case FETCH_UNPAID_INVOICES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_BATCH_INVOICE:
      return { ...state, isFetching: true, isLoading: true, error: null }
    case FETCH_BATCH_INVOICE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        batchInvoice: action.batchInvoice,
      }
    case FETCH_BATCH_INVOICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case GENERATE_PDF_FOR_INVOICE:
    case GENERATE_PDF_FOR_INVOICE_V2:
      return { ...state, isGeneratingInvoicePdf: true }
    case GENERATE_PDF_FOR_INVOICE_SUCCESS:
      return { ...state, isGeneratingInvoicePdf: false }
    case GENERATE_PDF_FOR_INVOICE_FAILURE:
      return {
        ...state,
        isGeneratingInvoicePdf: false,
        error: getErrorMessage(action.error),
      }
    case REDEEM_LOYALTY_POINTS:
      return { ...state, isLoading: true }
    case REDEEM_LOYALTY_POINTS_SUCCESS:
      return { ...state, isLoading: false }
    case REDEEM_LOYALTY_POINTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_APPOINTMENT_INVOICE_ITEMS:
      return { ...state, error: null }
    case FETCH_APPOINTMENT_INVOICE_ITEMS_SUCCESS:
      return {
        ...state,
        appointmentLineItemsMap: secondLevelMerge(
          state.appointmentLineItemsMap || {},
          arrayToMap(
            (action.items as InvoiceLineItem[]) || [],
            R.prop('priceId'),
            R.identity,
          ),
        ),
      }
    case FETCH_APPOINTMENT_INVOICE_ITEMS_FAILURE:
      return { ...state, error: getErrorMessage(action.error) }
    case CLEAR_APPOINTMENT_INVOICE_ITEMS:
      return { ...state, appointmentLineItemsMap: null }
    case ADD_PAYMENT_TO_INVOICES:
      return { ...state, ...addPaymentToInvoices(action.payment, state) }
    case UPDATE_INVOICE_COMPLETENESS_FROM_SPAIN:
      return {
        ...state,
        invoiceLocaleOptions: {
          [CountryCode.ES]: {
            [action.invoiceId]: {
              complete: action.isCompleted,
            },
          },
        },
      }
    case EDIT_INVOICE_LINE_ITEM:
      return { ...state, isSaving: true, error: null }
    case EDIT_INVOICE_LINE_ITEM_SUCCESS:
      const foundInvoiceToEdit: InvoiceOrEstimate | Nil =
        state.invoicesMap[action.invoiceId]
      return {
        ...state,
        isSaving: false,
        invoicesMap: {
          ...state.invoicesMap,
          [action.invoiceId]: {
            ...(foundInvoiceToEdit || {}),
            groups: foundInvoiceToEdit?.groups?.map((group) => ({
              groupedItems: group.groupedItems?.map((items) => {
                if (items.id === action.id) {
                  return { ...items, ...action.newData }
                }
                return items
              }),
              soap: group.soap,
            })),
          },
        },
      }
    case EDIT_INVOICE_LINE_ITEM_FAILURE:
      return {
        ...state,
        isRearranging: false,
        error: getErrorMessage(action.error),
      }
    case REARRANGE_INVOICE_LINE_ITEMS:
      return {
        ...state,
        isRearranging: true,
        errorRearranging: null,
        error: null,
      }
    case REARRANGE_INVOICE_LINE_ITEMS_SUCCESS:
      return {
        ...state,
        isRearranging: false,
        errorRearranging: null,
        error: null,
      }
    case REARRANGE_INVOICE_LINE_ITEMS_FAILURE:
      const error = getErrorMessage(action.error)
      return { ...state, isRearranging: false, error, errorRearranging: error }
    case TOGGLE_INVOICE_GROUP_EXPANDED:
      const expandedSet = state.expandedGroupInvoicesIds
      return {
        ...state,
        expandedGroupInvoicesIds: R.has(action.invoiceGroupId, expandedSet)
          ? R.omit([action.invoiceGroupId], expandedSet)
          : { ...expandedSet, [action.invoiceGroupId]: true },
      }
    case CLEAR_INVOICE_GROUP_EXPANDED:
      return { ...state, expandedGroupInvoicesIds: {} }
    default:
      return state
  }
}

export default finance
export const getFinance = (state: RootState): FinanceState => state.finance
export const getBalanceHistory = (state: RootState) => getFinance(state).list
export const getFinanceInvoicesMap = (state: RootState) =>
  getFinance(state).invoicesMap
export const getFinanceInvoice = (id: string | Nil) =>
  createSelector(
    getFinanceInvoicesMap,
    (map: ReturnType<typeof getFinanceInvoicesMap>) =>
      id ? map[id] : undefined,
  )
export const getMultipleInvoices = (ids: string[]) =>
  createSelector(getFinanceInvoicesMap, (map) => R.props(ids, map))

export const getMultipleBalanceEntries = (list: BalanceListEntry[]) =>
  createSelector(
    [
      getFinanceInvoicesMap,
      getPaymentsMap,
      getGoPaymentsMap,
      getGoStripePaymentsMap,
    ],
    (
      invoiceMap: Record<string, InvoiceOrEstimate>,
      paymentsMap: Record<string, ExtendPayment>,
      goPaymentsMap: Record<string, ExtendPayment>,
      goStripePaymentsMap: Record<string, ExtendPayment>,
    ) => {
      const paymentMethodsMap: Record<string, Record<string, any>> = {
        Invoice: invoiceMap,
        Payment: paymentsMap,
        GoPayment: goPaymentsMap,
        GoStripePayment: goStripePaymentsMap,
      }
      return list.map((entry) =>
        entry ? paymentMethodsMap[entry.schema]?.[entry.id] || {} : {},
      )
    },
  )
export const getFinanceInvoiceByEventId = (
  eventId: string,
  clientId?: string | Nil,
) =>
  createSelector(getFinanceInvoicesMap, (map) =>
    Object.values(map).find(
      (invoice) => invoice.eventId === eventId && invoice.client === clientId,
    ),
  )
export const getFinanceTemplate = (state: RootState) =>
  getFinance(state).template
export const getFinanceIsLoading = (state: RootState) =>
  getFinance(state).isLoading
export const getFinanceIsEstimateDeleting = (state: RootState) =>
  getFinance(state).isEstimateDeleting
export const getFinanceIsSaving = (state: RootState) =>
  getFinance(state).isSaving
export const getFinanceIsRearranging = (state: RootState) =>
  getFinance(state).isRearranging
export const getFinanceIsFetching = (state: RootState) =>
  getFinance(state).isFetching
export const getFinanceIsFetchingSoapInvoices = (state: RootState) =>
  getFinance(state).isFetchingSoapInvoices
export const getFinanceTotalCount = (state: RootState) =>
  getFinance(state).totalCount
export const getFinanceLineItemsCandidates = (state: RootState) =>
  getFinance(state).lineItemsCandidates
export const getFinanceLineItemsBundleDiscount = (state: RootState) =>
  getFinance(state).bundleDiscount
export const getFinancePrintHtml = (state: RootState) =>
  getFinance(state).printHtml
export const getEstimatesList = (state: RootState) =>
  getFinance(state).estimatesList
export const getPatientEstimatesIds = (state: RootState) =>
  getFinance(state).patientEstimatesIdsList
export const getSortedPatientEstimates = (soapId?: string) =>
  createSelector(
    getPatientEstimatesIds,
    getFinanceInvoicesMap,
    (idsArray, map) => {
      const estimateMap = map as Record<string, Estimate>
      if (!idsArray.length) {
        return []
      }
      if (!soapId) {
        return idsArray.map((id: string) => estimateMap[id])
      }
      const byCreationDate = R.descend((id: string) =>
        new Date(estimateMap[id].creationDate as string).getTime(),
      )
      const thisSoapIds = R.sort(
        byCreationDate,
        idsArray.filter((id: string) =>
          estimateMap[id]?.assignedSoapDetails?.some(
            (soap: AssignedSoapDetail) => soap?.soapId === soapId,
          ),
        ),
      )
      const otherSoapIds = R.sort(
        byCreationDate,
        idsArray.filter((id: string) =>
          estimateMap[id]?.assignedSoapDetails?.every(
            (soap: AssignedSoapDetail) => soap?.soapId !== soapId,
          ),
        ),
      )
      const result = [...thisSoapIds, ...otherSoapIds].map(
        (id: string) => estimateMap[id],
      )
      return result
    },
  )
export const getPatientEstimatesTotalCount = (state: RootState) =>
  getFinance(state).patientEstimatesTotalCount
export const getFinanceIsConvertingToLineItems = (state: RootState) =>
  getFinance(state).isConvertingToLineItems
export const getDeletedLineItemsMap = (state: RootState) =>
  getFinance(state).deletedLineItemsMap
export const getAutoSaveInvoice = (state: RootState) =>
  getFinance(state).invoiceAutoSave
export const getFinanceError = (state: RootState) => getFinance(state).error
export const getClonedEstimateId = (state: RootState) =>
  getFinance(state).clonedEstimateId
export const getFinanceValidationErrorType = (state: RootState) =>
  getFinance(state).validationErrorType
export const getFinanceErrorRearranging = (state: RootState) =>
  getFinance(state).errorRearranging
export const getFinanceCheckoutInvoicesData = (state: RootState) =>
  getFinance(state).checkoutInvoicesData
export const getFinanceUnpaidInvoiceIds = (state: RootState) =>
  getFinance(state).unpaidInvoiceIds
export const getFinanceBatchInvoice = (state: RootState) =>
  getFinance(state).batchInvoice
export const getIsGeneratingInvoicePdf = (state: RootState) =>
  getFinance(state).isGeneratingInvoicePdf
export const getWellnessCoverage = (state: RootState) =>
  getFinanceCheckoutInvoicesData(state)?.wellnessCoverage
export const getAppointmentLineItemsMap = (state: RootState) =>
  getFinance(state).appointmentLineItemsMap
export const getFinanceInvoiceIsPosted = R.curry(
  (id, state) => getFinanceInvoicesMap(state)[id]?.posted ?? false,
)
export const getFinanceInvoiceLocaleOptions = (state: RootState) =>
  getFinance(state).invoiceLocaleOptions
const getExpandedInvoiceGroupIdsObj = (state: RootState) =>
  getFinance(state).expandedGroupInvoicesIds
export const getExpandedInvoiceGroupIds = createSelector(
  getExpandedInvoiceGroupIdsObj,
  (expandedInvoiceGroupIdsObject) => Object.keys(expandedInvoiceGroupIdsObject),
)
export const getInvoiceGroupId = (
  invoiceGroupId: string | Nil,
  soapId: string | Nil,
) => `${soapId || null}-${invoiceGroupId}`
export const getInvoiceGroupIdIsExpanded = (
  invoiceGroupId: string | Nil,
  soapId: string | Nil,
) =>
  createSelector(getExpandedInvoiceGroupIdsObj, (ids) =>
    invoiceGroupId
      ? R.has(getInvoiceGroupId(invoiceGroupId, soapId), ids)
      : false,
  )
