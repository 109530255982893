import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  CustomFieldValidatorState,
  FieldProp,
  Nil,
  useFields,
  Utils,
  Validators,
} from '@pbt/pbt-ui-components'
import { PuiDialogProps } from '@pbt/pbt-ui-components/src/components/dialog/PuiDialog'
import { CustomFieldValidator } from '@pbt/pbt-ui-components/src/types/validation'

import { RxPrescriptionOrigin, RxType } from '~/api/graphql/generated/types'
import useTeamMembers from '~/components/common/inputs/useTeamMembers'
import FeatureToggle from '~/constants/featureToggle'
import { PrescriptionWorkflowType } from '~/constants/PrescriptionWorkflow'
import { OrderType, PrescriptionItemState } from '~/constants/SOAPStates'
import { RootState } from '~/store'
import { fetchMember } from '~/store/actions/members'
import {
  clearCurrentOrderDetails,
  clearCurrentUnifiedOrder,
  fetchOrderDetailsGrouped,
  fetchUnifiedOrder,
} from '~/store/actions/orders'
import { getChargeSheetSubItemsBySoapId } from '~/store/duck/clientFinanceData'
import { useIsInjectableDrug } from '~/store/hooks/orders'
import {
  useGetIsCompoundingReasonDetailsRequired,
  useGetPrescriptionStateIdByName,
  useGetPrescriptionStateType,
  useGetPrescriptionWithPopulatedInventoryFields,
  useIsChewyActiveRxReadOnly,
} from '~/store/hooks/prescription'
import { usePrescriptionTemplates } from '~/store/hooks/prescriptionTemplates'
import { getCurrentBusinessIsOmniChannel } from '~/store/reducers/auth'
import {
  getAutoshipUnit,
  getFeatureToggle,
  getLicenseTypes,
} from '~/store/reducers/constants'
import { getMembersSelectList } from '~/store/reducers/members'
import {
  getCreatedOrder,
  getOrderDetails,
  getOrdersIsSending,
  getUnifiedOrder,
} from '~/store/reducers/orders'
import {
  getAppointmentId,
  getClientId,
  getDoctorId,
  getPatientId,
  getSoapId,
} from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'
import {
  ChargeSheetItemSection,
  CRUDOrderOptions,
  Order,
  Prescription,
} from '~/types'
import { createDeepEqualityComparator, getDVMLicensesNumbers } from '~/utils'
import {
  getIsCreatedPrescriptionChewyActiveRx,
  getPrescriptionGlobalInventoryId,
  getPrescriptionTypeByWorkflow,
  getPrescriptionWorkflow,
  getRxType,
  getWorkflowType,
} from '~/utils/prescription'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import { getGroupIdForPrescription } from '../../../soap/utils/prescriptionUtils'
import { PrescriptionBodyProps } from '../PrescriptionBody'
import { useChewyActiveWorkflow } from './useChewyActiveWorkflow'
import { useChewyItemOutOfStock } from './useChewyItemOutOfStock'
import { usePrescriptionSignature } from './usePrescriptionSignature'

const equateToNull = (value: any) => value || null
const comparePrescriptions = createDeepEqualityComparator({
  ignoreProperties: [
    'price',
    'inventory',
    'variation',
    'globalInventory',
    'globalVariation',
    'globalInventoryMapping',
    'globalVariationMapping',
    'drugInfo',
    'client',
    'previousEntityType',
    'rxType',
  ],
  propertyPickers: {
    signatureUrl: equateToNull,
    signedDate: equateToNull,
    signatureDate: equateToNull,
    signerId: equateToNull,
  },
})

export interface PrescriptionEditorProps
  extends Pick<BasePuiDialogProps, 'onClose'>,
  Pick<
    PrescriptionBodyProps,
    | 'automaticallyCreateTaskLabel'
    | 'showDiscussedWithClient'
    | 'showOnlyDrugVariation'
    | 'simpleAdministrationInstructions'
  > {
  areChargesPostedAndEditable?: boolean
  assigneeId?: string
  clientId: string
  disabledCustomCompound?: boolean
  isFood?: boolean
  onSave: (
    prescription: Order,
    options: CRUDOrderOptions,
    closeAfterSave?: boolean,
  ) => void
  outsideSoap?: boolean
  patientId: string
  prescription: Prescription
  selector?: (state: RootState) => any
  skipOrderFetching?: boolean
  soapId?: string
  taskId?: string | Nil
}

type PrescriptionEditorState = PrescriptionBodyProps & {
  disableDrafts: boolean
  getHasUnsavedChanges: PuiDialogProps['hasUnsavedChanges']
  hasUnsavedData: boolean
  isAutomateChewyRxTasksEnabled: boolean
  isChewyActiveRxReadOnly: boolean
  isChewyReactiveRxReadOnly: boolean
  isChewyReadOnly: boolean
  taskId: string | Nil
}

const usePrescriptionEditor = ({
  assigneeId,
  automaticallyCreateTaskLabel = '',
  clientId: clientIdProp,
  disabledCustomCompound = false,
  isFood,
  onClose,
  onSave,
  outsideSoap = false,
  patientId: patientIdProp,
  prescription: prescriptionProp,
  selector,
  showDiscussedWithClient = false,
  showOnlyDrugVariation = false,
  simpleAdministrationInstructions = false,
  skipOrderFetching = false,
  soapId: soapIdProp,
  areChargesPostedAndEditable,
  taskId,
}: PrescriptionEditorProps): PrescriptionEditorState => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { t } = useTranslation('Common')

  const soapDoctorId = useSelector(getDoctorId)
  const soapId = useSelector(getSoapId) || soapIdProp
  const createdOrder = useSelector(getCreatedOrder)
  const prescriptionDetails = useSelector(getOrderDetails)
  const unifiedOrder = useSelector(getUnifiedOrder)
  const membersList = useSelector(getMembersSelectList)
  const licenseTypes = useSelector(getLicenseTypes)
  const appointmentId = useSelector(getAppointmentId)
  const isOmnichannelBusiness = useSelector(getCurrentBusinessIsOmniChannel)
  const soapSections = useSelector(getChargeSheetSubItemsBySoapId(soapId))

  const assignedVetId = R.pipe<
    (typeof soapSections)[],
    ChargeSheetItemSection | undefined,
    ChargeSheetItemSection,
    string | undefined
  >(
    R.find((section: ChargeSheetItemSection) => section.soapId === soapId),
    R.defaultTo({} as ChargeSheetItemSection),
    R.path(['soap', 'assignedVetId']),
  )(soapSections)

  const isAutomateChewyRxTasksEnabled = useSelector(
    getFeatureToggle(FeatureToggle.AUTO_CREATE_TASKS_FOR_RX_FLOW),
  )
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )
  const isInjectableDefaultWorkflowEnabled = useSelector(
    getFeatureToggle(FeatureToggle.INJECTABLE_DEFAULT_WORKFLOW),
  )
  const isMultiBottleLevelFunctionalityToPrescriptionsEnabled = useSelector(
    getFeatureToggle(
      FeatureToggle.MULTI_BOTTLE_LEVEL_FUNCTIONALITY_TO_PRESCRIPTIONS,
    ),
  )

  const isIpoM1CheckoutEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M1_CHECKOUT),
  )
  const autoshipUnitsList = useSelector(getAutoshipUnit)

  const soapClientId =
    useSelector(getClientId) ??
    prescriptionProp.clientId ??
    (clientIdProp as string)
  const soapPatientId =
    useSelector(getPatientId) ??
    prescriptionProp.patientId ??
    (patientIdProp as string)
  const soapClient = useSelector(getUser(soapClientId))
  const clientId = outsideSoap ? clientIdProp : soapClientId
  const patientId = outsideSoap ? patientIdProp : soapPatientId

  const client = useSelector(getUser(clientId))

  const doctors = useTeamMembers(membersList)

  const setCloseAfterConvertPrescriptionUpdate = useCloseAfterCreation(
    onClose,
    selector || getOrdersIsSending,
  )
  const getIsCompoundingReasonDetailsRequired =
    useGetIsCompoundingReasonDetailsRequired()
  const getPrescriptionStateIdByName = useGetPrescriptionStateIdByName()
  const getPrescriptionWithPopulatedInventoryFields =
    useGetPrescriptionWithPopulatedInventoryFields()

  const createdPrescription =
    createdOrder?.type === OrderType.PRESCRIPTION ? createdOrder : null

  const [previousEntityType, setPreviousEntityType] = useState(
    prescriptionProp.type,
  )

  const [prescription, setPrescription] = useState<Prescription>(
    getPrescriptionWithPopulatedInventoryFields(prescriptionProp),
  )
  const isInjectableDrug = useIsInjectableDrug(prescription)
  const [activeWorkflow, setActiveWorkflow] =
    useState<PrescriptionWorkflowType>()
  const [isCustomCompound, setIsCustomCompound] = useState(
    Boolean(!disabledCustomCompound && prescription.drugInfo?.customCompound),
  )
  // used for checking changes
  const [initialPrescription, setInitialPrescription] = useState()
  const [isPrescriptionSetFromTemplate, setIsPrescriptionSetFromTemplate] =
    useState(false)

  const isEdit = Boolean(prescription.id || prescriptionDetails?.id)

  const { isDraft } = useGetPrescriptionStateType()(prescription?.stateId)

  const {
    isOrder,
    isChewyActiveRx,
    isChewyReactiveRx,
    isOutsidePharmacy,
    isInHouseRx,
  } = getWorkflowType(activeWorkflow)
  const [isTemplatesLoaded, setIsTemplatesLoaded] = useState(isEdit || isOrder)

  const isChewyReactiveRxReadOnly = isChewyReactiveRx && !isDraft
  const isChewyActiveRxReadOnly = useIsChewyActiveRxReadOnly({
    isChewyActiveRx,
    stateId: prescription?.stateId,
  })

  const isChewyReadOnly = isChewyReactiveRxReadOnly || isChewyActiveRxReadOnly
  const isChewyRx = isChewyReactiveRx || isChewyActiveRx
  const isChewyCatalogItemNotLinked =
    isChewyReactiveRx &&
    R.isNil(
      prescription[
      isFoodCatalogEnabled
        ? 'globalVariationMappingId'
        : prescription.rxType === RxType.VetDiet
          ? 'globalFoodCatalogVariationId'
          : 'globalVariationId'
      ],
    )

  const showDoctorSelect =
    isChewyRx || (outsideSoap && (isInHouseRx || isOutsidePharmacy))

  const currentClient = outsideSoap ? client : soapClient

  const automatedTaskWorkflows = [
    PrescriptionWorkflowType.PRESCRIBE,
    PrescriptionWorkflowType.OUTSIDE_PHARMACY,
    PrescriptionWorkflowType.CHEWY_ACTIVE_RX,
    PrescriptionWorkflowType.CHEWY_REACTIVE_RX,
  ]
  const activeWorkflowSupportsAutomatedTasks =
    !activeWorkflow || automatedTaskWorkflows.includes(activeWorkflow)
  const isAutomatedChewyTaskSupported =
    isAutomateChewyRxTasksEnabled &&
    isOmnichannelBusiness &&
    activeWorkflowSupportsAutomatedTasks
  const showAutomaticallyCreateTaskOption =
    Boolean(patientId) &&
    (currentClient?.isBoopUser || isAutomatedChewyTaskSupported)
  const disableDrafts = isAutomatedChewyTaskSupported && isOmnichannelBusiness

  const prescriptionInventoryId =
    prescription.inventory?.id || prescription.inventoryId
  const prescriptionGlobalInventoryId = getPrescriptionGlobalInventoryId(
    prescription,
    isFoodCatalogEnabled,
  )

  const { isVetDiet, isRxPrescription } = getRxType(prescription)

  const variationIsCompounded =
    prescription.variation?.compounded ??
    prescription[isVetDiet ? 'globalVariationMapping' : 'globalVariation']
      ?.compounded
  const isCompounded =
    (isChewyActiveRx && variationIsCompounded) ||
    (isChewyReactiveRx && prescription.drugInfo?.compounded)

  const isCompoundingReasonRequired = Boolean(
    !showOnlyDrugVariation && !isVetDiet && (isCustomCompound || isCompounded),
  )

  const validateDoctorId = ({ value }: CustomFieldValidatorState) =>
    doctors.some((doctor) => doctor.id === value)

  const compoundingReasonIdFieldValidator: CustomFieldValidator['validator'] =
    ({ value }) =>
      !isCompoundingReasonRequired || Validators.notEmptyFormField(value)

  const compoundingReasonFieldValidator: CustomFieldValidator['validator'] = ({
    state,
    value,
  }) => {
    const isCompoundingReasonDetailsRequired =
      getIsCompoundingReasonDetailsRequired(state.compoundingReasonId)
    return (
      !isCompoundingReasonRequired ||
      !isCompoundingReasonDetailsRequired ||
      Validators.notEmptyFormField(value)
    )
  }

  const initialDoctorId =
    isChewyActiveRx && !outsideSoap ? soapDoctorId : undefined

  const autoshipWeekUnitId = Utils.findConstantIdByName(
    'Week',
    autoshipUnitsList,
  ) as string

  const getFields = (obj: Prescription): FieldProp[] => [
    {
      name: 'doctorId',
      label: t('Common:SELECT_A_DOCTOR'),
      type: 'select',
      initialValue: obj.doctorId ?? initialDoctorId,
      validators: showDoctorSelect
        ? [
          'required',
          { validator: validateDoctorId, validatorName: 'required' },
        ]
        : [],
    },
    {
      name: 'teamMemberId',
      initialValue: assigneeId,
    },
    { name: 'saveAsDefault', type: 'toggle', initialValue: false },
    {
      name: 'automaticallyCreateTask',
      type: 'toggle',
      initialValue: isAutomateChewyRxTasksEnabled
        ? isAutomatedChewyTaskSupported || Boolean(obj.task)
        : Boolean(obj.task),
    },
    {
      name: 'compoundingReasonId',
      validators: [
        {
          validator: compoundingReasonIdFieldValidator,
          validatorName: 'required',
        },
      ],
      type: 'select',
      initialValue: obj.compoundingReasonId,
    },
    {
      name: 'compoundingReason',
      validators: [
        {
          validator: compoundingReasonFieldValidator,
          validatorName: 'required',
        },
      ],
      type: 'text',
      initialValue: obj.compoundingReason,
    },
    {
      name: 'chewyItem',
      ...(isChewyActiveRx ? { validators: ['required'] } : {}),
      type: 'text',
      initialValue: obj.partNumber,
    },
    {
      name: 'autoshipFrequency',
      type: 'number',
      initialValue: 12, // TODO: obj.autoshipFrequency ?? 12 when BE is complete (https://chewyinc.atlassian.net/browse/CVC-6412)
    },
    {
      name: 'autoshipUnitId',
      type: 'text',
      initialValue: autoshipWeekUnitId, // TODO: obj.autoshipUnitId ?? Utils.findConstantIdByName('Week', autoshipUnitsList) when BE is complete (https://chewyinc.atlassian.net/browse/CVC-6412)
    },
    {
      name: 'enableAutoship',
      type: 'toggle',
      initialValue: false, // TODO: obj.autoshipUnitId && obj,autoshipFrequency || false when BE is complete (https://chewyinc.atlassian.net/browse/CVC-6412)
    },
  ]

  const {
    fields: {
      automaticallyCreateTask,
      chewyItem,
      compoundingReason,
      compoundingReasonId,
      doctorId,
      saveAsDefault,
      teamMemberId,
      autoshipFrequency,
      autoshipUnitId,
      enableAutoship,
    },
    validate: validateFields,
    reset,
  } = useFields(getFields(prescription), false)

  useEffect(() => {
    // Forcibly set value to false if we don't show the option
    if (!showAutomaticallyCreateTaskOption && isAutomatedChewyTaskSupported) {
      automaticallyCreateTask.setValue(false)
    }
  }, [showAutomaticallyCreateTaskOption, isAutomatedChewyTaskSupported])

  const currentDoctorId = isChewyActiveRx
    ? doctorId.value
    : soapDoctorId || doctorId.value || assignedVetId
  const currentDoctor = useSelector(getUser(currentDoctorId))

  const { signatureData, isUserSignatureAreaEnabled } =
    usePrescriptionSignature({
      currentDoctor,
      isChewyActiveRx,
      prescription,
      prescriptionDetails,
      soapId,
      unifiedOrder,
    })

  const handleSetActiveWorkflow = (
    workflow: PrescriptionWorkflowType | undefined,
  ) => {
    if (workflow === PrescriptionWorkflowType.CHEWY_ACTIVE_RX) {
      // RHAP-1710 - After this ticket is done we need to adjust this rule
      // if (isEdit && activeWorkflow !== workflow) {
      //   setPrescription({
      //     ...prescription,
      //     stateId: getPrescriptionStateIdByName(PrescriptionItemState.DRAFT),
      //   })
      // }
      dispatch(
        fetchOrderDetailsGrouped({
          businessItemId: prescriptionInventoryId,
          globalInventoryCommonOrMappingId: prescriptionGlobalInventoryId,
          matchChewyItems: true,
        }),
      )
    }
    setActiveWorkflow(workflow)
  }

  const updateRxType = () => {
    // We should set this on creation, so we will be able to display fields correctly depending
    // on whether the prescription is food or medical. But this data should not be send to BE side
    if (isChewyActiveRx && R.isNil(prescription.rxType)) {
      setPrescription((prevPrescription) => ({
        ...prevPrescription,
        rxType: isFood ? RxType.VetDiet : RxType.MedicalPrescription,
      }))
    }
    if (
      !isChewyReactiveRx &&
      !isChewyActiveRx &&
      !R.isNil(prescription.rxType) &&
      !isChewyReadOnly
    ) {
      setPrescription((prevPrescription) => ({
        ...prevPrescription,
        rxType: null,
      }))
    }
  }

  useEffect(() => {
    updateRxType()
  }, [isChewyActiveRx])

  useEffect(() => {
    if (skipOrderFetching) {
      setPrescription(
        getPrescriptionWithPopulatedInventoryFields(prescriptionProp),
      )
    }
  }, [skipOrderFetching, prescriptionProp])

  useEffect(() => {
    if (!skipOrderFetching && prescription.id) {
      dispatch(fetchUnifiedOrder(prescription.id, prescription.type))
    }
    return () => {
      if (!skipOrderFetching) {
        dispatch(clearCurrentUnifiedOrder())
      }
    }
  }, [prescription.id, skipOrderFetching])

  useEffect(() => {
    if (!soapId && !outsideSoap && onClose) {
      onClose()
    }
  }, [soapId, outsideSoap])

  useEffectExceptOnMount(() => {
    reset(getFields(prescription))
  }, [prescription])

  const processingGlobalVariationId =
    prescription?.[
      isFoodCatalogEnabled ? 'globalVariationMapping' : 'globalVariation'
    ]?.id

  useLayoutEffect(() => {
    if (!activeWorkflow) {
      if (isEdit) {
        const workflow = getPrescriptionWorkflow({
          orderType: prescription.type,
          prescriptionType: prescription.prescriptionType,
          origin: prescription.origin,
        })
        handleSetActiveWorkflow(workflow)
      } else {
        let defaultActiveWorkflow = PrescriptionWorkflowType.PRESCRIBE
        if (isInjectableDrug && isInjectableDefaultWorkflowEnabled) {
          setIsTemplatesLoaded(true)
          defaultActiveWorkflow = PrescriptionWorkflowType.ORDER
        }
        // if we do not have local variation change the workflow to Outside pharmacy
        handleSetActiveWorkflow(
          !prescription?.variation
            ? PrescriptionWorkflowType.OUTSIDE_PHARMACY
            : defaultActiveWorkflow,
        )
      }
    }
  }, [prescription?.variation?.id, processingGlobalVariationId])

  useEffect(() => {
    if (
      prescriptionDetails?.type &&
      prescriptionDetails?.type !== previousEntityType
    ) {
      // after signing we need to change previous entity type
      setPreviousEntityType(prescriptionDetails?.type)
    }
  }, [prescriptionDetails?.type])

  const refs: PrescriptionBodyProps['refs'] = {
    administrationSectionRef: useRef(null),
    chargeSectionRef: useRef(null),
    controlledSubstanceSectionRef: useRef(null),
    discussedWithClientRef: useRef(null),
    dispensedFromSectionRef: useRef(null),
    drugCompoundInfoRef: useRef(null),
    quantitySectionRef: useRef(null),
    refillableSectionRef: useRef(null),
    taskSectionRef: useRef(null),
  }

  useEffect(() => {
    if (prescriptionInventoryId || prescriptionGlobalInventoryId) {
      dispatch(
        fetchOrderDetailsGrouped({
          businessItemId: prescriptionInventoryId,
          globalInventoryCommonOrMappingId: prescriptionGlobalInventoryId,
          matchChewyItems:
            isEdit &&
            getIsCreatedPrescriptionChewyActiveRx(
              prescription.prescriptionType,
              prescription.origin,
            ),
        }),
      )
    }
  }, [prescriptionInventoryId, prescriptionGlobalInventoryId])

  useEffect(() => {
    // cleanup current order on opening
    dispatch(clearCurrentOrderDetails())

    return () => {
      // cleanup current order on closing
      dispatch(clearCurrentOrderDetails())
    }
  }, [])

  const getValidSectionsRef = () => {
    const adminSectionValid = refs.administrationSectionRef.current
      ? refs.administrationSectionRef.current.validate()
      : true
    const quantitySectionValid = refs.quantitySectionRef.current
      ? refs.quantitySectionRef.current.validate()
      : true
    const discussedWithClientValid = refs.discussedWithClientRef.current
      ? refs.discussedWithClientRef.current.validate()
      : true
    const chargeSectionValid = refs.chargeSectionRef.current
      ? refs.chargeSectionRef.current.validate()
      : true
    const refillableSectionValid = refs.refillableSectionRef.current
      ? refs.refillableSectionRef.current.validate()
      : true

    const taskIsValid =
      showAutomaticallyCreateTaskOption &&
        automaticallyCreateTask.value &&
        refs.taskSectionRef.current
        ? refs.taskSectionRef.current.validate()
        : true

    return {
      adminSectionValid,
      quantitySectionValid,
      discussedWithClientValid,
      chargeSectionValid,
      refillableSectionValid,
      taskIsValid,
    }
  }

  const validate = () => {
    const {
      adminSectionValid,
      chargeSectionValid,
      discussedWithClientValid,
      quantitySectionValid,
      refillableSectionValid,
      taskIsValid,
    } = getValidSectionsRef()

    const fieldsAreValid = validateFields()

    return (
      adminSectionValid &&
      chargeSectionValid &&
      discussedWithClientValid &&
      fieldsAreValid &&
      quantitySectionValid &&
      refillableSectionValid &&
      taskIsValid
    )
  }

  const getData = () => {
    const dispensedFromData = refs.dispensedFromSectionRef.current?.get()

    return {
      ...R.omit(
        [
          ...(isCustomCompound
            ? ['variation', 'globalVariation', 'globalVariationMapping']
            : []),
          'price',
          'drugInfo',
          'task',
        ],
        prescription,
      ),
      eventId: appointmentId,
      lineItem: { ...prescription.lineItem },
      ...(isOutsidePharmacy || isChewyActiveRx ? signatureData : {}),
      id: createdPrescription?.id || prescription.id,
      ...(isOrder
        ? {}
        : isCustomCompound
          ? {
            drugInfo: {
              ...(prescription.drugInfo?.customCompound
                ? prescription.drugInfo
                : {}),
              ...(refs.drugCompoundInfoRef.current
                ? refs.drugCompoundInfoRef.current.get()
                : {}),
              customCompound: true,
            },
          }
          : { drugInfo: { customCompound: false } }),
      inventoryId: prescription.inventory?.id || null,
      variationId: !isCustomCompound
        ? prescription.variation?.id || null
        : null,
      globalInventoryId: prescription.globalInventory?.id,
      globalVariationId: !isCustomCompound
        ? prescription.globalVariation?.id || null
        : null,
      ...(isFoodCatalogEnabled
        ? {
          globalInventoryMappingId: prescription.globalInventoryMapping?.id,
          globalVariationMappingId: !isCustomCompound
            ? prescription.globalVariationMapping?.id || null
            : null,
        }
        : {}),
      ...(refs.administrationSectionRef.current?.get() || {}),
      ...(refs.chargeSectionRef.current?.get() || {}),
      ...(refs.controlledSubstanceSectionRef.current?.get() || {}),
      ...(refs.discussedWithClientRef.current?.get() || {}),
      ...(isMultiBottleLevelFunctionalityToPrescriptionsEnabled &&
        Array.isArray(dispensedFromData)
        ? {
          inventoryLineItemInfo: dispensedFromData.map((item) => ({
            inventoryShipmentItemId: item.inventoryShipmentItemId,
            inventoryLineItemQuantity: item.inventoryLineItemQuantity,
          })),
        }
        : dispensedFromData || {}),
      ...(refs.quantitySectionRef.current?.get() || {}),
      ...(refs.refillableSectionRef.current?.get() || {}),
      type: isOrder ? OrderType.INVENTORY : OrderType.PRESCRIPTION,
      prescriptionType: getPrescriptionTypeByWorkflow(
        activeWorkflow,
        isChewyActiveRx ? RxPrescriptionOrigin.Vet : prescription.origin,
      ),
      ...(isEdit ? { previousEntityType } : {}),
      ...(outsideSoap || isChewyActiveRx
        ? {
          doctorId: doctorId.value,
          clientId,
          patientId,
        }
        : {}),
      ...(!isOrder && saveAsDefault.value ? { storeDefault: true } : {}),
      ...(!isOrder &&
        showAutomaticallyCreateTaskOption &&
        automaticallyCreateTask.value
        ? { task: refs.taskSectionRef.current?.get() }
        : {}),
      compoundingReasonId: isCompoundingReasonRequired
        ? compoundingReasonId.value
        : null,
      compoundingReason:
        isCompoundingReasonRequired &&
          getIsCompoundingReasonDetailsRequired(compoundingReasonId.value)
          ? compoundingReason.value
          : null,
      ...(isChewyActiveRx ? { partNumber: chewyItem.value } : {}),
      group: getGroupIdForPrescription(location.search, unifiedOrder),
      quantityUnitId: null, // force server to remap quantityUnitId according to priceId
      ...(isIpoM1CheckoutEnabled && isOmnichannelBusiness
        ? {
          autoshipFrequency: enableAutoship.value
            ? autoshipFrequency.value
            : null,
          autoshipUnitId: enableAutoship.value ? autoshipUnitId.value : null,
        }
        : {}),
    }
  }

  const getHasUnsavedChanges = () => {
    const newPrescription = getData()
    if (isChewyReadOnly) {
      return false
    }
    return (
      Boolean(newPrescription) &&
      !comparePrescriptions(newPrescription, initialPrescription)
    )
  }

  const [hasUnsavedData, setHasUnsavedData] = useState(false)

  useEffect(() => {
    setHasUnsavedData(getHasUnsavedChanges())
  }, [
    prescription,
    isCustomCompound,
    isOutsidePharmacy,
    signatureData,
    createdPrescription,
    isOrder,
    isInHouseRx,
    isEdit,
    outsideSoap,
    doctorId,
    clientId,
    patientId,
    saveAsDefault,
    showAutomaticallyCreateTaskOption,
    automaticallyCreateTask,
    compoundingReasonId,
    compoundingReason,
    location.search,
    unifiedOrder,
    autoshipFrequency,
    autoshipUnitId,
    enableAutoship,
  ])

  const onRefChange = () => {
    setHasUnsavedData(getHasUnsavedChanges())
  }

  const handleSetPrescriptionWithTemplate = (newPrescription: Prescription) => {
    setPrescription(newPrescription)
    // in order to set initial template only one time from template check this flag
    if (!isPrescriptionSetFromTemplate) {
      // reset initial prescription to re-generate it from new one after template is set
      setInitialPrescription(undefined)
    }
    updateRxType()
    setIsPrescriptionSetFromTemplate(true)
  }

  usePrescriptionTemplates({
    prescription,
    patientId,
    activeWorkflow,
    doctorId: currentDoctorId,
    outsideSoap,
    onTemplateFetched: () => {
      setIsTemplatesLoaded(true)
    },
    onSetPrescription: handleSetPrescriptionWithTemplate,
  })

  useChewyActiveWorkflow({
    activeWorkflow,
    clientId,
    isChewyReactiveRx,
    patientId,
    globalVariationId:
      prescription.globalVariation?.id ?? prescription.globalVariationId,
    globalVariationMappingId:
      prescription.globalVariationMapping?.id ??
      prescription.globalVariationMappingId,
  })

  useChewyItemOutOfStock({
    chewyItemField: chewyItem,
    isDraft,
  })

  useEffectExceptOnMount(() => {
    if (
      prescription &&
      activeWorkflow &&
      !initialPrescription &&
      isTemplatesLoaded
    ) {
      setTimeout(() => setInitialPrescription(getData()))
    }
  }, [prescription, activeWorkflow, isTemplatesLoaded])

  const currentDoctorDVMLicenseNumber = getDVMLicensesNumbers(
    currentDoctor,
    licenseTypes,
  )

  const handleSave = (
    options: CRUDOrderOptions = {},
    closeAfterSave = true,
  ) => {
    if (
      options.isSaveDraft ||
      options.declineReason ||
      options.isActiveRxSaveDraft ||
      validate()
    ) {
      const prescriptionData = getData()

      if (
        (isOutsidePharmacy || isChewyActiveRx) &&
        !prescriptionData.quantity
      ) {
        // for outside pharmacy and chewy active RX quantity should be same as totalQuantity
        prescriptionData.quantity = prescriptionData.totalQuantity
      }

      if (prescriptionData.lineItem) {
        if (prescriptionData.lineItem.prepaid) {
          prescriptionData.lineItem.usedQuantity = prescriptionData.quantity
        } else {
          prescriptionData.lineItem.quantity = prescriptionData.quantity
        }
      }

      if (options.isApprove) {
        prescriptionData.stateId = getPrescriptionStateIdByName(
          PrescriptionItemState.APPROVED,
        )
      } else if (options.declineReason) {
        prescriptionData.stateId = getPrescriptionStateIdByName(
          PrescriptionItemState.DECLINED,
        )
        prescriptionData.rejectionReason = options.declineReason || null
      }

      if (prescriptionData.task) {
        const taskData = prescriptionData.task
        prescriptionData.clientTasks = {
          dueDateTime: taskData.dueDate,
          name: taskData.name,
          occurrencesCount: taskData.occurrencesCount,
          recurrenceEndDateTime: taskData.recurrenceEndDatetime,
          recurrenceOffset: taskData.recurrencePeriod
            ? {
              amount: taskData.recurrencePeriod.amount,
              unitId: taskData.recurrencePeriod.unit,
            }
            : undefined,
        }
      }

      onSave(
        prescriptionData,
        {
          doctorId: currentDoctorId,
          teamMemberId: teamMemberId.value,
          soapId,
          outsideSoap,
          ...options,
        },
        closeAfterSave,
      )
      if (closeAfterSave) {
        setCloseAfterConvertPrescriptionUpdate()
      }
    }
  }

  const isDvmLicenseRequired = isChewyRx
  const isLengthOfCourseRequired = isRxPrescription

  useEffect(() => {
    if (currentDoctorId && isDvmLicenseRequired) {
      dispatch(fetchMember(currentDoctorId, isChewyRx))
    }
  }, [currentDoctorId, isDvmLicenseRequired])

  const currentDoctorHasDvmLicense = R.isNil(currentDoctorId)
    ? null
    : currentDoctorDVMLicenseNumber.length > 0

  const handleVariationChange: PrescriptionBodyProps['handleVariationChange'] =
    (orderDetails, isCustom = false) => {
      if (!disabledCustomCompound) {
        setIsCustomCompound(isCustom)
      }

      if (isCustom && !isChewyRx) {
        // if custom compound we can only do OUTSIDE_PHARMACY or Chewy Active/Reactive Rx workflow
        handleSetActiveWorkflow(PrescriptionWorkflowType.OUTSIDE_PHARMACY)
      } else {
        const prescriptionWithNewVariation = {
          ...prescription,
          drugInfo: orderDetails?.variation
            ? R.pick(
              [
                'count',
                'customCompound',
                'customForm',
                'deliveryMethodId',
                'description',
                'flavor',
                'formId',
                'packageTypeId',
                'perPackageAmount',
                'perPackageUnitsId',
                'strength',
                'strengthColorId',
                'strengthUnitsId',
              ],
              orderDetails.variation,
            )
            : undefined,
          variation: orderDetails?.variation,
          globalVariation: orderDetails?.globalVariation,
          ...(isFoodCatalogEnabled
            ? {
              globalVariationMapping: orderDetails?.globalVariationMapping,
            }
            : {}),
        }
        setPrescription(
          getPrescriptionWithPopulatedInventoryFields(
            prescriptionWithNewVariation,
          ),
        )
      }
    }

  return {
    activeWorkflow,
    areChargesPostedAndEditable,
    disableDrafts,
    isAutomateChewyRxTasksEnabled,
    automaticallyCreateTaskLabel,
    clientId,
    currentDoctorHasDvmLicense,
    currentDoctorId,
    fields: {
      automaticallyCreateTask,
      chewyItem,
      compoundingReason,
      compoundingReasonId,
      doctorId,
      saveAsDefault,
      teamMemberId,
      autoshipFrequency,
      autoshipUnitId,
      enableAutoship,
    },
    getHasUnsavedChanges,
    handleSave,
    handleSetActiveWorkflow,
    handleVariationChange,
    hasUnsavedData,
    isChewyCatalogItemNotLinked,
    isChewyActiveRxReadOnly,
    isChewyReactiveRxReadOnly,
    isChewyReadOnly,
    isCompoundingReasonRequired,
    isCustomCompound,
    isDvmLicenseRequired,
    isEdit,
    isLengthOfCourseRequired,
    isRxPrescription,
    isTemplatesLoaded,
    isUserSignatureAreaEnabled,
    isVetDiet,
    onRefChange,
    outsideSoap,
    patientId,
    prescription,
    taskId,
    refs,
    showAutomaticallyCreateTaskOption,
    showDiscussedWithClient,
    showDoctorSelect,
    showOnlyDrugVariation,
    signatureData,
    simpleAdministrationInstructions,
    validate,
  }
}

export default usePrescriptionEditor
