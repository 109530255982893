import React, { useState } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Collapse, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Business, PuiTheme } from '@pbt/pbt-ui-components'
import { ChevronDown, ChevronUp } from '@pbt/pbt-ui-components/src/icons'

import PracticeTableName from './PracticeTableName'
import PracticeTableRow from './PracticeTableRow'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    groupHeaderText: {
      color: theme.colors.profileText,
    },
    groupContainer: {
      backgroundColor: theme.colors.primaryText,
      border: `1px solid ${theme.colors.primaryText}`,
      padding: 2,
      borderRadius: 2,
    },
    selectedHeaderRow: {
      boxShadow: theme.constants.listItemHeaderSelectedShadow,
    },
    listHeaderShadow: {
      '&:hover': {
        boxShadow: theme.constants.listItemHeaderShadow,
      },
    },
    groupItemRoot: {
      borderRadius: 0,
      backgroundColor: theme.colors.profileText,
      border: 'none',
      borderTop: theme.constants.tableBorder,
    },
    chevronIcon: {
      cursor: 'pointer',
      color: theme.colors.profileText,
      width: 20,
      height: 20,
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'PracticeTableGroupRow' },
)

export interface PracticeTableGroupRowProps {
  children: Business[]
  highlightProps: {
    internalName?: string[]
    practiceName?: string[]
  }
  isLoading: boolean
  isMobile: boolean
  isSelectedRow: boolean
  onClick?: (business: Business) => void
  practice: Business
  rowFocused?: string
}

const PracticeTableGroupRow = ({
  practice,
  children,
  isMobile,
  rowFocused,
  onClick,
  isSelectedRow,
  isLoading,
  highlightProps,
}: PracticeTableGroupRowProps) => {
  const classes = useStyles()

  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const IconChevronComponent = expanded ? ChevronUp : ChevronDown

  return (
    <Grid
      container
      className={classNames(classes.groupContainer, {
        [classes.listHeaderShadow]: !isSelectedRow,
        [classes.selectedHeaderRow]: isSelectedRow,
      })}
      direction="column"
      wrap="nowrap"
    >
      <Grid
        container
        item
        alignItems="center"
        justifyContent="space-between"
        pl={1.5}
        pr={2}
        py={0.25}
        wrap="nowrap"
      >
        <Grid
          container
          item
          wrap="nowrap"
          onClick={() => onClick && onClick(practice)}
        >
          <Dotdotdot clamp={1}>
            <PracticeTableName
              className={classes.groupHeaderText}
              highlights={highlightProps?.practiceName}
              isLoading={isLoading}
              name={practice?.name}
            />
          </Dotdotdot>
        </Grid>
        <IconChevronComponent
          className={classes.chevronIcon}
          onClick={toggleExpanded}
        />
      </Grid>
      <Collapse in={expanded}>
        {children.map((child) => (
          <PracticeTableRow
            className={classes.groupItemRoot}
            highlightProps={highlightProps}
            isLoading={isLoading}
            isMobile={isMobile}
            key={child.id}
            practice={child}
            rowFocused={rowFocused}
            onClick={onClick}
          />
        ))}
      </Collapse>
    </Grid>
  )
}
export default PracticeTableGroupRow
