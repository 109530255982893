import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { useDebounce } from 'use-debounce'
import { Defaults, PuiTheme, Text, Utils } from '@pbt/pbt-ui-components'

import QuantityInput from '~/components/common/inputs/QuantityInput'
import { ProcedureState } from '~/constants/SOAPStates'
import { partialEditOrder } from '~/store/actions/orders'
import { getProcedureStatus } from '~/store/reducers/constants'
import { Order } from '~/types'
import { calculateAvailableQuantity } from '~/utils/orderUtils'
import { useGetFormattedPriceUnit } from '~/utils/priceUtils'

import OrderStatusSelect from '../../../../to-do/OrderStatusSelect'
import ProcedureCompleteButton from '../buttons/ProcedureItemCompleteButton'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    fab: {
      height: 33,
      width: '100%',
      fontSize: '1.2rem',
      fontWeight: 500,
      padding: theme.spacing(0, 1),
    },
    fabSelected: {
      '&&&&:hover': {
        backgroundColor: theme.colors.selectedOption,
      },
      backgroundColor: theme.colors.selectedOption,
      color: theme.colors.primaryText,
    },
    grid: {
      width: 'calc(100% + 20px)',
      marginLeft: -20,
    },
    statusSelect: {
      width: 'fit-content',
      fontSize: '1rem',
    },
  }),
  { name: 'ProcedureButtonSection' },
)

export interface ProcedureButtonSectionProps {
  editDisabled?: boolean
  order: Order
  readOnly?: boolean
}

const ProcedureButtonSection = ({
  editDisabled,
  order,
  readOnly,
}: ProcedureButtonSectionProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Abbreviations', 'Common'])

  const ProcedureStatus = useSelector(getProcedureStatus)

  const [rawQuantity, setRawQuantity] = useState(order.quantity)

  const availableQuantity = calculateAvailableQuantity(order)
  const priceUnits = useGetFormattedPriceUnit(order.price, order.variation)

  const [quantity] = useDebounce(rawQuantity, Defaults.DEBOUNCE_ACTION_TIME)

  const getProcedureStateId = (name: string) =>
    Utils.findConstantIdByName(name, ProcedureStatus)

  const setState = (stateId: string) => {
    dispatch(
      partialEditOrder({
        id: order.id,
        type: order.type,
        stateId:
          stateId === order.stateId
            ? getProcedureStateId(ProcedureState.ORDERED)
            : stateId,
      }),
    )
  }

  useEffect(() => {
    setRawQuantity(order.quantity)
  }, [order.quantity])

  useEffect(() => {
    if (quantity !== order.quantity) {
      dispatch(
        partialEditOrder({
          id: order.id,
          type: order.type,
          quantity: quantity || 1,
        }),
      )
    }
  }, [quantity])

  return (
    <Grid container className={classes.grid}>
      <Grid item pr={1} pt={0.5}>
        {readOnly ? (
          <Grid container item direction="column" pl={2.5}>
            <Text variant="body2">
              {`${t('Abbreviations:COMMON.QUANTITY')}: ${
                order.quantity ? `${order.quantity} ` : ''
              }${priceUnits}`}
            </Text>
            <OrderStatusSelect
              disabled
              classes={{ statusSelect: classes.statusSelect }}
              order={order}
            />
          </Grid>
        ) : (
          <QuantityInput
            allowDecimal
            labelEnabled
            minReachedTooltipEnabled
            availableQuantity={availableQuantity}
            disabled={editDisabled}
            min={0.001}
            value={rawQuantity}
            onChange={(value) => setRawQuantity(value)}
          />
        )}
      </Grid>
      {!readOnly && (
        <Grid container item xs alignItems="center" spacing={1}>
          <Grid item xs={6}>
            <ProcedureCompleteButton disabled={editDisabled} order={order} />
          </Grid>
          <Grid item xs={6}>
            <Fab
              className={classNames(classes.fab, {
                [classes.fabSelected]:
                  order.stateId ===
                  getProcedureStateId(ProcedureState.DECLINED),
              })}
              color="primary"
              disabled={editDisabled}
              type="button"
              variant="extended"
              onClick={() =>
                setState(getProcedureStateId(ProcedureState.DECLINED))
              }
            >
              {order.stateId === getProcedureStateId(ProcedureState.DECLINED)
                ? t('Common:DECLINED')
                : t('Common:DECLINE_ACTION')}
            </Fab>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default ProcedureButtonSection
