import React, { forwardRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme } from '@pbt/pbt-ui-components'

// @ts-ignore
import OrderSelectableListWithFilters from '~/components/dashboard/soap/list-with-filters/OrderSelectableListWithFilters'
import { fetchOrdersFilters } from '~/store/actions/orders'
import { getBundlesIsLoading } from '~/store/reducers/bundles'
import {
  getOrdersFilters,
  getOrdersIsReceiving,
  getOrdersList,
  getOrdersTotalCount,
} from '~/store/reducers/orders'
import { Bundle, BundleItem, Order } from '~/types'
import { getItemId } from '~/utils/orderUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    buttonContainer: {
      paddingRight: theme.spacing(9),
    },
  }),
  { name: 'BundleItems' },
)

export interface BundleItemsHandle {
  getItems: () => BundleItem[]
}

interface BundleItemsProps {
  bundle: Bundle
  onBack: () => void
  onProceed: (bundleItems: BundleItem[]) => void
  proceedButtonLabel?: string
}

const BundleItems = forwardRef<BundleItemsHandle, BundleItemsProps>(
  function BundleItems({ bundle, proceedButtonLabel, onBack, onProceed }, ref) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const isLoading = useSelector(getBundlesIsLoading)
    const isReceivingOrders = useSelector(getOrdersIsReceiving)
    const ordersFilters = useSelector(getOrdersFilters)
    const orders = useSelector(getOrdersList)
    const ordersIsReceiving = useSelector(getOrdersIsReceiving)
    const ordersTotalCount = useSelector(getOrdersTotalCount)
    const { t } = useTranslation(['Common', 'Admin', 'Search'])
    const bundleItemsProceedButtonLabel = proceedButtonLabel || t('Common:NEXT')

    const getIsSelectableItem = (order: Order) => Boolean(order.price)

    useEffect(() => {
      if (!isReceivingOrders) {
        dispatch(fetchOrdersFilters())
      }
    }, [])

    return (
      <Grid container className={classes.root} direction="column" pt={2}>
        <OrderSelectableListWithFilters
          showBackButton
          showPrePaid
          showPrice
          showPriceUnits
          showQuantity
          showRange
          showTotalPrice
          classes={{ buttonContainer: classes.buttonContainer }}
          filters={ordersFilters}
          getUniqId={getItemId}
          hasMore={orders.length < ordersTotalCount}
          isLoading={isLoading}
          isReceivingListItems={ordersIsReceiving}
          isSelectableItem={getIsSelectableItem}
          listItems={orders}
          proceedButtonLabel={bundleItemsProceedButtonLabel}
          ref={ref}
          searchPlaceholder={t('Search:CATALOG')}
          selectedItems={bundle?.items}
          totalPriceLabel={t('Admin:CATALOG.BUNDLE_ITEMS.TOTAL_PRICE_LABEL')}
          onBack={onBack}
          onProceed={onProceed}
        />
      </Grid>
    )
  },
)

export default BundleItems
