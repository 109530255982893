import React from 'react'
import { Tab, TabProps, Tabs, TabsProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, PuiTheme } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tabs: {
      minHeight: 0,
      marginTop: theme.spacing(1),
      borderBottom: theme.constants.tableBorder,
    },
    tabRoot: {
      minWidth: 62,
      minHeight: 30,
      textTransform: 'none',
      color: theme.colors.tabLabel,
      fontSize: '1.2rem',
    },
  }),
  { name: 'PuiTabs' },
)

export type PuiTab = {
  hide?: boolean
  id?: string | number
  label: string
  tabElement?: React.ReactNode
}

export interface PuiTabsProps {
  PuiTabProps?: TabProps
  PuiTabsProps?: TabsProps
  actions?: React.ReactNode
  classes?: ClassesType<typeof useStyles>
  onSelectedTabChange: (index: number) => void
  selectedTab: number | boolean
  tabs: PuiTab[]
  variant?: TabsProps['variant']
}

const PuiTabs = ({
  actions,
  selectedTab,
  onSelectedTabChange,
  tabs,
  variant = 'fullWidth',
  classes: classesProps,
  PuiTabProps,
  PuiTabsProps,
}: PuiTabsProps) => {
  const classes = useStyles({ classes: classesProps })

  return (
    <Tabs
      className={classes.tabs}
      value={selectedTab}
      variant={variant}
      onChange={(event, tab) => onSelectedTabChange(tab)}
      {...PuiTabsProps}
    >
      {tabs.map(({ hide, id, label, tabElement }) => {
        const tabId = id?.toString() || label

        return (
          <Tab
            className={classes.tabRoot}
            id={tabId}
            key={tabId}
            label={tabElement ?? label}
            sx={{ display: hide ? 'none' : undefined }}
            {...PuiTabProps}
          />
        )
      })}
      {actions}
    </Tabs>
  )
}

export default PuiTabs
