import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LanguageUtils, StateLabel, Utils } from '@pbt/pbt-ui-components'

import {
  getDiagnosisProblemStates,
  getDiagnosisStatus,
} from '~/store/reducers/constants'
import { DiagnosisSnapshotItem, DiagnosisSnapshotItemType } from '~/types'

interface DiagnosisStatusCellProps {
  item: DiagnosisSnapshotItem
  shorten?: boolean
}

const DiagnosisStatusCell = ({ item, shorten }: DiagnosisStatusCellProps) => {
  const { t } = useTranslation(['Common', 'Clients'])
  const DiagnosisStatus = useSelector(getDiagnosisStatus)
  const ProblemDiagnosisStates = useSelector(getDiagnosisProblemStates)
  const diagnosisStates =
    item.type === DiagnosisSnapshotItemType.DIAGNOSIS_LOG
      ? DiagnosisStatus
      : ProblemDiagnosisStates

  const statusDisplayName = LanguageUtils.getConstantTranslatedName(
    item.statusId,
    diagnosisStates,
  )
  const activeStatus =
    item.statusId && Utils.findById(item.statusId, diagnosisStates)

  const isHistoryRecord = !item.soapId
  const stateName = isHistoryRecord
    ? shorten
      ? t('Common:PREVIOUS_CLINIC')
      : t('Clients:TIMELINE.DIAGNOSIS_CARD.RECORD_FROM_PLACE', {
          diagnosedIn: item.diagnosedIn || t('Common:PREVIOUS_CLINIC'),
        })
    : statusDisplayName

  return (
    <Dotdotdot clamp={1}>
      <StateLabel
        {...(activeStatus?.colorVariant
          ? { [activeStatus.colorVariant]: true }
          : {})}
      >
        {stateName}
      </StateLabel>
    </Dotdotdot>
  )
}

export default DiagnosisStatusCell
