import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { HtmlNotesPreview, Nil, PuiTheme, Text } from '@pbt/pbt-ui-components'

import MobilePhonePreview from '../preview/MobilePhonePreview'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    previewRoot: {
      margin: theme.spacing(-1, 2, 0, -2),
    },
    content: {
      padding: theme.spacing(0.5, 1),
      backgroundColor: theme.colors.patientAvatarBackground,
    },
  }),
  { name: 'AutomaticCommunicationTextMessagePreview' },
)

export interface AutomaticCommunicationTextMessagePreviewProps {
  textTemplate: string | Nil
}

const AutomaticCommunicationTextMessagePreview = ({
  textTemplate,
}: AutomaticCommunicationTextMessagePreviewProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  return (
    <Grid container item className={classes.root} direction="column">
      <Text strong variant="subheading3">
        {t('Common:TEXT_MESSAGE')}
      </Text>
      <MobilePhonePreview
        classes={{
          root: classes.previewRoot,
          content: classes.content,
        }}
      >
        {textTemplate && (
          <HtmlNotesPreview fontSize="1.2rem" notes={textTemplate} />
        )}
      </MobilePhonePreview>
    </Grid>
  )
}

export default AutomaticCommunicationTextMessagePreview
