import React from 'react'
import { Warning } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, PuiTheme, Text, TextProps } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    message: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: 104,
      backgroundColor: theme.colors.filterBackground,
      lineHeight: '14px',
    },
    icon: {
      verticalAlign: 'middle',
      width: 14,
      height: 14,
      color: theme.colors.tabLabel,
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'WarningLabel' },
)

export interface WarningLabelProps extends TextProps {
  classes?: ClassesType<typeof useStyles>
  message?: React.ReactNode
}

const WarningLabel = ({
  classes: classesProp,
  message = '',
  ...rest
}: WarningLabelProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Text
      strong
      align="left"
      className={classes.message}
      px={1}
      py={{ xs: 0.5, md: 1.5 }}
      variant="lowAccent3"
      {...rest}
    >
      <Warning className={classes.icon} />
      {message}
    </Text>
  )
}

export default WarningLabel
