import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ClassesType,
  LanguageUtils,
  Nil,
  PermissionArea,
  PuiSelect,
  PuiTextField,
  PuiTheme,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import InfoList from '~/components/common/InfoList'
import YesNoSelect from '~/components/common/inputs/YesNoSelect'
import PuiSwitch from '~/components/common/PuiSwitch'
import {
  InventoryCategoryName,
  InventoryGlobalCategoryNames,
} from '~/constants/inventoryCategory'
import {
  useIsFood,
  useIsGlobalInventoryItem,
  useIsVetDiet,
} from '~/store/hooks/orders'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getControlledSubstanceSchedules,
  getInventoryCategory,
  getInventorySubCategory,
} from '~/store/reducers/constants'
import { DataHandle, InventoryItem } from '~/types'
import useFieldsChanged from '~/utils/useFieldsChanged'

const useStyles = makeStyles(
  () => ({
    yesNoSelect: {
      fontSize: '1.4rem',
      paddingLeft: 0,
    },
  }),
  { name: 'Inventory' },
)

export interface InventoryHandle extends DataHandle {}

export interface InventoryProps {
  classes?: ClassesType<typeof useStyles>
  dialog?: boolean
  inventory: InventoryItem | Nil
  onChange?: () => void
}

const Inventory = forwardRef<InventoryHandle, InventoryProps>(
  function Inventory(
    { inventory, dialog, onChange = R.F, classes: classesProp },
    ref,
  ) {
    const classes = useStyles({ classes: classesProp })
    const { t } = useTranslation(['Admin', 'Common'])

    const InventoryCategory = useSelector(getInventoryCategory)
    const ControlledSubstanceSchedules = useSelector(
      getControlledSubstanceSchedules,
    )

    const controlledSubstanceSchedule = LanguageUtils.getConstantTranslatedName(
      inventory?.controlledSubstanceScheduleId,
      ControlledSubstanceSchedules,
    )
    const InventoryCategoryWithoutGlobalItems = InventoryCategory.filter(
      ({ name }) =>
        !InventoryGlobalCategoryNames.includes(name as InventoryCategoryName),
    )
    const InventorySubCategory = useSelector(getInventorySubCategory)
    const permissions = useSelector(getCRUDByArea(PermissionArea.INVENTORY))
    const isGlobalItem = useIsGlobalInventoryItem(inventory)
    const isVetDiet = useIsVetDiet(inventory)
    const isFood = useIsFood(inventory)

    const isMobile = useMediaQuery((theme: PuiTheme) =>
      theme.breakpoints.down('md'),
    )

    const { fields, validate, reset } = useFields(
      [
        {
          name: 'name',
          label: t('Common:NAME'),
          validators: ['required'],
          initialValue: inventory?.name,
        },
        {
          name: 'categoryId',
          label: t('Common:CATEGORY'),
          validators: ['required'],
          type: 'select',
          initialValue: inventory?.categoryId,
        },
        {
          name: 'subcategoryId',
          label: t('Common:SUB-CATEGORY'),
          validators: ['required'],
          type: 'select',
          initialValue: inventory?.subcategoryId,
        },
        {
          name: 'description',
          label: t('Common:DESCRIPTION'),
          initialValue: inventory?.description || '',
        },
        { name: 'pdmpReport', initialValue: inventory?.pdmpReport || false },
        {
          name: 'requiresPrescription',
          label: t('Admin:CATALOG.INVENTORY.REQUIRES_PRESCRIPTION'),
          type: 'toggle',
          initialValue: inventory?.requiresPrescription,
        },
      ],
      false,
    )

    useFieldsChanged(() => {
      onChange()
    }, fields)

    const {
      name,
      categoryId,
      subcategoryId,
      description,
      pdmpReport,
      requiresPrescription,
    } = fields

    useImperativeHandle(ref, () => ({
      validate,
      get: () => ({
        name: name.value,
        categoryId: categoryId.value,
        subcategoryId: subcategoryId.value,
        description: description.value,
        pdmpReport: pdmpReport.value,
        requiresPrescription: requiresPrescription.value,
      }),
    }))

    useEffect(() => {
      reset()
    }, [inventory])

    useEffect(() => {
      if (
        categoryId.value !== categoryId.initialValue &&
        (dialog || !isGlobalItem)
      ) {
        subcategoryId.setValue(undefined)
      }
    }, [categoryId.value, dialog, isGlobalItem])

    useEffect(() => {
      if (!inventory?.id) {
        const isFoodPrescriptionSubCategory =
          categoryId.value &&
          subcategoryId.value &&
          Utils.getConstantName(
            subcategoryId.value,
            InventorySubCategory[categoryId.value],
          ) === 'Prescription food'

        requiresPrescription.setValue(isFoodPrescriptionSubCategory)
      }
    }, [subcategoryId.value])

    const globalItemBaseInfoList = [
      {
        name: t('Common:CATEGORY'),
        value: LanguageUtils.getConstantTranslatedName(
          inventory?.categoryId,
          InventoryCategory,
        ),
      },
      {
        name: t('Common:SUB-CATEGORY'),
        value: LanguageUtils.getConstantTranslatedName(
          inventory?.subcategoryId,
          inventory?.categoryId
            ? InventorySubCategory[inventory.categoryId]
            : [],
        ),
      },
    ]

    const globalItemInfoList = isVetDiet
      ? globalItemBaseInfoList
      : [
          ...globalItemBaseInfoList,
          {
            name: t('Common:CONTROLLED_SUBSTANCE'),
            value: inventory?.controlled
              ? controlledSubstanceSchedule
                ? t('Admin:DRUG_CONTROLLER_SUBSTANCE_SCHEDULE', {
                    controlledSubstanceSchedule,
                  })
                : t('Common:YES')
              : t('Common:NO'),
          },
          {
            name: t(
              'Admin:CATALOG.INVENTORY.INFO_LIST.PRESCRIPTION_DRUG_MONITORING_PROGRAM_REPORTING',
            ),
            component: (
              <YesNoSelect
                disableUnderline
                classes={{
                  select: classes.yesNoSelect,
                }}
                disabled={!permissions.update}
                input={<Input id="PDMP-select" />}
                value={pdmpReport.value}
                onChange={Utils.handleFormSelectInput((val) =>
                  pdmpReport.setValue(Boolean(Number(val))),
                )}
              />
            ),
          },
          {
            name: t('Admin:CATALOG.INVENTORY.INFO_LIST.BRAND_NAME'),
            value: inventory?.brandName || '',
          },
          {
            name: t('Admin:CATALOG.INVENTORY.INFO_LIST.ACTIVE_INGREDIENT'),
            value: inventory?.activeIngredient || '',
          },
        ]

    return (
      <>
        {(dialog || !isGlobalItem) && (
          <Grid item md={dialog ? 12 : 8} xs={12}>
            <PuiTextField
              disabled={!permissions.update}
              field={name}
              inputProps={{ maxLength: 100 }}
              label={`${name.label}*`}
              margin="none"
            />
          </Grid>
        )}
        {!dialog && !isGlobalItem && (
          <Grid item md={6} xs={12}>
            <FormControl fullWidth margin="none">
              <InputLabel htmlFor="category-select">
                {categoryId.label}*
              </InputLabel>
              <PuiSelect
                disabled={!permissions.update}
                field={categoryId}
                input={<Input id="category-select" />}
                items={InventoryCategoryWithoutGlobalItems}
              />
            </FormControl>
          </Grid>
        )}
        {(dialog || !isGlobalItem) && (
          <Grid item md={dialog ? 7 : 6} xs={12}>
            <FormControl fullWidth margin="none">
              <InputLabel htmlFor="subcategory-select">
                {subcategoryId.label}*
              </InputLabel>
              <PuiSelect
                disabled={!permissions.update}
                field={subcategoryId}
                input={<Input id="subcategory-select" />}
                items={
                  categoryId.value ? InventorySubCategory[categoryId.value] : []
                }
              />
            </FormControl>
          </Grid>
        )}
        {isGlobalItem && !dialog && (
          <Grid container item direction="column" px={2}>
            <Text variant="h2">{inventory?.name}</Text>
            <InfoList items={globalItemInfoList} />
          </Grid>
        )}
        {isFood && (
          <Grid item xs={12}>
            <PuiSwitch
              disabled={!permissions.update}
              field={requiresPrescription}
              label={requiresPrescription.label}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Text strong mt={1} variant={isMobile ? 'body' : 'body2'}>
            {description.label}
          </Text>
          <Grid item xs>
            <PuiTextField
              multiline
              disabled={!permissions.update}
              field={description}
              id="description"
              inputProps={{
                maxLength: 1000,
              }}
              margin="none"
              maxRows={4}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </>
    )
  },
)

export default Inventory
