import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  Defaults,
  ErrorTooltip,
  Field,
  PasswordAdornment,
  PasswordInput,
  PhoneUtils,
  PuiAutocomplete,
  PuiTextField,
  PuiTheme,
  useFields,
  Utils,
  Validators,
} from '@pbt/pbt-ui-components'

import PhoneInput from '~/components/common/form-inputs/PhoneInput'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import { registrationRequest, savePerson } from '~/store/actions/registration'
import { createPerson } from '~/store/dto/Person'
import { getPims } from '~/store/reducers/constants'
import {
  getIsActivationKeyFlow,
  getIsUserAlreadyExist,
  getRegistrationBusinesses,
  getRegistrationError,
  getRegistrationIsLoading,
  getRegistrationPerson,
} from '~/store/reducers/registration'
import { removeServerErrorPrefix } from '~/utils/errors'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useIsAuthenticated from '~/utils/useIsAuthenticated'

import RegistrationPage from './RegistrationPage'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    input: {
      marginTop: 0,
    },
    inputContainer: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    form: {
      paddingBottom: 70,
      [theme.breakpoints.down('md')]: {
        paddingBottom: 49,
      },
    },
    formWithShownPassword: {
      paddingBottom: 131,
      [theme.breakpoints.down('md')]: {
        paddingBottom: 109,
      },
    },
  }),
  { name: 'ManagerDetails' },
)

const ManagerDetails = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )
  const dispatch = useDispatch()
  const registrationError = useSelector(getRegistrationError)
  const userAlreadyExist = useSelector(getIsUserAlreadyExist)
  const person = useSelector(getRegistrationPerson)
  const businesses = useSelector(getRegistrationBusinesses)
  const isLoading = useSelector(getRegistrationIsLoading)
  const isActivationFlow = useSelector(getIsActivationKeyFlow)
  const isUserAlreadyExist = useSelector(getIsUserAlreadyExist)
  const Pims: string[] = useSelector(getPims)
  const { t } = useTranslation([
    'Abbreviations',
    'Common',
    'Registration',
    'Tooltips',
    'Validations',
  ])

  const { isAuthenticated } = useIsAuthenticated()

  const [firstName, setFirstName] = useState(person?.firstName || '')
  const [email, setEmail] = useState(person?.email || '')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [mobilePhone, setMobilePhone] = useState(
    PhoneUtils.getPhoneNumber(person?.phone),
  )
  const [lastName, setLastName] = useState(person?.lastName || '')
  const [showPassword, setShowPassword] = useState(false)
  const requiredFields = [firstName, email, password, mobilePhone, lastName]
  const [touched, setTouched] = useState(true)
  const [requiredFieldsEmptyFlagShow, setRequiredFieldsEmptyFlagShow] =
    useState(false)
  const [emailFieldFlagShow, setEmailFieldFlagShow] = useState(false)
  const [phoneFieldFlagShow, setPhoneFieldFlagShow] = useState(false)
  const [passwordFieldFlagShow, setPasswordFieldFlagShow] = useState(false)
  const [
    passwordConfirmationFieldFlagShow,
    setPasswordConfirmationFieldFlagShow,
  ] = useState(false)
  const [registrationErrorMessage, setRegistrationErrorMessage] =
    useState<string>()

  const {
    fields: { pims },
  } = useFields([
    {
      name: 'pims',
      label: t(
        'Abbreviations:ACRONYMS.PRODUCTION_INFORMATION_MANAGEMENT_SYSTEM.WHAT_IS_YOUR_CURRENT',
      ),
      initialValue: person?.pims || '',
    },
  ])

  useEffect(() => {
    setRequiredFieldsEmptyFlagShow(false)
    setPasswordConfirmationFieldFlagShow(false)
    setPasswordFieldFlagShow(false)
    setEmailFieldFlagShow(false)
    setPhoneFieldFlagShow(false)
  }, [...requiredFields, showPassword])

  useEffect(() => {
    if (registrationError) {
      setRegistrationErrorMessage(removeServerErrorPrefix(registrationError))
      setEmailFieldFlagShow(true)
      setTouched(false)
    }
  }, [registrationError])

  const validate = () => {
    const notAllSet = requiredFields.reduce(
      (acc, value) => acc || !value,
      false,
    )
    setRequiredFieldsEmptyFlagShow(notAllSet)
    const phoneValid = Validators.isPhoneValid(mobilePhone)
    setPhoneFieldFlagShow(!phoneValid)
    const emailValid = Validators.isEmailValid(email)
    if (!emailValid) {
      setRegistrationErrorMessage(undefined)
    }
    setEmailFieldFlagShow(!emailValid)
    const passwordValid = Validators.isPasswordValid(password)
    setPasswordFieldFlagShow(!passwordValid)
    const passwordConfirmationValid =
      Validators.isPasswordConfirmationValid(passwordConfirmation, password) ||
      showPassword
    setPasswordConfirmationFieldFlagShow(!passwordConfirmationValid)
    return (
      !notAllSet &&
      phoneValid &&
      emailValid &&
      passwordValid &&
      passwordConfirmationValid
    )
  }

  const saveManagerDetails = () => {
    setTouched(false)
    if (!validate()) {
      return false
    }
    dispatch(
      savePerson(
        createPerson({
          firstName,
          email,
          password,
          phone: PhoneUtils.parsePhoneNumber(mobilePhone),
          pims: pims.value,
          lastName,
        }),
      ),
    )
    return true
  }

  const inviteTeam = () => {
    if (saveManagerDetails()) {
      navigate('/register/members')
    }
  }

  const onRegistrationRequest = async () => {
    if (!registrationError && !isUserAlreadyExist) {
      navigate('/register/termsAndConditions')
    }
  }

  const setOnRegistrationRequest = useCloseAfterCreation(
    onRegistrationRequest,
    getRegistrationIsLoading,
  )

  const finishRegistration = () => {
    if (saveManagerDetails()) {
      if (!isActivationFlow) {
        setOnRegistrationRequest()
        dispatch(registrationRequest())
      } else {
        navigate('/register/activation/questions')
      }
    }
  }

  if (isAuthenticated) {
    return <Navigate replace to="/" />
  }
  if (!businesses || !Object.values(businesses).length) {
    return <Navigate replace to="/register" />
  }

  return (
    <RegistrationPage
      disabled={isLoading}
      header={`${t('Common:GREAT')}!`}
      rejectLabel={t('Common:GET_STARTED_NOW')}
      subheader={
        isMobile
          ? t('Registration:MANAGER_DETAILS.SUB_HEADER_MOBILE_TEXT')
          : t('Registration:MANAGER_DETAILS.SUB_HEADER_TEXT')
      }
      submitLabel={t('Registration:MANAGER_DETAILS.SUBMIT_LABEL')}
      onReject={finishRegistration}
      onSubmit={inviteTeam}
    >
      <Grid
        container
        className={classNames({
          [classes.form]: !showPassword,
          [classes.formWithShownPassword]: showPassword,
        })}
        spacing={1}
      >
        <Grid item md={6} xs={12}>
          <div className={classes.inputContainer}>
            <PuiTextField
              autoFocus
              className={classes.input}
              error={!touched && !firstName}
              inputProps={{ maxLength: 100 }}
              label={`${t('Common:FIRST_NAME')}*`}
              value={firstName}
              onChange={Utils.handleFormTextInput(setFirstName)}
            />
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className={classes.inputContainer}>
            <PuiTextField
              className={classes.input}
              error={!touched && !lastName}
              inputProps={{ maxLength: 100 }}
              label={`${t('Common:LAST_NAME')}*`}
              value={lastName}
              onChange={Utils.handleFormTextInput(setLastName)}
            />
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className={classes.inputContainer}>
            <PhoneInput
              className={classes.input}
              field={
                {
                  value: mobilePhone,
                  set: Utils.handleFormTextInput(setMobilePhone),
                  label: `${t('Common:MOBILE_PHONE_NUMBER')}*`,
                  message: t('Tooltips:PLEASE_ENTER_VALID_PHONE_NUMBER'),
                  open:
                    !touched &&
                    phoneFieldFlagShow &&
                    !requiredFieldsEmptyFlagShow,
                  valid: touched || Validators.isPhoneValid(mobilePhone),
                } as Field
              }
            />
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className={classes.inputContainer}>
            <ErrorTooltip
              message={
                registrationErrorMessage || t('Validations:EMAIL_INVALID')
              }
              open={
                !touched &&
                emailFieldFlagShow &&
                !requiredFieldsEmptyFlagShow &&
                !phoneFieldFlagShow &&
                !userAlreadyExist
              }
            >
              <PuiTextField
                autoComplete="email"
                className={classes.input}
                error={!touched && !Validators.isEmailValid(email)}
                inputProps={{ maxLength: 100 }}
                label={`${t('Common:EMAIL')}*`}
                type="email"
                value={email}
                onChange={Utils.handleFormTextInput(setEmail)}
              />
            </ErrorTooltip>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.inputContainer}>
            <ErrorTooltip
              message={t('Tooltips:PASSWORD_MUST_BE_AT_LEAST_X_CHARACTERS', {
                minLength: Defaults.REQUIRED_PASSWORD_LENGTH,
              })}
              open={
                !touched &&
                passwordFieldFlagShow &&
                !requiredFieldsEmptyFlagShow &&
                !emailFieldFlagShow &&
                !phoneFieldFlagShow
              }
            >
              <Grid container alignItems="center" wrap="nowrap">
                <PasswordInput
                  adornmentEnabled
                  className={classes.input}
                  error={!touched && !Validators.isPasswordValid(password)}
                  label={`${t('Common:CREATE_A_PASSWORD')}*`}
                  password={password}
                  onChange={Utils.handleFormTextInput(setPassword)}
                  onShowPassword={setShowPassword}
                />
              </Grid>
            </ErrorTooltip>
          </div>
        </Grid>
        {!showPassword && (
          <Grid item xs={12}>
            <div className={classes.inputContainer}>
              <ErrorTooltip
                message={t('Tooltips:PASSWORDS_DO_NOT_MATCH')}
                open={
                  !touched &&
                  passwordConfirmationFieldFlagShow &&
                  !requiredFieldsEmptyFlagShow &&
                  !passwordFieldFlagShow &&
                  !emailFieldFlagShow &&
                  !phoneFieldFlagShow
                }
              >
                <PuiTextField
                  InputProps={{
                    endAdornment: (
                      <PasswordAdornment
                        condition={Validators.isPasswordConfirmationValid(
                          passwordConfirmation,
                          password,
                        )}
                      />
                    ),
                    inputProps: {
                      maxLength: 100,
                    },
                  }}
                  className={classes.input}
                  error={
                    !touched &&
                    !Validators.isPasswordConfirmationValid(
                      passwordConfirmation,
                      password,
                    )
                  }
                  label={`${t('Common:CONFIRM_PASSWORD')}*`}
                  type="password"
                  value={passwordConfirmation}
                  onChange={Utils.handleFormTextInput(setPasswordConfirmation)}
                />
              </ErrorTooltip>
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <div className={classes.inputContainer}>
            <PuiAutocomplete
              freeSolo
              className={classes.input}
              field={pims}
              inputProps={{ maxLength: 100 }}
              label={pims.label}
              maxSuggestions={5}
              options={Pims.map((_) => ({ name: _, id: _ }))}
            />
          </div>
        </Grid>
        <Grid item pl={1} xs={12}>
          <RequiredFieldsNotice
            hasRequiredErrors={!touched && requiredFieldsEmptyFlagShow}
          />
        </Grid>
      </Grid>
    </RegistrationPage>
  )
}

export default ManagerDetails
