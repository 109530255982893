import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { loginRequest } from '~/store/actions/auth'
import { getAuthIsFetching } from '~/store/reducers/auth'
import { getRegistrationPerson } from '~/store/reducers/registration'
import useLogout from '~/utils/useLogout'

import TermsAndConditionsWithAccept from '../terms-and-conditions/TermsAndConditionsWithAccept'

const TermsAndConditionsComponent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const person = useSelector(getRegistrationPerson)
  const isFetching = useSelector(getAuthIsFetching)

  const [wasFetching, setWasFetching] = useState(false)

  const logout = useLogout()

  useEffect(() => {
    if (wasFetching && !isFetching) {
      navigate('/')
    }
  }, [isFetching])

  const onAccept = async () => {
    if (person) {
      dispatch(loginRequest(person?.email, person?.password, true))
      setWasFetching(true)
    }
  }

  useEffect(() => {
    if (!person || !person.email) {
      logout('/auth/login')
    }
  }, [])

  return (
    <TermsAndConditionsWithAccept
      isRegistration
      disableAcceptButton={isFetching}
      onAccept={onAccept}
    />
  )
}

export default TermsAndConditionsComponent
