import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, PuiDialog, PuiTheme } from '@pbt/pbt-ui-components'
import {
  List as ListIcon,
  LoveList as LoveListIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { OrderType } from '~/constants/SOAPStates'
import { createOrder, fetchOrdersFilters } from '~/store/actions/orders'
import { useLogItemStateGetter } from '~/store/hooks/orders'
import {
  getOrdersFilters,
  getOrdersIsReceiving,
  getOrdersList,
  getOrdersTotalCount,
  getSelectedOrders,
} from '~/store/reducers/orders'
import {
  getAppointmentId,
  getClientId,
  getPatientId,
} from '~/store/reducers/soap'
import { LabTest, ListType, Order, OrderListType, Price } from '~/types'
import { arrayToMap } from '~/utils'

// @ts-ignore
import OrderSelectableListWithFilters from '../../list-with-filters/OrderSelectableListWithFilters'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 976,
      maxWidth: 976,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    dialogContentRoot: {
      paddingTop: theme.spacing(1),
    },
  }),
  { name: 'AddNewLabTestDialog' },
)

const getLabTestUniqId = ({
  labTest,
  price,
}: {
  labTest?: LabTest
  price: Price
}) => `${labTest?.id}_${price?.id}`

interface AddNewLabTestDialogProps extends BasePuiDialogProps {
  soapId?: string
  state: string
  vendorId: string
}

const AddNewLabTestDialog = ({
  onClose,
  open,
  vendorId,
  soapId,
  state,
}: AddNewLabTestDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const patientId = useSelector(getPatientId)
  const clientId = useSelector(getClientId)
  const ordersFilters = useSelector(getOrdersFilters)
  const ordersIsReceiving = useSelector(getOrdersIsReceiving)
  const appointmentId = useSelector(getAppointmentId)
  const orders = useSelector(getOrdersList)
  const ordersTotalCount = useSelector(getOrdersTotalCount)
  const selectedOrders = useSelector(getSelectedOrders)
  const { t } = useTranslation(['Common', 'Dialogs', 'Search'])

  const logItemStateGetter = useLogItemStateGetter()

  const LabTestListTypesFilters: ListType[] = [
    {
      id: OrderListType.OUR,
      name: t('Common:OUR_LIST'),
      isDefault: true,
      Icon: LoveListIcon,
    },
    {
      id: OrderListType.FULL,
      name: t('Common:FULL_LIST'),
      isDefault: false,
      Icon: ListIcon,
    },
  ]

  const labTestFilters = ordersFilters.filter(
    ({ type }) => type === OrderType.LAB_TEST,
  )

  useEffect(() => {
    if (ordersFilters.length === 0 && !ordersIsReceiving) {
      dispatch(fetchOrdersFilters())
    }
  }, [])

  const selectedOrdersMap = arrayToMap(selectedOrders, getLabTestUniqId, true)

  const isSelectedLabTestOrder = (order: Order) =>
    selectedOrdersMap[getLabTestUniqId(order)]

  const filteredOrders = orders.filter(
    (order) => !isSelectedLabTestOrder(order),
  )

  const addNewLabTest = (labTests: Order[]) => {
    const readyForCreation = labTests.map((labTest) =>
      logItemStateGetter(labTest, state),
    )
    readyForCreation.map((labTest) =>
      dispatch(createOrder(labTest!, { soapId })),
    )

    onClose?.()
  }

  return (
    <PuiDialog
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={t('Dialogs:ADD_NEW_LAB_TEST_DIALOG.TITLE')}
      onClose={onClose}
    >
      <OrderSelectableListWithFilters
        showPrice
        clientId={clientId}
        eventId={appointmentId}
        filters={labTestFilters}
        hasMore={orders.length < ordersTotalCount}
        isLoading={ordersIsReceiving}
        isReceivingListItems={ordersIsReceiving}
        labVendorId={vendorId}
        listItems={filteredOrders}
        listTypes={LabTestListTypesFilters}
        patientId={patientId}
        proceedButtonLabel={t('Common:ADD_TO_ORDER')}
        searchPlaceholder={t('Search:CATALOG')}
        onProceed={addNewLabTest}
      />
    </PuiDialog>
  )
}

export default AddNewLabTestDialog
