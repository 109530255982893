import { Defaults, Nil } from '@pbt/pbt-ui-components'

import {
  ReminderProtocol,
  ReminderProtocolGroup,
  UnsavedReminderProtocolGroup,
} from '~/types'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchReminderProtocolGroups = (
  businessId: string,
  query: string | Nil,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  request(
    getAPIPath('admin/services/reminder-groups'),
    { method: 'GET', params: { businessId, query, from, to } },
    true,
    Schema.reminderProtocolGroupsList,
  )

export const fetchReminderProtocolGroup = (
  businessId: string,
  groupId: string,
) =>
  request(
    getAPIPath(`admin/services/reminder-groups/${groupId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.reminderProtocolGroup,
  )

export const createReminderProtocolGroup = (
  businessId: string,
  group: UnsavedReminderProtocolGroup,
) =>
  request(
    getAPIPath('admin/services/reminder-groups'),
    { method: 'POST', data: group, params: { businessId } },
    true,
    Schema.reminderProtocolGroup,
  )

export const updateReminderProtocolGroup = (
  businessId: string,
  group: ReminderProtocolGroup,
) =>
  request(
    getAPIPath(`admin/services/reminder-groups/${group.id}`),
    { method: 'PUT', data: group, params: { businessId } },
    true,
    Schema.reminderProtocolGroup,
  )

export const deleteReminderProtocolGroup = (
  businessId: string,
  groupId: string,
) =>
  request(
    getAPIPath(`admin/services/reminder-groups/${groupId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const fetchReminderProtocols = (
  businessId: string,
  query: string | Nil,
  speciesId: string | Nil,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  request(
    getAPIPath('admin/services/reminder-protocols'),
    { method: 'GET', params: { query, speciesId, from, to, businessId } },
    true,
  )

export const createReminderProtocol = (
  businessId: string,
  groupId: string,
  protocol: ReminderProtocol,
) =>
  request(
    getAPIPath('admin/services/reminder-protocols'),
    { method: 'POST', data: { ...protocol, groupId }, params: { businessId } },
    true,
  )

export const updateReminderProtocol = (
  businessId: string,
  groupId: string,
  protocol: ReminderProtocol,
) =>
  request(
    getAPIPath(`admin/services/reminder-protocols/${protocol.id}`),
    { method: 'PUT', data: { ...protocol, groupId }, params: { businessId } },
    true,
  )

export const deleteReminderProtocol = (
  businessId: string,
  groupId: string,
  protocolId: string,
) =>
  request(
    getAPIPath(`admin/services/reminder-protocols/${protocolId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )
