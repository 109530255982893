import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import {
  createAdjustment,
  editAdjustment,
  getAdjustmentsIsSaving,
} from '~/store/duck/inventoryAdjustments'
import { Adjustment } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import AdjustmentComponent, { AdjustmentHandle } from './Adjustment'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    dialogContentRoot: {
      padding: theme.spacing(2, 3),
    },
  }),
  { name: 'AdjustmentDialog' },
)

export interface AdjustmentDialogProps extends BasePuiDialogProps {
  adjustment: Partial<Adjustment>
  onSave?: () => void
}

const AdjustmentDialog = ({
  open,
  onClose,
  adjustment,
  onSave,
}: AdjustmentDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const dispatch = useDispatch()

  const adjustmentRef = useRef<AdjustmentHandle>(null)

  const isEdit = Boolean(adjustment?.id)
  const isSavingSelector = getAdjustmentsIsSaving(isEdit)

  const isSaving = useSelector(isSavingSelector)

  const readOnly = isEdit
  const title = isEdit
    ? t('Common:EDIT_ADJUSTMENT')
    : t('Common:NEW_ADJUSTMENT')

  const closeAfterSave = useCloseAfterCreation(() => {
    if (onClose) {
      onClose()
    }
    if (onSave) {
      onSave()
    }
  }, isSavingSelector)

  const handleSave = () => {
    if (adjustmentRef.current?.validate()) {
      const newAdjustment = adjustmentRef.current?.get()

      closeAfterSave()
      if (isEdit) {
        dispatch(editAdjustment(newAdjustment as Adjustment))
      } else {
        dispatch(createAdjustment(newAdjustment))
      }
    }
  }

  return (
    <PuiDialog
      fullWidth
      actions={
        !readOnly ? (
          <ButtonWithLoader
            disabled={isSaving}
            loading={isSaving}
            type="submit"
            onClick={handleSave}
          >
            {isEdit
              ? t('Common:SAVE_ADJUSTMENT')
              : t('Common:RECORD_ADJUSTMENT')}
          </ButtonWithLoader>
        ) : null
      }
      aria-labelledby="adjustment-dialog"
      classes={{
        dialogContentRoot: classes.dialogContentRoot,
      }}
      maxWidth="sm"
      open={open}
      title={title}
      onClose={onClose}
    >
      <AdjustmentComponent adjustment={adjustment} ref={adjustmentRef} />
      {!readOnly && <RequiredFieldsNotice />}
    </PuiDialog>
  )
}

export default AdjustmentDialog
