import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BackButton,
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  NumberUtils,
  PuiDialog,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import MembershipPaymentMethodCell from '~/components/common/lists/primitive-table/cells/MembershipPaymentMethodCell'
import DialogNames from '~/constants/DialogNames'
import { WpCancelPaymentType } from '~/constants/paymentTypes'
import { cancelWellnessPlan } from '~/store/actions/wellnessPlans'
// @ts-ignore
import { patientMembershipsSelectors } from '~/store/duck/patientMemberships'
import { getPatientName } from '~/store/reducers/patients'
import {
  getWellnessPlansIsCancelling,
  getWellnessPlansIsLoading,
} from '~/store/reducers/wellnessPlans'
import { DataHandle, Membership } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import CancelMembership, { CancelData } from './CancelMembership'
import CancelMembershipPayment from './CancelMembershipPayment'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
    actions: {
      padding: theme.spacing(1, 2),
    },
    button: {
      minWidth: 170,
    },
  }),
  { name: 'CancelMembershipDialog' },
)

const Steps = {
  CANCEL: 'CANCEL',
  PAYMENT: 'PAYMENT',
  CONFIRM_CANCEL: 'CONFIRM_CANCEL',
}

interface CancelMembershipDialogProps extends BasePuiDialogProps {
  cancelId?: string
  clientId: string | Nil
  onCancelMembership?: () => void
  patientId: string | Nil
}

const CancelMembershipDialog = ({
  clientId,
  patientId,
  cancelId,
  open,
  onClose,
  onCancelMembership,
}: CancelMembershipDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isLoading = useSelector(getWellnessPlansIsLoading)
  const membership: Membership | Nil = useSelector(
    patientMembershipsSelectors.getItem(patientId),
  )
  const patientName = useSelector(getPatientName(patientId))

  const { t } = useTranslation(['Common', 'Tooltips', 'Dialogs', 'Payments'])

  const [openPatientMembershipDialog] = useDialog(
    DialogNames.PATIENT_MEMBERSHIP,
  )

  const [step, setStep] = useState(Steps.CANCEL)
  const [cancelData, setCancelData] = useState<CancelData>()

  const cancelMembershipRef = useRef<DataHandle<CancelData>>()

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    if (onCancelMembership) {
      onCancelMembership()
    }
  }

  const setCloseOnCancelledOn = useCloseAfterCreation(
    handleClose,
    getWellnessPlansIsCancelling,
  )

  const isRefund = membership?.cancellationType === WpCancelPaymentType.REFUND
  const isCharge = membership?.cancellationType === WpCancelPaymentType.CHARGE
  const isAllGood = !isRefund && !isCharge

  const additionalMembershipPaymentLabels = !isAllGood
    ? [
        {
          name: isRefund
            ? t('Dialogs:CANCEL_MEMBERSHIP_DIALOG.CREDIT_CLIENT_BALANCE')
            : t('Dialogs:CANCEL_MEMBERSHIP_DIALOG.WRITE_OFF'),
          value: NumberUtils.formatMoney(
            isRefund ? cancelData?.creditBalance : cancelData?.writeOff,
          ),
        },
        {
          name: isRefund
            ? t('Payments:REFUND_CARD_ON_FILE')
            : t('Payments:CHARGE_CARD_ON_FILE'),
          value: (
            <MembershipPaymentMethodCell
              paymentMethod={membership?.paymentMethod}
            />
          ),
        },
      ]
    : []

  const paymentAmount = isRefund
    ? cancelData?.refundClient
    : cancelData?.chargeClient

  const getCancelReason = (data?: CancelData) =>
    data?.reason === 'Other' ? data?.otherReason : data?.reason

  const doCancel = () => {
    setCloseOnCancelledOn()
    const data = (cancelData ||
      cancelMembershipRef.current?.get()) as CancelData

    if (clientId && patientId) {
      dispatch(
        cancelWellnessPlan(
          clientId,
          patientId,
          {
            cancellationAmount: Number(paymentAmount) || 0,
            cancellationType: membership?.cancellationType,
            creditOrWriteOffAmount: isRefund
              ? Number(data?.creditBalance)
              : Number(data?.writeOff),
            cancelId,
            reason: {
              reason: getCancelReason(data),
              notes: data?.notes,
            },
          },
          membership?.confirmToken,
        ),
      )
    }
  }

  const handleProceed = () => {
    if (step === Steps.CANCEL) {
      if (cancelMembershipRef.current?.validate()) {
        setCancelData(cancelMembershipRef.current?.get())
        if (isAllGood) {
          setStep(Steps.CONFIRM_CANCEL)
        } else {
          setStep(Steps.PAYMENT)
        }
      }
    }

    if (step === Steps.PAYMENT || step === Steps.CONFIRM_CANCEL) {
      doCancel()
    }
  }

  const handleBack = () => {
    setStep(Steps.CANCEL)
  }

  const handleViewTotalPaid = () => {
    handleClose()
    navigate(`/membership/${clientId}/payments`)
  }

  const handleViewTotalUsed = () => {
    handleClose()
    openPatientMembershipDialog({
      clientId,
      patientId,
    })
  }

  const membershipInfo = `${patientName} | ${membership?.planName}`

  const StepTitles = {
    [Steps.CANCEL]: `${t('Common:CANCEL_ACTION')}: ${membershipInfo}`,
    [Steps.PAYMENT]: `${
      isRefund
        ? t('Common:PAYMENTS.REFUND_ACTION')
        : t('Common:PAYMENTS.PAYMENT')
    }: ${membershipInfo}`,
    [Steps.CONFIRM_CANCEL]: t('Dialogs:CANCEL_MEMBERSHIP_DIALOG.TITLE'),
  }

  const stepTitle = StepTitles[step]

  return (
    <PuiDialog
      actions={
        <>
          {step !== Steps.CANCEL && (
            <Grid item mr={1}>
              <BackButton
                label={t('Common:BACK_ACTION')}
                onClick={handleBack}
              />
            </Grid>
          )}
          {step === Steps.CANCEL && (
            <ButtonWithLoader
              className={classes.button}
              disabled={isLoading}
              loading={isLoading}
              onClick={handleProceed}
            >
              {t('Common:NEXT')}
            </ButtonWithLoader>
          )}
          {step === Steps.PAYMENT && (
            <ButtonWithLoader
              className={classes.button}
              disabled={isLoading}
              loading={isLoading}
              onClick={handleProceed}
            >
              {isRefund
                ? t('Common:PAYMENTS.REFUND_ACTION')
                : t('Common:CHARGE_ACTION')}
            </ButtonWithLoader>
          )}
          {step === Steps.CONFIRM_CANCEL && (
            <ButtonWithLoader
              className={classes.button}
              disabled={isLoading}
              loading={isLoading}
              onClick={handleProceed}
            >
              {t('Dialogs:CANCEL_MEMBERSHIP_DIALOG.CONFIRM_CANCEL')}
            </ButtonWithLoader>
          )}
        </>
      }
      aria-labelledby="cancel-membership-dialog"
      classes={{
        paper: classes.paper,
        actions: classes.actions,
      }}
      open={open}
      scroll="paper"
      title={stepTitle}
      onClose={handleClose}
    >
      {step === Steps.CANCEL && (
        <CancelMembership
          cancelData={cancelData}
          patientId={patientId}
          ref={cancelMembershipRef}
          onViewTotalPaid={handleViewTotalPaid}
          onViewTotalUsed={handleViewTotalUsed}
        />
      )}
      {step === Steps.PAYMENT && (
        <CancelMembershipPayment
          additionalLabels={additionalMembershipPaymentLabels}
          isRefund={isRefund}
          paymentAmount={paymentAmount}
        />
      )}
      {step === Steps.CONFIRM_CANCEL && (
        <Grid container item p={3}>
          <Text strong variant="body2">
            {membershipInfo}
          </Text>
        </Grid>
      )}
    </PuiDialog>
  )
}

export default CancelMembershipDialog
