import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ClassesType,
  Defaults,
  PuiTheme,
  useInterval,
} from '@pbt/pbt-ui-components'
import { Clock } from '@pbt/pbt-ui-components/src/icons'

import PuiIconButton from '~/components/common/PuiIconButton'
import DialogNames from '~/constants/DialogNames'
import { checkStatus, getCurrentTimeEntity } from '~/store/duck/timeTracker'
import {
  getConstantsError,
  getConstantsIsLoading,
} from '~/store/reducers/constants'
import useDialog from '~/utils/useDialog'

import TimeTrackerButtonLabel from './TimeTrackerButtonLabel'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    clockIn: {
      backgroundColor: theme.colors.success,
    },
  }),
  { name: 'TimeTrackerButton' },
)

export interface TimeTrackerButtonProps {
  classes?: ClassesType<typeof useStyles>
}
const TimeTrackerButton = ({
  classes: classesProp,
}: TimeTrackerButtonProps) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const entity = useSelector(getCurrentTimeEntity)
  const constantsError = useSelector(getConstantsError)
  const isLoading = useSelector(getConstantsIsLoading)

  const [openTimeTrackerDialog] = useDialog(DialogNames.TIME_TRACKER)

  useInterval(() => {
    dispatch(checkStatus())
  }, Defaults.STATUS_UPDATE_INTERVAL)

  return (
    <PuiIconButton
      Icon={entity ? Clock : TimeTrackerButtonLabel}
      className={classNames({
        [classes.clockIn]: !entity,
      })}
      data-testid={
        !entity ? 'timeTrackerButtonClockIn' : 'timeTrackerButtonClockOut'
      }
      disabled={Boolean(constantsError)}
      isLoading={isLoading}
      onClick={() => openTimeTrackerDialog()}
    />
  )
}

export default TimeTrackerButton
