import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  PuiDialog,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import { fetchMoreItemsForBusinessesList } from '~/store/actions/businesses'
import { getBusinessesTotalCount } from '~/store/reducers/businesses'

import CreatePractice from './CreatePractice'
import GroupPracticeSelect from './GroupPracticeSelect'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 700,
    },
    title: {
      [theme.breakpoints.down('md')]: {
        fontSize: '2rem',
      },
    },
    contentContainer: {
      padding: theme.spacing(3, 4),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 3),
      },
    },
    createPracticeContainer: {
      padding: theme.spacing(3, 4),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 0, 0, 0),
      },
    },
    dialogContentRoot: {
      overflowY: 'visible',
    },
  }),
  { name: 'AddChildDialog' },
)

enum Steps {
  GROUP_PRACTICE_SELECT = 'GROUP_PRACTICE_SELECT',
  NEW_PRACTICE = 'NEW_PRACTICE',
}

interface AddBusinessChildDialogProps extends BasePuiDialogProps {
  creatorRoleId?: string
  onSelected: (ids: string[]) => void
  selectedPractices: string[]
}

const AddBusinessChildDialog = ({
  open,
  onClose,
  onSelected,
  selectedPractices,
  creatorRoleId,
}: AddBusinessChildDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )

  const dispatch = useDispatch()

  const totalCount = useSelector(getBusinessesTotalCount)

  const [step, setStep] = useState(Steps.GROUP_PRACTICE_SELECT)

  useEffect(() => {
    if (open && totalCount > 0) {
      dispatch(fetchMoreItemsForBusinessesList(0, totalCount))
    }
  }, [open, totalCount])

  return (
    <PuiDialog
      aria-labelledby="add-business-child-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      maxWidth={isMobile ? 'sm' : 'md'}
      open={open}
      onClose={onClose}
    >
      {step === Steps.GROUP_PRACTICE_SELECT && (
        <Grid container className={classes.contentContainer} direction="column">
          <Text className={classes.title} variant="hero2">
            {t('Common:ADD_PRACTICE')}
          </Text>
          <GroupPracticeSelect
            selectedPractices={selectedPractices}
            onAddNewPractice={() => setStep(Steps.NEW_PRACTICE)}
            onSelect={(selected) => {
              onSelected(selected.map((_) => _.id))
              if (onClose) {
                onClose()
              }
            }}
          />
        </Grid>
      )}
      {step === Steps.NEW_PRACTICE && (
        <Grid
          container
          item
          className={classes.createPracticeContainer}
          direction="column"
        >
          <CreatePractice
            creatorRoleId={creatorRoleId}
            onCreated={() => setStep(Steps.GROUP_PRACTICE_SELECT)}
          />
        </Grid>
      )}
    </PuiDialog>
  )
}

export default AddBusinessChildDialog
