import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useSelector } from 'react-redux'
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material'
import { Grid, IconButton, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  DateUtils,
  LanguageUtils,
  PuiTheme,
  TextInteractive,
  Utils,
} from '@pbt/pbt-ui-components'

import ReminderStateLabel from '~/components/common/labels/ReminderStateLabel'
import { getReminder } from '~/store/duck/reminders'
import { getEventState } from '~/store/reducers/constants'
import { Reminder } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    row: {
      flexShrink: 0,
    },
    nameResolved: {
      color: theme.colors.tabLabel,
      textDecoration: 'line-through',
    },
    iconButton: {
      padding: 0,
    },
    icon: {
      color: theme.colors.lowAccentText,
    },
  }),
  { name: 'PatientRemindersListItem' },
)

export interface PatientRemindersListItemProps {
  className?: string
  handleToggleActions: (event: React.MouseEvent, reminder?: Reminder) => void
  itemId: string
}

const PatientRemindersListItem = ({
  className,
  itemId,
  handleToggleActions,
}: PatientRemindersListItemProps) => {
  const classes = useStyles()
  const reminder = useSelector(getReminder(itemId))
  const EventState = useSelector(getEventState)

  const ResolvedStateId = Utils.findConstantIdByName('Resolved', EventState)

  const isResolved = reminder?.state?.id === ResolvedStateId
  const isLoading = !reminder

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(className, classes.row)}
      pl={2}
      pr={1}
      py={0.5}
      wrap="nowrap"
    >
      <Grid item xs={3}>
        <TextInteractive
          disableSkeletonAnimation
          isLoading={isLoading}
          variant="lowAccent2"
        >
          {DateUtils.formatDate(reminder?.dueDatetime)}
        </TextInteractive>
      </Grid>
      <Grid container item alignItems="center" px={0.5} xs={2.2}>
        {isLoading ? (
          <Skeleton animation={false} width={40} />
        ) : (
          <ReminderStateLabel reminder={reminder} variant="small" />
        )}
      </Grid>
      <Grid item xs>
        <Dotdotdot clamp={1}>
          <TextInteractive
            disableSkeletonAnimation
            className={classNames({
              [classes.nameResolved]: isResolved,
            })}
            isLoading={isLoading}
            loaderWidth={165}
            variant="body2"
          >
            {LanguageUtils.getTranslatedFieldName(reminder)}
          </TextInteractive>
        </Dotdotdot>
      </Grid>
      <IconButton
        className={classes.iconButton}
        onClick={(event) => handleToggleActions(event, reminder)}
      >
        {isLoading ? (
          <Skeleton
            animation={false}
            height={24}
            variant="circular"
            width={24}
          />
        ) : (
          <MoreHorizIcon className={classes.icon} />
        )}
      </IconButton>
    </Grid>
  )
}

export default PatientRemindersListItem
