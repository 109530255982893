import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Input } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  Nil,
  PuiSelect,
  PuiTheme,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import QuantityInput from '~/components/common/inputs/QuantityInput'
import TaskTimeUnitsSelect from '~/components/dashboard/tasks-dashboard/TaskTimeUnitsSelect'
import {
  CustomDatePrepositions,
  CustomDatePrepositionsList,
} from '~/constants/taskConstants'
import {
  getTaskTemplateOptions,
  getTaskTimeUnits,
} from '~/store/reducers/constants'
import {
  DataHandleWithUnsavedChanges,
  Task,
  TaskCustomDueDateRecurrence,
} from '~/types'
import { isFieldValuesChanged } from '~/utils'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    select: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  }),
  { name: 'BundleTaskCustomDueDateSettings' },
)

export interface BundleTaskCustomDueDateSettingsProps {
  className?: string
  dueDateOptionId?: string
  task: Task | Nil
}

export interface BundleTaskCustomDueDateSettingsHandle
  extends DataHandleWithUnsavedChanges<TaskCustomDueDateRecurrence> {}

const BundleTaskCustomDueDateSettings = forwardRef(
  function BundleTaskCustomDueDateSettings(
    {
      className,
      dueDateOptionId: dueDateOptionIdProp,
      task,
    }: BundleTaskCustomDueDateSettingsProps,
    ref: ForwardedRef<BundleTaskCustomDueDateSettingsHandle>,
  ) {
    const classes = useStyles()
    const { t } = useTranslation('Common')

    const TaskTemplateOptions = useSelector(getTaskTemplateOptions)
    const TaskTimeUnits = useSelector(getTaskTimeUnits)

    const isContextItem = useIsCurrentContextItem(task)

    const dueDateOptionId = dueDateOptionIdProp || task?.dueDateOptionId

    const CustomDueDate = Utils.findConstantIdByName(
      'Custom',
      TaskTemplateOptions.dueDate || [],
    )
    const DayTimeUnit = Utils.findConstantIdByName('Day', TaskTimeUnits)

    const isCustomDueDate = dueDateOptionId === CustomDueDate
    const amount = task?.customDueDateOffset?.amount || 1

    const { fields, validate, reset } = useFields(
      [
        { name: 'customDueDateAmount', initialValue: Math.abs(amount) },
        {
          name: 'customDueDateUnits',
          initialValue: task?.customDueDateOffset?.unit || DayTimeUnit,
        },
        {
          name: 'customDueDatePreposition',
          initialValue:
            amount >= 0
              ? CustomDatePrepositions.AFTER
              : CustomDatePrepositions.BEFORE,
        },
      ],
      false,
    )

    const {
      customDueDateAmount,
      customDueDateUnits,
      customDueDatePreposition,
    } = fields

    useEffect(() => {
      reset()
    }, [task])

    useImperativeHandle(ref, () => ({
      validate,
      get: () => ({
        customDueDateOffset: isCustomDueDate
          ? {
              amount:
                customDueDatePreposition.value === CustomDatePrepositions.AFTER
                  ? customDueDateAmount.value
                  : -customDueDateAmount.value,
              unit: customDueDateUnits.value,
            }
          : null,
      }),
      hasUnsavedChanges: () => isFieldValuesChanged(fields),
    }))

    if (!isCustomDueDate) {
      return null
    }

    return (
      <Grid
        container
        item
        alignItems="center"
        className={classNames(className, classes.root)}
        wrap="nowrap"
        xs={12}
      >
        <Grid item>
          <QuantityInput
            showControls
            disabled={!isContextItem}
            field={customDueDateAmount}
          />
        </Grid>
        <TaskTimeUnitsSelect
          amount={customDueDateAmount.value}
          className={classes.select}
          disabled={!isContextItem}
          field={customDueDateUnits}
        />
        <PuiSelect
          className={classes.select}
          disabled={!isContextItem}
          field={customDueDatePreposition}
          input={<Input id="task-due-date-preposition-select" />}
          items={CustomDatePrepositionsList}
          renderEmpty={false}
        />
        <Text variant="body">{t('Common:APPOINTMENT_DATE')}</Text>
      </Grid>
    )
  },
)

export default BundleTaskCustomDueDateSettings
