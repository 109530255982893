import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Hidden, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  InfiniteLoaderList,
  PuiTheme,
  Text,
  User,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'

import RolesFilter from './MemberDetailsPanelsSections/RolesFilter'
import MembersTableRow from './MembersTableRow'

const ITEM_SIZE = 64

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    expandedColumn: {
      maxWidth: '75%',
      transition: theme.transitions.create(['max-width', 'flex-basis'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    collapsedColumn: {
      maxWidth: '0%',
      transition: theme.transitions.create(['max-width', 'flex-basis'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    expandedMainColumn: {
      transition: theme.transitions.create(['max-width', 'flex-basis'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen / 2,
        delay: 100,
      }),
    },
    noOverflow: {
      overflow: 'hidden',
      height: '100%',
    },
    mainContainer: {
      height: '100%',
    },
  }),
  { name: 'MembersTable' },
)

interface MembersTableProps {
  filterRoleIds?: string[]
  filterSelectedRoles?: string[]
  isItemLoaded: (index: number) => boolean
  loadMoreItems: (startIndex: number, endIndex: number) => void
  members: User[]
  onFilterSelectedRolesChange?: (roleIds: string[]) => void
  onItemClick?: (newItem: any) => void
  selectedItemId?: string
  totalCount: number
}

const MembersTable = ({
  members,
  selectedItemId,
  onItemClick,
  filterRoleIds,
  filterSelectedRoles,
  onFilterSelectedRolesChange,
  isItemLoaded,
  loadMoreItems,
  totalCount,
}: MembersTableProps) => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )
  const { t } = useTranslation(['Admin', 'Common'])

  const isRemoteUsersEnabled = useSelector(
    getFeatureToggle(FeatureToggle.REMOTE_MEMBERS),
  )

  const enableRolesFilter =
    isRemoteUsersEnabled &&
    filterRoleIds &&
    filterSelectedRoles &&
    onFilterSelectedRolesChange

  const data = {
    onClick: onItemClick,
    rowFocused: selectedItemId,
    isMobile,
  }

  const hasSelectedItem = !R.isNil(selectedItemId)

  return (
    <Grid
      container
      className={classes.mainContainer}
      direction="column"
      wrap="nowrap"
    >
      <Hidden mdDown>
        <Grid container px={3} py={1}>
          <Grid
            container
            item
            alignItems="center"
            className={classes.noOverflow}
          >
            <Grid
              container
              item
              className={classNames({
                [classes.expandedMainColumn]: !hasSelectedItem,
              })}
              md={hasSelectedItem ? 12 : 3}
              wrap="nowrap"
            >
              <Grid item md>
                <Text noWrap strong align="left" variant="lowAccent2">
                  {t('Common:TEAM_MEMBER')}
                </Text>
              </Grid>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              className={classNames({
                [classes.collapsedColumn]: hasSelectedItem,
                [classes.expandedColumn]: !hasSelectedItem,
              })}
              wrap="nowrap"
            >
              <Grid item md={5}>
                {enableRolesFilter ? (
                  <RolesFilter
                    groupByRemote
                    includeSearch
                    roleIds={filterRoleIds}
                    selectedRoles={filterSelectedRoles}
                    onSelectedRolesChange={onFilterSelectedRolesChange}
                  />
                ) : (
                  <Text noWrap strong align="left" variant="lowAccent2">
                    {t('Admin:MEMBER.ROLE.ROLE_ONE_OR_OTHER')}
                  </Text>
                )}
              </Grid>
              <Grid item md={3}>
                <Text noWrap strong align="left" variant="lowAccent2">
                  {t('Common:MOBILE_PHONE')}
                </Text>
              </Grid>
              <Grid item md={4}>
                <Text noWrap strong align="left" variant="lowAccent2">
                  {t('Common:EMAIL')}
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <InfiniteLoaderList
        isItemLoaded={isItemLoaded}
        itemCount={totalCount}
        itemData={members}
        itemSize={ITEM_SIZE}
        loadMoreItems={loadMoreItems}
      >
        {(item) => <MembersTableRow data={data} item={item} />}
      </InfiniteLoaderList>
    </Grid>
  )
}

export default MembersTable
