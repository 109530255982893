import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { DateUtils, LinkButton, PuiTheme } from '@pbt/pbt-ui-components'

import { getUser } from '~/store/reducers/users'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    linkButton: {
      color: theme.colors.link,
    },
  }),
  { name: 'ClientLink' },
)

export interface ClientLinkProps {
  clientId: string
  disableWithNoPatients?: boolean
  onClick: (clientId: string, patientsIds: string[]) => void
}

const ClientLink = ({
  clientId,
  onClick,
  disableWithNoPatients,
}: ClientLinkProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const client = useSelector(getUser(clientId))

  const date = DateUtils.formatDate(client?.creationDate)

  return (
    <LinkButton
      className={classes.linkButton}
      disabled={disableWithNoPatients && client?.patients?.length === 0}
      onClick={() => onClick(clientId, client?.patients || [])}
    >
      {`${client?.firstName} ${client?.lastName} [${t(
        'Common:CREATED',
      )}: ${date}]`}
    </LinkButton>
  )
}
export default ClientLink
