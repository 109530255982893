import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { KeyboardArrowRight } from '@mui/icons-material'
import { Grid, IconButton, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  ControlButtonGroup,
  ControlButtonGroupName,
  PermissionArea,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import { getCRUDByArea } from '~/store/reducers/auth'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    container: {
      cursor: 'pointer',
      marginTop: theme.spacing(0.5),
    },
    petFriendPrimaryText: {},
    petFriendSecondaryText: {
      [theme.breakpoints.down('md')]: {
        fontWeight: 500,
      },
    },
    iconButton: {},
  }),
  { name: 'PetFriendItem' },
)

interface PetFriendItemProps {
  classes?: ClassesType<typeof useStyles>
  firstName?: string
  id: string
  lastName?: string
  onDelete: (id: string) => void
  onEdit: (id: string) => void
  onToggle: (id: string) => void
  role?: string
}

const PetFriendItem = ({
  id,
  firstName,
  lastName,
  role,
  onDelete,
  onEdit,
  onToggle,
  classes: classesProp,
}: PetFriendItemProps) => {
  const classes = useStyles({ classes: classesProp })
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )
  const permissions = useSelector(getCRUDByArea(PermissionArea.PATIENT))
  const { t } = useTranslation('Common')

  const [isExpanded, setIsExpanded] = useState(false)
  const handleToggle = () => {
    onToggle(id)
    setIsExpanded(!isExpanded)
  }

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.container}
      onClick={() => {
        if (isMobile) {
          onToggle(id)
        }
      }}
    >
      <Grid item xs>
        <Text strong align="left" className={classes.petFriendPrimaryText}>
          {Utils.getPersonString({ firstName, lastName })}
        </Text>
        {role && (
          <Text align="left" className={classes.petFriendSecondaryText}>
            ({role})
          </Text>
        )}
      </Grid>
      {isMobile ? (
        <IconButton aria-label={t('Common:EXPAND_PET_FRIEND')} size="large">
          <KeyboardArrowRight />
        </IconButton>
      ) : (
        <ControlButtonGroup
          buttons={[
            permissions.update && {
              name: ControlButtonGroupName.REMOVE,
              onClick: () => onDelete(id),
            },
            permissions.update && {
              name: ControlButtonGroupName.EDIT,
              onClick: () => onEdit(id),
            },
            !isExpanded && {
              name: ControlButtonGroupName.EXPAND,
              onClick: handleToggle,
            },
            isExpanded && {
              name: ControlButtonGroupName.COLLAPSE,
              onClick: handleToggle,
            },
          ]}
          classes={{ iconButton: classes.iconButton }}
        />
      )}
    </Grid>
  )
}

export default PetFriendItem
