import React, { useEffect, useState } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  Nil,
  PuiTextField,
  PuiTheme,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    title: {
      cursor: 'pointer',
      '&:hover': {
        borderBottom: theme.constants.stripedTableHeaderBorder,
      },
    },
    titleReadOnly: {
      cursor: 'initial',
      '&:hover': {
        borderBottom: 'none',
      },
    },
    titleContainer: {
      margin: theme.spacing(0, 2),
    },
  }),
  { name: 'EditableTitle' },
)

export interface EditableTitleProps {
  endAdornment?: React.ReactNode
  readOnly?: boolean
  setTitle: (title: string) => void
  title: string | Nil
}

const EditableTitle = ({
  title,
  setTitle,
  readOnly,
  endAdornment,
}: EditableTitleProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common'])

  const [titleRef, setTitleRef] = useState<HTMLInputElement>()
  const [titleEditing, setTitleEditing] = useState(false)

  const {
    fields: { title: titleField },
    validate,
  } = useFields(
    [
      {
        name: 'title',
        label: t('Common:TITLE'),
        validators: ['required'],
      },
    ],
    false,
  )

  const handleFinishEditing = () => {
    if (validate()) {
      setTitle(titleField.value)
    } else {
      titleField.setValue(setTitle)
    }
    setTitleEditing(false)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleFinishEditing()
    }
  }

  const handleStartEditing = () => {
    setTitleEditing(true)
  }

  useEffect(() => {
    if (titleEditing && titleRef) {
      titleRef.focus()
    }
  }, [titleEditing, titleRef])

  useEffect(() => {
    titleField.setValue(title)
  }, [title])

  return (
    <Grid
      container
      item
      xs
      alignItems="center"
      className={classes.titleContainer}
      wrap="nowrap"
    >
      <Grid item xs={Boolean(titleEditing)}>
        <Dotdotdot clamp={2}>
          {titleEditing ? (
            <PuiTextField
              fullWidth
              InputLabelProps={{ disabled: true }}
              field={{ ...titleField, set: titleField.set }}
              inputProps={{
                maxLength: 255,
                onBlur: handleFinishEditing,
                onKeyDown: handleKeyDown,
                ref: setTitleRef,
              }}
            />
          ) : (
            <Text
              inline
              strong
              className={classNames(classes.title, {
                [classes.titleReadOnly]: readOnly,
              })}
              onClick={readOnly ? undefined : handleStartEditing}
            >
              {title}
            </Text>
          )}
        </Dotdotdot>
      </Grid>
      {endAdornment}
    </Grid>
  )
}

export default EditableTitle
