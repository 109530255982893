import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useSelector } from 'react-redux'
import { Grid, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  LanguageUtils,
  PhoneUtils,
  PuiTheme,
  Text,
  TextInteractive,
  User,
  UserInvitationStatus,
  Utils,
} from '@pbt/pbt-ui-components'

import Avatar from '~/components/common/Avatar'
import WarningLabel from '~/components/common/labels/WarningLabel'
import i18n from '~/locales/i18n'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getRolesMap } from '~/store/reducers/roles'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    container: {
      height: '100%',
      userSelect: 'none',
      cursor: 'pointer',
      borderRadius: 2,
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    listShadow: {
      '&:hover': {
        boxShadow: theme.constants.listItemShadow,
      },
    },
    selectedRow: {
      border: theme.constants.tableBorderSelected,
    },
    nameContainerForInvitedUser: {
      marginLeft: -104,
    },
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordBreak: 'break-all',
    },
    memberNameTextSelected: {
      color: theme.colors.tabSelected,
    },
    memberNameTextContainer: {
      paddingRight: theme.spacing(0.5),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(2),
      },
    },
    nameForInvitedUser: {
      paddingLeft: theme.spacing(13),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(14),
      },
    },
    nameSimplePadding: {
      paddingLeft: theme.spacing(3),
    },
    mainInfoContainer: {
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1),
        borderBottom: theme.constants.tableBorder,
      },
    },
    inactiveMemberRow: {
      opacity: theme.constants.inactiveOpacity,
      backgroundColor: theme.colors.inactive,
    },
    expandedColumn: {
      maxWidth: '75%',
      transition: theme.transitions.create(['max-width', 'flex-basis'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    collapsedColumn: {
      maxWidth: '0%',
      transition: theme.transitions.create(['max-width', 'flex-basis'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    expandedMainColumn: {
      transition: theme.transitions.create(['max-width', 'flex-basis'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen / 2,
        delay: 100,
      }),
    },
    content: {
      [theme.breakpoints.up('md')]: {
        padding: `calc(${theme.spacing(1)} - 2px) ${theme.spacing(3)}`,
      },
      overflow: 'hidden',
      height: '100%',
    },
  }),
  { name: 'MembersTableRow' },
)

const warningMessages = {
  [UserInvitationStatus.PENDING]: i18n.t('Admin:MEMBER.INVITE.PENDING'),
  [UserInvitationStatus.EXPIRED]: i18n.t('Admin:MEMBER.INVITE.EXPIRED'),
  [UserInvitationStatus.IS_NOT_SENT]: i18n.t('Admin:MEMBER.INVITE.IS_NOT_SENT'),
}

interface MembersTableRowProps {
  data: {
    isMobile: boolean
    onClick?: (newItem: any) => void
    rowFocused?: string
  }
  item: User
}

const MembersTableRow = ({
  item,
  data: { isMobile, onClick, rowFocused },
}: MembersTableRowProps) => {
  const classes = useStyles()
  const roles = useSelector(getRolesMap)
  const currentBusiness = useSelector(getCurrentBusiness)
  const parentBusinessId = currentBusiness?.parentBusinessId

  const isLoading = R.isEmpty(item)
  const hasFocusedRow = !R.isNil(rowFocused)

  const getDataWhileLoading = () => ({
    invitationMessage: undefined,
    isFocusedRow: false,
    businessToRoleList: [],
    member: null,
  })

  const getData = () => ({
    invitationMessage: item?.invitationStatus
      ? warningMessages[item.invitationStatus as keyof typeof warningMessages]
      : null,
    member: item,
    businessToRoleList: (item?.businessToRoleList || []).filter(
      ({ business: businessId, role }) =>
        (businessId === currentBusiness?.id ||
          businessId === parentBusinessId) &&
        roles[role] &&
        !roles[role].hidden,
    ),
    isFocusedRow: hasFocusedRow && rowFocused === item?.id,
  })

  const { invitationMessage, member, businessToRoleList, isFocusedRow } =
    isLoading ? getDataWhileLoading() : getData()

  return (
    <Grid
      container
      alignItems={isMobile ? 'flex-start' : 'center'}
      className={classNames(classes.container, {
        [classes.listShadow]: !isFocusedRow,
        [classes.selectedRow]: isFocusedRow,
        [classes.inactiveMemberRow]: !isLoading && !item?.active,
      })}
      direction={isMobile ? 'column' : 'row'}
      wrap={isMobile ? 'wrap' : 'nowrap'}
      onClick={() => {
        if (onClick) {
          onClick(member)
        }
      }}
    >
      {invitationMessage && <WarningLabel message={invitationMessage} />}
      <Grid
        container
        item
        alignItems={isMobile ? 'stretch' : 'center'}
        className={classNames(classes.content, {
          [classes.nameContainerForInvitedUser]: Boolean(invitationMessage),
        })}
        wrap="nowrap"
      >
        <Grid
          container
          item
          alignItems="center"
          className={classNames(classes.mainInfoContainer, {
            [classes.expandedMainColumn]: !hasFocusedRow,
          })}
          md={hasFocusedRow ? 12 : 3}
          wrap="nowrap"
        >
          {!invitationMessage && (
            <Grid item>
              <Avatar
                alt="userpic"
                isLoading={isLoading}
                person={member || {}}
                size="small"
              />
            </Grid>
          )}
          <Grid
            item
            xs
            className={classNames(classes.memberNameTextContainer, {
              [classes.nameForInvitedUser]: Boolean(invitationMessage),
              [classes.nameSimplePadding]: !invitationMessage,
            })}
          >
            <Dotdotdot clamp={1}>
              <TextInteractive
                strong
                className={classNames(classes.text, {
                  [classes.memberNameTextSelected]: isFocusedRow,
                })}
                isLoading={isLoading}
              >
                {Utils.getPersonString(member)}
              </TextInteractive>
            </Dotdotdot>
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid
            container
            item
            alignItems="center"
            className={classNames({
              [classes.collapsedColumn]: hasFocusedRow,
              [classes.expandedColumn]: !hasFocusedRow,
            })}
            justifyContent="flex-start"
            spacing={1}
            wrap="nowrap"
          >
            <Grid item md={5}>
              {isLoading && <Skeleton height={20} width={70} />}
              <Dotdotdot clamp={1}>
                {businessToRoleList.map((entry, index) => (
                  <Text inline strong className={classes.text} key={entry.role}>
                    {LanguageUtils.getTranslatedFieldName(roles[entry.role])}
                    {index !== businessToRoleList.length - 1 ? ', ' : ''}
                  </Text>
                ))}
              </Dotdotdot>
            </Grid>
            <Grid item md={3}>
              <Dotdotdot clamp={1}>
                <TextInteractive
                  strong
                  className={classes.text}
                  isLoading={isLoading}
                >
                  {PhoneUtils.getPhoneNumber(member?.mobilePhone)}
                </TextInteractive>
              </Dotdotdot>
            </Grid>
            <Grid item md={4}>
              <Dotdotdot clamp={1}>
                <TextInteractive className={classes.text} isLoading={isLoading}>
                  {member?.email}
                </TextInteractive>
              </Dotdotdot>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default MembersTableRow
