import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { PermissionArea, PuiTheme, QuestionMode } from '@pbt/pbt-ui-components'

import QuestionListView from '~/components/dashboard/admin/catalog/questions/QuestionListView'
import { useSoapQuestions } from '~/store/hooks/questions'
import { getCRUDByArea } from '~/store/reducers/auth'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    questionContainer: {
      padding: theme.spacing(0, 2),
    },
    questionText: {
      fontWeight: 500,
      fontSize: '1.4rem',
    },
    questionRadioLabel: {
      fontSize: '1.4rem',
    },
  }),
  { name: 'QuestionTab' },
)

export interface QuestionTabProps {
  id: string
  mode: QuestionMode
  showAllPriorAnswers: boolean
}

const QuestionTab = ({ id, mode, showAllPriorAnswers }: QuestionTabProps) => {
  const classes = useStyles()

  const SOAPPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))

  const { onSaveQuestionLog, soapQuestions } = useSoapQuestions()

  return (
    <QuestionListView
      renderPriorAnswers
      showNoQuestionsMessage
      useDivider
      StackProps={{ spacing: 1 }}
      allAnswersExpanded={showAllPriorAnswers}
      className={classes.questionContainer}
      classes={{
        text: classes.questionText,
        radioLabel: classes.questionRadioLabel,
      }}
      editDisabled={!SOAPPermissions.update}
      mode={mode}
      questionsLog={soapQuestions}
      type={id}
      onSaveQuestionLog={onSaveQuestionLog}
    />
  )
}

export default React.memo(QuestionTab)
