import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  ErrorTooltip,
  PhoneUtils,
  PuiTextField,
  PuiTheme,
  Text,
  Utils,
  Validators,
} from '@pbt/pbt-ui-components'

import EmailTextField from '~/components/common/email/EmailTextField'
import PhoneInput from '~/components/common/form-inputs/PhoneInput'
import { updateCurrentUserOnServer } from '~/store/actions/users'
import { getCurrentUser } from '~/store/reducers/auth'
import { getUserIsFetching } from '~/store/reducers/users'

import ProfileAvatarHeader from './ProfileAvatarHeader'
import ProfileBottomContainer from './ProfileBottomContainer'
import ProfileTopContainer from './ProfileTopContainer'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    profileTopContainer: {
      height: 113,
      position: 'relative',
    },
    profileBottomContainer: {
      padding: theme.spacing(2),
      marginTop: 50,
      [theme.breakpoints.down('md')]: {
        marginTop: 40,
      },
    },
    saveButton: {
      width: '100%',
    },
    deaInput: {
      letterSpacing: 5,
      textTransform: 'uppercase',
    },
  }),
  { name: 'ProfileEdit' },
)

export interface ProfileEditProps {
  onAvatarEditClick: () => void
  onBack: () => void
}

const ProfileEdit = ({ onBack, onAvatarEditClick }: ProfileEditProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(getCurrentUser)
  const isFetching = useSelector(getUserIsFetching)
  const { t } = useTranslation(['Abbreviations', 'Common', 'Profile'])

  const [firstName, setFirstName] = useState(user.firstName)
  const [lastName, setLastName] = useState(user.lastName)
  const [mobilePhone, setMobilePhone] = useState(
    user.mobilePhone as string | undefined,
  )
  const [dea, setDea] = useState(user.dea || '')

  const [firstNameFlagShow, setFirstNameFlagShow] = useState(false)
  const [lastNameFlagShow, setLastNameFlagShow] = useState(false)
  const [mobilePhoneFlagShow, setMobilePhoneFlagShow] = useState(false)
  const [deaFlagShow, setDeaFlagShow] = useState(false)
  const [updateTriggered, setUpdateTriggered] = useState(false)

  useEffect(() => {
    if (
      !isFetching &&
      updateTriggered &&
      user.firstName === firstName &&
      user.lastName === lastName &&
      user.mobilePhone === PhoneUtils.parsePhoneNumber(mobilePhone) &&
      (user.dea !== null ? user.dea === dea : true)
    ) {
      onBack()
    }
  }, [user, isFetching, updateTriggered])

  const fields = [
    {
      changed: firstName,
      initial: user.firstName,
      validator: Validators.isLengthValid(1, null),
      callback: setFirstNameFlagShow,
      name: 'firstName',
    },
    {
      changed: lastName,
      initial: user.lastName,
      validator: Validators.isLengthValid(1, null),
      callback: setLastNameFlagShow,
      name: 'lastName',
    },
    {
      changed: mobilePhone,
      initial: user.mobilePhone,
      validator: Validators.isPhoneValid,
      callback: setMobilePhoneFlagShow,
      name: 'mobilePhone',
      parser: PhoneUtils.parsePhoneNumber,
    },
    {
      changed: dea,
      initial: user.dea,
      validator: Validators.isLengthValid(0, null),
      callback: setDeaFlagShow,
      name: 'dea',
    },
  ]

  const validate = () => {
    const changedFields = fields.filter(
      (fieldObj) => fieldObj.changed !== fieldObj.initial,
    )
    const hasInvalidField = changedFields.find((fieldObj) => {
      const result = fieldObj.validator(fieldObj.changed || '')
      fieldObj.callback(!result)
      return !result
    })

    return (
      !hasInvalidField &&
      changedFields.length > 0 &&
      changedFields.reduce(
        (acc, _) => ({
          ...acc,
          [_.name]: _.parser ? _.parser(_.changed) : _.changed,
        }),
        {},
      )
    )
  }

  const handleSubmit = () => {
    const dataToUpdate = validate()
    if (!dataToUpdate) {
      return
    }
    setUpdateTriggered(true)
    dispatch(updateCurrentUserOnServer(dataToUpdate))
  }

  return (
    <Grid
      container
      item
      alignItems="center"
      direction="column"
      justifyContent="center"
      width={412}
    >
      <ProfileTopContainer className={classes.profileTopContainer}>
        <ProfileAvatarHeader onAvatarEditClick={onAvatarEditClick} />
      </ProfileTopContainer>
      <ProfileBottomContainer
        alignItems="flex-start"
        className={classes.profileBottomContainer}
      >
        <Grid container item spacing={2}>
          <Grid item xs>
            <ErrorTooltip
              message={t('Validations:ENTER_VALID_NAME')}
              open={firstNameFlagShow}
            >
              <PuiTextField
                label={t('Common:FIRST_NAME')}
                value={firstName}
                onChange={Utils.handleFormTextInput(setFirstName)}
              />
            </ErrorTooltip>
          </Grid>
          <Grid item xs>
            <ErrorTooltip
              message={t('Validations:ENTER_VALID_LAST_NAME')}
              open={lastNameFlagShow}
            >
              <PuiTextField
                label={t('Common:LAST_NAME')}
                value={lastName}
                onChange={Utils.handleFormTextInput(setLastName)}
              />
            </ErrorTooltip>
          </Grid>
        </Grid>
        <Grid item mt={4}>
          <Text strong variant="lowAccent2">
            {t('Common:CONTACT_ONE').toUpperCase()}
          </Text>
        </Grid>
        <PhoneInput
          field={{
            name: 'mobilePhone',
            value: mobilePhone,
            set: Utils.handleFormTextInput(setMobilePhone),
            label: t('Common:PHONE_NUMBER'),
            message: t('Validations:ENTER_VALID_PHONE_NUMBER'),
            open: mobilePhoneFlagShow,
            valid: !mobilePhoneFlagShow,
            errors: [],
            setValue: () => {},
          }}
        />
        <EmailTextField
          fullWidth
          label={t('Common:EMAIL')}
          value={user.email || ''}
        />
        <Grid item mt={3}>
          <Text strong variant="lowAccent2">
            {t('Common:JOB_INFO').toUpperCase()}
          </Text>
        </Grid>
        <ErrorTooltip
          message={t('Abbreviations:ACRONYMS.DEA.ENTER_VALID_DEA_NUMBER')}
          open={deaFlagShow}
        >
          <PuiTextField
            inputProps={{ maxLength: 100, className: classes.deaInput }}
            label={t('Abbreviations:ACRONYMS.DEA.DEA_NUMBER_SIGN')}
            value={dea}
            onChange={Utils.handleFormTextInput(setDea)}
          />
        </ErrorTooltip>
        <Grid container item justifyContent="center" mb={2} mt={3.5} px={4}>
          <ButtonWithLoader
            className={classes.saveButton}
            color="secondary"
            disabled={isFetching}
            loading={isFetching}
            onClick={handleSubmit}
          >
            {t('Common:SAVE_ACTION')}
          </ButtonWithLoader>
        </Grid>
      </ProfileBottomContainer>
    </Grid>
  )
}

export default ProfileEdit
