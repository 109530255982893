import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { HtmlNotesPreview, Text, Utils } from '@pbt/pbt-ui-components'

import { InventoryItemState } from '~/constants/SOAPStates'
import { getInventoryStatuses } from '~/store/reducers/constants'
import { Order, PrescriptionShort } from '~/types'
import { getIsRefill } from '~/utils/orderUtils'
import { getPrescriptionType } from '~/utils/prescription'

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  { name: 'PrescriptionItemContent' },
)

export interface PrescriptionItemContentProps {
  className?: string
  order: PrescriptionShort
}

const PrescriptionItemContent = ({
  className,
  order,
}: PrescriptionItemContentProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Plurals'])

  const InventoryStatuses = useSelector(getInventoryStatuses)

  const DeclinedInventoryState = Utils.findConstantIdByName(
    InventoryItemState.DECLINED,
    InventoryStatuses,
  )

  const { isInHouse, isChewyActiveRx } = getPrescriptionType(
    order.prescriptionType,
    order.origin,
  )
  const isRefill = getIsRefill(order as Order)
  const showRefillInfo =
    isRefill &&
    order.inventoryLogStatusId !== DeclinedInventoryState &&
    order.refillOrdinalNumber &&
    order.parent?.quantityNumbRefills

  return (
    <Grid item className={classNames(className, classes.root)} mb={1} xs={12}>
      <Text variant="body2">
        {isInHouse
          ? t('Common:IN_HOUSE')
          : isChewyActiveRx
          ? t('Common:CHEWY_RX')
          : t('Common:SCRIPT_OUT')}
      </Text>
      {order.notes && <HtmlNotesPreview includeLabel notes={order.notes} />}
      {!R.isNil(order.quantityNumbRefills) && order.quantityNumbRefills > 0 && (
        <Text variant="body2">
          {t('Plurals:Z_ICU_WORKAROUND.NUMBER_REFILLS', {
            number: order.quantityNumbRefills,
          })}
        </Text>
      )}
      {showRefillInfo && (
        <Text variant="body2">
          {t('Common:REFILL_NUMBER_OF_TOTAL', {
            number: order.refillOrdinalNumber,
            total: order?.parent?.quantityNumbRefills,
          })}
        </Text>
      )}
    </Grid>
  )
}

export default PrescriptionItemContent
