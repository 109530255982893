import React, { RefObject, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  DateUtils,
  Field,
  PermissionArea,
  PuiCheckbox,
  RoleName,
  SignatureView,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import TeamMemberSelect from '~/components/common/inputs/TeamMemberSelect'
import UserSelect from '~/components/common/inputs/UserSelect'
import CompoundingReason from '~/components/dashboard/prescription/prescription-editor/CompoundingReason'
import RxRequestCatalogItemNotLinked from '~/components/dashboard/tasks-dashboard/rx-request-task/RxRequestCatalogItemNotLinked'
import FeatureToggle from '~/constants/featureToggle'
import { OrderType } from '~/constants/SOAPStates'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle, getLicenseTypes } from '~/store/reducers/constants'
import { getDVMLicensesNumbers } from '~/utils'
import { getPrescriptionName, getWorkflowType } from '~/utils/prescription'
import { usePrescriptionLoading } from '~/utils/usePrescriptionLoading'

import AdministrationSectionSimple, {
  AdministrationSectionSimpleHandle,
} from '../AdministrationSectionSimple'
import ChargeSection, { ChargeSectionHandle } from '../ChargeSection'
import ControlledSubstanceSection, {
  ControlledSubstanceSectionHandle,
} from '../ControlledSubstanceSection'
import {
  DiscussedWithClientField,
  DiscussedWithClientFieldHandle,
} from '../DiscussedWithClientField'
import { DispensedFromSectionHandle } from '../DispensedFromSection'
import DoctorLicenseWarning from '../DoctorLicenseWarning'
import { DrugCompoundInfoHandle } from '../DrugCompoundInfo'
import DrugInfoSection from '../DrugInfoSection'
import type { PrescriptionBodyProps } from '../PrescriptionBody'
import PrescriptionChewyOrderInfo from '../PrescriptionChewyOrderInfo'
import PrescriptionDetailsSection, {
  PrescriptionDetailsSectionHandle,
} from '../PrescriptionDetailsSection'
import QuantitySection, { QuantitySectionHandle } from '../QuantitySection'
import {
  RefillableSection,
  RefillableSectionDataHandle,
} from '../RefillableSection'
import SaveAsDefaultField from '../SaveAsDefaultField'
import TaskSection, { TaskSectionHandle } from '../TaskSection'
import VariationsTable, { VariationsTableProps } from '../VariationsTable'
import VetDietInfo from '../VetDietInfo'

const useStyles = makeStyles(
  () => ({
    expandCollapse: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
    },
  }),
  { name: 'PrescriptionOtherWorkflowsBody' },
)

export interface PrescriptionOtherWorkflowsBodyProps
  extends Pick<
    PrescriptionBodyProps,
    | 'activeWorkflow'
    | 'automaticallyCreateTaskLabel'
    | 'clientId'
    | 'currentDoctorHasDvmLicense'
    | 'isChewyCatalogItemNotLinked'
    | 'isCompoundingReasonRequired'
    | 'isCustomCompound'
    | 'isDvmLicenseRequired'
    | 'isEdit'
    | 'isLengthOfCourseRequired'
    | 'isRxPrescription'
    | 'isVetDiet'
    | 'onRefChange'
    | 'outsideSoap'
    | 'patientId'
    | 'prescription'
    | 'showAutomaticallyCreateTaskOption'
    | 'showDiscussedWithClient'
    | 'showDoctorSelect'
    | 'showOnlyDrugVariation'
    | 'signatureData'
    | 'simpleAdministrationInstructions'
  > {
  activeAdminFrequencyId: string | undefined
  areChargesPostedAndEditable?: boolean
  fields: {
    automaticallyCreateTask: Field
    compoundingReason: Field
    compoundingReasonId: Field
    doctorId: Field
    saveAsDefault: Field
    teamMemberId: Field
  }
  isControlledSubstance: boolean
  onSetActiveAdminFrequencyId: (id: string) => void
  onSignatureEdit: () => void
  onVariationChange: VariationsTableProps['onVariationChange']
  refs: {
    administrationSectionRef: RefObject<
      AdministrationSectionSimpleHandle | PrescriptionDetailsSectionHandle
    >
    chargeSectionRef: RefObject<ChargeSectionHandle>
    controlledSubstanceSectionRef: RefObject<ControlledSubstanceSectionHandle>
    discussedWithClientRef: RefObject<DiscussedWithClientFieldHandle>
    dispensedFromSectionRef: RefObject<DispensedFromSectionHandle>
    drugCompoundInfoRef: RefObject<DrugCompoundInfoHandle>
    quantitySectionRef: RefObject<QuantitySectionHandle>
    refillableSectionRef: RefObject<RefillableSectionDataHandle>
    taskSectionRef: RefObject<TaskSectionHandle>
  }
}

export const PrescriptionOtherWorkflowsBody = ({
  areChargesPostedAndEditable,
  activeAdminFrequencyId,
  activeWorkflow,
  automaticallyCreateTaskLabel,
  clientId,
  currentDoctorHasDvmLicense,
  fields: {
    automaticallyCreateTask,
    compoundingReason,
    compoundingReasonId,
    doctorId,
    saveAsDefault,
    teamMemberId,
  },
  isChewyCatalogItemNotLinked,
  isCompoundingReasonRequired,
  isControlledSubstance,
  isCustomCompound,
  isDvmLicenseRequired,
  isEdit,
  isLengthOfCourseRequired,
  isRxPrescription,
  isVetDiet,
  onRefChange,
  onSetActiveAdminFrequencyId,
  onSignatureEdit,
  onVariationChange,
  outsideSoap,
  patientId,
  prescription,
  refs: {
    administrationSectionRef,
    chargeSectionRef,
    controlledSubstanceSectionRef,
    discussedWithClientRef,
    dispensedFromSectionRef,
    drugCompoundInfoRef,
    quantitySectionRef,
    refillableSectionRef,
    taskSectionRef,
  },
  showAutomaticallyCreateTaskOption,
  showDiscussedWithClient,
  showDoctorSelect,
  showOnlyDrugVariation,
  signatureData,
  simpleAdministrationInstructions,
}: PrescriptionOtherWorkflowsBodyProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const permissions = useSelector(getCRUDByArea(PermissionArea.PRESCRIPTION))
  const LicenseTypes = useSelector(getLicenseTypes)
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )
  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )

  const {
    isChewyActiveRx,
    isChewyReactiveRx,
    isInHouseRx,
    isOrder,
    isOutsidePharmacy,
  } = getWorkflowType(activeWorkflow)

  const isChewy = isChewyReactiveRx || isChewyActiveRx
  const [variationsExpanded, setVariationsExpanded] = useState(false)
  const { isLoading } = usePrescriptionLoading(outsideSoap)

  const showTeamMemberSelect = isChewyReactiveRx
  const name = getPrescriptionName(prescription, isFoodCatalogEnabled)

  return (
    <>
      {isChewyReactiveRx && (
        <Grid container direction="column" mb={2}>
          <PrescriptionChewyOrderInfo prescription={prescription} />
        </Grid>
      )}

      {isVetDiet && (
        <>
          <Text strong mb={1} variant="subheading3">
            {t('Dialogs:PRESCRIPTION_DIALOG.VET_DIET_INFO')}
          </Text>
          {isChewyCatalogItemNotLinked ? (
            <RxRequestCatalogItemNotLinked sx={{ mb: 2 }} />
          ) : (
            <VetDietInfo drugInfo={prescription.drugInfo} />
          )}
        </>
      )}
      {!isVetDiet && (
        <>
          {showOnlyDrugVariation ? (
            <>
              <Text
                link
                strong
                className={classes.expandCollapse}
                variant="subheading3"
                onClick={() => setVariationsExpanded(!variationsExpanded)}
              >
                {variationsExpanded
                  ? t('Dialogs:PRESCRIPTION_DIALOG.HIDE_VARIATIONS')
                  : t('Dialogs:PRESCRIPTION_DIALOG.SHOW_VARIATIONS')}
                {variationsExpanded ? (
                  <KeyboardArrowUp />
                ) : (
                  <KeyboardArrowDown />
                )}
              </Text>

              {variationsExpanded && (
                <VariationsTable
                  activeWorkflow={activeWorkflow}
                  disabled={areChargesPostedAndEditable}
                  drugCompoundInfoRef={drugCompoundInfoRef}
                  outsideSoap={outsideSoap}
                  prescription={prescription}
                  showAllVariationsToggle={false}
                  showCustomCompound={false}
                  showNationalInstituteOfHealthLink={false}
                  onRefChange={onRefChange}
                  onVariationChange={onVariationChange}
                />
              )}
            </>
          ) : (
            <>
              <Text strong mb={1} variant="subheading3">
                {t('Dialogs:PRESCRIPTION_DIALOG.DRUG_INFO')}
              </Text>
              {isChewyCatalogItemNotLinked ? (
                <RxRequestCatalogItemNotLinked />
              ) : (
                <DrugInfoSection
                  activeWorkflow={activeWorkflow}
                  compoundingReasonField={compoundingReason}
                  compoundingReasonIdField={compoundingReasonId}
                  disabled={areChargesPostedAndEditable}
                  drugCompoundInfoRef={drugCompoundInfoRef}
                  isChewyReactiveRx={isChewyReactiveRx}
                  isCustomCompound={isCustomCompound}
                  outsideSoap={outsideSoap}
                  patientId={patientId}
                  prescription={prescription}
                  onRefChange={onRefChange}
                  onVariationChange={onVariationChange}
                />
              )}
            </>
          )}
          {isCompoundingReasonRequired && (
            <Grid container item mt={1.5}>
              <CompoundingReason
                compoundingReasonField={compoundingReason}
                compoundingReasonIdField={compoundingReasonId}
              />
            </Grid>
          )}
        </>
      )}

      <Text strong mt={2} variant="subheading3">
        {isOrder
          ? t('Common:DETAILS')
          : isVetDiet
          ? t('Common:DETAILS')
          : t('Dialogs:PRESCRIPTION_DIALOG.PRESCRIPTION_DETAILS')}
      </Text>
      {simpleAdministrationInstructions ? (
        <AdministrationSectionSimple
          prescription={prescription}
          ref={administrationSectionRef}
          onRefChange={onRefChange}
        />
      ) : (
        <PrescriptionDetailsSection
          alwaysExpanded={isRxPrescription}
          isChewy={isChewy}
          isExpandable={!isChewy}
          isLengthOfCourseRequired={isLengthOfCourseRequired}
          isOrder={isOrder}
          isRxPrescription={isRxPrescription}
          isVetDiet={isVetDiet}
          prescription={prescription}
          ref={administrationSectionRef}
          onActiveAdminFrequencyChange={onSetActiveAdminFrequencyId}
          onRefChange={onRefChange}
        />
      )}

      <QuantitySection
        dispensedFromSectionRef={dispensedFromSectionRef}
        isChewyActiveRx={false}
        isChewyReactiveRx={isChewyReactiveRx}
        isCustomCompound={isCustomCompound}
        isEdit={isEdit}
        isOutsidePharmacy={isOutsidePharmacy}
        prescription={prescription}
        ref={quantitySectionRef}
        showTotalQuantitySection={!isOrder}
        onRefChange={onRefChange}
      />
      {!isOrder && (
        <RefillableSection
          isChewyActiveRx={isChewyActiveRx}
          quantityNumbRefills={prescription.quantityNumbRefills}
          quantityRefillExpirationDate={
            prescription.quantityRefillExpirationDate
          }
          ref={refillableSectionRef}
          onRefChange={onRefChange}
        />
      )}
      {!isOrder && showDiscussedWithClient && (
        <DiscussedWithClientField
          discussedWithClient={prescription.discussedWithClient}
          ref={discussedWithClientRef}
          onRefChange={onRefChange}
        />
      )}

      {(isOrder || isInHouseRx) && (
        <>
          <Text strong mb={1} mt={2} variant="subheading3">
            {t('Common:CHARGE_ACTION')}*
          </Text>

          <ChargeSection
            prescription={prescription}
            ref={chargeSectionRef}
            onRefChange={onRefChange}
          />
        </>
      )}

      {!isOutsidePharmacy && isControlledSubstance && (
        <ControlledSubstanceSection
          activeWorkflow={activeWorkflow}
          prescription={prescription}
          ref={controlledSubstanceSectionRef}
          onRefChange={onRefChange}
        />
      )}

      {showDoctorSelect && (
        <>
          <Text strong mt={2} variant="subheading3">
            {t('Common:DOCTOR')}
          </Text>
          <Grid item mb={2} width={250}>
            <TeamMemberSelect
              disabled={!permissions.update}
              field={doctorId}
              label={`${doctorId.label}*`}
              renderEmpty={false}
              roles={[RoleName.Veterinarian, RoleName.Veterinarian2]}
            />
          </Grid>
          {isDvmLicenseRequired && (
            <DoctorLicenseWarning
              currentDoctorHasDvmLicense={currentDoctorHasDvmLicense}
              doctorId={doctorId.value}
            />
          )}
        </>
      )}

      {showTeamMemberSelect && (
        <>
          <Text strong mb={1} mt={2} variant="subheading3">
            {t('Tasks:TASK_DETAILS.TASK_DETAILS')}
          </Text>
          <Grid bgcolor="white" width={250}>
            <UserSelect
              disabled={!permissions.update}
              emptyLabel={t('Common:ASSIGN_TEAM_MEMBER')}
              field={teamMemberId}
              label={t('Common:ASSIGN_TEAM_MEMBER')}
            />
          </Grid>
        </>
      )}

      {isOutsidePharmacy && (
        <Grid mt={3}>
          <SignatureView
            disabled={
              (isCvcRolesEnabled && !permissions.sign) ||
              (!isCvcRolesEnabled && !permissions.update) ||
              isLoading
            }
            signatureDate={DateUtils.formatDateWithHours(
              signatureData.signedDate,
            )}
            signatureString={signatureData.signatureUrl}
            signerLicence={getDVMLicensesNumbers(
              signatureData.signer,
              LicenseTypes,
            )}
            signerName={
              signatureData.signer &&
              Utils.getPersonString(signatureData.signer)
            }
            onEdit={onSignatureEdit}
          />
        </Grid>
      )}
      {!isOrder && (
        <>
          {isChewyReactiveRx ? null : (
            <Grid mt={3}>
              <SaveAsDefaultField field={saveAsDefault} />
            </Grid>
          )}
          {showAutomaticallyCreateTaskOption && (
            <Grid>
              <PuiCheckbox
                field={automaticallyCreateTask}
                label={
                  automaticallyCreateTaskLabel ||
                  t('Dialogs:PRESCRIPTION_DIALOG.AUTOMATICALLY_CREATE_TASK')
                }
              />
            </Grid>
          )}

          {showAutomaticallyCreateTaskOption &&
            automaticallyCreateTask.value && (
              <>
                <Text strong mb={1} variant="subheading3">
                  {t('Dialogs:PRESCRIPTION_DIALOG.PET_PARENT_TASK_DETAILS')}
                </Text>

                <TaskSection
                  adminFrequencyId={activeAdminFrequencyId}
                  clientId={clientId}
                  name={name}
                  orderType={
                    isOrder ? OrderType.INVENTORY : OrderType.PRESCRIPTION
                  }
                  patientId={patientId}
                  recurrenceRequired={
                    isChewyReactiveRx && automaticallyCreateTask.value
                  }
                  ref={taskSectionRef}
                  task={prescription.task}
                  onRefChange={onRefChange}
                />
              </>
            )}
        </>
      )}
    </>
  )
}
