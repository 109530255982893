import { gql } from '@apollo/client'

import {
  INVOICE_LINE_ITEM_ARRANGEMENT_INPUT_FIELDS,
  INVOICE_LINE_ITEM_FIELDS,
} from '../fragments'

export const UPDATE_INVOICE_LINE_ITEM = gql`
  ${INVOICE_LINE_ITEM_FIELDS}
  mutation UpdateInvoiceLineItem(
    $id: ID!
    $input: UpdateInvoiceLineItemInput!
  ) {
    updateInvoiceLineItem(id: $id, input: $input) {
      ...InvoiceLineItemFields
    }
  }
`

export const REARRANGE_INVOICE_LINE_ITEMS = gql`
  ${INVOICE_LINE_ITEM_ARRANGEMENT_INPUT_FIELDS}
  mutation RearrangeInvoiceLineItems(
    $invoiceId: ID!
    $input: [InvoiceLineItemArrangementInput]!
  ) {
    rearrangeInvoiceLineItems(invoiceId: $invoiceId, input: $input) {
      ...InvoiceLineItemArrangementFields
    }
  }
`
