import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  CircularProgressOverlay,
  Nil,
  PuiDialog,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import DownloadButton from '~/components/common/buttons/DownloadButton'
import {
  clearResultFrameUrl,
  completeLabOrder,
  fetchResultFrameUrl,
  getLabOrderIsCompleting,
  getResultFrameUrl,
  getResultFrameUrlIsLoading,
} from '~/store/duck/labOrders'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 820,
      maxWidth: 820,
      height: `calc(100% - ${theme.spacing(8)})`,
      maxHeight: `calc(100% - ${theme.spacing(8)})`,
      [theme.breakpoints.down('sm')]: {
        width: `calc(100% - ${theme.spacing(4)})`,
      },
    },
    iframe: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: '100%',
    },
    dialogContentRoot: {
      position: 'relative',
      height: `calc(100% - ${theme.spacing(14)})`,
    },
    button: {
      minWidth: 150,
    },
    downloadButton: {
      marginLeft: 'auto',
    },
  }),
  { name: 'OrderResultsFrameDialog' },
)

export interface OrderResultFrameDialogProps extends BasePuiDialogProps {
  canComplete?: boolean
  orderId: string | Nil
  pdfUrl: string | Nil
  resultFrameUrl: string | Nil
  resultIdentifier: string | Nil
  vendorId: string | Nil
}

const OrderResultFrameDialog = ({
  orderId,
  vendorId,
  pdfUrl,
  canComplete = false,
  resultIdentifier,
  resultFrameUrl: resultFrameUrlProp,
  open,
  onClose,
}: OrderResultFrameDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Search'])

  const isCompleting = useSelector(getLabOrderIsCompleting)
  const loadedResultFrameUrl = useSelector(getResultFrameUrl)
  const isLoadingResultFrameUrl = useSelector(getResultFrameUrlIsLoading)
  const resultFrameUrl = resultFrameUrlProp ?? loadedResultFrameUrl

  const handleClose = () => {
    dispatch(clearResultFrameUrl())

    if (onClose) {
      onClose()
    }
  }

  const handleMarkAsReviewed = () => {
    if (orderId) {
      dispatch(completeLabOrder(vendorId, orderId))
    }
  }

  useEffect(() => {
    if (!isLoadingResultFrameUrl && !resultFrameUrl && resultIdentifier) {
      dispatch(fetchResultFrameUrl(resultIdentifier))
    }
  }, [resultFrameUrl, isLoadingResultFrameUrl, resultIdentifier])

  const base = window.location.origin
  const pdfDownloadUrl = new URL(pdfUrl || '', base)
  pdfDownloadUrl.searchParams.set('download', 'true')

  return (
    <PuiDialog
      actions={
        <>
          {canComplete && (
            <ButtonWithLoader
              className={classes.button}
              disabled={isCompleting}
              loading={isCompleting}
              onClick={handleMarkAsReviewed}
            >
              {t('Common:MARK_AS_REVIEWED')}
            </ButtonWithLoader>
          )}
          <DownloadButton
            round
            classes={{
              link: classes.downloadButton,
            }}
            href={pdfDownloadUrl.href}
          />
        </>
      }
      aria-labelledby="order-results-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={t('Common:LAB_TEST_RESULTS')}
      onClose={handleClose}
    >
      <CircularProgressOverlay open={isLoadingResultFrameUrl} />
      {resultFrameUrl && (
        // eslint-disable-next-line react/iframe-missing-sandbox
        <iframe
          className={classes.iframe}
          src={resultFrameUrl}
          title="lab-order-result-frame"
        />
      )}
      {!resultIdentifier && (
        <Text variant="body2">{t('Search:NO_SEARCH_RESULTS')}</Text>
      )}
    </PuiDialog>
  )
}

export default OrderResultFrameDialog
