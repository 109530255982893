import React, { RefObject, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Field, Nil, PuiTheme, Text } from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import DialogNames from '~/constants/DialogNames'
import { PrescriptionWorkflowType } from '~/constants/PrescriptionWorkflow'
import { Prescription, SignatureData } from '~/types'
import { getWorkflowType } from '~/utils/prescription'
import useDialog from '~/utils/useDialog'

import { PrescriptionActionContextType } from '../PrescriptionContext'
import { AdministrationSectionSimpleHandle } from './AdministrationSectionSimple'
import { PrescriptionChewyActiveRxBody } from './body/PrescriptionChewyActiveRxBody'
import { PrescriptionOtherWorkflowsBody } from './body/PrescriptionOtherWorkflowsBody'
import { ChargeSectionHandle } from './ChargeSection'
import { ControlledSubstanceSectionHandle } from './ControlledSubstanceSection'
import { DiscussedWithClientFieldHandle } from './DiscussedWithClientField'
import { DispensedFromSectionHandle } from './DispensedFromSection'
import { DrugCompoundInfoHandle } from './DrugCompoundInfo'
import { PrescriptionDetailsSectionHandle } from './PrescriptionDetailsSection'
import { QuantitySectionHandle } from './QuantitySection'
import { RefillableSectionDataHandle } from './RefillableSection'
import { TaskSectionHandle } from './TaskSection'
import { VariationsTableProps } from './VariationsTable'
import WorkflowSection from './workflow-section/WorkflowSection'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    loader: {
      height: `calc(80vh - ${theme.spacing(17)})`, // approximate dialog height - bottom and header height
    },
    mismatchWarning: {
      color: 'white',
      backgroundColor: theme.colors.alertLabelError,
      display: 'flex',
      alignItems: 'center',
    },
    warningIcon: {
      marginRight: theme.spacing(2),
      width: '2rem',
      height: '2rem',
    },
    expandCollapse: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
    },
    teamMemberSelect: {
      backgroundColor: 'white',
    },
  }),
  { name: 'PrescriptionBody' },
)

export interface PrescriptionBodyProps {
  activeWorkflow?: PrescriptionWorkflowType
  areChargesPostedAndEditable?: boolean
  automaticallyCreateTaskLabel?: string
  clientId: string
  currentDoctorHasDvmLicense: boolean | null // null - no doctor selected
  currentDoctorId: string | Nil
  fields: {
    automaticallyCreateTask: Field
    autoshipFrequency: Field
    autoshipUnitId: Field
    chewyItem: Field
    compoundingReason: Field
    compoundingReasonId: Field
    doctorId: Field
    enableAutoship: Field
    saveAsDefault: Field
    teamMemberId: Field
  }
  handleSave: PrescriptionActionContextType['onSave']
  handleSetActiveWorkflow: (
    workflow: PrescriptionWorkflowType | undefined,
  ) => void
  handleVariationChange: VariationsTableProps['onVariationChange']
  isChewyCatalogItemNotLinked: boolean
  isCompoundingReasonRequired?: boolean
  isCustomCompound?: boolean
  isDvmLicenseRequired: boolean
  isEdit: boolean
  isLengthOfCourseRequired: boolean
  isRxPrescription: boolean
  isTemplatesLoaded: boolean
  isUserSignatureAreaEnabled?: boolean
  isVetDiet: boolean
  onRefChange?: () => void
  outsideSoap: boolean
  patientId: string
  prescription: Prescription
  refs: {
    administrationSectionRef: RefObject<
      AdministrationSectionSimpleHandle | PrescriptionDetailsSectionHandle
    >
    chargeSectionRef: RefObject<ChargeSectionHandle>
    controlledSubstanceSectionRef: RefObject<ControlledSubstanceSectionHandle>
    discussedWithClientRef: RefObject<DiscussedWithClientFieldHandle>
    dispensedFromSectionRef: RefObject<DispensedFromSectionHandle>
    drugCompoundInfoRef: RefObject<DrugCompoundInfoHandle>
    quantitySectionRef: RefObject<QuantitySectionHandle>
    refillableSectionRef: RefObject<RefillableSectionDataHandle>
    taskSectionRef: RefObject<TaskSectionHandle>
  }
  showAutomaticallyCreateTaskOption?: boolean
  showDiscussedWithClient?: boolean
  showDoctorSelect: boolean
  showOnlyDrugVariation?: boolean
  signatureData: SignatureData
  simpleAdministrationInstructions?: boolean
  validate: () => boolean
}

const PrescriptionBody = ({
  areChargesPostedAndEditable,
  activeWorkflow,
  automaticallyCreateTaskLabel,
  clientId,
  currentDoctorHasDvmLicense,
  currentDoctorId,
  fields: {
    automaticallyCreateTask,
    chewyItem,
    compoundingReason,
    compoundingReasonId,
    doctorId,
    saveAsDefault,
    teamMemberId,
    autoshipFrequency,
    autoshipUnitId,
    enableAutoship,
  },
  handleSave,
  handleVariationChange,
  isChewyCatalogItemNotLinked,
  isCompoundingReasonRequired,
  isCustomCompound,
  isDvmLicenseRequired,
  isEdit,
  isLengthOfCourseRequired,
  isRxPrescription,
  isTemplatesLoaded,
  isUserSignatureAreaEnabled,
  isVetDiet,
  onRefChange,
  outsideSoap,
  patientId,
  prescription,
  refs: {
    administrationSectionRef,
    chargeSectionRef,
    controlledSubstanceSectionRef,
    discussedWithClientRef,
    dispensedFromSectionRef,
    drugCompoundInfoRef,
    quantitySectionRef,
    refillableSectionRef,
    taskSectionRef,
  },
  handleSetActiveWorkflow,
  showAutomaticallyCreateTaskOption,
  showDiscussedWithClient,
  showDoctorSelect,
  showOnlyDrugVariation,
  signatureData,
  simpleAdministrationInstructions,
  validate,
}: PrescriptionBodyProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Dialogs', 'Tasks'])

  const [activeAdminFrequencyId, setActiveAdminFrequencyId] = useState<
    string | undefined
  >(prescription.administrationFrequencyId)

  const [openSignatureDialog] = useDialog(DialogNames.DOCTOR_SIGNATURE_DIALOG)

  const { isChewyReactiveRx, isChewyActiveRx } = getWorkflowType(activeWorkflow)

  const isControlledSubstance = Boolean(prescription.inventory?.controlled)

  const acceptPrescriptionSignature = (
    signerId: string,
    signaturePngString: string,
  ) => {
    handleSave(
      {
        isActiveRxSaveDraft: isChewyActiveRx,
        signatureInfo: {
          signature: signaturePngString,
          signerId,
        },
      },
      false,
    )
  }

  const handleSignatureEdit = () => {
    if (isChewyActiveRx || validate()) {
      openSignatureDialog({
        initialSignatureUrl: signatureData.signatureUrl,
        initialSignerValue: signatureData.signerId || currentDoctorId,
        disabled: isChewyActiveRx,
        enableSignerSignature: isUserSignatureAreaEnabled,
        outputFormat: 'png',
        onSign: acceptPrescriptionSignature,
      })
    }
  }

  const handleSetActiveAdminFrequencyId = (id: string) => {
    setActiveAdminFrequencyId(id)
  }

  if (!isTemplatesLoaded) {
    return (
      <Grid
        container
        alignItems="center"
        className={classes.loader}
        direction="column"
        justifyContent="center"
      >
        <CircularProgress size={32} />
      </Grid>
    )
  }

  return (
    <Grid container direction="column" p={2}>
      {prescription.mismatchWarning && (
        <Text className={classes.mismatchWarning} mb={2} px={1} variant="h1">
          <WarningIcon className={classes.warningIcon} />
          {prescription.mismatchWarning}
        </Text>
      )}
      <Text strong mb={1} variant="subheading3">
        {t('Common:WORKFLOW_ONE')}
      </Text>
      <Grid item mb={2}>
        <WorkflowSection
          activeWorkflow={activeWorkflow}
          areChargesPostedAndEditable={areChargesPostedAndEditable}
          clientId={clientId}
          isChewyReactiveRx={isChewyReactiveRx}
          isCustomCompound={isCustomCompound}
          outsideSoap={outsideSoap}
          prescription={prescription}
          onChange={handleSetActiveWorkflow}
        />
      </Grid>

      {/* At the moment only chewy active Rx was refactored separately to show more visibility */}
      {isChewyActiveRx ? (
        <PrescriptionChewyActiveRxBody
          activeAdminFrequencyId={activeAdminFrequencyId}
          activeWorkflow={activeWorkflow}
          automaticallyCreateTaskLabel={automaticallyCreateTaskLabel}
          clientId={clientId}
          currentDoctorHasDvmLicense={currentDoctorHasDvmLicense}
          fields={{
            automaticallyCreateTask,
            chewyItem,
            compoundingReason,
            compoundingReasonId,
            doctorId,
            autoshipFrequency,
            autoshipUnitId,
            enableAutoship,
          }}
          isCompoundingReasonRequired={isCompoundingReasonRequired}
          isControlledSubstance={isControlledSubstance}
          isCustomCompound={isCustomCompound}
          isEdit={isEdit}
          isRxPrescription={isRxPrescription}
          isVetDiet={isVetDiet}
          outsideSoap={outsideSoap}
          patientId={patientId}
          prescription={prescription}
          refs={{
            administrationSectionRef,
            controlledSubstanceSectionRef,
            discussedWithClientRef,
            dispensedFromSectionRef,
            drugCompoundInfoRef,
            quantitySectionRef,
            refillableSectionRef,
            taskSectionRef,
          }}
          showAutomaticallyCreateTaskOption={showAutomaticallyCreateTaskOption}
          showDiscussedWithClient={showDiscussedWithClient}
          signatureData={signatureData}
          simpleAdministrationInstructions={simpleAdministrationInstructions}
          onActiveAdminFrequencyChange={setActiveAdminFrequencyId}
          onRefChange={onRefChange}
          onSignatureEdit={handleSignatureEdit}
          onVariationChange={handleVariationChange}
        />
      ) : (
        <PrescriptionOtherWorkflowsBody
          activeAdminFrequencyId={activeAdminFrequencyId}
          activeWorkflow={activeWorkflow}
          areChargesPostedAndEditable={areChargesPostedAndEditable}
          automaticallyCreateTaskLabel={automaticallyCreateTaskLabel}
          clientId={clientId}
          currentDoctorHasDvmLicense={currentDoctorHasDvmLicense}
          fields={{
            automaticallyCreateTask,
            compoundingReason,
            compoundingReasonId,
            doctorId,
            saveAsDefault,
            teamMemberId,
          }}
          isChewyCatalogItemNotLinked={isChewyCatalogItemNotLinked}
          isCompoundingReasonRequired={isCompoundingReasonRequired}
          isControlledSubstance={isControlledSubstance}
          isCustomCompound={isCustomCompound}
          isDvmLicenseRequired={isDvmLicenseRequired}
          isEdit={isEdit}
          isLengthOfCourseRequired={isLengthOfCourseRequired}
          isRxPrescription={isRxPrescription}
          isVetDiet={isVetDiet}
          outsideSoap={outsideSoap}
          patientId={patientId}
          prescription={prescription}
          refs={{
            administrationSectionRef,
            chargeSectionRef,
            controlledSubstanceSectionRef,
            discussedWithClientRef,
            dispensedFromSectionRef,
            drugCompoundInfoRef,
            quantitySectionRef,
            refillableSectionRef,
            taskSectionRef,
          }}
          showAutomaticallyCreateTaskOption={showAutomaticallyCreateTaskOption}
          showDiscussedWithClient={showDiscussedWithClient}
          showDoctorSelect={showDoctorSelect}
          showOnlyDrugVariation={showOnlyDrugVariation}
          signatureData={signatureData}
          simpleAdministrationInstructions={simpleAdministrationInstructions}
          onRefChange={onRefChange}
          onSetActiveAdminFrequencyId={handleSetActiveAdminFrequencyId}
          onSignatureEdit={handleSignatureEdit}
          onVariationChange={handleVariationChange}
        />
      )}
    </Grid>
  )
}

export default PrescriptionBody
