import React from 'react'
import { useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  Nil,
  PuiDialog,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import CreatePractice from './CreatePractice'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 960,
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(3),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3, 4, 2, 4),
      },
    },
  }),
  { name: 'CreatePracticeDialog' },
)

interface CreatePracticeDialogProps extends BasePuiDialogProps {
  creatorRoleId: string | Nil
}

const CreatePracticeDialog = ({
  open,
  onClose,
  creatorRoleId,
}: CreatePracticeDialogProps) => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )

  return (
    <PuiDialog
      aria-labelledby="create-practice-dialog"
      classes={{
        paper: classes.paper,
      }}
      maxWidth={isMobile ? 'sm' : 'md'}
      open={open}
      onClose={onClose}
    >
      <CreatePractice creatorRoleId={creatorRoleId} onCreated={onClose} />
    </PuiDialog>
  )
}

export default CreatePracticeDialog
