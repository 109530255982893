import React, { RefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Grid } from '@mui/material'
import {
  Field,
  moment,
  PermissionArea,
  PuiCheckbox,
  RoleName,
  Text,
} from '@pbt/pbt-ui-components'

import TeamMemberSelect from '~/components/common/inputs/TeamMemberSelect'
import FeatureToggle from '~/constants/featureToggle'
import { OrderType } from '~/constants/SOAPStates'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPrescriptionName } from '~/utils/prescription'

import AdministrationSectionSimple, {
  AdministrationSectionSimpleHandle,
} from '../AdministrationSectionSimple'
import ChewyItems from '../ChewyItems'
import CompoundingReason from '../CompoundingReason'
import ControlledSubstanceSection, {
  ControlledSubstanceSectionHandle,
} from '../ControlledSubstanceSection'
import {
  DiscussedWithClientField,
  DiscussedWithClientFieldHandle,
} from '../DiscussedWithClientField'
import { DispensedFromSectionHandle } from '../DispensedFromSection'
import DoctorLicenseWarning from '../DoctorLicenseWarning'
import { DrugCompoundInfoHandle } from '../DrugCompoundInfo'
import DrugInfoSection from '../DrugInfoSection'
import type { PrescriptionBodyProps } from '../PrescriptionBody'
import PrescriptionDetailsSection, {
  PrescriptionDetailsSectionHandle,
  PrescriptionDetailsSectionProps,
} from '../PrescriptionDetailsSection'
import { PrescriptionSignature } from '../PrescriptionSignature'
import QuantitySection, { QuantitySectionHandle } from '../QuantitySection'
import {
  RefillableSection,
  RefillableSectionDataHandle,
} from '../RefillableSection'
import TaskSection, { TaskSectionHandle } from '../TaskSection'
import { VariationsTableProps } from '../VariationsTable'
import VetDietInfo from '../VetDietInfo'

export interface PrescriptionChewyActiveRxBodyProps
  extends Pick<
    PrescriptionBodyProps,
    | 'activeWorkflow'
    | 'automaticallyCreateTaskLabel'
    | 'clientId'
    | 'currentDoctorHasDvmLicense'
    | 'isCompoundingReasonRequired'
    | 'isCustomCompound'
    | 'isEdit'
    | 'isRxPrescription'
    | 'isVetDiet'
    | 'outsideSoap'
    | 'patientId'
    | 'prescription'
    | 'showAutomaticallyCreateTaskOption'
    | 'showDiscussedWithClient'
    | 'signatureData'
    | 'simpleAdministrationInstructions'
  > {
  activeAdminFrequencyId: string | undefined
  fields: {
    automaticallyCreateTask: Field
    autoshipFrequency: Field
    autoshipUnitId: Field
    chewyItem: Field
    compoundingReason: Field
    compoundingReasonId: Field
    doctorId: Field
    enableAutoship: Field
  }
  isControlledSubstance: boolean
  onActiveAdminFrequencyChange?: PrescriptionDetailsSectionProps['onActiveAdminFrequencyChange']
  onRefChange?: () => void
  onSignatureEdit: () => void
  onVariationChange: VariationsTableProps['onVariationChange']
  refs: {
    administrationSectionRef: RefObject<
      AdministrationSectionSimpleHandle | PrescriptionDetailsSectionHandle
    >
    controlledSubstanceSectionRef: RefObject<ControlledSubstanceSectionHandle>
    discussedWithClientRef: RefObject<DiscussedWithClientFieldHandle>
    dispensedFromSectionRef: RefObject<DispensedFromSectionHandle>
    drugCompoundInfoRef: RefObject<DrugCompoundInfoHandle>
    quantitySectionRef: RefObject<QuantitySectionHandle>
    refillableSectionRef: RefObject<RefillableSectionDataHandle>
    taskSectionRef: RefObject<TaskSectionHandle>
  }
}

const DEFAULT_CHEWY_VET_DIET_REFILLS_QUANTITY = 999
const DEFAULT_CHEWY_VET_DIET_REFILLS_EXPIRATION_DATE = moment()
  .add(1, 'year')
  .toISOString()

export const PrescriptionChewyActiveRxBody = ({
  activeWorkflow,
  activeAdminFrequencyId,
  automaticallyCreateTaskLabel,
  clientId,
  currentDoctorHasDvmLicense,
  fields: {
    automaticallyCreateTask,
    chewyItem,
    compoundingReason,
    compoundingReasonId,
    doctorId,
    autoshipFrequency,
    autoshipUnitId,
    enableAutoship,
  },
  isCompoundingReasonRequired,
  isControlledSubstance,
  isCustomCompound,
  isEdit,
  isRxPrescription,
  isVetDiet,
  onActiveAdminFrequencyChange,
  onRefChange,
  onSignatureEdit,
  onVariationChange,
  outsideSoap,
  patientId,
  prescription,
  refs: {
    administrationSectionRef,
    controlledSubstanceSectionRef,
    discussedWithClientRef,
    dispensedFromSectionRef,
    drugCompoundInfoRef,
    quantitySectionRef,
    refillableSectionRef,
    taskSectionRef,
  },
  showAutomaticallyCreateTaskOption,
  showDiscussedWithClient,
  signatureData,
  simpleAdministrationInstructions,
}: PrescriptionChewyActiveRxBodyProps) => {
  const { t } = useTranslation(['Common', 'Dialogs'])

  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )
  const permissions = useSelector(getCRUDByArea(PermissionArea.PRESCRIPTION))

  const name = getPrescriptionName(prescription, isFoodCatalogEnabled)
  const isVetDietCreation = isFoodCatalogEnabled && isVetDiet && !isEdit

  return (
    <>
      {isVetDiet && (
        <>
          <Text strong mb={1} variant="subheading3">
            {t('Dialogs:PRESCRIPTION_DIALOG.VET_DIET_INFO')}
          </Text>
          <VetDietInfo drugInfo={prescription.drugInfo} />
        </>
      )}

      {!isVetDiet && (
        <>
          <Text strong mb={1} variant="subheading3">
            {t('Dialogs:PRESCRIPTION_DIALOG.DRUG_INFO')}
          </Text>
          <DrugInfoSection
            activeWorkflow={activeWorkflow}
            compoundingReasonField={compoundingReason}
            compoundingReasonIdField={compoundingReasonId}
            drugCompoundInfoRef={drugCompoundInfoRef}
            isChewyReactiveRx={false}
            isCustomCompound={isCustomCompound}
            outsideSoap={outsideSoap}
            patientId={patientId}
            prescription={prescription}
            onRefChange={onRefChange}
            onVariationChange={onVariationChange}
          />
        </>
      )}

      <ChewyItems chewyItemField={chewyItem} />

      <QuantitySection
        isChewyActiveRx
        showTotalQuantitySection
        dispensedFromSectionRef={dispensedFromSectionRef}
        isEdit={isEdit}
        prescription={prescription}
        ref={quantitySectionRef}
        selectedChewyItem={chewyItem.value}
        onRefChange={onRefChange}
      />

      <Text strong mt={2} variant="subheading3">
        {isVetDiet
          ? t('Common:DETAILS')
          : t('Dialogs:PRESCRIPTION_DIALOG.PRESCRIPTION_DETAILS')}
      </Text>
      {simpleAdministrationInstructions ? (
        <AdministrationSectionSimple
          prescription={prescription}
          ref={administrationSectionRef}
          onRefChange={onRefChange}
        />
      ) : (
        <PrescriptionDetailsSection
          isChewy
          alwaysExpanded={isRxPrescription}
          isExpandable={false}
          isLengthOfCourseRequired={isRxPrescription}
          isOrder={false}
          isRxPrescription={isRxPrescription}
          isVetDiet={isVetDiet}
          prescription={prescription}
          ref={administrationSectionRef}
          onActiveAdminFrequencyChange={onActiveAdminFrequencyChange}
          onRefChange={onRefChange}
        />
      )}

      {isControlledSubstance && (
        <ControlledSubstanceSection
          activeWorkflow={activeWorkflow}
          prescription={prescription}
          ref={controlledSubstanceSectionRef}
          onRefChange={onRefChange}
        />
      )}

      {isCompoundingReasonRequired && (
        <Box maxWidth="100%" mb={-2} mt={2}>
          <CompoundingReason
            compoundingReasonField={compoundingReason}
            compoundingReasonIdField={compoundingReasonId}
          />
        </Box>
      )}

      <RefillableSection
        isChewyActiveRx
        autoshipFrequency={autoshipFrequency}
        autoshipUnitId={autoshipUnitId}
        enableAutoship={enableAutoship}
        quantityNumbRefills={
          isVetDietCreation
            ? DEFAULT_CHEWY_VET_DIET_REFILLS_QUANTITY
            : prescription.quantityNumbRefills
        }
        quantityRefillExpirationDate={
          isVetDietCreation
            ? DEFAULT_CHEWY_VET_DIET_REFILLS_EXPIRATION_DATE
            : prescription.quantityRefillExpirationDate
        }
        ref={refillableSectionRef}
        onRefChange={onRefChange}
      />

      {showDiscussedWithClient && (
        <DiscussedWithClientField
          discussedWithClient={prescription.discussedWithClient}
          ref={discussedWithClientRef}
          onRefChange={onRefChange}
        />
      )}

      <Text strong mt={2} variant="subheading3">
        {t('Common:DOCTOR')}
      </Text>
      <Grid item mb={2} width={250}>
        <TeamMemberSelect
          disabled={!permissions.update}
          field={doctorId}
          label={`${doctorId.label}*`}
          renderEmpty={false}
          roles={[
            RoleName.Veterinarian,
            RoleName.Veterinarian2,
            RoleName.ChewyAuthorization,
          ]}
        />
      </Grid>

      <DoctorLicenseWarning
        currentDoctorHasDvmLicense={currentDoctorHasDvmLicense}
        doctorId={doctorId.value}
      />

      {isCompoundingReasonRequired && (
        <PrescriptionSignature
          outsideSoap={outsideSoap}
          signatureData={signatureData}
          onSignatureEdit={onSignatureEdit}
        />
      )}

      {/* Boop tasks will be implemented in future PR */}
      {showAutomaticallyCreateTaskOption && (
        <Grid>
          <PuiCheckbox
            field={automaticallyCreateTask}
            label={
              automaticallyCreateTaskLabel ||
              t('Dialogs:PRESCRIPTION_DIALOG.AUTOMATICALLY_CREATE_TASK')
            }
          />
        </Grid>
      )}

      {showAutomaticallyCreateTaskOption && automaticallyCreateTask.value && (
        <>
          <Text strong mb={1} variant="subheading3">
            {t('Dialogs:PRESCRIPTION_DIALOG.PET_PARENT_TASK_DETAILS')}
          </Text>

          <TaskSection
            adminFrequencyId={activeAdminFrequencyId}
            clientId={clientId}
            name={name}
            orderType={OrderType.PRESCRIPTION}
            patientId={patientId}
            recurrenceRequired={automaticallyCreateTask.value}
            ref={taskSectionRef}
            task={prescription.task}
            onRefChange={onRefChange}
          />
        </>
      )}
    </>
  )
}
