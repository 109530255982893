import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader, PuiTheme, Text } from '@pbt/pbt-ui-components'

import { PracticeDetailsPanels } from '~/components/dashboard/admin/general/practices/practices'
import { getCurrentBusinessId } from '~/store/reducers/auth'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    button: {
      marginTop: theme.spacing(2),
      minWidth: 150,
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'MissingCIFNumber' },
)

export interface MissingCIFNumberProps {
  handleClose: () => void
}

const MissingCIFNumber = ({ handleClose }: MissingCIFNumberProps) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const currentBusinessId = useSelector(getCurrentBusinessId)

  const { t } = useTranslation(['Dialogs'])

  const handleAddCIFNumber = () => {
    handleClose()
    navigate(
      `/admin/general/practices/${currentBusinessId}?panels=${PracticeDetailsPanels.GENERAL_INFORMATION}`,
    )
  }

  return (
    <>
      <Text align="center" variant="body">
        {t('Dialogs:INVOICE_MISSING_FIELDS_DIALOG.CIF_DESCRIPTION')}
      </Text>
      <ButtonWithLoader className={classes.button} onClick={handleAddCIFNumber}>
        {t('Dialogs:INVOICE_MISSING_FIELDS_DIALOG.ADD_CIF_NUMBER')}
      </ButtonWithLoader>
    </>
  )
}

export default MissingCIFNumber
