import { Nil } from '@pbt/pbt-ui-components'

import { GroupIdentifier } from '~/api/graphql/generated/types'
import {
  TopicContext,
  WsAction,
  WsReportAggregationAction,
} from '~/constants/websocket'

import type { EmailEntityConfig } from '../index'

export type WSTopic = {
  actionType?: string
  topic: string
  topicContext: TopicContext
  useUserPrefix: boolean
  wsMessageHandleDataByWsAction: {
    [K in WsAction & WsReportAggregationAction]?: { actionType: string }
  }
}

export type EmailInvoiceData = EmailEntityConfig & {
  invoiceId?: string
  invoiceIds: string[]
}

export type EmailInvoiceParams = {
  complete: boolean | Nil
  expandedGroups?: string | Nil
  includeServiceFee?: boolean | Nil
}

export type GeneratePdfForInvoiceData = EmailInvoiceData & {
  params: EmailInvoiceParams
}

export type GeneratePdfForInvoiceDataV2 = {
  expandedGroups: GroupIdentifier[]
  includeServiceFee?: boolean
  invoiceId: string
}

export type EmailChargeSheetData = EmailEntityConfig & {
  businessId: string
}

export enum ContactMethodName {
  TEXT_MESSAGE = 'Text message',
  CALL_MOBILE_PHONE = 'Call mobile phone',
  CALL_OTHER_PHONE = 'Call other phone',
  EMAIL = 'Email',
  CALL_HOME_PHONE = 'Call home phone',
  CALL_WORK_PHONE = 'Call work phone',
}

export enum CommunicationTransportBackupOptionName {
  BOOP_MESSAGE = 'Boop message',
  EMAIL = 'Email',
  PREFERRED_CONTACT_METHOD = 'Preferred contact method',
  TEXT_MESSAGE = 'Text message',
}
