import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControlLabel, Grid, Radio, RadioGroup, Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { ClassesType, Nil, PuiTheme, Text } from '@pbt/pbt-ui-components'
import { TextProps } from '@pbt/pbt-ui-components/src/components/Text'

import { ConversationTransport } from '~/api/graphql/generated/types'
import { getContact } from '~/store/duck/contacts'
import { useCanSendSmsToClient } from '~/store/hooks/clients'
import {
  getCurrentBusinessWellnessPlansEnabled,
  getSmsCommunicationsEnabled,
} from '~/store/reducers/auth'
import { getUser } from '~/store/reducers/users'
import { ContactSlot } from '~/types'
import { useBoopEnabled } from '~/utils/boop'

import useGetPreferredContactMethod from '../../clients/details/new-client-and-patient/useGetPreferredContactMethod'
import MissingClientContactsPopper from './MissingClientContactsPopper'
import MissingGenericContactsPopper from './MissingGenericContactsPopper'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    radio: {
      padding: theme.spacing(0.75),
      marginLeft: theme.spacing(-1),
    },
    labelRoot: {
      margin: 0,
    },
  }),
  { name: 'ConversationTransportSelect' },
)

export interface ConversationTransportSelectProps {
  allowedTransports?: ConversationTransport[]
  classes?: ClassesType<typeof useStyles>
  configTransport?: ConversationTransport
  contactSlot: ContactSlot
  disabled?: boolean
  label?: string
  labelVariant?: TextProps['variant']
  onChange: (transport: ConversationTransport) => void
  transport: ConversationTransport | Nil
}

const ConversationTransportSelect = ({
  allowedTransports = Object.values(ConversationTransport),
  label: labelProp,
  labelVariant = 'body2',
  contactSlot,
  onChange,
  configTransport,
  transport,
  classes: classesProps,
  disabled = false,
}: ConversationTransportSelectProps) => {
  const classes = useStyles({ classes: classesProps })
  const { t } = useTranslation(['Common', 'Clients', 'Dialogs'])

  const isSmsCommunicationsEnabled = useSelector(getSmsCommunicationsEnabled)
  const wellnessPlansEnabled = useSelector(
    getCurrentBusinessWellnessPlansEnabled,
  )

  const rootRef = useRef<HTMLDivElement>(null)

  const label = labelProp || `${t('Common:SEND_AS')}:`

  const [addMissingClientsOpen, setAddMissingClientsOpen] = useState(false)

  const logPhoneCallAllowed = R.includes(
    ConversationTransport.LogPhoneCall,
    allowedTransports,
  )
  const boopAllowed = R.includes(ConversationTransport.Boop, allowedTransports)
  const smsAllowed =
    R.includes(ConversationTransport.Sms, allowedTransports) &&
    isSmsCommunicationsEnabled
  const emailAllowed = R.includes(
    ConversationTransport.Email,
    allowedTransports,
  )

  const { contactId, clientId } = contactSlot
  const contact = useSelector(getContact(contactId))
  const client = useSelector(getUser(clientId))

  const { transport: preferredTransport } = useGetPreferredContactMethod({
    client,
  })

  const boopFlowEnabled = useBoopEnabled()

  const isBoopUser = Boolean(client?.isBoopUser)
  const isRecipientEmailExists =
    Boolean(contact?.email) || Boolean(client?.email)
  const isRecipientPhoneExists =
    Boolean(contact?.phone) || Boolean(client?.mobilePhone)

  const canSendSmsToClient = useCanSendSmsToClient(clientId)

  const canSendSmsToPerson = canSendSmsToClient || Boolean(contactId)

  const isEmailDisabled = !isRecipientEmailExists || disabled
  const isSmsDisabled =
    !isRecipientPhoneExists || !canSendSmsToPerson || disabled
  const isBoopDisabled = !isBoopUser || disabled
  const isLogPhoneCallDisabled = disabled

  const onAddContact = () => {
    setAddMissingClientsOpen(true)
  }

  useEffect(() => {
    const allowedInitialTransports = [
      isRecipientEmailExists && emailAllowed && ConversationTransport.Email,
      isRecipientPhoneExists &&
        smsAllowed &&
        canSendSmsToPerson &&
        ConversationTransport.Sms,
      isBoopUser &&
        boopAllowed &&
        boopFlowEnabled &&
        ConversationTransport.Boop,
      logPhoneCallAllowed && ConversationTransport.LogPhoneCall,
    ].filter(Boolean) as ConversationTransport[]

    const firstAllowedTransport = R.head(allowedInitialTransports)
    const overridenTransport = configTransport || preferredTransport

    if (
      overridenTransport &&
      allowedInitialTransports.includes(overridenTransport)
    ) {
      onChange(overridenTransport)
    } else if (firstAllowedTransport) {
      onChange(firstAllowedTransport)
    }
  }, [
    isRecipientEmailExists,
    emailAllowed,
    isRecipientPhoneExists,
    smsAllowed,
    canSendSmsToPerson,
    isBoopUser,
    boopAllowed,
    boopFlowEnabled,
    preferredTransport,
    configTransport,
  ])

  const radioClasses = {
    root: classes.labelRoot,
  }

  return (
    <Grid container direction="column" ref={rootRef}>
      <Grid item>
        <Text strong mb={1} variant={labelVariant}>
          {label}
        </Text>
      </Grid>
      <Grid item mb={2}>
        <RadioGroup
          aria-label={label}
          name="conversationTypeRadio"
          value={transport || ''}
          onChange={(_, value) => onChange(value as ConversationTransport)}
        >
          <Stack spacing={0.5}>
            {emailAllowed && (
              <FormControlLabel
                classes={radioClasses}
                control={
                  <Radio className={classes.radio} disabled={isEmailDisabled} />
                }
                label={
                  <>
                    <Text disabled={isEmailDisabled} variant="body2">
                      {t('Common:EMAIL')}
                    </Text>
                    {!isRecipientEmailExists && (
                      <Text disabled={isEmailDisabled} variant="body2">
                        {t(
                          'Dialogs:NEW_CONVERSATION_WITH_STEPS_DIALOG.THERE_IS_NO_EMAIL',
                        )}
                        &nbsp;
                        <Text
                          link
                          component="span"
                          disabled={isEmailDisabled}
                          variant="body2"
                          onClick={onAddContact}
                        >
                          {t('Common:ADD_ONE_NOW')}
                        </Text>
                      </Text>
                    )}
                  </>
                }
                value={ConversationTransport.Email}
              />
            )}
            {smsAllowed && (
              <FormControlLabel
                classes={radioClasses}
                control={
                  <Radio className={classes.radio} disabled={isSmsDisabled} />
                }
                label={
                  <>
                    <Text disabled={isSmsDisabled} variant="body2">
                      {t('Common:TEXT_MESSAGE')}
                    </Text>
                    {!isRecipientPhoneExists ? (
                      <Text disabled={isSmsDisabled} variant="body2">
                        {t(
                          'Dialogs:NEW_CONVERSATION_WITH_STEPS_DIALOG.THERE_IS_NO_PHONE_NUMBER',
                        )}
                        &nbsp;
                        <Text
                          link
                          component="span"
                          disabled={isSmsDisabled}
                          variant="body2"
                          onClick={onAddContact}
                        >
                          {t('Common:ADD_ONE_NOW')}
                        </Text>
                      </Text>
                    ) : (
                      <>
                        {!canSendSmsToPerson && (
                          <Text disabled variant="body2">
                            {t('Clients:SMS_CONSENT_DISABLED')}
                          </Text>
                        )}
                      </>
                    )}
                  </>
                }
                value={ConversationTransport.Sms}
              />
            )}
            {wellnessPlansEnabled && boopAllowed && boopFlowEnabled && (
              <FormControlLabel
                classes={radioClasses}
                control={
                  <Radio className={classes.radio} disabled={isBoopDisabled} />
                }
                label={
                  <>
                    <Text disabled={isBoopDisabled} variant="body2">
                      {t('Common:BOOP_SYSTEM_NAME')}
                    </Text>
                    {!isBoopUser && (
                      <Text disabled={isBoopDisabled} variant="body2">
                        {t(
                          'Dialogs:NEW_CONVERSATION_WITH_STEPS_DIALOG.THERE_IS_NO_BOOP',
                        )}
                        &nbsp;
                      </Text>
                    )}
                  </>
                }
                value={ConversationTransport.Boop}
              />
            )}
            {logPhoneCallAllowed && (
              <FormControlLabel
                classes={radioClasses}
                control={
                  <Radio
                    className={classes.radio}
                    disabled={isLogPhoneCallDisabled}
                  />
                }
                label={t('Common:LOG_PHONE_CALL')}
                value={ConversationTransport.LogPhoneCall}
              />
            )}
          </Stack>
        </RadioGroup>
      </Grid>
      {addMissingClientsOpen && contactSlot?.contactId && (
        <MissingGenericContactsPopper
          anchorEl={rootRef.current}
          contactId={contactSlot?.contactId}
          open={addMissingClientsOpen}
          onClose={() => setAddMissingClientsOpen(false)}
        />
      )}
      {addMissingClientsOpen && contactSlot?.clientId && (
        <MissingClientContactsPopper
          anchorEl={rootRef.current}
          clientId={contactSlot?.clientId}
          open={addMissingClientsOpen}
          onClose={() => setAddMissingClientsOpen(false)}
        />
      )}
    </Grid>
  )
}

export default ConversationTransportSelect
