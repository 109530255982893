import React from 'react'
import { FormControlLabel, Grid, Radio } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import { ReminderProtocol } from '~/types'

import { useGetDueDateString } from '../reminderUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
    radio: {
      marginLeft: theme.spacing(2),
    },
    label: {
      color: theme.colors.secondaryText,
      padding: theme.spacing(1),
    },
    dues: {
      paddingRight: theme.spacing(2),
    },
  }),
  { name: 'ReminderProtocolRow' },
)

export interface ReminderProtocolRowProps {
  checked?: boolean
  item: ReminderProtocol
  onChange: () => void
}

const ReminderProtocolRow = ({
  item,
  checked = false,
  onChange,
}: ReminderProtocolRowProps) => {
  const classes = useStyles()
  const dueDate = useGetDueDateString(item.dueDateOffset || {})
  const dueWith = item.due || []

  return (
    <Grid container alignItems="center" className={classes.root} py={1}>
      <Grid item xs={5}>
        <FormControlLabel
          classes={{
            root: classes.radio,
            label: classes.label,
          }}
          control={<Radio checked={checked} />}
          label={item.reminderName}
          onClick={(event) => {
            event.preventDefault()
            onChange()
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Text variant="body2">{dueDate}</Text>
      </Grid>
      <Grid item xs={5}>
        <Grid className={classes.dues}>
          {dueWith.map(({ id, name }) => (
            <Text key={id} variant="body2">
              {name}
            </Text>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReminderProtocolRow
