import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, Skeleton, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { ClassesType, PuiTheme } from '@pbt/pbt-ui-components'

import { getPracticeTypes } from '~/store/reducers/constants'
import { arrayToMap } from '~/utils'

import BusinessTypeIcon from './icons/BusinessTypeIcon'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    item: {
      marginRight: theme.spacing(0.5),
      '&:last-child': {
        marginRight: 0,
      },
    },
  }),
  { name: 'BusinessTypeIconGroup' },
)

export interface BusinessTypeIconGroupProps {
  className?: string
  classes?: ClassesType<typeof useStyles>
  ids?: string[]
  isLoading?: boolean
  showTooltip?: boolean
}

const BusinessTypeIconGroup = ({
  ids,
  isLoading,
  showTooltip,
  className,
  classes: classesProp,
}: BusinessTypeIconGroupProps) => {
  const classes = useStyles({ classes: classesProp })

  const PracticeTypes = useSelector(getPracticeTypes)
  const practiceTypesMap = arrayToMap(
    PracticeTypes,
    R.prop('id'),
    R.prop('name'),
  )

  if (isLoading) {
    return <Skeleton height={20} variant="circular" width={20} />
  }

  if (!ids?.length) {
    return null
  }

  return (
    <Grid container item alignItems="center" className={className}>
      {ids.map((id) =>
        showTooltip ? (
          <Tooltip
            className={classes.item}
            key={id}
            placement="top"
            title={practiceTypesMap?.[id]}
          >
            <Grid item>
              <BusinessTypeIcon iconName={practiceTypesMap?.[id]} />
            </Grid>
          </Tooltip>
        ) : (
          <BusinessTypeIcon
            className={classes.item}
            iconName={practiceTypesMap?.[id]}
            key={id}
          />
        ),
      )}
    </Grid>
  )
}

export default BusinessTypeIconGroup
