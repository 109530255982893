import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  NumberUtils,
  PuiDialog,
  PuiTextArea,
  PuiTheme,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import PaymentTypes from '~/constants/paymentTypes'
import { createPayment } from '~/store/actions/payments'
import { getPaymentType } from '~/store/reducers/constants'
import {
  getLastCreatedPayment,
  getPaymentsIsLoading,
} from '~/store/reducers/payments'
import { Payment, UnsavedExtendPayment } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import PaymentClientNotesSection from './PaymentClientNotesSection'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
    actions: {
      padding: theme.spacing(1, 3),
    },
    button: {
      width: 155,
    },
  }),
  { name: 'UndoPaymentDialog' },
)

interface UndoPaymentDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  onOk?: () => void
  payment: Payment
}

const UndoPaymentDialog = ({
  open,
  onClose,
  onOk,
  payment,
  clientId,
}: UndoPaymentDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const PaymentType = useSelector(getPaymentType)
  const isLoading = useSelector(getPaymentsIsLoading)
  const createdPayment = useSelector(getLastCreatedPayment)
  const { t } = useTranslation('Common')

  const [openPaymentDetails] = useDialog(DialogNames.PAYMENT_DETAILS)

  const UndoPaymentType = Utils.findConstantIdByName(
    PaymentTypes.UNDO,
    PaymentType,
  )

  const onUndoPaymentCreated = () => {
    if (onClose) {
      onClose()
    }
    if (createdPayment) {
      if (onOk) {
        onOk()
      }
      openPaymentDetails({
        clientId,
        payment: createdPayment,
      })
    }
  }

  const closeDialogAfterCreation = useCloseAfterCreation(
    onUndoPaymentCreated,
    getPaymentsIsLoading,
  )

  const {
    fields: { notes },
    validate,
  } = useFields([{ name: 'notes', initialValue: '' }])

  const undo = () => {
    if (validate()) {
      const undoPayment = {
        paymentTypeId: UndoPaymentType,
        notes: notes.value,
        originPaymentId: payment.id,
      } as UnsavedExtendPayment

      closeDialogAfterCreation()
      dispatch(createPayment(clientId, undoPayment))
    }
  }

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader
          className={classes.button}
          loading={isLoading}
          onClick={undo}
        >
          {t('Common:UNDO_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="undo-payment-dialog"
      classes={{
        actions: classes.actions,
        paper: classes.paper,
      }}
      open={open}
      title={t('Common:UNDO_ACTION')}
      onClose={onClose}
    >
      <Grid container item>
        <Grid container item p={3} xs={7}>
          <Grid>
            <Text strong mb={2} variant="body">
              {`${t('Common:PAYMENTS.UNDO_AMOUNT')}: ${NumberUtils.formatMoney(
                payment.amount,
              )}`}
            </Text>
          </Grid>
          <Grid xs={12}>
            <PuiTextArea
              field={notes}
              label={t('Common:PAYMENTS.UNDO_NOTES')}
              margin="none"
            />
          </Grid>
        </Grid>
        <Grid container item xs>
          <PaymentClientNotesSection
            isRefund
            clientId={clientId}
            payment={payment}
          />
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default UndoPaymentDialog
