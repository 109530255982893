import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Button, Collapse, Grid, IconButton, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme } from '@pbt/pbt-ui-components'
import { Pin } from '@pbt/pbt-ui-components/src/icons'

import MarketplaceItemIframe from '~/components/common/marketplaceIframes/MarkeplaceItemIframe'
import { MarketplaceWorkflowNames } from '~/constants/marketplaceConstants'
import { fetchMarketplaceIFrames } from '~/store/duck/marketplace'
import {
  getIsMarketplacePanelPinned,
  pinMarketplacePanel,
  unpinMarketplacePanel,
} from '~/store/duck/userSettings'
import { useGetWorkflowIFrames } from '~/store/hooks/marketplace'
import { getCurrentBusinessId, getCurrentUserId } from '~/store/reducers/auth'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
    },
    expandButton: {
      textTransform: 'none',
      color: theme.colors.title,
      marginLeft: theme.spacing(2.5),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    collapse: {
      padding: 0,
      width: 0,
      minWidth: '100%',
      overflowX: 'scroll',
    },
    collapseInner: {
      display: 'flex',
    },
    marketplaceItem: {
      '&:first-of-type': {
        marginLeft: theme.spacing(2.5),
      },
      margin: theme.spacing(0.5, 0.5, 1, 0.5),
    },
    iconButton: {
      margin: theme.spacing(1, 2, 1, 'auto'),
      padding: 0,
      '&:hover': {
        backgroundColor: theme.colors.selectedOption,
      },
    },
    pinIcon: {
      color: theme.colors.tabLabel,
    },
    iconButtonPinned: {
      backgroundColor: theme.colors.link,
    },
    pinIconPinned: {
      color: theme.colors.tableBackground,
    },
  }),
  { name: 'ClientAndPatientMarketplacePanel' },
)

export interface ClientAndPatientMarketplacePanelProps {
  clientId: string
  patientId: string
}

const ClientAndPatientMarketplacePanel = ({
  clientId,
  patientId,
}: ClientAndPatientMarketplacePanelProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Tooltips'])

  const isMarketplacePanelPinned = useSelector(getIsMarketplacePanelPinned)
  const currentUserId = useSelector(getCurrentUserId) as string
  const businessId = useSelector(getCurrentBusinessId)

  const [expanded, setExpanded] = useState(isMarketplacePanelPinned)
  const [pinned, setPinned] = useState(isMarketplacePanelPinned)

  useEffect(() => {
    if (businessId) {
      dispatch(fetchMarketplaceIFrames())
    }
  }, [businessId])

  useEffect(() => {
    if (expanded === undefined && isMarketplacePanelPinned) {
      setExpanded(isMarketplacePanelPinned)
      setPinned(isMarketplacePanelPinned)
    }
  }, [isMarketplacePanelPinned])

  const iFrames = useGetWorkflowIFrames(
    MarketplaceWorkflowNames.CLIENT_AND_PATIENT_DETAILS,
  )
  const hasIFrames = iFrames?.length > 0

  const handlePinClicked = () => {
    dispatch(
      isMarketplacePanelPinned
        ? unpinMarketplacePanel(currentUserId)
        : pinMarketplacePanel(currentUserId),
    )
    setPinned(!pinned)
  }

  const handleExpandCollapseClick = () => {
    if (expanded && pinned) {
      dispatch(unpinMarketplacePanel(currentUserId))
      setPinned(false)
    }

    setExpanded(!expanded)
  }

  if (!hasIFrames) {
    return null
  }

  return (
    <Grid
      container
      alignItems="flex-start"
      className={classes.root}
      direction="column"
      wrap="nowrap"
    >
      <Grid container>
        <Button
          className={classes.expandButton}
          onClick={handleExpandCollapseClick}
        >
          {expanded
            ? t('Common:COLLAPSE_MARKETPLACE')
            : t('Common:EXPAND_MARKETPLACE')}
          {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Button>
        {(expanded || pinned) && (
          <Tooltip
            placement="top-start"
            title={
              pinned
                ? t('Tooltips:UNPIN_MARKETPLACE')
                : t('Tooltips:PIN_MARKETPLACE')
            }
          >
            <IconButton
              className={classNames(classes.iconButton, {
                [classes.iconButtonPinned]: pinned,
              })}
              size="large"
              onClick={handlePinClicked}
            >
              <Pin
                className={classNames(classes.pinIcon, {
                  [classes.pinIconPinned]: pinned,
                })}
              />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
      <Collapse
        className={classes.collapse}
        classes={{ wrapperInner: classes.collapseInner }}
        in={expanded}
      >
        {iFrames.map((iFrame) => (
          <MarketplaceItemIframe
            preview
            className={classes.marketplaceItem}
            id={iFrame.id}
            key={iFrame.id}
            params={{
              businessId,
              patientId,
              clientId,
            }}
            title={iFrame.name}
            url={iFrame.tileUrl}
            visible={expanded}
          />
        ))}
        <Grid pr={0.5} />
      </Collapse>
    </Grid>
  )
}

export default ClientAndPatientMarketplacePanel
