import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  DateUtils,
  NumberUtils,
  PuiDialog,
  PuiTextArea,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import { MembershipPlan } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
    actions: {
      padding: theme.spacing(2, 3),
    },
    dialogContentRoot: {
      padding: theme.spacing(2, 12.5, 2, 3),
    },
    button: {
      minWidth: 170,
    },
    item: {
      borderBottom: theme.constants.tabBorder,
      '&:not(:first-of-type)': {
        paddingTop: theme.spacing(1),
      },
      paddingBottom: theme.spacing(1),
    },
    textArea: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'CancelMembershipDetailsDialog' },
)

interface CancelMembershipDetailsDialogProps extends BasePuiDialogProps {
  plan: MembershipPlan
}

const CancelMembershipDetailsDialog = ({
  plan,
  open,
  onClose,
}: CancelMembershipDetailsDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Dialogs'])

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader className={classes.button} onClick={onClose}>
          {t('Common:CLOSE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="cancel-membership-details-dialog"
      classes={{
        paper: classes.paper,
        actions: classes.actions,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={t('Dialogs:CANCEL_MEMBERSHIP_DETAILS_DIALOG.TITLE')}
      onClose={onClose}
    >
      <Grid container item className={classes.item}>
        <Grid item xs={4}>
          <Text strong variant="body2">
            {t('Common:PAYMENTS.REFUNDED')}
          </Text>
        </Grid>
        <Grid item xs>
          <Text variant="body2">
            {NumberUtils.formatMoney(plan?.refundAmount)}
          </Text>
        </Grid>
      </Grid>
      <Grid container item className={classes.item}>
        <Grid item xs={4}>
          <Text strong variant="body2">
            {t('Dialogs:CANCEL_MEMBERSHIP_DETAILS_DIALOG.CANCELLATION_REASON')}
          </Text>
        </Grid>
        <Grid item xs>
          <Text variant="body2">{plan?.cancellationReason || '-'}</Text>
        </Grid>
      </Grid>
      <Grid container item className={classes.item}>
        <Grid item xs={4}>
          <Text strong variant="body2">
            {t('Dialogs:CANCEL_MEMBERSHIP_DETAILS_DIALOG.CANCELLED_DATE_TIME')}
          </Text>
        </Grid>
        <Grid item xs>
          <Text variant="body2">
            {DateUtils.formatDate(plan?.cancellationDate)}
          </Text>
        </Grid>
      </Grid>
      <Grid container item className={classes.item}>
        <Grid item xs={4}>
          <Text strong variant="body2">
            {t('Dialogs:CANCEL_MEMBERSHIP_DETAILS_DIALOG.CANCELLED_BY')}
          </Text>
        </Grid>
        <Grid item xs>
          <Text variant="body2">
            {Utils.getPersonString(plan?.cancelledBy)}
          </Text>
        </Grid>
      </Grid>
      <PuiTextArea
        disabled
        className={classes.textArea}
        placeholder={t('Common:NOTES')}
        value={plan?.cancellationNotes}
      />
    </PuiDialog>
  )
}

export default CancelMembershipDetailsDialog
