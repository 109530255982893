import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DateUtils, moment, PuiTheme, Text } from '@pbt/pbt-ui-components'

import { QuestionTimelineItemEntry } from '~/types'

import OneQuestion from './OneQuestion'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    subText: {
      color: theme.colors.markerHighlighted,
    },
    prevAnswersTextContainer: {
      borderTop: `1px solid ${theme.colors.divider}`,
    },
    questionContainer: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
  }),
  { name: 'MultipleQuestions' },
)

interface MultipleQuestionsProps {
  enableBusinessShareIcon?: boolean
  questions: QuestionTimelineItemEntry[]
}

const MultipleQuestions = ({
  questions,
  enableBusinessShareIcon,
}: MultipleQuestionsProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Clients')

  return (
    <Grid container direction="column">
      <Grid
        item
        className={classes.prevAnswersTextContainer}
        pb={1}
        pl={2}
        pt={1}
      >
        <Text strong variant="subheading3">
          {t('Clients:TIMELINE.QUESTION_CARD.PREVIOUS_ANSWERS')}
        </Text>
      </Grid>
      <Grid container direction="column">
        {questions.map((entry) => (
          <Grid
            container
            item
            className={classes.questionContainer}
            direction="column"
            key={entry.id}
            p={2}
          >
            <Grid item>
              <Text inline strong variant="subheading3">
                {DateUtils.formatDate(entry.date)}
              </Text>
              <Text inline className={classes.subText} ml={1} variant="body2">
                {moment(entry.date).fromNow()}
              </Text>
            </Grid>
            <OneQuestion
              showAppointmentButton
              enableBusinessShareIcon={enableBusinessShareIcon}
              question={entry}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default MultipleQuestions
