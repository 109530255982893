import React, { ForwardedRef, forwardRef } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, TextInteractive } from '@pbt/pbt-ui-components'

import OrderListItem, {
  OrderListItemProps,
} from '~/components/dashboard/soap/order/OrderListItem'
import { Order } from '~/types'

// @ts-ignore
import { addAnyPrefix } from './reminderProtocolUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    bundleNameText: {
      cursor: 'pointer',
    },
    caret: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    collapse: {
      width: '100%',
      maxWidth: `calc(100% - ${theme.spacing(3)})`,
      paddingLeft: theme.spacing(3),
    },
  }),
  { name: 'ProtocolListItem' },
)

export interface ProtocolListItemProps extends OrderListItemProps {
  isSelected: boolean
  onUncheckItem: () => void
  selectedSubItem: Order
  updateSelectedItem: (order: Order) => void
  updateSelectedSubItem: () => void
}

const ProtocolListItem = forwardRef(function ProtocolListItem(
  {
    searchTerm,
    order: protocol,
    isSelected,
    selectedSubItem,
    isCheckedItem,
    onCheckItem,
    onUncheckItem,
    updateSelectedItem,
    updateSelectedSubItem,
    ListItemProps,
    ...rest
  }: ProtocolListItemProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const classes = useStyles()

  const hasCheckedItems = protocol.items?.some(isCheckedItem)
  const anyOption = {
    id: protocol.id,
    type: protocol.type,
    name: addAnyPrefix(protocol.name),
  } as Order
  const everyOption = {
    ...anyOption,
    name: protocol.name,
  }
  const shouldShowAnyOption = protocol.items && protocol.items.length > 0
  const items = shouldShowAnyOption
    ? [anyOption].concat(protocol.items || [])
    : protocol.items

  const expand = () => {
    updateSelectedItem(protocol)
  }

  const mergeProtocolNameWithItemName = ({ name, ...orderFields }: Order) => ({
    name: `${protocol.name} | ${name}`,
    ...orderFields,
  })

  const handleCheckItem = (item: Order) => {
    if (item.id === protocol.id) {
      onCheckItem(item)
    } else {
      onCheckItem(mergeProtocolNameWithItemName(item))
    }
  }

  return (
    <Grid
      container
      item
      className={classes.root}
      direction="column"
      p={0.5}
      ref={ref}
    >
      <Grid
        container
        item
        alignItems="center"
        className={classes.bundleNameText}
        wrap="nowrap"
        onClick={expand}
      >
        {shouldShowAnyOption ? (
          <>
            {isSelected ? (
              <ExpandLess className={classes.caret} />
            ) : (
              <ExpandMore className={classes.caret} />
            )}
            <TextInteractive
              highlight={searchTerm}
              strong={hasCheckedItems}
              variant={hasCheckedItems ? 'h5' : 'body2'}
            >
              {protocol.name}
            </TextInteractive>
          </>
        ) : (
          <OrderListItem
            {...rest}
            ListItemProps={ListItemProps}
            isCheckedItem={isCheckedItem}
            order={everyOption}
            searchTerm={searchTerm}
            onCheckItem={onCheckItem}
            onUncheckItem={onUncheckItem}
          />
        )}
      </Grid>
      <Collapse
        mountOnEnter
        unmountOnExit
        className={classes.collapse}
        in={isSelected}
      >
        {items?.map((item, index) => (
          <OrderListItem
            {...rest}
            ListItemProps={ListItemProps}
            isCheckedItem={isCheckedItem}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            order={item}
            searchTerm={searchTerm}
            onCheckItem={handleCheckItem}
            onUncheckItem={onUncheckItem}
          />
        ))}
      </Collapse>
    </Grid>
  )
})

export default ProtocolListItem
