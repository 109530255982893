import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  DateUtils,
  moment,
  PrimitiveTableUtils,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import {
  LandingType,
  LandingWidgetName,
  WidgetWidthType,
} from '~/constants/landingConstants'
import { getWidgetData, getWidgetMeta } from '~/store/duck/landing'
import { getMultipleTimeTrackerEntities } from '~/store/duck/timeTracker'
import { TimeEntity, WidgetColumn } from '~/types'
import { getHoursBetween } from '~/utils/time'

const { getWidthInPercents } = PrimitiveTableUtils

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    scheduledInfoContainer: {
      display: 'flex',
      alignSelf: 'stretch',
      alignItems: 'center',
      borderRight: theme.constants.tableBorder,
    },
    scheduledInfo: {
      color: theme.colors.tabLabel,
      paddingRight: theme.spacing(1),
      fontSize: '1.4rem',
    },
    scheduledHeaderOnFullWidth: {
      width: '80%',
    },
    headingCell: {
      paddingLeft: theme.spacing(0.5),
    },
    headingCellText: {
      color: theme.colors.tabLabel,
      fontSize: '1.4rem',
    },
  }),
  { name: 'TimeTrackingHeader' },
)

interface TimeTrackingHeaderProps {
  className?: string
  columns: WidgetColumn[]
  isSettingsMode?: boolean
  name: string
  widthType?: WidgetWidthType
}

const TimeTrackingHeader = ({
  className,
  name,
  isSettingsMode,
  widthType,
  columns,
}: TimeTrackingHeaderProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Landing'])

  const { clockinSchedule } =
    useSelector(
      getWidgetMeta(
        LandingType.LANDING_DASHBOARD,
        LandingWidgetName.TIME_TRACKING,
      ),
    ) || {}
  const timeTrackingIds = useSelector(
    getWidgetData(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.TIME_TRACKING,
    ),
  )
  const timeTrackingEntities = useSelector(
    getMultipleTimeTrackerEntities(timeTrackingIds),
  )

  const hours = R.pipe(
    R.map((item: TimeEntity) =>
      item?.clockoutDatetime
        ? Number(getHoursBetween(item.clockinDatetime, item.clockoutDatetime))
        : 0,
    ),
    R.sum,
  )(timeTrackingEntities)

  const { from, to } = clockinSchedule || {}
  const formattedFrom = moment(from, 'HH:mm:ss').toISOString()
  const formattedTo = moment(to, 'HH:mm:ss').toISOString()

  const scheduled =
    from && to
      ? `${DateUtils.formatTime(formattedFrom)}—${DateUtils.formatTime(
          formattedTo,
        )}`
      : undefined

  const isFullWidth = widthType === WidgetWidthType.FULL_WIDTH

  return (
    <Grid container className={className} wrap="nowrap">
      <Grid
        container
        alignItems="center"
        className={classNames({
          [classes.scheduledHeaderOnFullWidth]: isFullWidth,
        })}
        justifyContent="space-between"
      >
        <Text ml={1} variant="h4">
          {name}
        </Text>
        {!isSettingsMode && (
          <Grid className={classes.scheduledInfoContainer}>
            <Text className={classes.scheduledInfo}>
              {hours
                ? t('Landing:TIME_TRACKING_HEADER.CURRENT_HOURS_TODAY', {
                    hours: Utils.round(hours),
                  })
                : scheduled
                ? `${t('Common:SCHEDULED')}: ${scheduled}`
                : ''}
            </Text>
          </Grid>
        )}
      </Grid>
      {isFullWidth && !isSettingsMode && (
        <Grid container>
          {columns.map((column) => {
            const width = column.width
              ? getWidthInPercents(column.width)
              : undefined
            return (
              <Grid
                container
                alignItems="center"
                className={classes.headingCell}
                key={column?.label}
                style={{ width: `${width}%` }}
              >
                <Text strong className={classes.headingCellText}>
                  {column.label}
                </Text>
              </Grid>
            )
          })}
        </Grid>
      )}
    </Grid>
  )
}

export default TimeTrackingHeader
