import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { Collapse, Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  AddButton,
  LanguageUtils,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import PatientLink from '~/components/common/link/PatientLink'
import PatientWarningAvatar from '~/components/common/PatientWarningAvatar'
import TaskGroupedStateButtonMesh from '~/components/dashboard/tasks-dashboard/mesh/TaskGroupedStateButtonMesh'
import TaskMesh from '~/components/dashboard/tasks-dashboard/mesh/TaskMesh'
import DialogNames from '~/constants/DialogNames'
import { getEventState } from '~/store/reducers/constants'
import { getSpaceName } from '~/store/reducers/spaces'
import { getMultipleTasks } from '~/store/reducers/tasks'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { WhiteboardTreatmentsSchedule } from '~/types'
import useDialog from '~/utils/useDialog'

import WhiteboardDoneTaskRow from './WhiteboardDoneTaskRow'
import WhiteboardTaskRow from './WhiteboardTaskRow'
import WhiteboardTreatmentsAssignedCell from './WhiteboardTreatmentsAssignedCell'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    row: {
      border: theme.constants.tableBorder,
      borderRadius: 2,
      backgroundColor: theme.colors.tableBackground,
    },
    rowCollapsed: {
      borderBottom: 'none',
    },
    rowExpanded: {
      border: theme.constants.tableBorderSelected,
    },
    mainRow: {
      borderBottom: theme.constants.tableBorder,
    },
    expandButtonContainer: {
      width: 'auto',
      borderRight: theme.constants.tableBorder,
    },
    iconButton: {
      padding: theme.spacing(0.5),
    },
    iconButtonExpanded: {
      color: theme.colors.editIconColor,
    },
    addButtonContainer: {
      borderRight: theme.constants.tableBorder,
    },
    taskRow: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    avatar: {
      width: 32,
      height: 32,
    },
    badge: {
      width: 16,
      minWidth: 16,
      height: 16,
      right: 4,
    },
    icon: {
      fontSize: '1.1rem',
    },
  }),
  { name: 'WhiteboardTreatmentsViewRow' },
)

export interface WhiteboardTreatmentsViewRowProps {
  className?: string
  schedule: WhiteboardTreatmentsSchedule
  scrollOffset?: number
  scrollRefs: [
    React.RefObject<HTMLDivElement>,
    React.RefObject<HTMLDivElement>[],
    React.RefObject<HTMLDivElement>,
    React.RefObject<HTMLDivElement>,
  ]
  timeColumns: string[]
}

const WhiteboardTreatmentsViewRow = ({
  scrollOffset,
  timeColumns,
  scrollRefs,
  className,
  schedule,
}: WhiteboardTreatmentsViewRowProps) => {
  const appointmentId = schedule.appointment

  const classes = useStyles()
  const { t } = useTranslation('Common')
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const spaceName = useSelector(getSpaceName(appointment?.assignedSpace))
  const tasks = useSelector(getMultipleTasks(schedule.tasks))
  const EventState = useSelector(getEventState)

  const DoneState = Utils.findConstantIdByName('Done', EventState)

  const [openTaskDialog] = useDialog(DialogNames.TASK)
  const [openAppointmentDialog] = useDialog(DialogNames.EVENT)

  const [expanded, setExpanded] = useState(false)

  const [
    mainScrollRef,
    tasksScrollRefs,
    doneTaskHeaderScrollRef,
    addButtonScrollRef,
  ] = scrollRefs

  const appointmentTypeDisplayName = LanguageUtils.getTranslatedFieldName(
    appointment?.businessAppointmentType,
  )

  const patientId = appointment?.patient
  const clientId = appointment?.client

  const onAddTask = () => {
    openTaskDialog({
      clientId,
      patientId,
      appointmentId,
      dueDate: appointment?.scheduledEndDatetime,
    })
  }

  const onAppointmentClick = () => {
    openAppointmentDialog({
      appointmentId,
    })
  }

  const otherTasks = tasks.filter(
    (task) => task.aggregatedStateId !== DoneState,
  )

  const shouldUseResponsibilities =
    appointment?.personResponsibilities &&
    appointment.personResponsibilities.length > 0

  return (
    <Grid
      container
      item
      className={classNames(className, classes.row, {
        [classes.rowCollapsed]: !expanded,
        [classes.rowExpanded]: expanded,
      })}
      direction="column"
      wrap="nowrap"
    >
      <Grid
        container
        item
        alignItems="center"
        className={classes.mainRow}
        wrap="nowrap"
      >
        <Grid container item xs wrap="nowrap">
          <Grid
            container
            item
            alignItems="center"
            className={classes.expandButtonContainer}
          >
            <IconButton
              aria-label={t('Common:EXPAND_ACTION')}
              className={classNames(classes.iconButton, {
                [classes.iconButtonExpanded]: expanded,
              })}
              size="large"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </Grid>
          <Grid
            container
            item
            xs
            alignItems="center"
            p={1.5}
            pr={0}
            wrap="nowrap"
          >
            <Grid item mr={1}>
              <PatientWarningAvatar
                tiny
                classes={{
                  avatar: classes.avatar,
                  badge: classes.badge,
                  icon: classes.icon,
                }}
                patientId={patientId}
              />
            </Grid>
            <PatientLink clientId={clientId} patientId={patientId} />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          {shouldUseResponsibilities
            ? appointment?.personResponsibilities?.map(
                (personResponsibility) => (
                  <WhiteboardTreatmentsAssignedCell
                    key={personResponsibility.responsibilityId}
                    personResponsibility={personResponsibility}
                  />
                ),
              )
            : appointment?.personRoles?.map((personRole) => (
                <WhiteboardTreatmentsAssignedCell
                  key={personRole.roleId}
                  personRole={personRole}
                />
              ))}
        </Grid>
        <Grid item xs={2}>
          <Text link variant="body2" onClick={onAppointmentClick}>
            {appointmentTypeDisplayName}
          </Text>
        </Grid>
        <Grid item xs={1}>
          {spaceName && <Text variant="body2">{spaceName}</Text>}
        </Grid>
        <TaskGroupedStateButtonMesh
          columns={timeColumns}
          ref={mainScrollRef}
          schedule={schedule}
          scrollOffset={scrollOffset}
        />
      </Grid>
      <Collapse mountOnEnter unmountOnExit in={expanded}>
        {otherTasks.map((task, taskIndex) => (
          <WhiteboardTaskRow
            className={classes.taskRow}
            key={task.id}
            scrollOffset={scrollOffset}
            scrollRef={tasksScrollRefs[taskIndex]}
            taskId={task.id}
            timeColumns={timeColumns}
          />
        ))}
        <WhiteboardDoneTaskRow
          headerScrollRef={doneTaskHeaderScrollRef}
          schedule={schedule}
          scrollOffset={scrollOffset}
          tasksScrollRefs={tasksScrollRefs.slice(otherTasks.length)}
          timeColumns={timeColumns}
        />
        <Grid container item className={classes.taskRow}>
          <Grid
            item
            xs
            className={classes.addButtonContainer}
            mr={1}
            pl={5.5}
            pr={1}
            py={1.5}
          >
            <AddButton addText={t('Common:ADD_TASK')} onAdd={onAddTask} />
          </Grid>
          <Grid item xs={5} />
          <TaskMesh
            columns={timeColumns}
            ref={addButtonScrollRef}
            scrollOffset={scrollOffset}
          />
        </Grid>
      </Collapse>
    </Grid>
  )
}

export default WhiteboardTreatmentsViewRow
