import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { Nil, useFields } from '@pbt/pbt-ui-components'

import {
  getDiscountAmount,
  getHighValue,
  getLimitedDiscountAmount,
  getTaxAmount,
  isGroupedInvoiceItem,
  isRange,
} from '~/components/dashboard/invoices/invoiceUtils'
import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  Invoice,
  InvoiceLineItem,
  InvoiceOrEstimate,
} from '~/types/entities/finance'
import { getIsCreatedPrescriptionChewyActiveRx } from '~/utils/prescription'

export const useChargeDetailsFields = (
  item: InvoiceLineItem,
  invoice: InvoiceOrEstimate | Nil,
) => {
  const { t } = useTranslation(['Common', 'Prices', 'Tooltips'])

  const isChewyActiveRx = getIsCreatedPrescriptionChewyActiveRx(
    item.prescriptionType,
    item.origin,
  )
  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )

  const { fields, reset } = useFields(
    [
      {
        name: 'quantity',
        label: item.prepaid ? t('Common:QUANTITY_PAID') : t('Common:QUANTITY'),
        initialValue: isGroupedInvoiceItem(item) ? 1 : item.quantity,
        type: 'number',
      },
      {
        name: 'usedQuantity',
        label: t('Common:QUANTITY_USED'),
        initialValue:
          item.prepaid && Number.isInteger(item.usedQuantity)
            ? item.usedQuantity
            : undefined,
        type: 'number',
      },
      {
        name: 'remainingQuantity',
        label: t('Common:QUANTITY_REMAINING'),
        initialValue:
          item.prepaid && Number.isInteger(item.prepaidRemaining)
            ? item.prepaidRemaining
            : undefined,
        type: 'number',
      },
      {
        name: 'catalogUnitPrice',
        label: isChewyActiveRx
          ? t('Prices:CHEWY_PRICE')
          : t('Prices:CATALOG_UNIT_PRICE'),
        // For now it was decided to be the same value as price
        initialValue: isGroupedInvoiceItem(item)
          ? item.subTotal
          : R.pathOr(item?.price, ['price', 'price'], item),
        type: 'number',
      },
      {
        name: 'sellPrice',
        label: t('Prices:SELL_PRICE'),
        initialValue: isGroupedInvoiceItem(item)
          ? item.subTotal
          : R.pathOr(item?.price, ['price', 'price'], item),
        type: 'number',
      },
      {
        name: 'taxed',
        label: t('Common:TAXABLE'),
        initialValue: String(Number(item.taxed)),
        type: 'select',
      },
      {
        name: 'tax',
        label: t('Common:TAX'),
        initialValue: isChargeSheetEnabled
          ? R.propOr(0, 'taxAmount', item)
          : getTaxAmount(item, invoice as Invoice),
        type: 'number',
      },
      {
        name: 'discountAmount',
        label: t('Common:ITEM_DISCOUNT'),
        initialValue: isChargeSheetEnabled
          ? R.propOr(0, 'discountAmount', item)
          : isRange(getDiscountAmount(item))
          ? ''
          : getHighValue(getLimitedDiscountAmount(item)),
        type: 'number',
      },
      {
        name: 'itemSubtotal',
        label: t('Common:ITEM_SUBTOTAL'),
        initialValue: item.subTotal,
        type: 'number',
      },
    ],
    false,
  )

  useEffect(() => {
    reset()
  }, [item])

  return fields
}
