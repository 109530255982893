import React from 'react'
import { Trans } from 'react-i18next'
import { KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material'
import { Box, TableCell } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import {
  Invoice as GraphqlInvoice,
  RefundInvoice,
} from '~/api/graphql/generated/types'
import { PuiDataTableColumn } from '~/components/common/lists/pui-data-table/puiDataTableType'
import { StaticTableColumn } from '~/components/common/lists/table/StaticTable'
import { TableAmountCell } from '~/components/dashboard/clients/balance/table/common/TableAmountCell'
import {
  getInvoiceDate,
  getInvoiceDueToPayNoFee,
} from '~/components/dashboard/invoices/invoiceUtils'
import i18nPortal from '~/locales/i18n'
import { Invoice } from '~/types'
import { isRefundInvoice } from '~/utils/refundUtils'

import { TableDateCell } from '../common/TableDateCell'
import { InvoiceTableDoctorCell } from './InvoiceTableDoctorCell'
import { InvoiceTableEventTypeCell } from './InvoiceTableEventTypeCell'
import { InvoiceTableInvoiceNoCell } from './InvoiceTableInvoiceNoCell'
import { InvoiceTablePatientNameCell } from './InvoiceTablePatientNameCell'
import { InvoiceTableStatusCell } from './InvoiceTableStatusCell'

type RowItem = Invoice | GraphqlInvoice | RefundInvoice

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    alignRight: {
      textAlign: 'right',
      width: '100%',
    },
    alignWithMultipleLines: {
      display: 'flex',
      height: '40px',
      alignSelf: 'baseline',
      alignItems: 'center',
    },
    checkbox: {
      padding: theme.spacing(1),
      marginLeft: theme.spacing(-0.75),
      marginRight: theme.spacing(-1),
    },
    titleCell: {
      color: theme.colors.lowAccentText,
    },
  }),
  { name: 'UseGetInvoiceBillingActivityTableColumns' },
)

const FallbackComponent = ({ item }: { item: RowItem }) => {
  if ('sections' in item) {
    const totalEvents = item.sections.length
    return (
      <TableCell colSpan={5} sx={{ py: 1 }}>
        <Box
          alignItems="center"
          display="flex"
          sx={{ cursor: 'pointer' }}
          width="fit-content"
          // @ts-ignore
          onClick={item.onExpand}
        >
          <KeyboardArrowRightIcon />
          <Text fontSize="1.4rem" variant="h3">
            <Trans
              components={{
                span: <Text component="span" variant="body2" />,
              }}
              i18nKey="Invoices:MORE_CHARGE_EVENTS"
              values={{ totalEvents }}
            />
          </Text>
        </Box>
      </TableCell>
    )
  }

  return null
}

const getHasMultipleLines = (invoice: RowItem) =>
  'sections' in invoice && invoice.sections.length > 1

export const useGetInvoiceBillingActivityTableColumns = ({
  key,
  multipleRowsLimit,
  checkbox,
  dueToPayPrimary,
}: {
  checkbox?: {
    getIsChecked: (id: string) => boolean
    hasError: boolean
    onCheck: (id: string) => void
  }
  dueToPayPrimary?: boolean
  key: 'prop' | 'value'
  multipleRowsLimit?: number
}): (PuiDataTableColumn | StaticTableColumn<RowItem>)[] => {
  const classes = useStyles()

  const displayExpandButton = (invoice: RowItem) =>
    Boolean(multipleRowsLimit) &&
    'sections' in invoice &&
    invoice.id.startsWith('skip')

  return [
    {
      id: 'date',
      label: i18nPortal.t<string>('Common:DATE_TIME'),
      [key]: (invoice: RowItem) => (
        <TableDateCell
          classes={{
            container: getHasMultipleLines(invoice)
              ? classes.alignWithMultipleLines
              : undefined,
            checkbox: classes.checkbox,
          }}
          date={
            isRefundInvoice(invoice.invoiceNo)
              ? (invoice as RefundInvoice).creationDate
              : getInvoiceDate(invoice as Invoice | GraphqlInvoice)
          }
          hasError={checkbox?.hasError}
          id={invoice.id}
          isChecked={checkbox?.getIsChecked(invoice.id)}
          onCheck={checkbox?.onCheck ?? undefined}
        />
      ),
      getTableCellProps: (invoice) =>
        Boolean(multipleRowsLimit) &&
        'sections' in invoice &&
        invoice.sections.length > multipleRowsLimit!
          ? {
              rowSpan: multipleRowsLimit,
              sx: { verticalAlign: 'baseline' },
            }
          : {},
      skip: displayExpandButton,
    },
    {
      id: 'number',
      [key]: (invoice: RowItem) => (
        <InvoiceTableInvoiceNoCell
          classes={{
            container: getHasMultipleLines(invoice)
              ? classes.alignWithMultipleLines
              : undefined,
          }}
          invoice={invoice}
        />
      ),
      label: i18nPortal.t<string>('Common:INVOICE'),
      getTableCellProps: (invoice) =>
        Boolean(multipleRowsLimit) &&
        'sections' in invoice &&
        invoice.sections.length > multipleRowsLimit!
          ? {
              rowSpan: multipleRowsLimit,
              sx: { verticalAlign: 'baseline' },
            }
          : {},
      skip: displayExpandButton,
    },
    {
      id: 'doctor',
      [key]: (invoice: RowItem) => (
        <InvoiceTableDoctorCell
          invoice={invoice}
          multipleRowsLimit={multipleRowsLimit}
        />
      ),
      label: i18nPortal.t<string>('Common:DOCTOR'),
      FallbackComponent,
      skip: displayExpandButton,
    },
    {
      id: 'patientName',
      [key]: (invoice: RowItem) => (
        <InvoiceTablePatientNameCell
          invoice={invoice}
          multipleRowsLimit={multipleRowsLimit}
        />
      ),
      label: i18nPortal.t<string>('Common:PATIENT'),
      skip: displayExpandButton,
    },
    {
      id: 'eventType',
      [key]: (invoice: RowItem) => (
        <InvoiceTableEventTypeCell
          invoice={invoice}
          multipleRowsLimit={multipleRowsLimit}
        />
      ),
      label: i18nPortal.t<string>('Common:APPOINTMENT_ONE'),
      skip: displayExpandButton,
    },
    {
      id: 'total',
      [key]: (invoice: RowItem) => {
        const isRefund = isRefundInvoice(invoice.invoiceNo) ?? false
        const dueToPayNoFee = getInvoiceDueToPayNoFee(
          invoice as Invoice | GraphqlInvoice,
        )

        const getTotalValue = () => {
          if (isRefund) {
            return (invoice as RefundInvoice).amount
          }
          return dueToPayPrimary ? dueToPayNoFee : invoice.amountNoFee
        }

        return (
          <TableAmountCell
            amount={getTotalValue()}
            classes={{
              container: classNames(
                classes.alignRight,
                getHasMultipleLines(invoice)
                  ? classes.alignWithMultipleLines
                  : undefined,
              ),
            }}
          />
        )
      },
      label: (
        <Text
          strong
          className={classNames(classes.alignRight, classes.titleCell)}
          fontSize="1.4rem"
        >
          {dueToPayPrimary
            ? i18nPortal.t<string>('Common:TOTAL_DUE_TODAY')
            : i18nPortal.t<string>('Common:TOTAL')}
        </Text>
      ),
      getTableCellProps: (invoice) =>
        getHasMultipleLines(invoice)
          ? { sx: { verticalAlign: 'baseline' } }
          : {},
      skip: displayExpandButton,
    },
    {
      id: 'status',
      [key]: (invoice: RowItem) => (
        <InvoiceTableStatusCell
          classes={{
            container: getHasMultipleLines(invoice)
              ? classes.alignWithMultipleLines
              : undefined,
          }}
          invoice={invoice}
        />
      ),
      label: i18nPortal.t<string>('Common:STATUS'),
      getTableCellProps: (invoice) =>
        getHasMultipleLines(invoice)
          ? { sx: { verticalAlign: 'baseline' } }
          : {},
      skip: displayExpandButton,
    },
  ]
}
