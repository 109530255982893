import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, InputAdornment } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  ClassesType,
  CurrencyTextField,
  CustomFieldValidatorState,
  LanguageUtils,
  NamedEntity,
  NumberUtils,
  PuiDialog,
  PuiTextField,
  PuiTheme,
  PuiTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { Info as InfoIcon } from '@pbt/pbt-ui-components/src/icons'

import { createLabTestPrice, editLabTestPrice } from '~/store/actions/labTests'
import { getPriceUnits, getSpecies } from '~/store/reducers/constants'
import {
  getLabTestsIsCreating,
  getLabTestsIsEditing,
} from '~/store/reducers/labTests'
import { LabTest, Price } from '~/types'
import { isFieldValuesChanged } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import PropSpeciesSelect from '../../inputs/PropSpeciesSelect'
import TaxRateSelect from '../../inputs/TaxRateSelect'
import PuiSwitch from '../../PuiSwitch'

interface UseStylesProps {
  classes?: ClassesType<typeof useStyles>
}

interface LabTestPriceDialogProps extends BasePuiDialogProps {
  labTest: LabTest
  price?: Price
}

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    inputLabel: {},
    dialogContentRoot: {
      padding: theme.spacing(2, 3),
      color: theme.colors.secondaryText,
    },
    actions: {
      padding: theme.spacing(1, 2),
    },
    button: {
      width: 144,
    },
    infoIcon: {
      color: theme.colors.link,
      cursor: 'pointer',
      marginTop: theme.spacing(3.5),
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'LabTestPriceDialog' },
)

const findMatchingPrices = (priceValue: number) => {
  const regexpConfig = `\\$${priceValue}( \\(\\d+\\))?`
  const regexp = new RegExp(regexpConfig)
  const test: (candidate: any) => boolean = (candidate) =>
    regexp.test(candidate)
  const matcher = R.pipe(R.prop('name'), test)
  return R.filter(matcher)
}

const ensurePriceNameExists = (labTestPrice: Price, allPrices: Price[]) => {
  if (labTestPrice?.name) {
    return labTestPrice
  }

  const price = labTestPrice?.price
  const matchingPrices = findMatchingPrices(price)(allPrices) || []
  const nextPriceIndex = matchingPrices.length
  const name = nextPriceIndex
    ? `${NumberUtils.getCurrencySymbol()}${price} (${nextPriceIndex})`
    : `${NumberUtils.getCurrencySymbol()}${price}`

  return {
    ...labTestPrice,
    name,
  }
}

const LabTestPriceDialog = ({
  open,
  onClose,
  labTest,
  price,
}: LabTestPriceDialogProps) => {
  const useStylesProps: UseStylesProps = {}
  const classes = useStyles(useStylesProps)
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Tooltips'])
  const isCreating: boolean = useSelector(getLabTestsIsCreating)
  const isEditing: boolean = useSelector(getLabTestsIsEditing)
  const PriceUnits: NamedEntity[] = useSelector(getPriceUnits)
  const Species = useSelector(getSpecies)

  const availableSpecies = labTest.speciesIds?.length
    ? Species.filter((item) => labTest.speciesIds?.includes(item.id))
    : Species

  const setCloseOnCreated: () => void = useCloseAfterCreation(
    onClose,
    getLabTestsIsCreating,
  )
  const setCloseOnEdited: () => void = useCloseAfterCreation(
    onClose,
    getLabTestsIsEditing,
  )

  const labTestPriceUnitId: string = Utils.findConstantIdByName(
    'Each',
    PriceUnits,
  )

  const isCreate: boolean = !price?.id
  const dialogTitle: string = [
    isCreate ? t('Common:ADD_PRICE') : t('Common:EDIT_PRICE'),
    LanguageUtils.getTranslatedFieldName(labTest || {}),
  ]
    .filter(Boolean)
    .join(' | ')

  const taxesValidator = ({ state, value }: CustomFieldValidatorState) =>
    !state.taxable || value.length > 0

  const { fields, validate } = useFields(
    [
      {
        name: 'priceName',
        label: t('Common:PRICE_NAME'),
        initialValue: price?.name,
      },
      {
        name: 'priceValue',
        label: t('Common:PRICE'),
        initialValue: price?.price,
        validators: ['required'],
      },
      {
        name: 'cost',
        label: t('Common:COST'),
        initialValue: price?.cost,
      },
      {
        name: 'species',
        label: t('Common:SPECIES'),
        type: 'select',
        initialValue: price?.speciesId,
      },
      {
        name: 'allowDiscount',
        label: t('Common:ALLOW_DISCOUNT'),
        initialValue: price?.discountAllowed ?? true,
        type: 'toggle',
      },
      {
        name: 'taxable',
        label: t('Common:TAXABLE'),
        initialValue: price?.taxable,
        type: 'toggle',
      },
      {
        name: 'taxes',
        label: t('Common:SELECT_TAX_RATE'),
        type: 'select',
        validators: [
          { validator: taxesValidator, validatorName: 'taxesValidator' },
        ],
        messages: {
          taxesValidator: t('Validations:PLEASE_SELECT_TAX_RATE'),
        },
        initialValue: price?.taxes ?? [],
      },
      {
        name: 'active',
        label: t('Common:ACTIVE_ONE'),
        initialValue: price?.active ?? true,
        type: 'toggle',
      },
      {
        name: 'unitId',
        label: t('Common:UNIT'),
        initialValue: price?.unitId ?? labTestPriceUnitId,
        type: 'toggle',
      },
      {
        name: 'originalCode',
        label: t('Common:CODE'),
        initialValue: price?.originalCode || '',
      },
    ],
    false,
  )

  const {
    priceName,
    priceValue,
    cost,
    species,
    allowDiscount,
    taxable,
    taxes,
    active,
    unitId,
    originalCode,
  } = fields

  const handleSave = () => {
    if (!validate()) {
      return
    }

    const updatedPrice = {
      ...(price || {}),
      name: priceName.value,
      price: priceValue.value,
      cost: cost.value,
      speciesId: species.value,
      discountAllowed: allowDiscount.value,
      taxable: taxable.value,
      active: active.value,
      unitId: unitId.value,
      taxes: taxes.value,
      originalCode: originalCode.value,
    }

    const normalizedPrice: Price = ensurePriceNameExists(
      updatedPrice as Price,
      labTest.prices!,
    )

    if (price?.id) {
      setCloseOnEdited()
      dispatch(editLabTestPrice(labTest?.id, normalizedPrice))
    } else {
      setCloseOnCreated()
      dispatch(createLabTestPrice(labTest?.id, normalizedPrice))
    }
  }

  return (
    <PuiDialog
      confirmSaveOnClose
      fullWidth
      ConfirmCloseDialogProps={{
        onOk: handleSave,
      }}
      actions={
        <ButtonWithLoader
          className={classes.button}
          loading={isCreating || isEditing}
          onClick={handleSave}
        >
          {isCreate ? t('Common:ADD_ACTION') : t('Common:SAVE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="lab-test-price-dialog"
      classes={{
        dialogContentRoot: classes.dialogContentRoot,
        actions: classes.actions,
      }}
      hasUnsavedChanges={() => isFieldValuesChanged(fields)}
      maxWidth="sm"
      open={open}
      title={dialogTitle}
      onClose={onClose}
    >
      <Grid container direction="column" spacing={1}>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <PuiTextField
              InputLabelProps={{
                className: classes.inputLabel,
                shrink: true,
              }}
              field={priceName}
              inputProps={{ maxLength: 100 }}
              label={priceName.label}
              margin="none"
            />
          </Grid>
          <Grid item xs={6}>
            <PropSpeciesSelect field={species} species={availableSpecies} />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <CurrencyTextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    / {t('Common:EACH')}
                  </InputAdornment>
                ),
              }}
              field={priceValue}
              label={`${priceValue.label}*`}
            />
          </Grid>
          <Grid item xs={6}>
            <CurrencyTextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    / {t('Common:EACH')}
                  </InputAdornment>
                ),
              }}
              field={cost}
              label={cost.label}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid container item md={3} wrap="nowrap" xs={6}>
            <PuiTextField
              InputLabelProps={{
                className: classes.inputLabel,
                shrink: true,
              }}
              field={originalCode}
              inputProps={{ maxLength: 15 }}
              label={originalCode.label}
              margin="none"
            />
            <PuiTooltip
              tooltipText={t(
                'Tooltips:ALPHANUMERIC_IDENTIFIER_TO_SEARCH_FOR_ITEMS',
              )}
            >
              <InfoIcon className={classes.infoIcon} />
            </PuiTooltip>
          </Grid>
        </Grid>

        <Grid container item direction="column" rowSpacing={1}>
          <Grid item>
            <PuiSwitch field={allowDiscount} label={allowDiscount.label} />
          </Grid>
          <Grid
            container
            item
            alignItems="flex-end"
            columnSpacing={1}
            mb={1}
            minHeight={60}
            wrap="nowrap"
          >
            <Grid item>
              <PuiSwitch field={taxable} label={taxable.label} />
            </Grid>
            {taxable.value && (
              <Grid item width={300}>
                <TaxRateSelect field={taxes} taxableField={taxable} />
              </Grid>
            )}
          </Grid>
          <Grid item>
            <PuiSwitch field={active} label={active.label} />
          </Grid>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default LabTestPriceDialog
