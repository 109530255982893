import React from 'react'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { PuiTheme } from '@pbt/pbt-ui-components'

import PuiTabsPanel from '~/components/common/PuiTabsPanel'
import {
  ChargeTab,
  CRUDOrderOptions,
  Order,
  OrderCallbackWithTypeCheck,
} from '~/types'

import { useChargeTabParams } from '../utils/useChargeTabParams'
import { ChargesPanelTabs } from './chargesTabs'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tabContainer: {
      minHeight: 33,
      fontSize: '1.4rem',
      fontWeight: 500,
      color: theme.colors.secondaryText,
    },
    tab: {
      padding: `${theme.spacing(0, 2)}`,
    },
  }),
  { name: 'ChargesTabsView' },
)

export interface ChargesTabsViewProps {
  editDisabled: boolean
  handleEditListItem: OrderCallbackWithTypeCheck
  handleRefill: (prescription: Order) => void
  handleSavePrescription: (
    prescription: Order,
    options: CRUDOrderOptions,
    closeAfterSaving: boolean,
  ) => void
  logItemStateGetter: (order: Order, state?: string | undefined) => Order | null
  openPrescriptionDialog: (openParams: any) => void
  panels: ChargesPanelTabs
  tabs: ChargeTab[]
}

const ChargesTabsView = ({
  editDisabled,
  handleEditListItem,
  handleRefill,
  handleSavePrescription,
  logItemStateGetter,
  openPrescriptionDialog,
  panels,
  tabs,
}: ChargesTabsViewProps) => {
  const classes = useStyles()

  const { onTabChange, tabValue } = useChargeTabParams(tabs)

  return (
    <PuiTabsPanel
      classes={{
        tabContainer: classes.tabContainer,
        tabRoot: classNames(classes.tabContainer, classes.tab),
      }}
      panels={panels}
      tabValue={tabValue}
      tabs={tabs}
      onChange={onTabChange}
    >
      {({ tabPanel }) => {
        const { Component, props } = tabPanel

        return (
          <Component
            disabled={editDisabled}
            handleEditListItem={handleEditListItem}
            handleRefill={handleRefill}
            handleSavePrescription={handleSavePrescription}
            logItemStateGetter={logItemStateGetter}
            openPrescriptionDialog={openPrescriptionDialog}
            {...(props as any)}
          />
        )
      }}
    </PuiTabsPanel>
  )
}

export default ChargesTabsView
