import React from 'react'
import { useSelector } from 'react-redux'
import { Checkbox, Grid, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, PuiTheme, TextInteractive } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'

import ObsoleteListItem from './ObsoleteListItem'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    checkbox: {
      padding: 0,
      '&&&&:hover': {
        backgroundColor: 'transparent',
      },
      color: theme.colors.secondaryText,
      margin: theme.spacing(0, 1),
    },
    highlightText: {
      fontWeight: '500',
      backgroundColor: 'transparent',
    },
    icons: {
      marginLeft: theme.spacing(0.5),
      width: 'auto',
    },
  }),
  { name: 'ListItem' },
)

export interface ListItemProps {
  className?: string
  classes?: ClassesType<typeof useStyles>
  disabled?: boolean
  icons?: React.ReactNode
  isLoading?: boolean
  isSelected?: boolean
  name: string
  obsolete?: boolean
  onClick: () => void
  rawSearchTerm?: string
  wrapDescription?: boolean
}

const ListItem = ({
  classes: classesProp,
  isSelected,
  name,
  obsolete = false,
  rawSearchTerm,
  disabled = false,
  isLoading,
  onClick,
  className,
  icons,
  wrapDescription = false,
}: ListItemProps) => {
  const classes = useStyles({ classes: classesProp })

  const isObsoleteEnabled = useSelector(
    getFeatureToggle(FeatureToggle.OBSOLETE_GLOBAL_INVENTORY_ITEM_VARIATION),
  )

  const loadingComponent = (
    <>
      <Skeleton className={classes.checkbox} height={24} width={24} />
      <Skeleton height={14} width="50%" />
    </>
  )

  const displayComponent =
    isObsoleteEnabled && obsolete ? (
      <ObsoleteListItem name={name} />
    ) : (
      <>
        <Checkbox
          checked={isSelected}
          className={classes.checkbox}
          disabled={disabled}
        />
        <TextInteractive
          HighlightProps={{
            classes: {
              highlightOnPage: classes.highlightText,
            },
          }}
          highlight={rawSearchTerm}
          noWrap={!wrapDescription}
          variant="body2"
        >
          {name}
        </TextInteractive>
        {icons && (
          <Grid
            container
            alignItems="center"
            className={classes.icons}
            wrap="nowrap"
          >
            {icons}
          </Grid>
        )}
      </>
    )

  return (
    <Grid
      container
      item
      alignItems="center"
      className={className}
      wrap="nowrap"
      onClick={onClick}
    >
      {isLoading ? loadingComponent : displayComponent}
    </Grid>
  )
}

export default ListItem
