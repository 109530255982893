import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Fab } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme, Utils } from '@pbt/pbt-ui-components'

import { useDeclineOrderPrescriptionItem } from '~/components/dashboard/soapV2/charges/utils/useDeclineOrderItem'
import FeatureToggle from '~/constants/featureToggle'
import {
  InventoryItemState,
  PrescriptionItemState,
} from '~/constants/SOAPStates'
import { useIsDeclinableOrDeletable } from '~/store/hooks/prescription'
import {
  getFeatureToggle,
  getInventoryStatuses,
  getPrescriptionStates,
} from '~/store/reducers/constants'
import { Order } from '~/types'
import { getPrescriptionType } from '~/utils/prescription'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    fab: {
      height: 33,
      minWidth: 75,
      fontSize: 12,
      fontWeight: 500,
      padding: theme.spacing(0, 1),
    },
    fabSelected: {
      '&&&&:hover': {
        backgroundColor: theme.colors.selectedOption,
      },
      backgroundColor: theme.colors.selectedOption,
      color: theme.colors.primaryText,
    },
  }),
  { name: 'PrescriptionItemDeclineButton' },
)

export interface PrescriptionItemDeclineButtonProps {
  disabled?: boolean
  order: Order
}

const PrescriptionItemDeclineButton = ({
  order,
  disabled,
}: PrescriptionItemDeclineButtonProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const InventoryStatuses = useSelector(getInventoryStatuses)
  const PrescriptionStates = useSelector(getPrescriptionStates)
  const isChewyActiveRxEnabled = useSelector(
    getFeatureToggle(FeatureToggle.ACTIVE_RX),
  )

  const isDeclinableOrDeletable = useIsDeclinableOrDeletable({
    orderType: order.type,
    stateId: order.stateId,
    prescriptionType: order.prescriptionType,
    origin: order.origin,
  })

  const declinePrescription = useDeclineOrderPrescriptionItem({
    id: order.id,
    inventoryLogStatusId: order.inventoryLogStatusId,
    prescriptionType: order.prescriptionType,
    stateId: order.stateId,
    origin: order.origin,
  })

  if (!isDeclinableOrDeletable) {
    return null
  }

  const { isInHouse, isChewyActiveRx } = getPrescriptionType(
    order.prescriptionType,
    order.origin,
  )

  const DeclinedInventoryState = Utils.findConstantIdByName(
    InventoryItemState.DECLINED,
    InventoryStatuses,
  )

  const DeclinedPrescriptionState = Utils.findConstantIdByName(
    PrescriptionItemState.DECLINED,
    PrescriptionStates,
  )

  const declinedLabel =
    isChewyActiveRxEnabled && isChewyActiveRx
      ? t('Common:CLIENT_DECLINED')
      : t('Common:DECLINED')
  const declineLabel =
    isChewyActiveRxEnabled && isChewyActiveRx
      ? t('Common:CLIENT_DECLINE')
      : t('Common:DECLINE_ACTION')

  const isDeclined = isInHouse
    ? order.inventoryLogStatusId === DeclinedInventoryState
    : order.stateId === DeclinedPrescriptionState

  const label = isDeclined ? declinedLabel : declineLabel

  return (
    <Fab
      className={classNames(classes.fab, {
        [classes.fabSelected]: isDeclined,
      })}
      color="primary"
      disabled={disabled}
      type="button"
      variant="extended"
      onClick={declinePrescription}
    >
      {label}
    </Fab>
  )
}

export default PrescriptionItemDeclineButton
