import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, PuiTheme } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      padding: theme.spacing(0, 3),
      backgroundColor: theme.colors.soapStatusBar,
    },
  }),
  { name: 'TopMenu' },
)

export interface TopMenuProps {
  children: React.ReactNode
  classes?: ClassesType<typeof useStyles>
}

const TopMenu = ({ children, classes: classesProp }: TopMenuProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Grid container item alignItems="center" className={classes.root}>
      {children}
    </Grid>
  )
}

export default TopMenu
