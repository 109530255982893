import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Link, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DateUtils, PuiTheme, Text, Utils } from '@pbt/pbt-ui-components'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import DialogNames, { ConfirmAlertType } from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { useCloseDialogsCallback } from '~/store/hooks/dialogs'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Invoice, InvoiceLineItemGroup } from '~/types'
import { addOriginalBusinessId } from '~/utils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    link: {
      cursor: 'pointer',
    },
    groupHeaderCell: {
      padding: theme.spacing(1),
      borderBottom: 'none',
    },
  }),
  { name: 'InvoiceGroupTitleRow' },
)

export interface InvoiceGroupTitleRowProps {
  group: InvoiceLineItemGroup
  invoice: Invoice
}

const InvoiceGroupTitleRow = ({
  invoice,
  group,
}: InvoiceGroupTitleRowProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation(['Abbreviations', 'Common'])

  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const closeDialogs = useCloseDialogsCallback(DialogNames.INVOICE)
  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.INVOICE_CLOSE,
  })

  const handleLinkClick = () => {
    openConfirmAlert({
      preventShowAgainCheckBox: false,
      applyCustomMessage: true,
      message: t('Dialogs:CONFIRM_ALERT_DIALOG.INVOICE_CLOSE.MESSAGE'),
      onConfirm: (proceed: any) => {
        if (proceed) {
          closeDialogs()
          navigate(
            addOriginalBusinessId(
              `/soap/${group.soap?.id}`,
              isPatientSharingEnabled ? group.soap?.businessId : null,
            ),
          )
        }
      },
      okButtonText: t('Common:YES_CONTINUE'),
      cancelButtonText: t('Common:NO_GO_BACK'),
    })
  }

  return (
    <TableRow>
      <TableCell className={classes.groupHeaderCell} colSpan={100}>
        <Text strong variant="h5">
          {group.soap ? (
            <Link className={classes.link} onClick={handleLinkClick}>
              {t('Common:APPOINTMENT_ONE')}&nbsp;
              {group.soap.assignedVet
                ? `${t('Common:WITH').toLowerCase()} ${Utils.getPersonString(
                    group.soap.assignedVet,
                  )} `
                : ''}
              {invoice.event?.scheduledStartDatetime
                ? `| ${DateUtils.formatDate(
                    invoice.event?.scheduledStartDatetime,
                  )}`
                : ''}
            </Link>
          ) : (
            t('Abbreviations:ACRONYMS.OVER_THE_COUNTER.OTC_SALE')
          )}
        </Text>
      </TableCell>
    </TableRow>
  )
}

export default InvoiceGroupTitleRow
