import i18n from '~/locales/i18n'

export interface AutoshipFrequencyOption {
  frequency: number
  unit: 'Day' | 'Week' | 'Month' | 'Annual'
}

export const defaulAutoshipFrequency: AutoshipFrequencyOption = {
  unit: 'Week',
  frequency: 12,
}

/*
 * @deprecated Please use AUTOSHIP_FREQUENCY_OPTIONS_MAP instead.
 */
export const AUTOSHIP_FREQUENCY_OPTIONS: AutoshipFrequencyOption[] = [
  { unit: 'Week', frequency: 1 },
  { unit: 'Week', frequency: 2 },
  { unit: 'Week', frequency: 3 },
  { unit: 'Week', frequency: 4 },
  { unit: 'Week', frequency: 5 },
  { unit: 'Week', frequency: 6 },
  { unit: 'Week', frequency: 7 },
  { unit: 'Week', frequency: 8 },
  { unit: 'Week', frequency: 9 },
  { unit: 'Week', frequency: 10 },
  { unit: 'Week', frequency: 11 },
  { unit: 'Week', frequency: 12 },
  { unit: 'Week', frequency: 13 },
  { unit: 'Week', frequency: 14 },
  { unit: 'Week', frequency: 15 },
  { unit: 'Week', frequency: 16 },
  { unit: 'Month', frequency: 5 },
  { unit: 'Month', frequency: 6 },
  { unit: 'Month', frequency: 7 },
  { unit: 'Month', frequency: 8 },
]

export const AUTOSHIP_FREQUENCY_OPTIONS_MAP = AUTOSHIP_FREQUENCY_OPTIONS.map(
  (option) => {
    let frequencyLabel
    if (option.frequency === 1) {
      if (option.unit === 'Week') {
        frequencyLabel = i18n.t(`Constants:TIME_UNITS.WEEK_ONE`)
      } else if (option.unit === 'Month') {
        frequencyLabel = i18n.t(`Constants:TIME_UNITS.MONTH_ONE`)
      }
    } else if (option.unit === 'Week') {
      frequencyLabel = i18n.t(`Constants:TIME_UNITS.WEEK_OTHER`)
    } else if (option.unit === 'Month') {
      frequencyLabel = i18n.t(`Constants:TIME_UNITS.MONTH_OTHER`)
    }

    return {
      id: `${option.unit}-${option.frequency}`,
      name: `${i18n.t('Common:EVERY')} ${option.frequency} ${frequencyLabel}`,
      option,
    }
  },
)
