import React from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'
import { WarnAlert } from '@pbt/pbt-ui-components/src/icons'

import SuccessSvg from '~/components/common/auth/success.svg'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    submit: {
      marginTop: theme.spacing(1),
      width: '100%',
    },
    icon: {
      width: 39,
      height: 44,
      marginTop: parseInt(theme.spacing(2), 10) - 3,
    },
  }),
  { name: 'ProfileEmailPasswordLoading' },
)

export enum EmailResetStatusType {
  FAILED,
  SENDING,
  SUCCESS,
}

export interface ProfileEmailPasswordResetProps {
  emailStatus: EmailResetStatusType
}

const ProfileEmailPasswordReset = ({
  emailStatus,
}: ProfileEmailPasswordResetProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Auth'])

  const emailStatusMap = {
    [EmailResetStatusType.FAILED]: {
      headerText: t('Auth:EMAIL_PASSWORD.FAILED'),
      infoText: t('Auth:EMAIL_PASSWORD.FAILED_INFO'),
      icon: <WarnAlert className={classes.icon} />,
    },
    [EmailResetStatusType.SENDING]: {
      headerText: t('Auth:EMAIL_PASSWORD.SENDING'),
      infoText: t('Auth:EMAIL_PASSWORD.SENDING_INFO'),
      icon: (
        <CircularProgress
          className={classes.icon}
          color="secondary"
          size={32}
        />
      ),
    },
    [EmailResetStatusType.SUCCESS]: {
      headerText: t('Auth:EMAIL_PASSWORD.SUCCESS'),
      infoText: t('Auth:EMAIL_PASSWORD_SUCCESS.INSTRUCTIONS'),
      icon: (
        <img
          alt={t('Common:SUCCESS')}
          className={classes.icon}
          src={SuccessSvg}
        />
      ),
    },
  }

  const { headerText, infoText, icon } = emailStatusMap[emailStatus]

  return (
    <Grid container className={classes.root} direction="column" mt={2}>
      <Grid
        container
        item
        alignItems="center"
        direction="column"
        pb={4}
        pt={3}
        px={3}
      >
        <Text variant="hero2">{headerText}</Text>
        {infoText}
        <Text align="center" mb={1} mt={2} sx={{ width: '80%' }}>
          {icon}
        </Text>
      </Grid>
    </Grid>
  )
}

export default ProfileEmailPasswordReset
