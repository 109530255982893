import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme } from '@pbt/pbt-ui-components'

import NotAssignedPatient from '~/components/common/lists/primitive-table/cells/patient/NotAssignedPatient'
import ChoosePatientButton from '~/components/dashboard/lab-tests-dashboard/lab-tests-table/ChoosePatientButton'
import { LabTestDashboardItem } from '~/types'

import ClientAndPatientCell from '../ClientAndPatientCell'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    notAssignedPatient: {
      width: 'auto',
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'ClientAndPatientLabsCell' },
)

interface ClientAndPatientLabsCellProps {
  item?: LabTestDashboardItem
}

const ClientAndPatientLabsCell = ({ item }: ClientAndPatientLabsCellProps) => {
  const classes = useStyles()
  const patientAssigned = Boolean(item?.patient)

  return patientAssigned ? (
    <ClientAndPatientCell item={item} />
  ) : (
    <Grid container alignItems="center" wrap="nowrap">
      <NotAssignedPatient classes={{ root: classes.notAssignedPatient }} />
      <Dotdotdot clamp={1}>
        <ChoosePatientButton labTest={item} textVariant="body2" />
      </Dotdotdot>
    </Grid>
  )
}

export default ClientAndPatientLabsCell
