import React from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ClassesType,
  DocumentFile,
  PuiTheme,
  StateLabel,
  Text,
} from '@pbt/pbt-ui-components'

import AttachmentFileControlButtons from './AttachmentFileControlButtons'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    itemRow: {
      paddingRight: theme.spacing(1),
      '&:not(:last-child)': {
        borderBottom: theme.constants.tableBorder,
      },
    },
    labelContainer: {
      marginRight: theme.spacing(4),
    },
    stateLabel: {
      fontSize: '1rem',
    },
    filenameContainer: {
      minWidth: 0,
    },
    checkboxContainer: {
      minWidth: 10,
    },
    attachmentDisabled: {
      pointerEvents: 'none',
      opacity: 0.5,
    },
  }),
  { name: 'Attachment' },
)

export interface AttachmentProps {
  classes?: ClassesType<typeof useStyles>
  deleteDisabled?: boolean
  disabled?: boolean
  file: DocumentFile['file']
  hideDelete?: boolean
  hideDownload?: boolean
  hidePreview?: boolean
  onDeleteRequested?: (file: DocumentFile['file']) => void
  onDownloadRequested?: (file: DocumentFile['file']) => void
  onPreviewRequested?: (file: DocumentFile['file']) => void
  onSelectionToggle?: (fileId: string, value: boolean) => void
  selected?: boolean
}

const Attachment = ({
  file,
  hideDownload = false,
  hideDelete = false,
  hidePreview = false,
  deleteDisabled,
  disabled = false,
  onDownloadRequested,
  onPreviewRequested,
  onDeleteRequested,
  classes: classesProp,
  selected = false,
  onSelectionToggle,
}: AttachmentProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const fileId = file.id as string

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(classes.itemRow, {
        [classes.attachmentDisabled]: disabled,
      })}
      id={fileId}
    >
      <Grid item className={classes.checkboxContainer}>
        {onSelectionToggle && (
          <Checkbox
            checked={selected}
            onChange={(event, value) => onSelectionToggle(fileId, value)}
          />
        )}
      </Grid>
      <Grid item xs className={classes.filenameContainer}>
        <Text noWrap>{file.name}</Text>
      </Grid>
      <Grid item className={classes.labelContainer}>
        {file.required && (
          <StateLabel warning className={classes.stateLabel}>
            {t('Common:REQUIRED')}
          </StateLabel>
        )}
      </Grid>
      <Grid item>
        <AttachmentFileControlButtons
          deleteDisabled={deleteDisabled}
          file={file}
          hideDelete={hideDelete}
          hideDownload={hideDownload}
          hidePreview={hidePreview}
          onDeleteRequested={() => {
            if (onDeleteRequested) {
              onDeleteRequested(file)
            }
          }}
          onDownloadRequested={() =>
            onDownloadRequested && onDownloadRequested(file)
          }
          onPreviewRequested={() =>
            onPreviewRequested && onPreviewRequested(file)
          }
        />
      </Grid>
    </Grid>
  )
}

export default Attachment
