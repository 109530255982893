import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  CopyToClipboard,
  PuiDialog,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import {
  fetchActivationToken,
  generateActivationToken,
} from '~/store/actions/migration'
import { getPimsFull } from '~/store/reducers/constants'
import {
  getActivationToken,
  getIsActivationTokenLoading,
  getIsLoading,
} from '~/store/reducers/migration'
import { BaseMigrationDialogProps } from '~/types/entities/migration'

const useStyles = makeStyles((theme: PuiTheme) => ({
  copyText: {
    color: theme.colors.title,
  },
  button: {
    minWidth: 150,
  },
}))

interface MigrationActivationTokenDialogProps extends BaseMigrationDialogProps {
  exportedType: string
  pimsName: string
}

const MigrationActivationTokenDialog = ({
  businessId,
  pimsName,
  exportedType,
  onClose,
  open,
}: MigrationActivationTokenDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Businesses')

  const isLoading: boolean = useSelector(getIsLoading)
  const activationToken: string = useSelector(getActivationToken)
  const activationTokenLoading: boolean = useSelector(
    getIsActivationTokenLoading,
  )
  const PimsFull = useSelector(getPimsFull)

  const PimsId = Utils.findConstantIdByName(pimsName, PimsFull)

  useEffect(() => {
    dispatch(fetchActivationToken(businessId, PimsId))
  }, [])

  const handleGenerateToken = () => {
    dispatch(generateActivationToken(PimsId, businessId, exportedType))
  }

  return (
    <PuiDialog
      fullWidth
      actions={
        <ButtonWithLoader
          className={classes.button}
          disabled={Boolean(activationToken) || activationTokenLoading}
          loading={isLoading}
          onClick={handleGenerateToken}
        >
          {t('Businesses:MIGRATION.GENERATE_TOKEN')}
        </ButtonWithLoader>
      }
      aria-labelledby="migration-activation-token-dialog"
      maxWidth="xs"
      open={open}
      title={t('Businesses:MIGRATION.ACTIVATION_TOKEN')}
      onClose={onClose}
    >
      <Grid container justifyContent="center" p={2}>
        {activationTokenLoading ? (
          <CircularProgress color="primary" size={32} />
        ) : activationToken ? (
          <>
            <CopyToClipboard label={activationToken} text={activationToken} />
            <Text>
              <Trans
                components={{ copyText: <span className={classes.copyText} /> }}
                i18nKey="Businesses:MIGRATION.PLEASE_COPY_THIS_ACTIVATION_CODE"
              />
            </Text>
          </>
        ) : (
          <Text variant="h2">
            {t('Businesses:MIGRATION.SESSION_NOT_FOUND')}
          </Text>
        )}
      </Grid>
    </PuiDialog>
  )
}

export default MigrationActivationTokenDialog
