import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FilterLabel, PuiTheme, Text } from '@pbt/pbt-ui-components'

import AppointmentTypeFilter from '~/components/common/filters/AppointmentTypeFilter'
import MainTeamMemberFilter from '~/components/common/filters/MainTeamMemberFilter'
import DayScrollableColumns from '~/components/common/lists/scrollable-columns/DayScrollableColumns'
import { TimetableArea } from '~/constants/schedulerConstants'
import { setWhiteboardTreatmentsFilters } from '~/store/actions/whiteboard'
import {
  getWhiteboardIsLoading,
  getWhiteboardTreatmentsFilters,
} from '~/store/reducers/whiteboard'
import { TableFilter } from '~/types'
import { getConstantFromMapOrDefault } from '~/utils'

import TimetableHeader from '../header/TimetableHeader'
import {
  WhiteboardViewHumanReadable,
  WhiteboardViewType,
} from './whiteboardViews'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    header: {
      margin: theme.spacing(0, 1, 1),
    },
    headerContainer: {
      '&:first-child': {
        marginLeft: theme.spacing(1),
      },
      marginLeft: theme.spacing(2),
    },
  }),
  { name: 'WhiteboardTreatmentsHeader' },
)

const FILTERS = {
  ASSIGNED_TO: 'assignedTo',
  APPOINTMENT_TYPE: 'appointmentType',
}

export interface WhiteboardTreatmentsHeaderProps {
  additionalRefs?: React.RefObject<HTMLDivElement>[]
  onScrollOffsetChange: () => void
  openShadow: boolean
}
const WhiteboardTreatmentsHeader = ({
  openShadow,
  additionalRefs,
  onScrollOffsetChange,
}: WhiteboardTreatmentsHeaderProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const dispatch = useDispatch()
  const { view: viewProp } = useParams() as { view: string }
  const view = getConstantFromMapOrDefault(
    viewProp,
    WhiteboardViewType,
    WhiteboardViewType.DEFAULT,
  )

  const isLoading = useSelector(getWhiteboardIsLoading)
  const filters = useSelector(getWhiteboardTreatmentsFilters)

  const [openFilter, setOpenFilter] = useState<string>()
  const [wasLoading, setWasLoading] = useState<boolean>()
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    const timer = wasLoading
      ? window.setTimeout(() => setHasLoaded(true), 500)
      : null

    setWasLoading(isLoading)

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [isLoading])

  const onFilterClose = () => {
    setOpenFilter(undefined)
  }

  const onFilterClick = (filter: string) => {
    setOpenFilter(openFilter === filter ? undefined : filter)
  }

  const onApplyFilter = (filter: string, value: TableFilter) => {
    dispatch(setWhiteboardTreatmentsFilters({ ...filters, [filter]: value }))
  }

  return (
    <TimetableHeader
      area={TimetableArea.WHITEBOARD}
      isLoading={isLoading}
      openShadow={openShadow}
      selectedView={view}
      views={WhiteboardViewHumanReadable}
    >
      <Grid
        container
        item
        alignItems="center"
        className={classes.header}
        wrap="nowrap"
      >
        <Grid item xs />
        <Grid item xs={2}>
          <FilterLabel
            FilterComponent={MainTeamMemberFilter}
            filter={FILTERS.ASSIGNED_TO}
            filterValue={filters.assignedTo}
            label={t('Common:ASSIGNED')}
            open={openFilter === FILTERS.ASSIGNED_TO}
            onApplyFilter={onApplyFilter}
            onFilterClick={onFilterClick}
            onFilterClose={onFilterClose}
          />
        </Grid>
        <Grid item xs={2}>
          <FilterLabel
            FilterComponent={AppointmentTypeFilter}
            filter={FILTERS.APPOINTMENT_TYPE}
            filterValue={filters.appointmentType}
            label={t('Common:APPOINTMENT_ONE')}
            open={openFilter === FILTERS.APPOINTMENT_TYPE}
            onApplyFilter={onApplyFilter}
            onFilterClick={onFilterClick}
            onFilterClose={onFilterClose}
          />
        </Grid>
        <Grid item xs={1}>
          <Text strong variant="lowAccent2">
            {t('Common:SPACE_ONE')}
          </Text>
        </Grid>
        <Grid item xs={5}>
          <DayScrollableColumns
            additionalRefs={additionalRefs}
            hasLoaded={hasLoaded}
            onScrollOffsetChange={onScrollOffsetChange}
          />
        </Grid>
      </Grid>
    </TimetableHeader>
  )
}

export default WhiteboardTreatmentsHeader
