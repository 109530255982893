import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PuiTheme } from '@pbt/pbt-ui-components'

import { useShowOutstandingLabOrdersSection } from '~/store/hooks/labOrders'

import OrderManagementDialog from '../../../soap/wrap-up/lab-orders/order-management-dialog/OrderManagementDialog'
import { useGetLabOrderData } from '../../../soap/wrap-up/lab-orders/useGetLabOrderData'
import FinalizeOrdersRow from '../FinalizeOrdersRow'
import FinalizeOrdersSection from '../FinalizeOrdersSection'
import FinalizeLabOrdersRow from './FinalizeLabOrdersRow'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      borderTop: theme.constants.tabBorder,
    },
    rows: {
      '&:nth-child(even)': {
        background: theme.colors.divider,
      },
    },
  }),
  { name: 'FinalizeLabOrdersSection' },
)

const FinalizeLabOrdersSection = () => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Soap'])

  const {
    soapId,
    vendorsHasIntegrationMap,
    ordersMap,
    allLabOrders,
    currentOrderLabTestMap,
    keysToPick,
    clientId,
    patientId,
    setOrderManagementDialogVisible,
    orderManagementDialogVisible,
    setCurrentOrderVendorId,
    handleCancelOrder,
    handleEdit,
    handlePrintLabel,
  } = useGetLabOrderData()

  const { showOutstandingLabOrdersSection } =
    useShowOutstandingLabOrdersSection()

  return (
    <Grid container className={classes.root}>
      <FinalizeOrdersSection
        buttonLabel={t('Common:FINALIZE_ACTION')}
        description={t('Soap:FINALIZE_LAB_ORDERS_DESCRIPTION')}
        isFinalized={!showOutstandingLabOrdersSection}
        title={t('Soap:FINALIZE_LAB_ORDERS_TITLE')}
        onClick={() => setOrderManagementDialogVisible(true)}
      >
        {allLabOrders.length > 0 &&
          allLabOrders.map((order) => (
            <FinalizeLabOrdersRow
              currentOrderLabTestMap={currentOrderLabTestMap}
              handleCancel={() => handleCancelOrder(order.vendorId, order.id)}
              handleEdit={() => handleEdit(order.vendorId)}
              handlePrintLabel={() =>
                handlePrintLabel(order.labOrderId, order.date, order.vendorId)
              }
              hasIntegrationMap={vendorsHasIntegrationMap}
              key={`${order.id}-${order.vendorId}`}
              keysToPick={keysToPick}
              order={order}
            />
          ))}
        {allLabOrders.length === 0 && (
          <FinalizeOrdersRow
            orderNameDetails={{
              orderName: t('Soap:FINALIZE_LAB_ORDERS_NO_ORDERS'),
            }}
            showWarning={false}
            statusName={undefined}
          />
        )}
      </FinalizeOrdersSection>
      {clientId && patientId && (
        <OrderManagementDialog
          clientId={clientId}
          hasIntegrationMap={vendorsHasIntegrationMap}
          labTestsMap={currentOrderLabTestMap}
          open={orderManagementDialogVisible}
          ordersMap={ordersMap}
          patientId={patientId}
          soapId={soapId}
          onCancelOrder={handleCancelOrder}
          onClose={() => {
            setCurrentOrderVendorId(undefined)
            setOrderManagementDialogVisible(false)
          }}
        />
      )}
    </Grid>
  )
}

export default FinalizeLabOrdersSection
