import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  LanguageUtils,
  PuiCheckbox,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import { getDiagnosisProblemStates } from '~/store/reducers/constants'
import {
  getIsLoadingLogs,
  getProblemCatalogProblemsMap,
  getProblemsBodySystemLogsMap,
  getProblemsLogsMap,
} from '~/store/reducers/problems'
import { Problem, ProblemStates } from '~/types'

import ProblemsCheckboxLabel from './ProblemsCheckboxLabel'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    checkbox: {
      padding: theme.spacing(0.5),
    },
    label: {
      fontSize: '1.4rem',
      padding: theme.spacing(0.5),
    },
    root: {
      margin: 0,
    },
  }),
  { name: 'ProblemsCheckboxList' },
)

interface ProblemsCheckboxListProps {
  onProblemCheckboxClick: (problem: Problem) => void
  problemList: string[]
}

const ProblemsCheckboxList = ({
  problemList,
  onProblemCheckboxClick,
}: ProblemsCheckboxListProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Soap')
  const problemMap = useSelector(getProblemCatalogProblemsMap)
  const bodySystemLogs = useSelector(getProblemsBodySystemLogsMap)
  const problemsLogsMap = useSelector(getProblemsLogsMap)
  const diagnosisStates = useSelector(getDiagnosisProblemStates)
  const removedState = Utils.findByName(ProblemStates.REMOVED, diagnosisStates)
  const isLoading = useSelector(getIsLoadingLogs)

  const isChecked = (problemId: string) => {
    if (bodySystemLogs && problemMap) {
      const problem = problemMap[problemId]
      const bodySystemLog = bodySystemLogs[problem.bodySystemId]

      if (
        !bodySystemLog?.entity?.problemLogs.includes(problem.logKey as string)
      ) {
        return false
      }

      return (
        problemsLogsMap?.[problem.logKey!]?.entity?.stateId !== removedState.id
      )
    }
    return false
  }

  if (problemList.length === 0) {
    return (
      <Text mx={1} my={0.5} variant="body2">
        {t('Soap:PROBLEMS.NO_ITEMS_TO_DISPLAY')}
      </Text>
    )
  }

  return (
    <>
      {problemList.map((problemId: string) => (
        <PuiCheckbox
          checkboxClasses={{ root: classes.checkbox }}
          checked={isChecked(problemId)}
          classes={{ switchLabel: classes.label, labelRoot: classes.root }}
          disabled={isLoading}
          key={problemId}
          label={
            <ProblemsCheckboxLabel
              name={
                LanguageUtils.getTranslatedFieldName(problemMap![problemId])!
              }
              type={problemMap![problemId].type!}
            />
          }
          size="small"
          onChange={() => onProblemCheckboxClick(problemMap![problemId])}
        />
      ))}
    </>
  )
}

export default ProblemsCheckboxList
