import React, { useCallback } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ShareIcon from '@mui/icons-material/Share'
import { Grid, Hidden, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  DateUtils,
  ImageScalingUtils,
  Nil,
  Patient,
  PatientAvatar,
  PermissionArea,
  PuiTheme,
  TextInteractive,
  User,
  Utils,
} from '@pbt/pbt-ui-components'
import { getHighlightedText } from '@pbt/pbt-ui-components/src/utils'

import AlertLabel from '~/components/common/labels/AlertLabel'
import PatientInfoLabel from '~/components/common/labels/PatientInfoLabel'
import { AlertColorLevel } from '~/constants/alertColors'
import { useAlertType } from '~/store/hooks/patient'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getConstantsIsLoading, getSpecies } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { getPatientStatus } from '~/utils'

import PatientMembershipLink from '../patient/PatientMembershipLink'

const AVATAR_MAX_SIZE = 72

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    listShadow: {
      '&:hover': {
        boxShadow: theme.constants.listItemShadow,
      },
    },
    patientContainer: {
      flex: 1,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
      },
      [theme.breakpoints.only('sm')]: {
        paddingTop: 10,
        paddingBottom: 10,
      },
      '&:not(:last-child)': {
        borderBottom: theme.constants.tableBorder,
      },
    },
    patientInner: {
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
      },
      [theme.breakpoints.down('lg')]: {
        paddingLeft: 15,
      },
      paddingLeft: 30,
    },
    patientInnerInactive: {
      opacity: theme.constants.inactiveOpacity,
    },
    scheduleContainer: {
      userSelect: 'none',
      height: '100%',
    },
    avatar: {
      width: AVATAR_MAX_SIZE,
      height: AVATAR_MAX_SIZE,
      [theme.breakpoints.down('sm')]: {
        width: 55,
        height: 55,
      },
    },
    tertiaryText: {
      wordBreak: 'break-all',
    },
    text: {
      wordBreak: 'break-all',
    },
    scheduleLink: {
      wordBreak: 'break-all',
    },
    appointmentType: {
      wordBreak: 'break-all',
    },
    shareIcon: {
      width: '0.75em',
      height: '0.75em',
      color: theme.colors.tableBackground,
      marginRight: 2,
    },
    shareIconContainer: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      borderRadius: theme.spacing(1.5),
      backgroundColor: theme.colors.link,
    },
    businessShareIcon: {
      '&:not(:first-child)': {
        marginTop: theme.spacing(1),
      },
    },
  }),
  { name: 'PatientRow' },
)

export type PatientRowActionButton = {
  className?: string
  disabled?: (patient: Patient | Nil) => boolean
  onClick: (patient: Patient | Nil, client: User | Nil) => void
  title: string
}

interface PatientRowProps {
  actionButtons?: PatientRowActionButton[]
  clientId: string
  hideActionsColumn?: boolean
  hideFlagsColumn?: boolean
  hideLastAppointmentColumn?: boolean
  hideMembershipColumn?: boolean
  hideScheduleColumn?: boolean
  hideSharedColumn?: boolean
  highlightProps: Record<string, string[]>
  isLoading?: boolean
  onClick: (clientId: string | Nil, patientId: string | Nil) => void
  patientId: string
  patientsCount: number
}

const PatientRow = ({
  actionButtons: actionButtonsProp,
  isLoading: isClientLoading,
  patientId,
  patientsCount,
  clientId,
  highlightProps,
  onClick,
  hideFlagsColumn = false,
  hideScheduleColumn = false,
  hideLastAppointmentColumn = true,
  hideMembershipColumn = false,
  hideActionsColumn = true,
  hideSharedColumn = true,
}: PatientRowProps) => {
  const actionButtons = actionButtonsProp || []
  const navigate = useNavigate()
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Clients'])

  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))
  const Species = useSelector(getSpecies)
  const isConstantsLoading = useSelector(getConstantsIsLoading)
  const { create: appointmentCreatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )

  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('sm'),
  )

  const isLoading = isClientLoading || isConstantsLoading
  const patientStatus = getPatientStatus(patient)

  const { alertsToRender } = useAlertType(patientId)
  const alertTypes = patientStatus.disabled
    ? patientStatus.reasons
    : alertsToRender.slice(0, 2)

  const onSchedulerLinkClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (patient?.nextAppointment) {
        event.stopPropagation()
        navigate(`/scheduler/schedule/${patient.nextAppointment.appointmentId}`)
      } else if (clientId !== undefined) {
        event.stopPropagation()
        navigate('/scheduler', { state: { clientId, patientId } })
      }
    },
    [patient, client, navigate],
  )

  const md = hideLastAppointmentColumn && !hideFlagsColumn ? 6 : 7
  const sm = hideLastAppointmentColumn && !hideFlagsColumn ? 5 : 6

  const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
    patient?.photo,
    patient?.photoThumbnail,
    AVATAR_MAX_SIZE,
  )

  return (
    <Grid
      container
      item
      xs
      alignItems="center"
      className={classNames(
        classes.patientContainer,
        patientsCount > 1 ? classes.listShadow : {},
      )}
      wrap="nowrap"
      onClick={() => onClick(clientId, patientId)}
    >
      <Grid
        container
        item
        alignItems="center"
        className={classNames(classes.patientInner, {
          [classes.patientInnerInactive]: patientStatus.disabled,
        })}
        md={hideMembershipColumn ? md : md - 1}
        sm={hideMembershipColumn ? sm : sm - 1}
        wrap="nowrap"
        xs={12}
      >
        <Grid item>
          <PatientAvatar
            animal={Utils.getConstantName(patient?.species, Species) || ''}
            className={classes.avatar}
            isLoading={isLoading}
            showBoopBadge={patient?.isBoop}
            src={avatarSrc}
          />
        </Grid>
        <Grid item xs ml={{ xs: 2, lg: 5 }}>
          {patient?.name && (
            <Dotdotdot clamp={1}>
              <TextInteractive
                strong
                align="left"
                className={classes.text}
                highlight={getHighlightedText(
                  patient.name,
                  highlightProps.name,
                )}
                isLoading={isLoading}
                loaderWidth={120}
                variant="subheading"
              >
                {patient.name}
              </TextInteractive>
            </Dotdotdot>
          )}
          <PatientInfoLabel
            isLoading={isLoading}
            loaderWidth={150}
            patientId={patientId}
            variant="body2"
          />
        </Grid>
      </Grid>
      {!hideFlagsColumn && (
        <Grid
          container
          item
          alignItems="flex-start"
          direction={isMobile ? 'row' : 'column'}
          md={hideSharedColumn ? 3 : 2}
          pt={{ xs: 0.5, sm: 0.5 }}
          px={2}
          sm={hideSharedColumn ? 4 : 3}
          xs={12}
        >
          {isLoading ? (
            <TextInteractive
              align="left"
              className={classes.appointmentType}
              isLoading={isLoading}
              pt={0.5}
              variant="body2"
            />
          ) : (
            alertTypes.map((alert) => (
              <Grid item key={alert}>
                <AlertLabel
                  alertColorId={
                    patientStatus.disabled ? undefined : patient?.alertColorId
                  }
                  alertColorLevel={AlertColorLevel.PATIENT}
                  message={alert}
                  sx={{ mr: 0.5, mb: 0.5 }}
                  variant={patientStatus.disabled ? 'disabled' : 'attention'}
                />
              </Grid>
            ))
          )}
        </Grid>
      )}
      <Hidden smDown>
        {!hideScheduleColumn && (
          <Grid
            container
            item
            alignItems="flex-start"
            className={classes.scheduleContainer}
            direction="column"
            justifyContent="center"
            xs={2}
            onClick={
              appointmentCreatePermissions ? onSchedulerLinkClick : undefined
            }
          >
            {!patientStatus.disabled &&
              (patient && patient.nextAppointment ? (
                <>
                  <TextInteractive
                    underline
                    align="left"
                    className={classes.scheduleLink}
                    disabled={!appointmentCreatePermissions}
                    isLoading={isLoading}
                  >
                    {DateUtils.formatDate(patient.nextAppointment.startDate)}
                  </TextInteractive>
                  <TextInteractive
                    align="left"
                    className={classes.appointmentType}
                    disabled={!appointmentCreatePermissions}
                    isLoading={isLoading}
                    pt={0.5}
                    variant="body2"
                  >
                    {patient.nextAppointment.typeName}
                  </TextInteractive>
                </>
              ) : (
                <TextInteractive
                  underline
                  align="left"
                  className={classes.scheduleLink}
                  disabled={!appointmentCreatePermissions}
                  isLoading={isLoading}
                >
                  {t('Common:SCHEDULE_NOUN')}
                </TextInteractive>
              ))}
          </Grid>
        )}
      </Hidden>
      {!hideLastAppointmentColumn && (
        <Grid
          container
          item
          alignItems="flex-start"
          className={classes.scheduleContainer}
          direction="column"
          justifyContent="center"
          md={2}
          sm={2}
        >
          {patient &&
            (patient.lastAppointment ? (
              <>
                <TextInteractive
                  underline
                  align="left"
                  className={classes.scheduleLink}
                  isLoading={isLoading}
                >
                  {DateUtils.formatDate(patient.lastAppointment.startDate)}
                </TextInteractive>
                <TextInteractive
                  align="left"
                  className={classes.appointmentType}
                  isLoading={isLoading}
                  pt={0.5}
                  variant="body2"
                >
                  {patient.lastAppointment.typeName}
                </TextInteractive>
              </>
            ) : (
              <TextInteractive
                underline
                align="left"
                className={classes.scheduleLink}
                isLoading={isLoading}
              >
                {t('Common:NO_APPOINTMENTS')}
              </TextInteractive>
            ))}
        </Grid>
      )}
      <Hidden smDown>
        {!hideMembershipColumn && (
          <Grid item xs={2}>
            <PatientMembershipLink
              clientId={clientId}
              isLoading={isLoading}
              patientId={patientId}
            />
          </Grid>
        )}
      </Hidden>
      {!hideSharedColumn && (
        <Hidden xsDown>
          <Grid
            container
            item
            alignItems="center"
            className={classes.scheduleContainer}
            direction="column"
            justifyContent="center"
            xs={1}
          >
            {client?.isShared && (
              <Grid
                container
                item
                alignItems="center"
                className={classes.shareIconContainer}
                justifyContent="center"
              >
                <ShareIcon className={classes.shareIcon} />
              </Grid>
            )}
          </Grid>
        </Hidden>
      )}
      {!hideActionsColumn && (
        <Grid container item alignItems="center" justifyContent="center" xs={3}>
          {actionButtons.map(
            ({ className, disabled, title, onClick: onButtonClick }) => (
              <ButtonWithLoader
                className={className}
                disabled={
                  R.isNil(disabled)
                    ? !patient?.active
                    : typeof disabled === 'function'
                    ? disabled(patient)
                    : disabled
                }
                key={title}
                onClick={() => onButtonClick(patient, client)}
              >
                {title}
              </ButtonWithLoader>
            ),
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default PatientRow
