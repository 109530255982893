import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, useMediaQuery } from '@mui/material'
import * as R from 'ramda'
import {
  Constant,
  GenderRestriction,
  PermissionArea,
  PuiTheme,
  TextWithTooltip,
  UnitUtils,
  useFields,
} from '@pbt/pbt-ui-components'
import { UnitTypes } from '@pbt/pbt-ui-components/src/localization'

import AgeSelect from '~/components/common/inputs/AgeSelect'
import GenderRestrictionSelect from '~/components/common/inputs/gender/GenderRestrictionSelect'
import PuiSelectAll from '~/components/common/inputs/PuiSelectAll'
import WeightRangeInput from '~/components/common/inputs/WeightInput'
import { getUnitsState } from '~/store/duck/settings'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getSpecies } from '~/store/reducers/constants'
import { InventoryItem, InventoryItemRestriction } from '~/types'
import { getConstantsList, handleNumberInput } from '~/utils'
import useFieldsChanged from '~/utils/useFieldsChanged'

export interface InventoryRestrictionProps {
  inventory?: InventoryItem
  onChange?: () => void
}

export interface InventoryRestrictionHandle {
  getRestriction: () => InventoryItemRestriction
}

const InventoryRestriction = forwardRef<
  InventoryRestrictionHandle,
  InventoryRestrictionProps
>(function InventoryRestriction({ inventory, onChange }, ref) {
  const Species = useSelector(getSpecies)
  const unitsState = useSelector(getUnitsState)
  const permissions = useSelector(getCRUDByArea(PermissionArea.INVENTORY))
  const { t } = useTranslation(['Admin', 'Common', 'Tooltips'])

  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )

  const { fields, reset } = useFields(
    [
      {
        name: 'species',
        label: t('Common:SPECIES'),
        type: 'select',
        initialValue: getConstantsList(inventory?.speciesIds, Species),
      },
      {
        name: 'gender',
        label: t('Common:GENDER'),
        type: 'select',
        initialValue: inventory?.genderRestrictions || [],
      },
      {
        name: 'weightMinimum',
        label: t('Admin:CATALOG.INVENTORY_RESTRICTION.WEIGHT_MINIMUM'),
        initialValue: UnitUtils.convertUnits(
          UnitTypes.WEIGHT,
          inventory?.weightMinimum,
          unitsState,
        ),
      },
      {
        name: 'ageMinimum',
        label: t('Admin:CATALOG.INVENTORY_RESTRICTION.AGE_MINIMUM'),
        initialValue: {
          ageUnit: inventory?.ageUnitId,
          value: inventory?.ageMinimum,
        },
      },
    ],
    false,
  )

  const { species, gender, weightMinimum, ageMinimum } = fields

  useFieldsChanged(onChange, fields)

  useEffect(() => {
    if (inventory) {
      reset()
    }
  }, [inventory])

  useImperativeHandle(ref, () => ({
    getRestriction: () => {
      const { ageUnit, value: ageMinimumValue } = ageMinimum.value
      const newWeightMinimum = UnitUtils.serializeWeightUnit({
        field: weightMinimum,
        unitsState,
        initialValue: inventory?.weightMinimum,
      })

      return {
        speciesIds: R.pluck('id', (species.value || []) as Constant[]),
        genderRestrictions: (gender.value || []).map(
          (restriction: GenderRestriction) =>
            R.pick(['genderId', 'spayedNeuteredStatusId'], restriction),
        ),
        weightMinimum: newWeightMinimum || null,
        ageMinimum: ageMinimumValue,
        ageUnitId: ageUnit,
      }
    },
  }))

  return (
    <Grid container item direction="column">
      <Grid item>
        <TextWithTooltip
          strong
          tooltipText={t(
            'Tooltips:IF_PATIENT_OR_APPOINTMENT_DOES_NOT_MEET_CRITERIA_INVENTORY',
          )}
          variant={isMobile ? 'body' : 'body2'}
        >
          {t('Common:RESTRICTION_OTHER')}
        </TextWithTooltip>
      </Grid>
      <Grid container item direction="column" rowSpacing={3}>
        <Grid container item alignItems="flex-end" columnSpacing={3} pt={0}>
          <Grid item md={9} xs={12}>
            <PuiSelectAll
              disabled={!permissions.update}
              field={species}
              items={Species}
              label={species.label}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <GenderRestrictionSelect
              disabled={!permissions.update}
              field={gender}
            />
          </Grid>
        </Grid>
        <Grid container item columnSpacing={3}>
          <Grid item md={4} xs={12}>
            <AgeSelect
              disabled={!permissions.update}
              label={ageMinimum.label}
              value={ageMinimum.value}
              onChange={({ ageUnit, value }) =>
                ageMinimum.setValue({ ageUnit, value })
              }
            />
          </Grid>
          <Grid container item md={5} xs={12}>
            <WeightRangeInput
              disabled={!permissions.update}
              label={weightMinimum.label}
              weightField={{
                ...weightMinimum,
                set: handleNumberInput(weightMinimum.setValue, 10, 4),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
})

export default InventoryRestriction
