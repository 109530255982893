import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Field,
  PuiTextField,
  PuiTheme,
  useFields,
  ValidateHandle,
  ValidateOptions,
} from '@pbt/pbt-ui-components'

import { getBusiness } from '~/store/reducers/businesses'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../../../practices'
import {
  getFieldsValuesList,
  getPropertiesMapFromFields,
  hasCredentialsChanged,
  // @ts-ignore
} from '../../../utils'
import VendorFormContainer from '../../VendorFormContainer'
import ModalitiesTable from '../ModalitiesTable'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      position: 'relative',
    },
    item: {
      padding: theme.spacing(1, 1.5),
    },
    progress: {
      position: 'absolute',
      left: 'calc(50% - 16px)',
      top: 'calc(50% - 16px)',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
    },
  }),
  { name: 'IdexxImagingForm' },
)

export interface IdexxImagingFormProps {
  active: boolean
  businessId: string
  credentials: {
    locationToken: string
    password: string
    username: string
  }
  initiallyActive: boolean
  isTesting: boolean
  name: string
  onTest: () => void
  onUpdate: (credentials: IdexxImagingFormProps['credentials']) => void
  status: string
  vendorId: string
}

const IdexxImagingForm = forwardRef<ValidateHandle, IdexxImagingFormProps>(
  function IdexxImagingForm(
    {
      active,
      initiallyActive,
      businessId,
      credentials,
      isTesting,
      name,
      status,
      vendorId,
      onTest,
      onUpdate,
    },
    ref,
  ) {
    const classes = useStyles()
    const { t } = useTranslation('Common')

    const business = useSelector(getBusiness(businessId))

    const { fields, validate, reset } = useFields(
      [
        {
          name: 'username',
          label: t('Common:USERNAME'),
          validators: ['required'],
          initialValue: credentials.username || '',
        },
        {
          name: 'password',
          label: t('Common:PASSWORD'),
          initialValue: credentials.password || '',
        },
        {
          name: 'locationToken',
          label: t('Common:LOCATION_TOKEN'),
          validators: ['required'],
          initialValue: credentials.locationToken || '',
        },
      ],
      false,
    )

    const { username, password, locationToken } = fields

    usePracticeFieldsSection({
      business,
      fields: {
        ...fields,
        active: {
          name: 'active',
          initialValue: initiallyActive,
          value: active,
        } as Field,
      },
      sectionName:
        `${PracticeDetailsPanels.IMAGING_INTEGRATIONS}_IDEXX` as PracticeDetailsPanels,
      parentSectionName: PracticeDetailsPanels.LAB_INTEGRATIONS,
      validate: (options?: ValidateOptions | undefined): boolean => {
        if (active) {
          return validate(options)
        }
        return true
      },
      reset,
      resetDependencies: [active, initiallyActive, business],
    })

    useImperativeHandle(ref, () => ({ validate }))

    useEffect(() => {
      const newCredentials = getPropertiesMapFromFields(fields)
      if (hasCredentialsChanged(credentials, newCredentials) && active) {
        onUpdate(newCredentials)
      }
    }, [active, ...getFieldsValuesList(fields)])

    if (!active) {
      return null
    }

    return (
      <VendorFormContainer
        Footer={
          <ModalitiesTable
            active={active}
            businessId={businessId}
            vendorId={vendorId}
            vendorName={name}
          />
        }
        isTesting={isTesting}
        status={status}
        onTest={onTest}
      >
        <Grid item className={classes.item} md={6}>
          <PuiTextField
            autoComplete="off"
            field={username}
            inputProps={{ maxLength: 100 }}
            label={username.label}
          />
        </Grid>
        <Grid item className={classes.item} md={6}>
          <PuiTextField
            shrink
            autoComplete="off"
            field={password}
            inputProps={{ maxLength: 100 }}
            label={password.label}
            placeholder={
              active
                ? `<${t('Common:USE_STORED_PASSWORD').toLowerCase()}>`
                : '∗∗∗∗∗∗∗∗∗∗∗∗∗'
            }
            type="password"
          />
        </Grid>
        <Grid item className={classes.item} md={6}>
          <PuiTextField
            data-private
            autoComplete="off"
            field={locationToken}
            inputProps={{ maxLength: 100 }}
            label={locationToken.label}
          />
        </Grid>
      </VendorFormContainer>
    )
  },
)

export default IdexxImagingForm
