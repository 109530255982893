import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Fab, FormControl, Grid, Grow, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ClassesType,
  Constant,
  IdObject,
  LanguageUtils,
  PuiTextField,
  PuiTheme,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import BusinessTypeLabel from '~/components/common/BusinessTypeLabel'
import PuiSelectAll from '~/components/common/inputs/PuiSelectAll'
import { getBusinessStatus, getPracticeTypes } from '~/store/reducers/constants'
import { FieldsQueryPair } from '~/types'
import {
  getFieldsQueryMergeObject,
  getStringifyFieldsQueryPairs,
  getUrlSearchParam,
} from '~/utils'

const isNotEmpty = R.complement(R.isEmpty)

const queryObjectToPairs = R.pipe(R.filter(isNotEmpty), R.toPairs)

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    dropDown: {
      position: 'absolute',
      top: theme.spacing(3),
      width: '100%',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      boxShadow: theme.constants.blockShadow,
      padding: theme.spacing(4, 1, 3),
      [theme.breakpoints.down('sm')]: {
        top: theme.spacing(8),
        marginLeft: 0,
      },
      overflowX: 'hidden',
      overflowY: 'auto',
      maxHeight: `calc(100vh - ${theme.spacing(4)})`,
    },
    wrapper: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    section: {
      padding: theme.spacing(0.5, 0.5, 1),
    },
    searchButton: {
      width: 150,
      height: 40,
    },
    row: {
      minHeight: theme.spacing(8),
    },
  }),
  { name: 'AdvancedPracticeSearch' },
)

export interface AdvancedPracticeSearchProps {
  classes?: ClassesType<typeof useStyles>
  initialFieldsQuery?: string
  onSearch: (search: string) => void
  open: boolean
}

const AdvancedPracticeSearch = ({
  open,
  initialFieldsQuery = '',
  onSearch,
  classes: classesProp,
}: AdvancedPracticeSearchProps) => {
  const classes = useStyles({ classes: classesProp })
  const BusinessStatus = useSelector(getBusinessStatus)
  const PracticeTypes = useSelector(getPracticeTypes)

  const { t } = useTranslation(['Common', 'Businesses'])

  const location = useLocation()

  const fieldsQuery =
    getUrlSearchParam('fieldsQuery', location.search) || initialFieldsQuery
  const searchFieldsObject = getFieldsQueryMergeObject(fieldsQuery)

  const statusValue = searchFieldsObject.status || ''
  const typeValue = searchFieldsObject.type || ''

  const statusInitialValue =
    statusValue
      .split(',')
      .filter(Boolean)
      .map((name) => Utils.findConstantByName(name.trim(), BusinessStatus)) ||
    []

  const typeInitialValue =
    typeValue
      .split(',')
      .filter(Boolean)
      .map((name) => Utils.findConstantByName(name.trim(), PracticeTypes)) || []

  const {
    fields: {
      groupName,
      practiceName,
      internalName,
      accountNumber,
      region,
      status,
      tagName,
      type,
    },
  } = useFields([
    {
      name: 'groupName',
      label: t('Businesses:GROUP_NAME'),
      initialValue: searchFieldsObject.groupName || '',
    },
    {
      name: 'practiceName',
      label: t('Businesses:PRACTICE_NAME'),
      initialValue: searchFieldsObject.practiceName || '',
    },
    {
      name: 'internalName',
      label: t('Businesses:INTERNAL_NAME'),
      initialValue: searchFieldsObject.internalName || '',
    },
    {
      name: 'accountNumber',
      label: t('Businesses:ACCOUNT_NUMBER'),
      initialValue: searchFieldsObject.accountNumber || '',
    },
    {
      name: 'region',
      label: t('Businesses:REGION'),
      initialValue: searchFieldsObject.region || '',
    },
    {
      name: 'status',
      type: 'select',
      initialValue: statusInitialValue,
    },
    {
      name: 'tagName',
      label: t('Businesses:TAGS'),
      initialValue: searchFieldsObject.tagName || '',
    },
    {
      name: 'type',
      type: 'select',
      initialValue: typeInitialValue,
    },
  ])

  const onSearchClick = () => {
    const values = {
      groupName: groupName.value,
      practiceName: practiceName.value,
      internalName: internalName.value,
      accountNumber: accountNumber.value,
      region: region.value,
      status: status.value
        .map((item: IdObject) =>
          Utils.getConstantName(item?.id, BusinessStatus, ''),
        )
        .join(','),
      tagName: tagName.value,
      type: type.value
        .map((item: IdObject) =>
          Utils.getConstantName(item?.id, PracticeTypes, ''),
        )
        .join(','),
    }

    const valuePairs = queryObjectToPairs(values) as FieldsQueryPair[]

    onSearch(getStringifyFieldsQueryPairs(valuePairs))
  }

  const renderTypeItem = ({ item }: { item: Constant }) => (
    <BusinessTypeLabel icon={item.name}>
      {LanguageUtils.getTranslatedFieldName(item)}
    </BusinessTypeLabel>
  )

  return (
    <Grow in={open}>
      <Paper className={classes.dropDown}>
        <Grid
          container
          className={classes.wrapper}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              onSearchClick()
              const target = event.target as HTMLElement
              target.blur()
            }
          }}
        >
          <Grid item className={classes.section} xs={12}>
            <Grid container direction="column">
              <Grid item className={classes.row}>
                <PuiTextField
                  field={groupName}
                  inputProps={{ maxLength: 100 }}
                  label={groupName.label}
                />
              </Grid>
              <Grid item className={classes.row}>
                <PuiTextField
                  field={practiceName}
                  inputProps={{ maxLength: 100 }}
                  label={practiceName.label}
                />
              </Grid>
              <Grid item className={classes.row}>
                <PuiTextField
                  field={internalName}
                  inputProps={{ maxLength: 100 }}
                  label={internalName.label}
                />
              </Grid>
              <Grid item className={classes.row}>
                <PuiTextField
                  field={accountNumber}
                  inputProps={{ maxLength: 100 }}
                  label={accountNumber.label}
                />
              </Grid>
              <Grid item className={classes.row}>
                <PuiTextField
                  field={region}
                  inputProps={{ maxLength: 100 }}
                  label={region.label}
                />
              </Grid>
              <Grid item className={classes.row}>
                <FormControl fullWidth margin="normal">
                  <PuiSelectAll
                    allSelectedText={t('Businesses:ALL_STATUSES')}
                    field={status}
                    id="status-puiselect"
                    items={BusinessStatus}
                    noneSelectedText={t('Businesses:STATUS')}
                  />
                </FormControl>
              </Grid>
              <Grid item className={classes.row}>
                <PuiTextField
                  field={tagName}
                  inputProps={{ maxLength: 100 }}
                  label={tagName.label}
                />
              </Grid>
              <Grid item className={classes.row}>
                <FormControl fullWidth margin="normal">
                  <PuiSelectAll
                    ItemComponent={renderTypeItem}
                    allSelectedText={t('Businesses:ALL_TYPES')}
                    field={type}
                    id="type-puiselect"
                    items={PracticeTypes}
                    noneSelectedText={t('Businesses:TYPES')}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item justifyContent="center" xs={12}>
            <Fab
              className={classes.searchButton}
              color="inherit"
              variant="extended"
              onClick={onSearchClick}
            >
              {t('Common:SEARCH_ACTION')}
            </Fab>
          </Grid>
        </Grid>
      </Paper>
    </Grow>
  )
}

export default AdvancedPracticeSearch
