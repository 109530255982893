import React, { createRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  PermissionArea,
  PuiDialog,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import { editMultipleLabTestPrices } from '~/store/actions/labTests'
import { fetchSOAPOrders } from '~/store/actions/soap'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getLabTestsIsLoading } from '~/store/reducers/labTests'
import { getSelectedOrders } from '~/store/reducers/orders'
import { DataHandle, Order } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import InvalidLabTestPrice from './InvalidLabTestPrice'

const LAB_TEST_ORDER_TYPE = 'LAB_TEST'
const isLabTestOrder = (order: Order) => order.type === LAB_TEST_ORDER_TYPE
const isWeirdOrder = (order: Order) => Boolean(order.price?.tempValue)

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    root: {
      backgroundColor: theme.colors.contentBackground,
      overflowY: 'hidden',
    },
    button: {
      height: 40,
      padding: theme.spacing(0, 6),
    },
    warningIcon: {
      color: theme.colors.alertWarning,
      fontSize: '2.6rem',
    },
  }),
  { name: 'LabTestPriceValidationDialog' },
)

interface LabTestPriceValidationDialogProps extends BasePuiDialogProps {
  onOk?: () => void
  soapBusinessId: string | Nil
  soapId: string | Nil
}

const LabTestPriceValidationDialog = ({
  open,
  onClose,
  onOk,
  soapId,
  soapBusinessId,
}: LabTestPriceValidationDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Soap'])

  const permissions = useSelector(getCRUDByArea(PermissionArea.LAB_TEST_PRICE))
  const selectedOrders = useSelector(getSelectedOrders) || []

  const weirdPriceLabOrders = selectedOrders.filter(
    R.allPass([isLabTestOrder, isWeirdOrder]),
  )

  const [weirdPricesExist, setWeirdPricesExist] = useState(
    weirdPriceLabOrders.length > 0,
  )

  const confirmationButtonLabel =
    weirdPriceLabOrders.length > 1
      ? t('Common:ADD_PRICES')
      : t('Common:ADD_PRICE')
  const isLoadingSomething = false
  const refs = weirdPriceLabOrders.map(() => createRef<DataHandle>())

  const validateRefs = () => refs.every((ref) => ref.current?.validate())

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const handleSucceedValidation = () => {
    if (onOk) {
      onOk()
    }
    if (soapId) {
      dispatch(fetchSOAPOrders(soapId, soapBusinessId))
    }
    handleClose()
  }

  useEffect(() => {
    if (!selectedOrders?.length && soapId) {
      dispatch(fetchSOAPOrders(soapId, soapBusinessId))
    }
    setWeirdPricesExist(weirdPriceLabOrders.length > 0)
  }, [selectedOrders])

  useEffect(() => {
    if (!weirdPricesExist) {
      handleSucceedValidation()
    }
  }, [weirdPricesExist])

  const closeOnUpdate = useCloseAfterCreation(
    handleSucceedValidation,
    getLabTestsIsLoading,
  )

  const handleSave = () => {
    if (validateRefs()) {
      const updatedPriceValues = refs.map((ref) => ref.current?.get())

      const updates = weirdPriceLabOrders.map((labOrder, index) => {
        const updatedPrice = updatedPriceValues[index]
        const price = {
          ...labOrder.price,
          price: updatedPrice,
          tempValue: false,
        }
        const labTestId = labOrder.labTest?.id

        return { labTestId, price }
      })

      dispatch(editMultipleLabTestPrices(updates))
      closeOnUpdate()
    }
  }

  return (
    <PuiDialog
      aria-labelledby="lab-test-price-validation-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open && weirdPricesExist}
      onClose={handleClose}
    >
      <Grid
        container
        alignItems="center"
        className={classes.root}
        direction="column"
        p={3}
        spacing={3}
      >
        <Grid item>
          <WarningIcon className={classes.warningIcon} />
        </Grid>
        <Grid item>
          <Text>{t('Soap:PLEASE_ENTER_PRICE_FOR_LAB_TESTS')}:</Text>
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={2}>
            {weirdPriceLabOrders.map(({ name }, index) => (
              <Grid item key={name}>
                <InvalidLabTestPrice ref={refs[index]} title={name} />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item>
          <Text>
            {t(
              'Soap:NOTE_IF_YOU_ARE_NOT_PRACTICE_ADMINISTRATOR_YOU_WILL_NEED_TO_ASK_THEM_TO_UPDATE_PRICES',
            )}
          </Text>
        </Grid>
        <Grid item>
          <ButtonWithLoader
            className={classes.button}
            disabled={isLoadingSomething || !permissions.update}
            loading={isLoadingSomething}
            onClick={handleSave}
          >
            {confirmationButtonLabel}
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default LabTestPriceValidationDialog
