import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button, Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { equals } from 'ramda'
import { ClassesType, PermissionArea, PuiTheme } from '@pbt/pbt-ui-components'
import { Wings as WingsIcon } from '@pbt/pbt-ui-components/src/icons'

import MenuDropdown from '~/components/common/inputs/MenuDropdown'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  fetchRabiesVaccinations,
  fetchVaccinations,
  getRabiesVaccinations,
  getVaccinations,
} from '~/store/duck/vaccination'
import { useOpenInvoice } from '~/store/hooks/finance'
import { useCreatePatient } from '~/store/hooks/patient'
import {
  getCRUDByArea,
  getCurrentBusinessIsOmniChannel,
} from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatient, getPatientsList } from '~/store/reducers/patients'
import { getPatientsForUser, getUser } from '~/store/reducers/users'
import useDialog from '~/utils/useDialog'

import DetailsBackButton from '../../clients/DetailsBackButton'
import ClientAndPatientLabelMenuDropDown, {
  LabelMenuOptions,
} from '../../labels/ClientAndPatientLabelMenuDropDown'
import TopMenu from './TopMenu'
import TopMenuButtonsSection from './TopMenuButtonsSection'
import {
  createMenuItem,
  NewMenuItemType,
  onSelectMenuItem,
  ShareMenuItemType,
} from './topMenuUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    backButton: {
      marginRight: theme.spacing(1),
    },
    menuButton: {
      '&:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
    addNewPatientItem: {
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
      borderBottom: theme.constants.tableBorder,
    },
    wingsIcon: {
      width: '1.25em',
      height: '1.25em',
      margin: theme.spacing(-0.5, 0.5, -0.5, 0),
    },
    deceasedTag: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
  }),
  { name: 'ClientsAndPatientsTopMenu' },
)

export interface ClientsAndPatientsTopMenuProps {
  classes?: ClassesType<typeof useStyles>
}

const ClientsAndPatientsTopMenu = ({
  classes: classesProp,
}: ClientsAndPatientsTopMenuProps) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const { createPatient } = useCreatePatient()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation(['Abbreviations', 'Common', 'Clients'])

  const { clientId, patientId } = useParams() as {
    clientId: string
    patientId: string
  }
  const locationState = location.state as { searchQuery?: string }

  const isXs = useMediaQuery((theme: PuiTheme) => theme.breakpoints.down('sm'))

  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))
  const patients = useSelector(getPatientsForUser(clientId), equals)
  const patientsList = useSelector(getPatientsList(patients))
  const { create: reminderCreatePermission } = useSelector(
    getCRUDByArea(PermissionArea.REMINDERS),
  )
  const { create: appointmentCreatePermission } = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )
  const { create: invoiceCreatePermission } = useSelector(
    getCRUDByArea(PermissionArea.INVOICE),
  )
  const { create: taskCreatePermission } = useSelector(
    getCRUDByArea(PermissionArea.TASK),
  )
  const { create: communicationCreatePermission } = useSelector(
    getCRUDByArea(PermissionArea.COMMUNICATION),
  )
  const { create: patientCreatePermissions, update: patientUpdatePermissions } =
    useSelector(getCRUDByArea(PermissionArea.PATIENT))
  const { create: soapCreatePermissions, update: soapUpdatePermissions } =
    useSelector(getCRUDByArea(PermissionArea.SOAP))
  const { update: vitalsUpdatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.VITAL),
  )

  const vaccinations = useSelector(getVaccinations(patientId))
  const isProofOfVaccinationPossible = Boolean(vaccinations?.other?.length)

  const rabiesVaccinations = useSelector(getRabiesVaccinations(patientId))
  const isRabiesVaccinationPossible = Boolean(rabiesVaccinations?.length)

  const [openVaccineHistoryDialog] = useDialog(DialogNames.VACCINE_HISTORY)
  const [openDiagnosisHistoryDialog] = useDialog(DialogNames.DIAGNOSIS_HISTORY)
  const [openNoteHistoryDialog] = useDialog(DialogNames.NOTE_HISTORY)
  const [openAttachmentHistoryDialog] = useDialog(
    DialogNames.MULTI_ATTACHMENT_HISTORY,
  )
  const [openMedicalHistoryDialog] = useDialog(
    DialogNames.SHARE_MEDICAL_HISTORY,
  )
  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const [openPrintCageLabelDialog] = useDialog(DialogNames.PRINT_CAGE_LABEL)
  const [openPrintFolderLabelDialog] = useDialog(DialogNames.PRINT_FOLDER_LABEL)
  const [openPrintPracticeAddressLabelDialog] = useDialog(
    DialogNames.PRINT_PRACTICE_ADDRESS_LABEL,
  )
  const [openReminderDialog] = useDialog(DialogNames.ADD_REMINDER)
  const [openTaskDialog] = useDialog(DialogNames.TASK)
  const [openNewConversationDialog] = useDialog(
    DialogNames.NEW_CONVERSATION_WITH_STEPS,
  )
  const [openVitalsDialog] = useDialog(DialogNames.VITALS)
  const [openProofOfVaccinationDialog] = useDialog(
    DialogNames.PROOF_OF_VACCINATION,
  )
  const [openRabiesVaccinationsDialog] = useDialog(
    DialogNames.RABIES_VACCINATIONS,
  )
  const [openPatientDialog] = useDialog(DialogNames.PATIENT)
  const [openCoPetParentDialog] = useDialog(DialogNames.CO_PET_PARENT_DIALOG)
  const [openPetFriendDialog] = useDialog(DialogNames.PET_FRIEND)

  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)

  const isCurrentBusinessOmniChannel = useSelector(
    getCurrentBusinessIsOmniChannel,
  )
  const isSuppressAddClientsAndPatientsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SUPPRESS_ADD_CLIENTS_AND_PATIENTS),
  )

  const showAddClient = !(
    isCurrentBusinessOmniChannel && isSuppressAddClientsAndPatientsEnabled
  )

  useEffect(() => {
    if (patientId) {
      dispatch(fetchVaccinations(patientId))
      dispatch(fetchRabiesVaccinations(patientId))
    }
  }, [patientId])

  if (!client || !patient) {
    return null
  }

  const handleOpenAppointmentDialog = () => {
    navigate('/scheduler', { state: { clientId, patientId } })
  }

  const handleOtc = () =>
    openInvoice({ create: true, isOtcInvoice: true, clientId, patientId })

  const handleAddPatient = () => {
    if (isXs) {
      navigate(`/client/${clientId}/patient/new`)
    } else {
      openPatientDialog({ clientId, createPatient })
    }
  }

  const hasEmergencyContact = Boolean(client.emergencyContact)

  const handleAddPetFriend = () => {
    if (hasEmergencyContact) {
      if (isXs) {
        navigate(`/client/${clientId}/coparent/new`)
      } else {
        openCoPetParentDialog({ clientId })
      }
    } else if (isXs) {
      navigate(`/client/${clientId}/petfriend`)
    } else {
      openPetFriendDialog({ clientId })
    }
  }

  const onBackToListClicked = () => {
    if (locationState?.searchQuery) {
      navigate(`/clients?query=${locationState.searchQuery}`)
    } else {
      navigate('/clients')
    }
  }

  const SHARE_MENU_ITEMS = [
    createMenuItem(
      t('Common:MEDICAL_HISTORY'),
      ShareMenuItemType.MEDICAL_HISTORY,
      soapCreatePermissions,
    ),
    createMenuItem(
      t('Common:PROOF_OF_VACCINATION'),
      ShareMenuItemType.PROOF_OF_VACCINATION,
      soapUpdatePermissions && isProofOfVaccinationPossible,
    ),
    createMenuItem(
      t('Common:RABIES_CERTIFICATE'),
      ShareMenuItemType.RABIES_CERTIFICATE,
      soapUpdatePermissions && isRabiesVaccinationPossible,
    ),
  ]

  const ShareMenuActionsMap = {
    [ShareMenuItemType.PROOF_OF_VACCINATION]: () => {
      openProofOfVaccinationDialog({
        clientId,
        patientId,
        vaccinations,
        selectAll: true,
      })
    },
    [ShareMenuItemType.RABIES_CERTIFICATE]: () => {
      openRabiesVaccinationsDialog({
        clientId,
        patientId,
        vaccinations: rabiesVaccinations,
      })
    },
    [ShareMenuItemType.MEDICAL_HISTORY]: () => {
      openMedicalHistoryDialog({ patientId, clientId })
    },
  }

  const NEW_MENU_ITEMS = [
    createMenuItem(
      `${t('Common:APPOINTMENT_ONE')}/${t('Common:SOAP_METHOD')}`,
      NewMenuItemType.APPOINTMENT_SOAP,
      appointmentCreatePermission,
    ),
    createMenuItem(
      t('Common:ATTACHMENT'),
      NewMenuItemType.ATTACHMENT,
      patientUpdatePermissions,
    ),
    createMenuItem(
      t('Common:CONVERSATION'),
      NewMenuItemType.CONVERSATION,
      communicationCreatePermission,
    ),
    createMenuItem(
      t('Common:DIAGNOSIS_HISTORY'),
      NewMenuItemType.DIAGNOSIS_HISTORY,
      patientUpdatePermissions,
    ),
    createMenuItem(
      t('Common:ESTIMATE_BUDGET'),
      NewMenuItemType.ESTIMATE,
      invoiceCreatePermission,
    ),
    createMenuItem(
      t('Common:NOTE'),
      NewMenuItemType.NOTE,
      patientUpdatePermissions,
    ),
    createMenuItem(
      `${t('Abbreviations:ACRONYMS.OVER_THE_COUNTER.LABEL_ABBREVIATION')}/${t(
        'Common:REFILL_ONE',
      )}`,
      NewMenuItemType.OTC_REFILL,
      invoiceCreatePermission,
    ),
    ...(showAddClient
      ? [
          createMenuItem(
            t('Common:PATIENT'),
            NewMenuItemType.PATIENT,
            patientCreatePermissions,
          ),
        ]
      : []),
    createMenuItem(
      t('Common:PET_FRIEND'),
      NewMenuItemType.PET_FRIEND,
      patientUpdatePermissions,
    ),
    createMenuItem(
      t('Common:REMINDER'),
      NewMenuItemType.REMINDER,
      reminderCreatePermission,
    ),
    createMenuItem(
      t('Common:TASK'),
      NewMenuItemType.TASK,
      taskCreatePermission,
    ),
    createMenuItem(
      t('Common:VACCINE_HISTORY'),
      NewMenuItemType.VACCINE_HISTORY,
      patientUpdatePermissions,
    ),
    createMenuItem(
      t('Common:VITALS'),
      NewMenuItemType.VITALS,
      vitalsUpdatePermissions,
    ),
  ]

  const NewMenuActionsMap = {
    [NewMenuItemType.APPOINTMENT_SOAP]: () => handleOpenAppointmentDialog(),
    [NewMenuItemType.ATTACHMENT]: () =>
      openAttachmentHistoryDialog({ clientId, patientId }),
    [NewMenuItemType.CONVERSATION]: () =>
      openNewConversationDialog({ clientId, patientId }),
    [NewMenuItemType.DIAGNOSIS_HISTORY]: () =>
      openDiagnosisHistoryDialog({ clientId, patientId }),
    [NewMenuItemType.ESTIMATE]: () =>
      openInvoice({ create: true, isEstimate: true, clientId, patientId }),
    [NewMenuItemType.NOTE]: () =>
      openNoteHistoryDialog({ clientId, patientId }),
    [NewMenuItemType.OTC_REFILL]: () => handleOtc(),
    [NewMenuItemType.PATIENT]: () => handleAddPatient(),
    [NewMenuItemType.PET_FRIEND]: () => handleAddPetFriend(),
    [NewMenuItemType.REMINDER]: () => openReminderDialog({ patientId }),
    [NewMenuItemType.TASK]: () => openTaskDialog({ clientId, patientId }),
    [NewMenuItemType.VACCINE_HISTORY]: () =>
      openVaccineHistoryDialog({ clientId, patientId }),
    [NewMenuItemType.VITALS]: () => openVitalsDialog({ clientId, patientId }),
  }

  const LabelMenuActionsMap = {
    [LabelMenuOptions.CAGE]: () =>
      openPrintCageLabelDialog({ clientId, patientId }),
    [LabelMenuOptions.FOLDER]: () =>
      openPrintFolderLabelDialog({ clientId, patientId }),
    [LabelMenuOptions.PRACTICE_ADDRESS]: () =>
      openPrintPracticeAddressLabelDialog(),
  }

  const DeceasedTag = (
    <span className={classes.deceasedTag}>
      <WingsIcon className={classes.wingsIcon} />
      {t('Common:DECEASED')}
    </span>
  )

  const patientItems = patientsList.map((otherPatient) => ({
    name: `${otherPatient.name || ''}${
      otherPatient.id === patientId ? ` (${t('Common:CURRENTLY_VIEWING')})` : ''
    }`,
    disabled: otherPatient.id === patientId,
    value: otherPatient.id,
    tags: [
      !otherPatient.active && t('Common:INACTIVE_ONE'),
      otherPatient.deceased && DeceasedTag,
    ].filter(Boolean),
  }))

  const addPatientMenuItems = showAddClient
    ? [
        {
          name: t('Common:ADD_NEW_PATIENT'),
          value: NewMenuItemType.PATIENT,
          classes: { root: classes.addNewPatientItem },
          disabled: !patientCreatePermissions,
        },
      ]
    : []

  const patientsMenuItems = [...addPatientMenuItems, ...patientItems]

  const handlePatientChange = (newPatientId: string) => {
    if (newPatientId === NewMenuItemType.PATIENT) {
      handleAddPatient()
    } else {
      navigate(`/client/${clientId}/patient/${newPatientId}`)
    }
  }

  return (
    <TopMenu classes={{ root: classes.root }}>
      <Grid item md="auto" sm={12}>
        <DetailsBackButton
          fullWidth
          thin
          className={classes.backButton}
          onClick={onBackToListClicked}
        >
          {t('Clients:CLIENTS_AND_PATIENTS_TOP_MENU.DETAILS_BACK')}
        </DetailsBackButton>
      </Grid>
      <TopMenuButtonsSection className={classes.menuButton}>
        <MenuDropdown
          linkButton
          items={NEW_MENU_ITEMS}
          title={t('Common:ADD_ACTION')}
          onSelected={onSelectMenuItem(NewMenuActionsMap)}
        />

        <ClientAndPatientLabelMenuDropDown
          linkButton
          title={t('Common:LABEL')}
          onChange={onSelectMenuItem(LabelMenuActionsMap)}
        />

        {appointmentCreatePermission && (
          <Button onClick={handleOpenAppointmentDialog}>
            {t('Common:SCHEDULE_ACTION')}
          </Button>
        )}

        {invoiceCreatePermission && (
          <Button onClick={handleOtc}>
            {t('Abbreviations:ACRONYMS.OVER_THE_COUNTER.LABEL_ABBREVIATION')}
          </Button>
        )}

        <MenuDropdown
          linkButton
          items={SHARE_MENU_ITEMS}
          title={t('Common:SHARE')}
          onSelected={onSelectMenuItem(ShareMenuActionsMap)}
        />

        <MenuDropdown
          linkButton
          items={patientsMenuItems}
          title={t('Common:PATIENTS')}
          onSelected={handlePatientChange}
        />
      </TopMenuButtonsSection>
    </TopMenu>
  )
}

export default ClientsAndPatientsTopMenu
