import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Business,
  HtmlNotesPreview,
  Nil,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import MobilePhoneChatPreview from '../preview/MobilePhoneChatPreview'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    previewRoot: {
      margin: theme.spacing(-1, 2, 0, -2),
    },
    content: {
      backgroundColor: '#F9F0E5',
    },
  }),
  { name: 'AutomaticCommunicationBoopMessagePreview' },
)

export interface AutomaticCommunicationBoopMessagePreviewProps {
  business: Business
  textTemplate: string | Nil
}

const AutomaticCommunicationBoopMessagePreview = ({
  textTemplate,
  business,
}: AutomaticCommunicationBoopMessagePreviewProps) => {
  const classes = useStyles()

  const { t } = useTranslation('Common')

  return (
    <Grid container item className={classes.root} direction="column">
      <Text strong variant="subheading3">
        {t('Common:BOOP_SYSTEM_NAME_MESSAGE')}
      </Text>
      <MobilePhoneChatPreview sender={business.name}>
        {textTemplate && (
          <HtmlNotesPreview fontSize="1.2rem" notes={textTemplate} />
        )}
      </MobilePhoneChatPreview>
    </Grid>
  )
}

export default AutomaticCommunicationBoopMessagePreview
