import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { LanguageUtils, PuiTheme, QuestionAnswer } from '@pbt/pbt-ui-components'

import DragAndDropTable, {
  DragAndDropTableProps,
} from '~/components/common/lists/DragAndDropTable'
import { getAnswerInputTypes } from '~/store/reducers/constants'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      paddingLeft: theme.spacing(4.5),
    },
    tableCell: {
      height: 54,
    },
    tableTitle: {
      height: 38,
    },
  }),
  { name: 'AnswersTable' },
)

const getId = (answer: QuestionAnswer) => answer.id || answer.text

export interface AnswersTableProps
  extends Omit<DragAndDropTableProps<QuestionAnswer>, 'children'> {
  answers: QuestionAnswer[]
}

const AnswersTable = ({ answers, ...rest }: AnswersTableProps) => {
  const classes = useStyles()
  const AnswerInputTypes = useSelector(getAnswerInputTypes)
  const { t } = useTranslation('Common')

  const headerRow = [{ label: t('Common:INCLUDE_NOUN') }]

  const mainColumns = [
    {
      label: t('Common:ANSWER_ONE'),
      prop: 'text' as keyof QuestionAnswer,
    },
  ]

  return (
    <DragAndDropTable
      useIndexAsId
      classes={classes}
      data={answers}
      getId={getId}
      headerRow={headerRow}
      mainColumns={mainColumns}
      {...rest}
    >
      {({ item: answer, tableCellClassName }) => (
        <TableCell
          className={classNames(classes.tableCell, tableCellClassName)}
        >
          {answer.inputs.length > 0
            ? answer.inputs
                .map((input) =>
                  LanguageUtils.getConstantTranslatedName(
                    input.inputTypeId,
                    AnswerInputTypes,
                    '',
                  ).toLowerCase(),
                )
                .join(', ')
            : '-'}
        </TableCell>
      )}
    </DragAndDropTable>
  )
}

export default AnswersTable
