import React from 'react'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    button: {
      height: 32,
      width: 29,
      backgroundColor: theme.colors.tabSelected,
      cursor: 'pointer',
    },
    leftButton: {
      marginRight: -3,
      borderTopLeftRadius: theme.spacing(0.5),
      borderBottomLeftRadius: theme.spacing(0.5),
    },
    rightButton: {
      marginLeft: -3,
      borderTopRightRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
    },
    disabled: {
      backgroundColor: theme.colors.selectedOption,
      cursor: 'unset',
    },
    chevronIcon: {
      color: theme.colors.tableBackground,
      width: 24,
      height: 24,
    },
  }),
  { name: 'StepperNavigationButton' },
)

export interface StepperNavigationButtonProps {
  disabled: boolean
  onClick: () => void
  right?: boolean
}

const StepperNavigationButton = ({
  right = false,
  disabled = false,
  onClick,
}: StepperNavigationButtonProps) => {
  const classes = useStyles()
  const Chevron = right ? ChevronRight : ChevronLeft
  return (
    <Grid
      container
      alignItems="center"
      className={classNames(classes.button, {
        [classes.rightButton]: right,
        [classes.leftButton]: !right,
        [classes.disabled]: disabled,
      })}
      justifyContent="center"
      onClick={onClick}
    >
      <Chevron className={classes.chevronIcon} />
    </Grid>
  )
}

export default StepperNavigationButton
