import React, { forwardRef, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, PuiTheme } from '@pbt/pbt-ui-components'
import { UnitTypes, WeightTypes } from '@pbt/pbt-ui-components/src/localization'

import { VITALS_LAYOUT_CONSTANTS } from '~/constants/vitals'
import { getUnitsState } from '~/store/duck/settings'
import { getConstantMap } from '~/store/reducers/constants'
import { Vital } from '~/types'

import { vitalsTableConfig } from '../../soapV2/vitals/config/vitalsTableConfig'
import VitalsDataTableCell from './VitalsDataTableCell'
// @ts-ignore
import { getTableData } from './vitalUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tableOverflowContent: {
      scrollbarWidth: 'none', // firefox
      '&::-webkit-scrollbar': {
        // webkit
        display: 'none',
      },
    },
    table: {},
    tableRow: {
      height: theme.spacing(7),
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
    expandedRow: {
      height: theme.spacing(13),
    },
    cell: {
      position: 'relative',
      border: 'none',
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
      padding: theme.spacing(2, 2, 2, 4),
      verticalAlign: 'top',
      '&:last-child': {
        paddingRight: `calc(100vw + ${theme.spacing(2)})`,
      },
    },
    cellV2: {
      position: 'relative',
      border: 'none',
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
      verticalAlign: 'top',
    },
    infoIcon: {
      cursor: 'pointer',
      position: 'absolute',
      left: 130,
      top: 0,
    },
    cellText: {
      whiteSpace: 'nowrap',
    },
    dataItem: {
      // 32px cell padding,
      width: VITALS_LAYOUT_CONSTANTS.SCROLLABLE_ITEM_WIDTH - 32,
      padding: theme.spacing(0, 2.5, 0, 1.5),
    },
  }),
  { name: 'VitalsDataTable' },
)

export interface VitalsDataTableProps {
  classes?: ClassesType<typeof useStyles>
  vitals: Vital[]
}

const VitalsDataTable = forwardRef<HTMLDivElement, VitalsDataTableProps>(
  function VitalsDataTable({ vitals, classes: classesProp }, ref) {
    const { t } = useTranslation('Errors')
    const classes = useStyles({ classes: classesProp })
    const constantMap = useSelector(getConstantMap)
    const unitsState = useSelector(getUnitsState)

    const tableData = getTableData({
      config: vitalsTableConfig,
      vitals,
      unitsState,
      constantMap,
    })

    return (
      <Grid
        className={classes.tableOverflowContent}
        id="vitals-data-container"
        overflow="hidden"
        ref={ref}
      >
        <Table className={classes.table}>
          <TableBody id="vitals-data-container-table-body">
            {vitalsTableConfig.map((configItem) => {
              const areLbsUnits =
                configItem.id === UnitTypes.WEIGHT &&
                unitsState.weight === WeightTypes.LBS
              const isExpanded = areLbsUnits
              const tableRowId = `vitals-data-container-table-body-row-${configItem.id}`

              return (
                <TableRow
                  className={classNames(classes.tableRow, {
                    [classes.expandedRow]: isExpanded,
                  })}
                  id={tableRowId}
                  key={tableRowId}
                >
                  {tableData[configItem.id].map(
                    (
                      { value = '—', notes = undefined, finalized = false },
                      index: number,
                    ) => (
                      <Fragment
                        key={`${vitals[index].id}-${configItem.id}-cell`}
                      >
                        {finalized ? (
                          <Tooltip
                            placement="top-start"
                            title={t('Errors:API_ERROR.SOAP_IS_LOCKED')}
                          >
                            <TableCell
                              align="left"
                              className={classes.cellV2}
                              id={`vitals-data-container-table-body-row-cell-${index}`}
                              key={`${vitals[index].id}-${configItem.id}-cell`}
                            >
                              <VitalsDataTableCell
                                id={`${vitals[index].id}-${configItem.id}`}
                                notes={notes}
                                value={value}
                              />
                            </TableCell>
                          </Tooltip>
                        ) : (
                          <TableCell
                            align="left"
                            className={classes.cell}
                            id={`vitals-data-container-table-body-row-cell-${index}`}
                            key={`${vitals[index].id}-${configItem.id}-cell`}
                          >
                            <VitalsDataTableCell
                              id={`${vitals[index].id}-${configItem.id}`}
                              notes={notes}
                              value={value}
                            />
                          </TableCell>
                        )}
                      </Fragment>
                    ),
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Grid>
    )
  },
)

export default VitalsDataTable
