import React, { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  PermissionArea,
  PuiTheme,
  useFields,
  UserPermissions,
} from '@pbt/pbt-ui-components'

import Link from '~/components/common/link/Link'
import PuiSwitch from '~/components/common/PuiSwitch'
import {
  editWellnessPlansSettings,
  fetchWellnessPlansSettings,
} from '~/store/actions/wellnessPlans'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getWellnessPlansSettings,
  getWellnessPlansSettingsIsLoading,
} from '~/store/reducers/wellnessPlans'
import {
  BasePracticeDetailsSectionProps,
  PracticeWellnessPlanActivationFields,
  WellnessPlanSettings,
} from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'
import WellnessPlanConfiguration from './WellnessPlanConfiguration'
import WellnessPlanPromo from './WellnessPlanPromo'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      zIndex: theme.zIndex.base,
      position: 'relative',
    },
    switch: {
      marginLeft: theme.spacing(1),
    },
    link: {
      fontSize: '1.4rem',
      display: 'flex',
    },
  }),
  { name: 'WellnessPlanActivation' },
)

const WellnessPlanActivation = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Businesses')

  const permissions: UserPermissions = useSelector(
    getCRUDByArea(PermissionArea.SUPPORT),
  )
  const settings: WellnessPlanSettings = useSelector(
    getWellnessPlansSettings(business.id),
  )
  const settingsIsLoading = useSelector(getWellnessPlansSettingsIsLoading)

  const { fields, validate, reset } = useFields([
    {
      name: 'enabled',
      type: 'toggle',
      initialValue: settings?.enabled || false,
    },
  ])

  const { enabled } = fields

  const onSave = () => {
    const newSettings = {
      ...R.omit(['basePriceType'], settings),
      enabled: enabled.value,
    }
    dispatch(editWellnessPlansSettings(business.id, newSettings))
  }

  usePracticeFieldsSection<PracticeWellnessPlanActivationFields>({
    business,
    fields,
    sectionName: PracticeDetailsPanels.WELLNESS_PLANS,
    onSave,
    validate,
    reset,
    resetDependencies: [business, settings],
    softUpdate: enabled.value !== enabled.initialValue,
  })

  useEffect(() => {
    if (business.id && !settings && !settingsIsLoading) {
      dispatch(fetchWellnessPlansSettings(business.id))
    }
  }, [business.id, settings])

  return (
    <Grid container item className={classes.root} direction="column">
      {permissions.update && (
        <PuiSwitch
          classes={{
            root: classes.switch,
          }}
          field={enabled}
          label={
            <>
              {t('Businesses:WELLNESS_PLANS.TURN_ON_WELLNESS_PLANS')}
              {enabled.value && (
                <Link
                  className={classes.link}
                  to="/admin/catalog/wellness-plans"
                >
                  {t('Businesses:WELLNESS_PLANS.GO_TO_WELLNESS_PLAN_SET_UP')}
                </Link>
              )}
            </>
          }
        />
      )}
      {enabled.value ? (
        <WellnessPlanConfiguration business={business} />
      ) : (
        <WellnessPlanPromo />
      )}
    </Grid>
  )
}

export default memo(WellnessPlanActivation, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
