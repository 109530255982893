import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { findIndex, last, propEq, remove } from 'ramda'
import { AddButton, Nil, PuiTheme } from '@pbt/pbt-ui-components'

import { DataHandle, NoteHistoryItem } from '~/types'

import NoteHistory, { NoteHistoryHandle } from './NoteHistory'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    noteHistory: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'NoteHistories' },
)

export interface NoteHistoriesProps {
  clientId: string | Nil
  note: NoteHistoryItem | Nil
  patientId: string | Nil
}

export interface NoteHistoriesHandle extends DataHandle<NoteHistoryItem[]> {}

const NoteHistories = forwardRef<NoteHistoriesHandle, NoteHistoriesProps>(
  function NoteHistories(
    { note, clientId, patientId }: NoteHistoriesProps,
    ref,
  ) {
    const classes = useStyles()
    const { t } = useTranslation(['Common', 'MedicalHistory'])

    const [noteRefs, setNoteRefs] = useState<
      { id: number; ref: React.RefObject<NoteHistoryHandle> }[]
    >([
      {
        id: 0,
        ref: React.createRef<NoteHistoryHandle>(),
      },
    ])

    const addAnotherNote = () => {
      setNoteRefs([
        ...noteRefs,
        { id: (last(noteRefs)?.id || 0) + 1, ref: React.createRef() },
      ])
    }

    const removeNote = (id: number) => {
      const index = findIndex(propEq('id', id))(noteRefs)
      setNoteRefs(remove(index, 1, noteRefs))
    }

    const validate = () =>
      noteRefs.every((noteRef) => noteRef.ref.current?.validate())

    useImperativeHandle(ref, () => ({
      validate,
      get: () =>
        noteRefs.map(
          (noteRef) => noteRef.ref.current?.get() as NoteHistoryItem,
        ),
    }))

    const hasSingleNote = noteRefs.length === 1

    return (
      <Grid container item direction="column">
        {noteRefs.map(({ id, ref: noteRef }) => (
          <NoteHistory
            className={classes.noteHistory}
            clientId={clientId}
            key={id}
            note={note}
            patientId={patientId}
            ref={noteRef}
            onDelete={hasSingleNote ? undefined : () => removeNote(id)}
          />
        ))}
        {!note && (
          <Grid item>
            <AddButton
              addText={t('MedicalHistory:NOTE_HISTORY.ADD_ANOTHER_NOTE')}
              onAdd={addAnotherNote}
            />
          </Grid>
        )}
      </Grid>
    )
  },
)

export default NoteHistories
