import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme } from '@pbt/pbt-ui-components'

import { InvoiceLineItem } from '~/types'
import { useGetPriceUnitsDisplayName } from '~/utils/priceUtils'

import { formatMoneyRange } from '../../invoiceUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    amountCell: {
      borderRight: 'none !important',
      minWidth: 85,
    },
    declinedCell: {
      color: theme.colors.tabLabel,
    },
    groupedCell: {
      borderBottom: 'none',
    },
  }),
  { name: 'UnitPriceCellBody' },
)

export interface UnitPriceCellBodyProps {
  className?: string
  isDeclined: boolean
  isEstimate: boolean
  isGrouped: boolean
  item: InvoiceLineItem
  showPrice?: boolean
}

const UnitPriceCellBody = ({
  className,
  item,
  isEstimate,
  isGrouped,
  isDeclined,
  showPrice = true,
}: UnitPriceCellBodyProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const priceUnitsDisplayName = useGetPriceUnitsDisplayName(item.priceUnits)

  if (isEstimate) {
    return null
  }

  if (isGrouped) {
    return (
      <>
        <TableCell className={classes.groupedCell} />
        <TableCell className={classNames(className, classes.groupedCell)} />
      </>
    )
  }

  return (
    <>
      {showPrice && (
        <TableCell
          className={classNames(className, classes.amountCell, {
            [classes.declinedCell]: isDeclined,
          })}
        >
          {isDeclined
            ? `(${t('Common:DECLINED')})`
            : formatMoneyRange(item.price as number)}
        </TableCell>
      )}
      <TableCell className={className}>
        {!isDeclined && priceUnitsDisplayName}
      </TableCell>
    </>
  )
}

export default UnitPriceCellBody
