import React, { useState } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  PuiDialog,
  PuiTheme,
  User,
} from '@pbt/pbt-ui-components'

import { SoapFile } from '~/types'

import AttachmentBody from './AttachmentBody'
import AttachmentHeader from './AttachmentHeader'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    dialogContainer: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    paper: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 'calc(100% - 64px)',
    },
    image: {
      maxWidth: '100%',
    },
    contentContainer: {
      padding: theme.spacing(6, 2, 2, 2),
    },
    figure: {
      margin: 0,
    },
  }),
  { name: 'AttachmentLightbox' },
)

export interface AttachmentLightboxProps extends BasePuiDialogProps {
  attachment: SoapFile
  author: User
}

const AttachmentLightbox = ({
  open,
  onClose,
  attachment,
  author,
}: AttachmentLightboxProps) => {
  const classes = useStyles()
  const [imgWidth, setImgWidth] = useState(0)

  const onImageLoad = (event: React.SyntheticEvent) => {
    setImgWidth((event.target as HTMLElement).offsetWidth)
  }

  return (
    <PuiDialog
      aria-labelledby="attachment-lightbox-dialog"
      classes={{
        paper: classes.paper,
        container: classes.dialogContainer,
      }}
      open={open}
      onClose={onClose}
    >
      <Grid container direction="column">
        <Grid
          container
          item
          alignItems="center"
          className={classes.contentContainer}
          direction="column"
        >
          <Grid item>
            <figure className={classes.figure}>
              <img
                alt=""
                className={classes.image}
                sizes="100vw"
                src={attachment.fileUrl}
                onLoad={onImageLoad}
              />
            </figure>
          </Grid>
          <AttachmentHeader expanded attachment={attachment} author={author} />
          <Grid
            container
            item
            direction="column"
            style={{
              width: imgWidth,
            }}
          >
            <AttachmentBody attachment={attachment} />
          </Grid>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default AttachmentLightbox
