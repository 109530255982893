import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  PuiDialog,
  PuiTheme,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import EnumSelect from '~/components/common/inputs/EnumSelect'
import { ShipmentItemStatus } from '~/constants/shipments'
import {
  createShipmentItem,
  editShipmentItem,
  getShipment,
  getShipmentsIsLoading,
  getShipmentsIsUpdating,
} from '~/store/duck/shipments'
import { getInventoryShipmentItemStatus } from '~/store/reducers/constants'
import { ShipmentItem as ShipmentItemType } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import ShipmentItem, { ShipmentItemHandle } from './ShipmentItem'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    dialogContentRoot: {
      padding: theme.spacing(2, 3, 6, 3),
    },
    button: {
      minWidth: 115,
    },
  }),
  { name: 'ShipmentItemDialog' },
)

interface ShipmentItemDialogProps extends BasePuiDialogProps {
  shipmentId: string | Nil
  shipmentItem: ShipmentItemType | Nil
  title: string | Nil
}

const ShipmentItemDialog = ({
  open,
  onClose,
  title,
  shipmentId,
  shipmentItem,
}: ShipmentItemDialogProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const shipmentItemRef = useRef<ShipmentItemHandle>(null)

  const shipment = useSelector(getShipment(shipmentId))
  const InventoryShipmentItemStatus = useSelector(
    getInventoryShipmentItemStatus,
  )
  const isLoading = useSelector(getShipmentsIsLoading)
  const isUpdating = useSelector(getShipmentsIsUpdating)

  const closeAfterSave = useCloseAfterCreation(
    onClose,
    shipmentItem?.id ? getShipmentsIsUpdating : getShipmentsIsLoading,
  )
  const DraftStatusId = Utils.findConstantIdByName(
    ShipmentItemStatus.DRAFT,
    InventoryShipmentItemStatus,
  )

  const dirtyShipmentTotalTax = Boolean(shipment?.customTotalTax)
  const dirtyShipmentItemsTaxes = shipment?.items?.some((item) =>
    Number(item.tax),
  )

  const {
    fields: { status },
    validate,
  } = useFields([
    {
      name: 'status',
      label: t('Common:STATUS'),
      validators: ['required'],
      initialValue: shipmentItem?.statusId || DraftStatusId,
    },
  ])

  const handleSave = () => {
    if (shipmentItemRef.current?.validate() && validate()) {
      const newShipmentItem = {
        ...shipmentItemRef.current?.get(),
        statusId: status.value,
      }

      closeAfterSave()
      if (shipmentId) {
        if (shipmentItem?.id) {
          dispatch(editShipmentItem(shipmentId, newShipmentItem))
        } else {
          dispatch(createShipmentItem(shipmentId, newShipmentItem))
        }
      }
    }
  }

  return (
    <PuiDialog
      confirmSaveOnClose
      fullWidth
      ConfirmCloseDialogProps={{
        onOk: handleSave,
      }}
      actions={
        <ButtonWithLoader
          className={classes.button}
          disabled={isUpdating || isLoading}
          loading={isUpdating || isLoading}
          type="submit"
          onClick={handleSave}
        >
          {t('Common:SAVE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="shipment-item-dialog"
      classes={{
        dialogContentRoot: classes.dialogContentRoot,
      }}
      hasUnsavedChanges={() =>
        Boolean(shipmentItemRef.current?.hasUnsavedChanges())
      }
      header={
        <Stack alignItems="center" direction="row" flexWrap="wrap" spacing={2}>
          <Text variant="h2">{title || t('Common:ADD_SHIPMENT_ITEM')}</Text>
          <EnumSelect
            accent
            Constant={InventoryShipmentItemStatus}
            field={status}
          />
        </Stack>
      }
      maxWidth="lg"
      open={open}
      scroll="paper"
      onClose={onClose}
    >
      <ShipmentItem
        ref={shipmentItemRef}
        shipmentItem={shipmentItem}
        taxDisabled={dirtyShipmentTotalTax && !dirtyShipmentItemsTaxes}
      />
    </PuiDialog>
  )
}

export default ShipmentItemDialog
