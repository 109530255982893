import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme } from '@pbt/pbt-ui-components'

import MarketplaceItemIframe from '~/components/common/marketplaceIframes/MarkeplaceItemIframe'
import { clearTasksValidationError } from '~/store/actions/tasks'
import { fetchTimeline, resetTimeline } from '~/store/actions/timeline'
import { getExpandedIFrame } from '~/store/duck/marketplace'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getTasksValidationError } from '~/store/reducers/tasks'
import { getUser } from '~/store/reducers/users'
import useErrorAlert from '~/utils/useErrorAlert'

import ClientsAndPatientsTopMenu from '../../header/top-menu/ClientsAndPatientsTopMenu'
import NewPatient from '../new-client/NewPatient'
import SnapshotsAndRecordsComponent from '../snapshots-and-records/SnapshotsAndRecordsComponent'
import ClientAndPatientSection from './new-client-and-patient/ClientAndPatientSection'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    clientAndPatientColumn: {
      width: 'auto',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
    },
    topMenu: {
      padding: theme.spacing(0, 2),
    },
    iframeContainer: {
      height: `calc(100vh - ${
        theme.mixins.toolbar.minHeight + theme.constants.progressBarHeight
      }px)`,
    },
    hidden: {
      display: 'none',
    },
  }),
  { name: 'ClientAndPatientDetailsPage' },
)

interface ClientAndPatientDetailsPageProps {
  nopet?: boolean
}

const ClientAndPatientDetailsPage = ({
  nopet,
}: ClientAndPatientDetailsPageProps) => {
  const { clientId = '', patientId = '' } = useParams()
  const classes = useStyles()
  const dispatch = useDispatch()

  const client = useSelector(getUser(clientId))
  const iFrame = useSelector(getExpandedIFrame)
  const businessId = useSelector(getCurrentBusinessId)

  useErrorAlert({
    errorSelector: getTasksValidationError,
    onOpenAction: fetchTimeline(),
    onCloseAction: clearTasksValidationError(),
  })

  useEffect(
    () => () => {
      // reset timeline store state on unmount
      dispatch(resetTimeline())
    },
    [],
  )

  return (
    <Grid container className={classes.root}>
      <Grid
        container
        className={classes.clientAndPatientColumn}
        direction="column"
      >
        <ClientAndPatientSection clientId={clientId} patientId={patientId} />
      </Grid>
      <Grid container direction="column" flex={1}>
        {iFrame && (
          <Grid
            container
            item
            className={classes.iframeContainer}
            wrap="nowrap"
          >
            <MarketplaceItemIframe
              id={iFrame.id}
              params={{
                businessId,
                patientId,
                clientId,
              }}
              title={iFrame.name}
              url={iFrame.expandedUrl}
            />
          </Grid>
        )}
        <Grid
          container
          className={classNames({ [classes.hidden]: Boolean(iFrame) })}
          direction="column"
        >
          <ClientsAndPatientsTopMenu classes={{ root: classes.topMenu }} />
          <Grid container item alignItems="center" wrap="nowrap">
            {!nopet && (!client?.patients || client?.patients.length > 0) ? (
              <SnapshotsAndRecordsComponent
                clientId={clientId}
                patientId={patientId}
              />
            ) : (
              <NewPatient clientId={clientId} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ClientAndPatientDetailsPage
