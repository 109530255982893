import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { LanguageUtils, PuiTheme, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    pathContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    text: {
      minWidth: theme.spacing(7),
      textAlign: 'left',
      wordBreak: 'break-all',
      justifyContent: 'flex-start',
      fontWeight: 'normal',
      textTransform: 'none',
    },
    button: {
      color: theme.colors.markerHighlighted,
    },
    pathDivider: {
      color: theme.colors.markerHighlighted,
    },
  }),
  { name: 'SearchPath' },
)

export type PathItem = {
  id: string
  name: string
}

export interface SearchPathProps {
  onClick?: (index: number) => void
  path: PathItem[]
}

const SearchPath = ({ path, onClick }: SearchPathProps) => {
  const classes = useStyles()

  return (
    <div className={classes.pathContainer}>
      {path.map((pathItem, index) => (
        <React.Fragment key={pathItem.id + pathItem.name}>
          {index > 0 && (
            <Text
              className={classNames({
                [classes.pathDivider]: onClick,
              })}
              variant={onClick ? 'body2' : 'lowAccent2'}
            >
              {'>'}
            </Text>
          )}
          {onClick ? (
            <Button
              className={classNames(classes.button, classes.text)}
              onClick={() => onClick(index)}
            >
              <Dotdotdot clamp={1}>
                {LanguageUtils.getTranslatedFieldName(pathItem)}
              </Dotdotdot>
            </Button>
          ) : (
            <Text
              className={classes.text}
              px={1}
              py={0.75}
              variant="lowAccent2"
            >
              <Dotdotdot clamp={1} tagName="span">
                {LanguageUtils.getTranslatedFieldName(pathItem)}
              </Dotdotdot>
            </Text>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default SearchPath
