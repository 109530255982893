import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Nil, PuiTheme, Text } from '@pbt/pbt-ui-components'

import { DEFAULT_APPOINTMENT_NAME } from '~/constants/schedulerConstants'
import { getTimetableEvent } from '~/store/reducers/timetable'

import { getHourAppointmentRange } from '../timetableUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    content: {
      position: 'relative',
      flex: 1,
    },
    busyText: {
      color: theme.colors.sideText,
    },
  }),
  { name: 'BusyCard' },
)

export interface BusyCardProps {
  appointmentId: string | Nil
  isNotAvailable?: boolean
  isReserved?: boolean
  showHourRange?: boolean
}

const BusyCard = ({
  isNotAvailable,
  isReserved,
  appointmentId,
  showHourRange,
}: BusyCardProps) => {
  const classes = useStyles()
  const appointment = useSelector(getTimetableEvent(appointmentId))

  const { t } = useTranslation('Common')

  const busyText = isNotAvailable
    ? t('Common:UNAVAILABLE')
    : isReserved
    ? t('Common:RESERVED')
    : t('Common:BUSY')

  return (
    <Grid container item className={classes.content} direction="column" p={1}>
      <Text strong className={classes.busyText}>
        {busyText}
      </Text>
      {appointment?.name && appointment.name !== DEFAULT_APPOINTMENT_NAME && (
        <Text className={classes.busyText} mt={0.5}>
          {appointment.name}
        </Text>
      )}
      {showHourRange && appointment && (
        <Text className={classes.busyText} mt={0.5}>
          {getHourAppointmentRange(appointment)}
        </Text>
      )}
    </Grid>
  )
}

export default BusyCard
