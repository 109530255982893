import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import { useIsReminderStateName } from '~/components/dashboard/reminders/reminderUtils'
import { Reminder, ReminderStateName } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    reminderName: {
      lineHeight: '24px',
    },
    resolved: {
      color: theme.colors.tabLabel,
      textDecoration: 'line-through',
    },
  }),
  { name: 'ReminderNameCell' },
)

export interface ReminderNameCellProps {
  isRowSelected?: boolean
  reminder: Reminder
}

const ReminderNameCell = ({
  isRowSelected,
  reminder,
}: ReminderNameCellProps) => {
  const classes = useStyles()

  const isResolved = useIsReminderStateName(
    reminder,
    ReminderStateName.RESOLVED,
  )

  const { name, businessId } = reminder

  return (
    <Grid container item alignItems="center" wrap="nowrap">
      <Dotdotdot clamp={2}>
        <Text
          className={classNames(classes.reminderName, {
            [classes.resolved]: isResolved,
          })}
          variant={isRowSelected ? 'h5' : 'body2'}
        >
          {name}
        </Text>
      </Dotdotdot>
      <BusinessShareIcon businessIds={businessId ? [businessId] : []} />
    </Grid>
  )
}

export default ReminderNameCell
