import { uniq } from 'ramda'
import { actionChannel, all, call, fork, put, take } from 'redux-saga/effects'
import { Defaults } from '@pbt/pbt-ui-components'

import * as API from '~/api/practiceCloning'
import { getErrorMessage } from '~/utils/errors'

import requestAPI from '../sagas/utils/requestAPI'
import createDuck from './duck-generators/createDuck'
import practiceCloningBuilder, {
  INITIAL_STATE,
} from './duck-generators/practiceCloning'

export function* fetchSessionsForBusinessSaga(
  duck,
  { targetBusinessId, from = 0, to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT },
) {
  try {
    const {
      entities: { practiceCloningSession },
      result: { data: sessionsList, totalCount },
    } = yield requestAPI(
      API.fetchExportSessionForBusiness,
      targetBusinessId,
      from,
      to,
    )
    yield put(duck.actions.updateSessionEntities(practiceCloningSession))
    yield put(
      duck.actions.fetchSessionsForBusinessSuccess(sessionsList, totalCount),
    )
  } catch (error) {
    yield put(duck.actions.fetchSessionsForBusinessFailure(error))
  }
}

function* watchFetchSessionsForBusiness(duck) {
  // running request tasks serially not to overload migration service
  yield fork(function* () {
    const requestChan = yield actionChannel(
      duck.types.FETCH_SESSIONS_FOR_BUSINESS,
    )
    while (true) {
      const test = yield take(requestChan)
      yield call(fetchSessionsForBusinessSaga, duck, test)
    }
  })
}

const duckName = 'practiceExport'

const practiceExportDuck = createDuck({
  name: duckName,
  duck: practiceCloningBuilder,
  reducer: (state = INITIAL_STATE, action, duck) => {
    switch (action.type) {
      case duck.types.FETCH_SESSIONS_FOR_BUSINESS:
        return {
          ...state,
          isLoading: true,
          error: null,
        }
      case duck.types.FETCH_SESSIONS_FOR_BUSINESS_SUCCESS:
        return {
          ...state,
          list: uniq([...state.list, ...(action.list || [])]),
          isLoading: false,
          totalCount: action.totalCount,
        }
      case duck.types.FETCH_SESSIONS_FOR_BUSINESS_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: getErrorMessage(action.error),
        }
      default:
        return state
    }
  },
  types: {
    FETCH_SESSIONS_FOR_BUSINESS: 'FETCH_SESSIONS_FOR_BUSINESS',
    FETCH_SESSIONS_FOR_BUSINESS_SUCCESS: 'FETCH_SESSIONS_FOR_BUSINESS_SUCCESS',
    FETCH_SESSIONS_FOR_BUSINESS_FAILURE: 'FETCH_SESSIONS_FOR_BUSINESS_FAILURE',
  },
  actions: (duck) => ({
    fetchSessionsForBusiness: (targetBusinessId, from, to) => ({
      type: duck.types.FETCH_SESSIONS_FOR_BUSINESS,
      targetBusinessId,
      from,
      to,
    }),
    fetchSessionsForBusinessSuccess: (list, totalCount) => ({
      type: duck.types.FETCH_SESSIONS_FOR_BUSINESS_SUCCESS,
      list,
      totalCount,
    }),
    fetchSessionsForBusinessFailure: (error) => ({
      type: duck.types.FETCH_SESSIONS_FOR_BUSINESS_FAILURE,
      error,
    }),
  }),
  saga: (duck) =>
    function* saga() {
      yield all([watchFetchSessionsForBusiness(duck)])
    },
  selectors: {
    getTotalCount: (state) => state[duckName].totalCount,
  },
  apiEndpoints: (types) => ({
    [types.CREATE_SESSION]: API.createExportSession,
    [types.FETCH_SESSION]: API.fetchExportSession,
    [types.FETCH_ACTIVE_SESSION]: API.fetchActiveExportSession,
  }),
})

export const practiceExportReducer = practiceExportDuck.reducer
export const practiceExportSaga = practiceExportDuck.saga
export const practiceExportSelectors = practiceExportDuck.selectors
export const practiceExportActions = practiceExportDuck.actions
