import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  CircularProgressOverlay,
  PuiTextField,
  PuiTheme,
  useFields,
} from '@pbt/pbt-ui-components'

import {
  editImportSettings,
  fetchImportSettings,
} from '~/store/actions/migration'
import {
  getCurrentSessionId,
  getImportSettings,
  getIsLoadingImportSettings,
} from '~/store/reducers/migration'
import { ImportSettings } from '~/types/entities/migration'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    container: {
      position: 'relative',
    },
    button: {
      height: 40,
      padding: theme.spacing(0, 3),
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'CovetrusSetup' },
)

interface CovetrusSetupProps {
  onSaved: () => void
}

const CovetrusSetup = ({ onSaved }: CovetrusSetupProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const sessionId = useSelector(getCurrentSessionId)
  const isLoading = useSelector(getIsLoadingImportSettings)
  const settings = useSelector(getImportSettings)

  const [isSettingsFetched, setIsSettingsFetched] = useState(false)

  const isSettingsFetching = !settings && isLoading
  const isCovetrusConfigured =
    settings?.covetrusUser && settings?.covetrusInstallationId

  const setCloseOnSaveSettings = useCloseAfterCreation(
    onSaved,
    getIsLoadingImportSettings,
  )

  const {
    fields: {
      covetrusUser,
      covetrusPassword,
      covetrusInstallationId,
      covetrusEnabled,
    },
    validate,
    reset,
  } = useFields(
    [
      {
        name: 'covetrusUser',
        label: t('Common:USER'),
        validators: ['required'],
        initialValue: settings?.covetrusUser || '',
      },
      {
        name: 'covetrusPassword',
        label: t('Common:PASSWORD'),
        validators: settings?.covetrusPassword ? [] : ['required'],
        initialValue: '',
      },
      {
        name: 'covetrusInstallationId',
        label: t('Businesses:MIGRATION.INSTALLATION_ID'),
        validators: ['required'],
        initialValue: settings?.covetrusInstallationId || '',
      },
      {
        name: 'covetrusEnabled',
        initialValue: true,
      },
    ],
    false,
  )

  useEffect(() => {
    if (!isSettingsFetched && sessionId) {
      dispatch(fetchImportSettings(sessionId))
      setIsSettingsFetched(true)
    }
  }, [isSettingsFetched, sessionId])

  useEffect(() => {
    if (settings) {
      reset()
    }
  }, [settings])

  const handleSave = () => {
    if (validate()) {
      const updatedSettings = {
        ...(settings as ImportSettings),
        covetrusUser: covetrusUser.value,
        covetrusInstallationId: covetrusInstallationId.value,
        covetrusEnabled: covetrusEnabled.value,
        ...(covetrusPassword.value
          ? { covetrusPassword: covetrusPassword.value }
          : {}),
      }
      dispatch(editImportSettings(sessionId, updatedSettings))
      setCloseOnSaveSettings()
    }
  }

  return (
    <Grid container>
      <Grid container className={classes.container} pb={1} px={3}>
        <CircularProgressOverlay
          open={isSettingsFetching}
          preloaderText={t('Businesses:MIGRATION.LOADING_CONFIG')}
        />
        <Grid item md={6} px={1.5} py={1}>
          <PuiTextField
            autoComplete="off"
            field={covetrusUser}
            inputProps={{ maxLength: 100 }}
            label={covetrusUser.label}
          />
        </Grid>
        <Grid item md={6} px={1.5} py={1}>
          <PuiTextField
            shrink
            autoComplete="off"
            field={covetrusPassword}
            inputProps={{ maxLength: 100 }}
            label={covetrusPassword.label}
            placeholder="∗∗∗∗∗∗∗∗∗∗∗∗∗"
            type="password"
          />
        </Grid>
        <Grid item px={1.5} py={1} xs={12}>
          <PuiTextField
            shrink
            autoComplete="off"
            field={covetrusInstallationId}
            inputProps={{ maxLength: 100 }}
            label={covetrusInstallationId.label}
          />
        </Grid>
      </Grid>
      <Grid item pb={2} pt={1} px={2}>
        <ButtonWithLoader
          className={classes.button}
          color="primary"
          loading={isSettingsFetching || isLoading}
          onClick={handleSave}
        >
          {isCovetrusConfigured
            ? t('Common:UPDATE_ACTION')
            : t('Common:SETUP_ACTION')}
        </ButtonWithLoader>
      </Grid>
    </Grid>
  )
}

export default CovetrusSetup
