import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'

import {
  analyticsReportToPath,
  OmnichannelSupportedAnalyticsReport,
} from '~/constants/analytics'
import i18n from '~/locales/i18n'

import { MenuItem } from '../LeftMenu'

const menuItemsSortFn = ({ text: a }: MenuItem, { text: b }: MenuItem) =>
  a > b ? 1 : -1

export const getMenu = (children: MenuItem[]) => children.sort(menuItemsSortFn)

export const omnichannelAnalyticsDashboardMapping: Record<
  OmnichannelSupportedAnalyticsReport,
  MenuItem
> = {
  [OmnichannelSupportedAnalyticsReport.GROUP_PRICE_LISTS]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.GROUP_PRICE_LISTS'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.GROUP_PRICE_LISTS
    ],
  },
  [OmnichannelSupportedAnalyticsReport.CLIENT_AND_PATIENT_STATUS]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.CLIENT_AND_PATIENT_STATUS'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.CLIENT_AND_PATIENT_STATUS
    ],
  },
  [OmnichannelSupportedAnalyticsReport.CVC_DAILY_RECONCILIATION]: {
    id: uuid(),
    text: i18n.t(
      'Menu:OMNICHANNEL_ANALYTICS.DAILY_RECONCILIATION_SALES_AND_PAYMENTS',
    ),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.CVC_DAILY_RECONCILIATION
    ],
  },
  [OmnichannelSupportedAnalyticsReport.OPEN_AND_UNAPPLIED_BILLING]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.OPEN_AND_UNAPPLIED_BILLING'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.OPEN_AND_UNAPPLIED_BILLING
    ],
  },
  [OmnichannelSupportedAnalyticsReport.BANK_RECONCILIATION]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.BANK_RECONCILIATION'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.BANK_RECONCILIATION
    ],
  },
  [OmnichannelSupportedAnalyticsReport.REMINDERS_COMPLIANCE]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.REMINDERS_COMPLIANCE'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.REMINDERS_COMPLIANCE
    ],
  },
  [OmnichannelSupportedAnalyticsReport.RABIES_VACCINE_HISTORY]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.RABIES_VACCINE_HISTORY'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.RABIES_VACCINE_HISTORY
    ],
  },
  [OmnichannelSupportedAnalyticsReport.PRACTICE_PERFORMANCE]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.PRACTICE_PERFORMANCE'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.PRACTICE_PERFORMANCE
    ],
  },
  [OmnichannelSupportedAnalyticsReport.MEDICAL_INSIGHTS]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.MEDICAL_INSIGHTS'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.MEDICAL_INSIGHTS
    ],
  },
  [OmnichannelSupportedAnalyticsReport.INVENTORY_USAGE]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.INVENTORY_USAGE'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.INVENTORY_USAGE
    ],
  },
  [OmnichannelSupportedAnalyticsReport.FORWARD_BOOKINGS]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.FORWARD_BOOKINGS'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.FORWARD_BOOKINGS
    ],
  },
  [OmnichannelSupportedAnalyticsReport.ACQUISITION_AND_LIFETIME_VALUE]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.ACQUISITION_AND_LIFETIME_VALUE'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.ACQUISITION_AND_LIFETIME_VALUE
    ],
  },
  [OmnichannelSupportedAnalyticsReport.CONTROLLED_SUBSTANCE_LOG]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.CONTROLLED_SUBSTANCE_LOG'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.CONTROLLED_SUBSTANCE_LOG
    ],
  },
  [OmnichannelSupportedAnalyticsReport.GROUP_PERFORMANCE]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.GROUP_PERFORMANCE'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.GROUP_PERFORMANCE
    ],
  },
  [OmnichannelSupportedAnalyticsReport.SALES_SUMMARY_EXCLUDING_UNPAID_OTC]: {
    id: uuid(),
    text: i18n.t(
      'Menu:OMNICHANNEL_ANALYTICS.SALES_SUMMARY_EXCLUDING_UNPAID_OTC',
    ),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.SALES_SUMMARY_EXCLUDING_UNPAID_OTC
    ],
  },
  [OmnichannelSupportedAnalyticsReport.PRACTICE_SALES]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.PRACTICE_SALES'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.PRACTICE_SALES
    ],
  },
  [OmnichannelSupportedAnalyticsReport.CVC_PRODUCER_SALES]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.PRODUCER_SALES'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.CVC_PRODUCER_SALES
    ],
  },
  [OmnichannelSupportedAnalyticsReport.SCHEDULE_MANAGEMENT_NEW]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.APPOINTMENT_BREAKDOWN'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.SCHEDULE_MANAGEMENT_NEW
    ],
  },
  [OmnichannelSupportedAnalyticsReport.CANCELLATIONS_AND_NO_SHOWS]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.CANCELLATIONS_AND_NO_SHOWS'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.CANCELLATIONS_AND_NO_SHOWS
    ],
  },
  [OmnichannelSupportedAnalyticsReport.CLIENT_INITIATED_REQUESTS]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.CLIENT_INITIATED_REQUESTS'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.CLIENT_INITIATED_REQUESTS
    ],
  },
  [OmnichannelSupportedAnalyticsReport.DENTAL_AND_HEARTWORM_OVERVIEW]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.DENTAL_AND_HEARTWORM_OVERVIEW'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.DENTAL_AND_HEARTWORM_OVERVIEW
    ],
  },
  [OmnichannelSupportedAnalyticsReport.APPOINTMENT_MIX_BENCHMARK]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.APPOINTMENT_MIX_BENCHMARK'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.APPOINTMENT_MIX_BENCHMARK
    ],
  },
  [OmnichannelSupportedAnalyticsReport.CVC_INVOICE_ACTIVITY]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.INVOICE_ACTIVITY'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.CVC_INVOICE_ACTIVITY
    ],
  },
  [OmnichannelSupportedAnalyticsReport.OPEN_SOAPS_AND_INVOICES_SSO]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.OPEN_SOAPS_AND_INVOICES'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.OPEN_SOAPS_AND_INVOICES_SSO
    ],
  },
  [OmnichannelSupportedAnalyticsReport.BILLING_AGING]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.BILLING_AGING_REPORT'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.BILLING_AGING
    ],
  },
  [OmnichannelSupportedAnalyticsReport.MAGIC_QUADRANT_SSO]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.MAGIC_QUADRANT'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.MAGIC_QUADRANT_SSO
    ],
  },
  [OmnichannelSupportedAnalyticsReport.INVENTORY_USAGE_AND_AVERAGE_INVOICE]: {
    id: uuid(),
    text: i18n.t(
      'Menu:OMNICHANNEL_ANALYTICS.INVENTORY_USAGE_AND_AVERAGE_INVOICE',
    ),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.INVENTORY_USAGE_AND_AVERAGE_INVOICE
    ],
  },
  [OmnichannelSupportedAnalyticsReport.REPEAT_AND_RECHECK_PATIENTS]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.REPEAT_AND_RECHECK_PATIENTS'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.REPEAT_AND_RECHECK_PATIENTS
    ],
  },
  [OmnichannelSupportedAnalyticsReport.CVC_RESOLUTION_RATE]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.RESOLUTION_RATE'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.CVC_RESOLUTION_RATE
    ],
  },
  [OmnichannelSupportedAnalyticsReport.CVC_DISCOUNT_USAGE]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.DISCOUNT_USAGE'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.CVC_DISCOUNT_USAGE
    ],
  },
  [OmnichannelSupportedAnalyticsReport.CVC_INVENTORY_AND_CATALOG_AUDIT]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.INVENTORY_AND_CATALOG_AUDIT'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.CVC_INVENTORY_AND_CATALOG_AUDIT
    ],
  },
  [OmnichannelSupportedAnalyticsReport.CVC_OVERDUE_TASKS]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.OVERDUE_TASKS'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.CVC_OVERDUE_TASKS
    ],
  },
  [OmnichannelSupportedAnalyticsReport.CVC_SMS_ACTIVITY]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.SMS_ACTIVITY'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.CVC_SMS_ACTIVITY
    ],
  },
  [OmnichannelSupportedAnalyticsReport.ONLINE_SICK_APPOINTMENTS]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.ONLINE_SICK_APPOINTMENTS'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.ONLINE_SICK_APPOINTMENTS
    ],
  },
  [OmnichannelSupportedAnalyticsReport.DAILY_BILLING_ACTIVITY]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.DAILY_BILLING_ACTIVITY'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.DAILY_BILLING_ACTIVITY
    ],
  },
  [OmnichannelSupportedAnalyticsReport.TIME_TRACKING]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.STAFF_TIME_TRACKING'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.TIME_TRACKING
    ],
  },
  [OmnichannelSupportedAnalyticsReport.CLIENT_LIST]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.CLIENT_LIST'),
    url: analyticsReportToPath[OmnichannelSupportedAnalyticsReport.CLIENT_LIST],
  },
  [OmnichannelSupportedAnalyticsReport.BUNDLES_AND_WELLNESS_PLANS]: {
    id: uuid(),
    text: i18n.t('Menu:OMNICHANNEL_ANALYTICS.BUNDLES_AND_WELLNESS_PLANS'),
    url: analyticsReportToPath[
      OmnichannelSupportedAnalyticsReport.BUNDLES_AND_WELLNESS_PLANS
    ],
  },
}

export const useOmnichannelAnalyticsMenu = (): MenuItem[] => {
  const { t } = useTranslation(['Common'])

  const OmnichannelAnalyticsFinancialsSection: MenuItem[] = [
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.BILLING_AGING
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.BANK_RECONCILIATION
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.CVC_INVOICE_ACTIVITY
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.CVC_DAILY_RECONCILIATION
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.PRACTICE_SALES
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.CVC_PRODUCER_SALES
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.OPEN_AND_UNAPPLIED_BILLING
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.DAILY_BILLING_ACTIVITY
    ],
  ]

  const OmnichannelAnalyticsOperationsSection: MenuItem[] = [
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.SCHEDULE_MANAGEMENT_NEW
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.ACQUISITION_AND_LIFETIME_VALUE
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.APPOINTMENT_MIX_BENCHMARK
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.CANCELLATIONS_AND_NO_SHOWS
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.CLIENT_AND_PATIENT_STATUS
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.CLIENT_INITIATED_REQUESTS
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.CONTROLLED_SUBSTANCE_LOG
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.DENTAL_AND_HEARTWORM_OVERVIEW
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.FORWARD_BOOKINGS
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.GROUP_PRICE_LISTS
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.INVENTORY_USAGE
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.MAGIC_QUADRANT_SSO
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.MEDICAL_INSIGHTS
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.OPEN_SOAPS_AND_INVOICES_SSO
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.PRACTICE_PERFORMANCE
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.RABIES_VACCINE_HISTORY
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.REMINDERS_COMPLIANCE
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.CVC_RESOLUTION_RATE
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.CVC_DISCOUNT_USAGE
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.CVC_INVENTORY_AND_CATALOG_AUDIT
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.CVC_OVERDUE_TASKS
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.CVC_SMS_ACTIVITY
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.ONLINE_SICK_APPOINTMENTS
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.REPEAT_AND_RECHECK_PATIENTS
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.BUNDLES_AND_WELLNESS_PLANS
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.TIME_TRACKING
    ],
    omnichannelAnalyticsDashboardMapping[
      OmnichannelSupportedAnalyticsReport.CLIENT_LIST
    ],
  ]

  return [
    {
      id: uuid(),
      text: t('Menu:OMNICHANNEL_ANALYTICS.FINANCIALS'),
      expanded: true,
      children: getMenu(OmnichannelAnalyticsFinancialsSection),
    },
    {
      id: uuid(),
      text: t('Menu:OMNICHANNEL_ANALYTICS.OPERATIONS'),
      expanded: true,
      children: getMenu(OmnichannelAnalyticsOperationsSection),
    },
  ]
}
