import React, { ReactElement } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    button: {
      color: theme.colors.secondaryText,
      fontWeight: 400,
      fontSize: '1.4rem',
      textTransform: 'none',
      textDecoration: 'underline',
      '&:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
  { name: 'TopMenuButtonsSection' },
)

export interface TopMenuButtonsSectionProps {
  children: React.ReactNode
  className?: string
}

const TopMenuButtonsSection = ({
  children,
  className,
}: TopMenuButtonsSectionProps) => {
  const classes = useStyles()

  const childrenArray = React.Children.toArray(children) as ReactElement[]

  return (
    <>
      {childrenArray.map((child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {React.cloneElement(child, {
            className: classNames(classes.button, className),
          })}
        </React.Fragment>
      ))}
    </>
  )
}

export default TopMenuButtonsSection
