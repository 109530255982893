import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, Grid, Input, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Nil, PuiSelect, PuiTheme, useFields } from '@pbt/pbt-ui-components'

import ThreeStepsToggle from '~/components/common/inputs/ThreeStepsToggle'
import { ExceptionColumnType } from '~/constants/migration'
import { editMapping } from '~/store/actions/migration'
import { getDictionaryScope } from '~/store/reducers/constants'
import {
  getCurrentSessionId,
  getMappingsIsLoading,
} from '~/store/reducers/migration'
import { MigrationConstant } from '~/types'
import { MigrationException, ProposedColumn } from '~/types/entities/migration'
import useFieldsChanged from '~/utils/useFieldsChanged'

import MigrationExceptionAutocomplete from './MigrationExceptionAutocomplete'
import {
  buildScopeToggleProps,
  extractExceptionRow,
  getColumnOptions,
  getColumnValue,
  getFields,
  getRhapsodyValues,
} from './utils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    tableRow: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
    tableCell: {
      border: theme.constants.tableBorder,
      color: theme.colors.secondaryText,
    },
    checkboxCell: {
      width: 52,
    },
    scopeCell: {
      width: 136,
    },
    optionsSelect: {
      minWidth: 112,
    },
    autocomplete: {
      width: '100%',
    },
  }),
  { name: 'MigrationExceptionsTableRow' },
)

interface MigrationExceptionsTableRowProps {
  columns: ProposedColumn[]
  exception: MigrationException
  isEnterprise: boolean
  proposedColumns: ProposedColumn[]
}

const MigrationExceptionsTableRow = ({
  columns,
  proposedColumns,
  exception,
  isEnterprise,
}: MigrationExceptionsTableRowProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Businesses')

  const sessionId = useSelector(getCurrentSessionId)
  const isLoading = useSelector(getMappingsIsLoading)
  const DictionaryScope = useSelector(getDictionaryScope)

  const { fields } = useFields(getFields(proposedColumns, exception))
  const scopeToggleProps = buildScopeToggleProps(DictionaryScope)
  const row = extractExceptionRow(exception)
  const getReadableColumnValue = getColumnValue(fields)
  const handleMappingUpdate = () => {
    const existingMapping = exception || {}
    const rhapsodyValues = getRhapsodyValues(fields, proposedColumns)
    const mapping = {
      ...existingMapping,
      rhapsodyValue1: rhapsodyValues[0],
      rhapsodyValue2: rhapsodyValues[1],
      resolved: true,
    }

    dispatch(editMapping(sessionId, mapping, isEnterprise))
  }

  const handleCheckboxChange = () => {
    if (!exception.resolved) {
      handleMappingUpdate()
    }
  }

  const handleScopeChanged = (dictionaryScope: string | Nil) => {
    const existingMapping = exception || {}
    const mapping = { ...existingMapping, dictionaryScope }
    dispatch(editMapping(sessionId, mapping, isEnterprise))
  }

  useFieldsChanged(handleMappingUpdate, fields)

  const resolvedStateHint = exception?.resolved
    ? t('Businesses:MIGRATION.RESOLVED')
    : t('Businesses:MIGRATION.CLICK_TO_RESOLVE_AS_IS')

  return (
    <Grid container className={classes.tableRow}>
      {columns.map((column, columnIndex) =>
        !exception ? (
          <Grid
            container
            item
            xs
            alignItems="center"
            className={classes.tableCell}
            key={column.id}
            px={2}
          />
        ) : (
          <Grid
            container
            item
            xs
            alignItems="center"
            className={classes.tableCell}
            key={column.id}
            px={2}
          >
            {column.type === ExceptionColumnType.STRING && (
              <Dotdotdot clamp={1}>{row[columnIndex]}</Dotdotdot>
            )}
            {column.type === ExceptionColumnType.SELECT &&
              (isLoading ? (
                <Dotdotdot clamp={1}>
                  {getReadableColumnValue(column)}
                </Dotdotdot>
              ) : (
                <PuiSelect
                  fullWidth
                  renderEmpty
                  classes={{
                    select: classes.optionsSelect,
                  }}
                  field={fields[column.id]}
                  input={<Input />}
                  items={
                    getColumnOptions(fields, column) as MigrationConstant[]
                  }
                />
              ))}
            {column.type === ExceptionColumnType.AUTOCOMPLETE &&
              (isLoading ? (
                <Dotdotdot clamp={1}>
                  {getReadableColumnValue(column)}
                </Dotdotdot>
              ) : (
                <MigrationExceptionAutocomplete
                  className={classes.autocomplete}
                  field={fields[column.id]}
                  options={
                    getColumnOptions(fields, column) as MigrationConstant[]
                  }
                />
              ))}
          </Grid>
        ),
      )}
      <Grid
        container
        item
        alignItems="center"
        className={classNames(classes.tableCell, classes.scopeCell)}
        px={2}
      >
        <ThreeStepsToggle
          disabled={isLoading}
          {...scopeToggleProps}
          value={exception?.dictionaryScope as string}
          onStateChange={handleScopeChanged}
        />
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classNames(classes.tableCell, classes.checkboxCell)}
        justifyContent="center"
      >
        <Tooltip placement="top" title={resolvedStateHint}>
          <Checkbox
            checked={exception?.resolved}
            onChange={handleCheckboxChange}
          />
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default MigrationExceptionsTableRow
