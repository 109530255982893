import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ClickAwayListener, Grid, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  DebouncedInlineSearch,
  Defaults,
  InfiniteLoaderList,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import { searchMoreProblems, searchProblems } from '~/store/actions/problems'
import { getPatient } from '~/store/reducers/patients'
import {
  getIsSearchResultLoading,
  getRemovedProblemsList,
  getSearchResultIdsList,
  getSearchResultTotalCount,
} from '~/store/reducers/problems'
import { getAppointmentId } from '~/store/reducers/soap'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { Problem } from '~/types'

import SearchFindingsRow from './SearchFindingsRow'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    problemsSearchWrapper: {
      position: 'relative',
      minHeight: 40,
      zIndex: theme.utils.modifyZIndex(theme.zIndex.searchShadow, 'below', 2),
    },
    paper: {
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      width: '100%',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.searchShadow, 'below'),
      paddingTop: 40,
    },
    search: {
      position: 'absolute',
      zIndex: theme.zIndex.searchShadow,
    },
  }),
  { name: 'ProblemsSearch' },
)

interface ProblemsSearchProps {
  isRightDrawerOpen: boolean
  onSearchFindingClick: (finding: Problem) => void
}

const ProblemsSearch = ({
  onSearchFindingClick,
  isRightDrawerOpen,
}: ProblemsSearchProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Soap', 'Search'])
  const classes = useStyles()

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchResultOpen, setSearchResultOpen] = useState(false)

  const findingsIds = useSelector(getSearchResultIdsList)
  const totalCount = useSelector(getSearchResultTotalCount)
  const isLoading = useSelector(getIsSearchResultLoading)
  const removedProblemsList = useSelector(getRemovedProblemsList)
  const removedProblemsListIds = removedProblemsList?.map(
    (problem) => problem.logKey,
  )
  const filteredFromRemovedFindingsIds = findingsIds.filter(
    (id) => !removedProblemsListIds.includes(id),
  )

  const isVisibleSearchResults = searchResultOpen

  const appointmentId = useSelector(getAppointmentId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const patient = useSelector(getPatient(appointment?.patient))

  const isItemLoaded = (index: number) => Boolean(findingsIds[index])

  const loadMoreItems = (startIndex: number) => {
    if (startIndex === 0) {
      return
    }
    if (patient) {
      dispatch(
        searchMoreProblems(
          searchTerm,
          patient.id,
          startIndex,
          Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        ),
      )
    }
  }

  useEffect(() => {
    if (
      searchTerm.length >= Defaults.MIN_PROBLEMS_WIDGET_SEARCH_LENGTH &&
      patient
    ) {
      dispatch(
        searchProblems(
          searchTerm,
          patient.id,
          0,
          Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        ),
      )
    }
  }, [searchTerm])

  useEffect(() => {
    if (isRightDrawerOpen) {
      setSearchResultOpen(false)
    }
  }, [isRightDrawerOpen])

  return (
    <Grid>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={() => {
          setSearchResultOpen(false)
        }}
      >
        <Grid className={classes.problemsSearchWrapper} m={1}>
          <DebouncedInlineSearch
            classes={{ root: classes.search }}
            disabled={false}
            isLoading={isLoading}
            name="problemsWidgetSearch"
            placeholder={t('Soap:PROBLEMS.SEARCH_PLACEHOLDER')}
            onChange={setSearchTerm}
            onFocus={() => {
              setSearchResultOpen(true)
            }}
          />
          {isVisibleSearchResults ? (
            <Paper className={classes.paper} elevation={3} sx={{ flex: 1 }}>
              {searchTerm.length >=
                Defaults.MIN_PROBLEMS_WIDGET_SEARCH_LENGTH &&
              findingsIds.length ? (
                <InfiniteLoaderList
                  isItemLoaded={isItemLoaded}
                  itemCount={totalCount}
                  itemData={filteredFromRemovedFindingsIds}
                  itemSpacing={0}
                  loadMoreItems={loadMoreItems}
                  style={{ height: 380 }}
                >
                  {(findingId) => (
                    <SearchFindingsRow
                      findingId={findingId}
                      key={findingId}
                      searchTerm={searchTerm}
                      onClick={onSearchFindingClick}
                    />
                  )}
                </InfiniteLoaderList>
              ) : null}
              {searchTerm.length <
              Defaults.MIN_PROBLEMS_WIDGET_SEARCH_LENGTH ? (
                <Text p={2} variant="body2">
                  {t('Search:SEARCH_TERM_MUST_BE_LONGER')}
                </Text>
              ) : null}
              {!findingsIds.length &&
              searchTerm.length >=
                Defaults.MIN_PROBLEMS_WIDGET_SEARCH_LENGTH ? (
                <Text p={2} variant="body2">
                  {t('Search:NO_SEARCH_RESULTS')}
                </Text>
              ) : null}
            </Paper>
          ) : null}
        </Grid>
      </ClickAwayListener>
    </Grid>
  )
}

export default ProblemsSearch
