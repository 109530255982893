import React, { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AddButton,
  Business,
  InlineSearch,
  PermissionArea,
  PuiTheme,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import DialogNames, { ConfirmAlertType } from '~/constants/DialogNames'
import { deleteBusinessGroup } from '~/store/actions/businesses'
import { useUpdateCurrentUsersBusinessId } from '~/store/hooks/user'
import {
  getCRUDByAreaForBusiness,
  getCurrentUserId,
} from '~/store/reducers/auth'
import { getMultipleBusinesses } from '~/store/reducers/businesses'
import {
  BasePracticeDetailsSectionProps,
  PracticeGroupDetailsFields,
} from '~/types'
import { getBusinessName } from '~/utils/businessUtils'
import useDialog from '~/utils/useDialog'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'
import PracticeDetailsChildrenTable from './PracticeDetailsChildrenTable'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    plusButtonFeatureContainer: {
      alignSelf: 'center',
      marginRight: 0,
      marginLeft: theme.spacing(2),
    },
    search: {
      width: '100%',
      position: 'relative',
    },
  }),
  { name: 'GroupDetailsConfigurationSection' },
)

const GroupDetailsConfigurationSection = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Admin'])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )
  const currentUserId = useSelector(getCurrentUserId)
  const childrenField = {
    name: 'children',
    initialValue: business.children || [],
  }
  const { fields, reset } = useFields([childrenField])

  const businesses = useSelector(getMultipleBusinesses(fields.children.value))

  const updateCurrentUsersBusinessId = useUpdateCurrentUsersBusinessId()

  const [openConfirmDeleteAlert] = useConfirmAlert({
    type: ConfirmAlertType.DELETE_BUSINESS_GROUP,
  })

  const [openAddChildDialog] = useDialog(DialogNames.ADD_BUSINESS_CHILD)

  usePracticeFieldsSection<PracticeGroupDetailsFields>({
    business,
    fields,
    sectionName: PracticeDetailsPanels.GROUP_DETAILS,
  })

  const onDelete = useCallback(
    (id: string) => {
      openConfirmDeleteAlert({
        onConfirm: (proceed) => {
          if (proceed) {
            if (business?.children?.includes(id)) {
              dispatch(deleteBusinessGroup(id))
            }
            reset([
              {
                ...childrenField,
                initialValue: R.without([id], fields.children.value),
              },
            ])
          }
        },
      })
    },
    [fields.children.value, business?.children],
  )

  const onNavigateToBusiness = useCallback((id: string) => {
    navigate(`/admin/general/practices/${id}`)
  }, [])

  const onChangeCurrentBusiness = useCallback((id: string) => {
    if (currentUserId) {
      updateCurrentUsersBusinessId({ businessId: id, userId: currentUserId })
    }
  }, [])

  const onAddPractice = () => {
    openAddChildDialog({
      selectedPractices: fields.children.value,
      onSelected: (selected: string[]) => fields.children.setValue(selected),
    })
  }

  const filteredBusinessIds = R.pipe<any[], any[], string[]>(
    R.filter((item: Business) =>
      Utils.matchSubstring(search, getBusinessName(item)),
    ),
    R.pluck('id'),
  )(businesses)

  const filteredChildren = fields.children.value.filter((id: string) =>
    filteredBusinessIds.includes(id),
  )

  return (
    <Grid container item direction="column">
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item className={classes.search} xs={8}>
          <InlineSearch
            placeholder={t('Admin:PRACTICE.SEARCH_PRACTICE')}
            search={search}
            onChange={setSearch}
          />
        </Grid>
        <Grid container item justifyContent="flex-end" xs={4}>
          {permissions.update && (
            <AddButton
              inline
              addText={t('Admin:PRACTICE.ADD_PRACTICE_TO_GROUP')}
              classes={{ addItem: classes.plusButtonFeatureContainer }}
              onAdd={onAddPractice}
            />
          )}
        </Grid>
      </Grid>
      <Grid container>
        <PracticeDetailsChildrenTable
          editable={permissions.update}
          practices={filteredChildren}
          onChangeCurrentBusiness={onChangeCurrentBusiness}
          onDelete={onDelete}
          onNavigateToBusiness={onNavigateToBusiness}
        />
      </Grid>
    </Grid>
  )
}

export default memo(GroupDetailsConfigurationSection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
