import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { PuiTheme, TextInteractive } from '@pbt/pbt-ui-components'

import ExpandButton from '~/components/common/buttons/ExpandButton'
import {
  getMultiplePrices,
  getVariation,
  isAllPricesSelected,
  setManualSelection,
} from '~/store/duck/bulkPrices'
import { getConstantTranslatedName } from '~/store/reducers/constants'

import BulkPricesEditSubItem from './BulkPricesEditSubItem'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    control: {
      padding: theme.spacing(0.5),
    },
    expanderMarker: {
      backgroundColor: 'transparent',
      border: 'none',
    },
    expanderMarkerHidden: {
      visibility: 'hidden',
    },
    paper: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
      borderRadius: 4,
    },
    expanderHeading: {
      cursor: 'pointer',
    },
    oddItem: {
      backgroundColor: theme.colors.tableOddRowBackground,
    },
  }),
  { name: 'BulkPricesEditRow' },
)

interface BulkPricesEditRowProps {
  expanded?: boolean
  itemId: string
}

const BulkPricesEditRow = ({
  itemId,
  expanded: expandedProp,
}: BulkPricesEditRowProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common'])

  const item = useSelector(getVariation(itemId))
  const isLoading = !item
  const { name, genderId, speciesIds, prices: priceIds = [] } = item || {}
  const hasPrices = priceIds.length > 0

  const bulkPrices = useSelector(getMultiplePrices(priceIds))
  const isSelected = useSelector(isAllPricesSelected(priceIds))
  const genderRestrictions =
    useSelector(getConstantTranslatedName('Gender', genderId)) ||
    t('Common:ALL')
  const speciesRestrictions =
    useSelector(getConstantTranslatedName('Species', speciesIds)) ||
    t('Common:ALL')

  const [expanded, setExpanded] = useState(expandedProp)

  useEffect(() => {
    setExpanded(expandedProp)
  }, [expandedProp])

  const handleSelectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    dispatch(setManualSelection({ priceIds, state: !isSelected }))
  }

  const groupedPrices = useMemo(
    () => R.groupBy(R.prop('variationId'), bulkPrices),
    [bulkPrices.length],
  )

  const toggleExpanded = () => setExpanded(!expanded)

  return (
    <Grid container className={classes.paper} direction="column">
      <Grid
        container
        item
        alignItems="center"
        className={classNames({
          [classes.expanderHeading]: hasPrices,
        })}
        columns={{ xs: 10 }}
        px={0.5}
        py={1}
        onClick={hasPrices ? toggleExpanded : undefined}
      >
        <Grid item width={70}>
          <Checkbox
            checked={isSelected}
            className={classes.control}
            disabled={isLoading}
            onChange={handleSelectionChange}
          />
          <ExpandButton
            className={classNames(classes.expanderMarker, {
              [classes.expanderMarkerHidden]: !hasPrices,
            })}
            disabled={isLoading}
            expanded={expanded}
          />
        </Grid>
        <Grid item xs pl={1} py={0.5}>
          <TextInteractive isLoading={isLoading} variant="body">
            {name}
          </TextInteractive>
        </Grid>
        <Grid item pl={1} xs={1} />
        <Grid item pl={1} xs={1} />
        <Grid item pl={1} xs={1} />
        <Grid item pl={1} py={0.5} xs={1}>
          <TextInteractive isLoading={isLoading} variant="body">
            {speciesRestrictions}
          </TextInteractive>
        </Grid>
        <Grid item pl={1} py={0.5} xs={1}>
          <TextInteractive isLoading={isLoading} variant="body">
            {genderRestrictions}
          </TextInteractive>
        </Grid>
      </Grid>
      {expanded && (
        <Grid container item direction="column">
          {Object.values(groupedPrices).map((prices, variationGroupIndex) =>
            prices.map((price) => (
              <BulkPricesEditSubItem
                className={classNames({
                  [classes.oddItem]: variationGroupIndex % 2,
                })}
                key={price.id}
                price={price}
              />
            )),
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default BulkPricesEditRow
