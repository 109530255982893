import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme } from '@pbt/pbt-ui-components'

import { TimetableArea } from '~/constants/schedulerConstants'
import { getWhiteboardIsLoading } from '~/store/reducers/whiteboard'
import { getConstantFromMapOrDefault } from '~/utils'

import TimetableHeader from '../header/TimetableHeader'
import WhiteboardColumnHeader from './WhiteboardColumnHeader'
import {
  WhiteboardViewHumanReadable,
  WhiteboardViewType,
} from './whiteboardViews'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    header: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(2),
    },
    headerContainer: {
      '&:first-child': {
        marginLeft: theme.spacing(1),
      },
      marginLeft: theme.spacing(2),
    },
  }),
  { name: 'WhiteboardHeader' },
)

export interface WhiteboardHeaderProps {
  columns: {
    id?: string
    name: string
  }[]
  openShadow: boolean
}

const WhiteboardHeader = ({ columns, openShadow }: WhiteboardHeaderProps) => {
  const classes = useStyles()
  const isLoading = useSelector(getWhiteboardIsLoading)
  const { view: viewProp } = useParams() as { view: string }
  const view = getConstantFromMapOrDefault(
    viewProp,
    WhiteboardViewType,
    WhiteboardViewType.DEFAULT,
  )

  return (
    <TimetableHeader
      area={TimetableArea.WHITEBOARD}
      isLoading={isLoading}
      openShadow={openShadow}
      selectedView={view}
      views={WhiteboardViewHumanReadable}
    >
      <Grid container item className={classes.header} wrap="nowrap">
        {columns.map(({ id, name }) => (
          <Grid
            item
            xs
            className={name && classes.headerContainer}
            key={id || name}
          >
            {name && <WhiteboardColumnHeader name={name} />}
          </Grid>
        ))}
      </Grid>
    </TimetableHeader>
  )
}

export default WhiteboardHeader
