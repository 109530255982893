import { Defaults } from '@pbt/pbt-ui-components'

import { getPreludeAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchPatientMembershipsList = (
  businessId: string,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  clientId: string,
) =>
  request(
    getPreludeAPIPath(`wplans/membership/${clientId}`),
    { method: 'GET', params: { businessId, from, to } },
    true,
    Schema.patientMembershipsList,
  )

export const fetchPatientMembership = (
  businessId: string,
  clientId: string,
  patientId: string,
) =>
  request(
    getPreludeAPIPath(`wplans/membership/${clientId}/patient/${patientId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.patientMembership,
  )
