import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, PuiDialog, PuiTheme } from '@pbt/pbt-ui-components'

import { sendResetPasswordEmail } from '~/store/actions/auth'
import {
  getAuthError,
  getAuthIsResetEmailSending,
  getCurrentUser,
} from '~/store/reducers/auth'
import { auth0Enabled } from '~/utils'

import ProfileAvatarEdit from './ProfileAvatarEdit'
import ProfileChangePassword from './ProfileChangePassword'
import ProfileEdit from './ProfileEdit'
import ProfileEmailPasswordReset, {
  EmailResetStatusType,
} from './ProfileEmailPasswordReset'
import ProfileInfo from './ProfileInfo'
import ProfileNotificationSettings from './ProfileNotificationSettings'
import ProfileSavedSignature from './ProfileSavedSignature'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      maxWidth: 1100,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: 'calc(100% - 64px)',
      },
    },
  }),
  { name: 'ProfileDialog' },
)

enum ProfileContentType {
  AVATAR_EDIT,
  CHANGE_PASSWORD,
  EDIT,
  INFO,
  NOTIFICATION_SETTINGS,
  SAVED_SIGNATURE,
  PASSWORD_RESET,
}

const RootProfileContentType = ProfileContentType.INFO

const ProfileContentComponentMap = {
  [ProfileContentType.AVATAR_EDIT]: ProfileAvatarEdit,
  [ProfileContentType.CHANGE_PASSWORD]: ProfileChangePassword,
  [ProfileContentType.PASSWORD_RESET]: ProfileEmailPasswordReset,
  [ProfileContentType.EDIT]: ProfileEdit,
  [ProfileContentType.INFO]: ProfileInfo,
  [ProfileContentType.NOTIFICATION_SETTINGS]: ProfileNotificationSettings,
  [ProfileContentType.SAVED_SIGNATURE]: ProfileSavedSignature,
}

export interface ProfileDialogProps extends BasePuiDialogProps {
  openNotificationSettings?: boolean
}

const ProfileDialog = ({
  open,
  onClose,
  openNotificationSettings = false,
}: ProfileDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(getCurrentUser)
  const isResetEmailSending = useSelector(getAuthIsResetEmailSending)
  const authError = useSelector(getAuthError)
  const [emailStatus, setEmailStatus] = useState(EmailResetStatusType.SENDING)

  const [currentContentType, setCurrentContentType] = useState(
    openNotificationSettings
      ? ProfileContentType.NOTIFICATION_SETTINGS
      : RootProfileContentType,
  )

  useEffect(() => {
    if (isResetEmailSending) {
      setEmailStatus(EmailResetStatusType.SENDING)
    } else if (authError) {
      setEmailStatus(EmailResetStatusType.FAILED)
    } else {
      setEmailStatus(EmailResetStatusType.SUCCESS)
    }
  }, [isResetEmailSending, authError])

  const sendPasswordResetEmail = () => {
    if (user && user.email) {
      dispatch(sendResetPasswordEmail(user.email))
      setCurrentContentType(ProfileContentType.PASSWORD_RESET)
    }
  }

  const ProfileContentComponent = ProfileContentComponentMap[currentContentType]

  return (
    <PuiDialog
      aria-labelledby="profile-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <ProfileContentComponent
        emailStatus={emailStatus}
        onAvatarEditClick={() =>
          setCurrentContentType(ProfileContentType.AVATAR_EDIT)
        }
        onBack={() => setCurrentContentType(RootProfileContentType)}
        onChangePasswordClick={() =>
          auth0Enabled
            ? sendPasswordResetEmail()
            : setCurrentContentType(ProfileContentType.CHANGE_PASSWORD)
        }
        onClick={() => setCurrentContentType(ProfileContentType.EDIT)}
        onNotificationSettingsClick={() =>
          setCurrentContentType(ProfileContentType.NOTIFICATION_SETTINGS)
        }
        onSaved={onClose}
        onSavedSignatureClick={() =>
          setCurrentContentType(ProfileContentType.SAVED_SIGNATURE)
        }
      />
    </PuiDialog>
  )
}

export default ProfileDialog
