import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
  PuiTextArea,
  PuiTheme,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 390,
    },
    actions: {
      borderTop: 0,
      justifyContent: 'center',
      paddingBottom: theme.spacing(2),
    },
    icon: {
      color: theme.colors.alertWarning,
    },
    button: {
      minWidth: 150,
    },
  }),
  { name: 'PrescriptionDeclineReasonDialog' },
)

export interface PrescriptionDeclineReasonDialogProps
  extends BasePuiDialogProps {
  onDecline: (declineReason: string) => void
}

const PrescriptionDeclineReasonDialog = ({
  open,
  onClose,
  onDecline,
}: PrescriptionDeclineReasonDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const {
    fields: { declineReason },
    validate,
  } = useFields([
    { name: 'declineReason', initialValue: '', validators: ['required'] },
  ])

  const handleDecline = () => {
    if (validate()) {
      if (onClose) {
        onClose()
      }
      onDecline(declineReason.value)
    }
  }

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader
          className={classes.button}
          variant="contained"
          onClick={handleDecline}
        >
          {t('Common:DECLINE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="prescription-decline-reason-dialog"
      classes={{
        paper: classes.paper,
        actions: classes.actions,
      }}
      open={open}
      onClose={onClose}
    >
      <Grid p={3} pb={1}>
        <Grid container justifyContent="center">
          <WarningIcon className={classes.icon} />
        </Grid>
        <InputLabel htmlFor="dialog-decline-reason">
          <Text pb={1} pt={2} textAlign="center" variant="body">
            {t('Common:PLEASE_ADD_A_REASON_FOR_DECLINING')}
          </Text>
        </InputLabel>
        <PuiTextArea
          fullWidth
          field={declineReason}
          id="dialog-decline-reason"
          margin="none"
          placeholder={t('Common:REASON_FOR_DECLINING')}
          rows={3}
        />
      </Grid>
    </PuiDialog>
  )
}

export default PrescriptionDeclineReasonDialog
