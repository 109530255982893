import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useSelector } from 'react-redux'
import { Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Nil, PuiTheme, Text } from '@pbt/pbt-ui-components'

import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'

import ClientAndPatientTooltip from './ClientAndPatientTooltip'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    name: {
      display: 'inline',
    },
    pointerName: {
      cursor: 'pointer',
      display: 'inline',
    },
    tooltip: {
      backgroundColor: theme.colors.tableBackground,
      color: theme.colors.secondaryText,
      padding: 0,
      minWidth: 330,
      fontWeight: 400,
      boxShadow: '3px 3px 20px 0 rgba(168,163,163,0.5)',
    },
  }),
  { name: 'ClientAndPatientCell' },
)

interface ClientAndPatientCellProps {
  item?: {
    client?: string | Nil
    clientId?: string | Nil
    patient?: string | Nil
    patientId?: string | Nil
  }
}

const ClientAndPatientCell = ({ item }: ClientAndPatientCellProps) => {
  const classes = useStyles()

  const client = useSelector(getUser(item?.clientId || item?.client))
  const patient = useSelector(getPatient(item?.patientId || item?.patient))

  const clientLastName = client?.lastName || ''
  const patientName = patient?.name || ''

  const clientPatientName =
    patientName || clientLastName
      ? `${patientName} ${clientLastName}`.trim()
      : '—'

  const showTooltip = client?.id && patient?.id

  return (
    <Dotdotdot clamp={1}>
      {showTooltip ? (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          placement="right-start"
          title={
            <ClientAndPatientTooltip
              clientId={client.id}
              patientId={patient.id}
            />
          }
        >
          <Text className={classes.pointerName}>{clientPatientName}</Text>
        </Tooltip>
      ) : (
        <Text className={classes.name}>{clientPatientName}</Text>
      )}
    </Dotdotdot>
  )
}

export default ClientAndPatientCell
