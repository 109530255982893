import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  PuiDialog,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import {
  editNoteHistory,
  saveNoteHistory,
} from '~/store/actions/medicalHistory'
import { getMedicalHistoryIsSending } from '~/store/reducers/medicalHistory'
import { NoteHistoryItem } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import NoteHistories, { NoteHistoriesHandle } from './NoteHistories'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 976,
      maxWidth: 976,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    dialogContentRoot: {
      padding: theme.spacing(2),
    },
  }),
  { name: 'NoteHistoryDialog' },
)

export interface NoteHistoryDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  note?: NoteHistoryItem
  patientId: string | Nil
}

const NoteHistoryDialog = ({
  note,
  clientId,
  patientId,
  open,
  onClose,
}: NoteHistoryDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isSending = useSelector(getMedicalHistoryIsSending)
  const setCloseAfterCreationOn = useCloseAfterCreation(
    onClose,
    getMedicalHistoryIsSending,
  )
  const { t } = useTranslation(['Common', 'Dialogs'])

  const noteHistoriesRef = useRef<NoteHistoriesHandle>(null)

  const save = () => {
    if (noteHistoriesRef.current?.validate()) {
      const items = noteHistoriesRef.current.get()
      const action = note ? editNoteHistory : saveNoteHistory

      setCloseAfterCreationOn()
      if (patientId) {
        dispatch(action(items, patientId, clientId))
      }
    }
  }

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader
          disabled={isSending}
          loading={isSending}
          type="submit"
          onClick={save}
        >
          {note ? t('Common:SAVE_ACTION') : t('Common:ADD_TO_TIMELINE')}
        </ButtonWithLoader>
      }
      aria-labelledby="note-history-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={
        note
          ? t('Dialogs:NOTE_HISTORY_DIALOG.TITLE_EDIT')
          : t('Common:ADD_HISTORICAL_NOTE')
      }
      onClose={onClose}
    >
      <NoteHistories
        clientId={clientId}
        note={note}
        patientId={patientId}
        ref={noteHistoriesRef}
      />
    </PuiDialog>
  )
}

export default NoteHistoryDialog
