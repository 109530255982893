import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, Divider, FormControlLabel, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { isEmpty, without } from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  ClassesType,
  Coparent,
  Defaults,
  InlineSearch,
  Nil,
  PuiDialog,
  PuiTheme,
  TextWithTooltip,
  Utils,
} from '@pbt/pbt-ui-components'
import { Note } from '@pbt/pbt-ui-components/src/icons'

import { fetchClient } from '~/store/actions/clients'
import {
  clearContacts,
  fetchContacts,
  fetchMoreItemsForContacts,
  getContactsIsFetchingList,
  getContactsList,
  getContactsMap,
  getContactsTotalCount,
} from '~/store/duck/contacts'
import { getMultipleUsers, getUser } from '~/store/reducers/users'
import { Contact, ContactsMap } from '~/types'

interface UseStylesProps {
  classes?: ClassesType<typeof useStyles>
}

interface GetLabelTextProps {
  email: string
  name: string
  phone: string
}

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      maxWidth: 976,
      width: 976,
    },
    dialogContentRoot: {
      padding: theme.spacing(0, 2, 2),
    },
    resultsContainer: {
      border: theme.constants.tableBorder,
      height: 288,
      overflow: 'auto',
    },
    labelRoot: {
      display: 'flex',
      margin: 0,
    },
    radio: {
      padding: theme.spacing(0.5),
      marginRight: theme.spacing(1),
    },
    radioLabelDisabled: {
      color: theme.colors.tabLabel,
    },
    divider: {
      marginTop: theme.spacing(2),
    },
    addButton: {
      width: 120,
    },
  }),
  { name: 'SelectContactDialog' },
)

export const ContactSelectType = {
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
}

const isContactDisabled = (
  { email, phone }: Contact,
  contactSelectType: string,
) => {
  switch (contactSelectType) {
    case ContactSelectType.EMAIL:
      return !email
    default:
      return !phone
  }
}

interface SelectContactDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  contactSelectType: string | undefined
  onAdd: (selectedCoparents: Coparent[], selectedContacts: Contact[]) => void
}

const SelectContactDialog = ({
  open,
  onClose,
  onAdd,
  clientId,
  contactSelectType = ContactSelectType.EMAIL,
}: SelectContactDialogProps) => {
  const useStylesProps: UseStylesProps = {}
  const classes = useStyles(useStylesProps)

  const { t } = useTranslation('Common')

  const dispatch = useDispatch()
  const list: string[] = useSelector(getContactsList) || []
  const map: ContactsMap = useSelector(getContactsMap) || {}
  const isLoading: boolean = useSelector(getContactsIsFetchingList)
  const totalCount: number = useSelector(getContactsTotalCount)
  const client = useSelector(getUser(clientId))
  const coparents = useSelector(
    getMultipleUsers(client?.coparents),
  ) as Coparent[]
  const contacts: Contact[] = list.map((id: string) => map[id]) || []

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([])
  const [selectedCoparents, setSelectedCoparents] = useState<Coparent[]>([])

  const isEmail = contactSelectType === ContactSelectType.EMAIL

  const getLabelText = ({ name, email, phone }: GetLabelTextProps) =>
    isEmail
      ? `${name} | ${email || t('Common:NO_EMAIL')}`
      : `${name} | ${phone || t('Common:NO_PHONE_NUMBER')}`

  const renderLabel = (contact: Contact) =>
    contact.notes ? (
      <TextWithTooltip Icon={Note} tooltipText={contact.notes} variant="body2">
        {getLabelText(contact)}
      </TextWithTooltip>
    ) : (
      getLabelText(contact)
    )

  const handleContactSelectionChange = (
    contactId: string,
    selected: boolean,
  ) => {
    const currentContact = Utils.findById(contactId, contacts)
    if (selected) {
      setSelectedContacts([...selectedContacts, currentContact])
    } else {
      setSelectedContacts(without([currentContact], selectedContacts))
    }
  }

  const handleCoparentsSelectionChange = (
    coparentId: string,
    selected: boolean,
  ) => {
    const currentCoparent = Utils.findById(coparentId, coparents)
    if (selected) {
      setSelectedCoparents([...selectedCoparents, currentCoparent])
    } else {
      setSelectedCoparents(without([currentCoparent], selectedCoparents))
    }
  }

  const coparentsWithEmail = coparents.filter(({ email }) => Boolean(email))
  const coparentsWithMobilePhone = coparents.filter(({ mobilePhone }) =>
    Boolean(mobilePhone),
  )

  useEffect(() => {
    if (open) {
      dispatch(fetchContacts())
    } else {
      dispatch(clearContacts())
    }
  }, [open])

  useEffect(() => {
    if (searchTerm) {
      dispatch(fetchContacts(undefined, undefined, searchTerm))
    } else if (open) {
      dispatch(fetchContacts())
    }
  }, [searchTerm])

  const missingCoparents = R.isNil(client?.coparents)

  useEffect(() => {
    if (missingCoparents && clientId) {
      dispatch(fetchClient({ clientId }))
    }
  }, [missingCoparents, clientId])

  const loadMore = useCallback(() => {
    dispatch(
      fetchMoreItemsForContacts(
        contacts.length,
        contacts.length + Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        searchTerm,
      ),
    )
  }, [contacts, searchTerm])

  const handleContactSelected = () => {
    onAdd(selectedCoparents, selectedContacts)
    if (onClose) {
      onClose()
    }
  }

  const coparentsToDisplay = isEmail
    ? coparentsWithEmail
    : coparentsWithMobilePhone

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader
          className={classes.addButton}
          disabled={isEmpty(selectedContacts) && isEmpty(selectedCoparents)}
          onClick={handleContactSelected}
        >
          {t('Common:ADD_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="select-contact-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={`${t('Common:EMAIL_FORM.TO')}:`}
      onClose={onClose}
    >
      {coparentsToDisplay.length > 0 && (
        <>
          <Grid item pl={1} pt={1.5}>
            {coparentsToDisplay.map((person) => (
              <FormControlLabel
                classes={{
                  root: classes.labelRoot,
                }}
                control={
                  <Checkbox
                    checked={Boolean(
                      Utils.findById(person.id, selectedCoparents),
                    )}
                    className={classes.radio}
                    onChange={Utils.handleFormCheckboxInput((selected) =>
                      handleCoparentsSelectionChange(person.id, selected),
                    )}
                  />
                }
                key={person.id}
                label={`${Utils.getPersonString(person)} | ${
                  isEmail ? person.email : person.mobilePhone
                } | ${t('Common:CO-PET_PARENT')}`}
                value={person.id}
              />
            ))}
          </Grid>
          <Divider className={classes.divider} />
        </>
      )}
      <Grid item my={2}>
        <InlineSearch
          isLoading={isLoading}
          placeholder={t('Common:SEARCH_ACTION')}
          onChange={setSearchTerm}
        />
      </Grid>
      <Grid
        container
        className={classes.resultsContainer}
        direction="column"
        id="select-contact-dialog-container"
        p={1}
      >
        <InfiniteScroll
          dataLength={contacts?.length || 0}
          hasMore={totalCount > contacts.length}
          loader={null}
          next={() => loadMore()}
          scrollableTarget="select-contact-dialog-container"
        >
          {contacts.map((contact) => {
            const { id } = contact
            const disabled = isContactDisabled(contact, contactSelectType)
            return (
              <FormControlLabel
                classes={{
                  root: classes.labelRoot,
                  label: classNames(disabled && classes.radioLabelDisabled),
                }}
                control={
                  <Checkbox
                    checked={Boolean(Utils.findById(id, selectedContacts))}
                    className={classes.radio}
                    disabled={disabled}
                    onChange={Utils.handleFormCheckboxInput((selected) =>
                      handleContactSelectionChange(id, selected),
                    )}
                  />
                }
                key={id}
                label={renderLabel(contact)}
                value={id}
              />
            )
          })}
        </InfiniteScroll>
      </Grid>
    </PuiDialog>
  )
}

export default SelectContactDialog
