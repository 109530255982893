import * as R from 'ramda'
import { AnyAction } from 'redux'

import { RegistrationFinalQuestions } from '~/types'
import { createArrayOf } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import { LOGIN_SUCCESS } from '../actions/types/auth'
import {
  CLEAR_INVITATION_ALREADY_ACCEPTED,
  CLEAR_KEY_ALREADY_EXISTS,
  CLEAR_USER_ALREADY_EXISTS,
  CREATE_PERSONS,
  FETCH_INVITATION,
  FETCH_INVITATION_FAILURE,
  FETCH_INVITATION_SUCCESS,
  FETCHING_LOCATION_DATA,
  INVITATION_ALREADY_ACCEPTED,
  REGISTER_BY_INVITE_REQUEST,
  REGISTRATION_FAILURE,
  REGISTRATION_REQUEST,
  REGISTRATION_SUCCESS,
  REMOVE_BUSINESS,
  SAVE_PERSON,
  SET_KEY_ALREADY_EXISTS,
  SET_USER_ALREADY_EXISTS,
  UPDATE_BUSINESS,
  UPDATE_BUSINESS_CANDIDATE,
  UPDATE_FINAL_QUESTIONS,
  UPDATE_MEMBER,
  VALIDATE_ACTIVATION_KEY,
  VALIDATE_ACTIVATION_KEY_FAILURE,
  VALIDATE_ACTIVATION_KEY_SUCCESS,
} from '../actions/types/registration'
import { BusinessDto } from '../dto/Business'
import { createPerson, PersonDto } from '../dto/Person'
import type { RootState } from '../index'

export type RegistrationState = {
  activationKey: string | null
  businessCandidate: BusinessDto | null
  businesses: Record<string, BusinessDto>
  error: string | null
  finalQuestions: RegistrationFinalQuestions | null
  invitationAlreadyAcceptedEmail: string | null
  invitationToken: string | null
  isActivationKeyFlow: boolean
  isKeyAlreadyExist: boolean
  isLoading: boolean
  isUserAlreadyExist: boolean
  isValidate: boolean
  members: Record<string, PersonDto>
  person: PersonDto | null
  validationMetadata: {
    defaultAdministratorRoleId: number
    type: string
  } | null
}

const INITIAL_STATE: RegistrationState = {
  activationKey: null,
  isActivationKeyFlow: false,
  person: null,
  businessCandidate: null,
  businesses: {},
  members: {},
  isLoading: false,
  error: null,
  invitationToken: null,
  isUserAlreadyExist: false,
  isKeyAlreadyExist: false,
  invitationAlreadyAcceptedEmail: null,
  isValidate: false,
  validationMetadata: null,
  finalQuestions: null,
}
const createPersons = (amount: number) =>
  createArrayOf(amount).map(() => createPerson())

const registration = (
  state = INITIAL_STATE,
  action: AnyAction,
): RegistrationState => {
  switch (action.type) {
    case CREATE_PERSONS:
      const persons = createPersons(action.amountOfPersons)
      return {
        ...state,
        members: R.indexBy(R.prop('id'), persons),
      }
    case UPDATE_FINAL_QUESTIONS:
      return {
        ...state,
        finalQuestions: action.finalQuestions,
      }
    case FETCHING_LOCATION_DATA:
      return { ...state, businessCandidate: null, isLoading: true }
    case UPDATE_BUSINESS:
      if (action.business) {
        return {
          ...state,
          businessCandidate: null,
          businesses: {
            ...state.businesses,
            [action.business.id]: action.business,
          },
          isLoading: false,
        }
      }
      return {
        ...state,
        businessCandidate: null,
        businesses: {
          ...state.businesses,
          [state.businessCandidate!.id]: state.businessCandidate!,
        },
        isLoading: false,
      }
    case UPDATE_BUSINESS_CANDIDATE:
      return { ...state, businessCandidate: action.business, isLoading: false }
    case REMOVE_BUSINESS:
      const { [action.businessId]: deletedBusiness, ...businesses } =
        state.businesses
      return { ...state, businesses }
    case REGISTRATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case REGISTRATION_REQUEST:
    case REGISTER_BY_INVITE_REQUEST:
      return { ...state, isLoading: true, error: null }
    case SAVE_PERSON:
      return { ...state, person: action.person }
    case UPDATE_MEMBER:
      return {
        ...state,
        members: { ...state.members, [action.member.id]: action.member },
      }
    case FETCH_INVITATION:
      return {
        ...state,
        invitationToken: action.invitationToken,
        isLoading: true,
      }
    case FETCH_INVITATION_SUCCESS:
      return {
        ...state,
        person: action.person,
        businesses: action.businesses,
        isLoading: false,
        invitationToken: action.token,
      }
    case FETCH_INVITATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case INVITATION_ALREADY_ACCEPTED:
      return {
        ...state,
        isLoading: false,
        invitationAlreadyAcceptedEmail: action.email,
      }
    case CLEAR_INVITATION_ALREADY_ACCEPTED:
      return { ...state, invitationAlreadyAcceptedEmail: null }
    case LOGIN_SUCCESS:
      return INITIAL_STATE
    case REGISTRATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        isActivationKeyFlow: false,
      }
    case SET_USER_ALREADY_EXISTS:
      return { ...state, isUserAlreadyExist: true, isLoading: false }
    case CLEAR_USER_ALREADY_EXISTS:
      return { ...state, isUserAlreadyExist: false }
    case SET_KEY_ALREADY_EXISTS:
      return { ...state, isKeyAlreadyExist: true }
    case CLEAR_KEY_ALREADY_EXISTS:
      return { ...state, isKeyAlreadyExist: false }
    case VALIDATE_ACTIVATION_KEY:
      return {
        ...state,
        isLoading: true,
        isValidate: false,
        error: null,
        validationMetadata: null,
        isActivationKeyFlow: true,
        activationKey: null,
      }
    case VALIDATE_ACTIVATION_KEY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isValidate: true,
        validationMetadata: action.metadata,
        activationKey: action.key,
      }
    case VALIDATE_ACTIVATION_KEY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

export default registration
export const getRegistration = (state: RootState): RegistrationState =>
  state.registration
export const getIsActivationKeyFlow = (state: RootState) =>
  getRegistration(state).isActivationKeyFlow
export const getActivationKey = (state: RootState) =>
  getRegistration(state).activationKey
export const getActivationFinalQuestions = (state: RootState) =>
  getRegistration(state).finalQuestions
export const getRegistrationError = (state: RootState) =>
  getRegistration(state).error
export const getRegistrationPerson = (state: RootState) =>
  getRegistration(state).person
export const getRegistrationBusinesses = (state: RootState) =>
  getRegistration(state).businesses
export const getRegistrationBusinessCandidate = (state: RootState) =>
  getRegistration(state).businessCandidate
export const getRegistrationMembers = (state: RootState) =>
  getRegistration(state).members
export const getRegistrationIsLoading = (state: RootState) =>
  getRegistration(state).isLoading
export const getIsValidateActivationKey = (state: RootState) =>
  getRegistration(state).isValidate
export const getValidationMetadata = (state: RootState) =>
  getRegistration(state).validationMetadata
export const getRegistrationInvitationToken = (state: RootState) =>
  getRegistration(state).invitationToken
export const getCurrentUser = (state: RootState) =>
  state.users[state.auth.currentUserId]
export const getIsUserAlreadyExist = (state: RootState) =>
  getRegistration(state).isUserAlreadyExist
export const getIsKeyAlreadyExist = (state: RootState) =>
  getRegistration(state).isKeyAlreadyExist
export const getIsInvitationAlreadyAcceptedEmail = (state: RootState) =>
  getRegistration(state).invitationAlreadyAcceptedEmail
export const getRegistrationSanitized = (state: RootState) => ({
  ...state.registration,
  invitationToken: '*',
})
