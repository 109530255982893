import React from 'react'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, PuiTheme, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      margin: theme.spacing(2, 3),
    },
    title: {
      lineHeight: '2.4rem',
    },
    contentContainer: {
      maxWidth: 660,
      background: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
      marginTop: theme.spacing(5),
      padding: theme.spacing(2),
    },
    fullWidth: {
      width: '100%',
    },
    actionButton: {
      height: 40,
      marginTop: theme.spacing(3),
    },
    narrowActionButton: {
      minWidth: 132,
    },
    wideActionButton: {
      minWidth: 170,
    },
  }),
  { name: 'NoItemsScreen' },
)

export interface NoItemsScreenProps {
  action?: () => void
  actionMessage?: string
  children: React.ReactNode
  classes?: ClassesType<typeof useStyles>
  fullWidth?: boolean
  title: React.ReactNode
  wideButton?: boolean
}

const NoItemsScreen = ({
  title,
  action,
  actionMessage,
  wideButton,
  children,
  fullWidth,
  classes: classesProp,
}: NoItemsScreenProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Grid
      className={classNames(classes.root, {
        [classes.fullWidth]: Boolean(fullWidth),
      })}
    >
      {typeof title === 'string' ? (
        <Text className={classes.title} variant="h1">
          {title}
        </Text>
      ) : (
        title
      )}
      <Grid className={classes.contentContainer}>
        {children}
        {action && (
          <Fab
            className={classNames(
              classes.actionButton,
              wideButton
                ? classes.wideActionButton
                : classes.narrowActionButton,
            )}
            color="inherit"
            variant="extended"
            onClick={action}
          >
            {actionMessage}
          </Fab>
        )}
      </Grid>
    </Grid>
  )
}

export default NoItemsScreen
