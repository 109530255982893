import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  BasePuiDialogProps,
  CircularProgressOverlay,
  HtmlNotesPreview,
  PuiDialog,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import EmailPreview from '~/components/common/emailPreview/EmailPreview'
import MobilePhoneChatPreview from '~/components/common/preview/MobilePhoneChatPreview'
import MobilePhonePreview from '~/components/common/preview/MobilePhonePreview'
import {
  getAutomaticCommunicationsIsFetchingPreview,
  getAutomaticCommunicationsMessagePreview,
  previewAutoReplyByTransportType,
  resetAutomaticCommunications,
} from '~/store/duck/automaticCommunications'
import { BusinessHours } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      minHeight: 380,
    },
    smsContent: {
      backgroundColor: theme.colors.dialogHeaderBackground,
      padding: theme.spacing(2, 1),
      height: 'auto',
    },
  }),
  { name: 'AutoReplyMessagePreviewDialog' },
)

export interface AutoReplyMessagePreviewDialogProps extends BasePuiDialogProps {
  businessId: string
  businessName?: string
  message: string
  transport: ConversationTransport
  type: BusinessHours
}

export const AutoReplyMessagePreviewDialog = ({
  businessId,
  businessName,
  message,
  open,
  transport,
  type,
  onClose,
}: AutoReplyMessagePreviewDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const isFetchingPreview = useSelector(
    getAutomaticCommunicationsIsFetchingPreview,
  )
  const messagePreview = useSelector(getAutomaticCommunicationsMessagePreview)

  const getTitle = (label: string) =>
    type === 'AFTER_HOURS'
      ? t('Dialogs:AUTO_REPLY_MESSAGE_PREVIEW_DIALOG.TITLE_AFTER_HOURS', {
          transport: label,
        })
      : t('Dialogs:AUTO_REPLY_MESSAGE_PREVIEW_DIALOG.TITLE_OPENING_HOURS', {
          transport: label,
        })

  const PreviewMap = {
    [ConversationTransport.Boop]: {
      Component: (
        <MobilePhoneChatPreview sender={businessName}>
          {messagePreview && (
            <HtmlNotesPreview fontSize="1.2rem" notes={messagePreview} />
          )}
        </MobilePhoneChatPreview>
      ),
      title: getTitle(t('Common:BOOP_SYSTEM_NAME_MESSAGE')),
    },
    [ConversationTransport.Email]: {
      Component: messagePreview && (
        <EmailPreview emailTemplate={messagePreview} scale={1} />
      ),
      title: getTitle(t('Common:EMAIL').toLowerCase()),
    },
    [ConversationTransport.Sms]: {
      Component: (
        <MobilePhonePreview classes={{ content: classes.smsContent }}>
          {messagePreview && (
            <HtmlNotesPreview fontSize="1.2rem" notes={messagePreview} />
          )}
        </MobilePhonePreview>
      ),
      title: getTitle(t('Common:SMS')),
    },
    [ConversationTransport.LogPhoneCall]: {
      Component: null,
      title: null,
    },
  }

  const { Component, title } = PreviewMap[transport]

  useEffect(() => {
    dispatch(
      previewAutoReplyByTransportType({
        businessId,
        config: {
          message,
        },
        transport,
      }),
    )

    return () => {
      dispatch(resetAutomaticCommunications())
    }
  }, [businessId, message, transport])

  return (
    <PuiDialog
      aria-labelledby="auto-reply-message-preview"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <Box p={3}>
        <Text strong variant="subheading2">
          {title}
        </Text>
        <Text variant="body2">
          {t('Dialogs:AUTO_REPLY_MESSAGE_PREVIEW_DIALOG.DESCRIPTION')}
        </Text>
        <CircularProgressOverlay
          open={isFetchingPreview}
          preloaderText={t('Common:LOADING')}
        />
        {Component}
      </Box>
    </PuiDialog>
  )
}
