import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  PermissionArea,
  PuiTheme,
  Text,
  TextInteractive,
  useFields,
  WellnessPlan,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import ActiveStateSwitch from '~/components/common/ActiveStateSwitch'
import Link from '~/components/common/link/Link'
import Expander from '~/components/common/lists/Expander'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  MembershipFilters,
  WellnessPlanLevels,
} from '~/constants/wellnessPlansConstants'
import {
  clearWellnessPlanErrorStatus,
  deleteWellnessPlanVersion,
  editWellnessPlanVersion,
  fetchWellnessPlanVersion,
} from '~/store/actions/wellnessPlans'
import {
  useSaveWellnessPlanVersion,
  useWellnessPlanVersionActivationAlert,
} from '~/store/hooks/wellnessPlans'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getBundlesIsDeleting } from '~/store/reducers/bundles'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getWellnessPlansErrorStatus,
  getWellnessPlansIsDeleting,
  getWellnessPlansIsFetching,
  getWellnessPlansIsFetchingBenefits,
  getWellnessPlansIsLoading,
  getWellnessPlansIsSaving,
  getWellnessPlanVersion,
} from '~/store/reducers/wellnessPlans'
import { isFieldValuesChanged } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import { useSortedWellnessPlans } from '../wellnessPlanUtils'
import WellnessPlanLevelsDetailsSection, {
  WellnessPlanLevelsDetailsSectionHandle,
} from './WellnessPlanLevelsDetailsSection'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    activePatientsContainer: {
      minWidth: 300,
      width: 'fit-content',
      padding: theme.spacing(1, 12, 1, 0),
      borderBottom: theme.constants.tabBorder,
    },
    activePatientsContainerWithLink: {
      paddingRight: 0,
    },
    levelsSection: {
      marginTop: theme.spacing(3),
    },
    warningIcon: {
      fontSize: '1.8rem',
      marginRight: theme.spacing(1.5),
      color: theme.colors.important,
    },
  }),
  { name: 'WellnessPlanDetails' },
)

interface WellnessPlanDetailsProps {
  itemId: string
  onClose: () => void
}

const WellnessPlanDetails = ({ itemId, onClose }: WellnessPlanDetailsProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Admin', 'Common', 'WellnessPlans'])

  const versionFromStore = useSelector(getWellnessPlanVersion(itemId))
  const isLoading = useSelector(getWellnessPlansIsLoading)
  const isSaving = useSelector(getWellnessPlansIsSaving)
  const isFetching = useSelector(getWellnessPlansIsFetching)
  const isFetchingBenefits = useSelector(getWellnessPlansIsFetchingBenefits)
  const isDeleting = useSelector(getWellnessPlansIsDeleting)
  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )
  const permissions = useSelector(
    getCRUDByArea(
      isCvcRolesEnabled
        ? PermissionArea.WELLNESS_PLAN_CONFIG
        : PermissionArea.WELLNESS_PLAN,
    ),
  )
  const wellnessPlanErrorStatus = useSelector(getWellnessPlansErrorStatus)

  const sortedPlans = useSortedWellnessPlans(versionFromStore?.plans)

  const [openDeletedVersionAlert, closeDeletedVersionAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const setCloseOnDelete = useCloseAfterCreation(onClose, getBundlesIsDeleting)
  const saveWellnessPlanVersion = useSaveWellnessPlanVersion()

  const [saved, setSaved] = useState(false)

  const detailsSectionRef = useRef<WellnessPlanLevelsDetailsSectionHandle>(null)

  const wellnessPlanVersion = {
    ...(versionFromStore || ({} as WellnessPlanVersion)),
    plans: sortedPlans,
  }

  const [wellnessPlanVersionCandidate, setWellnessPlanVersionCandidate] =
    useState<WellnessPlanVersion>(wellnessPlanVersion)

  const hasActivePatients =
    (wellnessPlanVersionCandidate?.activePatients || 0) > 0

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'active',
        type: 'toggle',
        initialValue: wellnessPlanVersion?.active ?? true,
      },
    ],
    false,
  )

  const { active } = fields

  useEffect(() => {
    if (itemId) {
      dispatch(fetchWellnessPlanVersion(itemId))
    }
  }, [itemId])

  useEffect(() => {
    if (versionFromStore) {
      reset()
      setWellnessPlanVersionCandidate(wellnessPlanVersion)
    }
  }, [versionFromStore])

  useEffect(() => {
    setSaved(false)
  }, [])

  const onDeleteRequested = () => {
    setCloseOnDelete()
    dispatch(deleteWellnessPlanVersion(itemId))
  }

  const onCloneRequested = () => {
    navigate(`/admin/catalog/wellness-plans/clone/${itemId}`)
  }

  const getUpdatedWellnessPlanVersion = () =>
    ({
      ...wellnessPlanVersionCandidate,
      plans: detailsSectionRef.current?.get(),
      active: active.value,
    } as WellnessPlanVersion)

  const isValid = () => validate() && detailsSectionRef.current?.validate()

  const onSaveRequested = () => {
    if (isValid()) {
      saveWellnessPlanVersion(getUpdatedWellnessPlanVersion())
      setSaved(true)
    }
  }

  const onForceSaveRequested = (currentActiveId: string) => {
    if (isValid()) {
      dispatch(
        editWellnessPlanVersion({
          ...getUpdatedWellnessPlanVersion(),
          currentActiveId,
        }),
      )
    }
  }

  useEffect(() => {
    if (wellnessPlanErrorStatus === 410) {
      openDeletedVersionAlert({
        message: t('WellnessPlans:THIS_VERSION_WAS_REMOVED_BY_ANOTHER_USER'),
        cancelButtonText: t('Common:NO_DISCARD_IT'),
        okButtonText: t('Common:YES'),
        onCancel: () => {
          closeDeletedVersionAlert()
          navigate('/admin/catalog/wellness-plans/')
        },
        onOk: () => {
          const updatedVersion = getUpdatedWellnessPlanVersion()
          saveWellnessPlanVersion({
            ...updatedVersion,
            id: null,
            active: false,
          })
          closeDeletedVersionAlert()
        },
      })
      dispatch(clearWellnessPlanErrorStatus())
    }

    if (wellnessPlanErrorStatus === 404) {
      navigate('/admin/catalog/wellness-plans/')
    }
  }, [wellnessPlanErrorStatus])

  useWellnessPlanVersionActivationAlert(
    wellnessPlanVersionCandidate,
    onForceSaveRequested,
  )

  const goToFullEdit = () => {
    navigate(`/admin/catalog/wellness-plans/edit/${itemId}`)
  }

  const handleDeletePlan = (planToDelete: WellnessPlan) => {
    const isBaseLevel = planToDelete.level === WellnessPlanLevels.BASE

    if (isBaseLevel) {
      setWellnessPlanVersionCandidate({
        ...wellnessPlanVersionCandidate,
        basePlanHidden: true,
      })
    } else {
      setWellnessPlanVersionCandidate({
        ...wellnessPlanVersionCandidate,
        plans: R.without([planToDelete], wellnessPlanVersionCandidate.plans),
        deletedPlanIds: R.uniq(
          (wellnessPlanVersionCandidate.deletedPlanIds || []).concat(
            planToDelete.id,
          ),
        ),
      })
    }
  }

  const fieldsQuery = `${MembershipFilters.WELLNESS_PLAN_VERSION}=${wellnessPlanVersionCandidate?.versionNumber}`

  const getUnsavedData = () => {
    const hasChangesInDetailsSection =
      detailsSectionRef.current?.hasUnsavedChanges() ?? false
    const hasChangesInFields = isFieldValuesChanged(fields)
    const hasDeletedBasePlan =
      wellnessPlanVersionCandidate.basePlanHidden !==
      wellnessPlanVersion.basePlanHidden
    const hasDeletedPlan = !R.equals(
      wellnessPlanVersionCandidate.deletedPlanIds,
      wellnessPlanVersion.deletedPlanIds,
    )

    return saved
      ? false
      : hasChangesInFields ||
          hasChangesInDetailsSection ||
          hasDeletedBasePlan ||
          hasDeletedPlan
  }

  return (
    <Expander
      isConfirmToDelete
      additionalButtons={[
        <ButtonWithLoader
          color="secondary"
          disabled={isLoading}
          key="edit-all"
          loading={isLoading}
          onClick={goToFullEdit}
        >
          {t('Common:EDIT_ALL')}
        </ButtonWithLoader>,
      ]}
      expandedItemClass={t(
        'Admin:PRACTICE.DETAILS_LABEL.WELLNESS_PLAN_VERSION',
      ).toLowerCase()}
      getUnsavedData={getUnsavedData}
      isDeleting={isDeleting}
      isFetching={isFetching || isFetchingBenefits}
      isSaving={isSaving}
      item={wellnessPlanVersionCandidate}
      showButtons={permissions.update}
      onBack={onClose}
      onCloneRequested={onCloneRequested}
      onDeleteRequested={hasActivePatients ? undefined : onDeleteRequested}
      onSaveRequested={onSaveRequested}
    >
      <TextInteractive
        strong
        isLoading={!wellnessPlanVersionCandidate}
        variant="subheading"
      >
        {`${t('Common:VERSION')} ${
          wellnessPlanVersionCandidate?.versionNumber
        }`}
      </TextInteractive>
      <ActiveStateSwitch disabled={!permissions.update} field={active} />
      <Grid
        container
        item
        alignItems="center"
        className={classNames(classes.activePatientsContainer, {
          [classes.activePatientsContainerWithLink]: hasActivePatients,
        })}
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Text strong variant="body2">
          {t('Common:ACTIVE_PATIENTS')}
        </Text>
        <Text color="secondary" variant="body2">
          {wellnessPlanVersionCandidate?.activePatients}
        </Text>
        {hasActivePatients && (
          <Link to={`/clients?fieldsQuery=${encodeURIComponent(fieldsQuery)}`}>
            {t('Common:VIEW_LIST')}
          </Link>
        )}
      </Grid>
      {hasActivePatients && (
        <Grid container item alignItems="center" mt={2}>
          <WarningIcon className={classes.warningIcon} />
          <Text variant="body2">
            {t('WellnessPlans:THIS_VERSION_HAS_ACTIVE_PATIENTS')}
          </Text>
        </Grid>
      )}
      <WellnessPlanLevelsDetailsSection
        className={classes.levelsSection}
        ref={detailsSectionRef}
        wellnessPlanVersion={wellnessPlanVersionCandidate}
        onDeletePlan={handleDeletePlan}
      />
    </Expander>
  )
}

export default WellnessPlanDetails
