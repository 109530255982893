import * as R from 'ramda'
import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import FeatureToggle from '~/constants/featureToggle'
import { TableFilter } from '~/types'

import {
  createInventory,
  createInventoryFailure,
  createInventorySuccess,
  deleteInventory,
  deleteInventoryFailure,
  deleteInventorySuccess,
  editInventory,
  editInventoryFailure,
  editInventorySuccess,
  fetchInventoriesList,
  fetchInventoriesListFailure,
  fetchInventoriesListSuccess,
  fetchInventory,
  fetchInventoryError,
  fetchInventorySuccess,
  fetchMoreItemsForInventoriesList,
  fetchMoreItemsForInventoriesListFailure,
  fetchMoreItemsForInventoriesListSuccess,
} from '../actions/inventories'
import { updateSearchHighlights } from '../actions/search'
import {
  CREATE_INVENTORY,
  DELETE_INVENTORY,
  EDIT_INVENTORY,
  FETCH_INVENTORIES_LIST,
  FETCH_INVENTORY,
  FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST,
} from '../actions/types/inventories'
import { finishLoading, startLoading } from '../duck/progress'
import { getFeatureToggle } from '../reducers/constants'
import { getInventoriesListFilters } from '../reducers/inventories'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* createInventorySaga({
  inventory,
}: ReturnType<typeof createInventory>) {
  try {
    const isFoodCatalogEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.FOOD_CATALOG),
    )

    const { result: inventoryId, entities } = yield* requestAPI(
      isFoodCatalogEnabled ? API.createInventoryV2 : API.createInventory,
      inventory,
    )
    yield call(updateEntities, entities)
    yield put(fetchInventoriesList())
    yield put(createInventorySuccess(entities.inventories[inventoryId]))
  } catch (error) {
    yield put(createInventoryFailure(error as ApiError))
  }
}

export function* editInventorySaga({
  inventory: newInventory,
}: ReturnType<typeof editInventory>) {
  try {
    const isFoodCatalogEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.FOOD_CATALOG),
    )

    const { result: inventoryId, entities } = yield* requestAPI(
      isFoodCatalogEnabled ? API.editInventoryV2 : API.editInventory,
      newInventory,
    )
    yield call(updateEntities, entities)
    yield put(editInventorySuccess(inventoryId))
  } catch (error) {
    yield put(editInventoryFailure(error as ApiError))
  }
}

export function* fetchInventorySaga({
  inventoryId,
}: ReturnType<typeof fetchInventory>) {
  try {
    const isFoodCatalogEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.FOOD_CATALOG),
    )

    const { entities } = yield* requestAPI(
      isFoodCatalogEnabled ? API.fetchInventoryV2 : API.fetchInventory,
      inventoryId,
    )
    yield call(updateEntities, entities)
    yield put(fetchInventorySuccess(inventoryId))
  } catch (error) {
    yield put(fetchInventoryError(error as ApiError))
  }
}

export function* fetchInventoriesListSaga({
  from,
  to,
  query,
}: ReturnType<typeof fetchInventoriesList>) {
  try {
    const filters: Record<string, TableFilter>[] = yield select(
      getInventoriesListFilters,
    )

    yield put(startLoading('inventories'))
    const {
      result: { data: list, totalCount, highlights },
      entities,
    } = yield* requestAPI(
      API.fetchInventories,
      from,
      to,
      query,
      R.pluck('value', filters),
    )
    yield put(updateSearchHighlights(highlights, totalCount))
    yield call(updateEntities, entities)
    yield put(fetchInventoriesListSuccess(list, totalCount))
    yield put(finishLoading('inventories'))
  } catch (error) {
    yield put(fetchInventoriesListFailure(error as ApiError))
    yield put(finishLoading('inventories'))
  }
}

export function* fetchMoreItemsForInventoriesListSaga({
  from,
  to,
  query,
}: ReturnType<typeof fetchMoreItemsForInventoriesList>) {
  try {
    const filters: Record<string, TableFilter>[] = yield select(
      getInventoriesListFilters,
    )

    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(
      API.fetchInventories,
      from,
      to,
      query,
      R.pluck('value', filters),
    )
    yield call(updateEntities, entities)
    yield put(fetchMoreItemsForInventoriesListSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreItemsForInventoriesListFailure(error as ApiError))
  }
}

export function* deleteInventorySaga({
  inventoryId,
}: ReturnType<typeof deleteInventory>) {
  try {
    yield* requestAPI(API.deleteInventory, inventoryId)
    yield put(deleteInventorySuccess(inventoryId))
  } catch (error) {
    yield put(deleteInventoryFailure(error as ApiError))
  }
}

function* watchCreateInventory() {
  yield takeLeading(CREATE_INVENTORY, createInventorySaga)
}

function* watchEditInventory() {
  yield takeLeading(EDIT_INVENTORY, editInventorySaga)
}

function* watchFetchInventory() {
  yield takeLeading(FETCH_INVENTORY, fetchInventorySaga)
}

function* watchFetchInventoriesList() {
  yield takeLeading(FETCH_INVENTORIES_LIST, fetchInventoriesListSaga)
}

function* watchFetchMoreItemsForInventoriesList() {
  yield takeLatest(
    FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST,
    fetchMoreItemsForInventoriesListSaga,
  )
}

function* watchDeleteInventory() {
  yield takeLeading(DELETE_INVENTORY, deleteInventorySaga)
}

export default function* inventoriesSaga() {
  yield all([
    watchCreateInventory(),
    watchEditInventory(),
    watchFetchInventory(),
    watchFetchInventoriesList(),
    watchFetchMoreItemsForInventoriesList(),
    watchDeleteInventory(),
  ])
}
