import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Tooltip, TooltipProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PuiTheme, QuestionMode } from '@pbt/pbt-ui-components'
import {
  Edit as EditIcon,
  Eye as EyeIcon,
} from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    iconButton: {
      marginLeft: 'auto',
      padding: 0,
    },
    tooltipIcon: {
      backgroundColor: theme.colors.secondaryText,
      padding: theme.spacing(0, 0.5),
      fontSize: '1.4rem',
    },
  }),
  { name: 'QuestionsWidget' },
)

export interface QuestionWidgetActionsProps {
  mode: QuestionMode
  toggleMode: () => void
}

const QuestionWidgetActions = ({
  mode,
  toggleMode,
}: QuestionWidgetActionsProps) => {
  const classes = useStyles()

  const { t } = useTranslation('Common')

  const anchorEl = React.useRef<HTMLButtonElement>(null)

  const tooltipProps: Partial<TooltipProps> = {
    PopperProps: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -8],
          },
        },
      ],
      anchorEl: anchorEl.current,
    },
    classes: {
      tooltip: classes.tooltipIcon,
    },
    placement: 'top',
  }

  return (
    <IconButton
      aria-label={t('Common:VIEW_MODE')}
      className={classes.iconButton}
      id={mode}
      ref={anchorEl}
      onClick={toggleMode}
    >
      {mode === 'read-only' ? (
        <Tooltip {...tooltipProps} title={t('Common:EDIT_ACTION')}>
          <EditIcon color="primary" />
        </Tooltip>
      ) : (
        <Tooltip {...tooltipProps} title={t('Common:READ_ONLY')}>
          <EyeIcon />
        </Tooltip>
      )}
    </IconButton>
  )
}

export default QuestionWidgetActions
