import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, PuiDialog, PuiTheme } from '@pbt/pbt-ui-components'

import { acceptEula } from '~/store/actions/users'
import { getError, getUserIsSending } from '~/store/reducers/users'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import TermsAndConditionsWithAccept from './TermsAndConditionsWithAccept'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 1000,
      maxWidth: 1000,
      paddingTop: 35,
      paddingLeft: 50,
      paddingRight: 50,

      [theme.breakpoints.down('md')]: {
        width: 500,
        maxWidth: 500,
        paddingTop: 5,
        paddingLeft: 5,
        paddingRight: 5,
      },
    },
  }),
  { name: 'TermsAndConditionsDialog' },
)

export interface TermsAndConditionsDialogProps extends BasePuiDialogProps {}

const TermsAndConditionsDialog = ({
  open,
  onClose,
  ...rest
}: TermsAndConditionsDialogProps) => {
  const classes = useStyles()
  const error = useSelector(getError)
  const dispatch = useDispatch()
  const isLoading = useSelector(getUserIsSending)
  const setCloseOnAccepted = useCloseAfterCreation(onClose, getUserIsSending)

  const onAccept = () => {
    setCloseOnAccepted()
    dispatch(acceptEula())
  }

  return (
    <PuiDialog
      hideCloseButton
      classes={{
        paper: classes.paper,
      }}
      open={open}
      {...rest}
    >
      <TermsAndConditionsWithAccept
        error={error}
        isLoading={isLoading}
        onAccept={onAccept}
      />
    </PuiDialog>
  )
}

export default TermsAndConditionsDialog
