import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { DocumentFile, PuiTheme } from '@pbt/pbt-ui-components'

import Attachment from '~/components/common/inputs/attachment/Attachment'
import DialogNames from '~/constants/DialogNames'
import DocumentDialogStates from '~/constants/DocumentDialogStates'
import { Document } from '~/types'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    itemRow: {
      padding: 0,
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tableBorder,
      },
    },
  }),
  { name: 'RightRailAttachments' },
)

export interface RightRailAttachmentsProps {
  conversationId: string
  files: DocumentFile['file'][]
}

const RightRailAttachments = ({
  files,
  conversationId,
}: RightRailAttachmentsProps) => {
  const classes = useStyles()

  const [openDocumentDialog] = useDialog(DialogNames.DOCUMENT)

  const onPreviewRequested = (file: DocumentFile['file']) => {
    openDocumentDialog({
      conversationId,
      previewOnly: true,
      document: file as Document,
      PreviewProps: {
        hideTopButtons: true,
        view: true,
        showChangeFile: false,
      },
      step: DocumentDialogStates.PREVIEW,
    })
  }

  return (
    <>
      {files.map((file) => (
        <Attachment
          hideDelete
          classes={{ itemRow: classes.itemRow }}
          file={file}
          key={file.id}
          onPreviewRequested={onPreviewRequested}
        />
      ))}
    </>
  )
}

export default RightRailAttachments
