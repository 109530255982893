import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { RegistrationFinalQuestions } from '~/types'

import {
  fetchInvitation,
  fetchInvitationFailure,
  fetchInvitationSuccess,
  invitationAlreadyAccepted,
  registrationFailure,
  registrationSuccess,
  setKeyAlreadyExists,
  setUserAlreadyExists,
  validateActivationKey,
  validateActivationKeyFailure,
  validateActivationKeySuccess,
} from '../actions/registration'
import {
  FETCH_INVITATION,
  REGISTER_BY_INVITE_REQUEST,
  REGISTRATION_REQUEST,
  VALIDATE_ACTIVATION_KEY,
} from '../actions/types/registration'
import {
  getActivationFinalQuestions,
  getActivationKey,
  getIsActivationKeyFlow,
  getRegistration,
} from '../reducers/registration'

export function* registrationRequestSaga() {
  try {
    const isActivationKeyFlow: boolean = yield select(getIsActivationKeyFlow)
    const key: string = yield select(getActivationKey)
    const finalQuestions: RegistrationFinalQuestions = yield select(
      getActivationFinalQuestions,
    )
    const { person, businesses, members } = yield select(getRegistration)
    yield call(
      isActivationKeyFlow ? API.registerV2 : API.register,
      person,
      businesses,
      members,
      key,
      finalQuestions?.isAaha,
      finalQuestions?.fearFree,
      finalQuestions?.doctorCount,
      finalQuestions?.practiceType,
    )

    yield put(registrationSuccess())
  } catch (error) {
    const {
      response: {
        status,
        data: { description },
      },
    } = error as ApiError
    if (
      status === 400 &&
      description.toLowerCase().includes('user already exists')
    ) {
      yield put(setUserAlreadyExists())
    } else {
      // ToDo: check for 409 status when backend will migrate
      yield put(registrationFailure(error as ApiError))
    }
  }
}

export function* registerByInviteSaga() {
  try {
    const { person, invitationToken } = yield select(getRegistration)
    yield call(API.registerByInvite, person, invitationToken)
    yield put(registrationSuccess())
  } catch (error) {
    const {
      response: {
        status,
        data: { description },
      },
    } = error as ApiError
    if (
      status === 400 &&
      description.toLowerCase().includes('user already exists')
    ) {
      yield put(setUserAlreadyExists())
    } else {
      yield put(registrationFailure(error as ApiError))
    }
  }
}

export function* fetchInvitationSaga({
  invitationToken,
}: ReturnType<typeof fetchInvitation>) {
  try {
    const { person, businesses } = yield call(
      API.fetchInvitationData,
      invitationToken,
    )
    yield put(fetchInvitationSuccess(person, businesses, invitationToken))
  } catch (e) {
    const error = e as ApiError
    if (error.response?.status === 302 && error.response.data?.email) {
      yield put(invitationAlreadyAccepted(error.response.data.email))
    } else {
      yield put(fetchInvitationFailure(error))
    }
  }
}

export function* validateActivationKeySaga({
  key,
}: ReturnType<typeof validateActivationKey>) {
  try {
    const metadata: {
      defaultAdministratorRoleId: number
      type: string
    } = yield call(API.validateActivationKey, key)
    yield put(validateActivationKeySuccess(metadata, key))
  } catch (e) {
    const error = e as ApiError
    const {
      response: { status },
    } = error
    yield put(validateActivationKeyFailure(error))
    if (status === 400) {
      yield put(setKeyAlreadyExists())
    }
  }
}

function* watchValidationActivationKey() {
  yield takeLatest(VALIDATE_ACTIVATION_KEY, validateActivationKeySaga)
}

function* watchRegistration() {
  yield takeLatest(REGISTRATION_REQUEST, registrationRequestSaga)
}

function* watchInvitationRequest() {
  yield takeLeading(FETCH_INVITATION, fetchInvitationSaga)
}

function* watchRegisterByInviteRequest() {
  yield takeLeading(REGISTER_BY_INVITE_REQUEST, registerByInviteSaga)
}

export default function* () {
  yield all([
    watchRegistration(),
    watchInvitationRequest(),
    watchRegisterByInviteRequest(),
    watchValidationActivationKey(),
  ])
}
