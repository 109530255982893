import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, useMediaQuery } from '@mui/material'
import { LanguageUtils, PuiTheme, Text, Utils } from '@pbt/pbt-ui-components'

import DetailsList from '~/components/common/lists/DetailsList'
import Expander from '~/components/common/lists/Expander'
import { fetchLabTest } from '~/store/actions/labTests'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getLabTestTypes, getSpecies } from '~/store/reducers/constants'
import { getLabTest } from '~/store/reducers/labTests'
import { getConstantsStringWithAll } from '~/utils'

import LabTestPricesTable from './LabTestPricesTable'

const LABEL_COLUMN_WIDTH = 4

export interface LabTestDetailsProps {
  itemId: string
  onClose: () => void
}

const LabTestDetails = ({ itemId, onClose }: LabTestDetailsProps) => {
  const dispatch = useDispatch()
  const labTest = useSelector(getLabTest(itemId))
  const LabTestTypes = useSelector(getLabTestTypes)
  const business = useSelector(getCurrentBusiness)
  const Species = useSelector(getSpecies)
  const { t } = useTranslation(['Abbreviations', 'Admin', 'Common'])

  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )

  useEffect(() => {
    if (!labTest || typeof labTest.aahaRevCode === 'undefined') {
      dispatch(fetchLabTest(itemId))
    }
  }, [labTest])

  if (!labTest || typeof labTest.aahaRevCode === 'undefined') {
    return null
  }

  return (
    <Expander
      expandedItemClass={t('Common:LAB_TEST').toLowerCase()}
      showButtons={false}
      onBack={onClose}
    >
      <>
        <Grid item>
          <Text variant="h2">{labTest.name}</Text>
        </Grid>
        <Grid item mb={{ xs: 2, md: 0 }} mt={{ xs: 2, md: 4 }}>
          <Text strong variant={isMobile ? 'subheading2' : 'subheading3'}>
            {business
              ? t('Admin:CATALOG.LAB_TEST_DETAILS.BUSINESS_NAME_PRICES', {
                  businessName: business.name,
                })
              : t('Common:PRICES').toLowerCase()}
          </Text>
        </Grid>
        <Grid item>
          <LabTestPricesTable labTest={labTest} />
        </Grid>
        <Grid item mt={{ xs: 4, md: 6 }}>
          <DetailsList
            data={[
              {
                label: t('Common:VENDOR'),
                value: labTest.vendorName,
              },
              {
                label: t('Common:TYPE_ONE'),
                value: LanguageUtils.getConstantTranslatedName(
                  Utils.findConstantByName(labTest.type, LabTestTypes)?.id,
                  LabTestTypes,
                  labTest.type,
                ),
              },
              {
                label: t('Admin:CATALOG.LAB_TEST_DETAILS.VENDOR_NAME_CODE', {
                  vendorName: labTest.vendorName,
                }),
                value: labTest.vendorCode,
              },
              {
                label: t('Common:SPECIES'),
                value: getConstantsStringWithAll(labTest.speciesIds, Species),
              },
              {
                label: t(
                  'Abbreviations:ACRONYMS.AMERICAN_ANIMAL_HOSPITAL_ASSOCIATION.REV_CODE',
                ),
                value: labTest.aahaRevCode,
              },
            ]}
            headerText={t('Admin:CATALOG.LAB_TEST_DETAILS.BASIC_INFO')}
            labelColumnWidth={LABEL_COLUMN_WIDTH}
          />
        </Grid>
        <Grid item mt={{ xs: 4, md: 6 }}>
          <DetailsList
            data={[
              {
                label: t('Admin:CATALOG.LAB_TEST_DETAILS.DEVICE_OR_DEVICES'),
                value: labTest.vendorDevice,
              },
              {
                label: t('Common:TESTS_FOR'),
                value: labTest.testsFor,
              },
              {
                label: t('Admin:CATALOG.LAB_TEST_DETAILS.TEST_PROTOCOL'),
                value: labTest.testProtocol,
              },
              {
                label: t('Common:INSTRUCTIONS'),
                value: labTest.instructions,
              },
              {
                label: t('Admin:CATALOG.LAB_TEST_DETAILS.STORAGE_STABILITY'),
                value: labTest.storage,
              },
              {
                label: t('Common:SPECIMEN'),
                value: labTest.specimen,
              },
              {
                label: t('Admin:CATALOG.LAB_TEST_DETAILS.INTERPRETATION'),
                value: labTest.interpretation,
                isHtml: true,
              },
              {
                label: t('Admin:CATALOG.LAB_TEST_DETAILS.LAB_INTERFERENCE'),
                value: labTest.labInterference,
              },
              {
                label: t('Admin:CATALOG.LAB_TEST_DETAILS.COMMENTS'),
                value: labTest.comments,
              },
            ]}
            headerText={t('Common:ADDITIONAL_INFO')}
            labelColumnWidth={LABEL_COLUMN_WIDTH}
          />
        </Grid>
      </>
    </Expander>
  )
}

export default LabTestDetails
