import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { fetchCurrentUser } from '~/store/actions/auth'
import {
  getAuthError,
  getAuthIsFetching,
  getCurrentUser,
  getSilentLoginHappened,
} from '~/store/reducers/auth'

const useStyles = makeStyles(
  () => ({
    spinner: {
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
  }),
  { name: 'CurrentUserFetcher' },
)

export interface CurrentUserFetcherProps {
  children: React.ReactNode
}

const CurrentUserFetcher = ({
  children,
}: CurrentUserFetcherProps): JSX.Element => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(getCurrentUser)
  const isFetching = useSelector(getAuthIsFetching)
  const error = useSelector(getAuthError)
  const silentLoginHappened = useSelector(getSilentLoginHappened)

  const noRoles = !user || !user.businessToRoleList

  useEffect(() => {
    if (noRoles && !isFetching && !error) {
      dispatch(fetchCurrentUser())
    }
  }, [user])

  return ((noRoles || isFetching) && !silentLoginHappened) || Boolean(error) ? (
    <CircularProgress className={classes.spinner} />
  ) : (
    <>{children}</>
  )
}

export default CurrentUserFetcher
