import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Calendar,
  Nil,
  PhoneUtils,
  PuiDialog,
  PuiSelect,
  PuiTheme,
  Text,
  useFields,
  User,
  Utils,
} from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import NdcNumberForm, {
  NdcNumberFormHandle,
} from '~/components/common/form-inputs/ndc/NdcNumberForm'
import PhoneInput from '~/components/common/form-inputs/PhoneInput'
import AddressForm from '~/components/dashboard/clients/AddressForm'
import DialogNames from '~/constants/DialogNames'
import { partialUpdateClient } from '~/store/actions/clients'
import {
  clearAssignedDoctorValidationError,
  fetchSoap,
  selectDoctor,
} from '~/store/actions/soap'
import { partialUpdateVariation } from '~/store/actions/variations'
import {
  getClientIsLoading as getIsClientSaving,
  getClientsError,
} from '~/store/reducers/clients'
import { getGender } from '~/store/reducers/constants'
import {
  getAssignedDoctorValidationError,
  getDoctors,
  getError as getSoapError,
  getIsSaving as getIsDoctorSaving,
} from '~/store/reducers/soap'
import {
  getVariationIsSending as getIsVariationSaving,
  getVariationsError,
} from '~/store/reducers/variations'
import { DataHandleWithUnsavedChanges, Variation } from '~/types'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      overflowY: 'visible',
      width: 650,
      maxWidth: 650,
    },
    paperSmall: {
      width: 386,
      maxWidth: 386,
    },
    button: {
      width: 150,
    },
    icon: {
      color: theme.colors.alertWarning,
    },
    sectionTitleContainer: {
      paddingBottom: '0 !important',
    },
  }),
  { name: 'PrescriptionMissingFieldsDialog' },
)

const getActiveNamedPersons = R.pipe<
  User[][],
  User[],
  { id: string; name: string }[]
>(
  R.filter(R.propOr(undefined, 'active')),
  R.map((user: User) => ({ id: user.id, name: Utils.getPersonString(user) })),
)

export interface PrescriptionMissingFieldsDialogProps
  extends BasePuiDialogProps {
  client: User | Nil
  inventoryId?: string
  missingAssignedDoctor?: boolean
  missingClientAddress?: boolean
  missingClientDOB?: boolean
  missingClientGender?: boolean
  missingClientPhoneNumber?: boolean
  missingNdcNumber?: boolean
  onOk: () => void
  soapId?: string
  variation?: Variation | Nil
}

const PrescriptionMissingFieldsDialog = ({
  open,
  missingAssignedDoctor = false,
  missingNdcNumber = false,
  missingClientAddress = false,
  missingClientPhoneNumber = false,
  missingClientDOB = false,
  missingClientGender = false,
  inventoryId,
  soapId,
  variation,
  client,
  onClose,
  onOk,
}: PrescriptionMissingFieldsDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const doctors = useSelector(getDoctors)
  const isDoctorSaving = useSelector(getIsDoctorSaving)
  const isVariationSaving = useSelector(getIsVariationSaving)
  const isClientSaving = useSelector(getIsClientSaving)
  const Gender = useSelector(getGender)
  const soapError = useSelector(getSoapError)
  const variationError = useSelector(getVariationsError)
  const clientsError = useSelector(getClientsError)
  const assignedDoctorValidationError = useSelector(
    getAssignedDoctorValidationError,
  )
  const { t } = useTranslation(['Common', 'Dialogs'])

  const [openValidationErrorDialog] = useDialog(DialogNames.VALIDATION_ERROR)

  const missingOnlyDoctor =
    missingAssignedDoctor &&
    !(
      missingNdcNumber ||
      missingClientAddress ||
      missingClientPhoneNumber ||
      missingClientGender ||
      missingClientDOB
    )

  const [isSaving, setIsSaving] = useState(false)
  const [doctorValidationError, setDoctorValidationError] = useState(false)

  const {
    fields: { doctorId },
    validate: validateDoctor,
  } = useFields([
    {
      name: 'doctorId',
      label: t('Common:SELECT_A_DOCTOR'),
      type: 'select',
      validators: missingAssignedDoctor ? ['required'] : [],
    },
  ])

  const {
    fields: { mobilePhone, otherPhone },
    validate: validatePhone,
  } = useFields([
    {
      name: 'mobilePhone',
      label: `${t('Common:MOBILE_PHONE')}*`,
      validators: missingClientPhoneNumber
        ? ['phone', 'phoneRequired']
        : ['phone'],
      initialValue: client?.mobilePhone || '',
    },
    {
      name: 'otherPhone',
      label: t('Common:OTHER_PHONE_NUMBER'),
      validators: ['phone'],
      initialValue: client?.otherPhone || '',
    },
  ])

  const {
    fields: { clientGender, clientDOB },
  } = useFields([
    {
      name: 'clientGender',
      label: t(
        'Dialogs:PRESCRIPTION_DIALOG.PRESCRIPTION_MISSING_FIELDS_DIALOG.CLIENT_GENDER',
      ),
      type: 'select',
    },
    {
      name: 'clientDOB',
      label: t(
        'Dialogs:PRESCRIPTION_DIALOG.PRESCRIPTION_MISSING_FIELDS_DIALOG.CLIENT_DATE_OF_BIRTH',
      ),
    },
  ])

  const ndcFormRef = useRef<NdcNumberFormHandle>(null)
  const addressFormRef = useRef<DataHandleWithUnsavedChanges>(null)

  useEffect(() => {
    if (missingAssignedDoctor && doctors.length === 0 && soapId) {
      dispatch(fetchSoap(soapId))
    }
  }, [missingAssignedDoctor, doctors, soapId])

  useEffect(() => {
    if (assignedDoctorValidationError) {
      setDoctorValidationError(true)
      openValidationErrorDialog({
        error: assignedDoctorValidationError,
        onBack: () => dispatch(clearAssignedDoctorValidationError()),
        onProceed: () => {
          dispatch(clearAssignedDoctorValidationError())
          dispatch(selectDoctor(doctorId.value, true))
          setIsSaving(true)
          setDoctorValidationError(false)
        },
      })
    }
  }, [assignedDoctorValidationError])

  useEffect(() => {
    if (isSaving) {
      const allIsSaved =
        (!missingAssignedDoctor || !isDoctorSaving) &&
        (!missingNdcNumber || !isVariationSaving) &&
        (!missingClientAddress || !isClientSaving) &&
        (!missingClientPhoneNumber || !isClientSaving)

      if (allIsSaved) {
        setIsSaving(false)
        if (
          (!missingAssignedDoctor ||
            (!soapError &&
              !doctorValidationError &&
              !assignedDoctorValidationError)) &&
          (!missingNdcNumber || !variationError) &&
          ((!missingClientAddress && !missingClientPhoneNumber) ||
            !clientsError)
        ) {
          onOk()
          onClose?.()
        }
      }
    }
  }, [
    isSaving,
    isDoctorSaving,
    isVariationSaving,
    isClientSaving,
    doctorValidationError,
  ])

  const submit = () => {
    const isValid =
      (!missingAssignedDoctor || validateDoctor()) &&
      (!missingNdcNumber || ndcFormRef.current?.validate()) &&
      (!missingClientAddress || addressFormRef.current?.validate()) &&
      (!missingClientPhoneNumber || validatePhone())

    if (isValid) {
      setIsSaving(true)
      setDoctorValidationError(false)
      if (missingAssignedDoctor) {
        dispatch(selectDoctor(doctorId.value, false))
      }
      if (missingNdcNumber && inventoryId && variation?.id) {
        dispatch(
          partialUpdateVariation(inventoryId, {
            id: variation.id,
            nationalDrugCode: ndcFormRef.current?.getNationalDrugCode(),
          }),
        )
      }
      if (
        missingClientAddress ||
        missingClientPhoneNumber ||
        clientGender.value ||
        clientDOB.value
      ) {
        const newClient = {
          id: client?.id,
          ...(missingClientAddress ? addressFormRef.current?.get() : {}),
          ...(missingClientPhoneNumber
            ? {
                mobilePhone: PhoneUtils.parsePhoneNumber(mobilePhone.value),
                otherPhone: PhoneUtils.parsePhoneNumber(otherPhone.value),
              }
            : {}),
          ...(clientGender.value ? { genderId: clientGender.value } : {}),
          ...(clientDOB.value ? { dateOfBirth: clientDOB.value } : {}),
        }
        dispatch(partialUpdateClient(newClient))
      }
    }
  }

  if (missingAssignedDoctor && doctors.length === 0) {
    return null
  }

  return (
    <PuiDialog
      aria-labelledby="assign-doctor-dialog"
      classes={{
        paper: missingOnlyDoctor ? classes.paperSmall : classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <Grid
        container
        justifyContent={missingOnlyDoctor ? 'center' : 'flex-start'}
        pb={3}
        pt={4}
        px={3}
      >
        <Grid container item alignItems="center" direction="column">
          <Grid item>
            <WarningIcon className={classes.icon} />
          </Grid>
          <Grid item mb={2} mt={1}>
            <Text>
              {missingOnlyDoctor
                ? t(
                    'Dialogs:PRESCRIPTION_DIALOG.PRESCRIPTION_MISSING_FIELDS_DIALOG.PLEASE_ASSIGN_A_DOCTOR',
                  )
                : t(
                    'Dialogs:PRESCRIPTION_DIALOG.PRESCRIPTION_MISSING_FIELDS_DIALOG.INCLUDE_PRESCRIPTION',
                  )}
            </Text>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={missingOnlyDoctor ? 'center' : 'flex-start'}
          spacing={3}
        >
          {missingNdcNumber && (
            <NdcNumberForm
              required
              classes={{
                titleContainer: classes.sectionTitleContainer,
              }}
              nationalDrugCode={variation?.nationalDrugCode || ''}
              ref={ndcFormRef}
            />
          )}
          {missingClientAddress && (
            <>
              <Grid item md={12} xs={12}>
                <Text strong variant="body2">
                  {t(
                    'Dialogs:PRESCRIPTION_DIALOG.PRESCRIPTION_MISSING_FIELDS_DIALOG.CLIENT_ADDRESS',
                  )}
                </Text>
              </Grid>
              <AddressForm required ref={addressFormRef} userId={client?.id} />
            </>
          )}
          {missingClientPhoneNumber && (
            <>
              <Grid item md={12} xs={12}>
                <Text strong variant="body2">
                  {t(
                    'Dialogs:PRESCRIPTION_DIALOG.PRESCRIPTION_MISSING_FIELDS_DIALOG.CLIENT_PHONE_NUMBER',
                  )}
                </Text>
              </Grid>
              <Grid item md={6} xs={12}>
                <PhoneInput fullWidth field={mobilePhone} />
              </Grid>
              <Grid item md={6} xs={12}>
                <PhoneInput fullWidth field={otherPhone} />
              </Grid>
            </>
          )}
          {missingAssignedDoctor && (
            <>
              {!missingOnlyDoctor && (
                <Grid item md={12} xs={12}>
                  <Text strong variant="body2">
                    {t('Common:ASSIGNED_DOCTOR')}
                  </Text>
                </Grid>
              )}
              <Grid item width={240}>
                <FormControl fullWidth error={!doctorId.valid} margin="normal">
                  <InputLabel htmlFor="doctor-select">
                    {doctorId.label}*
                  </InputLabel>
                  <PuiSelect
                    field={doctorId}
                    input={<Input id="doctor-select" />}
                    items={getActiveNamedPersons(doctors)}
                  />
                </FormControl>
              </Grid>
            </>
          )}
          {(missingClientDOB || missingClientGender) && (
            <>
              <Grid item md={12} xs={12}>
                <Text strong variant="body2">
                  {t(
                    'Dialogs:PRESCRIPTION_DIALOG.PRESCRIPTION_MISSING_FIELDS_DIALOG.OTHER_CLIENT_INFORMATION',
                  )}
                </Text>
              </Grid>
              {missingClientDOB && (
                <Grid item md={3} xs={6}>
                  <Calendar
                    fullWidth
                    field={clientDOB}
                    label={clientDOB.label}
                  />
                </Grid>
              )}
              {missingClientGender && (
                <Grid item md={3} width={240} xs={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="client-gender-select">
                      {clientGender.label}
                    </InputLabel>
                    <PuiSelect
                      field={clientGender}
                      input={<Input id="client-gender-select" />}
                      items={Gender}
                    />
                  </FormControl>
                </Grid>
              )}
            </>
          )}
        </Grid>
        <Grid item mt={3}>
          <ButtonWithLoader
            className={classes.button}
            disabled={isSaving}
            loading={isSaving}
            type="submit"
            onClick={submit}
          >
            {t('Common:SAVE_ACTION')}
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default PrescriptionMissingFieldsDialog
