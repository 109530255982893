import React from 'react'
import { Drawer } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ClassesType,
  PuiCloseButton,
  PuiTheme,
} from '@pbt/pbt-ui-components'

export interface RightDrawerProps extends BasePuiDialogProps {
  children: React.ReactNode
  classes?: ClassesType<typeof useStyles>
}

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      maxWidth: 500,
      width: '100%',
      top: theme.mixins.toolbar.minHeight,
    },
    closeButton: {},
  }),
  { name: 'RightDrawer' },
)

const RightDrawer = ({
  classes: classesProp,
  open,
  onClose,
  children,
}: RightDrawerProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.paper }}
      open={open}
      variant="persistent"
    >
      <PuiCloseButton
        classes={{ iconButton: classes.closeButton }}
        onClick={onClose}
      />
      {children}
    </Drawer>
  )
}

export default RightDrawer
