import React from 'react'
import { Grid, GridProps, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, PuiTheme, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      cursor: 'pointer',
      width: 'auto',
    },
    iconButton: {
      backgroundColor: theme.colors.menuSubheader,
      '&&&&:hover': {
        backgroundColor: theme.colors.menuSubheader,
      },
      width: 20,
      height: 20,
      padding: 0,
      marginRight: theme.spacing(1),
    },
    icon: {
      color: theme.colors.badgeColor,
      width: 16,
      height: 16,
    },
  }),
  { name: 'TableActionButton' },
)

export interface TableActionButtonProps extends GridProps {
  Icon: React.JSXElementConstructor<any>
  classes?: ClassesType<typeof useStyles>
  label: React.ReactNode
}

const TableActionButton = ({
  Icon,
  classes: classesProp,
  label,
  ...rest
}: TableActionButtonProps) => {
  const classes = useStyles({ classes: classesProp })
  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      py={1}
      {...rest}
    >
      <IconButton disableRipple className={classes.iconButton} size="large">
        <Icon className={classes.icon} />
      </IconButton>
      <Text variant="lowAccent2">{label}</Text>
    </Grid>
  )
}

export default TableActionButton
