import React from 'react'
import { CurrencyTextField, Field } from '@pbt/pbt-ui-components'

import { InvoiceLineItem } from '~/types'

import { useGetIsDeclinedLineItem } from '../../utils/useDeclineOrderItem'
import ChargeDetailsFieldWrapper from './ChargeDetailsFieldWrapper'

export interface ChargeDetailsCurrencyFieldProps {
  className?: string
  disabled: boolean
  field: Field
  inputClassName?: string
  item: InvoiceLineItem
  wrapperClassName?: string
}

const ChargeDetailsCurrencyField = ({
  className: classNameProp,
  disabled,
  field,
  inputClassName,
  item,
  wrapperClassName,
}: ChargeDetailsCurrencyFieldProps) => {
  const isDeclined = useGetIsDeclinedLineItem(item)
  if (isDeclined) {
    return null
  }

  return (
    <ChargeDetailsFieldWrapper
      className={wrapperClassName}
      disabled={disabled}
      label={field.label}
    >
      <CurrencyTextField
        InputProps={{ disableUnderline: true }}
        className={classNameProp}
        disabled={disabled}
        field={field}
        fullWidth={false}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ className: inputClassName }}
        margin="none"
      />
    </ChargeDetailsFieldWrapper>
  )
}

export default ChargeDetailsCurrencyField
