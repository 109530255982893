import React from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

import {
  Invoice as GraphqlInvoice,
  RefundInvoice,
} from '~/api/graphql/generated/types'
import Link from '~/components/common/link/Link'
import { getPatient } from '~/store/reducers/patients'
import { Invoice } from '~/types'

import { CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT } from '../common/tableConstants'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    link: {
      textDecorationColor: theme.colors.secondaryText,
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'InvoiceTablePatientNameCell' },
)

interface InvoiceTablePatientNameCellProps {
  invoice: Invoice | GraphqlInvoice | RefundInvoice
  multipleRowsLimit?: number
}

export const InvoiceTablePatientNameCell = ({
  invoice,
  multipleRowsLimit,
}: InvoiceTablePatientNameCellProps) => {
  const classes = useStyles()

  const patientId = 'sections' in invoice ? null : invoice.patientId
  const patientFromStore = useSelector(getPatient(patientId))

  if ('sections' in invoice) {
    const sections = R.isNil(multipleRowsLimit)
      ? invoice.sections
      : invoice.sections.slice(0, multipleRowsLimit)

    return (
      <>
        {sections.map(({ patient }, index) => (
          <Box
            alignItems="center"
            display="flex"
            // eslint-disable-next-line react/no-array-index-key
            key={`${patient?.id}_${index}`}
            minHeight={CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT}
            width="100%"
          >
            <Link
              className={classes.link}
              id="credit-adjustment-patient-link"
              target="_self"
              to={`/patient/${patient?.id}`}
            >
              <Text variant="body2">{patient?.name}</Text>
            </Link>
          </Box>
        ))}
      </>
    )
  }

  if (!patientFromStore) {
    return null
  }

  return (
    <Link
      className={classes.link}
      id="credit-adjustment-patient-link"
      target="_blank"
      to={`/patient/${patientFromStore.id}`}
    >
      <Text variant="body2">{patientFromStore.name}</Text>
    </Link>
  )
}
