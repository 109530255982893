import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  Business,
  CircularProgressOverlay,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import AutomaticCommunicationBoopMessagePreview from '~/components/common/automaticCommunication/AutomaticCommunicationBoopMessagePreview'
import AutomaticCommunicationEmailPreview from '~/components/common/automaticCommunication/AutomaticCommunicationEmailPreview'
import AutomaticCommunicationTextMessagePreview from '~/components/common/automaticCommunication/AutomaticCommunicationTextMessagePreview'
import {
  fetchReminderTemplatePreview,
  getIsPreviewFetching,
  getReminderPreviewEmail,
  getReminderPreviewTextMessage,
} from '~/store/duck/reminderProtocolSettings'
import { ReminderCommunicationTemplatePreviewInput } from '~/types'
import { useBoopEnabled } from '~/utils/boop'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    emailPreview: {
      marginRight: theme.spacing(2),
      zoom: 0.7,
    },
  }),
  { name: 'AutomaticCommunicationPreview' },
)

export interface AutomaticCommunicationPreviewProps {
  automaticCommunication: ReminderCommunicationTemplatePreviewInput
  business: Business
  className?: string
}

const ReminderAutomaticCommunicationPreview = ({
  business,
  automaticCommunication,
  className,
}: AutomaticCommunicationPreviewProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const emailMessagePreview = useSelector(getReminderPreviewEmail)
  const textMessagePreview = useSelector(getReminderPreviewTextMessage)
  const isFetchingPreview = useSelector(getIsPreviewFetching)
  const showBoopPreview = useBoopEnabled(business)

  useEffect(() => {
    if (automaticCommunication) {
      dispatch(
        fetchReminderTemplatePreview(business.id, automaticCommunication),
      )
    }
  }, [automaticCommunication])

  return (
    <Grid
      container
      item
      className={classNames(className, classes.root)}
      wrap="nowrap"
    >
      <CircularProgressOverlay
        open={isFetchingPreview}
        preloaderText={t('Common:LOADING')}
      />
      <Grid item xs mr={5}>
        <AutomaticCommunicationEmailPreview template={emailMessagePreview} />
      </Grid>
      <Grid item>
        <AutomaticCommunicationTextMessagePreview
          textTemplate={textMessagePreview}
        />
      </Grid>
      {showBoopPreview && (
        <Grid item>
          <AutomaticCommunicationBoopMessagePreview
            business={business}
            textTemplate={textMessagePreview}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default ReminderAutomaticCommunicationPreview
