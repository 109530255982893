import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import * as R from 'ramda'
import {
  ControlButtonGroup,
  ControlButtonGroupName,
  DateFormat,
  Nil,
  PermissionArea,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import ResponsibilityName from '@pbt/pbt-ui-components/src/constants/responsibilityNames'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import ColorPinIcon from '~/components/common/icons/ColorPinIcon'
import { getCRUDByArea, getCurrentBusiness } from '~/store/reducers/auth'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { TimetableEvent } from '~/types'
import { getAppointmentTypeColor } from '~/utils/time'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    rowContainer: {
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tabBorder,
      },
    },
    newLabel: {
      display: 'inline-block',
      marginLeft: theme.spacing(1),
      backgroundColor: theme.colors.newLabel,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    newLabelText: {
      color: theme.colors.link,
    },
    fromNow: {
      color: theme.colors.timelineTitle,
    },
    iconButton: {
      padding: theme.spacing(0.5),
    },
  }),
  { name: 'UpcomingAppointmentsContentItem' },
)

const getText = (appointment: TimetableEvent | Nil) => {
  const typeName = appointment?.type?.name
  const personRoles = appointment?.personRoles || []
  const personResponsibilities = appointment?.personResponsibilities || []

  if (personResponsibilities.length > 0) {
    const doctor = personResponsibilities.find(
      R.pathEq(['responsibility', 'name'], ResponsibilityName.Veterinarian),
    )?.person
    const name = doctor
      ? Utils.getPersonString(doctor)
      : Utils.getPersonString(
          R.path([0, 'person'], appointment?.personResponsibilities),
        )
    return `${typeName} | ${name}`
  }

  if (personRoles.length > 0) {
    const doctor = personRoles.find(
      R.pathEq(['role', 'name'], 'Veterinarian'),
    )?.person
    const name = doctor
      ? Utils.getPersonString(doctor)
      : Utils.getPersonString(R.path([0, 'person'], appointment?.personRoles))

    return `${typeName} | ${name}`
  }

  return `${typeName}`
}

export interface UpcomingAppointmentsContentItemProps {
  appointmentId: string
  onEdit: (appointment: TimetableEvent | Nil) => void
}

const UpcomingAppointmentsContentItem = ({
  appointmentId,
  onEdit,
}: UpcomingAppointmentsContentItemProps) => {
  const classes = useStyles()

  const { t } = useTranslation('Common')

  const currentBusiness = useSelector(getCurrentBusiness)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const appointmentPermission = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )

  const isContextItem = useIsCurrentContextItem(appointment)

  const momentDate = moment(appointment?.scheduledStartDatetime)
  const fromNow = momentDate.fromNow()
  const formattedDate = momentDate.format(DateFormat.FULL_DATE_COMMA)

  const text = getText(appointment)
  const pinColor = appointment?.typeId
    ? getAppointmentTypeColor(currentBusiness, appointment.typeId)
    : undefined

  return (
    <Grid
      container
      item
      className={classes.rowContainer}
      direction="column"
      id={appointment?.id}
      pt={1}
      px={2}
    >
      <Grid item>
        <Text inline variant="body2">
          {formattedDate}
        </Text>
        <Text inline className={classes.fromNow} pl={1} variant="body2">
          {fromNow}
        </Text>
      </Grid>
      <Grid container item alignItems="center">
        <Grid container item xs alignItems="center" wrap="nowrap">
          <ColorPinIcon color={pinColor} mr={1} />
          <Text inline strong>
            {text}
          </Text>
          <BusinessShareIcon
            businessIds={
              appointment?.businessId ? [appointment.businessId] : []
            }
          />
          {appointment?.isNew && (
            <div className={classes.newLabel}>
              <Text inline className={classes.newLabelText} variant="body5">
                {t('Common:NEW').toUpperCase()}
              </Text>
            </div>
          )}
        </Grid>
        <Grid item>
          <ControlButtonGroup
            buttons={[
              appointmentPermission.update &&
                isContextItem && {
                  name: ControlButtonGroupName.EDIT,
                  onClick: () => onEdit(appointment),
                },
            ]}
            classes={{ iconButton: classes.iconButton }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UpcomingAppointmentsContentItem
