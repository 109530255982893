import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { includes, T } from 'ramda'
import {
  BasePuiDialogProps,
  Nil,
  PuiDialog,
  PuiTheme,
  Utils,
} from '@pbt/pbt-ui-components'

import AlertLabel from '~/components/common/labels/AlertLabel'
import DialogNames from '~/constants/DialogNames'
import LabVendorNames from '~/constants/labVendorName'
import { LabOrdersStatus } from '~/constants/SOAPStates'
import {
  finalizeLabOrder,
  getLabOrderIsFinalizing,
} from '~/store/duck/labOrders'
import { getLabOrderStatuses, getLabVendors } from '~/store/reducers/constants'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

const FinalizedOrderDetectionStrategy: Record<string, (data: any) => boolean> =
  {
    [LabVendorNames.IDEXX]: ({ data }: any) =>
      data.indexOf && data.indexOf('ui/done') === 0,
    [LabVendorNames.ANTECH]: T,
  }

const AutoClosingLabVendors = [LabVendorNames.IDEXX]

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: '100%',
      maxWidth: 976,
      position: 'relative',
      [theme.breakpoints.down('lg')]: {
        width: '100vw',
        maxWidth: '100vw',
        margin: 0,
      },
    },
    content: {
      width: '100%',
      maxWidth: '100%',
      overflowX: 'auto',
    },
    iframeContainer: {
      position: 'relative',
      height: 564,
      width: '100%',
    },
    iframeContainerAntech: {
      width: 1040,
      maxWidth: 1040,
      height: 752,
    },
    spinner: {
      position: 'absolute',
      left: 'calc(50% - 16px)',
      top: 'calc(50% - 16px)',
    },
    iframe: {
      border: 'none',
      height: '100%',
      width: '100%',
    },
    alertContainer: {
      padding: theme.spacing(1),
      margin: theme.spacing(1, 3),
    },
  }),
  { name: 'FinalizeLabOrderDialog' },
)

export interface FinalizeLabOrderDialogProps extends BasePuiDialogProps {
  invoiceId?: string
  orderId: string | Nil
  soapBusinessId: string | Nil
  soapId: string | Nil
  statusId?: string
  url: string | Nil
  vendorId: string | Nil
}

const FinalizeLabOrderDialog = ({
  onClose,
  open,
  orderId,
  soapId,
  soapBusinessId,
  statusId,
  url,
  vendorId,
  invoiceId,
}: FinalizeLabOrderDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const LabVendors = useSelector(getLabVendors)
  const isFinalizing = useSelector(getLabOrderIsFinalizing)
  const LabOrdersStatusesList = useSelector(getLabOrderStatuses)
  const { t } = useTranslation(['Common', 'Dialogs'])

  const labVendorName = Utils.getConstantName(vendorId, LabVendors, '')
  const isAntech = labVendorName === LabVendorNames.ANTECH

  const [openFillMissingPricesDialog] = useDialog(
    DialogNames.LAB_TEST_PRICE_VALIDATION,
  )

  const [loading, setLoading] = useState(true)
  const [shouldConfirmOrderFinalized, setShouldConfirmOrderFinalized] =
    useState(true)

  const doClose = () => {
    if (onClose) {
      onClose()
    }
    openFillMissingPricesDialog({ soapId, soapBusinessId })
  }

  const setProceedAfterFinalizing = useCloseAfterCreation(
    doClose,
    getLabOrderIsFinalizing,
  )

  const orderStatus = Utils.getConstantName(statusId, LabOrdersStatusesList)

  const handleClose = () => {
    setLoading(false)
    if (shouldConfirmOrderFinalized) {
      setProceedAfterFinalizing()
      if (orderId) {
        dispatch(finalizeLabOrder(vendorId, orderId, invoiceId))
      }
    } else {
      doClose()
    }
  }

  const handleIframeMessage = (message: any) => {
    const getIsOrderFinalized = FinalizedOrderDetectionStrategy[labVendorName]
    const shouldAutoClose = includes(labVendorName, AutoClosingLabVendors)
    if (getIsOrderFinalized && getIsOrderFinalized(message)) {
      setLoading(true)
      setShouldConfirmOrderFinalized(true)
      if (shouldAutoClose) {
        handleClose()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage)
    return () => {
      window.removeEventListener('message', handleIframeMessage)
    }
  }, [url])

  useEffect(() => {
    if (open && !loading) {
      setLoading(true)
    }
  }, [open])

  return (
    <PuiDialog
      classes={{
        paper: classes.paper,
      }}
      open={open}
      title={
        LabOrdersStatus.CREATED === orderStatus
          ? t('Dialogs:FINALIZE_LAB_ORDER_DIALOG.FINALIZE_AND_PLACE_ORDER')
          : t('Common:VIEW_ORDER')
      }
      onClose={handleClose}
    >
      <Grid container direction="column">
        {isAntech && (
          <AlertLabel
            showIcon
            clamp={2}
            classes={{ root: classes.alertContainer }}
            message={
              <>
                {t('Dialogs:FINALIZE_LAB_ORDER_DIALOG.ALERT_LABEL_NOTE_01')}
                <br />
                {t('Dialogs:FINALIZE_LAB_ORDER_DIALOG.ALERT_LABEL_NOTE_02')}
              </>
            }
          />
        )}
        <Grid item className={classes.content}>
          {(loading || isFinalizing) && (
            <CircularProgress
              className={classes.spinner}
              color="secondary"
              size={32}
            />
          )}
          <Grid
            className={classNames(classes.iframeContainer, {
              [classes.iframeContainerAntech]: isAntech,
            })}
          >
            {/* eslint-disable-next-line react/iframe-missing-sandbox */}
            <iframe
              className={classes.iframe}
              src={url || ''}
              title="lab-order-frame"
              onLoad={() => setLoading(false)}
            />
          </Grid>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default FinalizeLabOrderDialog
