import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  CustomFieldValidatorState,
  Nil,
  NumberUtils,
  PuiDialog,
  PuiTheme,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import NumericInput from '~/components/common/inputs/NumericInput'
import { redeemLoyaltyPoints } from '~/store/actions/finance'
import { getFinanceIsLoading } from '~/store/reducers/finance'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import { formatMoneyRange } from './invoiceUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 600,
      maxWidth: 600,
    },
    totalColumn: {
      backgroundColor: theme.colors.tableLeftColumnBackground,
    },
    label: {
      color: theme.colors.secondaryText,
      fontSize: '1.6rem',
    },
    input: {
      width: 80,
    },
    button: {
      marginLeft: theme.spacing(2),
      width: 152,
    },
  }),
  { name: 'RedeemLoyaltyPointsDialog' },
)

const POINT_COEFFICIENT = 0.1

interface RedeemLoyaltyPointsDialogProps extends BasePuiDialogProps {
  invoiceAmount: number | Nil
  invoiceId: string
  totalPoints: number | Nil
}

const RedeemLoyaltyPointsDialog = ({
  totalPoints,
  invoiceAmount,
  invoiceId,
  open,
  onClose,
}: RedeemLoyaltyPointsDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoading = useSelector(getFinanceIsLoading)
  const { t } = useTranslation(['Common', 'Dialogs', 'Validations'])

  const RedeemOptions = {
    ALL: t('Dialogs:REDEEM_LOYALTY_POINTS_DIALOG.REDEEM_ALL_POINTS'),
    PART: t('Dialogs:REDEEM_LOYALTY_POINTS_DIALOG.REDEEM_PART_POINTS'),
  }

  const preparedInvoiceAmount = invoiceAmount || 0
  const preparedTotalPoints = totalPoints || 0

  const [selectedRedeemOption, setSelectedRedeemOption] = useState(
    RedeemOptions.ALL,
  )

  const setCloseAfterRedeemOn = useCloseAfterCreation(
    onClose,
    getFinanceIsLoading,
  )

  const initialPointsAmount = Math.min(
    preparedTotalPoints,
    Math.round(preparedInvoiceAmount / POINT_COEFFICIENT),
  )

  const validatePointsAmount = ({
    state: { pointsAmount },
  }: CustomFieldValidatorState) => pointsAmount <= initialPointsAmount

  const {
    fields: { pointsAmount },
    validate,
  } = useFields(
    [
      {
        name: 'pointsAmount',
        messages: {
          validatePointsAmount: t('Validations:VALIDATE_POINTS_AMOUNT', {
            initialPointsAmount,
          }),
        },
        validators: [
          {
            validator: validatePointsAmount,
            validatorName: 'validatePointsAmount',
          },
        ],
        initialValue: initialPointsAmount,
      },
    ],
    false,
  )

  const moneyAmount = pointsAmount.value * POINT_COEFFICIENT
  const total = NumberUtils.formatMoney(
    Math.max(preparedInvoiceAmount - moneyAmount, 0),
  )

  useEffect(() => {
    if (selectedRedeemOption === RedeemOptions.ALL) {
      pointsAmount.setValue(initialPointsAmount)
    }
  }, [selectedRedeemOption])

  const handleRedeem = () => {
    if (validate()) {
      setCloseAfterRedeemOn()
      dispatch(redeemLoyaltyPoints(pointsAmount.value, invoiceId))
    }
  }

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader
          className={classes.button}
          loading={isLoading}
          onClick={handleRedeem}
        >
          {t('Common:REDEEM_AWARD_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="redeem-loyalty-points-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      title={t('Dialogs:REDEEM_LOYALTY_POINTS_DIALOG.TITLE')}
      onClose={onClose}
    >
      <Grid container item wrap="nowrap">
        <Grid container item direction="column" pb={12} pt={2} px={3}>
          <Text strong variant="subheading2">
            {`${pointsAmount.value} ${t(
              'Common:POINTS',
            ).toLowerCase()} = ${NumberUtils.formatMoney(moneyAmount)}`}
          </Text>
          <RadioGroup
            name="redeem-options"
            value={selectedRedeemOption}
            onChange={(_, value) => setSelectedRedeemOption(value)}
          >
            {Object.values(RedeemOptions).map((redeemOption) => (
              <Grid
                container
                item
                alignItems="center"
                key={redeemOption}
                wrap="nowrap"
              >
                <FormControlLabel
                  classes={{
                    label: classes.label,
                  }}
                  control={<Radio value={redeemOption} />}
                  label={redeemOption}
                />
                {redeemOption === RedeemOptions.PART &&
                  selectedRedeemOption === RedeemOptions.PART && (
                    <NumericInput
                      className={classes.input}
                      field={pointsAmount}
                      min={1}
                    />
                  )}
              </Grid>
            ))}
          </RadioGroup>
        </Grid>
        <Grid
          container
          item
          className={classes.totalColumn}
          direction="column"
          p={3}
        >
          <Grid container item justifyContent="space-between">
            <Text variant="body">{t('Common:INVOICE_TOTAL')}:</Text>
            <Text variant="body">{formatMoneyRange(invoiceAmount)}</Text>
          </Grid>
          <Grid container item justifyContent="space-between">
            <Text variant="body">{t('Common:REDEEMED_POINTS')}:</Text>
            <Text variant="body">{formatMoneyRange(invoiceAmount, true)}</Text>
          </Grid>
          <Divider />
          <Grid container item justifyContent="space-between">
            <Text variant="body">{t('Common:TOTAL')}:</Text>
            <Text variant="body">{total}</Text>
          </Grid>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default RedeemLoyaltyPointsDialog
