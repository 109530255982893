import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import * as R from 'ramda'
import { LanguageUtils, Nil, PuiTheme, Utils } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getAlertColor } from '~/store/reducers/constants'

export enum AlertColors {
  RED = 'RED',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  GREY = 'GREY',
}

export const AlertColorMap = {
  [AlertColors.RED]: '#E02020',
  [AlertColors.YELLOW]: '#F8B752',
  [AlertColors.GREEN]: '#7DAC1E',
  [AlertColors.BLUE]: '#5A98B1',
  [AlertColors.GREY]: '#949494',
}

export enum AlertColorLevel {
  CLIENT = 'CLIENT',
  PATIENT = 'PATIENT',
}

export const DEF_BUSINESS_COLOR_ID = 'BUSINESS'
// TODO: when backend is ready we can remove constants translations
export const DEF_BUSINESS_COLOR = i18n.t(
  'Constants:ALERT_COLORS.DEF_BUSINESS_COLOR',
)

export const useGetAlertColor = (
  alertColorId: string | Nil,
  alertColorLevel: AlertColorLevel | Nil,
) => {
  const currentBusiness = useSelector(getCurrentBusiness)
  const AlertColor = useSelector(getAlertColor)
  const businessColorId =
    alertColorLevel === AlertColorLevel.CLIENT
      ? currentBusiness?.clientAlertColorId
      : currentBusiness?.patientAlertColorId
  const currentAlertColorId = alertColorId || businessColorId
  const colorName = Utils.getConstantName(
    currentAlertColorId,
    AlertColor,
  ) as AlertColors

  return AlertColorMap[colorName] || AlertColorMap[AlertColors.RED]
}

export const useGetAlertColorsWithBusinessDefault = (
  alertColorLevel: AlertColorLevel,
) => {
  const currentBusiness = useSelector(getCurrentBusiness)
  const AlertColor = useSelector(getAlertColor)

  const alertColors = R.map(
    (item) => ({ ...item, name: LanguageUtils.capitalize(item.name) }),
    AlertColor,
  )

  const businessColorId =
    alertColorLevel === AlertColorLevel.CLIENT
      ? currentBusiness?.clientAlertColorId
      : currentBusiness?.patientAlertColorId

  const businessPatientColor = businessColorId
    ? LanguageUtils.capitalize(
        LanguageUtils.getConstantTranslatedName(businessColorId, AlertColor),
      ) || DEF_BUSINESS_COLOR
    : DEF_BUSINESS_COLOR

  return [
    {
      id: businessColorId || DEF_BUSINESS_COLOR_ID,
      name: i18n.t('Constants:ALERT_COLORS.PRACTICE_DEFAULT_COLOR', {
        businessPatientColor,
      }),
    },
    ...alertColors,
  ]
}

export const AlertLabelColorVariant = {
  NORMAL: 'normal',
  ATTENTION: 'attention',
  ATTENTION_RED: 'attention_red',
  WARNING: 'warning',
  DISABLED: 'disabled',
  ACTIVE: 'active',
  RELAXED: 'relaxed',
  WARNING_LIGHT: 'warning_light',
}

export const getAlertLabelBlockColorMap = (theme: PuiTheme) => ({
  [AlertLabelColorVariant.NORMAL]: theme.colors.tabLabel,
  [AlertLabelColorVariant.ATTENTION]: theme.colors.alertError,
  [AlertLabelColorVariant.ATTENTION_RED]: theme.colors.alertError,
  [AlertLabelColorVariant.WARNING]: theme.colors.alertWarning,
  [AlertLabelColorVariant.DISABLED]: theme.colors.searchPicker,
  [AlertLabelColorVariant.ACTIVE]: theme.colors.title,
  [AlertLabelColorVariant.RELAXED]: theme.colors.newLabel,
  [AlertLabelColorVariant.WARNING_LIGHT]: theme.colors.actionNeededBackground,
})

export const getAlertLabelTextColorMap = (theme: PuiTheme) => ({
  [AlertLabelColorVariant.NORMAL]: theme.colors.profileText,
  [AlertLabelColorVariant.ATTENTION]: theme.colors.alertErrorText,
  [AlertLabelColorVariant.ATTENTION_RED]: theme.colors.alertErrorText,
  [AlertLabelColorVariant.WARNING]: theme.colors.alertWarningText,
  [AlertLabelColorVariant.DISABLED]: theme.colors.tabLabel,
  [AlertLabelColorVariant.ACTIVE]: theme.colors.profileText,
  [AlertLabelColorVariant.RELAXED]: theme.colors.title,
  [AlertLabelColorVariant.WARNING_LIGHT]: theme.colors.important,
})

export const useGetAlertLabelColors = (
  variant = AlertLabelColorVariant.ATTENTION,
) => {
  const theme = useTheme<PuiTheme>()

  return {
    alertBlockColor: getAlertLabelBlockColorMap(theme)[variant],
    alertTextColor: getAlertLabelTextColorMap(theme)[variant],
  }
}
