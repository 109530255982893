import React from 'react'
import { useTranslation } from 'react-i18next'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTheme } from '@pbt/pbt-ui-components'

import { useDeclineOrderLabTestItem } from '~/components/dashboard/soapV2/charges/utils/useDeclineOrderItem'
import { partialEditOrder } from '~/store/actions/orders'
import { useLabTestItemDecline } from '~/store/hooks/orders'
import { Order } from '~/types'

import LabTestStateItemButton from '../buttons/LabTestStateItemButton'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    fab: {
      height: 33,
      width: '100%',
      fontSize: '1.2rem',
      fontWeight: 500,
      padding: theme.spacing(0, 1),
    },
    fabSelected: {
      '&&&&:hover': {
        backgroundColor: theme.colors.selectedOption,
      },
      backgroundColor: theme.colors.selectedOption,
      color: theme.colors.primaryText,
    },
    select: {
      fontSize: '1.4rem',
    },
    orderStatusChip: {
      color: theme.colors.title,
      pointerEvents: 'none',
    },
    statusSelect: {
      width: 'fit-content',
      fontSize: '1rem',
    },
  }),
  { name: 'LabTestButtonSection' },
)

export interface LabTestButtonSectionProps {
  editDisabled?: boolean
  order: Order
  readOnly?: boolean
}

const LabTestButtonSection = ({
  order,
  editDisabled,
  readOnly,
}: LabTestButtonSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const { declineDisabled, isDeclinedState, declinedLabTestStateId } =
    useLabTestItemDecline(order, editDisabled)

  const declineLabTest = () =>
    partialEditOrder({
      id: order.id,
      type: order.type,
      stateId: declinedLabTestStateId,
    })

  const onDecline = useDeclineOrderLabTestItem(order, declineLabTest)

  return (
    <Grid container item alignItems="center" spacing={1}>
      <Grid item xs={8}>
        <LabTestStateItemButton disabled={editDisabled} order={order} />
      </Grid>
      {!readOnly && (
        <Grid item xs={4}>
          <Fab
            className={classNames(classes.fab, {
              [classes.fabSelected]: isDeclinedState,
            })}
            color="primary"
            disabled={declineDisabled}
            type="button"
            variant="extended"
            onClick={onDecline}
          >
            {isDeclinedState
              ? t('Common:DECLINED')
              : t('Common:DECLINE_ACTION')}
          </Fab>
        </Grid>
      )}
    </Grid>
  )
}

export default LabTestButtonSection
