import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      top: 0,
      height: `calc(100% - ${theme.spacing(1)})`,
      position: 'sticky',
      padding: theme.spacing(4, 2, 0),
      border: theme.constants.filterBorder,
      borderStyle: 'dashed',
    },
  }),
  { name: 'WhiteboardEmptyColumn' },
)

const WhiteboardEmptyColumn = () => {
  const classes = useStyles()
  const { t } = useTranslation('TimeTable')

  return (
    <Grid container item className={classes.root} justifyContent="center">
      <Text align="center" variant="lowAccent2">
        {t('TimeTable:CLICK_DRAG_INFO')}
      </Text>
    </Grid>
  )
}

export default WhiteboardEmptyColumn
