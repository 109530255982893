import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  PuiDialog,
  PuiDialogProps,
  PuiTheme,
  Text,
  TextWithTooltip,
} from '@pbt/pbt-ui-components'

import ItemWithChildren from './ItemWithChildren'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    container: {
      minWidth: 400,
    },
    leftButton: {
      minWidth: 175,
      marginRight: theme.spacing(2),
    },
    rightButton: {
      minWidth: 228,
    },
  }),
  { name: 'ListWithChildrenDialog' },
)

export interface ListWithChildrenDialogProps extends PuiDialogProps {
  StateComponent?: React.JSXElementConstructor<any>
  addButtonLabel: string
  createButtonLabel: string
  emptyPlaceholder?: string
  getChildName?: (item: any) => string
  getName: (item: any) => string
  isFetching?: boolean
  isSaving?: boolean
  items?: any[]
  onAddClick: (itemId: string) => void
  onCreateClick: () => void
  onEyeClick?: (itemId: string) => void
  propWithChildren?: string
  subTitle?: React.ReactNode
  title?: React.ReactNode
  tooltipText?: React.ReactNode
}

const ListWithChildrenDialog = ({
  classes: classesProp,
  StateComponent,
  createButtonLabel,
  addButtonLabel,
  emptyPlaceholder = '',
  items: itemsProp,
  isFetching,
  isSaving,
  propWithChildren,
  onEyeClick,
  getName,
  getChildName,
  onCreateClick,
  onAddClick,
  tooltipText,
  title,
  subTitle,
  open,
  ...props
}: ListWithChildrenDialogProps) => {
  const items = itemsProp || []
  const classes = useStyles({ classes: classesProp })

  const [selectedItemId, setSelectedItemId] = useState<string>()

  useEffect(() => {
    if (open) {
      setSelectedItemId(undefined)
    }
  }, [open])

  const handleSelectionChange = (itemId: string) => () => {
    setSelectedItemId(itemId === selectedItemId ? undefined : itemId)
  }

  return (
    <PuiDialog
      actions={
        <>
          <ButtonWithLoader
            className={classes.leftButton}
            disabled={isSaving || !selectedItemId}
            loading={isSaving}
            type="submit"
            onClick={() => {
              if (selectedItemId) {
                onAddClick(selectedItemId)
              }
            }}
          >
            {addButtonLabel}
          </ButtonWithLoader>
          <ButtonWithLoader
            className={classes.rightButton}
            color="secondary"
            type="submit"
            onClick={onCreateClick}
          >
            {createButtonLabel}
          </ButtonWithLoader>
        </>
      }
      header={
        <>
          <TextWithTooltip tooltipText={tooltipText} variant="h2">
            {title}
          </TextWithTooltip>
          {subTitle && (
            <Text color="secondary" mb={1} variant="body2">
              {subTitle}
            </Text>
          )}
        </>
      }
      open={open}
      scroll="paper"
      {...props}
    >
      <Grid
        container
        className={classes.container}
        direction="column"
        pb={3}
        px={3}
      >
        {isFetching ? (
          <Grid container alignItems="center" justifyContent="center" mt={2}>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : items.length > 0 ? (
          <Grid container item direction="column">
            {items.map((item) => (
              <ItemWithChildren
                StateComponent={StateComponent}
                getChildName={getChildName}
                getName={getName}
                handleSelectionChange={handleSelectionChange(item.id)}
                isSelected={selectedItemId === item.id}
                item={item}
                key={item.id}
                propWithChildren={propWithChildren}
                onEyeClick={onEyeClick}
              />
            ))}
          </Grid>
        ) : (
          <Text align="center" variant="body2">
            {emptyPlaceholder}
          </Text>
        )}
      </Grid>
    </PuiDialog>
  )
}

export default ListWithChildrenDialog
