import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid, Radio } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  DateUtils,
  Nil,
  Patient,
  PuiDialog,
  PuiTheme,
  Text,
  useFields,
  User,
} from '@pbt/pbt-ui-components'

import UserSelect from '~/components/common/inputs/UserSelect'
import AssignPatientSearch from '~/components/common/orphans-assignment/AssignPatientSearch'
import PatientSearchResult from '~/components/common/orphans-assignment/PatientSearchResult'
import i18n from '~/locales/i18n'
import { assignOrder, getIsAssigningOrder } from '~/store/duck/imagingDashboard'
import {
  cleanUpLastAppointments,
  fetchLastMonthAppointments,
  getAppointmentsList,
  getLastAppointmentsIsLoading,
} from '~/store/duck/lastAppointments'
import { useGetIdexxImagingId } from '~/store/hooks/constants'
import { SoapAppointment } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import DetailsBackButton from '../clients/DetailsBackButton'
import ClientView from '../timetable/scheduler/appointment/ClientView'

const DEFAULT_APPOINTMENT = {
  typeName: i18n.t('Dialogs:IMAGING_ASSIGNMENT_DIALOG.NO_EVENT'),
}

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      overflow: 'visible',
    },
    paperSm: {
      width: 660,
      maxWidth: 660,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    paperLg: {
      width: 'calc(100% - 64px)',
      maxWidth: 'calc(100% - 64px)',
    },
    dialogContentRoot: {
      overflow: 'visible',
    },
  }),
  { name: 'ImagingAssignmentDialog' },
)

const AssignmentDialogDisplay = {
  SEARCH_BOX: 'search-box',
  SEARCH_RESULT: 'search-result',
  SINGLE_PATIENT: 'single-patient',
}

export interface ImagingAssignmentDialogProps extends BasePuiDialogProps {
  orderId: string
  orderInfo?: string
}

const ImagingAssignmentDialog = ({
  orderId,
  orderInfo = '',
  open,
  onClose,
}: ImagingAssignmentDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const [actualDisplay, setActualDisplay] = useState(
    AssignmentDialogDisplay.SEARCH_BOX,
  )
  const [selectedClientId, setSelectedClientId] = useState<string>()
  const [selectedPatientId, setSelectedPatientId] = useState<string>()
  const [searchTerm, setSearchTerm] = useState('')
  const [fieldsQuery, setFieldsQuery] = useState('')
  const [additionalParams, setAdditionalParams] = useState<[string, string][]>(
    [],
  )
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<string>()

  const patientAppointments = useSelector(getAppointmentsList)
  const isLoadingPatientAppointments = useSelector(getLastAppointmentsIsLoading)

  const appointments = [...patientAppointments, DEFAULT_APPOINTMENT]

  const vendorId = useGetIdexxImagingId()

  const {
    fields: { assignedVetId },
    validate,
  } = useFields([
    {
      name: 'assignedVetId',
      label: t('Common:ASSIGN_A_DOCTOR'),
      validators: ['required'],
    },
  ])

  useEffect(() => {
    if (selectedPatientId) {
      dispatch(fetchLastMonthAppointments(selectedPatientId, true))
    }
  }, [selectedPatientId])

  const handleBackToSearchResults = () => {
    setSelectedClientId(undefined)
    setSelectedPatientId(undefined)
    setActualDisplay(AssignmentDialogDisplay.SEARCH_BOX)
  }

  const handlePatientSelection = (
    clientId: string,
    patientId: string | Nil,
  ) => {
    // NOTE: do not handle clicks on client-only box
    if (patientId) {
      dispatch(cleanUpLastAppointments())
      setSelectedClientId(clientId)
      setSelectedPatientId(patientId)
      setActualDisplay(AssignmentDialogDisplay.SINGLE_PATIENT)
    }
  }

  const handleDialogClosed = () => {
    dispatch(cleanUpLastAppointments())
    if (onClose) {
      onClose()
    }
  }

  const closeAfterAssignment = useCloseAfterCreation(
    handleDialogClosed,
    getIsAssigningOrder,
  )

  const handleAddResults = (eventId: string | Nil) => {
    if (validate()) {
      dispatch(
        assignOrder({
          eventId,
          orderId,
          vendorId,
          vetId: assignedVetId.value,
          selectedClientId,
          selectedPatientId,
        }),
      )
      closeAfterAssignment()
    }
  }

  const searchResultActionButtons = [
    {
      title: t('Common:ADD_RESULTS'),
      onClick: (patient: Patient, client: User) => {
        const appointmentId = patient?.lastAppointment?.appointmentId
        setSelectedAppointmentId(appointmentId)
        handlePatientSelection(client.id, patient.id)
      },
    },
  ]

  return (
    <PuiDialog
      actions={
        actualDisplay === AssignmentDialogDisplay.SINGLE_PATIENT ? (
          <ButtonWithLoader
            onClick={() => handleAddResults(selectedAppointmentId)}
          >
            {t('Common:ADD_IMAGING_RESULTS')}
          </ButtonWithLoader>
        ) : null
      }
      aria-labelledby="imaging-assignment-dialog"
      classes={{
        paper: classNames(classes.paper, {
          [classes.paperSm]:
            actualDisplay !== AssignmentDialogDisplay.SEARCH_RESULT,
          [classes.paperLg]:
            actualDisplay === AssignmentDialogDisplay.SEARCH_RESULT,
        }),
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={t('Common:ASSIGN_IMAGE_TO_PATIENT')}
      onClose={handleDialogClosed}
    >
      {actualDisplay === AssignmentDialogDisplay.SEARCH_BOX && (
        <AssignPatientSearch
          actionBtnTitle={t('Common:ASSIGN_IMAGING_RESULT')}
          info={orderInfo}
          onExpand={(newSearchTerm, newFieldsQuery, newAdditionalParams) => {
            setSearchTerm(newSearchTerm)
            setFieldsQuery(newFieldsQuery || '')
            setAdditionalParams(newAdditionalParams || [])
            setActualDisplay(AssignmentDialogDisplay.SEARCH_RESULT)
          }}
          onItemClick={handlePatientSelection}
        />
      )}
      {actualDisplay === AssignmentDialogDisplay.SINGLE_PATIENT && (
        <Grid container item direction="column" wrap="nowrap">
          <Grid item my={1} px={1.5}>
            <DetailsBackButton onClick={handleBackToSearchResults}>
              {t('Common:BACK_TO_SEARCH')}
            </DetailsBackButton>
          </Grid>
          <ClientView
            clientId={selectedClientId}
            patientId={selectedPatientId}
          />
          <Grid container p={2}>
            {isLoadingPatientAppointments || !patientAppointments.length ? (
              <Grid container item justifyContent="center">
                <CircularProgress color="secondary" size={32} />
              </Grid>
            ) : (
              appointments.map(
                ({ appointmentId, startDate, typeName }: SoapAppointment) => (
                  <Grid container alignItems="center" key={appointmentId}>
                    <Grid item>
                      <Radio
                        checked={selectedAppointmentId === appointmentId}
                        onChange={() => setSelectedAppointmentId(appointmentId)}
                      />
                    </Grid>
                    <Grid item>
                      <Text variant="body">
                        {typeName} {DateUtils.formatDate(startDate)}
                      </Text>
                    </Grid>
                  </Grid>
                ),
              )
            )}
            <Grid item mt={1} pl={1} xs={5}>
              <UserSelect field={assignedVetId} label={assignedVetId.label} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {actualDisplay === AssignmentDialogDisplay.SEARCH_RESULT && (
        <PatientSearchResult
          actionButtons={searchResultActionButtons}
          additionalParams={additionalParams}
          fieldsQuery={fieldsQuery}
          searchTerm={searchTerm}
          onItemClick={handlePatientSelection}
          onSearchCancelled={handleBackToSearchResults}
        />
      )}
    </PuiDialog>
  )
}

export default ImagingAssignmentDialog
