import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import SearchIcon from '@mui/icons-material/Search'
import {
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
  InputBase,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { useDebounce } from 'use-debounce'
import {
  ClassesType,
  Defaults,
  PermissionArea,
  PuiTheme,
  Utils,
} from '@pbt/pbt-ui-components'

import SearchResults from '~/components/common/lists/SearchResults'
import SearchContext, { SearchLabel } from '~/constants/searchContext'
import { fetchSuggestionResults } from '~/store/actions/search'
import { useSearchEnabled } from '~/store/hooks/search'
import { getGroupCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getSuggestionIsReceiving,
  getSuggestionResultsWithStatuses,
} from '~/store/reducers/search'
import { SuggestionResult } from '~/types'
import { getUrlSearchParam } from '~/utils'

import AdvancedClientSearch from './AdvancedClientSearch'
import AdvancedPracticeSearch from './AdvancedPracticeSearch'
import { ContextAvailabilityMap } from './headerUtils'
import ToolbarContextSelect from './ToolbarContextSelect'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    iconButton: {
      padding: theme.spacing(1),
    },
    searchIcon: {
      width: 20,
      height: 20,
      color: theme.colors.searchButton,
    },
    advancedSearchText: {
      fontSize: '1.4rem',
      color: theme.colors.tabLabel,
      cursor: 'pointer',
    },
    advancedSearchIcon: {
      width: 20,
      height: 20,
    },
    inputRoot: {
      width: '100%',
      color: theme.colors.secondaryText,
    },
    searchPickerContainer: {
      width: 167,
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above', 2),
    },
    searchBox: {
      height: 37,
      backgroundColor: theme.colors.searchPicker,
      border: `1px solid ${theme.colors.searchPicker}`,
      borderRadius: '0 19px 19px 0',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above', 2),
      borderLeft: 'none',
    },
    searchBoxWithOffset: {
      paddingLeft: theme.spacing(1),
    },
    searchBoxActive: {
      border: theme.constants.activeFieldBorder,
      borderLeft: 'none',
    },
    progressContainer: {
      display: 'flex',
    },
    progress: {
      color: theme.colors.disabledLabelText,
      marginRight: 4,
    },
    popperContainer: {
      width: '100%',
      position: 'relative',
    },
  }),
  { name: 'Search' },
)

export const INCLUDE_INACTIVE_QUERY_KEY = 'includeInactive'
export const ONLY_SHARED_QUERY_KEY = 'onlyShared'
export const GROUP_SEARCH_REQUIRE = 'groupSearchRequire'

const LocationContextMap: Record<string, SearchContext> = {
  '/clients': SearchContext.CLIENT_PATIENTS,
  '/contacts': SearchContext.CONTACTS,
  '/on_hand': SearchContext.ON_HAND_CATALOG,
  '/inventories': SearchContext.INVENTORY,
  '/lab-tests': SearchContext.LAB_TESTS,
  '/marketplace': SearchContext.MARKETPLACE,
  '/procedures': SearchContext.PROCEDURES,
  '/reminder-protocols': SearchContext.REMINDER_PROTOCOLS,
  '/tasks-dashboard': SearchContext.TASK_DASHBOARD,
  '/imaging-dashboard': SearchContext.IMAGING_DASHBOARD,
  '/practices': SearchContext.PRACTICES,
}

const ContextLocationMap = {
  [SearchContext.CLIENT_PATIENTS]: '/clients',
  [SearchContext.CONTACTS]: '/admin/general/contacts',
  [SearchContext.INVENTORY]: '/admin/catalog/inventories',
  [SearchContext.ON_HAND_CATALOG]: '/admin/catalog/inventories/on_hand',
  [SearchContext.LAB_TESTS]: '/admin/catalog/lab-tests',
  [SearchContext.MARKETPLACE]: '/admin/general/marketplace',
  [SearchContext.PROCEDURES]: '/admin/catalog/procedures',
  [SearchContext.REMINDER_PROTOCOLS]: '/admin/catalog/reminder-protocols',
  [SearchContext.TASK_DASHBOARD]: '/tasks-dashboard',
  [SearchContext.IMAGING_DASHBOARD]: '/imaging-dashboard',
  [SearchContext.PRACTICES]: '/admin/general/practices',
}

const ContextNavigationMap = {
  [SearchContext.CLIENT_PATIENTS]: (clientId: string, patientId = '') =>
    `/client/${clientId}/patient/${patientId}`,
  [SearchContext.CONTACTS]: (contactId: string) =>
    `/admin/general/contacts/${contactId}`,
  [SearchContext.INVENTORY]: (inventoryId: string, catalog = 'catalog') =>
    `/admin/catalog/inventories/${catalog}/${inventoryId}`,
  [SearchContext.LAB_TESTS]: (labTestId: string) =>
    `/admin/catalog/lab-tests/${labTestId}`,
  [SearchContext.MARKETPLACE]: (marketplaceId: string) =>
    `/admin/general/marketplace/${marketplaceId}`,
  [SearchContext.PROCEDURES]: (procedureId: string) =>
    `/admin/catalog/procedures/${procedureId}`,
  [SearchContext.REMINDER_PROTOCOLS]: (reminderProtocolId: string) =>
    `/admin/catalog/reminder-protocols/${reminderProtocolId}`,
  [SearchContext.TASK_DASHBOARD]: (taskId: string) =>
    `/tasks-dashboard/${taskId}`,
  [SearchContext.IMAGING_DASHBOARD]: (imagingRecordId: string) =>
    `/imaging-dashboard/${imagingRecordId}`,
  [SearchContext.PRACTICES]: (practiceId: string) =>
    `/admin/general/practices/${practiceId}`,
  [SearchContext.ON_HAND_CATALOG]: (variationId: string) =>
    `/admin/catalog/inventories/on_hand/${variationId}`,
}

const ContextAdvancedSearchMap: Partial<
  Record<SearchContext, React.JSXElementConstructor<any>>
> = {
  [SearchContext.CLIENT_PATIENTS]: AdvancedClientSearch,
  [SearchContext.PRACTICES]: AdvancedPracticeSearch,
}

const getContextByLocation = (pathname: string) => {
  const match = Object.keys(LocationContextMap).find((path) =>
    pathname.includes(path),
  )
  return match ? LocationContextMap[match] : SearchContext.CLIENT_PATIENTS
}

export interface SearchProps {
  classes?: ClassesType<typeof useStyles>
  minSearchLength?: number
}

const Search = ({
  classes: classesProp,
  minSearchLength = Defaults.MIN_SEARCH_LENGTH,
}: SearchProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const { t } = useTranslation(['Abbreviations', 'Common', 'Search'])

  const isReceiving = useSelector(getSuggestionIsReceiving)
  const searchResults = useSelector(getSuggestionResultsWithStatuses)

  const { read: patientGroupReadPermissions } = useSelector(
    getGroupCRUDByArea(PermissionArea.PATIENT),
  )

  const locationContext = getContextByLocation(location.pathname)
  const newContextAvailability = ContextAvailabilityMap[locationContext]
  const isFeatureToggleContextEnabled = useSelector(
    getFeatureToggle(newContextAvailability?.feature),
  )

  const isLocationContextEnabled = newContextAvailability?.feature
    ? isFeatureToggleContextEnabled
    : true

  const isSmallScreen = useMediaQuery('(max-width:1300px)')

  const [currentSearchResults, setCurrentSearchResults] = useState<
    SuggestionResult[]
  >([])
  const [context, setContext] = useState(SearchContext.CLIENT_PATIENTS)
  const [isOpen, setIsOpen] = useState(false)
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false)
  const [rawSearchTerm, setRawSearchTerm] = useState('')

  const [searchTerm] = useDebounce(
    rawSearchTerm.trim(),
    Defaults.DEBOUNCE_ACTION_TIME,
  )

  const initialSearch = getUrlSearchParam('query', location.search)
  const searchEnabled = useSearchEnabled(context)

  const isClientSearchContext = context === SearchContext.CLIENT_PATIENTS

  useEffect(() => {
    if (initialSearch !== rawSearchTerm) {
      setRawSearchTerm(initialSearch || '')
    }
  }, [initialSearch])

  useEffect(() => {
    if (searchResults !== currentSearchResults) {
      setCurrentSearchResults(searchResults)
    }
  }, [searchResults])

  const updateContext = (newContext: SearchContext) => {
    setCurrentSearchResults([])
    setContext(newContext)
    setIsAdvancedSearchOpen(false)
  }

  useEffect(() => {
    if (
      isLocationContextEnabled &&
      locationContext &&
      locationContext !== context
    ) {
      updateContext(locationContext)
      setRawSearchTerm('')
    }
  }, [location.pathname])

  const getSearchLength = () => {
    if (isClientSearchContext) {
      return Defaults.CLIENTS_SEARCH_LENGTH
    }
    return minSearchLength
  }

  const getClientsIncludeInactive = () => {
    const clientsPage = location.pathname.includes(
      ContextLocationMap[SearchContext.CLIENT_PATIENTS],
    )
    if (!clientsPage) {
      return true
    }

    return (
      getUrlSearchParam(INCLUDE_INACTIVE_QUERY_KEY, location.search) === 'true'
    )
  }

  const getIncludeInactiveSearchParameter = () =>
    context !== SearchContext.CLIENT_PATIENTS || getClientsIncludeInactive()

  useEffect(() => {
    if (
      searchEnabled &&
      searchTerm &&
      !isAdvancedSearchOpen &&
      searchTerm.length >= getSearchLength()
    ) {
      if (!initialSearch) {
        setIsOpen(true)
      }
      dispatch(
        fetchSuggestionResults({
          searchContext: context,
          searchTerm,
          includeInactive: getIncludeInactiveSearchParameter(),
        }),
      )
    } else {
      setIsOpen(false)
    }
  }, [context, searchTerm, minSearchLength])

  const openSearchResults = () => {
    const queryParams = new URLSearchParams()

    if (rawSearchTerm) {
      queryParams.append('query', rawSearchTerm)
      if (isClientSearchContext && getClientsIncludeInactive()) {
        queryParams.append(INCLUDE_INACTIVE_QUERY_KEY, 'true')
      }
    }

    navigate(`${ContextLocationMap[context]}?${queryParams}`)
    setIsOpen(false)
  }

  const onAdvancedSearch = (
    fieldsQuery: string,
    additionalParams?: [string, string][],
  ) => {
    const queryParams = new URLSearchParams()

    if (fieldsQuery) {
      setRawSearchTerm('')
      queryParams.append('fieldsQuery', fieldsQuery)
    }

    if (additionalParams) {
      setRawSearchTerm('')
      additionalParams.forEach(([key, value]) => {
        queryParams.append(key, value)
      })
    }

    navigate(`${ContextLocationMap[context]}?${queryParams}`)
    setIsAdvancedSearchOpen(false)
    setIsOpen(false)
  }

  const handleNavigation = (
    itemId: string,
    subItemId: string,
    item: string,
  ) => {
    const getNavigationUrl = ContextNavigationMap[context]
    if (navigator) {
      const queryParams = new URLSearchParams()
      queryParams.append('query', item)
      const url = getNavigationUrl(itemId, subItemId)
      navigate(`${url}?${queryParams}`)
    }
  }

  const hasSubItems = searchResults.some(({ subItem }) => subItem)

  const AdvancedSearchComponent = ContextAdvancedSearchMap[context]
  const isAdvancedSearchEnabled = Boolean(AdvancedSearchComponent)

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      onClickAway={() => setIsOpen(false)}
    >
      <Grid container>
        {isAdvancedSearchOpen && AdvancedSearchComponent && (
          <div
            aria-labelledby="advanced-search-popup"
            className={classes.popperContainer}
          >
            <AdvancedSearchComponent open onSearch={onAdvancedSearch} />
          </div>
        )}
        {isOpen &&
          !isAdvancedSearchOpen &&
          (searchResults.length > 0 || !isReceiving) && (
            <div className={classes.popperContainer}>
              <SearchResults
                hasSubItems={hasSubItems}
                open={isOpen}
                openSearchResults={openSearchResults}
                results={searchResults}
                searchTerm={searchTerm}
                showContextIds={
                  patientGroupReadPermissions && isClientSearchContext
                }
                suggestionsEnabled={isClientSearchContext}
                onItemClick={(id, subItemId, item) => {
                  handleNavigation(id, subItemId, item)
                  setIsOpen(false)
                  setRawSearchTerm('')
                }}
                onSuggestionClick={onAdvancedSearch}
              />
            </div>
          )}

        <Grid
          container
          item
          alignItems="center"
          className={classes.searchPickerContainer}
          justifyContent="center"
        >
          <ToolbarContextSelect
            contexts={ContextAvailabilityMap}
            isActive={isOpen}
            label={SearchLabel[context]}
            value={context}
            onChange={updateContext}
            onOpen={() => setIsOpen(false)}
          />
        </Grid>
        <Grid
          container
          item
          xs
          alignItems="center"
          className={classNames(
            classes.searchBox,
            isOpen && classes.searchBoxActive,
            !isAdvancedSearchEnabled && classes.searchBoxWithOffset,
          )}
        >
          {isAdvancedSearchEnabled && (
            <Grid item>
              <IconButton
                aria-label={t('Common:SEARCH_ACTION')}
                className={classes.iconButton}
                disabled={!searchEnabled}
                onClick={openSearchResults}
              >
                <SearchIcon className={classes.searchIcon} />
              </IconButton>
            </Grid>
          )}
          <Grid item xs>
            <InputBase
              aria-label={t('Common:SEARCH_ACTION')}
              classes={{
                root: classes.inputRoot,
              }}
              disabled={!searchEnabled}
              inputProps={{ maxLength: 100 }}
              placeholder={t('Common:SEARCH_ACTION')}
              value={rawSearchTerm}
              onChange={Utils.handleFormTextInput(setRawSearchTerm)}
              onFocus={() => {
                if (!isOpen) {
                  if (searchTerm.length >= getSearchLength()) {
                    setIsOpen(true)
                    dispatch(
                      fetchSuggestionResults({
                        searchContext: context,
                        searchTerm,
                        includeInactive: getIncludeInactiveSearchParameter(),
                      }),
                    )
                  }
                }
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  openSearchResults()
                  const target = event.target as HTMLElement
                  target.blur()
                }
              }}
            />
          </Grid>
          <Grid item className={classes.progressContainer}>
            {isReceiving && (
              <CircularProgress className={classes.progress} size={20} />
            )}
          </Grid>
          {!isAdvancedSearchEnabled && (
            <Grid item>
              <IconButton
                disableRipple
                aria-label={t('Common:SEARCH_ACTION')}
                className={classes.iconButton}
                size="large"
                onClick={openSearchResults}
              >
                <SearchIcon className={classes.searchIcon} />
              </IconButton>
            </Grid>
          )}
          {searchEnabled && isAdvancedSearchEnabled && (
            <>
              <label
                className={classes.advancedSearchText}
                htmlFor="universalAdvancedSearchBtn"
              >
                {isSmallScreen
                  ? t('Abbreviations:COMMON.ADVANCED')
                  : t('Search:ADVANCED_SEARCH')}
              </label>
              <IconButton
                disableRipple
                className={classes.iconButton}
                id="universalAdvancedSearchBtn"
                size="large"
                onClick={() => setIsAdvancedSearchOpen(!isAdvancedSearchOpen)}
              >
                {isAdvancedSearchOpen ? (
                  <ExpandLess className={classes.advancedSearchIcon} />
                ) : (
                  <ExpandMore className={classes.advancedSearchIcon} />
                )}
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>
    </ClickAwayListener>
  )
}

export default Search
