import React from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme } from '@pbt/pbt-ui-components'

import { OrderType } from '~/constants/SOAPStates'
import { Order, OrderNestedItem } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    link: {
      color: theme.colors.markerHighlighted,
    },
  }),
  { name: 'ReadonlyOrderTooltip' },
)

const OrderTypeUrlMap: Partial<Record<OrderType, (id: string) => string>> = {
  [OrderType.INVENTORY]: (id: string) =>
    `/admin/catalog/inventories/catalog/${id}`,
  [OrderType.PROCEDURE]: (id: string) => `/admin/catalog/procedures/${id}`,
  [OrderType.LAB_TEST]: (id: string) => `/admin/catalog/lab-tests/${id}`,
}

const ConstantToPropNameMap: Partial<Record<OrderType, OrderNestedItem>> = {
  [OrderType.INVENTORY]: 'inventory',
  [OrderType.PROCEDURE]: 'procedure',
  [OrderType.LAB_TEST]: 'labTest',
}

const toHumanReadable = (type: string) => type.toLowerCase().replace('_', '')

const getOrderUrl = (order: Order) => {
  const propName = ConstantToPropNameMap[order.type]
  const entityId = propName ? order[propName]?.id : undefined
  const getUrl = OrderTypeUrlMap[order.type]
  return getUrl && entityId ? getUrl(entityId) : ''
}

export interface ReadonlyOrderTooltipProps {
  order: Order
}

const ReadonlyOrderTooltip = ({ order }: ReadonlyOrderTooltipProps) => {
  const classes = useStyles()

  const url = getOrderUrl(order)
  return (
    <p>
      <Trans
        components={{
          linkUrl: <Link className={classes.link} to={url} />,
          span: <span />,
        }}
        i18nKey="Soap:READ_ONLY_ORDER_TOOLTIP.TEXT"
        values={{ orderType: toHumanReadable(order.type) }}
      />
    </p>
  )
}

export default ReadonlyOrderTooltip
