import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DialogActions, DialogTitle, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  PuiPopper,
  PuiPopperProps,
  PuiTextField,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import {
  editContact,
  getContact,
  getContactsIsUpdating,
} from '~/store/duck/contacts'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    input: {
      width: 280,
    },
    paper: {
      width: 480,
    },
    dialogTitle: {
      padding: theme.spacing(1.5, 2),
      borderBottom: theme.constants.tableBorder,
    },
    actions: {
      borderTop: theme.constants.tableBorder,
      justifyContent: 'flex-start',
      padding: theme.spacing(1.5, 2),
    },
    addButton: {
      height: 40,
      width: 150,
    },
  }),
  { name: 'MissingGenericContactsPopper' },
)

export interface MissingGenericContactsPopperProps extends PuiPopperProps {
  contactId: string
}

const MissingGenericContactsPopper = ({
  contactId,
  onClose,
  ...rest
}: MissingGenericContactsPopperProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const contact = useSelector(getContact(contactId))
  const isLoading = useSelector(getContactsIsUpdating)
  const { t } = useTranslation(['Common', 'Dialogs'])

  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const closeAfterUpdate = useCloseAfterCreation(onClose, getContactsIsUpdating)

  const handleAddMissingInfo = () => {
    dispatch(editContact({ ...contact!, email, phone }))
    closeAfterUpdate()
  }

  useEffect(() => {
    setEmail(contact?.email || '')
    setPhone(contact?.phone || '')
  }, [contact])

  return (
    <PuiPopper
      classes={{
        paper: classes.paper,
      }}
      onClose={onClose}
      {...rest}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Text variant="h2">
          {t(
            'Dialogs:NEW_CONVERSATION_WITH_STEPS_DIALOG.ADD_INFO_FOR_CONTACT',
            {
              contact: contact?.name,
            },
          )}
        </Text>
      </DialogTitle>
      <Grid container direction="column" p={2}>
        {!contact?.email && (
          <PuiTextField
            className={classes.input}
            label={t('Common:EMAIL')}
            margin="none"
            value={email}
            onChange={Utils.handleFormTextInput(setEmail)}
          />
        )}
        {!contact?.phone && (
          <PuiTextField
            className={classes.input}
            label={t('Common:PHONE')}
            margin="none"
            value={phone}
            onChange={Utils.handleFormTextInput(setPhone)}
          />
        )}
      </Grid>
      <DialogActions className={classes.actions}>
        <ButtonWithLoader
          className={classes.addButton}
          disabled={isLoading}
          loading={isLoading}
          onClick={handleAddMissingInfo}
        >
          {t('Common:ADD_ACTION')}
        </ButtonWithLoader>
      </DialogActions>
    </PuiPopper>
  )
}

export default MissingGenericContactsPopper
