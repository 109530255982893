import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Grid, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  LanguageUtils,
  moment,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { getIsScrollSmoothSupported } from '@pbt/pbt-ui-components/src/utils/browserUtils'

import {
  BoardingScheduleType,
  BoardingScheduleTypeNames,
} from '~/constants/schedulerConstants'
import {
  getSchedulerBoardingColumns,
  getSchedulerBoardingSchedules,
} from '~/store/reducers/scheduler'
import useTimetableDate from '~/utils/useTimetableDate'

import Timetable from '../../../Timetable'
import {
  getSlotId,
  getSlotOffsetPosition,
  getSlotsPositions,
} from '../../../timetableUtils'
import SchedulerHeader from '../../SchedulerHeader'
import BoardingAppointmentCard from './BoardingAppointmentCard'
import BoardingRowHeader from './BoardingRowHeader'

const isScrollSmoothSupported = getIsScrollSmoothSupported()

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    groupHeader: {
      marginTop: theme.spacing(1),
      cursor: 'pointer',
      width: 180,
      height: 20,
    },
    columnItem: {
      width:
        theme.constants.boardingColumnWidth + parseInt(theme.spacing(1), 10),
      minWidth:
        theme.constants.boardingColumnWidth + parseInt(theme.spacing(1), 10),
      '&:not(:last-child)': {
        borderRight: theme.constants.filterBorder,
      },
    },
    columnItemToday: {
      backgroundColor: '#F6FAFB',
    },
    header: {
      paddingLeft: theme.spacing(22),
      paddingBottom: theme.spacing(1),
      paddingRight: 20,
    },
    content: {
      paddingLeft: theme.spacing(3),
    },
    itemContainer: {
      position: 'relative',
      transition: theme.transitions.create(['height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    itemContainerExpanded: {
      borderBottom: theme.constants.fabBorder,
    },
    itemContainerCollapsed: {
      height: 0,
    },
    itemContainerWithExpanderCollapsed: {
      height: 40,
    },
    caret: {
      color: theme.colors.title,
    },
    columnHeader: {
      minHeight: 56,
      position: 'relative',
      zIndex: theme.zIndex.searchShadow,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    columnHeaderUnassigned: {
      marginTop: theme.spacing(2),
    },
    leftColumn: {
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    cardContainer: {
      minHeight: 1, // this is needed so element does not lose scroll offset on collapse
      position: 'relative',
      maxWidth: 0, // hey safari
      minWidth: 'calc(100% - 180px)',
      overflow: 'hidden',
      scrollBehavior: isScrollSmoothSupported ? 'smooth' : undefined,
    },
    cardWrapper: {
      height: '100%',
      minWidth: '100vw',
    },
    grid: {
      overflow: 'hidden',
      position: 'relative',
      left: 180,
      maxWidth: 'calc(100% - 180px)',
    },
    outerCardContainer: {
      overflowY: 'hidden',
    },
    outerCardContainerExpanded: {
      height: '100%',
      transition: theme.transitions.create(['height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    outerCardContainerCollapsed: {
      height: 0,
      transition: theme.transitions.create(['height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  }),
  { name: 'SchedulerBoardingView' },
)

const EXPANDER_HEIGHT = 29
const UNASSIGNED_HEIGHT = 80
const NORMAL_HEIGHT = 72

const SchedulerBoardingView = () => {
  const classes = useStyles()

  const columns = useSelector(getSchedulerBoardingColumns)
  const schedules = useSelector(getSchedulerBoardingSchedules)

  const { selectedDate } = useTimetableDate()

  const [collapsedSchedules, setCollapsedSchedules] = useState<
    BoardingScheduleType[]
  >([])
  const [openCardId, setOpenCardId] = useState<string>()

  const theme = useTheme<PuiTheme>()

  const startDate = moment(R.head(columns), 'YYYY-MM-DD').startOf('day')

  const rowRefs = Array.from(schedules, (schedule) =>
    Array.from({ length: (schedule.items || []).length }, () =>
      React.createRef<HTMLDivElement>(),
    ),
  )

  const onCardClick = useCallback((id: string) => {
    setOpenCardId(id)
  }, [])

  const onCardClose = useCallback(() => {
    setOpenCardId(undefined)
  }, [])

  return (
    <Timetable
      row
      HeaderComponent={SchedulerHeader}
      HeaderProps={{
        classes: {
          header: classes.header,
        },
      }}
      additionalRefs={R.flatten(rowRefs)}
      classes={{
        content: classes.content,
      }}
      columns={columns}
      openShadow={Boolean(openCardId)}
    >
      {schedules.map((schedule, scheduleIndex) => {
        const isUnassigned = schedule.type === BoardingScheduleType.UNASSIGNED
        const isCollapsed = collapsedSchedules.includes(schedule.type)

        return (
          <Grid container item direction="column" key={schedule.type}>
            {schedule.items.map((item, index) => {
              const showExpander = !isUnassigned && index === 0
              const slotsPositions = getSlotsPositions(item.slots)
              const maxNumberOfOverlaps = Math.max(slotsPositions.length - 1, 0)
              const baseHeight =
                (isUnassigned ? UNASSIGNED_HEIGHT : NORMAL_HEIGHT) +
                (showExpander ? EXPANDER_HEIGHT : 0)
              const height = isCollapsed
                ? undefined
                : baseHeight +
                  maxNumberOfOverlaps * parseInt(theme.spacing(8), 10)

              return (
                <Grid
                  container
                  item
                  className={classNames(classes.itemContainer, {
                    [classes.itemContainerExpanded]: !isCollapsed,
                    [classes.itemContainerCollapsed]: isCollapsed,
                    [classes.itemContainerWithExpanderCollapsed]:
                      isCollapsed && showExpander,
                  })}
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={`${item.name || 'unassigned'}-${index}`}
                  style={{ height }}
                  wrap="nowrap"
                >
                  <Grid
                    container
                    item
                    className={classes.leftColumn}
                    direction="column"
                    wrap="nowrap"
                  >
                    {showExpander && (
                      <Grid
                        container
                        item
                        className={classes.groupHeader}
                        justifyContent="space-between"
                        onClick={() =>
                          setCollapsedSchedules(
                            Utils.toggleListItem(
                              schedule.type,
                              collapsedSchedules,
                            ),
                          )
                        }
                      >
                        <Grid item>
                          <Text variant="h4">
                            {LanguageUtils.capitalize(
                              BoardingScheduleTypeNames[schedule.type] ||
                                schedule.type,
                            )}
                          </Text>
                        </Grid>
                        <Grid item>
                          {isCollapsed ? (
                            <ExpandMore className={classes.caret} />
                          ) : (
                            <ExpandLess className={classes.caret} />
                          )}
                        </Grid>
                      </Grid>
                    )}
                    <Grid
                      container
                      item
                      className={classNames(classes.outerCardContainer, {
                        [classes.outerCardContainerExpanded]: !isCollapsed,
                        [classes.outerCardContainerCollapsed]: isCollapsed,
                      })}
                      wrap="nowrap"
                    >
                      <Grid container item>
                        <BoardingRowHeader
                          className={classNames(classes.columnHeader, {
                            [classes.columnHeaderUnassigned]: isUnassigned,
                          })}
                          isUnassigned={isUnassigned}
                          name={LanguageUtils.getTranslatedFieldName(item)}
                        />
                      </Grid>
                      <Grid
                        item
                        className={classes.cardContainer}
                        ref={rowRefs[scheduleIndex][index]}
                      >
                        <Grid container item className={classes.cardWrapper}>
                          {item.slots.map((slot) => {
                            const slotId = getSlotId(slot)
                            const offsetPosition = getSlotOffsetPosition(
                              slot,
                              slotsPositions,
                            )

                            return (
                              <BoardingAppointmentCard
                                appointmentId={slotId}
                                canOpenPopup={
                                  !openCardId || slotId === openCardId
                                }
                                key={slotId}
                                offsetPosition={offsetPosition}
                                slot={slot}
                                startDate={startDate}
                                onClick={(cardId) => onCardClick(cardId!)}
                                onClose={onCardClose}
                              />
                            )
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs
                    className={classes.grid}
                    wrap="nowrap"
                  >
                    {columns.map((column) => {
                      const isToday = moment(column, 'YYYY-MM-DD').isSame(
                        selectedDate,
                        'day',
                      )

                      return (
                        <Grid
                          item
                          className={classNames(classes.columnItem, {
                            [classes.columnItemToday]: isToday,
                          })}
                          key={`${schedule.type}-${column}`}
                        />
                      )
                    })}
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        )
      })}
    </Timetable>
  )
}

export default SchedulerBoardingView
