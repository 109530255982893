import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PhoneUtils, PuiTheme, TextInteractive } from '@pbt/pbt-ui-components'

import { getContact } from '~/store/duck/contacts'
import { getClientIsLoading } from '~/store/reducers/clients'

import Link from '../link/Link'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      height: '100%',
      border: theme.constants.tableBorder,
      borderRadius: 2,
      padding: theme.spacing(1, 2),
    },
  }),
  { name: 'ContactCard' },
)

export interface ContactCardProps {
  contactId: string
}

const ContactCard = ({ contactId }: ContactCardProps) => {
  const classes = useStyles()
  const isLoading: boolean = useSelector(getClientIsLoading)
  const contact = useSelector(getContact(contactId))
  const { t } = useTranslation('Common')

  return (
    <Grid container item className={classes.root} direction="column">
      <TextInteractive isLoading={isLoading} loaderWidth={50} variant="body2">
        <Link to={`/admin/general/contacts/${contactId}`}>{contact?.name}</Link>
      </TextInteractive>
      <TextInteractive isLoading={isLoading} variant="body2">
        {contact?.email}
      </TextInteractive>
      <TextInteractive isLoading={isLoading} loaderWidth={90} variant="body2">
        {`${t('Common:PHONE')}: ${PhoneUtils.formatPhoneNumber(
          contact?.phone,
        )}`}
      </TextInteractive>
    </Grid>
  )
}

export default ContactCard
