import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { KeyboardArrowRight } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ImageScalingUtils,
  PatientAvatar,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { Wings as WingsIcon } from '@pbt/pbt-ui-components/src/icons'

import AlertLabel from '~/components/common/labels/AlertLabel'
import { getSpecies } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'

const AVATAR_SIZE = 40

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    patientAvatar: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
      backgroundColor: theme.colors.badgeColor,
    },
    patientContainer: {
      marginTop: 5,
    },
    showPointer: {
      cursor: 'pointer',
    },
    halfOpacity: {
      opacity: 0.5,
    },
    wingsIcon: {
      fontSize: '3.5rem',
    },
    patientNameContainer: {
      lineHeight: '2.4rem',
      overflow: 'hidden',
    },
    alertLabelContainer: {
      '&:not(:last-of-type)': {
        marginRight: theme.spacing(1),
      },
    },
    alertLabelRoot: {
      height: 24,
    },
    alertLabelText: {
      fontSize: '1.5rem',
    },
    alertLabelIcon: {
      fontSize: '4rem',
    },
    alertsContainer: {
      paddingLeft: theme.spacing(6.5),
    },
  }),
  { name: 'PatientItem' },
)

export interface PatientItemProps {
  disableNavigation?: boolean
  onClick: (patientId?: string) => void
  patientId: string
}

const PatientItem = ({
  patientId,
  disableNavigation,
  onClick,
}: PatientItemProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const {
    id,
    active,
    deceased,
    species: speciesId,
    name,
    photo,
    photoThumbnail,
    deceasedDate,
  } = useSelector(getPatient(patientId)) || {}
  const Species = useSelector(getSpecies)

  const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
    photo,
    photoThumbnail,
    AVATAR_SIZE,
  )

  const species = Utils.getConstantsValue(speciesId, Species)

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(
        classes.patientContainer,
        !disableNavigation && classes.showPointer,
        deceasedDate || !active ? classes.halfOpacity : '',
      )}
      direction="column"
      onClick={() => onClick(id)}
    >
      <Grid container item alignItems="center" wrap="nowrap">
        <PatientAvatar
          small
          animal={species && species.name}
          classes={{ root: classes.patientAvatar }}
          src={avatarSrc}
        />
        <Grid container item direction="column">
          <Grid
            container
            item
            alignItems="center"
            className={classes.patientNameContainer}
          >
            <Dotdotdot clamp={1}>
              <Text noWrap strong align="left" ml={1.5} variant="subheading2">
                {name}
              </Text>
            </Dotdotdot>
          </Grid>
        </Grid>
        {!disableNavigation && (
          <IconButton aria-label={t('Common:EXPAND_PATIENT')} size="large">
            <KeyboardArrowRight />
          </IconButton>
        )}
      </Grid>
      <Grid container item className={classes.alertsContainer}>
        {deceased && (
          <Grid item className={classes.alertLabelContainer}>
            <AlertLabel
              Icon={WingsIcon}
              classes={{
                root: classes.alertLabelRoot,
                icon: classes.alertLabelIcon,
                text: classes.alertLabelText,
              }}
              message={t('Common:DECEASED')}
              variant="normal"
            />
          </Grid>
        )}
        {!active && (
          <Grid item className={classes.alertLabelContainer}>
            <AlertLabel
              classes={{
                root: classes.alertLabelRoot,
                text: classes.alertLabelText,
              }}
              message={t('Common:INACTIVE_ONE')}
              variant="normal"
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default PatientItem
