import React from 'react'
import { Field } from '@pbt/pbt-ui-components'

import QuantityInput from '~/components/common/inputs/QuantityInput'
import {
  calculatePrepaidColumns,
  isGroupedInvoiceItem,
} from '~/components/dashboard/invoices/invoiceUtils'
import { OrderType } from '~/constants/SOAPStates'
import { InvoiceLineItem, InvoiceLineItemPayload } from '~/types'

import ChargeDetailsFieldWrapper from './ChargeDetailsFieldWrapper'

export interface ChargeFieldQuantityProps {
  className?: string
  disabled: boolean
  inputClassName?: string
  item: InvoiceLineItem
  onEditInvoiceLineItem: (payload: InvoiceLineItemPayload) => void
  quantityField: Field
  remainingQuantityField: Field
}

const ChargeFieldQuantity = ({
  className: classNameProp,
  disabled: disabledProp,
  inputClassName,
  item,
  onEditInvoiceLineItem,
  quantityField,
  remainingQuantityField,
}: ChargeFieldQuantityProps) => {
  const disabled = disabledProp || isGroupedInvoiceItem(item)

  return (
    <ChargeDetailsFieldWrapper disabled={disabled} label={quantityField.label}>
      <QuantityInput
        disableUnderline
        showControls
        allowDecimal={item.logType !== OrderType.LAB_TEST}
        className={classNameProp}
        disabled={disabled}
        field={{
          ...quantityField,
          setValue: (value: number) => {
            quantityField.setValue(value)

            if (item.prepaid) {
              const newItem = { ...item, quantity: value }
              calculatePrepaidColumns(item, newItem, false)
              remainingQuantityField.setValue(newItem.prepaidRemaining)
            }

            onEditInvoiceLineItem({ quantity: value })
          },
        }}
        inputProps={{
          'aria-labelledby': 'charge-field-quantity',
          className: inputClassName,
        }}
        min={item.logType !== OrderType.LAB_TEST ? 0.01 : 1}
      />
    </ChargeDetailsFieldWrapper>
  )
}

export default ChargeFieldQuantity
