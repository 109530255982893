import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormControlLabel, FormGroup, Grid, Switch } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  PuiDialog,
  PuiTheme,
  useFields,
} from '@pbt/pbt-ui-components'

import {
  clearShipmentItems,
  fetchMoreShipmentItemsForVaccine,
  fetchMoreShipmentItemsForVaccineWithVariation,
  fetchMoreShipmentItemsForVariation,
  getIsLoading,
  getMultipleShipmentItems,
  getShipmentItemsList,
  getTotalCount,
} from '~/store/duck/shipmentItems'
import { Order, ShipmentItem } from '~/types'

import ChooseShipmentItemTable from './ChooseShipmentItemTable'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    button: {
      height: 40,
      padding: theme.spacing(1, 6),
      margin: theme.spacing(1, 2),
    },
  }),
  { name: 'ChooseShipmentItemDialog' },
)

export enum ShipmentItemSearchCriteria {
  INVENTORY = 'INVENTORY',
  PROCEDURE = 'PROCEDURE',
  PROCEDURE_WITH_VARIATION = 'PROCEDURE_WITH_VARIATION',
}

const SearchCriteriaValuePath = {
  [ShipmentItemSearchCriteria.INVENTORY]: ['variation', 'id'],
  [ShipmentItemSearchCriteria.PROCEDURE]: ['procedure', 'id'],
  [ShipmentItemSearchCriteria.PROCEDURE_WITH_VARIATION]: ['procedure', 'id'],
}

const SearchCriteriaFetchAction = {
  [ShipmentItemSearchCriteria.INVENTORY]: fetchMoreShipmentItemsForVariation,
  [ShipmentItemSearchCriteria.PROCEDURE]: fetchMoreShipmentItemsForVaccine,
  [ShipmentItemSearchCriteria.PROCEDURE_WITH_VARIATION]:
    fetchMoreShipmentItemsForVaccineWithVariation,
}
interface ChooseShipmentItemDialogProps extends BasePuiDialogProps {
  isMultiSelect?: boolean
  onSelect: (
    shipmentItems: ChooseShipmentItemDialogProps['isMultiSelect'] extends true
      ? ShipmentItem[]
      : ShipmentItem,
  ) => void
  order: Partial<Order> | Nil
  searchCriteria?: ShipmentItemSearchCriteria
  selectedItems?: Partial<ShipmentItem>[]
  showToggle: boolean
}

const ChooseShipmentItemDialog = ({
  order,
  onSelect,
  isMultiSelect = false,
  open,
  onClose,
  showToggle,
  selectedItems,
  searchCriteria = ShipmentItemSearchCriteria.PROCEDURE,
}: ChooseShipmentItemDialogProps) => {
  const [includeExpiredItems, setIncludeExpiredItems] = useState(false)
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const dispatch = useDispatch()
  const shipmentItemsList = useSelector(getShipmentItemsList)
  const shipmentItems =
    useSelector(getMultipleShipmentItems(shipmentItemsList)) || []
  const totalCount = useSelector(getTotalCount)
  const isLoading = useSelector(getIsLoading)

  const searchCriteriaId = R.path<string>(
    SearchCriteriaValuePath[searchCriteria],
    order,
  )
  const fetchAction = SearchCriteriaFetchAction[searchCriteria]

  const loadMoreItems = (from: number, to: number) => {
    if (searchCriteriaId) {
      dispatch(fetchAction(searchCriteriaId, from, to, includeExpiredItems))
    }
  }

  const handleIncludeExpiredItemsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIncludeExpiredItems(event.target.checked)
  }

  useEffect(() => {
    dispatch(clearShipmentItems())
    if (searchCriteriaId) {
      dispatch(fetchAction(searchCriteriaId, 0, 25, includeExpiredItems))
    }
    return () => {
      dispatch(clearShipmentItems())
    }
  }, [includeExpiredItems])

  const titleText = `${t('Common:CHOOSE_FROM_INVENTORY')}: ${order?.name}`

  const title = (
    <Grid container alignItems="center" component="span" spacing={3}>
      <Grid item>{titleText}</Grid>
      {showToggle && (
        <Grid item>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={includeExpiredItems}
                  onChange={handleIncludeExpiredItemsChange}
                />
              }
              label={t('Common:INCLUDE_EXPIRED_ITEMS')}
            />
          </FormGroup>
        </Grid>
      )}
    </Grid>
  )

  const {
    fields: { chosenItem },
    validate,
  } = useFields([
    {
      name: 'chosenItem',
      label: t('Common:CHOSEN_ITEM'),
      validators: ['required'],
      initialValue: isMultiSelect ? selectedItems : null,
    },
  ])

  const noItemSelected = !chosenItem.value

  const handleSelect = () => {
    if (validate()) {
      onSelect(chosenItem.value)
      if (onClose) {
        onClose()
      }
    }
  }

  return (
    <PuiDialog
      fullWidth
      actions={
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading || noItemSelected}
          loading={isLoading}
          type="submit"
          onClick={handleSelect}
        >
          {t('Common:SELECT_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="choose-from-inventory-dialog"
      maxWidth="lg"
      open={open}
      title={title}
      onClose={onClose}
    >
      <Grid container px={3} py={2}>
        <ChooseShipmentItemTable
          chosenItemField={chosenItem}
          isMultiSelect={isMultiSelect}
          loadMoreItems={loadMoreItems}
          options={shipmentItems}
          totalCount={totalCount}
        />
      </Grid>
    </PuiDialog>
  )
}

export default ChooseShipmentItemDialog
