export const NO_RIGHTS = {
  create: false,
  read: false,
  update: false,
  delete: false,
  sign: false,
}

export const ALL_RIGHTS = {
  create: true,
  read: true,
  update: true,
  delete: true,
  sign: true,
}

export const READ_ONLY = {
  create: false,
  read: true,
  update: false,
  delete: false,
  sign: false,
}
