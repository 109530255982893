import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  IdObject,
  Nil,
  PuiDialog,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import AttachmentListWithFilters, {
  AttachmentListWithFiltersProps,
} from '~/components/common/inputs/attachment/attachment-dialog/AttachmentListWithFilters'
import {
  fetchDocuments,
  fetchResolvedDocuments,
} from '~/store/actions/documents'
import {
  getDocumentsError,
  getDocumentsIsReceiving,
  getMultipleDocuments,
} from '~/store/reducers/documents'
import { Document } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 976,
      maxWidth: 976,
      [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 32px)',
      },
    },
  }),
  { name: 'SelectAttachmentDialog' },
)

interface SelectAttachmentDialogProps
  extends BasePuiDialogProps,
    Omit<AttachmentListWithFiltersProps, 'onProceed'> {
  clientId: string | Nil
  convertToTextDocument?: boolean
  enrich?: boolean
  eventId: string | Nil
  onDocumentsFetched: (documents: Document[]) => void
  patientId: string | Nil
  soapId?: string | Nil
}

const SelectAttachmentDialog = ({
  open,
  label,
  onClose,
  eventId,
  soapId,
  clientId,
  patientId,
  onDocumentsFetched,
  enrich = true,
  convertToTextDocument = false,
  skipFileTemplates = true,
  ...rest
}: SelectAttachmentDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const [templatesIds, setTemplatesIds] = useState<string[]>([])

  const hasDocumentError = useSelector(getDocumentsError)
  const documents = useSelector(getMultipleDocuments(templatesIds))

  // Bug: https://chewyinc.atlassian.net/browse/RHAP-3557
  // We should only call onDocumentsFetched on success and not on failure
  const onSuccess = () => {
    if (!hasDocumentError) {
      onDocumentsFetched(documents)
    }

    setTemplatesIds([])

    if (onClose) {
      onClose()
    }
  }

  const setCloseAfterSuccessOn = useCloseAfterCreation(
    onSuccess,
    getDocumentsIsReceiving,
  )

  const onTemplatesSelected = (items: IdObject[]) => {
    const ids = R.pluck('id', items)
    setTemplatesIds(ids)
    setCloseAfterSuccessOn()

    const context = { clientId, patientId, eventId, soapId }

    if (enrich) {
      dispatch(fetchResolvedDocuments(ids, context, convertToTextDocument))
    } else {
      dispatch(fetchDocuments(ids))
    }
  }

  return (
    <PuiDialog
      aria-labelledby="select-attachment-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      title={t('Common:ADD_SOME_TYPE', { type: label })}
      onClose={onClose}
    >
      <Grid container direction="column" pt={2} px={2}>
        <AttachmentListWithFilters
          eventId={eventId}
          label={label}
          patientId={patientId}
          skipFileTemplates={skipFileTemplates}
          onProceed={onTemplatesSelected}
          {...rest}
        />
      </Grid>
    </PuiDialog>
  )
}

export default SelectAttachmentDialog
