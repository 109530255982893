/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import * as R from 'ramda'
import {
  DateUtils,
  PermissionArea,
  PuiTheme,
  Utils,
} from '@pbt/pbt-ui-components'
import {
  Check as CheckIcon,
  Decline as DeclineIcon,
  Delete as DeleteIcon,
  Eye as EyeIcon,
  RoundedCheck,
} from '@pbt/pbt-ui-components/src/icons'

import { PopperAction } from '~/components/common/ActionsPopper'
import ReminderStateLabel from '~/components/common/labels/ReminderStateLabel'
import LandingWidget, {
  LandingWidgetProps,
} from '~/components/dashboard/landing/widgets/LandingWidget'
import NoRecords from '~/components/dashboard/landing/widgets/NoRecords'
import TableWidget from '~/components/dashboard/landing/widgets/TableWidget'
import DialogNames from '~/constants/DialogNames'
import { LandingType } from '~/constants/landingConstants'
import SnapshotsAliasTypes from '~/constants/SnapshotsAliasTypes'
import i18n from '~/locales/i18n'
import {
  fetchWidgetsData,
  getWidgetData,
  getWidgetDataIsLoading,
} from '~/store/duck/landing'
import {
  deleteReminder,
  editReminder,
  getIsDeleting,
  getIsLoading,
  getMultipleReminders,
} from '~/store/duck/reminders'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getEventState } from '~/store/reducers/constants'
import { ReminderSnapshotItem, WidgetColumn } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import ReminderNameCell from './ReminderNameCell'

const columns: WidgetColumn<ReminderSnapshotItem>[] = [
  {
    id: 'name',
    component: ReminderNameCell,
    width: 4,
    label: i18n.t('Common:NAME'),
  },
  {
    id: 'performedDate',
    prop: ({ performedDate }) => DateUtils.formatDate(performedDate),
    label: i18n.t('Common:PERFORMED'),
    width: 2.25,
  },
  {
    id: 'dueDatetime',
    prop: ({ dueDatetime }) => DateUtils.formatDate(dueDatetime),
    label: i18n.t('Common:DUE_DATE'),
    width: 2.25,
  },
  {
    id: 'reminderState',
    prop: (reminder) => <ReminderStateLabel reminder={reminder} />,
    width: 2,
    label: i18n.t('Common:STATE'),
  },
]

const RoundedCheckIcon = () => {
  const theme = useTheme<PuiTheme>()

  return <RoundedCheck fillColor={theme.colors.tabLabel} sx={{ mr: 1 }} />
}

interface RemindersSnapshotProps
  extends Omit<LandingWidgetProps<ReminderSnapshotItem>, 'component' | 'data'> {
  clientId: string
  expandedSnapshotType?: SnapshotsAliasTypes
  name: string
  patientId: string
}

const RemindersSnapshot = ({
  name,
  clientId,
  patientId,
  expandedSnapshotType,
  ...rest
}: RemindersSnapshotProps) => {
  const dispatch = useDispatch()
  const EventState = useSelector(getEventState)
  const ResolvedStateId = Utils.findConstantIdByName('Resolved', EventState)
  const OpenStateId = Utils.findConstantIdByName('Open', EventState)
  const DeclinedStateId = Utils.findConstantIdByName('Declined', EventState)
  const { t } = useTranslation(['Common', 'Clients'])

  const remindersIds = useSelector(
    getWidgetData(
      LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
      SnapshotsAliasTypes.Reminders,
    ),
  )
  const reminders = useSelector(getMultipleReminders(remindersIds)).filter(
    Boolean,
  )

  const isDataLoading = useSelector(
    getWidgetDataIsLoading(
      LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
      SnapshotsAliasTypes.Reminders,
    ),
  )

  const isRemindersLoading = useSelector(getIsLoading)
  const isRemindersDeleting = useSelector(getIsDeleting)
  const {
    create: reminderCreatePermissions,
    delete: reminderDeletePermissions,
    read: reminderReadPermissions,
    update: reminderUpdatePermissions,
  } = useSelector(getCRUDByArea(PermissionArea.REMINDERS))

  const isLoading = isDataLoading || isRemindersLoading || isRemindersDeleting

  const [openReminderDetailsDialog] = useDialog(DialogNames.REMINDER_DETAILS)
  const [openReminderDialog] = useDialog(DialogNames.ADD_REMINDER)

  const refetchReminders = () => {
    dispatch(
      fetchWidgetsData([SnapshotsAliasTypes.Reminders], {
        quiet: false,
        landingType: LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
        patientId,
      }),
    )
  }

  const refetchRemindersAfterChange = useCloseAfterCreation(
    refetchReminders,
    getIsLoading,
  )
  const refetchRemindersAfterDelete = useCloseAfterCreation(
    refetchReminders,
    getIsDeleting,
  )

  const handleView = (reminder: ReminderSnapshotItem) =>
    openReminderDetailsDialog({ reminderId: reminder.id })

  const handleUpdate = (newReminder: ReminderSnapshotItem) => {
    dispatch(editReminder(newReminder))
    refetchRemindersAfterChange()
  }

  const handleDelete = (reminder: ReminderSnapshotItem) => {
    dispatch(deleteReminder(reminder.id))
    refetchRemindersAfterDelete()
  }

  const handleAdd = () => openReminderDialog({ patientId })

  const getActions = (reminder: ReminderSnapshotItem) =>
    [
      {
        id: 'view',
        label: t('Common:VIEW_ACTION'),
        Icon: EyeIcon,
        onClick: reminderReadPermissions
          ? () => handleView(reminder)
          : undefined,
        disabled: !reminderReadPermissions,
      },
      reminder?.state?.id !== ResolvedStateId && {
        id: 'resolve',
        label: t('Common:RESOLVE_ACTION'),
        Icon: CheckIcon,
        onClick: reminderUpdatePermissions
          ? () => handleUpdate({ ...reminder, state: ResolvedStateId })
          : undefined,
        disabled: !reminderUpdatePermissions,
      },
      reminder?.state?.id === ResolvedStateId && {
        id: 'open',
        label: t('Common:OPEN_ACTION'),
        Icon: RoundedCheckIcon,
        onClick: reminderUpdatePermissions
          ? () => handleUpdate({ ...reminder, state: OpenStateId })
          : undefined,
        disabled: !reminderUpdatePermissions,
      },
      reminder?.state?.id !== DeclinedStateId && {
        id: 'decline',
        label: t('Common:DECLINE_ACTION'),
        Icon: DeclineIcon,
        onClick: reminderUpdatePermissions
          ? () => handleUpdate({ ...reminder, state: DeclinedStateId })
          : undefined,
        disabled: !reminderUpdatePermissions,
      },
      {
        id: 'delete',
        label: t('Common:DELETE_ACTION'),
        Icon: DeleteIcon,
        onClick: reminderDeletePermissions
          ? () => handleDelete(reminder)
          : undefined,
        disabled: !reminderDeletePermissions,
      },
    ].filter(Boolean) as PopperAction[]

  return (
    <LandingWidget
      showHeaderTitles
      canNavigateToDetails={false}
      canNavigateToItemDetails={R.F}
      columns={columns}
      component={TableWidget}
      data={reminders}
      getActions={getActions}
      isLoading={isLoading}
      name={name}
      noRecords={
        <NoRecords
          action={t('Clients:SNAPSHOTS_AND_RECORDS.ADD_REMINDER')}
          text={t('Clients:SNAPSHOTS_AND_RECORDS.NO_REMINDERS')}
          onClick={reminderCreatePermissions ? handleAdd : undefined}
        />
      }
      showPaddings={false}
      {...rest}
    />
  )
}

export default RemindersSnapshot
