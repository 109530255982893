import React, { useMemo } from 'react'
import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ClassesType,
  InfiniteLoaderList,
  InfiniteLoaderListProps,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import { EditItemButtonGroupProps } from '../../buttons/EditItemButtonGroup'
import PuiDataTableHeader, {
  usePuiDataTableHeaderStyles,
} from './PuiDataTableHeader'
import PuiDataTableRow, { usePuiDataTableRowStyles } from './PuiDataTableRow'
import type { PuiDataTableColumn } from './puiDataTableType'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {},
    table: {
      border: theme.constants.tableBorder,
    },
    loaderList: {
      overflowX: 'hidden',
    },
  }),
  { name: 'PuiDataTable' },
)

const ITEM_SIZE = 50

export interface PuiDataTableProps<T extends { id: string }>
  extends Omit<Partial<EditItemButtonGroupProps>, 'disabled'> {
  InfiniteLoaderListComponentProps?: Partial<InfiniteLoaderListProps>
  classes?: ClassesType<typeof useStyles>
  classesHeader?: ClassesType<typeof usePuiDataTableHeaderStyles>
  classesRow?: ClassesType<typeof usePuiDataTableRowStyles>
  columns: PuiDataTableColumn[]
  densed?: boolean
  dynamicSize?: boolean
  emptyPlaceholder?: React.ReactNode
  filledBackground?: 'even' | 'odd'
  getItemPreviewEnabled?: (item: T) => boolean
  isLoading?: boolean
  items: T[]
  loadMoreItems: (from: number, to: number) => void
  mainColumn?: PuiDataTableColumn
  minRowHeight?: number
  minViewPortHeight?: number
  onSelectRow?: (item: T) => void
  rowHeight?: number
  selectedRowId?: string
  totalCount: number
  viewportItems?: number
  withBorder?: boolean
}

const PuiDataTable = <T extends { id: string }>({
  InfiniteLoaderListComponentProps,
  classes: classesProp,
  classesHeader,
  classesRow,
  columns,
  densed,
  dynamicSize = false,
  emptyPlaceholder,
  filledBackground = 'even',
  getItemPreviewEnabled,
  isLoading = false,
  items,
  loadMoreItems,
  mainColumn,
  minRowHeight,
  minViewPortHeight = 0,
  onDelete,
  onDuplicate,
  onEdit,
  onPreview,
  onSelectRow,
  selectedRowId,
  totalCount,
  viewportItems = 6,
  rowHeight = ITEM_SIZE,
  withBorder = false,
}: PuiDataTableProps<T>) => {
  const classes = useStyles({ classes: classesProp })
  const isItemLoaded = (index: number) => Boolean(items[index])

  const viewportHeight = useMemo(
    () =>
      Math.max(
        minViewPortHeight,
        Math.min(viewportItems, items?.length || 0) * rowHeight,
      ),
    [minViewPortHeight, rowHeight, viewportItems, items?.length],
  )

  return (
    <Grid container className={classes.root} direction="column">
      <Grid container className={classes.table} direction="column">
        <PuiDataTableHeader
          classes={classesHeader}
          columns={columns}
          densed={densed}
          hasScrollSpace={items?.length > viewportItems}
          mainColumn={mainColumn}
        />
        {isLoading ? (
          <Table>
            <TableBody>
              {Array.from({ length: viewportItems }, (_, i) => i).map(
                (index) => (
                  <TableRow key={index} style={{ height: rowHeight }}>
                    {[mainColumn, ...columns].filter(Boolean).map((cell) => (
                      <TableCell
                        key={`${index}-${cell!.label}`}
                        width={cell!.width}
                      >
                        <Skeleton variant="rectangular" width="100%" />
                      </TableCell>
                    ))}
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        ) : (
          <>
            {totalCount === 0 && Boolean(emptyPlaceholder) ? (
              <>{emptyPlaceholder}</>
            ) : (
              <Grid
                item
                style={
                  InfiniteLoaderListComponentProps?.useWindowScroll
                    ? { minHeight: viewportHeight + 1 }
                    : { height: viewportHeight + 1 }
                }
              >
                <InfiniteLoaderList
                  className={classNames(classes.loaderList)}
                  isItemLoaded={isItemLoaded}
                  itemCount={totalCount}
                  itemData={items}
                  itemSize={dynamicSize ? undefined : rowHeight}
                  itemSpacing={0}
                  loadMoreItems={loadMoreItems}
                  {...InfiniteLoaderListComponentProps}
                >
                  {(item = {}, index) => (
                    <PuiDataTableRow
                      classes={classesRow}
                      columns={columns}
                      dynamicSize={dynamicSize}
                      filledBackground={filledBackground}
                      index={index}
                      item={item}
                      key={item.id}
                      mainColumn={mainColumn}
                      minRowHeight={minRowHeight}
                      selectedRowId={selectedRowId}
                      withBorder={withBorder}
                      onDelete={onDelete}
                      onDuplicate={onDuplicate}
                      onEdit={onEdit}
                      onPreview={
                        getItemPreviewEnabled
                          ? getItemPreviewEnabled(item)
                            ? onPreview
                            : undefined
                          : onPreview
                      }
                      onSelectRow={
                        onSelectRow
                          ? (rowItem: T) => onSelectRow(rowItem)
                          : undefined
                      }
                    />
                  )}
                </InfiniteLoaderList>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default PuiDataTable
