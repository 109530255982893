import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ControlButtonGroup,
  ControlButtonGroupName,
  PermissionArea,
  PuiTheme,
  Text,
  UnitUtils,
  Utils,
} from '@pbt/pbt-ui-components'
import { UnitTypes, WeightTypes } from '@pbt/pbt-ui-components/src/localization'

import FullGendersCell from '~/components/common/lists/primitive-table/cells/FullGendersCell'
import SpeciesCell from '~/components/common/lists/primitive-table/cells/SpeciesCell'
import { useGetDueDateString } from '~/components/dashboard/reminders/reminderUtils'
import { getUnitsState } from '~/store/duck/settings'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getAgeUnits, getWeightUnits } from '~/store/reducers/constants'
import { ReminderProtocol } from '~/types'

import ReminderProtocolListTableSection from './ReminderProtocolListTableSection'
// @ts-ignore
import { getRangeUnits } from './reminderProtocolUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      border: theme.constants.tableBorder,
      '&:not(:last-child)': {
        borderBottomWidth: 0,
      },
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    nameSection: {
      borderRight: theme.constants.tableBorder,
      minWidth: 212,
      maxWidth: 212,
    },
    buttons: {
      marginLeft: 'auto',
      width: 'auto',
    },
    name: {
      wordBreak: 'break-word',
    },
  }),
  { name: 'ReminderProtocolSection' },
)

export interface ReminderProtocolSectionProps {
  onClone: () => void
  onDelete: () => void
  onEdit: () => void
  protocol: ReminderProtocol
}

const ReminderProtocolSection = ({
  protocol,
  onEdit,
  onDelete,
  onClone,
}: ReminderProtocolSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Admin', 'Common'])
  const unitsState = useSelector(getUnitsState)
  const permissions = useSelector(
    getCRUDByArea(PermissionArea.REMINDER_PROTOCOLS),
  )
  const WeightUnits = useSelector(getWeightUnits)
  const AgeUnits = useSelector(getAgeUnits)

  const defaultUnitId = Utils.findConstantIdByName(
    unitsState[UnitTypes.WEIGHT],
    WeightUnits,
  )
  const kgUnitId = Utils.findConstantIdByName(WeightTypes.KG, WeightUnits)

  const {
    reminderName: name,
    dueDateOffset,
    speciesIds,
    genderRestrictions,
    ageMin,
    ageMax,
    weightMin,
    weightMax,
    triggersWith = [],
    due = [],
    resolvesWith = [],
    dueSameAsTrigger,
    resolveSameAsTrigger,
  } = protocol

  const dueDate = useGetDueDateString(dueDateOffset)
  const ageRange = getRangeUnits(AgeUnits, ageMin, ageMax)

  const convertedWeightMin = weightMin
    ? {
        ...weightMin,
        amount:
          weightMin.unitId === defaultUnitId
            ? weightMin.amount
            : weightMin.unitId === kgUnitId
            ? UnitUtils.kgToLbs(weightMin.amount)
            : UnitUtils.convertUnits(
                UnitTypes.WEIGHT,
                weightMin.amount,
                unitsState,
              ),
        unitId: defaultUnitId,
      }
    : null

  const convertedWeightMax = weightMax
    ? {
        ...weightMax,
        amount:
          weightMax.unitId === defaultUnitId
            ? weightMax.amount
            : weightMax.unitId === kgUnitId
            ? UnitUtils.kgToLbs(weightMax.amount)
            : UnitUtils.convertUnits(
                UnitTypes.WEIGHT,
                weightMax.amount,
                unitsState,
              ),
        unitId: defaultUnitId,
      }
    : null

  const weightRange = getRangeUnits(
    WeightUnits,
    convertedWeightMin,
    convertedWeightMax,
  )

  return (
    <Grid container className={classes.root} wrap="nowrap">
      <Grid container item xs className={classes.nameSection}>
        <Grid container alignItems="flex-start" wrap="nowrap">
          <Text className={classes.name} p={1} variant="body2">
            {name}
          </Text>
          <Grid
            container
            item
            alignItems="center"
            className={classes.buttons}
            wrap="nowrap"
          >
            <ControlButtonGroup
              buttons={[
                permissions.delete && {
                  name: ControlButtonGroupName.DELETE,
                  onClick: onDelete,
                },
                permissions.update && {
                  name: ControlButtonGroupName.EDIT,
                  onClick: onEdit,
                },
                permissions.create && {
                  name: ControlButtonGroupName.DUPLICATE,
                  onClick: onClone,
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs>
        <Grid container item>
          <Grid item xs={3}>
            <Text px={1} py={2} variant="body2">
              {dueDate}
            </Text>
          </Grid>
          <Grid item xs={2}>
            <Text px={1} py={2} variant="body2">
              <SpeciesCell speciesIds={speciesIds} />
            </Text>
          </Grid>
          <Grid item xs={2}>
            <Text px={1} py={2} variant="body2">
              <FullGendersCell genderIds={genderRestrictions} />
            </Text>
          </Grid>
          <Grid item xs={3}>
            <Text px={1} py={2} variant="body2">
              {ageRange}
            </Text>
          </Grid>
          <Grid item xs={2}>
            <Text px={1} py={2} variant="body2">
              {weightRange}
            </Text>
          </Grid>
        </Grid>
        <ReminderProtocolListTableSection
          items={triggersWith}
          label={t('Common:TRIGGER_ONE_OR_OTHERS')}
          placeholder={t(
            'Admin:CATALOG.REMINDER_PROTOCOL_SECTION.PLACEHOLDER_TRIGGERS',
          )}
        />
        <ReminderProtocolListTableSection
          items={dueSameAsTrigger ? triggersWith : due}
          label={t('Common:WHAT_IS_DUE_OR_EXPECTED')}
          placeholder={t(
            'Admin:CATALOG.REMINDER_PROTOCOL_SECTION.PLACEHOLDER_WHAT_IS_DUE_OR_EXPECTED',
          )}
        />
        <ReminderProtocolListTableSection
          items={resolveSameAsTrigger ? triggersWith : resolvesWith}
          label={t('Common:WHAT_RESOLVES_IT')}
          placeholder={t(
            'Admin:CATALOG.REMINDER_PROTOCOL_SECTION.PLACEHOLDER_WHAT_RESOLVES_IT',
          )}
        />
      </Grid>
    </Grid>
  )
}

export default ReminderProtocolSection
