import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DateUtils, Field, PuiTheme, Text } from '@pbt/pbt-ui-components'
import { formatMoney } from '@pbt/pbt-ui-components/src/utils/numberUtils'

import { Payment } from '~/api/graphql/generated/types'
import { getRefundInvoice } from '~/store/duck/refunds'

import { PaymentInvoiceRefundPaymentAmountInput } from './PaymentInvoiceRefundAmountInput'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    radioLabel: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
    },
    labelRoot: {
      margin: 0,
      height: 30,
    },
    radio: {
      padding: 0,
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'PaymentInvoiceRefundPaymentSelection' },
)

interface PaymentInvoiceRefundPaymentSelectionProps {
  assignedInvoiceId: string
  fields: {
    invoiceRefundMethod: Field
    notes: Field
    refundingValue: Field
  }
  invoiceAmount: number
  isLoading: boolean
  onSetRefundPaymentId: (id: string) => void
  refundPaymentId: string
}

export const PaymentInvoiceRefundPaymentSelection = ({
  assignedInvoiceId,
  fields,
  isLoading,
  onSetRefundPaymentId,
  refundPaymentId,
  invoiceAmount,
}: PaymentInvoiceRefundPaymentSelectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Errors', 'Invoices', 'Validations'])
  const refundInvoice = useSelector(getRefundInvoice(assignedInvoiceId))
  const refundOriginalPayments =
    refundInvoice?.originalInvoice.payments ?? ([] as Payment[])
  const eligibleRefundOriginalPayments = refundOriginalPayments.filter(
    (payment) => payment.refundableAmount > 0,
  )

  const { invoiceRefundMethod, refundingValue, notes } = fields

  useEffect(() => {
    if (eligibleRefundOriginalPayments.length === 1) {
      onSetRefundPaymentId(eligibleRefundOriginalPayments[0].id)
    }
  }, [eligibleRefundOriginalPayments])

  if (eligibleRefundOriginalPayments.length === 0) {
    return <></>
  }

  return eligibleRefundOriginalPayments.length === 1 ? (
    <PaymentInvoiceRefundPaymentAmountInput
      fields={{ invoiceRefundMethod, notes }}
      initialValue={refundingValue.initialValue}
      isLoading={isLoading}
      maxRefund={Math.min(
        eligibleRefundOriginalPayments[0].refundableAmount,
        invoiceAmount,
      )}
      onSetRefundValue={(newValue) => refundingValue.setValue(newValue)}
    />
  ) : (
    <>
      <Text variant="h4">{t('Common:PAYMENTS.REFUND_TO')}</Text>
      <RadioGroup
        aria-label="paymentTypes"
        name="payments"
        value={refundPaymentId}
        onChange={(_, value) => {
          onSetRefundPaymentId(value)
        }}
      >
        {eligibleRefundOriginalPayments.map((payment: Payment) => (
          <Grid key={payment.id}>
            <FormControlLabel
              classes={{
                root: classes.labelRoot,
                label: classes.radioLabel,
              }}
              control={<Radio className={classes.radio} />}
              label={`${payment.method?.name} - ${DateUtils.formatDate(
                payment.creationDate,
              )}`}
              value={payment.id}
            />
            <Text variant="lowAccent2">
              {t('Invoices:MAX_REFUND_ALLOWED', {
                amount: formatMoney(
                  Math.min(payment.refundableAmount, invoiceAmount),
                ),
              })}
            </Text>
            {refundPaymentId === payment.id && (
              <>
                <PaymentInvoiceRefundPaymentAmountInput
                  fields={{ invoiceRefundMethod, notes }}
                  initialValue={refundingValue.initialValue}
                  isLoading={isLoading}
                  maxRefund={Math.min(payment.refundableAmount, invoiceAmount)}
                  onSetRefundValue={(newValue) =>
                    refundingValue.setValue(newValue)
                  }
                />
              </>
            )}
          </Grid>
        ))}
      </RadioGroup>
    </>
  )
}
