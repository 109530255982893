import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme } from '@pbt/pbt-ui-components'

import MarketplaceItemIframe from '~/components/common/marketplaceIframes/MarkeplaceItemIframe'
import { MarketplaceWorkflowNames } from '~/constants/marketplaceConstants'
import { useGetWorkflowIFrames } from '~/store/hooks/marketplace'
import { getCurrentBusinessId } from '~/store/reducers/auth'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    marketplaceItem: {
      '&:last-of-type': {
        marginBottom: theme.spacing(2),
      },
    },
  }),
  { name: 'TimetableMarketplaceSection' },
)

const TimetableMarketplaceSection = () => {
  const classes = useStyles()
  const businessId = useSelector(getCurrentBusinessId)

  const iFrames = useGetWorkflowIFrames(MarketplaceWorkflowNames.SCHEDULER)

  return iFrames.map((iFrame) => (
    <Grid
      item
      className={classes.marketplaceItem}
      key={iFrame.id}
      mt={2}
      mx={2}
    >
      <MarketplaceItemIframe
        preview
        id={iFrame.id}
        params={{
          businessId,
        }}
        title={iFrame.name}
        url={iFrame.tileUrl}
      />
    </Grid>
  ))
}

export default TimetableMarketplaceSection
