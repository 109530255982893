import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ButtonWithLoader,
  DateUtils,
  PuiPopper,
  PuiTheme,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { Note } from '@pbt/pbt-ui-components/src/icons'

import FeatureToggle from '~/constants/featureToggle'
import { TaskContext } from '~/constants/taskConstants'
import { queueEasterEggEvent } from '~/store/actions/easterEgg'
import { fetchSoapTasks, partialEditTask } from '~/store/actions/tasks'
import { useTaskEvent } from '~/store/hooks/tasks'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getIsCurrentContextSoap, getIsFinalized } from '~/store/reducers/soap'
import { getTasksIsLoading } from '~/store/reducers/tasks'
import { getUser } from '~/store/reducers/users'
import { AtLeast, EasterEggEvents, Task } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import TaskStateButton from '../../tasks-dashboard/TaskStateButton'
import NotesTemplateInput from '../../template-inputs/NotesTemplateInput'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    popper: {
      width: 260,
    },
    button: {
      minWidth: 110,
    },
    buttonHidden: {
      visibility: 'hidden',
    },
    noteIcon: {
      color: theme.colors.editIconColor,
    },
    templateInput: {
      margin: theme.spacing(1, 0),
    },
  }),
  { name: 'TaskStateButtonDetailed' },
)

export interface TaskStateButtonDetailedProps {
  context: TaskContext
  task: Task
}

const TaskStateButtonDetailed = ({
  context,
  task,
}: TaskStateButtonDetailedProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Tasks'])

  const assignee = useSelector(getUser(task.assigned))
  const isLoading = useSelector(getTasksIsLoading)
  const isSoapFinalized = useSelector(getIsFinalized)
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)
  const isEasterEggEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EASTER_EGG),
  )
  const isTaskListOracleIntegrationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.TASK_LIST_ORACLE_INTEGRATION),
  )

  const isReadOnly = isSoapFinalized || !isCurrentContextSoap

  const TaskType = useTaskEvent()
  const TaskStates = TaskType.states
  const DoneState = Utils.findConstantIdByName('Done', TaskStates)
  const OpenState = Utils.findConstantIdByName('Open', TaskStates)
  const isDone = task.stateId === DoneState

  const [popperOpen, setPopperOpen] = useState(false)

  const rootRef = useRef<HTMLDivElement>(null)

  const setCloseAfterEditedOn = useCloseAfterCreation(() => {
    setPopperOpen(false)
    dispatch(fetchSoapTasks())
  }, getTasksIsLoading)

  const {
    fields: { notes },
    reset,
  } = useFields([{ name: 'notes', initialValue: task.notes }])

  useEffect(() => {
    reset()
  }, [task])

  const onClick = () => {
    setPopperOpen(true)
  }

  const save = (newStateId?: string) => {
    setCloseAfterEditedOn()
    const newTask: AtLeast<Task, 'id'> = {
      id: task.id,
      notes: notes.value,
    }

    if (newStateId) {
      newTask.stateId = newStateId
    }

    dispatch(partialEditTask(newTask, context))
  }

  const unDo = () => save(OpenState)

  const markAsDone = () => {
    if (isEasterEggEnabled) {
      dispatch(queueEasterEggEvent({ actionType: EasterEggEvents.TASK_DONE }))
    }
    save(DoneState)
  }

  const shouldUseVCRBehavior =
    isTaskListOracleIntegrationEnabled && task?.forChewyVcr

  return (
    <Grid container item alignItems="center" ref={rootRef}>
      <TaskStateButton task={task} onClick={onClick} />
      {task.notes && <Note className={classes.noteIcon} />}
      {popperOpen && (
        <PuiPopper
          anchorEl={rootRef.current}
          classes={{
            popper: classes.popper,
          }}
          disablePortal={false}
          open={popperOpen}
          placement="top"
          onClose={() => setPopperOpen(false)}
        >
          <Grid container item direction="column" p={2}>
            <Text strong variant="subheading2">
              {Utils.getPersonString(assignee)}
            </Text>
            <Text variant="body2">
              {DateUtils.formatDateWithHours(task.dueDate)}
            </Text>
            <NotesTemplateInput
              hidePanel
              isSoap
              resetStateOnValueChange
              className={classes.templateInput}
              field={notes}
              maxEditorHeight={200}
              maxHeight={200}
              minEditorHeight={80}
              minHeight={80}
              placeholder={t('Common:NOTES')}
            />
            <Grid
              container
              item
              columnSpacing={1}
              justifyContent="space-between"
              rowSpacing={1}
            >
              <Grid item>
                <ButtonWithLoader
                  className={classNames(classes.button, {
                    [classes.buttonHidden]: isReadOnly,
                  })}
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={() => save()}
                >
                  {t('Common:SAVE_ACTION')}
                </ButtonWithLoader>
              </Grid>
              <Grid item>
                <ButtonWithLoader
                  className={classes.button}
                  disabled={isLoading || (shouldUseVCRBehavior && !isDone)}
                  loading={isLoading}
                  onClick={isDone ? unDo : markAsDone}
                >
                  {isDone
                    ? t('Tasks:LABEL.UN-DO')
                    : t('Tasks:LABEL.MARK_AS_DONE')}
                </ButtonWithLoader>
              </Grid>
            </Grid>
          </Grid>
        </PuiPopper>
      )}
    </Grid>
  )
}

export default TaskStateButtonDetailed
