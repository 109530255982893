import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { isEmpty } from 'ramda'
import {
  DateUtils,
  PuiTheme,
  StateLabel,
  TextInteractive,
} from '@pbt/pbt-ui-components'

import { MembershipPaymentStatus } from '~/constants/paymentTypes'
import { MembershipPayment } from '~/types'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    stateLabel: {
      marginLeft: theme.spacing(0.75),
    },
  }),
  { name: 'MembershipPaymentDateCell' },
)

const MembershipPaymentDateCell = (item: MembershipPayment) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const isLoading = isEmpty(item)
  const isNext = item.status === MembershipPaymentStatus.NEXT
  const date = DateUtils.formatDate(item.creationDate)

  return (
    <Grid container item alignItems="center">
      <TextInteractive isLoading={isLoading}>
        {isNext ? `${t('Common:PAYMENTS.DUE_DATE')}: ` : ''}
        {date}
      </TextInteractive>
      {isNext && (
        <StateLabel warning className={classes.stateLabel} variant="small">
          {t('Common:PAYMENTS.NEXT_PAYMENT')}
        </StateLabel>
      )}
    </Grid>
  )
}

export default MembershipPaymentDateCell
