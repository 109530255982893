import React from 'react'
import { Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DocumentFile, PuiTheme } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    link: {
      height: theme.spacing(3),
      color: 'inherit',
    },
  }),
  { name: 'ControlDownloadButton' },
)

export interface ControlDownloadButtonProps {
  button: React.ReactNode
  file?: DocumentFile['file']
}

const ControlDownloadButton = ({
  file,
  button,
}: ControlDownloadButtonProps) => {
  const classes = useStyles()

  return (
    <Link
      className={classes.link}
      download={
        file?.name && file?.extension ? `${file.name}.${file.extension}` : true
      }
      href={file?.fileUrl || undefined}
      rel="noopener noreferrer"
      target="_blank"
    >
      {button}
    </Link>
  )
}

export default ControlDownloadButton
