import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FormControl, Grid, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  DateUtils,
  moment,
  PermissionArea,
  PuiSelect,
  PuiTextArea,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import InfoList from '~/components/common/InfoList'
import EnumRadioGroup from '~/components/common/inputs/EnumRadioGroup'
import QuantityInput from '~/components/common/inputs/QuantityInput'
import FeatureToggle from '~/constants/featureToggle'
import { fetchSpacesListWithType } from '~/store/actions/spaces'
import { useInStockUnits } from '~/store/hooks/inventories'
import { getCRUDByArea, getCurrentUser } from '~/store/reducers/auth'
import {
  getFeatureToggle,
  getInventoryAdjustmentMethod,
  getInventoryAdjustmentReasons,
  getInventoryInStockUnit,
  getInventoryProductSizeUnit,
  getPackageType,
  getSelectableInventoryAdjustmentReasons,
  getSpaceType,
} from '~/store/reducers/constants'
import {
  getMultipleSpaces,
  getSpaceCurrentTypeId,
  getSpacesIsFetching,
  getSpacesList,
} from '~/store/reducers/spaces'
import { Adjustment, DataHandle, UnsavedAdjustment } from '~/types'

import {
  getDirectAdjustmentDelta,
  getMinimalAdjustmentAmountByOnHandAmount,
  getOnHandAmount,
  getVariationWithAddedOnHandAmount,
  // @ts-ignore
} from '../inventoryUtils'
import AdjustmentAmountCell from './AdjustmentAmountCell'

const useStyles = makeStyles(
  () => ({
    quantityUnitSelect: {
      minWidth: 200,
    },
  }),
  { name: 'Adjustment' },
)

const formatDateAndTime = (date: string) =>
  `${DateUtils.formatDate(date)} ${DateUtils.formatTime(date)}`.trim()

export interface AdjustmentProps {
  adjustment?: Partial<Adjustment>
}

export interface AdjustmentHandle extends DataHandle<UnsavedAdjustment> {}

const AdjustmentComponent = forwardRef<AdjustmentHandle, AdjustmentProps>(
  ({ adjustment }, ref) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { t } = useTranslation(['Admin', 'Common', 'Tooltips'])
    const navigate = useNavigate()

    const permissions = useSelector(getCRUDByArea(PermissionArea.ADJUSTMENTS))
    const InventoryInStockUnit = useSelector(getInventoryInStockUnit)
    const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)
    const PackageType = useSelector(getPackageType)
    const SpaceType = useSelector(getSpaceType)
    const isIpoM0InvoiceRefundsEnabled = useSelector(
      getFeatureToggle(FeatureToggle.INVOICE_BASED_REFUNDS),
    )
    const InventoryAdjustmentReasons = useSelector(
      getInventoryAdjustmentReasons,
    )
    const SelectableInventoryAdjustmentReasons = useSelector(
      getSelectableInventoryAdjustmentReasons,
    )
    const InventoryAdjustmentMethod = useSelector(getInventoryAdjustmentMethod)
    const currentUser = useSelector(getCurrentUser)
    const spaceIsFetching = useSelector(getSpacesIsFetching)
    const spacesList = useSelector(getSpacesList)
    const spaces = useSelector(getMultipleSpaces(spacesList))
    const currentSpaceId = useSelector(getSpaceCurrentTypeId)

    const AdjustId = Utils.findConstantIdByName(
      'Adjusted quantity',
      InventoryAdjustmentMethod,
    )
    const SetDirectlyId = Utils.findConstantIdByName(
      'Set on-hand directly',
      InventoryAdjustmentMethod,
    )

    const isEdit = Boolean(adjustment?.id)
    const readOnly = isEdit
    const date = adjustment?.creationDate || moment().toISOString()
    const person = adjustment?.person || currentUser
    const initialUnitId = adjustment?.quantityUnitId
    const perPackageUnitsId = adjustment?.variation?.perPackageUnitsId
    const packageTypeId = adjustment?.variation?.packageTypeId
    const initialQuantity = isEdit ? adjustment?.quantity || 0 : 0

    const inStockUnitOptions = useInStockUnits(perPackageUnitsId, packageTypeId)

    const locationTypeSpaceId = Utils.findConstantIdByName(
      'Storage location',
      SpaceType,
    )
    const packageInStockId = Utils.findConstantIdByName(
      'Package',
      InventoryProductSizeUnit,
    )
    const getOnHandAmountLabel = getOnHandAmount(
      InventoryProductSizeUnit,
      PackageType,
      true,
    )
    const onHandAmount =
      adjustment?.variation && getOnHandAmountLabel(adjustment?.variation)

    const goToInvoice = (refundInvoiceId: string) => {
      navigate(`/refund/${refundInvoiceId}`)
    }

    const adjustmentInfoItems = [
      {
        name: t('Common:DATE_AND_TIME'),
        value: formatDateAndTime(date),
      },
      {
        name: t('Common:USER'),
        value: Utils.getPersonString(person),
      },
      onHandAmount && {
        name: t('Common:ON-HAND'),
        value: onHandAmount,
      },
      readOnly && {
        name: t('Common:ADJUSTMENT'),
        value: <AdjustmentAmountCell {...adjustment} />,
      },
      readOnly &&
        adjustment?.reasonId && {
          name: t('Common:ADJUSTMENT_REASON'),
          value: Utils.getConstantName(
            adjustment?.reasonId,
            InventoryAdjustmentReasons,
          ),
        },
      readOnly &&
        adjustment?.invoiceNo &&
        adjustment?.refundInvoiceId && {
          name: t('Common:INVOICE'),
          component: (
            <Text
              link
              fontSize="1.4rem"
              onClick={() => goToInvoice(adjustment.refundInvoiceId!)}
            >
              {adjustment.invoiceNo}
            </Text>
          ),
        },
      readOnly &&
        adjustment?.storageLocationId && {
          name: t('Common:STORAGE_LOCATION'),
          value: Utils.getConstantName(adjustment?.storageLocationId, spaces),
        },
      readOnly &&
        adjustment?.notes && {
          name: t('Common:NOTES'),
          value: adjustment?.notes,
        },
    ].filter(Boolean)

    const {
      fields: {
        quantity,
        quantityUnit,
        reason,
        storageLocationId,
        notes,
        method,
      },
      validate,
      reset,
    } = useFields(
      [
        {
          name: 'quantity',
          initialValue:
            adjustment?.desiredOnHandAmount ?? adjustment?.quantity ?? 0,
        },
        {
          name: 'quantityUnit',
          label: t('Common:UNIT'),
          type: 'select',
          validators: ['required'],
          initialValue: adjustment?.quantityUnitId ?? packageInStockId,
        },
        {
          name: 'method',
          label: t('Common:METHOD'),
          type: 'select',
          validators: ['required'],
          initialValue:
            adjustment?.methodId ||
            (R.isNil(adjustment?.quantity)
              ? AdjustId
              : R.isNil(adjustment?.desiredOnHandAmount)
              ? AdjustId
              : SetDirectlyId),
        },
        {
          name: 'reason',
          label: t('Common:ADJUSTMENT_REASON'),
          type: 'select',
          validators: ['required'],
          initialValue: adjustment?.reasonId,
        },
        {
          name: 'storageLocationId',
          label: t('Common:STORAGE_LOCATION'),
          type: 'select',
          initialValue: adjustment?.storageLocationId,
        },
        {
          name: 'notes',
          label: t('Common:NOTES'),
          initialValue: adjustment?.notes,
        },
      ],
      false,
    )

    const initialMinAmount = getMinimalAdjustmentAmountByOnHandAmount(
      InventoryInStockUnit,
      adjustment?.variation,
      initialQuantity,
      initialUnitId,
      quantityUnit.value,
    )

    useEffect(() => {
      reset()
    }, [adjustment])

    useEffect(() => {
      if (
        !spaceIsFetching &&
        locationTypeSpaceId &&
        currentSpaceId !== locationTypeSpaceId
      ) {
        dispatch(fetchSpacesListWithType(locationTypeSpaceId))
      }
    }, [currentSpaceId, spaceIsFetching])

    // do allow negative but the min amount must consider the current selected unit and
    // adjust the quantity accordingly to the minimum amount of each unit
    useEffect(() => {
      if (method.value === AdjustId && quantity.value <= initialMinAmount) {
        quantity.setValue(initialMinAmount)
      }
    }, [method.value, quantityUnit.value, initialMinAmount])

    // do not allow keep negative values when switching to SET_DIRECTLY method
    useEffect(() => {
      if (method.value === SetDirectlyId && quantity.value < 0) {
        quantity.setValue(0)
      }
    }, [method.value])

    useImperativeHandle(ref, () => ({
      validate,
      get: () => ({
        ...adjustment,
        desiredOnHandAmount:
          method.value === SetDirectlyId ? quantity.value : null,
        quantity:
          method.value === AdjustId
            ? quantity.value
            : getDirectAdjustmentDelta(
                InventoryInStockUnit,
                adjustment?.variation,
                initialQuantity,
                initialUnitId,
                quantity.value,
                quantityUnit.value,
              ),
        quantityUnitId: quantityUnit.value,
        reasonId: reason.value,
        storageLocationId: storageLocationId.value,
        inventoryVariationId: adjustment?.inventoryVariationId,
        notes: notes.value,
        methodId: method.value,
      }),
    }))

    const tooltipText = {
      Adjusted: t('Tooltips:POSITIVE_OR_NEGATIVE_ADJUSTMENT_ON_HAND'),
    }

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <InfoList items={adjustmentInfoItems} />
        </Grid>
        {!readOnly && (
          <>
            <Grid item>
              <EnumRadioGroup
                inline
                Constant={InventoryAdjustmentMethod}
                disabled={!permissions.update}
                field={method}
                label={`${method.label}:`}
                tooltipText={tooltipText}
              />
            </Grid>
            <Grid
              container
              item
              alignItems="flex-end"
              columnSpacing={2}
              wrap="nowrap"
            >
              <Grid item>
                <QuantityInput
                  allowDecimal
                  minReachedTooltipEnabled
                  showControls
                  disabled={!permissions.update}
                  field={quantity}
                  margin="none"
                  max={999999}
                  min={method.value === AdjustId ? initialMinAmount : 0}
                  minReachedTooltipText={t(
                    'Tooltips:ADJUSTMENT_CANNOT_BRING_ON_HAND_TOTAL_BELLOW_ZERO',
                  )}
                />
              </Grid>
              <Grid item>
                <FormControl
                  className={classes.quantityUnitSelect}
                  margin="none"
                >
                  <InputLabel>{quantityUnit.label}*</InputLabel>
                  <PuiSelect
                    disabled={!permissions.update}
                    field={quantityUnit}
                    items={inStockUnitOptions}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {method.value === AdjustId && (
              <Grid item>
                <Text>
                  {t(
                    'Admin:CATALOG.ADJUSTMENT.UPDATED_TOTAL_ON-HAND_INVENTORY',
                  )}
                  :{' '}
                  {getOnHandAmountLabel(
                    getVariationWithAddedOnHandAmount(
                      InventoryInStockUnit,
                      adjustment?.variation,
                      initialQuantity,
                      initialUnitId,
                      quantity.value,
                      quantityUnit.value,
                    ),
                  )}
                </Text>
              </Grid>
            )}
            <Grid container item>
              <FormControl fullWidth margin="none">
                <InputLabel>{reason.label}*</InputLabel>
                <PuiSelect
                  disabled={!permissions.update}
                  field={reason}
                  items={
                    isIpoM0InvoiceRefundsEnabled
                      ? SelectableInventoryAdjustmentReasons
                      : InventoryAdjustmentReasons
                  }
                  renderEmpty={false}
                />
              </FormControl>
            </Grid>
            <Grid container item>
              <FormControl fullWidth margin="none">
                <InputLabel>{storageLocationId.label}</InputLabel>
                <PuiSelect
                  disabled={!permissions.update}
                  field={storageLocationId}
                  items={spaces}
                />
              </FormControl>
            </Grid>
            <Grid container item>
              <PuiTextArea
                disabled={!permissions.update}
                field={notes}
                label={notes.label}
              />
            </Grid>
          </>
        )}
      </Grid>
    )
  },
)

export default AdjustmentComponent
