import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  Nil,
  PuiDialog,
  PuiTheme,
  Text,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import {
  applyPaymentToInvoice,
  linkUnappliedPayment,
} from '~/store/actions/payments'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getFinanceIsLoading } from '~/store/reducers/finance'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import PaymentInvoiceSelection from './PaymentInvoiceSelection'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
    header: {
      borderBottom: theme.constants.tabBorder,
    },
  }),
  { name: 'ApplyPaymentToInvoiceDialog' },
)

interface ApplyPaymentToInvoiceDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  paymentAmount: number
  paymentId: string
}

const ApplyPaymentToInvoiceDialog = ({
  open,
  onClose,
  paymentId,
  paymentAmount,
  clientId,
}: ApplyPaymentToInvoiceDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoading = useSelector(getFinanceIsLoading)
  const isIpoM0EstimatesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_ESTIMATES),
  )
  const { t } = useTranslation(['Common', 'Dialogs'])

  const [invoiceIds, setInvoiceIds] = useState<string[]>([])

  const setCloseAfterAppliedOn = useCloseAfterCreation(
    onClose,
    getFinanceIsLoading,
  )

  const onContinue = () => {
    setCloseAfterAppliedOn()
    if (isIpoM0EstimatesEnabled) {
      dispatch(linkUnappliedPayment(invoiceIds, paymentId))
    } else {
      dispatch(applyPaymentToInvoice(paymentId, invoiceIds))
    }
  }

  return (
    <PuiDialog
      aria-labelledby="apply-payment-to-invoice-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <Grid item className={classes.header} pb={1} pt={3} px={3}>
        <Text variant="h2">
          {t('Dialogs:APPLY_PAYMENT_TO_INVOICE_DIALOG.HEADER_TEXT')}
        </Text>
      </Grid>
      <PaymentInvoiceSelection
        canEditAmount={false}
        clientId={clientId}
        continueButtonText={t('Common:FINISH_ACTION')}
        invoiceIds={invoiceIds}
        isLoading={isLoading}
        paymentAmount={paymentAmount}
        setInvoiceIds={setInvoiceIds}
        onContinue={onContinue}
      />
    </PuiDialog>
  )
}

export default ApplyPaymentToInvoiceDialog
