import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { List, ListItem, ListItemText } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { Business, PuiTheme } from '@pbt/pbt-ui-components'
import { Lock as LockIcon } from '@pbt/pbt-ui-components/src/icons'

import { useUpdateCurrentUsersBusinessId } from '~/store/hooks/user'
import {
  getAssignedLocations,
  getCurrentBusinessId,
  getCurrentUser,
} from '~/store/reducers/auth'
import { getLockWorkingHoursSettings } from '~/store/reducers/businesses'
import { arrayToMap } from '~/utils'
import { businessSortingFunction } from '~/utils/businessUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      padding: 0,
    },
    listItem: {
      height: 56,
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
      color: 'rgba(255, 255, 255, 0.5)',
    },
    listItemText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    listItemSelected: {
      backgroundColor: 'transparent !important',
      color: 'rgba(255, 255, 255, 1)',
    },
    divider: {
      borderBottom: 'none',
      borderTop: '1px solid rgba(245, 249, 251, 0.6)',
    },
    text: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      lineHeight: '24px',
      fontWeight: 500,
      maxWidth: 245,
      maxHeight: 35,
      fontSize: '1.6rem',
    },
  }),
  { name: 'ClinicSelectorMobile' },
)

export interface ClinicSelectorMobileProps {
  open: boolean
}

const ClinicSelectorMobile = ({ open }: ClinicSelectorMobileProps) => {
  const classes = useStyles()
  const assignedBusinesses = useSelector(getAssignedLocations)
  const currentUser = useSelector(getCurrentUser)
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const lockWorkingHoursSettings = useSelector(getLockWorkingHoursSettings)

  const updateCurrentUsersBusinessId = useUpdateCurrentUsersBusinessId()

  const businessesLockMap = arrayToMap(
    lockWorkingHoursSettings,
    R.prop('businessId'),
    R.prop('locked'),
  )

  const [resortedBusinesses, setResortedBusinesses] = useState<Business[]>([])

  useEffect(() => {
    if (open) {
      setResortedBusinesses(
        assignedBusinesses.sort((a, b) =>
          businessSortingFunction(a, b, currentBusinessId),
        ),
      )
    }
  }, [open])

  return (
    <List className={classes.root}>
      {resortedBusinesses.map((business, id) => {
        const businessLocked = Boolean(businessesLockMap[business.id])
        return (
          <ListItem
            button
            className={classes.listItem}
            classes={{
              selected: classes.listItemSelected,
              divider: classes.divider,
            }}
            disabled={businessLocked}
            divider={id !== 0}
            key={business.id}
            selected={business.id === currentBusinessId}
            onClick={() =>
              updateCurrentUsersBusinessId({
                businessId: business.id,
                userId: currentUser.id,
              })
            }
          >
            <ListItemText
              className={classes.listItemText}
              primary={business.name}
              primaryTypographyProps={{
                className: classes.text,
                color: 'inherit',
                noWrap: true,
              }}
            />
            {businessLocked && <LockIcon />}
          </ListItem>
        )
      })}
    </List>
  )
}

export default ClinicSelectorMobile
