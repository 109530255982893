import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Nil, PuiTheme } from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import { AlertColorLevel, useGetAlertColor } from '~/constants/alertColors'
import { useAlertType } from '~/store/hooks/patient'
import { getPatient } from '~/store/reducers/patients'

import TooltipChip from './TooltipChip'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    icon: {
      fontSize: '1.2rem',
      fill: theme.colors.alertErrorText,
    },
  }),
  { name: 'PatientWarningChip' },
)

export interface PatientWarningChipProps {
  className?: string
  patientId: string | Nil
}

const PatientWarningChip = ({
  patientId,
  className,
}: PatientWarningChipProps) => {
  const patient = useSelector(getPatient(patientId))
  const { alertsToRender } = useAlertType(patientId)
  const alertColor = useGetAlertColor(
    patient?.alertColorId,
    AlertColorLevel.PATIENT,
  )
  const classes = useStyles({ alertColor })

  const hasWarning = alertsToRender.length > 0

  return (
    <>
      {hasWarning && (
        <TooltipChip
          className={className}
          color={alertColor}
          icon={<WarningIcon className={classNames(classes.icon)} />}
          tooltipPlacement="top"
          tooltipText={alertsToRender.join(',')}
        />
      )}
    </>
  )
}

export default PatientWarningChip
