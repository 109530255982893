import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  Nil,
  PrimitiveTableColumn,
  PrimitiveTableProps,
  PuiTheme,
} from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import { getClientIsFetchingList } from '~/store/reducers/clients'

import ClientsTableRow from './ClientsTableRow'
import MembershipFilter from './MembershipFilter'
import { PatientRowActionButton } from './PatientRow'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    patientColumn: {
      paddingLeft: theme.spacing(2.5),
    },
    header: {
      minHeight: 0,
    },
    tableContainer: {
      padding: 0,
    },
    tableHeader: {
      padding: 0,
    },
  }),
  { name: 'ClientsTable' },
)

interface ClientsTableProps
  extends Omit<
    PrimitiveTableProps,
    'isItemLoaded' | 'columns' | 'onItemClick'
  > {
  actionButtons?: PatientRowActionButton[]
  classes?: ClassesType<typeof useStyles>
  hideActionsColumn?: boolean
  hideFlagsColumn?: boolean
  hideLastAppointmentColumn?: boolean
  hideMembershipColumn?: boolean
  hideScheduleColumn?: boolean
  hideSharedColumn?: boolean
  highlightProps: Record<string, string[]>
  isItemLoaded: (index: number) => boolean
  list: string[]
  onItemClick: (clientId: string, patientId: string | Nil) => void
}

const ClientsTable = ({
  actionButtons,
  list,
  onItemClick,
  isItemLoaded,
  hideFlagsColumn,
  hideScheduleColumn,
  hideLastAppointmentColumn,
  hideActionsColumn,
  hideMembershipColumn,
  hideSharedColumn,
  highlightProps,
  classes: classesProp,
  ...rest
}: ClientsTableProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Clients', 'Common'])

  const isLoading = useSelector(getClientIsFetchingList)

  const columns = [
    {
      label: t('Common:CLIENT'),
      width: hideMembershipColumn ? 3 : 2,
    },
    {
      className: classes.patientColumn,
      label: t('Common:PATIENT'),
      width: hideMembershipColumn ? 6 : 5,
    },
    !hideFlagsColumn && {
      width: hideSharedColumn ? 3 : 2,
      label: t('Common:FLAGS'),
    },
    !hideLastAppointmentColumn && {
      width: 3,
      label: t('Clients:CLIENTS_TABLE.LAST_APPOINTMENT'),
    },
    !hideScheduleColumn && {
      width: 2,
      label: t('Common:APPOINTMENT_OTHER'),
    },
    !hideMembershipColumn && {
      width: 2,
      label: t('Common:WELLNESS_PLANS'),
      filter: 'membership',
      FilterComponent: MembershipFilter,
    },
    !hideSharedColumn && {
      width: 1.5,
      label: t('Common:SHARED'),
    },
  ].filter(Boolean) as PrimitiveTableColumn[]

  return (
    <ExpandableTable
      classes={{
        header: classes.header,
        tableContainer: classes.tableContainer,
      }}
      isLoading={isLoading}
      list={list}
    >
      <PrimitiveTableWithSearchHighlights
        HeaderProps={{
          classes: {
            tableHeader: classes.tableHeader,
          },
        }}
        RowComponent={ClientsTableRow}
        RowComponentProps={{
          actionButtons,
          hideActionsColumn,
          hideFlagsColumn,
          hideLastAppointmentColumn,
          hideScheduleColumn,
          hideMembershipColumn,
          hideSharedColumn,
          highlightProps,
          onClick: onItemClick,
        }}
        columns={columns}
        isItemLoaded={isItemLoaded}
        itemSpacing={16}
        {...rest}
      />
    </ExpandableTable>
  )
}

export default ClientsTable
