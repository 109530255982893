import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Fab, Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PermissionArea, PuiTheme } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import {
  getCRUDByArea,
  getCurrentBusiness,
  getHasUserAccessToBenchmarkKeys,
} from '~/store/reducers/auth'
import useDialog from '~/utils/useDialog'

import BenchmarkingActivationKeyDetails from './BenchmarkingActivationKeyDetails'
import BenchmarkingTableComponent from './BenchmarkingTableComponent'

const useStyles = makeStyles(
  () => ({
    button: {
      minWidth: 135,
      height: 40,
    },
  }),
  { name: 'BenchmarkingApiAccessPage' },
)

const BenchmarkingApiAccessPage = () => {
  const classes = useStyles()
  const { t } = useTranslation('Admin')
  const navigate = useNavigate()
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('md'),
  )
  const { activationKeyId } = useParams()

  const currentBusiness = useSelector(getCurrentBusiness)
  const hasUserAccessToBenchmarkKeys = useSelector(
    getHasUserAccessToBenchmarkKeys(currentBusiness),
  )
  const permissions = useSelector(getCRUDByArea(PermissionArea.PERSON))
  const [openNewBenchmarkingActivationDialog] = useDialog(
    DialogNames.NEW_BENCHMARKING_ACTIVATION_KEY,
  )

  if (!hasUserAccessToBenchmarkKeys) {
    navigate('/')
  }

  const tryOpenDialog = () => {
    if (permissions.create) {
      openNewBenchmarkingActivationDialog()
    }
  }

  const onDetailsClose = () => {
    navigate('/admin/general/benchmarking')
  }

  if (isMobile && activationKeyId) {
    return (
      <BenchmarkingActivationKeyDetails
        itemId={activationKeyId}
        onClose={onDetailsClose}
      />
    )
  }

  const headerButtons = (
    <Grid container item justifyContent="flex-end" px={3} py={2}>
      <Fab
        className={classes.button}
        color="inherit"
        variant="extended"
        onClick={tryOpenDialog}
      >
        {t('Admin:BENCHMARK.NEW_ACTIVATION_KEY')}
      </Fab>
    </Grid>
  )

  return (
    <Grid container item flex={1} wrap="nowrap">
      <BenchmarkingTableComponent
        activationKeyId={activationKeyId}
        headerButtons={headerButtons}
        onDetailsClose={onDetailsClose}
      />
    </Grid>
  )
}

export default BenchmarkingApiAccessPage
