import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { NumberUtils, PuiTheme, Text } from '@pbt/pbt-ui-components'

import { InvoiceOrEstimate } from '~/types'

import { getInvoiceTotals } from '../../invoiceUtils'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      flexShrink: 0,
    },
    summary: {
      borderLeft: theme.constants.tableBorder,
      width: `calc(500px - ${theme.spacing(3)})`,
      padding: theme.spacing(1, 0, 1, 2),
      backgroundColor: theme.colors.tableEvenItem,
    },
    summaryItem: {
      flexShrink: 0,
    },
    leftContainer: {
      borderRight: theme.constants.tableBorder,
    },
  }),
  { name: 'InvoiceSummary' },
)

export interface InvoiceSummaryProps {
  invoice: InvoiceOrEstimate
}

const InvoiceSummary = ({ invoice }: InvoiceSummaryProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const totals = getInvoiceTotals(invoice)

  return (
    <Grid container item className={classes.root} wrap="nowrap">
      <Grid item xs />
      <Grid
        container
        item
        className={classes.summary}
        direction="column"
        justifyContent="center"
      >
        <Grid
          container
          item
          className={classes.summaryItem}
          justifyContent="space-between"
        >
          <Grid item className={classes.leftContainer} pl={1} xs={8}>
            <Text variant="body2">{t('Common:SUBTOTAL')}</Text>
          </Grid>
          <Grid item pr={2} xs={4}>
            <Text align="right" variant="body2">
              {NumberUtils.formatMoney(totals.totalWithoutTax)}
            </Text>
          </Grid>
        </Grid>
        <Grid
          container
          item
          className={classes.summaryItem}
          justifyContent="space-between"
        >
          <Grid item className={classes.leftContainer} pl={1} xs={8}>
            <Text variant="body2">{t('Common:DISCOUNT')}</Text>
          </Grid>
          <Grid item pr={2} xs={4}>
            <Text align="right" variant="body2">
              {NumberUtils.formatMoney(-totals.totalDiscountWithAdditional)}
            </Text>
          </Grid>
        </Grid>
        <Grid
          container
          item
          className={classes.summaryItem}
          justifyContent="space-between"
        >
          <Grid item className={classes.leftContainer} pl={1} xs={8}>
            <Text variant="body2">{t('Common:TAX')}</Text>
          </Grid>
          <Grid item pr={2} xs={4}>
            <Text align="right" variant="body2">
              {NumberUtils.formatMoney(totals.totalTax)}
            </Text>
          </Grid>
        </Grid>
        <Grid
          container
          item
          className={classes.summaryItem}
          justifyContent="space-between"
        >
          <Grid item className={classes.leftContainer} pl={1} xs={8}>
            <Text strong variant="subheading3">
              {t('Common:TOTAL')}
            </Text>
          </Grid>
          <Grid item pr={2} xs={4}>
            <Text strong align="right" variant="subheading3">
              {NumberUtils.formatMoney(totals.totalWithoutFee)}
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InvoiceSummary
