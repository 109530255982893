import { gql } from '@apollo/client/core'

import { INVOICE_LINE_ITEM_FIELDS } from '../fragments'

export const GENERATE_HTML_CHARGE_SHEET = gql`
  mutation GenerateHtmlForChargeSheet(
    $businessId: ID!
    $clientId: ID!
    $expandedGroups: [GroupIdentifier!]
    $includeServiceFee: Boolean
  ) {
    generateHtmlForChargeSheet(
      businessId: $businessId
      clientId: $clientId
      expandedGroups: $expandedGroups
      includeServiceFee: $includeServiceFee
    ) {
      title
      content
    }
  }
`

export const ADD_CHARGE_SHEET_ITEMS = gql`
  ${INVOICE_LINE_ITEM_FIELDS}
  mutation AddItemsToChargeSheet(
    $clientId: ID!
    $createItemInputs: [CreateChargeSheetItemInput!]!
  ) {
    addItemsToChargeSheet(
      clientId: $clientId
      createItemInputs: $createItemInputs
    ) {
      ...InvoiceLineItemFields
    }
  }
`

export const EDIT_CHARGE_SHEET_ITEM_PRODUCER_BATCH = gql`
  ${INVOICE_LINE_ITEM_FIELDS}
  mutation UpdateChargeSheetLineItemProducerBatch(
    $input: [InvoiceLineItemProducerInput!]!
  ) {
    updateChargeSheetLineItemProducerBatch(input: $input) {
      ...InvoiceLineItemFields
    }
  }
`

export const DELETE_CHARGE_SHEET_ITEM = gql`
  mutation DeleteChargeSheetItems(
    $modifierId: ID!
    $deleteItemInputs: [DeleteChargeSheetItemInput!]!
  ) {
    deleteChargeSheetItems(
      modifierId: $modifierId
      deleteItemInputs: $deleteItemInputs
    )
  }
`
export const UPDATE_SECTION_ADDITIONAL_DISCOUNT = gql`
  mutation UpdateSectionsAdditionalDiscount(
    $modifierId: ID!
    $updateSectionsDiscountInputs: [UpdateSectionDiscountInput!]!
  ) {
    batchUpdateSectionsAdditionalDiscount(
      modifierId: $modifierId
      updateSectionsDiscountInputs: $updateSectionsDiscountInputs
    ) {
      id
      clientId
    }
  }
`

export const EDIT_CHARGE_SHEET_ITEM_BATCH = gql`
  ${INVOICE_LINE_ITEM_FIELDS}
  mutation EditChargeSheetItemBatch(
    $updateItemInput: [ModifiedChargeSheetLineItemInput!]!
  ) {
    updateChargeSheetItemBatch(updateItemInput: $updateItemInput) {
      ...InvoiceLineItemFields
    }
  }
`
