import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  BackButton,
  ButtonWithLoader,
  DocumentFile,
  FileTemplate,
  Nil,
  PuiTheme,
  SignButton,
} from '@pbt/pbt-ui-components'

import DownloadButton from '~/components/common/buttons/DownloadButton'
import SingleFileSelector from '~/components/common/inputs/SingleFileSelector'
import PrintHtml from '~/components/common/print/PrintHtml'
import DialogNames from '~/constants/DialogNames'
import { HTML } from '~/constants/extensions'
import { print } from '~/store/duck/print'
import {
  getDocumentsIsSending,
  getResolvedDocumentBody,
} from '~/store/reducers/documents'
import { getIsAttachingDocuments } from '~/store/reducers/soap'
import { Document as DocumentType } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import useUserContext from '~/utils/useUserContext'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    soapButton: {
      minWidth: 120,
    },
    button: {
      minWidth: 100,
      height: 40,
      marginRight: theme.spacing(1),
    },
    footer: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      padding: theme.spacing(1, 2),
      borderRadius: '0 0 2px 2px',
      backgroundColor: theme.colors.tableBackground,
      boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.1)',
    },
  }),
  { name: 'DocumentPreviewActions' },
)

interface DocumentPreviewActionsProps {
  clientId: string
  conversationId: string
  document: DocumentType
  hideEmail?: boolean
  hidePrint?: boolean
  iframeDocument: Document | Nil
  isSOAP?: boolean
  onBack: () => void
  onDocumentSigned: (file: DocumentFile | Nil) => void
  onDocumentUpdate: (fileTemplate: FileTemplate) => void
  onFileSelected: (fileTemplate: FileTemplate) => void
  onProceed: (document: DocumentType) => void
  patientId: string
  resolvePlaceholders?: boolean
  showSaveButton?: boolean
  soapFiles?: any[]
  view?: boolean
}

const DocumentPreviewActions = ({
  conversationId,
  document,
  view,
  onBack,
  onDocumentUpdate,
  onDocumentSigned,
  onProceed,
  isSOAP,
  soapFiles,
  onFileSelected,
  resolvePlaceholders = false,
  iframeDocument,
  showSaveButton,
  hideEmail = false,
  hidePrint = false,
  clientId: clientIdProp,
  patientId: patientIdProp,
}: DocumentPreviewActionsProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Admin', 'Common'])

  const [contextClientId, contextPatientId] = useUserContext({ conversationId })

  const clientId = contextClientId || clientIdProp
  const patientId = contextPatientId || patientIdProp
  const isSending = useSelector(getDocumentsIsSending)
  const isSoapUpdate = useSelector(getIsAttachingDocuments)
  const resolvedDocumentBody = useSelector(getResolvedDocumentBody)

  const dispatch = useDispatch()

  const [showPrint, setShowPrint] = useState(false)
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false)

  const [openEmailAttachmentDialog] = useDialog(
    DialogNames.EMAIL_ATTACHMENT_DIALOG,
  )

  const setSaveButtonEnabledAfterSoapUpdate = useCloseAfterCreation(() => {
    setSaveButtonEnabled(false)
  }, getIsAttachingDocuments)

  const isHTML = document?.template?.extension === HTML
  const hasIFrameDocument = Boolean(iframeDocument?.body?.innerHTML)
  const documentTemplate =
    document?.template || (document as DocumentType['template'])
  const body = documentTemplate?.body
  const useBackendResolvedBody = body && resolvePlaceholders
  const template = useBackendResolvedBody
    ? { ...documentTemplate, body: resolvedDocumentBody }
    : documentTemplate

  const proceed = () => {
    onProceed(document)
  }

  const handleSendEmail = () => {
    openEmailAttachmentDialog({
      selectedDocuments: [document.id],
      soapId: document.soapId,
      patientId,
      clientId,
      scopedAttachments: R.uniq([...(soapFiles || []), document]),
    })
  }

  return (
    <>
      {!view && (
        <>
          <Grid item>
            <BackButton label={t('Common:BACK_ACTION')} onClick={onBack} />
          </Grid>
          <SingleFileSelector onSelected={onFileSelected}>
            <Fab
              className={classes.button}
              color="primary"
              type="button"
              variant="extended"
            >
              {isHTML
                ? t('Admin:CATALOG.DOCUMENT_PREVIEW.REPLACE_WITH_FILE')
                : t('Common:CHANGE_FILE')}
            </Fab>
          </SingleFileSelector>
          <ButtonWithLoader
            className={classes.button}
            disabled={isSending}
            loading={isSending}
            onClick={proceed}
          >
            {t('Common:ADD_ACTION')}
          </ButtonWithLoader>
        </>
      )}
      {isSOAP && showSaveButton && (
        <Grid item>
          <ButtonWithLoader
            className={classNames(classes.button, classes.soapButton)}
            disabled={isSoapUpdate || !saveButtonEnabled}
            loading={isSoapUpdate}
            onClick={() => {
              setSaveButtonEnabledAfterSoapUpdate()
              const documentBody = iframeDocument?.body?.innerHTML
              onDocumentUpdate({
                ...R.omit(['fileUrl'], document),
                file: {
                  extension: document.extension,
                  body: documentBody,
                },
              } as FileTemplate)
            }}
          >
            {t('Common:SAVE_ACTION')}
          </ButtonWithLoader>
        </Grid>
      )}
      {!hidePrint && !saveButtonEnabled && (
        <Grid item>
          <ButtonWithLoader
            className={classNames(classes.button, classes.soapButton)}
            disabled={isSending}
            loading={isSending}
            onClick={() => {
              if (hasIFrameDocument) {
                setShowPrint(true)
              } else if (template?.fileUrl) {
                dispatch(print(template.fileUrl))
              }
            }}
          >
            {t('Common:PRINT_ACTION')}
          </ButtonWithLoader>
        </Grid>
      )}
      {!hideEmail && !saveButtonEnabled && (
        <Grid item xs={!isSOAP}>
          <ButtonWithLoader
            className={classNames(classes.button, classes.soapButton)}
            disabled={isSending}
            loading={isSending}
            onClick={handleSendEmail}
          >
            {t('Common:EMAIL')}
          </ButtonWithLoader>
        </Grid>
      )}
      {isSOAP && (
        <Grid item xs>
          <SignButton
            className={classes.button}
            document={document}
            iframeDocument={iframeDocument}
            onCheckboxChange={() => setSaveButtonEnabled(true)}
            onSigned={(newDocument) => {
              setSaveButtonEnabled(true)
              onDocumentSigned(!showSaveButton ? newDocument : null)
            }}
          />
        </Grid>
      )}
      {template?.fileUrl && !saveButtonEnabled && (
        <Grid item>
          <DownloadButton round href={template.fileUrl} />
        </Grid>
      )}
      {showPrint && hasIFrameDocument && (
        <PrintHtml
          source={iframeDocument?.body?.innerHTML}
          onOk={() => setShowPrint(false)}
        />
      )}
    </>
  )
}

export default DocumentPreviewActions
