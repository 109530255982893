import React, { useState } from 'react'
import { FormControl, Grid, MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ClassesType,
  Constant,
  ConstantWithColors,
  Field,
  PuiSelect,
  PuiSelectProps,
  PuiTheme,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import { ColorVariant } from '~/constants/colors'

import AccentSelectItem from './AccentSelectItem'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    fitsContentWidth: {
      width: 'auto',
    },
    rightPaddingSmall: {
      '&&': {
        paddingRight: theme.spacing(1),
      },
    },
    statusSelect: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(4),
      paddingBottom: 0,
      fontWeight: 500,
      borderRadius: 3,
      color: theme.colors.lowAccentText,
    },
    colorNormal: {
      color: theme.colors.title,
      backgroundColor: theme.colors.newLabel,
      '&&:focus': {
        backgroundColor: theme.colors.newLabel,
      },
    },
    colorImportant: {
      color: theme.colors.important,
      backgroundColor: theme.colors.actionNeededBackground,
      '&&:focus': {
        backgroundColor: theme.colors.actionNeededBackground,
      },
    },
    colorSuccess: {
      color: theme.colors.success,
      backgroundColor: theme.colors.successBackground,
      '&&:focus': {
        backgroundColor: theme.colors.successBackground,
      },
    },
    colorError: {
      color: theme.colors.profileText,
      backgroundColor: theme.colors.errorColor,
      '&&:focus': {
        backgroundColor: theme.colors.errorColor,
      },
    },
    colorDisabled: {
      color: theme.colors.disabledLabelText,
      backgroundColor: theme.colors.disabledLabel,
      '&:focus': {
        backgroundColor: theme.colors.disabledLabel,
      },
    },
  }),
  { name: 'EnumSelect' },
)

export interface EnumSelectProps
  extends Omit<PuiSelectProps, 'classes' | 'margin'> {
  Constant?: ConstantWithColors[]
  accent?: boolean
  className?: string
  classes?: ClassesType<typeof useStyles>
  colorVariantMap?: Record<string, ColorVariant>
  disabled?: boolean
  field: Field
  filter?: (entry: Constant, value: any, Constant: Constant[]) => boolean
  fullWidth?: boolean
  label?: string
  margin?: 'none' | 'normal'
  openOnHover?: boolean
  renderEmpty?: boolean
}

const EnumSelect = ({
  Constant: ConstantProp = [],
  accent = false,
  className: classNameProp,
  classes: classesProp,
  colorVariantMap = {},
  disabled,
  field,
  filter,
  fullWidth,
  label,
  renderEmpty = false,
  openOnHover = false,
  margin = 'none',
  ...rest
}: EnumSelectProps) => {
  const classes = useStyles({ classes: classesProp })
  const [open, setOpen] = useState(false)
  const currentItem = Utils.findById(field.value, ConstantProp) || {}
  const currentColorVariant =
    colorVariantMap[currentItem.name] ||
    currentItem.colorVariant ||
    ColorVariant.NORMAL
  const items = filter
    ? ConstantProp.filter((entry) => filter(entry, field.value, ConstantProp))
    : ConstantProp

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const extendedItems = items.map((item) => ({
    ...item,
    colorVariant: colorVariantMap[item.name] || item.colorVariant,
  }))

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(classNameProp, {
        [classes.fitsContentWidth]: !fullWidth,
      })}
    >
      {label && (
        <Grid item mr={2}>
          <Text strong variant="body2">
            {label}:
          </Text>
        </Grid>
      )}
      <Grid item maxWidth="100%" xs={fullWidth}>
        <FormControl fullWidth error={!field.valid} margin={margin}>
          <PuiSelect
            disableUnderline
            Item={accent ? AccentSelectItem : MenuItem}
            classes={{
              select: classNames(classes.statusSelect, {
                [classes.colorNormal]:
                  accent &&
                  field.value &&
                  currentColorVariant === ColorVariant.NORMAL,
                [classes.colorImportant]:
                  accent &&
                  field.value &&
                  currentColorVariant === ColorVariant.IMPORTANT,
                [classes.colorDisabled]:
                  accent &&
                  field.value &&
                  currentColorVariant === ColorVariant.DISABLED,
                [classes.colorSuccess]:
                  accent &&
                  field.value &&
                  currentColorVariant === ColorVariant.SUCCESS,
                [classes.colorError]:
                  accent &&
                  field.value &&
                  currentColorVariant === ColorVariant.ERROR,
                [classes.rightPaddingSmall]: disabled,
              }),
            }}
            disabled={disabled}
            field={field}
            items={extendedItems}
            open={open}
            renderEmpty={renderEmpty}
            onClose={handleClose}
            onMouseEnter={openOnHover ? handleOpen : undefined}
            onOpen={handleOpen}
            {...rest}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default EnumSelect
